import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Price from "./Forms/Price";
import SchoolForm from "./Forms/Step2";
import SchoolUserForm from "./Forms/Step3";
import Sprintful from "./Forms/Step4";
import Backgrounds from "../../../Admin/Question/View/Background";
import api from "../../../../api/api";
import { SchoolRegisterBySocket } from "../../../../api/socket.io";
import { userLogin } from "../../../actions";
import { LazyLoadImage } from "react-lazy-load-image-component";

class SchoolRegister extends React.Component {

    state = {
        data: {},
        step: 1
    };

    componentDidMount() {
        window.scrollToPosition("school-register-section");
    }

    goBack = () => {
        if (this.state.step > 1) {
            this.setState({
                step: this.state.step - 1
            });
        }
    }

    goNext = data => {
        this.setState({
            data: { ...this.state.data, ...data },
            step: this.state.step + 1
        });
    }

    onSubmit = data => {
        const { t } = this.props;

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const postData = { ...this.state.data, ...data };

        api.post(`/SchoolRegister/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {
                SchoolRegisterBySocket(postData.SchoolName);
                this.goNext(data);
            }
            toast.dismiss(toastId);
        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    renderForm = () => {
        const { location } = this.props;
        if (this.state.step === 1)
            return <SchoolForm onSubmit={this.goNext} goBack={this.goBack} data={this.state.data} />;
        else if (this.state.step === 3)
            return <Price onSubmit={this.goNext} goBack={this.goBack} data={this.state.data} />;
        else if (this.state.step === 4)
            return <Sprintful onSubmit={this.goNext} goBack={this.goBack} data={this.state.data} />;
        else if (this.state.step === 2)
            return <SchoolUserForm
                onSubmit={this.onSubmit}
                goBack={this.goBack}
                country_code={location.location?.country_code}
                Address={location.location?.Address}
                data={this.state.data} />;
    }

    renderPoint = state => {
        if (state === 1) {
            return <div>
                <i className="fa-regular fa-circle-check text-primary fa-xl"></i>
            </div>
        } else if (state === 2) {
            return <div>
                <i className="fa-regular fa-circle-dot text-primary fa-xl"></i>
            </div>
        }
        return <div>
            <i className="fa-regular fa-circle fa-xl text-secondary"></i>
        </div>
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.School.title')} | {t('alarabeyya')}
                    </title>
                    <script type="text/javascript" src="https://app.sprintful.com/widget/v1.js"></script>
                    <link href="https://app.sprintful.com/widget/v1.css" rel="stylesheet"></link>
                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% );overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}.footer-bottom{display:none;}` }}></style>
                <section className="pos-rel call-in-action call-overlay oh" id="school-register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53'
                }}>

                    <div className="position-relative padding-bottom padding-top" >
                        <Backgrounds CustomBackground="Registration" />
                        <div className="container h-100" >
                            <div className="widget widget-category col-xl-12 mx-auto">
                                <div className="widget-header p-3 d-flex justify-content-center align-items-center">
                                    <LazyLoadImage src="/assets/images/logo/logo-1.png" alt={`${t('alarabeyya')}`} />
                                </div>
                                <div className="widget-body">

                                    <div className="d-flex justify-content-between align-items-center mb-3 px-2">
                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                            {
                                                this.renderPoint(this.state.step === 1 ? 2 : this.state.step >= 1 ? 1 : 0)
                                            }
                                        </div>
                                        <div className="d-flex align-items-center" style={{ flex: 1, justifyContent: 'space-evenly' }}>
                                            <i className={`fa-solid fa-arrow-left fa-xs  ${this.state.step >= 2 ? "text-primary" : "text-secondary"}`}></i>
                                            <i className={`fa-solid fa-arrow-left fa-xs  ${this.state.step >= 2 ? "text-primary" : "text-secondary"}`}></i>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                            {
                                                this.renderPoint(this.state.step === 2 ? 2 : this.state.step >= 2 ? 1 : 0)
                                            }
                                        </div>
                                        <div className="d-flex align-items-center" style={{ flex: 1, justifyContent: 'space-evenly' }}>
                                            <i className={`fa-solid fa-arrow-left fa-xs  ${this.state.step >= 3 ? "text-primary" : "text-secondary"}`}></i>
                                            <i className={`fa-solid fa-arrow-left fa-xs  ${this.state.step >= 3 ? "text-primary" : "text-secondary"}`}></i>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                            {
                                                this.renderPoint(this.state.step === 3 ? 2 : this.state.step >= 3 ? 1 : 0)
                                            }
                                        </div>
                                        <div className="d-flex align-items-center" style={{ flex: 1, justifyContent: 'space-evenly' }}>
                                            <i className={`fa-solid fa-arrow-left fa-xs  ${this.state.step >= 4 ? "text-primary" : "text-secondary"}`}></i>
                                            <i className={`fa-solid fa-arrow-left fa-xs  ${this.state.step >= 4 ? "text-primary" : "text-secondary"}`}></i>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                            {
                                                this.renderPoint(this.state.step === 4 ? 2 : this.state.step >= 4 ? 1 : 0)
                                            }
                                        </div>
                                    </div>
                                    {this.renderForm()}

                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};

export default connect(mapStateToProps, { userLogin })(withTranslation('common')(SchoolRegister));
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import Background from "../Background/";
import GameObjects from "../GameObjects/";
import Tools from "./Tools";
import MoveBackgroundObject from "./MoveBackground";

class PullView extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.Rocket = React.createRef();
        this.Drop = React.createRef();
    }

    state = {
        rotateFactor: 1,
        rotate: 0,
        categories: [],
        CustomStyle: configData.CustomStyle
    };

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.categories = props.categories;
        return state;
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.rotate <= 85 && this.state.rotate >= -85) {
                this.setState({
                    rotate: this.state.rotate + (3 * this.state.rotateFactor)
                });
            } else {
                this.setState({
                    rotateFactor: this.state.rotateFactor * -1,
                    rotate: this.state.rotateFactor === 1 ? 85 : -85
                });
            }
        }, 30);
    }

    render() {
        const { t, ViewGameResult, examAnswers, categories, answers } = this.props;

        let styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${this.state.CustomStyle.answerStyle.width}px`,
            minHeight: `${this.state.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`,
        };

        let styleBox = {
            minWidth: `${this.state.CustomStyle.answerBoxStyle.width}%`,
            maxWidth: '100%',
            minHeight: `${this.state.CustomStyle.answerBoxStyle.height}px`,
            paddingLeft: `${this.state.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerBoxStyle.BorderRadiusTop}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusRight}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerBoxStyle.ShadowPositionX}px ${this.state.CustomStyle.answerBoxStyle.ShadowPositionY}px ${this.state.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerBoxStyle.shadowColor}`,
            opacity: `${this.state.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerBoxStyle.BgAttachment}`,
        };

        let align = "x";
        if (this.state.CustomStyle.answerBoxStyle.AnswersAlign) {
            if (this.state.CustomStyle.answerBoxStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.answerBoxStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        const CustomBackground = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.CustomBackground : "";

        const MoveObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObject : "";
        const MoveObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrc : "";
        const MoveObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrcHover : "";
        const MoveCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveCustomColor : "";
        const AnswerOrder = this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswerOrder : "";
        const AnswersColsMargin = this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswersColsMargin : "";
        const OffsetTopObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.OffsetTopObject : "";

        const DropObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObject : "";
        const DropObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObjectSrc : "";
        const DropObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const DropCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropCustomColor : "";
        const TranslateTime = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.TranslateTime : "";
        const TranslateX = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.TranslateX : "";
        const RopeColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.RopeColor : "";
        const OffsetTop = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.OffsetTop : "";
        const FlipY = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.FlipY : "";
        const ShootObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.ShootObjectSrc : "";


        let Cursor = "";
        if (this.state.CustomStyle.gamesSetting.Cursor !== 'custom' && this.state.CustomStyle.gamesSetting.Cursor !== 'none' && this.state.CustomStyle.gamesSetting.Cursor) {
            Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.gamesSetting.Cursor;
        } else if (this.state.CustomStyle.gamesSetting.Cursor === 'custom' && this.state.CustomStyle.gamesSetting.CursorCustom) {
            Cursor = this.state.CustomStyle.gamesSetting.CursorCustom;
        }

        let styleBackground = {
            height: '500px',
            opacity: `${this.state.CustomStyle.gamesSetting.Opacity}%`,
            backgroundColor: this.state.CustomStyle.gamesSetting.backgroundColor !== 'transparent' ? this.state.CustomStyle.gamesSetting.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.gamesSetting.backgroundImage !== '' ? `url('${this.state.CustomStyle.gamesSetting.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.gamesSetting.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.gamesSetting.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.gamesSetting.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.gamesSetting.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.gamesSetting.BgAttachment}`,
            cursor: `url('${Cursor}') 20 20, auto`
        };

        let text = t("Pages.Games.Question");
        if (this.state.categories && this.state.categories.length > 1) {
            text = this.state.categories[0].Name;
        }

        let width = 0;
        if (this.Rocket && this.Rocket.current) {
            width = this.Rocket.current.clientWidth / 2;
        }

        let heightDrop = 200;
        let widthDrop = 200;
        if (this.Drop && this.Drop.current) {
            heightDrop = this.Drop.current.clientHeight;
            widthDrop = this.Drop.current.clientWidth;
        }

        let style = {
            bottom: `${this.Drop && this.Drop.current ? this.Drop.current.clientHeight - parseFloat(OffsetTop) : 0}px`,
        };
        if (AnswerOrder === "bottom") {
            style = {
                top: `${this.Drop && this.Drop.current ? this.Drop.current.clientHeight + parseFloat(OffsetTop) : 0}px`,
            };
        }

        if (ViewGameResult) {
            return <>
                <div className="widget">
                    <div className="widget-body">
                        {
                            categories.sort((a, b) => a.Sort - b.Sort).map(category => {
                                return <table className="table table-striped table-hover table-bordered" key={`table-${category.Id}`}>
                                    <caption className="text-center pt-3 pb-1" style={{
                                        captionSide: 'top',
                                        color: '#000'
                                    }}><div className="text-center d-flex justify-content-center align-items-center h4 mb-0" dangerouslySetInnerHTML={{
                                        __html: category.Name
                                    }}></div></caption>
                                    <tbody>
                                        <tr className="text-center">
                                            <th>
                                                {t('Pages.Missions.table.userCategoryAnswer')}
                                            </th>
                                            <th>
                                                {t('Pages.Missions.table.result')}
                                            </th>
                                        </tr>
                                        {
                                            examAnswers && examAnswers[0] && examAnswers[0].filter(p => p.Category.Id === category.Id).length > 0
                                                ? examAnswers[0].filter(p => p.Category.Id === category.Id).map(item => {
                                                    const answer = answers.filter(p => p.Id === item.Answer.Id)[0];
                                                    if (answer) {
                                                        return <tr className="text-center" key={`Answer-${category.Id}-${item.Answer.Id}-${item.Answer.Sort}`}>
                                                            <td className="text-center" ><div className="text-center d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{
                                                                __html: answer.Name
                                                            }}></div></td>
                                                            <td className="text-center">
                                                                {
                                                                    item.isTrue ?
                                                                        <i className="fas fa-check-square text-success mx-2" title={t('Pages.Lessons.Questions.forms.trueAnswer')}></i>
                                                                        :
                                                                        <i className="fas fa-times-square text-danger mx-2" title={t('Pages.Lessons.Questions.forms.falseAnswer')}></i>
                                                                }</td>
                                                        </tr>;
                                                    }
                                                    return "";

                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={2}>{t('Pages.Missions.table.NoCategoryAnswer')}</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            })
                        }
                    </div>
                </div >
                <div className="widget">
                    <div className="widget-body">
                        <table className="table table-striped table-hover table-bordered">
                            {this.props.renderFooter()}
                        </table>
                    </div>
                </div >
            </>
        }

        return (
            <div className={`w-100  mx-auto`} style={{
                userSelect: 'none'
            }}>
                <div className="bg-white w-100 position-relative"  >
                    <div className="w-100 position-relative oh" style={styleBackground}>
                        <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${this.state.CustomStyle.answerStyle.Custom} } .dragdrop-static-box{${this.state.CustomStyle.answerBoxStyle.Custom}} @keyframes dropObjectMovment{ 0%{transform:translateX(0%)} 25%{transform:translateX(${TranslateX}%)} 50%{transform:translateX(0%)} 75%{transform:translateX(-${TranslateX}%)} 100%{transform:translateX(0%)} }` }}></style>
                        <Tools
                            BounsTime={0}
                            BounsTry={0}
                            falseTry={0}
                            gamesSetting={this.state.CustomStyle.gamesSetting}></Tools>

                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column" style={{
                            zIndex: '15'
                        }}>
                            <div className={`h-100 w-100 position-relative d-flex justify-content-around align-items-center flex-column${AnswerOrder === "top" ? "-reverse" : ""}`} >
                                <div className=" w-100 position-relative d-flex justify-content-around align-items-center" >
                                    <div>
                                        <GameObjects
                                            objectCss={MoveObject}
                                            ObjectSrc={MoveObjectSrc}
                                            ObjectHoverSrc={MoveObjectSrcHover}
                                            customColor={MoveCustomColor}
                                            style={styleAnswer}
                                            text={t("Pages.Games.Answer")}
                                            type="dragdrop-move"
                                        />
                                    </div>
                                    <div className="position-relative" style={{
                                        height: `${heightDrop}px`,
                                        width: `${widthDrop}px`
                                    }}>
                                        <div className={`position-absolute`}
                                            ref={this.Drop}
                                            style={{
                                                margin: OffsetTopObject + "px 0px",
                                                zIndex: '15',
                                                top: AnswerOrder !== "top" ? '0' : 'unset',
                                                bottom: AnswerOrder === "top" ? '0' : 'unset',
                                                touchAction: 'none',
                                                animation: `dropObjectMovment linear forwards ${TranslateTime}s normal infinite`
                                            }}>
                                            <GameObjects
                                                objectCss={DropObject}
                                                ObjectSrc={DropObjectSrc}
                                                ObjectHoverSrc={DropObjectSrcHover}
                                                customColor={DropCustomColor}
                                                style={{}}
                                                text=""
                                                type="dragdrop-static"
                                            />
                                        </div>

                                        <div
                                            ref={this.Rocket}
                                            className={`position-absolute objectMovment `}
                                            id={`objectMovment${this.state.objectMovmentId}`}
                                            style={{
                                                zIndex: 15,
                                                left: `calc(50% - ${width}px)`,
                                                ...style
                                            }} >
                                            <div className={`position-relative d-flex align-items-center flex-column `} style={{
                                                height: `100%`,

                                                // top: `${AnswerOrder === "top" ? OffsetTop + "px" : "-" + OffsetTop + "px"}`,
                                                transformOrigin: `left ${AnswerOrder === "top" ? "bottom" : "top"} `,
                                                transform: this.props.isGo ? `rotate(${this.state.rotate}deg)` : "",
                                            }}>
                                                {
                                                    AnswerOrder === "top" ?
                                                        <>
                                                            <div ref={this.Rocket} style={{
                                                                transform: `rotate(${this.state.rotate}deg)`,
                                                                transformOrigin: `${AnswerOrder === "top" ? "bottom" : "top"}  `,
                                                            }}>
                                                                <img src={ShootObjectSrc} alt="" style={{
                                                                    transform: FlipY === "true" ? "scaleY(-1)" : ""
                                                                }} />
                                                            </div>
                                                            <div style={{
                                                                width: "3px",
                                                                background: `${RopeColor}`,
                                                                borderRadius: '10px',
                                                                height: `100%`,
                                                            }}></div>
                                                        </>
                                                        :
                                                        <>
                                                            <div style={{
                                                                width: "3px",
                                                                background: `${RopeColor}`,
                                                                borderRadius: '10px',
                                                                height: `100%`,
                                                            }}></div>
                                                            <div ref={this.Rocket} style={{
                                                                transform: `rotate(${this.state.rotate}deg)`,
                                                                transformOrigin: `${AnswerOrder === "top" ? "bottom" : "top"}  `,
                                                            }}>
                                                                <img src={ShootObjectSrc} alt="" style={{
                                                                    transform: FlipY === "true" ? "scaleY(-1)" : ""
                                                                }} />
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={` d-flex justify-content-center align-items-center flex-wrap mx-3 m${align}-auto my-${AnswersColsMargin}`} style={styleBox}>
                                    <div dangerouslySetInnerHTML={{
                                        __html: text
                                    }}></div>
                                </div>
                            </div>
                        </div>
                        <Background absolute={true} CustomBackground={CustomBackground} />
                        <MoveBackgroundObject CustomStyle={this.props.CustomStyle} Id={this.props.Id} />
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(PullView));
import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Field, reduxForm } from 'redux-form';

import DragDrop from './DragDrop';
import api from '../../../../api/api';
import ScreenSetting from '../../Question/Forms/Designer/ScreenSetting';
import MoveBackgroundObject from '../../Question/View/GamePreview/MoveBackground';

class Items extends React.Component {

    state = {
        items: [], show: false, EditItem: null, Count: 0, Text: "", CustomStyle: {}, showEditStyle: false
    }

    constructor(props) {
        super(props);
        this._editor = new React.createRef();
        this.audio = new Audio();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.items) {
            let items = [];
            let mainItem = props.items;
            mainItem.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                items.push({
                    ...item,
                    Id: item.Id,
                    Name: item.Name,
                    CustomStyle: item.CustomStyle,
                    Sort: item.Sort,
                    Count: item.Count,
                    Position: item.Position,
                });
            });
            state.items = items;
        }
        return state;
    }

    saveSort = () => {
        const { t, toast } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const sortedId = [];
        this.state.items.forEach((item, index) => {
            sortedId.push({
                id: item.Id,
                sort: index
            });
        });

        var postData = {
            sortedIds: sortedId
        };

        api.post(`${this.props.API}` + this.props.LessonId,
            postData
        ).then(response => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('ControlPanel.Indices.Sort.Title')}</div> <div className="h6">{t('ControlPanel.Indices.Sort.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    setSort = value => {
        this.setState({
            items: value
        });
        this.props.setSort(value, this.props.Position);
    }
    AddItem = () => {
        const { t, toast } = this.props;

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var items = [];

        this.props.items.sort((a, b) => a.Sort - b.Sort).forEach(item => {
            items.push({
                Id: item.Id,
                Name: item.Name,
                Sort: item.Sort,
                Count: item.Count,
                Position: item.Position
            });
        });

        var html = this.state.Text;

        var postData = {
            Name: this.state.Text,
            Count: this.state.Count,
            Sort: items.length,
            Position: this.props.Position,
            LessonId: this.props.LessonId
        };

        if (!html || this.state.Count <= 0) {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Error.PageInfo')}</div> </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        api.post(
            `${this.props.API}`,
            postData
        ).then(response => {
            if (response.data.data.Id !== 0) {
                toast.update(toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });

                this.props.updateLessonPage([
                    ...items,
                    {
                        Id: response.data.data.Id,
                        Name: html,
                        Sort: items.length,
                        Count: this.state.Count,
                        Position: this.props.Position,
                    }
                ], this.props.Position);

                this.setState({
                    Text: '',
                    Count: 0,
                    items: [
                        ...items,
                        {
                            Id: response.data.data.Id,
                            Name: html,
                            Count: this.state.Count,
                            Sort: items.length,
                            Position: this.props.Position,
                        }]
                });
            } else {
                toast.update(toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.error.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.error.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    EditItem = item => {
        var CustomStyle = item.CustomStyle;
        let showEditStyle = false;
        if (item.CustomStyle && item.CustomStyle.GlobalStyle) {
            showEditStyle = true;
        }
        this.setState({
            EditItem: item,
            Text: item.Name,
            CustomStyle,
            Count: item.Count,
            showEditStyle,
            show: true
        });
    }
    DeleteItem = value => {
        this.props.showDelete(value);
    }
    handleClose = () => {
        this.setState({
            Text: '',
            Count: 0,
            show: false
        });
    }
    handleSubmitQuestionEditItems = () => {
        const { t, toast } = this.props;

        var html = this.state.Text;

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (!html || this.state.Count <= 0) {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Error.PageInfo')}</div> </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        var items = [];
        this.props.items.sort((a, b) => a.Sort - b.Sort).forEach(item => {
            if (item.Id === this.state.EditItem.Id) {
                items.push({
                    Id: item.Id,
                    Name: html,
                    Sort: item.Sort,
                    Count: this.state.Count,
                    Position: item.Position,
                    CustomStyle: this.state.CustomStyle
                });
            } else {
                items.push({
                    Id: item.Id,
                    Name: item.Name,
                    Sort: item.Sort,
                    Count: item.Count,
                    Position: item.Position,
                    CustomStyle: item.CustomStyle
                });
            }
        });

        var postData = {
            CustomStyle: { ...this.state.CustomStyle },
            Name: this.state.Text,
            Count: this.state.Count,
            Sort: items.length,
            Position: this.props.Position,
            LessonId: this.props.LessonId
        };

        api.put(
            `${this.props.API}` + this.state.EditItem.Id,
            postData
        ).then(response => {

            this.props.updateLessonPage([...items], this.props.Position);
            this.setState({
                show: false,
                Text: '',
                Count: 0,
                items: [...items]
            });

            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    renderField = ({
        input,
        title,
        type,
        defaultValue,
        id,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} id={id} value={defaultValue} title={title} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
    validateQuestionCount = value => {
        const { t } = this.props;
        if (!value || value <= 0) {
            return t('Pages.Lessons.PageCount.placeholder');
        }
    }
    setCountValue = Count => {
        if (Count > 0 && parseInt(Count) > 0) {
            this.setState({
                Count: parseInt(Count)
            });
        }
    }
    validateQuestionText = value => {
        const { t } = this.props;
        if (!value) {
            return t('Pages.Lessons.PageText.placeholder');
        }
    }
    setTextValue = Text => {
        this.setState({
            Text
        });
    }
    UpdateCustomStyle = NewCustomStyle => {
        this.setState({
            CustomStyle: {
                ...NewCustomStyle,
            }
        });
    }
    showEditStyle = () => {
        this.setState({
            showEditStyle: true,
            CustomStyle: this.props.lesson.CustomStyle.DashBoard
        });
    }

    render() {
        const { t } = this.props;
        const Random = Math.floor(Math.random() * 1000);
        const RandomEdit = Math.floor(Math.random() * 1000);

        let style = {};
        let styleBackground = {};
        let align = "x";
        let ControlColor = "custom";
        let MainColor = "";
        let SecondColor = "";
        let FontColor = "";
        let Cursor = "";
        let GlobalStyleCustom = "";

        let LabelStyleCustom = "";
        let styleLabelStyle = {};
        let styleBackgroundLabelStyle = {};
        let alignLabelStyle = "x";
        let FontColorLabelStyle = "";
        let MarginBottomLabelStyle = 0;

        let IconType = "fas";
        let Icon = "caret";
        let IconColor = "";
        let IconCustomColor = "";
        let IconHoverColor = "";
        let IconPosition = "in";
        let Background = "";

        if (this.state.CustomStyle) {
            if (this.state.CustomStyle.GlobalStyle) {
                style = {
                    minHeight: `${this.state.CustomStyle.GlobalStyle.height}px`,
                    width: `${this.state.CustomStyle.GlobalStyle.width}%`,
                    maxWidth: '100%',
                    paddingLeft: `${this.state.CustomStyle.GlobalStyle.PaddingLeft}px`,
                    paddingTop: `${this.state.CustomStyle.GlobalStyle.PaddingTop}px`,
                    paddingRight: `${this.state.CustomStyle.GlobalStyle.PaddingRight}px`,
                    paddingBottom: `${this.state.CustomStyle.GlobalStyle.PaddingBottom}px`,
                    borderColor: this.state.CustomStyle.GlobalStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.GlobalStyle.BorderColor : "",
                    borderStyle: `${this.state.CustomStyle.GlobalStyle.BorderStyle}`,
                    borderLeftWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthRight}px`,
                    borderTopWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthBottom}px`,
                    borderRadius: `${this.state.CustomStyle.GlobalStyle.BorderRadiusTop}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.GlobalStyle.BorderRadiusRight}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.GlobalStyle.BorderRadiusBottom}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.GlobalStyle.BorderRadiusLeft}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit}`,
                    boxShadow: `${this.state.CustomStyle.GlobalStyle.ShadowPositionX}px ${this.state.CustomStyle.GlobalStyle.ShadowPositionY}px ${this.state.CustomStyle.GlobalStyle.ShadowRadius}px 0px ${this.state.CustomStyle.GlobalStyle.shadowColor}`,
                };
                if (this.state.CustomStyle.GlobalStyle.AnswersAlign) {
                    if (this.state.CustomStyle.GlobalStyle.AnswersAlign === 'left') {
                        align = "l";
                    } else if (this.state.CustomStyle.GlobalStyle.AnswersAlign === 'right') {
                        align = "r";
                    }
                }
                if (this.state.CustomStyle.GlobalStyle.ControlColor) {
                    ControlColor = this.state.CustomStyle.GlobalStyle.ControlColor;
                }
                if (this.state.CustomStyle.GlobalStyle.ControlColor === "custom") {
                    MainColor = this.state.CustomStyle.GlobalStyle.MainColor;
                    FontColor = this.state.CustomStyle.GlobalStyle.FontColor;
                    SecondColor = this.state.CustomStyle.GlobalStyle.SecondColor;
                }
                styleBackground = {
                    height: `100%`,
                    opacity: `${this.state.CustomStyle.GlobalStyle.Opacity}%`,
                    backgroundColor: this.state.CustomStyle.GlobalStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.GlobalStyle.backgroundColor : "",
                    backgroundImage: this.state.CustomStyle.GlobalStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.GlobalStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${this.state.CustomStyle.GlobalStyle.BgPositionX}%`,
                    backgroundPositionY: `${this.state.CustomStyle.GlobalStyle.BgPositionY}%`,
                    backgroundSize: `${this.state.CustomStyle.GlobalStyle.BgSize}`,
                    backgroundRepeat: `${this.state.CustomStyle.GlobalStyle.BgRepeat}`,
                    backgroundAttachment: `${this.state.CustomStyle.GlobalStyle.BgAttachment}`,
                    marginLeft: `-${this.state.CustomStyle.GlobalStyle.PaddingLeft}px`,
                    marginTop: `-${this.state.CustomStyle.GlobalStyle.PaddingTop}px`,
                    marginRight: `-${this.state.CustomStyle.GlobalStyle.PaddingRight}px`,
                    marginBottom: `-${this.state.CustomStyle.GlobalStyle.PaddingBottom}px`,
                };

                if (this.state.CustomStyle.GlobalStyle.Cursor !== 'custom' && this.state.CustomStyle.GlobalStyle.Cursor !== 'none' && this.state.CustomStyle.GlobalStyle.Cursor) {
                    Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.GlobalStyle.Cursor;
                } else if (this.state.CustomStyle.GlobalStyle.Cursor === 'custom' && this.state.CustomStyle.GlobalStyle.CursorCustom) {
                    Cursor = this.state.CustomStyle.GlobalStyle.CursorCustom;
                }

                if (this.state.CustomStyle.GlobalStyle.IconPosition) {
                    IconPosition = this.state.CustomStyle.GlobalStyle.IconPosition;
                }
                if (this.state.CustomStyle.GlobalStyle.Icon) {
                    Icon = this.state.CustomStyle.GlobalStyle.Icon;
                }
                if (this.state.CustomStyle.GlobalStyle.IconType) {
                    IconType = this.state.CustomStyle.GlobalStyle.IconType;
                }

                if (this.state.CustomStyle.GlobalStyle.IconHoverColor) {
                    IconHoverColor = this.state.CustomStyle.GlobalStyle.IconHoverColor;
                }
                if (this.state.CustomStyle.GlobalStyle.IconColor === "custom" && this.state.CustomStyle.GlobalStyle.IconCustomColor) {
                    IconCustomColor = this.state.CustomStyle.GlobalStyle.IconCustomColor;
                } else if (this.state.CustomStyle.GlobalStyle.IconColor) {
                    IconColor = this.state.CustomStyle.GlobalStyle.IconColor;
                }
                GlobalStyleCustom = this.state.CustomStyle.GlobalStyle.Custom;
                Background = this.state.CustomStyle.GlobalStyle.CustomBackground;
            }
            if (this.state.CustomStyle.LabelStyle) {
                styleLabelStyle = {
                    minHeight: `${this.state.CustomStyle.LabelStyle.height}px`,
                    width: `${this.state.CustomStyle.LabelStyle.width}%`,
                    maxWidth: '100%',
                    color: this.state.CustomStyle.LabelStyle.FontColor,
                    paddingLeft: `${this.state.CustomStyle.LabelStyle.PaddingLeft}px`,
                    paddingTop: `${this.state.CustomStyle.LabelStyle.PaddingTop}px`,
                    paddingRight: `${this.state.CustomStyle.LabelStyle.PaddingRight}px`,
                    paddingBottom: `${this.state.CustomStyle.LabelStyle.PaddingBottom}px`,
                    borderColor: this.state.CustomStyle.LabelStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.LabelStyle.BorderColor : "",
                    borderStyle: `${this.state.CustomStyle.LabelStyle.BorderStyle}`,
                    borderLeftWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthRight}px`,
                    borderTopWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthBottom}px`,
                    borderRadius: `${this.state.CustomStyle.LabelStyle.BorderRadiusTop}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.LabelStyle.BorderRadiusRight}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.LabelStyle.BorderRadiusBottom}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.LabelStyle.BorderRadiusLeft}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit}`,
                    boxShadow: `${this.state.CustomStyle.LabelStyle.ShadowPositionX}px ${this.state.CustomStyle.LabelStyle.ShadowPositionY}px ${this.state.CustomStyle.LabelStyle.ShadowRadius}px 0px ${this.state.CustomStyle.LabelStyle.shadowColor}`,
                    marginBottom: `${this.state.CustomStyle.LabelStyle.Margin}px`,
                    zIndex: 9
                };
                if (this.state.CustomStyle.LabelStyle.AnswersAlign) {
                    if (this.state.CustomStyle.LabelStyle.AnswersAlign === 'left') {
                        alignLabelStyle = "l";
                    } else if (this.state.CustomStyle.LabelStyle.AnswersAlign === 'right') {
                        alignLabelStyle = "r";
                    }
                }
                FontColorLabelStyle = this.state.CustomStyle.LabelStyle.FontColor;
                MarginBottomLabelStyle = this.state.CustomStyle.LabelStyle.MarginBottom;
                styleBackgroundLabelStyle = {
                    height: `100%`,
                    opacity: `${this.state.CustomStyle.LabelStyle.Opacity}%`,
                    backgroundColor: this.state.CustomStyle.LabelStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.LabelStyle.backgroundColor : "",
                    backgroundImage: this.state.CustomStyle.LabelStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.LabelStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${this.state.CustomStyle.LabelStyle.BgPositionX}%`,
                    backgroundPositionY: `${this.state.CustomStyle.LabelStyle.BgPositionY}%`,
                    backgroundSize: `${this.state.CustomStyle.LabelStyle.BgSize}`,
                    backgroundRepeat: `${this.state.CustomStyle.LabelStyle.BgRepeat}`,
                    backgroundAttachment: `${this.state.CustomStyle.LabelStyle.BgAttachment}`,
                    marginLeft: `-${this.state.CustomStyle.LabelStyle.PaddingLeft}px`,
                    marginTop: `-${this.state.CustomStyle.LabelStyle.PaddingTop}px`,
                    marginRight: `-${this.state.CustomStyle.LabelStyle.PaddingRight}px`,
                    marginBottom: `-${this.state.CustomStyle.LabelStyle.PaddingBottom}px`,
                    zIndex: -1
                };
                LabelStyleCustom = this.state.CustomStyle.LabelStyle.Custom;
            }
        }

        return (
            <>
                <Modal
                    size="xl"
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    enforceFocus={false}
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title> <div className="h5 mb-0"> <i className="icon fal fa-edit"></i> {t('Pages.Lessons.EditPage')}</div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="contact-form">
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor={`Text${RandomEdit}`}>
                                    <i className="icon fas fa-text"></i> {t('Pages.Lessons.PageText.title')}
                                </label>
                                <Field
                                    ref={Text => this.Text = Text}
                                    id={`Text${RandomEdit}`}
                                    name="Text"
                                    component={this.renderField}
                                    defaultValue={this.state.Text}
                                    type="text"
                                    required={true}
                                    onChange={e => this.setTextValue(e.target.value)}
                                    validate={this.validateQuestionText}
                                    placeholder={t('Pages.Lessons.PageText.placeholder')}
                                    title={t('Pages.Lessons.PageText.title')}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor={`Count${RandomEdit}`}>
                                    <i className="icon fas fa-tally "></i> {t('Pages.Lessons.PageCount.title')}
                                </label>
                                <Field
                                    ref={Count => this.Count = Count}
                                    id={`Count${RandomEdit}`}
                                    name="Count"
                                    component={this.renderField}
                                    defaultValue={this.state.Count}
                                    type="number"
                                    required={true}
                                    onChange={e => this.setCountValue(e.target.value)}
                                    validate={this.validateQuestionCount}
                                    placeholder={t('Pages.Lessons.PageCount.placeholder')}
                                    title={t('Pages.Lessons.PageCount.title')}
                                    className="form-control"
                                />
                            </div>
                            {
                                this.state.showEditStyle ? "" :
                                    <div className="form-group w-100 text-center">
                                        <button className="custom-button" title={t('EditStyle')} type="button" onClick={() => this.showEditStyle()}>
                                            <span>
                                                <i className="icon far fa-edit"></i>
                                                {t('EditStyle')}
                                            </span>
                                        </button>
                                    </div>
                            }
                        </div>

                        {
                            this.props.isGame && this.state.showEditStyle ?
                                <div className='w-100 mt-3'>
                                    <ScreenSetting isStop={true} isDashBoard={true} CustomStyle={this.state.CustomStyle} update={this.UpdateCustomStyle} />

                                    <div className="w-100 position-relative oh game-font" style={{
                                        backgroundImage: `url("${Background}")`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "100%",
                                        minHeight: '600px',
                                        backgroundPosition: 'center',
                                        border: '10px solid #fff',
                                        cursor: `url('${Cursor}') 20 20, auto`
                                    }}>

                                        <MoveBackgroundObject CustomStyle={this.state.CustomStyle.GlobalStyle} Id={this.state.Id} />
                                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column" style={{
                                            zIndex: '15'
                                        }}>
                                            <div className="row d-flex flex-wrap justify-content-center">
                                                <div className={`m${alignLabelStyle}-auto position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={styleLabelStyle}>
                                                    <div className="w-100 position-absolute" style={styleBackgroundLabelStyle} id={`SelectDesignerPreviewLabelStyle_${this.state.Id}`}> </div>
                                                    <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyle_${this.state.Id}{${LabelStyleCustom} }` }}></style>
                                                    <div className="h3" style={{
                                                        color: `${FontColorLabelStyle}`,
                                                        marginBottom: `${MarginBottomLabelStyle}px`
                                                    }}>
                                                        {t('Pages.Games.LevelSelection')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" w-100 position-relative d-flex justify-content-around align-items-center" >

                                                {
                                                    IconPosition === "out" ?
                                                        <div className="row d-flex w-100 h-100 align-items-center position-absolute justify-content-between" >
                                                            <div className="mx-2">
                                                                <div className=" h1 custom-pointer" style={{
                                                                    fontSize: '6rem',
                                                                    zIndex: 9999
                                                                }}
                                                                    title={t('Pages.Games.prev')}>
                                                                    <span style={{
                                                                        color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                    }}>
                                                                        <i className={`text-${IconColor} ${IconType} fa-${Icon}-right custom-hover`}></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="mx-2">
                                                                <div className=" h1 custom-pointer" style={{
                                                                    fontSize: '6rem',
                                                                    zIndex: 9999
                                                                }}
                                                                    title={t('Pages.Games.next')}>
                                                                    <span style={{
                                                                        color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                    }}>
                                                                        <i className={`text-${IconColor} ${IconType} fa-${Icon}-left custom-hover`}></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                                <div className={`m${align}-auto position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={style}>
                                                    <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${this.state.Id}`}> </div>
                                                    <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${this.state.Id}{${GlobalStyleCustom} }.level-button::before{background: ${MainColor};}.level-button::after{background: ${SecondColor};}.level-button span{color:${FontColor}}.custom-hover:hover{color:${IconHoverColor} !important;}` }}></style>

                                                    <div className={`${IconPosition === "in" ? "d-flex w-100 justify-content-between" : ""}`}>

                                                        <div className={`mx-2 ${IconPosition === "in" ? "" : "d-none"}`} style={{
                                                            zIndex: 9999
                                                        }}>
                                                            <div className=" h1 custom-pointer" style={{
                                                                fontSize: '6rem'
                                                            }}
                                                                title={t('Pages.Games.prev')}>
                                                                <span style={{
                                                                    color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                }}>
                                                                    <i className={`text-${IconColor} ${IconType} fa-${Icon}-right custom-hover`}></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="row d-flex flex-wrap" style={{
                                                            justifyContent: 'space-evenly'
                                                        }}>
                                                            <div className="m-3">
                                                                <div className={`custom-button ${ControlColor}-button level-button `}>
                                                                    <span>1</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={`mx-2 ${IconPosition === "in" ? "" : "d-none"}`} style={{
                                                            zIndex: 9999
                                                        }}>
                                                            <div className=" h1 custom-pointer" style={{
                                                                fontSize: '6rem'
                                                            }}
                                                                title={t('Pages.Games.next')}>
                                                                <span style={{
                                                                    color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                }}>
                                                                    <i className={`text-${IconColor} ${IconType} fa-${Icon}-left custom-hover`}></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="w-100 row">
                            <div className="col-6">
                                <button className="btn btn-success" onClick={this.handleSubmitQuestionEditItems} >
                                    <i className="icon fal fa-edit"></i>
                                    {t('Pages.Lessons.Questions.forms.Edit')}
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-danger" onClick={this.handleClose} >
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                <form method="POST" className="contact-form">
                    <div className="title h5 mt-2 mb-3 w-100">
                        {
                            this.props.Position === false ?
                                <>
                                    <i className="icon text-primary fad fa-arrow-alt-to-right"></i>
                                    {t('Pages.Lessons.pageRight')}
                                </> :
                                <>
                                    <i className="icon text-danger fad fa-arrow-alt-to-left"></i>
                                    {t('Pages.Lessons.pageLeft')}
                                </>
                        }
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor={`Text${Random}`}>
                            <i className="icon fas fa-text"></i> {t('Pages.Lessons.PageText.title')}
                        </label>
                        <Field
                            ref={Text => this.Text = Text}
                            id={`Text${Random}`}
                            name="Text"
                            component={this.renderField}
                            defaultValue={this.state.Text}
                            type="text"
                            required={true}
                            onChange={e => this.setTextValue(e.target.value)}
                            validate={this.validateQuestionText}
                            placeholder={t('Pages.Lessons.PageText.placeholder')}
                            title={t('Pages.Lessons.PageText.title')}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor={`Count${Random}`}>
                            <i className="icon fas fa-tally "></i> {t('Pages.Lessons.PageCount.title')}
                        </label>
                        <Field
                            ref={Count => this.Count = Count}
                            id={`Count${Random}`}
                            name="Count"
                            component={this.renderField}
                            defaultValue={this.state.Count}
                            type="number"
                            required={true}
                            onChange={e => this.setCountValue(e.target.value)}
                            validate={this.validateQuestionCount}
                            placeholder={t('Pages.Lessons.PageCount.placeholder')}
                            title={t('Pages.Lessons.PageCount.title')}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group w-100 text-center">
                        <button className="custom-button" title={t('Save')} type="button" onClick={() => this.AddItem()}>
                            <span>
                                <i className="icon far fa-save"></i>
                                {t('Save')}
                            </span>
                        </button>
                    </div>

                    <div className="title h5 mt-2 mb-3 w-100"> <i className="icon fal fa-sort-amount-down"></i> {t('Pages.Lessons.reOrder')} </div>
                    <div className="form-group w-100 position-relative">
                        <DragDrop
                            items={this.state.items}
                            setSort={this.setSort}
                            DeleteItem={this.DeleteItem}
                            EditItem={this.EditItem}>
                        </DragDrop>
                    </div>
                    <div className="form-group w-100 text-center">
                        <button className="custom-button" title={t('Pages.Lessons.Questions.forms.tools.saveSort')} type="button" onClick={() => this.saveSort()}>
                            <span>
                                <i className="icon far fa-save"></i>
                                {t('Pages.Lessons.Questions.forms.tools.saveSort')}
                            </span>
                        </button>
                    </div>
                </form>
            </>
        );
    }
}

export default withTranslation('common')(reduxForm({
    form: 'Items'
})(Items));

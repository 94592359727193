import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollArea from "react-scrollbar";

import Footer from "../Footer/FooterBottom";
import LastNews from './LastNews';
import { fetchIndices } from '../Admin/Indices/actions/';
import { getIndeices, setIndeices, setObject, getObject } from "../../context/sessionStorage";
import {
    setLessonFavorite as setFavorite
} from './actions/';
import CarouselComponent from './Carousel/';
import "../scss/book.scss";
import RankList from "./HomeSlider/RankList";
import api from "../../api/api";
import BookList from "./HomeSlider/BookList";
import Sky from "../Admin/Question/View/Background/Sky";

class HomeSlider extends React.Component {

    state = {
        indices: [], positionIndex: 0,
        lessons: [], lessonsIsLoaded: false,
        Quizzes: [], QuizzesIsLoaded: false,
        Exams: [], ExamsIsLoaded: false,
        Files: [], FilesIsLoaded: false,
        current: 0, scrolled: 0,
        pageHeight: 0, showMenu: false
    };

    constructor(props) {
        super(props);
        this.pathList = [];
        this.bookList = [];
        this.footerBox = React.createRef();
        this.current = -1;
        this.countScrollDown = 0;
        window.addEventListener('scroll', this.handleScroll, { passive: true });
        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }

        let Lessons = getObject("LastLessons");
        if (Lessons === null || Lessons.length === 0) {
            this.getLastNews("Lessons");
        } else {
            this.setState({
                lessonsIsLoaded: true,
                lessons: Lessons
            });
        }

        let Exams = getObject("LastExams");
        if (Exams === null || Exams.length === 0) {
            this.getLastNews("Exams");
        } else {
            this.setState({
                ExamsIsLoaded: true,
                Exams: Exams
            });
        }

        let Quizzes = getObject("LastQuizzes");
        if (Quizzes === null || Quizzes.length === 0) {
            this.getLastNews("Quizzes");
        } else {
            this.setState({
                QuizzesIsLoaded: true,
                Quizzes: Quizzes
            });
        }

        let Files = getObject("LastFiles");
        if (Files === null || Files.length === 0) {
            this.getLastNews("Files");
        } else {
            this.setState({
                FilesIsLoaded: true,
                Files: Files
            });
        }

        window.scrollToPosition("home-page-slider");
        this.handleResize();
        this.setState({
            indices: indices
        });

        setTimeout(() => {
            this.handleResize();
        }, 300);
    }

    handleResize = () => {
        const header = document.getElementsByTagName('header')[0];
        let headerHeight = 0;
        if (header) {
            headerHeight = header.offsetHeight;
        }
        let footerHeight = 0;
        if (this.footerBox && this.footerBox.current) {
            footerHeight = this.footerBox.current.offsetHeight;
        }

        this.setState({
            pageHeight: window.innerHeight - (footerHeight + headerHeight) - 2
        });

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScroll = () => {
        const scrolled = window.scrollY;
        var factor = 1;
        if (this.state.scrolled > scrolled) {
            factor = -1;
        }
        this.setState({
            scrolled
        });
        this.changeSlider(factor);

    }

    changeSlider = factor => {
        if (this.state.current + factor >= 0 && this.state.current + factor <= 3 && this.current === this.state.current) {
            this.setState({
                current: this.state.current + factor
            });
        }
        setTimeout(() => {
            this.current = this.state.current;
        }, 500);
        this.countScrollDown = 0;
    }

    handleInnerScroll = e => {
        if (e.containerHeight !== e.realHeight) {
            if (e.containerHeight + e.topPosition === e.realHeight) {
                if (this.countScrollDown > 3) {
                    this.changeSlider(1);
                }
                this.countScrollDown += 1;
            } else if (e.topPosition === 0) {
                this.changeSlider(-1);
            }
        } else {
            this.handleScroll();
        }
    }

    getLastNews = type => {
        api.post(`/LastLessons/?type=${type}`).then(response => {
            if (type === "Lessons") {
                this.setState({
                    lessons: response.data.data,
                    lessonsIsLoaded: true
                });
            } else if (type === "Exams") {
                this.setState({
                    Exams: response.data.data,
                    ExamsIsLoaded: true
                });
            } else if (type === "Quizzes") {
                this.setState({
                    Quizzes: response.data.data,
                    QuizzesIsLoaded: true
                });
            } else if (type === "Files") {
                this.setState({
                    Files: response.data.data,
                    FilesIsLoaded: true
                });
            }
            setObject("Last" + type, response.data.data);
        });
    }

    getNodePath(nodes, indexType) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, indexType);
                if (isOpen) {
                    this.getNodePath(node.nodes, indexType);
                    if (indexType === node.indexType) {
                        this.pathList.push(node);
                    }
                }
            });
        }
    }

    HasNode(node, indexType) {
        if (node) {
            return (indexType === node.indexType) || this.hasSelectedNodeInChildren(node.nodes, indexType);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, indexType) {
        var result = false;
        nodes.forEach(node => {
            result = result || (indexType === node.indexType) || this.hasSelectedNodeInChildren(node.nodes, indexType);
        });
        return result;
    }

    setBookList = (books, index) => {
        books.nodes.forEach(bookClass => {
            bookClass.nodes.forEach(book => {
                this.bookList.push({
                    ...book,
                    index
                });
            });
        });
    }

    showMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        });
    }

    render() {
        const { t, user } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        this.bookList = [];
        this.pathList = [];
        if (indices && indices.nodes) {
            this.getNodePath(indices.nodes, "Books");
        }

        this.pathList.forEach((books, index) => {
            this.setBookList(books, index)
        });

        return (
            <>
                <Helmet>
                    <title>{t("Home")} | {t('alarabeyya')}</title>
                </Helmet>
                <div className="position-relative " id="home-page-slider" >

                    <ScrollArea
                        horizontal={false}
                        className={`area w-100 lazyLoading ${this.state.current === 0 ? "" : "d-none"}`}
                        smoothScrolling={true}
                        contentClassName="content pb-1"
                        onScroll={this.handleInnerScroll}
                        style={{
                            height: `${this.state.pageHeight}px`,
                            background: '#149aec'
                        }} >
                        <section className={`banner-section style-2 oh `} >
                            <div className="top-shape-content">
                                <LazyLoadImage src="/assets/images/banner/banner04.png" alt={`${t('alarabeyya')}`} />
                            </div>
                            <div className="mx-5">
                                <div className="d-flex justify-content-between banner-container">

                                    <div className="banner-content style-2 cl-white w-100">
                                        <h1 className="title"> {t('PageHeader.head')}</h1>
                                        <p>{t('PageHeader.Short')}</p>
                                        <div className="banner-buttons">
                                            {
                                                user && user.isLogin === true ?
                                                    <>
                                                        <Link to="/Profile" className="custom-button mx-1" title={t('Pages.Profile.title')}>
                                                            <span>
                                                                <i className="icon fal fa-user"></i>
                                                                {t('Pages.Profile.title')}
                                                            </span>
                                                        </Link>
                                                        <Link to="/Logout" className="custom-button my-2 mx-1" title={t('Logout')}>
                                                            <span>
                                                                <i className="icon fal fa-power-off"></i>
                                                                {t('Logout')}
                                                            </span>
                                                        </Link>
                                                    </>
                                                    :
                                                    <>
                                                        <Link to="/Register" className="custom-button mx-1">
                                                            <span>
                                                                <i className="icon fal fa-registered"></i>
                                                                {t('Register_Now')}
                                                            </span>
                                                        </Link>
                                                        <Link to="/Login" className="custom-button my-2 mx-1">
                                                            <span>
                                                                <i className="icon fal fa-sign-in-alt"></i>
                                                                {t('Login')}
                                                            </span>
                                                        </Link>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                    <div className="banner-content p-0 oh cl-white mr-auto d-flex align-items-center">
                                        <CarouselComponent></CarouselComponent>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ScrollArea>

                    <ScrollArea
                        horizontal={false}
                        className={`area w-100 lazyLoading ${this.state.current === 1 ? "" : "d-none"}`}
                        smoothScrolling={true}
                        style={{
                            height: `${this.state.pageHeight}px`,
                            background: '#3cc5fa'
                        }}
                        onScroll={this.handleInnerScroll}
                        contentClassName="content">

                        <section className={`pos-rel last-news-section oh pt-3 call-in-action call-overlay bg_img  `} >

                            <Sky />

                            <div className="row mx-4 pb-5 ">
                                <LastNews
                                    hasOffset={true}
                                    background='#fffee8'
                                    typeToFacorite="Lessons"
                                    setFavorite={this.props.setFavorite}
                                    Items={this.state.lessons}
                                    isLoaded={this.state.lessonsIsLoaded}
                                    indices={indices}
                                    showMenu={this.showMenu}
                                    user={user}
                                    title={` <i class="fas fa-layer-group"></i> ` + t("Pages.Home.Last.Lessons")} />

                                <LastNews
                                    background='#f8f4ff'
                                    typeToFacorite="Files"
                                    setFavorite={this.props.setFavorite}
                                    indices={indices}
                                    Items={this.state.Files}
                                    isLoaded={this.state.FilesIsLoaded}
                                    user={user}
                                    showMenu={this.showMenu}
                                    type="Files"
                                    title={` <i class="fas fa-paperclip"></i> ` + t("Pages.Home.Last.Files")} />
                                <LastNews
                                    hasOffset={true}
                                    typeToFacorite="Exams"
                                    type="Exams"
                                    background='#fff5fd'
                                    setFavorite={this.props.setFavorite}
                                    Items={this.state.Exams}
                                    isLoaded={this.state.ExamsIsLoaded}
                                    showMenu={this.showMenu}
                                    user={user}
                                    indices={indices}
                                    title={` <i class="fas fa-pencil-alt"></i> ` + t("Pages.Home.Last.Exams")} />
                                <LastNews
                                    typeToFacorite="Quizzes"
                                    type="Exams"
                                    background='#f2ffeb'
                                    setFavorite={this.props.setFavorite}
                                    Items={this.state.Quizzes}
                                    isLoaded={this.state.QuizzesIsLoaded}
                                    showMenu={this.showMenu}
                                    user={user}
                                    indices={indices}
                                    title={` <i class="fas fa-question"></i> ` + t("Pages.Home.Last.Quizzes")} />

                            </div>

                        </section>
                    </ScrollArea>

                    <ScrollArea
                        horizontal={false}
                        className={`area w-100 lazyLoading ${this.state.current === 2 ? "" : "d-none"}`}
                        smoothScrolling={true}
                        style={{
                            height: `${this.state.pageHeight}px`,
                        }}
                        onScroll={this.handleInnerScroll}
                        contentClassName="content pb-5" >
                        <RankList user={user} />
                    </ScrollArea>

                    <ScrollArea
                        horizontal={false}
                        className={`area w-100 lazyLoading ${this.state.current === 3 ? "" : "d-none"}`}
                        smoothScrolling={true}
                        style={{
                            height: `${this.state.pageHeight}px`,
                        }}
                        onScroll={this.handleInnerScroll}
                        contentClassName="content py-1">
                        <BookList bookList={this.bookList} pathList={this.pathList} />
                    </ScrollArea>

                </div>
                <div className="position-sticky w-100 " style={{
                    bottom: 0,
                    borderTop: "1px solid #f26522"
                }}>
                    {
                        this.state.current < 3 ?
                            <div className="row justify-content-md-center" style={{
                                marginTop: '-52px'
                            }} >
                                <div className="d-flex justify-content-center w-100">
                                    <div onClick={() => this.changeSlider(1)} className="theme-btn btn-style-two btn-style-orange d-inline-flex m-0" style={{
                                        borderRadius: 'unset',
                                        borderTopRightRadius: '10px',
                                        borderTopLeftRadius: '10px'
                                    }}>
                                        <span className="txt pl-2"> {t('LoadMore')} </span>
                                        <div className="mouse-btn-down">
                                            <div className="chevron chevron-orange"></div>
                                            <div className="chevron chevron-orange"></div>
                                            <div className="chevron chevron-orange"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                    <div ref={this.footerBox}><Footer /></div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices,
        user: state.user
    };
};

export default connect(mapStateToProps, { setFavorite, fetchIndices })(withTranslation('common')(HomeSlider));
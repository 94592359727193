import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import QuestionTitle from '../QuestionTitle/';

import pointer from './pointer.png';

class MulitSelectView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        fills: [],
        answers: [],
        categories: [],
        answersList: [],
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        if (props.question.question && props.question.question.Answers) {
            state.answers = props.question.question.Answers;
        } else {
            state.answers = props.answers;
        }
        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        state.categories = props.categories;
        if (props.fills) {
            state.fills = props.fills;
        }

        let upadteAnswerList = [];
        state.answersList.forEach((item) => {
            let True = answerList.filter(v => v.Id === item.Id)[0];
            if (True) {
                upadteAnswerList.push({
                    ...item,
                    ...True
                });
            }
        });
        state.answersList = upadteAnswerList;

        if (props.examAnswers) {
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answersList = hasAnswer.answers;
                if (props.showResult && hasAnswer.answers) {
                    const { t, mark } = props;

                    let userMark = 0;
                    const catMark = mark / state.categories.length;
                    state.categories.forEach(category => {
                        var answers = state.answers.filter(p => state.fills.some(f => f.CategoryId === category.Id && f.AnswerId === p.Id));
                        if (answers) {
                            var userAnswers = state.answersList.filter(p => answers.some(a => a.Id === p.Id));
                            if (userAnswers) {
                                let trueResultCount = 0;
                                let trueAnswer = 0;
                                let falseAnswer = 0;
                                userAnswers.forEach(answer => {
                                    if (answer.True === 1) {
                                        trueResultCount++;
                                        if (answer.isChecked === true) {
                                            trueAnswer++;
                                        }
                                    } else if (answer.isChecked === true) {
                                        falseAnswer++;
                                    }
                                });
                                if (trueResultCount < (falseAnswer + trueAnswer) && trueAnswer >= falseAnswer) {
                                    userMark += catMark * (trueAnswer - falseAnswer) / trueResultCount;
                                } else if (trueAnswer > falseAnswer) {
                                    userMark += catMark * trueAnswer / trueResultCount;
                                } else {
                                    userMark += 0;
                                }
                            }
                        }
                    });

                    if (userMark === mark) {
                        state.mark = {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        };
                    } else if (userMark === 0) {
                        state.mark = {
                            type: 'danger',
                            userMark: 0,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else if (userMark >= (mark / 2)) {
                        state.mark = {
                            type: 'warning',
                            userMark: userMark.toFixed(2),
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.more'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')

                        };
                    } else {
                        state.mark = {
                            type: 'danger',
                            userMark: userMark.toFixed(2),
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    }
                }
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }
        return state;
    }

    renderCategory() {
        if (this.state.categories) {
            let categories = this.state.categories;

            let style = {
                borderColor: this.state.CustomStyle.questionTypeStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionTypeStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.questionTypeStyle.BorderStyle}`,
                borderTopWidth: '0px',
                borderLeftWidth: '0px',
                borderRightWidth: '0px',
                borderBottomWidth: `${this.state.CustomStyle.questionTypeStyle.BorderWidth}px`,
            };

            let AnswersMargin = "";

            return (
                <>
                    <div className="row row-cols-1" style={{
                        height: '100%',
                        position: 'relative'
                    }}>                    {
                            categories.sort((a, b) => a.Sort - b.Sort).map((item, index) => {
                                if (index === 0) {
                                    AnswersMargin = this.state.CustomStyle.questionTypeStyle.AnswersMargin ? `pb-${this.state.CustomStyle.questionTypeStyle.AnswersMargin}` : "pb-1";
                                } else if (categories.length === index + 1) {
                                    style = null;
                                    AnswersMargin = this.state.CustomStyle.questionTypeStyle.AnswersMargin ? `pt-${this.state.CustomStyle.questionTypeStyle.AnswersMargin}` : "pt-1";
                                } else {
                                    AnswersMargin = this.state.CustomStyle.questionTypeStyle.AnswersMargin ? `py-${this.state.CustomStyle.questionTypeStyle.AnswersMargin}` : "py-1";
                                }
                                return (
                                    <div className={`col ${AnswersMargin}`} key={item.Id} style={style}>
                                        <div dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                                        <div className="mt-2">
                                            {this.renderAnswer(item.Id, item.CustomStyle)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            );
        }
        return "";
    }

    renderAnswer(categoryId, CustomStyle) {
        let answers = this.state.answers;
        if (answers && CustomStyle && CustomStyle.Answers) {
            answers = answers.filter(p => this.state.fills.some(f => f.CategoryId === categoryId && f.AnswerId === p.Id));
            const CustomAnswerStyle = CustomStyle.Answers.filter(p => p.Id === categoryId)[0];

            let RandomId = (Math.random() * 10000).toFixed(0);
            if (CustomAnswerStyle && CustomAnswerStyle.AnswerOrder === 'orderd') {
                answers = answers.sort((a, b) => a.Sort - b.Sort);
            }
            if (CustomAnswerStyle) {

                let css = "";
                let cssCenter = "";
                let contanierCss = "custom-control mr-4";
                if (this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'circle' ||
                    this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'rounded') {
                    css = `border border-3 border-${CustomAnswerStyle.AnswerClass} `;
                    if (this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'circle') {
                        css += `rounded-circle`;
                    } else {
                        css += `rounded`;
                    }
                    cssCenter = "text-center";
                    contanierCss = "";
                }

                return (
                    <div className={`row row-cols-md-${CustomAnswerStyle.AnswersCols}`} key={categoryId} style={{
                        height: '100%',
                        position: 'relative'
                    }}>
                        <>
                            {
                                answers.map((item, index) =>
                                (
                                    <div className={`col-md ${cssCenter}`} key={item.Id}>
                                        <div className={`${contanierCss} custom-${CustomAnswerStyle.AnswersSelectType} my-0 checkbox-${CustomAnswerStyle.AnswerClass}`} >
                                            {
                                                this.props.onEdit ?
                                                    <input
                                                        defaultChecked={item.True !== 0 ? true : false}
                                                        type={CustomAnswerStyle.AnswersSelectType}
                                                        onChange={e => this.handleChangeSelect(e.target.checked, item.Id, categoryId)}
                                                        className="custom-control-input"
                                                        name={`checkboxAnswer-${categoryId}-${this.props.Id}`}
                                                        id={`checkboxAnswer-${index}-${categoryId}-${this.props.Id}-${RandomId}`} />
                                                    :
                                                    this.controledCheck(item, categoryId, RandomId, index, CustomAnswerStyle)
                                            }
                                            <label className={`custom-control-label p-0 checkboxAnswer-${this.props.Id} ${((this.props.onEdit && item.True !== 0) || (!this.props.onEdit && this.state.answersList.filter(v => v.Id === item.Id)[0] ? this.state.answersList.filter(v => v.Id === item.Id)[0].isChecked : false)) ? css : ""}`} htmlFor={`checkboxAnswer-${index}-${categoryId}-${this.props.Id}-${RandomId}`} style={{
                                                cursor: `url('${pointer}'),auto`
                                            }}>
                                                <div dangerouslySetInnerHTML={{ __html: item.Name }} ></div>
                                            </label>
                                            {this.renderCheckAnswerIsTrue(item)}
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    </div>
                );
            }
        }
        return "";
    }

    renderCheckAnswerIsTrue(item) {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            const answer = this.state.answersList.filter(v => v.Id === item.Id)[0];
            const isChecked = answer ? answer.isChecked : false;
            if (isChecked && answer.True === 1) {
                return (
                    <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="icon fad fa-check-circle text-success"></i>
                );
            } else if (isChecked) {
                return (
                    <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="icon fas fa-exclamation-triangle text-danger"></i>
                );
            }
        }
        return "";
    }

    controledCheck(item, categoryId, RandomId, index, CustomAnswerStyle) {
        const isChecked = this.state.answersList.filter(v => v.Id === item.Id)[0] ? this.state.answersList.filter(v => v.Id === item.Id)[0].isChecked : false;
        return (
            <>
                <input
                    value={item.Id}
                    checked={isChecked ? isChecked : false}
                    type={CustomAnswerStyle.AnswersSelectType}
                    onChange={e => this.handleChangeAnswerSelect(e.target.checked, item.Id, categoryId)}
                    className="custom-control-input"
                    name={`checkboxAnswer-${categoryId}-${this.props.Id}`}
                    id={`checkboxAnswer-${index}-${categoryId}-${this.props.Id}-${RandomId}`} />
            </>
        );
    }

    handleChangeAnswerSelect = (isChecked, AnswerId, categoryId) => {
        let currentAnswers = this.state.answersList && this.state.answersList.length > 0 ? this.state.answersList : this.state.answers;
        let answerResult = [];

        this.state.categories.forEach(category => {
            let answers = currentAnswers.filter(p => this.state.fills.some(f => f.CategoryId === category.Id && f.AnswerId === p.Id));
            const CustomAnswerStyle = category.CustomStyle.Answers.filter(p => p.Id === category.Id)[0];
            answers.forEach((answer) => {
                if (categoryId === category.Id) {
                    if (CustomAnswerStyle.AnswersSelectType === "radio") {
                        let Checked = false;
                        if (AnswerId === answer.Id) {
                            Checked = true;
                        }
                        answerResult.push({
                            ...answer,
                            isChecked: Checked
                        });
                    } else {
                        if (AnswerId === answer.Id) {
                            answerResult.push({
                                ...answer,
                                isChecked: isChecked ? true : false
                            });
                        } else {
                            answerResult.push({
                                ...answer
                            });
                        }
                    }
                } else {
                    answerResult.push({
                        ...answer
                    });
                }
            });
        });

        let AutoCorrection = false;
        if (this.props.isExamQuestion !== true) {
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
                if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                    AutoCorrection = true;
                    const answer = answerResult.filter(v => v.Id === AnswerId)[0];
                    if (isChecked) {
                        if (answer && answer.True === 1) {
                            this.props.playTrueSound();
                        } else {
                            this.props.playFalseSound();
                        }
                    }
                }
            }
        }

        this.setState({
            answersList: answerResult,
            mark: {
                type: '',
                userMark: 0,
                isSolved: AutoCorrection
            }
        });

        if (this.props.saveExamAnswer) {
            let userMark = 0;
            const catMark = this.props.mark / this.state.categories.length;
            this.state.categories.forEach(category => {
                var answers = this.state.answers.filter(p => this.state.fills.some(f => f.CategoryId === category.Id && f.AnswerId === p.Id));
                if (answers) {
                    var userAnswers = answerResult.filter(p => answers.some(a => a.Id === p.Id));
                    if (userAnswers) {
                        let trueResultCount = 0;
                        let trueAnswer = 0;
                        let falseAnswer = 0;
                        userAnswers.forEach(answer => {
                            if (answer.True === 1) {
                                trueResultCount++;
                                if (answer.isChecked === true) {
                                    trueAnswer++;
                                }
                            } else if (answer.isChecked === true) {
                                falseAnswer++;
                            }
                        });
                        if (trueResultCount < (falseAnswer + trueAnswer) && trueAnswer >= falseAnswer) {
                            userMark += catMark * (trueAnswer - falseAnswer) / trueResultCount;
                        } else if (trueAnswer > falseAnswer) {
                            userMark += catMark * trueAnswer / trueResultCount;
                        } else {
                            userMark += 0;
                        }
                    }
                }
            });

            this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answerResult, {
                questionMark: this.props.mark
            }, userMark);
        }
    }

    handleChangeSelect = (isChecked, AnswerId, categoryId) => {
        let answerResult = [];

        this.state.categories.forEach(category => {
            let answers = this.state.answers.filter(p => this.state.fills.some(f => f.CategoryId === category.Id && f.AnswerId === p.Id));
            const CustomAnswerStyle = category.CustomStyle.Answers.filter(p => p.Id === category.Id)[0];

            answers.forEach((answer) => {
                if (categoryId === category.Id) {
                    if (CustomAnswerStyle.AnswersSelectType === "radio") {
                        let Checked = 0;
                        if (AnswerId === answer.Id) {
                            Checked = 1;
                        }
                        answerResult.push({
                            ...answer,
                            True: Checked
                        });
                    } else {
                        if (AnswerId === answer.Id) {
                            answerResult.push({
                                ...answer,
                                True: isChecked ? 1 : 0
                            });
                        } else {
                            answerResult.push({
                                ...answer
                            });
                        }
                    }
                } else {
                    answerResult.push({
                        ...answer
                    });
                }
            });
        });

        this.setState({
            answers: answerResult,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });


        if (this.props.question && this.props.question.question) {
            var copy = this.props.question;
            copy.question = {
                ...copy.question,
                Answers: answerResult
            }
            this.props.updateQuestion(copy);
        }
    }

    ResetAnswer = () => {
        this.setState({
            answersList: [],
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });

    }

    CheckAnswer = () => {
        const { t, mark, toast } = this.props;

        if (this.state.answersList.length === 0) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-lightbulb-on"></i> {t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return "";
        }

        let userMark = 0;
        const catMark = mark / this.state.categories.length;
        this.state.categories.forEach(category => {
            var answers = this.state.answers.filter(p => this.state.fills.some(f => f.CategoryId === category.Id && f.AnswerId === p.Id));
            if (answers) {
                var userAnswers = this.state.answersList.filter(p => answers.some(a => a.Id === p.Id));
                if (userAnswers) {
                    let trueResultCount = 0;
                    let trueAnswer = 0;
                    let falseAnswer = 0;
                    userAnswers.forEach(answer => {
                        if (answer.True === 1) {
                            trueResultCount++;
                            if (answer.isChecked === true) {
                                trueAnswer++;
                            }
                        } else if (answer.isChecked === true) {
                            falseAnswer++;
                        }
                    });
                    if (trueResultCount < (falseAnswer + trueAnswer) && trueAnswer >= falseAnswer) {
                        userMark += catMark * (trueAnswer - falseAnswer) / trueResultCount;
                    } else if (trueAnswer > falseAnswer) {
                        userMark += catMark * trueAnswer / trueResultCount;
                    } else {
                        userMark += 0;
                    }
                }
            }
        });

        if (userMark === mark) {
            this.props.playTrueSound();
            this.props.showConfetti();
            this.setState({
                mark: {
                    type: 'success',
                    userMark: mark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                    title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                }
            });
        } else if (userMark === 0) {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        } else if (userMark >= (mark / 2)) {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'warning',
                    userMark: userMark.toFixed(2),
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.more'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')

                }
            });
        } else {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'danger',
                    userMark: userMark.toFixed(2),
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        }
    }

    renderMark() {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        }
        return "";
    }

    render() {
        const { title, mark, index, Id, onEdit, isExamQuestion, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'absolute') {
            style = { ...style, height: `${this.state.CustomStyle.questionStyle.height}px` };
        }

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        let AutoCorrection = false;
        if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                AutoCorrection = true;
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`}>
                <div className="bg-white w-100 p-3 form-group position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} style={style} >
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`}> </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            {
                                (this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'circle' ||
                                    this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'rounded') ?
                                    <style dangerouslySetInnerHTML={{ __html: `.checkboxAnswer-${Id}::after,.checkboxAnswer-${Id}::before{display:none}` }}></style>
                                    : ""
                            }
                            {this.renderCategory()}
                        </div>
                    </div>
                    {showResult === true ? this.renderMark() : ""}
                    {isExamQuestion !== true ? AutoCorrection === false ? this.renderControlButton() : "" : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(MulitSelectView));
import React from "react";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';

import Layout from "./layout/Layout";
import { socket } from './api/socket.io';
import { apiBlob, fetchLocations } from './api/api';
import history from './history';
import { notificationIsView, newNotification } from './layout/actions';
import { notificationIsView as notificationIsViewFromSocket } from './api/socket.io';

class App extends React.Component {

    state = { showMessageInfo: false, Title: '', Message: '' };

    constructor(props) {
        super(props);
        this.props.fetchLocations();
    }

    componentDidMount() {
        const { t, notificationIsView, newNotification } = this.props;
        socket.on("SEND_NOTIFICATION", response => {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            if (response.type === 'NEW_EMAIL') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5 p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-bell-on"></i>
                            {response.Title}
                        </div>
                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => this.showMessageInfo(response.Title, response.Message)
                });
            } else if (response.type === 'SHARE_LESSON') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-mind-share"></i>
                            <div className="h5">
                                {t('toast.ShareLesson')}
                                <div className="h5 mt-2">
                                    {response.data.Name}
                                </div>
                            </div>
                        </div>

                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => this.openLesson(response.data, response.IndexType)
                });
                if (response.IndexType === "Exams") {
                    newNotification("EXAMS");
                } else if (response.IndexType === "Quizzes") {
                    newNotification("QUIZZES");
                } else if (response.IndexType === "Lesson") {
                    newNotification("LESSON");
                } else {
                    newNotification("BOOK");
                }
            } else if (response.type === 'SHARE_FILE') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-mind-share"></i>
                            <div className="h5">
                                {t('toast.ShareFile')}
                                <div className="h5 mt-2">
                                    {response.data.Name}
                                </div>
                            </div>
                        </div>

                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => this.openFile(response.data)
                });
                newNotification("FILE");
            } else if (response.type === 'SHARE_MISSION') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-bullseye-arrow"></i>
                            <div className="h5">
                                {t('toast.ShareMission')}
                                <div className="h5 mt-2">
                                    {response.data.Name}
                                </div>
                            </div>
                        </div>
                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => this.openMission(response.data)
                });
                newNotification("MISSION");
            } else if (response.type === 'NOTIFICATION_ISVIEW') {
                toast.dismiss(_toastId);
                notificationIsView(response.notificationType);
            } else if (response.type === 'SEND_CONTACTUS') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-mailbox"></i>
                            <div className="h5">
                                {t('toast.NewContactUs')}
                                <div className="h5 mt-2">
                                    {response.Title}
                                </div>
                            </div>
                        </div>
                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => history.push('/ControlPanel/MailBox')
                });
                newNotification("CONTACTUS");
            } else if (response.type === 'SCHOOL_REGISTER') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-registered"></i>
                            <div className="h5">
                                {t('toast.NewSchool')}
                                <div className="h5 mt-2">
                                    {response.Title}
                                </div>
                            </div>
                        </div>
                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => history.push('/ControlPanel/Schools')
                });
                newNotification("SCHOOL_REGISTER");
            } else if (response.type === 'ERROR_REPORT') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-tools"></i>
                            <div className="h5">
                                {t('toast.NewErrorReport')}
                            </div>
                        </div>
                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => history.push('/ControlPanel/ErrorReport')
                });
                newNotification("ERROR_REPORT");
            } else if (response.type === 'ERROR_REPORT_IS_SOLVE') {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className=" p-2 d-flex align-items-center">
                            <i className="icon h3 my-0 fal fa-badge-check"></i>
                            <div>
                                <span className="h5">
                                    {t('Pages.ErrorReport.toast.Solve.Title')}
                                </span>
                                <p className="mt-1">
                                    {t('Pages.ErrorReport.toast.Solve.Body')}
                                    <br />
                                    {t('Pages.ErrorReport.toast.Solve.sub')}
                                </p>
                            </div>
                        </div>
                    </div>,
                    type: toast.TYPE.DARK,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    onClick: () => window.openLessonView(response.ErrorRepor.LessonId)
                });
            }
        });
    }

    openMission = Mission => {
        window.openMissionView(Mission.Id);
        notificationIsViewFromSocket("MISSION", this.props.user.user.Id);
    }

    openFile = File => {
        const { t, user } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        apiBlob.post(`/EducationalFiles/?token=${File.token}`).then(response => {
            if (response) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";
                const blob = new Blob([response.data]),
                    url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = File.Name + '.' + File.extension;
                a.click();
                window.URL.revokeObjectURL(url);
            }
            toast.dismiss(_toastId);
            notificationIsViewFromSocket("FILE", user.user.Id);

        }).catch(error => {
            toast.dismiss(_toastId);
            history.push("/NoPermissions");
        });

    }

    openLesson = (Lesson, IndexType) => {
        if (IndexType === "Exams" || IndexType === "Quizzes") {
            window.openExamView(Lesson.Id);

            if (IndexType === "Exams") {
                notificationIsViewFromSocket("EXAMS", this.props.user.user.Id);
            } else if (IndexType === "Quizzes") {
                notificationIsViewFromSocket("QUIZZES", this.props.user.user.Id);
            }
        } else if (IndexType === "SelfDevelopment") {
            window.openSelfDevelopmentView(this.state.Id);
            notificationIsViewFromSocket("SELFDEVELOPMENT", this.props.user.user.Id);
        } else {
            window.openLessonView(Lesson.Id);

            if (IndexType === "Lessons") {
                notificationIsViewFromSocket("LESSON", this.props.user.user.Id);
            } else {
                notificationIsViewFromSocket("BOOK", this.props.user.user.Id);
            }
        }
    }

    showMessageInfo = (Title, Message) => {
        if (this.props.user && this.props.user.user) {

            const user = this.props.user.user;

            var currentdate = new Date();
            var datetime = currentdate.getDate() + "/" + (currentdate.getMonth() + 1) + "/" + currentdate.getFullYear();

            Message = Message.replaceAll("(__USER__)", user.Name);
            Message = Message.replaceAll("(__EMAIL__)", user.Email);
            Message = Message.replaceAll("(__SCHOOLNAME__)", user.School.Name);
            Message = Message.replaceAll("(__BIRTHDAY__)", user.Birthday);
            Message = Message.replaceAll("(__ACTIVEDATE__)", user.ActivateUntilDate);
            Message = Message.replaceAll("(__DATE__)", datetime);
            Message = Message.replaceAll("(__USERNAME__)", user.Username);

            this.setState({
                showMessageInfo: true,
                Message,
                Title
            });
        }
    }

    hideMessageInfo = () => {
        this.setState({
            showMessageInfo: false
        });
    }

    renderMessageInfo = () => {
        return <Modal
            size="xl"
            centered
            show={this.state.showMessageInfo}
            onHide={this.hideMessageInfo}
            backdrop="static"
            keyboard={false}
            enforceFocus={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="icon fal fa-bell-on"></i>
                    {this.state.Title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="widget widget-category ">
                    <div className="widget-body">
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: this.state.Message }}>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    }

    render() {
        return (
            <>
                {this.state.showMessageInfo ? this.renderMessageInfo() : ""}
                <Layout socket={socket} />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, { notificationIsView, newNotification, fetchLocations })(withTranslation('common')(App));
const NotificationsReducers = (state = [], action) => {
    switch (action.type) {
        case "NOTIFICATION_LOADING":
            return {
                Notification: [],
                isLoaded: false
            };
        case "NOTIFICATION_INITIAL":
            return {
                Notification: action.Notification,
                isLoaded: true
            };
        case "NOTIFICATION_ISVIEW":
            return {
                Notification: UpdateNotificationsIsView(action.notificationType, state.Notification),
                isLoaded: true
            };
        case "NEW_NOTIFICATION":
            return {
                Notification: NewNotificationsIsView(action.notificationType, state.Notification),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default NotificationsReducers;

function UpdateNotificationsIsView(notificationType, Notification) {
    let copy = Notification;
    if (notificationType === "MISSION") {
        copy.Mission -= 1;
    } else if (notificationType === "EXAMS") {
        copy.Exams -= 1;
    } else if (notificationType === "QUIZZES") {
        copy.Quizzes -= 1;
    } else if (notificationType === "FILE") {
        copy.Files -= 1;
    } else if (notificationType === "LESSON") {
        copy.Lessons -= 1;
    } else if (notificationType === "CONTACTUS") {
        copy.Contact -= 1;
    } else if (notificationType === "SCHOOL_REGISTER") {
        copy.Schools -= 1;
    } else if (notificationType === "ERROR_REPORT") {
        copy.ErrorReport -= 1;
    } else {
        copy.Books -= 1;
    }
    return copy;
}

function NewNotificationsIsView(notificationType, Notification) {
    let copy = Notification;
    if (notificationType === "MISSION") {
        copy.Mission += 1;
    } else if (notificationType === "EXAMS") {
        copy.Exams += 1;
    } else if (notificationType === "QUIZZES") {
        copy.Quizzes += 1;
    } else if (notificationType === "FILE") {
        copy.Files += 1;
    } else if (notificationType === "LESSON") {
        copy.Lessons += 1;
    } else if (notificationType === "CONTACTUS") {
        copy.Contact += 1;
    } else if (notificationType === "SCHOOL_REGISTER") {
        copy.Schools += 1;
    } else if (notificationType === "ERROR_REPORT") {
        copy.ErrorReport += 1;
    } else {
        copy.Books += 1;
    }
    return copy;
}
import React from "react";
import GameObjects from "../../GameObjects";

class Library2 extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {
        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Library/bg2.png?v=1"})`,
            backgroundPositionX: 'right',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>
            <div className="position-absolute user-select-none" style={{
                left: '1%',
                bottom: '1%'
            }}><GameObjects objectCss="BoyWithBooks" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="position-absolute user-select-none" style={{
                right: '1%',
                bottom: '2%'
            }}><GameObjects objectCss="GirlWaveWithBook" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default Library2;
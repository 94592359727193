import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class Balloon extends React.Component {

    constructor(props) {
        super(props);
        this.random = Math.ceil(Math.random() * 500000);
    }

    render() {

        let color = "#531b9bb3";
        let style = "";
        if (this.props.customColor) {
            color = this.props.customColor;
            style = `.Balloon_Balloon_${this.random}{background-color: ${color};}.Balloon_Balloon_${this.random}:after{color: ${color}; }`;
        }

        return <>
            <style dangerouslySetInnerHTML={{ __html: style }}></style>
            <div className="position-relative">
                <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                    <div style={{
                        transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                        transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                    }}>
                        <div style={{
                            height: '150px',
                            zIndex: 1
                        }}>
                            <div className={`Balloon_Balloon mx-auto position-relative Balloon_Balloon_${this.random} mb-2 ${this.props.isSpain ? "fa-spin" : ""}`}></div>
                        </div>
                    </div>
                </div>
                <div style={{
                    zIndex: 99
                }}>
                    <TextBox {...this.props} />
                </div>
            </div>
        </>;
    }
}

export default Balloon;
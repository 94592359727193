import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactHtml from 'raw-html-react';

import DragDropContainer from '../DragDrop/DragDropContainer';
import DropableFiled from '../FillFiled/DropableFiled';
import { updateQuestion } from '../../actions';
import configData from "../../../../Config/CustomStyle.json";
import QuestionTitle from '../QuestionTitle';

class FillMultipuleView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        answers: [],
        answersList: [],
        categories: [],
        QuestionId: 0,
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.answers = props.answers;
        state.categories = props.categories;
        state.QuestionId = props.Id;

        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        let fills = [];
        if (props.fills) {
            props.fills.forEach((node) => {
                const hasAnswer = answerList.filter(p => node.AnswerId === p.Id)[0];
                if (hasAnswer) {
                    const hasCategory = state.categories.filter(p => node.CategoryId === p.Id)[0];
                    if (hasCategory) {
                        fills.push(node);
                    }
                }
            });
        }
        state.fills = fills;

        if (props.onEdit === true && props.Matches) {
            state.Matches = props.Matches;
            state.answersList = props.Matches;
        }

        if (props.examAnswers) {
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answersList = hasAnswer.answers;
                state.Matches = hasAnswer.answers;
                if (props.showResult && hasAnswer.answers) {

                    const { t, mark } = props;
                    let trueAnswer = 0;
                    const trueAnswers = props.Matches.length;
                    const answersList = hasAnswer.answers;

                    props.Matches.forEach(item => {
                        var hasResult = answersList.filter(p => p.Source === item.Source && p.Target === item.Target)[0];
                        if (hasResult) {
                            trueAnswer++;
                        }
                    });

                    if (trueAnswer === 0) {
                        state.mark = {
                            type: 'danger',
                            userMark: 0,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else if (trueAnswers === trueAnswer) {
                        state.mark = {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        };
                    } else {
                        let userMark = (mark * trueAnswer / trueAnswers).toFixed(2);
                        state.mark = {
                            type: userMark >= (mark / 2) ? 'warning' : 'danger',
                            userMark: userMark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    }
                }
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }

        return state;
    }

    ResetAnswer = () => {
        this.setState({
            answersList: [],
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });
    }

    CheckAnswer = () => {
        const { t, mark, toast } = this.props;
        let answersList = this.state.answersList;

        if (answersList.length === 0) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-lightbulb-on"></i> {t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let trueAnswer = 0;
            const trueResultCount = this.props.Matches.length;
            this.props.Matches.forEach(item => {
                var hasResult = answersList.filter(p => p.Source === item.Source && p.Target === item.Target)[0];
                if (hasResult) {
                    trueAnswer++;
                }
            });

            if (trueResultCount === trueAnswer) {
                this.props.playTrueSound();
                this.props.showConfetti();
                this.setState({
                    mark: {
                        type: 'success',
                        userMark: mark,
                        isSolved: true,
                        text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                        title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                    }
                });
            } else if (trueAnswer === 0) {
                this.props.playFalseSound();
                this.setState({
                    mark: {
                        type: 'danger',
                        userMark: 0,
                        isSolved: true,
                        text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                        title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                    }
                });
            } else {
                this.props.playFalseSound();
                this.setState({
                    mark: {
                        type: 'warning',
                        userMark: (mark * trueAnswer / trueResultCount).toFixed(2),
                        isSolved: true,
                        text: t('Pages.Lessons.Questions.forms.Options.error.more'),
                        title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                    }
                });
            }
        }
    }

    renderMark() {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        } else if (showResult) {
            return (
                <>
                    {this.renderMark()}
                </>
            );
        }
        return "";
    }

    renderCategories() {
        if (this.state.categories) {

            let style = {
                maxWidth: '100%',
                minWidth: '100px',
                paddingLeft: `${this.state.CustomStyle.categoryStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.categoryStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.categoryStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.categoryStyle.PaddingBottom}px`,

                borderColor: this.state.CustomStyle.categoryStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.categoryStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.categoryStyle.BorderRadiusTop}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusRight}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.categoryStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryStyle.shadowColor}`,

                opacity: `${this.state.CustomStyle.categoryStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.categoryStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.categoryStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.categoryStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.categoryStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.categoryStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.categoryStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.categoryStyle.BgAttachment}`,
            };

            let styleBackground = {
                opacity: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Opacity : ''}%`,
                backgroundColor: this.state.CustomStyle.categoryListStyle ?
                    this.state.CustomStyle.categoryListStyle.backgroundColor !== 'transparent' ?
                        this.state.CustomStyle.categoryListStyle ?
                            this.state.CustomStyle.categoryListStyle.backgroundColor : '' : "" : '',
                backgroundImage: this.state.CustomStyle.categoryListStyle ?
                    this.state.CustomStyle.categoryListStyle.backgroundImage ?
                        `url('${this.state.CustomStyle.categoryListStyle.backgroundImage}')` : "" : '',
                backgroundPositionX: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgPositionX : ''}%`,
                backgroundPositionY: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgPositionY : ''}%`,
                backgroundSize: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgSize : ''}`,
                backgroundRepeat: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgRepeat : ''}`,
                backgroundAttachment: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgAttachment : ''}`,
                width: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.width : ''}%`,
                maxWidth: '100%',

                borderColor: this.state.CustomStyle.categoryListStyle ?
                    this.state.CustomStyle.categoryListStyle.BorderColor !== 'transparent' ?
                        this.state.CustomStyle.categoryListStyle ?
                            this.state.CustomStyle.categoryListStyle.BorderColor : '' : "" : '',
                borderStyle: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderStyle : ''}`,
                borderLeftWidth: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthLeft : ''}px`,
                borderRightWidth: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthRight : ''}px`,
                borderTopWidth: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthTop : ''}px`,
                borderBottomWidth: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthBottom : ''}px`,
                borderRadius: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusTop : ''}${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusUnit : ''} ${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusRight : ''}${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusUnit : ''} ${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusBottom : ''}${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusUnit : ''} ${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusLeft : ''}${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusUnit : ''}`,
                boxShadow: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowPositionX : ''}px ${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowPositionY : ''}px ${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowRadius : ''}px 0px ${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.shadowColor : ''}`,
            };

            if (this.props.isGame) {
                if (this.state.CustomStyle.categoryListStyle) {
                    style = {
                        maxWidth: '100%',
                        minWidth: '100px',
                        paddingLeft: `${this.state.CustomStyle.categoryListStyle.PaddingLeft}px`,
                        paddingTop: `${this.state.CustomStyle.categoryListStyle.PaddingTop}px`,
                        paddingRight: `${this.state.CustomStyle.categoryListStyle.PaddingRight}px`,
                        paddingBottom: `${this.state.CustomStyle.categoryListStyle.PaddingBottom}px`,

                        borderColor: this.state.CustomStyle.categoryListStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryListStyle.BorderColor : "",
                        borderStyle: `${this.state.CustomStyle.categoryListStyle.BorderStyle}`,
                        borderLeftWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthLeft}px`,
                        borderRightWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthRight}px`,
                        borderTopWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthTop}px`,
                        borderBottomWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthBottom}px`,
                        borderRadius: `${this.state.CustomStyle.categoryListStyle.BorderRadiusTop}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryListStyle.BorderRadiusRight}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryListStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryListStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit}`,
                        boxShadow: `${this.state.CustomStyle.categoryListStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryListStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryListStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryListStyle.shadowColor}`,

                        opacity: `${this.state.CustomStyle.categoryListStyle.Opacity}%`,
                        backgroundColor: this.state.CustomStyle.categoryListStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryListStyle.backgroundColor : "",
                        backgroundImage: this.state.CustomStyle.categoryListStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryListStyle.backgroundImage}')` : "",
                        backgroundPositionX: `${this.state.CustomStyle.categoryListStyle.BgPositionX}%`,
                        backgroundPositionY: `${this.state.CustomStyle.categoryListStyle.BgPositionY}%`,
                        backgroundSize: `${this.state.CustomStyle.categoryListStyle.BgSize}`,
                        backgroundRepeat: `${this.state.CustomStyle.categoryListStyle.BgRepeat}`,
                        backgroundAttachment: `${this.state.CustomStyle.categoryListStyle.BgAttachment}`,
                    };
                }
                if (this.state.CustomStyle.answerBoxStyle) {
                    styleBackground = {
                        minWidth: `${this.state.CustomStyle.answerBoxStyle.width}%`,
                        maxWidth: '100%',
                        minHeight: `${this.state.CustomStyle.answerBoxStyle.height}px`,
                        paddingLeft: `${this.state.CustomStyle.answerBoxStyle.PaddingLeft}px`,
                        paddingTop: `${this.state.CustomStyle.answerBoxStyle.PaddingTop}px`,
                        paddingRight: `${this.state.CustomStyle.answerBoxStyle.PaddingRight}px`,
                        paddingBottom: `${this.state.CustomStyle.answerBoxStyle.PaddingBottom}px`,

                        borderColor: this.state.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.BorderColor : "",
                        borderStyle: `${this.state.CustomStyle.answerBoxStyle.BorderStyle}`,
                        borderLeftWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
                        borderRightWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
                        borderTopWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
                        borderBottomWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
                        borderRadius: `${this.state.CustomStyle.answerBoxStyle.BorderRadiusTop}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusRight}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
                        boxShadow: `${this.state.CustomStyle.answerBoxStyle.ShadowPositionX}px ${this.state.CustomStyle.answerBoxStyle.ShadowPositionY}px ${this.state.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerBoxStyle.shadowColor}`,
                        opacity: `${this.state.CustomStyle.answerBoxStyle.Opacity}%`,
                        backgroundColor: this.state.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.backgroundColor : "",
                        backgroundImage: this.state.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
                        backgroundPositionX: `${this.state.CustomStyle.answerBoxStyle.BgPositionX}%`,
                        backgroundPositionY: `${this.state.CustomStyle.answerBoxStyle.BgPositionY}%`,
                        backgroundSize: `${this.state.CustomStyle.answerBoxStyle.BgSize}`,
                        backgroundRepeat: `${this.state.CustomStyle.answerBoxStyle.BgRepeat}`,
                        backgroundAttachment: `${this.state.CustomStyle.answerBoxStyle.BgAttachment}`,
                    };
                }
            }

            let hoverStyle = {
                maxWidth: '100%',
                minWidth: '100px',
                paddingLeft: `${this.state.CustomStyle.categoryHoverStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.categoryHoverStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.categoryHoverStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.categoryHoverStyle.PaddingBottom}px`,

                borderColor: this.state.CustomStyle.categoryHoverStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryHoverStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.categoryHoverStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.categoryHoverStyle.BorderRadiusTop}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusRight}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.categoryHoverStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryHoverStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryHoverStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryHoverStyle.shadowColor}`,

                opacity: `${this.state.CustomStyle.categoryHoverStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.categoryHoverStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryHoverStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.categoryHoverStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryHoverStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.categoryHoverStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.categoryHoverStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.categoryHoverStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.categoryHoverStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.categoryHoverStyle.BgAttachment}`,
            };

            let padding = {
                paddingLeft: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingLeft : ''}px`,
                paddingTop: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingTop : ''}px`,
                paddingRight: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingRight : ''}px`,
                paddingBottom: `${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingBottom : ''}px`,
            };

            let answerBoxPosstion = 'bottom';
            if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'top' ||
                this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'innerTop' ||
                this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'innerBottom' ||
                this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'bottom') {
                answerBoxPosstion = this.state.CustomStyle.questionTypeStyle.AnswerOrder;
            }

            let align = "x";
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
                if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                    align = "l";
                } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                    align = "r";
                }
            }

            return (
                <div className={`row row-cols-1`} style={{
                    height: '100%',
                    position: 'relative'
                }}>
                    {
                        this.state.categories.sort((a, b) => a.Sort - b.Sort).map(item =>
                        (
                            <div className="col mb-1" key={item.Id}>
                                {answerBoxPosstion === "top" ? this.renderAnswerBox(item.Id) : ""}
                                <div className={`m${align}-auto position-relative overflow-hidden order-2 CategoryListDesignerPreview_${this.props.Id} mb-${this.state.CustomStyle.questionTypeStyle.AnswersMargin}`} style={styleBackground} >
                                    {answerBoxPosstion === "innerTop" ? this.renderAnswerBox(item.Id) : ""}
                                    <div style={padding} >
                                        {this.renderDropableFiled(item, style, hoverStyle)}
                                    </div>
                                    {answerBoxPosstion === "innerBottom" ? this.renderAnswerBox(item.Id) : ""}
                                </div>
                                {answerBoxPosstion === "bottom" ? this.renderAnswerBox(item.Id) : ""}
                            </div>
                        ))
                    }
                </div >
            );
        }
        return "";
    }

    renderDropableFiled(item, style, hoverStyle) {
        const count = item.Name.split("(_)").length - 1;
        var html = item.Name;
        const CustomStyle = item.CustomStyle;
        for (let index = 0; index < count; index++) {
            const fills = this.state.fills.filter(a => a.CategoryId === item.Id);
            let answer = this.state.answersList.filter(a => a.Source === index + 1 && fills.some(f => a.Target === f.AnswerId))[0];
            let AnswerId = 0;
            if (answer) {
                var isTrueAnswer = this.props.answers.filter(p => p.Id === answer.Target)[0];
                if (isTrueAnswer) {
                    AnswerId = answer.Target;
                }
            }

            let isTrue = "";
            if (this.state.mark.isSolved === true) {
                if (answer) {
                    let hasAnswer = this.props.Matches.filter(p => p.Source === answer.Source && p.Target === answer.Target)[0];
                    if (hasAnswer) {
                        isTrue = 1;
                    } else {
                        isTrue = 0;
                    }
                } else {
                    isTrue = 0;
                }
            }

            html = html.replace("(_)", `<span version='${this.props.version}' isTrue='${isTrue}' AnswerId='${AnswerId}'  Sort='${index + 1}' data-react-component="DropableFiled"></span>`);
        }
        return <ReactHtml
            html={html}
            style={style}
            SoundIconType={this.state.CustomStyle.questionTypeStyle.AnswersSelectType}
            SoundIconClass={this.state.CustomStyle.questionTypeStyle.AnswersSelectClass}
            CustomStyle={CustomStyle}
            onEdit={this.props.onEdit}
            answers={this.state.answers}
            componentMap={{ DropableFiled }}
            hoverStyle={hoverStyle}
            CategoryId={item.Id}
            QuestionId={item.Id}
            emptyAnswer={this.emptyAnswer}
            setDropTargetAnswer={this.setDropTargetAnswer} />;
    }

    emptyAnswer = (CategoryId, Source) => {
        let answersList = this.state.answersList.slice();

        const fills = this.state.fills.filter(a => a.CategoryId === CategoryId);
        const index = answersList.findIndex(a => a.Source === Source && fills.some(f => a.Target === f.AnswerId));

        if (index !== -1) {
            answersList.splice(index, 1);
            this.setState({
                answersList,
                mark: {
                    type: '',
                    userMark: 0,
                    isSolved: false
                }
            });

            if (this.props.question && this.props.question.question) {
                var copy = this.props.question;
                copy.question = {
                    ...copy.question,
                    Matches: answersList
                }
                this.props.updateQuestion(copy);
                this.props.setMatches(answersList);
            }
            this.saveExamAnswer(answersList);
        }

    }

    setDropTargetAnswer = (item, CategoryId, Sort) => {
        let answersList = this.state.answersList.slice();

        const hasFill = this.state.fills.some(a => a.CategoryId === CategoryId && a.AnswerId === item.Id);
        if (hasFill) {
            const index = answersList.findIndex(a => a.Target === item.Id && a.Source === Sort);
            if (index !== -1) {
                answersList.splice(index, 1);
            }

            answersList.push({
                Target: item.Id,
                Source: Sort
            });

            this.setState({
                answersList,
                mark: {
                    type: '',
                    userMark: 0,
                    isSolved: false
                }
            });

            if (this.props.question && this.props.question.question) {
                var copy = this.props.question;
                copy.question = {
                    ...copy.question,
                    Matches: answersList
                }
                this.props.updateQuestion(copy);
                this.props.setMatches(answersList);
            }
            this.saveExamAnswer(answersList);
        }
    }

    saveExamAnswer(answersList) {
        if (this.props.saveExamAnswer) {
            setTimeout(() => {
                let trueAnswer = 0;
                let falseAnswer = 0;
                const trueResultCount = this.props.Matches.length;
                this.props.Matches.forEach(item => {
                    var hasResult = answersList.filter(p => p.Source === item.Source && p.Target === item.Target)[0];
                    if (hasResult) {
                        trueAnswer++;
                    }
                });

                let userMark = 0;
                const mark = this.props.mark
                if (trueResultCount === trueAnswer) {
                    userMark = mark;
                } else {
                    userMark = (mark * trueAnswer / trueResultCount).toFixed(2);
                }

                this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answersList, {
                    trueResultCount,
                    trueAnswer,
                    falseAnswer,
                    questionMark: this.props.mark
                }, userMark);
            }, 200);
        }
    }

    renderAnswers = categoryId => {
        if (this.state.answers && this.props.fills) {

            let style = {
                maxWidth: '100%',
                paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

                borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,

                marginLeft: `-${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
                marginTop: `-${this.state.CustomStyle.answerStyle.PaddingTop}px`,
                marginRight: `-${this.state.CustomStyle.answerStyle.PaddingRight}px`,
                marginBottom: `-${this.state.CustomStyle.answerStyle.PaddingBottom}px`,
                opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`,
            };

            let answerBoxPosstion = 'bottom';
            if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'top' ||
                this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'innerTop' ||
                this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'innerBottom' ||
                this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'bottom') {
                answerBoxPosstion = this.state.CustomStyle.questionTypeStyle.AnswerOrder;
            }
            return (
                <div className="w-100 d-flex flex-wrap justify-content-around" >
                    {
                        this.props.fills.filter(a => a.CategoryId === categoryId).map((answer, mainKey) => (
                            <span key={`FillMultipuleMainCategoryView-${mainKey}`}>
                                {
                                    this.state.answers.filter(a => a.Id === answer.AnswerId).map((item, indexKey) => {
                                        return <span key={`FillMultipuleCategoryView-${indexKey}`}>
                                            {
                                                this.state.answersList.filter(a => a.Target === item.Id)[0] ? "" :
                                                    <DragDropContainer AnswerOrder={answerBoxPosstion} marginTop={this.state.CustomStyle.questionTypeStyle.AnswersCols} parentElement={this.designerContainer} key={item.Id} dragData={item} width={this.state.CustomStyle.questionStyle.width} targetKey={`QuestionId-${categoryId}`}>
                                                        <div className={`d-flex m-1 text-center AnswerDesignerPreview_${this.props.Id}`} style={style} key={item.Id}>
                                                            <div dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                                                        </div>
                                                    </DragDropContainer>
                                            }
                                        </span>
                                    })
                                }
                            </span>
                        ))
                    }
                </div>
            );
        }
        return "";
    }

    renderAnswerBox = categoryId => {
        const { Id } = this.props;
        let order = this.state.CustomStyle.questionTypeStyle.AnswerOrder === "top" ? 1 : 3;

        let styleBox = {
            width: `${this.state.CustomStyle.answerBoxStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerBoxStyle.BorderRadiusTop}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusRight}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerBoxStyle.ShadowPositionX}px ${this.state.CustomStyle.answerBoxStyle.ShadowPositionY}px ${this.state.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerBoxStyle.shadowColor}`,
            opacity: `${this.state.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerBoxStyle.BgAttachment}`,
        };
        if (this.props.isGame) {
            styleBox = {};
        }
        return (
            <>
                <div className={`m-100 position-relative BoxDesignerPreview_${Id} mb-${this.state.CustomStyle.questionTypeStyle.AnswersCols} order-${order}`} style={styleBox} >
                    <style dangerouslySetInnerHTML={{ __html: `.BoxDesignerPreview_${Id}{${this.state.CustomStyle.answerBoxStyle.Custom} }` }}></style>
                    <div className="m-auto position-relative overflow-x-hidden">
                        {this.renderAnswers(categoryId)}
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { isExamQuestion, title, mark, index, Id, onEdit, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let padding = {};
        let style = {};
        let styleBackground = {};
        let align = "x";

        if (this.state.CustomStyle.questionStyle) {
            style = {
                minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
                width: `${this.state.CustomStyle.questionStyle.width}%`,
                maxWidth: '100%',

                borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
            };

            styleBackground = {
                height: `100%`,
                opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
            };
            padding = {
                paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            };
        }
        if (this.state.CustomStyle.questionTypeStyle && this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`}>
                <div className="bg-white w-100 p-3 form-group position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className={`m${align}-auto position-relative overflow-hidden`} ref={this.designerContainer} style={style} >
                        <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`}> </div>
                        <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle ? this.state.CustomStyle.questionStyle.Custom : ""} }` }}></style>
                        <style dangerouslySetInnerHTML={{ __html: `.AnswerDesignerPreview_${Id}{${this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.Custom : ""} }` }}></style>
                        <style dangerouslySetInnerHTML={{ __html: `.CategoryListDesignerPreview_${Id}{${this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Custom : ""} }` }}></style>
                        <div style={padding}>
                            {this.renderCategories()}
                        </div>
                    </div>
                    {isExamQuestion === true ? showResult === true ? this.renderMark() : "" : ""}
                    {isExamQuestion !== true ? this.renderControlButton() : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(FillMultipuleView));
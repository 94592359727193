import React from "react";
import GameObjects from "../../../Question/View/GameObjects";
import configData from "../../../../Config/objects.json";

class MoveObject extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.state = {
            top: 0,
            currentStatus: "",
            updatePosition: this.updatePosition,
            left: 0,
            timer: "",
            timerSound: "",
            zIndex: 19,
            isHide: false,
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            isHover: false,
            objectMovmentId: Math.floor(Math.random() * 100000)
        };
        this.Objects = configData.Objects;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.MoveType !== "custom") {
            if (state.timer) {
                clearInterval(state.timer);
            }
            state.timer = setInterval(() => {
                state.updatePosition(props.Time);
            }, props.Time * 1000);
        }
        return state;
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    componentDidMount() {
        if (this.props.MoveType !== "custom") {
            this.updatePosition(1);
            setTimeout(() => {
                this.updatePosition(this.props.Time);
            }, 1000);
        }
    }

    updatePosition = Time => {
        if (!this.state.isHover) {
            const top = this.props.getRandomNumber(this.props.count, 50, "top");
            const left = this.props.getRandomNumber(this.props.count, 85, "left");
            this.setState({
                top,
                left
            });
            window.moveTo(`objectMovment${this.state.objectMovmentId}`, top, left, Time * 1000);
        }
    }

    renderMoveStyle = currentMovment => {
        let result = "";
        let oldScale = 1;
        let isScaleChange = false;
        currentMovment.forEach(item => {
            if (item.AnswersScale && item.AnswersScale !== oldScale) {
                isScaleChange = true;
            }
            item.AnswersScale = oldScale;
        })
        currentMovment.sort((a, b) => a.Index - b.Index).forEach(item =>
            result += item.Index + `% {visibility:visible; transform-origin:100%; transform: ${isScaleChange ? `scale(${item.AnswersScale})` : ""} rotate(` + item.AnswersRotate + `deg); opacity:${item.AnswersOpacity / 100}; ${item.AnswersTranslatePositionY === "bottom" ? "top:unset; bottom" : "bottom:unset; top"} : ${item.AnswersTranslateY}%; ${item.AnswersTranslatePositionX === "left" ? "right:unset; left" : "left:unset; right"} :${item.AnswersTranslateX}% }`
        );
        return result;
    }

    stop = () => {
        this.setState({
            isHover: true,
            zIndex: 20
        });
        window.stopMove(`objectMovment${this.state.objectMovmentId}`);

        setTimeout(() => {
            this.move();
        }, 4000);
    }

    move = () => {
        setTimeout(() => {
            this.setState({
                zIndex: 90,
                isHover: false
            });
        }, 100);
    }

    handelClickOnObject = () => {
        var item = this.Objects.filter(p => p.value === this.props.objectCss)[0];
        if (item && item.hasEffect) {
            var isTrue = this.props.checkIsTrueAnswer(this.props.param);
            let currentStatus = "Wrong";
            if (isTrue) {
                currentStatus = "True";
            }
            this.setState({
                currentStatus
            });

        } else {
            this.FinishMove(true);
        }
    }

    FinishMove = hasSound => {
        if (!this.state.isHide) {
            this.setState({
                currentStatus: "",
                isHide: true
            });
            this.props.handelClickOnObject(this.props.param, hasSound, this.props.randomKey);
        }
    }

    render() {
        const { Movments, id, Time, index, MoveType, delay } = this.props;

        const currentMovment = Movments.filter(p => p.Id === id && p.Sort === index);

        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide) {

            let right = "unset";
            let top = "unset";
            let bottom = "unset";
            if (MoveType === "custom" && currentMovment && currentMovment[0]) {
                let item = currentMovment[0];
                right = item.AnswersTranslateX + '%';
                if (item.AnswersTranslatePositionY === "bottom") {
                    bottom = item.AnswersTranslateY + '%';
                } else {
                    top = item.AnswersTranslateY + '%';
                }
            }
            return <>
                {
                    MoveType === "custom" && currentMovment ?
                        <style type="text/css" dangerouslySetInnerHTML={
                            {
                                __html: ` @keyframes objectMovment-${id}-${index} { ${this.renderMoveStyle(currentMovment)} } `
                            }
                        }></style>
                        : ""
                }
                <div
                    className={`position-absolute objectMovment `}
                    onMouseDown={() => this.stop()}
                    onMouseUp={() => this.move()}
                    onClick={() => this.handelClickOnObject()}
                    onDragEnd={() => this.move()}
                    id={`objectMovment${this.state.objectMovmentId}`}
                    style={{
                        visibility: MoveType === "custom" ? 'hidden' : "visible",
                        right: MoveType === "custom" ? right : '',
                        left: MoveType !== "custom" ? '-20%' : '',
                        top: MoveType === "custom" ? top : '-20%',
                        bottom: MoveType === "custom" ? bottom : '-20%',
                        animationDelay: `${delay}s`,
                        animationName: `objectMovment-${id}-${index}`,
                        animationIterationCount: 'infinite',
                        animationDuration: `${Time}s`,
                        animationFillMode: 'forwards',
                        animationTimingFunction: 'linear',
                        animationPlayState: this.state.isHover ? "paused" : "running",
                        position: "absolute",
                        zIndex: this.state.zIndex ? this.state.zIndex : 19
                    }}>
                    <GameObjects {...this.props} FinishMove={this.FinishMove} status={this.state.currentStatus} type="dragdrop-move" />
                </div>

            </>;
        }
        return "";
    }
}

export default MoveObject;
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import { userInitial } from '../../actions/';
import Footer from "../../Footer/FooterBottom";
import history from "../../../history";
import DashBoardBg from "../../Admin/Question/View/Background/DashBoard/DashBoardBg";

class DashBoard extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-dashboard-section");
    }

    renderNotification = count => {
        if (count > 0) {
            if (count > 99) {
                return <span className="badge badge-danger m-1 position-absolute" style={{
                    zIndex: 99
                }}>+99</span>
            }
            else {
                return <span className="badge badge-danger m-1 position-absolute" style={{
                    zIndex: 99
                }}>{count}</span>
            }
        }
        return "";
    }

    goTo = path => {
        history.push(path);
    }


    render() {
        const { t, notifications, user } = this.props;

        let Books = 0;
        let Exams = 0;
        let Files = 0;
        let Lessons = 0;
        let Mission = 0;
        let Quizzes = 0;
        let Games = 0;
        if (notifications && notifications.Notification) {
            Books = notifications.Notification.Books;
            Exams = notifications.Notification.Exams;
            Files = notifications.Notification.Files;
            Lessons = notifications.Notification.Lessons;
            Mission = notifications.Notification.Mission;
            Quizzes = notifications.Notification.Quizzes;
            Games = notifications.Notification.Games;
        }

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.UserControl.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="position-relative padding-bottom padding-top oh" id="main-dashboard-section" style={{
                    minHeight: '100vh'
                }}>
                    <DashBoardBg user={user} />

                    <div className="container">
                        <div className="row mx-3">
                            <div className="col-lg-12">
                                <div className="row align-items-center justify-content-center align-content-center mb-30-none -mx-10">

                                    <div className="teacher-wrapper col-12 mb-4 " style={{
                                        backgroundColor: '#ffffffd9'
                                    }}>
                                        <div className="teacher-single-item d-flex flex-wrap justify-content-evenly align-items-center mb-0 p-2 " >

                                            <div className="d-flex align-items-center justify-content-center flex-column" >
                                                <div>
                                                    <Link to="/Profile/Edit" className="btn btn-danger mr-1" title={t('edit')}>
                                                        <i className="fal fa-edit"></i> <span className="d-none d-xl-inline-block"> {t('edit')} </span>
                                                    </Link>
                                                    <Link to="/Profile/ChangePassword" className="btn btn-info mr-1" title={t('ChangePassword')}>
                                                        <i className="fal fa-key"></i> <span className="d-none d-xl-inline-block"> {t('ChangePassword')} </span>
                                                    </Link>
                                                    <Link to="/Profile/Picture" className="btn btn-success mr-1" title={t('ChangePicture')}>
                                                        <i className="fal fa-image"></i> <span className="d-none d-xl-inline-block"> {t('ChangePicture')} </span>
                                                    </Link>
                                                </div>

                                                <div className="d-flex align-items-center flex-column">
                                                    <div className="teacher-single-thumb  mt-4 ">
                                                        <LazyLoadImage src={`${user.user.Photo ? user.user.Photo : "/assets/images/teacher/teacher-single.jpg"}`} alt={`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} />
                                                    </div>

                                                    <div className="w-100">
                                                        <Link to="/Student/Game/Profile" className=" custom-button orange-button mt-2 mx-1" style={{
                                                            minWidth: '215px'
                                                        }}>
                                                            <span>
                                                                {t('Pages.Competition.Title')}
                                                            </span>
                                                        </Link>

                                                        <Link to="/CompetitionStage" className=" custom-button primary-button mt-2 mx-1" style={{
                                                            minWidth: '215px'
                                                        }}>
                                                            <span>
                                                                <i className="icon fal fa-crown"></i>
                                                                {t('Pages.Competition.Stage')}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="teacher-single-content  mx-3">
                                                <h3 className="title pb-4">
                                                    {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`}
                                                </h3>
                                                <ul>
                                                    <li className="d-flex flex-wrap justify-content-start">
                                                        <span className="list-name">{t('Pages.Register.Birthday.title')}</span>
                                                        <span className="list-attr"> {user.user.Birthday}</span>
                                                    </li>
                                                    {
                                                        user.user.Sex ?
                                                            <>
                                                                <li className="d-flex flex-wrap justify-content-start">
                                                                    <span className="list-name">{t('Pages.Register.Sex.title')}</span>
                                                                    <span className="list-attr">
                                                                        <i className={`icon text-info fad fa-${user.user.Sex}`}></i>
                                                                        {t('Pages.Register.Sex.' + user.user.Sex)}
                                                                    </span>
                                                                </li>
                                                            </> : ""
                                                    }

                                                    <li className="d-flex flex-wrap justify-content-start">
                                                        <span className="list-name">{t('Pages.Register.Address.title')}</span>
                                                        <span className="list-attr">  {user.user.Address}</span>
                                                    </li>

                                                    {
                                                        user.user.Email ?
                                                            <>
                                                                <li className="d-flex flex-wrap justify-content-start">
                                                                    <span className="list-name">{t('Pages.Register.Email.title')}</span>
                                                                    <span className="list-attr">
                                                                        <a href={`mailto:${user.user.Email}`}>
                                                                            {user.user.Email}
                                                                        </a>
                                                                    </span>
                                                                </li>
                                                            </> : ""
                                                    }
                                                    {
                                                        user.user.Phone ?
                                                            <li className="d-flex flex-wrap justify-content-start">
                                                                <span className="list-name">{t('Pages.Register.Phone.title')}</span>
                                                                <span className="list-attr">
                                                                    <a href={`Tel:${user.user.Phone}`}>
                                                                        <span dir="ltr">
                                                                            {user.user.Phone}
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                            </li> : ""
                                                    }

                                                    <li className="d-flex flex-wrap justify-content-start">
                                                        <span className="list-name">{t('Pages.Register.Username.title')}</span>
                                                        <span className="list-attr">  {user.user.Username} </span>
                                                    </li>
                                                    <li className="d-flex flex-wrap justify-content-start">
                                                        <span className="list-name">{t('Pages.Register.SchoolName.title')}</span>
                                                        <span className="list-attr">  {user.user.School.Name} </span>
                                                    </li>
                                                    <li className="d-flex flex-wrap justify-content-start">
                                                        <span className="list-name">{t('Pages.Profile.LastLogin.title')}</span>
                                                        <span className="list-attr">  {user.user.LastLogin}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="d-flex justify-content-center flex-wrap grid-container">

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Games`)}>
                                            {this.renderNotification(Games)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-gamepad-alt "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.MyGames')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Missions`)}>
                                            {this.renderNotification(Mission)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-bullseye-arrow "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.MyMissions')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Books`)}>
                                            {this.renderNotification(Books)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-book "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Books')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Lessons`)}>
                                            {this.renderNotification(Lessons)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-layer-group "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Lessons')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Files`)}>
                                            {this.renderNotification(Files)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-paperclip "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Files')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Exams`)}>
                                            {this.renderNotification(Exams)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-pencil-alt "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Exams')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Quizzes`)}>
                                            {this.renderNotification(Quizzes)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-question "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Quizzes')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Profile`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-user "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.title')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/Mark`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-trophy-alt "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.markList')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/Favorite/Lesson`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-heart "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.favoriteList')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/Favorite/Files`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-heart-circle "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.favoriteFiles')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/History/Lessons`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-history "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.lessonsHistory')}</h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications
    };
};

export default connect(mapStateToProps, { userInitial })(withTranslation('common')(DashBoard));
import React from "react";

import GameObjects from "../../GameObjects";
import './style.css';

class TugOfWar extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/scoreboard/mounten.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            top: '0'
        }}>

            <div className="position-absolute user-select-none" style={{
                right: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud2" /></div>
            <div className="position-absolute user-select-none" style={{
                right: 'calc(50% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>
            <div className="position-absolute user-select-none MoveAirplane"><GameObjects objectCss="Airplane" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="w-100 h-100 position-absolute user-select-none" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/scoreboard/ground.png"})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: "100%",
                backgroundPositionX: "50%"
            }}></div>

            <div className="position-absolute user-select-none d-none d-sm-block MoveTugOfWar"><GameObjects objectCss="BoysTugOfWar" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="w-100 h-100 position-absolute user-select-none" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/scoreboard/gress.png"})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: "100%",
                backgroundPositionX: "50%",
                bottom: '0%',
                backgroundSize: '100%'
            }}></div>

        </div>
    }
}
export default TugOfWar;
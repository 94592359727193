import { registerOnilneUser, UserLogout } from '../api/socket.io';

const UserReducers = (state = [], action) => {
    switch (action.type) {
        case "USER_INITIAL":
            if (action.user && action.user.user) {
                registerOnilneUser(action.user.user);
            } else {
                registerOnilneUser(action.user);
            }
            return {
                isLogin: true,
                user: action.user
            };
        case "USER_IS_ACTIVE": {
            state.user.IsActive = true;
            return {
                isLogin: true,
                user: state.user
            };
        }
        case "USER_NOT_LOGIN":
            if (action.user) {
                UserLogout(action.user.user);
            }
            return {
                user: '',
                isLogin: false
            };
        default:
            return state;
    }
}

export default UserReducers;
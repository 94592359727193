import api from '../../../../api/api';
import { setIndeices } from '../../../../context/sessionStorage';

export const fetchIndices = () => async dispatch => {
    const response = await api.get(`/Indices/`);
    dispatch({ type: 'INDICES_INITIAL', nodes: response.data.data });
    setIndeices(response.data.data);
}

export const setIndices = (nodes) => async dispatch => {
    dispatch({ type: 'INDICES_INITIAL', nodes: nodes });
}

export const removeIndices = (id) => async dispatch => {
    dispatch({ type: 'INDICES_QUEUE_FOR_REMOVAL', id });
}

export const undoIndices = (id) => async dispatch => {
    dispatch({ type: 'INDICES_UNDO', id });
}

export const clearIndices = (id) => async dispatch => {
    dispatch({ type: 'INDICES_CLEAN_NODES', id });
}

export const updateIndices = (newNode, id) => async dispatch => {
    dispatch({ type: 'INDICES_UPDATE', id: id, newNode: newNode });
}

export const createIndices = (newNode, id) => async dispatch => {
    dispatch({ type: 'INDICES_CREATE', id: id, newNode: newNode });
}

export const updateIndicesRemoveCount = (id) => async dispatch => {
    dispatch({ type: 'INDICES_UPDATE_REMOVE_COUNT', id: id });
}
import React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next'
import { Player } from '@lottiefiles/react-lottie-player';

import SelectView from './Questions/SelectView';
import MulitSelectView from './Questions/MulitSelectView';
import QuestionnaireView from './Questions/QuestionnaireView';
import OrderView from './Questions/OrderView';
import FillView from './Questions/FillView';
import FillTextView from './Questions/FillTextView';
import FillListView from './Questions/FillListView';
import FillChooseView from './Questions/FillChooseView';
import MatchesView from './Questions/MatchesView';
import TextView from './Questions/TextView';
import ClassificationView from './Questions/ClassificationView';
import ClassificationClausView from './Questions/ClassificationClausView';
import ClickView from './Questions/ClickView';
import FillMultipuleView from './Questions/FillMultipuleView';

import configSoundData from "../../../Config/Sound.json";
import effectData from "../../../Config/effect.json";
import DragDropView from './GamePreview/DragDropView';
import ShootingGameView from './GamePreview/ShootingGameView';
import FallDownView from './GamePreview/FallDownView';
import MultiSelectView from './GamePreview/MultiSelectView';
import GameFillView from './GamePreview/GameFillView';
import DropMultiSelectView from './GamePreview/DropMultiSelectView';
import PullView from './GamePreview/PullView';
import ShootHorizantalMultiSelectView from './GamePreview/ShootHorizantalMultiSelectView';
import MultiFillType from './Questions/MultiFillType';

class QuestionView extends React.Component {

    state = {
        answers: [],
        Fills: [],
        Matches: [],
        randomId: 0,
        first: true,
        showConfetti: false,
        showGame: false
    };

    constructor(props) {
        super(props)
        this.audio = new Audio();
        this.True_Sound = configSoundData.True_Sound;
        this.False_Sound = configSoundData.False_Sound;
        this.success = effectData.success;
    }

    componentDidMount() {
        let src = "";
        if (this.props.question.CustomStyle.questionSound) {

            const Confetti = this.props.question.CustomStyle.questionSound.Confetti;
            const CustomConfetti = this.props.question.CustomStyle.questionSound.CustomConfetti;
            if (Confetti !== 'custom' && Confetti !== 'none') {
                if (Confetti === 'random') {
                    src = this.success[Math.floor(Math.random() * this.success.length)].url;
                } else {
                    src = process.env.PUBLIC_URL + Confetti;
                }
            } else if (Confetti === 'custom' && CustomConfetti) {
                src = CustomConfetti;
            }
        }

        this.setState({
            answers: this.shuffleArray(this.props.question.Answers),
            Fills: this.shuffleArray(this.props.question.Fills),
            Matches: this.shuffleArray(this.props.question.Matches),
            randomId: Math.random() * 200,
            src
        });
    }

    shuffleArray(array) {
        if (array) {
            let i = array.length - 1;
            for (; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        }
        return [];
    }

    setSortAnswers = answers => {
        this.setState({
            answers,
            first: false
        });
    }

    setFills = Fills => {
        this.setState({
            Fills
        });
    }

    setMatches = Matches => {
        this.setState({
            Matches
        });
    }

    playTrueSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        }
        if (this.props.question.CustomStyle.questionSound &&
            this.props.question.CustomStyle.questionSound.TrueSound &&
            this.props.question.CustomStyle.questionSound.TrueSound !== '' &&
            this.props.question.CustomStyle.questionSound.TrueSound !== 'custom' &&
            this.props.question.CustomStyle.questionSound.TrueSound !== 'none') {
            let sound = this.props.question.CustomStyle.questionSound.TrueSound;
            if (this.props.question.CustomStyle.questionSound.TrueSound === 'random') {
                const soundsList = this.True_Sound.filter(p => p.type === this.props.indexType);
                if (soundsList[Math.floor(Math.random() * soundsList.length)]) {
                    sound = soundsList[Math.floor(Math.random() * soundsList.length)].url;
                }
            }
            this.audio = new Audio(process.env.PUBLIC_URL + sound);
            this.audio.play();
        } else if (this.props.question.CustomStyle.questionSound &&
            this.props.question.CustomStyle.questionSound.TrueSound &&
            this.props.question.CustomStyle.questionSound.TrueSound !== '' &&
            this.props.question.CustomStyle.questionSound.TrueSound === 'custom' &&
            this.props.question.CustomStyle.questionSound.CustomTrueSound !== '') {
            this.audio = new Audio(this.props.question.CustomStyle.questionSound.CustomTrueSound);
            this.audio.play();
        }
        setTimeout(
            () => {
                window.webuiPopover();
            },
            500
        );
    }

    playFalseSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        }
        if (this.props.question.CustomStyle.questionSound &&
            this.props.question.CustomStyle.questionSound.FalseSound &&
            this.props.question.CustomStyle.questionSound.FalseSound !== '' &&
            this.props.question.CustomStyle.questionSound.FalseSound !== 'custom' &&
            this.props.question.CustomStyle.questionSound.FalseSound !== 'none') {
            let sound = this.props.question.CustomStyle.questionSound.FalseSound;
            if (this.props.question.CustomStyle.questionSound.FalseSound === 'random') {
                const soundsList = this.False_Sound.filter(p => p.type === this.props.indexType);
                if (soundsList[Math.floor(Math.random() * soundsList.length)]) {
                    sound = soundsList[Math.floor(Math.random() * soundsList.length)].url;
                }
            }
            this.audio = new Audio(process.env.PUBLIC_URL + sound);
            this.audio.play();
        } else if (this.props.question.CustomStyle.questionSound &&
            this.props.question.CustomStyle.questionSound.FalseSound &&
            this.props.question.CustomStyle.questionSound.FalseSound !== '' &&
            this.props.question.CustomStyle.questionSound.FalseSound === 'custom' &&
            this.props.question.CustomStyle.questionSound.CustomFalseSound !== '') {
            this.audio = new Audio(this.props.question.CustomStyle.questionSound.CustomFalseSound);
            this.audio.play();
        }
        setTimeout(
            () => {
                window.webuiPopover();
            },
            500
        );
    }

    calcTime = Time => {
        let result = "";
        if (Time % 3600) {
            result = (parseInt(Time / 3600) > 9 ? parseInt(Time / 3600) : "0" + parseInt(Time / 3600)) + ":";
            Time = Time % 3600;
        } else {
            result += "00:";
        }

        if (Time % 60) {
            result += (parseInt(Time / 60) > 9 ? parseInt(Time / 60) : "0" + parseInt(Time / 60)) + ":";
            Time = Time % 60;
        } else {
            result += "00:";
        }

        if (Time > 0) {
            result += parseInt(Time) > 9 ? parseInt(Time) : "0" + parseInt(Time);
        } else {
            result += "00:";
        }
        return result;
    }

    renderFooter = () => {
        const { t, options } = this.props;
        return <tbody>
            <tr className="text-center">
                <th>
                    {t('Pages.Missions.table.mark')}
                </th>
                <th>
                    {t('Pages.Games.Table.Point')}
                </th>
                <th>
                    {t('Pages.Games.Table.UserTime')}
                </th>
                <th>
                    {t('Pages.Games.Table.Repate')}
                </th>
            </tr>
            <tr className="text-center">
                <td>
                    {options.Mark}
                </td>
                <td>
                    {options.Score}
                </td>
                <td title={t('Pages.Games.Table.Time')}>
                    {this.calcTime(options.Time)}
                </td>
                <td>
                    {options.TryNumber}
                </td>
            </tr>
            {
                options.IsTimesUp ?
                    <tr>
                        <td className="text-center text-danger" colSpan="4">
                            <i className="icon fa-regular fa-timer"></i> {t('Pages.Games.Table.IsTimesUp')}
                        </td>
                    </tr>
                    :
                    !options.IsPassed ?
                        <tr>
                            <td className="text-center text-danger" colSpan="4">
                                <i className="icon fa-regular fa-bug"></i> {t('Pages.Games.Table.IsFailed')}
                            </td>
                        </tr>
                        : ""
            }
            <tr>
                <td className="text-center" colSpan="2">
                    <i className="fas fa-check-square text-success mx-2" ></i>
                    {t('Pages.Lessons.Questions.forms.trueAnswer')}
                </td>
                <td className="text-center" colSpan="2">
                    <i className="fas fa-times-square text-danger mx-2" ></i>
                    {t('Pages.Lessons.Questions.forms.falseAnswer')}
                </td>
            </tr>
        </tbody>
    }

    renderQuestionView(question, index) {
        if (question && question.Type) {
            if (question.Type.Id === 1) {
                return (
                    <SelectView
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 2) {
                return (
                    <OrderView
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setSortAnswers={this.setSortAnswers}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={this.props.onEdit ? question.Answers : this.state.answers}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView} />
                );
            } else if (question.Type.Id === 3) {
                return (
                    <FillView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setFills={this.setFills}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={this.state.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 4) {
                return (
                    <FillTextView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setFills={this.setFills}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={this.state.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 5) {
                return (
                    <FillListView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 6) {
                return (
                    <FillChooseView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 7) {
                return (
                    <MulitSelectView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 8) {
                return (
                    <QuestionnaireView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 9) {
                return (
                    <MatchesView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        Matches={this.state.Matches}
                        setMatches={this.setMatches}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        randomId={this.state.randomId}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 10) {
                return (
                    <ClassificationView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setFills={this.setFills}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={this.state.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 11) {
                return (
                    <TextView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={this.state.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 12) {
                return (
                    <ClickView
                        updateVersion={this.props.updateVersion}
                        version={this.props.version}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={this.state.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 13) {
                return (
                    <ClassificationClausView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setFills={this.setFills}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={this.state.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        Fills={question.Fills}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 14) {
                return (
                    <FillMultipuleView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setFills={this.setFills}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        Matches={this.state.Matches}
                        setMatches={this.setMatches}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            } else if (question.Type.Id === 15) {
                return (
                    <DragDropView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        categories={question.Categories}
                        ViewGameResult={this.props.ViewGameResult}
                        CustomStyle={question.CustomStyle}
                    />);
            } else if (question.Type.Id === 16) {
                return (
                    <ShootingGameView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        categories={question.Categories}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 17 || question.Type.Id === 18) {
                return (
                    <FallDownView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 19) {
                return (
                    <MultiSelectView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        categories={question.Categories}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 20) {
                if (this.state.showGame || this.props.ViewGameResult) {
                    return (<GameFillView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        question={question}
                        fills={question.Fills}
                        version={this.props.version}
                        categories={question.Categories}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                        updateVersion={this.props.updateVersion}
                    />);
                } else {
                    return (<FillMultipuleView
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        setFills={this.setFills}
                        title={question.Name}
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        mark={question.Mark}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        isGame={true}
                        Matches={this.state.Matches}
                        setMatches={this.setMatches}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />);
                }
            } else if (question.Type.Id === 21) {
                return (
                    <FallDownView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 22) {
                return (
                    <DragDropView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        categories={question.Categories}
                        examAnswers={this.props.examAnswers}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 23) {
                return (
                    <DropMultiSelectView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        categories={question.Categories}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 24 || question.Type.Id === 25 || question.Type.Id === 28 || question.Type.Id === 29 || question.Type.Id === 30) {
                return (
                    <PullView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        categories={question.Categories}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 26 || question.Type.Id === 27 || question.Type.Id === 31) {
                return (
                    <ShootHorizantalMultiSelectView
                        Id={question.Id}
                        renderFooter={this.renderFooter}
                        answers={question.Answers}
                        examAnswers={this.props.examAnswers}
                        categories={question.Categories}
                        CustomStyle={question.CustomStyle}
                        ViewGameResult={this.props.ViewGameResult}
                    />);
            } else if (question.Type.Id === 32) {
                return (
                    <MultiFillType
                        updateVersion={this.props.updateVersion}
                        isExamQuestion={this.props.isExamQuestion}
                        showResult={this.props.showResult}
                        TypeId={question.Type.Id}
                        examAnswers={this.props.examAnswers}
                        saveExamAnswer={this.props.saveExamAnswer}
                        title={question.Name}
                        Id={question.Id}
                        mark={question.Mark}
                        answers={question.Answers}
                        categories={question.Categories}
                        fills={question.Fills}
                        index={index}
                        onEdit={this.props.onEdit}
                        solveButton={this.props.solveButton}
                        toast={toast}
                        CustomStyle={question.CustomStyle}
                        SizeView={this.props.SizeView}
                        version={this.props.version}
                        setQuestionAnswerPosition={this.props.setQuestionAnswerPosition}
                        setQuestionDesignerContainer={this.props.setQuestionDesignerContainer}
                        playTrueSound={this.playTrueSound}
                        playFalseSound={this.playFalseSound}
                        showConfetti={this.showConfetti}
                    />
                );
            }
        }
        return "";
    }

    renderPalyer = () => {
        if (this.state.src && this.state.showConfetti === true) {
            return (
                <div className="position-absolute w-100 d-flex align-items-center justify-content-center" style={{
                    pointerEvents: 'none',
                    top: '0',
                    right: '0',
                    zIndex: 98,
                    height: '100%',
                    flexShrink: '0'
                }}>
                    <Player
                        onEvent={event => {
                            if (event === 'complete') {
                                this.setState({
                                    showConfetti: false
                                });
                            }
                        }}
                        autoplay
                        loop={false}
                        src={this.state.src}
                        style={{ height: '100%', width: '100%' }} ></Player>
                </div>
            );
        }
        return "";
    }

    showConfetti = () => {
        this.setState({
            showConfetti: true
        });
    }

    handleShowGame = showGame => {
        this.setState({
            showGame
        });
    }

    render() {
        const { t } = this.props;
        let FontSize = 18;
        if (this.props.FontSize) {
            FontSize = this.props.FontSize;
        }
        return (
            <div style={{ fontSize: FontSize + 'px' }} className="position-relative" key={this.props.question.Id}>
                {
                    this.props.question.Type.Id === 20 && this.props.ViewGameResult !== true ?
                        <div className="w-100 ">
                            <ul className="filter mb-2">
                                <li>
                                    <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                        <input
                                            type='radio'
                                            defaultChecked={true}
                                            onChange={e => this.handleShowGame(false)}
                                            className="custom-control-input"
                                            name="fullPage"
                                            id="showAnswer" />
                                        <label className="custom-control-label pt-1" htmlFor="showAnswer" >
                                            <i className="icon fal fa-book-open"></i>
                                            {t('Pages.Lessons.Questions.forms.tools.showAnswer')}
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                        <input
                                            type='radio'
                                            onChange={e => this.handleShowGame(true)}
                                            className="custom-control-input"
                                            name="fullPage"
                                            id="showGame" />
                                        <label className="custom-control-label pt-1" htmlFor="showGame" >
                                            <i className="icon fad fa-book-open"></i>
                                            {t('Pages.Lessons.Questions.forms.tools.showGame')}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        : ""
                }
                <div key="palyer">
                    {this.renderPalyer()}
                </div>
                {this.renderQuestionView(this.props.question, this.props.index)}
            </div>
        );
    }
}

export default withTranslation('common')(QuestionView);
import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import api from '../../../../api/api';
import DragDrop from './DragDrop';

class DragDropModal extends React.Component {

    state = { show: false, items: [] }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.show && props.nodes) {
            state.items = props.nodes;
            state.show = true;
        }
        return state;
    }

    setSort = items => {
        this.setState({
            items: items
        });
    }

    saveSort = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const sortedId = [];
        this.state.items.forEach((item, index) => {
            sortedId.push({
                id: item.Id,
                sort: index
            });
        });

        var postData = {
            sortedIds: sortedId
        };

        api.post(`/UserGuides/` + this.props.IndexId,
            postData
        ).then(response => {
            this.props.setSort(this.state.items);
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UserGuide.Indices.Sort.Title')}</div> <div className="h6">{t('Pages.UserGuide.Indices.Sort.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Modal
                    size="lg"
                    show={true}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="h5 mb-0">
                                <i className=" icon fas fa-sort-numeric-up"></i> {t("Pages.UserGuide.Indices.tools.sort")}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group w-100 position-relative">
                            <DragDrop
                                items={this.state.items}
                                setSort={this.setSort}  >
                            </DragDrop>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="w-100 row">
                            <div className="col-6">
                                <button className="btn btn-success" onClick={() => this.saveSort()} >
                                    <i className="icon fal fa-save"></i>
                                    {t('Pages.UserGuide.Indices.form.save')}
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-danger" onClick={this.props.handleClose} >
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


}

export default withTranslation('common')(DragDropModal);
import api from '../../../../api/api';

export const fetchFiles = id => async dispatch => {
    dispatch({ type: 'FILES_LOADING' });
    const response = await api.get(`/EducationalFiles/${id}?byIndex=true`);
    dispatch({ type: 'FILES_INITIAL', files: response.data.data });
}

export const fetchFavoriteFile = () => async dispatch => {
    dispatch({ type: 'FILES_LOADING' });
    const response = await api.get(`/EducationalFiles/`);
    dispatch({ type: 'FILES_INITIAL', files: response.data.data });
}

export const fetchHistoryFiles = (startDate, endDate, page) => async dispatch => {
    dispatch({ type: 'FILES_LOADING' });
    const response = await api.get(`/HistoryFiles/?startDate=${startDate}&endDate=${endDate}&skip=${page}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'FILES_LAZY_LOADING', files: response.data.data });
    } else {
        dispatch({ type: 'FILES_LAZY_LOADING_STOP' });
    }
}

export const fetchSharedFiles = (startDate, endDate) => async dispatch => {
    dispatch({ type: 'FILES_LOADING' });
    const response = await api.get(`/SharedLessonsAndFiles/?startDate=${startDate}&endDate=${endDate}&isFile=${true}`);
    dispatch({ type: 'FILES_INITIAL', files: response.data.data });
}

export const fetchFile = id => async dispatch => {
    try {
        const response = await api.get(`/EducationalFiles/${id}`);
        dispatch({ type: 'FILE_INITIAL', file: response.data.data, code: response.data.code });
        if (response.data.code === "no_permission") {
            const token = localStorage.getItem("token");
            if (!token) {
                window.location.href = `/Login?backTo=/LessonView/${id}`;
            }
        }
    } catch {
        window.location.href = "/NotFound";
    }
}

export const setFileIsView = id => async dispatch => {
    dispatch({ type: 'FILES_UPDATE_ISVIEW', id });
}

export const setFileFavorite = id => async dispatch => {
    const response = await api.put(`/User/${id}?IsForLesson=${false}`);
    dispatch({ type: 'FILES_UPDATE_FAVORITE', id: response.data });
}

export const clearFileInfo = () => async dispatch => {
    dispatch({ type: 'CLEAR_FILE_INFO' });
}

export const removeFile = (id) => async dispatch => {
    dispatch({ type: 'FILE_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteFile = (id) => async dispatch => {
    dispatch({ type: 'FILE_UNDO', id });
}

export const clearFile = (id) => async dispatch => {
    dispatch({ type: 'FILE_CLEAN_NODES', id });
}

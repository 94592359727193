import api from '../../../../../api/api';

export const fetchIndices = () => async dispatch => {
    const response = await api.get(`/UserGuideIndexes/`);
    dispatch({ type: 'USER_GUIDE_INDICES_INITIAL', nodes: response.data.data });
}

export const fetchArticle = id => async dispatch => {
    const response = await api.get(`/UserGuides/${id}`);
    dispatch({ type: 'USER_GUIDE_ARTICLE_INITIAL', article: response.data.data });
}

export const fetchArticles = IndexId => async dispatch => {
    const response = await api.get(`/UserGuides/${IndexId}?ByIndex=${true}`);
    dispatch({ type: 'USER_GUIDE_ARTICLES_INITIAL', articles: response.data.data });
}

export const clearArticle = () => async dispatch => {
    dispatch({ type: 'USER_GUIDE_ARTICLE_LOADING' });
}

export const setIndices = (nodes) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_INITIAL', nodes: nodes });
}

export const removeIndices = (id) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_QUEUE_FOR_REMOVAL', id });
}

export const undoIndices = (id) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_UNDO', id });
}

export const clearIndices = (id) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_CLEAN_NODES', id });
}

export const updateIndices = (newNode, id) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_UPDATE', id: id, newNode: newNode });
}

export const createIndices = (newNode, id) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_CREATE', id: id, newNode: newNode });
}

export const updateIndicesRemoveCount = (id) => async dispatch => {
    dispatch({ type: 'USER_GUIDE_INDICES_UPDATE_REMOVE_COUNT', id: id });
}
import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

import configData from "../../../../Config/objects.json";

class GameSetting extends React.Component {

    handleCloseColorPicker = () => {
        this.setState({
            displayMoveColorPicker: false,
            displayDropColorPicker: false,
            displayRopeColorPicker: false
        })
    }

    handleClickDisplayMoveColorPicker = () => {
        this.setState({ displayMoveColorPicker: !this.state.displayMoveColorPicker });
    }

    handleClickDisplayTryColorCustomPicker = () => {
        this.setState({ displayTryColorCustomPicker: !this.state.displayTryColorCustomPicker });
    }

    handleClickDisplayDropColorPicker = () => {
        this.setState({ displayDropColorPicker: !this.state.displayDropColorPicker });
    }

    handleClickDisplayRopeColorPicker = () => {
        this.setState({ displayRopeColorPicker: !this.state.displayRopeColorPicker });
    }

    handleMoveColorChange = (color) => {
        this.setState({ MoveCustomColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setMoveCustomColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }

    ClearMoveCustomColor = () => {
        this.props.setMoveCustomColor('');
    }

    handleDropColorChange = (color) => {
        this.setState({ DropCustomColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setDropCustomColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }

    handleTryColorCustomColorChange = (color) => {
        this.setState({ TryColorCustom: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setGameTryColorCustom(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }

    handleRopeCustomColorChange = (color) => {
        this.setState({ RopeColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setGameyRopeColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }

    ClearDropCustomColor = () => {
        this.props.setDropCustomColor('');
    }

    constructor(props) {
        super(props);
        this.state = {
            Cursor: "",
            MoveObject: "",
            MoveObjectSrc: "",
            MoveObjectSrcHover: "",
            MoveCustomColor: "",
            DropObject: "",
            DropImage: "",
            DropObjectSrc: "",
            DropObjectSrcHover: "",
            DropCustomColor: "",
            TryCount: 3,
            FlipX: "",
            FlipY: "",
            MaxTop: 1,
            MinTop: 0,
            RopeColor: "",
            OffsetTop: "",
            OffsetTopObject: "",
            ShootObjectSrc: "",
            ShootObjectSrcHover: "",
            ObjectCategory: 1,
            ObjectCategoryDrop: 1,
            displayDropColorPicker: false,
            displayMoveColorPicker: false,
            displayRopeColorPicker: false,
            displayTryColorCustomPicker: false,
            DropObjectColor: false,
            MoveObjectColor: false,
            ShootObjectSound: "",
            TransformOriginY: "center",
            TransformOriginX: "center",
            IconType: "fad",
            Time: 60
        };
        this.Objects = configData.Objects.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else return 1;
        });
        this.Cursor = configData.Cursor;
        this.Category = configData.Category;
        this.ShottingObjects = configData.ShottingObjects.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else return 1;
        });

        var item = this.Objects.filter(p => p.value === this.props.MoveObject)[0];
        if (item) {
            this.state.ObjectCategory = item.category;
            this.state.MoveObjectColor = item.hasColor;
        }
        item = this.Objects.filter(p => p.value === this.props.DropObject)[0];
        if (item) {
            this.state.ObjectCategoryDrop = item.category;
            this.state.DropObjectColor = item.hasColor;
        }
        this.audio = new Audio();
    }

    static getDerivedStateFromProps(props, state) {
        state.Cursor = props.Cursor;
        state.CursorCustom = props.CursorCustom;
        state.MoveObject = props.MoveObject;
        state.MoveObjectSrc = props.MoveObjectSrc;
        state.MoveObjectSrcHover = props.MoveObjectSrcHover;
        state.DropObject = props.DropObject;
        state.DropObjectSrc = props.DropObjectSrc;
        state.DropObjectSrcHover = props.DropObjectSrcHover;
        state.TryCount = props.TryCount;
        state.TryShape = props.TryShape;
        state.TryColor = props.TryColor;
        state.DropCustomColor = props.DropCustomColor;
        state.MoveCustomColor = props.MoveCustomColor;
        state.TryColorCustom = props.TryColorCustom;
        state.TranslateX = props.TranslateX;
        state.TranslateTime = props.TranslateTime;
        state.Time = props.Time;
        state.ExplosionObject = props.ExplosionObject;
        state.IconType = props.IconType;
        state.FlipX = props.FlipX;
        state.FlipY = props.FlipY;
        state.Rotate = props.Rotate;
        state.RopeColor = props.RopeColor;
        state.OffsetTop = props.OffsetTop;
        state.OffsetTopObject = props.OffsetTopObject;
        state.ShootObjectSrc = props.ShootObjectSrc;
        state.ShootObjectSrcHover = props.ShootObjectSrcHover;
        state.ShootObjectSound = props.ShootObjectSound;
        state.ShootObjectOption = props.ShootObjectOption;
        state.MaxTop = props.MaxTop;
        state.MinTop = props.MinTop;
        state.TransformOriginY = props.TransformOriginY;
        state.TransformOriginX = props.TransformOriginX;
        return state;
    }

    renderCursor = () => {
        return this.Cursor.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderObject = type => {
        return this.Objects.filter(p => (p.type === 2 || p.type === -1 || p.type === type) && p.category === this.state.ObjectCategory).map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderObjectTypeTwo = () => {
        return this.Objects.filter(p => (p.type === 2 || p.type === -1) && p.category === this.state.ObjectCategoryDrop).map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderObjectCategory = () => {
        return this.Category.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderShottingObject = () => {
        return this.ShottingObjects.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    setGameObjectCategory = ObjectCategory => {
        this.setState({
            ObjectCategory: parseInt(ObjectCategory)
        });
        var item = this.Objects.filter(p => p.category === parseInt(ObjectCategory))[0];
        if (item) {
            this.setState({
                MoveObjectColor: item.hasColor
            });
            this.props.setGameMoveObject(item.value);
        }
    }
    setGameObjectCategoryDrop = ObjectCategoryDrop => {
        this.setState({
            ObjectCategoryDrop: parseInt(ObjectCategoryDrop)
        });
        var item = this.Objects.filter(p => (p.type === 2 || p.type === -1) && p.category === parseInt(ObjectCategoryDrop))[0];
        if (item) {
            this.setState({
                DropObjectColor: item.hasColor
            });
            this.props.setGameDropObject(item.value);
        }
    }

    setGameMoveObject = value => {
        const item = this.Objects.filter(p => p.value === value)[0];
        if (item) {
            this.setState({
                MoveObjectColor: item.hasColor
            });
        }
        this.props.setGameMoveObject(value);
    }

    setGameDropObject = value => {
        const item = this.Objects.filter(p => p.value === value)[0];
        if (item) {
            this.setState({
                DropObjectColor: item.hasColor
            });
        }
        this.props.setGameDropObject(value);
    }

    playShootObjectSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else if (this.state.ShootObjectSound) {
            this.audio = new Audio(this.state.ShootObjectSound);
            this.audio.play();
        }
    }

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        const { t, typeId } = this.props;
        return (
            <>
                <li id="PopoverPadding" title={t('Pages.Lessons.Questions.forms.tools.custom')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="fal fa-ufo"></i>
                            {t('Pages.Lessons.Questions.forms.tools.custom')}
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="fal fa-ufo icon"></i>
                                {t('Pages.Lessons.Questions.forms.tools.custom')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">

                                    <div className="title w-100 text-center">
                                        {t('Pages.Lessons.Questions.forms.tools.objects.MoveObject')}
                                    </div>

                                    <div className={`form-group mb-3`}>
                                        <label className="text-dark h6"><i className="icon fas fa-box-ballot"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ObjectCategory')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.setGameObjectCategory(e.target.value)}
                                                className="w-100"
                                                value={this.state.ObjectCategory ? this.state.ObjectCategory : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                {this.renderObjectCategory()}
                                            </select>
                                            <button type="button" >
                                                <i className="fas fa-box-ballot"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className={`form-group mb-3 `}>
                                        <label className="text-dark h6"><i className="icon fas fa-ufo"></i> {t('Pages.Lessons.Questions.forms.tools.objects.MoveObject')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.setGameMoveObject(e.target.value)}
                                                className="w-100"
                                                value={this.state.MoveObject ? this.state.MoveObject : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                {this.renderObject(1)}
                                            </select>
                                            <button type="button" >
                                                <i className="fas fa-ufo"></i>
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        this.state.MoveObject === "custom" ?
                                            <>
                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.MoveObjectSrc')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.MoveObjectSrc')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.MoveObjectSrc')}
                                                            value={this.state.MoveObjectSrc ? this.state.MoveObjectSrc : ''}
                                                            onChange={e => this.props.setGameMoveObjectSrc(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.MoveObjectSrcHover')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.MoveObjectSrcHover')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.MoveObjectSrcHover')}
                                                            value={this.state.MoveObjectSrcHover ? this.state.MoveObjectSrcHover : ''}
                                                            onChange={e => this.props.setGameMoveObjectSrcHover(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="widget-header p-1 w-100 mb-3"></div>
                                            </>
                                            :
                                            this.state.MoveObjectColor ?
                                                <div className="form-group mb-3 w-100">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                            value={this.state.MoveCustomColor}
                                                            onChange={this.ClearMoveCustomColor}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleClickDisplayMoveColorPicker}
                                                            style={{
                                                                color: this.state.MoveCustomColor !== 'transparent' ? this.state.MoveCustomColor : "",
                                                            }}>
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                        {
                                                            this.state.displayMoveColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                    <SketchPicker color={this.state.MoveCustomColor} onChange={this.handleMoveColorChange} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                : ""
                                    }
                                    {
                                        typeId !== 16 && typeId !== 19 ?
                                            <>
                                                <div className="widget-header p-1 w-100 mb-3"></div>
                                                <div className="title w-100 text-center">
                                                    {t('Pages.Lessons.Questions.forms.tools.objects.DropObject')}
                                                </div>
                                                <div className={`form-group mb-3`}>
                                                    <label className="text-dark h6"><i className="icon fas fa-box-ballot"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ObjectCategory')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.setGameObjectCategoryDrop(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.ObjectCategoryDrop ? this.state.ObjectCategoryDrop : 'none'}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                            {this.renderObjectCategory()}
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-box-ballot"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={`form-group mb-3 `}>
                                                    <label className="text-dark h6"><i className="icon fas fa-ufo"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObject')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.setGameDropObject(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.DropObject ? this.state.DropObject : 'none'}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                            {this.renderObjectTypeTwo()}
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-ufo"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.DropObject === "custom" ?
                                                        <>
                                                            <div className="form-group mb-3 ">
                                                                <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                        value={this.state.DropObjectSrc ? this.state.DropObjectSrc : ''}
                                                                        onChange={e => this.props.setGameDropObjectSrc(e.target.value)}
                                                                    />
                                                                    <button type="button" >
                                                                        <i className="fas fa-image"  ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 ">
                                                                <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                        value={this.state.DropObjectSrcHover ? this.state.DropObjectSrcHover : ''}
                                                                        onChange={e => this.props.setGameDropObjectSrcHover(e.target.value)}
                                                                    />
                                                                    <button type="button" >
                                                                        <i className="fas fa-image"  ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="widget-header p-1 w-100 mb-3"></div>
                                                        </>
                                                        :
                                                        this.state.DropObjectColor ?
                                                            <div className="form-group mb-3 w-100">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        value={this.state.DropCustomColor}
                                                                        onChange={this.ClearDropCustomColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplayDropColorPicker}
                                                                        style={{
                                                                            color: this.state.DropCustomColor !== 'transparent' ? this.state.DropCustomColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displayDropColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                                <SketchPicker color={this.state.DropCustomColor} onChange={this.handleDropColorChange} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            : ""
                                                }
                                                {
                                                    typeId === 20 ?
                                                        <>
                                                            <div className="form-group my-3">
                                                                <label className="text-dark h6">
                                                                    <i className="icon fa-regular fa-left-right"></i>
                                                                    {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.position.x')}
                                                                </label>
                                                                <div className="search-form">
                                                                    <select
                                                                        onChange={e => this.props.setTransformOriginX(e.target.value)}
                                                                        className="w-100"
                                                                        value={this.state.TransformOriginX}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.position.x')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.position.x')}>
                                                                        <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                                        <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                                                        <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>
                                                                    </select>
                                                                    <button type="button" >
                                                                        <i className="fa-regular fa-left-right"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="form-group my-3">
                                                                <label className="text-dark h6">
                                                                    <i className="icon fa-sharp fa-solid fa-up-down"></i>
                                                                    {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.position.y')}
                                                                </label>
                                                                <div className="search-form">
                                                                    <select
                                                                        onChange={e => this.props.setTransformOriginY(e.target.value)}
                                                                        className="w-100"
                                                                        value={this.state.TransformOriginY}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.position.y')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.position.y')}>
                                                                        <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                                        <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                                                        <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                                                    </select>
                                                                    <button type="button" >
                                                                        <i className="fa-sharp fa-solid fa-up-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                            </>
                                            : ""
                                    }
                                    <div className="widget-header p-1 w-100 mb-3"></div>
                                    {
                                        typeId === 21 || typeId === 26 | typeId === 31 ?
                                            <>
                                                <div className="form-group mb-3">
                                                    <label className="text-dark h6"><i className="fal fa-sync-alt"></i> {t('Pages.Lessons.Questions.forms.tools.move.Rotate')}</label>
                                                    <div className="search-form">

                                                        <select
                                                            onChange={e => this.props.setGameMoveObjectRotate(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.Rotate}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}>
                                                            <option value="0">{t('Pages.Lessons.Questions.forms.tools.move.no_rotate')}</option>
                                                            <option value="90">{t('Pages.Lessons.Questions.forms.tools.move.horizantal_rotate')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-sync-alt"  ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label className="text-dark h6"><i className="fal fa-retweet"></i> {t('Pages.Lessons.Questions.forms.tools.move.flipX')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setGameMoveObjectFlipX(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.FlipX}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.flipX')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.flipX')}>
                                                            <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_flipX')}</option>
                                                            <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.flipX')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fal fa-retweet"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-stars"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TryCount')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TryCount')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.TryCount')}
                                                value={this.state.TryCount ? this.state.TryCount : ''}
                                                onChange={e => this.props.setGameTryCount(e.target.value)}
                                            />
                                            <button type="button" >
                                                <i className="fas fa-stars"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-stopwatch"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Time')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.Time')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.Time')}
                                                value={this.state.Time ? this.state.Time : ''}
                                                onChange={e => this.props.setGameTime(e.target.value)}
                                            />
                                            <button type="button" >
                                                <i className="fas fa-stopwatch"  ></i>
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        (
                                            typeId !== 17 &&
                                            typeId !== 18 &&
                                            typeId !== 21 &&
                                            typeId !== 24 &&
                                            typeId !== 25 &&
                                            typeId !== 26 &&
                                            typeId !== 28 &&
                                            typeId !== 30 &&
                                            typeId !== 31
                                        ) ?
                                            <>
                                                <div className={`form-group mb-3`}>
                                                    <label className="text-dark h6"><i className="icon fas fa-mouse"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Cursor')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setCursor(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.Cursor ? this.state.Cursor : 'none'}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                            {this.renderCursor()}
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-mouse"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                            value={this.state.CursorCustom ? this.state.CursorCustom : ''}
                                                            onChange={e => this.props.setCursorCustom(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-shapes"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TryShape')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TryShape')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.TryShape')}
                                                value={this.state.TryShape ? this.state.TryShape : ''}
                                                onChange={e => this.props.setGameTryShape(e.target.value)}
                                            />
                                            <button type="button" className={`text-${this.state.TryColor}`}  >
                                                <i className={`${this.state.IconType} fa-${this.state.TryShape ? this.state.TryShape : 'shapes'} `}  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameIconType(e.target.value)}
                                                className="w-100"
                                                value={this.state.IconType}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconType.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}>
                                                <option value="fa-duotone">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fad')}</option>
                                                <option value="fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fas')}</option>
                                                <option value="fa-regular">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.far')}</option>
                                                <option value="fa-thin">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fal')}</option>
                                                <option value="fa-light">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.light')}</option>
                                                <option value="fa-sharp fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.sharp')}</option>
                                                <option value="fab">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fab')}</option>
                                            </select>
                                            <button type="button" className={`text-${this.state.TryColor}`}>
                                                <i className={` ${this.state.IconType} fa-${this.state.TryShape ? this.state.TryShape : 'shapes'} `}></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameTryColor(e.target.value)}
                                                className="w-100"
                                                value={this.state.TryColor ? this.state.TryColor : ''}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}>
                                                <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>
                                            </select>
                                            <button type="button" className={`text-${this.state.TryColor}`} >
                                                <i className="fas fa-tint"></i>
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        this.state.TryColor === "custom" ?
                                            <div className="form-group ">
                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                        value={this.state.TryColorCustom}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={this.handleClickDisplayTryColorCustomPicker}
                                                        style={{
                                                            color: this.state.TryColorCustom !== 'transparent' ? this.state.TryColorCustom : "",
                                                        }}>
                                                        <i className="fas fa-tint"></i>
                                                    </button>
                                                    {
                                                        this.state.displayTryColorCustomPicker ?
                                                            <div style={styles.popover}>
                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                <SketchPicker color={this.state.TryColorCustom} onChange={this.handleTryColorCustomColorChange} />
                                                            </div> : null
                                                    }
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        typeId === 21 || typeId === 26 || typeId === 31 ?
                                            <>
                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-arrow-to-top"></i> {t('Pages.Lessons.Questions.forms.tools.objects.MaxTop')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.MaxTop')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.MaxTop')}
                                                            value={this.state.MinTop ? this.state.MinTop : ''}
                                                            onChange={e => this.props.setGameMinTop(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-arrow-to-top"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-arrow-to-bottom"></i> {t('Pages.Lessons.Questions.forms.tools.objects.MinTop')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.MinTop')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.MinTop')}
                                                            value={this.state.MaxTop ? this.state.MaxTop : ''}
                                                            onChange={e => this.props.setGameMaxTop(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-arrow-to-bottom"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </> : ""
                                    }
                                    {
                                        typeId === 18 || typeId === 21 || typeId === 25 || typeId === 26 || typeId === 28 ?
                                            <>
                                                <div className="form-group ">
                                                    <label className="text-dark h6">
                                                        <i className="icon fas fa-ufo"></i>
                                                        {t('Pages.Lessons.Questions.forms.tools.ShottingType.title')}
                                                    </label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setGameExplosionObject(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.ExplosionObject}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.ShottingType.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.ShottingType.title')}>
                                                            {this.renderShottingObject()}
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-ufo"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}
                                                            value={this.state.ShootObjectSrc ? this.state.ShootObjectSrc : ''}
                                                            onChange={e => this.props.setGameShootObjectSrc(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                            value={this.state.ShootObjectSound ? this.state.ShootObjectSound : ''}
                                                            onChange={e => this.props.setShootObjectSound(e.target.value)}
                                                        />
                                                        <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playShootObjectSound()}>
                                                            <i className="fas fa-volume"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        typeId === 22 || typeId === 23 ?
                                            <>
                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TranslateX')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TranslateX')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.TranslateX')}
                                                            value={this.state.TranslateX ? this.state.TranslateX : ''}
                                                            onChange={e => this.props.setGameTranslateX(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-arrows-h"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-stopwatch"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TranslateTime')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TranslateTime')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.TranslateTime')}
                                                            value={this.state.TranslateTime ? this.state.TranslateTime : ''}
                                                            onChange={e => this.props.setGameTranslateTime(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-stopwatch"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}
                                                            value={this.state.ShootObjectSrc ? this.state.ShootObjectSrc : ''}
                                                            onChange={e => this.props.setGameShootObjectSrc(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-cogs"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectOption.title')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setGameShootObjectOption(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.ShootObjectOption ? this.state.ShootObjectOption : ''}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectOption.title')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectOption.title')}>
                                                            <option value="default">{t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectOption.default')}</option>
                                                            <option value="onaction">{t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectOption.onaction')}</option>
                                                        </select>
                                                        <button type="button">
                                                            <i className="fas fa-cogs"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        typeId === 24 ||
                                            typeId === 30 ?
                                            <>

                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.RopeColor')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.RopeColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                            value={this.state.RopeColor}
                                                            readOnly={true}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleClickDisplayRopeColorPicker}
                                                            style={{
                                                                color: this.state.RopeColor !== 'transparent' ? this.state.RopeColor : "",
                                                            }}>
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                        {
                                                            this.state.displayRopeColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                    <SketchPicker color={this.state.RopeColor} onChange={this.handleRopeCustomColorChange} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group  ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrc')}
                                                            value={this.state.ShootObjectSrc ? this.state.ShootObjectSrc : ''}
                                                            onChange={e => this.props.setGameShootObjectSrc(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group  ">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrcHover')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrcHover')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ShootObjectSrcHover')}
                                                            value={this.state.ShootObjectSrcHover ? this.state.ShootObjectSrcHover : ''}
                                                            onChange={e => this.props.setGameShootObjectSrcHover(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>


                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.objects.OffsetTop')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.OffsetTop')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.OffsetTop')}
                                                            value={this.state.OffsetTop ? this.state.OffsetTop : ''}
                                                            onChange={e => this.props.setGameOffsetTop(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-arrows-v"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-retweet"></i> {t('Pages.Lessons.Questions.forms.tools.move.flipY')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setGameMoveObjectFlipY(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.FlipY}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.flipY')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.flipY')}>
                                                            <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_flipY')}</option>
                                                            <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.flipY')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fal fa-retweet"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-stopwatch"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TranslateTime')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TranslateTime')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.TranslateTime')}
                                                            value={this.state.TranslateTime ? this.state.TranslateTime : ''}
                                                            onChange={e => this.props.setGameTranslateTime(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-stopwatch"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.objects.OffsetTopObject')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.OffsetTopObject')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.OffsetTopObject')}
                                                            value={this.state.OffsetTopObject ? this.state.OffsetTopObject : ''}
                                                            onChange={e => this.props.setGameOffsetTopObject(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-arrows-v"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </> : ""
                                    }

                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(GameSetting);
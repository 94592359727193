import React from "react";
import { withTranslation } from 'react-i18next'
import { CountdownCircleTimer } from "react-countdown-circle-timer";

class CountdownCircle extends React.Component {

    constructor(props) {
        super(props);
        this.isNewTimeFirstTick = new React.createRef(false);
        this.currentTime = new React.createRef(false);
        this.prevTime = new React.createRef();
        this.audio = new Audio();
    }

    componentWillUnmount() {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        }
    }

    formatRemainingTime = time => {
        let minutes = Math.floor((time % 3600) / 60);
        const hour = Math.floor(time / 3600);
        if (hour > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${hour}:${minutes}`;
        }
        let seconds = time % 60;
        if (minutes === 0) {
            return seconds;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        return `${minutes}:${seconds}`;
    }

    renderTime = (remainingTime) => {
        if (remainingTime === 0) {
            const { t } = this.props;
            if (this.audio && !this.audio.paused) {
                this.audio.pause();
            }
            return <div className="timer" title={t('Pages.Exams.endTime')}> <i className="fal fa-hourglass-end"></i></div>;
        }

        if (this.currentTime.current !== remainingTime && remainingTime < 60) {
            if (this.audio && this.audio.paused && remainingTime !== 0) {
                this.audio = new Audio(process.env.PUBLIC_URL + "/assets/sounds/exam_sounds/tick_tock.wav");
                this.audio.loop = true;
                this.audio.play();
            }
            this.isNewTimeFirstTick.current = true;
            this.prevTime.current = this.currentTime.current;
            this.currentTime.current = remainingTime;
        } else {
            this.isNewTimeFirstTick.current = false;
        }

        const isTimeUp = this.isNewTimeFirstTick.current;

        return (
            <div className="time-wrapper">
                <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>{this.formatRemainingTime(remainingTime)}</div>
                {this.prevTime.current !== null && (
                    <div
                        key={this.prevTime.current}
                        className={`time ${!isTimeUp ? "down" : ""}`}  >
                        {this.formatRemainingTime(this.prevTime.current)}
                    </div>
                )}
            </div>
        );
    }

    toHex = color => {
        if (color) {
            const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
            const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
            return hex;
        }
        return "";
    }

    render() {
        const { t } = this.props;
        const color = this.toHex(this.props.TimeBorderColor);
        return (
            <>
                <div className="time-container" title={t('Pages.Exams.remainingTime')} style={{
                    background: `${this.props.BackgroundBorderColor ? this.props.BackgroundBorderColor : "#fff"}`
                }}>

                    <CountdownCircleTimer
                        isPlaying
                        duration={this.props.duration}
                        strokeWidth={this.props.strokeWidth ? this.props.strokeWidth : 6}
                        size={this.props.size ? this.props.size : 70}
                        colors={color ? color : "#92278f"}
                        onComplete={() => [false, 1000]}  >
                        {({ remainingTime, elapsedTime }) =>
                            this.renderTime(remainingTime)
                        }
                    </CountdownCircleTimer>
                </div>
            </>
        );
    }
}

export default withTranslation('common')(CountdownCircle);
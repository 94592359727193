
import React from "react";
import ReactHtml from 'raw-html-react';

import MoveObject from "../DragDrop/DragObject";
import roundsData from "../../../../Config/rounds.json";
import PopUps from "../PopUps";
import DropableGameFiled from '../../../Question/View/FillFiled/DropableGameFiled';
import GameObjects from "../../../Question/View/GameObjects";

class FillGame extends React.Component {

    constructor(props) {
        super(props);
        this.selectedCount = 0;
        this.selectableCount = 0;
        this.stageCount = 0;
        this.randomKey = Math.floor(Math.random() * 100000);
        this.state = {
            userAnswers: [],
            showItems: [],
            randomKey: this.randomKey,
            currentStageIndex: 0,
            currentCategory: {},
            isStop: false,
            removeState: false,
            position: {
                x: 0,
                y: 0
            },
            BounsTry: 0,
            BounsTime: 0,
            left: 0,
            top: 0,
            showBounsTime: false,
            showBounsTry: false,
            stageName: {}
        };
        this.Rounds = roundsData.Rounds;
        this.audio = new Audio();
        this.centerObject = React.createRef();
        this.randomList = [];
    }

    componentWillUnmount() {
        this.randomKey = Math.floor(Math.random() * 100000);
    }

    componentDidMount() {
        this.stageCount = this.props.Question.Categories.length;
        this.nextStage();
    }

    playMoveSound = MoveSound => {
        if (MoveSound) {
            if (this.audio && !this.audio.paused === false) {
                this.audio.pause();
            }
            this.audio = new Audio(MoveSound);
            this.audio.play();
        }
    }

    nextStage = () => {
        if (this.props.Question.Categories) {
            var currentCategory = this.props.Question.Categories.sort((a, b) => a.Sort - b.Sort)[this.state.currentStageIndex];
            if (currentCategory) {
                if (this.state.currentStageIndex > 0) {
                    this.props.playStageSound();
                }
                this.setState({
                    top: 0,
                    left: 0,
                    removeState: true,
                    currentCategory,
                    currentStageIndex: this.state.currentStageIndex + 1,
                    stageName: this.Rounds[this.stageCount === (this.state.currentStageIndex + 1) ? 0 : (this.state.currentStageIndex + 1)],
                    showItems: this.setItemToShow(currentCategory.Id)
                });
                setTimeout(() => {
                    this.setState({
                        removeState: false
                    });
                }, 3000);
            } else if (this.randomKey === this.state.randomKey) {
                this.props.showEndGame();
            }
        }
    };

    checkIsTrueAnswer = clickedItem => {
        const hasAnswer = parseInt(clickedItem.AnswersSelectType);
        if (hasAnswer) {
            if (parseInt(clickedItem.BounsTry) > 0 || parseInt(clickedItem.BounsTime) > 0) {
                if (parseInt(clickedItem.BounsTry) > 0) {
                    this.props.playBounsSound();
                }
                if (parseInt(clickedItem.BounsTime) > 0) {
                    this.props.playBounsTimeSound();
                }
            } else {
                this.props.playTrueTrySound();
            }
            return true;
        }
        this.props.playFalseTrySound();
        return false;
    }

    handelDropOnObject = (item, Sort) => {
        return this.props.Question.Matches.some(p => p.Target === item.Id) && !this.props.Question.Matches.some(p => p.Target === item.Id && p.Source === Sort);
    };

    setItemToShow = currentId => {
        const { Question } = this.props;

        const answers = Question.Answers.filter(p => Question.Fills.some(f => f.CategoryId === currentId && f.AnswerId === p.Id)).map(answer => {
            return answer;
        });

        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerStyle.BorderRadiusTop}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusRight}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusBottom}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusLeft}${Question.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerStyle.ShadowPositionX}px ${Question.CustomStyle.answerStyle.ShadowPositionY}px ${Question.CustomStyle.answerStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerStyle.BgAttachment}`,
            zIndex: 1
        };

        answers.forEach(item => {
            const hasFill = Question.Matches.some(p => p.Target === item.Id);
            if (hasFill) {
                this.selectableCount++;
            }
        });

        const count = answers.length;
        let fromRight = false;
        this.randomList = [];
        for (let index = 0; index < count; index++) {
            this.randomList.push(this.getRandomNumber());
        }

        let items = [];
        for (let index = 0; index < count; index++) {
            const answer = answers[index];
            const CustomStyle = answer.CustomStyle;
            let isAdded = false;
            if (CustomStyle && CustomStyle.Answers && CustomStyle.Movments) {
                const param = CustomStyle.Answers.filter(p => p.Id === answer.Id)[0];
                const Movments = CustomStyle.Movments.filter(p => p.Id === answer.Id);
                if (param) {
                    let customColor = param.CustomColor;
                    let objectCss = param.AnswersSelectObject;
                    let ObjectSrc = param.AnswersSelectObjectSrc;
                    let ObjectHoverSrc = param.AnswersSelectObjectSrcHover;
                    if (objectCss === "") {
                        objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                        ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                        ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                        if (!customColor) {
                            customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                        }
                    }

                    objectCss = this.props.getRandomObject(objectCss);

                    fromRight = !fromRight;
                    var ref = React.createRef();
                    const top = this.randomList[index];

                    items.push({
                        Id: answer.Id,
                        param,
                        jsx: <MoveObject
                            getRandomNumber={this.props.getRandomNumber}
                            count={count}
                            playMoveSound={this.playMoveSound}
                            designerContainer={this.props.designerContainer}
                            item={answer}
                            top={top}
                            fromRight={fromRight}
                            ref={ref}
                            id={answer.Id}
                            key={`objectId-${answer.Id}`}
                            param={answer}
                            rotate={param.rotate}
                            delay={param.delay}
                            isSpin={param.isSpin}
                            flipX={param.flipX}
                            scale={param.scale}
                            index={param.Sort}
                            transformOriginX={param.transformOriginX}
                            transformOriginY={param.transformOriginY}
                            randomKey={this.randomKey}
                            style={styleAnswer}
                            ObjectSrc={ObjectSrc}
                            customColor={customColor}
                            ObjectHoverSrc={ObjectHoverSrc}
                            MoveType={param.AnswersMoveType}
                            Movments={Movments}
                            Time={param.AnswersTime}
                            objectCss={objectCss}
                            handelDropOnObject={this.handelDropOnObject}
                            checkIsTrueAnswer={this.checkIsTrueAnswer}
                            text={answer.Name} />
                    });
                    isAdded = true;
                }
            }

            if (!isAdded) {
                let objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                const ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                const ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                const customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                if (objectCss === "RandomFruit") {
                    const itemsObject = this.Objects.filter(p => p.type === 1 && p.category === 3);
                    const itemObject = itemsObject[Math.floor(Math.random() * itemsObject.length)];
                    objectCss = itemObject.value;
                } else if (objectCss === "RandomFish") {
                    const itemsObject = this.Objects.filter(p => p.type === 1 && p.category === 6);
                    const itemObject = itemsObject[Math.floor(Math.random() * itemsObject.length)];
                    objectCss = itemObject.value;
                }

                const param = {
                    ...answer,
                    BounsTry: 0,
                    BounsTime: 0,
                    AnswersSelectType: "0"
                };
                items.push({
                    Id: answer.Id,
                    param,
                    jsx: <MoveObject
                        getRandomNumber={this.props.getRandomNumber}
                        count={count}
                        playMoveSound={this.playMoveSound}
                        scale={this.props.scale}
                        designerContainer={this.props.designerContainer}
                        item={answer}
                        id={answer.Id}
                        key={`objectId-${answer.Id}`}
                        param={answer}
                        index={0}
                        style={styleAnswer}
                        ObjectSrc={ObjectSrc}
                        customColor={customColor}
                        ObjectHoverSrc={ObjectHoverSrc}
                        randomKey={this.randomKey}
                        MoveType={""}
                        Movments={[]}
                        Time={3}
                        objectCss={objectCss}
                        handelDropOnObject={this.handelDropOnObject}
                        checkIsTrueAnswer={this.checkIsTrueAnswer}
                        text={answer.Name} />
                });
            }
        }
        return items;
    };

    getRandomNumber = (depth = 0) => {
        let number = Math.floor(Math.random() * 40);
        if (depth === 10) {
            return number;
        }
        var inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * 40);
            inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (this.randomList.length > 0) {
            const lastItem = this.randomList[this.randomList.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (this.randomList.length > 1) {
                const beforeItem = this.randomList[this.randomList.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
            if (number > 40) {
                number = this.getRandomNumber(depth + 1);
            }
        }
        return number;
    }

    renderDropableFiled(item, style, hoverStyle) {
        if (item && item.Name) {
            const count = item.Name.split("(_)").length - 1;
            var html = item.Name;
            for (let index = 0; index < count; index++) {
                html = html.replace("(_)", `<span version='${this.props.version}' Sort='${index + 1}' data-react-component="DropableGameFiled"></span>`);
            }
            return <ReactHtml
                html={html}
                style={style}
                answers={this.state.answers}
                componentMap={{ DropableGameFiled }}
                hoverStyle={hoverStyle}
                CategoryId={item.Id}
                QuestionId={this.props.Question.Id}
                setDropTargetAnswer={this.setDropTargetAnswer}
            />;
        }
    }

    removeBounsMove = () => {
        setTimeout(() => {
            this.setState({
                BounsTry: 0,
                BounsTime: 0,
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
        }, 4 * 1000);
    }

    setDropTargetAnswer = (item, Sort) => {
        this.setState({
            showBounsTry: false,
            showBounsTime: false,
            BounsTry: 0,
            BounsTime: 0
        });

        let isTrue = this.props.Question.Matches.some(p => p.Source === Sort && p.Target === item.Id);

        if (this.props.isMission === true) {
            let userAnswers = this.state.userAnswers.slice();
            userAnswers.push({
                Answer: item,
                Sort,
                isTrue
            });
            this.setState({
                userAnswers
            });
            this.props.saveAnswer(userAnswers);
        }

        if (isTrue) {
            let answersList = this.state.showItems;
            const index = answersList.findIndex(a => a.Id === item.Id);
            if (index !== -1) {
                const currentAnswer = answersList[index];
                if (currentAnswer.param && parseInt(currentAnswer.param.BounsTry) > 0 && parseInt(currentAnswer.param.BounsTime) > 0) {
                    this.setState({
                        showBounsTime: true,
                        showBounsTry: true,
                        BounsTry: currentAnswer.param.BounsTry,
                        BounsTime: currentAnswer.param.BounsTime,
                    });
                    this.props.addBounsTimeAndTry(parseInt(currentAnswer.param.BounsTime), parseInt(currentAnswer.param.BounsTry), true);
                    this.removeBounsMove();
                } else if (currentAnswer.param && parseInt(currentAnswer.param.BounsTry) > 0) {
                    this.setState({
                        showBounsTry: true,
                        BounsTry: currentAnswer.param.BounsTry,
                    });
                    this.props.addBounsTry(parseInt(currentAnswer.param.BounsTry), true);
                    this.removeBounsMove();
                } else if (currentAnswer.param && parseInt(currentAnswer.param.BounsTime) > 0) {
                    this.setState({
                        showBounsTime: true,
                        BounsTime: currentAnswer.param.BounsTime,
                    });
                    this.props.addBounsTime(parseInt(currentAnswer.param.BounsTime), true);
                    this.removeBounsMove();
                } else {
                    this.props.addTrueTry(true);
                }
                this.selectedCount++;
                this.setState({
                    currentStatus: "True"
                });
            }
        } else {
            this.setState({
                currentStatus: "Wrong"
            });
            this.props.addFalseTry(true);
        }

        if (this.selectedCount === this.selectableCount) {
            this.setState({
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
            this.selectedCount = 0;
            this.selectableCount = 0;
            this.nextStage();
        }
        return isTrue;
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { Question } = this.props;

        const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
        const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";
        const AnswerOrder = Question.CustomStyle.answerBoxStyle ? Question.CustomStyle.answerBoxStyle.AnswerOrder : "";
        const AnswersColsMargin = Question.CustomStyle.answerBoxStyle ? Question.CustomStyle.answerBoxStyle.AnswersColsMargin : "";

        const DropObject = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObject : "";
        const DropObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrc : "";
        const DropObjectSrcHover = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const DropCustomColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropCustomColor : "";

        const TransformOriginY = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TransformOriginY : "center";
        const TransformOriginX = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TransformOriginX : "center";

        var poistionY = "align-items-center";
        if (TransformOriginY === "top") {
            poistionY = "align-items-start";
        } else if (TransformOriginY === "bottom") {
            poistionY = "align-items-end";
        }

        var poistionX = "justify-content-around";
        if (TransformOriginX === "left") {
            poistionX = "justify-content-start";
        } else if (TransformOriginX === "right") {
            poistionX = "justify-content-end";
        }

        const styleAnswer = {
            minWidth: `${Question.CustomStyle.answerBoxStyle.width}%`,
            maxWidth: '100%',
            minHeight: `${Question.CustomStyle.answerBoxStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerBoxStyle.BorderRadiusTop}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusRight}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusBottom}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusLeft}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerBoxStyle.ShadowPositionX}px ${Question.CustomStyle.answerBoxStyle.ShadowPositionY}px ${Question.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerBoxStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerBoxStyle.BgAttachment}`,
            marginTop: AnswerOrder === "top" ? '85px' : 'unset',
            lineHeight: 1.5
        };

        let hoverStyle = {
            maxWidth: '100%',
            minWidth: '100px',
            paddingLeft: `${Question.CustomStyle.categoryHoverStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.categoryHoverStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.categoryHoverStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.categoryHoverStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.categoryHoverStyle.BorderColor !== 'transparent' ? Question.CustomStyle.categoryHoverStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.categoryHoverStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.categoryHoverStyle.BorderRadiusTop}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryHoverStyle.BorderRadiusRight}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryHoverStyle.BorderRadiusBottom}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryHoverStyle.BorderRadiusLeft}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.categoryHoverStyle.ShadowPositionX}px ${Question.CustomStyle.categoryHoverStyle.ShadowPositionY}px ${Question.CustomStyle.categoryHoverStyle.ShadowRadius}px 0px ${Question.CustomStyle.categoryHoverStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.categoryHoverStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.categoryHoverStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.categoryHoverStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.categoryHoverStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.categoryHoverStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.categoryHoverStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.categoryHoverStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.categoryHoverStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.categoryHoverStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.categoryHoverStyle.BgAttachment}`,
        };

        let style = {
            maxWidth: '100%',
            minWidth: '100px',
            paddingLeft: `${Question.CustomStyle.categoryListStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.categoryListStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.categoryListStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.categoryListStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.categoryListStyle.BorderColor !== 'transparent' ? Question.CustomStyle.categoryListStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.categoryListStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.categoryListStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.categoryListStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.categoryListStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.categoryListStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.categoryListStyle.BorderRadiusTop}${Question.CustomStyle.categoryListStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryListStyle.BorderRadiusRight}${Question.CustomStyle.categoryListStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryListStyle.BorderRadiusBottom}${Question.CustomStyle.categoryListStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryListStyle.BorderRadiusLeft}${Question.CustomStyle.categoryListStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.categoryListStyle.ShadowPositionX}px ${Question.CustomStyle.categoryListStyle.ShadowPositionY}px ${Question.CustomStyle.categoryListStyle.ShadowRadius}px 0px ${Question.CustomStyle.categoryListStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.categoryListStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.categoryListStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.categoryListStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.categoryListStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.categoryListStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.categoryListStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.categoryListStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.categoryListStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.categoryListStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.categoryListStyle.BgAttachment}`,
        };

        let objectWidth = 0;
        let position = {
            x: 0,
            y: 0
        };
        if (this.centerObject && this.centerObject.current) {
            const centerObject = this.centerObject.current.getBoundingClientRect();
            objectWidth = (centerObject.width / 2);
            position.x = centerObject.x + objectWidth;
            position.y = centerObject.y;
        }

        return <div className="w-100 h-100 position-absolute oh"  >
            <style dangerouslySetInnerHTML={{
                __html: `.dragdrop-move-box{${Question.CustomStyle.answerStyle.Custom} }.dragdrop-static-box{${Question.CustomStyle.answerBoxStyle.Custom} }`
            }}></style>
            <PopUps
                removeState={this.state.removeState}
                showBounsTry={this.state.showBounsTry}
                showBounsTime={this.state.showBounsTime}
                bounsTry={this.state.BounsTry}
                bounsTime={this.state.BounsTime}
                stageCount={this.stageCount}
                TryColorCustom={TryColorCustom}
                TryColor={TryColor}
                label={this.state.stageName.label}
                position={position}
            />
            {
                this.state.showItems ?
                    this.state.showItems.map((item, key) => {
                        return <span key={key} >{item.jsx} </span>
                    })
                    : ""
            }

            <div className={` h-100 w-100 position-relative d-flex ${poistionX} ${poistionY} p-3`} style={{
                zIndex: '15',
            }}>
                <div ref={this.centerObject}>
                    <GameObjects
                        FinishMove={this.FinishMove}
                        status={this.state.currentStatus}
                        objectCss={DropObject}
                        ObjectSrc={DropObjectSrc}
                        ObjectHoverSrc={DropObjectSrcHover}
                        customColor={DropCustomColor}
                        style={{}}
                        text={""}
                        type="dragdrop-static"
                    />
                </div>
            </div>
            <div className={`position-absolute w-100 d-flex justify-content-center  mb-${AnswersColsMargin}`}
                style={{
                    zIndex: '15',
                    top: AnswerOrder === "top" ? '0' : 'unset',
                    bottom: AnswerOrder !== "top" ? '0' : 'unset'
                }}>
                <div className={` d-flex justify-content-center align-items-center flex-wrap mx-3 `} style={styleAnswer}>
                    {this.renderDropableFiled(this.state.currentCategory, style, hoverStyle)}
                </div>
            </div>
        </div>;

    }
}

export default FillGame;
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ScrollArea from "react-scrollbar";

import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import {
    fetchMissions as fetch,
    resetMissions
} from './actions';
import ListItem from "../Missions/ListItem";
import TugOfWar from "../../Admin/Question/View/Background/TugOfWar";

class Missions extends React.Component {

    state = {
        Active: true,
        search: '',
        isFetching: false,
        isClicked: false,
        page: 0,
        startDate: moment().add(-15, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        date: moment().add(-15, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY')
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.fetchMoreContainer = React.createRef();
        this.scrollArea = React.createRef();
        this.props.resetMissions();
        setTimeout(() => {
            this.Sync();
        }, 50);
    }

    componentDidUpdate() {
        window.scrollToPosition("main-mission-section");
    }

    setSearch = search => {
        this.setState({
            search
        });
    }

    handleScroll = () => {
        var react = this.fetchMoreContainer;
        if (react && react.current) {
            let rect = react.current.getBoundingClientRect();
            if (rect) {
                if (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    (rect.bottom - rect.height) <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) && this.state.isFetching
                ) {
                    this.setState({
                        isFetching: false
                    });
                    this.Sync();
                }
            }
        }
    }

    Sync = () => {
        let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
        this.props.fetch(this.state.startDate, this.state.endDate, searchText, this.state.Active, this.state.page);
        this.setState({
            page: this.state.page + 1,
            isClicked: true,
            isFetching: true
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    setActive = Active => {
        this.setState({
            Active
        });
    }

    Reset = () => {
        this.props.resetMissions();
        this.setState({
            page: 0
        });
        setTimeout(() => {
            this.Sync();
        }, 50);
    }

    renderMissions() {
        const { t, missions, user } = this.props;

        if (missions && missions.missions && missions.missions.length > 0) {
            let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterMission = missions.missions.filter(v => v.Lesson.SearchName.includes(searchText));

            if (filterMission.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Missions.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Missions.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }
            return filterMission.sort((a, b) => a.CreationDate < b.CreationDate ? 1 : -1).map(mission =>
                <ListItem
                    isTeacher={false}
                    Sync={this.Sync}
                    user={user}
                    item={mission}
                    Lesson={mission.Lesson}
                    remove={this.showDelete}
                    key={`mission-${mission.Id}`} />
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Missions.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    scrollDown = () => {
        this.scrollArea.current.scrollArea.scrollBottom();
        this.Sync();
    }

    render() {
        const { t, user, missions } = this.props;

        let isLoaded = false;
        let lazyLoading = true;
        if (missions) {
            isLoaded = missions.isLoaded;
            lazyLoading = missions.lazyLoading;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <Helmet>
                    <title>
                        {t('PageHeader.MyMissions')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="position-relative" id="main-mission-section" style={{
                    minHeight: '100vh',
                    backgroundColor: '#74caee'
                }}>
                    <TugOfWar />
                    <div className="container padding-top padding-bottom ">
                        <div className="row ">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="section-header mb-4">
                                        <div className="display-4 h3 custome-title" style={{
                                            color: '#ef669d'
                                        }}><i className="mx-3 fal fa-bullseye-arrow "></i> {t('PageHeader.MyMissions')} </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div className="py-2 flex-grow-1 ">
                                                <form className="search-form d-flex w-100">

                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>

                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Missions.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>

                                            <div className="search-form d-flex align-items-center w-100">
                                                <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-danger">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setActive(e.target.checked)}
                                                        ref={Active => this.Active = Active}
                                                        defaultChecked={this.state.Active}
                                                        name="Active"
                                                        id="Active" />
                                                    <label className="custom-control-label h6" style={{
                                                        whiteSpace: 'pre'
                                                    }} htmlFor="Active" >
                                                        {t(`Pages.Missions.ActiveMissions`)}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Reset()} >
                                                <span>
                                                    {
                                                        isLoaded ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ScrollArea
                                    horizontal={false}
                                    className={`area w-100 lazyLoading  p-2 `}
                                    smoothScrolling={true}
                                    style={{
                                        height: '100vh',
                                        background: '#fafafa91',
                                        borderRadius: '10px'
                                    }}
                                    ref={this.scrollArea}
                                    contentClassName="content "
                                    onScroll={this.handleScroll} >

                                    {this.renderMissions()}

                                    {
                                        lazyLoading && isLoaded && this.state.isClicked ?
                                            <div className="product-list-item" ref={this.fetchMoreContainer}>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-danger">
                                                        <i className="fad fa-sync-alt fa-spin"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle">
                                                        {t("Pages.Missions.Loading")}
                                                    </h4>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </ScrollArea>

                                {
                                    lazyLoading && isLoaded && this.state.isClicked ?
                                        <div className="row justify-content-md-center" style={{
                                            marginTop: '-50px'
                                        }} >
                                            <div className="d-flex justify-content-center w-100">
                                                <div onClick={() => this.scrollDown()} className="theme-btn btn-style-two d-inline-flex mb-1" style={{
                                                    borderRadius: 'unset',
                                                    borderTopRightRadius: '50px',
                                                    borderTopLeftRadius: '50px'
                                                }}>
                                                    <span className="txt pl-2"> {t('Pages.Lessons.LoadMore')} </span>
                                                    <div className="mouse-btn-down">
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        missions: state.missions
    };
};


export default connect(mapStateToProps, { fetch, resetMissions })(withTranslation('common')(Missions));

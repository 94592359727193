import React from "react";
import Spritesheet from 'react-responsive-spritesheet';
import TextBox from "../../TextBox";

import img from './images/1.png';

const width = 211 / 15;
const height = 100 / 1;

const Thunder = (props) => {

    return <div className="position-relative" >
        <div className={`mb-1 d-inline-block`} >
            <div className={`${props.isSpin === "true" || props.isSpin === true ? "fa-spin" : ""}`} style={{
                transformOrigin: `${props.transformOriginX ? props.transformOriginX : "center"} ${props.transformOriginY ? props.transformOriginY : "center"}`,
                transform: `
                        ${props.scale ? `scale(${props.scale})` : ""}
                        ${props.flipX === "true" || props.flipX === true ? "scaleX(-1)" : ""}
                        ${props.rotate ? `rotate(${props.rotate}deg)` : ""}
                        `
            }}>
                <Spritesheet
                    image={img}
                    widthFrame={width}
                    heightFrame={height}
                    steps={15}
                    startAt={1}
                    endAt={15}
                    fps={15}
                    autoplay={true}
                    loop={true}
                />
            </div>
        </div>
        <TextBox {...props} />
    </div >
}

export default Thunder;
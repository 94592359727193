const TemplatesReducers = (state = [], action) => {
    switch (action.type) {
        case "TEMPLATES_LOADING":
            return {
                templates: state.templates,
                current: null,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "TEMPLATES_INITIAL":
            return {
                templates: action.templates,
                current: null,
                toRemove: [],
                isLoaded: true
            };
        case "TEMPLATE_INITIAL":
            return {
                templates: state.templates,
                current: action.template,
                toRemove: [],
                isLoaded: true
            };
        case "TEMPLATE_QUEUE_FOR_REMOVAL":
            return {
                templates: state.templates,
                current: null,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "TEMPLATE_CLEAN_NODES":
            return {
                templates: state.toRemove.some(v => v === action.id) ? state.templates.filter(v => v.Id !== action.id) : state.templates,
                current: null,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "TEMPLATE_UNDO":
            return {
                templates: state.templates,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default TemplatesReducers;
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import QuestionTitle from '../QuestionTitle/';

class OrderView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        answers: [],
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.answers = props.answers;

        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }

        if (props.onEdit === true) {
            answerList = answerList.sort((a, b) => a.Sort > b.Sort ? 1 : -1);
        }

        state.answers = answerList;

        if (props.examAnswers) {
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answers = hasAnswer.answers;
                if (props.showResult && hasAnswer.answers) {
                    const { t, mark } = props;

                    let trueAnswer = 0;
                    let trueResultCount = 0;

                    let answers = [...hasAnswer.answers];
                    if (props.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal') {
                        answers = answers.reverse();
                    }

                    answers.forEach((answer, index) => {
                        if (answer.Sort === index) {
                            trueAnswer++;
                        }
                        trueResultCount++;
                    });

                    if (trueAnswer === trueResultCount) {
                        state.mark = {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        };
                    } else if (trueAnswer === 0) {
                        state.mark = {
                            type: 'danger',
                            userMark: 0,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else {
                        state.mark = {
                            type: 'warning',
                            userMark: (mark * trueAnswer / trueResultCount).toFixed(2),
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.missed'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    }
                }
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }
        return state;
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        this.ResetAnswer();

        const answers = this.reorder(
            this.state.answers,
            result.source.index,
            result.destination.index
        );

        this.setState({
            answers
        });

        if (this.props.saveExamAnswer) {
            let cloneAnswer = [...answers];
            if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal') {
                cloneAnswer = cloneAnswer.reverse();
            }
            let trueAnswer = 0;
            let falseAnswer = 0;
            let trueResultCount = 0;
            cloneAnswer.forEach((answer, index) => {
                if (answer.Sort === index) {
                    trueAnswer++;
                }
                trueResultCount++;
            });

            let userMark = 0;
            const mark = this.props.mark
            if (trueAnswer === trueResultCount) {
                userMark = mark;
            } else {
                userMark = (mark * trueAnswer / trueResultCount).toFixed(2);
            }

            this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answers, {
                trueResultCount,
                trueAnswer,
                falseAnswer,
                questionMark: this.props.mark
            }, userMark);
        }

        this.props.setSortAnswers(answers);
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: 5,
        position: 'static !important',
        // change background colour if dragging
        //borderBottom: isDragging ? "2px dashed lightgreen" : "2px solid rgb(14, 44, 83)",

        paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
        paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
        paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
        paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

        borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
        borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
        borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
        borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
        borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
        borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
        borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
        boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,

        opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
        backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
        backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
        backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
        backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
        backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
        backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
        backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`
    });

    renderAnswer() {
        if (this.state.answers) {
            return (
                <DragDropContext onDragEnd={this.onDragEnd} className="position-relative align-self-cente" dir="rtl" >
                    <Droppable droppableId="droppable"
                        direction={this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal' ? 'horizontal' : 'vertical'}
                    >
                        {(provided) => (
                            <div
                                className={` position-relative ${this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal' ? 'd-flex justify-content-center align-items-center' : ''}`}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    padding: 10,
                                    height: '100%',
                                    direction: this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal' ? 'ltr' : ''
                                }} >
                                {this.state.answers.map((item, index) => (
                                    <Draggable key={item.Id} draggableId={`${item.Id}`} index={index}  >
                                        {(provided, snapshot) => (
                                            <div className={`d-flex ${this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal' ? '' : 'w-100'}`} style={{ direction: 'rtl' }}>
                                                <div
                                                    className={`SelectDesignerPreview_${item.Id} ${this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal' ? '' : 'w-100'}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )} >
                                                    <style dangerouslySetInnerHTML={{ __html: `.SelectDesignerPreview_${item.Id}{${this.state.CustomStyle.answerStyle.Custom} }` }}></style>
                                                    <div className="">
                                                        <div dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                                                    </div>
                                                </div>
                                                {this.renderCheckAnswerIsTrue(index)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext >
            );
        }
        return "";
    }

    renderCheckAnswerIsTrue(index) {
        const { t } = this.props;
        if (this.state.mark.isSolved) {

            const length = this.state.answers.length - 1;
            let count = index;
            if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal') {
                count = length - index;
            }

            const currentAnswer = this.state.answers[index];
            const trueAnswer = this.state.answers.filter(v => v.Sort === count)[0];
            if (currentAnswer && trueAnswer && currentAnswer.Id === trueAnswer.Id) {
                return <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="icon fad fa-check-circle text-success h6"></i>;
            } else {
                return <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="icon fas fa-exclamation-triangle text-danger h6"></i>
            }

        }
        return "";
    }

    ResetAnswer = () => {
        this.setState({
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });

    }

    CheckAnswer = () => {
        const { t, mark } = this.props;
        let trueAnswer = 0;
        let trueResultCount = 0;

        let answers = this.state.answers;

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'horizontal') {
            answers = answers.reverse();
        }

        answers.forEach((answer, index) => {
            if (answer.Sort === index) {
                trueAnswer++;
            }
            trueResultCount++;
        });

        if (trueAnswer === trueResultCount) {
            this.props.playTrueSound();
            this.props.showConfetti();
            this.setState({
                mark: {
                    type: 'success',
                    userMark: mark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                    title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                }
            });
        } else if (trueAnswer === 0) {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        } else {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'warning',
                    userMark: (mark * trueAnswer / trueResultCount).toFixed(2),
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.missed'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        }
    }

    renderMark() {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        } else if (showResult) {
            return (
                <>
                    {this.renderMark()}
                </>
            );
        }
        return "";
    }

    render() {
        const { onEdit, title, mark, index, Id, isExamQuestion, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'absolute') {
            style = { ...style, height: `${this.state.CustomStyle.questionStyle.height}px` };
        }

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`}>
                <div className="bg-white w-100 p-3 form-group w-100 position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} style={style} >
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`} > </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            {this.renderAnswer()}
                        </div>
                    </div>
                    {isExamQuestion === true ? showResult === true ? this.renderMark() : "" : ""}
                    {isExamQuestion !== true ? this.renderControlButton() : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(OrderView));
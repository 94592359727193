import React from "react";
import { withTranslation } from 'react-i18next'

class InnerAbout extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <div className="about-content">
                <div className="section-header left-style mb-olpo">
                    <span className="cate">{t('About')}</span>
                    <h3 className="title">{t('Pages.AboutUs.MainHead')}</h3>
                    <p className="text-justify mr-0">{t('Pages.AboutUs.Short')}</p>
                </div>
                <div className="faq-wrapper mb--10">
                    <div className="faq-item open">
                        <div className="faq-title">
                            <span className="right-icon"></span><h5 className="title">{t('Pages.AboutUs.Brief')}</h5>
                        </div>
                        <div className="faq-content" style={{ display: 'block' }} >
                            <ul>
                                <li className="text-justify"> <i className="far fa-dot-circle pr-3 dark-blue-text"></i> {t('Pages.AboutUs.BriefPoint.1')}</li>
                                <li className="text-justify"> <i className="far fa-dot-circle pr-3 dark-blue-text"></i> {t('Pages.AboutUs.BriefPoint.2')}</li>
                                <li className="text-justify"> <i className="far fa-dot-circle pr-3 dark-blue-text"></i> {t('Pages.AboutUs.BriefPoint.3')}</li>
                                <li className="text-justify"> <i className="far fa-dot-circle pr-3 dark-blue-text"></i> {t('Pages.AboutUs.BriefPoint.4')}</li>
                                <li className="text-justify"> <i className="far fa-dot-circle pr-3 dark-blue-text"></i> {t('Pages.AboutUs.BriefPoint.5')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="faq-item active">
                        <div className="faq-title">
                            <span className="right-icon"></span><h5 className="title">{t('Pages.AboutUs.teams')}</h5>
                        </div>
                        <div className="faq-content" style={{ display: 'none' }}>
                            <p className=" text-justify">{t('Pages.AboutUs.teamsBody')}</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-title">
                            <span className="right-icon"></span><h5 className="title">{t('Pages.AboutUs.WhyUs')}</h5>
                        </div>
                        <div className="faq-content" style={{ display: 'none' }}>
                            <p className=" text-justify">{t('Pages.AboutUs.WhyUsBody')}</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-title">
                            <span className="right-icon"></span><h5 className="title">{t('Pages.AboutUs.OurSlogan')}</h5>
                        </div>
                        <div className="faq-content" style={{ display: 'none' }}>
                            <p className=" text-justify">{t('Pages.AboutUs.OurSloganBody')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(InnerAbout);
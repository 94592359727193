import React from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";

class ComingSoon extends React.Component {

    state = {
        date: moment().month('July').endOf('month'),
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0
    };

    constructor(props) {
        super(props);
        this.timer = 0;
    }
    componentDidMount() {
        if (this.props.date) {
            this.setState({
                date: this.props.date
            });
        } else {
            let date = moment().month('July').endOf('month');
            if (moment().month('July').endOf('month') < moment()) {
                date = moment().month('August').endOf('month');
            }
            this.setState({
                date
            });
        }

        this.timer = setInterval(() => {
            this.remainTime();
        }, 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    remainTime = () => {
        if (this.state.date) {
            const duration = moment.duration(this.state.date.diff(moment()));
            const days = duration.asDays().toFixed();
            const hours = (duration.asHours() % 24).toFixed();
            const minutes = (duration.asMinutes() % 60).toFixed();
            const seconds = (duration.asSeconds() % 60).toFixed();
            this.setState({
                days,
                minutes,
                hours,
                seconds
            });
        }
    }

    render() {
        const { t } = this.props;
        return (<div className="container">
            <div className="coming-wrapper">
                <ul className="coming-countdown mb-1">

                    <li className="mb-0 px-2 px-lg-4 pt-2">
                        <h4 className="count-title">
                            <span className="seconds">
                                {this.state.seconds}
                            </span>
                        </h4>
                        <p className="seco_text">{t("Pages.ComingSoon.seconds")} </p>
                    </li>

                    <li className="mb-0 px-2 px-lg-4 pt-2">
                        <h4 className="count-title">
                            <span className="minutes">
                                {this.state.minutes}
                            </span>
                        </h4>
                        <p className="minu_text">{t("Pages.ComingSoon.minutes")} </p>
                    </li>

                    <li className="mb-0 px-2 px-lg-4 pt-2">
                        <h4 className="count-title">
                            <span className="hours">
                                {this.state.hours}
                            </span>
                        </h4>
                        <p className="hour_text" style={{
                            color: "#92278f"
                        }}>{t("Pages.ComingSoon.hours")} </p>
                    </li>
                    {
                        this.state.days > 0 ?
                            <li className="mb-0 px-2 px-lg-4 pt-2">
                                <h4 className="count-title">
                                    <span className="days">
                                        {this.state.days}
                                    </span>
                                </h4>
                                <p className="day_text" style={{
                                    color: "#8dc63f"
                                }}>{t("Pages.ComingSoon.days")} </p>
                            </li>
                            : ""
                    }
                </ul>
            </div>
        </div>);
    }
}

export default withTranslation('common')(ComingSoon);
import React from "react";
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import '../../Home/Tracks/css/lesson.css';

import List from "../Question/List";
import LessonInfo from "./Modal/LessonInfo";
import LessonPage from "./Modal/LessonPage";
import history from '../../../history';
import FooterBottom from "../../Footer/FooterBottom";

import Loading from "../../Loading/";
import api from '../../../api/api';
import QuestionSortModal from './Modal/QuestionSortModal';
import View from './View';
import {
    fetchLesson,
    updateLessonQuestion,
    updateLessons,
    removeLessonQuestion,
    undoDeleteLessonQuestion,
    clearLessonQuestion,
    setLessonQuestionsSort,
    createNewLessonQuestion,
    removeLessonPage,
    undoDeleteLessonPage,
    clearLessonPage
} from './actions/';
import { fetchQuestion, showUpdateModal, updateQuestion } from '../Question/actions/';

class LessonEdit extends React.Component {

    state = {
        type: "Lessons", currentLeft: 0, currentRight: 0, FontSize: 18, showLessonInfoModal: false, showDragDropModal: false, resetQuestion: false, showPagesModal: false,
        questionSeparated: {
            BorderStyle: "dashed",
            BorderColor: 'rgba(68, 68, 68, 1)',
            BorderWidth: 3,
            width: 100
        },
        Clock: {
            TimeBorderColor: 'rgba(146, 39, 143, 1)',
            BackgroundBorderColor: 'rgba(255, 255, 255, 1)',
            TimeType: "continue",
            TimeMinutes: 0,
            TimeHour: 0
        },
        MaterialsObject: []
    };

    constructor(props) {
        super(props);
        this.state = {
            FontSize: 18,
            showSound: false,
            showText: false,
            styleQuestion: '',
            styleBind: '',
            styleText: '',

            currentLeft: 0,
            currentRight: 0,
            first: true,

            Id: 0,
            Name: '',
            Bio: '',
            Sort: 0,
            Sound: '',
            Icon: '',
            Url: '',
            Background: '',
            Questions: [],
            IsActive: true,
            IsPublic: false,
            IndexId: 0,
            questionSeparated: {
                BorderStyle: "dashed",
                BorderColor: 'rgba(68, 68, 68, 1)',
                BorderWidth: 3,
                width: 100
            },
            Clock: {
                ConfettiTimesUp: 'none',
                CustomConfettiTimesUp: '',
                TimeBorderColor: 'rgba(146, 39, 143, 1)',
                BackgroundBorderColor: 'rgba(255, 255, 255, 1)',
                TimeType: "continue",
                TimeMinutes: 0,
                TimeHour: 0
            },
            ExamBackground: {
                Confetti: 'none',
                CustomConfetti: '',
            },
            MaterialsObject: []
        };
        this._toastId = new React.createRef();
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            Id: id
        });
        if (id !== '0') {
            this.props.fetchLesson(id);
        } else {
            this.setState({
                showLessonInfoModal: true
            });
        }
    }
    static getDerivedStateFromProps(props, state) {
        const { id } = props.match.params;
        if (props.type) {
            state.type = props.type
        } else {
            state.type = "Lessons";
        }
        if (props.lessons && state.Id && state.Id !== '0') {

            if (!state.showPagesModal && !state.showLessonInfoModal && props.lessons.current) {
                let lesson = props.lessons.current;

                let FontSize = 18;
                let questionSeparated = state.questionSeparated;
                let Clock = state.Clock;
                let MaterialsObject = state.MaterialsObject;
                let ExamBackground = state.ExamBackground;
                if (lesson.CustomStyle) {
                    if (lesson.CustomStyle.FontSize) {
                        FontSize = lesson.CustomStyle.FontSize;
                    }
                    if (lesson.CustomStyle.questionSeparated) {
                        questionSeparated = lesson.CustomStyle.questionSeparated;
                    }
                    if (lesson.CustomStyle.Clock) {
                        Clock = lesson.CustomStyle.Clock;
                    }
                    if (lesson.CustomStyle.MaterialsObject) {
                        MaterialsObject = lesson.CustomStyle.MaterialsObject;
                    }
                    if (lesson.CustomStyle.ExamBackground) {
                        ExamBackground = lesson.CustomStyle.ExamBackground;
                    }
                }

                let LessonPage = [];
                if (lesson.LessonPage) {
                    let toRemove = props.lessons.PageToRemove;
                    lesson.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                        if (!toRemove || !toRemove.includes(item.Id)) {
                            LessonPage = [...LessonPage, item];
                        }
                    });
                }

                let obj = {
                    Sort: lesson.Sort,
                    Id: lesson.Id,
                    Name: lesson.Name,
                    Bio: lesson.Bio,
                    Background: lesson.Background,
                    Sound: lesson.Sound,
                    IsActive: lesson.IsActive,
                    IsPublic: lesson.IsPublic,
                    Url: lesson.Url,
                    IndexId: lesson.Index ? lesson.Index.Id : state.IndexId,
                    Icon: lesson.Icon,
                    LessonPage: LessonPage,
                    Questions: lesson.Questions ? lesson.Questions.sort((a, b) => a.Sort - b.Sort) : [],
                    FontSize,
                    questionSeparated,
                    ExamBackground,
                    Clock,
                    MaterialsObject
                    //        first: false,
                };

                if (obj.Questions) {
                    var hasRight = obj.Questions.some(p => p.Position === true);
                    var hasLeft = obj.Questions.some(p => p.Position === false);
                    if (hasRight && !hasLeft) {
                        obj = {
                            ...obj,
                            styleQuestion: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    width: '100%'
                                }
                            }
                        };
                    } else if (!hasRight && hasLeft) {
                        obj = {
                            ...obj,
                            styleQuestion: {
                                style: {
                                    width: '100%'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    display: 'none'
                                }
                            }
                        };
                    } else {
                        obj = {
                            ...obj,
                            styleQuestion: {},
                            styleBind: {},
                            styleText: {}
                        };
                    }
                }

                return obj;
            } else if (props.lessons.current) {
                let lesson = props.lessons.current;
                state.Questions = lesson.Questions ? lesson.Questions.sort((a, b) => a.Sort - b.Sort) : [];

                let LessonPage = [];
                if (lesson.LessonPage) {
                    let toRemove = props.lessons.PageToRemove;
                    lesson.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                        if (!toRemove || !toRemove.includes(item.Id)) {
                            LessonPage = [...LessonPage, item];
                        }
                    });
                }
                state.LessonPage = LessonPage;

                if (lesson.CustomStyle) {
                    if (lesson.CustomStyle.questionSeparated) {
                        state.questionSeparated = lesson.CustomStyle.questionSeparated;
                    }
                    if (lesson.CustomStyle.Clock) {
                        state.Clock = lesson.CustomStyle.Clock;
                    }
                    if (lesson.CustomStyle.MaterialsObject) {
                        state.MaterialsObject = lesson.CustomStyle.MaterialsObject;
                    }
                    if (lesson.CustomStyle.ExamBackground) {
                        state.ExamBackground = lesson.CustomStyle.ExamBackground;
                    }
                }

                if (lesson.Questions && lesson.Questions.length !== 0) {
                    var hasRights = lesson.Questions.some(p => p.Position === true);
                    var hasLefts = lesson.Questions.some(p => p.Position === false);
                    if (hasRights && !hasLefts) {
                        state = {
                            ...state,
                            styleQuestion: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    width: '100%'
                                }
                            }
                        };
                    } else if (!hasRights && hasLefts) {
                        state = {
                            ...state,
                            styleQuestion: {
                                style: {
                                    width: '100%'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    display: 'none'
                                }
                            }
                        };
                    } else {
                        state = {
                            ...state,
                            styleQuestion: {},
                            styleBind: {},
                            styleText: {}
                        };
                    }
                }

            }
        }
        state.Id = id;
        return state;
    }
    setId = Id => {
        this.setState({ Id });
        this.props.fetchLesson(Id);
        if (this.props.type === "BookLessons") {
            history.push(`/ControlPanel/Books/Edit/${Id}`);
        } else if (this.state.type === "Exams") {
            history.push(`/ControlPanel/Exams/Edit/${Id}`);
        } else if (this.state.type === "Quizzes") {
            history.push(`/ControlPanel/Quizzes/Edit/${Id}`);
        } else if (this.state.type === "SelfDevelopment") {
            history.push(`/ControlPanel/SelfDevelopment/Edit/${Id}`);
        } else {
            history.push(`/ControlPanel/Lesson/Edit/${Id}`);
        }
    }
    setName = value => {
        this.setState({ Name: value });
    }
    setFontSize = value => {
        this.setState({ FontSize: value });
    }
    setSound = value => {
        this.setState({ Sound: value });
    }
    setUrl = value => {
        this.setState({ Url: value });
    }
    setBackground = value => {
        this.setState({ Background: value });
    }
    setIcon = value => {
        this.setState({ Icon: value });
    }
    setIsActive = value => {
        this.setState({ IsActive: value });
    }
    setIsPublic = value => {
        this.setState({ IsPublic: value });
    }
    setBio = value => {
        this.setState({ Bio: value });
    }
    setIndexId = value => {
        this.setState({ IndexId: value });
    }
    hideModal = () => {
        let showLessonInfoModal = true;
        if (this.state.Id && this.state.Id !== "0") {
            showLessonInfoModal = false;
        } else {
            if (this.state.type === "Books") {
                history.push(`/ControlPanel/Books`);
            } else if (this.state.type === "Exams") {
                history.push(`/ControlPanel/Exams`);
            } else if (this.state.type === "Quizzes") {
                history.push(`/ControlPanel/Quizzes`);
            } else if (this.state.type === "SelfDevelopment") {
                history.push(`/ControlPanel/SelfDevelopment`);
            } else {
                history.push(`/ControlPanel/Lessons/0`);
            }
        }
        this.setState({
            showLessonInfoModal,
            showPagesModal: false
        });
    }
    showLessonInfoModal = () => {
        this.setState({ showLessonInfoModal: true });
    }
    showPagesModal = () => {
        this.setState({ showPagesModal: true });
    }

    showUpdateModal = (Id, QuestionLessonId, TypeId) => {
        if (Id) {
            this.props.fetchQuestion(QuestionLessonId);
        }
        this.props.showUpdateModal(Id, TypeId);
    }
    setQuestionValue = (newQuestion) => {
        this.props.updateLessonQuestion(newQuestion);
        this.setState({
            resetQuestion: true
        });
    }
    showDeleteQuestion = id => {
        if (id) {
            this.props.removeLessonQuestion(id);

            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDeleteQuestion(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteQuestion(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }
    deleteQuestion = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.props.clearLessonQuestion(id);

        api.delete(`/Questions/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Delete_Question.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Delete_Question.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    undoDeleteQuestion = id => {
        this.props.undoDeleteLessonQuestion(id);
    }

    showSort = () => {
        this.setState({
            showDragDropModal: true
        });
    }
    handleCloseDragDropModal = () => {
        this.setState({
            showDragDropModal: false
        });
    }
    setSort = items => {

        items.forEach(item => {
            this.props.setLessonQuestionsSort(item.id, item.sort);
        });

        this.handleCloseDragDropModal();
    }
    updateQuestion = question => {
        this.props.updateQuestion(question);

        let hasQuestion = this.state.Questions.some(p => p.Id === question.id);
        if (!hasQuestion) {
            this.props.createNewLessonQuestion(question.question);
        }
    }

    render() {
        const { lessons } = this.props;

        if ((lessons.length === 0 || !lessons.current) && this.state.Id !== '0') {
            return <Loading />
        }
        return (
            <>
                {
                    this.state.showDragDropModal ?
                        <QuestionSortModal
                            LessonId={this.state.Id}
                            show={this.state.showDragDropModal}
                            nodes={this.state.Questions}
                            IndexId={this.state.Id}
                            API={`/Questions/`}
                            updateModal={this.showUpdateModal}
                            deleteModal={this.showDeleteQuestion}
                            setSort={this.setSort}
                            handleClose={this.handleCloseDragDropModal}
                            updateCurrentQuestion={this.updateQuestion} />
                        : ""
                }
                <List
                    LessonId={this.state.Id}
                    FontSize={this.state.FontSize}
                    setQuestionValue={this.setQuestionValue}
                    updateCurrentQuestion={this.updateQuestion} />
                {
                    this.state.showLessonInfoModal ?
                        <LessonInfo
                            type={this.state.type}
                            key={`LessonInfo-${this.state.Id}`}
                            Id={this.state.Id}
                            Name={this.state.Name}
                            Sound={this.state.Sound}
                            Url={this.state.Url}
                            Background={this.state.Background}
                            IsPublic={this.state.IsPublic}
                            IsActive={this.state.IsActive}
                            Icon={this.state.Icon}
                            Bio={this.state.Bio}
                            FontSize={this.state.FontSize}
                            IndexId={this.state.IndexId}
                            questionSeparated={this.state.questionSeparated}
                            Clock={this.state.Clock}
                            ExamBackground={this.state.ExamBackground}
                            MaterialsObject={this.state.MaterialsObject}
                            setIndexId={this.setIndexId}
                            setBio={this.setBio}
                            setUrl={this.setUrl}
                            setFontSize={this.setFontSize}
                            setIsPublic={this.setIsPublic}
                            setIsActive={this.setIsActive}
                            setBackground={this.setBackground}
                            setSound={this.setSound}
                            setName={this.setName}
                            setId={this.setId}
                            setIcon={this.setIcon}
                            updateLessons={this.props.updateLessons}
                            show={this.state.showLessonInfoModal}
                            typeTreeView={this.props.typeTreeView}
                            handleClose={this.hideModal} />
                        : ""
                }
                {
                    this.state.showPagesModal ?
                        <LessonPage
                            LessonId={this.state.Id}
                            updateLessons={this.props.updateLessons}
                            clearLessonPage={this.props.clearLessonPage}
                            LessonPage={this.state.LessonPage}
                            removeLessonPage={this.props.removeLessonPage}
                            undoDeleteLessonPage={this.props.undoDeleteLessonPage}
                            handleClose={this.hideModal}
                            show={this.state.showPagesModal} /> : ""
                }
                <View
                    type={this.props.type}
                    key={this.state.Id}
                    Id={this.state.Id}
                    Name={this.state.Name}
                    Sound={this.state.Sound}
                    IndexId={this.state.IndexId}
                    Questions={this.state.Questions}
                    LessonPage={this.state.LessonPage}
                    Url={this.state.Url}
                    questionSeparated={this.state.questionSeparated}
                    QuestionToRemove={this.props.lessons.QuestionToRemove}
                    FontSize={this.state.FontSize}
                    Background={this.state.Background}
                    isOnEdit={true}
                    showLessonInfoModal={this.showLessonInfoModal}
                    showUpdateModal={this.showUpdateModal}
                    showDeleteQuestion={this.showDeleteQuestion}
                    showSort={this.showSort}
                    showPagesModal={this.showPagesModal}
                />
                <footer>
                    <FooterBottom />
                </footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons,
        question: state.questions
    };
};

export default connect(mapStateToProps, { fetchLesson, fetchQuestion, updateLessons, showUpdateModal, updateLessonQuestion, updateQuestion, createNewLessonQuestion, setLessonQuestionsSort, removeLessonQuestion, undoDeleteLessonQuestion, clearLessonQuestion, removeLessonPage, undoDeleteLessonPage, clearLessonPage })(withTranslation('common')(LessonEdit));
import React, { useEffect, useRef } from "react";
import Spritesheet from 'react-responsive-spritesheet';

import img from './images/2.png';
import sound from './sounds/explosion.mp3';

const audio = new Audio(sound);

const RocketExplosion = (props) => {

    const { top, left, isMute } = props;

    const spritesheeInstance = useRef(null);

    useEffect(() => {
        if (audio && !audio.paused) {
            audio.pause();
        }
        if (spritesheeInstance && spritesheeInstance.current) {
            spritesheeInstance.current.goToAndPlay(1);
            spritesheeInstance.current.play();
        }
        if (audio && isMute !== true) {
            audio.currentTime = 0;
            audio.play();
        }
    });

    return <div className="position-absolute " style={{
        top: top,
        left: left,
        userSelect: 'none',
        zIndex: 999,
        transform: 'scale(0.5)',
        pointerEvents: 'none',
        transformOrigin: '-50% -50%'
    }}>
        <Spritesheet
            image={img}
            widthFrame={850 / 3}
            heightFrame={950 / 4}
            steps={12}
            fps={12}
            autoplay={spritesheeInstance.current ? false : true}
            loop={true}
            getInstance={spritesheet => {
                spritesheeInstance.current = spritesheet;
            }}
            onLoopComplete={spritesheet => {
                if (props.stop) {
                    props.stop();
                }
                spritesheet.pause();
            }}
        />
    </div>;

}
export default RocketExplosion;






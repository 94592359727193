import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class SpaceShip extends React.Component {

    constructor(props) {
        super(props);
        this.random = Math.ceil(Math.random() * 500);
    }


    render() {
        let style = "";
        let color = "#0e7972";
        if (this.props.customColor) {
            color = this.props.customColor;
            style = `.spaceships__body_${this.random}:before{background-color:${color}}`;
        }

        return <>
            <style dangerouslySetInnerHTML={{ __html: style }}></style>

            <div className="spaceships d-inline-block"  >
                <div className="h-100">
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className="spaceships__head"></div>
                            <div className={`spaceships__body spaceships__body_${this.random}`} style={{
                                backgroundColor: color
                            }} ></div>
                            <div className="spaceships__foot"></div>
                            <div className="alien">
                                <div className="alien__body">
                                    <div className="alien__ear"></div>
                                    <div className="alien__eye"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 " style={{
                    transform: 'scale(2)'
                }}>
                    <TextBox {...this.props} />
                </div>
            </div>
        </>;
    }
}

export default SpaceShip;

import React, { useCallback, useEffect, useRef, useState } from "react";
import Spritesheet from 'react-responsive-spritesheet';
import TextBox from "../../../TextBox";

import img from './images/1.png';

const width = 2730 / 15;
const height = 522 / 3;

const PurpleFish = (props) => {

    const { status } = props;
    const steps = 15;

    const spritesheeInstance = useRef(null);
    const [type, setType] = useState("Defult");
    const [isHover, setIsHover] = useState(false);
    const [startAt, setStartAt] = useState(1);
    const [endAt, setEndAt] = useState(15);

    const setDimensions = useCallback(newType => {
        let startAt = 1;
        let endAt = 15;
        if (newType === "True") {
            startAt = 16;
            endAt = 30;
        } else if (newType === "Wrong") {
            startAt = 31;
            endAt = 45;
        }

        setStartAt(startAt);
        setEndAt(endAt);

        if (spritesheeInstance && spritesheeInstance.current && (!isHover || newType !== "Open")) {
            spritesheeInstance.current.setStartAt(startAt);
            spritesheeInstance.current.setEndAt(endAt);
            spritesheeInstance.current.goToAndPlay(startAt);
            setType(newType);
        }
    }, [isHover]);

    useEffect(() => {
        if (status) {
            setDimensions(status);
        }
    }, [status, setDimensions]);

    return <div className="position-relative d-flex justify-content-center flex-column align-items-center move_up_down_20" >
        <div className={`mb-1 d-inline-block`} >
            <div className={`${props.isSpin === "true" || props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${props.transformOriginX ? props.transformOriginX : "center"} ${props.transformOriginY ? props.transformOriginY : "center"}`,
                    transform: `
                        ${props.scale ? `scale(${props.scale})` : ""}
                        ${props.flipX === "true" || props.flipX === true ? "scaleX(-1)" : ""}
                        ${props.rotate ? `rotate(${props.rotate}deg)` : ""}
                        `
                }}>
                    <Spritesheet
                        image={img}
                        widthFrame={width}
                        heightFrame={height}
                        steps={steps}
                        startAt={startAt}
                        endAt={endAt}
                        fps={15}
                        autoplay={true}
                        loop={true}
                        getInstance={spritesheet => {
                            spritesheeInstance.current = spritesheet;
                        }}
                        onLoopComplete={spritesheet => {
                            if (type !== "Defult" && type !== "Open") {
                                props.FinishMove();
                                setIsHover(false);
                                setDimensions("Defult");
                            }
                            if (type === "Open" && !isHover) {
                                setDimensions("Defult");
                            }
                        }}
                    />
                </div>
            </div>
        </div>
        <TextBox {...props} />
    </div >
}

export default PurpleFish;
import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

import history from '../../../history';

class TreeView extends React.Component {

    setCurrentItem = item => {
        item.isOpen = !item.isOpen;
        this.props.setCurrentItem(item);
    }

    renderTree(treeNode, hasType, isOpen) {
        const { Level } = this.props;

        hasType = hasType === false ? (this.props.type ? this.HasType(this.props.type, treeNode) : true) : true;
        const active = (this.props.currentId === treeNode.id) ? "active" : "";

        return (
            <>
                <Link
                    to="#"
                    className={`${active}`}
                    onClick={() => this.setCurrentItem(treeNode)}>
                    <span className="m-1">
                        {
                            (treeNode.nodes && treeNode.nodes.length > 0) ?
                                Level && treeNode.Level === Level ? <i className="fas fa-angle-double-left"></i> :
                                    isOpen ? <i className="fas fa-minus-circle"></i> : <i className="fas fa-plus-circle"></i> :
                                <i className="fas fa-angle-double-left"></i>
                        }
                        {treeNode.label}
                    </span>
                    <span className="m-1 mx-2">{treeNode.count > 0 ? treeNode.count : ""}</span>
                </Link>
                {
                    (treeNode.nodes && treeNode.nodes.length > 0 && isOpen && hasType) ? !Level ? this.renderSubNode(treeNode.nodes, hasType) : treeNode.Level < Level ? this.renderSubNode(treeNode.nodes, hasType) : "" : ''
                }
            </>
        );
    }

    HasType(type, node) {
        if (node) {
            return type.includes(node.type) || this.hasSelectedNodeTypeInChildren(type, node.nodes);
        }
        return false;
    }

    hasSelectedNodeTypeInChildren(type, nodes) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                result = result || type.includes(node.type) || this.hasSelectedNodeTypeInChildren(type, node.nodes);
            });
        }
        return result;
    }

    HasNode(node) {
        if (node) {
            return (this.props.currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes) {
        var result = false;
        nodes.forEach(node => {
            result = result || (this.props.currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes);
        });
        return result;
    }

    renderSubNode(nodes, hasType) {
        if (nodes) {
            return (
                <div className="widget-body pl-2 pb-1 pt-2" >
                    <ul>
                        {
                            nodes.map((node, key) =>
                                ((this.props.toRemove && this.props.toRemove.includes(node.id)) || (this.props.type && (node.Level === 2 && !node.type.includes(this.props.type)))) ? "" :
                                    <li key={`node-${key}`}>
                                        {this.renderSubNodeIsOpen(node, hasType)}
                                    </li>
                            )
                        }
                    </ul>
                </div>
            );
        }
    }

    renderSubNodeIsOpen(node, hasType) {
        const isOpen = this.HasNode(node);
        var style = isOpen ? {
            background: '#f8f4ff',
            borderRadius: '10px'
        } : {};
        return <div style={style}>{this.renderTree(node, hasType, isOpen)} </div>
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    goBack = () => {
        if (this.props.IsAdmin) {
            history.push(`/ControlPanel/Books/${this.props.parentId}`);
        } else {
            history.push(`/Books/${this.props.parentId}`);
        }
    }

    render() {
        const { t, type, startFrom } = this.props;
        const hasType = type ? this.HasType(this.props.type, this.props.data) : true;

        let data = this.props.data;
        if (startFrom) {
            data = this.GetNode(data, startFrom);
        }
        return (
            <>
                <div className="widget widget-category">
                    <div className="widget-header d-flex justify-content-between">
                        <h5 className="title align-self-center">
                            {this.props.title ?
                                <Link onClick={() => this.goBack()} to="#">
                                    {this.props.title}
                                </Link>
                                : t("ControlPanel.Indices.title")}
                        </h5>
                    </div>

                    <div className="widget-body">
                        {
                            data ?
                                <ul>
                                    {this.renderSubNode(data, hasType)}
                                </ul>
                                :
                                <div className="text-center">
                                    <div className="display-1 text-danger">
                                        <i className="fad fa-sync-alt fa-spin"></i>
                                    </div>
                                    <h6 className="subtitle">
                                        {t("ControlPanel.Indices.Loading")}
                                    </h6>
                                </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation('common')(TreeView);
import React from "react";
import GameObjects from "../../../Question/View/GameObjects";

class MoveObject extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.timerMove = "";
        this.designerContainer = React.createRef();
        this.state = {
            currentPoint: {
                top: Math.floor(Math.random() * 20) * -1,
                left: Math.floor(Math.random() * 100)
            },
            endPoint: {
                top: 0,
                left: 0
            },
            updatePosition: this.updatePosition,
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            timerSound: "",
            zIndex: 19,
            speedY: this.props.designerContainer.current.clientHeight / this.props.Time,
            speedX: this.props.designerContainer.current.clientWidth / this.props.Time,
            isHide: false,
            isShow: false,
            isHover: false,
            objectMovmentId: this.props.objectMovmentId ? this.props.objectMovmentId : Math.floor(Math.random() * 100000)
        };
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount() {
        if (this.props.MoveType !== "custom") {
            let timeout = this.state.delay;
            setTimeout(() => {
                this.setState({
                    isShow: true
                });
                this.updatePosition(1);
                setTimeout(() => {
                    this.updatePosition(this.props.Time);
                    this.timer = setInterval(() => {
                        this.updatePosition(this.props.Time);
                    }, this.props.Time * 1000);
                }, 1000);
            }, timeout);
        } else {
            this.setState({
                isShow: true
            });
        }
    }

    updatePosition = Time => {
        if (!this.state.isHover) {
            const top = this.props.getRandomNumber(this.props.count, 30, "top");
            const left = this.props.getRandomNumber(this.props.count, 90, "left");
            this.setState({
                top: top < 30 ? top : 30,
                left
            });
            window.moveTo(`objectMovment${this.state.objectMovmentId}`, top, left, Time * 1000);
        }
    }

    renderMoveStyle = currentMovment => {
        let result = "";
        let oldScale = 1;
        let isScaleChange = false;
        currentMovment.forEach(item => {
            if (item.AnswersScale && item.AnswersScale !== oldScale) {
                isScaleChange = true;
            }
            item.AnswersScale = oldScale;
        })
        currentMovment.sort((a, b) => a.Index - b.Index).forEach(item =>
            result += item.Index + `% {visibility:visible; transform-origin:100%; transform: ${isScaleChange ? `scale(${item.AnswersScale})` : ""} rotate(` + item.AnswersRotate + `deg); opacity:${item.AnswersOpacity / 100}; ${item.AnswersTranslatePositionY === "bottom" ? "top:unset; bottom" : "bottom:unset; top"} : ${item.AnswersTranslateY}%; ${item.AnswersTranslatePositionX === "left" ? "right:unset; left" : "left:unset; right"} :${item.AnswersTranslateX}% }`
        );
        return result;
    }

    render() {
        const { Movments, id, Time, index, MoveType, delay } = this.props;
        const currentMovment = Movments.filter(p => p.Id === id && p.Sort === index);
        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide && this.state.isShow) {
            return <>
                {
                    MoveType === "custom" && currentMovment ?
                        <style type="text/css" dangerouslySetInnerHTML={
                            {
                                __html: ` @keyframes objectMovment-${id}-${index} { ${this.renderMoveStyle(currentMovment)} } `
                            }
                        }></style>
                        : ""
                }
                <div ref={this.designerContainer}
                    className={`position-absolute  `}
                    id={`objectMovment${this.state.objectMovmentId}`}
                    style={{
                        visibility: MoveType === "custom" ? 'hidden' : "visible",
                        left: MoveType !== "custom" ? this.state.currentPoint.left + '%' : '',
                        top: MoveType !== "custom" ? this.state.currentPoint.top + '%' : '',
                        animationPlayState: "running",
                        animationDelay: `${delay}s`,
                        animationIterationCount: "infinite",
                        animationDirection: "normal",
                        animationFillMode: 'forwards',
                        animationDuration: `${Time}s`,
                        animationTimingFunction: 'linear',
                        animationName: `objectMovment-${id}-${index}`,
                        position: "absolute",
                        zIndex: this.state.zIndex ? this.state.zIndex : 19
                    }}>
                    <GameObjects {...this.props} type="dragdrop-move" />
                </div>
            </>;
        }
        return "";
    }
}

export default MoveObject;
import React from 'react';
import { withTranslation } from 'react-i18next';

import Items from './Items';

class SubAnswerItem extends React.Component {

    state = {
        items: []
    };

    static getDerivedStateFromProps(props, state) {
        if (props.answers) {
            let mainItem = props.answers;
            if (props.categoryId && props.fills) {
                mainItem = mainItem.filter(p => props.fills.some(f => f.CategoryId === props.categoryId && f.AnswerId === p.Id)).map(answer => {
                    return answer;
                });
            }
            state.items = mainItem;
        }
        return state;
    }

    render() {
        const { t } = this.props;
        return <Items
            {...this.props}
            updateQuestionInfo={this.props.updateQuestionAnswerInfo}
            showDelete={this.props.showDeleteAnswer}
            EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
            title={t('Pages.Lessons.Questions.forms.Answers')}
            text={t('Pages.Lessons.Questions.forms.Answer')}
            sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
            RedactorId="QuestionAnswers"
            API="/QuestionAnswers/"
            ObjectType={1}
            moreSetting={true}
            isCategory={false}
            items={this.state.items}>
        </Items>
    }
}

export default withTranslation('common')(SubAnswerItem);
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";

import Footer from "../Footer/FooterBottom";
import api from '../../api/api';

class PayBill extends React.Component {

    state = { showResult: false, showError: true, qs: "", bill: null };

    constructor(props) {
        super(props);
        window.scrollToTop();
    }

    componentDidMount() {
        const { search } = this.props.location;
        this.setState({
            qs: search.substring(1)
        });
        this.reloadAfterPurchaes(search.substring(1));
    }

    reloadAfterPurchaes = (qs) => {
        let postData = {
            qs
        };
        api.post(`/PayBill/`,
            postData
        ).then(response => {
            this.setState({
                bill: response.data.data,
                showResult: true,
                showError: false
            });
        }).catch(error => {
            this.setState({
                showResult: true,
                showError: true
            });
        });
    }

    renderServiceItem = () => {
        const { bill } = this.state;
        const services = bill.Items;
        if (services) {
            const { t } = this.props;
            return services.map(item => {
                return <li key={item.Id} className="d-flex justify-content-around align-items-center">

                    <label className="font-weight-bold" htmlFor={`serviceId-${item.Id}`} >
                        {item.Name}
                    </label>
                    <div className="h5">
                        {item.Price}
                        {
                            bill.IsNIS ?
                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i> :
                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i>
                        }
                    </div>
                </li>;
            });
        }
        return "";
    }

    pay = () => {
        window.location.href = this.state.bill.link;
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>
                    <Helmet>
                        <title>{t("Pages.PayBill.title")} | {t('alarabeyya')}</title>
                    </Helmet>
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="teacher-wrapper pos-rel pt-5  " style={{
                            minWidth: '50%'
                        }}>
                            <div className="teacher-single-top mt-2">
                                {
                                    this.state.showResult ?
                                        this.state.showError ?
                                            <div className="product-content w-100 text-center ">
                                                <div className="display-1 text-danger">
                                                    <i className="fad fa-exclamation-triangle"></i>
                                                </div>
                                                <h4 className="display-3 subtitle  my-3">
                                                    {t("Pages.PayBill.Error")}
                                                </h4>
                                            </div> :
                                            <>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 " style={{
                                                        color: '#f16421'
                                                    }}>
                                                        {
                                                            this.state.bill.user.School.Id === 1 ?
                                                                <i className="fad fa-user-graduate"></i> :
                                                                <i className="fad fa-school"></i>
                                                        }
                                                    </div>
                                                    <h3 className="title">
                                                        {
                                                            this.state.bill.user.School.Id === 1 ?
                                                                t('Pages.Register.Single.title') :
                                                                t('Pages.Register.School.title')
                                                        }
                                                    </h3>
                                                    <div className="display-3 subtitle my-4  d-flex align-items-center justify-content-between">
                                                        <div>
                                                            {t("Pages.PayBill.forUser")} :
                                                            <span className="mx-1 text-info font-weight-bold">
                                                                {
                                                                    this.state.bill.user.School.Id === 1 ?
                                                                        this.state.bill.user.Name :
                                                                        this.state.bill.user.School.Name

                                                                }
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <h4 className="title" style={{
                                                        textDecoration: 'underline'
                                                    }}>
                                                        {t("Pages.PayBill.Items")}
                                                    </h4>
                                                    <ul className="mb-0">
                                                        {
                                                            this.renderServiceItem()
                                                        }
                                                    </ul>
                                                    <hr />
                                                    <div className="h5 font-weight-bold mt-4" >
                                                        <span className="mx-2"> {t('Pages.myBills.TotalPrice')} </span>
                                                        {this.state.bill.Price}
                                                        {
                                                            this.state.bill.IsNIS ?
                                                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                                                    color: '#92278f'
                                                                }}></i> :
                                                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                                                    color: '#92278f'
                                                                }}></i>
                                                        }
                                                    </div>
                                                    <div className="h5 font-weight-bold my-3" >
                                                        <span className="mx-2"> {t('Pages.myBills.Discount')} </span>
                                                        {this.state.bill.Discount}
                                                        {
                                                            this.state.bill.IsNIS ?
                                                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                                                    color: '#92278f'
                                                                }}></i> :
                                                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                                                    color: '#92278f'
                                                                }}></i>
                                                        }
                                                    </div>
                                                    <div className="h5 font-weight-bold " >
                                                        <span className="mx-2"> {t('Pages.myBills.NatPrice')} </span>
                                                        {this.state.bill.Price - this.state.bill.Discount}
                                                        {
                                                            this.state.bill.IsNIS ?
                                                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                                                    color: '#92278f'
                                                                }}></i> :
                                                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                                                    color: '#92278f'
                                                                }}></i>
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-center mt-4 align-items-center  ">
                                                    <button className="custom-button mt-1" onClick={() => this.pay()} >
                                                        <span>
                                                            <i className="icon fal fa-credit-card"></i>
                                                            {t('Pages.PayBill.Pay')}
                                                        </span>
                                                    </button>
                                                </div>
                                            </>
                                        :
                                        <div className="product-content w-100 text-center ">
                                            <div className="display-1 text-danger">
                                                <i className="fad fa-sync-alt fa-spin"></i>
                                            </div>
                                            <h4 className="display-3 subtitle">
                                                {t("Pages.PayBill.Loading")}
                                            </h4>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

export default withTranslation('common')(PayBill);
import React from "react";
import TextBox from "../../TextBox";
import './style.css';
class Ghost extends React.Component {
    render() {
        let color = "#F0EFDA";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const style = {
            backgroundColor: color,
            borderColor: color
        };
        return (
            <>
                <div className="ghost">
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className="body" style={style}>
                                <div className="face">
                                    <div className="eye left"></div>
                                    <div className="eye right"></div>
                                    <div className="smile"></div>
                                    <div className="rosy left"></div>
                                    <div className="rosy right"></div>
                                </div>
                                <div className="arm left" style={style}></div>
                                <div className="arm right" style={style}></div>
                                <div className="bottom">
                                    <div style={style}></div>
                                    <div style={style}></div>
                                    <div style={style}></div>
                                    <div style={style}></div>
                                    <div style={style}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow">
                        <div style={{
                            transform: 'scale(2)'
                        }}>
                            <TextBox {...this.props} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Ghost;

import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'

import { fetchFile } from '../../../Admin/Files/actions/';
import Loading from "../../../Loading/";
import View from "./View";

class FilePDFView extends React.Component {

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            Id: id
        });
        if (id !== '0') {
            this.props.fetchFile(id);
        }
    }

    render() {
        const { files, user } = this.props;
        if (files.length === 0 || !files.current) {
            return <Loading></Loading>
        }
        return <View Id={this.state.Id} isFromFile={true} user={user} item={this.props.files.current} code={this.props.files.code} />
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        files: state.files
    };
};

export default connect(mapStateToProps, { fetchFile })(withTranslation('common')(FilePDFView));
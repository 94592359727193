import React from "react";
import GameObjects from "../../../Question/View/GameObjects";

class VerticalMove extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.designerContainer = React.createRef();
        this.state = {
            top: 0,
            isShow: false,
            left: this.props.left ?
                this.props.left > 50 ?
                    100 - this.props.left : this.props.left
                : Math.floor(Math.random() * 80),
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            timerSound: "",
            zIndex: 19,
            isHide: false,
            isHover: false,
            objectMovmentId: Math.floor(Math.random() * 100000),
            isLeft: this.props.left < 50
        };
    }

    componentDidMount() {

        let timeout = this.state.delay;
        setTimeout(() => {
            this.setState({
                isShow: true
            });
            if (!this.state.isLeft) {
                if (this.props.direction === "bottom") {
                    window.moveToFromRightBottom(`objectMovment${this.state.objectMovmentId}`, 100, this.state.left, this.props.Time * 1000);
                } else {
                    window.moveToFromRight(`objectMovment${this.state.objectMovmentId}`, 100, this.state.left, this.props.Time * 1000);
                }
            } else {
                if (this.props.direction === "bottom") {
                    window.moveToFromBottom(`objectMovment${this.state.objectMovmentId}`, 100, this.state.left, this.props.Time * 1000);
                } else {
                    window.moveTo(`objectMovment${this.state.objectMovmentId}`, 100, this.state.left, this.props.Time * 1000);
                }
            }
        }, timeout);

        this.timer = setTimeout(() => {
            if (this.state.isHide === false) {
                this.props.handelDropObject(this.props.param, false);
                this.setState({
                    isHide: true
                });
            }
        }, (this.props.Time * 1000) + timeout);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide && this.state.isShow) {

            let style = {
                zIndex: this.state.zIndex ? this.state.zIndex : 19
            };
            if (this.state.isLeft) {
                style = {
                    left: `${this.state.left}%`,
                    ...style
                }

            } else {
                style = {
                    right: `${this.state.left}%`,
                    ...style
                }
            }

            if (this.props.direction === "bottom") {
                style = {
                    bottom: `${this.state.top}%`,
                    ...style
                };
            } else {
                style = {
                    top: `${this.state.top}%`,
                    ...style
                };
            }

            return <div ref={this.designerContainer}
                className={`position-absolute objectMovment `}
                id={`objectMovment${this.state.objectMovmentId}`}
                style={style}>
                <GameObjects   {...this.props} type="dragdrop-move" />
            </div>;
        }
        return "";
    }
}

export default VerticalMove;
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Footer from "../../Footer/FooterBottom";
import SubMenu from "./SubMenu";
import TreeView from "../../Admin/Indices/TreeView";
import { fetchIndices } from '../../Admin/Indices/actions/';
import history from '../../../history';
import { getIndeices, setIndeices } from "../../../context/sessionStorage";
import Library from "../../Admin/Question/View/Background/Library";

class Books extends React.Component {

    state = { currentItem: '', indices: [], search: '' };

    componentDidMount() {
        const GetNode = this.GetNode;
        this.setState({ GetNode });

        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices
        });
        const { indexId } = this.props.match.params;
        if (indexId !== '0') {
            this.setState({
                currentItem: {
                    id: parseInt(indexId)
                }
            });
        }

        window.scrollToPosition("main-books-section");
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id) {
                    result = node;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.indices && props.indices.nodes && state.currentItem && state.currentItem.id && !state.currentItem.label) {
            state.currentItem = state.GetNode(props.indices.nodes, state.currentItem.id);
        }
        return state;
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        history.push(`/Tracks/Books/${item.id}`);
    }

    openBook = id => {
        history.push(`/Tracks/BookLessons/${id}`);
    }

    renderBooks() {
        if (this.state.currentItem && this.state.currentItem.Level === 3 && this.state.currentItem.nodes.length > 0) {
            let search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            return this.state.currentItem.nodes.filter(v => v.label.includes(search)).map(item => {
                return (
                    <div className="pr-1 bookWrap mb-50 " key={item.id}>
                        <Link to="#" className='w-100' onClick={() => this.openBook(item.id)}>
                            <div className="shop-item">
                                <div className="product-thumb">
                                    {
                                        item.icon ?
                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                            :
                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                    }
                                </div>
                                <div className="product-content">
                                    <h5 className="title">{item.label}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                );

            });
        } else {
            let start = 4;
            let IndexId = (this.state.currentItem && this.state.currentItem.id) ? parseInt(this.state.currentItem.id) : start;

            var indexNodes = this.GetNode(this.props.indices.nodes, IndexId);
            if (indexNodes && indexNodes.nodes && indexNodes.nodes.length > 0) {
                return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                    {
                        indexNodes.nodes.map(item => {
                            return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                    <div className="shop-item">
                                        <div className="product-thumb">
                                            {
                                                item.icon ?
                                                    <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                    :
                                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                            }
                                        </div>
                                        <div className="product-content">
                                            <h5 className="title">{item.label}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })
                    }
                </div>
            }
        }
        const { t } = this.props;

        return <div className="product-list-item w-100" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-telescope"></i>
                </div>
                <h4 className="display-3 subtitle">
                    {t("Pages.Books.NotFound")}
                </h4>
            </div>
        </div>;
    }

    render() {
        const { t, startFrom } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let currentId = (this.state.currentItem) ? this.state.currentItem.id : 0;

        let start = 4;
        if (startFrom) {
            start = startFrom;
        }

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.Books")} | {t('alarabeyya')}</title>

                    <meta property="og:title" content={t("PageHeader.PageHeader") + ' | ' + t('alarabeyya')} />
                    <meta property="twitter:title" content={t("PageHeader.PageHeader") + ' | ' + t('alarabeyya')} />
                    <meta name="description" content={t('PageHeader.Books_desc')} />
                    <meta property="og:description" content={t('PageHeader.Books_desc')} />
                    <meta property="twitter:description" content={t('PageHeader.Books_desc')} />
                </Helmet>

                <section className="position-relative" id="main-books-section" style={{
                    minHeight: '100vh'
                }}>

                    <Library />
                    <div className="container padding-top padding-bottom">
                        <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                            <div className="section-header mb-4">
                                <span className="cate custome-title"> {t('PageHeader.StudyTrack')} </span>
                                <div className="display-4 h3 custome-title" ><i className="mx-3 fal fa-book "></i> {t('PageHeader.Books')} {this.props.title ? ` - ${this.props.title}` : ""}</div>
                            </div>
                        </div>
                        <div data-aos="fade-bottom" data-aos-easing="linear">
                            <SubMenu />
                        </div>
                        <div className="row mb-30-none grid">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        currentId={currentId}
                                        data={indices.nodes}
                                        toRemove={indices.toRemove}
                                        type='Books'
                                        Level={3}
                                        startFrom={start}
                                        setCurrentItem={this.setCurrentItem}></TreeView>
                                    : ""
                                }
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Books.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                                    {this.renderBooks()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, { fetchIndices, fetch })(withTranslation('common')(Books));
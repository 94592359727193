import React from "react";
import DropTarget from "../../../Question/View/DragDrop/DropTarget";
import GameObjects from "../../../Question/View/GameObjects";

class DropObject extends React.Component {

    onHit = e => {
        this.props.setDropTarget(e, this.props.item, this.props.TrueSound, this.props.FalseSound);
    }

    render() {
        return <DropTarget targetKey={"DragDrop-game"} onHit={this.onHit} >
            <GameObjects {...this.props} type="dragdrop-static" />
        </DropTarget>;
    }
}

export default DropObject;
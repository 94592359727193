import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GameObjects from "../../GameObjects";

class Sky extends React.Component {

    state = {
        hover: false
    };

    setHover = hover => {
        this.setState({
            hover
        });
    }

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            background: 'linear-gradient(180deg, rgba(57,179,246,1) 0%, rgba(60,197,250,1) 100%)',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud9" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(55% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud8" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud7" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(20% - 169px)',
                top: '50%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud6" scale={0.5} /></div>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud5" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(70% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud4" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud2" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(50% - 169px)',
                top: '70%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud3" scale={0.5} /></div>


            <div className="position-absolute d-none d-md-block" style={{
                top: `calc(50% - 306px)`,
                right: `calc(50% - 172px)`
            }}>
                <div className="position-relative">

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `108px`,
                        right: `29px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/1.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `26px`,
                        right: `94px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/2.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `0px`,
                        right: `calc(50% - 25px)`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/3.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `181px`,
                        left: `-22px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/5.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `282px`,
                        left: `281px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/6.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `71px`,
                        left: `-12px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/9.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `290px`,
                        left: `27px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/8.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `182px`,
                        right: `-13px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/7.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        top: `17px`,
                        left: `87px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/10.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `515px`,
                        right: `50px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/11.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `495px`,
                        right: `248px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/12.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `428px`,
                        right: `-15px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/13.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `439px`,
                        right: `325px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/14.png" />
                    </div>


                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `335px`,
                        left: `300px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/15.png" />
                    </div>
                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `329px`,
                        left: `0px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/16.png" />
                    </div>

                    <div className="position-absolute" style={{
                        transform: 'scale(0.8)',
                        bottom: `220px`,
                        right: `50px`
                    }}>
                        <LazyLoadImage src="/assets/images/Element/17.png" />
                    </div>
                    <GameObjects objectCss="BoyFlyingWithBalloon" scale="0.8" />
                </div>
            </div>

        </div>
    }
}
export default Sky;
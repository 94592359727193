import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import EditModal from "./Modal/EditModal";
import { connect } from 'react-redux';
import { hideUpdateModal, showUpdateModal, removeQuestionAnswer, undoRemoveQuestionAnswer, clearQuestionAnswer, removeQuestionCategory, undoRemoveQuestionCategory, clearQuestionCategory } from './actions/';

class List extends React.Component {

    state = {
        showSelectModal: false,
        showMultiSelectModal: false,
        showOrderModal: false,
        showFillsModal: false,
        showFillsListModal: false,
        showFillsChooseModal: false,
        showQuestionnaireModal: false,
        showMatchesModal: false,
        showClassificationModal: false,
        showFillsFreeTextModal: false,
        showTextModal: false,
        showClickOrDoubleModal: false,
        showClassificationClauseModal: false,
        showshowFillsMultipuleModal: false,
        showGameFallDownModal: false,
        showGameShootFallDownModal: false,
        showGameShootHorizontalModal: false,
        showGameFillModal: false,
        showGamePullMultiSelect: false,
        showMultiSelectShootingFallDownGame: false,
        showMultiSelectShootHorizontalGame: false,
        showMultiSelectFillDownGame: false,
        showShootDownGameModal: false,
        showGamePullUpMultiSelect: false,
        showGamePullUpMultiSelectSpaceShip: false,
        showCatchHorizontalMultiSelectGame: false,
        showMultiTypeModal: false
    };

    static getDerivedStateFromProps(props, state) {
        if (props.question) {
            if (props.question.typeId === 1) {
                state.showSelectModal = props.question.showModal;
            } else if (props.question.typeId === 2) {
                state.showOrderModal = props.question.showModal;
            } else if (props.question.typeId === 3) {
                state.showFillsModal = props.question.showModal;
            } else if (props.question.typeId === 4) {
                state.showFillsFreeTextModal = props.question.showModal;
            } else if (props.question.typeId === 5) {
                state.showFillsListModal = props.question.showModal;
            } else if (props.question.typeId === 6) {
                state.showFillsChooseModal = props.question.showModal;
            } else if (props.question.typeId === 7) {
                state.showMultiSelectModal = props.question.showModal;
            } else if (props.question.typeId === 8) {
                state.showQuestionnaireModal = props.question.showModal;
            } else if (props.question.typeId === 9) {
                state.showMatchesModal = props.question.showModal;
            } else if (props.question.typeId === 10) {
                state.showClassificationModal = props.question.showModal;
            } else if (props.question.typeId === 11) {
                state.showTextModal = props.question.showModal;
            } else if (props.question.typeId === 12) {
                state.showClickOrDoubleModal = props.question.showModal;
            } else if (props.question.typeId === 13) {
                state.showClassificationClauseModal = props.question.showModal;
            } else if (props.question.typeId === 14) {
                state.showshowFillsMultipuleModal = props.question.showModal;
            } else if (props.question.typeId === 15) {
                state.showGameDargDropModal = props.question.showModal;
            } else if (props.question.typeId === 16) {
                state.showGameShootingModal = props.question.showModal;
            } else if (props.question.typeId === 17) {
                state.showGameFallDownModal = props.question.showModal;
            } else if (props.question.typeId === 18) {
                state.showGameShootFallDownModal = props.question.showModal;
            } else if (props.question.typeId === 19) {
                state.showGameMultiSelect = props.question.showModal;
            } else if (props.question.typeId === 20) {
                state.showGameFillModal = props.question.showModal;
            } else if (props.question.typeId === 21) {
                state.showGameShootHorizontalModal = props.question.showModal;
            } else if (props.question.typeId === 22) {
                state.showGameDropVertical = props.question.showModal;
            } else if (props.question.typeId === 23) {
                state.showGameDropVerticalMultiSelect = props.question.showModal;
            } else if (props.question.typeId === 24) {
                state.showGamePullMultiSelect = props.question.showModal;
            } else if (props.question.typeId === 25) {
                state.showMultiSelectShootingFallDownGame = props.question.showModal;
            } else if (props.question.typeId === 26) {
                state.showMultiSelectShootHorizontalGame = props.question.showModal;
            } else if (props.question.typeId === 27) {
                state.showMultiSelectFillDownGame = props.question.showModal;
            } else if (props.question.typeId === 28) {
                state.showShootDownGameModal = props.question.showModal;
            } else if (props.question.typeId === 29) {
                state.showGamePullUpMultiSelectSpaceShip = props.question.showModal;
            } else if (props.question.typeId === 30) {
                state.showGamePullUpMultiSelect = props.question.showModal;
            } else if (props.question.typeId === 31) {
                state.showCatchHorizontalMultiSelectGame = props.question.showModal;
            } else if (props.question.typeId === 32) {
                state.showMultiTypeModal = props.question.showModal;
            }
        }
        return state;
    }

    hideModal = () => {
        this.props.hideUpdateModal();
        this.setState({
            showSelectModal: false,
            showFillsModal: false,
            showFillsListModal: false,
            showFillsFreeTextModal: false,
            showFillsChooseModal: false,
            showMultiSelectModal: false,
            showQuestionnaireModal: false,
            showClassificationModal: false,
            showMatchesModal: false,
            showOrderModal: false,
            showTextModal: false,
            showshowFillsMultipuleModal: false,
            showClickOrDoubleModal: false,
            showGameShootingModal: false,
            showClassificationClauseModal: false,
            showGameShootFallDownModal: false,
            showGameDargDropModal: false,
            showGameFallDownModal: false,
            showGameMultiSelect: false,
            showGameFillModal: false,
            showGameShootHorizontalModal: false,
            showGameDropVertical: false,
            showGameDropVerticalMultiSelect: false,
            showGamePullMultiSelect: false,
            showMultiSelectShootingFallDownGame: false,
            showMultiSelectShootHorizontalGame: false,
            showMultiSelectFillDownGame: false,
            showShootDownGameModal: false,
            showGamePullUpMultiSelectSpaceShip: false,
            showGamePullUpMultiSelect: false,
            showCatchHorizontalMultiSelectGame: false,
            showMultiTypeModal: false
        });
    }

    showSelectModal = () => {
        this.setState({ showSelectModal: true });
        this.props.showUpdateModal(0, 1);
    }
    showOrderModal = () => {
        this.setState({ showOrderModal: true });
        this.props.showUpdateModal(0, 2);
    }
    showFillsModal = () => {
        this.setState({ showFillsModal: true });
        this.props.showUpdateModal(0, 3);
    }
    showFillsFreeTextModal = () => {
        this.setState({ showFillsFreeTextModal: true });
        this.props.showUpdateModal(0, 4);
    }
    showFillsListModal = () => {
        this.setState({ showFillsListModal: true });
        this.props.showUpdateModal(0, 5);
    }
    showFillsChooseModal = () => {
        this.setState({ showFillsChooseModal: true });
        this.props.showUpdateModal(0, 6);
    }
    showMultiSelectModal = () => {
        this.setState({ showMultiSelectModal: true });
        this.props.showUpdateModal(0, 7);
    }
    showQuestionnaireModal = () => {
        this.setState({ showQuestionnaireModal: true });
        this.props.showUpdateModal(0, 8);
    }
    showMatchesModal = () => {
        this.setState({ showMatchesModal: true });
        this.props.showUpdateModal(0, 9);
    }
    showClassificationModal = () => {
        this.setState({ showClassificationModal: true });
        this.props.showUpdateModal(0, 10);
    }
    showTextModal = () => {
        this.setState({ showTextModal: true });
        this.props.showUpdateModal(0, 11);
    }
    showClickOrDoubleModal = () => {
        this.setState({ showClickOrDoubleModal: true });
        this.props.showUpdateModal(0, 12);
    }
    showClassificationClauseModal = () => {
        this.setState({ showClassificationClauseModal: true });
        this.props.showUpdateModal(0, 13);
    }
    showshowFillsMultipuleModal = () => {
        this.setState({ showshowFillsMultipuleModal: true });
        this.props.showUpdateModal(0, 14);
    }
    showGameDargDropModal = () => {
        this.setState({ showGameDargDropModal: true });
        this.props.showUpdateModal(0, 15);
    }
    showGameShootingModal = () => {
        this.setState({ showGameShootingModal: true });
        this.props.showUpdateModal(0, 16);
    }
    showGameFallDownModal = () => {
        this.setState({ showGameFallDownModal: true });
        this.props.showUpdateModal(0, 17);
    }
    showGameShootFallDownModal = () => {
        this.setState({ showGameShootFallDownModal: true });
        this.props.showUpdateModal(0, 18);
    }
    showGameMultiSelect = () => {
        this.setState({ showGameMultiSelect: true });
        this.props.showUpdateModal(0, 19);
    }
    showGameFillModal = () => {
        this.setState({ showGameFillModal: true });
        this.props.showUpdateModal(0, 20);
    }
    showGameShootHorizontalModal = () => {
        this.setState({ showGameShootHorizontalModal: true });
        this.props.showUpdateModal(0, 21);
    }
    showGameDropVertical = () => {
        this.setState({ showGameDropVertical: true });
        this.props.showUpdateModal(0, 22);
    }
    showGameDropVerticalMultiSelect = () => {
        this.setState({ showGameDropVerticalMultiSelect: true });
        this.props.showUpdateModal(0, 23);
    }
    showGamePullMultiSelect = () => {
        this.setState({ showGamePullMultiSelect: true });
        this.props.showUpdateModal(0, 24);
    }
    showMultiSelectShootingFallDownGame = () => {
        this.setState({ showMultiSelectShootingFallDownGame: true });
        this.props.showUpdateModal(0, 25);
    }
    showMultiSelectShootHorizontalGame = () => {
        this.setState({ showMultiSelectShootHorizontalGame: true });
        this.props.showUpdateModal(0, 26);
    }
    showMultiSelectFillDownGame = () => {
        this.setState({ showMultiSelectFillDownGame: true });
        this.props.showUpdateModal(0, 27);
    }
    showShootDownGameModal = () => {
        this.setState({ showShootDownGameModal: true });
        this.props.showUpdateModal(0, 28);
    }
    showGamePullUpMultiSelectSpaceShip = () => {
        this.setState({ showGamePullUpMultiSelectSpaceShip: true });
        this.props.showUpdateModal(0, 29);
    }
    showGamePullUpMultiSelect = () => {
        this.setState({ showGamePullUpMultiSelect: true });
        this.props.showUpdateModal(0, 30);
    }
    showCatchHorizontalMultiSelectGame = () => {
        this.setState({ showCatchHorizontalMultiSelectGame: true });
        this.props.showUpdateModal(0, 31);
    }
    showMultiTypeModal = () => {
        this.setState({ showMultiTypeModal: true });
        this.props.showUpdateModal(0, 32);
    }

    renderSelectModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.SingleSelect')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.SingleSelect')}>{t('Pages.Lessons.Questions.type.SingleSelect')}</h4>
                        <Link to="#" onClick={() => this.showSelectModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.SingleSelect'), 1, this.state.showSelectModal)}
                    </div>
                </div>
            </>
        );
    }
    renderOrderModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.Order')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.Order')}>{t('Pages.Lessons.Questions.type.Order')}</h4>
                        <Link to="#" onClick={() => this.showOrderModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.Order'), 2, this.state.showOrderModal)}
                    </div>
                </div>
            </>
        );
    }
    renderFillsModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.Fills')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.Fills')}>{t('Pages.Lessons.Questions.type.Fills')}</h4>
                        <Link to="#" onClick={() => this.showFillsModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.Fills'), 3, this.state.showFillsModal)}
                    </div>
                </div>
            </>
        );
    }
    renderFillsTextModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.FillsFreeText')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.FillsFreeText')}>{t('Pages.Lessons.Questions.type.FillsFreeText')}</h4>
                        <Link to="#" onClick={() => this.showFillsFreeTextModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.FillsFreeText'), 4, this.state.showFillsFreeTextModal)}
                    </div>
                </div>
            </>
        );
    }
    renderMultiTypeModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiType')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiType')}>{t('Pages.Lessons.Questions.type.MultiType')}</h4>
                        <Link to="#" onClick={() => this.showMultiTypeModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiType'), 32, this.state.showMultiTypeModal)}
                    </div>
                </div>
            </>
        );
    }
    renderFillsListModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.FillsList')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.FillsList')}>{t('Pages.Lessons.Questions.type.FillsList')}</h4>
                        <Link to="#" onClick={() => this.showFillsListModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.FillsList'), 5, this.state.showFillsListModal)}
                    </div>
                </div>
            </>
        );
    }
    renderFillsChooseModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.FillsChoose')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.FillsChoose')}>{t('Pages.Lessons.Questions.type.FillsChoose')}</h4>
                        <Link to="#" onClick={() => this.showFillsChooseModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.FillsChoose'), 6, this.state.showFillsChooseModal)}
                    </div>
                </div>
            </>
        );
    }
    renderMultiSelectModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelect')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelect')}>{t('Pages.Lessons.Questions.type.MultiSelect')}</h4>
                        <Link to="#" onClick={() => this.showMultiSelectModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelect'), 7, this.state.showMultiSelectModal)}
                    </div>
                </div>
            </>
        );
    }
    renderQuestionnaireModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.Questionnaire')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.Questionnaire')}>{t('Pages.Lessons.Questions.type.Questionnaire')}</h4>
                        <Link to="#" onClick={() => this.showQuestionnaireModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.Questionnaire'), 8, this.state.showQuestionnaireModal)}
                    </div>
                </div>
            </>
        );
    }
    renderMatchesModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.Matches')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.Matches')}>{t('Pages.Lessons.Questions.type.Matches')}</h4>
                        <Link to="#" onClick={() => this.showMatchesModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.Matches'), 9, this.state.showMatchesModal)}
                    </div>
                </div>
            </>
        );
    }
    renderClassificationModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.Classification')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.Classification')}>{t('Pages.Lessons.Questions.type.Classification')}</h4>
                        <Link to="#" onClick={() => this.showClassificationModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.Classification'), 10, this.state.showClassificationModal)}
                    </div>
                </div>
            </>
        );
    }
    renderTextModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.Text')}>
                    <div className="thumb">
                        <i className="fa fa-text"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.Text')}>{t('Pages.Lessons.Questions.type.Text')}</h4>
                        <Link to="#" onClick={() => this.showTextModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.Text'), 11, this.state.showTextModal)}
                    </div>
                </div>
            </>
        );
    }
    renderClickOrDoubleModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.ClickOrDouble')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.ClickOrDouble')}>{t('Pages.Lessons.Questions.type.ClickOrDouble')}</h4>
                        <Link to="#" onClick={() => this.showClickOrDoubleModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.ClickOrDouble'), 12, this.state.showClickOrDoubleModal)}
                    </div>
                </div>
            </>
        );
    }
    renderClassificationClauseModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.ClassificationClause')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.ClassificationClause')}>{t('Pages.Lessons.Questions.type.ClassificationClause')}</h4>
                        <Link to="#" onClick={() => this.showClassificationClauseModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.ClassificationClause'), 13, this.state.showClassificationClauseModal)}
                    </div>
                </div>
            </>
        );
    }
    renderFillsMultipuleModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.FillsMultipule')}>
                    <div className="thumb">
                        <i className="fa fa-question"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.FillsMultipule')}>{t('Pages.Lessons.Questions.type.FillsMultipule')}</h4>
                        <Link to="#" onClick={() => this.showshowFillsMultipuleModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.FillsMultipule'), 14, this.state.showshowFillsMultipuleModal)}
                    </div>
                </div>
            </>
        );
    }

    renderGameDargDropModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.DragDropGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.DragDropGame')}>{t('Pages.Lessons.Questions.type.DragDropGame')}</h4>
                        <Link to="#" onClick={() => this.showGameDargDropModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.DragDropGame'), 15, this.state.showGameDargDropModal)}
                    </div>
                </div>
            </>
        );
    }
    renderGameShootingModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.ShootingGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.ShootingGame')}>{t('Pages.Lessons.Questions.type.ShootingGame')}</h4>
                        <Link to="#" onClick={() => this.showGameShootingModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.ShootingGame'), 16, this.state.showGameShootingModal)}
                    </div>
                </div>
            </>
        );
    }
    renderGameFallDownModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.FallDownGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.FallDownGame')}>{t('Pages.Lessons.Questions.type.FallDownGame')}</h4>
                        <Link to="#" onClick={() => this.showGameFallDownModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.FallDownGame'), 17, this.state.showGameFallDownModal)}
                    </div>
                </div>
            </>
        );
    }

    renderGameSFallDownModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.ShootingFallDownGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.ShootingFallDownGame')}>{t('Pages.Lessons.Questions.type.ShootingFallDownGame')}</h4>
                        <Link to="#" onClick={() => this.showGameShootFallDownModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.ShootingFallDownGame'), 18, this.state.showGameShootFallDownModal)}
                    </div>
                </div>
            </>
        );
    }

    renderGameMultiSelect() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectGame')}>{t('Pages.Lessons.Questions.type.MultiSelectGame')}</h4>
                        <Link to="#" onClick={() => this.showGameMultiSelect()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectGame'), 19, this.state.showGameMultiSelect)}
                    </div>
                </div>
            </>
        );
    }

    renderGameShootHorizontalModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.ShootHorizontalGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.ShootHorizontalGame')}>{t('Pages.Lessons.Questions.type.ShootHorizontalGame')}</h4>
                        <Link to="#" onClick={() => this.showGameShootHorizontalModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.ShootHorizontalGame'), 21, this.state.showGameShootHorizontalModal)}
                    </div>
                </div>
            </>
        );
    }

    renderGameDropVertical() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.DropVerticalGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.DropVerticalGame')}>{t('Pages.Lessons.Questions.type.DropVerticalGame')}</h4>
                        <Link to="#" onClick={() => this.showGameDropVertical()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.DropVerticalGame'), 22, this.state.showGameDropVertical)}
                    </div>
                </div>
            </>
        );
    }

    renderGameDropVerticalMultiSelect() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectDropVerticalGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectDropVerticalGame')}>{t('Pages.Lessons.Questions.type.MultiSelectDropVerticalGame')}</h4>
                        <Link to="#" onClick={() => this.showGameDropVerticalMultiSelect()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectDropVerticalGame'), 23, this.state.showGameDropVerticalMultiSelect)}
                    </div>
                </div>
            </>
        );
    }

    renderGamePullMultiSelect() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectPullGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectPullGame')}>{t('Pages.Lessons.Questions.type.MultiSelectPullGame')}</h4>
                        <Link to="#" onClick={() => this.showGamePullMultiSelect()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectPullGame'), 24, this.state.showGamePullMultiSelect)}
                    </div>
                </div>
            </>
        );
    }


    renderMultiSelectShootingFallDownGame() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectShootingFallDownGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectShootingFallDownGame')}>{t('Pages.Lessons.Questions.type.MultiSelectShootingFallDownGame')}</h4>
                        <Link to="#" onClick={() => this.showMultiSelectShootingFallDownGame()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectShootingFallDownGame'), 25, this.state.showMultiSelectShootingFallDownGame)}
                    </div>
                </div>
            </>
        );
    }

    renderMultiSelectShootHorizontalGame() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectShootHorizontalGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectShootHorizontalGame')}>{t('Pages.Lessons.Questions.type.MultiSelectShootHorizontalGame')}</h4>
                        <Link to="#" onClick={() => this.showMultiSelectShootHorizontalGame()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectShootHorizontalGame'), 26, this.state.showMultiSelectShootHorizontalGame)}
                    </div>
                </div>
            </>
        );
    }

    renderGameFillModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.GameFill')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.GameFill')}>{t('Pages.Lessons.Questions.type.GameFill')}</h4>
                        <Link to="#" onClick={() => this.showGameFillModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.GameFill'), 20, this.state.showGameFillModal)}
                    </div>
                </div>
            </>
        );
    }

    renderMultiSelectFillDownGame() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectFallDownGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectFallDownGame')}>{t('Pages.Lessons.Questions.type.MultiSelectFallDownGame')}</h4>
                        <Link to="#" onClick={() => this.showMultiSelectFillDownGame()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectFallDownGame'), 27, this.state.showMultiSelectFillDownGame)}
                    </div>
                </div>
            </>
        );
    }

    renderShootDownGameModal() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.ShootDownGameModal')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.ShootDownGameModal')}>{t('Pages.Lessons.Questions.type.ShootDownGameModal')}</h4>
                        <Link to="#" onClick={() => this.showShootDownGameModal()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.ShootDownGameModal'), 28, this.state.showShootDownGameModal)}
                    </div>
                </div>
            </>
        );
    }


    renderGamePullUpMultiSelectSpaceShip() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectPullUpSpaceShipGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectPullUpSpaceShipGame')}>{t('Pages.Lessons.Questions.type.MultiSelectPullUpSpaceShipGame')}</h4>
                        <Link to="#" onClick={() => this.showGamePullUpMultiSelectSpaceShip()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectPullUpSpaceShipGame'), 29, this.state.showGamePullUpMultiSelectSpaceShip)}
                    </div>
                </div>
            </>
        );
    }

    renderGamePullUpMultiSelect() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.MultiSelectPullUpGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.MultiSelectPullUpGame')}>{t('Pages.Lessons.Questions.type.MultiSelectPullUpGame')}</h4>
                        <Link to="#" onClick={() => this.showGamePullUpMultiSelect()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.MultiSelectPullUpGame'), 30, this.state.showGamePullUpMultiSelect)}
                    </div>
                </div>
            </>
        );
    }

    renderCatchHorizontalMultiSelectGame() {
        const { t } = this.props;
        return (
            <>
                <div className="single-product-item d-flex flex-wrap align-items-center" title={t('Pages.Lessons.Questions.type.CatchHorizontalMultiSelectGame')}>
                    <div className="thumb">
                        <i className="fa-regular fa-gamepad-modern"></i>
                    </div>
                    <div className="content">
                        <h4 className="title" title={t('Pages.Lessons.Questions.type.CatchHorizontalMultiSelectGame')}>{t('Pages.Lessons.Questions.type.CatchHorizontalMultiSelectGame')}</h4>
                        <Link to="#" onClick={() => this.showCatchHorizontalMultiSelectGame()}> {t('Pages.Lessons.Questions.select')}</Link>
                        {this.renderEditModal(t('Pages.Lessons.Questions.type.CatchHorizontalMultiSelectGame'), 31, this.state.showCatchHorizontalMultiSelectGame)}
                    </div>
                </div>
            </>
        );
    }

    renderEditModal(title, typeId, show) {
        return (
            <EditModal
                isForGame={this.props.isForGame}
                key={typeId}
                undoRemoveQuestionAnswer={this.props.undoRemoveQuestionAnswer}
                updateCurrentQuestion={this.props.updateCurrentQuestion}
                removeQuestionAnswer={this.props.removeQuestionAnswer}
                clearQuestionAnswer={this.props.clearQuestionAnswer}

                undoRemoveQuestionCategory={this.props.undoRemoveQuestionCategory}
                removeQuestionCategory={this.props.removeQuestionCategory}
                clearQuestionCategory={this.props.clearQuestionCategory}

                setQuestionValue={this.props.setQuestionValue}
                question={this.props.question}
                LessonId={this.props.LessonId}
                FontSize={this.props.FontSize}
                typeId={typeId}
                handleClose={this.hideModal}
                title={title}
                show={show} />
        );
    }

    render() {
        const { t, LessonId, isForGame } = this.props;
        if (LessonId && LessonId !== "0") {
            return (
                <div className="cart-sidebar-area p-3">
                    <div className="top-content">
                        <span className="side-sidebar-close-btn"><i className="fas fa-times"></i></span>
                        <h4 className="text-white">
                            {
                                isForGame ?
                                    t('Pages.Games.forms.LessonInfo.ChooseType')
                                    :
                                    t('Pages.Lessons.AddQuestion')
                            }
                        </h4>
                    </div>
                    <div className="bottom-content">
                        <div className="cart-products">
                            {
                                isForGame ?
                                    <>
                                        {this.renderGameDargDropModal()}
                                        {this.renderGameFallDownModal()}
                                        {this.renderMultiSelectFillDownGame()}
                                        {this.renderGameSFallDownModal()}
                                        {this.renderMultiSelectShootingFallDownGame()}
                                        {this.renderGameDropVertical()}
                                        {this.renderGameDropVerticalMultiSelect()}
                                        {this.renderGamePullMultiSelect()}
                                        {this.renderGameMultiSelect()}
                                        {this.renderGameFillModal()}
                                        {this.renderGameShootHorizontalModal()}
                                        {this.renderMultiSelectShootHorizontalGame()}
                                        {this.renderGameShootingModal()}
                                        {this.renderShootDownGameModal()}
                                        {this.renderGamePullUpMultiSelect()}
                                        {this.renderGamePullUpMultiSelectSpaceShip()}
                                        {this.renderCatchHorizontalMultiSelectGame()}
                                    </>
                                    :
                                    <>
                                        {this.renderTextModal()}
                                        {this.renderSelectModal()}
                                        {this.renderMultiSelectModal()}
                                        {this.renderQuestionnaireModal()}
                                        {this.renderOrderModal()}
                                        {this.renderFillsModal()}
                                        {this.renderFillsMultipuleModal()}
                                        {this.renderFillsTextModal()}
                                        {this.renderFillsListModal()}
                                        {this.renderFillsChooseModal()}
                                        {this.renderMatchesModal()}
                                        {this.renderClassificationModal()}
                                        {this.renderClickOrDoubleModal()}
                                        {this.renderClassificationClauseModal()}
                                        {this.renderMultiTypeModal()}
                                    </>

                            }
                        </div>
                    </div>
                </div>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { hideUpdateModal, showUpdateModal, removeQuestionAnswer, undoRemoveQuestionAnswer, clearQuestionAnswer, removeQuestionCategory, undoRemoveQuestionCategory, clearQuestionCategory })(withTranslation('common')(List));
import React from "react";
import { withTranslation } from 'react-i18next'

class QuestionTitle extends React.Component {

    render() {
        const { onEdit, mark, title, index, t, isExamQuestion, ResetAnswer } = this.props;
        return (
            <>
                <div className="row row-col-2">
                    <div className="col">
                        <div className="text-right text-dark h6 font-weight-bold">
                            <i className="icon fas fa-question"></i>
                            <u>
                                {t('Pages.Lessons.Questions.forms.Question')}
                                {index}
                            </u> :
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-left title text-danger h6">
                            <i className="icon fas fa-tally"></i> {t('Pages.Lessons.Questions.forms.QuestionMark.title')} : {mark}
                            {
                                (onEdit === true) ?
                                    (this.props.updateVersion) ?
                                        <i onClick={() => this.props.updateVersion()} title={t('Pages.Lessons.Questions.sync')} className="mx-2 cursor-pointer text-info fad fa-sync"></i> : ""
                                    :
                                    (ResetAnswer && isExamQuestion !== true) ?
                                        <>
                                            <div
                                                title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                                                className="btn btn-dark btn-sm text-center mx-1"
                                                onClick={() => this.props.ResetAnswer()}>
                                                <i className="m-0 fas fa-repeat"></i>
                                            </div>
                                        </> : ""
                            }
                        </div>
                    </div>
                </div>
                <div className="questionTitle">
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>
                </div>
            </>
        );
    }
}

export default withTranslation('common')(QuestionTitle);
import React from "react";
import { withTranslation } from 'react-i18next'
import ReactPixel from 'react-facebook-pixel';

class Sprintful extends React.Component {

    componentDidMount = () => {
        window.Sprintful.initIframe();
        ReactPixel.init('484815450795869');
        ReactPixel.track('PageView', "School Register Sprintful Form");
        ReactPixel.track('lead', this.props.data.Email);
    }

    render() {
        const { data } = this.props;
        return (
            <>
                <div className="pt-5 w-100 mx-0">

                    <h4 className="title  d-flex justify-content-center align-items-center">
                        <i className="icon fa-solid fa-credit-card px-1 text-primary"></i>
                        حجز موعد
                    </h4>

                    <div
                        className="sprintful-inline-widget w-100"
                        data-copy-parents-query="false"
                        data-url={`https://on.sprintful.com/alarabeyya?hide-logo=false&hide-message=false&email=${data?.SchoolEmail}&name=${data?.FirstName + " " + data?.LastName}`}
                        style={{
                            height: '85vh'
                        }}></div>

                </div>
            </>
        );
    }
}

export default withTranslation('common')(Sprintful);
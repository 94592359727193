import React from "react";

import Edit from "../Lessons/LessonEdit";

class ExamsEdit extends React.Component {

    render() {
        return <>
            <Edit match={this.props.match}
                type="Exams"
                typeTreeView="Exams"></Edit>
        </>;
    }
}

export default ExamsEdit;
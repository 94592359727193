import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from "react-toastify";

import { userInitial } from '../../actions/';
import UserSubMenu from './UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import { myBillsInitial } from "../../actions/";
import BillItem from './BillItem';
import api from "../../../api/api";

class UserBills extends React.Component {

    constructor(props) {
        super(props);
        window.scrollToTop();
        this.props.myBillsInitial();
    }

    stop = postData => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/FinishPayProglobalPayment/`, postData).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-hand"></i>
                        {t('Pages.myBills.success_toast.Stop.Title')}</div>
                    <div className="h6">{t('Pages.myBills.success_toast.Stop.Body')}</div>
                </div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.scrollToTop();
            this.props.myBillsInitial();

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        });
    }

    renderBills() {
        const { t, bills, user } = this.props;

        if (bills && bills.bills) {

            if (bills.isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.myBills.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            return bills.bills.map(item =>
                <BillItem key={item.Id} item={item} stop={this.stop} IsForTest={user.IsForTest} />
            );

        }
        if (bills && bills.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.myBills.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }
        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.myBills.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    render() {
        const { t, user } = this.props;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.myBills.title')} | {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.myBills.title')}</h3>
                                        </div>
                                    </div>
                                </div>
                                {this.renderBills()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        bills: state.bills,
        user: state.user
    };
};

export default connect(mapStateToProps, { userInitial, myBillsInitial })(withTranslation('common')(UserBills));
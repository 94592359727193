const FilesReducers = (state = [], action) => {
    switch (action.type) {
        case "FILES_LOADING":
            return {
                files: state.files,
                current: null,
                toRemove: state.toRemove,
                isLoaded: false,
                lazyLoading: true
            };
        case "FILES_INITIAL":
            return {
                files: action.files,
                current: null,
                toRemove: [],
                isLoaded: true,
                lazyLoading: true
            };
        case "FILES_RESET":
            return {
                files: [],
                current: null,
                toRemove: [],
                isLoaded: true,
                lazyLoading: true
            };
        case "FILES_LAZY_LOADING_STOP":
            return {
                files: state.files,
                current: null,
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: false
            };
        case "FILES_LAZY_LOADING":
            return {
                files: state.files ? [...state.files, ...action.files] : action.files,
                current: null,
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: true
            };

        case "CLEAR_FILE_INFO":
            return {
                files: state.files,
                current: null,
                toRemove: [],
                isLoaded: true
            };
        case "FILE_INITIAL":
            return {
                files: state.files,
                code: action.code,
                current: action.file,
                toRemove: [],
                isLoaded: true
            };
        case "FILE_QUEUE_FOR_REMOVAL":
            return {
                files: state.files,
                current: null,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "FILES_UPDATE_FAVORITE":
            return {
                files: UpdateFileFavorite(state.files, action.id),
                current: null,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "FILES_UPDATE_ISVIEW":
            return {
                files: UpdateFileIsView(state.files, action.id),
                current: null,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "FILE_CLEAN_NODES":
            return {
                files: state.toRemove.some(v => v === action.id) ? state.files.filter(v => v.Id !== action.id) : state.files,
                current: null,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "FILE_UNDO":
            return {
                files: state.files,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "FILES_UPDATE_SORT":
            return {
                files: UpdateFilesSort(action.sort, state.files, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "FILES_CREATE":
            return {
                //     files: CreateNewNode(action.newNode, state.files, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

function UpdateFileIsView(files, id) {
    let copy = [];
    files.map(file => {
        if (file.Id === id) {
            file = {
                ...file,
                IsView: true
            };
        }
        copy.push({
            ...file
        });
        return '';
    });
    return copy;
}

function UpdateFileFavorite(files, id) {
    let copy = [];
    files.map(file => {
        if (file.Id === id) {
            file = {
                ...file,
                isFavorite: !file.isFavorite
            };
        }
        copy.push({
            ...file
        });
        return '';
    });
    return copy;
}

function UpdateFilesSort(sort, files, id) {
    let copy = [];
    files.map(file => {
        if (file.Id === id) {
            file = {
                ...file,
                Sort: sort
            };
        }
        copy.push({
            ...file
        });
        return '';
    });
    return copy;
}

export default FilesReducers;
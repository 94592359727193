import React from "react";
import Spritesheet from 'react-responsive-spritesheet';
import TextBox from "../../TextBox";

import img from './images/1.png';

const width = 4214 / 15;
const height = 300 / 1;

const KidsOnCamel = (props) => {

    const startAt = 1;
    const endAt = 15;
    const steps = 15;

    return <div className="position-relative d-flex justify-content-center flex-column align-items-center" >
        <div className={`mb-0 d-inline-block`} >
            <div className={`${props.isSpin === "true" || props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${props.transformOriginX ? props.transformOriginX : "center"} ${props.transformOriginY ? props.transformOriginY : "center"}`,
                    transform: `
                        ${props.scale ? `scale(${props.scale})` : ""}
                        ${props.flipX === "true" || props.flipX === true ? "scaleX(-1)" : ""}
                        ${props.rotate ? `rotate(${props.rotate}deg)` : ""}
                        `
                }}>
                    <Spritesheet
                        image={img}
                        widthFrame={width}
                        heightFrame={height}
                        steps={steps}
                        startAt={startAt}
                        endAt={endAt}
                        fps={15}
                        autoplay={true}
                        loop={true}
                    />
                </div>
            </div>
        </div>
        <TextBox {...props} />
    </div >
}

export default KidsOnCamel;
import React from "react";
import { withTranslation } from 'react-i18next'
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import IndicesModal from "./Modal/IndicesModal";
import { toast } from 'react-toastify';
import api from "../../../../api/api";
import TreeView from "./TreeView";
import DragDropModal from "./Modal/DragDropModal";
import {
    fetchIndices as fetch,
    removeIndices as remove,
    setIndices as set,
    undoIndices as undo,
    clearIndices as clear,
    updateIndices as update,
    createIndices as create
} from './actions';
class UserGuideIndices extends React.Component {

    state = { showIndicesModal: false, showDragDropModal: false, currentItem: null };

    constructor(props) {
        super(props);

        this.state = {
            showIndicesModal: false,
            currentItem: null,
            showDragDropModal: false,
            IndicesForm: {
                id: 0,
                count: 0,
                label: '',
                icon: '',
                isActive: true,
            }
        }
        this._toastId = new React.createRef();
    }

    showNew = () => {
        this.setState({
            showIndicesModal: true,
            IndicesForm: {
                id: 0,
                count: 0,
                label: '',
                icon: '',
                isActive: true,
            }
        });
    }

    handleCloseModal = () => {
        this.setState({
            showIndicesModal: false,
            showDragDropModal: false
        });
    }

    addNewNode = (node) => {
        const id = (this.state.currentItem) ? this.state.currentItem.id : 0;
        this.props.create(node, id);
    }

    setIsActive = value => {
        this.setState({
            IndicesForm: {
                ...this.state.IndicesForm,
                isActive: value
            }
        });
    }

    setName = value => {
        this.setState({
            IndicesForm: {
                ...this.state.IndicesForm,
                label: value
            }
        });
    }

    setIcon = value => {
        this.setState({
            IndicesForm: {
                ...this.state.IndicesForm,
                icon: value
            }
        });
    }

    editNode = (node) => {
        const id = this.state.currentItem.id;
        this.props.update(node, id);
    }

    showSort = () => {
        this.setState({
            showDragDropModal: true
        });
    }

    showEdit = () => {
        if (this.state.currentItem) {
            this.setState({
                showIndicesModal: true,
                IndicesForm: {
                    id: this.state.currentItem.id,
                    count: this.state.currentItem.count,
                    label: this.state.currentItem.label,
                    icon: this.state.currentItem.icon,
                    isActive: this.state.currentItem.isActive,
                }
            });
        }
    }

    closeSelect = () => {
        this.setState({ currentItem: '' });
    }

    setSort = value => {
        if (this.state.currentItem) {
            this.setState({
                currentItem: {
                    ...this.state.currentItem,
                    nodes: value
                }
            });
            // this.state.currentItem.nodes = value;
        } else {
            this.props.set(value);
        }

        this.setState({
            showDragDropModal: false
        });
    }

    editNode = (node) => {
        const id = this.state.currentItem.id;
        this.props.update(node, id);
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
    }

    showDelete = () => {
        if (this.state.currentItem) {
            const id = this.state.currentItem.id;
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteIndices(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteIndices = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/UserGuideIndexes/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UserGuide.Indices.Delete.Title')}</div> <div className="h6">{t('Pages.UserGuide.Indices.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            this.setState({ currentItem: '' });
            this.props.clear(id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    render() {
        const { t, UserGuideIndices } = this.props;
        let FatherId = (this.state.currentItem) ? this.state.currentItem.id : 0;
        let nodes = (this.state.currentItem) ? this.state.currentItem.nodes : UserGuideIndices.nodes;
        return (
            <>

                {
                    this.state.showIndicesModal ?
                        <IndicesModal
                            show={this.state.showIndicesModal}
                            FatherId={FatherId}
                            addNewNode={this.addNewNode}
                            editNode={this.editNode}
                            setName={this.setName}
                            setIcon={this.setIcon}
                            setIsActive={this.setIsActive}
                            IndicesForm={this.state.IndicesForm}
                            handleClose={this.handleCloseModal} />
                        : ""
                }
                {
                    this.state.showDragDropModal ?
                        <DragDropModal
                            show={this.state.showDragDropModal}
                            nodes={nodes}
                            FatherId={FatherId}
                            setSort={this.setSort}
                            handleClose={this.handleCloseModal} />
                        : ""
                }
                <div className="container">
                    <ul className="filter">

                        <li onClick={() => this.showNew()} title={t("Pages.UserGuide.Indices.tools.new")}>
                            <Link to="#" className="cate">
                                <i className="fas fa-plus"></i>
                                {t("Pages.UserGuide.Indices.tools.new")}
                            </Link>
                        </li>
                        {
                            this.state.currentItem ?
                                <li onClick={() => this.showEdit()} title={t("Pages.UserGuide.Indices.tools.edit")}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-edit"></i>
                                        {t("Pages.UserGuide.Indices.tools.edit")}
                                    </Link>
                                </li>
                                : ""
                        }
                        <li onClick={() => this.showSort()} title={t("Pages.UserGuide.Indices.tools.sort")}>
                            <Link to="#" className="cate" >
                                <i className="fas fa-sort-numeric-up"></i>
                                {t("Pages.UserGuide.Indices.tools.sort")}
                            </Link>
                        </li>
                        {
                            this.state.currentItem ?
                                <>
                                    <li onClick={() => this.showDelete()} title={t("Pages.UserGuide.Indices.tools.delete")}>
                                        <Link to="#" className="cate" >
                                            <i className="fal fa-trash-alt"></i>
                                            {t("Pages.UserGuide.Indices.tools.delete")}
                                        </Link>
                                    </li>
                                    <li onClick={() => this.closeSelect()} title={t("Pages.UserGuide.Indices.tools.close")}>
                                        <Link to="#" className="cate" >
                                            <i className="fal fa-window-close"></i>
                                            {t("Pages.UserGuide.Indices.tools.close")}
                                        </Link>
                                    </li>
                                </> : ""
                        }
                    </ul>
                </div>
                <div className="col-sm-12 col-md-11  col-lg-10 col-xl-10 col-xxl-8 mx-auto">
                    <div className="widget widget-search" >
                        <div className="widget-body">
                            <TreeView
                                currentId={this.state.currentItem ? this.state.currentItem.id : 0}
                                data={UserGuideIndices.nodes}
                                toRemove={UserGuideIndices.toRemove}
                                setCurrentItem={this.setCurrentItem}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserGuideIndices: state.UserGuideIndices
    };
};
export default connect(mapStateToProps, { fetch, remove, set, undo, clear, update, create })(withTranslation('common')(UserGuideIndices));
import api from '../../../../api/api';

export const fetchLessons = (id, startDate, endDate, search, Active, page) => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/MyLessons/?id=${id}&skip=${page}&startDate=${startDate}&endDate=${endDate}&Active=${Active}&search=${search}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'LESSONS_LAZY_LOADING', lessons: response.data.data });
    } else {
        dispatch({ type: 'LESSONS_LAZY_LOADING_STOP' });
    }
}
export const resetFiles = () => async dispatch => {
    dispatch({ type: 'FILES_RESET' });
}
export const resetLessons = () => async dispatch => {
    dispatch({ type: 'LESSONS_RESET' });
}

export const setLessonFavorite = id => async dispatch => {
    const response = await api.put(`/User/${id}?IsForLesson=${true}`);
    dispatch({ type: 'LESSONS_UPDATE_FAVORITE', id: response.data });
}

export const fetchFiles = (startDate, endDate, search, Active, page) => async dispatch => {
    dispatch({ type: 'FILES_LOADING' });
    const response = await api.get(`/MyFiles/?skip=${page}&startDate=${startDate}&endDate=${endDate}&Active=${Active}&search=${search}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'FILES_LAZY_LOADING', files: response.data.data });
    } else {
        dispatch({ type: 'FILES_LAZY_LOADING_STOP' });
    }
}

export const setFileFavorite = id => async dispatch => {
    const response = await api.put(`/User/${id}?IsForLesson=${false}`);
    dispatch({ type: 'FILES_UPDATE_FAVORITE', id: response.data });
}

export const resetMissions = () => async dispatch => {
    dispatch({ type: 'MISSIONS_RESET' });
}

export const fetchMissions = (startDate, endDate, search, Active, page) => async dispatch => {
    dispatch({ type: 'MISSIONS_LOADING' });
    const response = await api.get(`/MyMissions/?startDate=${startDate}&endDate=${endDate}&Active=${Active}&skip=${page}&search=${search}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'MISSIONS_LAZY_LOADING', missions: response.data.data });
    } else {
        dispatch({ type: 'MISSIONS_LAZY_LOADING_STOP' });
    }
}

export const fetchMission = id => async dispatch => {
    try {
        const response = await api.get(`/MyMissions/${id}`);
        dispatch({ type: 'LESSON_INITIAL', lesson: response.data.data });
    } catch {
        window.location.href = "/NoPermissions";
    }
}
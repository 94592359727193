import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import configDataObjects from "../../../../Config/objects.json";
import Background from "../Background/";
import GameObjects from "../GameObjects/";
import Tools from "./Tools";
import ExplosionType from "../../../Games/explosions/ExplosionType";
import SelectExplosionType from "../../../Games/Play/Shooting/SelectExplosionType";
import MoveBackgroundObject from "./MoveBackground";

class ShootingGameView extends React.Component {

    constructor(props) {
        super(props);
        this.designerContainer = React.createRef();
        let type = "Explosion";
        if (this.props.categories && this.props.categories.length >= 1) {
            let item = this.props.categories[0]
            if (item.CustomStyle && item.CustomStyle.Answers && item.CustomStyle.Answers.filter(p => p.Id === item.Id)[0]) {
                type = item.CustomStyle.Answers.filter(p => p.Id === item.Id)[0].AnswersSelectObject;
            }
        }
        this.state = {
            CustomStyle: configData.CustomStyle,
            top: 0,
            left: 0,
            ExplosionType: type,
            isStop: false
        };
        this.ShottingObjects = configDataObjects.ShottingObjects;
    }

    setExplosionType = ExplosionType => {
        this.setState({
            ExplosionType
        });
    }

    stopShooting = isStop => {
        this.setState({
            isStop,
            top: 0,
            left: 0
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        return state;
    }

    handleShoot = event => {
        if (!this.state.isStop) {
            let rect = this.designerContainer.current.getBoundingClientRect();
            this.setState({
                left: event.clientX - rect.left,
                top: event.clientY - rect.top
            });
        }
    }

    render() {
        const { t, categories, ViewGameResult, examAnswers } = this.props;

        let CursorButton = "";
        if (this.state.CustomStyle.buttonStyle.Cursor !== 'custom' && this.state.CustomStyle.buttonStyle.Cursor !== 'none' && this.state.CustomStyle.buttonStyle.Cursor) {
            CursorButton = process.env.PUBLIC_URL + this.state.CustomStyle.buttonStyle.Cursor;
        } else if (this.state.CustomStyle.buttonStyle.Cursor === 'custom' && this.state.CustomStyle.buttonStyle.CursorCustom) {
            CursorButton = this.state.CustomStyle.buttonStyle.CursorCustom;
        }

        const styleBox = {
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerBoxStyle.BorderRadiusTop}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusRight}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerBoxStyle.ShadowPositionX}px ${this.state.CustomStyle.answerBoxStyle.ShadowPositionY}px ${this.state.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerBoxStyle.shadowColor}`,
            opacity: `${this.state.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerBoxStyle.BgAttachment}`,

            height: `${this.state.CustomStyle.buttonStyle ? `${this.state.CustomStyle.buttonStyle.height === "auto" ? "auto" : `${this.state.CustomStyle.buttonStyle.height}px`}` : ""}`,
            width: `${this.state.CustomStyle.buttonStyle ? `${this.state.CustomStyle.buttonStyle.width === "auto" ? "auto" : `${this.state.CustomStyle.buttonStyle.width}px`}` : ""}`,
            color: `${this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.FontColor : ""}`,
            fontSize: `${this.state.CustomStyle.buttonStyle ? `${this.state.CustomStyle.buttonStyle.FontSize}px` : ""}`,
            WebkitTextStrokeWidth: `${this.state.CustomStyle.buttonStyle ? `${this.state.CustomStyle.buttonStyle.StrokeWidth}px` : ""}`,
            WebkitTextStrokeColor: `${this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.StrokeColor : ""}`,
            textAlign: 'center',
            cursor: CursorButton ? `url('${CursorButton}') 20 20, auto` : "crosshair"
        };

        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${this.state.CustomStyle.answerStyle.width}px`,
            minHeight: `${this.state.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`,
        };

        const CustomBackground = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.CustomBackground : "";

        const MoveObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObject : "";
        const MoveObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrc : "";
        const MoveObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrcHover : "";
        const MoveCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveCustomColor : "";

        let Cursor = "";
        if (this.state.CustomStyle.gamesSetting.Cursor !== 'custom' && this.state.CustomStyle.gamesSetting.Cursor !== 'none' && this.state.CustomStyle.gamesSetting.Cursor) {
            Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.gamesSetting.Cursor;
        } else if (this.state.CustomStyle.gamesSetting.Cursor === 'custom' && this.state.CustomStyle.gamesSetting.CursorCustom) {
            Cursor = this.state.CustomStyle.gamesSetting.CursorCustom;
        }

        const styleBackground = {
            height: '500px',
            opacity: `${this.state.CustomStyle.gamesSetting.Opacity}%`,
            backgroundColor: this.state.CustomStyle.gamesSetting.backgroundColor !== 'transparent' ? this.state.CustomStyle.gamesSetting.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.gamesSetting.backgroundImage !== '' ? `url('${this.state.CustomStyle.gamesSetting.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.gamesSetting.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.gamesSetting.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.gamesSetting.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.gamesSetting.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.gamesSetting.BgAttachment}`,
            cursor: Cursor ? `url('${Cursor}') 20 20, auto` : "crosshair"
        };

        const hoverStyle = {
            ...styleBox,
            paddingLeft: `${this.state.CustomStyle.categoryHoverStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.categoryHoverStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.categoryHoverStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.categoryHoverStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.categoryHoverStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryHoverStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.categoryHoverStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.categoryHoverStyle.BorderRadiusTop}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusRight}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.categoryHoverStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryHoverStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryHoverStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryHoverStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.categoryHoverStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.categoryHoverStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryHoverStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.categoryHoverStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryHoverStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.categoryHoverStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.categoryHoverStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.categoryHoverStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.categoryHoverStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.categoryHoverStyle.BgAttachment}`,
        };

        if (ViewGameResult) {
            return <>
                <div className="widget">
                    <div className="widget-body">
                        <table className="table table-striped table-hover table-bordered">
                            <thead>
                                <tr className="text-center">
                                    <th>
                                        {t('Pages.Missions.table.answers')}
                                    </th>
                                    <th>
                                        {t('Pages.Missions.table.userAnswer')}
                                    </th>
                                    <th>
                                        {t('Pages.Missions.table.showQuestionTrueResult')}
                                    </th>
                                    <th>
                                        {t('Pages.Missions.table.result')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    examAnswers && examAnswers[0] ?
                                        examAnswers[0].map(item => {
                                            const answer = this.props.answers.filter(p => p.Id === item.Answer.Id)[0];
                                            const html = answer.Name;
                                            const splitAnswer = html.split('(*_)');
                                            const count = (html.match(/\(*_\)/g) || []).length;
                                            let words = [];
                                            for (let index = 0; index < count; index++) {
                                                let text = splitAnswer[index + 1] ? splitAnswer[index + 1].split('(_*)')[0] : "";
                                                words.push(text);
                                            }
                                            var correctAnswer = parseInt(item.Answer.AnswersSelectType);
                                            const Category = categories.filter(p => p.Id === item.Category)[0];
                                            const correctAnswerCategory = categories.filter(p => p.Id === correctAnswer)[0];
                                            return <tr className="text-center" key={`Answer-${item.Answer.Id}-${item.Answer.Sort}`}>
                                                <td className="text-center" dangerouslySetInnerHTML={{
                                                    __html: words[item.Answer.Sort]
                                                }}></td>
                                                <td className="text-center" colSpan={item.isTrue ? 2 : 1} ><div className="text-center d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{
                                                    __html: Category.Name
                                                }}></div></td>
                                                {
                                                    item.isTrue ? "" :
                                                        <td className="text-center"><div className="text-center d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{
                                                            __html: correctAnswerCategory ? correctAnswerCategory.Name : t('Pages.Lessons.Questions.forms.falseAnswer')
                                                        }}></div></td>
                                                }
                                                <td className="text-center">{item.isTrue ?
                                                    <i className="fas fa-check-square text-success mx-2" title={t('Pages.Lessons.Questions.forms.trueAnswer')}></i>
                                                    :
                                                    <i className="fas fa-times-square text-danger mx-2" title={t('Pages.Lessons.Questions.forms.falseAnswer')}></i>
                                                }</td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={4}>{t('Pages.Missions.table.NoAnswer')}</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="widget">
                    <div className="widget-body">
                        <table className="table table-striped table-hover table-bordered">
                            {this.props.renderFooter()}
                        </table>
                    </div>
                </div >
            </>
        }

        return (
            <div className={`w-100  mx-auto`} style={{
                userSelect: 'none'
            }} >
                <div className="bg-white w-100 position-relative"  >
                    <div className="w-100 position-relative oh" ref={this.designerContainer} style={styleBackground} onClick={e => this.handleShoot(e)}>
                        {
                            this.state.top !== 0 && this.state.left !== 0 ?
                                <ExplosionType type={this.state.ExplosionType} top={this.state.top} left={this.state.left} isMute={false} />
                                : ""
                        }
                        <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${this.state.CustomStyle.answerStyle.Custom} } .dragdrop-static-box{${this.state.CustomStyle.answerBoxStyle.Custom}}` }}></style>
                        <div onMouseEnter={() => this.stopShooting(true)} onMouseLeave={() => this.stopShooting(false)}>
                            <Tools
                                BounsTime={0}
                                BounsTry={0}
                                falseTry={0}
                                gamesSetting={this.state.CustomStyle.gamesSetting}></Tools>
                        </div>

                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column" style={{
                            zIndex: '15'
                        }}>
                            <div className=" w-100 position-relative d-flex justify-content-around align-items-center" >
                                <div>
                                    <GameObjects
                                        objectCss={MoveObject}
                                        ObjectSrc={MoveObjectSrc}
                                        ObjectHoverSrc={MoveObjectSrcHover}
                                        customColor={MoveCustomColor}
                                        style={styleAnswer}
                                        text={t("Pages.Games.Answer")}
                                        type="dragdrop-move"
                                    ></GameObjects>
                                </div>
                            </div>
                        </div>
                        <div className="position-absolute w-100 d-flex justify-content-center "
                            style={{
                                zIndex: '15',
                                bottom: '0'
                            }}>
                            <div className="d-flex" onMouseEnter={() => this.stopShooting(true)} onMouseLeave={() => this.stopShooting(false)} >
                                {
                                    categories && categories.length > 1 ?
                                        categories.map(item => {
                                            let image = process.env.PUBLIC_URL + "/assets/images/explosion/gun.png";
                                            let type = "Explosion";
                                            if (item.CustomStyle && item.CustomStyle.Answers) {
                                                let CustomStyleAnswers = item.CustomStyle.Answers.filter(p => p.Id === item.Id)[0];
                                                if (CustomStyleAnswers) {
                                                    type = CustomStyleAnswers.AnswersSelectObject;
                                                    if (CustomStyleAnswers.AnswersSelectObjectSrc) {
                                                        image = CustomStyleAnswers.AnswersSelectObjectSrc;
                                                    } else {
                                                        var object = this.ShottingObjects.filter(p => p.value === type)[0];
                                                        if (object) {
                                                            image = process.env.PUBLIC_URL + object.img;
                                                        }
                                                    }
                                                }
                                            }
                                            return <SelectExplosionType
                                                key={`category-item-view-${item.Id}`}
                                                item={item}
                                                isActive={this.state.ExplosionType === type}
                                                styleBox={styleBox}
                                                hoverStyle={hoverStyle}
                                                image={image}
                                                setExplosionType={() => this.setExplosionType(type)}
                                            />
                                        })
                                        : ""
                                }
                            </div>
                        </div>
                        <Background absolute={true} CustomBackground={CustomBackground} />
                        <MoveBackgroundObject CustomStyle={this.props.CustomStyle} Id={this.props.Id} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(ShootingGameView));
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import TreeView from "../Indices/TreeView";
import { fetchIndices, updateIndicesRemoveCount } from '../Indices/actions';
import { getIndeices, setIndeices, setExams, getExams, removeExam, removeIndeicesCount, setExamSort } from "../../../context/sessionStorage";
import FooterBottom from "../../Footer/FooterBottom";
import LessonItem from "../../Home/Tracks/LessonItem";
import DragDropModal from '../Lessons/Modal/DragDropModal';
import {
    fetchLessons as fetch,
    removeLessons as remove,
    undoLessons as undo,
    clearLessons as clear,
    updateLessonSort as sort
} from '../Lessons/actions';
import api from '../../../api/api';
import history from '../../../history';

class SelfDevelopment extends React.Component {

    state = { currentItem: '', showDragDropModal: false, indices: [], search: '', startFrom: 0 };

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }

        this.setState({
            indices
        });

        const { id } = this.props.match.params;
        if (id && id !== '0') {
            this.setState({
                currentItem: {
                    id: parseInt(id)
                }
            });
            this.props.fetch(id);
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.startFrom = props.startFrom;
        return state;
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = () => {
        if (this.state.currentItem) {
            this.props.fetch(this.state.currentItem.id);
        }
    }

    SortExam = () => {
        if (this.state.currentItem) {
            this.setState({
                showDragDropModal: true,
            });
        }
    }

    handleCloseDragDropModal = () => {
        this.setState({
            showDragDropModal: false
        });
    }

    setSort = items => {

        items.forEach(item => {
            this.props.sort(item.id, item.sort);
            setExamSort(item.id, item.sort);
        });

        this.setState({
            showDragDropModal: false
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        let checkFromServer = false;
        let lessons = getExams();
        if (lessons === null || lessons.length === 0)
            checkFromServer = true;
        else {
            let filterExam = lessons.lessons.filter(v => v.Index.Id === item.id);
            if (filterExam === null || filterExam.length === 0)
                checkFromServer = true;
        }
        if (checkFromServer) {
            this.props.fetch(item.id);
        }

        history.push(`/ControlPanel/SelfDevelopment/${item.id}`);
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteExam(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteExam = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let Index = '';
        var Exams = getExams();
        if (Exams) {
            Index = Exams.lessons.filter(v => v.Id === id)[0].Index;
        }

        api.delete(`/Lessons/${id}`).then(response => {

            removeExam(id);
            this.props.clear(id);
            this.props.updateIndicesRemoveCount(Index.Id);
            removeIndeicesCount(Index.Id);

            this.setState({
                indices: this.state.indices
            });

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.SelfDevelopment.toast.Delete.Title')}</div> <div className="h6">{t('Pages.SelfDevelopment.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id && node.Level >= 2) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    renderExams() {
        const { t, user } = this.props;
        let lessons = this.props.lessons;
        let isLoaded = false;

        if (lessons) {
            isLoaded = lessons.isLoaded;
        }

        let toRemove = [];
        if (lessons) {
            toRemove = lessons.toRemove;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        if (lessons && lessons.lessons && lessons.lessons.length > 0) {
            setExams(lessons);
        } else {
            lessons = getExams();
        }

        if (lessons && lessons.lessons && lessons.lessons.length > 0 && this.state.currentItem) {
            let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterExam = lessons.lessons.filter(v => v.SearchName.includes(searchText) && v.Index.Id === this.state.currentItem.id);

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.SelfDevelopment.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterExam.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.SelfDevelopment.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.SelfDevelopment.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            if (filterExam.length === 0) {
                if (indices && indices.nodes && this.state.currentItem) {
                    var nodes = this.GetNode(indices.nodes, this.state.currentItem.id);
                    if (nodes && nodes.length > 0) {
                        return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                            {
                                nodes.map(item => {
                                    return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                        <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                            <div className="shop-item">
                                                <div className="product-thumb">
                                                    {
                                                        item.icon ?
                                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                            :
                                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                                    }
                                                </div>
                                                <div className="product-content">
                                                    <h5 className="title">{item.label}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    }
                }
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.SelfDevelopment.NotFound")}
                            </h4>
                        </div>
                    </div>
                );
            }

            return filterExam.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(lesson =>
                (toRemove && toRemove.includes(lesson.Id)) ? "" :
                    <LessonItem
                        user={user}
                        type={"SelfDevelopment"}
                        key={lesson.Id}
                        IsAdmin={true}
                        setIndex={this.setCurrentItem}
                        lesson={lesson}
                        indices={indices.nodes}
                        remove={this.showDelete} />
            );
        }
        if (!this.state.currentItem) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-glasses "></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.SelfDevelopment.SelectIndex")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (lessons && lessons.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.SelfDevelopment.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        if (indices && indices.nodes && this.state.currentItem) {
            var indexNodes = this.GetNode(indices.nodes, this.state.currentItem.id);
            if (indexNodes && indexNodes.length > 0) {
                return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                    {
                        indexNodes.map(item => {
                            return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                    <div className="shop-item">
                                        <div className="product-thumb">
                                            {
                                                item.icon ?
                                                    <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                    :
                                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                            }
                                        </div>
                                        <div className="product-content">
                                            <h5 className="title">{item.label}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })
                    }
                </div>
            }
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.SelfDevelopment.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let isLoaded = false;
        let lessons = this.props.lessons;
        if (lessons) {
            isLoaded = lessons.isLoaded;
        }

        let IndexId = (this.state.currentItem) ? parseInt(this.state.currentItem.id) : 0;

        let nodes = this.props.lessons;
        if (lessons && lessons.lessons && lessons.lessons.length > 0) {
            nodes = this.props.lessons.lessons.sort((a, b) => a.Sort > b.Sort ? 1 : -1);
        } else {
            nodes = getExams()
            if (nodes) {
                nodes = nodes.lessons.sort((a, b) => a.Sort > b.Sort ? 1 : -1);
            }
        }

        return (
            <>
                <DragDropModal
                    hideButton={true}
                    show={this.state.showDragDropModal}
                    IndexId={IndexId}
                    nodes={nodes}
                    API={`/Lessons/`}
                    setSort={this.setSort}
                    handleClose={this.handleCloseDragDropModal}></DragDropModal>

                <section className="pos-rel padding-top padding-bottom" style={{
                    minHeight: '75vh'
                }}>
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("PageHeader.SelfDevelopment")} | {t('alarabeyya')}</title>
                        </Helmet>

                        <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                            <div className="col-lg-8">
                                <div className="section-header">
                                    <span className="cate">{t('ControlPanel.title')}</span>
                                    <h3 className="title">{t('PageHeader.SelfDevelopment')} </h3>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-30-none">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        IsAdmin={true}
                                        title={this.props.title}
                                        parentId={this.props.parentId}
                                        currentId={IndexId}
                                        data={indices.nodes}
                                        type='SelfDevelopment'
                                        startFrom={this.state.startFrom}
                                        toRemove={indices.toRemove}
                                        setCurrentItem={this.setCurrentItem}></TreeView>
                                    : ""
                                }
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.SelfDevelopment.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <Link to={`/ControlPanel/SelfDevelopment/Edit/0`} title={t("Pages.SelfDevelopment.Add")} className="btn btn-primary ">
                                                    <i className="fas fa-plus"></i>
                                                </Link>
                                                <div onClick={() => this.SortExam()} title={t("Pages.SelfDevelopment.Sort")} className="btn btn-dark mx-1">
                                                    <i className="fas fa-sort-numeric-up"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.SelfDevelopment.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderExams()}
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <FooterBottom />
                </footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchIndices, updateIndicesRemoveCount, fetch, remove, undo, clear, sort })(withTranslation('common')(SelfDevelopment));
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import FooterBottom from "../../Footer/FooterBottom";
import { Link } from "react-router-dom";
import TreeView from "./TreeView";
import IndicesModal from './Modal/IndicesModal';
import DragDropModal from './Modal/DragDropModal';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
    fetchIndices as fetch,
    removeIndices as remove,
    setIndices as set,
    undoIndices as undo,
    clearIndices as clear,
    updateIndices as update,
    createIndices as create
} from './actions';
import api from '../../../api/api';

class Indices extends React.Component {

    state = { showIndicesModal: false, showDragDropModal: false, currentItem: '' };

    constructor(props) {
        super(props);

        this.state = {
            showIndicesModal: false,
            showDragDropModal: false,
            IndicesForm: {
                id: 0,
                count: 0,
                label: '',
                icon: '',
                isActive: true,
            }
        }
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        this.props.fetch();
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
    }

    showDelete = () => {
        if (this.state.currentItem) {
            const id = this.state.currentItem.id;
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteIndices(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteIndices = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Indices/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('ControlPanel.Indices.Delete.Title')}</div> <div className="h6">{t('ControlPanel.Indices.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            this.setState({ currentItem: '' });
            this.props.clear(id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    showEdit = () => {
        if (this.state.currentItem) {
            this.setState({
                showIndicesModal: true,
                IndicesForm: {
                    id: this.state.currentItem.id,
                    count: this.state.currentItem.count,
                    label: this.state.currentItem.label,
                    icon: this.state.currentItem.icon,
                    isActive: this.state.currentItem.isActive,
                }
            });
        }
    }

    editNode = (node) => {
        const id = this.state.currentItem.id;
        this.props.update(node, id);
    }

    showSort = () => {
        this.setState({
            showDragDropModal: true
        });
    }

    setSort = value => {
        if (this.state.currentItem) {
            this.setState({
                currentItem: {
                    ...this.state.currentItem,
                    nodes: value
                }
            });
            // this.state.currentItem.nodes = value;
        } else {
            this.props.set(value);
        }

        this.setState({
            showDragDropModal: false
        });
    }

    closeSelect = () => {
        this.setState({ currentItem: '' });
    }

    showNew = () => {
        this.setState({
            showIndicesModal: true,
            IndicesForm: {
                id: 0,
                count: 0,
                label: '',
                icon: '',
                isActive: true,
            }
        });
    }

    addNewNode = (node) => {
        const id = (this.state.currentItem) ? this.state.currentItem.id : 0;
        this.props.create(node, id);
    }

    handleCloseIndicesModal = () => {
        this.setState({
            showIndicesModal: false
        });
    }

    handleCloseDragDropModal = () => {
        this.setState({
            showDragDropModal: false
        });
    }

    setIsActive = value => {
        this.setState({
            IndicesForm: {
                ...this.state.IndicesForm,
                isActive: value
            }
        });
    }

    setName = value => {
        this.setState({
            IndicesForm: {
                ...this.state.IndicesForm,
                label: value
            }
        });
    }

    setIcon = value => {
        this.setState({
            IndicesForm: {
                ...this.state.IndicesForm,
                icon: value
            }
        });
    }

    render() {
        const { t } = this.props;
        let FatherId = (this.state.currentItem) ? this.state.currentItem.id : 0;
        let nodes = (this.state.currentItem) ? this.state.currentItem.nodes : this.props.indices.nodes;
        return (
            <>
                {
                    this.state.showDragDropModal ?
                        <DragDropModal
                            show={this.state.showDragDropModal}
                            nodes={nodes}
                            FatherId={FatherId}
                            setSort={this.setSort}
                            handleClose={this.handleCloseDragDropModal}></DragDropModal>
                        : ""
                }
                {
                    this.state.showIndicesModal ?
                        <IndicesModal
                            show={this.state.showIndicesModal}
                            FatherId={FatherId}
                            addNewNode={this.addNewNode}
                            editNode={this.editNode}
                            setName={this.setName}
                            setIcon={this.setIcon}
                            setIsActive={this.setIsActive}
                            IndicesForm={this.state.IndicesForm}
                            handleClose={this.handleCloseIndicesModal}></IndicesModal>
                        : ""
                }

                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <div className="row mt-0">
                            <Helmet>
                                <title>{t("ControlPanel.Indices.title")} | {t('ControlPanel.title')}</title>
                            </Helmet>
                            <div className="container">
                                <ul className="filter">

                                    <li onClick={() => this.showNew()} title={t("ControlPanel.Indices.tools.new")}>
                                        <Link to="#" className="cate">
                                            <i className="fas fa-plus"></i>
                                            {t("ControlPanel.Indices.tools.new")}
                                        </Link>
                                    </li>
                                    {
                                        this.state.currentItem ?
                                            <li onClick={() => this.showEdit()} title={t("ControlPanel.Indices.tools.edit")}>
                                                <Link to="#" className="cate" >
                                                    <i className="fas fa-edit"></i>
                                                    {t("ControlPanel.Indices.tools.edit")}
                                                </Link>
                                            </li>
                                            : ""
                                    }
                                    <li onClick={() => this.showSort()} title={t("ControlPanel.Indices.tools.sort")}>
                                        <Link to="#" className="cate" >
                                            <i className="fas fa-sort-numeric-up"></i>
                                            {t("ControlPanel.Indices.tools.sort")}
                                        </Link>
                                    </li>
                                    {
                                        this.state.currentItem ?
                                            <>
                                                <li onClick={() => this.showDelete()} title={t("ControlPanel.Indices.tools.delete")}>
                                                    <Link to="#" className="cate" >
                                                        <i className="fal fa-trash-alt"></i>
                                                        {t("ControlPanel.Indices.tools.delete")}
                                                    </Link>
                                                </li>
                                                <li onClick={() => this.closeSelect()} title={t("ControlPanel.Indices.tools.close")}>
                                                    <Link to="#" className="cate" >
                                                        <i className="fal fa-window-close"></i>
                                                        {t("ControlPanel.Indices.tools.close")}
                                                    </Link>
                                                </li>
                                            </> : ""
                                    }
                                </ul>
                            </div>
                            <div className="col-sm-12 col-md-11  col-lg-10 col-xl-10 col-xxl-8 mx-auto">
                                <TreeView
                                    currentId={this.state.currentItem ? this.state.currentItem.id : 0}
                                    data={this.props.indices.nodes}
                                    toRemove={this.props.indices.toRemove}
                                    setCurrentItem={this.setCurrentItem}></TreeView>
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <FooterBottom></FooterBottom>
                </footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, { fetch, remove, set, undo, clear, update, create })(withTranslation('common')(Indices));

const teachersReducers = (state = [], action) => {
    switch (action.type) {
        case "TEACHERS_LOADING":
            return {
                teachers: state.teachers,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "TEACHERS_INITIAL":
            return {
                teachers: action.teachers,
                toRemove: [],
                isLoaded: true
            };
        case "TEACHERS_UPDATE_INFO":
            return {
                teachers: UpdateTeacherData(action.teacher, state.teachers, action.id),
                toRemove: [],
                isLoaded: true
            };
        case "TEACHERS_QUEUE_FOR_REMOVAL":
            return {
                teachers: state.teachers,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "TEACHERS_CLEAN_NODES":
            return {
                teachers: state.toRemove.some(v => v === action.id) ? state.teachers.filter(v => v.Id !== action.id) : state.teachers,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "TEACHERS_UNDO":
            return {
                teachers: state.teachers,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "TEACHERS_ADD":
            return {
                teachers: AddTeacherData(action.teacher, state.teachers),
                toRemove: state.toRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

function AddTeacherData(newInfo, teachers) {
    let copy = [];
    teachers.map(teacher => {
        copy.push({
            ...teacher
        });
        return '';
    });
    copy.push({
        ...newInfo
    });
    return copy;
}

function UpdateTeacherData(newInfo, teachers, id) {
    let copy = [];
    teachers.map(teacher => {
        if (teacher.Id === id) {
            teacher = {
                ...teacher,
                ...newInfo,
                Name: newInfo.FirstName + " " + newInfo.SecondName + " " + newInfo.LastName
            };
        }
        copy.push({
            ...teacher
        });
        return '';
    });
    return copy;
}

export default teachersReducers;

import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

class ShowQuestionModal extends React.Component {

    render() {
        const { t, showResult, options } = this.props;

        return <Modal
            key="ModalQuestionView"
            centered
            size="xl"
            show={true}
            onHide={() => this.props.handleClose()}
            backdrop="static"
            keyboard={false}
            enforceFocus={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="icon fal fa-question"></i>
                    {t('Pages.Exams.SeeResult')}
                    {
                        options && options.Star ?
                            <>
                                <i className={`icon ${options.Star >= 1 ? "fas" : "fal"}  fa-star text-warning mr-2`}></i>
                                <i className={`icon ${options.Star >= 2 ? "fas" : "fal"}  fa-star text-warning mr-1`}></i>
                                <i className={`icon ${options.Star >= 3 ? "fas" : "fal"}  fa-star text-warning mr-1`}></i>
                            </>
                            : ""
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-100 position-relative">
                    {showResult ? <div className="overlayer-question"> </div> : ""}
                    {this.props.renderQuestionView()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12">
                    <button className="btn btn-danger" onClick={() => this.props.handleClose()}>
                        <i className="icon fas fa-times"></i>
                        {t('Close')}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    }
}


export default withTranslation('common')(ShowQuestionModal);
import React from "react";
import GameObjects from "../../GameObjects";

class Field extends React.Component {
    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {
        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundColor: '#b8f0ff',
            height: '100vh',
            top: '0',
        }}>
            <div className=" position-relative" style={{
                backgroundColor: '#b8f0ff',
                height: '100vh',
                backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/search/bg.png"})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: "100%",
                backgroundPositionX: "50%",
                backgroundSize: 'cover'
            }}>
                <div className="position-absolute user-select-none d-none d-sm-block" style={{
                    right: '2%',
                    top: '10%',
                    animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
                }}><GameObjects objectCss="Cloud2" /></div>
                <div className="position-absolute user-select-none d-none d-sm-block" style={{
                    right: 'calc(50% - 169px)',
                    top: '0%',
                    animation: `dropObjectMovment linear forwards 4s normal infinite`
                }}><GameObjects objectCss="Cloud1" /></div>
                <div className="position-absolute user-select-none d-none d-sm-block" style={{
                    left: '2%',
                    top: '10%',
                    animation: `dropObjectMovment linear forwards 5s normal infinite`
                }}><GameObjects objectCss="Cloud3" /></div>


                <div className="position-absolute user-select-none" style={{
                    right: '5%',
                    top: '5%',
                    animation: `dropObjectMovment linear forwards 5s normal infinite`
                }}><GameObjects objectCss="Drones" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

                <div className="position-absolute user-select-none d-none d-sm-block" style={{
                    right: '15%',
                    bottom: '10%'
                }}><GameObjects objectCss="GirlInCamp" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

                <div className="position-absolute user-select-none" style={{
                    right: '10%',
                    bottom: '10%'
                }}><GameObjects objectCss="BoyWithMagnifier" scale={"0.8"} transformOriginY="bottom" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

                <div className="position-absolute user-select-none d-none d-sm-block" style={{
                    left: '0%',
                    bottom: '3%'
                }}><GameObjects objectCss="BoyWithController" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            </div>
        </div>
    }
}
export default Field;
import React from "react";
import { withTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import reactCSS from 'reactcss';

import configData from "../../../../Config/objects.json";
import configDataBackground from "../../../../Config/background.json";
import { SketchPicker } from "react-color";
import MovePreview from "../../View/GameObjects/MovePreview";
import BackgroundGameObjectMoveItem from "./BackgroundGameObjectMoveItem";
import CustomMovePaint from "../CustomMovePaint";

class BackgroundGameObjectItem extends React.Component {

    constructor(props) {
        super(props);
        this.Category = configData.Category;
        this.Background = configDataBackground.Background.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else return 1;
        });
        this.Objects = configData.Objects.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else return 1;
        });
        this.state = {
            isFirst: true,
            showPaint: false,
            displayDropColorPicker: false,
            BackgroundGameObject: [],
            currentMovmentIndex: 0,
            currentItem: {
                MoveObject: 'SpaceShip',
                Background: "",
                category: 1,
                HTML: "",
                ObjectSrc: "",
                ObjectSrcHover: "",
                CustomColor: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                delay: 0,
                transformOriginX: "center",
                transformOriginY: "center",
                isSpin: false,
                Time: 1,
                Movments: []
            }
        };
    }

    renderBackgrounds() {
        return this.Background.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option>;
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentItem && state.isFirst) {
            state.currentItem = props.currentItem;
            state.isFirst = false;
        }
        return state;
    }

    handleClickDisplayDropColorPicker = () => {
        this.setState({
            displayDropColorPicker: true
        });
    }

    handleCloseColorPicker = () => {
        this.setState({
            displayDropColorPicker: false
        });
    }

    renderObjectCategory = () => {
        return this.Category.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderObject = (category = 1) => {
        category = parseInt(category);
        return this.Objects.filter(p => p.category === category).map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    setGameObjectSrcHover = ObjectSrcHover => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                ObjectSrcHover
            }
        });
    }

    setGameObjectSrc = ObjectSrc => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                ObjectSrc
            }
        });
    }

    setGameHTML = HTML => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                HTML
            }
        });
    }

    setGameObjectCategory = category => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                category
            }
        }, () => {
            var item = this.Objects.filter(p => p.category === parseInt(category))[0];
            if (item) {
                this.setGameObject(item.value);
            }
        });
    }

    setGameBackground = Background => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                Background
            }
        });
    }

    setGameObject = MoveObject => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                MoveObject
            }
        });
    }

    setGameTime = Time => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                Time
            }
        });
    }

    setGameIsSpin = isSpin => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                isSpin
            }
        });
    }

    setGameRotate = rotate => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                rotate
            }
        });
    }

    setGameFlipX = flipX => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                flipX
            }
        });
    }

    setGameTransformOriginX = transformOriginX => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                transformOriginX
            }
        });
    }

    setGameTransformOriginY = transformOriginY => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                transformOriginY
            }
        });
    }

    setGameDelay = delay => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                delay
            }
        });
    }

    setGameScale = scale => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                scale
            }
        });
    }

    ClearDropCustomColor = () => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                CustomColor: ""
            }
        });
    }

    handleDropColorChange = color => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                CustomColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
            }
        });
    }

    renderObjectMovment = () => {
        let movments = [];

        for (let index = 0; index <= 100; index++) {
            var hasItem = this.state.currentItem.Movments ? this.state.currentItem.Movments.filter(p => p.Index === index)[0] : "";

            movments.push(<div className={`ani-timekey ${hasItem ? "hasstyle" : ""} ${this.state.currentMovmentIndex === index ? "selected" : ""}`} title={index} onClick={() => this.setMovment(index)} key={index}> {this.state.currentMovmentIndex === index ? <span>{index}</span> : ""}</div>);
        }
        return movments;
    }

    setMovment = currentMovmentIndex => {
        this.setState({
            currentMovmentIndex
        });
    }

    setMovmentItem = item => {
        if (this.state.currentItem.Movments) {
            var hasItem = this.state.currentItem.Movments.filter(p => p.Index === this.state.currentMovmentIndex)[0];
            if (!hasItem) {
                this.setState({
                    currentItem: {
                        ...this.state.currentItem,
                        Movments: [...this.state.currentItem.Movments,
                        {
                            Index: this.state.currentMovmentIndex,
                            ...item
                        }]
                    }
                });
            } else {
                const Movments = this.state.currentItem.Movments.filter(p => p.Index !== this.state.currentMovmentIndex);
                this.setState({
                    currentItem: {
                        ...this.state.currentItem,
                        Movments: [...Movments,
                        {
                            Index: this.state.currentMovmentIndex,
                            ...item
                        }]
                    }
                });
            }
        } else {
            this.setState({
                currentItem: {
                    ...this.state.currentItem,
                    Movments: [item]
                }
            });
        }
    }

    SubmitItems = () => {
        this.setState({
            showModal: false
        });
        this.props.setBackgroundGameObject(this.state.currentItem);
    }

    showPaint = () => {
        this.setState({
            showPaint: !this.state.showPaint
        });
    }

    setDrawMovment = lines => {
        let currentItem = this.state.currentItem;
        currentItem.Movments = [];

        var transformOriginX = currentItem.transformOriginX;
        var transformOriginY = currentItem.transformOriginY;

        for (let i = 0; i < lines.length && lines.length > 1; i++) {
            const element = lines[i];
            const subIndx = Math.round(i / (lines.length - 1) * 100);//.toFixed(2);

            var hasItem = currentItem.Movments.filter(p => p.Index === subIndx)[0];
            if (!hasItem) {
                currentItem.Movments.push({
                    Index: subIndx,
                    AnswersOpacity: 100,
                    AnswersScale: 1,
                    AnswersTranslateY: element.y,
                    AnswersTranslatePositionY: transformOriginY ? transformOriginY : "top",
                    AnswersTranslatePositionX: transformOriginX ? transformOriginX : "right",
                    AnswersTranslateX: element.x,
                    AnswersRotate: 0,//element.rotate,
                    AnswersTransformOrigin: "center"
                });
            }
        }
        this.setState({
            currentItem
        });
    }

    render() {
        const { t } = this.props;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        const currentItemMove = this.state.currentItem && this.state.currentItem.Movments ? this.state.currentItem.Movments.filter(p => p.Index === this.state.currentMovmentIndex)[0] : "";

        return (
            <Modal
                size="xl"
                show={true}
                onHide={this.props.handleClose}
                backdrop="static"
                keyboard={false}
                autoFocus={true}
                enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title> <div className="h5 mb-0"> <i className="icon fal fa-cogs"></i>  {t('Pages.Lessons.Questions.forms.tools.objects.MoveObject')}</div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='widget widget-search' >
                        <div className='widget-body p-3'>
                            <div className="contact-form mb-1">

                                <div className="w-100 row row-cols-4">

                                    <div className="form-group col">
                                        <label className="text-dark h6">
                                            <i className="icon fas fa-box-ballot"></i>
                                            {t('Pages.Lessons.Questions.forms.tools.objects.ObjectCategory')}
                                        </label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.setGameObjectCategory(e.target.value)}
                                                className="w-100"
                                                value={this.state.currentItem.category ? this.state.currentItem.category : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                {this.renderObjectCategory()}
                                                <option value="background">{t('Pages.Lessons.Questions.forms.tools.objects.Backgrounds')}</option>
                                            </select>
                                            <button type="button" >
                                                <i className="fas fa-box-ballot"></i>
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        this.state.currentItem.category === "background" ?
                                            <>
                                                <div className="form-group col">


                                                    <label className="text-dark h6"><i className="icon fas fa-expand-alt"></i> {t('Pages.Lessons.Questions.forms.tools.CustomBackground.title')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.setGameBackground(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.currentItem.Background}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.CustomBackground.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.CustomBackground.title')}>
                                                            {
                                                                this.renderBackgrounds()
                                                            }
                                                        </select>
                                                        <button type="button">
                                                            <i className="fas fa-expand-alt"></i>
                                                        </button>
                                                    </div>


                                                </div>

                                                <div className="form-group col">
                                                </div>
                                                <div className="form-group col">
                                                </div>
                                            </>
                                            :
                                            <>

                                                <div className="form-group col">
                                                    <label className="text-dark h6">
                                                        <i className="icon fas fa-ufo"></i>
                                                        {t('Pages.Lessons.Questions.forms.tools.objects.MoveObject')}
                                                    </label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.setGameObject(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.currentItem.MoveObject}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                            {this.renderObject(this.state.currentItem.category)}
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-ufo"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group col">
                                                    <label className="text-dark h6"><i className="fal fa-clock"></i> {t('Pages.Lessons.Questions.forms.tools.move.Time')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            step="1"
                                                            min="1"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.Time')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.Time')}
                                                            value={this.state.currentItem.Time}
                                                            onChange={e => this.setGameTime(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-clock"  ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group col">
                                                    <label className="text-dark h6"><i className="fal fa-alarm-snooze"></i> {t('Pages.Lessons.Questions.forms.tools.move.delay')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            step="1"
                                                            min="1"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.delay')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.delay')}
                                                            value={this.state.currentItem.delay}
                                                            onChange={e => this.setGameDelay(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-alarm-snooze"  ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group col">
                                                    <label className="text-dark h6">
                                                        <i className="icon fas fa-ruler-combined"></i>
                                                        {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.x')}
                                                    </label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.setGameTransformOriginX(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.currentItem.transformOriginX}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.x')}>
                                                            <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                            <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>
                                                            <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-ruler-combined"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group col">
                                                    <label className="text-dark h6">
                                                        <i className="icon fas fa-ruler-combined"></i>
                                                        {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.y')}
                                                    </label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.setGameTransformOriginY(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.currentItem.transformOriginY}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.y')}>
                                                            <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                            <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                                            <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-ruler-combined"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.currentItem.MoveObject === "custom" ?
                                                        <>
                                                            <div className="form-group col">
                                                                <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                        value={this.state.currentItem.ObjectSrc}
                                                                        onChange={e => this.setGameObjectSrc(e.target.value)}
                                                                    />
                                                                    <button type="button" >
                                                                        <i className="fas fa-image"  ></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="form-group col">
                                                                <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                        value={this.state.currentItem.ObjectSrcHover}
                                                                        onChange={e => this.setGameObjectSrcHover(e.target.value)}
                                                                    />
                                                                    <button type="button" >
                                                                        <i className="fas fa-image"  ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        this.state.currentItem.MoveObject === "html" ?
                                                            <div className="form-group col-6">
                                                                <label className="text-dark h6"><i className="fal fa-flask-potion"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Code')}</label>
                                                                <div className="search-form">
                                                                    <textarea
                                                                        rows="10"
                                                                        onChange={e => this.setGameHTML(e.target.value)}
                                                                        className="w-100"
                                                                        value={this.state.currentItem.HTML}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.Code')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.Code')}>
                                                                    </textarea>
                                                                    <button type="button" >
                                                                        <i className="fas fa-flask-potion"  ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="form-group col" >
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        value={this.state.currentItem.CustomColor}
                                                                        onChange={() => this.ClearDropCustomColor()}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={e => this.handleClickDisplayDropColorPicker()}
                                                                        style={{
                                                                            color: this.state.currentItem.CustomColor !== 'transparent' ? this.state.currentItem.CustomColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displayDropColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                                <SketchPicker color={this.state.currentItem.CustomColor} onChange={this.handleDropColorChange} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                }
                                            </>
                                    }
                                </div>

                                {
                                    this.state.currentItem.category === "background" ? "" :
                                        <>
                                            <div className='w-100  col-12'></div>

                                            <div className="form-group col mb-2">
                                                <label className="text-dark h6"><i className="fal fa-spin fa-spinner"></i> {t('Pages.Lessons.Questions.forms.tools.move.spin')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.setGameIsSpin(e.target.value)}
                                                        className="w-100"
                                                        value={this.state.currentItem.isSpin}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.spin')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.spin')}>
                                                        <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_spin')}</option>
                                                        <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.spin')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fal fa-spin fa-spinner"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col mb-2">
                                                <label className="text-dark h6"><i className="fal fa-sync-alt"></i> {t('Pages.Lessons.Questions.forms.tools.move.Rotate')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                        value={this.state.currentItem.rotate}
                                                        onChange={e => this.setGameRotate(e.target.value)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-sync-alt"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col mb-2">
                                                <label className="text-dark h6"><i className="fal fa-retweet"></i> {t('Pages.Lessons.Questions.forms.tools.move.flipX')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.setGameFlipX(e.target.value)}
                                                        className="w-100"
                                                        value={this.state.currentItem.flipX}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.flipX')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.flipX')}>
                                                        <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_flipX')}</option>
                                                        <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.flipX')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fal fa-retweet"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col mb-2">
                                                <label className="text-dark h6"><i className="fal fa-expand-arrows"></i>{t('Pages.Lessons.Questions.forms.tools.move.Scale')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                        value={this.state.currentItem.scale}
                                                        onChange={e => this.setGameScale(e.target.value)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-expand-arrows"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='w-100  col-12'></div>

                                            <div className='w-100 col-12 mt-3 text-center text-info'>
                                                {t('Pages.Lessons.Questions.forms.tools.objects.CustomMove')}
                                                <i onClick={this.showPaint} className="icon fa-light fa-paint-roller btn btn-dark mx-2" title={t('Pages.Lessons.Questions.forms.tools.objects.Paint')}></i>
                                            </div>
                                            {
                                                this.state.showPaint ?
                                                    <CustomMovePaint index={0} id={0} setDrawMovment={this.setDrawMovment} />
                                                    :
                                                    <>
                                                        <div className="col-12 mb-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div>{t('Pages.Lessons.Questions.forms.tools.move.start')}</div>
                                                                <div>{t('Pages.Lessons.Questions.forms.tools.move.end')}</div>
                                                            </div>
                                                            <div className="ani-timeline p-0 w-100 ">
                                                                {this.renderObjectMovment()}
                                                            </div>
                                                        </div>
                                                        <BackgroundGameObjectMoveItem
                                                            currentItem={currentItemMove}
                                                            setMovmentItem={this.setMovmentItem}
                                                        />
                                                    </>
                                            }
                                        </>
                                }
                                <MovePreview
                                    id={1}
                                    index={1}
                                    delay={this.state.currentItem.delay}
                                    Background={this.state.currentItem.category === "background" ? this.state.currentItem.Background : ""}
                                    transformOriginX={this.state.currentItem.transformOriginX}
                                    transformOriginY={this.state.currentItem.transformOriginY}
                                    backgroundImage={this.props.backgroundImage}
                                    backgroundColor={this.props.backgroundColor}
                                    BgSize={this.props.BgSize}
                                    Custom={this.props.Custom}
                                    BgRepeat={this.props.BgRepeat}
                                    BgPositionX={this.props.BgPositionX}
                                    BgPositionY={this.props.BgPositionY}
                                    BgAttachment={this.props.BgAttachment}
                                    isForBackground={true}
                                    Time={this.state.currentItem.Time}
                                    rotate={this.state.currentItem.rotate}
                                    isSpin={this.state.currentItem.isSpin}
                                    flipX={this.state.currentItem.flipX}
                                    scale={this.state.currentItem.scale}
                                    HTML={this.state.currentItem.HTML}
                                    Movments={this.state.currentItem.Movments}
                                    ObjectSrc={this.state.currentItem.ObjectSrc}
                                    ObjectHoverSrc={this.state.currentItem.ObjectHoverSrc}
                                    customColor={this.state.currentItem.CustomColor}
                                    objectCss={this.state.currentItem.MoveObject !== "" ? this.state.currentItem.MoveObject : "SpaceShip"}
                                    style={{
                                        background: '#fff',
                                        padding: '5px'
                                    }}
                                    text={""} />

                                <div className='w-100  col-12'></div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 row">
                        <div className="col-6">
                            <button className="btn btn-success" onClick={this.SubmitItems} >
                                <i className="icon fal fa-edit"></i>
                                {t('Save')}
                            </button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-danger" onClick={this.props.handleClose} >
                                <i className="icon fas fa-times"></i>
                                {t('Close')}
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>);
    }
}

export default withTranslation('common')(BackgroundGameObjectItem);
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import Footer from "../../../Footer/FooterBottom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import api from "../../../../api/api";
import { Link } from "react-router-dom";

class CompetitionStage extends React.Component {

    state = {
        data: [],
        index: 0,
        show: false,
        code: ""
    };
    componentDidMount() {
        window.scrollToPosition("main-game-stage-section");
        this.getResult(0);
    }

    showResult = index => {
        window.scrollToPosition("main-game-stage-section");
        this.setState({
            data: [],
            show: false,
            index
        });
        this.getResult(index);
    }

    getResult = index => {
        setTimeout(() => {
            api.get(`/CompetitionResult/${index}`).then(response => {
                if (response.data.code === "success" || response.data.data.length >= 5) {
                    this.setState({
                        data: index > 0 ?
                            response.data.data.sort((a, b) => a.TryNumber > b.TryNumber ? 1 : -1).sort((a, b) => a.Time < b.Time ? 1 : -1).sort((a, b) => a.Star < b.Star ? 1 : -1) :
                            response.data.data,
                        show: true,
                        code: response.data.code
                    });
                } else {
                    this.setState({
                        code: response.data.code
                    });
                }
            });
        }, 500);
    }

    renderWinner = (index, item) => {
        if (item) {
            const { t } = this.props;
            const Name = item.User && item.User.Name ? item.User.Name : item.Name;
            return <div title={t('Pages.Competition.Winner.' + index) + " " + Name} className="teacher-single-item mb-0" >
                <div className=" d-flex flex-wrap flex-column align-content-center align-items-center mb-0 " style={{
                    marginTop: '2rem'
                }}>

                    <div className="position-relative d-block" style={{ zIndex: 8 }}>
                        <div className="position-absolute w-100" style={{ marginTop: index === 1 ? "-55px" : '-36px' }} >
                            <div className=" d-flex justify-content-around align-items-end" >
                                <div className={`display-${index === 1 ? "3" : "4"} pb-4`}>
                                    <i className={`fa-solid fa-crown competition-color  text-warning`} style={{
                                        WebkitTextStrokeColor: '#0e2c53',
                                        WebkitTextStrokeWidth: '2px'
                                    }}></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="teacher-single-thumb d-flex align-items-center justify-content-center m-0" style={{
                        borderColor: '#0e2c53',
                        width: '200px',
                        borderWidth: '5px',
                        backgroundColor: '#f7f9ee'
                    }}>
                        <LazyLoadImage src={`${item.User && item.User.Photo ? item.User.Photo : "/assets/images/logo/sub.png"}`} alt={Name} />
                    </div>
                    <div className="w-auto mt--40" style={{ zIndex: 8 }}>
                        <div className="text-center mx-3">
                            <div className={`p-3 h4 mb-0 rounded-custom `} style={{
                                backgroundColor: '#0e2c53',
                            }}>
                                <div className="text-center">
                                    <div className={`h5 text-truncate mb-0 pb-1 text-white`} title={Name}>
                                        {Name}
                                    </div>
                                </div>
                            </div>
                            {
                                item.User && item.User.SchoolName ?
                                    <span className="d-flex justify-content-center mb-0" style={{ marginTop: '-15px', height: '35px' }}>
                                        <div className="py-2 px-2 rounded-custom h6 mb-0 text-truncate text-white" title={item.SchoolName} style={{ background: '#1b3f7b' }}>
                                            {item.User.SchoolName}
                                        </div>
                                    </span>
                                    :
                                    <span className="d-flex justify-content-center mb-0" style={{ marginTop: '-15px', height: '35px' }}>
                                        <div className="py-2 px-2 rounded-custom h6 mb-0 text-truncate text-white bg-default" title={t('Pages.Home.Count.School')} style={{ background: '#4c258b' }}>
                                            {t('Pages.Home.Count.School')}
                                        </div>
                                    </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        return "";
    }

    renderTableRows = () => {
        return this.state.data.slice(3, 10).map((item, index) => {
            const Name = item.User && item.User.Name ? item.User.Name : item.Name;
            return <tr className="mb-2" key={index}>
                <td className="mb-2 text-center h2 rounded-circle px-2 bg-white mx-auto font-weight-bold" style={{
                    color: '#0e2c53',
                    width: '50px',
                    height: '50px',
                    lineHeight: '50px'
                }}>
                    {index + 4}
                </td>
                <td className="mb-2 text-center p-2 rounded text-white mx-1 font-weight-bold" style={{
                    minHeight: '50px',
                    background: '#0e2c53'
                }}>
                    {Name}
                </td>
                {
                    item.User ?
                        <td className="mb-2 text-center p-2 rounded bg-white font-weight-bold" style={{
                            minHeight: '50px',
                            color: '#0e2c53'
                        }}>
                            {item.User.SchoolName}
                        </td> : ""
                }
            </tr>
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Competition.Title')} | {t('alarabeyya')}
                    </title>
                    <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/css/game.css?v=' + process.env.REACT_APP_VERSION} />
                </Helmet>
                <section className="position-relative game-font oh" id="main-game-stage-section" style={{
                    minHeight: '100vh',
                    backgroundColor: '#985b46'
                }}>
                    <div className="position-relative oh" style={{
                        minHeight: '100vh',
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/bg.png"})`,
                        backgroundPositionX: 'center',
                        backgroundPositionY: 'bottom',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        top: '0'
                    }}>

                        <div className="w-100 position-absolute-md" style={{
                            zIndex: 9
                        }}>

                            <div className="w-100 position-relative" style={{
                                zIndex: 9
                            }}>
                                <div style={{
                                    background: 'linear-gradient(180deg, rgba(25,86,176,1) 0%, rgba(41,123,255,1) 20%)'
                                }}>
                                    <div className="d-flex w-100 py-1 justify-content-evenly align-items-center flex-wrap flex-md-nowrap">
                                        <div className="d-flex w-100 py-1 justify-content-evenly align-items-center order-2 order-sm-1 ">
                                            <div title={t('Pages.Competition.ClassRoom.1')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(1)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    1
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 1 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.2')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(2)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    2
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 2 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.3')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(3)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    3
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 3 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                        </div>
                                        <div className="display-3 position-relative order-1 order-sm-2 cursor-custom-pointer d-flex align-items-center flex-column" onClick={() => this.showResult(0)} >
                                            <i className={`fa-solid fa-crown ${this.state.index === 0 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            <div className="h4 mb-0 text-center text-white" style={{
                                                marginTop: '-5px'
                                            }}>
                                                {t('Pages.Competition.Schools')}
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 py-1 justify-content-evenly align-items-center order-3 ">
                                            <div title={t('Pages.Competition.ClassRoom.4')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(4)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    4
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 4 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.5')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(5)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    5
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 5 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.6')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(6)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    6
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 6 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 d-none d-md-block" style={{
                                    height: '100px',
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/1.png"})`,
                                    backgroundSize: 'contain',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'bottom',
                                    backgroundRepeat: 'repeat'
                                }}></div>
                            </div>
                            <div className="w-100 position-absolute" style={{
                                zIndex: 1,
                                top: 0
                            }}>
                                <div className={`curtain__panel ${this.state.show ? "curtain__panel--left" : ""}`}></div>
                                <div className={`curtain__panel curtain__panel__left ${this.state.show ? "curtain__panel--right" : ""}`}></div>
                                <div className="h-100 position-absolute d-none d-lg-block" style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/right.png"})`,
                                    backgroundSize: 'cover',
                                    backgroundPositionX: 'left',
                                    backgroundPositionY: 'top',
                                    backgroundRepeat: 'no-repeat',
                                    width: '300px',
                                    right: 0,
                                    top: 0,
                                    zIndex: 2
                                }}></div>
                                <div className="h-100 position-absolute d-none d-lg-block" style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/left.png"})`,
                                    backgroundSize: 'cover',
                                    backgroundPositionX: 'right',
                                    backgroundPositionY: 'top',
                                    backgroundRepeat: 'no-repeat',
                                    width: '300px',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2
                                }}></div>
                            </div>

                            {
                                this.state.code === "error" ?
                                    <div className="w-100 position-absolute d-flex justify-content-center align-items-center" style={{
                                        zIndex: 9,
                                        height: '50vh'
                                    }}>
                                        <div className="product-content rounded mt-5 mb-3 w-50 text-center bg-white p-5 border border-warning">
                                            <div className="display-1 text-warning mb-2">
                                                <i className="fad fa-crown"></i>
                                            </div>
                                            <h4 className="display-5 subtitle">
                                                {t("NoInfo")}
                                            </h4>
                                            <Link to="/Student/Game/Profile" className=" custom-button orange-button w-50 mt-3 ">
                                                <span>
                                                    {t('Pages.Competition.Title')}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                    : ""
                            }

                        </div>

                        <div className="container d-flex justify-content-end flex-column align-items-center h-100 position-relative" style={{
                            minHeight: '100vh'
                        }} >

                            <div className="d-flex align-items-stretch align-content-center flex-wrap flex-column mb-5 w-100">
                                <div className="h1 title text-center pb-2 display-4 mt-3" style={{
                                    color: '#0e2c53'
                                }}>
                                    {t('Pages.Competition.Title')}
                                </div>
                                <div className="h2 title text-center mb-3 pb-2 " style={{
                                    color: '#0e2c53'
                                }}>
                                    {this.state.index === 0 ? t('Pages.Competition.Schools') : t('Pages.Competition.ClassRoom.' + this.state.index)}
                                </div>
                                <div className="d-flex w-100 justify-content-evenly align-items-center flex-wrap flex-md-nowrap">
                                    <div className="w-100 d-flex justify-content-center justify-content-md-end order-sm-1 order-3">
                                        {this.renderWinner(3, this.state.data[2])}
                                    </div>
                                    <div className="w-75 mb-md-3 order-sm-2 order-1 mx-2">
                                        {this.renderWinner(1, this.state.data[0])}
                                    </div>
                                    <div className="w-100 d-flex justify-content-center justify-content-md-start order-sm-3 order-2">
                                        {this.renderWinner(2, this.state.data[1])}
                                    </div>
                                </div>
                                <div className="d-none d-md-block" style={{
                                    height: '200px',
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/footer.png"})`,
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'bottom',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain'
                                }}></div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.show && this.state.data && this.state.data.length > 3 ?
                            <div className="container mb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8 col-md-12">
                                        <table className="w-100" style={{
                                            borderSpacing: '0.5rem',
                                            borderCollapse: "separate"
                                        }}>
                                            <tbody>
                                                {this.renderTableRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : ""
                    }
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(CompetitionStage));

import React from "react";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

class NameForm extends React.Component {

    state = { initialize: false, errorList: [] };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.Title) {
            setTimeout(() => {
                props.initialize({
                    Title: props.Title
                });
            }, 500);
            state.initialize = true;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        if (!this.Title.ref.current.getValue()) {
            errors.Title = 'Pages.FileManager.Form.Title.error';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-bug"></i>
                        {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div>
                    <div className="h6">
                        {t('Pages.Register.error_AllInformationIsRequired_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const formData = new FormData();
            formData.append(
                "Title",
                this.Title.ref.current.getValue()
            );
            formData.append(
                "filename",
                this.props.id
            );

            this.setState({ errorList: [] });

            this.props.onSubmit(formData, this._toastId.current);
        }
        e.preventDefault();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <form method="POST" encType="multipart/form-data" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5'>{t('Pages.FileManager.Form.Title.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Title => this.Title = Title}
                                name="Title"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.FileManager.Form.Title.Placeholder')}
                                title={t('Pages.FileManager.Form.Title.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group w-100">
                        <button className="custom-button mt-1 w-100"><span> <i className="fal fa-save"></i> {t('Pages.FileManager.Form.submit')}</span></button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.File) {
        errors.File = 'Pages.FileManager.Form.File.error';
    }

    if (!values.Title) {
        errors.Address = 'Pages.FileManager.Form.Title.error';
    }
    return errors;
}

const formName = reduxForm({
    form: 'formName',
    validate
});

export default withTranslation('common')(formName(NameForm));

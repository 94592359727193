import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import api from '../../../api/api';
import Select from 'react-select';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import configData from "../../Config/countries.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SendContactBySocket } from '../../../api/socket.io';

const TEST_SITE_KEY = `${process.env.REACT_APP_TEST_SITE_KEY}`;

class ContactForm extends React.Component {

    state = { captchaErorr: null, errorList: [], countries: [], CountryIndex: -1 };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this._reCaptchaRef = React.createRef();
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.country_code) {
            var index = state.countries.findIndex(p => p.value === props.country_code);

            state.CountryIndex = index;
            state.Address = props.Address;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} title={title} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );

    renderTextarea = ({
        input,
        title,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <textarea {...input} title={title} rows="5" placeholder={placeholder} autoComplete="new-password" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} ></textarea>
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );

    validateTitle = value => {
        const { t } = this.props;

        if (!value) {
            return t('ContactUs.Title.Placeholder');
        }
    }

    validateSender = value => {
        const { t } = this.props;
        if (!value) {
            return t('ContactUs.Sender.Placeholder');
        }
    }

    validateCountry = value => {
        const { t } = this.props;
        if (!value) {
            return t('ContactUs.Country.Placeholder');
        }
    }

    validateEmail = value => {
        const { t } = this.props;
        if (!value) {
            return t('ContactUs.Email.Placeholder');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return t('ContactUs.Email.Placeholder');
        }
    }

    validateDetails = value => {
        const { t } = this.props;
        if (!value) {
            return t('ContactUs.Details.Placeholder');
        }
    }

    validate(Title = '', email = '', Sender = '', Details = '') {
        const { t, user } = this.props;
        const errors = [];

        if (!user) {
            if (!this.Country.state.value || !this.Country.state.value.value) {
                errors.push({ placeholder: t('ContactUs.Country.Placeholder'), name: 'Country' });
            }
            if (Sender.length === 0) {
                errors.push({ placeholder: t('ContactUs.Sender.Placeholder'), name: 'Sender' });
            }
            if (email.length === 0) {
                errors.push({ placeholder: t('ContactUs.Email.Placeholder'), name: 'Email' });
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                errors.push({ placeholder: t('ContactUs.Email.Placeholder'), name: 'Email' });
            }
        }

        if (Title.length === 0) {
            errors.push({ placeholder: t('ContactUs.Title.Placeholder'), name: 'Title' });
        }

        if (Details.length === 0) {
            errors.push({ placeholder: t('ContactUs.Details.Placeholder'), name: 'Details' });
        }
        return errors;
    }

    onSubmit = (e) => {

        const { t, user } = this.props;
        const recaptchaValue = this._reCaptchaRef.current ? this._reCaptchaRef.current.getValue() : "";

        var errors = this.validate(this.Title.value, this.Email ? this.Email.value : "", this.Sender ? this.Sender.value : "", this.Details.value);
        if (errors.length !== 0) {
            var errorList = errors.map((error, index) => {
                return <li key={`error-${index}`}>{error.placeholder}.</li>;
            });
            this.setState({ errorList: errorList });

            toast.error(t('Error.AllInformationIsRequired.Body'), {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            this.setState({ errorList: [] });
            if (recaptchaValue || user) {

                this.setState({ captchaErorr: '' });

                this._toastId.current = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });
                toast.update(this._toastId.current, {
                    render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                    autoClose: false,
                    closeOnClick: false,
                });

                var postData = {};
                if (user) {
                    postData = {
                        Title: this.Title.value,
                        Details: this.Details.value
                    };
                } else {
                    postData = {
                        Title: this.Title.value,
                        Email: this.Email.value,
                        Sender: this.Sender.value,
                        Country: this.Country.state.value.value,
                        Details: this.Details.value,
                        recaptchaValue
                    };
                }

                api.post(`/ContactUs/`,
                    postData
                ).then(response => {
                    if (response.data.data.success === true) {

                        toast.update(this._toastId.current, {
                            render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('ContactFormSendSuccess.Title')}</div> <div className="h6">{t('ContactFormSendSuccess.Body')}</div></div>,
                            type: toast.TYPE.SUCCESS,
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        SendContactBySocket(this.Title.value);
                        this.props.afterSubmit(true);
                    } else {
                        toast.update(this._toastId.current, {
                            render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Error.ContactFormNotSend.Title')}</div> <div className="h6">{t('Error.ContactFormNotSend.Body')}</div></div>,
                            type: toast.TYPE.ERROR,
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch(error => {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

            } else {
                this.setState({ captchaErorr: t('CaptchaErorr') });
            }
        }
        e.preventDefault();
    }

    handleChange = value => {
        this.setState({ value });
        if (value === null) {
            this.setState({ expired: "true" });
        } else {
            this.setState({ captchaErorr: '' });
        }
    };

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    render() {

        const { pristine, submitting, t, i18n, user } = this.props;
        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit}>

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                        user ? "" :
                            <>
                                <div className="form-group">
                                    <label className='text-dark font-size-h5'>{t('ContactUs.Sender.title')}</label>
                                    <div>
                                        <Field
                                            ref={Sender => this.Sender = Sender}
                                            name="Sender"
                                            component={this.renderField}
                                            type="text"
                                            required="true"
                                            validate={this.validateSender}
                                            placeholder={t('ContactUs.Sender.Placeholder')}
                                            title={t('ContactUs.Sender.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='text-dark font-size-h5'>{t('ContactUs.Country.title')}</label>
                                    <div>

                                        <Select
                                            defaultValue={this.state.CountryIndex >= 0 ? {
                                                label: this.state.countries[this.state.CountryIndex].label,
                                                value: this.state.countries[this.state.CountryIndex].value
                                            } : ""}
                                            name="Country"
                                            placeholder={t('ContactUs.Country.Placeholder')}
                                            isRtl={true}
                                            isSearchable={true}
                                            ref={Country => this.Country = Country}
                                            options={this.state.countries}
                                            filterOption={this.filterOptions}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#92278f',
                                                    primary: '#92278f',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </>
                    }

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('ContactUs.Title.title')}</label>
                        <div>
                            <Field
                                ref={Title => this.Title = Title}
                                name="Title"
                                component={this.renderField}
                                type="text"
                                required="true"
                                validate={this.validateTitle}
                                placeholder={t('ContactUs.Title.Placeholder')}
                                title={t('ContactUs.Title.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {
                        user ? "" :
                            <div className="form-group">
                                <label className='text-dark font-size-h5'>{t('Email')}</label>
                                <div>
                                    <Field
                                        ref={Email => this.Email = Email}
                                        name="Email"
                                        component={this.renderField}
                                        type="email"
                                        required="true"
                                        validate={this.validateEmail}
                                        placeholder={t('ContactUs.Email.Placeholder')}
                                        title={t('ContactUs.Email.title')}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                    }

                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5'>{t('ContactUs.Details.title')}</label>
                        <div>
                            <Field
                                ref={Details => this.Details = Details}
                                rows="5"
                                required="true"
                                id="message"
                                component={this.renderTextarea}
                                validate={this.validateDetails}
                                name="Details"
                                className="form-control"
                                placeholder={t('ContactUs.Details.Placeholder')}
                                title={t('ContactUs.Details.title')} />
                        </div>
                    </div>

                    {
                        user ? "" :
                            <div className="form-group w-100">
                                <div>
                                    <ReCAPTCHA
                                        ref={this._reCaptchaRef}
                                        sitekey={TEST_SITE_KEY}
                                        onChange={this.handleChange}
                                        hl={i18n.language}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">
                                    {this.state.captchaErorr}
                                </div>
                            </div>
                    }

                    <div className="form-group w-100 text-center">
                        <button className="custom-button" type="button" onClick={(e) => this.onSubmit(e)} disabled={pristine || submitting} ><span> {t('Send')}</span></button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.Country) {
        errors.Country = 'ContactUs.Country.error';
    }

    if (!values.Sender) {
        errors.Sender = 'ContactUs.Sender.error';
    }

    if (!values.Details) {
        errors.Details = 'ContactUs.Details.error';
    }

    if (!values.Title) {
        errors.Title = 'ContactUs.Title.error';
    }

    if (!values.Email) {
        errors.Email = 'ContactUs.Email.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.Email)) {
        errors.Email = 'ContactUs.Email.error_notmatch';
    }

    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'Contact',
    validate
})(ContactForm))
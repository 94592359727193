const QuestionTypeReducers = (state = [], action) => {
    switch (action.type) {
        case "QUESTION_TYPE_LOADING":
            return {
                questionTypes: state.questionTypes,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "QUESTION_TYPE_INITIAL":
            return {
                questionTypes: action.questionTypes,
                toRemove: [],
                isLoaded: true
            };
        default:
            return state;
    }
}

export default QuestionTypeReducers;
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Footer from "../Footer/FooterBottom";
import api from '../../api/api';

class AfterPurchase extends React.Component {

    state = { indices: [], showMessage: false, showResult: false, long_waiting: false, showError: true, count: 1, qs: "" };

    constructor(props) {
        super(props);
        window.scrollToTop();
    }

    componentDidMount() {
        const { search } = this.props.location;
        this.setState({
            qs: search.substring(1)
        });
        this.reloadAfterPurchaes(search.substring(1));
    }

    reloadAfterPurchaes = (qs) => {
        let postData = {
            qs
        };
        api.post(`/AfterPurchaes/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {
                this.setState({
                    showResult: true,
                    long_waiting: false,
                    showError: false
                });
            } else if (response.data.code === "user_canceled") {
                this.setState({
                    showResult: true,
                    long_waiting: false,
                    showError: true,
                    errorType: "user_canceled"
                });
            } else if (response.data.code === "not_success") {
                this.setState({
                    showResult: true,
                    long_waiting: false,
                    showError: true,
                    errorType: "not_success"
                });
            } else if (response.data.code === "error") {
                this.setState({
                    showResult: true,
                    long_waiting: false,
                    showError: true,
                    errorType: "error"
                });
            } else if (response.data.code === "long_waiting") {
                this.setState({
                    showResult: true,
                    long_waiting: true,
                    showError: true,
                    errorType: "long_waiting"
                });
            } else if (response.data.code === "waiting") {
                if (this.state.count <= 30) {
                    this.setState({
                        count: this.state.count + 1
                    });
                    setTimeout(() => {
                        this.reloadAfterPurchaes(qs);
                    }, 1000 * this.state.count);
                } else {
                    this.setState({
                        showResult: true,
                        showError: true,
                        long_waiting: true,
                        errorType: "long_waiting"
                    });
                }
            }
        }).catch(error => {
            this.setState({
                showMessage: true
            });
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>
                    <Helmet>
                        <title>{t("Pages.AfterPurchase.title")} | {t('alarabeyya')}</title>
                    </Helmet>
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="teacher-wrapper pos-rel pt-5  " style={{
                            minWidth: '50%'
                        }}>
                            <div className="teacher-single-top mt-2">
                                {
                                    this.state.showResult ?
                                        this.state.showError ?
                                            this.state.long_waiting ?
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-warning">
                                                        <i className="fad fa-exclamation-triangle"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle  my-3">
                                                        {t("Pages.AfterPurchase.Wait")}
                                                    </h4>
                                                </div>
                                                :
                                                this.state.errorType === "not_success" ?
                                                    <div className="product-content w-100 text-center ">
                                                        <div className="display-1 text-danger">
                                                            <i className="fad fa-exclamation-triangle"></i>
                                                        </div>
                                                        <h4 className="display-3 subtitle  my-3">
                                                            {t("Pages.AfterPurchase.not_success")}
                                                        </h4>
                                                    </div>
                                                    :
                                                    this.state.errorType === "user_canceled" ?
                                                        <div className="product-content w-100 text-center ">
                                                            <div className="display-1 text-danger">
                                                                <i className="fad fa-exclamation-triangle"></i>
                                                            </div>
                                                            <h4 className="display-3 subtitle  my-3">
                                                                {t("Pages.AfterPurchase.user_canceled")}
                                                            </h4>
                                                        </div>
                                                        :
                                                        <div className="product-content w-100 text-center ">
                                                            <div className="display-1 text-danger">
                                                                <i className="fad fa-exclamation-triangle"></i>
                                                            </div>
                                                            <h4 className="display-3 subtitle  my-3">
                                                                {t("Pages.AfterPurchase.Error")}
                                                            </h4>
                                                        </div>
                                            :
                                            <>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-success">
                                                        <i className="fad fa-shield-check"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle my-3">
                                                        {t("Pages.AfterPurchase.success")}
                                                    </h4>
                                                </div>
                                                <div className="d-flex justify-content-center mt-4 align-items-center  ">
                                                    <Link to="/Profile" className="custom-button mt-1" >
                                                        <span>
                                                            <i className="icon fal fa-user"></i>
                                                            {t('Pages.Profile.title')}
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        :
                                        this.state.showMessage ? "" :
                                            <div className="product-content w-100 text-center ">
                                                <div className="display-1 text-danger">
                                                    <i className="fad fa-sync-alt fa-spin"></i>
                                                </div>
                                                <h4 className="display-3 subtitle">
                                                    {t("Pages.AfterPurchase.Loading")}
                                                </h4>
                                            </div>
                                }
                                {
                                    this.state.showMessage ?
                                        <div className="product-content w-100 text-center ">
                                            <div className="display-1 text-danger">
                                                <i className="fad fa-exclamation-triangle"></i>
                                            </div>
                                            <h4 className="display-3 subtitle  my-3">
                                                {t("Pages.AfterPurchase.no_data.title")}
                                            </h4>
                                            <p className="text-justify">
                                                {t("Pages.AfterPurchase.no_data.s1")}
                                                <br />
                                                {t("Pages.AfterPurchase.no_data.s2")}
                                                <br />
                                                {t("Pages.AfterPurchase.no_data.s3")}
                                                <a href={`mailto:${t('HeaderTop.email')}`} className="ml-1 text-primary font-weight-bold d-inline-block">
                                                    {t('HeaderTop.email')}
                                                </a>
                                            </p>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

export default withTranslation('common')(AfterPurchase);
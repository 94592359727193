import React from "react";
import { withTranslation } from 'react-i18next'
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';

import TermsAndConditions from '../../../TermsAndConditions.js';
import api from "../../../../../api/api.js";

class UserForm extends React.Component {

    state = { showTermsAndConditions: false, emailCheck: false, Price: 20 };

    componentDidMount() {
        api.get(`/GetPrice/`).then(response => {
            this.setState({
                Price: response.data
            });
        });
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    showTermsAndConditions = () => {
        this.setState({
            showTermsAndConditions: true
        });
    }
    handleClose = () => {
        this.setState({
            showTermsAndConditions: false
        });
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Register.error_AllInformationIsRequired_toast.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    checkIfExist = Email => {
        this.setState({
            emailCheck: false
        });
        if (/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(Email)) {
            const { t } = this.props;
            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            api.post(`/Register/?isCheck=true`,
                {
                    Email
                }
            ).then(response => {
                if (response.data === "1") {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.Register.Email.info_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.info_toast.Body')}</div></div>,
                        type: toast.TYPE.INFO,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        emailCheck: true
                    });
                }
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    onSubmit = () => {
        if (!this.state.emailCheck) {
            const { t } = this.props;

            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            this.props.onSubmit({
                ...this.props.formValues
            });
        }
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;

        return (
            <>
                {
                    this.state.showTermsAndConditions ?
                        <Modal
                            size="xl"
                            show={this.state.showTermsAndConditions}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-route-interstate"></i> {t('Pages.Register.TermsAndConditions.modal')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget">
                                    <div className="widget-body">
                                        <TermsAndConditions />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={this.handleClose}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }

                <h4 className="title d-flex justify-content-center align-items-center mb-3 w-100">
                    <i className="fa-sharp fa-solid fa-coin-vertical px-1 text-primary"></i>
                    30 يومًا تجربة مجّانيّة
                </h4>

                <form method="POST" className="contact-form" onSubmit={
                    handleSubmit(values => {
                        return new Promise(resolve => resolve())
                            .then(() => {
                                const errors = validate(values, this.props);
                                return Object.keys(errors).length !== 0;
                            })
                            .then(errors => {
                                if (errors) {
                                    this.checkSubmit();
                                } else {
                                    this.onSubmit();
                                }
                            })
                    })
                } autoComplete="new-password">
                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5'><i className="fa-regular fa-at px-1"></i> {t('Pages.Register.Email.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Email => this.Email = Email}
                                name="Email"
                                component={this.renderField}
                                onBlur={e => this.checkIfExist(e.target.value)}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.Email.Placeholder')}
                                title={t('Pages.Register.Email.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group w-100">
                        <div className="custom-control ">
                            <label className="trol-label pt-1 cursor-pointer" onClick={() => this.showTermsAndConditions()}>
                                <Link to="#" title={t('Pages.Register.TermsAndConditions.show')} >
                                    <i className="icon fad fa-route-interstate text-info px-1"></i>
                                </Link>
                                {t('Pages.Register.TermsAndConditions.confirm')}
                            </label>
                        </div>
                    </div>

                    <div className="form-group w-100 text-center d-flex justify-content-around align-items-center">

                        <button className={`custom-button  ${pristine || submitting || !this.state.emailCheck ? "secondary-button" : ""}  `} disabled={pristine || submitting} >
                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                اُحصُلْ على 30 يومًا تجربةً مجّانيّةً الآن
                            </span>
                        </button>
                    </div>
                </form >

                <div className={`contact-form p-3 w-100 my-4 inner-box p-3 pt-4 `} style={{
                    background: 'rgb(170 233 255)',
                    border: '1px solid rgb(0 191 255)',
                    borderRadius: '10px'
                }}  >

                    <div>
                        <ul>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                اِختيارُ 10 ملفّات ورقيّة مِنْ بين آلاف الامتحانات وأوراق العمل.
                            </li>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                فَتحُ ثلاثة دروس محوسبة.
                            </li>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                فَتحُ ثلاث ألعاب تعليميّة (من إنتاجنا، وغير متوفّرة في أيّ موقع آخر).
                            </li>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                بدون أيّ مخاطر: يمكنك إلغاء الاشتراك بِبِضْعِ نقرات في أيّ وقت خلال الشهر الأوّل، ولن تتحمّل أيّ تكاليف.
                            </li>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                اشتراكٌ شهريٌّ بـ
                                <span className="mx-1">
                                    {this.state.Price}
                                </span>
                                دولار أمريكي، بعد انتهاء الشهر المجّاني (تُدفع سنويًّا).
                                .
                            </li>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                التسجيل سهل وسريع (يمكنك إِتمامُه في غضون 60 ثانية).
                            </li>
                            <li>
                                <i className="fa-solid fa-circle-small ml-2 fa-2xs mb-0"></i>
                                حتّى لو ألغيتَ الاشتراك، فإنّه يمكنك الاحتفاظ بجميع الملفّات التي قمتَ بتنزيلها.
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('UserForm')(state)
    };
};

const validate = values => {
    const errors = {};

    if (!values.Email) {
        errors.Email = 'Pages.Register.Email.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.Email)) {
        errors.Email = 'Pages.Register.Email.error_notmatch';
    }
    return errors;
}

const formUserForm = reduxForm({
    form: 'UserForm',
    validate
});

export default connect(mapStateToProps, {})(withTranslation('common')(formUserForm(UserForm)));
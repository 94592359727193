import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import 'moment/locale/ar';

import Footer from "../../Footer/FooterBottom";
import Backgrounds from "../../Admin/Question/View/Background";

import {
    fetchArticle as fetch,
    fetchArticles,
    clearArticle as clear
} from './Indices/actions';

class Article extends React.Component {

    state = { id: 0, IndexId: 0 };

    componentDidMount() {
        window.scrollToPosition("user-guide-article-section");
        moment.locale(`ar-dz`);
    }


    static getDerivedStateFromProps(props, state) {
        const { id } = props.match.params;
        if (id !== state.id) {
            props.clear();
            props.fetch(id);
            state.id = id;
        }
        if (props.Article && props.Article.article) {
            if (props.Article.article.Index.IndexId !== state.IndexId) {
                props.fetchArticles(props.Article.article.Index.IndexId);
                state.IndexId = props.Article.article.Index.IndexId;
            }
        }
        return state;
    }


    render() {
        const { t, Article } = this.props;
        let id = 0;
        if (Article && Article.article) {
            id = Article.article.Id;
        }
        return (
            <>
                <section className="pos-rel call-in-action call-overlay oh" id="user-guide-article-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom padding-top" style={{
                        minHeight: '100vh'
                    }}>
                        <Backgrounds CustomBackground="ReadGroup" />
                        <div className="container">
                            <div className="class-single-item mb-30" >
                                <div className="class-single-inner">
                                    <div className="class-single-content">
                                        {
                                            Article && Article.article ?
                                                <>
                                                    <h4 className="title text-center">{Article.article.Title}</h4>

                                                    <ul className="meta-post justify-content-end">
                                                        <li>
                                                            <Link to={`/User/UserGuide/${Article.article.Index.IndexId}`} >
                                                                <i className={`fa-light fa-${Article.article.Index.Icon}`}></i> {Article.article.Index.Name}
                                                            </Link>
                                                        </li>
                                                        <li  >
                                                            <Link to="#" title={`${t('Pages.UserGuide.Article.ViewCount')} : ${Article.article.ViewCount}`}>
                                                                <i className="fa-light fa-eye"></i> {Article.article.ViewCount}
                                                            </Link>
                                                        </li>
                                                        <li  >
                                                            <Link to="#" title={`${t('Pages.UserGuide.Article.UpdateDate')} : ${Article.article.UpdateDate}`}>
                                                                <i className="fa-light fa-calendar-clock"></i> {moment(Article.article.UpdateDate).fromNow()}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    <Helmet>
                                                        <title>{Article.article.Title} - {Article.article.Index.Name} | {t('alarabeyya')}</title>
                                                    </Helmet>

                                                    <div className="pt-4" dangerouslySetInnerHTML={{
                                                        __html: Article.article.Descrpition
                                                    }}>

                                                    </div>
                                                </>
                                                :
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-3 text-danger">
                                                        <i className="fad fa-sync-alt fa-spin"></i>
                                                    </div>
                                                    <h4 className="display-4 subtitle">
                                                        {t("Loading")}
                                                    </h4>
                                                </div>
                                        }
                                    </div>
                                    {
                                        Article.articles && Article.articles.length > 0 ?
                                            <div className="tags-area">
                                                <ul className="tags">
                                                    {
                                                        Article.articles.map(item => {
                                                            return <li key={`Articles_${item.Id}`}>
                                                                <Link to={`/User/UserGuides/Article/${item.Id}`} className={`${item.Id === id ? "active" : ""}`}>
                                                                    {item.Title}
                                                                </Link>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            : ""
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        Article: state.UserGuideIndices
    };
};
export default connect(mapStateToProps, { fetch, clear, fetchArticles })(withTranslation('common')(Article));
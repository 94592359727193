import React from "react";
import './style.css';

class SpaceBackground extends React.Component {

    renderSpace() {
        return (
            <div className="space">
                <div className="space__star space__star--shooting"></div>
                <div className="space__star space__star--1 space__star--small"></div>
                <div className="space__star space__star--2 space__star--small"></div>
                <div className="space__star space__star--3 space__star--small"></div>
                <div className="space__star space__star--4 space__star--medium"></div>
                <div className="space__star space__star--5 space__star--small"></div>
                <div className="space__star space__star--6 space__star--small"></div>
                <div className="space__star space__star--7 space__star--big"></div>
                <div className="space__star space__star--8 space__star--medium"></div>
                <div className="space__star space__star--9 space__star--small"></div>
                <div className="space__star space__star--10 space__star--small"></div>
                <div className="space__star space__star--11 space__star--small"></div>
                <div className="space__star space__star--12 space__star--medium"></div>
                <div className="space__star space__star--13 space__star--small"></div>
                <div className="space__star space__star--14 space__star--big"></div>
                <div className="space__star space__star--15 space__star--small"></div>
                <div className="space__star space__star--16 space__star--medium"></div>
                <div className="space__star space__star--17 space__star--small"></div>
                <div className="space__star space__star--18 space__star--small"></div>
                <div className="space__star space__star--19 space__star--small"></div>
                <div className="space__star space__star--20 space__star--medium"></div>
                <div className="space__star space__star--21 space__star--big"></div>
                <div className="space__star space__star--22 space__star--small"></div>
                <div className="space__star space__star--23 space__star--small"></div>
                <div className="space__star space__star--24 space__star--medium"></div>
                <div className="space__star space__star--25 space__star--small"></div>
                <div className="space__star space__star--26 space__star--small"></div>
                <div className="space__star space__star--27 space__star--small"></div>
                <div className="space__star space__star--28 space__star--medium"></div>
                <div className="space__star space__star--29 space__star--small"></div>
            </div>
        );
    }

    renderMoon() {
        return (
            <div className="moon">
                <div className="love">
                    <div className="love__heart love__heart--1"></div>
                    <div className="love__heart love__heart--2"></div>
                    <div className="love__heart love__heart--3"></div>
                </div>
                <div className="moon__container">
                    <div className="moon__crater moon__crater--1 moon__crater--small"></div>
                    <div className="moon__crater moon__crater--2 moon__crater--small"></div>
                    <div className="moon__crater moon__crater--3 moon__crater--small"></div>
                    <div className="moon__crater moon__crater--4 moon__crater--medium"></div>
                    <div className="moon__crater moon__crater--5 moon__crater--medium"></div>
                    <div className="moon__crater moon__crater--6 moon__crater--big"></div>
                    <div className="moon__face">
                        <div className="moon__eye moon__eye--left"></div>
                        <div className="moon__eye moon__eye--right"></div>
                        <div className="moon__cheek moon__cheek--left"></div>
                        <div className="moon__cheek moon__cheek--right"></div>
                        <div className="moon__smile"></div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                {this.renderSpace()}
                {this.renderMoon()}
            </>
        );
    }
}

export default SpaceBackground;
import React from "react";
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next'
import { toast } from 'react-toastify';

import InfoForm from '../Forms/InfoForm';
import api from '../../../../api/api';

class EditModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Id: 0,
            Name: '',
            Bio: '',
            Url: '',
            Sort: 0,
            Icon: '',
            IsForTeacher: true,
            IsPublic: false,
            IndexId: 0,
            first: false
        };
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        this.setState({
            Id: 0,
            Name: '',
            Bio: '',
            Url: '',
            Sort: 0,
            Icon: '',
            IsForTeacher: true,
            IsPublic: false,
            IndexId: 0,
            first: false
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.first && props.onUpdate && props.item) {
            state.first = true;
            state.Id = props.item.Id;
            state.Name = props.item.Name;
            state.Bio = props.item.Bio;
            state.Url = props.item.Url;
            state.Sort = props.item.Sort;
            state.Icon = props.item.Icon;
            state.IsForTeacher = props.item.IsForTeacher;
            state.IsPublic = props.item.IsPublic;
            state.IndexId = props.item.Index.Id;
        } else if (!state.first && props.onUpdate) {
            state = {
                Id: 0,
                Name: '',
                Bio: '',
                Url: '',
                Sort: 0,
                Icon: '',
                IsForTeacher: true,
                IsPublic: false,
                IndexId: 0
            };
        }
        return state;
    }

    setUrl = value => {
        this.setState({ Url: value });
    }
    setName = value => {
        this.setState({ Name: value });
    }
    setIcon = value => {
        this.setState({ Icon: value });
    }
    setIsForTeacher = value => {
        this.setState({ IsForTeacher: value });
    }
    setIsPublic = value => {
        this.setState({ IsPublic: value });
    }
    setBio = value => {
        this.setState({ Bio: value });
    }
    setIndexId = value => {
        this.setState({ IndexId: value });
    }
    setId = Id => {
        this.setState({ Id });
    }

    submitMainInfo = () => {

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.state.Name && this.state.Url && this.state.IndexId) {

            var postData = {
                Name: this.state.Name,
                Bio: this.state.Bio,
                Icon: this.state.Icon,
                Url: this.state.Url,
                IsForTeacher: this.state.IsForTeacher,
                IsPublic: this.state.IsPublic,
                IndexId: this.state.IndexId
            };

            let url = `/EducationalFiles/`;
            let method = api.post;
            if (this.state.Id && this.state.Id !== "0") {
                url = `/EducationalFiles/${this.state.Id}`;
                method = api.put;
            }

            method(url,
                postData
            ).then(response => {

                if (this.state.Id !== 0 || response.data.data.Id !== 0) {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Files.forms.Success.Title')}</div> <div className="h6">{t('Pages.Files.forms.Success.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    if (this.state.Id === "0") {
                        this.setId(response.data.data.Id);
                    }
                    this.props.updateFile();

                    this.handleClose();

                } else {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Files.forms.error.Title')}</div> <div className="h6">{t('Pages.Files.forms.error.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.AllInformationIsRequired.Title')}</div> <div className="h6">{t('Error.AllInformationIsRequired.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    handleClose = () => {
        this.setState({
            Id: 0,
            Name: '',
            Bio: '',
            Url: '',
            Sort: 0,
            Icon: '',
            IsForTeacher: true,
            IsPublic: false,
            IndexId: 0,
            first: false
        });
        this.props.handleClose();
    }

    render() {
        const { t, show, onUpdate, item } = this.props;

        if (onUpdate && !item) {
            return "";
        }

        return (
            <>
                <Modal
                    size="xl"
                    show={show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    enforceFocus={false} >
                    <Modal.Header closeButton>
                        <Modal.Title> <i className="icon fal fa-text"></i>{t('Pages.Files.forms.FilesInfo.title')} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InfoForm
                            key="filesEditForm"
                            Id={this.state.Id}
                            Name={this.state.Name}
                            IsPublic={this.state.IsPublic}
                            IsForTeacher={this.state.IsForTeacher}
                            Icon={this.state.Icon}
                            Url={this.state.Url}
                            IndexId={this.state.IndexId}
                            Bio={this.state.Bio}
                            setUrl={this.setUrl}
                            setBio={this.setBio}
                            setIsPublic={this.setIsPublic}
                            setIsForTeacher={this.setIsForTeacher}
                            setIndexId={this.setIndexId}
                            setIcon={this.setIcon}
                            setName={this.setName}></InfoForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="form-group w-100 text-center">
                            <button className="custom-button" type="button" onClick={() => this.submitMainInfo()}><span> {t('Save')}</span></button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withTranslation('common')(EditModal);
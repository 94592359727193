import React from "react";
import GameObjects from "../GameObjects";
import Backgrounds from "../Background/";

class MoveBackgroundObject extends React.Component {

    renderMoveStyle = currentMovment => {
        let result = "";
        if (currentMovment) {
            currentMovment.sort((a, b) => a.Index - b.Index).forEach(item =>
                result += item.Index + "% {visibility:visible;transform-origin:" + (item.AnswersTransformOrigin ? item.AnswersTransformOrigin : "100%") + "; transform:  scale(" + item.AnswersScale + ") rotate(" + item.AnswersRotate + "deg); opacity:" + item.AnswersOpacity / 100 + `; ${item.AnswersTranslatePositionY === "bottom" ? "bottom" : "top"} :  ` + item.AnswersTranslateY + `%; ${item.AnswersTranslatePositionX === "left" ? "left" : "right"} :` + item.AnswersTranslateX + "% }"
            );
        }
        return result;
    }

    renderBackgroundGameObject = () => {
        const { CustomStyle, Id, absolute } = this.props;

        if (CustomStyle) {
            const BackgroundGameObject = CustomStyle.gamesSetting ? CustomStyle.gamesSetting.BackgroundGameObject : CustomStyle.BackgroundGameObject ? CustomStyle.BackgroundGameObject : CustomStyle;
            if (BackgroundGameObject && BackgroundGameObject.length > 0) {
                return BackgroundGameObject.map((item, index) => {

                    const Background = item.Background;
                    if (Background) {
                        return <div key={`objectMovment-${Id}-${index}`} className="w-100 h-100 position-absolute"><Backgrounds CustomBackground={Background} absolute={absolute} /></div>;
                    }

                    return <div key={`objectMovment-${Id}-${index}`} className={`position-absolute objectMovment-${Id}-${index} ${item.MoveObject === "html" ? "w-100" : ""}`} style={{
                        visibility: 'hidden',
                        animationDelay: `${item.delay}s`,
                        animationName: `objectMovment-${Id}-${index} `,
                        animationIterationCount: 'infinite',
                        animationDuration: `${item.Time}s`,
                        animationFillMode: 'forwards',
                        animationTimingFunction: 'linear'
                    }}>
                        <style type="text/css" dangerouslySetInnerHTML={
                            {
                                __html: `
                            @keyframes objectMovment-${Id}-${index} { ${this.renderMoveStyle(item.Movments)} }`
                            }
                        }></style>
                        {
                            item.MoveObject === "html" && item.HTML ? <div dangerouslySetInnerHTML={{ __html: item.HTML }}></div> :
                                <GameObjects
                                    Time={item.Time}
                                    transformOriginX={item.transformOriginX}
                                    transformOriginY={item.transformOriginY}
                                    rotate={item.rotate}
                                    isSpin={item.isSpin}
                                    flipX={item.flipX}
                                    scale={item.scale}
                                    ObjectSrc={item.ObjectSrc}
                                    ObjectHoverSrc={item.ObjectHoverSrc}
                                    customColor={item.CustomColor}
                                    objectCss={item.MoveObject !== "" ? item.MoveObject : "SpaceShip"}
                                />
                        }
                    </div>
                });
            }
        }
        return "";
    }

    render() {
        return this.renderBackgroundGameObject();
    }
}

export default MoveBackgroundObject;
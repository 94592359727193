import React from "react";
import GameObjects from "../../GameObjects";

class PullGame extends React.Component {


    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/PullGame/bg.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute " style={{
                bottom: `20%`,
                right: `calc(50% - 275.6px)`
            }}>
                <GameObjects objectCss="GirlPullUpGame" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>
            <div className={`position-absolute w-100 h-100`} style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/PullGame/2.png"})`,
                backgroundPositionX: 'center',
                backgroundPositionY: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                top: '0'
            }}>
            </div>
            <div className={`position-absolute w-100`} style={{
                background: "#b32c39",
                height: '5px',
                top: '100px'
            }}></div>
        </div>
    }
}
export default PullGame;
import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { reduxForm, Field } from 'redux-form';
import { toast } from 'react-toastify';

import api from '../../../../../api/api';

class IndicesModal extends React.Component {

    state = { isActive: false, label: '', icon: '', id: 0 };
    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.IndicesForm) {
            state.isActive = props.IndicesForm.isActive;
            state.label = props.IndicesForm.label;
            state.icon = props.IndicesForm.icon;
            state.id = props.IndicesForm.id;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        defaultValue,
        id,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} id={id} value={defaultValue} title={title} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );

    onSubmit = (e) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });

        if (this.state.label) {
            toast.update(this._toastId.current, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            var postData = {
                FatherId: this.props.FatherId,
                label: this.props.IndicesForm.label,
                icon: this.props.IndicesForm.icon,
                isActive: this.props.IndicesForm.isActive,
                id: this.props.IndicesForm.id
            };

            let url = `/UserGuideIndexes/`;
            let method = api.post;
            if (this.state.id) {
                url += this.props.IndicesForm.id;
                method = api.put;
            }

            method(url,
                postData
            ).then(response => {
                if (this.state.id || response.data.data.Id !== 0) {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UserGuide.Indices.Success.Title')}</div> <div className="h6">{t('Pages.UserGuide.Indices.Success.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    if (this.state.id) {
                        this.props.editNode(postData);
                    } else {
                        postData.id = response.data.data.Id;
                        this.props.addNewNode(postData);
                    }
                    this.props.handleClose();
                } else {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UserGuide.Indices.error.Title')}</div> <div className="h6">{t('Pages.UserGuide.Indices.error.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            });

        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.AllInformationIsRequired.Title')}</div> <div className="h6">{t('Error.AllInformationIsRequired.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
        e.preventDefault();
    }

    validateName = value => {
        const { t } = this.props;
        if (!value) {
            return t('Pages.UserGuide.Indices.form.Name.placeholder');
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Modal
                    size="lg"
                    show={true}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="h5 mb-0">
                                {
                                    this.state.Id ?
                                        <>
                                            <i className="icon fal fa-edit"></i> {t("Pages.UserGuide.Indices.edit")}
                                        </>
                                        :
                                        <>
                                            <i className="icon fal fa-books"></i> {t("Pages.UserGuide.Indices.new")}
                                        </>
                                }
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form method="POST" className="contact-form" onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor="label"> <i className="icon fas fa-heading"></i> {t('Pages.UserGuide.Indices.form.Name.title')}</label>
                                <Field
                                    ref={label => this.label = label}
                                    id="label"
                                    name="label"
                                    defaultValue={this.props.IndicesForm.label}
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    validate={this.validateName}
                                    onChange={e => this.props.setName(e.target.value)}
                                    placeholder={t('Pages.UserGuide.Indices.form.Name.placeholder')}
                                    title={t('Pages.UserGuide.Indices.form.Name.placeholder')}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor="icon"> <i className="icon fas fa-image"></i> {t('Pages.UserGuide.Indices.form.Icon.title')}</label>
                                <Field
                                    ref={icon => this.icon = icon}
                                    id="icon"
                                    name="icon"
                                    defaultValue={(this.props.IndicesForm.icon) ? this.props.IndicesForm.icon : ''}
                                    component={this.renderField}
                                    onChange={e => this.props.setIcon(e.target.value)}
                                    type="url"
                                    placeholder={t('Pages.UserGuide.Indices.form.Icon.placeholder')}
                                    title={t('Pages.UserGuide.Indices.form.Icon.placeholder')}
                                    className="form-control"
                                />
                            </div>
                            <div className="bg-white w-100 p-1 mb-2"></div>
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor="isActive"> <i className="icon fas fa-lock-alt"></i> {t('Pages.UserGuide.Indices.form.IsActive.title')}</label>
                                <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-success">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        onChange={e => this.props.setIsActive(e.target.checked)}
                                        ref={isActive => this.isActive = isActive}
                                        defaultChecked={this.props.IndicesForm.isActive}
                                        name="isActive"
                                        id="isActive" />
                                    <label className="custom-control-label" htmlFor="isActive" >
                                        {t('Pages.UserGuide.Indices.form.IsActive.placeholder')}
                                    </label>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="w-100 row">
                            <div className="col-6">
                                <button className="btn btn-success" onClick={this.onSubmit} >
                                    <i className="icon fal fa-save"></i>
                                    {t('Pages.UserGuide.Indices.form.save')}
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-danger" onClick={this.props.handleClose} >
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withTranslation('common')(reduxForm({
    form: 'IndicesForm'
})(IndicesModal));

import React from "react";

import MoveObject from "./MoveObject";
import roundsData from "../../../../Config/rounds.json";
import objectsData from "../../../../Config/objects.json";
import ExplosionType from "../../explosions/ExplosionType";
import SelectExplosionType from "./SelectExplosionType";
import PopUps from "../PopUps";

class Shooting extends React.Component {

    constructor(props) {
        super(props);
        this.selectedCount = 0;
        this.selectableCount = 0;
        this.stageCount = 0;
        this.randomKey = Math.floor(Math.random() * 100000);
        this.state = {
            randomKey: this.randomKey,
            showItems: [],
            userAnswers: [],
            currentStageIndex: 0,
            currentCategoryId: 0,
            isStop: false,
            ExplosionType: "Explosion",
            removeState: false,
            position: {
                x: 0,
                y: 0
            },
            BounsTry: 0,
            BounsTime: 0,
            left: 0,
            top: 0,
            showBounsTime: false,
            showBounsTry: false,
            stageName: {}
        };
        this.Rounds = roundsData.Rounds;
        this.ShottingObjects = objectsData.ShottingObjects;
        this.audio = new Audio();
    }

    componentWillUnmount() {
        this.randomKey = Math.floor(Math.random() * 100000);
    }

    componentDidMount() {
        this.stageCount = this.props.Question.Answers.length;
        const category = this.props.Question.Categories.sort((a, b) => a.Sort - b.Sort)[0];
        if (category) {
            const style = category.CustomStyle.Answers.filter(p => p.Id === category.Id)[0];
            this.setState({
                currentCategoryId: category.Id,
                ExplosionType: style.AnswersSelectObject
            });
        }
        this.nextStage();
    }

    stopShooting = isStop => {
        this.setState({
            isStop,
            top: 0,
            left: 0
        });
    }

    handleShoot = event => {
        if (!this.state.isStop) {
            let rect = this.props.designerContainer.current.getBoundingClientRect();
            this.setState({
                top: event.clientY - rect.top,
                left: event.clientX - rect.left
            });
        }
    }

    handelExplosionType = (ExplosionType, id) => {
        this.setState({
            currentCategoryId: id,
            ExplosionType
        });
    }

    stop = () => {
        this.setState({
            top: 0,
            left: 0
        });
    }

    playMoveSound = MoveSound => {
        if (MoveSound) {
            if (this.audio && !this.audio.paused === false) {
                this.audio.pause();
            }
            this.audio = new Audio(MoveSound);
            this.audio.play();
        }
    }

    nextStage = () => {
        if (this.props.Question.Answers) {
            var firstAnswer = this.props.Question.Answers.sort((a, b) => a.Sort - b.Sort)[this.state.currentStageIndex];
            if (firstAnswer) {
                if (this.state.currentStageIndex > 0) {
                    this.props.playStageSound();
                }
                this.setState({
                    top: 0,
                    left: 0,
                    removeState: true,
                    currentStageIndex: this.state.currentStageIndex + 1,
                    stageName: this.Rounds[this.stageCount === (this.state.currentStageIndex + 1) ? 0 : (this.state.currentStageIndex + 1)],
                    showItems: this.setItemToShow(firstAnswer.Id)
                });
                setTimeout(() => {
                    this.setState({
                        removeState: false
                    });
                }, 3000);
            } else if (this.randomKey === this.state.randomKey) {
                this.props.showEndGame();
            }
        }
    };

    removeBounsMove = () => {
        setTimeout(() => {
            this.setState({
                BounsTry: 0,
                BounsTime: 0,
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
        }, 4 * 1000);
    }

    handelClickOnObject = clickedItem => {
        this.setState({
            BounsTry: 0,
            BounsTime: 0,
            removeState: false,
            showBounsTime: false,
            showBounsTry: false
        });
        const hasAnswer = parseInt(clickedItem.AnswersSelectType);

        if (this.props.isMission === true) {
            let userAnswers = this.state.userAnswers.slice();
            userAnswers.push({
                Answer: clickedItem,
                Category: this.state.currentCategoryId,
                isTrue: hasAnswer === this.state.currentCategoryId
            });
            this.setState({
                userAnswers
            });
            this.props.saveAnswer(userAnswers);
        }

        if (hasAnswer === this.state.currentCategoryId) {
            if (parseInt(clickedItem.BounsTry) > 0 && parseInt(clickedItem.BounsTime) > 0) {
                this.setState({
                    BounsTry: clickedItem.BounsTry,
                    BounsTime: clickedItem.BounsTime,
                    showBounsTime: true,
                    showBounsTry: true
                });
                this.props.addBounsTimeAndTry(parseInt(clickedItem.BounsTime), parseInt(clickedItem.BounsTry), true);
                this.removeBounsMove();
            } else if (parseInt(clickedItem.BounsTry) > 0) {
                this.setState({
                    BounsTry: clickedItem.BounsTry,
                    showBounsTry: true
                });
                this.props.addBounsTry(parseInt(clickedItem.BounsTry), true);
                this.removeBounsMove();
            } else if (parseInt(clickedItem.BounsTime) > 0) {
                this.setState({
                    BounsTime: clickedItem.BounsTime,
                    showBounsTime: true
                });
                this.props.addBounsTime(parseInt(clickedItem.BounsTime), true);
                this.removeBounsMove();
            } else {
                this.props.addTrueTry(true);
            }
            this.setState({
                position: {
                    x: this.state.left,
                    y: this.state.top
                }
            });
        } else {
            this.props.addFalseTry(true);
        }

        if (hasAnswer > 0) {
            this.selectedCount++;
        }
        if (this.selectedCount === this.selectableCount) {
            this.setState({
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
            this.selectedCount = 0;
            this.selectableCount = 0;
            this.nextStage();
        }
    };

    setItemToShow = currentId => {
        const { Question } = this.props;
        const answer = Question.Answers.filter(p => p.Id === currentId)[0];
        const html = answer.Name;
        const CustomStyle = answer.CustomStyle;
        let items = [];

        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerStyle.BorderRadiusTop}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusRight}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusBottom}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusLeft}${Question.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerStyle.ShadowPositionX}px ${Question.CustomStyle.answerStyle.ShadowPositionY}px ${Question.CustomStyle.answerStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerStyle.BgAttachment}`,
            zIndex: 1
        };

        const count = (html.match(/\(*_\)/g) || []).length;
        const splitAnswer = html.split('(*_)');
        for (let index = 0; index < count; index++) {
            let text = splitAnswer[index + 1] ? splitAnswer[index + 1].split('(_*)')[0] : "";
            if (CustomStyle && CustomStyle.Answers && CustomStyle.Movments) {
                const param = CustomStyle.Answers.filter(p => p.Id === currentId && p.Sort === index)[0];
                const Movments = CustomStyle.Movments.filter(p => p.Id === currentId && p.Sort === index);
                if (param) {
                    let customColor = param.CustomColor;
                    let objectCss = param.AnswersSelectObject;
                    let ObjectSrc = param.AnswersSelectObjectSrc;
                    let ObjectHoverSrc = param.AnswersSelectObjectSrcHover;
                    if (objectCss === "") {
                        objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                        ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                        ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                        if (!customColor) {
                            customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                        }
                    }
                    if (param.AnswersSelectType !== "radio" && /\d/.test(param.AnswersSelectType) && param.AnswersSelectType !== "0") {
                        this.selectableCount++;
                    }

                    objectCss = this.props.getRandomObject(objectCss);

                    items.push({
                        Id: param.Sort,
                        jsx: <MoveObject
                            getRandomNumber={this.props.getRandomNumber}
                            count={count}
                            playMoveSound={this.playMoveSound}
                            designerContainer={this.props.designerContainer}
                            item={answer}
                            id={param.Id}
                            key={`objectId-${param.Id}-${param.Sort}`}
                            param={param}
                            rotate={param.rotate}
                            isSpin={param.isSpin}
                            flipX={param.flipX}
                            scale={param.scale}
                            index={param.Sort}
                            delay={param.delay}
                            transformOriginX={param.transformOriginX}
                            transformOriginY={param.transformOriginY}
                            randomKey={this.randomKey}
                            style={styleAnswer}
                            ObjectSrc={ObjectSrc}
                            customColor={customColor}
                            ObjectHoverSrc={ObjectHoverSrc}
                            MoveType={param.AnswersMoveType}
                            Movments={Movments}
                            Time={param.AnswersTime}
                            objectCss={objectCss}
                            handelClickOnObject={this.handelClickOnObject}
                            text={text} />
                    });
                }
            }
        }

        return items;
    };

    render() {
        const { Question, isPlayVolumeSound } = this.props;
        const categories = Question.Categories;

        const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
        const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";

        let CursorButton = "";
        if (Question.CustomStyle.buttonStyle.Cursor !== 'custom' && Question.CustomStyle.buttonStyle.Cursor !== 'none' && Question.CustomStyle.buttonStyle.Cursor) {
            CursorButton = process.env.PUBLIC_URL + Question.CustomStyle.buttonStyle.Cursor;
        } else if (Question.CustomStyle.buttonStyle.Cursor === 'custom' && Question.CustomStyle.buttonStyle.CursorCustom) {
            CursorButton = Question.CustomStyle.buttonStyle.CursorCustom;
        }

        const styleBox = {
            maxWidth: '100%',
            paddingLeft: `${Question.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerBoxStyle.BorderRadiusTop}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusRight}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusBottom}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusLeft}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerBoxStyle.ShadowPositionX}px ${Question.CustomStyle.answerBoxStyle.ShadowPositionY}px ${Question.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerBoxStyle.shadowColor}`,
            opacity: `${Question.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerBoxStyle.BgAttachment}`,
            height: `${Question.CustomStyle.buttonStyle ? `${Question.CustomStyle.buttonStyle.height === "auto" ? "auto" : `${Question.CustomStyle.buttonStyle.height}px`}` : ""}`,
            width: `${Question.CustomStyle.buttonStyle ? `${Question.CustomStyle.buttonStyle.width === "auto" ? "auto" : `${Question.CustomStyle.buttonStyle.width}px`}` : ""}`,
            color: `${Question.CustomStyle.buttonStyle ? Question.CustomStyle.buttonStyle.FontColor : ""}`,
            fontSize: `${Question.CustomStyle.buttonStyle ? `${Question.CustomStyle.buttonStyle.FontSize}px` : ""}`,
            WebkitTextStrokeWidth: `${Question.CustomStyle.buttonStyle ? `${Question.CustomStyle.buttonStyle.StrokeWidth}px` : ""}`,
            WebkitTextStrokeColor: `${Question.CustomStyle.buttonStyle ? Question.CustomStyle.buttonStyle.StrokeColor : ""}`,
            textAlign: 'center',
            cursor: CursorButton ? `url('${CursorButton}') 20 20, auto` : "crosshair"
        };

        const hoverStyle = {
            ...styleBox,
            paddingLeft: `${Question.CustomStyle.categoryHoverStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.categoryHoverStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.categoryHoverStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.categoryHoverStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.categoryHoverStyle.BorderColor !== 'transparent' ? Question.CustomStyle.categoryHoverStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.categoryHoverStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.categoryHoverStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.categoryHoverStyle.BorderRadiusTop}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryHoverStyle.BorderRadiusRight}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryHoverStyle.BorderRadiusBottom}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${Question.CustomStyle.categoryHoverStyle.BorderRadiusLeft}${Question.CustomStyle.categoryHoverStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.categoryHoverStyle.ShadowPositionX}px ${Question.CustomStyle.categoryHoverStyle.ShadowPositionY}px ${Question.CustomStyle.categoryHoverStyle.ShadowRadius}px 0px ${Question.CustomStyle.categoryHoverStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.categoryHoverStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.categoryHoverStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.categoryHoverStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.categoryHoverStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.categoryHoverStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.categoryHoverStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.categoryHoverStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.categoryHoverStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.categoryHoverStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.categoryHoverStyle.BgAttachment}`,
        };

        return <div className="w-100 h-100 position-absolute oh" onClick={e => this.handleShoot(e)} >
            {
                this.state.top !== 0 && this.state.left !== 0 ?
                    <ExplosionType stop={this.stop} type={this.state.ExplosionType} top={this.state.top} left={this.state.left} isMute={!isPlayVolumeSound} />
                    : ""
            }
            <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${Question.CustomStyle.answerStyle.Custom}} .dragdrop-static-box{${Question.CustomStyle.answerBoxStyle.Custom}}` }}></style>
            <PopUps
                removeState={this.state.removeState}
                showBounsTry={this.state.showBounsTry}
                showBounsTime={this.state.showBounsTime}
                bounsTry={this.state.BounsTry}
                bounsTime={this.state.BounsTime}
                stageCount={this.stageCount}
                TryColorCustom={TryColorCustom}
                TryColor={TryColor}
                label={this.state.stageName.label}
                position={this.state.position}
            />
            {
                this.state.showItems ?
                    this.state.showItems.map((item, key) => {
                        return <span key={key} style={{
                            transform: `scale(${this.props.scale})`
                        }} >{item.jsx}</span>
                    })
                    : ""
            }
            <div className="position-absolute w-100 d-flex justify-content-center "
                style={{
                    zIndex: '15',
                    transform: `scale(${this.props.scale})`,
                    bottom: '0'
                }}>
                <div className="d-flex" onMouseEnter={() => this.stopShooting(true)} onMouseLeave={() => this.stopShooting(false)} >
                    {
                        categories && categories.length > 1 ?
                            categories.map(item => {
                                let image = process.env.PUBLIC_URL + "/assets/images/explosion/gun.png";
                                let type = "Explosion";
                                if (item.CustomStyle && item.CustomStyle.Answers) {
                                    let CustomStyleAnswers = item.CustomStyle.Answers.filter(p => p.Id === item.Id)[0];
                                    if (CustomStyleAnswers) {
                                        type = CustomStyleAnswers.AnswersSelectObject;
                                        if (CustomStyleAnswers.AnswersSelectObjectSrc) {
                                            image = CustomStyleAnswers.AnswersSelectObjectSrc;
                                        } else {
                                            var object = this.ShottingObjects.filter(p => p.value === type)[0];
                                            if (object) {
                                                image = process.env.PUBLIC_URL + object.img;
                                            }
                                        }
                                    }
                                }
                                return <SelectExplosionType
                                    key={`category-item-view-${item.Id}`}
                                    item={item}
                                    isActive={this.state.ExplosionType === type}
                                    styleBox={styleBox}
                                    hoverStyle={hoverStyle}
                                    image={image}
                                    setExplosionType={() => this.handelExplosionType(type, item.Id)}
                                />
                            })
                            : ""
                    }
                </div>
            </div>
        </div>;

    }
}

export default Shooting;
import React from "react";
import GameObjects from "../../../Question/View/GameObjects";

class HorizontalMove extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.designerContainer = React.createRef();
        this.state = {
            right: -1000,
            isShow: false,
            top: this.props.top ? this.props.top : Math.floor(Math.random() * 80),
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            timerSound: "",
            zIndex: 19,
            isHide: false,
            isHover: false,
            objectMovmentId: Math.floor(Math.random() * 100000)
        };
    }

    componentDidMount() {
        let timeout = this.state.delay;
        setTimeout(() => {
            let height = 0;
            let width = 0;
            let right = 0;
            if (this.props.designerContainer && this.props.designerContainer.current && this.designerContainer && this.designerContainer.current) {
                const subHeight = this.designerContainer.current.scrollHeight / 2;
                height = (this.props.designerContainer.current.scrollHeight * this.state.top / 100) - subHeight;
                width = this.props.designerContainer.current.scrollWidth;
                right = this.designerContainer.current.scrollWidth;
            }
            this.setState({
                right: -right,
                isShow: true,
                top: height
            });
            window.moveToInPixel(`objectMovment${this.state.objectMovmentId}`, height, width, this.props.Time * 1000);

            this.timer = setTimeout(() => {
                if (this.state.isHide === false) {
                    this.props.handelDropObject(this.props.param, false);
                    this.setState({
                        isHide: true
                    });
                }
            }, (this.props.Time * 1000));
        }, timeout);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return <div ref={this.designerContainer}
            className={`position-absolute objectMovment `}
            id={`objectMovment${this.state.objectMovmentId}`}
            style={{
                left: `${this.state.right}px`,
                top: `${this.state.top}px`,
                zIndex: this.state.zIndex ? this.state.zIndex : 19
            }}>
            <GameObjects {...this.props} type="dragdrop-move" />
        </div>
    }
}

export default HorizontalMove;
import React from "react";
import { withTranslation } from 'react-i18next';
import BackgroundGameObject from "./BackgroundGameObject";
import QuestionAnswer from "./QuestionAnswer";
import QuestionBackground from "./QuestionBackground";
import QuestionBorder from "./QuestionBorder";
import QuestionPadding from "./QuestionPadding";
import QuestionShadow from "./QuestionShadow";

class ScreenSetting extends React.Component {

    state = {
        CustomStyle: {
            GlobalStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                width: 80,
                FontColor: '',
                ButtonOneStyle: 'success',
                ButtonSecondStyle: 'danger'

            },
            LabelStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: ''
            },
            ScoreStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: '',
                StrokeColor: '',
                StrokeWidth: 3
            },
            ButtonOneStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: ''
            },
            ButtonOneHoverStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: ''
            },
            ButtonSecondStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: ''
            },
            ButtonSecondHoverStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: ''
            }
        }
    };

    constructor(props) {
        super(props);
        if (this.props.CustomStyle) {
            this.state.CustomStyle = this.props.CustomStyle;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            CustomStyle: {
                ...nextProps.CustomStyle
            }
        });
    }

    setPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setHeight = value => {
        if (value >= 1 && value <= 1000) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        height: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        height: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 1000) {
            value = 1000;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        height: 1000
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setWidth = value => {
        if (value >= 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        width: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        width: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setAlign = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    AnswersAlign: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    GlobalStyle: {
                        ...this.state.CustomStyle.GlobalStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonOneStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    ButtonOneStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    ButtonSecondStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    ButtonNextStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingTopLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingRightLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingLeftLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingBottomLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusUnitLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthTopLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthRightLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthLeftLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthBottomLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusTopLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusRightLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusLeftLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusBottomLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderColorLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderStyleLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCustomLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgSizeLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgAttachmentLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgRepeatLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundColorLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowColorLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowPositionXLabelStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowPositionYLabelStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowRadiusLabelStyle = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setHeightLabelStyle = value => {
        if (value >= 1 && value <= 1000) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        height: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        height: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 1000) {
            value = 1000;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        height: 1000
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setWidthLabelStyle = value => {
        if (value >= 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        width: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        width: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setAlignLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    AnswersAlign: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundImageLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setOpacityLabelStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionXLabelStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionYLabelStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LabelStyle: {
                        ...this.state.CustomStyle.LabelStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setFontColorLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setMarginLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    Margin: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setMarginBottomLabelStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LabelStyle: {
                    ...this.state.CustomStyle.LabelStyle,
                    MarginBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setPaddingScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingTopScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingRightScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingLeftScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingBottomScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusUnitScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthTopScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthRightScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthLeftScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthBottomScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusTopScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusRightScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusLeftScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusBottomScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderColorScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderStyleScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCustomScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgSizeScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgAttachmentScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgRepeatScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundColorScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowColorScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowPositionXScoreStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowPositionYScoreStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowRadiusScoreStyle = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setHeightScoreStyle = value => {
        if (value >= 1 && value <= 1000) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        height: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        height: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 1000) {
            value = 1000;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        height: 1000
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setWidthScoreStyle = value => {
        if (value >= 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        width: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        width: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setAlignScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    AnswersAlign: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundImageScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setOpacityScoreStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionXScoreStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionYScoreStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ScoreStyle: {
                        ...this.state.CustomStyle.ScoreStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setFontColorScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setStrokeWidthScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setStrokeColorScoreStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ScoreStyle: {
                    ...this.state.CustomStyle.ScoreStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }


    setButtonCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });

    }
    setButtonOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneStyle: {
                        ...this.state.CustomStyle.ButtonOneStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonFontSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    FontSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonMarginBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    MarginBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    height: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneStyle: {
                    ...this.state.CustomStyle.ButtonOneStyle,
                    width: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }


    setButtonHoverCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonHoverBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonHoverBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonHoverBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonHoverShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonHoverShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonOneHoverStyle: {
                        ...this.state.CustomStyle.ButtonOneHoverStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonHoverPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });

    }
    setButtonHoverFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    height: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonHoverWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonOneHoverStyle: {
                    ...this.state.CustomStyle.ButtonOneHoverStyle,
                    width: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }


    setButtonSecondCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });

    }
    setButtonSecondOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonSecondBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonSecondBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonSecondBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonSecondShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonSecondShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondStyle: {
                        ...this.state.CustomStyle.ButtonSecondStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonSecondPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondFontSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    FontSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondMarginBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    MarginBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    height: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondStyle: {
                    ...this.state.CustomStyle.ButtonSecondStyle,
                    width: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonSecondHoverBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonSecondHoverBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonSecondHoverBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonSecondHoverShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonSecondHoverShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonSecondHoverStyle: {
                        ...this.state.CustomStyle.ButtonSecondHoverStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonSecondHoverPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });

    }
    setButtonSecondHoverFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    height: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonSecondHoverWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonSecondHoverStyle: {
                    ...this.state.CustomStyle.ButtonSecondHoverStyle,
                    width: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setButtonNextCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });

    }
    setButtonNextOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonNextBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonNextBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonNextBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonNextShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonNextShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextStyle: {
                        ...this.state.CustomStyle.ButtonNextStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonNextPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextFontSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    FontSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextMarginBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    MarginBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    height: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextStyle: {
                    ...this.state.CustomStyle.ButtonNextStyle,
                    width: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonNextHoverBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonNextHoverBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonNextHoverBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonNextHoverShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }

    }
    setButtonNextHoverShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ButtonNextHoverStyle: {
                        ...this.state.CustomStyle.ButtonNextHoverStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setButtonNextHoverPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });

    }
    setButtonNextHoverFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    height: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setButtonNextHoverWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                ButtonNextHoverStyle: {
                    ...this.state.CustomStyle.ButtonNextHoverStyle,
                    width: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setFontSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    FontSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setSecondFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SecondFontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Repeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setControlColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    ControlColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setSecondControlColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SecondControlColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setMainColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    MainColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setSecondMainColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SecondMainColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setSecondColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SecondColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setSecondSecondColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SecondSecondColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Cursor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    CursorCustom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setIconType = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    IconType: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setIcon = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Icon: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setIconColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    IconColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setIconCustomColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    IconCustomColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setIconHoverColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    IconHoverColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setIconPosition = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    IconPosition: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCustomBackground = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    CustomBackground: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setBackgroundGameObject = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BackgroundGameObject: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setSplashGameObject = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SplashGameObject: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    StrokeWidth: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    StrokeColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Color: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setSplashTime = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SplashTime: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setBackgroundGameCustomSound = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    CustomBackgroundSound: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setGameBackgroundSound = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    BackgroundSound: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setSplashSound = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    SplashSound: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                GlobalStyle: {
                    ...this.state.CustomStyle.GlobalStyle,
                    Size: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setPaddingLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingTopLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingRightLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingLeftLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setPaddingBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusUnitLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusUnit: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthTopLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthRightLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthLeftLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderWidthBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusTopLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusTop: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusRightLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusRight: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusLeftLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusLeft: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderRadiusBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBorderStyleLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderStyle: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setCustomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Custom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgSizeLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgSize: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgAttachmentLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgAttachment: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBgRepeatLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgRepeat: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    backgroundColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    shadowColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setShadowPositionXLogoStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionX: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionX: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowPositionYLogoStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionY: -100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionY: 100
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setShadowRadiusLogoStyle = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowRadius: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setHeightLogoStyle = value => {
        if (value >= 1 && value <= 1000) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        height: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        height: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value > 1000) {
            value = 1000;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        height: 1000
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setWidthLogoStyle = value => {
        if (value >= 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        width: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        width: 1
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setAlignLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    AnswersAlign: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setBackgroundImageLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    backgroundImage: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setOpacityLogoStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        Opacity: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionXLogoStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        BgPositionX: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setBgPositionYLogoStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        BgPositionY: value
                    }
                }
            }, () => {
                this.afterSetStateFinished();
            });
        }
    }
    setFontColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    FontColor: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setMarginLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Margin: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }
    setMarginBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    MarginBottom: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setLogoMargin = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Margin: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setCustomBackgroundLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    CustomBackground: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setPositionYLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PositionY: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }

    setPositionXLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PositionX: value
                }
            }
        }, () => {
            this.afterSetStateFinished();
        });
    }


    afterSetStateFinished = () => {
        this.props.update(this.state.CustomStyle);
    }

    render() {
        const { t, isStop, isIntroScreen } = this.props;

        return <div className="d-flex flex-wrap w-100">
            <div className="w-100 mx-auto">
                <ul className="filter mb-2 mx-1"  >
                    <li>
                        {t('Pages.Lessons.Questions.forms.tools.GlobalStyle')}
                    </li>
                    <QuestionBackground
                        BgSize={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgSize : ""}
                        Custom={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Custom : ""}
                        Opacity={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Opacity : ""}
                        BgRepeat={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgRepeat : ""}
                        BgPositionX={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgPositionX : ""}
                        BgPositionY={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgPositionY : ""}
                        BgAttachment={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgAttachment : ""}
                        backgroundColor={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.backgroundColor : ""}
                        backgroundImage={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.backgroundImage : ""}
                        setQuestionBackgroundColor={this.setBackgroundColor}
                        setQuestionBackgroundImage={this.setBackgroundImage}
                        setQuestionBgPositionX={this.setBgPositionX}
                        setQuestionBgPositionY={this.setBgPositionY}
                        setQuestionBgSize={this.setBgSize}
                        setQuestionOpacity={this.setOpacity}
                        setQuestionCustom={this.setCustom}
                        setQuestionBgRepeat={this.setBgRepeat}
                        setQuestionBgAttachment={this.setBgAttachment}
                    />
                    <QuestionBorder
                        BorderColor={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderColor : ""}
                        BorderStyle={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderStyle : ""}
                        BorderWidth={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderWidth : ""}
                        BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderWidthTop : ""}
                        BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderWidthRight : ""}
                        BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderWidthLeft : ""}
                        BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderWidthBottom : ""}
                        BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderRadiusUnit : ""}
                        BorderRadius={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderRadius : ""}
                        BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderRadiusTop : ""}
                        BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderRadiusRight : ""}
                        BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderRadiusLeft : ""}
                        BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BorderRadiusBottom : ""}
                        setQuestionBorderStyle={this.setBorderStyle}
                        setQuestionBorderColor={this.setBorderColor}
                        setQuestionBorderWidth={this.setBorderWidth}
                        setQuestionBorderWidthTop={this.setBorderWidthTop}
                        setQuestionBorderWidthLeft={this.setBorderWidthLeft}
                        setQuestionBorderWidthRight={this.setBorderWidthRight}
                        setQuestionBorderWidthBottom={this.setBorderWidthBottom}
                        setQuestionBorderRadius={this.setBorderRadius}
                        setQuestionBorderRadiusTop={this.setBorderRadiusTop}
                        setQuestionBorderRadiusLeft={this.setBorderRadiusLeft}
                        setQuestionBorderRadiusRight={this.setBorderRadiusRight}
                        setQuestionBorderRadiusBottom={this.setBorderRadiusBottom}
                        setQuestionBorderRadiusUnit={this.setBorderRadiusUnit}
                    />
                    <QuestionShadow
                        shadowColor={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.shadowColor : ""}
                        ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ShadowRadius : ""}
                        ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ShadowPositionX : ""}
                        ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ShadowPositionY : ""}
                        setQuestionShadowColor={this.setShadowColor}
                        setQuestionShadowRadius={this.setShadowRadius}
                        setQuestionShadowPositionX={this.setShadowPositionX}
                        setQuestionShadowPositionY={this.setShadowPositionY}
                    />
                    <QuestionPadding
                        Padding={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Padding : ""}
                        PaddingTop={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.PaddingTop : ""}
                        PaddingRight={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.PaddingRight : ""}
                        PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.PaddingLeft : ""}
                        PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.PaddingBottom : ""}
                        setQuestionPadding={this.setPadding}
                        setQuestionPaddingTop={this.setPaddingTop}
                        setQuestionPaddingLeft={this.setPaddingLeft}
                        setQuestionPaddingRight={this.setPaddingRight}
                        setQuestionPaddingBottom={this.setPaddingBottom}
                    />
                    <BackgroundGameObject
                        BgSize={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgSize : ""}
                        Custom={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Custom : ""}
                        Opacity={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Opacity : ""}
                        BgRepeat={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgRepeat : ""}
                        BgPositionX={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgPositionX : ""}
                        BgPositionY={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgPositionY : ""}
                        BgAttachment={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgAttachment : ""}
                        backgroundColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.backgroundColor : ""}
                        backgroundImage={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.backgroundImage : ""}
                        BackgroundGameObject={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BackgroundGameObject : []}
                        setBackgroundGameObject={this.setBackgroundGameObject}
                    />
                    {
                        this.props.isDashBoard ?
                            <QuestionAnswer
                                hasControlColor={true}
                                hideLogo={true}
                                hasCursor={true}
                                IconType={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.IconType : "fas"}
                                Icon={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Icon : "arrow"}
                                IconColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.IconColor : ""}
                                IconPosition={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.IconPosition : "in"}
                                IconCustomColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.IconCustomColor : "rgba(0, 0,   0,  1)"}
                                IconHoverColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.IconHoverColor : "rgba(0, 0,   0,  1)"}
                                width={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.width : ""}
                                height={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.height : ""}
                                Cursor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Cursor : ""}
                                CursorCustom={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.CursorCustom : ""}
                                FontColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.FontColor : ""}
                                SecondFontColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SecondFontColor : ""}
                                MainColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.MainColor : ""}
                                SecondMainColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SecondMainColor : ""}
                                SecondColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SecondColor : ""}
                                SecondSecondColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SecondSecondColor : ""}
                                AnswersAlign={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.AnswersAlign : ""}
                                ControlColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ControlColor : ""}
                                SecondControlColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SecondControlColor : ""}
                                CustomBackground={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.CustomBackground : ""}
                                Repeat={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Repeat : ""}
                                setQuestionWidth={this.setWidth}
                                setCursor={this.setCursor}
                                setCursorCustom={this.setCursorCustom}
                                setMainColor={this.setMainColor}
                                setSecondMainColor={this.setSecondMainColor}
                                setFontColor={this.setFontColor}
                                setRepeat={this.setRepeat}
                                setSecondFontColor={this.setSecondFontColor}
                                setQuestionHeight={this.setHeight}
                                setSecondColor={this.setSecondColor}
                                setSecondSecondColor={this.setSecondSecondColor}
                                setControlColor={this.setControlColor}
                                setSecondControlColor={this.setSecondControlColor}
                                setQuestionAnswersAlign={this.setAlign}
                                setIconType={this.setIconType}
                                setIcon={this.setIcon}
                                setCustomBackground={this.setCustomBackground}
                                setIconColor={this.setIconColor}
                                setIconCustomColor={this.setIconCustomColor}
                                setIconHoverColor={this.setIconHoverColor}
                                setIconPosition={this.setIconPosition}
                            />
                            :
                            isIntroScreen === true ?
                                <QuestionAnswer
                                    isIntroScreen={true}
                                    hasSplashTime={true}
                                    IconType={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.IconType : ""}
                                    setIconType={this.setIconType}
                                    Icon={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Icon : ""}
                                    setIcon={this.setIcon}
                                    FontColor={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.FontColor : ""}
                                    setFontColor={this.setFontColor}
                                    FontSize={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.FontSize : ""}
                                    setFontSize={this.setFontSize}
                                    Cursor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Cursor : ""}
                                    setCursor={this.setCursor}
                                    CursorCustom={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.CursorCustom : ""}
                                    setCursorCustom={this.setCursorCustom}
                                    StrokeWidth={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.StrokeWidth : ""}
                                    setStrokeWidth={this.setStrokeWidth}
                                    StrokeColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.StrokeColor : ""}
                                    setStrokeColor={this.setStrokeColor}
                                    Color={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Color : ""}
                                    setColor={this.setColor}
                                    Size={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Size : ""}
                                    setSize={this.setSize}
                                    SplashTime={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SplashTime : ""}
                                    setSplashTime={this.setSplashTime}
                                    CustomBackgroundSound={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.CustomBackgroundSound : ""}
                                    setBackgroundGameCustomSound={this.setBackgroundGameCustomSound}
                                    BackgroundSound={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BackgroundSound : ""}
                                    setGameBackgroundSound={this.setGameBackgroundSound}
                                    SplashSound={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SplashSound : ""}
                                    setSplashSound={this.setSplashSound}
                                /> :
                                <QuestionAnswer
                                    subScreen={true}
                                    type={this.props.typeId === 2 || this.props.typeId === 3 ? "success" : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.width : ""}
                                    height={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.height : ""}
                                    FontSize={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.FontColor : ""}
                                    AnswersAlign={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.AnswersAlign : ""}
                                    ButtonOneStyle={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ButtonOneStyle : ""}
                                    ButtonSecondStyle={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ButtonSecondStyle : ""}
                                    ButtonNextStyle={this.state.CustomStyle && this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.ButtonNextStyle : ""}
                                    setQuestionWidth={this.setWidth}
                                    setFontColor={this.setFontColor}
                                    setQuestionHeight={this.setHeight}
                                    setQuestionAnswersAlign={this.setAlign}
                                    setButtonOneStyle={this.setButtonOneStyle}
                                    setButtonSecondStyle={this.setButtonSecondStyle}
                                    setButtonNextStyle={this.setButtonNextStyle}
                                />
                    }
                    {
                        this.props.isIntroScreen ?
                            <BackgroundGameObject
                                title={t('Pages.Lessons.Questions.forms.tools.SplashObject')}
                                BgSize={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgSize : ""}
                                Custom={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Custom : ""}
                                Opacity={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.Opacity : ""}
                                BgRepeat={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgRepeat : ""}
                                BgPositionX={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgPositionX : ""}
                                BgPositionY={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgPositionY : ""}
                                BgAttachment={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.BgAttachment : ""}
                                backgroundColor={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.backgroundColor : ""}
                                backgroundImage={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.backgroundImage : ""}
                                BackgroundGameObject={this.state.CustomStyle.GlobalStyle ? this.state.CustomStyle.GlobalStyle.SplashGameObject : []}
                                setBackgroundGameObject={this.setSplashGameObject}
                            />
                            : ""
                    }
                </ul>
            </div>

            <div className="w-100 mx-auto ">
                <ul className="filter mb-2 mx-1"  >
                    <li>
                        {
                            isIntroScreen === true ? t('Pages.Lessons.Questions.forms.tools.buttonHoverStyle') : t('Pages.Lessons.Questions.forms.tools.LevelStyle')
                        }
                    </li>
                    <QuestionBackground
                        BgSize={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BgSize : ""}
                        Custom={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.Custom : ""}
                        Opacity={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.Opacity : ""}
                        BgRepeat={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BgRepeat : ""}
                        BgPositionX={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BgPositionX : ""}
                        BgPositionY={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BgPositionY : ""}
                        BgAttachment={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BgAttachment : ""}
                        backgroundColor={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.backgroundColor : ""}
                        backgroundImage={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.backgroundImage : ""}
                        setQuestionBackgroundColor={this.setBackgroundColorLabelStyle}
                        setQuestionBackgroundImage={this.setBackgroundImageLabelStyle}
                        setQuestionBgPositionX={this.setBgPositionXLabelStyle}
                        setQuestionBgPositionY={this.setBgPositionYLabelStyle}
                        setQuestionBgSize={this.setBgSizeLabelStyle}
                        setQuestionOpacity={this.setOpacityLabelStyle}
                        setQuestionCustom={this.setCustomLabelStyle}
                        setQuestionBgRepeat={this.setBgRepeatLabelStyle}
                        setQuestionBgAttachment={this.setBgAttachmentLabelStyle}
                    />
                    <QuestionBorder
                        BorderColor={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderColor : ""}
                        BorderStyle={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderStyle : ""}
                        BorderWidth={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderWidth : ""}
                        BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderWidthTop : ""}
                        BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderWidthRight : ""}
                        BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderWidthLeft : ""}
                        BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderWidthBottom : ""}
                        BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderRadiusUnit : ""}
                        BorderRadius={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderRadius : ""}
                        BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderRadiusTop : ""}
                        BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderRadiusRight : ""}
                        BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderRadiusLeft : ""}
                        BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.BorderRadiusBottom : ""}
                        setQuestionBorderStyle={this.setBorderStyleLabelStyle}
                        setQuestionBorderColor={this.setBorderColorLabelStyle}
                        setQuestionBorderWidth={this.setBorderWidthLabelStyle}
                        setQuestionBorderWidthTop={this.setBorderWidthTopLabelStyle}
                        setQuestionBorderWidthLeft={this.setBorderWidthLeftLabelStyle}
                        setQuestionBorderWidthRight={this.setBorderWidthRightLabelStyle}
                        setQuestionBorderWidthBottom={this.setBorderWidthBottomLabelStyle}
                        setQuestionBorderRadius={this.setBorderRadiusLabelStyle}
                        setQuestionBorderRadiusTop={this.setBorderRadiusTopLabelStyle}
                        setQuestionBorderRadiusLeft={this.setBorderRadiusLeftLabelStyle}
                        setQuestionBorderRadiusRight={this.setBorderRadiusRightLabelStyle}
                        setQuestionBorderRadiusBottom={this.setBorderRadiusBottomLabelStyle}
                        setQuestionBorderRadiusUnit={this.setBorderRadiusUnitLabelStyle}
                    />
                    <QuestionShadow
                        shadowColor={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.shadowColor : ""}
                        ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.ShadowRadius : ""}
                        ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.ShadowPositionX : ""}
                        ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.ShadowPositionY : ""}
                        setQuestionShadowColor={this.setShadowColorLabelStyle}
                        setQuestionShadowRadius={this.setShadowRadiusLabelStyle}
                        setQuestionShadowPositionX={this.setShadowPositionXLabelStyle}
                        setQuestionShadowPositionY={this.setShadowPositionYLabelStyle}
                    />
                    <QuestionPadding
                        Padding={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.Padding : ""}
                        PaddingTop={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.PaddingTop : ""}
                        PaddingRight={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.PaddingRight : ""}
                        PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.PaddingLeft : ""}
                        PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.PaddingBottom : ""}
                        setQuestionPadding={this.setPaddingLabelStyle}
                        setQuestionPaddingTop={this.setPaddingTopLabelStyle}
                        setQuestionPaddingLeft={this.setPaddingLeftLabelStyle}
                        setQuestionPaddingRight={this.setPaddingRightLabelStyle}
                        setQuestionPaddingBottom={this.setPaddingBottomLabelStyle}
                    />

                    {
                        isIntroScreen === true ? "" :
                            <QuestionAnswer
                                hasFontColor={true}
                                Margin={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.Margin : ""}
                                width={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.width : ""}
                                height={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.height : ""}
                                FontColor={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.FontColor : ""}
                                MarginBottom={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.MarginBottom : ""}
                                AnswersAlign={this.state.CustomStyle && this.state.CustomStyle.LabelStyle ? this.state.CustomStyle.LabelStyle.AnswersAlign : ""}
                                setMargin={this.setMarginLabelStyle}
                                setQuestionWidth={this.setWidthLabelStyle}
                                setFontColor={this.setFontColorLabelStyle}
                                setMarginBottom={this.setMarginBottomLabelStyle}
                                setQuestionHeight={this.setHeightLabelStyle}
                                setQuestionAnswersAlign={this.setAlignLabelStyle}
                            />
                    }
                </ul>
            </div>

            {
                this.props.typeId === 1 ?
                    <div className="w-100 mx-auto ">
                        <ul className="filter mb-2 mx-1"  >
                            <li>
                                {t('Pages.Lessons.Questions.forms.tools.LogoStyle')}
                            </li>
                            <QuestionBackground
                                BgSize={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgSize : ""}
                                Custom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Custom : ""}
                                Opacity={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Opacity : ""}
                                BgRepeat={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgRepeat : ""}
                                BgPositionX={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgPositionX : ""}
                                BgPositionY={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgPositionY : ""}
                                BgAttachment={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgAttachment : ""}
                                backgroundColor={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.backgroundColor : ""}
                                backgroundImage={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.backgroundImage : ""}
                                setQuestionBackgroundColor={this.setBackgroundColorLogoStyle}
                                setQuestionBackgroundImage={this.setBackgroundImageLogoStyle}
                                setQuestionBgPositionX={this.setBgPositionXLogoStyle}
                                setQuestionBgPositionY={this.setBgPositionYLogoStyle}
                                setQuestionBgSize={this.setBgSizeLogoStyle}
                                setQuestionOpacity={this.setOpacityLogoStyle}
                                setQuestionCustom={this.setCustomLogoStyle}
                                setQuestionBgRepeat={this.setBgRepeatLogoStyle}
                                setQuestionBgAttachment={this.setBgAttachmentLogoStyle}
                            />
                            <QuestionBorder
                                BorderColor={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderColor : ""}
                                BorderStyle={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderStyle : ""}
                                BorderWidth={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidth : ""}
                                BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthTop : ""}
                                BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthRight : ""}
                                BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthLeft : ""}
                                BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthBottom : ""}
                                BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusUnit : ""}
                                BorderRadius={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadius : ""}
                                BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusTop : ""}
                                BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusRight : ""}
                                BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusLeft : ""}
                                BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusBottom : ""}
                                setQuestionBorderStyle={this.setBorderStyleLogoStyle}
                                setQuestionBorderColor={this.setBorderColorLogoStyle}
                                setQuestionBorderWidth={this.setBorderWidthLogoStyle}
                                setQuestionBorderWidthTop={this.setBorderWidthTopLogoStyle}
                                setQuestionBorderWidthLeft={this.setBorderWidthLeftLogoStyle}
                                setQuestionBorderWidthRight={this.setBorderWidthRightLogoStyle}
                                setQuestionBorderWidthBottom={this.setBorderWidthBottomLogoStyle}
                                setQuestionBorderRadius={this.setBorderRadiusLogoStyle}
                                setQuestionBorderRadiusTop={this.setBorderRadiusTopLogoStyle}
                                setQuestionBorderRadiusLeft={this.setBorderRadiusLeftLogoStyle}
                                setQuestionBorderRadiusRight={this.setBorderRadiusRightLogoStyle}
                                setQuestionBorderRadiusBottom={this.setBorderRadiusBottomLogoStyle}
                                setQuestionBorderRadiusUnit={this.setBorderRadiusUnitLogoStyle}
                            />
                            <QuestionShadow
                                shadowColor={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.shadowColor : ""}
                                ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.ShadowRadius : ""}
                                ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.ShadowPositionX : ""}
                                ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.ShadowPositionY : ""}
                                setQuestionShadowColor={this.setShadowColorLogoStyle}
                                setQuestionShadowRadius={this.setShadowRadiusLogoStyle}
                                setQuestionShadowPositionX={this.setShadowPositionXLogoStyle}
                                setQuestionShadowPositionY={this.setShadowPositionYLogoStyle}
                            />
                            <QuestionPadding
                                Padding={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Padding : ""}
                                PaddingTop={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingTop : ""}
                                PaddingRight={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingRight : ""}
                                PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingLeft : ""}
                                PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingBottom : ""}
                                setQuestionPadding={this.setPaddingLogoStyle}
                                setQuestionPaddingTop={this.setPaddingTopLogoStyle}
                                setQuestionPaddingLeft={this.setPaddingLeftLogoStyle}
                                setQuestionPaddingRight={this.setPaddingRightLogoStyle}
                                setQuestionPaddingBottom={this.setPaddingBottomLogoStyle}
                            />

                            <QuestionAnswer
                                Margin={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Margin : ""}
                                setLogoMargin={this.setLogoMargin}
                                CustomBackground={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.CustomBackground : ""}
                                setCustomBackground={this.setCustomBackgroundLogoStyle}
                                PositionY={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PositionY : ""}
                                setLogoPositionY={this.setPositionYLogoStyle}
                                PositionX={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PositionX : ""}
                                setLogoPositionX={this.setPositionXLogoStyle}
                            />
                        </ul>
                    </div>
                    : ""
            }
            {
                isStop === true ? "" :
                    <div className="w-100 mx-auto ">
                        <ul className="filter mb-2 mx-1"  >
                            <li>
                                {t('Pages.Lessons.Questions.forms.tools.ScoreStyle')}
                            </li>
                            <QuestionBackground
                                BgSize={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BgSize : ""}
                                Custom={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.Custom : ""}
                                Opacity={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.Opacity : ""}
                                BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BgRepeat : ""}
                                BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BgPositionX : ""}
                                BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BgPositionY : ""}
                                BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BgAttachment : ""}
                                backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.backgroundColor : ""}
                                backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.backgroundImage : ""}
                                setQuestionBackgroundColor={this.setBackgroundColorScoreStyle}
                                setQuestionBackgroundImage={this.setBackgroundImageScoreStyle}
                                setQuestionBgPositionX={this.setBgPositionXScoreStyle}
                                setQuestionBgPositionY={this.setBgPositionYScoreStyle}
                                setQuestionBgSize={this.setBgSizeScoreStyle}
                                setQuestionOpacity={this.setOpacityScoreStyle}
                                setQuestionCustom={this.setCustomScoreStyle}
                                setQuestionBgRepeat={this.setBgRepeatScoreStyle}
                                setQuestionBgAttachment={this.setBgAttachmentScoreStyle}
                            />
                            <QuestionBorder
                                BorderColor={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderColor : ""}
                                BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderStyle : ""}
                                BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderWidth : ""}
                                BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderWidthTop : ""}
                                BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderWidthRight : ""}
                                BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderWidthLeft : ""}
                                BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderWidthBottom : ""}
                                BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderRadiusUnit : ""}
                                BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderRadius : ""}
                                BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderRadiusTop : ""}
                                BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderRadiusRight : ""}
                                BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderRadiusLeft : ""}
                                BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.BorderRadiusBottom : ""}
                                setQuestionBorderStyle={this.setBorderStyleScoreStyle}
                                setQuestionBorderColor={this.setBorderColorScoreStyle}
                                setQuestionBorderWidth={this.setBorderWidthScoreStyle}
                                setQuestionBorderWidthTop={this.setBorderWidthTopScoreStyle}
                                setQuestionBorderWidthLeft={this.setBorderWidthLeftScoreStyle}
                                setQuestionBorderWidthRight={this.setBorderWidthRightScoreStyle}
                                setQuestionBorderWidthBottom={this.setBorderWidthBottomScoreStyle}
                                setQuestionBorderRadius={this.setBorderRadiusScoreStyle}
                                setQuestionBorderRadiusTop={this.setBorderRadiusTopScoreStyle}
                                setQuestionBorderRadiusLeft={this.setBorderRadiusLeftScoreStyle}
                                setQuestionBorderRadiusRight={this.setBorderRadiusRightScoreStyle}
                                setQuestionBorderRadiusBottom={this.setBorderRadiusBottomScoreStyle}
                                setQuestionBorderRadiusUnit={this.setBorderRadiusUnitScoreStyle}
                            />
                            <QuestionShadow
                                shadowColor={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.shadowColor : ""}
                                ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.ShadowRadius : ""}
                                ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.ShadowPositionX : ""}
                                ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.ShadowPositionY : ""}
                                setQuestionShadowColor={this.setShadowColorScoreStyle}
                                setQuestionShadowRadius={this.setShadowRadiusScoreStyle}
                                setQuestionShadowPositionX={this.setShadowPositionXScoreStyle}
                                setQuestionShadowPositionY={this.setShadowPositionYScoreStyle}
                            />
                            <QuestionPadding
                                Padding={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.Padding : ""}
                                PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.PaddingTop : ""}
                                PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.PaddingRight : ""}
                                PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.PaddingLeft : ""}
                                PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.PaddingBottom : ""}
                                setQuestionPadding={this.setPaddingScoreStyle}
                                setQuestionPaddingTop={this.setPaddingTopScoreStyle}
                                setQuestionPaddingLeft={this.setPaddingLeftScoreStyle}
                                setQuestionPaddingRight={this.setPaddingRightScoreStyle}
                                setQuestionPaddingBottom={this.setPaddingBottomScoreStyle}
                            />
                            <QuestionAnswer
                                hasFontColor={true}
                                hasStroke={true}
                                width={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.width : ""}
                                height={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.height : ""}
                                FontColor={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.FontColor : ""}
                                AnswersAlign={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.AnswersAlign : ""}
                                StrokeColor={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.StrokeColor : ""}
                                StrokeWidth={this.state.CustomStyle && this.state.CustomStyle.ScoreStyle ? this.state.CustomStyle.ScoreStyle.StrokeWidth : ""}
                                setQuestionWidth={this.setWidthScoreStyle}
                                setFontColor={this.setFontColorScoreStyle}
                                setQuestionHeight={this.setHeightScoreStyle}
                                setQuestionAnswersAlign={this.setAlignScoreStyle}
                                setStrokeWidth={this.setStrokeWidthScoreStyle}
                                setStrokeColor={this.setStrokeColorScoreStyle}
                            />
                        </ul>
                    </div>
            }
            {
                this.props.isIntroScreen || (this.state.CustomStyle && this.state.CustomStyle.GlobalStyle && this.state.CustomStyle.GlobalStyle.ButtonOneStyle === "custom") ?
                    <>
                        <div className="w-100 h3 text-center title mt-3">
                            {
                                this.props.isIntroScreen ?
                                    t('Pages.Lessons.Questions.forms.tools.objects.ButtonsStyle') :
                                    t('Pages.Lessons.Questions.forms.tools.objects.ButtonOneStyle')
                            }
                        </div>
                        <div className="w-50 mx-auto">
                            <div className="h6 text-center title">
                                {t('Pages.Lessons.Questions.forms.tools.buttonStyle')}
                            </div>
                            <ul className="filter mx-1 mb-3 px-1">
                                <QuestionBackground
                                    BgSize={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BgSize : ''}
                                    Custom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.Custom : ''}
                                    Opacity={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.Opacity : ''}
                                    BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BgRepeat : ''}
                                    BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BgPositionX : ''}
                                    BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BgPositionY : ''}
                                    BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BgAttachment : ''}
                                    backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.backgroundColor : ''}
                                    backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.backgroundImage : ''}
                                    setQuestionBackgroundColor={this.setButtonBackgroundColor}
                                    setQuestionBackgroundImage={this.setButtonBackgroundImage}
                                    setQuestionBgPositionX={this.setButtonBgPositionX}
                                    setQuestionBgPositionY={this.setButtonBgPositionY}
                                    setQuestionBgSize={this.setButtonBgSize}
                                    setQuestionOpacity={this.setButtonOpacity}
                                    setQuestionCustom={this.setButtonCustom}
                                    setQuestionBgRepeat={this.setButtonBgRepeat}
                                    setQuestionBgAttachment={this.setButtonBgAttachment}
                                />
                                <QuestionBorder
                                    BorderColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderColor : ''}
                                    BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderStyle : ''}
                                    BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderWidth : ''}
                                    BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderWidthTop : ''}
                                    BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderWidthRight : ''}
                                    BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderWidthLeft : ''}
                                    BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderWidthBottom : ''}
                                    BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderRadiusUnit : ''}
                                    BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderRadius : ''}
                                    BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderRadiusTop : ''}
                                    BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderRadiusRight : ''}
                                    BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderRadiusLeft : ''}
                                    BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.BorderRadiusBottom : ''}
                                    setQuestionBorderStyle={this.setButtonBorderStyle}
                                    setQuestionBorderColor={this.setButtonBorderColor}
                                    setQuestionBorderWidth={this.setButtonBorderWidth}
                                    setQuestionBorderWidthTop={this.setButtonBorderWidthTop}
                                    setQuestionBorderWidthLeft={this.setButtonBorderWidthLeft}
                                    setQuestionBorderWidthRight={this.setButtonBorderWidthRight}
                                    setQuestionBorderWidthBottom={this.setButtonBorderWidthBottom}
                                    setQuestionBorderRadius={this.setButtonBorderRadius}
                                    setQuestionBorderRadiusTop={this.setButtonBorderRadiusTop}
                                    setQuestionBorderRadiusLeft={this.setButtonBorderRadiusLeft}
                                    setQuestionBorderRadiusRight={this.setButtonBorderRadiusRight}
                                    setQuestionBorderRadiusBottom={this.setButtonBorderRadiusBottom}
                                    setQuestionBorderRadiusUnit={this.setButtonBorderRadiusUnit}
                                />
                                <QuestionShadow
                                    shadowColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.shadowColor : ''}
                                    ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.ShadowRadius : ''}
                                    ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.ShadowPositionX : ''}
                                    ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.ShadowPositionY : ''}
                                    setQuestionShadowColor={this.setButtonShadowColor}
                                    setQuestionShadowRadius={this.setButtonShadowRadius}
                                    setQuestionShadowPositionX={this.setButtonShadowPositionX}
                                    setQuestionShadowPositionY={this.setButtonShadowPositionY}
                                />
                                <QuestionPadding
                                    Padding={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.Padding : ''}
                                    PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.PaddingTop : ''}
                                    PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.PaddingRight : ''}
                                    PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.PaddingLeft : ''}
                                    PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.PaddingBottom : ''}
                                    setQuestionPadding={this.setButtonPadding}
                                    setQuestionPaddingTop={this.setButtonPaddingTop}
                                    setQuestionPaddingLeft={this.setButtonPaddingLeft}
                                    setQuestionPaddingRight={this.setButtonPaddingRight}
                                    setQuestionPaddingBottom={this.setButtonPaddingBottom}
                                />
                                {
                                    this.props.isIntroScreen ?
                                        <QuestionAnswer
                                            onlyFont={true}
                                            height={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.height : ""}
                                            width={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.width : ""}
                                            FontColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.FontColor : ""}
                                            setFontColor={this.setButtonFontColor}
                                            setQuestionHeight={this.setButtonHeight}
                                            setQuestionWidth={this.setButtonWidth}
                                        /> :
                                        <QuestionAnswer
                                            hasButton={true}
                                            hasCursor={true}
                                            hasDimensions={true}
                                            height={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.height : ""}
                                            width={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.width : ""}
                                            Cursor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.Cursor : ""}
                                            CursorCustom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.CursorCustom : ""}
                                            FontColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.FontColor : ""}
                                            StrokeWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.StrokeWidth : ""}
                                            StrokeColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.StrokeColor : ""}
                                            MarginBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.MarginBottom : ""}
                                            FontSize={this.state.CustomStyle && this.state.CustomStyle.ButtonOneStyle ? this.state.CustomStyle.ButtonOneStyle.FontSize : ""}
                                            setStrokeWidth={this.setButtonStrokeWidth}
                                            setStrokeColor={this.setButtonStrokeColor}
                                            setCursor={this.setButtonCursor}
                                            setCursorCustom={this.setButtonCursorCustom}
                                            setFontColor={this.setButtonFontColor}
                                            setFontSize={this.setButtonFontSize}
                                            setMarginBottom={this.setButtonMarginBottom}
                                            setQuestionHeight={this.setButtonHeight}
                                            setQuestionWidth={this.setButtonWidth}
                                        />
                                }
                            </ul>
                        </div>
                        <div className="w-50 mx-auto">
                            <div className="h6 text-center title">
                                {t('Pages.Lessons.Questions.forms.tools.buttonHoverStyle')}
                            </div>
                            <ul className="filter mx-1 mb-3 px-1">
                                <QuestionBackground
                                    BgSize={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BgSize : ''}
                                    Custom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.Custom : ''}
                                    Opacity={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.Opacity : ''}
                                    BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BgRepeat : ''}
                                    BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BgPositionX : ''}
                                    BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BgPositionY : ''}
                                    BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BgAttachment : ''}
                                    backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.backgroundColor : ''}
                                    backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.backgroundImage : ''}
                                    setQuestionBackgroundColor={this.setButtonHoverBackgroundColor}
                                    setQuestionBackgroundImage={this.setButtonHoverBackgroundImage}
                                    setQuestionBgPositionX={this.setButtonHoverBgPositionX}
                                    setQuestionBgPositionY={this.setButtonHoverBgPositionY}
                                    setQuestionBgSize={this.setButtonHoverBgSize}
                                    setQuestionOpacity={this.setButtonHoverOpacity}
                                    setQuestionCustom={this.setButtonHoverCustom}
                                    setQuestionBgRepeat={this.setButtonHoverBgRepeat}
                                    setQuestionBgAttachment={this.setButtonHoverBgAttachment}
                                />
                                <QuestionBorder
                                    BorderColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderColor : ''}
                                    BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderStyle : ''}
                                    BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderWidth : ''}
                                    BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderWidthTop : ''}
                                    BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderWidthRight : ''}
                                    BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderWidthLeft : ''}
                                    BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderWidthBottom : ''}
                                    BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderRadiusUnit : ''}
                                    BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderRadius : ''}
                                    BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderRadiusTop : ''}
                                    BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderRadiusRight : ''}
                                    BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderRadiusLeft : ''}
                                    BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.BorderRadiusBottom : ''}
                                    setQuestionBorderStyle={this.setButtonHoverBorderStyle}
                                    setQuestionBorderColor={this.setButtonHoverBorderColor}
                                    setQuestionBorderWidth={this.setButtonHoverBorderWidth}
                                    setQuestionBorderWidthTop={this.setButtonHoverBorderWidthTop}
                                    setQuestionBorderWidthLeft={this.setButtonHoverBorderWidthLeft}
                                    setQuestionBorderWidthRight={this.setButtonHoverBorderWidthRight}
                                    setQuestionBorderWidthBottom={this.setButtonHoverBorderWidthBottom}
                                    setQuestionBorderRadius={this.setButtonHoverBorderRadius}
                                    setQuestionBorderRadiusTop={this.setButtonHoverBorderRadiusTop}
                                    setQuestionBorderRadiusLeft={this.setButtonHoverBorderRadiusLeft}
                                    setQuestionBorderRadiusRight={this.setButtonHoverBorderRadiusRight}
                                    setQuestionBorderRadiusBottom={this.setButtonHoverBorderRadiusBottom}
                                    setQuestionBorderRadiusUnit={this.setButtonHoverBorderRadiusUnit}
                                />
                                <QuestionShadow
                                    shadowColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.shadowColor : ''}
                                    ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.ShadowRadius : ''}
                                    ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.ShadowPositionX : ''}
                                    ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.ShadowPositionY : ''}
                                    setQuestionShadowColor={this.setButtonHoverShadowColor}
                                    setQuestionShadowRadius={this.setButtonHoverShadowRadius}
                                    setQuestionShadowPositionX={this.setButtonHoverShadowPositionX}
                                    setQuestionShadowPositionY={this.setButtonHoverShadowPositionY}
                                />
                                <QuestionPadding
                                    Padding={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.Padding : ''}
                                    PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.PaddingTop : ''}
                                    PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.PaddingRight : ''}
                                    PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.PaddingLeft : ''}
                                    PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.PaddingBottom : ''}
                                    height={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.width : ""}
                                    setQuestionPadding={this.setButtonHoverPadding}
                                    setQuestionPaddingTop={this.setButtonHoverPaddingTop}
                                    setQuestionPaddingLeft={this.setButtonHoverPaddingLeft}
                                    setQuestionPaddingRight={this.setButtonHoverPaddingRight}
                                    setQuestionPaddingBottom={this.setButtonHoverPaddingBottom}
                                />
                                {
                                    this.props.isIntroScreen ?
                                        <QuestionAnswer
                                            onlyFont={true}
                                            hasCursor={true}
                                            FontColor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.FontColor : ""}
                                            height={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.height : ""}
                                            width={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.width : ""}
                                            Cursor={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.Cursor : ""}
                                            CursorCustom={this.state.CustomStyle && this.state.CustomStyle.ButtonOneHoverStyle ? this.state.CustomStyle.ButtonOneHoverStyle.CursorCustom : ""}
                                            setFontColor={this.setButtonHoverFontColor}
                                            setQuestionHeight={this.setButtonHoverHeight}
                                            setQuestionWidth={this.setButtonHoverWidth}
                                            setCursor={this.setButtonHoverCursor}
                                            setCursorCustom={this.setButtonHoverCursorCustom}
                                        /> :
                                        ""
                                }
                            </ul>
                        </div>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                    </>
                    : ""
            }
            {
                this.state.CustomStyle && this.state.CustomStyle.GlobalStyle && this.state.CustomStyle.GlobalStyle.ButtonSecondStyle === "custom" ?
                    <>
                        <div className="w-100 h3 text-center title mt-3">
                            {t('Pages.Lessons.Questions.forms.tools.objects.ButtonSecondStyle')}
                        </div>
                        <div className="w-50 mx-auto">
                            <div className="h6 text-center title">
                                {t('Pages.Lessons.Questions.forms.tools.buttonStyle')}
                            </div>
                            <ul className="filter mx-1 mb-3 px-1">
                                <QuestionBackground
                                    BgSize={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BgSize : ''}
                                    Custom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.Custom : ''}
                                    Opacity={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.Opacity : ''}
                                    BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BgRepeat : ''}
                                    BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BgPositionX : ''}
                                    BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BgPositionY : ''}
                                    BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BgAttachment : ''}
                                    backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.backgroundColor : ''}
                                    backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.backgroundImage : ''}
                                    setQuestionBackgroundColor={this.setButtonSecondBackgroundColor}
                                    setQuestionBackgroundImage={this.setButtonSecondBackgroundImage}
                                    setQuestionBgPositionX={this.setButtonSecondBgPositionX}
                                    setQuestionBgPositionY={this.setButtonSecondBgPositionY}
                                    setQuestionBgSize={this.setButtonSecondBgSize}
                                    setQuestionOpacity={this.setButtonSecondOpacity}
                                    setQuestionCustom={this.setButtonSecondCustom}
                                    setQuestionBgRepeat={this.setButtonSecondBgRepeat}
                                    setQuestionBgAttachment={this.setButtonSecondBgAttachment}
                                />
                                <QuestionBorder
                                    BorderColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderColor : ''}
                                    BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderStyle : ''}
                                    BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderWidth : ''}
                                    BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderWidthTop : ''}
                                    BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderWidthRight : ''}
                                    BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderWidthLeft : ''}
                                    BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderWidthBottom : ''}
                                    BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderRadiusUnit : ''}
                                    BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderRadius : ''}
                                    BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderRadiusTop : ''}
                                    BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderRadiusRight : ''}
                                    BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderRadiusLeft : ''}
                                    BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.BorderRadiusBottom : ''}
                                    setQuestionBorderStyle={this.setButtonSecondBorderStyle}
                                    setQuestionBorderColor={this.setButtonSecondBorderColor}
                                    setQuestionBorderWidth={this.setButtonSecondBorderWidth}
                                    setQuestionBorderWidthTop={this.setButtonSecondBorderWidthTop}
                                    setQuestionBorderWidthLeft={this.setButtonSecondBorderWidthLeft}
                                    setQuestionBorderWidthRight={this.setButtonSecondBorderWidthRight}
                                    setQuestionBorderWidthBottom={this.setButtonSecondBorderWidthBottom}
                                    setQuestionBorderRadius={this.setButtonSecondBorderRadius}
                                    setQuestionBorderRadiusTop={this.setButtonSecondBorderRadiusTop}
                                    setQuestionBorderRadiusLeft={this.setButtonSecondBorderRadiusLeft}
                                    setQuestionBorderRadiusRight={this.setButtonSecondBorderRadiusRight}
                                    setQuestionBorderRadiusBottom={this.setButtonSecondBorderRadiusBottom}
                                    setQuestionBorderRadiusUnit={this.setButtonSecondBorderRadiusUnit}
                                />
                                <QuestionShadow
                                    shadowColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.shadowColor : ''}
                                    ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.ShadowRadius : ''}
                                    ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.ShadowPositionX : ''}
                                    ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.ShadowPositionY : ''}
                                    setQuestionShadowColor={this.setButtonSecondShadowColor}
                                    setQuestionShadowRadius={this.setButtonSecondShadowRadius}
                                    setQuestionShadowPositionX={this.setButtonSecondShadowPositionX}
                                    setQuestionShadowPositionY={this.setButtonSecondShadowPositionY}
                                />
                                <QuestionPadding
                                    Padding={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.Padding : ''}
                                    PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.PaddingTop : ''}
                                    PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.PaddingRight : ''}
                                    PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.PaddingLeft : ''}
                                    PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.PaddingBottom : ''}
                                    setQuestionPadding={this.setButtonSecondPadding}
                                    setQuestionPaddingTop={this.setButtonSecondPaddingTop}
                                    setQuestionPaddingLeft={this.setButtonSecondPaddingLeft}
                                    setQuestionPaddingRight={this.setButtonSecondPaddingRight}
                                    setQuestionPaddingBottom={this.setButtonSecondPaddingBottom}
                                />
                                <QuestionAnswer
                                    hasButton={true}
                                    hasDimensions={true}
                                    hasCursor={true}
                                    Cursor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.Cursor : ""}
                                    CursorCustom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.CursorCustom : ""}
                                    FontColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.FontColor : ""}
                                    StrokeWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.StrokeWidth : ""}
                                    StrokeColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.StrokeColor : ""}
                                    MarginBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.MarginBottom : ""}
                                    FontSize={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.FontSize : ""}
                                    height={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondStyle ? this.state.CustomStyle.ButtonSecondStyle.width : ""}
                                    setStrokeWidth={this.setButtonSecondStrokeWidth}
                                    setStrokeColor={this.setButtonSecondStrokeColor}
                                    setCursor={this.setButtonSecondCursor}
                                    setCursorCustom={this.setButtonSecondCursorCustom}
                                    setFontColor={this.setButtonSecondFontColor}
                                    setFontSize={this.setButtonSecondFontSize}
                                    setMarginBottom={this.setButtonSecondMarginBottom}
                                    setQuestionHeight={this.setButtonSecondHeight}
                                    setQuestionWidth={this.setButtonSecondWidth}
                                />
                            </ul>
                        </div>
                        <div className="w-50 mx-auto">
                            <div className="h6 text-center title">
                                {t('Pages.Lessons.Questions.forms.tools.buttonHoverStyle')}
                            </div>
                            <ul className="filter mx-1 mb-3 px-1">
                                <QuestionBackground
                                    BgSize={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BgSize : ''}
                                    Custom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.Custom : ''}
                                    Opacity={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.Opacity : ''}
                                    BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BgRepeat : ''}
                                    BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BgPositionX : ''}
                                    BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BgPositionY : ''}
                                    BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BgAttachment : ''}
                                    backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.backgroundColor : ''}
                                    backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.backgroundImage : ''}
                                    setQuestionBackgroundColor={this.setButtonSecondHoverBackgroundColor}
                                    setQuestionBackgroundImage={this.setButtonSecondHoverBackgroundImage}
                                    setQuestionBgPositionX={this.setButtonSecondHoverBgPositionX}
                                    setQuestionBgPositionY={this.setButtonSecondHoverBgPositionY}
                                    setQuestionBgSize={this.setButtonSecondHoverBgSize}
                                    setQuestionOpacity={this.setButtonSecondHoverOpacity}
                                    setQuestionCustom={this.setButtonSecondHoverCustom}
                                    setQuestionBgRepeat={this.setButtonSecondHoverBgRepeat}
                                    setQuestionBgAttachment={this.setButtonSecondHoverBgAttachment}
                                />
                                <QuestionBorder
                                    BorderColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderColor : ''}
                                    BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderStyle : ''}
                                    BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderWidth : ''}
                                    BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderWidthTop : ''}
                                    BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderWidthRight : ''}
                                    BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderWidthLeft : ''}
                                    BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderWidthBottom : ''}
                                    BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderRadiusUnit : ''}
                                    BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderRadius : ''}
                                    BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderRadiusTop : ''}
                                    BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderRadiusRight : ''}
                                    BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderRadiusLeft : ''}
                                    BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.BorderRadiusBottom : ''}
                                    setQuestionBorderStyle={this.setButtonSecondHoverBorderStyle}
                                    setQuestionBorderColor={this.setButtonSecondHoverBorderColor}
                                    setQuestionBorderWidth={this.setButtonSecondHoverBorderWidth}
                                    setQuestionBorderWidthTop={this.setButtonSecondHoverBorderWidthTop}
                                    setQuestionBorderWidthLeft={this.setButtonSecondHoverBorderWidthLeft}
                                    setQuestionBorderWidthRight={this.setButtonSecondHoverBorderWidthRight}
                                    setQuestionBorderWidthBottom={this.setButtonSecondHoverBorderWidthBottom}
                                    setQuestionBorderRadius={this.setButtonSecondHoverBorderRadius}
                                    setQuestionBorderRadiusTop={this.setButtonSecondHoverBorderRadiusTop}
                                    setQuestionBorderRadiusLeft={this.setButtonSecondHoverBorderRadiusLeft}
                                    setQuestionBorderRadiusRight={this.setButtonSecondHoverBorderRadiusRight}
                                    setQuestionBorderRadiusBottom={this.setButtonSecondHoverBorderRadiusBottom}
                                    setQuestionBorderRadiusUnit={this.setButtonSecondHoverBorderRadiusUnit}
                                />
                                <QuestionShadow
                                    shadowColor={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.shadowColor : ''}
                                    ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.ShadowRadius : ''}
                                    ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.ShadowPositionX : ''}
                                    ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.ShadowPositionY : ''}
                                    setQuestionShadowColor={this.setButtonSecondHoverShadowColor}
                                    setQuestionShadowRadius={this.setButtonSecondHoverShadowRadius}
                                    setQuestionShadowPositionX={this.setButtonSecondHoverShadowPositionX}
                                    setQuestionShadowPositionY={this.setButtonSecondHoverShadowPositionY}
                                />
                                <QuestionPadding
                                    Padding={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.Padding : ''}
                                    PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.PaddingTop : ''}
                                    PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.PaddingRight : ''}
                                    PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.PaddingLeft : ''}
                                    PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonSecondHoverStyle ? this.state.CustomStyle.ButtonSecondHoverStyle.PaddingBottom : ''}
                                    setQuestionPadding={this.setButtonSecondHoverPadding}
                                    setQuestionPaddingTop={this.setButtonSecondHoverPaddingTop}
                                    setQuestionPaddingLeft={this.setButtonSecondHoverPaddingLeft}
                                    setQuestionPaddingRight={this.setButtonSecondHoverPaddingRight}
                                    setQuestionPaddingBottom={this.setButtonSecondHoverPaddingBottom}
                                />

                            </ul>
                        </div>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                    </>
                    : ""
            }
            {
                this.state.CustomStyle && this.state.CustomStyle.GlobalStyle && this.state.CustomStyle.GlobalStyle.ButtonNextStyle === "custom" ?
                    <>
                        <div className="w-100 h3 text-center title mt-3">
                            {t('Pages.Lessons.Questions.forms.tools.objects.ButtonNextStyle')}
                        </div>
                        <div className="w-50 mx-auto">
                            <div className="h6 text-center title">
                                {t('Pages.Lessons.Questions.forms.tools.buttonStyle')}
                            </div>
                            <ul className="filter mx-1 mb-3 px-1">
                                <QuestionBackground
                                    BgSize={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BgSize : ''}
                                    Custom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.Custom : ''}
                                    Opacity={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.Opacity : ''}
                                    BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BgRepeat : ''}
                                    BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BgPositionX : ''}
                                    BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BgPositionY : ''}
                                    BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BgAttachment : ''}
                                    backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.backgroundColor : ''}
                                    backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.backgroundImage : ''}
                                    setQuestionBackgroundColor={this.setButtonNextBackgroundColor}
                                    setQuestionBackgroundImage={this.setButtonNextBackgroundImage}
                                    setQuestionBgPositionX={this.setButtonNextBgPositionX}
                                    setQuestionBgPositionY={this.setButtonNextBgPositionY}
                                    setQuestionBgSize={this.setButtonNextBgSize}
                                    setQuestionOpacity={this.setButtonNextOpacity}
                                    setQuestionCustom={this.setButtonNextCustom}
                                    setQuestionBgRepeat={this.setButtonNextBgRepeat}
                                    setQuestionBgAttachment={this.setButtonNextBgAttachment}
                                />
                                <QuestionBorder
                                    BorderColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderColor : ''}
                                    BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderStyle : ''}
                                    BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderWidth : ''}
                                    BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderWidthTop : ''}
                                    BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderWidthRight : ''}
                                    BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderWidthLeft : ''}
                                    BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderWidthBottom : ''}
                                    BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderRadiusUnit : ''}
                                    BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderRadius : ''}
                                    BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderRadiusTop : ''}
                                    BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderRadiusRight : ''}
                                    BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderRadiusLeft : ''}
                                    BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.BorderRadiusBottom : ''}
                                    setQuestionBorderStyle={this.setButtonNextBorderStyle}
                                    setQuestionBorderColor={this.setButtonNextBorderColor}
                                    setQuestionBorderWidth={this.setButtonNextBorderWidth}
                                    setQuestionBorderWidthTop={this.setButtonNextBorderWidthTop}
                                    setQuestionBorderWidthLeft={this.setButtonNextBorderWidthLeft}
                                    setQuestionBorderWidthRight={this.setButtonNextBorderWidthRight}
                                    setQuestionBorderWidthBottom={this.setButtonNextBorderWidthBottom}
                                    setQuestionBorderRadius={this.setButtonNextBorderRadius}
                                    setQuestionBorderRadiusTop={this.setButtonNextBorderRadiusTop}
                                    setQuestionBorderRadiusLeft={this.setButtonNextBorderRadiusLeft}
                                    setQuestionBorderRadiusRight={this.setButtonNextBorderRadiusRight}
                                    setQuestionBorderRadiusBottom={this.setButtonNextBorderRadiusBottom}
                                    setQuestionBorderRadiusUnit={this.setButtonNextBorderRadiusUnit}
                                />
                                <QuestionShadow
                                    shadowColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.shadowColor : ''}
                                    ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.ShadowRadius : ''}
                                    ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.ShadowPositionX : ''}
                                    ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.ShadowPositionY : ''}
                                    setQuestionShadowColor={this.setButtonNextShadowColor}
                                    setQuestionShadowRadius={this.setButtonNextShadowRadius}
                                    setQuestionShadowPositionX={this.setButtonNextShadowPositionX}
                                    setQuestionShadowPositionY={this.setButtonNextShadowPositionY}
                                />
                                <QuestionPadding
                                    Padding={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.Padding : ''}
                                    PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.PaddingTop : ''}
                                    PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.PaddingRight : ''}
                                    PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.PaddingLeft : ''}
                                    PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.PaddingBottom : ''}
                                    setQuestionPadding={this.setButtonNextPadding}
                                    setQuestionPaddingTop={this.setButtonNextPaddingTop}
                                    setQuestionPaddingLeft={this.setButtonNextPaddingLeft}
                                    setQuestionPaddingRight={this.setButtonNextPaddingRight}
                                    setQuestionPaddingBottom={this.setButtonNextPaddingBottom}
                                />
                                <QuestionAnswer
                                    hasButton={true}
                                    hasDimensions={true}
                                    hasCursor={true}
                                    Cursor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.Cursor : ""}
                                    CursorCustom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.CursorCustom : ""}
                                    FontColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.FontColor : ""}
                                    StrokeWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.StrokeWidth : ""}
                                    StrokeColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.StrokeColor : ""}
                                    MarginBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.MarginBottom : ""}
                                    FontSize={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.FontSize : ""}
                                    height={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.ButtonNextStyle ? this.state.CustomStyle.ButtonNextStyle.width : ""}
                                    setStrokeWidth={this.setButtonNextStrokeWidth}
                                    setStrokeColor={this.setButtonNextStrokeColor}
                                    setCursor={this.setButtonNextCursor}
                                    setCursorCustom={this.setButtonNextCursorCustom}
                                    setFontColor={this.setButtonNextFontColor}
                                    setFontSize={this.setButtonNextFontSize}
                                    setMarginBottom={this.setButtonNextMarginBottom}
                                    setQuestionHeight={this.setButtonNextHeight}
                                    setQuestionWidth={this.setButtonNextWidth}
                                />
                            </ul>
                        </div>
                        <div className="w-50 mx-auto">
                            <div className="h6 text-center title">
                                {t('Pages.Lessons.Questions.forms.tools.buttonHoverStyle')}
                            </div>
                            <ul className="filter mx-1 mb-3 px-1">
                                <QuestionBackground
                                    BgSize={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BgSize : ''}
                                    Custom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.Custom : ''}
                                    Opacity={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.Opacity : ''}
                                    BgRepeat={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BgRepeat : ''}
                                    BgPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BgPositionX : ''}
                                    BgPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BgPositionY : ''}
                                    BgAttachment={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BgAttachment : ''}
                                    backgroundColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.backgroundColor : ''}
                                    backgroundImage={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.backgroundImage : ''}
                                    setQuestionBackgroundColor={this.setButtonNextHoverBackgroundColor}
                                    setQuestionBackgroundImage={this.setButtonNextHoverBackgroundImage}
                                    setQuestionBgPositionX={this.setButtonNextHoverBgPositionX}
                                    setQuestionBgPositionY={this.setButtonNextHoverBgPositionY}
                                    setQuestionBgSize={this.setButtonNextHoverBgSize}
                                    setQuestionOpacity={this.setButtonNextHoverOpacity}
                                    setQuestionCustom={this.setButtonNextHoverCustom}
                                    setQuestionBgRepeat={this.setButtonNextHoverBgRepeat}
                                    setQuestionBgAttachment={this.setButtonNextHoverBgAttachment}
                                />
                                <QuestionBorder
                                    BorderColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderColor : ''}
                                    BorderStyle={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderStyle : ''}
                                    BorderWidth={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderWidth : ''}
                                    BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderWidthTop : ''}
                                    BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderWidthRight : ''}
                                    BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderWidthLeft : ''}
                                    BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderWidthBottom : ''}
                                    BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderRadiusUnit : ''}
                                    BorderRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderRadius : ''}
                                    BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderRadiusTop : ''}
                                    BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderRadiusRight : ''}
                                    BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderRadiusLeft : ''}
                                    BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.BorderRadiusBottom : ''}
                                    setQuestionBorderStyle={this.setButtonNextHoverBorderStyle}
                                    setQuestionBorderColor={this.setButtonNextHoverBorderColor}
                                    setQuestionBorderWidth={this.setButtonNextHoverBorderWidth}
                                    setQuestionBorderWidthTop={this.setButtonNextHoverBorderWidthTop}
                                    setQuestionBorderWidthLeft={this.setButtonNextHoverBorderWidthLeft}
                                    setQuestionBorderWidthRight={this.setButtonNextHoverBorderWidthRight}
                                    setQuestionBorderWidthBottom={this.setButtonNextHoverBorderWidthBottom}
                                    setQuestionBorderRadius={this.setButtonNextHoverBorderRadius}
                                    setQuestionBorderRadiusTop={this.setButtonNextHoverBorderRadiusTop}
                                    setQuestionBorderRadiusLeft={this.setButtonNextHoverBorderRadiusLeft}
                                    setQuestionBorderRadiusRight={this.setButtonNextHoverBorderRadiusRight}
                                    setQuestionBorderRadiusBottom={this.setButtonNextHoverBorderRadiusBottom}
                                    setQuestionBorderRadiusUnit={this.setButtonNextHoverBorderRadiusUnit}
                                />
                                <QuestionShadow
                                    shadowColor={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.shadowColor : ''}
                                    ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.ShadowRadius : ''}
                                    ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.ShadowPositionX : ''}
                                    ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.ShadowPositionY : ''}
                                    setQuestionShadowColor={this.setButtonNextHoverShadowColor}
                                    setQuestionShadowRadius={this.setButtonNextHoverShadowRadius}
                                    setQuestionShadowPositionX={this.setButtonNextHoverShadowPositionX}
                                    setQuestionShadowPositionY={this.setButtonNextHoverShadowPositionY}
                                />
                                <QuestionPadding
                                    Padding={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.Padding : ''}
                                    PaddingTop={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.PaddingTop : ''}
                                    PaddingRight={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.PaddingRight : ''}
                                    PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.PaddingLeft : ''}
                                    PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.ButtonNextHoverStyle ? this.state.CustomStyle.ButtonNextHoverStyle.PaddingBottom : ''}
                                    setQuestionPadding={this.setButtonNextHoverPadding}
                                    setQuestionPaddingTop={this.setButtonNextHoverPaddingTop}
                                    setQuestionPaddingLeft={this.setButtonNextHoverPaddingLeft}
                                    setQuestionPaddingRight={this.setButtonNextHoverPaddingRight}
                                    setQuestionPaddingBottom={this.setButtonNextHoverPaddingBottom}
                                />

                            </ul>
                        </div>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                    </>
                    : ""
            }
        </div>;
    }
}

export default withTranslation('common')(ScreenSetting);
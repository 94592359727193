import React from "react";
import TextBox from "../TextBox";

class CustomImg extends React.Component {

    constructor(props) {
        super(props);
        this.state = { src: this.props.ObjectSrc }
    }

    onHover = isHover => {
        let src = this.props.ObjectSrc;
        if (isHover && this.props.ObjectHoverSrc) {
            src = this.props.ObjectHoverSrc;
        }
        this.setState({
            src
        });
    }

    render() {
        return (
            <div className="d-flex justify-content-center flex-column align-items-center"
                onMouseEnter={() => this.onHover(true)}
                onMouseOut={() => this.onHover(false)}>
                <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                    <div style={{
                        transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                        transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `,
                        width: 'max-content'
                    }}>
                        {
                            this.state.src ?
                                <img src={this.state.src} alt={`${this.props.text}`} />
                                : ""
                        }
                    </div>
                </div>
                <TextBox {...this.props} />
            </div >
        );
    }
}

export default CustomImg;
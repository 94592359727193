import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import {
    fetchSliders as fetch,
    removeSlider as remove,
    undoDeleteSlider as undo,
    clearSlider as clear
} from './actions';
import ListItem from "./ListItem";
import InfoForm from './Forms/InfoForm';
import api from '../../../api/api';

class SlidersList extends React.Component {

    state = { search: '', showEditFormModal: false, onUpdate: false };

    componentDidMount() {
        this.props.fetch();
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = () => {
        this.props.fetch();
    }

    ShowEditForm = () => {
        this.setState({
            showEditFormModal: true,
            onUpdate: false
        });
    }

    renderSliders() {
        const { t, sliders } = this.props;
        let isLoaded = false;

        if (sliders) {
            isLoaded = sliders.isLoaded;
        }

        let toRemove = [];
        if (sliders) {
            toRemove = sliders.toRemove;
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (sliders && sliders.sliders && sliders.sliders.length > 0) {
            let filterSlider = sliders.sliders.filter(v => v.Title.includes(search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Sliders.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterSlider.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Sliders.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Sliders.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterSlider.map(slider =>
                (toRemove && toRemove.includes(slider.Id)) ? "" :
                    <ListItem
                        key={slider.Id}
                        item={slider}
                        Sync={this.Sync}
                        remove={this.showDelete}></ListItem>
            );
        }


        if (sliders && sliders.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Sliders.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Sliders.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    handleClose = () => {
        this.setState({
            showEditFormModal: false,
            onUpdate: false
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteSlider(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteSlider = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Sliders/${id}`).then(response => {
            this.props.clear(id);
            this.Sync();

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Sliders.toast_Delete.Title')}</div> <div className="h6">{t('Pages.Sliders.toast_Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/Sliders/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {
                this.Sync();

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Sliders.add_success_toast.Title')}</div> <div className="h6">{t('Pages.Sliders.add_success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.setState({
                    showEditFormModal: false
                });

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Sliders.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Sliders.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, sliders } = this.props;

        let isLoaded = false;
        if (sliders) {
            isLoaded = sliders.isLoaded;
        }

        return (
            <>
                {
                    this.state.showEditFormModal ?
                        <Modal
                            size="xl"
                            show={this.state.showEditFormModal}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-image-polaroid"></i> {t('Pages.Sliders.form.title')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <InfoForm
                                    onSubmit={this.onSubmit} ></InfoForm>
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                <section className="pos-rel padding-top padding-bottom">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("Pages.Sliders.title")} | {t('alarabeyya')}</title>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                        </Helmet>

                        <div className="row mb-30-none">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.Sliders.title')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Sliders.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.ShowEditForm()} title={t("Pages.Sliders.Add")} className="btn btn-primary ">
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.Sliders.Sync")} className="btn mx-1 btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderSliders()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        sliders: state.sliders
    };
};

export default connect(mapStateToProps, { fetch, remove, undo, clear })(withTranslation('common')(SlidersList));
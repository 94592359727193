import React from "react";

import Edit from "../Lessons/LessonEdit";

class QuizzesEdit extends React.Component {

    render() {
        return <>
            <Edit match={this.props.match}
                type="Quizzes"
                typeTreeView="Quizzes"></Edit>
        </>;
    }
}

export default QuizzesEdit;
import React from "react";
import { withTranslation } from "react-i18next";
import MaterialItem from "./MaterialItem";

class MaterialsList extends React.Component {

    render() {
        const { t, MaterialsObject, user, indices } = this.props;
        return <>
            <div className="widget widget-category">
                <div className="widget-header" >
                    <h3 className={`text-center pt-3 `}>
                        {t("Pages.Exams.ShowMaterialsList")}
                    </h3>
                </div>
                <div className="widget-body" style={{
                    background: '#fff5fd'
                }}>
                    <div className="row row-cols-1 row-cols-md-2 justify-content-center ">
                        {
                            MaterialsObject.map(item => {
                                return <div key={item.id} className="col px-1"><MaterialItem item={item} user={user} indices={indices} /></div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>;
    }
}
export default withTranslation('common')(MaterialsList);
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";

import configData from "../../Config/countries.json";

class SchoolItem extends React.Component {

    state = {
        moreInfo: false
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    render() {
        const { t, item } = this.props;
        if (item) {

            let Country = "";
            if (item.Country) {
                Country = this.state.countries.filter(p => p.value === item.Country)[0];
            }

            return (
                <>

                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            {
                                item.IsLocked ?
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.SchoolsControl.IsLocked')}>
                                        <i className="fas fa-lock"></i>
                                    </div> : ""
                            }
                            <div className="product-thumb">
                                <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                <div className="product-action-link">
                                    <div className="my-1" onClick={() => this.props.approved(item.Id)} title={t("Pages.SchoolsControl.action.approved")}><i className="far fa-check-square"></i></div>
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.SchoolsControl.action.info")}><i className="far fa-info"></i></div>
                                    <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.SchoolsControl.action.remove")}><i className="far fa-trash"></i></div>
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title"><Link to="#"> {item.Name} </Link></h5>
                                <p>

                                    {
                                        item.Email ?
                                            <>
                                                <a href={`mailto:${item.Email}`} title={t('School.Email.title')}>
                                                    <i className="icon fal fa-envelope-open-text"></i>
                                                    {item.Email}
                                                </a>
                                            </>
                                            : ""
                                    }
                                    {
                                        item.Phone ?
                                            <>
                                                {
                                                    item.Email ? " - " : ""
                                                }
                                                <a href={`Tel:${item.Phone}`} title={t('School.Phone.title')}>
                                                    <i className="icon fal fa-phone-alt"></i>
                                                    <span dir="ltr">
                                                        {item.Phone}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                    {
                                        item.Fax ?
                                            <>
                                                {
                                                    item.Phone ? " - " : ""
                                                }
                                                <a href={`Tel:${item.Fax}`} title={t('School.Fax.title')}>
                                                    <i className="icon fal fa-fax"></i>
                                                    <span dir="ltr">
                                                        {item.Fax}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            Country ?
                                                <span title={t('Pages.Register.Country.title')} >
                                                    {Country.label}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.Address ?
                                                <span title={t('Pages.Register.Address.title')} >
                                                    {
                                                        Country ? " - " : ""
                                                    }
                                                    {item.Address}
                                                </span>
                                                : ""
                                        }
                                        <br />
                                        <span className="mt-2">
                                            {
                                                <span title={t('School.Type.title')}>
                                                    <i className="icon fal fa-school"></i>
                                                    <span className="text-danger" title={t('Pages.Register.SchoolCode.title')}>
                                                        {
                                                            item.PosCode ?
                                                                <>
                                                                    <i className="icon fal fa-mail-bulk"></i>
                                                                    {item.PosCode}
                                                                </>
                                                                : ""
                                                        }
                                                    </span>
                                                </span>
                                            }
                                        </span>
                                    </p>
                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User?.Name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(SchoolItem);
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { fetchLesson, fetchGameScores, fetchMissionGameScores } from '../../../Admin/Lessons/actions/';
import Loading from "../../../Loading";
import effectData from "../../../Config/effect.json";
import soundData from "../../../Config/Sound.json";
import DashBoard from "../../../Admin/Games/Screen/DashBoard";
import history from '../../../../history';

class GameView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentGame: 0,
            isFirst: true,
            isMission: false,
            FontSize: 18,
            hideRate: false,
            showRate: false,
            showSound: false,
            showText: false,
            styleQuestion: '',
            styleBind: '',
            styleText: '',
            first: true,
            showResult: false,
            showEndExam: false,
            showEndQuizePage: false,
            showQuestionModal: false,
            showClock: false,
            showTimesUp: false,
            isTimeFinish: false,
            question: null,
            yourTime: 0,
            totalPlayGame: 0,
            Name: '',
            Bio: '',
            Sort: 0,
            Sound: '',
            Url: '',
            Icon: '',
            Background: '',
            Questions: [],
            IsActive: true,
            IsPublic: false,
            IndexId: 0
        };
        this._toastId = new React.createRef();
        this.Finish_Sound = soundData.Finish_Sound;
        this.examBackground = effectData.exam;
        this.times_up = effectData.times_up;
        this.duration = 0;
        this.yourTime = 0;
        this.audio = new Audio();
    }

    static getDerivedStateFromProps(props, state) {

        if (props.lessons && props.lessons.current && state.isFirst) {

            if (props.lessons && props.lessons.code && props.lessons.code === "no_permission") {
                history.push(`/NoPermissions/`);
            }

            let lesson = props.lessons.current;

            let questionSeparated = state.questionSeparated;
            let Clock = state.Clock;
            let ExamBackground = state.ExamBackground;
            if (lesson.CustomStyle) {

                if (lesson.CustomStyle.questionSeparated) {
                    questionSeparated = lesson.CustomStyle.questionSeparated;
                }
                if (lesson.CustomStyle.Clock) {
                    Clock = lesson.CustomStyle.Clock;
                }
                if (lesson.CustomStyle.ExamBackground) {
                    ExamBackground = lesson.CustomStyle.ExamBackground;
                }
            }

            let LessonPage = [];
            if (lesson.LessonPage) {
                let toRemove = props.lessons.PageToRemove;
                lesson.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                    if (!toRemove || !toRemove.includes(item.Id)) {
                        LessonPage = [...LessonPage, item];
                    }
                });
            }

            state = {
                isFirst: false,
                Sort: lesson.Sort,
                Id: lesson.Id,
                Name: lesson.Name,
                Bio: lesson.Bio,
                Background: lesson.Background,
                Sound: lesson.Sound,
                IsActive: lesson.IsActive,
                IsPublic: lesson.IsPublic,
                IndexId: lesson.Index ? lesson.Index.Id : state.IndexId,
                Icon: lesson.Icon,
                Url: lesson.Url,
                LessonPage: LessonPage,
                Questions: lesson.Questions ? lesson.Questions.sort((a, b) => a.Sort - b.Sort) : [],
                questionSeparated,
                Clock,
                ExamBackground,
                first: false
            };
            if (props.fetchGameScores) {
                if (props.isMission !== true) {
                    props.fetchGameScores(lesson.Id);
                } else {
                    props.fetchMissionGameScores(props.MissionId)
                }
            }
        }

        if (props.lessons && props.lessons.current && props.lessons.current.scores && props.lessons.current.scores.Levels) {
            state.currentGame = props.lessons.current.scores.Levels.filter(p => p.IsPassed === true).length;
            state.totalPlayGame = props.lessons.current.scores.Levels.length;
        }

        return state;
    }

    updateGameScore = () => {
        if (this.props.isMission !== true) {
            this.props.fetchGameScores(this.state.Id);
        } else {
            this.props.fetchMissionGameScores(this.props.MissionId);
        }
    }

    updateCurrentGame = () => {
        this.setState({
            currentGame: this.state.currentGame + 1
        });
    }

    componentDidMount() {
        if (this.props.isMission !== true) {
            const { id } = this.props.match.params;
            this.setState({
                isFirst: true,
                Id: id
            });
            if (id !== '0') {
                this.props.fetchLesson(id, true);
            }
        } else {
            this.setState({
                isFirst: true
            });
        }
    }

    render() {
        const { t, lessons } = this.props;

        if (lessons.length === 0) {
            return <Loading showGame={true} />
        }
        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: `html{overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;overscroll-behavior: contain;}section{padding-top:5px !important;padding-bottom:5px !important;}` }}></style>

                <Helmet>
                    <title>{this.state.Name} | {t('alarabeyya')}</title>

                    <meta property="og:title" content={this.state.Name + ' | ' + t('alarabeyya')} />
                    <meta property="twitter:title" content={this.state.Name + ' | ' + t('alarabeyya')} />

                    <meta name="description" content={this.state.Bio} />
                    <meta property="og:description" content={this.state.Bio} />
                    <meta property="twitter:description" content={this.state.Bio} />
                </Helmet>

                <DashBoard
                    MissionId={this.props.MissionId}
                    isMission={this.props.isMission}
                    lesson={lessons.current}
                    onEdit={false}
                    canPlayAnyGame={lessons.current.CustomStyleMission && lessons.current.CustomStyleMission.GamePlay === "open" ? true : false}
                    canSaveGame={lessons.current.CustomStyleMission && lessons.current.CustomStyleMission.GameSave === "hard" ? false : true}
                    GameType={lessons.current.CustomStyleMission ? lessons.current.CustomStyleMission.GameSave : ""}
                    updateGameScore={this.updateGameScore}
                    user={this.props.user ? this.props.user.user : ""}
                    currentGame={this.state.currentGame}
                    totalPlayGame={this.state.totalPlayGame}
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchLesson, fetchGameScores, fetchMissionGameScores })(withTranslation('common')(GameView));
import React from "react";
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import { fetchRanks as fetch } from '../actions/';
import configData from "../../Config/countries.json";
import Rank from "../../Admin/Question/View/Background/Rank";

class RankList extends React.Component {

    state = {
        showMostStudentsHavePoints: false,
        showMostSchoolsHavePoints: false,
        showMostUsersHavePoints: false,
        positionIndex: 0
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.map(item => {
            return {
                value: item.value,
                name: item.label,
                img: <LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />,
            };
        });
    }

    componentDidMount() {
        this.props.fetch();
    }

    nextPositionIndex = () => {
        if (this.state.positionIndex < this.props.ranks.ranks.Point.length - 1) {
            this.setState({
                positionIndex: this.state.positionIndex + 1
            });
        } else {
            this.setState({
                positionIndex: 0
            });
        }
    }

    prevPositionIndex = () => {
        if (this.state.positionIndex > 0) {
            this.setState({
                positionIndex: this.state.positionIndex - 1
            });
        } else {
            this.setState({
                positionIndex: this.props.ranks.ranks.Point.length - 1
            });
        }
    }

    renderStudent = () => {
        const { t, ranks, user } = this.props;
        const ListOfColor = ["warning", "primary ", "orange"];
        const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd", "#fff", "#fff", "#fff"];
        const rankItem = ranks.ranks.Point[this.state.positionIndex];
        return <>
            {
                rankItem.MostStudentsHavePoints.slice(0, 6).map((item, index) => {
                    let Country = "";
                    if (item.Country && item.Country !== 'il') {
                        Country = this.state.countries.filter(p => p.value === item.Country)[0];
                    }
                    return <div className="col px-0 mx-0 w-100" key={`MostStudentsHavePoints-${rankItem.Label}-${item.Id}`}>
                        <div className="product-list-item" style={{
                            marginBottom: "10px",
                            minHeight: '160px',
                            backgroundColor: ListOfBackgroundColor[index]
                        }}>
                            <div className="product-thumb" style={{
                                height: 'unset',
                                minHeight: 'unset',
                                width: '30%',
                                backgroundColor: ListOfBackgroundColor[index]
                            }}>
                                <LazyLoadImage src={item.Photo} alt={item.FirstName} className="rounded-circle" />
                            </div>
                            <div className="product-content py-1" style={{
                                width: '70%',
                                padding: "10px 10px 5px 5px",
                            }}>
                                <div className="d-flex justify-content-between ">
                                    <div className="d-flex flex-column justify-content-center">
                                        <h5 className="title " style={{
                                            fontSize: "18px"
                                        }}>
                                            {item.FirstName ? item.FirstName + " " : ""}
                                            {item.SecondName ? item.SecondName + " " : ""}
                                            {item.LastName ? item.LastName + " " : ""}
                                        </h5>
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.img}
                                                        </span>
                                                        : ""
                                                }
                                            </div>
                                            <div className="mr-1">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.name}
                                                        </span>
                                                        : ""
                                                }
                                                {
                                                    item.Address ?
                                                        <p title={t('Pages.Register.Address.title')} >
                                                            {item.Address}
                                                        </p>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <p className="my-0">
                                            <span className="mt-1">
                                                {
                                                    item.Name ?
                                                        <span title={t('Pages.Register.SchoolName.title')}>
                                                            <i className="icon fal fa-school"></i>
                                                            {item.Name}
                                                        </span>
                                                        : ""
                                                }
                                            </span>
                                        </p>
                                        <p className="my-0">
                                            <span className="mt-1">
                                                <span title={t('Pages.Register.SchoolName.title')}>
                                                    <i className="icon fal fa-school"></i>
                                                    {item.SchoolName}
                                                </span>
                                            </span>
                                        </p>
                                        <p className="my-0">
                                            <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                                <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                                {Math.round(item.Point * 100)}
                                            </span>
                                        </p>
                                    </div>
                                    {
                                        index < 6 ?
                                            <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                <LazyLoadImage src={`/assets/images/archive/sa${4 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 100)} `} />
                                            </div>
                                            :
                                            <div className="d-flex text-dark h2 align-items-center mr-1 justify-content-center" >
                                                <i className="icon text-danger fad fa-hashtag"></i> {index + 1}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
            {
                user && user.user && user.user.UserType && user.user.UserType.Id === 5
                    && !rankItem.MostStudentsHavePoints.filter(p => p.UserId === user.user.Id)[0]
                    && user.user.Point && user.user.Point.length > 0 ?
                    <>
                        <div className="col px-0 mx-0 w-100">
                            <div className="product-list-item" style={{
                                minHeight: '160px',
                                marginBottom: "10px",
                                backgroundColor: '#ccd9ef'
                            }}>
                                <div className="product-thumb" style={{
                                    height: 'unset',
                                    minHeight: 'unset',
                                    backgroundColor: '#ccd9ef'
                                }}>
                                    <LazyLoadImage src={user.user.Photo} alt={user.user.FirstName} className="rounded-circle" />
                                </div>
                                <div className="product-content py-1" style={{
                                    width: '70%',
                                    padding: "10px 10px 5px 5px",
                                }}>
                                    <div className="d-flex justify-content-between ">
                                        <div className="d-flex flex-column justify-content-center">
                                            <h5 className="title " style={{
                                                fontSize: "18px"
                                            }}>
                                                {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`}
                                            </h5>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                    {
                                                        user.user.Country ?
                                                            <span title={t('Pages.Register.Country.title')} >
                                                                {this.state.countries.filter(p => p.value === user.user.Country)[0].img}
                                                            </span>
                                                            : ""
                                                    }
                                                </div>
                                                <div className="mr-1">
                                                    {
                                                        user.user.Country ?
                                                            <span title={t('Pages.Register.Country.title')} >
                                                                {this.state.countries.filter(p => p.value === user.user.Country)[0].name}
                                                            </span>
                                                            : ""
                                                    }
                                                    {
                                                        user.user.Address ?
                                                            <p title={t('Pages.Register.Address.title')} >
                                                                {user.user.Address}
                                                            </p>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                            <p className="my-0">
                                                <span className="mt-1">
                                                    {
                                                        user.user.School ?
                                                            <span title={t('Pages.Register.SchoolName.title')}>
                                                                <i className="icon fal fa-school"></i>
                                                                {user.user.School.Name}
                                                            </span>
                                                            : ""
                                                    }
                                                </span>
                                            </p>
                                            <p className="my-0">
                                                <span className={`h4 text-dark}`} title={t('Pages.Home.Point')}>
                                                    <i className={`icon h5 fad fa-circle text-dark`}></i>
                                                    {Math.round(user.user.Point.filter(p => p.Year === rankItem.Year && p.Month === rankItem.Month)[0].Point * 100)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : ""
            }
        </>
    }

    renderSchool = () => {
        const { t, ranks, user } = this.props;
        const ListOfColor = ["warning", "primary ", "orange"];
        const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd", "#fff", "#fff", "#fff"];

        const rankItem = ranks.ranks.Point[this.state.positionIndex];
        return <>
            {
                rankItem.MostSchoolsHavePoints.slice(0, 6).map((item, index) => {
                    let Country = "";
                    if (item.Country && item.Country !== 'il') {
                        Country = this.state.countries.filter(p => p.value === item.Country)[0];
                    }
                    return <div className="col px-0 mx-0 w-100" key={`MostSchoolsHavePoints-${rankItem.Label}-${item.Id}`}>
                        <div className="product-list-item" style={{
                            minHeight: '160px',
                            marginBottom: "10px",
                            backgroundColor: ListOfBackgroundColor[index]
                        }}>
                            <div className="product-content py-1 w-100" style={{
                                width: '70%',
                                padding: "10px 10px 5px 5px",
                            }}>
                                <div className="d-flex justify-content-between px-2">
                                    <div className="d-flex flex-column justify-content-center">
                                        <h5 className="title " style={{
                                            fontSize: "18px"
                                        }}>
                                            {item.Name}
                                        </h5>
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.img}
                                                        </span>
                                                        : ""
                                                }
                                            </div>
                                            <div className="mr-1">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.name}
                                                        </span>
                                                        : ""
                                                }
                                                {
                                                    item.Address ?
                                                        <p title={t('Pages.Register.Address.title')} >
                                                            {item.Address}
                                                        </p>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <p className="mt-2">
                                            <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                                <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                                {Math.round(item.Point * 1000)}
                                            </span>
                                        </p>
                                    </div>
                                    {
                                        index < 6 ?
                                            <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                <LazyLoadImage src={`/assets/images/archive/school/${1 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 1000)} `} />
                                            </div>
                                            :
                                            <div className="d-flex text-dark h2 align-items-center mr-1 justify-content-center" >
                                                <i className="icon text-danger fad fa-hashtag"></i> {index + 1}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
            {
                user && user.user && user.user.UserType && (user.user.UserType.Id === 3 || user.user.UserType.Id === 4)
                    && !rankItem.MostSchoolsHavePoints.filter(p => p.SchoolId === user.user.School.Id)[0]
                    && user.user.Point && user.user.Point.length > 0 ?
                    <>
                        <div className="col px-0 mx-0 w-100" key={`MostSchoolsHavePoints-${rankItem.Label}-${user.user.School.Id}`}>
                            <div className="product-list-item" style={{
                                minHeight: '160px',
                                marginBottom: "10px",
                                backgroundColor: '#ccd9ef'
                            }}>
                                <div className="product-content py-1 w-100" style={{
                                    width: '70%',
                                    padding: "10px 10px 5px 5px",
                                }}>
                                    <div className="d-flex justify-content-between px-2">
                                        <div className="d-flex flex-column justify-content-center">
                                            <h5 className="title " style={{
                                                fontSize: "18px"
                                            }}>
                                                {user.user.School.Name}
                                            </h5>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                    {
                                                        user.user.School.Country ?
                                                            <span title={t('Pages.Register.Country.title')} >
                                                                {this.state.countries.filter(p => p.value === user.user.School.Country)[0].img}
                                                            </span>
                                                            : ""
                                                    }
                                                </div>
                                                <div className="mr-1">
                                                    {
                                                        user.user.School.Country ?
                                                            <span title={t('Pages.Register.Country.title')} >
                                                                {this.state.countries.filter(p => p.value === user.user.School.Country)[0].name}
                                                            </span>
                                                            : ""
                                                    }
                                                    {
                                                        user.user.School.Address ?
                                                            <p title={t('Pages.Register.Address.title')} >
                                                                {user.user.School.Address}
                                                            </p>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                            <p className="mt-2">
                                                <span className={`h4 text-dark`} title={t('Pages.Home.Point')}>
                                                    <i className={`icon h5 fad fa-circle text-dark`}></i>
                                                    {Math.round(user.user.Point.filter(p => p.Year === rankItem.Year && p.Month === rankItem.Month)[0].Point * 1000)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ""
            }
        </>
    }

    renderUser = () => {
        const { t, ranks, user } = this.props;
        const ListOfColor = ["warning", "primary ", "orange"];
        const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd", "#fff", "#fff", "#fff"];
        const rankItem = ranks.ranks.Point[this.state.positionIndex];
        return <>
            {
                rankItem.MostUsersHavePoints.slice(0, 6).map((item, index) => {
                    let Country = "";
                    if (item.Country && item.Country !== 'il') {
                        Country = this.state.countries.filter(p => p.value === item.Country)[0];
                    }
                    return <div className="col px-0 mx-0 w-100" key={`MostUsersHavePoints-${rankItem.Label}-${item.Id}`}>
                        <div className="product-list-item" style={{
                            minHeight: '160px',
                            marginBottom: "10px",
                            backgroundColor: ListOfBackgroundColor[index]
                        }}>
                            <div className="product-thumb" style={{
                                height: 'unset',
                                minHeight: 'unset',
                                width: '30%',
                                backgroundColor: ListOfBackgroundColor[index]
                            }}>
                                <LazyLoadImage src={item.Photo} alt={item.FirstName} className="rounded-circle" />
                            </div>
                            <div className="product-content py-1" style={{
                                width: '70%',
                                padding: "10px 10px 5px 5px",
                            }}>
                                <div className="d-flex justify-content-between ">
                                    <div className="d-flex flex-column justify-content-center">
                                        <h5 className="title " style={{
                                            fontSize: "18px"
                                        }}>
                                            {item.FirstName ? item.FirstName + " " : ""}
                                            {item.SecondName ? item.SecondName + " " : ""}
                                            {item.LastName ? item.LastName + " " : ""}
                                        </h5>
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.img}
                                                        </span>
                                                        : ""
                                                }
                                            </div>
                                            <div className="mr-1">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.name}
                                                        </span>
                                                        : ""
                                                }
                                                {
                                                    item.Address ?
                                                        <p title={t('Pages.Register.Address.title')} >
                                                            {item.Address}
                                                        </p>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <p className="my-2">
                                            <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                                <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                                {Math.round(item.Point * 100)}
                                            </span>
                                        </p>
                                    </div>
                                    {
                                        index < 6 ?
                                            <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                <LazyLoadImage src={`/assets/images/archive/sa${4 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 100)} `} />
                                            </div>
                                            :
                                            <div className="d-flex text-dark h2 align-items-center mr-1 justify-content-center" >
                                                <i className="icon text-danger fad fa-hashtag"></i> {index + 1}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
            {
                user && user.user && user.user.UserType && (user.user.UserType.Id === 2 || user.user.UserType.Id === 6)
                    && !rankItem.MostUsersHavePoints.filter(p => p.UserId === user.user.Id)[0]
                    && user.user.Point && user.user.Point.length > 0 ?
                    <>
                        <div className="col px-0 mx-0 w-100">
                            <div className="product-list-item" style={{
                                minHeight: '160px',
                                backgroundColor: '#ccd9ef'
                            }}>
                                <div className="product-thumb" style={{
                                    height: 'unset',
                                    minHeight: 'unset',
                                    backgroundColor: '#ccd9ef'
                                }}>
                                    <LazyLoadImage src={user.user.Photo} alt={user.user.FirstName} className="rounded-circle" />
                                </div>
                                <div className="product-content py-1" style={{
                                    width: '70%',
                                    padding: "10px 10px 5px 5px",
                                }}>
                                    <div className="d-flex justify-content-between ">
                                        <div className="d-flex flex-column justify-content-center">
                                            <h5 className="title " style={{
                                                fontSize: "18px"
                                            }}>
                                                {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`}
                                            </h5>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                    {
                                                        user.user.Country ?
                                                            <span title={t('Pages.Register.Country.title')} >
                                                                {this.state.countries.filter(p => p.value === user.user.Country)[0].img}
                                                            </span>
                                                            : ""
                                                    }
                                                </div>
                                                <div className="mr-1">
                                                    {
                                                        user.user.Country ?
                                                            <span title={t('Pages.Register.Country.title')} >
                                                                {this.state.countries.filter(p => p.value === user.user.Country)[0].name}
                                                            </span>
                                                            : ""
                                                    }
                                                    {
                                                        user.user.Address ?
                                                            <p title={t('Pages.Register.Address.title')} >
                                                                {user.user.Address}
                                                            </p>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                            <p className="my-0">
                                                <span className={`h4 text-dark}`} title={t('Pages.Home.Point')}>
                                                    <i className={`icon h5 fad fa-circle text-dark`}></i>
                                                    {Math.round(user.user.Point.filter(p => p.Year === rankItem.Year && p.Month === rankItem.Month)[0].Point * 100)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ""
            }
        </>
    }

    showMostUsersHavePoints = () => {
        this.setState({
            showMostUsersHavePoints: true
        });
    }

    showMostSchoolsHavePoints = () => {
        this.setState({
            showMostSchoolsHavePoints: true
        });
    }

    showMostStudentsHavePoints = () => {
        this.setState({
            showMostStudentsHavePoints: true
        });
    }

    handleClose = () => {
        this.setState({
            showMostStudentsHavePoints: false,
            showMostSchoolsHavePoints: false,
            showMostUsersHavePoints: false
        });
    }

    render() {
        const { t, ranks } = this.props;
        const ListOfColor = ["warning", "primary ", "orange"];
        const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd", "#fff", "#fff", "#fff"];

        return (<>

            {
                this.state.showMostStudentsHavePoints ?
                    <Modal
                        size="lg"
                        show={true}
                        onHide={this.handleClose}
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="fa-sharp fa-solid fa-trophy-star"></i>     {t("Pages.Home.MostStudentsHavePoints")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <div className="widget">
                                <div className="widget-body" >
                                    {this.renderStudent()}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12">
                                <button className="btn btn-danger" onClick={this.handleClose}>
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ""
            }
            {
                this.state.showMostSchoolsHavePoints ?
                    <Modal
                        size="lg"
                        show={true}
                        onHide={this.handleClose}
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="fa-sharp fa-solid fa-crown"></i> {t("Pages.Home.MostSchoolsHavePoints")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <div className="widget">
                                <div className="widget-body" >
                                    {this.renderSchool()}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12">
                                <button className="btn btn-danger" onClick={this.handleClose}>
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ""
            }
            {
                this.state.showMostUsersHavePoints ?
                    <Modal
                        size="lg"
                        show={true}
                        onHide={this.handleClose}
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="fa-sharp fa-solid fa-medal"></i> {t("Pages.Home.MostUsersHavePoints")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <div className="widget">
                                <div className="widget-body" >
                                    {this.renderUser()}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12">
                                <button className="btn btn-danger" onClick={this.handleClose}>
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ""
            }

            <section className="pos-rel call-in-action call-overlay bg_img padding-bottom">
                <Rank absolute={true} />
                <div className="container padding-bottom" >
                    <div className="row">
                        <div className="col-12 pos-rel oh">
                            {
                                ranks && ranks.isLoaded ?
                                    <div className="post-slider w-100">
                                        {
                                            ranks && ranks.ranks && ranks.ranks.Point && ranks.ranks.Point.length > 0 ?
                                                ranks.ranks.Point.map((rankItem, pIndex) => {
                                                    return <div className={`post-thumb ${this.state.positionIndex === pIndex ? '' : 'd-none'}`} key={pIndex}>
                                                        <div className={`row padding-bottom ${rankItem.MostSchoolsHavePoints && rankItem.MostSchoolsHavePoints.length > 0 ? "" : "justify-content-around"}`} >
                                                            <div className="col-12">
                                                                <div className="row justify-content-center mb-3 ">
                                                                    <div className="col-lg-8">
                                                                        <div className="section-header rank-header- mb-0 bg-white p-5 " style={{
                                                                            borderRadius: '10px'
                                                                        }} >
                                                                            <h3 className="title">
                                                                                {t('Pages.Home.PositionMonthly')} :
                                                                                <span style={{
                                                                                    color: '#92278f'
                                                                                }}>
                                                                                    {
                                                                                        rankItem.Label
                                                                                    }
                                                                                </span>
                                                                            </h3>
                                                                        </div>
                                                                        {
                                                                            ranks.ranks.Point.length > 1 ?
                                                                                <>
                                                                                    <span className="post-prev" title={t("Next")} style={{
                                                                                        border: '1px solid #0e2c53'
                                                                                    }} onClick={() => this.prevPositionIndex()}><i className="fas fa-angle-left"></i></span>
                                                                                    <span className="post-next" title={t("Previous")} style={{
                                                                                        border: '1px solid #0e2c53'
                                                                                    }} onClick={() => this.nextPositionIndex()}><i className="fas fa-angle-right"></i></span>
                                                                                </>
                                                                                : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                rankItem.MostStudentsHavePoints && rankItem.MostStudentsHavePoints.length > 0 ?
                                                                    <div className="col-lg-4">
                                                                        <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                                                            <div className="col-12 mb-3 d-flex align-items-center justify-content-center flex-column">
                                                                                <LazyLoadImage src="/assets/images/archive/i2.png?v=1" />
                                                                                <div className="section-header mb-0">
                                                                                    <h3 className="title champion-title pt-3">
                                                                                        {t("Pages.Home.MostStudentsHavePoints")}
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            rankItem.MostStudentsHavePoints.slice(0, 3).map((item, index) => {
                                                                                let Country = "";
                                                                                if (item.Country && item.Country !== 'il') {
                                                                                    Country = this.state.countries.filter(p => p.value === item.Country)[0];
                                                                                }
                                                                                return <div className="col px-0 mx-0 w-100" key={`MostStudentsHavePoints-${rankItem.Label}-${item.Id}`}>
                                                                                    <div className="product-list-item" style={{
                                                                                        marginBottom: "10px",
                                                                                        minHeight: '160px',
                                                                                        backgroundColor: ListOfBackgroundColor[index]
                                                                                    }}>
                                                                                        <div className="product-thumb" style={{
                                                                                            height: 'unset',
                                                                                            minHeight: 'unset',
                                                                                            width: '30%',
                                                                                            backgroundColor: ListOfBackgroundColor[index]
                                                                                        }}>
                                                                                            <LazyLoadImage src={item.Photo} alt={item.FirstName} className="rounded-circle" />
                                                                                        </div>
                                                                                        <div className="product-content py-1" style={{
                                                                                            width: '70%',
                                                                                            padding: "10px 10px 5px 5px",
                                                                                        }}>
                                                                                            <div className="d-flex justify-content-between ">
                                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                                    <h5 className="title " style={{
                                                                                                        fontSize: "18px"
                                                                                                    }}>
                                                                                                        {item.FirstName ? item.FirstName + " " : ""}
                                                                                                        {item.SecondName ? item.SecondName + " " : ""}
                                                                                                        {item.LastName ? item.LastName + " " : ""}
                                                                                                    </h5>
                                                                                                    <div className="d-flex">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            {
                                                                                                                Country ?
                                                                                                                    <span title={t('Pages.Register.Country.title')} >
                                                                                                                        {Country.img}
                                                                                                                    </span>
                                                                                                                    : ""
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="mr-1">
                                                                                                            {
                                                                                                                Country ?
                                                                                                                    <span title={t('Pages.Register.Country.title')} >
                                                                                                                        {Country.name}
                                                                                                                    </span>
                                                                                                                    : ""
                                                                                                            }
                                                                                                            {
                                                                                                                item.Address ?
                                                                                                                    <p title={t('Pages.Register.Address.title')} >
                                                                                                                        {item.Address}
                                                                                                                    </p>
                                                                                                                    : ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="my-0">
                                                                                                        <span className="mt-1">
                                                                                                            {
                                                                                                                item.Name ?
                                                                                                                    <span title={t('Pages.Register.SchoolName.title')}>
                                                                                                                        <i className="icon fal fa-school"></i>
                                                                                                                        {item.Name}
                                                                                                                    </span>
                                                                                                                    : ""
                                                                                                            }
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p className="my-0">
                                                                                                        <span className="mt-1">
                                                                                                            <span title={t('Pages.Register.SchoolName.title')}>
                                                                                                                <i className="icon fal fa-school"></i>
                                                                                                                {item.SchoolName}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p className="my-0">
                                                                                                        <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                                                                                            <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                                                                                            {Math.round(item.Point * 100)}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                                                                    <LazyLoadImage src={`/assets/images/archive/sa${4 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 100)} `} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        {
                                                                            rankItem.MostStudentsHavePoints.length > 3 ?
                                                                                <div className="col px-0 mx-0 w-100">
                                                                                    <div className="product-list-item mb-0 p-0">
                                                                                        <div className="d-flex justify-content-center w-100">
                                                                                            <div onClick={() => this.showMostStudentsHavePoints()} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more-first">
                                                                                                <span className="txt pl-2">
                                                                                                    {t('showAll')}
                                                                                                    <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : ""
                                                                        }
                                                                    </div> : ""
                                                            }
                                                            {
                                                                rankItem.MostSchoolsHavePoints && rankItem.MostSchoolsHavePoints.length > 0 ?
                                                                    <div className="col-lg-4">
                                                                        <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                                                            <div className="col-12 mb-3 d-flex align-items-center justify-content-center flex-column">
                                                                                <LazyLoadImage src="/assets/images/archive/i1.png?v=1" />
                                                                                <div className="section-header mb-0">
                                                                                    <h3 className="title champion-title pt-3">
                                                                                        {t("Pages.Home.MostSchoolsHavePoints")}

                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            rankItem.MostSchoolsHavePoints.slice(0, 3).map((item, index) => {
                                                                                let Country = "";
                                                                                if (item.Country && item.Country !== 'il') {
                                                                                    Country = this.state.countries.filter(p => p.value === item.Country)[0];
                                                                                }
                                                                                return <div className="col px-0 mx-0 w-100" key={`MostSchoolsHavePoints-${rankItem.Label}-${item.Id}`}>
                                                                                    <div className="product-list-item" style={{
                                                                                        minHeight: '160px',
                                                                                        marginBottom: "10px",
                                                                                        backgroundColor: ListOfBackgroundColor[index]
                                                                                    }}>
                                                                                        <div className="product-content py-1 w-100" style={{
                                                                                            width: '70%',
                                                                                            padding: "10px 10px 5px 5px",
                                                                                        }}>
                                                                                            <div className="d-flex justify-content-between px-2">
                                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                                    <h5 className="title " style={{
                                                                                                        fontSize: "18px"
                                                                                                    }}>
                                                                                                        {item.Name}
                                                                                                    </h5>
                                                                                                    <div className="d-flex">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            {
                                                                                                                Country ?
                                                                                                                    <span title={t('Pages.Register.Country.title')} >
                                                                                                                        {Country.img}
                                                                                                                    </span>
                                                                                                                    : ""
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="mr-1">
                                                                                                            {
                                                                                                                Country ?
                                                                                                                    <span title={t('Pages.Register.Country.title')} >
                                                                                                                        {Country.name}
                                                                                                                    </span>
                                                                                                                    : ""
                                                                                                            }
                                                                                                            {
                                                                                                                item.Address ?
                                                                                                                    <p title={t('Pages.Register.Address.title')} >
                                                                                                                        {item.Address}
                                                                                                                    </p>
                                                                                                                    : ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="mt-2">
                                                                                                        <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                                                                                            <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                                                                                            {Math.round(item.Point * 1000)}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                                                                    <LazyLoadImage src={`/assets/images/archive/school/${1 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 1000)} `} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        {
                                                                            rankItem.MostSchoolsHavePoints.length > 3 ?
                                                                                <div className="col px-0 mx-0 w-100">
                                                                                    <div className="product-list-item mb-0 p-0">
                                                                                        <div className="d-flex justify-content-center w-100">
                                                                                            <div onClick={() => this.showMostSchoolsHavePoints()} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more-second" >
                                                                                                <span className="txt pl-2">
                                                                                                    {t('showAll')}
                                                                                                    <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : ""
                                                                        }
                                                                    </div> : ""
                                                            }
                                                            <div className={`col-lg-${rankItem.MostStudentsHavePoints && rankItem.MostStudentsHavePoints.length > 0 ? "4" : "12"}`}>
                                                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                                                    <div className="col-12 mb-3 d-flex align-items-center justify-content-center flex-column">
                                                                        <LazyLoadImage src="/assets/images/archive/i3.png?v=1" />
                                                                        <div className="section-header mb-0">
                                                                            <h3 className="title champion-title pt-3">
                                                                                {t("Pages.Home.MostUsersHavePoints")}

                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    rankItem.MostUsersHavePoints && rankItem.MostUsersHavePoints.length > 0 ?
                                                                        <div className={`${rankItem.MostStudentsHavePoints && rankItem.MostStudentsHavePoints.length > 0 ? "" : "row justify-content-center"}`}>
                                                                            {
                                                                                rankItem.MostUsersHavePoints.slice(0, 3).map((item, index) => {
                                                                                    let Country = "";
                                                                                    if (item.Country && item.Country !== 'il') {
                                                                                        Country = this.state.countries.filter(p => p.value === item.Country)[0];
                                                                                    }
                                                                                    return <div className={`${rankItem.MostStudentsHavePoints && rankItem.MostStudentsHavePoints.length > 0 ? "col px-0 mx-0 w-100" : "col-lg-4 col-md-12"}`} key={`MostUsersHavePoints-${rankItem.Label}-${item.Id}`}>
                                                                                        <div className="product-list-item" style={{
                                                                                            minHeight: '160px',
                                                                                            marginBottom: "10px",
                                                                                            backgroundColor: ListOfBackgroundColor[index]
                                                                                        }}>
                                                                                            <div className="product-thumb" style={{
                                                                                                height: 'unset',
                                                                                                minHeight: 'unset',
                                                                                                width: '30%',
                                                                                                backgroundColor: ListOfBackgroundColor[index]
                                                                                            }}>
                                                                                                <LazyLoadImage src={item.Photo} alt={item.FirstName} className="rounded-circle" />
                                                                                            </div>
                                                                                            <div className="product-content py-1" style={{
                                                                                                width: '70%',
                                                                                                padding: "10px 10px 5px 5px",
                                                                                            }}>
                                                                                                <div className="d-flex justify-content-between ">
                                                                                                    <div className="d-flex flex-column justify-content-center">
                                                                                                        <h5 className="title " style={{
                                                                                                            fontSize: "18px"
                                                                                                        }}>
                                                                                                            {item.FirstName ? item.FirstName + " " : ""}
                                                                                                            {item.SecondName ? item.SecondName + " " : ""}
                                                                                                            {item.LastName ? item.LastName + " " : ""}
                                                                                                        </h5>
                                                                                                        <div className="d-flex">
                                                                                                            <div className="d-flex align-items-center">
                                                                                                                {
                                                                                                                    Country ?
                                                                                                                        <span title={t('Pages.Register.Country.title')} >
                                                                                                                            {Country.img}
                                                                                                                        </span>
                                                                                                                        : ""
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="mr-1">
                                                                                                                {
                                                                                                                    Country ?
                                                                                                                        <span title={t('Pages.Register.Country.title')} >
                                                                                                                            {Country.name}
                                                                                                                        </span>
                                                                                                                        : ""
                                                                                                                }
                                                                                                                {
                                                                                                                    item.Address ?
                                                                                                                        <p title={t('Pages.Register.Address.title')} >
                                                                                                                            {item.Address}
                                                                                                                        </p>
                                                                                                                        : ""
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p className="my-2">
                                                                                                            <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                                                                                                <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                                                                                                {Math.round(item.Point * 100)}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                                                                        <LazyLoadImage src={`/assets/images/archive/sa${4 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 100)} `} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {
                                                                                rankItem.MostUsersHavePoints.length > 3 ?
                                                                                    <div className={`${rankItem.MostStudentsHavePoints && rankItem.MostStudentsHavePoints.length > 0 ? "col px-0 mx-0 w-100" : "col-lg-4 col-md-12"}`}>
                                                                                        <div className="product-list-item mb-0 p-0">
                                                                                            <div className="d-flex justify-content-center w-100">
                                                                                                <div onClick={() => this.showMostUsersHavePoints()} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more-third">
                                                                                                    <span className="txt pl-2">
                                                                                                        {t('showAll')}
                                                                                                        <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ""
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="col px-0 mx-0 w-100">
                                                                            <div className="product-list-item d-flex justify-content-center" style={{
                                                                                minHeight: '160px'
                                                                            }}>
                                                                                <div className="product-content py-1">
                                                                                    <div className="text-center ">
                                                                                        <div className="d-flex flex-column justify-content-center">
                                                                                            <h5 className="title " style={{
                                                                                                fontSize: "18px"
                                                                                            }}>
                                                                                                {t("NoInfo")}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                                :
                                                <>
                                                    <div className="product-content rounded mt-5 mb-3 w-100 text-center bg-white p-5 border border-warning">
                                                        <div className="display-1 text-warning">
                                                            <i className="fad fa-trophy-alt"></i>
                                                        </div>
                                                        <h4 className="display-5 subtitle">
                                                            {t("NoInfo")}
                                                        </h4>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    :
                                    <div className="product-content w-100 mt-5 rounded  mb-3 text-center bg-white p-5">
                                        <div className="display-2 text-danger">
                                            <i className="fad fa-sync-alt fa-spin"></i>
                                        </div>
                                        <h4 className="display-4 subtitle">
                                            {t("Loading")}
                                        </h4>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ranks: state.ranks
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(RankList));
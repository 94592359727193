import React from "react";
import GameObjects from "../../GameObjects";
import '../../../../../scss/bird.scss';

class Registration extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {
        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            height: '100vh',
            backgroundColor: '#75cdff',
            top: '0'
        }}>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud2" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(55% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(20% - 169px)',
                top: '70%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '20%',
                top: '40%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(70% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '2%',
                top: '80%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud2" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(50% - 169px)',
                top: '80%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>

            <div className="bird-container bird-container--two">
                <div className="bird bird--two"></div>
            </div>
            <div className="bird-container bird-container--four">
                <div className="bird bird--four"></div>
            </div>
            <div className="bird-container bird-container--five">
                <div className="bird bird--five"></div>
            </div>
            <div className="ballon">
                <div className="stripes"></div>
                <div className="rope-top"></div>
                <div className="rope-middle">
                    <div className="knotT"></div>
                    <div className="knotR"></div>
                    <div className="knotL"></div>
                </div>
                <div className="rope-bottom-front"></div>
                <div className="rope-bottom-back"></div>
                <div className="basket"></div>
            </div>
            <div className="bird-container bird-container--three">
                <div className="bird bird--three"></div>
            </div>

            <div className="bird-container bird-container--one">
                <div className="bird bird--one"></div>
            </div>

            <div className="position-absolute user-select-none d-none d-md-block" style={{
                left: '2%',
                bottom: 'calc(50% - 150px)'
            }}><GameObjects objectCss="BoyOnBook" /></div>

            <div className="position-absolute user-select-none d-none d-md-block" style={{
                right: '2%',
                bottom: 'calc(50% - 150px)'
            }}><GameObjects objectCss="GirlOnBook" /></div>
        </div>
    }
}
export default Registration;
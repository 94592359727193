import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Footer from "../../Footer/FooterBottom";
import LessonItem from "./LessonItem";
import TreeView from "../../Admin/Indices/TreeView";
import { fetchIndices } from '../../Admin/Indices/actions/';

import {
    fetchLessons as fetch,
    setLessonFavorite as setFavorite
} from '../../Admin/Lessons/actions';
import history from '../../../history';
import { fetchClassRooms } from '../../School/ClassRooms/actions';
import { fetchStudentInClassRooms } from '../../School/StudentInClassRooms/actions/';
import Beach from "../../Admin/Question/View/Background/Beach";

class Games extends React.Component {

    state = { currentItem: '', indices: [], search: '' };

    componentDidMount() {
        this.props.fetchIndices();


        const { indexId } = this.props.match.params;
        if (indexId !== '0') {
            this.props.fetch(indexId);
        }
        window.scrollToPosition("main-game-section");
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user &&
            props.user.user &&
            props.user.user.UserType &&
            (props.user.user.UserType.Id === 3 || props.user.user.UserType.Id === 4) &&
            props.classRooms && props.classRooms.length === 0) {
            props.fetchClassRooms();
        }
        return state;
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        this.props.fetch(item.id);

        history.push(`/Games/${item.id}`);
    }

    Sync = () => {
        if (this.state.currentItem) {
            this.props.fetch(this.state.currentItem.id);
        }
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id && node.Level >= 2) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    fetchStudentInClassRooms = ClassRoomId => {
        this.props.fetchStudentInClassRooms(ClassRoomId);
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    renderLessons() {
        const { t, user, classRooms } = this.props;
        let lessons = this.props.lessons;
        let isLoaded = false;

        if (lessons) {
            isLoaded = lessons.isLoaded;
        }

        let toRemove = [];
        if (lessons) {
            toRemove = lessons.toRemove;
        }

        let indices = this.props.indices;

        if (lessons && lessons.lessons && lessons.lessons.length > 0 && this.state.currentItem) {
            let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterLesson = lessons.lessons.filter(v => v.SearchName.includes(searchText) && v.Index.Id === this.state.currentItem.id);

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Games.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterLesson.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Games.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Games.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            if (filterLesson.length === 0) {
                if (indices && indices.nodes && this.state.currentItem) {
                    var nodes = this.GetNode(indices.nodes, this.state.currentItem.id);
                    if (nodes && nodes.length > 0) {
                        return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                            {
                                nodes.map(item => {
                                    return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                        <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                            <div className="shop-item">
                                                <div className="product-thumb">
                                                    {
                                                        item.icon ?
                                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                            :
                                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                                    }
                                                </div>
                                                <div className="product-content">
                                                    <h5 className="title">{item.label}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    }
                }
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Games.NotFound")}
                            </h4>
                        </div>
                    </div>
                );
            }

            return <>
                {this.renderSubLessons(true, filterLesson, toRemove, classRooms, user, indices)}
                {this.renderSubLessons(false, filterLesson, toRemove, classRooms, user, indices)}
            </>;
        }
        if (!this.state.currentItem) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-glasses "></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Games.SelectIndex")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (lessons && lessons.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Games.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        if (indices && indices.nodes) {

            let start = this.state.trackId === 1 ? 7 : 12;
            let IndexId = (this.state.currentItem && this.state.currentItem.id) ? parseInt(this.state.currentItem.id) : start;

            var indexNodes = this.GetNode(indices.nodes, IndexId);
            if (indexNodes && indexNodes.length > 0) {
                return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                    {
                        indexNodes.map(item => {
                            return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                    <div className="shop-item">
                                        <div className="product-thumb">
                                            {
                                                item.icon ?
                                                    <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                    :
                                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                            }
                                        </div>
                                        <div className="product-content">
                                            <h5 className="title">{item.label}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })
                    }
                </div>
            }
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Games.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    renderSubLessons(IsPublic, filterLesson, toRemove, classRooms, user, indices) {
        return filterLesson.filter(p => p.IsPublic === IsPublic).sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(lesson =>
            (toRemove && toRemove.includes(lesson.Id)) ? "" :
                <LessonItem
                    setFavorite={this.setFavorite}
                    classRooms={classRooms}
                    getStudent={this.fetchStudentInClassRooms}
                    user={user}
                    type={"Games"}
                    key={lesson.Id}
                    IsAdmin={false}
                    setIndex={this.setCurrentItem}
                    lesson={lesson}
                    indices={indices.nodes}></LessonItem>
        );
    }

    renderWave = () => {

        const { innerWidth: width, innerHeight: height } = window;

        return <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px"
            width={`${height}px`}
            height={`${width}px`}
            viewBox={`0 0 500 1`}
            xmlSpace="preserve">
            <style dangerouslySetInnerHTML={{
                __html:
                    `
                        @keyframes lightwaves {
                            0%,100% { transform: translate(0,0); }
                            25%     { transform: translate(5px,5px); transform: scale(1.05); }
                            50%     { transform: translate(25px, 5px); }
                            75%     { transform: translate(12px,10px); transform: scale(1.05); }
                        }
                        @keyframes darkwaves {
                            0%,100% { transform: translate(0,0); }
                            25%     { transform: translate(25px, 5px); transform: scale(1.05); }
                            50%     { transform: translate(12px,10px); }
                            75%     { transform: translate(5px,5px); }
                        }
                    `
            }}></style>

            <g id="DarkWaves" className="DarkWaves" style={{
                animation: 'darkwaves 2.8s infinite'
            }}>
                <g>
                    <path style={{ fill: '#6cecff' }} d="M761.8,202.4c-24.7,0-36.7,7.2-49.5,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.6-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.7-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.7-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7C78.7,224.9,65,232.9,38,232.9c-27,0-40.4-8.1-53.6-15.9
               c-12.1-7.1-24.3-13.9-45.4-14.6V602h922V232.9c-22.6-0.8-37.2-8.4-49.7-15.8C798.5,209.5,786.5,202.4,761.8,202.4z"/>
                </g>
            </g>
            <g id="LightWaves" className="LightWaves position-relative" style={{
                animation: 'lightwaves 2s infinite'
            }}>
                <g>
                    <path style={{ fill: '#24bef4' }} d="M750.9,229.8c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               s-46.9-8.4-62.1-16.6c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               c-31.2,0-46.9-8.4-62.1-16.6c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               c-31.2,0-46.9-8.4-62.1-16.6c-14.8-7.9-28.9-15.4-57.3-15.4c-16.9,0-28.8,2.6-38.8,6.4V600h922V237c-12,5.3-26,9.4-47.8,9.4
               C782.1,246.4,766.1,237.9,750.9,229.8z"/>
                </g>
            </g>
        </svg >
    }

    render() {
        const { t, startFrom } = this.props;

        let indices = this.props.indices;

        let IndexId = (this.state.currentItem) ? parseInt(this.state.currentItem.id) : 0;

        let start = 3;
        if (startFrom) {
            start = startFrom;
        }

        return (
            <>

                <Helmet>
                    <title>{t('PageHeader.FunPath')} {this.props.title ? ` - ${this.props.title}` : ""} | {t('alarabeyya')}</title>

                    <meta property="og:title" content={t("PageHeader.FunPath") + " - " + this.props.title + ' | ' + t('alarabeyya')} />
                    <meta property="twitter:title" content={t("PageHeader.FunPath") + " - " + this.props.title + ' | ' + t('alarabeyya')} />

                    <meta name="description" content={t('PageHeader.FunPath_desc')} />
                    <meta property="og:description" content={t('PageHeader.FunPath_desc')} />
                    <meta property="twitter:description" content={t('PageHeader.FunPath_desc')} />
                </Helmet>

                <section className="position-relative  oh" id="main-game-section" style={{
                    backgroundColor: '#f6d997',
                    minHeight: '100vh'
                }}>

                    <Beach />

                    <div className="container padding-top">

                        <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                            <div className="section-header mb-4">
                                <div className="display-4 h3 custome-title" style={{
                                    color: '#74b21f'
                                }}><i className="mx-3 fal fa-gamepad-alt "></i> {t('PageHeader.FunPath')} </div>
                            </div>
                        </div>

                        <div className="row mb-30-none">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        IsAdmin={false}
                                        title={this.props.title}
                                        parentId={this.props.parentId}
                                        currentId={IndexId}
                                        data={indices.nodes}
                                        startFrom={start}
                                        type="Fun"
                                        toRemove={indices.toRemove}
                                        setCurrentItem={this.setCurrentItem}></TreeView>
                                    : ""
                                }
                            </div>

                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Games.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderLessons()}
                            </div>

                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchStudentInClassRooms, fetchClassRooms, fetchIndices, fetch, setFavorite })(withTranslation('common')(Games));
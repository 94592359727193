import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class UserSubMenu extends React.Component {

    render() {
        const { t, user } = this.props;
        if (user && user.user && user.user.UserType) {
            return (
                <>
                    <div className="teacher-wrapper p-4 mt-0">
                        <div className="teacher-single-top bg-white">
                            <div className="teacher-single-item d-flex w-75 mx-auto justify-content-center align-items-center m-0" style={{
                                // marginTop: '-50%'
                            }}>
                                <div className="teacher-single-thumb d-flex w-100 ">
                                    <LazyLoadImage src={`${user.user.Photo ? user.user.Photo : "/assets/images/teacher/teacher-single.jpg"}`} alt={`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} />
                                </div>
                            </div>
                            <h3 className="title pt-4 text-center">
                                {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`}
                            </h3>
                            <ul className="list-group list-group-flush mt-3">
                                <li className="list-group-item">
                                    <Link to="/Profile" title={t('Pages.Profile.title')}>
                                        <i className="fal fa-user"></i> <span> {t('Pages.Profile.title')} </span>
                                    </Link>
                                </li>
                                {
                                    user.user.UserType.Id !== 5 && user.user.UserType.Id !== 6 ?
                                        <li className="list-group-item">
                                            <Link to="/User/Control" title={t('Pages.UserControl.title')}>
                                                <i className="fad fa-game-board-alt"></i> <span> {t('Pages.UserControl.title')} </span>
                                            </Link>
                                        </li> : ""
                                }
                                {
                                    user.user.UserType.Id === 5 ?
                                        <li className="list-group-item">
                                            <Link to="/Student/DashBoard" title={t('Pages.UserControl.title')}>
                                                <i className="fad fa-game-board-alt"></i> <span> {t('Pages.UserControl.title')} </span>
                                            </Link>
                                        </li> : ""
                                }
                                <li className="list-group-item">
                                    <Link to="/Profile/Edit" title={t('edit')}>
                                        <i className="fal fa-edit"></i> <span> {t('edit')} </span>
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link to="/Profile/ChangePassword" title={t('ChangePassword')}>
                                        <i className="fal fa-key"></i> <span> {t('ChangePassword')} </span>
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link to="/Profile/Picture" title={t('ChangePicture')}>
                                        <i className="fal fa-image"></i> <span> {t('ChangePicture')} </span>
                                    </Link>
                                </li>

                                <li className="list-group-item">
                                    <Link to="/User/Mark" title={t('Pages.Profile.markList')}>
                                        <i className="fal fa-trophy-alt text-warning"></i> <span> {t('Pages.Profile.markList')} </span>
                                    </Link>
                                </li>
                                {
                                    user.user.UserType.Id !== 5 ?
                                        <>
                                            <li className="list-group-item">
                                                <Link to="/User/Favorite/Lesson" title={t('Pages.Profile.favoriteList')}>
                                                    <i className="fal fa-heart text-danger"></i> <span> {t('Pages.Profile.favoriteList')} </span>
                                                </Link>
                                            </li>

                                            <li className="list-group-item">
                                                <Link to="/User/Favorite/Files" title={t('Pages.Profile.favoriteFiles')}>
                                                    <i className="fal fa-heart-circle text-danger"></i> <span> {t('Pages.Profile.favoriteFiles')} </span>
                                                </Link>
                                            </li>
                                        </>
                                        : ""
                                }

                                <li className="list-group-item">
                                    <Link to="/User/History/Lessons" title={t('Pages.Profile.lessonsHistory')}>
                                        <i className="fal fa-history"></i> <span> {t('Pages.Profile.lessonsHistory')} </span>
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link to="/User/History/Files" title={t('Pages.Profile.filesHistory')}>
                                        <i className="fal fa-file-arrow-down"></i> <span> {t('Pages.Profile.filesHistory')} </span>
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(UserSubMenu);
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ScrollArea from "react-scrollbar";

import { userInitial } from '../../actions/';
import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import {
    fetchFiles as fetch,
    setFileFavorite as setFavorite,
    resetFiles
} from './actions';
import { fetchIndices } from '../../Admin/Indices/actions/';
import ListItem from "../../Admin/Files/ListItem";
import School from "../../Admin/Question/View/Background/School";

class Files extends React.Component {

    state = {
        Active: true,
        isFetching: true,
        isClicked: true,
        search: '',
        startDate: moment().add(-45, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        date: moment().add(-45, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
        page: 0
    };

    constructor(props) {
        super(props);
        window.scrollToTop();
        this.props.fetchIndices();
        this.fetchMoreContainer = React.createRef();
        this.scrollArea = React.createRef();
        setTimeout(() => {
            this.Reset();
        }, 50);
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    componentDidMount() {
        this.props.resetFiles();
        window.scrollToPosition("main-files-section");
    }

    scrollDown = () => {
        this.scrollArea.current.scrollArea.scrollBottom();
        this.Sync();
    }

    handleScroll = () => {
        var react = this.fetchMoreContainer;
        if (react && react.current) {
            let rect = react.current.getBoundingClientRect();
            if (rect) {
                if (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    (rect.bottom - rect.height) <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) && this.state.isFetching
                ) {
                    this.setState({
                        isFetching: false
                    });
                    setTimeout(() => {
                        this.Sync();
                    }, 50);
                }
            }
        }
    }
    Reset = () => {
        this.props.resetFiles();
        this.setState({
            page: 0
        });
        setTimeout(() => {
            this.Sync();
        }, 50);
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    Sync = () => {
        let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
        this.props.fetch(this.state.startDate, this.state.endDate, searchText, this.state.Active, this.state.page);
        this.setState({
            page: this.state.page + 1,
            isClicked: true,
            isFetching: true
        });
    }

    renderFiles() {
        const { t, indices } = this.props;
        let files = this.props.files;

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (files && files.files && files.files.length > 0) {
            let filterFile = files.files.filter(v => v.Name.includes(search));


            if (filterFile.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Files.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Files.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterFile.map((file, index) =>
                <ListItem
                    setFavorite={this.setFavorite}
                    key={`${index}-${file.Id}`}
                    user={this.props.user}
                    IsAdmin={false}
                    setIndex={this.setCurrentItem}
                    item={file}
                    indices={indices.nodes}
                ></ListItem>
            );
        }

        if (files && files.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Files.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }
        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Files.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    setActive = Active => {
        this.setState({
            Active
        });
    }

    render() {
        const { t, user, files } = this.props;

        let lazyLoading = true;
        let isLoaded = false;
        if (files) {
            isLoaded = files.isLoaded;
            lazyLoading = files.lazyLoading;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <Helmet>
                    <title>
                        {t('PageHeader.Files')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="position-relative" id="main-files-section" style={{
                    minHeight: '100vh'
                }}>

                    <School />
                    <div className="container padding-top padding-bottom">

                        <div className="row ">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="section-header mb-4">
                                        <div className="display-4 h3 custome-title" ><i className="mx-3 fal fa-paperclip "></i> {t('PageHeader.Files')} </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div className="py-2 flex-grow-1 ">
                                                <form className="search-form d-flex w-100">
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>

                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Files.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="search-form d-flex align-items-center w-100">
                                                <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-danger">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setActive(e.target.checked)}
                                                        ref={Active => this.Active = Active}
                                                        defaultChecked={this.state.Active}
                                                        name="Active"
                                                        id="Active" />
                                                    <label className="custom-control-label h6" style={{
                                                        whiteSpace: 'pre'
                                                    }} htmlFor="Active" >
                                                        {t(`Pages.Files.NotShow`)}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Reset()} >
                                                <span>
                                                    {
                                                        isLoaded ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ScrollArea
                                    horizontal={false}
                                    className={`area w-100 lazyLoading  p-2 `}
                                    smoothScrolling={true}
                                    style={{
                                        height: '100vh',
                                        background: '#fafafa91',
                                        borderRadius: '10px'
                                    }}
                                    ref={this.scrollArea}
                                    contentClassName="content "
                                    onScroll={this.handleScroll} >

                                    {this.renderFiles()}
                                    {
                                        lazyLoading && isLoaded && this.state.isClicked ?
                                            <div className="product-list-item" ref={this.fetchMoreContainer}>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-danger">
                                                        <i className="fad fa-sync-alt fa-spin"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle">
                                                        {t("Pages.Lessons.Loading")}
                                                    </h4>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </ScrollArea>

                                {
                                    lazyLoading && isLoaded && this.state.isClicked ?
                                        <div className="row justify-content-md-center" style={{
                                            marginTop: '-50px'
                                        }} >
                                            <div className="d-flex justify-content-center w-100">
                                                <div onClick={() => this.scrollDown()} className="theme-btn btn-style-two d-inline-flex mb-1" style={{
                                                    borderRadius: 'unset',
                                                    borderTopRightRadius: '50px',
                                                    borderTopLeftRadius: '50px'
                                                }}>
                                                    <span className="txt pl-2"> {t('Pages.Lessons.LoadMore')} </span>
                                                    <div className="mouse-btn-down">
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices,
        user: state.user,
        files: state.files
    };
};

export default connect(mapStateToProps, { userInitial, fetch, setFavorite, fetchIndices, resetFiles })(withTranslation('common')(Files));

import React from "react";
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';

class DragDrop extends React.Component {

    state = { items: [] };
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.setState({
            items: this.props.items
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.items) {
            state.items = props.items;
        }
        return state;
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });

        this.props.setSort(items);
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    getListStyle = isDraggingOver => ({
        background: !isDraggingOver ? "rgb(246 250 249)" : "#fff",
        padding: 10,
        border: ' 5px dashed rgb(255, 255, 255)'
    });

    getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 16,
        margin: `0 0 ${8}px 0`,
        position: 'static !important',
        // change background colour if dragging
        borderBottom: isDragging ? "2px dashed lightgreen" : "2px solid rgb(14, 44, 83)",
    });

    CopyItemId = Id => {
        const { t } = this.props;

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        navigator.clipboard.writeText(Id);

        toast.update(toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-clipboard"></i>
                    {t('Pages.Templates.copy_to_clipboard.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Templates.copy_to_clipboard.Body')}
                </div>
            </div>,
            type: toast.TYPE.INFO,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    }

    renderItem = (item, index, hasDrag) => {
        const { t } = this.props;
        return <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                {hasDrag ? <i className=" icon fas fa-arrows-alt"></i> : ""}
                <span className="text-danger h4 mx-1 my-0">
                    {index + 1}-
                </span>
                <div dangerouslySetInnerHTML={{ __html: item.Name }}></div>
            </div>
            {
                this.props.hideButton === true ? "" :
                    <div className="d-flex">
                        <button type="button" className="btn btn-sm m-1 btn-success" style={{
                            height: '35px',
                            width: '35px'
                        }} onClick={() => this.props.EditItem(item)}
                            title={t('Pages.Lessons.Questions.forms.Edit')}>
                            <i className=" fal fa-edit"></i>
                        </button>
                        {
                            this.props.API === `/Questions/` ?
                                <button type="button" className="btn btn-sm m-1 btn-dark" style={{
                                    height: '35px',
                                    width: '35px'
                                }} onClick={() => this.CopyItemId(item.Id)}
                                    title={t('Pages.Lessons.Questions.forms.CopyId')}>
                                    <i className=" fal fa-copy"></i>
                                </button>
                                : ""
                        }
                        {
                            this.props.isGame === true ?
                                <button type="button" className="btn btn-sm m-1 btn-info" style={{
                                    height: '35px',
                                    width: '35px'
                                }} onClick={() => this.props.ShowGame(item.Id)}
                                    title={t('Pages.Lessons.Questions.forms.View')}>
                                    <i className=" fal fa-eye"></i>
                                </button>
                                : ""
                        }
                        <button type="button" className="btn btn-sm m-1 btn-danger" style={{
                            height: '35px',
                            width: '35px'
                        }} onClick={() => this.props.DeleteItem(item.Id)}
                            title={t('Pages.Lessons.Questions.forms.Delete')}>
                            <i className=" fal fa-trash"></i>
                        </button>
                    </div>
            }
        </div>
    }

    render() {
        if (this.props.search) {
            return (
                <div style={this.getListStyle()} >
                    {
                        this.state.items.filter(p => p.Name.includes(this.props.search)).map((item, index) => (
                            <div key={item.Id} style={this.getItemStyle()} >
                                {this.renderItem(item, index, false)}
                            </div>
                        ))
                    }
                </div>
            );
        }
        if (this.state.items) {
            return (
                <>
                    <DragDropContext onDragEnd={this.onDragEnd} className="position-relative align-self-cente">
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={this.getListStyle(snapshot.isDraggingOver)} >
                                    {this.state.items.map((item, index) => (
                                        <Draggable key={item.Id} draggableId={`l${item.Id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )} >
                                                    {this.renderItem(item, index, true)}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(DragDrop);
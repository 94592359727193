import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

import configData from "../../../../Config/background.json";

class QuestionBackground extends React.Component {

    state = {
        backgroundColor: '',
        backgroundImage: '',
        BgSize: 'auto',
        Custom: '',
        BgRepeat: 'auto',
        BgAttachment: 'scroll',
        BgPositionX: 0,
        BgPositionY: 0,
        Opacity: 100,
        displayColorPicker: false
    };

    constructor(props) {
        super(props);
        this.Background = configData.Background;
    }

    renderBackgrounds() {
        return this.Background.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option>;
        });
    }

    componentDidMount() {
        this.setState({ BgSize: this.props.BgSize });
        this.setState({ Custom: this.props.Custom });
        this.setState({ Opacity: this.props.Opacity });
        this.setState({ BgRepeat: this.props.BgRepeat });
        this.setState({ BgPositionX: this.props.BgPositionX });
        this.setState({ BgPositionY: this.props.BgPositionY });
        this.setState({ BgAttachment: this.props.BgAttachment });
        this.setState({ CustomBackground: this.props.CustomBackground });
        this.setState({ backgroundColor: this.props.backgroundColor });
        this.setState({ backgroundImage: this.props.backgroundImage });
    }

    static getDerivedStateFromProps(props, state) {
        state.BgSize = props.BgSize;
        state.Custom = props.Custom;
        state.Opacity = props.Opacity;
        state.BgRepeat = props.BgRepeat;
        state.BgPositionX = props.BgPositionX;
        state.BgPositionY = props.BgPositionY;
        state.BgAttachment = props.BgAttachment;
        state.backgroundColor = props.backgroundColor;
        state.backgroundImage = props.backgroundImage;
        state.CustomBackground = props.CustomBackground;
        return state;
    }

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false })
    };

    handleClickDisplayColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleColorChange = (color) => {
        this.setState({ backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setQuestionBackgroundColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setQuestionBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({ BgPositionX: value });
            this.props.setQuestionBgPositionX(value);
        }
    }

    setQuestionBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({ BgPositionY: value });
            this.props.setQuestionBgPositionY(value);
        }
    }

    setQuestionOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({ Opacity: value });
            this.props.setQuestionOpacity(value);
        }
    }

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        const { t, title } = this.props;
        return (
            <>
                <li id="PopoverBackground" title={title ? title : t('Pages.Lessons.Questions.forms.tools.background')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="fas fa-image"></i>
                            {
                                title ? title : t('Pages.Lessons.Questions.forms.tools.background')
                            }
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="icon fas fa-image"></i>
                                {title ? title : t('Pages.Lessons.Questions.forms.tools.background')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">
                                    {
                                        this.props.setQuestionCustomBackground ?
                                            <>
                                                <div className="form-group mb-3">
                                                    <label className="text-dark h6"><i className="icon fas fa-expand-alt"></i> {t('Pages.Lessons.Questions.forms.tools.CustomBackground.title')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setQuestionCustomBackground(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.CustomBackground}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.CustomBackground.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.CustomBackground.title')}>
                                                            {
                                                                this.renderBackgrounds()
                                                            }
                                                        </select>
                                                        <button type="button">
                                                            <i className="fas fa-expand-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </> : ""
                                    }
                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                readOnly={true}
                                                value={this.state.backgroundColor}
                                            />
                                            <button
                                                type="button"
                                                onClick={this.handleClickDisplayColorPicker}
                                                style={{
                                                    color: this.state.backgroundColor !== 'transparent' ? this.state.backgroundColor : "",
                                                }}>
                                                <i className="fas fa-tint"></i>
                                            </button>
                                            {
                                                this.state.displayColorPicker ?
                                                    <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                        <SketchPicker color={this.state.backgroundColor} onChange={this.handleColorChange} />
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.image')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.image')}
                                                title={t('Pages.Lessons.Questions.forms.tools.image')}
                                                defaultValue={this.state.backgroundImage}
                                                onChange={e => this.props.setQuestionBackgroundImage(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-file-image"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-h"></i> {t('Pages.Lessons.Questions.forms.tools.BgPositionX')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BgPositionX')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BgPositionX')}
                                                value={this.state.BgPositionX}
                                                onChange={e => this.setQuestionBgPositionX(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-arrows-alt-h"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.BgPositionY')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BgPositionY')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BgPositionY')}
                                                value={this.state.BgPositionY}
                                                onChange={e => this.setQuestionBgPositionY(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-arrows-alt-v"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-expand-alt"></i> {t('Pages.Lessons.Questions.forms.tools.BgSize.title')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setQuestionBgSize(e.target.value)}
                                                className="w-100"
                                                value={this.state.BgSize}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BgSize.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BgSize.title')}>
                                                <option value="auto">{t('Pages.Lessons.Questions.forms.tools.BgSize.auto')}</option>
                                                <option value="contain">{t('Pages.Lessons.Questions.forms.tools.BgSize.contain')}</option>
                                                <option value="cover">{t('Pages.Lessons.Questions.forms.tools.BgSize.cover')}</option>
                                            </select>
                                            <button type="button">
                                                <i className="fas fa-expand-alt"></i>
                                            </button>
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.tools.BgRepeat.title')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setQuestionBgRepeat(e.target.value)}
                                                className="w-100"
                                                value={this.state.BgRepeat}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BgRepeat.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BgRepeat.title')}>
                                                <option value="no-repeat">{t('Pages.Lessons.Questions.forms.tools.BgRepeat.no-repeat')}</option>
                                                <option value="repeat">{t('Pages.Lessons.Questions.forms.tools.BgRepeat.repeat')}</option>
                                                <option value="repeat-x">{t('Pages.Lessons.Questions.forms.tools.BgRepeat.repeat-x')}</option>
                                                <option value="repeat-y">{t('Pages.Lessons.Questions.forms.tools.BgRepeat.repeat-y')}</option>
                                            </select>
                                            <button type="button">
                                                <i className="fas fa-repeat-alt"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-braille"></i> {t('Pages.Lessons.Questions.forms.tools.Opacity')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.Opacity')}
                                                title={t('Pages.Lessons.Questions.forms.tools.Opacity')}
                                                value={this.state.Opacity}
                                                onChange={e => this.setQuestionOpacity(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-braille"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-map-pin"></i> {t('Pages.Lessons.Questions.forms.tools.BgAttachment.title')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setQuestionBgAttachment(e.target.value)}
                                                className="w-100"
                                                value={this.state.BgAttachment}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BgAttachment.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BgAttachment.title')}>
                                                <option value="scroll">{t('Pages.Lessons.Questions.forms.tools.BgAttachment.scroll')}</option>
                                                <option value="fixed">{t('Pages.Lessons.Questions.forms.tools.BgAttachment.fixed')}</option>
                                            </select>
                                            <button type="button">
                                                <i className="fas fa-map-pin"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group w-100">
                                        <label className="text-dark h6"><i className="icon fas fa-flask-potion"></i> {t('Pages.Lessons.Questions.forms.tools.Custom')}</label>
                                        <div className="search-form">
                                            <textarea
                                                rows="5"
                                                onChange={e => this.props.setQuestionCustom(e.target.value)}
                                                className="w-100"
                                                value={this.state.Custom}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.Custom')}
                                                title={t('Pages.Lessons.Questions.forms.tools.Custom')}>
                                            </textarea>
                                            <button type="button">
                                                <i className="fas fa-flask-potion"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(QuestionBackground);
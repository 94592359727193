import React from 'react';
import ReactDOM from 'react-dom';
import DropTarget from '../DragDrop/DropTarget';

class DropableGameFiled extends React.Component {

    state = {
        hoverStyle: '',
        style: '',
        text: '',
        isTrue: 2,
        Sort: 0,
        AnswerId: 0,
        hover: false,
        onEdit: false
    };

    componentDidMount() {
        const AnswerId = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("AnswerId"));
        const Sort = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("Sort"));
        const isTrue = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("isTrue"));
        this.setState({
            Sort,
            isTrue
        });
        if (AnswerId && AnswerId !== 0) {
            var answer = this.state.answers.filter(p => p.Id === AnswerId)[0];
            let text = '';
            if (answer) {
                text = answer.Name
            }
            this.setState({
                text: text,
                isTrue,
                AnswerId
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.style = props.style;
        state.hoverStyle = props.hoverStyle;
        state.onEdit = props.onEdit;
        state.answers = props.answers;
        return state;
    }

    onHit = e => {
        if (e) {
            let Sort = 0;
            if (e.path && e.path[2] && e.path[2].attributes && e.path[2].attributes.sort) {
                Sort = parseInt(e.path[2].attributes.sort.value);
            } else {
                Sort = this.state.Sort;
            }
            if (this.props.setDropTargetAnswer(e.dragData, Sort)) {
                this.setState({
                    text: e.dragData.Name,
                    hover: false,
                    Sort
                });
            }
            this.setState({
                hover: false
            });
        }
    }

    onDragEnter = e => {
        this.setState({
            hover: true
        });
    }

    onDragLeave = e => {
        this.setState({
            hover: false
        });
    }

    render() {
        let style = {
        };
        if (this.state.text === '') {
            style = {
                minWidth: '100px'
            };
        }

        let mainStyle = this.state.style;
        mainStyle = {
            display: 'inline-block',
            position: 'relative',
            ...mainStyle,
        };

        if (this.state.text !== '') {
            mainStyle = {
                ...mainStyle,
                width: 'auto',
                minWidth: 'auto'
            }
        }

        if (this.state.text) {
            return <span style={mainStyle} >
                <div className="d-inline-block my-10" style={style} dangerouslySetInnerHTML={{ __html: this.state.text }}>
                </div>
            </span>
        }

        if (this.state.hover) {
            let hover = this.state.hoverStyle;
            mainStyle = {
                ...mainStyle,
                ...hover
            };
        }

        return (<DropTarget
            dropData={{
                Sort: this.state.Sort
            }}
            targetKey={`DragDrop-game`}
            onDragLeave={this.onDragLeave}
            onDragEnter={this.onDragEnter}
            onHit={this.onHit} >
            <span style={mainStyle} >
                <div className="d-inline-block my-10" style={style} dangerouslySetInnerHTML={{ __html: this.state.text }}>
                </div>
            </span>
        </DropTarget>
        );
    }
}
export default DropableGameFiled;
import api from '../../../../api/api';

export const loadUsers = () => async dispatch => {
    dispatch({ type: 'USERS_LOADING' });
}

export const fetchUsers = postData => async dispatch => {
    dispatch({ type: 'USERS_LOADING' });
    const response = await api.post(`/users/`, postData);
    dispatch({ type: 'USERS_INITIAL', users: response.data.data });
}

export const fetchSchools = () => async dispatch => {
    dispatch({ type: 'SCHOOLS_LOADING' });
    const response = await api.get(`/Schools/?byView=${true}`);
    dispatch({ type: 'SCHOOLS_INITIAL', schools: response.data.data });
}

export const updateUser = (id, user) => async dispatch => {
    dispatch({ type: 'USERS_UPDATE_INFO', user, id });
}

export const removeUsers = (id) => async dispatch => {
    dispatch({ type: 'USERS_QUEUE_FOR_REMOVAL', id });
}

export const undoUsers = (id) => async dispatch => {
    dispatch({ type: 'USERS_UNDO', id });
}

export const clearUsers = (id) => async dispatch => {
    dispatch({ type: 'USERS_CLEAN_NODES', id });
}
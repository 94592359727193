import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";

import Footer from "../Footer/FooterBottom";
import api from "../../api/api";
import ComingSoon from "./ComingSoon";

class Prices extends React.Component {

    state = {
        Price: 20
    };

    componentDidMount() {
        api.get(`/GetPrice/`).then(response => {
            this.setState({
                Price: response.data
            });
        });
    }

    render() {
        const { t } = this.props;

        let showCountDown = true;
        let date = moment().month('July').endOf('month');
        if (moment().month('July').endOf('month') < moment()) {
            date = moment().month('August').endOf('month');
        }
        if (moment().month('July').endOf('August') < moment()) {
            showCountDown = false;
        }

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.Prices")} | {t('alarabeyya')}</title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>

                    <div className="container h-100">

                        <div className="row clearfix justify-content-center">

                            <div className="featured-block col-sm-6 " >
                                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                    <span className="border-one"></span>
                                    <span className="border-two"></span>
                                    <div className="icon-box d-flex justify-content-center flex-column cursor-pointer">
                                        <span className="icon"> <i className="fal fa-user-graduate display-4 text-white"></i></span>
                                    </div>
                                    <h5 className="cursor-pointer"> {t('Pages.Register.Single.title')}</h5>

                                    <div className="title mt-2" style={{
                                        fontSize: '18px'
                                    }}>
                                        <h5 className="cursor-pointer m-0 mb-3 p-0">
                                            <span className="mx-1">
                                                {this.state.Price}
                                            </span>
                                            <i className="fal fa-dollar-sign"></i>
                                            شهريّ
                                        </h5>
                                        يتمّ الدفع بشكل سنويّ
                                    </div>

                                    <Link to="/Register/Single" className={`custom-button primary-button mt-3`} >
                                        <span>
                                            اِبدَأْ تجربتك المجّانيّة الآن
                                        </span>
                                    </Link>

                                    <div className="list w-100 text-right">
                                        <ul>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                إمكانيّة تحميل أيّ ملفّ ورقيّ من الموقع بعد انتهاء التجربة المجّانية.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح كلّ الدروس المحوسبة لمدّة سنة بعد انتهاء التجربة المجّانية.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح كلّ الألعاب المحوسبة لمدّة سنة بعد انتهاء التجربة المجّانية.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح جميع محتويات الموقع لمدّة سنة بعد انتهاء التجربة المجّانية.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                بدون أيّ مخاطر: يمكنك إلغاء الاشتراك بِبِضْعِ نقرات في أيّ وقت خلال الشهر الأوّل، ولن تتحمّل أيّ تكاليف.
                                            </li>
                                        </ul>
                                    </div>


                                    {
                                        showCountDown && false ? <div className="text-center h6 mt-4">
                                            <p className="font-weight-bold h4">
                                                سترتفع الأسعار في الموقع اعتبارًا من
                                                <span className="mx-1">
                                                    {date.format("DD/MM/YYYY")}
                                                </span>
                                            </p>
                                            <div className="my-4">
                                                <ComingSoon date={date.format("MM/DD/YYYY")} />
                                            </div>
                                            <p className="font-weight-bold">
                                                إذا اشتركت بعد هذا التاريخ ستدفع السعر الجديد
                                            </p>
                                        </div> : ""
                                    }

                                </div>
                            </div>

                            <div className="featured-block col-sm-6 " >
                                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                    <span className="border-one"></span>
                                    <span className="border-two"></span>
                                    <div className="icon-box d-flex justify-content-center flex-column cursor-pointer">
                                        <span className="icon"> <i className="fal fa-school display-4 text-white"></i></span>
                                    </div>
                                    <h5 className="cursor-pointer mb-3"> {t('Pages.Register.School.title')}</h5>

                                    <div className="d-flex justify-content-around flex-wrap">
                                        <Link to="/Register/School" className={`custom-button`} >
                                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                                <div className="d-flex flex-column py-1" >
                                                    <span style={{
                                                        lineHeight: '30px'
                                                    }}>
                                                        30 دقيقة استشارة مجّانيّة
                                                    </span>
                                                    <span className="px-0" style={{
                                                        lineHeight: '25px',
                                                        fontSize: '16px'
                                                    }}>
                                                        التسجيل يستغرق أقلّ مِن 120 ثانية
                                                    </span>
                                                </div>
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="list w-100 text-right">
                                        <ul>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                إمكانيّة تحميل أيّ ملف من الموقع لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح كلّ الملفات المحوسبة لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح كلّ الألعاب المحوسبة لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح جميع محتويات الموقع لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح حساب منفرد لكلّ معلّم لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                إمكانيّة إرسال مهامّ محوسبة للطلّاب لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                صفر مخاطر: إذا لم تكن راضيًا لأيّ سبب، يمكنك استرجاع كامل المبلغ خلال 30 يومًا من الاشتراك.
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default withTranslation('common')(Prices);
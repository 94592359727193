import React from "react";
import BoomExplosion from "./BoomExplosion";

import Explosion from "./Explosion";
import RocketExplosion from "./RocketExplosion";
import ThunderExplosion from "./ThunderExplosion";
import GazExplosion from "./GazExplosion";

const ExplosionType = (props) => {

    const { top, left, isMute, type, stop } = props;

    if (type === "RocketExplosion") {
        return <RocketExplosion stop={stop} isMute={isMute} left={left} top={top} />
    }
    if (type === "ThunderExplosion" || type === "WaterExplosion" || type === "MagicExplosion") {
        return <ThunderExplosion stop={stop} isMute={isMute} left={left} top={top} />
    }
    if (type === "BoomExplosion" || type === "StoneExplosion") {
        return <BoomExplosion stop={stop} isMute={isMute} left={left} top={top} />
    }
    if (type === "GazExplosion") {
        return <GazExplosion stop={stop} isMute={isMute} left={left} top={top} />
    }
    return <Explosion stop={stop} isMute={isMute} left={left} top={top} />
}
export default ExplosionType;






import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
    fetchUsers as fetch,
    updateUser as update
} from './actions/';
import api from "../../../../api/api";
import SingleRegFrom from "../../Forms/SingleRegFrom";
import Footer from "../../../Footer/FooterBottom";
import UserSubMenu from "../UserSubMenu";
import Item from "./Item";

class SubUsers extends React.Component {

    state = { showAdd: false, search: '' };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.props.fetch();
        window.scrollToTop();
    }

    showAdd = () => {
        this.setState({
            showAdd: true
        });
    }
    handleCloseAdd = () => {
        this.setState({
            showAdd: false,
        });
    }
    Sync = () => {
        this.props.fetch();
    }
    setSearch = value => {
        this.setState({
            search: value
        });
    }

    renderUser() {
        const { t, users } = this.props;
        let isLoaded = false;

        if (users) {
            isLoaded = users.isLoaded;
        }

        if (users && users.users && users.users.length > 0) {
            let filterUser = users.users.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.SubUsers.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterUser.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.SubUsers.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.SubUsers.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterUser.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(user =>
                <Item key={user.Id} item={user}></Item>
            );
        }

        if (users && users.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.SubUsers.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.SubUsers.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/SubUsers/`,
            postData
        ).then(response => {
            if (response.data.code === "exists_username") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Username.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Username.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "exists_email") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "max_count") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.max_count_toast.Title')}</div> <div className="h6">{t('Pages.Register.max_count_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "success") {

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.SubUsers.toastAdd.Title')}</div> <div className="h6">{t('Pages.SubUsers.toastAdd.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleCloseAdd();
                this.Sync();

            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, users } = this.props;

        let isLoaded = false;
        if (users) {
            isLoaded = users.isLoaded;
        }

        return (
            <>
                {
                    this.state.showAdd ?
                        <>
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showAdd}
                                onHide={this.handleCloseAdd}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-plus text-primary icon"></i> {t("Pages.SubUsers.Add")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <SingleRegFrom
                                                isChild={true}
                                                country_code={user.user ? user.user.Country : ""}
                                                sendText={t('Pages.SubUsers.Save')}
                                                isForTeacher={true}
                                                onSubmit={this.onSubmit}></SingleRegFrom>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </> : ""
                }

                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.SubUsers.title')} | {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.SubUsers.title')}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.UsersControl.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.showAdd()} title={t("Pages.SubUsers.Add")} className="btn btn-primary mx-1">
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.SubUsers.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.renderUser()}

                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        users: state.users
    };
};

export default connect(mapStateToProps, { fetch, update })(withTranslation('common')(SubUsers));
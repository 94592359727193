import React from "react";
import { withTranslation } from 'react-i18next';
import DragDropContainer from "../../../Question/View/DragDrop/DragDropContainer";
import GameObjects from "../../../Question/View/GameObjects";
import configData from "../../../../Config/objects.json";

class DragObject extends React.Component {

    constructor(props) {
        super(props);
        this.countToWakeUp = 0;
        this.timer = "";
        this.wakeUp = "";
        this.designerContainer = React.createRef();
        this.to = this.props.fromRight ? 120 : -20;

        let left = -500;
        let top = 0;
        let flipX = this.props.flipX;
        if (this.props.MoveType === "horizontal") {
            left = this.props.fromRight ? -500 : this.props.designerContainer.current.clientWidth;
            top = this.props.top ? this.props.top : Math.floor(Math.random() * 40);
            flipX = this.props.fromRight ? this.props.flipX : !this.props.flipX;
        }
        this.state = {
            top,
            left,
            flipX,
            timerSound: "",
            isHide: false,
            isClick: false,
            isHover: false,
            canMove: true,
            isRepate: true,
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            objectMovmentId: Math.floor(Math.random() * 100000)
        };
        this.Objects = configData.Objects;
    }

    componentDidMount() {
        if (this.props.MoveType === "horizontal") {
            let timeout = this.state.delay;
            setTimeout(() => {
                window.moveTo(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
                this.timer = setInterval(() => {
                    if (this.state.isHide === false && this.state.isRepate) {
                        let left = -15;
                        if (this.designerContainer && this.designerContainer.current) {
                            var react = this.designerContainer.current.getBoundingClientRect();
                            const screenWidth = this.props.designerContainer.current.scrollWidth;
                            left = (parseFloat((react.width / screenWidth * 100).toFixed(2)) + 1) * -1;
                        }
                        if (this.to === 120) {
                            this.to = left;
                        } else {
                            this.to = 120;
                        }
                        this.setState({
                            flipX: !this.state.flipX,
                            top: Math.floor(Math.random() * 35)
                        });
                        window.moveTo(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
                    }
                }, (this.props.Time * 1000));
            }, timeout);
        } else if (this.props.MoveType !== "custom") {
            this.updatePosition(1);
            this.timer = setInterval(() => {
                this.updatePosition(this.props.Time);
            }, this.props.Time * 1000);
        } else if (this.props.MoveType === "custom") {
            this.wakeUp = setInterval(() => {
                if (this.state.canMove && !this.state.isHover && !this.state.isClick) {
                    this.moveAgain();
                } else if (this.countToWakeUp >= 2) {
                    this.countToWakeUp = 0;
                    this.moveAgain();
                } else {
                    this.countToWakeUp++;
                }
            }, 2000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
        clearInterval(this.timer);
        clearInterval(this.wakeUp);
        clearInterval(this.moveAgainTimer);
    }

    renderMoveStyle = currentMovment => {
        let result = "";
        let oldScale = 1;
        let isScaleChange = false;
        currentMovment.forEach(item => {
            if (item.AnswersScale && item.AnswersScale !== oldScale) {
                isScaleChange = true;
            }
            item.AnswersScale = oldScale;
        })
        currentMovment.sort((a, b) => a.Index - b.Index).forEach(item =>
            result += item.Index + `% {visibility:visible; transform-origin:100%; transform: ${isScaleChange ? `scale(${item.AnswersScale})` : ""} rotate(` + item.AnswersRotate + `deg); opacity:${item.AnswersOpacity / 100}; ${item.AnswersTranslatePositionY === "bottom" ? "top:unset; bottom" : "bottom:unset; top"} : ${item.AnswersTranslateY}%; ${item.AnswersTranslatePositionX === "left" ? "right:unset; left" : "left:unset; right"} :${item.AnswersTranslateX}% }`
        );
        return result;
    }

    updatePosition = Time => {
        if (this.props.MoveType === "horizontal") {
            window.moveTo(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, 1000);
            setTimeout(() => {
                this.timer = setInterval(() => {
                    if (this.state.isHide === false && this.state.isRepate) {
                        let left = -15;
                        if (this.designerContainer && this.designerContainer.current) {
                            var react = this.designerContainer.current.getBoundingClientRect();
                            const screenWidth = this.props.designerContainer.current.scrollWidth;
                            left = (parseFloat((react.width / screenWidth * 100).toFixed(2)) + 1) * -1;
                        }
                        if (this.to === 100) {
                            this.to = left;
                        } else {
                            this.to = 100;
                        }
                        window.moveTo(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
                        this.setState({
                            flipX: !this.state.flipX,
                            top: Math.floor(Math.random() * 35)
                        });
                    }
                }, this.props.Time * 1000);
            }, 1200);
        } else {
            const top = this.props.getRandomNumber(this.props.count, 40, "top");
            const left = this.props.getRandomNumber(this.props.count, 85, "left");
            this.setState({
                top,
                left
            });
            window.moveTo(`objectMovment${this.state.objectMovmentId}`, top, left, Time * 1000);
        }
    }

    stop = () => {
        if (!this.state.isHover) {
            window.stopMove(`objectMovment${this.state.objectMovmentId}`);
            clearInterval(this.timer);
            this.setState({
                isHover: true,
                isClick: true
            });
            this.move();
        }
    }

    move = () => {
        this.moveAgainTimer = setInterval(() => {
            if (!this.state.isClick) {
                this.moveAgain();
            }
        }, 3000);
    }

    moveAgain = () => {
        if (this.state.isHover) {
            if (this.moveAgainTimer) {
                clearInterval(this.moveAgainTimer);
            }
            if (this.props.MoveType !== "custom") {
                if (this.timer) {
                    clearInterval(this.timer);
                    this.updatePosition(1);
                    this.timer = setInterval(() => {
                        this.updatePosition(this.props.Time);
                    }, this.props.Time * 1000);
                }
            }
            this.setState({
                isClick: false,
                isHover: false
            });
        }
    }

    setClick = () => {
        this.setState({
            isClick: false,
            isHover: false
        });
    }

    Drop = data => {
        if (this.props.handelDropOnObject) {
            var answerImportant = this.props.handelDropOnObject(this.props.item, data.dropData.Sort);
            if (!answerImportant) {
                this.setState({
                    isHide: true
                });
            }
        } else if (data.dragData.Sort === this.props.index && data.dragData.Id === this.props.id) {
            this.setState({
                isHide: true
            });
        }
    }

    onDragStart = () => {
        if (!this.state.isHover) {
            this.setState({
                isHover: true
            });
        }
    }

    objectIsDrop = () => {
        if (this.state.isHover) {
            this.moveAgain();
        }
    }

    setCanMove = canMove => {
        this.setState({
            canMove
        });
    }

    render() {
        const { Movments, id, Time, index, MoveType, param, delay } = this.props;

        const currentMovment = Movments.filter(p => p.Id === id && p.Sort === index);

        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide) {

            let currentCursor = "";
            let hoverCursor = "";

            if (param) {
                if (param.Cursor && param.Cursor !== "none" && param.Cursor !== "custom") {
                    currentCursor = process.env.PUBLIC_URL + param.Cursor;
                } else if (param.Cursor && param.Cursor === "custom") {
                    currentCursor = param.CursorCustom;
                }
                if (param.ClickCursor && param.ClickCursor !== "none" && param.ClickCursor !== "custom") {
                    hoverCursor = process.env.PUBLIC_URL + param.ClickCursor;
                } else if (param.ClickCursor && param.ClickCursor === "custom") {
                    hoverCursor = param.ClickCursorCustom;
                }
            }

            let right = "unset";
            let top = "unset";
            let bottom = "unset";
            if (MoveType === "custom" && currentMovment && currentMovment[0]) {
                let item = currentMovment[0];
                right = item.AnswersTranslateX + '%';
                if (item.AnswersTranslatePositionY === "bottom") {
                    bottom = item.AnswersTranslateY + '%';
                } else {
                    top = item.AnswersTranslateY + '%';
                }
            }

            return <>
                {
                    MoveType === "custom" && currentMovment ?
                        <style type="text/css" dangerouslySetInnerHTML={
                            {
                                __html: ` @keyframes objectMovment-${id}-${index} { ${this.renderMoveStyle(currentMovment)} }`
                            }
                        }></style>
                        : ""
                }
                <div ref={this.designerContainer}
                    className={`position-absolute objectMovment `}
                    onMouseDown={() => this.stop()}
                    onMouseUp={() => { this.moveAgain(); this.setClick(); }}
                    onTouchMove={() => this.stop()}
                    onMouseEnter={() => this.setCanMove(false)}
                    onMouseLeave={() => this.setCanMove(true)}
                    id={`objectMovment${this.state.objectMovmentId}`}
                    key={`objectMovment${this.state.objectMovmentId}`}
                    style={{
                        visibility: this.state.isHover ? "visible" : MoveType === "custom" ? 'hidden' : "visible",
                        right: MoveType === "custom" ? right : '',
                        left: MoveType !== "custom" ? MoveType === "horizontal" ? this.state.left + 'px' : '-20%' : '',
                        top: MoveType === "custom" ? top : MoveType === "horizontal" ? `${this.state.top}%` : '-20%',
                        bottom: MoveType === "custom" ? bottom : '',
                        animationPlayState: this.state.isHover ? "paused" : "running",
                        animationDelay: `${delay}s`,
                        animationIterationCount: "infinite",
                        animationDirection: "normal",
                        animationFillMode: 'forwards',
                        animationDuration: `${Time}s`,
                        animationTimingFunction: 'linear',
                        animationName: this.state.isHover || this.state.isClick ? "" : `objectMovment-${id}-${index}`,
                        position: this.state.isHover ? "fixed" : "absolute",
                        zIndex: this.state.isHover ? 2 : 19,
                        cursor: this.state.isHover ? hoverCursor : currentCursor,
                        touchAction: 'none'
                    }}>
                    <DragDropContainer
                        id={`drag-${this.state.objectMovmentId}`}
                        key={`drag-${this.state.objectMovmentId}`}
                        zIndex={19}
                        objectIsDrop={this.objectIsDrop}
                        onDrop={this.Drop}
                        designerContainer={this.designerContainer}
                        customMove={MoveType === "custom"}
                        dragData={this.props.param}
                        grab={currentCursor}
                        grabbing={hoverCursor}
                        parentElement={this.props.designerContainer}
                        targetKey={`DragDrop-game`} >
                        <GameObjects
                            {...this.props}
                            flipX={this.state.flipX}
                            type="dragdrop-move" />
                    </DragDropContainer>
                </div>
            </>;
        }
        return "";
    }
}

export default withTranslation('common')(DragObject);


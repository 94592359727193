import React from "react";
import { withTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { toast } from 'react-toastify';

class InfoForm extends React.Component {

    state = {
        errorList: [],
        initialize: false
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        window.createRedactor("#Body");
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.item && Object.keys(props.item).length !== 0) {

            setTimeout(() => {
                props.initialize({
                    Title: props.item.Title,
                    Body: props.item.Body
                });
                window.setRedactor("#Body", props.item.Body);
            }, 700);

            state.initialize = true;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </div>
    );

    validate = () => {
        const errors = {};
        if (!this.Title.ref.current.getValue()) {
            errors.Title = 'Pages.EmailContents.form.Title.error';
        }
        const Body = window.getRedactor(`#Body`);
        if (!Body) {
            errors.Body = 'Pages.EmailContents.form.Body.error';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const Body = window.getRedactor(`#Body`);

            let postData = {
                Title: this.Title.ref.current.getValue(),
                Body
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);
        }
        e.preventDefault();
    }

    render() {
        const { t, item } = this.props;
        if (item) {
            return (
                <>
                    <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                        <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                            <h4 className="alert-heading">{t('ErrorsList')}</h4>
                            <div className="border-bottom border-white opacity-20"></div>
                            <div className="alert-text">
                                <ul className="list-unstyled mt-4">
                                    <li>
                                        <ul>
                                            {this.state.errorList}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="text-dark font-size-h5" htmlFor="Title"> <i className="icon fas fa-heading"></i> {t('Pages.EmailContents.form.Title.title')}</label>
                            <Field
                                ref={Title => this.Title = Title}
                                id="Title"
                                name="Title"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.EmailContents.form.Title.placeholder')}
                                title={t('Pages.EmailContents.form.Title.title')}
                                className="form-control"
                            />
                        </div>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <div className="form-group w-100">
                            <label className="text-dark font-size-h5" htmlFor="Body"> <i className="icon fas fa-text"></i> {t('Pages.EmailContents.form.Body.title')}</label>
                            <textarea
                                ref={Body => this.Body = Body}
                                id={`Body`}
                                name="Body"
                                placeholder={t('Pages.EmailContents.form.Body.placeholder')}
                                title={t('Pages.EmailContents.form.Body.title')}
                                autoComplete="off"
                                className="form-control" ></textarea>
                        </div>

                        <div className="form-group w-100 text-center">
                            <button className="custom-button" type="submit" ><span> {t('Save')}</span></button>
                        </div>
                    </form>
                </>
            );
        }
        return "";
    }
}

const validate = values => {
    const errors = {};
    if (!values.Title) {
        errors.Title = 'Pages.EmailContents.form.Title.error';
    }
    if (!values.Body) {
        errors.Body = 'Pages.EmailContents.form.Body.error';
    }
    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'EmailContentsForm',
    validate
})(InfoForm));

import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from "react-lazy-load-image-component";

class ListItem extends React.Component {

    state = { moreInfo: false };

    constructor(props) {
        super(props);
        this.pathList = [];
        this._toastId = new React.createRef();
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    render() {
        const { t, item, IsAdmin } = this.props;
        if (item) {

            return (
                <>

                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >

                            <div className="product-thumb">
                                <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                <div className="product-action-link">
                                    {
                                        IsAdmin ?
                                            <>
                                                <div className="my-1 mr-1" onClick={() => this.props.edit(item)} title={t("Pages.Lessons.action.edit")}><i className="far fa-edit"></i></div>
                                                <div className="my-1 mr-1 text-danger" onClick={() => this.props.remove(item.Id)} title={t("Pages.Lessons.action.remove")}><i className="far fa-trash"></i></div>
                                                <div className="my-1 mr-1 text-info" onClick={() => this.moreInfo()} title={t("Pages.Lessons.action.info")}><i className="far fa-info"></i></div>
                                                <div className="my-1 mr-1" onClick={() => this.props.duplicate(item.Id)} title={t("Pages.Lessons.action.duplicate")}><i className="far fa-clone"></i></div>
                                            </>
                                            :
                                            <div className="my-1" onClick={() => this.props.copyQuestionStyle(item.CustomStyle)} title={t("Pages.Lessons.Questions.forms.tools.copyStyleButton")}><i className="far fa-copy"></i></div>
                                    }
                                </div>
                            </div>
                            <div className="product-content">
                                <h5 className="title"> {item.Name} </h5>
                                <div className="d-flex justify-content-between ">
                                    <p className="px-1 ml-auto"></p>
                                </div>
                            </div>
                            {
                                IsAdmin && this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}
export default withTranslation('common')(ListItem);
import React from "react";
import GameObjects from "../../../Question/View/GameObjects";
import ExplosionType from "../../explosions/ExplosionType";

class ShootObject extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.Rocket = React.createRef();
        const distanc = this.props.designerContainer.current.clientWidth > this.props.designerContainer.current.clientHeight ? this.props.designerContainer.current.clientWidth : this.props.designerContainer.current.clientHeight;
        this.state = {
            updatePosition: this.updatePosition,
            zIndex: 14,
            left: distanc,
            ShowExplosion: false,
            isHide: false,
            speed: distanc / this.props.Time,
            objectMovmentId: Math.floor(Math.random() * 100000)
        };
        if (this.props.MoveSound) {
            this.audio = new Audio(process.env.PUBLIC_URL + this.props.MoveSound);
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.Rocket && this.Rocket.current && this.props.designerContainer && this.props.designerContainer.current) {
                this.move();
            }
        }, 30);
        if (this.audio && this.props.isPlayVolumeSound) {
            this.audio.play();
        }
    }

    move = () => {
        if (this.state.left >= 0) {
            this.setState({
                left: this.state.left - (this.state.speed / 30)
            });
        } else {
            this.setState({
                isHide: true
            });
            this.props.handleRemoveShoot(this.props.Id, null, null, false);
        }
        this.checkIfOut();
    }

    checkIfOut = () => {
        if (this.Rocket && this.Rocket.current) {
            const rocket = this.Rocket.current.getBoundingClientRect();

            for (var i = 0; i < this.props.items.length; ++i) {
                const element = this.props.items[i];

                if (element.jsx.ref.current && element.jsx.ref.current.designerContainer && element.jsx.ref.current.designerContainer.current) {
                    const object = element.jsx.ref.current.designerContainer.current.getBoundingClientRect();
                    const width = element.jsx.ref.current.designerContainer.current.scrollWidth;
                    const height = element.jsx.ref.current.designerContainer.current.scrollHeight;
                    if (rocket.x >= object.x && rocket.x <= (object.x + width)
                        &&
                        rocket.y >= object.y && rocket.y <= (object.y + height)
                    ) {
                        this.setState({
                            ShowExplosion: true,
                            element: element.jsx.props.param,
                            position: {
                                left: object.right + (width / 2),
                                top: object.bottom - (height / 2)
                            }
                        });
                        this.props.handleRemoveShoot(this.props.Id, this.state.element, true);
                        clearInterval(this.timer);
                        break;
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    stop = () => {
        this.setState({
            isHide: true
        });
    }

    render() {
        let height = 0;
        if (this.Rocket && this.Rocket.current) {
            height = this.Rocket.current.scrollHeight / 2;
        }
        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide) {
            return <div ref={this.Rocket}
                className={`position-absolute objectMovment d-inline-block`}
                id={`objectMovment${this.state.objectMovmentId}`}
                style={{
                    left: this.state.ShowExplosion ? `${this.state.position.left}px` : `${this.state.left}px`,
                    top: this.state.ShowExplosion ? `${this.state.position.top}px` : `${this.props.top - height}px`,
                    transform: `scale(${this.props.scale})`,
                    zIndex: this.state.zIndex ? this.state.zIndex : 19
                }}>
                {
                    this.state.ShowExplosion ?
                        <ExplosionType stop={this.stop}
                            type={this.props.ExplosionType}
                            isMute={!this.props.isPlayVolumeSound} />
                        :
                        <GameObjects rotate={-90} {...this.props} type="dragdrop-move" />
                }
            </div>;
        }
        return "";
    }
}

export default ShootObject;
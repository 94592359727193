import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

import DragDropContainer from '../DragDrop/DragDropContainer';

class DragFiled extends React.Component {

    state = {
        currentState: 'none',
        answer: '',
        style: '',
        text: '',
        isTrue: 2,
        Sort: 0,
        AnswerId: 0
    };

    constructor(props) {
        super(props);
        this.changeEvent = "";
    }

    static getDerivedStateFromProps(props, state) {
        state.answersList = props.answersList;
        state.answers = props.answers;
        state.fills = props.fills;
        state.CategoryId = props.CategoryId;
        return state;
    }


    componentDidMount() {
        const Sort = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("Sort"));
        const isTrue = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("isTrue"));
        const text = ReactDOM.findDOMNode(this).parentNode.getAttribute("text");
        const isSelected = ReactDOM.findDOMNode(this).parentNode.getAttribute("isSelected");

        var html = <span className={`cursor-pointer d-inline-block`} dangerouslySetInnerHTML={{
            __html: text
        }}></span>;

        if (isSelected === "true") {
            html = <span className={`selectedLineThrow d-inline-block`} style={{
                cursor: 'not-allowed'
            }} dangerouslySetInnerHTML={{
                __html: text
            }}></span>;
        }
        this.setState({
            Sort,
            isTrue,
            text,
            isSelected,
            item: {
                Sort,
                Id: this.props.AnswerId,
                html
            }
        });

        if (this.props.answersList) {
            var answer = this.props.answersList.filter(a => a.Sort === Sort && a.Id === this.props.CategoryId)[0];
            if (answer) {
                this.setState({
                    currentState: answer.SelectType
                });
            }
        }
    }

    render() {

        const { t } = this.props;

        let answerIsTrue = "";
        if (this.state.isTrue === 1) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="mx-1 fad fa-check-circle text-success h6"></i>;
        } else if (this.state.isTrue === 0) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="mx-1 fas fa-exclamation-triangle text-danger h6"></i>;
        }

        if (!this.state.item) {
            return "";
        }

        if (this.state.isSelected === "true") {
            return <>
                {this.state.item.html}
                {answerIsTrue}
            </>;
        }

        return (
            <>
                <DragDropContainer parentElement={this.props.designerContainer} dragData={this.state.item} targetKey={`QuestionId-${this.props.QuestionId}`} >
                    {this.state.item.html}
                </DragDropContainer>
                {answerIsTrue}
            </>
        );
    }
}
export default withTranslation('common')(DragFiled);
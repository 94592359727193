import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";

import LottiiePalyer from "./LottiiePalyer";

class Loading extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>{t("Loading")} {t('alarabeyya')}</title>
                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `header{ display:none; }section{padding-top:5px !important;padding-bottom:5px !important;}` }}></style>
                {
                    this.props.showGame ?
                        <div className="overlayer-loading">
                            <div className="d-flex align-items-center justify-content-center flex-column">
                                <h3 className="text-white py-3 text-center">
                                    {t("Loading")}
                                </h3>
                                <img src="/assets/images/logo/loading.gif?v=1" alt={`${t("alarabeyya")}`} />
                                <h3 className="text-white py-3 text-center">
                                    {t("Pages.AboutUs.MainHead")}
                                </h3>
                            </div>
                        </div>
                        :
                        <section className={`pos-rel pt-1 w-100 d-flex align-items-center justify-content-center`} style={{
                            height: '100vh',
                            background: '#fffcf5'
                        }}>
                            <div className="row mt-2" >
                                <div className="col-12 ">
                                    <div className="post-item mx-1 ">
                                        <div className="post-content position-relative oh" style={{
                                            borderRadius: '10px'
                                        }}>
                                            <div className="pt-3 w-100 text-center title display-3">
                                                {t("Loading")}
                                            </div>
                                            <LottiiePalyer />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </>
        );
    }
}

export default withTranslation('common')(Loading);
const MissionsReducers = (state = [], action) => {
    switch (action.type) {
        case "MISSIONS_LOADING":
            return {
                missions: state.missions,
                current: null,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "MISSIONS_INITIAL":
            return {
                missions: action.missions,
                current: null,
                toRemove: [],
                isLoaded: true
            };
        case "MISSION_INITIAL":
            return {
                missions: state.missions,
                current: action.mission,
                toRemove: [],
                isLoaded: true
            };
        case "MISSIONS_RESET":
            return {
                missions: [],
                current: null,
                toRemove: [],
                isLoaded: true
            };
        case "MISSIONS_LAZY_LOADING_STOP":
            return {
                missions: state.missions,
                current: null,
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: false
            };
        case "MISSIONS_LAZY_LOADING":
            return {
                missions: state.missions ? [...state.missions, ...action.missions] : action.missions,
                current: null,
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: true
            };
        case "MISSION_QUEUE_FOR_REMOVAL":
            return {
                missions: state.missions,
                current: null,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "MISSION_CLEAN_NODES":
            return {
                missions: state.toRemove.some(v => v === action.id) ? state.missions.filter(v => v.Id !== action.id) : state.missions,
                current: null,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "MISSION_UNDO":
            return {
                missions: state.missions,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default MissionsReducers;
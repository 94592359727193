import React from "react";
import { withTranslation } from 'react-i18next';
import MoveBackgroundObject from "../../Question/View/GamePreview/MoveBackground";

import LevelSelectionItem from "./LevelSelectionItem";

class LevelSelection extends React.Component {

    state = {
        CustomStyle: {},
        currentPage: 1
    };

    static getDerivedStateFromProps(props, state) {
        if (props.LessonPage && props.lesson && props.lesson.CustomStyle && props.lesson.CustomStyle.DashBoard) {
            state.CustomStyle = props.lesson.CustomStyle.DashBoard;
            let page = props.LessonPage[state.currentPage - 1];
            if (page && page.CustomStyle && page.CustomStyle.GlobalStyle) {
                state.CustomStyle = page.CustomStyle;
            }
        }
        return state;
    }

    showNext = () => {
        this.setState({
            currentPage: this.state.currentPage + 1
        });
    }

    showPrev = () => {
        this.setState({
            currentPage: this.state.currentPage - 1
        });
    }


    render() {
        const { t, lesson } = this.props;
        if (lesson) {

            let style = {};
            let styleBackground = {};
            let align = "x";
            let ControlColor = "custom";
            let MainColor = "";
            let SecondColor = "";
            let FontColor = "";
            let Cursor = "";
            let LabelStyleCustom = "";

            let SecondControlColor = "custom";
            let SecondMainColor = "";
            let SecondSecondColor = "";
            let SecondFontColor = "";
            let Repeat = "";

            let styleLabelStyle = {};
            let styleBackgroundLabelStyle = {};
            let alignLabelStyle = "x";
            let FontColorLabelStyle = "";
            let GlobalStyleCustom = "";
            let MarginBottomLabelStyle = 0;

            let IconType = "fas";
            let Icon = "caret";
            let IconColor = "";
            let IconCustomColor = "";
            let IconHoverColor = "";
            let IconPosition = "in";

            if (this.state.CustomStyle) {
                if (this.state.CustomStyle.GlobalStyle) {
                    style = {
                        minHeight: `${this.state.CustomStyle.GlobalStyle.height}px`,
                        width: `${this.state.CustomStyle.GlobalStyle.width}%`,
                        maxWidth: '100%',
                        paddingLeft: `${this.state.CustomStyle.GlobalStyle.PaddingLeft}px`,
                        paddingTop: `${this.state.CustomStyle.GlobalStyle.PaddingTop}px`,
                        paddingRight: `${this.state.CustomStyle.GlobalStyle.PaddingRight}px`,
                        paddingBottom: `${this.state.CustomStyle.GlobalStyle.PaddingBottom}px`,
                        borderColor: this.state.CustomStyle.GlobalStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.GlobalStyle.BorderColor : "",
                        borderStyle: `${this.state.CustomStyle.GlobalStyle.BorderStyle}`,
                        borderLeftWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthLeft}px`,
                        borderRightWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthRight}px`,
                        borderTopWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthTop}px`,
                        borderBottomWidth: `${this.state.CustomStyle.GlobalStyle.BorderWidthBottom}px`,
                        borderRadius: `${this.state.CustomStyle.GlobalStyle.BorderRadiusTop}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.GlobalStyle.BorderRadiusRight}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.GlobalStyle.BorderRadiusBottom}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.GlobalStyle.BorderRadiusLeft}${this.state.CustomStyle.GlobalStyle.BorderRadiusUnit}`,
                        boxShadow: `${this.state.CustomStyle.GlobalStyle.ShadowPositionX}px ${this.state.CustomStyle.GlobalStyle.ShadowPositionY}px ${this.state.CustomStyle.GlobalStyle.ShadowRadius}px 0px ${this.state.CustomStyle.GlobalStyle.shadowColor}`,
                    };
                    if (this.state.CustomStyle.GlobalStyle.AnswersAlign) {
                        if (this.state.CustomStyle.GlobalStyle.AnswersAlign === 'left') {
                            align = "l";
                        } else if (this.state.CustomStyle.GlobalStyle.AnswersAlign === 'right') {
                            align = "r";
                        }
                    }
                    if (this.state.CustomStyle.GlobalStyle.ControlColor) {
                        ControlColor = this.state.CustomStyle.GlobalStyle.ControlColor;
                    }
                    if (this.state.CustomStyle.GlobalStyle.ControlColor === "custom") {
                        MainColor = this.state.CustomStyle.GlobalStyle.MainColor;
                        FontColor = this.state.CustomStyle.GlobalStyle.FontColor;
                        SecondColor = this.state.CustomStyle.GlobalStyle.SecondColor;
                    }
                    if (this.state.CustomStyle.GlobalStyle.SecondControlColor) {
                        SecondControlColor = this.state.CustomStyle.GlobalStyle.SecondControlColor;
                    }
                    if (this.state.CustomStyle.GlobalStyle.SecondControlColor === "custom") {
                        SecondMainColor = this.state.CustomStyle.GlobalStyle.SecondMainColor;
                        SecondFontColor = this.state.CustomStyle.GlobalStyle.SecondFontColor;
                        SecondSecondColor = this.state.CustomStyle.GlobalStyle.SecondSecondColor;
                    }
                    if (this.state.CustomStyle.GlobalStyle) {
                        Repeat = this.state.CustomStyle.GlobalStyle.Repeat;
                    }

                    styleBackground = {
                        height: `100%`,
                        opacity: `${this.state.CustomStyle.GlobalStyle.Opacity}%`,
                        backgroundColor: this.state.CustomStyle.GlobalStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.GlobalStyle.backgroundColor : "",
                        backgroundImage: this.state.CustomStyle.GlobalStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.GlobalStyle.backgroundImage}')` : "",
                        backgroundPositionX: `${this.state.CustomStyle.GlobalStyle.BgPositionX}%`,
                        backgroundPositionY: `${this.state.CustomStyle.GlobalStyle.BgPositionY}%`,
                        backgroundSize: `${this.state.CustomStyle.GlobalStyle.BgSize}`,
                        backgroundRepeat: `${this.state.CustomStyle.GlobalStyle.BgRepeat}`,
                        backgroundAttachment: `${this.state.CustomStyle.GlobalStyle.BgAttachment}`,
                        marginLeft: `-${this.state.CustomStyle.GlobalStyle.PaddingLeft}px`,
                        marginTop: `-${this.state.CustomStyle.GlobalStyle.PaddingTop}px`,
                        marginRight: `-${this.state.CustomStyle.GlobalStyle.PaddingRight}px`,
                        marginBottom: `-${this.state.CustomStyle.GlobalStyle.PaddingBottom}px`
                    };
                    if (this.state.CustomStyle.GlobalStyle.Cursor !== 'custom' && this.state.CustomStyle.GlobalStyle.Cursor !== 'none' && this.state.CustomStyle.GlobalStyle.Cursor) {
                        Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.GlobalStyle.Cursor;
                    } else if (this.state.CustomStyle.GlobalStyle.Cursor === 'custom' && this.state.CustomStyle.GlobalStyle.CursorCustom) {
                        Cursor = this.state.CustomStyle.GlobalStyle.CursorCustom;
                    }

                    if (this.state.CustomStyle.GlobalStyle.IconPosition) {
                        IconPosition = this.state.CustomStyle.GlobalStyle.IconPosition;
                    }
                    if (this.state.CustomStyle.GlobalStyle.Icon) {
                        Icon = this.state.CustomStyle.GlobalStyle.Icon;
                    }
                    if (this.state.CustomStyle.GlobalStyle.IconType) {
                        IconType = this.state.CustomStyle.GlobalStyle.IconType;
                    }

                    if (this.state.CustomStyle.GlobalStyle.IconHoverColor) {
                        IconHoverColor = this.state.CustomStyle.GlobalStyle.IconHoverColor;
                    }
                    if (this.state.CustomStyle.GlobalStyle.IconColor === "custom" && this.state.CustomStyle.GlobalStyle.IconCustomColor) {
                        IconCustomColor = this.state.CustomStyle.GlobalStyle.IconCustomColor;
                    } else if (this.state.CustomStyle.GlobalStyle.IconColor) {
                        IconColor = this.state.CustomStyle.GlobalStyle.IconColor;
                    }
                    GlobalStyleCustom = this.state.CustomStyle.GlobalStyle.Custom;
                }

                if (this.state.CustomStyle.LabelStyle) {
                    styleLabelStyle = {
                        minHeight: `${this.state.CustomStyle.LabelStyle.height}px`,
                        width: `${this.state.CustomStyle.LabelStyle.width}%`,
                        maxWidth: '100%',
                        color: this.state.CustomStyle.LabelStyle.FontColor,
                        paddingLeft: `${this.state.CustomStyle.LabelStyle.PaddingLeft}px`,
                        paddingTop: `${this.state.CustomStyle.LabelStyle.PaddingTop}px`,
                        paddingRight: `${this.state.CustomStyle.LabelStyle.PaddingRight}px`,
                        paddingBottom: `${this.state.CustomStyle.LabelStyle.PaddingBottom}px`,
                        borderColor: this.state.CustomStyle.LabelStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.LabelStyle.BorderColor : "",
                        borderStyle: `${this.state.CustomStyle.LabelStyle.BorderStyle}`,
                        borderLeftWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthLeft}px`,
                        borderRightWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthRight}px`,
                        borderTopWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthTop}px`,
                        borderBottomWidth: `${this.state.CustomStyle.LabelStyle.BorderWidthBottom}px`,
                        borderRadius: `${this.state.CustomStyle.LabelStyle.BorderRadiusTop}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.LabelStyle.BorderRadiusRight}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.LabelStyle.BorderRadiusBottom}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.LabelStyle.BorderRadiusLeft}${this.state.CustomStyle.LabelStyle.BorderRadiusUnit}`,
                        boxShadow: `${this.state.CustomStyle.LabelStyle.ShadowPositionX}px ${this.state.CustomStyle.LabelStyle.ShadowPositionY}px ${this.state.CustomStyle.LabelStyle.ShadowRadius}px 0px ${this.state.CustomStyle.LabelStyle.shadowColor}`,
                        marginBottom: `${this.state.CustomStyle.LabelStyle.Margin}px`,
                        zIndex: 9
                    };
                    if (this.state.CustomStyle.LabelStyle.AnswersAlign) {
                        if (this.state.CustomStyle.LabelStyle.AnswersAlign === 'left') {
                            alignLabelStyle = "l";
                        } else if (this.state.CustomStyle.LabelStyle.AnswersAlign === 'right') {
                            alignLabelStyle = "r";
                        }
                    }
                    FontColorLabelStyle = this.state.CustomStyle.LabelStyle.FontColor;
                    MarginBottomLabelStyle = this.state.CustomStyle.LabelStyle.MarginBottom;
                    styleBackgroundLabelStyle = {
                        height: `100%`,
                        opacity: `${this.state.CustomStyle.LabelStyle.Opacity}%`,
                        backgroundColor: this.state.CustomStyle.LabelStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.LabelStyle.backgroundColor : "",
                        backgroundImage: this.state.CustomStyle.LabelStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.LabelStyle.backgroundImage}')` : "",
                        backgroundPositionX: `${this.state.CustomStyle.LabelStyle.BgPositionX}%`,
                        backgroundPositionY: `${this.state.CustomStyle.LabelStyle.BgPositionY}%`,
                        backgroundSize: `${this.state.CustomStyle.LabelStyle.BgSize}`,
                        backgroundRepeat: `${this.state.CustomStyle.LabelStyle.BgRepeat}`,
                        backgroundAttachment: `${this.state.CustomStyle.LabelStyle.BgAttachment}`,
                        marginLeft: `-${this.state.CustomStyle.LabelStyle.PaddingLeft}px`,
                        marginTop: `-${this.state.CustomStyle.LabelStyle.PaddingTop}px`,
                        marginRight: `-${this.state.CustomStyle.LabelStyle.PaddingRight}px`,
                        marginBottom: `-${this.state.CustomStyle.LabelStyle.PaddingBottom}px`,
                        zIndex: -1
                    };
                    LabelStyleCustom = this.state.CustomStyle.LabelStyle.Custom;
                }
            }

            let from = 0;
            let byPassGame = 0;
            let resetQuestion = 0;
            let currentPageCount = 0;
            let currentPage = this.state.currentPage;

            if (currentPage > this.props.LessonPage.length) {
                currentPage -= 1;
            }
            if (this.props.LessonPage && this.props.LessonPage[currentPage - 1]) {
                currentPageCount = this.props.LessonPage[currentPage - 1].Count;
                this.props.LessonPage.forEach((element, index) => {
                    if (currentPage > index + 1) {
                        from += element.Count;
                    }
                });
            }
            if (currentPage === this.props.LessonPage.length) {
                resetQuestion = lesson.Questions.length;
            }

            lesson.Questions.forEach(item => {
                if (lesson.scores && lesson.scores.Levels) {
                    const level = lesson.scores.Levels.filter(p => item.QuestionLessonId === p.LessonQuestionId || item.Id === p.QuestionId)[0];
                    if (level) {
                        if (level.IsPassed === false && item.Position && level.TryNumber >= item.Mark) {
                            byPassGame++;
                        }
                    }
                }
            });

            return <>
                <style dangerouslySetInnerHTML={{
                    __html: `html{overflow-x: unset;}header{ display:none; }.levels-container{overflow-x: hidden; height:100vh; background: url('${lesson ?
                        lesson.CustomStyle &&
                            this.state.CustomStyle &&
                            this.state.CustomStyle.GlobalStyle &&
                            this.state.CustomStyle.GlobalStyle.CustomBackground ?
                            this.state.CustomStyle.GlobalStyle.CustomBackground :
                            lesson.Background
                        : ""}');background-size:cover;background-position:center;cursor: url('${Cursor}') 20 20, auto;}section{padding-top:5px !important;padding-bottom:5px !important;}.custom-hover:hover{color:${IconHoverColor} !important;} `
                }}></style>
                <div id="LevelSelection" className="levels-container d-flex align-items-center" >
                    <div className="container position-relative" style={{
                        zIndex: 99999
                    }}>

                        <div className="row d-flex flex-wrap justify-content-center">
                            <div>
                                <div className={`m${alignLabelStyle}-auto p-s-5 position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={styleLabelStyle}>
                                    <div className="w-100 position-absolute " style={styleBackgroundLabelStyle} id={`SelectDesignerPreviewLabelStyle_${lesson.Id}`}> </div>
                                    <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyle_${lesson.Id}{${LabelStyleCustom} }` }}></style>
                                    <div className="h3" style={{
                                        color: `${FontColorLabelStyle}`,
                                        marginBottom: `${MarginBottomLabelStyle}px`,
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {t('Pages.Games.LevelSelection')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            IconPosition === "out" ?
                                <div className="row d-flex w-100 h-100 align-items-center position-absolute justify-content-between" >
                                    <div className="mx-2">
                                        {
                                            currentPage > 1 ?
                                                <div className=" h1 custom-pointer" style={{
                                                    fontSize: '6rem',
                                                    zIndex: 9999
                                                }}
                                                    onClick={() => this.showPrev()}
                                                    title={t('Pages.Games.prev')}>
                                                    <span style={{
                                                        color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                    }}>
                                                        <i className={`text-${IconColor} ${IconType} fa-${Icon}-right custom-hover`}></i>
                                                    </span>
                                                </div>
                                                : ""
                                        }

                                    </div>
                                    <div className="mx-2">
                                        {
                                            currentPage < this.props.LessonPage.length ?
                                                <div className=" h1 custom-pointer" style={{
                                                    fontSize: '6rem',
                                                    zIndex: 9999
                                                }}
                                                    onClick={() => this.showNext()}
                                                    title={t('Pages.Games.next')}>
                                                    <span style={{
                                                        color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                    }}>
                                                        <i className={`text-${IconColor} ${IconType} fa-${Icon}-left custom-hover`}></i>
                                                    </span>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                                : ""
                        }

                        <div className={`m${align}-auto position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={style}>
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerView_${lesson.Id}`}> </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerView_${lesson.Id}{${GlobalStyleCustom}}.level-button-custom::before{background: ${MainColor};}.level-button-custom::after{background: ${SecondColor};}.level-button-custom span{color:${FontColor}}.level-button-second::before{background: ${SecondMainColor};}.level-button-second::after{background: ${SecondSecondColor};}.level-button-second span{color:${SecondFontColor}}` }}></style>

                            <div className={`${IconPosition === "in" ? "d-flex w-100 justify-content-between align-items-center" : ""}`}>
                                <div className={`mx-2 ${IconPosition === "in" ? "" : "d-none"}`} style={{
                                    zIndex: 9999,
                                    visibility: currentPage > 1 ? "visible" : "hidden"
                                }}>
                                    <div className=" h1 custom-pointer" style={{
                                        fontSize: '6rem'
                                    }}
                                        onClick={() => this.showPrev()}
                                        title={t('Pages.Games.prev')}>
                                        <span style={{
                                            color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                        }}>
                                            <i className={`text-${IconColor} ${IconType} fa-${Icon}-right custom-hover`}></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row d-flex flex-wrap" style={{
                                    justifyContent: 'space-evenly'
                                }}>
                                    {
                                        lesson.Questions.slice(from, from + currentPageCount + resetQuestion).map((item, index) => {
                                            let starCount = 0;
                                            let isPlay = false;
                                            if (lesson.scores && lesson.scores.Levels) {
                                                const level = lesson.scores.Levels.filter(p => item.QuestionLessonId === p.LessonQuestionId || item.Id === p.QuestionId)[0];
                                                if (level) {
                                                    starCount = level.Star;
                                                    isPlay = true;
                                                }
                                            }
                                            let isRepeat = false;
                                            if (Repeat) {
                                                const factor = parseInt(Repeat);
                                                if ((index + from + 1) % factor === 0) {
                                                    isRepeat = true;
                                                }
                                            }
                                            return <LevelSelectionItem
                                                key={`level-${item.Id}-${index}`}
                                                index={index + from}
                                                isPlay={isPlay}
                                                onEdit={this.props.onEdit}
                                                canPlayAnyGame={this.props.canPlayAnyGame}
                                                canSaveGame={this.props.canSaveGame}
                                                currentGame={!this.props.canPlayAnyGame && !this.props.canSaveGame ? this.props.totalPlayGame + byPassGame : this.props.currentGame + byPassGame}
                                                item={item}
                                                isRepeat={isRepeat}
                                                starCount={starCount}
                                                ControlColor={ControlColor}
                                                SecondControlColor={SecondControlColor}
                                                showGame={this.props.showGame}
                                            />
                                        })
                                    }
                                </div>
                                <div className={`mx-2 ${IconPosition === "in" ? "" : "d-none"}`} style={{
                                    zIndex: 9999,
                                    visibility: currentPage < this.props.LessonPage.length ? "visible" : "hidden"
                                }}>
                                    <div className=" h1 custom-pointer" style={{
                                        fontSize: '6rem'
                                    }}
                                        onClick={() => this.showNext()}
                                        title={t('Pages.Games.next')}>
                                        <span style={{
                                            color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                        }}>
                                            <i className={`text-${IconColor} ${IconType} fa-${Icon}-left custom-hover`}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.hideMove ? "" :
                            <div className="w-100 h-100 oh position-absolute">
                                <MoveBackgroundObject CustomStyle={this.state.CustomStyle.GlobalStyle} Id={lesson.Id} />
                            </div>
                    }
                </div>
            </>
        }
        return "";
    }

}

export default withTranslation('common')(LevelSelection);
import React, { useState } from "react";

const SelectExplosionType = (props) => {

    const { image, styleBox, item, hoverStyle, isActive } = props;

    const [isHover, setIsHover] = useState(false);

    const isHoverStyle = () => {
        setIsHover(!isHover);
    }

    return <div key={`category-item-view-${item.Id}`}
        onMouseEnter={isHoverStyle}
        onMouseLeave={isHoverStyle}
        onClick={props.setExplosionType} className="d-flex text-center align-items-center justify-content-center flex-column mx-2 mb-3 oh" style={isHover || isActive ? hoverStyle : styleBox}>
        <div className="mb-1">
            <img src={image} alt="" />
        </div>
        <div dangerouslySetInnerHTML={{
            __html: item.Name
        }}></div>
    </div>;
}
export default SelectExplosionType;

import React from "react";

import DragDrop from "./DragDrop/DragDrop";
import FallDown from "./FallDown";
import ShootingFallDown from "./ShootingFallDown";
import Shooting from "./Shooting/Shooting";
import MultiSelect from "./MultiSelect";
import FillGame from "./Fill";
import ShootingHorizontal from "./ShootingHorizontal";
import DropVertical from "./DropVertical";
import DropMultiSelect from "./DropMultiSelect";
import PullGame from "./Pull";
import ShootingFallDownMultiSelect from "./ShootingFallDownMultiSelect";
import ShootingHorizontalMultiSelect from "./ShootingHorizontalMultiSelect";
import FallDownMultiSelect from "./FallDownMultiSelect";
import ShootDownMultiSelect from "./ShootDownMultiSelect";
import PullUpSpaceShip from "./PullUpSpaceShip";
import PullUp from "./PullUp";
import CatchHorizontalMultiSelectGame from "./CatchHorizontalMultiSelectGame";

class SelectGame extends React.Component {

    render() {
        const { Question } = this.props;
        if (Question.Type.Id === 15) {
            return <DragDrop  {...this.props} />
        } else if (Question.Type.Id === 16) {
            return <Shooting  {...this.props} />
        } else if (Question.Type.Id === 17) {
            return <FallDown  {...this.props} />
        } else if (Question.Type.Id === 18) {
            return <ShootingFallDown  {...this.props} />
        } else if (Question.Type.Id === 19) {
            return <MultiSelect  {...this.props} />
        } else if (Question.Type.Id === 20) {
            return <FillGame  {...this.props} />
        } else if (Question.Type.Id === 21) {
            return <ShootingHorizontal  {...this.props} />
        } else if (Question.Type.Id === 22) {
            return <DropVertical  {...this.props} />
        } else if (Question.Type.Id === 23) {
            return <DropMultiSelect  {...this.props} />
        } else if (Question.Type.Id === 24) {
            return <PullGame {...this.props} />
        } else if (Question.Type.Id === 25) {
            return <ShootingFallDownMultiSelect {...this.props} />
        } else if (Question.Type.Id === 26) {
            return <ShootingHorizontalMultiSelect {...this.props} />
        } else if (Question.Type.Id === 27) {
            return <FallDownMultiSelect {...this.props} />
        } else if (Question.Type.Id === 28) {
            return <ShootDownMultiSelect {...this.props} />
        } else if (Question.Type.Id === 29) {
            return <PullUpSpaceShip {...this.props} />
        } else if (Question.Type.Id === 30) {
            return <PullUp {...this.props} />
        } else if (Question.Type.Id === 31) {
            return <CatchHorizontalMultiSelectGame {...this.props} />
        }
        return "";
    }
}

export default SelectGame;
import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';

import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import {
    fetchTemplates as fetch,
    removeTemplate as remove,
    undoDeleteTemplate as undo,
    clearTemplate as clear
} from './actions';
import {
    fetchQuestionType
} from '../Question/actions';
import api from '../../../api/api';
import ListItem from "./ListItem";

class Templates extends React.Component {

    state = { search: '', initialize: false, QuestionTypeId: 0 };

    componentDidMount() {
        this.props.fetchQuestionType();
        this.Sync(this.state.QuestionTypeId);
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.questionTypes && props.questionTypes.questionTypes) {
            state.questionTypes = props.questionTypes.questionTypes.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
            state.initialize = true;
        }
    }

    Sync = QuestionTypeId => {
        this.props.fetch(QuestionTypeId);
    }

    renderTemplates() {
        const { t, templates } = this.props;
        let isLoaded = false;

        if (templates) {
            isLoaded = templates.isLoaded;
        }

        let toRemove = [];
        if (templates) {
            toRemove = templates.toRemove;
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (templates && templates.templates && templates.templates.length > 0) {
            let filterTemplate = templates.templates.filter(v => v.Name.includes(search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Templates.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterTemplate.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Templates.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Templates.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterTemplate.map(item =>
                (toRemove && toRemove.includes(item.Id)) ? "" :
                    <ListItem
                        IsAdmin={true}
                        key={item.Id}
                        item={item}
                        Sync={this.Sync}
                        remove={this.showDelete} ></ListItem>
            );
        }

        if (templates && templates.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Templates.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Templates.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteTemplate(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteTemplate = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/QuestionStyles/${id}`).then(response => {
            this.props.clear(id);
            this.Sync(this.state.QuestionTypeId);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Templates.toast_Delete.Title')}</div> <div className="h6">{t('Pages.Templates.toast_Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    changeQuestionType = type => {
        if (type) {
            this.Sync(type.value);
            this.setState({
                QuestionTypeId: type.value
            });
        }
    }

    render() {
        const { t, user, templates, questionTypes } = this.props;

        let isLoaded = false;
        if (templates) {
            isLoaded = templates.isLoaded;
        }

        let isQuestionType = false;
        if (questionTypes) {
            isQuestionType = questionTypes.isLoaded;
        }

        return (
            <>
                <section className="pos-rel padding-top padding-bottom">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("Pages.Templates.title")} | {t('alarabeyya')}</title>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                        </Helmet>

                        <div className="row mb-30-none">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.Templates.title')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">

                                            <div className="p-2 flex-grow-1 ">
                                                <Select
                                                    isClearable={true}
                                                    name="QuestionTypeId"
                                                    placeholder={t('Pages.Templates.form.QuestionType.placeholder')}
                                                    isRtl={true}
                                                    isLoading={!isQuestionType}
                                                    isSearchable={true}
                                                    ref={QuestionTypeId => this.QuestionTypeId = QuestionTypeId}
                                                    options={this.state.questionTypes}
                                                    filterOption={this.filterOptions}
                                                    onChange={this.changeQuestionType}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#92278f',
                                                            primary: '#92278f',
                                                        },
                                                    })}
                                                />
                                            </div>

                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Templates.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.Sync()} title={t("Pages.Templates.Sync")} className="btn mx-1 btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderTemplates()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        templates: state.templates,
        questionTypes: state.questionTypes
    };
};

export default connect(mapStateToProps, { fetchQuestionType, fetch, remove, undo, clear })(withTranslation('common')(Templates));
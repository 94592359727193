import React from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class BillItem extends React.Component {

    state = {
        moreInfo: false,
        showStop: false
    };

    showStop = () => {
        this.setState({
            showStop: true
        });
    }

    handleClose = () => {
        this.setState({
            showStop: false
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    renderTableBodyBillView() {
        const { item } = this.props;
        return item.Items.map(service => {
            return <tr key={`billView-${service.Service.Id}`}>
                <td>
                    {service.Service.Name}
                </td>
                <td>
                    {service.Service.Price}
                </td>
            </tr>
        });
    }

    onSubmitReason = () => {

        const { t, item } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.Reason.value) {
            const postData = {
                Reason: this.Reason.value,
                SubscriptionId: item.SubscriptionId
            };
            this.props.stop(postData);
            this.handleClose();
            toast.dismiss(_toastId);

        } else {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.myBills.Reason.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    render() {
        const { t, item } = this.props;

        return (<>
            {
                this.state.showStop ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showStop}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-hand text-primary icon"></i> {t("Pages.myBills.action.stop")}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category mb-2">
                                <div className="widget-body">
                                    <div className="form-group w-100">
                                        <label className='text-dark font-size-h5'>
                                            {t('Pages.myBills.Reason.title')}
                                            <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i>
                                        </label>
                                        <div>
                                            <textarea
                                                ref={Reason => this.Reason = Reason}
                                                name="Reason"
                                                style={{
                                                    resize: "vertical"
                                                }}
                                                placeholder={t('Pages.myBills.Reason.Placeholder')}
                                                title={t('Pages.myBills.Reason.title')}
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group w-100 text-center ">
                                <button className="custom-button danger-button" onClick={() => this.onSubmitReason()}>
                                    <span>
                                        <i className="icon fal fa-hand"></i> {t("Pages.myBills.action.stop")}
                                    </span>
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    ""
            }
            <div className="col px-0 mx-0" key={item.Id}>
                <div className="product-list-item" >
                    {
                        item.IsActive ? "" :
                            <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.myBills.NotActive')}>
                                <i className="fas fa-lock"></i>
                            </div>
                    }
                    {
                        item.IsVisa ?
                            <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.myBills.IsVisa")}>
                                <i className="fab fa-cc-visa"></i>
                            </div> : ""

                    }
                    <div className="product-content py-3 px-5 w-100">
                        <h5 className="title">
                            {t('Pages.myBills.NatPrice')} :
                            {item.Price - item.Discount}
                            {
                                item.IsNIS ?
                                    <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i> :
                                    <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                            }
                        </h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="px-1 ml-auto">
                                <p>
                                    {
                                        item.Discount ?
                                            <>
                                                <span title={t('Pages.myBills.TotalPrice')}>
                                                    <i className="icon fal fa-money-bill text-danger"></i>
                                                    {t('Pages.myBills.TotalPrice')} :
                                                    {item.Price}
                                                    {
                                                        item.IsNIS ?
                                                            <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i> :
                                                            <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                                                    }
                                                </span>
                                                <span className="mx-2 h5">
                                                    -
                                                </span>
                                                <span title={t('Pages.myBills.Discount')}>
                                                    <i className="icon fal fa-percent text-danger"></i>
                                                    {t('Pages.myBills.Discount')} :
                                                    {item.Discount}
                                                    {
                                                        item.IsNIS ?
                                                            <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i> :
                                                            <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                                                    }
                                                </span>
                                            </>
                                            : ""
                                    }
                                </p>
                                <p>
                                    {
                                        item.Note ?
                                            <span title={t('Pages.myBills.Note')}>
                                                <i className="icon fal fa-quote-right"></i>
                                                {item.Note}
                                            </span>
                                            : ""
                                    }
                                </p>
                                <p>
                                    {
                                        item.ActivateUntilDate ?
                                            <span title={t('Pages.myBills.ActivateUntilDate')} className="ml-2">
                                                <i className="icon fal fa-calendar-alt"></i>
                                                {item.ActivateUntilDate}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Payment ?
                                            <span title={t('Pages.myBills.Payment')}>
                                                <i className="icon fal fa-file-invoice"></i>
                                                {item.Payment}
                                            </span>
                                            : ""
                                    }
                                </p>
                                <p>
                                    {
                                        item.CreditInfo ?
                                            <div>
                                                <div title={t('Pages.myBills.CreditInfo.Title')}>
                                                    <i className="icon fal fa-credit-card"></i>
                                                    <span>
                                                        {item.CreditInfo.Last4 + "*******" + item.CreditInfo.Bin}
                                                    </span>
                                                </div>
                                                <div title={t('Pages.myBills.CreditInfo.ExpirationDate')}>
                                                    <i className="icon fal fa-calendar"></i>
                                                    <span>
                                                        {item.CreditInfo.ExpirationDate}
                                                    </span>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </p>
                            </div>
                            <Link to="#" className="cate mr-1 btn btn-info" onClick={() => this.moreInfo()} title={t("Pages.myBills.action.info")}>
                                <i className="far fa-info"></i>
                            </Link>
                            {
                                item.InvoiceLink ?
                                    <Link to={{
                                        pathname: item.InvoiceLink
                                    }} target="_blank" className="cate mr-1 btn btn-primary" title={t("Pages.myBills.action.invoice")}>
                                        <i className="far fa-file-invoice"></i>
                                    </Link>
                                    : ""
                            }
                            {
                                item.IsActive && item.SubscriptionId && item.IsTrial && this.props.IsForTest ?
                                    <Link to="#" className="cate mr-1 btn btn-danger" onClick={() => this.showStop()} title={t("Pages.myBills.action.stop")}>
                                        <i className="far fa-hand"></i>
                                    </Link>
                                    : ""
                            }
                        </div>
                    </div>
                    {
                        this.state.moreInfo ?
                            <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                borderTop: '1px dashed #92278f'
                            }}>
                                <table className="table table-striped table-hover table-bordered">
                                    <thead>
                                        <tr className="text-center">
                                            <th>
                                                {t('Pages.myBills.Service')}
                                            </th>
                                            <th>
                                                {t('Pages.myBills.Price')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableBodyBillView()}
                                    </tbody>
                                </table>
                            </div> : ""
                    }
                </div>
            </div>
        </>);
    }
}


export default withTranslation('common')(BillItem);
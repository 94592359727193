import React from "react";

const StageName = (props) => {

    const { TryColor, label, TryColorCustom } = props;

    return <div className="position-absolute text-center display-1 h1 w-100 h-100 d-flex justify-content-center align-items-center flex-column game-font"
        style={{
            zIndex: '14',
        }}>
        <span className={`text-${TryColor === "custom" ? "" : TryColor} font-weight-bold`} style={{
            animation: `focus-in-expand ease forwards 3s normal`,
            filter: `
drop-shadow(1px 1px 0px #000)
drop-shadow(-1px -1px 0px #000)
drop-shadow(-1px 1px 0px #000)
drop-shadow(1px -1px 0px #000)`,
            color: TryColorCustom
        }}>  {label}  </span>
    </div >
}
export default StageName;
import React from "react";
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

import ClassRoomItem from "../../School/Missions/ClassRoomItem";
import api from '../../../api/api';
import { ShareMissionBySocket } from '../../../api/socket.io';

class SendMissionForm extends React.Component {

    state = {
        id: 0,
        date: "",
        selectedStudent: [],
        startDate: "",
        endDate: "",
        TimeHour: "",
        TimeMinutes: "",
        TimeType: 'continue',
        isFirst: true,
        GameSave: "default",
        GamePlay: "default"
    }

    constructor(props) {
        super(props);
        moment.locale('en');
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.item && state.isFirst) {
            state.Id = props.item.Id;
            state.selectedStudent = [];
            state.startDate = props.item.StartDate;
            state.startDate = props.item.StartDate;
            state.endDate = props.item.EndDate;
            state.TimeHour = props.item.CustomStyle && props.item.CustomStyle.Clock ? props.item.CustomStyle.Clock.TimeHour : "";
            state.TimeMinutes = props.item.CustomStyle && props.item.CustomStyle.Clock ? props.item.CustomStyle.Clock.TimeMinutes : "";
            state.TimeType = props.item.CustomStyle && props.item.CustomStyle.Clock ? props.item.CustomStyle.Clock.TimeType : "";
            state.GamePlay = props.item.CustomStyle ? props.item.CustomStyle.GamePlay : "";
            state.GameSave = props.item.CustomStyle ? props.item.CustomStyle.GameSave : "";
            props.item.Students.forEach(student => {
                state.selectedStudent.push(student.Id);
            });
            state.date = props.item.StartDate + " - " + props.item.EndDate;
            state.isFirst = false;
        }
        return state;
    }

    setSelect = selectedStudent => {
        let result = this.state.selectedStudent;
        selectedStudent.forEach(item => {
            if (!result.includes(item)) {
                result.push(item);
            }
        });
        this.setState({
            selectedStudent: result
        });
    }
    setDeselect = selectedStudent => {
        let result = this.state.selectedStudent;
        selectedStudent.forEach(item => {
            result = result.filter(function (ele) {
                return ele !== item;
            });
        });
        this.setState({
            selectedStudent: result
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY hh:mm A') + " - " + picker.endDate.format('DD/MM/YYYY hh:mm A'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY hh:mm A'),
            endDate: picker.endDate.format('DD/MM/YYYY hh:mm A')
        });
    }
    setTimeHour = TimeHour => {
        if (TimeHour >= 0 && TimeHour < 24) {
            this.setState({
                TimeHour
            });
        }
    }
    setTimeMinutes = TimeMinutes => {
        if (TimeMinutes >= 0 && TimeMinutes < 60) {
            this.setState({
                TimeMinutes
            });
        }
    }
    setTimeType = TimeType => {
        this.setState({
            TimeType
        });
    }

    setGamePlay = GamePlay => {
        this.setState({
            GamePlay
        });
    }
    setGameSave = GameSave => {
        this.setState({
            GameSave
        });
    }

    submit = () => {
        const { t, lesson } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const sum = lesson.isGame === true ? 1 : parseInt((this.state.TimeHour ? this.state.TimeHour : 0) + (this.state.TimeMinutes ? this.state.TimeMinutes : 0));

        let postData = {
            LessonId: lesson.Id,
            endDate: this.state.endDate,
            startDate: this.state.startDate,
            selectedStudent: this.state.selectedStudent
        };

        if (this.state.selectedStudent.length === 0) {
            toast.update(this._toastId.current, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-bug"></i>
                        {t('Pages.Missions.error_student_not_selected_toast.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Missions.error_student_not_selected_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!this.state.startDate || !this.state.endDate) {
            toast.update(this._toastId.current, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-bug"></i>
                        {t('Pages.Missions.error_date_toast.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Missions.error_date_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (this.state.TimeType === "stoped" && !sum) {
            toast.update(this._toastId.current, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-bug"></i>
                        {t('Pages.Missions.error_time_toast.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Missions.error_time_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            if (lesson.isGame === true) {
                postData = {
                    ...postData,
                    CustomStyle: {
                        GamePlay: this.state.GamePlay,
                        GameSave: this.state.GameSave,
                    }
                };
            } else {
                postData = {
                    ...postData,
                    CustomStyle: {
                        Clock: {
                            TimeHour: parseInt(this.state.TimeHour),
                            TimeMinutes: parseInt(this.state.TimeMinutes),
                            TimeType: this.state.TimeType
                        }
                    }
                };
            }


            if (this.state.Id) {
                api.put(`/Missions/${this.state.Id}`,
                    postData
                ).then(response => {

                    toast.update(this._toastId.current, {
                        render: () => <div>
                            <div className="h5">
                                <i className="icon fal fa-bullseye-arrow"></i>
                                {t('Pages.Missions.success_update_toast.Title')}
                            </div>
                            <div className="h6">
                                {t('Pages.Missions.success_update_toast.Body')}
                            </div>
                        </div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.props.Sync();
                    this.props.handleClose();
                    ShareMissionBySocket(postData.selectedStudent, {
                        ...lesson,
                        Id: this.state.Id
                    });

                }).catch(error => {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } else {

                api.post(`/Missions/`,
                    postData
                ).then(response => {

                    toast.update(this._toastId.current, {
                        render: () => <div>
                            <div className="h5">
                                <i className="icon fal fa-bullseye-arrow"></i>
                                {t('Pages.Missions.success_toast.Title')}
                            </div>
                            <div className="h6">
                                {t('Pages.Missions.success_toast.Body')}
                            </div>
                        </div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.props.handleClose();
                    ShareMissionBySocket(postData.selectedStudent, {
                        ...lesson,
                        Id: response.data.Id
                    });

                }).catch(error => {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    }

    render() {
        const { t, lesson, classRooms, user } = this.props;
        return <Modal
            centered
            key="ModalSendMission"
            size="lg"
            show={true}
            onHide={() => this.props.handleClose()}
            backdrop="static"
            keyboard={false}
            enforceFocus={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="icon fal fa-bullseye-arrow "></i>
                    {t('Pages.Lessons.action.sendMission')}   : <span className="text-info"> {lesson.Name}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center flex-column align-items-center ">

                    <div className="widget widget-category w-100">
                        <div className="widget-header">
                            <h5 className="title">
                                <i className="fal fa-tools "></i> {t('Pages.Missions.info')}
                            </h5>
                        </div>
                        <div className="widget-body contact-form">
                            <div className={`form-group ${lesson.isGame === true ? "w-100" : ""}`}>
                                <label className='text-dark font-size-h5'><i className="icon fas fa-calendar-week"></i>{t('Pages.Missions.Date.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                <DateRangePicker
                                    ref={Date => this.Date = Date}
                                    initialSettings={{
                                        opens: 'center',
                                        autoUpdateInput: false,
                                        buttonClasses: 'btn',
                                        cancelClass: "btn-danger",
                                        applyButtonClasses: "btn-primary",
                                        showDropdowns: true,
                                        timePicker: true,
                                        minDate: `${moment().format('DD/MM/YYYY')}`,
                                        minYear: 2021,
                                        locale: {
                                            format: 'DD/MM/YYYY hh:mm A',
                                            cancelLabel: t('Close'),
                                            firstDay: 6,
                                            applyLabel: t('Apply'),
                                            customRangeLabel: t('range.customRange'),
                                            monthNames: [
                                                `${t("months.January")}`,
                                                `${t("months.February")}`,
                                                `${t("months.March")}`,
                                                `${t("months.April")}`,
                                                `${t("months.May")}`,
                                                `${t("months.June")}`,
                                                `${t("months.July")}`,
                                                `${t("months.August")}`,
                                                `${t("months.September")}`,
                                                `${t("months.October")}`,
                                                `${t("months.November")}`,
                                                `${t("months.December")}`
                                            ],
                                            daysOfWeek: [
                                                t("daysOfWeek.Sunday"),
                                                t("daysOfWeek.Monday"),
                                                t("daysOfWeek.Tuesday"),
                                                t("daysOfWeek.Wednesday"),
                                                t("daysOfWeek.Thursday"),
                                                t("daysOfWeek.Friday"),
                                                t("daysOfWeek.Saturday")
                                            ],
                                        },
                                    }}
                                    onApply={this.handleApplyDate} >
                                    <input
                                        defaultValue={this.state.date}
                                        name="Date"
                                        readOnly={true}
                                        autoComplete="off"
                                        required
                                        type="text"
                                        placeholder={t('Pages.Missions.Date.Placeholder')}
                                        title={t('Pages.Missions.Date.title')}
                                    />
                                </DateRangePicker>
                            </div>

                            {
                                lesson.isGame === true ?
                                    <>
                                        <div className="form-group">
                                            <label className="text-dark h6"><i className="icon fas fa-sort-numeric-down"></i> {t('Pages.Missions.GamePlay.title')}  <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                            <select
                                                onChange={e => this.setGamePlay(e.target.value)}
                                                className="w-100"
                                                value={this.state.GamePlay}
                                                placeholder={t('Pages.Missions.GamePlay.placeholder')}
                                                title={t('Pages.Missions.GamePlay.title')}>
                                                <option value="default">{t('Pages.Missions.GamePlay.default')}</option>
                                                <option value="open">{t('Pages.Missions.GamePlay.open')}</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-dark h6"><i className="icon fas fa-hdd"></i> {t('Pages.Missions.GameSave.title')}  <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                            <select
                                                onChange={e => this.setGameSave(e.target.value)}
                                                className="w-100"
                                                value={this.state.GameSave}
                                                placeholder={t('Pages.Missions.GameSave.placeholder')}
                                                title={t('Pages.Missions.GameSave.title')}>
                                                <option value="default">{t('Pages.Missions.GameSave.default')}</option>
                                                <option value="last">{t('Pages.Missions.GameSave.last')}</option>
                                                <option value="medium">{t('Pages.Missions.GameSave.medium')}</option>
                                                <option value="hard">{t('Pages.Missions.GameSave.hard')}</option>
                                            </select>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="form-group">
                                            <label className="text-dark h6"><i className="icon fas fa-traffic-light-stop"></i> {t('Pages.Missions.Type.title')}  <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                            <select
                                                onChange={e => this.setTimeType(e.target.value)}
                                                className="w-100"
                                                value={this.state.TimeType}
                                                placeholder={t('Pages.Missions.Type.placeholder')}
                                                title={t('Pages.Missions.Type.title')}>
                                                <option value="continue">{t('Pages.Missions.Type.continue')}</option>
                                                <option value="stoped">{t('Pages.Missions.Type.stoped')}</option>
                                            </select>
                                        </div>

                                        <div className="form-group ">
                                            <label className="text-dark h6"><i className="icon fas fa-hourglass-start"></i> {t('Pages.Missions.TimeHour.title')} </label>
                                            <input
                                                className="w-100 form-control number"
                                                type="number"
                                                step="1"
                                                min="0"
                                                placeholder={t('Pages.Missions.TimeHour.placeholder')}
                                                title={t('Pages.Missions.TimeHour.title')}
                                                value={this.state.TimeHour}
                                                onChange={e => this.setTimeHour(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-dark h6"><i className="icon fas fa-hourglass-start"></i> {t('Pages.Missions.TimeMinutes.title')} </label>
                                            <input
                                                className="w-100 form-control number"
                                                type="number"
                                                step="1"
                                                min="0"
                                                placeholder={t('Pages.Missions.TimeMinutes.placeholder')}
                                                title={t('Pages.Missions.TimeMinutes.title')}
                                                value={this.state.TimeMinutes}
                                                onChange={e => this.setTimeMinutes(e.target.value)}
                                            />
                                        </div>
                                    </>
                            }

                        </div>
                    </div>

                    <div className="widget widget-category col-lg-8">
                        <div className="widget-header">
                            <h5 className="title">
                                <i className="fal fa-user-graduate "></i> {t('Pages.Missions.studentsInMission')}
                                {
                                    this.state.selectedStudent.length ?
                                        <span className="mx-1">
                                            (
                                            <span className="text-danger mx-1">
                                                {this.state.selectedStudent.length}
                                            </span>
                                            )
                                        </span>
                                        : ""
                                }
                            </h5>
                        </div>
                        <div className="widget-body">
                            <ul>
                                <div className="widget-body pl-0 pb-0" >
                                    {
                                        this.state.Id ?
                                            <>
                                                <div className={`contact-form p-3 w-100 mb-2 inner-box p-3 pt-4 text-center justify-content-center d-flex `} style={{
                                                    background: 'rgb(241 174 181)',
                                                    border: '1px solid rgb(220 53 69)',
                                                    borderRadius: '10px'
                                                }}  >
                                                    <div>
                                                        <div className=" h4 mb-3">
                                                            <i className="icon fal fa-engine-warning"></i>
                                                            {t('Pages.Missions.warning.title')}
                                                        </div>
                                                        <p className="h6">
                                                            {t('Pages.Missions.warning.body')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        classRooms.classRooms.filter(p => (p.Teacher && p.Teacher.Id === user.user.Id) || user.user.UserType.Id === 3).sort((a, b) => a.Name > b.Name ? 1 : -1).map(classRoom => {
                                            return <ClassRoomItem
                                                setSelect={this.setSelect}
                                                setDeselect={this.setDeselect}
                                                selectedStudent={this.state.selectedStudent}
                                                classRoom={classRoom}
                                                key={`className-${classRoom.Id}`} />
                                        })
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className="w-100 my-1">
                    <div className="d-flex justify-content-center align-items-center teacher-single-bottom ">
                        <button className="custom-button mt-1" onClick={() => this.submit()}  >
                            <span>
                                <i className={`icon fal fa-bullseye-arrow`}></i>
                                {
                                    this.props.item ?
                                        t('Pages.Missions.update')
                                        :
                                        t('Pages.Missions.submit')
                                }
                            </span>
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >

    }

}
export default withTranslation('common')(SendMissionForm);
import React, { useCallback, useEffect, useRef, useState } from "react";
import Spritesheet from 'react-responsive-spritesheet';
import TextBox from "../../TextBox";

import img from './images/1.png';

const width = 1829 / 15;
const height = 1000 / 4;

const Hunter = (props) => {

    const { status, ShootNumber } = props;
    const steps = 15;

    const spritesheeInstance = useRef(null);
    const [type, setType] = useState("Defult");
    const [shoot, setShoot] = useState(0);
    const [startAt, setStartAt] = useState(1);
    const [endAt, setEndAt] = useState(15);
    const [fps] = useState(20);

    const setDimensions = useCallback(newType => {

        let startAt = 1;
        let endAt = 15;
        if (newType === "Click") {
            startAt = 16;
            endAt = 30;
        } else if (newType === "True") {
            startAt = 31;
            endAt = 45;
        } else if (newType === "Wrong") {
            startAt = 46;
            endAt = 60;
        }

        setStartAt(startAt);
        setEndAt(endAt);

        if (spritesheeInstance && spritesheeInstance.current && (ShootNumber !== shoot || newType !== "Click")) {
            spritesheeInstance.current.setStartAt(startAt);
            spritesheeInstance.current.setEndAt(endAt);
            spritesheeInstance.current.goToAndPlay(startAt);
            setType(newType);
        }
    }, [ShootNumber, shoot]);

    useEffect(() => {
        if (status) {
            setDimensions(status);
        }
    }, [status, setDimensions]);

    return <div className="position-relative d-flex align-items-center justify-content-end flex-column flex-nowrap">
        <div className={`mb-1 d-inline-block`} >
            <div className={`${props.isSpin === "true" || props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${props.transformOriginX ? props.transformOriginX : "center"} ${props.transformOriginY ? props.transformOriginY : "center"}`,
                    transform: `
                        ${props.scale ? `scale(${props.scale})` : ""}
                        ${props.flipX === "true" || props.flipX === true ? "scaleX(-1)" : ""}
                        ${props.rotate ? `rotate(${props.rotate}deg)` : ""}
                        `
                }}>
                    <Spritesheet
                        image={img}
                        widthFrame={width}
                        heightFrame={height}
                        steps={steps}
                        startAt={startAt}
                        endAt={endAt}
                        fps={fps}
                        autoplay={true}
                        loop={true}
                        getInstance={spritesheet => {
                            spritesheeInstance.current = spritesheet;
                        }}
                        onLoopComplete={spritesheet => {
                            if (type === "Click") {
                                setShoot(ShootNumber);
                            }
                            if (type !== "Defult") {
                                props.FinishMove();
                                setDimensions("Defult");
                            }
                        }}
                    />
                </div>
            </div>
        </div>
        <TextBox {...props} />
    </div >
}

export default Hunter;
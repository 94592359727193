const lessonsReducers = (state = [], action) => {
    switch (action.type) {
        case "LESSONS_LOADING":
            return {
                lessons: state.lessons,
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: false,
                lazyLoading: true
            };
        case "LESSONS_RESET":
            return {
                lessons: [],
                current: null,
                toRemove: [],
                PageToRemove: [],
                QuestionToRemove: [],
                isLoaded: true,
                lazyLoading: true
            };
        case "LESSONS_INITIAL":
            return {
                lessons: action.lessons,
                current: null,
                toRemove: [],
                PageToRemove: [],
                QuestionToRemove: [],
                isLoaded: true
            };
        case "LESSONS_LAZY_LOADING_STOP":
            return {
                lessons: state.lessons,
                current: null,
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true,
                lazyLoading: false
            };
        case "LESSONS_LAZY_LOADING":
            return {
                lessons: state.lessons ? [...state.lessons, ...action.lessons] : action.lessons,
                current: null,
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true,
                lazyLoading: true
            };
        case "LESSON_INITIAL":
            return {
                lessons: state.lessons,
                code: action.code,
                current: { ...action.lesson, scores: [] },
                QuestionToRemove: [],
                toRemove: [],
                PageToRemove: [],
                isLoaded: true
            };
        case "GAME_SCORES_INITIAL":
            return {
                lessons: state.lessons,
                code: state.code,
                current: { ...state.current, scores: action.scores },
                QuestionToRemove: [],
                toRemove: [],
                PageToRemove: [],
                isLoaded: true
            };
        case "LESSONS_QUEUE_FOR_REMOVAL":
            return {
                lessons: state.lessons,
                toRemove: [...state.toRemove, action.id],
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_CLEAN_NODES":
            return {
                lessons: state.toRemove.some(v => v === action.id) ? state.lessons.filter(v => v.Id !== action.id) : state.lessons,
                toRemove: state.toRemove.filter(v => v !== action.id),
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_UNDO":
            return {
                lessons: state.lessons,
                toRemove: state.toRemove.filter(v => v !== action.id),
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_UPDATE_FAVORITE_BYTYPE":
            return {
                lessons: UpdateLessonFavoriteByType(state.lessons, action.typeToFacorite, action.id),
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_UPDATE_ISVIEW":
            return {
                lessons: UpdateLessonIsView(state.lessons, action.id),
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };

        case "LESSONS_UPDATE_FAVORITE":
            return {
                lessons: UpdateLessonFavorite(state.lessons, action.id),
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_UPDATE_SORT":
            return {
                lessons: UpdateSort(action.sort, state.lessons, action.id),
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_UPDATE_QUESTION":
            return {
                lessons: state.lessons,
                current: UpdateQuestion(action.question, state.current),
                QuestionToRemove: state.QuestionToRemove,
                toRemove: state.toRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_CREATE_QUESTION":
            return {
                lessons: state.lessons,
                current: CreateQuestion(action.question, state.current),
                QuestionToRemove: state.QuestionToRemove,
                toRemove: state.toRemove,
                PageToRemove: state.PageToRemove,
                isLoaded: true
            };
        case "LESSONS_PAGE_QUEUE_FOR_REMOVAL":
            return {
                lessons: state.lessons,
                current: state.current,
                PageToRemove: [...state.PageToRemove, action.id],
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                isLoaded: true
            };
        case "LESSONS_PAGE_UNDO":
            return {
                lessons: state.lessons,
                current: state.current,
                PageToRemove: state.PageToRemove.filter(v => v !== action.id),
                QuestionToRemove: state.QuestionToRemove,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "LESSON_PAGE_CLEAN_NODES":
            return {
                lessons: state.lessons,
                current: DeletePage(action.id, state.current),
                PageToRemove: state.PageToRemove.filter(v => v !== action.id),
                QuestionToRemove: state.QuestionToRemove,
                toRemove: state.toRemove,
                isLoaded: true
            };

        case "LESSONS_QUESTION_QUEUE_FOR_REMOVAL":
            return {
                lessons: state.lessons,
                current: state.current,
                QuestionToRemove: [...state.QuestionToRemove, action.id],
                PageToRemove: state.PageToRemove,
                toRemove: state.toRemove,
                isLoaded: true
            };

        case "LESSONS_QUESTION_UNDO":
            return {
                lessons: state.lessons,
                current: state.current,
                QuestionToRemove: state.QuestionToRemove.filter(v => v !== action.id),
                PageToRemove: state.PageToRemove,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "LESSON_QUESTION_CLEAN_NODES":
            return {
                lessons: state.lessons,
                current: DeleteQuestion(action.id, state.current),
                QuestionToRemove: state.QuestionToRemove.filter(v => v !== action.id),
                PageToRemove: state.PageToRemove,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "LESSON_QUESTION_SET_SORT":
            return {
                lessons: state.lessons,
                current: UpdateQuestionSort(action.id, action.sort, state.current),
                QuestionToRemove: state.QuestionToRemove.filter(v => v !== action.id),
                PageToRemove: state.PageToRemove,
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "LESSONS_UPDATE":
            return {
                // lessons: UpdateNode(action.newNode, state.lessons, action.id),
                current: UpdateLesson(action.newNode, state.current),
                PageToRemove: state.PageToRemove,
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                isLoaded: true
            };
        case "LESSONS_CREATE":
            return {
                //     lessons: CreateNewNode(action.newNode, state.lessons, action.id),
                PageToRemove: state.PageToRemove,
                toRemove: state.toRemove,
                QuestionToRemove: state.QuestionToRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

function UpdateLesson(newLesson, lesson) {
    let copy = lesson;
    copy = {
        ...copy,
        ...newLesson
    };
    return copy;
}

function DeletePage(id, lesson) {
    let copy = lesson;
    let copyLessonPage = [];
    copy.LessonPage.map(item => {
        if (item.Id !== id) {
            copyLessonPage.push(item);
        }
        return '';
    });
    copy.LessonPage = copyLessonPage;
    return copy;
}

function CreateQuestion(newQuestion, lesson) {
    let copy = lesson;
    let copyQuestion = [];
    copy.Questions.map(question => {
        copyQuestion.push(question);
        return '';
    });
    copyQuestion.push(newQuestion);
    copy.Questions = copyQuestion;
    return copy;
}

function UpdateQuestionSort(QuestionId, Sort, lesson) {
    let copy = lesson;
    let copyQuestion = [];
    copy.Questions.map(question => {
        if (question.Id === QuestionId) {
            question = {
                ...question,
                Sort
            };
        }
        copyQuestion.push(question);
        return '';
    });
    copy.Questions = copyQuestion;
    return copy;
}

function DeleteQuestion(id, lesson) {
    let copy = lesson;
    let copyQuestion = [];
    copy.Questions.map(question => {
        if (question.Id !== id) {
            copyQuestion.push(question);
        }
        return '';
    });
    copy.Questions = copyQuestion;
    return copy;
}

function UpdateQuestion(newQuestion, lesson) {
    let copy = lesson;
    let copyQuestion = [];
    copy.Questions.map(question => {
        if (question.Id === newQuestion.Id) {
            question = {
                ...question,
                ...newQuestion
            };
        }
        copyQuestion.push(question);
        return '';
    });
    copy.Questions = copyQuestion;
    return copy;
}

function UpdateSort(sort, lessons, id) {
    let copy = [];
    lessons.map(lesson => {
        if (lesson.Id === id) {
            lesson = {
                ...lesson,
                Sort: sort
            };
        }
        copy.push({
            ...lesson
        });
        return '';
    });
    return copy;
}

function UpdateLessonIsView(lessons, id) {
    let copy = [];
    lessons.map(lesson => {
        if (lesson.Id === id) {
            lesson = {
                ...lesson,
                IsView: true
            };
        }
        copy.push({
            ...lesson
        });
        return '';
    });
    return copy;
}

function UpdateLessonFavorite(lessons, id) {
    let copy = [];
    lessons.map(lesson => {
        if (lesson.Id === id) {
            lesson = {
                ...lesson,
                isFavorite: !lesson.isFavorite
            };
        }
        copy.push({
            ...lesson
        });
        return '';
    });
    return copy;
}

function UpdateLessonFavoriteByType(items, type, id) {
    let copy = [];

    let lessons = items;
    if (type === "Lessons") {
        lessons = items.Lessons;
    } else if (type === "Exams") {
        lessons = items.Exams;
    } else if (type === "Quizzes") {
        lessons = items.Quizzes;
    } else if (type === "Files") {
        lessons = items.Files;
    } else if (type === "Games") {
        lessons = items.Games;
    }

    lessons.map(lesson => {
        if (lesson.Id === id) {
            lesson = {
                ...lesson,
                isFavorite: !lesson.isFavorite
            };
        }
        copy.push({
            ...lesson
        });
        return '';
    });

    if (type === "Lessons") {
        lessons = {
            ...items,
            Lessons: copy
        };
    } else if (type === "Exams") {
        lessons = {
            ...items,
            Exams: copy
        };
    } else if (type === "Quizzes") {
        lessons = {
            ...items,
            Quizzes: copy
        };
    } else if (type === "Files") {
        lessons = {
            ...items,
            Files: copy
        };
    } else if (type === "Games") {
        lessons = {
            ...items,
            Games: copy
        };
    }

    return lessons;
}

export default lessonsReducers;
import React from "react";
import GameObjects from "../../GameObjects";
import Backgrounds from "../index";

class ReadGroup extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/ReadGroup/bg.png?v=2"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud2" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(55% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>
            <div className="position-absolute user-select-none" style={{
                left: '1%',
                bottom: '0%'
            }}><GameObjects objectCss="GirlWithBook" status={this.props.currentStatus} FinishMove={this.FinishMove} scale={0.7} /></div>

            <Backgrounds CustomBackground="FlyingBirdBackground" />


            <div className="position-absolute user-select-none" style={{
                left: 'calc(50% - 198px)',
                bottom: '2%'
            }}><GameObjects objectCss="ReadGroupWithTeacher" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default ReadGroup;
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { fetchMission } from '../../School/Student/actions';
import ExamView from "./ExamView/";
import GameView from "./GameView/";


class MissionView extends React.Component {

    state = { id: '0' };

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            Id: id
        });
        if (id !== '0') {
            this.props.fetchMission(id);
        }
    }

    render() {
        const { lessons } = this.props;
        if (lessons && lessons.current && lessons.current.isGame) {
            return <GameView lessons={lessons} isMission={true} MissionId={this.state.Id} />;
        }
        return <ExamView isMission={true} Id={this.state.Id} MissionId={this.state.Id} lessons={lessons} />
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchMission })(withTranslation('common')(MissionView));

import React from "react";

import MoveObject from "./MoveObject";
import roundsData from "../../../../Config/rounds.json";
import GameObjects from "../../../Question/View/GameObjects";
import ShootObject from "./ShootObject";
import FallDownMoveObject from "./VerticalMove";
import PopUps from "../PopUps";
import HorizontalMove from "../DropVertical/HorizontalMove";

class ShootingFallDown extends React.Component {

    constructor(props) {
        super(props);
        this.selectedCount = 0;
        this.selectableCount = 0;
        this.stageCount = 0;
        this.randomKey = Math.floor(Math.random() * 100000);
        this.state = {
            randomKey: this.randomKey,
            showItems: [],
            userAnswers: [],
            shootList: [],
            ShootNumber: 0,
            currentStageIndex: 0,
            currentCategoryId: 0,
            currentStatus: "",
            removeState: false,
            position: {
                x: 0,
                y: 0
            },
            BounsTry: 0,
            BounsTime: 0,
            showBounsTime: false,
            showBounsTry: false,
            clickNumber: this.props.clickNumber,
            handleShoot: this.handleShoot.bind(this),
            stageName: {}
        };
        this.Rounds = roundsData.Rounds;
        this.audio = new Audio();
        this.designerContainer = React.createRef();
        this.RocketLauncher = React.createRef();
        this.boundingClientRect = null;
        this.randomList = [];
        this.randomListTop = [];
    }

    componentWillUnmount() {
        this.randomKey = Math.floor(Math.random() * 100000);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.clickNumber !== state.clickNumber) {
            state.clickNumber = props.clickNumber;
            state.handleShoot(props.cursorClickPosition);
        }
        return state;
    }

    componentDidMount() {
        this.stageCount = this.props.Question.Answers.length;
        this.nextStage();
    }

    playMoveSound = MoveSound => {
        if (MoveSound) {
            if (this.audio && !this.audio.paused === false) {
                this.audio.pause();
            }
            this.audio = new Audio(MoveSound);
            this.audio.play();
        }
    }

    nextStage = () => {
        if (this.props.Question.Answers) {
            var firstAnswer = this.props.Question.Answers.sort((a, b) => a.Sort - b.Sort)[this.state.currentStageIndex];
            if (firstAnswer) {
                if (this.state.currentStageIndex > 0) {
                    this.props.playStageSound();
                }
                this.setState({
                    removeState: true,
                    currentStageIndex: this.state.currentStageIndex + 1,
                    stageName: this.Rounds[this.stageCount === (this.state.currentStageIndex + 1) ? 0 : (this.state.currentStageIndex + 1)],
                    showItems: this.setItemToShow(firstAnswer.Id)
                });
            } else if (this.randomKey === this.state.randomKey) {
                this.props.showEndGame();
            }
        }
    };

    getRandomNumber = (randomList = this.randomList, max = 50, depth = 0) => {
        let number = Math.floor(Math.random() * max);
        if (depth === 10) {
            return number;
        }
        var inRange = randomList.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * max);
            inRange = randomList.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (randomList.length > 0) {
            const lastItem = randomList[randomList.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (randomList.length > 1) {
                const beforeItem = randomList[randomList.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
            if (number > max) {
                number = this.getRandomNumber(randomList, max, depth + 1);
            }
        }
        return number;
    }

    setItemToShow = currentId => {
        const { Question } = this.props;
        const answer = Question.Answers.filter(p => p.Id === currentId)[0];
        const html = answer.Name;
        const CustomStyle = answer.CustomStyle;
        let items = [];

        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerStyle.BorderRadiusTop}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusRight}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusBottom}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusLeft}${Question.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerStyle.ShadowPositionX}px ${Question.CustomStyle.answerStyle.ShadowPositionY}px ${Question.CustomStyle.answerStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerStyle.BgAttachment}`,
            zIndex: 1
        };

        const count = (html.match(/\(*_\)/g) || []).length;

        let fromRight = false;
        this.randomList = [];
        for (let index = 0; index < count; index++) {
            this.randomList.push(this.getRandomNumber(this.randomList, 100));
        }

        this.randomListTop = [];
        for (let index = 0; index < count; index++) {
            this.randomListTop.push(this.getRandomNumber(this.randomListTop, 40));
        }

        const splitAnswer = html.split('(*_)');
        for (let index = 0; index < count; index++) {
            let text = splitAnswer[index + 1] ? splitAnswer[index + 1].split('(_*)')[0] : "";
            if (CustomStyle && CustomStyle.Answers && CustomStyle.Movments) {
                const param = CustomStyle.Answers.filter(p => p.Id === currentId && p.Sort === index)[0];
                const Movments = CustomStyle.Movments.filter(p => p.Id === currentId && p.Sort === index);
                if (param) {
                    let customColor = param.CustomColor;
                    let objectCss = param.AnswersSelectObject;
                    let ObjectSrc = param.AnswersSelectObjectSrc;
                    let ObjectHoverSrc = param.AnswersSelectObjectSrcHover;
                    if (objectCss === "") {
                        objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                        ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                        ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                        if (!customColor) {
                            customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                        }
                    }
                    if (param.AnswersSelectType !== "radio" && /\d/.test(param.AnswersSelectType) && param.AnswersSelectType !== "0") {
                        this.selectableCount++;
                    }

                    objectCss = this.props.getRandomObject(objectCss);

                    var ref = React.createRef();
                    if (param.AnswersMoveType === "fall") {
                        const left = this.randomList[index];
                        items.push({
                            Id: param.Sort,
                            customColor,
                            key: `objectId-${currentId}-${param.Id}-${param.Sort}`,
                            jsx: <FallDownMoveObject
                                playMoveSound={this.playMoveSound}
                                designerContainer={this.props.designerContainer}
                                RocketLauncher={this.RocketLauncher}
                                item={answer}
                                id={param.Id}
                                left={left}
                                delay={param.delay}
                                rotate={param.rotate}
                                isSpin={param.isSpin}
                                flipX={param.flipX}
                                scale={param.scale}
                                transformOriginX={param.transformOriginX}
                                transformOriginY={param.transformOriginY}
                                randomKey={this.randomKey}
                                ref={ref}
                                key={`objectId-${param.Id}-${param.Sort}-${currentId}`}
                                param={param}
                                index={param.Sort}
                                style={styleAnswer}
                                ObjectSrc={ObjectSrc}
                                customColor={customColor}
                                ObjectHoverSrc={ObjectHoverSrc}
                                MoveType={param.AnswersMoveType}
                                Movments={Movments}
                                handelDropObject={this.handelDropObject}
                                Time={param.AnswersTime}
                                objectCss={objectCss}
                                text={text} />
                        });
                    } else if (param.AnswersMoveType === "horizontal") {

                        fromRight = !fromRight;
                        const top = this.randomListTop[index];
                        const flipX = param.flipX === true || param.flipX === "true" ? true : false;
                        items.push({
                            Id: param.Sort,
                            customColor,
                            key: `objectId-${currentId}-${param.Id}-${param.Sort}`,
                            jsx: <HorizontalMove
                                refList={this.refList}
                                playMoveSound={this.playMoveSound}
                                designerContainer={this.props.designerContainer}
                                item={answer}
                                id={param.Id}
                                top={top}
                                noDrop={true}
                                rotate={param.rotate}
                                isSpin={param.isSpin}
                                delay={param.delay}
                                flipX={flipX}
                                scale={param.scale}
                                transformOriginX={param.transformOriginX}
                                transformOriginY={param.transformOriginY}
                                randomKey={this.randomKey}
                                ref={ref}
                                fromRight={fromRight}
                                key={`objectId-${param.Id}-${param.Sort}-${currentId}`}
                                param={param}
                                index={param.Sort}
                                style={styleAnswer}
                                ObjectSrc={ObjectSrc}
                                customColor={customColor}
                                ObjectHoverSrc={ObjectHoverSrc}
                                MoveType={param.AnswersMoveType}
                                Movments={Movments}
                                handleDropObject={this.handelDropObject}
                                Time={param.AnswersTime}
                                objectCss={objectCss}
                                text={text} />
                        });
                    } else {
                        items.push({
                            Id: param.Sort,
                            customColor,
                            key: `objectId-${currentId}-${param.Id}-${param.Sort}`,
                            jsx: <MoveObject
                                getRandomNumber={this.props.getRandomNumber}
                                count={count}
                                playMoveSound={this.playMoveSound}
                                designerContainer={this.props.designerContainer}
                                RocketLauncher={this.RocketLauncher}
                                item={answer}
                                id={param.Id}
                                ref={ref}
                                rotate={param.rotate}
                                isSpin={param.isSpin}
                                delay={param.delay}
                                flipX={param.flipX}
                                scale={param.scale}
                                transformOriginX={param.transformOriginX}
                                transformOriginY={param.transformOriginY}
                                randomKey={this.randomKey}
                                ExplosionType="RocketExplosion"
                                key={`objectId-${param.Id}-${param.Sort}-${currentId}`}
                                param={param}
                                index={param.Sort}
                                style={styleAnswer}
                                ObjectSrc={ObjectSrc}
                                customColor={customColor}
                                ObjectHoverSrc={ObjectHoverSrc}
                                MoveType={param.AnswersMoveType}
                                Movments={Movments}
                                Time={param.AnswersTime}
                                objectCss={objectCss}
                                text={text} />
                        });
                    }

                }
            }
        }
        return items;
    };

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    handleShoot = position => {
        let shootList = this.state.shootList;
        if (this.state.currentStageIndex > shootList.length) {
            const ExplosionObject = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.ExplosionObject : "";
            let { objectCss, ObjectSrc, MoveSound, isSpan } = this.props.getExplosionObject(ExplosionObject);

            const ShootObjectSrc = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.ShootObjectSrc : "";
            if (ShootObjectSrc) {
                objectCss = "custom";
                ObjectSrc = ShootObjectSrc;
            }
            const ShootObjectSound = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.ShootObjectSound : "";
            if (ShootObjectSound) {
                MoveSound = ShootObjectSound;
            }
            shootList.push({
                Id: this.state.ShootNumber + 1,
                jsx: <ShootObject
                    Time={0.5}
                    scale={this.props.scale}
                    ExplosionType={ExplosionObject}
                    ExplosionObject={ExplosionObject}
                    objectCss={objectCss}
                    ObjectSrc={ObjectSrc}
                    MoveSound={MoveSound}
                    left={position.left}
                    isSpan={isSpan}
                    isPlayVolumeSound={this.props.isPlayVolumeSound}
                    items={this.state.showItems}
                    Id={this.state.ShootNumber + 1}
                    handleRemoveShoot={this.handleRemoveShoot}
                    designerContainer={this.props.designerContainer}
                />
            });
            this.setState({
                shootList,
                currentStatus: "Click",
                ShootNumber: this.state.ShootNumber + 1
            });
        }
    }

    removeBounsMove = () => {
        setTimeout(() => {
            this.setState({
                BounsTry: 0,
                BounsTime: 0,
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
        }, 4 * 1000);
    }

    handelDropObject = (dropItem, isIn) => {
        this.handleRemoveShoot(0, dropItem, isIn);
    }

    handleRemoveShoot = (Id, dropItem, isIn) => {
        let shootList = this.state.shootList;
        shootList = shootList.filter(p => p.Id !== Id);

        if (dropItem) {
            let showItems = this.state.showItems.filter(p => p.Id !== dropItem.Sort);
            const hasAnswer = parseInt(dropItem.AnswersSelectType);

            if (this.props.isMission === true && ((!hasAnswer && isIn) || (hasAnswer && !isIn) || (hasAnswer && isIn))) {
                let userAnswers = this.state.userAnswers.slice();
                userAnswers.push({
                    Answer: dropItem,
                    isIn: isIn,
                    isTrue: hasAnswer ? true : false
                });
                this.setState({
                    userAnswers
                });
                this.props.saveAnswer(userAnswers);
            }

            if (hasAnswer && isIn) {
                if (parseInt(dropItem.BounsTry) > 0 && parseInt(dropItem.BounsTime) > 0) {
                    this.setState({
                        removeState: false,
                        BounsTry: dropItem.BounsTry,
                        BounsTime: dropItem.BounsTime,
                        showBounsTime: true,
                        showBounsTry: true,
                        currentStatus: "True",
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addBounsTimeAndTry(parseInt(dropItem.BounsTime), parseInt(dropItem.BounsTry), true);
                    this.removeBounsMove();
                } else if (parseInt(dropItem.BounsTry) > 0) {
                    this.setState({
                        removeState: false,
                        BounsTry: dropItem.BounsTry,
                        showBounsTry: true,
                        BounsTime: 0,
                        showBounsTime: false,
                        currentStatus: "True",
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addBounsTry(parseInt(dropItem.BounsTry), true);
                    this.removeBounsMove();
                } else if (parseInt(dropItem.BounsTime) > 0) {
                    this.setState({
                        removeState: false,
                        BounsTime: dropItem.BounsTime,
                        showBounsTime: true,
                        currentStatus: "True",
                        BounsTry: 0,
                        showBounsTry: false,
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addBounsTime(parseInt(dropItem.BounsTime), true);
                    this.removeBounsMove();
                } else {
                    this.setState({
                        removeState: false,
                        BounsTime: 0,
                        showBounsTime: false,
                        currentStatus: "True",
                        BounsTry: 0,
                        showBounsTry: false,
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addTrueTry(true);
                }
            } else if ((!hasAnswer && isIn) || (hasAnswer && !isIn)) {
                this.setState({
                    BounsTry: 0,
                    BounsTime: 0,
                    showBounsTime: false,
                    removeState: false,
                    showBounsTry: false,
                    currentStatus: "Wrong",
                    showItems
                });
                this.props.addFalseTry(true);
            }



            if (hasAnswer > 0) {
                this.selectedCount++;
            }
            if (this.selectedCount === this.selectableCount) {
                this.setState({
                    shootList,
                    removeState: false,
                    showBounsTime: false,
                    showBounsTry: false
                });
                this.selectedCount = 0;
                this.selectableCount = 0;
                this.nextStage();
            }
        }
        this.setState({
            shootList
        });
    }

    render() {
        const { Question } = this.props;

        const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
        const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";
        const objectCss = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObject : "";
        const ObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrc : "";
        const ObjectHoverSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const customColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropCustomColor : "";

        let objectWidth = 0;
        let position = {
            x: 0,
            y: 0
        };
        if (this.RocketLauncher && this.RocketLauncher.current) {
            const RocketLauncher = this.RocketLauncher.current.getBoundingClientRect();
            objectWidth = (RocketLauncher.width / 2);
            position.x = RocketLauncher.x + objectWidth;
            position.y = RocketLauncher.y;
        }
        return <div className="w-100 h-100 position-absolute oh" id={`Question-${Question.Id}`}   >
            <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${Question.CustomStyle.answerStyle.Custom}} .dragdrop-static-box{${Question.CustomStyle.answerBoxStyle.Custom}}` }}></style>
            <PopUps
                removeState={this.state.removeState}
                showBounsTry={this.state.showBounsTry}
                showBounsTime={this.state.showBounsTime}
                bounsTry={this.state.BounsTry}
                bounsTime={this.state.BounsTime}
                stageCount={this.stageCount}
                TryColorCustom={TryColorCustom}
                TryColor={TryColor}
                label={this.state.stageName.label}
                position={position}
            />
            {
                this.state.showItems ?
                    this.state.showItems.map(item => {
                        return <span id={`moveObject-${Question.Id}-${item.key}`} color={`${item.customColor}`} key={`${Question.Id}-${this.state.currentStageIndex}-${item.Id}-${item.key}`}>{item.jsx}</span>
                    }) : ""
            }
            {
                this.state.shootList ?
                    this.state.shootList.map(item => {
                        return <span key={`${this.state.currentStageIndex}-${item.Id}`}>{item.jsx}</span>
                    }) : ""
            }
            <div className="position-absolute"
                style={{
                    zIndex: '15',
                    bottom: '0',
                    touchAction: 'none',
                    transform: `scale(${this.props.scale})`,
                    left: `${this.props.cursorPosition.left - objectWidth}px`
                }}>
                <div ref={this.RocketLauncher}>
                    <GameObjects
                        {...this.props}
                        ShootNumber={this.state.ShootNumber}
                        FinishMove={this.FinishMove}
                        status={this.state.currentStatus}
                        objectCss={objectCss}
                        ObjectSrc={ObjectSrc}
                        customColor={customColor}
                        ObjectHoverSrc={ObjectHoverSrc}
                        type="dragdrop-move" />
                </div>
            </div>
        </div>;
    }
}

export default ShootingFallDown;
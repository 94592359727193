import React from "react";
import GameObjects from "../../GameObjects";

class ClassRoom extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/ClassRoom/bg.png?v=2"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute " style={{
                bottom: `25%`,
                right: `35%`
            }}>
                <GameObjects objectCss="GirlTeacher" scale={0.7} transformOriginY="bottom" transformOriginX="right" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `0%`,
                left: `1%`
            }}>
                <GameObjects objectCss="GirlUseComputer" scale={0.65} transformOriginY="bottom" transformOriginX="left" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute " style={{
                bottom: `0%`,
                right: `1%`
            }}>
                <GameObjects objectCss="BoyUseComputer" scale={0.65} transformOriginY="bottom" transformOriginX="right" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

        </div>
    }
}
export default ClassRoom;
import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

class FooterBottom extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="footer-bottom">
                <div className="container">
                    <p>
                        <Link to="#">
                            {new Date().getFullYear()}
                        </Link>
                            &nbsp; &copy; {t('Footer.CopyRights')} &nbsp;
                            <Link to="/"> {t('alarabeyya')}  </Link>
                        <br />
                        <Link to="#">
                            {new Date().getFullYear()}
                        </Link>
                            &nbsp; &copy; &nbsp;
                            {t('Footer.EnglishCopyRights')} &nbsp;
                            <Link to="/"> {t('Footer.EnglishSite')}  </Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(FooterBottom);
import React from "react";
import { withTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import TreeView from "../../Indices/TreeView";
import { getIndeices, setIndeices } from "../../../../context/sessionStorage";
import { fetchIndices } from '../../Indices/actions/';

class InfoForm extends React.Component {

    state = {
        IndexId: 0, indices: [], isLoaded: false
    };

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });

    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isLoaded) {
            state.IndexId = props.IndexId;
            state.isLoaded = true;
        }
        return state;
    }

    setIsPublic = value => {
        this.props.setIsPublic(value);
    }
    setIsForTeacher = value => {
        this.props.setIsForTeacher(value);
    }
    setIcon = value => {
        this.props.setIcon(value);
    }
    setBio = value => {
        this.props.setBio(value);
    }
    setName = value => {
        this.props.setName(value);
    }
    setUrl = value => {
        this.props.setUrl(value);
    }

    validateName = value => {
        const { t } = this.props;
        if (!value) {
            return t('Pages.Files.forms.FilesInfo.Name.placeholder');
        }
    }

    validateUrl = value => {
        const { t } = this.props;
        if (!value) {
            return t('Pages.Files.forms.FilesInfo.Url.placeholder');
        }
    }

    setCurrentIndexItem = item => {
        this.setState({ IndexId: item.id });

        this.props.setIndexId(item.id);
    }


    setWidth = value => {
        this.setState({ width: value });
        if (value >= 1 && value <= 100) {
            this.props.setWidth(value);
        } else if (value > 100) {
            this.setState({ width: 100 });
            this.props.setWidth(100);
        }
    }

    renderField = ({
        input,
        title,
        type,
        defaultValue,
        id,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} id={id} value={defaultValue} title={title} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );

    render() {
        const { t } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.props.onSubmit}>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Name"> <i className="icon fas fa-heading"></i> {t('Pages.Files.forms.FilesInfo.Name.title')}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            id="Name"
                            name="Name"
                            defaultValue={this.props.Name}
                            component={this.renderField}
                            type="text"
                            required={true}
                            validate={this.validateName}
                            onChange={e => this.setName(e.target.value)}
                            placeholder={t('Pages.Files.forms.FilesInfo.Name.placeholder')}
                            title={t('Pages.Files.forms.FilesInfo.Name.placeholder')}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Bio"> <i className="icon fas fa-feather-alt"></i> {t('Pages.Files.forms.FilesInfo.Bio.title')}</label>
                        <Field
                            ref={Bio => this.Bio = Bio}
                            id="Bio"
                            name="Bio"
                            defaultValue={this.props.Bio}
                            component={this.renderField}
                            onChange={e => this.setBio(e.target.value)}
                            type="text"
                            placeholder={t('Pages.Files.forms.FilesInfo.Bio.placeholder')}
                            title={t('Pages.Files.forms.FilesInfo.Bio.placeholder')}
                            className="form-control"
                        />
                    </div>

                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Url"> <i className="icon fas fa-image"></i> {t('Pages.Files.forms.FilesInfo.Url.title')}</label>
                        <Field
                            ref={Url => this.Url = Url}
                            id="Url"
                            name="Url"
                            defaultValue={this.props.Url}
                            validate={this.validateUrl}
                            component={this.renderField}
                            onChange={e => this.setUrl(e.target.value)}
                            type="url"
                            placeholder={t('Pages.Files.forms.FilesInfo.Url.placeholder')}
                            title={t('Pages.Files.forms.FilesInfo.Url.placeholder')}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Icon"> <i className="icon fas fa-image"></i> {t('Pages.Files.forms.FilesInfo.Icon.title')}</label>
                        <Field
                            ref={Icon => this.Icon = Icon}
                            id="Icon"
                            name="Icon"
                            defaultValue={this.props.Icon}
                            component={this.renderField}
                            onChange={e => this.setIcon(e.target.value)}
                            type="url"
                            placeholder={t('Pages.Files.forms.FilesInfo.Icon.placeholder')}
                            title={t('Pages.Files.forms.FilesInfo.Icon.placeholder')}
                            className="form-control"
                        />
                    </div>

                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="IsForTeacher"> <i className="icon fas fa-lock-alt"></i> {t('Pages.Files.forms.FilesInfo.IsForTeacher.title')}</label>
                        <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-success">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={e => this.setIsForTeacher(e.target.checked)}
                                ref={IsForTeacher => this.IsForTeacher = IsForTeacher}
                                defaultChecked={this.props.IsForTeacher}
                                name="IsForTeacher"
                                id="IsForTeacher" />
                            <label className="custom-control-label" htmlFor="IsForTeacher" >
                                {t('Pages.Files.forms.FilesInfo.IsForTeacher.placeholder')}
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="IsPublic"> <i className="icon far fa-globe"></i>{t('Pages.Files.forms.FilesInfo.IsPublic.title')}</label>
                        <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-danger">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={e => this.setIsPublic(e.target.checked)}
                                ref={IsPublic => this.IsPublic = IsPublic}
                                defaultChecked={this.props.IsPublic}
                                name="IsPublic"
                                id="IsPublic" />
                            <label className="custom-control-label" htmlFor="IsPublic" >
                                {t('Pages.Files.forms.FilesInfo.IsPublic.placeholder')}
                            </label>
                        </div>
                    </div>

                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group col-sm-12 col-md-11  col-lg-10 col-xl-10 col-xxl-8 mx-auto">
                        {indices ?
                            <TreeView
                                currentId={this.state.IndexId}
                                data={indices.nodes}
                                type='Files'
                                title={t('Pages.Files.forms.FilesInfo.Index.placeholder')}
                                toRemove={indices.toRemove}
                                setCurrentItem={this.setCurrentIndexItem}></TreeView>
                            : ""
                        }
                    </div>
                </form>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, { fetchIndices })(withTranslation('common')(reduxForm({
    form: 'Contact'
})(InfoForm)));

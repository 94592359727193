import React from "react";
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class DragDrop extends React.Component {

    state = { items: [] };
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.items) {
            state.items = props.items;
        }
        return state;
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });

        this.props.setSort(items);
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    getListStyle = isDraggingOver => ({
        background: !isDraggingOver ? "rgb(246 250 249)" : "#fff",
        padding: 10,
        border: ' 5px dashed rgb(255, 255, 255)'
    });

    getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 16,
        margin: `0 0 ${8}px 0`,
        position: 'static !important',
        // change background colour if dragging
        borderBottom: isDragging ? "2px dashed lightgreen" : "2px solid rgb(14, 44, 83)",
    });

    render() {
        const { t } = this.props;

        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd} className="position-relative align-self-cente">
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={this.getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div className="d-flex justify-content-between">
                                                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                    <div className="d-flex " >
                                                        <button type="button" className="btn btn-sm m-1 btn-success" style={{
                                                            height: '35px',
                                                            width: '35px'
                                                        }} onClick={() => this.props.EditItem(item)}
                                                            title={t('Pages.Lessons.Questions.forms.Edit')}>
                                                            <i className=" fal fa-edit"></i>
                                                        </button>
                                                        {
                                                            (this.props.moreSetting === true) ?
                                                                <button type="button" className="btn btn-sm m-1 btn-info" style={{
                                                                    height: '35px',
                                                                    width: '35px'
                                                                }} onClick={() => this.props.EditStyle(item)}
                                                                    title={this.props.settingTitle}>
                                                                    <i className=" fal fa-cogs"></i>
                                                                </button> : ""
                                                        }
                                                        <button type="button" className="btn btn-sm m-1 btn-danger" style={{
                                                            height: '35px',
                                                            width: '35px'
                                                        }} onClick={() => this.props.DeleteItem(item.id)}
                                                            title={t('Pages.Lessons.Questions.forms.Delete')}>
                                                            <i className=" fal fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        );
    }
}
export default withTranslation('common')(DragDrop);
const RanksReducers = (state = [], action) => {
    switch (action.type) {
        case "RANKS_LOADING":
            return {
                ranks: [],
                isLoaded: false
            };
        case "RANKS_INITIAL":
            return {
                ranks: action.ranks,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default RanksReducers;
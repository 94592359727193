import React from "react";
import GameObjects from "../../GameObjects";

class FooterBg extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/FooterBg/bg.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: '20%',
                right: `10%`
            }}>
                <div className="position-relative d-flex justify-content-center ">
                    <div className="position-absolute">
                        <GameObjects objectCss="WaterCircles" />
                    </div>
                    <div className="position-absolute" style={{
                        top: '-80px',
                        marginLeft: '-20px'
                    }}>
                        <GameObjects objectCss="DiveFish" scale={0.6} transformOriginY={"bottom"} status={this.props.currentStatus} FinishMove={this.FinishMove} />
                    </div>
                </div>
            </div>


            <div className="position-absolute " style={{
                bottom: '1%',
                left: `2%`
            }}>
                <GameObjects objectCss="BoyDiving" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

        </div >
    }
}
export default FooterBg;
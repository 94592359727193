
import React from "react";

import roundsData from "../../../../Config/rounds.json";
import PopUps from "../PopUps";
import HorizontalMove from "../DropVertical/HorizontalMove";
import GameObjects from "../../../Question/View/GameObjects";

class DropMultiSelect extends React.Component {

    constructor(props) {
        super(props);
        this.selectedCount = 0;
        this.selectableCount = 0;
        this.stageCount = 0;
        this.randomKey = Math.floor(Math.random() * 100000);
        this.state = {
            userAnswers: [],
            showItems: [],
            randomKey: this.randomKey,
            currentStageIndex: 0,
            currentStatus: "",
            currentCategory: {},
            removeState: false,
            position: {
                x: 0,
                y: 0
            },
            BounsTry: 0,
            BounsTime: 0,
            left: 0,
            top: 0,
            showBounsTime: false,
            showBounsTry: false,
            stageName: {}
        };
        this.Rounds = roundsData.Rounds;
        this.audio = new Audio();
        this.refTop = React.createRef();
        this.randomList = [];
        this.refList = [];
        this.refDrop = React.createRef();
    }

    componentWillUnmount() {
        this.randomKey = Math.floor(Math.random() * 100000);
    }

    componentDidMount() {
        this.stageCount = this.props.Question.Categories.length;
        this.nextStage();
        this.refList.push({
            Id: 1,
            item: {
                Id: 1,
            },
            ref: this.refDrop
        });
    }

    playMoveSound = MoveSound => {
        if (MoveSound) {
            if (this.audio && !this.audio.paused === false) {
                this.audio.pause();
            }
            this.audio = new Audio(MoveSound);
            this.audio.play();
        }
    }

    nextStage = () => {
        if (this.props.Question.Categories) {
            var currentCategory = this.props.Question.Categories.sort((a, b) => a.Sort - b.Sort)[this.state.currentStageIndex];
            if (currentCategory) {
                if (this.state.currentStageIndex > 0) {
                    this.props.playStageSound();
                }
                this.setState({
                    top: 0,
                    left: 0,
                    removeState: true,
                    currentCategory,
                    currentStageIndex: this.state.currentStageIndex + 1,
                    stageName: this.Rounds[this.stageCount === (this.state.currentStageIndex + 1) ? 0 : (this.state.currentStageIndex + 1)],
                    showItems: this.setItemToShow(currentCategory.Id)
                });
                setTimeout(() => {
                    this.setState({
                        removeState: false
                    });
                }, 3000);
            } else if (this.randomKey === this.state.randomKey) {
                this.props.showEndGame();
            }
        }
    };

    removeBounsMove = () => {
        setTimeout(() => {
            this.setState({
                BounsTry: 0,
                BounsTime: 0,
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
        }, 4 * 1000);
    }

    handleDropObject = (clickedItem, dropItem) => {
        this.setState({
            BounsTry: 0,
            BounsTime: 0,
            removeState: false,
            showBounsTime: false,
            showBounsTry: false
        });
        const hasAnswer = parseInt(clickedItem.AnswersSelectType);
        let returnAnswer = false;
        let currentStatus = "";
        if (this.props.isMission === true) {
            let userAnswers = this.state.userAnswers.slice();
            userAnswers.push({
                Answer: clickedItem,
                Category: this.state.currentCategory,
                isTrue: dropItem && hasAnswer,
                isIn: dropItem && dropItem.Id ? true : false
            });
            this.setState({
                userAnswers
            });
            this.props.saveAnswer(userAnswers);
        }

        if (dropItem && hasAnswer && dropItem.Id) {
            currentStatus = "True";
            if (parseInt(clickedItem.BounsTry) > 0 && parseInt(clickedItem.BounsTime) > 0) {
                this.setState({
                    BounsTry: clickedItem.BounsTry,
                    BounsTime: clickedItem.BounsTime,
                    showBounsTime: true,
                    showBounsTry: true
                });
                this.props.addBounsTimeAndTry(parseInt(clickedItem.BounsTime), parseInt(clickedItem.BounsTry), true);
                this.removeBounsMove();
            } else if (parseInt(clickedItem.BounsTry) > 0) {
                this.setState({
                    BounsTry: clickedItem.BounsTry,
                    showBounsTry: true
                });
                this.props.addBounsTry(parseInt(clickedItem.BounsTry), true);
                this.removeBounsMove();
            } else if (parseInt(clickedItem.BounsTime) > 0) {
                this.setState({
                    BounsTime: clickedItem.BounsTime,
                    showBounsTime: true
                });
                this.props.addBounsTime(parseInt(clickedItem.BounsTime), true);
                this.removeBounsMove();
            } else {
                this.props.addTrueTry(true);
            }
            this.setState({
                position: {
                    x: this.state.left,
                    y: this.state.top
                },
                currentStatus
            });
        } else {
            currentStatus = "Wrong";
            this.props.addFalseTry(true);
            this.setState({
                currentStatus
            });
            if (hasAnswer) {
                returnAnswer = true;
            }
        }

        if (returnAnswer) {
            return true;
        }

        if (hasAnswer > 0) {
            this.selectedCount++;
        }
        if (this.selectedCount === this.selectableCount) {
            this.setState({
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
            this.selectedCount = 0;
            this.selectableCount = 0;

            setTimeout(() => {
                this.nextStage();
            }, 300);
        }
        return currentStatus;
    };

    getRandomNumber = (depth = 0) => {
        let number = Math.floor(Math.random() * 40);
        if (depth === 10) {
            return number;
        }
        var inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * 40);
            inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (this.randomList.length > 0) {
            const lastItem = this.randomList[this.randomList.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (this.randomList.length > 1) {
                const beforeItem = this.randomList[this.randomList.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
            if (number > 40) {
                number = this.getRandomNumber(depth + 1);
            }
        }
        return number;
    }

    setItemToShow = currentId => {
        const { Question } = this.props;

        const answers = Question.Answers.filter(p => Question.Fills.some(f => f.CategoryId === currentId && f.AnswerId === p.Id)).map(answer => {
            return answer;
        });

        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerStyle.BorderRadiusTop}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusRight}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusBottom}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusLeft}${Question.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerStyle.ShadowPositionX}px ${Question.CustomStyle.answerStyle.ShadowPositionY}px ${Question.CustomStyle.answerStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerStyle.BgAttachment}`,
            zIndex: 1
        };

        const count = answers.length;

        this.randomList = [];
        for (let index = 0; index < count; index++) {
            this.randomList.push(this.getRandomNumber());
        }
        const ShootObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.ShootObjectSrc : "";
        const ShootObjectOption = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.ShootObjectOption : "";

        let fromRight = false;
        let items = [];
        for (let index = 0; index < count; index++) {
            var ref = React.createRef();
            const top = this.randomList[index];
            const answer = answers[index];
            const CustomStyle = answer.CustomStyle;
            fromRight = !fromRight;
            let isAdded = false;
            if (CustomStyle && CustomStyle.Answers && CustomStyle.Movments) {
                const param = CustomStyle.Answers.filter(p => p.Id === answer.Id)[0];
                const Movments = CustomStyle.Movments.filter(p => p.Id === answer.Id);
                if (param) {
                    let customColor = param.CustomColor;
                    let objectCss = param.AnswersSelectObject;
                    let ObjectSrc = param.AnswersSelectObjectSrc;
                    let ObjectHoverSrc = param.AnswersSelectObjectSrcHover;
                    if (objectCss === "") {
                        objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                        ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                        ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                        if (!customColor) {
                            customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                        }
                    }
                    if (param.AnswersSelectType !== "radio" && /\d/.test(param.AnswersSelectType) && param.AnswersSelectType !== "0") {
                        this.selectableCount++;
                    }

                    objectCss = this.props.getRandomObject(objectCss);

                    items.push({
                        Id: answer.Id,
                        jsx: <HorizontalMove
                            refList={this.refList}
                            getRandomNumber={this.props.getRandomNumber}
                            randomKey={this.randomKey}
                            count={count}
                            playMoveSound={this.playMoveSound}
                            designerContainer={this.props.designerContainer}
                            item={answer}
                            id={answer.Id}
                            key={`objectId-${answer.Id}`}
                            param={param}
                            ref={ref}
                            fromRight={fromRight}
                            top={top}
                            img={ShootObjectSrc}
                            delay={param.delay}
                            rotate={param.rotate}
                            isSpin={param.isSpin}
                            flipX={param.flipX === true || param.flipX === "true" ? true : false}
                            scale={param.scale}
                            index={param.Sort}
                            transformOriginX={param.transformOriginX}
                            transformOriginY={param.transformOriginY}
                            style={styleAnswer}
                            ShootObjectOption={ShootObjectOption}
                            ObjectSrc={ObjectSrc}
                            customColor={customColor}
                            ObjectHoverSrc={ObjectHoverSrc}
                            MoveType={param.AnswersMoveType}
                            Movments={Movments}
                            Time={param.AnswersTime}
                            objectCss={objectCss}
                            handleDropObject={this.handleDropObject}
                            checkIsTrueAnswer={this.checkIsTrueAnswer}
                            text={answer.Name} />
                    });
                    isAdded = true;
                }
            }

            if (!isAdded) {
                let objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                const ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                const ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                const customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                objectCss = this.props.getRandomObject(objectCss);

                const param = {
                    ...answer,
                    BounsTry: 0,
                    BounsTime: 0,
                    AnswersSelectType: "0"
                };
                items.push({
                    Id: answer.Id,
                    jsx: <HorizontalMove
                        refList={this.refList}
                        getRandomNumber={this.props.getRandomNumber}
                        count={count}
                        playMoveSound={this.playMoveSound}
                        randomKey={this.randomKey}
                        scale={this.props.scale}
                        designerContainer={this.props.designerContainer}
                        item={answer}
                        delay={param.delay}
                        id={answer.Id}
                        key={`objectId-${answer.Id}`}
                        param={param}
                        fromRight={fromRight}
                        index={0}
                        ref={ref}
                        top={top}
                        img={ShootObjectSrc}
                        style={styleAnswer}
                        ObjectSrc={ObjectSrc}
                        customColor={customColor}
                        ObjectHoverSrc={ObjectHoverSrc}
                        MoveType={""}
                        Movments={[]}
                        Time={3}
                        objectCss={objectCss}
                        handleDropObject={this.handleDropObject}
                        checkIsTrueAnswer={this.checkIsTrueAnswer}
                        text={answer.Name} />
                });
            }
        }
        return items;
    };

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { Question } = this.props;

        const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
        const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";
        const AnswerOrder = Question.CustomStyle.answerBoxStyle ? Question.CustomStyle.answerBoxStyle.AnswerOrder : "";
        const AnswersColsMargin = Question.CustomStyle.answerBoxStyle ? Question.CustomStyle.answerBoxStyle.AnswersColsMargin : "";

        const styleAnswer = {
            minWidth: `${Question.CustomStyle.answerBoxStyle.width}%`,
            maxWidth: '100%',
            minHeight: `${Question.CustomStyle.answerBoxStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerBoxStyle.BorderRadiusTop}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusRight}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusBottom}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusLeft}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerBoxStyle.ShadowPositionX}px ${Question.CustomStyle.answerBoxStyle.ShadowPositionY}px ${Question.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerBoxStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerBoxStyle.BgAttachment}`,
            marginTop: AnswerOrder === "top" ? '85px' : 'unset',
            lineHeight: 1.5
        };

        const objectCss = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObject : "";
        const DropObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrc : "";
        const DropObjectSrcHover = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const DropCustomColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropCustomColor : "";
        const TranslateX = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TranslateX : "";
        const TranslateTime = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TranslateTime : "";

        return <div className="w-100 h-100 position-absolute oh"  >
            <style dangerouslySetInnerHTML={{
                __html: `.dragdrop-move-box{${Question.CustomStyle.answerStyle.Custom}} .dragdrop-static-box{${Question.CustomStyle.answerBoxStyle.Custom} }  @keyframes dropObjectMovment{ 0%{transform:translateX(0%)} 25%{transform:translateX(${TranslateX}%)} 50%{transform:translateX(0%)} 75%{transform:translateX(-${TranslateX}%)} 100%{transform:translateX(0%)} }`
            }}></style>
            <PopUps
                removeState={this.state.removeState}
                showBounsTry={this.state.showBounsTry}
                showBounsTime={this.state.showBounsTime}
                bounsTry={this.state.BounsTry}
                bounsTime={this.state.BounsTime}
                stageCount={this.stageCount}
                TryColorCustom={TryColorCustom}
                TryColor={TryColor}
                label={this.state.stageName.label}
                position={this.state.position}
            />
            {
                this.state.showItems ?
                    this.state.showItems.map((item, key) => {
                        return <span key={key} >{item.jsx} </span>
                    })
                    : ""
            }
            <div ref={this.refTop} className={`position-absolute w-100 d-flex justify-content-center my-${AnswersColsMargin}`}
                style={{
                    zIndex: '15',
                    top: AnswerOrder === "top" ? '0' : 'unset',
                    bottom: AnswerOrder !== "top" ? '0' : 'unset'
                }}>
                <div className="d-flex justify-content-center align-items-center flex-wrap mx-3" style={styleAnswer} dangerouslySetInnerHTML={{
                    __html: this.state.currentCategory.Name
                }}></div>
            </div>

            <div className={`position-absolute w-100 d-flex justify-content-center`}
                style={{
                    zIndex: '15',
                    bottom: AnswerOrder !== "top" ? `calc(1% + ${this.refTop && this.refTop.current ? this.refTop.current.clientHeight : 0}px)` : '1%'
                }}>
                <div ref={this.refDrop} key={`DropObject`} className="objectMovment" style={{
                    animation: `dropObjectMovment linear forwards ${TranslateTime}s normal infinite`
                }}>
                    <GameObjects
                        objectCss={objectCss}
                        ObjectSrc={DropObjectSrc}
                        ObjectHoverSrc={DropObjectSrcHover}
                        customColor={DropCustomColor}
                        FinishMove={this.FinishMove}
                        status={this.state.currentStatus}
                    />
                </div>
            </div>
        </div>;
    }
}

export default DropMultiSelect;
const registrationSchoolsReducers = (state = [], action) => {
    switch (action.type) {
        case "REGISTRATION_SCHOOLS_LOADING":
            return {
                schools: state.schools,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "REGISTRATION_SCHOOLS_INITIAL":
            return {
                schools: action.schools,
                toRemove: [],
                isLoaded: true
            };
        case "REGISTRATION_SCHOOLS_QUEUE_FOR_REMOVAL":
            return {
                schools: state.schools,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "REGISTRATION_SCHOOLS_CLEAN_NODES":
            return {
                schools: state.toRemove.some(v => v === action.id) ? state.schools.filter(v => v.Id !== action.id) : state.schools,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "REGISTRATION_SCHOOLS_UNDO":
            return {
                schools: state.schools,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}


export default registrationSchoolsReducers;

import React from "react";
import GameObjects from "../../GameObjects";

class Beach extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    renderWave = () => {

        const { innerWidth: width, innerHeight: height } = window;

        return <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px"
            width={`${height}px`}
            height={`${width}px`}
            viewBox={`0 0 500 1`}
            xmlSpace="preserve">
            <style dangerouslySetInnerHTML={{
                __html:
                    `
                        @keyframes lightwaves {
                            0%,100% { transform: translate(0,0); }
                            25%     { transform: translate(5px,5px); transform: scale(1.05); }
                            50%     { transform: translate(25px, 5px); }
                            75%     { transform: translate(12px,10px); transform: scale(1.05); }
                        }
                        @keyframes darkwaves {
                            0%,100% { transform: translate(0,0); }
                            25%     { transform: translate(25px, 5px); transform: scale(1.05); }
                            50%     { transform: translate(12px,10px); }
                            75%     { transform: translate(5px,5px); }
                        }
                    `
            }}></style>

            <g id="DarkWaves" className="DarkWaves" style={{
                animation: 'darkwaves 2.8s infinite'
            }}>
                <g>
                    <path style={{ fill: '#6cecff' }} d="M761.8,202.4c-24.7,0-36.7,7.2-49.5,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.6-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.7-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.7-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7C78.7,224.9,65,232.9,38,232.9c-27,0-40.4-8.1-53.6-15.9
               c-12.1-7.1-24.3-13.9-45.4-14.6V602h922V232.9c-22.6-0.8-37.2-8.4-49.7-15.8C798.5,209.5,786.5,202.4,761.8,202.4z"/>
                </g>
            </g>
            <g id="LightWaves" className="LightWaves position-relative" style={{
                animation: 'lightwaves 2s infinite'
            }}>
                <g>
                    <path style={{ fill: '#24bef4' }} d="M750.9,229.8c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               s-46.9-8.4-62.1-16.6c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               c-31.2,0-46.9-8.4-62.1-16.6c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               c-31.2,0-46.9-8.4-62.1-16.6c-14.8-7.9-28.9-15.4-57.3-15.4c-16.9,0-28.8,2.6-38.8,6.4V600h922V237c-12,5.3-26,9.4-47.8,9.4
               C782.1,246.4,766.1,237.9,750.9,229.8z"/>
                </g>
            </g>
        </svg >
    }


    render() {
        const { innerWidth: width } = window;

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundColor: '#f6d997',
            height: '100vh',
            top: '0'
        }}>
            <div className=" position-relative" style={{
                backgroundColor: '#f6d997',
                height: '100vh'
            }}>

                <div className="position-absolute fun-bg-1 d-flex flex-column oh">
                    <div className="w-100 h-100 position-relative"></div>
                    <div className="w-100 h-100 position-relative"></div>
                </div>
                <div className="position-absolute fun-bg-2"></div>

                <div className="position-absolute d-none d-md-block" style={{
                    top: `0%`,
                    right: `0%`,
                    transform: 'rotate(-90deg)',
                    transformOrigin: 'right bottom',
                    marginTop: `-${width}px`
                }}>
                    {
                        this.renderWave()
                    }
                </div>
                <div className="position-absolute" style={{
                    bottom: `10%`,
                    left: `5%`
                }}>
                    <GameObjects objectCss="BoysPlayWithSand" status={this.props.currentStatus} FinishMove={this.FinishMove} />
                </div>

                <div className="position-absolute CrabMove objectMovment" style={{
                    top: `5%`,
                    right: `25%`
                }}>
                    <GameObjects objectCss="Crab" scale={0.6} status={this.props.currentStatus} FinishMove={this.FinishMove} />
                </div>

                <div className="position-absolute d-none d-md-block" style={{
                    bottom: '20%',
                    right: `10%`
                }}>
                    <div className="position-relative d-flex justify-content-center ">
                        <div className="position-absolute">
                            <GameObjects objectCss="WaterCircles" />
                        </div>
                        <div className="position-absolute" style={{
                            top: '-80px',
                            marginLeft: '-20px'
                        }}>
                            <GameObjects objectCss="DiveFish" scale={0.6} transformOriginY={"bottom"} status={this.props.currentStatus} FinishMove={this.FinishMove} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
export default Beach;
import React from "react";
import Spritesheet from 'react-responsive-spritesheet';
import TextBox from "../../TextBox";

import img_1 from './images/1.png';
import img_2 from './images/2.png';
import img_3 from './images/3.png';

const width = 2846 / 14;
const height = 150 / 1;

const AlliedSpaceShip = (props) => {

    const { color } = props;
    let img = img_3;
    if (color === "blue") {
        img = img_2;
    } else if (color === "yallow") {
        img = img_1;
    }

    return <div className="position-relative d-flex justify-content-center flex-column align-items-center" >
        <div className={`mb-1 d-inline-block`} >
            <div className={`${props.isSpin === "true" || props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${props.transformOriginX ? props.transformOriginX : "center"} ${props.transformOriginY ? props.transformOriginY : "center"}`,
                    transform: `
                        ${props.scale ? `scale(${props.scale})` : ""}
                        ${props.flipX === "true" || props.flipX === true ? "scaleX(-1)" : ""}
                        ${props.rotate ? `rotate(${props.rotate}deg)` : ""}
                        `
                }}>
                    <Spritesheet
                        className="mx-auto position-relative"
                        image={img}
                        widthFrame={width}
                        heightFrame={height}
                        steps={14}
                        startAt={1}
                        endAt={14}
                        fps={12}
                        autoplay={true}
                        loop={true}
                    />
                </div>
            </div>
        </div>
        <TextBox {...props} />
    </div >
}

export default AlliedSpaceShip;
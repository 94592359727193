import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ReactPixel from 'react-facebook-pixel';

import Footer from "../Footer/FooterBottom";

class AfterSprintful extends React.Component {

    constructor(props) {
        super(props);
        window.scrollToTop();
        ReactPixel.init('484815450795869');
        ReactPixel.track('Schedule');
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>
                    <Helmet>
                        <title> لقد تم حجز الاستشارة المجانية بنجاح | {t('alarabeyya')}</title>
                    </Helmet>
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="teacher-wrapper pos-rel pt-5  " style={{
                            minWidth: '50%'
                        }}>
                            <div className="teacher-single-top mt-2">
                                <div className="product-content w-100 text-center ">
                                    <div className="display-1 text-success">
                                        <i className="fad fa-shield-check"></i>
                                    </div>
                                    <h4 className="display-3 subtitle mt-0 mb-4">
                                        لقد تم حجز الاستشارة المجانية بنجاح
                                    </h4>
                                    <p className="text-justify">
                                        <ul>
                                            <li>
                                                مكان عقد الاستشارة هو google meet\zoom .
                                            </li>
                                            <li>
                                                سنبعث لك رابط الاجتماع قبل الموعد ب 5 دقائق .
                                            </li>
                                            <li>
                                                مفضل أن تدعو أكبر عدد من معلمي اللغة العربية بالمدرسة إلى الاجتماع لكي يتعرفوا على مزايا الموقع ويشاركون باتخاذ القرار
                                                .
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(AfterSprintful));
import React from "react";
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import Footer from "../Footer/FooterBottom";
import ListItem from "../Admin/Files/ListItem";
import LessonItem from "./Tracks/LessonItem";
import {
    searchLessons as fetch,
    setLessonFavorite,
    setFileFavorite
} from './actions/';
import { fetchIndices } from '../Admin/Indices/actions/';
import { getIndeices, setIndeices } from "../../context/sessionStorage";
import { fetchClassRooms } from '../School/ClassRooms/actions';
import { fetchStudentInClassRooms } from '../School/StudentInClassRooms/actions/';
import Field from "../Admin/Question/View/Background/Field";
import ClassTypeData from "../Config/classType.json";

class Home extends React.Component {

    state = {
        indices: [],
        ClassType: [],
        ClassTypeId: "",
        showResult: false,
        IsExams: true,
        IsFun: true,
        IsBooks: true,
        IsQuizzes: true,
        IsLessons: true,
        IsFiles: true,
        showAll: false,
        count: 50
    };

    constructor(props) {
        super(props);
        this.state.ClassType = ClassTypeData.ClassType;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user &&
            props.user.user &&
            props.user.user.UserType &&
            (props.user.user.UserType.Id === 3 || props.user.user.UserType.Id === 4) &&
            props.classRooms && props.classRooms.length === 0) {
            props.fetchClassRooms();
        }
        return state;
    }

    fetchStudentInClassRooms = ClassRoomId => {
        this.props.fetchStudentInClassRooms(ClassRoomId);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.Search.value = id;
            this.getSearch(id);
        }

        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });
        window.scrollToPosition("main-search-section");
    }

    setIsExams = IsExams => {
        this.setState({
            IsExams
        });
    }

    setIsFun = IsFun => {
        this.setState({
            IsFun
        });
    }

    setIsBooks = IsBooks => {
        this.setState({
            IsBooks
        });
    }

    setIsQuizzes = IsQuizzes => {
        this.setState({
            IsQuizzes
        });
    }

    setIsFiles = IsFiles => {
        this.setState({
            IsFiles
        });
    }

    setIsLessons = IsLessons => {
        this.setState({
            IsLessons
        });
    }

    search = (e) => {
        if (e) {
            if (e.key === 'Enter') {
                this.getSearch();
            }
        }
    }

    getSearch = () => {
        if (this.Search.value) {
            if (this.Search.value.length > 2) {
                this.setState({
                    showResult: true
                });

                const postData = {
                    key: this.Search.value,
                    IsExams: this.IsExams.checked,
                    IsFiles: this.IsFiles.checked,
                    IsBooks: this.IsBooks.checked,
                    IsQuizzes: this.IsQuizzes.checked,
                    IsSelfDevelopment: this.IsQuizzes.checked,
                    IsLessons: this.IsLessons.checked,
                    IsFun: this.IsFun.checked,
                    ClassTypeId: this.state.ClassTypeId,
                    count: this.state.count
                };
                this.props.fetch(postData);
            } else {
                const { t } = this.props;

                let _toastId = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });

                toast.update(_toastId, {
                    render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
                    autoClose: false,
                    closeOnClick: false,
                });

                toast.update(_toastId, {
                    render: () => <>
                        <div className="h5">
                            <i className="icon fal fa-magnifying-glass-minus"></i>
                            {t('Pages.Search.toast.KeyLength.Title')}
                        </div>
                        <div className="h6">{t('Pages.Search.toast.KeyLength.Body')}
                        </div>
                    </>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            toast.update(_toastId, {
                render: () => <>
                    <div className="h5">
                        <i className="icon fal fa-magnifying-glass-minus"></i>
                        {t('Pages.Search.toast.NoKey.Title')}
                    </div>
                    <div className="h6">{t('Pages.Search.toast.NoKey.Body')}
                    </div>
                </>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    setFileFavorite = id => {
        this.props.setFileFavorite(id);
    }

    renderFileItem = count => {
        const { t, user, search } = this.props;
        if (search.isLoaded === false) {
            return this.renderLoading();
        }
        if (search.Files && search.Files.length > 0) {
            let indices = this.props.indices;
            if (indices) {
                setIndeices(indices);
            } else {
                indices = this.state.indices;
            }
            return search.Files.slice(0, count).map(item =>
                <ListItem
                    user={user}
                    classRooms={this.props.classRooms}
                    getStudent={this.fetchStudentInClassRooms}
                    indices={indices.nodes}
                    setFavorite={this.setFileFavorite}
                    key={item.Id}
                    IsAdmin={false}
                    item={item} />
            );
        }
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-telescope"></i>
                </div>
                <h4 className=" subtitle">
                    {t("Pages.Search.NotFound")}
                </h4>
                <div className="h2 text-danger">
                    "{this.Search.value}"
                    {t("Pages.Search.IsNotHere")}
                </div>
            </div>
        </div>;
    }

    renderLessonItem = (type, count = 2) => {

        const { t, user, search } = this.props;

        if (search.isLoaded === false) {
            return this.renderLoading();
        }

        var lessons = [];
        if (type === "Lessons") {
            lessons = search.Lessons;
        } else if (type === "Exams") {
            lessons = search.Exams;
        } else if (type === "Quizzes") {
            lessons = search.Quizzes;
        } else if (type === "Files") {
            lessons = search.Files;
        } else if (type === "Games") {
            lessons = search.Games;
        } else if (type === "Books") {
            lessons = search.Books;
        }

        if (lessons && lessons.length > 0) {
            let indices = this.props.indices;
            if (indices) {
                setIndeices(indices);
            } else {
                indices = this.state.indices;
            }
            return lessons.slice(0, count).map(item =>
                <LessonItem
                    classRooms={this.props.classRooms}
                    getStudent={this.fetchStudentInClassRooms}
                    user={user}
                    indices={indices.nodes}
                    key={item.Id}
                    IsAdmin={false}
                    setFavorite={this.props.setLessonFavorite}
                    lesson={item}
                    type={item.Index.Type} />
            );
        }
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-telescope"></i>
                </div>
                <h4 className=" subtitle">
                    {t("Pages.Search.NotFound")}
                </h4>
                <div className="h2 text-danger">
                    "{this.Search.value}"
                    {t("Pages.Search.IsNotHere")}
                </div>
            </div>
        </div>;
    }

    renderLoading() {
        const { t } = this.props;
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-sync-alt fa-spin"></i>
                </div>
                <h4 className="display-3 subtitle">
                    {t("Pages.Search.Loading")}
                </h4>
            </div>
        </div>
    }

    renderClassTypeOption() {
        return this.state.ClassType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    setClassTypeId = ClassTypeId => {
        this.setState({
            ClassTypeId
        });
    }

    showAll = (type, className, background) => {
        const { t } = this.props;
        this.setState({
            type,
            showAll: true,
            title: `<i class="${className}"></i>` + t(`Pages.Search.${type}Result`),
            background
        });
    }

    hideAll = () => {
        this.setState({
            showAll: false
        });
    }

    renderItem() {
        if (this.state.type === "Files") {
            return this.renderFileItem(1000);
        }
        return this.renderLessonItem(this.state.type, 1000);
    }

    replaceJSX = (string, find, replace) => {
        return string.split(find).flatMap((item) => [item, replace]).slice(0, -1).join("");
    }

    renderModal() {
        if (this.state.showAll) {
            const { t, search } = this.props;

            var count = this.state.count;
            if (this.state.type === "Lessons") {
                count = search.Lessons.length;
            } else if (this.state.type === "Exams") {
                count = search.Exams.length;
            } else if (this.state.type === "Quizzes") {
                count = search.Quizzes.length;
            } else if (this.state.type === "Files") {
                count = search.Files.length;
            } else if (this.state.type === "Games") {
                count = search.Games.length;
            } else if (this.state.type === "Books") {
                count = search.Books.length;
            }

            const note = this.replaceJSX(t("Pages.Search.Note"), "__COUNT__", `<tag class="font-weight-bold"> ${count} </tag>`);

            return <Modal
                size="lg"
                show={true}
                onHide={this.hideAll}
                keyboard={false}
                enforceFocus={false} >
                <Modal.Header closeButton>
                    <Modal.Title> <span className="title text-center" dangerouslySetInnerHTML={{ __html: this.state.title }}></span> </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="widget">
                        <div className="widget-body" style={{
                            background: this.state.background
                        }}>
                            {this.renderItem()}
                            <blockquote className="single-quote">
                                <div className="quotes" dangerouslySetInnerHTML={{
                                    __html: this.replaceJSX(note, "__KEY__", `<tag class="font-weight-bold"> ${this.Search.value} </tag>`)
                                }}></div>
                            </blockquote>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-12">
                        <button className="btn btn-danger" onClick={this.hideAll}>
                            <i className="icon fas fa-times"></i>
                            {t('Close')}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        }
        return "";
    }

    render() {
        const { t, search } = this.props;
        return (
            <>
                {this.renderModal()}
                <Helmet>
                    <title>{t("Pages.Search.title")} | {t('alarabeyya')}</title>
                </Helmet>
                <section className="position-relative padding-bottom padding-top oh " id="main-search-section" style={{
                    backgroundColor: '#b8f0ff',
                    minHeight: '100vh'
                }}>
                    <Field />
                    <style dangerouslySetInnerHTML={{
                        __html: `.form-container{max-width:500px;padding:5px;background:#92278f;}.product-list-item .product-thumb{min-height: unset;}.product-list-item{margin-bottom:10px;}.shop-item .product-content .title, .product-list-item .product-content .title{font-size:18px;}.product-list-item .product-content{padding: 10px 10px 5px 5px;}.product-list-item{min-height: 210px;}.product-list-item .product-content .subtitle{font-size:14px;}.product-list-item .product-content p{font-size:13px;}`
                    }}></style>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-4 mb-50">
                                <div className="widget widget-category">
                                    <div className="widget-header d-flex justify-content-between">
                                        <h5 className="title align-self-center">
                                            {t("Pages.Search.title")}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <div className="contact-form">
                                            <div className="form-group w-100">
                                                <label className='text-dark font-size-h5'> <i className="icon fas fa-magnifying-glass"></i> {t('Pages.Search.input.title')}</label>
                                                <input
                                                    onKeyDown={this.search}
                                                    ref={Search => this.Search = Search}
                                                    autoComplete="off"
                                                    type="text"
                                                    title={t('Pages.Search.input.title')}
                                                    placeholder={t('Pages.Search.input.Placeholder')} />
                                            </div>
                                            <div className="form-group w-100 ">
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-keyboard"></i>{t('Pages.Search.Type.title')}</label>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsLessons(e.target.checked)}
                                                        ref={IsLessons => this.IsLessons = IsLessons}
                                                        defaultChecked={true}
                                                        name="IsLessons"
                                                        id="IsLessons" />
                                                    <label className="custom-control-label" htmlFor="IsLessons" >
                                                        {t("PageHeader.Lessons")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsFiles(e.target.checked)}
                                                        ref={IsFiles => this.IsFiles = IsFiles}
                                                        defaultChecked={true}
                                                        name="IsFiles"
                                                        id="IsFiles" />
                                                    <label className="custom-control-label" htmlFor="IsFiles" >
                                                        {t("PageHeader.Files")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={true}
                                                        onChange={e => this.setIsExams(e.target.checked)}
                                                        ref={IsExams => this.IsExams = IsExams}
                                                        name="IsExams"
                                                        id="IsExams" />
                                                    <label className="custom-control-label" htmlFor="IsExams" >
                                                        {t("PageHeader.Exams")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        ref={IsQuizzes => this.IsQuizzes = IsQuizzes}
                                                        onChange={e => this.setIsQuizzes(e.target.checked)}
                                                        defaultChecked={true}
                                                        name="IsQuizzes"
                                                        id="IsQuizzes" />
                                                    <label className="custom-control-label" htmlFor="IsQuizzes" >
                                                        {t("PageHeader.Quizzes")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsFun(e.target.checked)}
                                                        ref={IsFun => this.IsFun = IsFun}
                                                        defaultChecked={true}
                                                        name="IsFun"
                                                        id="IsFun" />
                                                    <label className="custom-control-label" htmlFor="IsFun" >
                                                        {t("PageHeader.Fun")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsBooks(e.target.checked)}
                                                        ref={IsBooks => this.IsBooks = IsBooks}
                                                        defaultChecked={true}
                                                        name="IsBooks"
                                                        id="IsBooks" />
                                                    <label className="custom-control-label" htmlFor="IsBooks" >
                                                        {t("PageHeader.Books")}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group w-100">
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-screen-users"></i>
                                                    {t('Pages.UsersControl.advancedSearch.ClassType.Placeholder')}</label>
                                                <select
                                                    defaultValue={this.state.ClassTypeId}
                                                    onChange={e => this.setClassTypeId(e.target.value)}
                                                    className="form-control"
                                                    name="ClassTypeId"
                                                    placeholder={t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}
                                                    title={t("Pages.UsersControl.advancedSearch.ClassType.title")}  >
                                                    <option value="">{t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}</option>
                                                    {this.renderClassTypeOption()}
                                                </select>
                                            </div>
                                            <div className="form-group w-100 ">
                                                <button className="custom-button w-100" onClick={() => this.getSearch()}  >
                                                    <span>
                                                        <i className={`icon fal fa-magnifying-glass`}></i>
                                                        {
                                                            t('Pages.Search.submit')
                                                        }
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 mb-50">
                                {
                                    this.state.showResult ? <>
                                        {
                                            this.state.IsLessons ?
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title" > <i className="fas fa-layer-group"></i> {t("Pages.Search.LessonsResult")}</h5>
                                                    </div>
                                                    <div className="widget-body" style={{
                                                        background: '#fffee8'
                                                    }}>
                                                        {this.renderLessonItem("Lessons", 2)}
                                                    </div>
                                                    {
                                                        search.Lessons && search.Lessons.length > 2 ?
                                                            <div className="tags-area p-0" >
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div onClick={() => this.showAll("Lessons", "fas fa-layer-group", '#fffee8')} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more" style={{
                                                                        background: "#8dc53e"
                                                                    }}>
                                                                        <span className="txt pl-2">
                                                                            {t('showAll')}
                                                                            <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                                : ""
                                        }
                                        {
                                            this.state.IsFiles ?
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title" > <i className="fas fa-paperclip"></i> {t("Pages.Search.FilesResult")}</h5>
                                                    </div>
                                                    <div className="widget-body" style={{
                                                        background: '#f5a0c5'
                                                    }}>
                                                        {this.renderFileItem(2)}
                                                    </div>
                                                    {
                                                        search.Files && search.Files.length > 2 ?
                                                            <div className="tags-area p-0" >
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div onClick={() => this.showAll("Files", "fas fa-paperclip", "#f5a0c5")} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more" style={{
                                                                        background: "#f16421"
                                                                    }}>
                                                                        <span className="txt pl-2">
                                                                            {t('showAll')}
                                                                            <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }

                                                </div>
                                                : ""
                                        }
                                        {
                                            this.state.IsExams ?
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title" > <i className="fas fa-pencil-alt"></i> {t("Pages.Search.ExamsResult")}</h5>
                                                    </div>
                                                    <div className="widget-body" style={{
                                                        background: '#fff5fd'
                                                    }}>
                                                        {this.renderLessonItem("Exams", 2)}
                                                    </div>
                                                    {
                                                        search.Exams && search.Exams.length > 2 ?
                                                            <div className="tags-area p-0" >
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div onClick={() => this.showAll("Exams", "fas fa-pencil-alt", "#fff5fd")} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more" style={{
                                                                        background: "#002c9d"
                                                                    }}>
                                                                        <span className="txt pl-2">
                                                                            {t('showAll')}
                                                                            <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }

                                                </div>
                                                : ""
                                        }
                                        {
                                            this.state.IsQuizzes ?
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title" > <i className="fas fa-question"></i> {t("Pages.Search.QuizzesResult")}</h5>
                                                    </div>
                                                    <div className="widget-body" style={{
                                                        background: '#f2ffeb'
                                                    }}>
                                                        {this.renderLessonItem("Quizzes", 2)}
                                                    </div>
                                                    {
                                                        search.Quizzes && search.Quizzes.length > 2 ?
                                                            <div className="tags-area p-0" >
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div onClick={() => this.showAll("Quizzes", "fas fa-question", '#f2ffeb')} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more" style={{
                                                                        background: "#8dc53e"
                                                                    }}>
                                                                        <span className="txt pl-2">
                                                                            {t('showAll')}
                                                                            <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                                : ""
                                        }
                                        {
                                            this.state.IsFun ?
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title" > <i className="fas fa-gamepad-alt"></i> {t("Pages.Search.FunsResult")}</h5>
                                                    </div>
                                                    <div className="widget-body" style={{
                                                        background: '#d8e5fb'
                                                    }}>
                                                        {this.renderLessonItem("Games", 2)}
                                                    </div>
                                                    {
                                                        search.Games && search.Games.length > 2 ?
                                                            <div className="tags-area p-0" >
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div onClick={() => this.showAll("Games", "fas fa-gamepad-alt", "#d8e5fb")} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more" style={{
                                                                        background: "#e43638"
                                                                    }}>
                                                                        <span className="txt pl-2">
                                                                            {t('showAll')}
                                                                            <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }

                                                </div>
                                                : ""
                                        }
                                        {
                                            this.state.IsBooks ?
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title" > <i className="fas fa-book"></i> {t("Pages.Search.BooksResult")}</h5>
                                                    </div>
                                                    <div className="widget-body" style={{
                                                        background: '#b8e3f6'
                                                    }}>
                                                        {this.renderLessonItem("Books", 2)}
                                                    </div>
                                                    {
                                                        search.Books && search.Books.length > 2 ?
                                                            <div className="tags-area p-0" >
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div onClick={() => this.showAll("Books", "fas fa-book", "#b8e3f6")} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more" style={{
                                                                        background: "#ec407a"
                                                                    }}>
                                                                        <span className="txt pl-2">
                                                                            {t('showAll')}
                                                                            <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }

                                                </div>
                                                : ""
                                        }
                                    </> : ""
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        indices: state.indices,
        search: state.search,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetchStudentInClassRooms, fetchClassRooms, fetchIndices, fetch, setLessonFavorite, setFileFavorite })(withTranslation('common')(Home));
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';

import api from '../../../../api/api';
import { toast } from 'react-toastify';

import Items from '../Forms/Items';

class LessonPage extends React.Component {

    state = {
        pages: [],
        Leftpages: [],
        Rightpages: []
    };

    static getDerivedStateFromProps(props, state) {
        if (props.LessonPage) {
            state.Rightpages = props.LessonPage.filter(p => p.Position === false);
            state.Leftpages = props.LessonPage.filter(p => p.Position === true);
        }
        return state;
    }

    setLessonPageSort = (pages, Position) => {
        let lessonPageResult = [];
        pages.forEach((page, index) => {
            lessonPageResult.push({
                ...page,
                Sort: index + 1,
            });
        });

        if (Position) {
            this.state.Rightpages.forEach(page => {
                lessonPageResult.push({
                    ...page
                });
            });
        } else {
            this.state.Leftpages.forEach(page => {
                lessonPageResult.push({
                    ...page
                });
            });
        }

        this.props.updateLessons({
            LessonPage: lessonPageResult
        });
    }
    showDelete = id => {
        const { t } = this.props;

        this.props.removeLessonPage(id);

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-light btn-block " onClick={() => { this.delete(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-recycle"></i> {t('confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.DARK,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    undoDelete = id => {
        this.props.undoDeleteLessonPage(id);
    }
    delete = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/LessonPage/${id}?LessonId=${this.props.LessonId}`).then(response => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Delete_toast.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Delete_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            this.props.clearLessonPage(id);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    updateLessonPage = (pages, Position) => {

        if (Position) {
            this.state.Rightpages.forEach(page => {
                pages.push({
                    ...page
                });
            });
        } else {
            this.state.Leftpages.forEach(page => {
                pages.push({
                    ...page
                });
            });
        }

        this.props.updateLessons({
            LessonPage: pages
        });
    }

    render() {
        const { t, show, handleClose, oneSide } = this.props;
        return (
            <Modal
                size={`${oneSide ? "lg" : "xl"} `}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                enforceFocus={false} >
                <Modal.Header closeButton>
                    <Modal.Title> <i className="fad fa-book-reader text-danger"></i> {t('Pages.Lessons.tools.pages')} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {
                            oneSide ? ""
                                :
                                <div className="col-6" style={{
                                    borderLeft: '3px dashed rgb(14 44 83)'
                                }}>
                                    <Items
                                        isGame={this.props.isGame}
                                        lesson={this.props.lesson}
                                        updateLessonPage={this.updateLessonPage}
                                        showDelete={this.showDelete}
                                        API="/LessonPage/"
                                        toast={toast}
                                        items={this.state.Rightpages}
                                        LessonId={this.props.LessonId}
                                        Position={false}
                                        setSort={this.setLessonPageSort}>
                                    </Items>
                                </div>
                        }
                        <div className={`${oneSide ? "col-12" : "col-6"} `}>
                            <Items
                                lesson={this.props.lesson}
                                isGame={this.props.isGame}
                                updateLessonPage={this.updateLessonPage}
                                showDelete={this.showDelete}
                                API="/LessonPage/"
                                toast={toast}
                                items={this.state.Leftpages}
                                LessonId={this.props.LessonId}
                                Position={true}
                                setSort={this.setLessonPageSort}>
                            </Items>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-12">
                        <button className="btn btn-danger" onClick={handleClose}>
                            <i className="icon fas fa-times"></i>
                            {t('Close')}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation('common')(LessonPage);
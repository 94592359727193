import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import Footer from "../../Footer/FooterBottom";
import TracksBg from "./TracksBg";

class Tracks extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('PageHeader.StudyTrack')}  | {t('alarabeyya')}
                    </title>
                </Helmet>
                <TracksBg
                    title={t('PageHeader.StudyTrack')}
                    subTitle={t('PageHeader.MainType')}
                    onEdit={false}
                    perfixUrl={"Tracks"}
                />
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

export default withTranslation('common')(Tracks);
import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import configData from "../../../../Config/objects.json";
import configDataSound from "../../../../Config/game_sound.json";

class QuestionAnswer extends React.Component {

    state = {
        AnswersSelectClass: 'primary',
        AnswersSelectType: 'radio',
        AnswersSeparated: ',',
        AnswerOrder: 'orderd',
        AnswersAlign: "center",
        AnswersSelectTemplate: 'auto',
        AutoCorrection: false,
        displayColorPicker: false,
        displaySecondColorPicker: false,
        displayFontColorPicker: false,
        displayIconCustomColorPicker: false,
        displayStrokeColorPicker: false,
        displayColorSecondCPicker: false,
        displaySecondSecondColorPicker: false,
        displaySecondFontColorPicker: false,
        BorderStyle: '',
        BorderWidth: 0,
        BorderColor: '',
        AnswersMargin: 1,
        AnswersColsMargin: 0,
        AnswersCols: 1,
        ColsWidthRight: 4,
        ColsWidthLeft: 4,
        Cursor: "",
        Margin: 0,
        height: 300,
        width: 100,
        BackgroundSound: "",
        SplashSound: "",
        CustomBackgroundSound: ""
    };

    constructor(props) {
        super(props);
        this.Cursor = configData.Cursor;
        this.BackgroundSound = configDataSound.Background;
        this.audio = new Audio();
    }


    static getDerivedStateFromProps(props, state) {
        state.Icon = props.Icon;
        state.height = props.height;
        state.Margin = props.Margin;
        state.width = props.width;
        state.Cursor = props.Cursor;
        state.Color = props.Color;
        state.Size = props.Size;
        state.IconType = props.IconType;
        state.FontSize = props.FontSize;
        state.MainColor = props.MainColor;
        state.SecondMainColor = props.SecondMainColor;
        state.FontColor = props.FontColor;
        state.SecondFontColor = props.SecondFontColor;
        state.IconColor = props.IconColor;
        state.SecondColor = props.SecondColor;
        state.SecondSecondColor = props.SecondSecondColor;
        state.AnswerOrder = props.AnswerOrder;
        state.BorderColor = props.BorderColor;
        state.BorderStyle = props.BorderStyle;
        state.AnswersCols = props.AnswersCols;
        state.StrokeWidth = props.StrokeWidth;
        state.StrokeColor = props.StrokeColor;
        state.MarginBottom = props.MarginBottom;
        state.CursorCustom = props.CursorCustom;
        state.AnswersAlign = props.AnswersAlign;
        state.ControlColor = props.ControlColor;
        state.SecondControlColor = props.SecondControlColor;
        state.AnswersMargin = props.AnswersMargin;
        state.IconPosition = props.IconPosition;
        state.IconHoverColor = props.IconHoverColor;
        state.IconCustomColor = props.IconCustomColor;
        state.ButtonSecondStyle = props.ButtonSecondStyle;
        state.ButtonNextStyle = props.ButtonNextStyle;
        state.ButtonOneStyle = props.ButtonOneStyle;
        state.CustomBackground = props.CustomBackground;
        state.Repeat = props.Repeat;
        state.AnswersColsMargin = props.AnswersColsMargin;
        state.AutoCorrection = props.AutoCorrection;
        state.BorderWidth = props.BorderWidth;
        state.ColsWidthLeft = props.ColsWidthLeft;
        state.ColsWidthRight = props.ColsWidthRight;
        state.AnswersSeparated = props.AnswersSeparated;
        state.AnswersSelectType = props.AnswersSelectType;
        state.AnswersSelectClass = props.AnswersSelectClass;
        state.AnswersSelectTemplate = props.AnswersSelectTemplate;
        state.SplashTime = props.SplashTime;
        state.CustomBackgroundSound = props.CustomBackgroundSound;
        state.BackgroundSound = props.BackgroundSound;
        state.SplashSound = props.SplashSound;
        state.PositionY = props.PositionY;
        state.PositionX = props.PositionX;
        return state;
    }

    renderBackgroundSound() {
        return this.BackgroundSound.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }

    setColsWidthLeft = value => {
        if (value >= 1 && value <= 5) {
            this.setState({ ColsWidthLeft: value });
            this.props.setColsWidthLeft(value);
        } else if (value < 1) {
            this.setState({ ColsWidthLeft: 1 });
            this.props.setColsWidthLeft(1);
        } else if (value > 5) {
            this.setState({ ColsWidthLeft: 5 });
            this.props.setColsWidthLeft(5);
        }
    }

    setColsWidthRight = value => {
        if (value >= 1 && value <= 5) {
            this.setState({ ColsWidthRight: value });
            this.props.setColsWidthRight(value);
        } else if (value < 1) {
            this.setState({ ColsWidthRight: 1 });
            this.props.setColsWidthRight(1);
        } else if (value > 5) {
            this.setState({ ColsWidthRight: 5 });
            this.props.setColsWidthRight(5);
        }
    }

    setQuestionAnswersMargin = value => {
        if (value >= 0 && value <= 5) {
            this.setState({ AnswersCols: value });
            this.props.setQuestionAnswersCols(value);
        } else if (value < 0) {
            this.setState({ AnswersCols: 0 });
            this.props.setQuestionAnswersCols(0);
        } else if (value > 5) {
            this.setState({ AnswersCols: 5 });
            this.props.setQuestionAnswersCols(5);
        }
    }

    setQuestionAnswersCols = value => {
        if (value >= 1 && value <= 6) {
            this.setState({ AnswersCols: value });
            this.props.setQuestionAnswersCols(value);
        } else if (value < 1) {
            this.setState({ AnswersCols: 1 });
            this.props.setQuestionAnswersCols(1);
        } else if (value > 6) {
            this.setState({ AnswersCols: 6 });
            this.props.setQuestionAnswersCols(6);
        }
    }

    setQuestionWidth = value => {
        this.setState({ width: value });
        this.props.setQuestionWidth(value);
    }

    setQuestionHeight = value => {
        this.setState({ height: value });
        this.props.setQuestionHeight(value);
    }

    setQuestionAnswersSeparated = value => {
        this.props.setQuestionAnswersSeparated(value);
    }

    handleCloseColorPicker = () => {
        this.setState({
            displayColorPicker: false,
            displayIconCustomColorPicker: false,
            displayIconHoverColorPicker: false,
            displayStrokeColorPicker: false,
            displaySecondSecondColorPicker: false,
            displayColorSecondCPicker: false,
            displaySecondFontColorPicker: false
        })
    };

    handleCloseSecondColorPicker = () => {
        this.setState({ displaySecondColorPicker: false })
    };

    handleCloseFontColorPicker = () => {
        this.setState({ displayFontColorPicker: false })
    };

    handleClickDisplayColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClickDisplaySecondColorPicker = () => {
        this.setState({ displayColorSecondCPicker: !this.state.displayColorSecondCPicker });
    };

    handleClickDisplaySecondColoricker = () => {
        this.setState({ displaySecondColorPicker: !this.state.displaySecondColorPicker });
    };
    handleClickDisplaySecondSecondColoricker = () => {
        this.setState({ displaySecondSecondColorPicker: !this.state.displaySecondSecondColorPicker });
    };
    handleClickDisplayFontColoricker = () => {
        this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker });
    };
    handleClickDisplaySecondFontColoricker = () => {
        this.setState({ displaySecondFontColorPicker: !this.state.displaySecondFontColorPicker });
    };

    handleDisplayIconCustomPicker = () => {
        this.setState({ displayIconCustomColorPicker: !this.state.displayIconCustomColorPicker });
    };

    handleDisplayIconHoverColorPicker = () => {
        this.setState({ displayIconHoverColorPicker: !this.state.displayIconHoverColorPicker });
    };
    handleClickDisplayStrokeColoricker = () => {
        this.setState({ displayStrokeColorPicker: !this.state.displayStrokeColorPicker });
    };

    handleIconHoverColorhandleIconCustomChange = (color) => {
        this.setState({ IconHoverColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setIconHoverColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    handleIconCustomColorhandleIconCustomChange = (color) => {
        this.setState({ IconCustomColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setIconCustomColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    handleColorChange = (color) => {
        this.setState({ BorderColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setQuestionBorderColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setMainColor = (color) => {
        this.setState({ MainColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setMainColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setSecondMainColor = (color) => {
        this.setState({ SecondMainColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setSecondMainColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setSecondColor = (color) => {
        this.setState({ SecondColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setSecondColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };
    setSecondSecondColor = (color) => {
        this.setState({ SecondSecondColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setSecondSecondColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setFontColor = (color) => {
        this.setState({ FontColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setFontColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setSecondFontColor = (color) => {
        this.setState({ SecondFontColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setSecondFontColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setColor = (color) => {
        this.setState({ Color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setStrokeColor = (color) => {
        this.setState({ StrokeColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setStrokeColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setQuestionAnswersTypeMargin = value => {
        if (value >= 0 && value <= 5) {
            this.setState({ AnswersMargin: value });
            this.props.setQuestionAnswersMargin(value);
        } else if (value < 0) {
            this.setState({ AnswersMargin: 0 });
            this.props.setQuestionAnswersMargin(0);
        } else if (value > 5) {
            this.setState({ AnswersMargin: 5 });
            this.props.setQuestionAnswersMargin(5);
        }
    }

    setQuestionAnswersColsMargin = value => {
        if (value >= 0 && value <= 5) {
            this.setState({ AnswersColsMargin: value });
            this.props.setQuestionAnswersColsMargin(value);
        } else if (value < 0) {
            this.setState({ AnswersColsMargin: 0 });
            this.props.setQuestionAnswersColsMargin(0);
        } else if (value > 5) {
            this.setState({ AnswersColsMargin: 5 });
            this.props.setQuestionAnswersColsMargin(5);
        }
    }

    playBackgroundSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.BackgroundSound !== 'custom' && this.state.BackgroundSound !== 'random' && this.state.BackgroundSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.BackgroundSound);
                this.audio.play();
            } else if (this.state.BackgroundSound === 'custom' && this.state.CustomBackgroundSound) {
                this.audio = new Audio(this.state.CustomBackgroundSound);
                this.audio.play();
            }
        }
    }

    SplashSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else if (this.state.SplashSound) {
            this.audio = new Audio(this.state.SplashSound);
            this.audio.play();
        }
    }

    renderCustomStyle() {
        const { t, typeId } = this.props;
        if (typeId === 1) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerOrder.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                                className="w-100"
                                value={this.state.AnswerOrder}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerOrder.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswerOrder.title')}>
                                <option value="orderd">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.orderd')}</option>
                                <option value="notorderd">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.notorderd')}</option>
                                <option value="absolute">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.absolute')}</option>
                            </select>
                            <button type="button">
                                <i className="fas fa-sort-amount-down"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersCols')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersCols')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersCols')}
                                value={this.state.AnswersCols}
                                onChange={e => this.setQuestionAnswersCols(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-line-columns"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectType(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectType}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.title')}>
                                <option value="radio">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.radio')}</option>
                                <option value="checkbox">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.checkbox')}</option>
                            </select>
                            <button type="button" >
                                <i className="fas fa-check-square"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectClass(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectClass}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectClass}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-v"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fal fa-check-double"></i> {t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAutoCorrection(e.target.value)}
                                className="w-100"
                                value={this.state.AutoCorrection}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}>
                                <option value="false">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.false')}</option>
                                <option value="true">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.true')}</option>
                            </select>
                            <button type="button">
                                <i className="fal fa-check-double"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fal fa-pencil-ruler"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectTemplate(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectTemplate}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.title')}>
                                <option value="auto">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.auto')}</option>
                                <option value="circle">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.circle')}</option>
                                <option value="rounded">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.rounded')}</option>
                            </select>
                            <button type="button">
                                <i className="fal fa-pencil-ruler"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 2) {
            return (
                <>
                    <div className="form-group mb-3 w-100">
                        <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerOrder.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                                className="w-100"
                                value={this.state.AnswerOrder}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerOrder.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswerOrder.title')}>
                                <option value="vertical">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.vertical')}</option>
                                <option value="horizontal">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.horizontal')}</option>
                            </select>
                            <button type="button">
                                <i className="fas fa-sort-amount-down"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 3) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                                className="w-100"
                                value={this.state.AnswerOrder}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}>
                                <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.bottom')}</option>
                                <option value="top">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.top')}</option>
                                <option value="innerBottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerBottom')}</option>
                                <option value="innerTop">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerTop')}</option>
                            </select>
                            <button type="button">
                                <i className="fas fa-sort-amount-down"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersCols}
                                onChange={e => this.setQuestionAnswersMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-line-columns"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectClass(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectClass}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconClass.title')}>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectClass}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectType(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectType}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}>
                                <option value="fa-duotone">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fad')}</option>
                                <option value="fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fas')}</option>
                                <option value="fa-regular">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.far')}</option>
                                <option value="fa-thin">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fal')}</option>
                                <option value="fa-light">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.light')}</option>
                                <option value="fa-sharp fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.sharp')}</option>
                                <option value="fab">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fab')}</option>
                            </select>
                            <button type="button" >
                                <i className={` ${this.state.AnswersSelectType} fa-volume `}></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-v"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 4 || typeId === 5) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectClass(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectClass}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconClass.title')}>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectClass}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectType(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectType}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}>
                                <option value="fa-duotone">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fad')}</option>
                                <option value="fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fas')}</option>
                                <option value="fa-regular">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.far')}</option>
                                <option value="fa-thin">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fal')}</option>
                                <option value="fa-light">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.light')}</option>
                                <option value="fa-sharp fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.sharp')}</option>
                                <option value="fab">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fab')}</option>
                            </select>
                            <button type="button" >
                                <i className={` ${this.state.AnswersSelectType} fa-volume `}></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-v"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fal fa-check-double"></i> {t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAutoCorrection(e.target.value)}
                                className="w-100"
                                value={this.state.AutoCorrection}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}>
                                <option value="false">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.false')}</option>
                                <option value="true">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.true')}</option>
                            </select>
                            <button type="button">
                                <i className="fal fa-check-double"></i>
                            </button>
                        </div>
                    </div>

                </>
            );
        } else if (typeId === 6) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectClass(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectClass}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconClass.title')}>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.SoundIconClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectClass}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectType(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectType}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}>
                                <option value="fa-duotone">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fad')}</option>
                                <option value="fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fas')}</option>
                                <option value="fa-regular">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.far')}</option>
                                <option value="fa-thin">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fal')}</option>
                                <option value="fa-light">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.light')}</option>
                                <option value="fa-sharp fa-solid">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.sharp')}</option>
                                <option value="fab">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fab')}</option>
                            </select>
                            <button type="button" >
                                <i className={` ${this.state.AnswersSelectType} fa-volume `}></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fad fa-circle"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerSelectedType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                                className="w-100"
                                value={this.state.AnswerOrder}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerSelectedType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswerSelectedType.title')}>
                                <option value="circle">{t('Pages.Lessons.Questions.forms.tools.AnswerSelectedType.circle')}</option>
                                <option value="square">{t('Pages.Lessons.Questions.forms.tools.AnswerSelectedType.square')}</option>
                                <option value="line">{t('Pages.Lessons.Questions.forms.tools.AnswerSelectedType.line')}</option>
                            </select>
                            <button type="button">
                                <i className="fad fa-circle"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-h"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSeparated.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSeparated.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSeparated.title')}
                                value={this.state.AnswersSeparated}
                                onChange={e => this.setQuestionAnswersSeparated(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-h"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-v"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fal fa-check-double"></i> {t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAutoCorrection(e.target.value)}
                                className="w-100"
                                value={this.state.AutoCorrection}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}>
                                <option value="false">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.false')}</option>
                                <option value="true">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.true')}</option>
                            </select>
                            <button type="button">
                                <i className="fal fa-check-double"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 7) {
            const styles = reactCSS({
                'default': {
                    color: {
                        width: '36px',
                        height: '14px',
                        borderRadius: '2px',
                    },
                    swatch: {
                        padding: '5px',
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                    },
                    popover: {
                        position: 'absolute',
                        zIndex: '12',
                    },
                    cover: {
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                    },
                },
            });
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                        <div className="search-form">
                            <input
                                className="w-100 form-control"
                                type="text"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                title={t('Pages.Lessons.Questions.forms.tools.color')}
                                readOnly={true}
                                value={this.state.BorderColor}
                            />
                            <button
                                type="button"
                                onClick={this.handleClickDisplayColorPicker}
                                style={{
                                    color: this.state.BorderColor !== 'transparent' ? this.state.BorderColor : "",
                                }}>
                                <i className="fas fa-tint"></i>
                            </button>
                            {
                                this.state.displayColorPicker ?
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                        <SketchPicker color={this.state.BorderColor} onChange={this.handleColorChange} />
                                    </div> : null
                            }
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-border-style"></i> {t('Pages.Lessons.Questions.forms.tools.BorderStyle.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionBorderStyle(e.target.value)}
                                className="w-100"
                                value={this.state.BorderStyle}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderStyle.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.BorderStyle.title')}>
                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.none')}</option>
                                <option value="solid">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.solid')}</option>
                                <option value="dashed">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.dashed')}</option>
                                <option value="dotted">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.dotted')}</option>
                                <option value="double">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.double')}</option>
                            </select>
                            <button type="button">
                                <i className="fas fa-border-style"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-border-all"></i> {t('Pages.Lessons.Questions.forms.tools.BorderWidth.title')}</label>
                        <div className="search-form">
                            <input
                                className="w-100 form-control"
                                type="text"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.title')}
                                value={this.state.BorderWidth}
                                onChange={e => this.props.setQuestionBorderWidth(e.target.value)}
                            />
                            <button type="button">
                                <i className="fas fa-border-all"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-line-columns"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fal fa-check-double"></i> {t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAutoCorrection(e.target.value)}
                                className="w-100"
                                value={this.state.AutoCorrection}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}>
                                <option value="false">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.false')}</option>
                                <option value="true">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.true')}</option>
                            </select>
                            <button type="button">
                                <i className="fal fa-check-double"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fal fa-pencil-ruler"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectTemplate(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectTemplate}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.title')}>
                                <option value="auto">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.auto')}</option>
                                <option value="circle">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.circle')}</option>
                                <option value="rounded">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectTemplate.rounded')}</option>
                            </select>
                            <button type="button">
                                <i className="fal fa-pencil-ruler"></i>
                            </button>
                        </div>
                    </div>

                </>
            );
        } else if (typeId === 8) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectClass(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectClass}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}>
                                <option value="">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectClass}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-border-style"></i> {t('Pages.Lessons.Questions.forms.tools.TableType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionBorderStyle(e.target.value)}
                                className="w-100"
                                value={this.state.BorderStyle}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.TableType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.TableType.title')}>
                                <option value="">{t('Pages.Lessons.Questions.forms.tools.TableType.auto')}</option>
                                <option value="borderless">{t('Pages.Lessons.Questions.forms.tools.TableType.borderless')}</option>
                                <option value="striped">{t('Pages.Lessons.Questions.forms.tools.TableType.striped')}</option>
                                <option value="bordered">{t('Pages.Lessons.Questions.forms.tools.TableType.bordered')}</option>
                            </select>
                            <button type="button" >
                                <i className={` fa-border-style fas  `}></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.TableClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectType(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectType}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.TableClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.TableClass.title')}>
                                <option value="">{t('Pages.Lessons.Questions.forms.tools.TableClass.none')}</option>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.TableClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.TableClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.TableClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.TableClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.TableClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.TableClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.TableClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectType}`}>
                                <i className={` fas fa-tint `}></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-line-columns"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionBorderColor(e.target.value)}
                                className="w-100"
                                value={this.state.BorderColor}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.title')}>
                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.none')}</option>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.TableHeaderClass.secondary')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.BorderColor}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 9) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswersSelectClass(e.target.value)}
                                className="w-100"
                                value={this.state.AnswersSelectClass}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}>
                                <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                <option value="white">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.white')}</option>
                            </select>
                            <button type="button" className={`text-${this.state.AnswersSelectClass}`}>
                                <i className="fas fa-tint"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-border-style"></i> {t('Pages.Lessons.Questions.forms.tools.connectionType.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionBorderStyle(e.target.value)}
                                className="w-100"
                                value={this.state.BorderStyle}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.connectionType.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.connectionType.title')}>
                                <option value="square">{t('Pages.Lessons.Questions.forms.tools.connectionType.square')}</option>
                                <option value="circle">{t('Pages.Lessons.Questions.forms.tools.connectionType.circle')}</option>
                                <option value="round">{t('Pages.Lessons.Questions.forms.tools.connectionType.round')}</option>
                            </select>
                            <button type="button" >
                                <i className={` fa-border-style fas  `}></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrow-down"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}
                                value={this.state.AnswersCols}
                                onChange={e => this.setQuestionAnswersMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrow-up"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-balance-scale-right"></i> {t('Pages.Lessons.Questions.forms.tools.MatchRightColumnWidth.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.MatchRightColumnWidth.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.MatchRightColumnWidth.placeholder')}
                                value={this.state.ColsWidthRight}
                                onChange={e => this.setColsWidthRight(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-balance-scale-right"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-balance-scale-left"></i> {t('Pages.Lessons.Questions.forms.tools.MatchLeftColumnWidth.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.MatchLeftColumnWidth.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.MatchLeftColumnWidth.placeholder')}
                                value={this.state.ColsWidthLeft}
                                onChange={e => this.setColsWidthLeft(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-balance-scale-left"></i>
                            </button>
                        </div>
                    </div>

                </>
            );
        } else if (typeId === 10) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                                className="w-100"
                                value={this.state.AnswerOrder}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}>
                                <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.bottom')}</option>
                                <option value="top">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.top')}</option>
                                <option value="innerBottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerBottom')}</option>
                                <option value="innerTop">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerTop')}</option>
                            </select>
                            <button type="button">
                                <i className="fas fa-sort-amount-down"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.ColumnWidth.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.ColumnWidth.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.ColumnWidth.title')}
                                value={this.state.AnswersCols}
                                onChange={e => this.setQuestionAnswersCols(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-h"></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-v"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 12) {
            return <>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fal fa-check-double"></i> {t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}</label>
                    <div className="search-form">
                        <select
                            onChange={e => this.props.setQuestionAutoCorrection(e.target.value)}
                            className="w-100"
                            value={this.state.AutoCorrection}
                            placeholder={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.AutoCorrection.title')}>
                            <option value="false">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.false')}</option>
                            <option value="true">{t('Pages.Lessons.Questions.forms.tools.AutoCorrection.true')}</option>
                        </select>
                        <button type="button">
                            <i className="fal fa-check-double"></i>
                        </button>
                    </div>
                </div>

                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                    <div className="search-form">
                        <input
                            type="text"
                            className="w-100"
                            placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                            value={this.state.AnswersMargin}
                            onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                        <button type="button">
                            <i className="fas fa-arrows-alt-v"></i>
                        </button>
                    </div>
                </div>
            </>
        } else if (typeId === 13) {
            return <>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}</label>
                    <div className="search-form">
                        <select
                            onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                            className="w-100"
                            value={this.state.AnswerOrder}
                            placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}>
                            <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.bottom')}</option>
                            <option value="top">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.top')}</option>
                            <option value="innerBottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerBottom')}</option>
                            <option value="innerTop">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerTop')}</option>
                        </select>
                        <button type="button">
                            <i className="fas fa-sort-amount-down"></i>
                        </button>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.ColumnWidth.title')}</label>
                    <div className="search-form">
                        <input
                            type="text"
                            className="w-100"
                            placeholder={t('Pages.Lessons.Questions.forms.tools.ColumnWidth.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.ColumnWidth.title')}
                            value={this.state.AnswersCols}
                            onChange={e => this.setQuestionAnswersCols(e.target.value)} />
                        <button type="button">
                            <i className="fas fa-arrows-h"></i>
                        </button>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                    <div className="search-form">
                        <input
                            type="text"
                            className="w-100"
                            placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                            value={this.state.AnswersMargin}
                            onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                        <button type="button">
                            <i className="fas fa-arrows-alt-v"></i>
                        </button>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}</label>
                    <div className="search-form">
                        <input
                            type="text"
                            className="w-100"
                            placeholder={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}
                            value={this.state.AnswersColsMargin}
                            onChange={e => this.setQuestionAnswersColsMargin(e.target.value)} />
                        <button type="button">
                            <i className="fas fa-arrow-up"></i>
                        </button>
                    </div>
                </div>
            </>;
        } else if (typeId === 14) {
            return (
                <>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}</label>
                        <div className="search-form">
                            <select
                                onChange={e => this.props.setQuestionAnswerOrder(e.target.value)}
                                className="w-100"
                                value={this.state.AnswerOrder}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}>
                                <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.bottom')}</option>
                                <option value="top">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.top')}</option>
                                <option value="innerBottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerBottom')}</option>
                                <option value="innerTop">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.innerTop')}</option>
                            </select>
                            <button type="button">
                                <i className="fas fa-sort-amount-down"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                value={this.state.AnswersCols}
                                onChange={e => this.setQuestionAnswersMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrows-alt-v"></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.title')}</label>
                        <div className="search-form">
                            <input
                                type="text"
                                className="w-100"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.title')}
                                value={this.state.AnswersMargin}
                                onChange={e => this.setQuestionAnswersTypeMargin(e.target.value)} />
                            <button type="button">
                                <i className="fas fa-arrow-down"></i>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (typeId === 19 || typeId === 20 || typeId === 23 || typeId === 24 || typeId === 25 || typeId === 26 || typeId === 27 || typeId === 28 || typeId === 29 || typeId === 30 || typeId === 31) {
            return <>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}</label>
                    <div className="search-form">
                        <select
                            onChange={e => this.props.setAnswerOrder(e.target.value)}
                            className="w-100"
                            value={this.state.AnswerOrder}
                            placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.AnswerPosition.title')}>
                            <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.bottom')}</option>
                            <option value="top">{t('Pages.Lessons.Questions.forms.tools.AnswerPosition.top')}</option>
                        </select>
                        <button type="button">
                            <i className="fas fa-sort-amount-down"></i>
                        </button>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}</label>
                    <div className="search-form">
                        <input
                            type="text"
                            className="w-100"
                            placeholder={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.placeholder')}
                            title={t('Pages.Lessons.Questions.forms.tools.MatchAnswersMargin.title')}
                            value={this.state.AnswersColsMargin}
                            onChange={e => this.props.setAnswersColsMargin(e.target.value)} />
                        <button type="button">
                            <i className="fas fa-arrow-up"></i>
                        </button>
                    </div>
                </div>
            </>;
        }
        return "";
    }

    renderCursor = () => {
        return this.Cursor.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    render() {
        const { t } = this.props;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <>
                <li id="PopoverAnswers" title={t('Pages.Lessons.Questions.forms.tools.answers')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="icon fal fa-bullseye-arrow"></i>
                            {t('Pages.Lessons.Questions.forms.tools.answers')}
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="icon fal fa-bullseye-arrow"></i>
                                {t('Pages.Lessons.Questions.forms.tools.answers')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">
                                    {this.renderCustomStyle()}

                                    {
                                        this.props.subScreen === true ?
                                            <>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ButtonOneStyle')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setButtonOneStyle(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.ButtonOneStyle ? this.state.ButtonOneStyle : ''}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ButtonOneStyle')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ButtonOneStyle')}>
                                                            <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                            <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                            <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                            <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                            <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                            <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                            <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                            <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                            <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                            <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                            <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                            <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                            <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>
                                                        </select>
                                                        <button type="button" className={`text-${this.state.ButtonOneStyle}`} >
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ButtonSecondStyle')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setButtonSecondStyle(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.ButtonSecondStyle ? this.state.ButtonSecondStyle : ''}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ButtonSecondStyle')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.ButtonSecondStyle')}>
                                                            <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                            <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                            <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                            <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                            <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                            <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                            <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                            <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                            <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                            <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                            <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                            <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                            <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>
                                                        </select>
                                                        <button type="button" className={`text-${this.state.ButtonSecondStyle}`} >
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    this.props.type === "success" ?
                                                        <div className="form-group ">
                                                            <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.ButtonNextStyle')}</label>
                                                            <div className="search-form">
                                                                <select
                                                                    onChange={e => this.props.setButtonNextStyle(e.target.value)}
                                                                    className="w-100"
                                                                    value={this.state.ButtonNextStyle ? this.state.ButtonNextStyle : ''}
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.ButtonNextStyle')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.ButtonNextStyle')}>
                                                                    <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                                    <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                                    <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                                    <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                                    <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                                    <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                                    <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                                    <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                                    <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                                    <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                                    <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                                    <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                                    <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>
                                                                </select>
                                                                <button type="button" className={`text-${this.state.ButtonNextStyle}`} >
                                                                    <i className="fas fa-tint"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }


                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                            readOnly={true}
                                                            value={this.state.FontColor}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleClickDisplayFontColoricker}
                                                            style={{
                                                                color: this.state.FontColor !== 'transparent' ? this.state.FontColor : "",
                                                            }}>
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                        {
                                                            this.state.displayFontColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                    <SketchPicker color={this.state.FontColor} onChange={this.setFontColor} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        this.props.hasCursor === true ?
                                            <>
                                                <div className={`form-group mb-3`}>
                                                    <label className="text-dark h6"><i className="icon fas fa-mouse"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Cursor')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setCursor(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.Cursor ? this.state.Cursor : 'none'}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                            {this.renderCursor()}
                                                        </select>
                                                        <button type="button" >
                                                            <i className="fas fa-mouse"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                            value={this.state.CursorCustom ? this.state.CursorCustom : ''}
                                                            onChange={e => this.props.setCursorCustom(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        this.props.hasButton === true ?
                                            <>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                            readOnly={true}
                                                            value={this.state.FontColor}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleClickDisplayFontColoricker}
                                                            style={{
                                                                color: this.state.FontColor !== 'transparent' ? this.state.FontColor : "",
                                                            }}>
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                        {
                                                            this.state.displayFontColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                    <SketchPicker color={this.state.FontColor} onChange={this.setFontColor} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}
                                                            value={this.state.StrokeWidth}
                                                            onChange={e => this.props.setStrokeWidth(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.StrokeColor')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.StrokeColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.StrokeColor')}
                                                            readOnly={true}
                                                            value={this.state.StrokeColor}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleClickDisplayStrokeColoricker}
                                                            style={{
                                                                color: this.state.StrokeColor !== 'transparent' ? this.state.StrokeColor : "",
                                                            }}>
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                        {
                                                            this.state.displayStrokeColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                    <SketchPicker color={this.state.StrokeColor} onChange={this.setStrokeColor} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fad fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.FontSize.title')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.FontSize.title')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.FontSize.title')}
                                                            value={this.state.FontSize}
                                                            onChange={e => this.props.setFontSize(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fad fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}
                                                            value={this.state.MarginBottom}
                                                            onChange={e => this.props.setMarginBottom(e.target.value)} />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            this.props.isIntroScreen === true ?
                                                <>
                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="fal fa-shapes"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Icon')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                className="w-100 form-control"
                                                                type="text"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.Icon')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.objects.Icon')}
                                                                value={this.state.Icon ? this.state.Icon : ''}
                                                                onChange={e => this.props.setIcon(e.target.value)}
                                                            />
                                                            <button type="button" className={`text-${this.state.TryColor}`}  >
                                                                <i className={`text-${this.state.IconColor} ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'shapes'}-left `}  ></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}</label>
                                                        <div className="search-form">
                                                            <select
                                                                onChange={e => this.props.setIconType(e.target.value)}
                                                                className="w-100"
                                                                value={this.state.IconType}
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconType.placeholder')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}>
                                                                <option value="fad">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fad')}</option>
                                                                <option value="fas">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fas')}</option>
                                                                <option value="far">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.far')}</option>
                                                                <option value="fal">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fal')}</option>
                                                            </select>
                                                            <button type="button" >
                                                                <i className={`text-${this.state.IconColor} ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'play'} `}></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                className="w-100 form-control"
                                                                type="text"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                readOnly={true}
                                                                value={this.state.FontColor}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={this.handleClickDisplayFontColoricker}
                                                                style={{
                                                                    color: this.state.FontColor !== 'transparent' ? this.state.FontColor : "",
                                                                }}>
                                                                <i className="fas fa-tint"></i>
                                                            </button>
                                                            {
                                                                this.state.displayFontColorPicker ?
                                                                    <div style={styles.popover}>
                                                                        <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                        <SketchPicker color={this.state.FontColor} onChange={this.setFontColor} />
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fad fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.FontSize.title')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                type="text"
                                                                className="w-100"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.FontSize.title')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.FontSize.title')}
                                                                value={this.state.FontSize}
                                                                onChange={e => this.props.setFontSize(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className={`form-group mb-3`}>
                                                        <label className="text-dark h6"><i className="icon fas fa-mouse"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Cursor')}</label>
                                                        <div className="search-form">
                                                            <select
                                                                onChange={e => this.props.setCursor(e.target.value)}
                                                                className="w-100"
                                                                value={this.state.Cursor ? this.state.Cursor : 'none'}
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                                {this.renderCursor()}
                                                            </select>
                                                            <button type="button" >
                                                                <i className="fas fa-mouse"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                className="w-100 form-control"
                                                                type="text"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                                value={this.state.CursorCustom ? this.state.CursorCustom : ''}
                                                                onChange={e => this.props.setCursorCustom(e.target.value)}
                                                            />
                                                            <button type="button" >
                                                                <i className="fas fa-image"  ></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="widget-header p-1 w-100 mb-3"></div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                className="w-100 form-control"
                                                                type="text"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                readOnly={true}
                                                                value={this.state.Color}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={this.handleClickDisplayColorPicker}
                                                                style={{
                                                                    color: this.state.Color !== 'transparent' ? this.state.Color : "",
                                                                }}>
                                                                <i className="fas fa-tint"></i>
                                                            </button>
                                                            {
                                                                this.state.displayColorPicker ?
                                                                    <div style={styles.popover}>
                                                                        <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                        <SketchPicker color={this.state.Color} onChange={this.setColor} />
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fad fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.FontSize.title')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                type="text"
                                                                className="w-100"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.FontSize.title')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.FontSize.title')}
                                                                value={this.state.Size}
                                                                onChange={e => this.props.setSize(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                type="text"
                                                                className="w-100"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}
                                                                value={this.state.StrokeWidth}
                                                                onChange={e => this.props.setStrokeWidth(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.StrokeColor')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                className="w-100 form-control"
                                                                type="text"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.StrokeColor')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.StrokeColor')}
                                                                readOnly={true}
                                                                value={this.state.StrokeColor}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={this.handleClickDisplayStrokeColoricker}
                                                                style={{
                                                                    color: this.state.StrokeColor !== 'transparent' ? this.state.StrokeColor : "",
                                                                }}>
                                                                <i className="fas fa-tint"></i>
                                                            </button>
                                                            {
                                                                this.state.displayStrokeColorPicker ?
                                                                    <div style={styles.popover}>
                                                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                        <SketchPicker color={this.state.StrokeColor} onChange={this.setStrokeColor} />
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                <>
                                                    {
                                                        this.props.setQuestionAnswersAlign ?
                                                            <div className="form-group mb-3">
                                                                <label className="text-dark h6"><i className="icon fas fa-align-justify"></i> {t('Pages.Lessons.Questions.forms.tools.align.title')}</label>
                                                                <div className="search-form">
                                                                    <select
                                                                        onChange={e => this.props.setQuestionAnswersAlign(e.target.value)}
                                                                        className="w-100"
                                                                        value={this.state.AnswersAlign}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.align.placeholder')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.align.title')}>
                                                                        <option value="center">{t('Pages.Lessons.Questions.forms.tools.align.center')}</option>
                                                                        <option value="left">{t('Pages.Lessons.Questions.forms.tools.align.left')}</option>
                                                                        <option value="right">{t('Pages.Lessons.Questions.forms.tools.align.right')}</option>
                                                                    </select>
                                                                    <button type="button">
                                                                        <i className="fas fa-align-justify"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.props.setQuestionHeight ?
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.height')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        type="text"
                                                                        className="w-100"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.height')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.height')}
                                                                        value={this.state.height}
                                                                        onChange={e => this.setQuestionHeight(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.props.setQuestionWidth ?
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.width')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        type="text"
                                                                        className="w-100"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.width')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.width')}
                                                                        value={this.state.width}
                                                                        onChange={e => this.setQuestionWidth(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.props.setLogoMargin ?
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        type="text"
                                                                        className="w-100"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.MatchCategoryMargin.placeholder')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}
                                                                        value={this.state.Margin}
                                                                        onChange={e => this.props.setLogoMargin(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.props.hideLogo ? "" :
                                                            this.props.setCustomBackground ?
                                                                <div className="form-group mb-3">
                                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.LogoStyle')}</label>
                                                                    <div className="search-form">
                                                                        <input
                                                                            className="w-100 form-control"
                                                                            type="text"
                                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.LogoStyle')}
                                                                            title={t('Pages.Lessons.Questions.forms.tools.LogoStyle')}
                                                                            value={this.state.CustomBackground ? this.state.CustomBackground : ''}
                                                                            onChange={e => this.props.setCustomBackground(e.target.value)}
                                                                        />
                                                                        <button type="button" >
                                                                            <i className="fas fa-image"  ></i>
                                                                        </button>
                                                                    </div>
                                                                </div> : ""
                                                    }

                                                    {
                                                        this.props.setLogoPositionX ?
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-align-justify"></i> {t('Pages.Lessons.Questions.forms.tools.align.title')}</label>
                                                                <div className="search-form">
                                                                    <select
                                                                        onChange={e => this.props.setLogoPositionX(e.target.value)}
                                                                        className="w-100"
                                                                        value={this.state.PositionX}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.Padding.placeholder')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.Padding.title')}>
                                                                        <option value="left">{t('Pages.Lessons.Questions.forms.tools.Padding.left')}</option>
                                                                        <option value="right">{t('Pages.Lessons.Questions.forms.tools.Padding.right')}</option>
                                                                    </select>
                                                                    <button type="button">
                                                                        <i className="fas fa-align-justify"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.props.setLogoPositionY ?
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-align-justify"></i> {t('Pages.Lessons.Questions.forms.tools.align.title')}</label>
                                                                <div className="search-form">
                                                                    <select
                                                                        onChange={e => this.props.setLogoPositionY(e.target.value)}
                                                                        className="w-100"
                                                                        value={this.state.PositionY}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.Padding.placeholder')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.Padding.title')}>
                                                                        <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.Padding.bottom')}</option>
                                                                        <option value="top">{t('Pages.Lessons.Questions.forms.tools.Padding.top')}</option>
                                                                    </select>
                                                                    <button type="button">
                                                                        <i className="fas fa-align-justify"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                </>
                                    }
                                    {
                                        this.props.hasControlColor === true ?
                                            <>
                                                <div className="widget-header p-1 w-100 mb-3"></div>
                                                <div className="title w-100 text-center">{t('Pages.Lessons.Questions.forms.tools.objects.TryColorTitle')}</div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setControlColor(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.ControlColor ? this.state.ControlColor : ''}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}>
                                                            <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                            <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                            <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                            <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                            <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                            <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                            <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                            <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                            <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                            <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                            <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                            <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                            <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>

                                                        </select>
                                                        <button type="button" className={`text-${this.state.ControlColor}`} >
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.ControlColor === "custom" ?
                                                        <>
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        readOnly={true}
                                                                        value={this.state.MainColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplayColorPicker}
                                                                        style={{
                                                                            color: this.state.MainColor !== 'transparent' ? this.state.MainColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displayColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                                <SketchPicker color={this.state.MainColor} onChange={this.setMainColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.SecondColor')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.SecondColor')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.SecondColor')}
                                                                        readOnly={true}
                                                                        value={this.state.SecondColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplaySecondColoricker}
                                                                        style={{
                                                                            color: this.state.SecondColor !== 'transparent' ? this.state.SecondColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displaySecondColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseSecondColorPicker} />
                                                                                <SketchPicker color={this.state.SecondColor} onChange={this.setSecondColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                        readOnly={true}
                                                                        value={this.state.FontColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplayFontColoricker}
                                                                        style={{
                                                                            color: this.state.FontColor !== 'transparent' ? this.state.FontColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displayFontColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                                <SketchPicker color={this.state.FontColor} onChange={this.setFontColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }

                                                <div className="widget-header p-1 w-100 mb-3"></div>
                                                <div className="title w-100 text-center">{t('Pages.Lessons.Questions.forms.tools.objects.TryColorSecond')}</div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setSecondControlColor(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.SecondControlColor ? this.state.SecondControlColor : ''}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.TryColor')}>
                                                            <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                            <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                            <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                            <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                            <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                            <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                            <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                            <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                            <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                            <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                            <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                            <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                            <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>

                                                        </select>
                                                        <button type="button" className={`text-${this.state.SecondControlColor}`} >
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.SecondControlColor === "custom" ?
                                                        <>
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                        readOnly={true}
                                                                        value={this.state.SecondMainColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplaySecondColorPicker}
                                                                        style={{
                                                                            color: this.state.SecondMainColor !== 'transparent' ? this.state.SecondMainColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displayColorSecondCPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                                <SketchPicker color={this.state.SecondMainColor} onChange={this.setSecondMainColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.SecondColor')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.SecondColor')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.SecondColor')}
                                                                        readOnly={true}
                                                                        value={this.state.SecondSecondColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplaySecondSecondColoricker}
                                                                        style={{
                                                                            color: this.state.SecondSecondColor !== 'transparent' ? this.state.SecondSecondColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displaySecondSecondColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseSecondColorPicker} />
                                                                                <SketchPicker color={this.state.SecondSecondColor} onChange={this.setSecondSecondColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                                        readOnly={true}
                                                                        value={this.state.SecondFontColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplaySecondFontColoricker}
                                                                        style={{
                                                                            color: this.state.SecondFontColor !== 'transparent' ? this.state.SecondFontColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displaySecondFontColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseSecondFontColorPicker} />
                                                                                <SketchPicker color={this.state.SecondFontColor} onChange={this.setSecondFontColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                                <div className="form-group mb-3">
                                                    <label className="text-dark h6"><i className="fal fa-repeat"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Repeat.title')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="number"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.Repeat.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.Repeat.title')}
                                                            value={this.state.Repeat ? this.state.Repeat : ''}
                                                            onChange={e => this.props.setRepeat(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-repeat"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="widget-header p-1 w-100 mb-3"></div>
                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="fal fa-shapes"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Icon')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.Icon')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.Icon')}
                                                            value={this.state.Icon ? this.state.Icon : ''}
                                                            onChange={e => this.props.setIcon(e.target.value)}
                                                        />
                                                        <button type="button" className={`text-${this.state.TryColor}`}  >
                                                            <i className={`text-${this.state.IconColor} ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'shapes'}-left `}  ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setIconType(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.IconType}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.SoundIconType.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.SoundIconType.title')}>
                                                            <option value="fad">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fad')}</option>
                                                            <option value="fas">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fas')}</option>
                                                            <option value="far">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.far')}</option>
                                                            <option value="fal">{t('Pages.Lessons.Questions.forms.tools.SoundIconType.fal')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className={`text-${this.state.IconColor} ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'shapes'}-left `}></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-arrows"></i> {t('Pages.Lessons.Questions.forms.tools.ArrowsPosition.title')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setIconPosition(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.IconPosition}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.ArrowsPosition.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.ArrowsPosition.title')}>
                                                            <option value="in">{t('Pages.Lessons.Questions.forms.tools.ArrowsPosition.in')}</option>
                                                            <option value="out">{t('Pages.Lessons.Questions.forms.tools.ArrowsPosition.out')}</option>
                                                        </select>
                                                        <button type="button" >
                                                            <i className={`fas fa-arrows `}></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.objects.IconColor')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setIconColor(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.IconColor ? this.state.IconColor : ''}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.IconColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.IconColor')}>
                                                            <option value="default">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.default')}</option>
                                                            <option value="custom">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.custom')}</option>
                                                            <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                            <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                            <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                            <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                            <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                            <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                            <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                            <option value="light">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.light')}</option>
                                                            <option value="pink">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.pink')}</option>
                                                            <option value="purple">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.purple')}</option>
                                                            <option value="orange">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.orange')}</option>
                                                        </select>
                                                        <button type="button" className={`text-${this.state.IconColor} `} >
                                                            <i className={`text-${this.state.IconColor} ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'shapes'}-left `}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.IconColor === "custom" ?
                                                        <div className="form-group">
                                                            <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                    readOnly={true}
                                                                    value={this.state.IconCustomColor}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={this.handleDisplayIconCustomPicker}
                                                                    style={{
                                                                        color: this.state.IconCustomColor !== 'transparent' ? this.state.IconCustomColor : "",
                                                                    }}>
                                                                    <i className={` ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'shapes'}-left `}></i>
                                                                </button>
                                                                {
                                                                    this.state.displayIconCustomColorPicker ?
                                                                        <div style={styles.popover}>
                                                                            <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                            <SketchPicker color={this.state.IconCustomColor} onChange={this.handleIconCustomColorhandleIconCustomChange} />
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                            readOnly={true}
                                                            value={this.state.IconHoverColor}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleDisplayIconHoverColorPicker}
                                                            style={{
                                                                color: this.state.IconHoverColor !== 'transparent' ? this.state.IconHoverColor : "",
                                                            }}>
                                                            <i className={` ${this.state.IconType} fa-${this.state.Icon ? this.state.Icon : 'shapes'}-left `}></i>
                                                        </button>
                                                        {
                                                            this.state.displayIconHoverColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                    <SketchPicker color={this.state.IconHoverColor} onChange={this.handleIconHoverColorhandleIconCustomChange} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.CustomBackground.title')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.CustomBackground.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.CustomBackground.title')}
                                                            value={this.state.CustomBackground ? this.state.CustomBackground : ''}
                                                            onChange={e => this.props.setCustomBackground(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-image"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        this.props.hasFontColor === true ?
                                            <>
                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                            readOnly={true}
                                                            value={this.state.FontColor}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={this.handleClickDisplayFontColoricker}
                                                            style={{
                                                                color: this.state.FontColor !== 'transparent' ? this.state.FontColor : "",
                                                            }}>
                                                            <i className="fas fa-tint"></i>
                                                        </button>
                                                        {
                                                            this.state.displayFontColorPicker ?
                                                                <div style={styles.popover}>
                                                                    <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                    <SketchPicker color={this.state.FontColor} onChange={this.setFontColor} />
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.props.hasStroke === true ?
                                                        <>
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        type="text"
                                                                        className="w-100"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.StrokeWidth')}
                                                                        value={this.state.StrokeWidth}
                                                                        onChange={e => this.props.setStrokeWidth(e.target.value)} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.StrokeColor')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.StrokeColor')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.StrokeColor')}
                                                                        readOnly={true}
                                                                        value={this.state.StrokeColor}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleClickDisplayStrokeColoricker}
                                                                        style={{
                                                                            color: this.state.StrokeColor !== 'transparent' ? this.state.StrokeColor : "",
                                                                        }}>
                                                                        <i className="fas fa-tint"></i>
                                                                    </button>
                                                                    {
                                                                        this.state.displayStrokeColorPicker ?
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                                <SketchPicker color={this.state.StrokeColor} onChange={this.setStrokeColor} />
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        type="text"
                                                                        className="w-100"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswersMargin.title')}
                                                                        value={this.state.Margin}
                                                                        onChange={e => this.props.setMargin(e.target.value)} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="text-dark h6"><i className="icon fad fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        type="text"
                                                                        className="w-100"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.MarginBottom.title')}
                                                                        value={this.state.MarginBottom}
                                                                        onChange={e => this.props.setMarginBottom(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                            : ""
                                    }
                                    {
                                        this.props.hasDimensions === true ?
                                            <>
                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.height')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.height')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.height')}
                                                            value={this.state.height}
                                                            onChange={e => this.setQuestionHeight(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-dark h6"><i className="icon fas fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.width')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.width')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.width')}
                                                            value={this.state.width}
                                                            onChange={e => this.setQuestionWidth(e.target.value)} />
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        this.props.onlyFont === true ?
                                            <div className="form-group">
                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.FontColor')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.FontColor')}
                                                        readOnly={true}
                                                        value={this.state.FontColor}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={this.handleClickDisplayFontColoricker}
                                                        style={{
                                                            color: this.state.FontColor !== 'transparent' ? this.state.FontColor : "",
                                                        }}>
                                                        <i className="fas fa-tint"></i>
                                                    </button>
                                                    {
                                                        this.state.displayFontColorPicker ?
                                                            <div style={styles.popover}>
                                                                <div style={styles.cover} onClick={this.handleCloseFontColorPicker} />
                                                                <SketchPicker color={this.state.FontColor} onChange={this.setFontColor} />
                                                            </div> : null
                                                    }
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        this.props.hasSplashTime === true ?
                                            <>

                                                <div className="form-group mb-3">
                                                    <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.BackgroundSound')}</label>
                                                    <div className="search-form">
                                                        <select
                                                            onChange={e => this.props.setGameBackgroundSound(e.target.value)}
                                                            className="w-100"
                                                            value={this.state.BackgroundSound ? this.state.BackgroundSound : 'none'}
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                            <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                            <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                            {this.renderBackgroundSound()}
                                                        </select>
                                                        <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBackgroundSound()}>
                                                            <i className="fas fa-volume"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                            value={this.state.CustomBackgroundSound ? this.state.CustomBackgroundSound : ''}
                                                            onChange={e => this.props.setBackgroundGameCustomSound(e.target.value)}
                                                        />
                                                        <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBackgroundSound()}>
                                                            <i className="fas fa-volume"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <label className="text-dark h6"><i className="fal fa-stopwatch"></i> {t('Pages.Lessons.Questions.forms.tools.objects.SplashTime')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.SplashTime')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.SplashTime')}
                                                            value={this.state.SplashTime ? this.state.SplashTime : ''}
                                                            onChange={e => this.props.setSplashTime(e.target.value)}
                                                        />
                                                        <button type="button" >
                                                            <i className="fas fa-stopwatch"  ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3 ">
                                                    <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.objects.SplashSound')}</label>
                                                    <div className="search-form">
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.objects.SplashSound')}
                                                            title={t('Pages.Lessons.Questions.forms.tools.objects.SplashSound')}
                                                            value={this.state.SplashSound ? this.state.SplashSound : ''}
                                                            onChange={e => this.props.setSplashSound(e.target.value)}
                                                        />
                                                        <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.SplashSound()}>
                                                            <i className="fas fa-volume"  ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(QuestionAnswer);
import api from '../../../../api/api';

export const fetchFileManager = path => async dispatch => {
    dispatch({ type: 'FILE_MANAGER_LOADING' });
    const response = await api.get(`/FileManager/?path=${path ? path : ""}`);
    dispatch({ type: 'FILE_MANAGER_INITIAL', files: response.data.data });
}

export const removeFileManager = (id) => async dispatch => {
    dispatch({ type: 'FILE_MANAGER_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteFileManager = (id) => async dispatch => {
    dispatch({ type: 'FILE_MANAGER_UNDO', id });
}

export const clearFileManager = (id) => async dispatch => {
    dispatch({ type: 'FILE_MANAGER_CLEAN_NODES', id });
}

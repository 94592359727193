import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

import 'bootstrap-daterangepicker/daterangepicker.css';
import configData from "../../Config/countries.json";
import api, { setToken } from '../../../api/api';
import TermsAndConditions from '../TermsAndConditions.js';
import { SchoolRegisterBySocket } from '../../../api/socket.io';

const TEST_SITE_KEY = `${process.env.REACT_APP_TEST_SITE_KEY}`;

class SingleRegFrom extends React.Component {

    state = { captchaErorr: null, IsSchool: false, errorList: [], countries: [], Sex: "male", IsTeacher: false, showTermsAndConditions: false, touchedBirthday: false, errorBirthday: '', CountryIndex: -1 };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        moment.locale('en');
        this._reCaptchaRef = React.createRef();
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }

    componentDidMount() {
        this.props.initialize({
            Address: this.props.Address,
            FirstName: this.props.FirstName,
            SecondName: this.props.SecondName,
            LastName: this.props.LastName,
            Phone: this.props.Phone,
            Birthday: this.props.Birthday,
            Sex: this.props.Sex,
            Country: this.props.Country,
            Email: this.props.Email,
            IsTeacher: this.props.IsTeacher,
            SchoolCode: this.props.SchoolCode,
            SchoolName: this.props.SchoolName,
            SchoolAddress: this.props.SchoolAddress,
            Code: this.props.Code,
            SchoolPhone: this.props.SchoolPhone,
            SchoolEmail: this.props.SchoolEmail,
            SchoolFax: this.props.SchoolFax,
            Username: this.props.Username,
            Password: this.props.Password
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.country_code) {
            var index = state.countries.findIndex(p => p.value === props.country_code);

            state.CountryIndex = index;
            state.Address = props.Address;

            state.IsSchool = props.IsSchool;
            if (state.IsSchool === true) {
                state.IsTeacher = true;
            }
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    handleChange = value => {
        this.setState({ value });
        if (value === null) {
            this.setState({ expired: "true" });
        } else {
            this.setState({ captchaErorr: '' });
        }
    };

    onSubmit = e => {
        const { t, isForTeacher, onSubmit } = this.props;
        const recaptchaValue = this._reCaptchaRef.current ? this._reCaptchaRef.current.getValue() : true;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if ((errors && errors.length !== 0 && Object.keys(errors).length !== 0) || (!recaptchaValue && !isForTeacher)) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.scrollToPosition("main-register-section");
        } else {

            let postData = {};

            if (this.props.isChild && this.props.isChild === true) {
                postData = {
                    FirstName: this.FirstName.ref.current.getValue(),
                    LastName: this.LastName.ref.current.getValue(),
                    SecondName: this.SecondName.ref.current.getValue(),
                    Birthday: this.Birthday.ref.value,
                    Sex: this.state.Sex,
                    Country: this.Country.state.value.value,
                    Address: this.Address.ref.current.getValue(),
                    IsTeacher: this.state.IsTeacher,
                    Username: this.Username.ref.current.getValue(),
                    Password: this.Password.ref.current.getValue(),
                    recaptchaValue
                };
            } else {
                postData = {
                    FirstName: this.FirstName.ref.current.getValue(),
                    LastName: this.LastName.ref.current.getValue(),
                    SecondName: this.SecondName.ref.current.getValue(),
                    Birthday: this.Birthday.ref.value,
                    Sex: this.state.Sex,
                    Country: this.Country.state.value.value,
                    Address: this.Address.ref.current.getValue(),
                    Email: this.Email && this.Email.ref && this.Email.ref.current ? this.Email.ref.current.getValue() : "",
                    Phone: this.Phone && this.Phone.ref && this.Phone.ref.current ? this.Phone.ref.current.getValue() : "",
                    IsTeacher: this.state.IsTeacher,
                    Username: this.Username.ref.current.getValue(),
                    Password: this.Password.ref.current.getValue(),
                    recaptchaValue
                };
            }

            if (onSubmit) {
                onSubmit(postData, this._toastId.current);
            } else {

                if (this.state.IsSchool) {
                    postData = {
                        ...postData,
                        IsSchool: this.state.IsSchool,
                        Code: this.Code.ref.current.getValue(),
                        SchoolCode: this.SchoolCode.ref.current.getValue(),
                        SchoolFax: this.SchoolFax.ref.current.getValue(),
                        SchoolName: this.SchoolName.ref.current.getValue(),
                        SchoolAddress: this.SchoolAddress.ref.current.getValue(),
                        SchoolEmail: this.SchoolEmail.ref.current.getValue(),
                        SchoolPhone: this.SchoolPhone.ref.current.getValue()
                    }
                }

                api.post(`/Register/`,
                    postData
                ).then(response => {
                    if (response.data.code === "exists_username") {
                        toast.update(this._toastId.current, {
                            render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Username.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Username.error_toast.Body')}</div></div>,
                            type: toast.TYPE.ERROR,
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    } else if (response.data.code === "exists_email") {
                        toast.update(this._toastId.current, {
                            render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                            type: toast.TYPE.ERROR,
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    } else if (response.data.code === "success") {

                        const token = response.data.data.token;
                        localStorage.setItem('token', token);

                        setToken(token);

                        const browserToken = response.data.data.browserToken;
                        localStorage.setItem('browserToken', browserToken);

                        this.props.userLogin(response.data.data);

                        if (this.state.IsSchool) {
                            SchoolRegisterBySocket(this.SchoolName.ref.current.getValue());
                        }

                        toast.update(this._toastId.current, {
                            render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Register.success_toast.Title')}</div> <div className="h6">{t('Pages.Register.success_toast.Body')}</div></div>,
                            type: toast.TYPE.INFO,
                            position: "bottom-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        this.props.goToActivePage();
                    }
                }).catch(error => {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
        e.preventDefault();
    }

    validate = () => {
        const errors = {};

        if (!this.props.isForTeacher && !this.TermsAndConditions.ref.current.getValue()) {
            errors.TermsAndConditions = 'Pages.Register.TermsAndConditions.error';
        }

        if (!this.Country.state.value || !this.Country.state.value.value) {
            errors.Country = 'Pages.Register.Country.error';
        }

        if (!this.Username.ref.current.getValue()) {
            errors.Username = 'Pages.Register.Username.error';
        }

        if (!this.Address.ref.current.getValue()) {
            errors.Address = 'Pages.Register.Address.error';
        }

        if (!this.FirstName.ref.current.getValue()) {
            errors.FirstName = 'Pages.Register.FirstName.error';
        }

        if (!this.LastName.ref.current.getValue()) {
            errors.LastName = 'Pages.Register.LastName.error';
        }

        if (this.state.IsTeacher === true) {
            if (!this.SchoolCode.ref.current.getValue()) {
                errors.SchoolCode = 'Pages.Register.SchoolCode.error';
            }
            if (!this.SchoolName.ref.current.getValue()) {
                errors.SchoolName = 'Pages.Register.SchoolName.error';
            }
            if (!this.Code.ref.current.getValue()) {
                errors.Code = 'Pages.Register.Code.error';
            }
            if (!this.SchoolAddress.ref.current.getValue()) {
                errors.SchoolAddress = 'Pages.Register.SchoolAddress.error';
            }
            if (!this.SchoolPhone.ref.current.getValue()) {
                errors.SchoolPhone = 'Pages.Register.SchoolPhone.error';
            }
            if (!this.SchoolFax.ref.current.getValue()) {
                errors.SchoolFax = 'Pages.Register.SchoolFax.error';
            }
            if (!this.SchoolEmail.ref.current.getValue()) {
                errors.SchoolEmail = 'Pages.Register.SchoolEmail.error';
            } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(this.SchoolEmail.ref.current.getValue())) {
                errors.SchoolEmail = 'Pages.Register.SchoolEmail.error_notmatch';
            }

        }

        if (this.props.isForTeacher || (this.props.isChild && this.props.isChild === true)) {

        } else {
            if (!this.Email.ref.current.getValue()) {
                errors.Email = 'Pages.Register.Email.error';
            } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(this.Email.ref.current.getValue())) {
                errors.Email = 'Pages.Register.Email.error_notmatch';
            }

            if (!this.Phone.ref.current.getValue()) {
                errors.Phone = 'Pages.Register.Phone.error';
            }
            // else if (!/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(this.Phone.ref.current.getValue())) {
            //     errors.Phone = 'Pages.Register.Phone.error_notmatch';
            // }
        }

        if (!this.Password.ref.current.getValue()) {
            errors.Password = 'Pages.Register.Password.error';
        } else if (this.props.isStudent !== true && !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(this.Password.ref.current.getValue())) {
            errors.Password = 'Pages.Register.Password.error_notmatch';
        }

        if (!this.Birthday.ref.value) {
            errors.Birthday = 'Pages.Register.Birthday.error';
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(this.Birthday.ref.value)) {
            errors.Birthday = 'Pages.Register.Birthday.error_notmatch';
        }

        return errors;
    }

    validateBirthday = value => {
        const { t } = this.props;
        if (!value) {
            this.setState({
                touchedBirthday: true,
                errorBirthday: t('Pages.Register.Birthday.error')
            });
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)) {
            this.setState({
                touchedBirthday: true,
                errorBirthday: t('Pages.Register.Birthday.error_notmatch')
            });
        }
    }

    changeUsername = Username => {
        if (Username) {
            this.checkIfExist({
                Username
            }, "Username");
        }
    }

    changeEmail = Email => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            this.checkIfExist({
                Email
            }, "Email");
        }
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            touchedBirthday: false,
            errorBirthday: ''
        });
    }
    onBlurBirthday = e => {
        const value = e.target.value;
        this.validateBirthday(value);
    }

    setSex = Sex => {
        this.setState({
            Sex
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    checkIfExist = (postData, type) => {
        const { t } = this.props;
        let ifExist = false;
        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/Register/?isCheck=true`,
            postData
        ).then(response => {
            if (response.data === "1") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.' + type + '.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.' + type + '.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                ifExist = true;
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.Register.' + type + '.info_toast.Title')}</div> <div className="h6">{t('Pages.Register.' + type + '.info_toast.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        return ifExist;
    }

    setIsTeacher = () => {
        this.setState({
            IsTeacher: !this.state.IsTeacher
        });
    }

    showTermsAndConditions = () => {
        this.setState({
            showTermsAndConditions: true
        });
    }
    handleClose = () => {
        this.setState({
            showTermsAndConditions: false
        });
    }

    setTermsAndConditions = () => {
        this.props.change('TermsAndConditions', !this.TermsAndConditions.ref.current.getValue());
    }

    renderSchoolForm() {
        const { t } = this.props;
        return (
            <>
                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={SchoolName => this.SchoolName = SchoolName}
                            name="SchoolName"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Register.SchoolName.Placeholder')}
                            title={t('Pages.Register.SchoolName.title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolAddress.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={SchoolAddress => this.SchoolAddress = SchoolAddress}
                            name="SchoolAddress"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Register.SchoolAddress.Placeholder')}
                            title={t('Pages.Register.SchoolAddress.title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolCode.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={SchoolCode => this.SchoolCode = SchoolCode}
                            name="SchoolCode"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Register.SchoolCode.Placeholder')}
                            title={t('Pages.Register.SchoolCode.title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolEmail.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={SchoolEmail => this.SchoolEmail = SchoolEmail}
                            name="SchoolEmail"
                            component={this.renderField}
                            type="email"
                            required={true}
                            placeholder={t('Pages.Register.SchoolEmail.Placeholder')}
                            title={t('Pages.Register.SchoolEmail.title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolPhone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={SchoolPhone => this.SchoolPhone = SchoolPhone}
                            name="SchoolPhone"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Register.SchoolPhone.Placeholder')}
                            title={t('Pages.Register.SchoolPhone.title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolFax.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={SchoolFax => this.SchoolFax = SchoolFax}
                            name="SchoolFax"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Register.SchoolFax.Placeholder')}
                            title={t('Pages.Register.SchoolFax.title')}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className='text-dark font-size-h5'>{t('Pages.Register.Code.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                    <div>
                        <Field
                            ref={Code => this.Code = Code}
                            name="Code"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Register.Code.Placeholder')}
                            title={t('Pages.Register.Code.title')}
                            className="form-control"
                        />
                    </div>
                </div>

            </>
        );
    }

    render() {
        const { pristine, submitting, t, i18n } = this.props;

        return (
            <>
                {
                    this.state.showTermsAndConditions ?
                        <Modal
                            size="xl"
                            show={this.state.showTermsAndConditions}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-route-interstate"></i> {t('Pages.Register.TermsAndConditions.modal')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget">
                                    <div className="widget-body">
                                        <TermsAndConditions></TermsAndConditions>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={this.handleClose}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                        this.state.IsSchool === true ?
                            <>
                                <div className={`contact-form p-3 w-100 mb-4 inner-box p-3 pt-4  `} style={{
                                    background: 'rgb(170 233 255)',
                                    border: '1px solid rgb(0 191 255)',
                                    borderRadius: '10px'
                                }}  >
                                    <div>
                                        <div className="title h4">
                                            <i className="icon fal fa-bell-exclamation"></i>
                                            {t('Pages.Register.School.note.title')}
                                        </div>
                                        <p>
                                            {t('Pages.Register.School.note.body')}
                                        </p>
                                    </div>

                                </div>

                                <div className={`contact-form p-3 w-100 mb-4 inner-box  fadeInLeft animated `} style={{
                                    background: '#fff9ee',
                                    border: '1px solid #f16421',
                                    borderRadius: '10px'
                                }} data-wow-delay="0ms" data-wow-duration="500ms" >

                                    {
                                        this.state.IsTeacher === true ?
                                            <>
                                                {this.renderSchoolForm()}
                                            </> : ""
                                    }
                                </div>
                                <div className="my-4 pb-2 w-100 title h4" style={{
                                    borderBottom: '1px solid #f6fafd'
                                }}>
                                    <i className="icon fad fa-chalkboard-teacher"></i>
                                    {t('Pages.Register.SchoolTeacher')}
                                </div>
                            </> : ""
                    }

                    <div className="row row-col-3 w-100 mx-0">
                        <div className="form-group col-md">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.FirstName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={FirstName => this.FirstName = FirstName}
                                    name="FirstName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.FirstName.Placeholder')}
                                    title={t('Pages.Register.FirstName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group col-md">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SecondName.title')}</label>
                            <div>
                                <Field
                                    ref={SecondName => this.SecondName = SecondName}
                                    name="SecondName"
                                    component={this.renderField}
                                    required={false}
                                    type="text"
                                    placeholder={t('Pages.Register.SecondName.Placeholder')}
                                    title={t('Pages.Register.SecondName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group col-md">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.LastName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={LastName => this.LastName = LastName}
                                    name="LastName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.LastName.Placeholder')}
                                    title={t('Pages.Register.LastName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Birthday.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <DateRangePicker
                                ref={Birthday => this.Birthday = Birthday}
                                initialSettings={{
                                    drops: 'auto',
                                    opens: 'center',
                                    autoUpdateInput: false,
                                    cancelClass: "btn btn-sm btn-danger",
                                    singleDatePicker: true,
                                    showDropdowns: true,
                                    //  startDate: `${moment().format('DD/MM/YYYY')}`,
                                    minYear: 1951,
                                    maxDate: `${moment().add(-5, 'y').format('DD/MM/YYYY')}`,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        monthNames: [
                                            `${t("months.January")}`,
                                            `${t("months.February")}`,
                                            `${t("months.March")}`,
                                            `${t("months.April")}`,
                                            `${t("months.May")}`,
                                            `${t("months.June")}`,
                                            `${t("months.July")}`,
                                            `${t("months.August")}`,
                                            `${t("months.September")}`,
                                            `${t("months.October")}`,
                                            `${t("months.November")}`,
                                            `${t("months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("daysOfWeek.Sunday"),
                                            t("daysOfWeek.Monday"),
                                            t("daysOfWeek.Tuesday"),
                                            t("daysOfWeek.Wednesday"),
                                            t("daysOfWeek.Thursday"),
                                            t("daysOfWeek.Friday"),
                                            t("daysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    name="Birthday"
                                    readOnly={true}
                                    autoComplete="off"
                                    required
                                    type="text"
                                    onBlur={e => this.onBlurBirthday(e)}
                                    className={`form-control ${(this.state.touchedBirthday && this.state.errorBirthday) ? 'is-invalid' : ''}`}
                                    placeholder={t('Pages.Register.Birthday.Placeholder')}
                                    title={t('Pages.Register.Birthday.title')}
                                />
                            </DateRangePicker>
                            {this.state.touchedBirthday && this.state.errorBirthday && <div className="invalid-feedback">{this.state.errorBirthday}</div>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Sex.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <ul className="filter mb-2 px-0 w-100">
                            <li className="p-0 border-0">
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.Sex === "male" ? true : false}
                                        onChange={e => this.setSex("male")}
                                        className="custom-control-input"
                                        title={t('Pages.Register.Sex.male')}
                                        name="Sex"
                                        id="male" />
                                    <label className="custom-control-label pt-1" htmlFor="male" >
                                        <i className="icon fad fa-male"></i>
                                        {t('Pages.Register.Sex.male')}
                                    </label>
                                </div>
                            </li>
                            <li className="p-0">
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.Sex === "female" ? true : false}
                                        onChange={e => this.setSex("female")}
                                        title={t('Pages.Register.Sex.female')}
                                        className="custom-control-input"
                                        name="Sex"
                                        id="female" />
                                    <label className="custom-control-label pt-1" htmlFor="female" >
                                        <i className="icon fad fa-female"></i>
                                        {t('Pages.Register.Sex.female')}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Country.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Select
                                defaultValue={this.state.CountryIndex >= 0 ? {
                                    label: this.state.countries[this.state.CountryIndex].label,
                                    value: this.state.countries[this.state.CountryIndex].value
                                } : ""}
                                name="Country"
                                placeholder={t('Pages.Register.Country.Placeholder')}
                                isRtl={true}
                                isSearchable={true}
                                ref={Country => this.Country = Country}
                                options={this.state.countries}
                                filterOption={this.filterOptions}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#92278f',
                                        primary: '#92278f',
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Address.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Address => this.Address = Address}
                                name="Address"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.Address.Placeholder')}
                                title={t('Pages.Register.Address.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {
                        !this.props.showEmail && (this.props.isForTeacher || (this.props.isChild && this.props.isChild === true)) ? "" :
                            <>
                                <div className="form-group ">
                                    <label className='text-dark font-size-h5'>{t('Pages.Register.Email.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={Email => this.Email = Email}
                                            name="Email"
                                            component={this.renderField}
                                            type="text"
                                            required={true}
                                            onBlur={e => this.changeEmail(e.target.value)}
                                            placeholder={t('Pages.Register.Email.Placeholder')}
                                            title={t('Pages.Register.Email.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='text-dark font-size-h5'>{t('Pages.Register.Phone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={Phone => this.Phone = Phone}
                                            name="Phone"
                                            component={this.renderField}
                                            type="text"
                                            required={true}
                                            placeholder={t('Pages.Register.Phone.Placeholder')}
                                            title={t('Pages.Register.Phone.title')}
                                            className="form-control number"
                                        />
                                    </div>
                                </div>
                            </>
                    }

                    {
                        !this.props.isForTeacher && this.state.IsSchool === false ?
                            <>
                                <div className="form-group w-100">
                                    <div className="custom-control custom-checkbox mr-sm-4 checkbox-danger">
                                        <input
                                            type='checkbox'
                                            onChange={e => this.setIsTeacher()}
                                            className="custom-control-input"
                                            title={t('Pages.Register.IsTeacher.title')}
                                            name="IsTeacher"
                                            id="IsTeacher" />
                                        <label className="custom-control-label pt-1 d-flex align-items-center" htmlFor="IsTeacher" >
                                            <i className="icon h3 fad fa-chalkboard-teacher text-success"></i>
                                            {t('Pages.Register.IsTeacher.title')}
                                        </label>
                                    </div>
                                </div>

                                <div className={`contact-form p-3 w-100 mb-4 inner-box  fadeInLeft animated ${this.state.IsTeacher === true ? '' : 'd-none'}`} style={{
                                    background: '#fff9ee',
                                    border: '1px solid #f16421',
                                    borderRadius: '10px'
                                }} data-wow-delay="0ms" data-wow-duration="500ms" >

                                    {
                                        this.state.IsTeacher === true ?
                                            <>
                                                {this.renderSchoolForm()}
                                            </> : ""
                                    }
                                </div>

                            </> : ""
                    }

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Username.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Username => this.Username = Username}
                                name="Username"
                                component={this.renderField}
                                type="text"
                                required={true}
                                onBlur={e => this.changeUsername(e.target.value)}
                                placeholder={t('Pages.Register.Username.Placeholder')}
                                title={t('Pages.Register.Username.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Password.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Password => this.Password = Password}
                                name="Password"
                                component={this.renderField}
                                type="password"
                                required={true}
                                placeholder={t('Pages.Register.Password.Placeholder')}
                                title={t('Pages.Register.Password.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {
                        this.props.isForTeacher ? "" :
                            <>
                                <div className="form-group w-100">
                                    <div className="custom-control custom-checkbox mr-sm-4 checkbox-danger">
                                        <Field
                                            ref={TermsAndConditions => this.TermsAndConditions = TermsAndConditions}
                                            type='checkbox'
                                            className="custom-control-input"
                                            component={this.renderField}
                                            required={true}
                                            title={t('Pages.Register.TermsAndConditions.title')}
                                            name="TermsAndConditions"
                                            id="TermsAndConditions" />
                                        <label className="custom-control-label pt-1" onClick={this.setTermsAndConditions} htmlFor="TermsAndConditions" >
                                            <i className="icon fad fa-route-interstate text-info"></i>
                                            {t('Pages.Register.TermsAndConditions.title')}
                                        </label>
                                        <Link to="#" title={t('Pages.Register.TermsAndConditions.show')} onClick={() => this.showTermsAndConditions()}>
                                            <i className="icon fad fa-info-circle text-danger px-1"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group w-100">
                                    <div>
                                        <ReCAPTCHA
                                            ref={this._reCaptchaRef}
                                            required={true}
                                            sitekey={TEST_SITE_KEY}
                                            onChange={this.handleChange}
                                            hl={i18n.language}
                                        />
                                    </div>
                                    <div className="invalid-feedback d-block">
                                        {this.state.captchaErorr}
                                    </div>
                                </div>
                            </>
                    }
                    <div className="form-group w-100 text-center">
                        <button className="custom-button" disabled={pristine || submitting} ><span> {this.props.sendText ? this.props.sendText : t('Pages.Register.Send')}</span></button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.TermsAndConditions) {
        errors.TermsAndConditions = 'Pages.Register.TermsAndConditions.error';
    }

    if (!values.Username) {
        errors.Username = 'Pages.Register.Username.error';
    }

    if (!values.Address) {
        errors.Address = 'Pages.Register.Address.error';
    }

    if (!values.SchoolFax) {
        errors.SchoolFax = 'Pages.Register.SchoolFax.error';
    }

    if (!values.SchoolName) {
        errors.SchoolName = 'Pages.Register.SchoolName.error';
    }

    if (!values.SchoolAddress) {
        errors.SchoolAddress = 'Pages.Register.SchoolAddress.error';
    }

    if (!values.Code) {
        errors.Code = 'Pages.Register.Code.error';
    }

    if (!values.SchoolPhone) {
        errors.SchoolPhone = 'Pages.Register.SchoolPhone.error';
    }

    if (!values.SchoolCode) {
        errors.SchoolCode = 'Pages.Register.SchoolCode.error';
    }

    if (!values.FirstName) {
        errors.FirstName = 'Pages.Register.FirstName.error';
    }

    if (!values.LastName) {
        errors.LastName = 'Pages.Register.LastName.error';
    }

    if (!values.SchoolEmail) {
        errors.SchoolEmail = 'Pages.Register.SchoolEmail.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.SchoolEmail)) {
        errors.SchoolEmail = 'Pages.Register.SchoolEmail.error_notmatch';
    }

    if (!values.Email) {
        errors.Email = 'Pages.Register.Email.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.Email)) {
        errors.Email = 'Pages.Register.Email.error_notmatch';
    }

    if (!values.Phone) {
        errors.Phone = 'Pages.Register.Phone.error';
    }
    //  else if (!/^(\+\d{1, 2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(values.Phone)) {
    //     errors.Phone = 'Pages.Register.Phone.error_notmatch';
    // }

    if (!values.Password) {
        errors.Password = 'Pages.Register.Password.error';
    }
    // else if (this.props.isStudent !== true && !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(values.Password)) {
    //     errors.Password = 'Pages.Register.Password.error_notmatch';
    // }

    if (!values.Birthday) {
        errors.Birthday = 'Pages.Register.Birthday.error';
    } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(values.Birthday)) {
        errors.Birthday = 'Pages.Register.Birthday.error_notmatch';
    }

    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'SingleRegFrom',
    validate
})(SingleRegFrom))
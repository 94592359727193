import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class Submarine extends React.Component {

    render() {
        return (<>
            <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                    transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                }}>
                    <div className="submarine-container mb-2">

                        <div className="submarine-submarine">
                            <div className="submarine-anchor-window"><i className="submarine-icon-anchor-window"></i></div>
                            <div className="submarine-propellor"><i className="submarine-icon-propellor2"></i></div>
                            <div className="submarine-dk-brown"><i className="submarine-icon-dark-brown3"></i></div>
                            <div className="submarine-tan"><i className="submarine-icon-tan"></i></div>
                            <div className="submarine-lt-yellow"><i className="submarine-icon-light-yellow"></i></div>
                            <div className="submarine-o-body"><i className="submarine-icon-body2"></i></div>
                            <div className="submarine-periscope"><i className="submarine-icon-periscope3"></i></div>
                        </div>
                    </div>

                </div>
            </div>
            <TextBox {...this.props} />
        </>);
    }
}

export default Submarine;

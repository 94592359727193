import React from "react";
import './style.scss';

class SunriseOverStillLake extends React.Component {
    render() {
        return <div className="SunriseOverStillLake-landscape">

            <div className="SunriseOverStillLake-mountain"></div>
            <div className="SunriseOverStillLake-mountain SunriseOverStillLake-mountain-2"></div>
            <div className="SunriseOverStillLake-mountain SunriseOverStillLake-mountain-3"></div>
            <div className="SunriseOverStillLake-sun-container SunriseOverStillLake-sun-container-1">
            </div>
            <div className="SunriseOverStillLake-sun-container">
                <div className="SunriseOverStillLake-sun"></div>
            </div>
            <div className="SunriseOverStillLake-cloud"></div>
            <div className="SunriseOverStillLake-cloud SunriseOverStillLake-cloud-1"></div>
            <div className="SunriseOverStillLake-sun-container sun-container-reflection">
                <div className="SunriseOverStillLake-sun"></div>
            </div>
            <div className="SunriseOverStillLake-light"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-1"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-2"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-3"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-4"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-5"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-6"></div>
            <div className="SunriseOverStillLake-light SunriseOverStillLake-light-7"></div>
            <div className="SunriseOverStillLake-water"></div>
            <div className="SunriseOverStillLake-splash"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-delay-1"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-delay-2"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-splash-4 SunriseOverStillLake-delay-2"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-splash-4 SunriseOverStillLake-delay-3"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-splash-4 SunriseOverStillLake-delay-4"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-splash-stone SunriseOverStillLake-delay-3"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-splash-stone SunriseOverStillLake-splash-4"></div>
            <div className="SunriseOverStillLake-splash SunriseOverStillLake-splash-stone SunriseOverStillLake-splash-5"></div>
            <div className="SunriseOverStillLake-lotus lotus-1"></div>
            <div className="SunriseOverStillLake-lotus lotus-2"></div>
            <div className="SunriseOverStillLake-lotus lotus-3"></div>
            <div className="SunriseOverStillLake-front">
                <div className="SunriseOverStillLake-stone"></div>
                <div className="SunriseOverStillLake-grass"></div>
                <div className="SunriseOverStillLake-grass SunriseOverStillLake-grass-1"></div>
                <div className="SunriseOverStillLake-grass SunriseOverStillLake-grass-2"></div>
                <div className="SunriseOverStillLake-reed"></div>
                <div className="SunriseOverStillLake-reed SunriseOverStillLake-reed-1"></div>
            </div>
        </div>
    }
}

export default SunriseOverStillLake;
import React from "react";
import './style.css';

class BubbleBackground extends React.Component {

    render() {
        return (
            <div className="BubbleBackground-bubble-field">
                <div className="bubble-rise" style={{ animationDuration: '5.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '4.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '3.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '2.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '1.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '6.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '7.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '7.9s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '2.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '8.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '9.5s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '1.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '2.1s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '2.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '3.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '4.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '5.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '6.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '9.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '7.0s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '2.2s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '3.3s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '4.4s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '5.9s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '6.6s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '11.6s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '10s' }}><div className="bubble"></div></div>
                <div className="bubble-rise" style={{ animationDuration: '10.6s' }}><div className="bubble"></div></div>
            </div>
        );
    }
}

export default BubbleBackground;
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';

import Footer from "../../../Footer/FooterBottom";
import UserSubMenu from '../../../Home/User/UserSubMenu';
import configData from "../../../Config/UserType.json";
import api from '../../../../api/api';

class UsersStatistics extends React.Component {

    state = {
        UserTypeCount: [],
        schoolCount: 0,
        LessonsCount: 0,
        FilesCount: 0,
        ActiveUserCount: 0
    };

    constructor(props) {
        super(props);
        let UserType = configData.UserType;
        this._toastId = new React.createRef();
        window.scrollToTop();

        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.get(`/UsersStatistics/`).then(response => {
            let UserTypeCount = [];
            if (response.data.usersCounts) {
                UserType.forEach(item => {
                    var UserCount = response.data.usersCounts.filter(p => p.Id === item.Id)[0];
                    if (UserCount) {
                        UserTypeCount.push({
                            ...UserCount
                        });
                    } else {
                        UserTypeCount.push({
                            ...item,
                            UserCount: 0
                        });
                    }
                });
            }

            this.setState({
                UserTypeCount,
                schoolCount: response.data.schoolCount,
                LessonsCount: response.data.LessonsCount,
                FilesCount: response.data.FilesCount,
                ActiveUserCount: response.data.ActiveUserCount
            });

            toast.dismiss(_toastId);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    renderUserTypes() {
        return this.state.UserTypeCount.map(item => {
            return <div className="featured-block col-lg-4 col-sm-6" key={item.Id}>
                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                    <span className="border-one"></span>
                    <span className="border-two"></span>
                    <div className="icon-box d-flex justify-content-center flex-column" style={{
                        width: "auto",
                        borderRadius: "0px"
                    }}>
                        <span className=" h1 m-0 text-white"> {item.UserCount}</span>
                    </div>
                    <h5> {item.Name}</h5>
                </div>
            </div>
        });
    }

    renderSchoolCount() {
        const { t } = this.props;
        return <div className="featured-block col-lg-4 col-sm-6" >
            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                <span className="border-one"></span>
                <span className="border-two"></span>
                <div className="icon-box d-flex justify-content-center flex-column" style={{
                    width: "auto",
                    borderRadius: "0px"
                }}>
                    <span className=" h1 m-0 text-white"> {this.state.schoolCount}</span>
                </div>
                <h5> {t('Pages.UserControl.schoolsCount')}</h5>
            </div>
        </div>
    }

    renderLessonsCount() {
        const { t } = this.props;
        return <div className="featured-block col-lg-4 col-sm-6" >
            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                <span className="border-one"></span>
                <span className="border-two"></span>
                <div className="icon-box d-flex justify-content-center flex-column" style={{
                    width: "auto",
                    borderRadius: "0px"
                }}>
                    <span className=" h1 m-0 text-white"> {this.state.LessonsCount}</span>
                </div>
                <h5> {t('Pages.UserControl.LessonsCount')}</h5>
            </div>
        </div>
    }

    renderFilesCount() {
        const { t } = this.props;
        return <div className="featured-block col-lg-4 col-sm-6" >
            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                <span className="border-one"></span>
                <span className="border-two"></span>
                <div className="icon-box d-flex justify-content-center flex-column" style={{
                    width: "auto",
                    borderRadius: "0px"
                }}>
                    <span className=" h1 m-0 text-white"> {this.state.FilesCount}</span>
                </div>
                <h5> {t('Pages.UserControl.FilesCount')}</h5>
            </div>
        </div>
    }

    renderActiveUserCount() {
        const { t } = this.props;
        return <div className="featured-block col-lg-4 col-sm-6" >
            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                <span className="border-one"></span>
                <span className="border-two"></span>
                <div className="icon-box d-flex justify-content-center flex-column" style={{
                    width: "auto",
                    borderRadius: "0px"
                }}>
                    <span className=" h1 m-0 text-white"> {this.state.ActiveUserCount}</span>
                </div>
                <h5> {t('Pages.UserControl.ActiveUserCount')}</h5>
            </div>
        </div>
    }

    render() {
        const { t, user } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.UserControl.CountStatistics')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.UserControl.CountStatistics')}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix justify-content-center">
                                    {this.renderUserTypes()}
                                    {this.renderActiveUserCount()}
                                    {this.renderSchoolCount()}
                                    {this.renderLessonsCount()}
                                    {this.renderFilesCount()}
                                </div>
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(UsersStatistics));
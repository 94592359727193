import React from "react";
import { withTranslation } from 'react-i18next'

class ListItem extends React.Component {

    state = { moreInfo: false };

    constructor(props) {
        super(props);
        this.pathList = [];
        this._toastId = new React.createRef();
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    render() {
        const { t, item, IsAdmin } = this.props;
        if (item) {

            const style = item.CustomStyle.questionStyle ? {
                paddingLeft: `${item.CustomStyle.questionStyle.PaddingLeft}px`,
                paddingTop: `${item.CustomStyle.questionStyle.PaddingTop}px`,
                paddingRight: `${item.CustomStyle.questionStyle.PaddingRight}px`,
                paddingBottom: `${item.CustomStyle.questionStyle.PaddingBottom}px`,

                borderColor: item.CustomStyle.questionStyle.BorderColor !== 'transparent' ? item.CustomStyle.questionStyle.BorderColor : "",
                borderStyle: `${item.CustomStyle.questionStyle.BorderStyle}`,
                borderLeftWidth: `${item.CustomStyle.questionStyle.BorderWidthLeft}px`,
                borderRightWidth: `${item.CustomStyle.questionStyle.BorderWidthRight}px`,
                borderTopWidth: `${item.CustomStyle.questionStyle.BorderWidthTop}px`,
                borderBottomWidth: `${item.CustomStyle.questionStyle.BorderWidthBottom}px`,
                borderRadius: `${item.CustomStyle.questionStyle.BorderRadiusTop}${item.CustomStyle.questionStyle.BorderRadiusUnit} ${item.CustomStyle.questionStyle.BorderRadiusRight}${item.CustomStyle.questionStyle.BorderRadiusUnit} ${item.CustomStyle.questionStyle.BorderRadiusBottom}${item.CustomStyle.questionStyle.BorderRadiusUnit} ${item.CustomStyle.questionStyle.BorderRadiusLeft}${item.CustomStyle.questionStyle.BorderRadiusUnit}`,
                boxShadow: `${item.CustomStyle.questionStyle.ShadowPositionX}px ${item.CustomStyle.questionStyle.ShadowPositionY}px ${item.CustomStyle.questionStyle.ShadowRadius}px 0px ${item.CustomStyle.questionStyle.shadowColor}`,

                opacity: `${item.CustomStyle.questionStyle.Opacity}%`,
                backgroundColor: item.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? item.CustomStyle.questionStyle.backgroundColor : "",
                backgroundImage: item.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${item.CustomStyle.questionStyle.backgroundImage}')` : "",
                backgroundPositionX: `${item.CustomStyle.questionStyle.BgPositionX}%`,
                backgroundPositionY: `${item.CustomStyle.questionStyle.BgPositionY}%`,
                backgroundSize: `${item.CustomStyle.questionStyle.BgSize}`,
                backgroundRepeat: `${item.CustomStyle.questionStyle.BgRepeat}`,
                backgroundAttachment: `${item.CustomStyle.questionStyle.BgAttachment}`,
            } : {};

            const style1 = item.CustomStyle.answerBoxStyle ? {
                width: '50px',
                height: '50px',
                borderColor: item.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? item.CustomStyle.answerBoxStyle.BorderColor : "",
                borderStyle: `${item.CustomStyle.answerBoxStyle.BorderStyle}`,
                borderLeftWidth: `${item.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
                borderRightWidth: `${item.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
                borderTopWidth: `${item.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
                borderBottomWidth: `${item.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
                borderRadius: `${item.CustomStyle.answerBoxStyle.BorderRadiusTop}${item.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${item.CustomStyle.answerBoxStyle.BorderRadiusRight}${item.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${item.CustomStyle.answerBoxStyle.BorderRadiusBottom}${item.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${item.CustomStyle.answerBoxStyle.BorderRadiusLeft}${item.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
                boxShadow: `${item.CustomStyle.answerBoxStyle.ShadowPositionX}px ${item.CustomStyle.answerBoxStyle.ShadowPositionY}px ${item.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${item.CustomStyle.answerBoxStyle.shadowColor}`,

                opacity: `${item.CustomStyle.answerBoxStyle.Opacity}%`,
                backgroundColor: item.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? item.CustomStyle.answerBoxStyle.backgroundColor : "",
                backgroundImage: item.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${item.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
                backgroundPositionX: `${item.CustomStyle.answerBoxStyle.BgPositionX}%`,
                backgroundPositionY: `${item.CustomStyle.answerBoxStyle.BgPositionY}%`,
                backgroundSize: `${item.CustomStyle.answerBoxStyle.BgSize}`,
                backgroundRepeat: `${item.CustomStyle.answerBoxStyle.BgRepeat}`,
                backgroundAttachment: `${item.CustomStyle.answerBoxStyle.BgAttachment}`,
            } : {};

            const style2 = item.CustomStyle.answerStyle ? {
                width: '50px',
                height: '50px',

                borderColor: item.CustomStyle.answerStyle.BorderColor !== 'transparent' ? item.CustomStyle.answerStyle.BorderColor : "",
                borderStyle: `${item.CustomStyle.answerStyle.BorderStyle}`,
                borderLeftWidth: `${item.CustomStyle.answerStyle.BorderWidthLeft}px`,
                borderRightWidth: `${item.CustomStyle.answerStyle.BorderWidthRight}px`,
                borderTopWidth: `${item.CustomStyle.answerStyle.BorderWidthTop}px`,
                borderBottomWidth: `${item.CustomStyle.answerStyle.BorderWidthBottom}px`,
                borderRadius: `${item.CustomStyle.answerStyle.BorderRadiusTop}${item.CustomStyle.answerStyle.BorderRadiusUnit} ${item.CustomStyle.answerStyle.BorderRadiusRight}${item.CustomStyle.answerStyle.BorderRadiusUnit} ${item.CustomStyle.answerStyle.BorderRadiusBottom}${item.CustomStyle.answerStyle.BorderRadiusUnit} ${item.CustomStyle.answerStyle.BorderRadiusLeft}${item.CustomStyle.answerStyle.BorderRadiusUnit}`,
                boxShadow: `${item.CustomStyle.answerStyle.ShadowPositionX}px ${item.CustomStyle.answerStyle.ShadowPositionY}px ${item.CustomStyle.answerStyle.ShadowRadius}px 0px ${item.CustomStyle.answerStyle.shadowColor}`,

                opacity: `${item.CustomStyle.answerStyle.Opacity}%`,
                backgroundColor: item.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? item.CustomStyle.answerStyle.backgroundColor : "",
                backgroundImage: item.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${item.CustomStyle.answerStyle.backgroundImage}')` : "",
                backgroundPositionX: `${item.CustomStyle.answerStyle.BgPositionX}%`,
                backgroundPositionY: `${item.CustomStyle.answerStyle.BgPositionY}%`,
                backgroundSize: `${item.CustomStyle.answerStyle.BgSize}`,
                backgroundRepeat: `${item.CustomStyle.answerStyle.BgRepeat}`,
                backgroundAttachment: `${item.CustomStyle.answerStyle.BgAttachment}`,
            } : {};

            const style3 = item.CustomStyle.categoryStyle ? {
                width: '50px',
                height: '50px',

                borderColor: item.CustomStyle.categoryStyle.BorderColor !== 'transparent' ? item.CustomStyle.categoryStyle.BorderColor : "",
                borderStyle: `${item.CustomStyle.categoryStyle.BorderStyle}`,
                borderLeftWidth: `${item.CustomStyle.categoryStyle.BorderWidthLeft}px`,
                borderRightWidth: `${item.CustomStyle.categoryStyle.BorderWidthRight}px`,
                borderTopWidth: `${item.CustomStyle.categoryStyle.BorderWidthTop}px`,
                borderBottomWidth: `${item.CustomStyle.categoryStyle.BorderWidthBottom}px`,
                borderRadius: `${item.CustomStyle.categoryStyle.BorderRadiusTop}${item.CustomStyle.categoryStyle.BorderRadiusUnit} ${item.CustomStyle.categoryStyle.BorderRadiusRight}${item.CustomStyle.categoryStyle.BorderRadiusUnit} ${item.CustomStyle.categoryStyle.BorderRadiusBottom}${item.CustomStyle.categoryStyle.BorderRadiusUnit} ${item.CustomStyle.categoryStyle.BorderRadiusLeft}${item.CustomStyle.categoryStyle.BorderRadiusUnit}`,
                boxShadow: `${item.CustomStyle.categoryStyle.ShadowPositionX}px ${item.CustomStyle.categoryStyle.ShadowPositionY}px ${item.CustomStyle.categoryStyle.ShadowRadius}px 0px ${item.CustomStyle.categoryStyle.shadowColor}`,

                opacity: `${item.CustomStyle.categoryStyle.Opacity}%`,
                backgroundColor: item.CustomStyle.categoryStyle.backgroundColor !== 'transparent' ? item.CustomStyle.categoryStyle.backgroundColor : "",
                backgroundImage: item.CustomStyle.categoryStyle.backgroundImage !== '' ? `url('${item.CustomStyle.categoryStyle.backgroundImage}')` : "",
                backgroundPositionX: `${item.CustomStyle.categoryStyle.BgPositionX}%`,
                backgroundPositionY: `${item.CustomStyle.categoryStyle.BgPositionY}%`,
                backgroundSize: `${item.CustomStyle.categoryStyle.BgSize}`,
                backgroundRepeat: `${item.CustomStyle.categoryStyle.BgRepeat}`,
                backgroundAttachment: `${item.CustomStyle.categoryStyle.BgAttachment}`,
            } : {};

            const style4 = item.CustomStyle.categoryHoverStyle ? {
                width: '50px',
                height: '50px',

                borderColor: item.CustomStyle.categoryHoverStyle.BorderColor !== 'transparent' ? item.CustomStyle.categoryHoverStyle.BorderColor : "",
                borderStyle: `${item.CustomStyle.categoryHoverStyle.BorderStyle}`,
                borderLeftWidth: `${item.CustomStyle.categoryHoverStyle.BorderWidthLeft}px`,
                borderRightWidth: `${item.CustomStyle.categoryHoverStyle.BorderWidthRight}px`,
                borderTopWidth: `${item.CustomStyle.categoryHoverStyle.BorderWidthTop}px`,
                borderBottomWidth: `${item.CustomStyle.categoryHoverStyle.BorderWidthBottom}px`,
                borderRadius: `${item.CustomStyle.categoryHoverStyle.BorderRadiusTop}${item.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${item.CustomStyle.categoryHoverStyle.BorderRadiusRight}${item.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${item.CustomStyle.categoryHoverStyle.BorderRadiusBottom}${item.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${item.CustomStyle.categoryHoverStyle.BorderRadiusLeft}${item.CustomStyle.categoryHoverStyle.BorderRadiusUnit}`,
                boxShadow: `${item.CustomStyle.categoryHoverStyle.ShadowPositionX}px ${item.CustomStyle.categoryHoverStyle.ShadowPositionY}px ${item.CustomStyle.categoryHoverStyle.ShadowRadius}px 0px ${item.CustomStyle.categoryHoverStyle.shadowColor}`,

                opacity: `${item.CustomStyle.categoryHoverStyle.Opacity}%`,
                backgroundColor: item.CustomStyle.categoryHoverStyle.backgroundColor !== 'transparent' ? item.CustomStyle.categoryHoverStyle.backgroundColor : "",
                backgroundImage: item.CustomStyle.categoryHoverStyle.backgroundImage !== '' ? `url('${item.CustomStyle.categoryHoverStyle.backgroundImage}')` : "",
                backgroundPositionX: `${item.CustomStyle.categoryHoverStyle.BgPositionX}%`,
                backgroundPositionY: `${item.CustomStyle.categoryHoverStyle.BgPositionY}%`,
                backgroundSize: `${item.CustomStyle.categoryHoverStyle.BgSize}`,
                backgroundRepeat: `${item.CustomStyle.categoryHoverStyle.BgRepeat}`,
                backgroundAttachment: `${item.CustomStyle.categoryHoverStyle.BgAttachment}`,
            } : {};

            const style5 = item.CustomStyle.categoryListStyle ? {
                width: '50px',
                height: '50px',

                borderColor: item.CustomStyle.categoryListStyle.BorderColor !== 'transparent' ? item.CustomStyle.categoryListStyle.BorderColor : "",
                borderStyle: `${item.CustomStyle.categoryListStyle.BorderStyle}`,
                borderLeftWidth: `${item.CustomStyle.categoryListStyle.BorderWidthLeft}px`,
                borderRightWidth: `${item.CustomStyle.categoryListStyle.BorderWidthRight}px`,
                borderTopWidth: `${item.CustomStyle.categoryListStyle.BorderWidthTop}px`,
                borderBottomWidth: `${item.CustomStyle.categoryListStyle.BorderWidthBottom}px`,
                borderRadius: `${item.CustomStyle.categoryListStyle.BorderRadiusTop}${item.CustomStyle.categoryListStyle.BorderRadiusUnit} ${item.CustomStyle.categoryListStyle.BorderRadiusRight}${item.CustomStyle.categoryListStyle.BorderRadiusUnit} ${item.CustomStyle.categoryListStyle.BorderRadiusBottom}${item.CustomStyle.categoryListStyle.BorderRadiusUnit} ${item.CustomStyle.categoryListStyle.BorderRadiusLeft}${item.CustomStyle.categoryListStyle.BorderRadiusUnit}`,
                boxShadow: `${item.CustomStyle.categoryListStyle.ShadowPositionX}px ${item.CustomStyle.categoryListStyle.ShadowPositionY}px ${item.CustomStyle.categoryListStyle.ShadowRadius}px 0px ${item.CustomStyle.categoryListStyle.shadowColor}`,

                opacity: `${item.CustomStyle.categoryListStyle.Opacity}%`,
                backgroundColor: item.CustomStyle.categoryListStyle.backgroundColor !== 'transparent' ? item.CustomStyle.categoryListStyle.backgroundColor : "",
                backgroundImage: item.CustomStyle.categoryListStyle.backgroundImage !== '' ? `url('${item.CustomStyle.categoryListStyle.backgroundImage}')` : "",
                backgroundPositionX: `${item.CustomStyle.categoryListStyle.BgPositionX}%`,
                backgroundPositionY: `${item.CustomStyle.categoryListStyle.BgPositionY}%`,
                backgroundSize: `${item.CustomStyle.categoryListStyle.BgSize}`,
                backgroundRepeat: `${item.CustomStyle.categoryListStyle.BgRepeat}`,
                backgroundAttachment: `${item.CustomStyle.categoryListStyle.BgAttachment}`,
            } : {};

            return (
                <>

                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >

                            <div className="product-thumb" style={style}>
                                <div className="product-action-link">
                                    {
                                        IsAdmin ?
                                            <>
                                                <div className="my-1 mr-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.Lessons.action.remove")}><i className="far fa-trash"></i></div>
                                                <div className="my-1 mr-1" onClick={() => this.moreInfo()} title={t("Pages.Lessons.action.info")}><i className="far fa-info"></i></div>
                                            </>
                                            :
                                            <div className="my-1" onClick={() => this.props.copyQuestionStyle(item.CustomStyle)} title={t("Pages.Lessons.Questions.forms.tools.copyStyleButton")}><i className="far fa-copy"></i></div>
                                    }
                                </div>
                            </div>
                            <div className="product-content">
                                <h5 className="title"> {item.Name} </h5>
                                <div className="d-flex justify-content-between ">
                                    <p className="px-1 ml-auto"></p>
                                    {
                                        (item.QuestionType.Id === 2 || item.QuestionType.Id === 3 || item.QuestionType.Id === 10 || item.QuestionType.Id === 12 || item.QuestionType.Id === 13 || item.QuestionType.Id === 14) ?
                                            <div className="d-flex align-items-center mr-4" title={t("Pages.Lessons.Questions.forms.tools.answerBoxStyle")} style={style1}>
                                            </div>
                                            : ""
                                    }
                                    {
                                        (item.QuestionType.Id === 2 || item.QuestionType.Id === 3 || item.QuestionType.Id === 9 || item.QuestionType.Id === 10 || item.QuestionType.Id === 13 || item.QuestionType.Id === 14) ?
                                            <div className="d-flex align-items-center mr-4" title={t("Pages.Lessons.Questions.forms.tools.answerStyle")} style={style2}>
                                            </div>
                                            : ""
                                    }
                                    {
                                        (item.QuestionType.Id === 3 || item.QuestionType.Id === 9 || item.QuestionType.Id === 10 || item.QuestionType.Id === 12 || item.QuestionType.Id === 13 || item.QuestionType.Id === 14) ?
                                            <div className="d-flex align-items-center mr-4" title={t("Pages.Lessons.Questions.forms.tools.categoryStyle")} style={style3}>
                                            </div>
                                            : ""
                                    }
                                    {
                                        (item.QuestionType.Id === 3 || item.QuestionType.Id === 10 || item.QuestionType.Id === 13 || item.QuestionType.Id === 14) ?
                                            <div className="d-flex align-items-center mr-4" title={t("Pages.Lessons.Questions.forms.tools.categoryHoverStyle")} style={style4}>
                                            </div>
                                            : ""
                                    }
                                    {
                                        item.QuestionType.Id === 14 ?
                                            <div className="d-flex align-items-center mr-4" title={t('Pages.Lessons.Questions.forms.tools.categoryListStyle')} style={style5}>
                                            </div>
                                            : ""
                                    }

                                </div>
                            </div>
                            {
                                IsAdmin && this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}
export default withTranslation('common')(ListItem);
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import api from '../../../api/api';
import Footer from "../../Footer/FooterBottom";

class TeachersStatistics extends React.Component {

    state = {
        startDate: moment().add(-15, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        date: moment().add(-15, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
        isLoaded: true,
        isSearch: false,
        data: {}
    };

    constructor(props) {
        super(props);
        window.scrollToTop();
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    Sync = () => {
        this.setState({
            isSearch: true,
            isLoaded: false,
            data: {}
        });
        const { t } = this.props;

        var _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/TeacherStatistic/`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
        ).then(response => {
            this.setState({
                data: response.data.data,
                isLoaded: true
            });
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t } = this.props;

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.UserControl.TeacherReport')} | {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-header">
                                    <h3 className="title">{t('Pages.UserControl.TeacherReport')}</h3>
                                </div>

                                <div className="widget widget-search mb-3" >
                                    <div className="widget-body">
                                        <div className="search-form contact-form align-items-center">

                                            <div className={`form-group w-100`} style={{
                                                zIndex: 9999999
                                            }}>
                                                <DateRangePicker
                                                    ref={Date => this.Date = Date}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        autoUpdateInput: false,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        ranges: ranges,
                                                        showMonthYearPicker: true,
                                                        minYear: 2021,
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            customRangeLabel: t('range.customRange'),
                                                            applyLabel: t('Apply'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.date}
                                                        name="Date"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        placeholder={t('SearchDate.Placeholder')}
                                                        title={t('SearchDate.title')}
                                                    />
                                                </DateRangePicker>
                                            </div>

                                        </div>

                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Sync()} title={t("Pages.SchoolsControl.Sync")} >
                                                <span>
                                                    {
                                                        this.state.isLoaded ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>

                                            <ReactToPrint content={() => this.componentRef}
                                                trigger={() => {
                                                    return <div className="custom-button orange-button mx-1">
                                                        <span>
                                                            <i className="icon fal fa-print"></i> {t('Print')}
                                                        </span>
                                                    </div>;
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !this.state.isSearch ? "" :
                                <div ref={el => (this.componentRef = el)}>

                                    <div className="d-none d-print-block">
                                        <div className="pt-3 px-3 mt-3 border-bottom border-dark mx-1">
                                            <div className="d-flex justify-content-around align-items-center">
                                                <img src="/assets/images/logo/logo.png" alt={`${t('alarabeyya')}`} />
                                                <div className="h5 text-center">
                                                    <div className="h3 title mt-2">
                                                        {t('Pages.UserControl.TeacherReport')}
                                                    </div>
                                                    {this.state.date}
                                                </div>
                                                <div className="h5 text-right">
                                                    {t('Date')} :
                                                    {`${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`contact-form p-3 w-100 mb-4 inner-box p-3 pt-4 text-center `} style={{
                                        background: 'rgb(170 233 255)',
                                        border: '1px solid rgb(0 191 255)',
                                        borderRadius: '10px'
                                    }}  >
                                        <div className="w-100">
                                            <div className="title h4 mb-1 d-flex align-items-center justify-content-center">
                                                <i className="icon fal fa-bell-exclamation  display-4"></i>
                                                <div className="h1 mx-3">
                                                    {t('Pages.TeacherReport.note.title')}
                                                </div>
                                            </div>
                                            <p>
                                                {t('Pages.TeacherReport.note.body')} <span className="font-weight-bold">{this.state.startDate}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="widget widget-search" >
                                        <div className="widget-body">
                                            <div className="row clearfix justify-content-between mt-0 mx-0 w-100">
                                                <table className="table table-striped table-hover table-bordered mt-0 text-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" rowSpan={2} style={{
                                                                verticalAlign: "middle"
                                                            }}>
                                                                {t('Pages.TeacherReport.Teacher')}
                                                            </th>
                                                            <th className="text-center" colSpan={2}>
                                                                <i className="icon fal fa-bullseye-arrow"></i>
                                                                {t('Pages.TeacherReport.Missions')}
                                                            </th>
                                                            <th className="text-center" colSpan={2}>
                                                                <i className="icon fal fa-layer-group"></i>
                                                                {t('Pages.TeacherReport.Lessons')}
                                                            </th>
                                                            <th className="text-center" colSpan={2}>
                                                                <i className="icon fal fa-paperclip"></i>
                                                                {t('Pages.TeacherReport.Files')}
                                                            </th>
                                                            <th className="text-center" colSpan={2}>
                                                                <i className="icon fal fa-mind-share"></i>
                                                                {t('Pages.TeacherReport.SharedLessons')}
                                                            </th>
                                                            <th className="text-center" colSpan={2}>
                                                                <i className="icon fal fa-head-side-brain"></i>
                                                                {t('Pages.TeacherReport.SharedFiles')}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th title={t('Pages.TeacherReport.total')}>
                                                                <i className="icon fad fa-sigma text-success"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.uniqe')}>
                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.total')}>
                                                                <i className="icon fad fa-sigma text-success"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.uniqe')}>
                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.total')}>
                                                                <i className="icon fad fa-sigma text-success"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.uniqe')}>
                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                            </th>

                                                            <th title={t('Pages.TeacherReport.total')}>
                                                                <i className="icon fad fa-sigma text-success"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.uniqe')}>
                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                            </th>

                                                            <th title={t('Pages.TeacherReport.total')}>
                                                                <i className="icon fad fa-sigma text-success"></i>
                                                            </th>
                                                            <th title={t('Pages.TeacherReport.uniqe')}>
                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.data && this.state.data.length > 0 ?
                                                                this.state.data.map(teacher => {
                                                                    return <tr key={teacher.Id}>
                                                                        <td>
                                                                            {`${teacher.FirstName} ${teacher.SecondName} ${teacher.LastName}`}
                                                                        </td>
                                                                        <td>{teacher.missions.total}</td>
                                                                        <td>{teacher.missions.unique}</td>
                                                                        <td>{teacher.lessons.total}</td>
                                                                        <td>{teacher.lessons.unique}</td>
                                                                        <td>{teacher.files.total}</td>
                                                                        <td>{teacher.files.unique}</td>

                                                                        <td>{teacher.shared.lesson.total}</td>
                                                                        <td>{teacher.shared.lesson.unique}</td>
                                                                        <td>{teacher.shared.file.total}</td>
                                                                        <td>{teacher.shared.file.unique}</td>
                                                                    </tr>
                                                                })
                                                                : ""
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>

                </section>
                <Footer></Footer>
            </>
        );
    }
}

export default withTranslation('common')(TeachersStatistics);
import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import moment from "moment";
import ComingSoon from "../../../ComingSoon";

class Price extends React.Component {

    constructor(props) {
        super(props);
        ReactPixel.init('484815450795869');
        ReactPixel.track('PageView', "School Register Price Form");
    }

    render() {
        const date = moment().month('september').endOf('month');

        return (
            <>
                <h4 className="title d-flex justify-content-center align-items-center mb-3 mt-5">
                    <i className="icon fa-solid fa-user-plus px-1 text-primary"></i>
                    اطّلع على أسعارنا قبل حجز الموعد
                </h4>
                <div className="text-center h4 mb-4">
                    لحجز الاستشارة المجّانية انقر على الزرّ الأزرق الموجود في أسفل الصفحة
                </div>

                <div className="row clearfix justify-content-center">

                    <div className="featured-block col-sm-6 " >
                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                            <span className="border-one"></span>
                            <span className="border-two"></span>

                            <h5 className="cursor-pointer">
                                سعر الاشتراك بدون خصم
                            </h5>
                            <div className="title mt-2" style={{
                                fontSize: '18px'
                            }}>
                                <h5 className="cursor-pointer m-0 mb-3 p-0">
                                    <span className="mx-1">
                                        897
                                    </span>
                                    <i className="fal fa-dollar-sign"></i>
                                    لمدّة سنة
                                </h5>
                            </div>

                            <div className="list w-100 text-right">
                                <ul>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        إمكانيّة تحميل أيّ ملف من الموقع لمدّة سنة.
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح كلّ الملفات المحوسبة لمدّة سنة.
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح كلّ الألعاب المحوسبة لمدّة سنة.
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح جميع محتويات الموقع لمدّة سنة.
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح حساب منفرد لكلّ معلّم لمدّة سنة.
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        إمكانيّة إرسال مهامّ محوسبة للطلّاب لمدّة سنة.
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        صفر مخاطر: إذا لم تكن راضيًا لأيّ سبب، يمكنك استرجاع كامل المبلغ خلال 30 يومًا من الاشتراك.
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="featured-block col-sm-6 " >
                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                            <span className="border-one"></span>
                            <span className="border-two"></span>
                            <h5 className="cursor-pointer">
                                إذا اشتركت اليوم ستحصل على (سنة اضافيّة مجّانا)
                            </h5>
                            <div className="title mt-2" style={{
                                fontSize: '18px'
                            }}>
                                <h5 className="cursor-pointer m-0 mb-3 p-0">
                                    <span className="mx-1">
                                        897
                                    </span>
                                    <i className="fal fa-dollar-sign"></i>
                                    لمدّة سنتين
                                </h5>
                            </div>
                            <div className="list w-100 text-right">
                                <ul>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        إمكانيّة تحميل أيّ ملفّ من الموقع لمدّة سنتين (سنة مجّانا).
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح كلّ الملفات المحوسبة لمدّة سنتين (سنة مجّانا).
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح كلّ الألعاب المحوسبة لمدّة سنتين (سنة مجّانا).
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح جميع محتويات الموقع لمدّة سنتين (سنة مجّانا).
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        فتح حساب منفرد لكلّ معلّم لمدّة سنتين (سنة مجّانا).
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        إمكانيّة إرسال مهامّ محوسبة للطلّاب لمدّة سنتين (سنة مجانا).
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                        صفر مخاطر: إذا لم تكن راضيًا لأيّ سبب، يمكنك استرجاع كامل المبلغ خلال 30 يومًا من الاشتراك.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-around flex-wrap mb-4">
                    <Link to="#" className={`custom-button primary-button`} onClick={() => this.props.onSubmit()} >
                        <span className="d-flex justify-content-center align-items-center flex-wrap px-1">
                            <div className="d-flex flex-column py-2" >
                                <span className="px-2" style={{
                                    lineHeight: '30px'
                                }}>
                                    اضغط هنا لتحديد موعد الاستشارة
                                </span>
                                <span className="px-1" style={{
                                    lineHeight: '25px',
                                    fontSize: '16px'
                                }}>
                                    سيتم نقلك إلى صفحة حجز الموعد
                                </span>
                            </div>
                        </span>
                    </Link>
                </div>

                <div className="d-flex justify-content-around flex-wrap h4 mt-3 font-weight-bold">
                    هذا الخصم متاح حصريًّا بمناسبة بداية العام الدراسيّ الجديد وسينتهي في تاريخ 2024\09\30
                </div>

                <div className="my-3">
                    <ComingSoon date={date} />
                </div>
            </>
        );
    }
}

export default withTranslation('common')(Price);
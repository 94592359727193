const usersReducers = (state = [], action) => {
    switch (action.type) {
        case "USERS_LOADING":
            return {
                users: [],
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "USERS_INITIAL":
            return {
                users: action.users,
                toRemove: [],
                isLoaded: true
            };
        case "USERS_UPDATE_INFO":
            return {
                users: UpdateUserData(action.user, state.users, action.id),
                toRemove: [],
                isLoaded: true
            };
        case "USERS_QUEUE_FOR_REMOVAL":
            return {
                users: state.users,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "USERS_CLEAN_NODES":
            return {
                users: state.toRemove.some(v => v === action.id) ? state.users.filter(v => v.Id !== action.id) : state.users,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "USERS_UNDO":
            return {
                users: state.users,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

function UpdateUserData(newInfo, users, id) {
    let copy = [];
    users.map(user => {
        if (user.Id === id) {
            user = {
                ...user,
                ...newInfo,
                Name: newInfo.FirstName + " " + newInfo.SecondName + " " + newInfo.LastName
            };
        }
        copy.push({
            ...user
        });
        return '';
    });
    return copy;
}

export default usersReducers;

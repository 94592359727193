import React from "react";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

class LevelSelectionItem extends React.Component {

    showGame = () => {
        const { t, item, index, onEdit, canPlayAnyGame, currentGame, isPlay, canSaveGame } = this.props;

        if (isPlay && !canSaveGame) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-engine-warning"></i>
                        {t('Pages.Games.toast.LevelIsComplete.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Games.toast.LevelIsComplete.Body')}
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                position: "bottom-left",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (onEdit || canPlayAnyGame || (!onEdit && currentGame >= index)) {
            this.props.showGame(item.Id);
        } else {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-engine-warning"></i>
                        {t('Pages.Games.toast.MustCompletePastLevel.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Games.toast.MustCompletePastLevel.Body')}
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                position: "bottom-left",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        const { item, index, onEdit, currentGame, starCount, canPlayAnyGame, isPlay, canSaveGame } = this.props;
        return <div className="mx-3 mb-3 position-relative " key={item.Id} style={{
            flex: '1 1 120px'
        }}>
            <div className="d-flex align-items-center flex-nowrap flex-column">
                {
                    onEdit === false ?
                        <>
                            <div className="d-flex justify-content-around align-items-end">
                                <div className="h4 mb-1">
                                    <i className={`right-star fas fa-star ${starCount >= 1 ? 'star-color' : 'empty-star'}`} style={{
                                        WebkitTextStroke: '1px #fff'
                                    }}></i>
                                </div>
                                <div className="h3 pb-1 mx-2 mb-1">
                                    <i className={`fas fa-star  ${starCount >= 2 ? 'star-color' : 'empty-star'}`} style={{
                                        WebkitTextStroke: '1px #fff'
                                    }}></i>
                                </div>
                                <div className="h4 mb-1">
                                    <i className={`left-star fas fa-star ${starCount >= 3 ? 'star-color' : 'empty-star'}`} style={{
                                        WebkitTextStroke: '1px #fff'
                                    }}></i>
                                </div>
                            </div>
                        </>
                        : ""
                }
                <div onClick={() => this.showGame()} className={`custom-button ${this.props.isRepeat ? this.props.SecondControlColor : this.props.ControlColor}-button   ${this.props.isRepeat ? "level-button-second" : "level-button level-button-custom"} `}>
                    {
                        (canPlayAnyGame === false && onEdit === false && currentGame < index) || (isPlay && !canSaveGame) ?
                            <>
                                <div style={{
                                    cursor: "not-allowed",
                                    background: 'rgb(112 112 112 / 75%)',
                                    zIndex: 9,
                                    width: ' 100%',
                                    height: ' 100%',
                                    position: 'absolute'
                                }}></div>
                                <div className="position-absolute mb-1 ml-1 star-color" style={{
                                    zIndex: 9,
                                    left: 0,
                                    WebkitTextStroke: '1px #fff'
                                }}>
                                    <i className="fas fa-lock"></i>
                                </div>
                            </> : ""
                    }
                    <span>{index + 1}</span>
                </div>
            </div>
        </div>;
    }

}

export default withTranslation('common')(LevelSelectionItem);
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import Grass from "../../Admin/Question/View/Background/Grass";

const BookList = (props) => {

    const { t } = props;

    const renderBookRow = () => {
        const arr = props.bookList.sort((a, b) => a.sort > b.sort ? 1 : -1);

        return arr.map(book => {
            return <Link to={`/BookLessons/${book.index + 1}/${book.id}`} key={book.id} title={book.label} className=" featured-block col-lg-3 col-md-4 col-sm-6 ">
                <div className="inner-box wow  " >
                    <span className="border-one"></span>
                    <span className="border-two"></span>
                    <div className="book-item" >
                        <div className="bk-img">
                            <div className="bk-wrapper">
                                <div className="bk-book bk-bookdefault">
                                    <div className="bk-front">
                                        <div className="bk-cover">
                                            <LazyLoadImage className="h-100 w-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                        </div>
                                    </div>
                                    <div className="bk-back"></div>
                                    <div className="bk-left overflow-hidden" >
                                        <h2>{book.label}</h2>
                                        <LazyLoadImage className="float-left w-100 h-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="feature-content">
                        <h5 className="title">{book.label}</h5>
                    </div>
                </div>
            </Link>
        })
    }

    return (
        <section className="pos-rel " >
            <Grass />
            <div className="row offset-xl-2 col-xl-10 col-lg-12" style={{
                zIndex: 10
            }}>
                <div className="col-12">
                    <div className="row justify-content-end mt-5 mb-3">
                        <div className="col-lg-10 text-center">
                            <h3 className="title display-4 text-white">
                                {t("Pages.Home.Publish")}
                            </h3>
                        </div>
                    </div>
                    <div className="row justify-content-end mt-5 mb-3">
                        <div className="col-lg-10">
                            {renderBookRow()}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );

}
export default withTranslation('common')(BookList);
import React from "react";
import GameObjects from "../../../Question/View/GameObjects";

class MoveObject extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.designerContainer = React.createRef();
        this.state = {
            top: 0,
            isShow: false,
            updatePosition: this.updatePosition,
            left: this.props.left ? this.props.left : Math.floor(Math.random() * 100),
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            timerSound: "",
            zIndex: 19,
            speed: this.props.designerContainer.current.clientHeight / this.props.Time,
            isHide: false,
            isHover: false,
            objectMovmentId: Math.floor(Math.random() * 100000),
            isLeft: true
        };
    }

    componentDidMount() {
        let timeout = this.state.delay;
        setTimeout(() => {
            this.setState({
                isShow: true
            });
            if (this.state.left > 50) {
                this.setState({
                    isLeft: false,
                    left: 100 - this.state.left
                });
            }
            this.timer = setInterval(() => {
                if (this.designerContainer && this.designerContainer.current && this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide) {
                    this.fall();
                }
            }, 30);
        }, timeout);
    }

    fall = () => {
        if (this.state.top < this.props.designerContainer.current.clientHeight - this.designerContainer.current.clientHeight) {
            this.setState({
                top: this.state.top + this.state.speed / 30
            });
        } else {
            this.props.handelDropObject(this.props.param, false);
            this.setState({
                isHide: true
            });
        }
        this.checkIfCaught();
    }

    checkIfCaught = () => {
        if (this.props.basket && this.props.basket.current) {
            const box = this.props.basket.current.getBoundingClientRect();
            if (this.designerContainer && this.designerContainer.current) {
                const object = this.designerContainer.current.getBoundingClientRect();
                if (object.x >= box.x &&
                    object.x <= (box.x + box.width) &&
                    (object.y + object.height) >= box.y &&
                    object.y <= (box.y + box.height)) {
                    this.props.handelDropObject(this.props.param, true);
                    this.setState({
                        isHide: true
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide && this.state.isShow) {
            let style = {
                top: `${this.state.top}px`,
                zIndex: this.state.zIndex ? this.state.zIndex : 19
            };
            if (this.state.isLeft) {
                style = {
                    left: `${this.state.left}%`,
                    ...style
                }

            } else {
                style = {
                    right: `${this.state.left}%`,
                    ...style
                }
            }
            return <div ref={this.designerContainer}
                className={`position-absolute objectMovment `}
                id={`objectMovment${this.state.objectMovmentId}`}
                style={style}>
                <GameObjects   {...this.props} type="dragdrop-move" />
            </div>;
        }
        return "";
    }
}

export default MoveObject;
import React from "react";

const TextBox = (props) => {

    return <div className={`d-flex justify-content-center position-${props.position === "absolute" ? "absolute" : "relative"
        } align-items-center flex-column`} >
        {
            props.img ?
                <div className="mb-1">
                    <img src={props.img} alt="" />
                </div>
                : ""
        }
        <div style={props.style} className={`${props.type} -box`} dangerouslySetInnerHTML={{ __html: props.text }}></div>
    </div>
}

export default TextBox;
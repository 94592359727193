const BillsReducers = (state = [], action) => {
    switch (action.type) {
        case "BILLS_LOADING":
            return {
                bills: [],
                isLoaded: false
            };
        case "BILLS_INITIAL":
            return {
                bills: action.data,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default BillsReducers;
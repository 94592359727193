import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import LevelSelection from "./LevelSelection";
import GameView from '../View';
import MoveBackgroundObject from "../../Question/View/GamePreview/MoveBackground";
import ResultScreen from "./ResultScreen";
import Scoreboard from "./Scoreboard";
import MyScoreboard from "./MyScoreboard";
import Rate from "../../../Home/Tracks/Rate";

class DashBoard extends React.Component {

    constructor(props) {
        super(props);
        window.addEventListener('resize', this.handleResize);
        this.audio = new Audio();
        this.state = {
            Id: '0',
            hideRate: false,
            showRate: false,
            IndexId: 0,
            isLastIndex: false,
            level: "",
            question: "",
            orientation: "portrait",
            showSplashScreen: false,
            isIntroScreen: false,
            showScoreboardScreen: false,
            showMyScoreScreen: false,
            className: "slidein",
            Questions: [],
            LessonPage: [],
            scale: 1,
            isMute: false,
            audio: new Audio(),
            showGameId: 0,
            showGame: this.showGame.bind(this)
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.handleResize();

        if (this.props.lesson && this.props.lesson.CustomStyle && this.props.lesson.CustomStyle.IntroScreen && this.props.lesson.CustomStyle.IntroScreen.GlobalStyle) {
            if (this.props.lesson.CustomStyle.IntroScreen.GlobalStyle.SplashSound) {
                this.audio = new Audio(this.props.lesson.CustomStyle.IntroScreen.GlobalStyle.SplashSound);
            }
        }
    }

    showRate = () => {
        this.setState({
            showRate: !this.state.showRate
        });
    }

    hideRate = () => {
        this.setState({
            showRate: false,
            hideRate: true
        });
    }

    handleResize = () => {
        let orientation = "portrait";
        const { innerWidth: width, innerHeight: height } = window;
        if (height < width) {
            orientation = "landscape";
        }
        this.setState({
            orientation
        });
        let scale = 1;
        if (width <= 770) {
            scale = 0.5;
        } else if (width > 770) {
            scale = 1
        }
        this.setState({
            scale
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.lesson && props.lesson) {
            state.Id = props.lesson.Id;
            state.IndexId = props.lesson.Index.Id
            state.Questions = props.lesson.Questions ? props.lesson.Questions.sort((a, b) => a.Sort - b.Sort) : [];

            let LessonPage = [];
            if (props.lesson.LessonPage) {
                props.lesson.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                    LessonPage = [...LessonPage, item];
                });
            }
            state.LessonPage = LessonPage;

            if (state.audio && !state.audio.paused) {
                state.audio.pause();
            }

            if (props.lesson && props.lesson.CustomStyle && props.lesson.CustomStyle.IntroScreen && props.lesson.CustomStyle.IntroScreen.GlobalStyle) {
                if (props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound && props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound !== 'custom' && props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound !== 'random' && props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound !== 'none') {
                    if (state.audio.currentSrc !== (window.location.origin + props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound)) {
                        state.audio = new Audio(process.env.PUBLIC_URL + props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound);
                    }
                } else if (props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound && props.lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound === 'custom' && props.lesson.CustomStyle.IntroScreen.GlobalStyle.CustomBackgroundSound) {
                    if (state.audio.currentSrc !== props.lesson.CustomStyle.IntroScreen.GlobalStyle.CustomBackgroundSound) {
                        state.audio = new Audio(props.lesson.CustomStyle.IntroScreen.GlobalStyle.CustomBackgroundSound);
                    }
                }
            }

            if (state.audio && state.audio.paused && state.isMute === false && !state.level) {
                state.audio.play();
            }

            if (props.showGameId && state.showGame && props.lesson.Questions && props.showGameId !== state.showGameId) {
                state.showGameId = props.showGameId;
                state.showGame(props.showGameId);
            }
        }
        return state;
    }

    goToFullScreen = () => {
        const isMobile = this.mobileCheck();
        if (window && window.innerHeight !== window.screen.height && !isMobile) {
            window.openFullscreen();
        }
    }

    showGame = id => {
        var question = this.props.lesson.Questions.filter(p => p.Id === id)[0];
        if (question) {
            this.goToFullScreen();
            if (this.audio) {
                this.audio.pause();
            }
            if (this.state.audio) {
                this.state.audio.pause();
            }
            let level = this.props.lesson.Questions.findIndex(p => p.Id === id) + 1;
            this.setState({
                question,
                className: "slideout",
                level,
                isLastIndex: this.props.lesson.Questions.length === level
            });
        }
    }

    goNext = () => {
        if (this.state.question) {
            const index = this.props.lesson.Questions.findIndex(p => p.Id === this.state.question.Id);
            if (index !== -1 && this.props.lesson.Questions.length > index) {
                const question = this.props.lesson.Questions[index + 1];
                if (question) {
                    this.setState({
                        question,
                        className: "slideout",
                        level: index + 2,
                        isLastIndex: this.props.lesson.Questions.length === index + 2
                    });
                }
            }
        }
    }

    goBack = () => {
        this.setState({
            question: "",
            className: "slidein",
            level: "",
            showGameId: 0
        });
        if (this.props.HideGame) {
            this.props.HideGame();
        }
    }

    goToIntroScreen = () => {
        if (this.state.showScoreboardScreen) {
            this.showScoreboardScreen();
        } else if (this.state.showMyScoreScreen) {
            this.showMyScoreScreen();
        } else {
            if (this.state.isIntroScreen) {
                this.setState({
                    isIntroScreen: false,
                    showSplashScreen: false
                });
            } else if (this.props.goBack) {
                if (this.state.audio && !this.state.audio.paused) {
                    this.state.audio.pause();
                }
                this.props.goBack();
            } else {
                window.close();
            }
        }
    }

    renderPlayButton = () => {
        if (this.props.lesson && this.props.lesson.CustomStyle) {
            const IntroScreen = this.props.lesson.CustomStyle.IntroScreen;
            if (IntroScreen && IntroScreen.GlobalStyle) {

                let Cursor = "";
                let buttonStyle = "";
                let buttonStyleHover = "";
                if (IntroScreen.GlobalStyle) {
                    Cursor = "";
                    if (IntroScreen.GlobalStyle.Cursor !== 'custom' && IntroScreen.GlobalStyle.Cursor !== 'none' && IntroScreen.GlobalStyle.Cursor) {
                        Cursor = process.env.PUBLIC_URL + IntroScreen.GlobalStyle.Cursor;
                    } else if (IntroScreen.GlobalStyle.Cursor === 'custom' && IntroScreen.GlobalStyle.CursorCustom) {
                        Cursor = IntroScreen.GlobalStyle.CursorCustom;
                    }

                    buttonStyle = `max-width: 100%;
                            user-select: none;
                            transition-property: all;
                            transition-duration: 0.35s;
                            transition-timing-function: ease-in-out;
                            cursor: url('${Cursor}') 20 20, auto;
                            color: ${IntroScreen.GlobalStyle.FontColor};
                            padding-left: ${IntroScreen.GlobalStyle.PaddingLeft}px;
                            padding-top: ${IntroScreen.GlobalStyle.PaddingTop}px;
                            padding-right: ${IntroScreen.GlobalStyle.PaddingRight}px;
                            padding-bottom: ${IntroScreen.GlobalStyle.PaddingBottom}px;
                            opacity: ${IntroScreen.GlobalStyle.Opacity}%;
                            background-color: ${IntroScreen.GlobalStyle.backgroundColor !== 'transparent' ? IntroScreen.GlobalStyle.backgroundColor : ""};
                            background-image: ${IntroScreen.GlobalStyle.backgroundImage !== '' ? `url('${IntroScreen.GlobalStyle.backgroundImage}')` : ""};
                            background-position-x: ${IntroScreen.GlobalStyle.BgPositionX}%;
                            background-position-y: ${IntroScreen.GlobalStyle.BgPositionY}%;
                            background-size: ${IntroScreen.GlobalStyle.BgSize};
                            background-repeat: ${IntroScreen.GlobalStyle.BgRepeat};
                            background-attachment: ${IntroScreen.GlobalStyle.BgAttachment};
                            border-color: ${IntroScreen.GlobalStyle.BorderColor !== 'transparent' ? IntroScreen.GlobalStyle.BorderColor : ""};
                            border-style: ${IntroScreen.GlobalStyle.BorderStyle};
                            border-left-width: ${IntroScreen.GlobalStyle.BorderWidthLeft}px;
                            border-right-width: ${IntroScreen.GlobalStyle.BorderWidthRight}px;
                            border-top-width: ${IntroScreen.GlobalStyle.BorderWidthTop}px;
                            border-bottom-width: ${IntroScreen.GlobalStyle.BorderWidthBottom}px;
                            border-radius: ${IntroScreen.GlobalStyle.BorderRadiusTop}${IntroScreen.GlobalStyle.BorderRadiusUnit} ${IntroScreen.GlobalStyle.BorderRadiusRight}${IntroScreen.GlobalStyle.BorderRadiusUnit} ${IntroScreen.GlobalStyle.BorderRadiusBottom}${IntroScreen.GlobalStyle.BorderRadiusUnit} ${IntroScreen.GlobalStyle.BorderRadiusLeft}${IntroScreen.GlobalStyle.BorderRadiusUnit};
                            box-shadow: ${IntroScreen.GlobalStyle.ShadowPositionX}px ${IntroScreen.GlobalStyle.ShadowPositionY}px ${IntroScreen.GlobalStyle.ShadowRadius}px 0px ${IntroScreen.GlobalStyle.shadowColor};
                            ${IntroScreen.GlobalStyle.Custom} `;

                    buttonStyleHover = `max-width: 100%;
                            color: ${IntroScreen.LabelStyle.FontColor};
                            padding-left: ${IntroScreen.LabelStyle.PaddingLeft}px;
                            padding-top: ${IntroScreen.LabelStyle.PaddingTop}px;
                            padding-right: ${IntroScreen.LabelStyle.PaddingRight}px;
                            padding-bottom: ${IntroScreen.LabelStyle.PaddingBottom}px;
                            opacity: ${IntroScreen.LabelStyle.Opacity}%;
                            cursor: url('${Cursor}') 20 20, auto;
                            background-color: ${IntroScreen.LabelStyle.backgroundColor !== 'transparent' ? IntroScreen.LabelStyle.backgroundColor : ""};
                            background-image: ${IntroScreen.LabelStyle.backgroundImage !== '' ? `url('${IntroScreen.LabelStyle.backgroundImage}')` : ""};
                            background-position-x: ${IntroScreen.LabelStyle.BgPositionX}%;
                            background-position-y: ${IntroScreen.LabelStyle.BgPositionY}%;
                            background-size: ${IntroScreen.LabelStyle.BgSize};
                            background-repeat: ${IntroScreen.LabelStyle.BgRepeat};
                            background-attachment: ${IntroScreen.LabelStyle.BgAttachment};
                            border-color: ${IntroScreen.LabelStyle.BorderColor !== 'transparent' ? IntroScreen.LabelStyle.BorderColor : ""};
                            border-style: ${IntroScreen.LabelStyle.BorderStyle};
                            border-left-width: ${IntroScreen.LabelStyle.BorderWidthLeft}px;
                            border-right-width: ${IntroScreen.LabelStyle.BorderWidthRight}px;
                            border-top-width: ${IntroScreen.LabelStyle.BorderWidthTop}px;
                            border-bottom-width: ${IntroScreen.LabelStyle.BorderWidthBottom}px;
                            border-radius: ${IntroScreen.LabelStyle.BorderRadiusTop}${IntroScreen.LabelStyle.BorderRadiusUnit} ${IntroScreen.LabelStyle.BorderRadiusRight}${IntroScreen.LabelStyle.BorderRadiusUnit} ${IntroScreen.LabelStyle.BorderRadiusBottom}${IntroScreen.LabelStyle.BorderRadiusUnit} ${IntroScreen.LabelStyle.BorderRadiusLeft}${IntroScreen.LabelStyle.BorderRadiusUnit};
                            box-shadow: ${IntroScreen.LabelStyle.ShadowPositionX}px ${IntroScreen.LabelStyle.ShadowPositionY}px ${IntroScreen.LabelStyle.ShadowRadius}px 0px ${IntroScreen.LabelStyle.shadowColor};
                            ${IntroScreen.LabelStyle.Custom} `;
                }
                return <>
                    <style dangerouslySetInnerHTML={{ __html: ` #playbutton_${this.props.lesson.Id}{${buttonStyle}} #playbutton_${this.props.lesson.Id}:hover{${buttonStyleHover}}  @keyframes objectMovment-${this.props.lesson.Id} { 0% {visibility:visible;transform-origin:center; transform:  scale(0.95) rotate(0deg); opacity:1; top :  5%; right :0% }50% {visibility:visible;transform-origin:center; transform:  scale(1.1) rotate(0deg); opacity:1; top :  2%; right :0% }100% {visibility:visible;transform-origin:center; transform:  scale(0.95) rotate(0deg); opacity:1; top :  5%; right :0% } }` }}></style>
                    <div className={` ${this.state.showSplashScreen === true ? "goOut" : ""} w-100 position-absolute h-100 `} >
                        <div className='d-flex align-items-center h-100 flex-column' style={{
                            justifyContent: 'space-evenly'
                        }}>
                            <div className="display-3 game-font h3 w-100 text-center position-relative mb-0 ">
                                <div className={` objectMovment-${this.props.lesson.Id} w-100`} style={{
                                    visibility: 'hidden',
                                    animationName: `objectMovment-${this.props.lesson.Id}`,
                                    animationIterationCount: 'infinite',
                                    animationDuration: '60s',
                                    animationFillMode: 'forwards',
                                    animationTimingFunction: 'linear'
                                }}>
                                    <div className="w-100 d-flex justify-content-center" >
                                        <div className=" w-50 d-flex justify-content-center" style={{
                                            visibility: 'hidden',
                                            animationName: `objectMovment-${this.props.lesson.Id}`,
                                            animationIterationCount: 'infinite',
                                            animationDuration: '30s',
                                            animationFillMode: 'forwards',
                                            animationTimingFunction: 'linear'
                                        }}>
                                            <img
                                                src="/assets/images/logo/game.png"
                                                className="w-50"
                                                alt={`${this.props.lesson.Name}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id={`playbutton_${this.props.lesson.Id}`} className='m-3 ' onClick={() => this.showSplashScreen()}>
                                <i style={{
                                    fontSize: `${IntroScreen.GlobalStyle.FontSize}pt`,
                                    color: `${IntroScreen.GlobalStyle.FontColor}`
                                }} className={`icon ml-3 ${IntroScreen.GlobalStyle.IconType} fa-${IntroScreen.GlobalStyle.Icon ? IntroScreen.GlobalStyle.Icon : 'play'}`} />
                            </div>

                            <div className="display-2 game-font h3 w-100 mb-5 text-center"
                                style={{
                                    fontSize: `${IntroScreen.GlobalStyle.Size}pt`,
                                    color: `${IntroScreen.GlobalStyle.Color}`,
                                    filter: `
                                drop-shadow(${IntroScreen.GlobalStyle.StrokeWidth}px ${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})
                                drop-shadow(-${IntroScreen.GlobalStyle.StrokeWidth}px -${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})
                                drop-shadow(-${IntroScreen.GlobalStyle.StrokeWidth}px ${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})
                                drop-shadow(${IntroScreen.GlobalStyle.StrokeWidth}px -${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})`
                                }}>
                                {this.props.lesson.Name}
                            </div>
                        </div>
                    </div>
                </>
            }
        }
        return "";
    }

    showLevels = () => {
        this.setState({
            isIntroScreen: true
        });
    }

    mobileCheck = () => {
        let check = false;
        (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    showSplashScreen = () => {
        this.goToFullScreen();
        this.setState({
            showSplashScreen: true
        }, () => {
            if (this.audio && this.audio.paused) {
                this.audio.play();
            }
            if (this.state.audio && !this.state.audio.paused) {
                this.state.audio.pause();
            }
            setTimeout(() => {
                this.showLevels();
            }, this.props.lesson.CustomStyle.IntroScreen.GlobalStyle.SplashTime ? parseInt(this.props.lesson.CustomStyle.IntroScreen.GlobalStyle.SplashTime) * 1000 : 4000);
        });
    }

    muteSound = () => {
        if (this.state.audio && !this.state.audio.paused && this.state.isMute === false) {
            this.state.audio.pause();
        } else if (this.state.audio && this.state.audio.paused && this.state.isMute === true) {
            this.state.audio.play();
        }
        this.setState({
            isMute: !this.state.isMute
        });
    }

    renderLogo = () => {
        const { lesson } = this.props;

        let style = {};
        let styleBackground = {};
        let margin = 2;
        let Custom = "";
        let logoSrc = "/assets/images/logo/black.png";
        let position = {
            bottom: 0,
            right: 0,
            zIndex: 14
        };
        if (lesson && lesson.CustomStyle && lesson.CustomStyle.DashBoard) {
            if (lesson.CustomStyle.DashBoard.LogoStyle) {
                style = {
                    minHeight: `${lesson.CustomStyle.DashBoard.LogoStyle.height}px`,
                    width: `${lesson.CustomStyle.DashBoard.LogoStyle.width}%`,
                    maxWidth: '100%',
                    color: lesson.CustomStyle.DashBoard.LogoStyle.FontColor,
                    paddingLeft: `${lesson.CustomStyle.DashBoard.LogoStyle.PaddingLeft}px`,
                    paddingTop: `${lesson.CustomStyle.DashBoard.LogoStyle.PaddingTop}px`,
                    paddingRight: `${lesson.CustomStyle.DashBoard.LogoStyle.PaddingRight}px`,
                    paddingBottom: `${lesson.CustomStyle.DashBoard.LogoStyle.PaddingBottom}px`,
                    borderColor: lesson.CustomStyle.DashBoard.LogoStyle.BorderColor !== 'transparent' ? lesson.CustomStyle.DashBoard.LogoStyle.BorderColor : "",
                    borderStyle: `${lesson.CustomStyle.DashBoard.LogoStyle.BorderStyle}`,
                    borderLeftWidth: `${lesson.CustomStyle.DashBoard.LogoStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${lesson.CustomStyle.DashBoard.LogoStyle.BorderWidthRight}px`,
                    borderTopWidth: `${lesson.CustomStyle.DashBoard.LogoStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${lesson.CustomStyle.DashBoard.LogoStyle.BorderWidthBottom}px`,
                    borderRadius: `${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusTop}${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit} ${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusRight}${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit} ${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusBottom}${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit} ${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusLeft}${lesson.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit}`,
                    boxShadow: `${lesson.CustomStyle.DashBoard.LogoStyle.ShadowPositionX}px ${lesson.CustomStyle.DashBoard.LogoStyle.ShadowPositionY}px ${lesson.CustomStyle.DashBoard.LogoStyle.ShadowRadius}px 0px ${lesson.CustomStyle.DashBoard.LogoStyle.shadowColor}`,
                    marginBottom: `${lesson.CustomStyle.DashBoard.LogoStyle.Margin}px`,
                    zIndex: 9
                };

                styleBackground = {
                    height: `100%`,
                    opacity: `${lesson.CustomStyle.DashBoard.LogoStyle.Opacity}%`,
                    backgroundColor: lesson.CustomStyle.DashBoard.LogoStyle.backgroundColor !== 'transparent' ? lesson.CustomStyle.DashBoard.LogoStyle.backgroundColor : "",
                    backgroundImage: lesson.CustomStyle.DashBoard.LogoStyle.backgroundImage !== '' ? `url('${lesson.CustomStyle.DashBoard.LogoStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${lesson.CustomStyle.DashBoard.LogoStyle.BgPositionX}%`,
                    backgroundPositionY: `${lesson.CustomStyle.DashBoard.LogoStyle.BgPositionY}%`,
                    backgroundSize: `${lesson.CustomStyle.DashBoard.LogoStyle.BgSize}`,
                    backgroundRepeat: `${lesson.CustomStyle.DashBoard.LogoStyle.BgRepeat}`,
                    backgroundAttachment: `${lesson.CustomStyle.DashBoard.LogoStyle.BgAttachment}`,
                    marginLeft: `-${lesson.CustomStyle.DashBoard.LogoStyle.PaddingLeft}px`,
                    marginTop: `-${lesson.CustomStyle.DashBoard.LogoStyle.PaddingTop}px`,
                    marginRight: `-${lesson.CustomStyle.DashBoard.LogoStyle.PaddingRight}px`,
                    marginBottom: `-${lesson.CustomStyle.DashBoard.LogoStyle.PaddingBottom}px`,
                    zIndex: -1
                };
                Custom = lesson.CustomStyle.DashBoard.LogoStyle.Custom;

                if (lesson.CustomStyle.DashBoard.LogoStyle.CustomBackground) {
                    logoSrc = lesson.CustomStyle.DashBoard.LogoStyle.CustomBackground;
                }
                if (lesson.CustomStyle.DashBoard.LogoStyle.Margin) {
                    margin = lesson.CustomStyle.DashBoard.LogoStyle.Margin;
                }

                position = {
                    zIndex: 14
                };
                if (lesson.CustomStyle.DashBoard.LogoStyle.PositionX === "left") {
                    position = {
                        ...position,
                        left: 0
                    };
                } else {
                    position = {
                        ...position,
                        right: 0
                    };
                }

                if (lesson.CustomStyle.DashBoard.LogoStyle.PositionY === "top") {
                    position = {
                        ...position,
                        top: 0
                    };
                } else {
                    position = {
                        ...position,
                        bottom: 0
                    };
                }
            }

            if (this.state.question && this.state.question.CustomStyle && this.state.question.CustomStyle.LogoStyle) {
                style = {
                    minHeight: `${this.state.question.CustomStyle.LogoStyle.height}px`,
                    width: `${this.state.question.CustomStyle.LogoStyle.width}%`,
                    maxWidth: '100%',
                    color: this.state.question.CustomStyle.LogoStyle.FontColor,
                    paddingLeft: `${this.state.question.CustomStyle.LogoStyle.PaddingLeft}px`,
                    paddingTop: `${this.state.question.CustomStyle.LogoStyle.PaddingTop}px`,
                    paddingRight: `${this.state.question.CustomStyle.LogoStyle.PaddingRight}px`,
                    paddingBottom: `${this.state.question.CustomStyle.LogoStyle.PaddingBottom}px`,
                    borderColor: this.state.question.CustomStyle.LogoStyle.BorderColor !== 'transparent' ? this.state.question.CustomStyle.LogoStyle.BorderColor : "",
                    borderStyle: `${this.state.question.CustomStyle.LogoStyle.BorderStyle}`,
                    borderLeftWidth: `${this.state.question.CustomStyle.LogoStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${this.state.question.CustomStyle.LogoStyle.BorderWidthRight}px`,
                    borderTopWidth: `${this.state.question.CustomStyle.LogoStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${this.state.question.CustomStyle.LogoStyle.BorderWidthBottom}px`,
                    borderRadius: `${this.state.question.CustomStyle.LogoStyle.BorderRadiusTop}${this.state.question.CustomStyle.LogoStyle.BorderRadiusUnit} ${this.state.question.CustomStyle.LogoStyle.BorderRadiusRight}${this.state.question.CustomStyle.LogoStyle.BorderRadiusUnit} ${this.state.question.CustomStyle.LogoStyle.BorderRadiusBottom}${this.state.question.CustomStyle.LogoStyle.BorderRadiusUnit} ${this.state.question.CustomStyle.LogoStyle.BorderRadiusLeft}${this.state.question.CustomStyle.LogoStyle.BorderRadiusUnit}`,
                    boxShadow: `${this.state.question.CustomStyle.LogoStyle.ShadowPositionX}px ${this.state.question.CustomStyle.LogoStyle.ShadowPositionY}px ${this.state.question.CustomStyle.LogoStyle.ShadowRadius}px 0px ${this.state.question.CustomStyle.LogoStyle.shadowColor}`,
                    marginBottom: `${this.state.question.CustomStyle.LogoStyle.Margin}px`,
                    zIndex: 9
                };

                styleBackground = {
                    height: `100%`,
                    opacity: `${this.state.question.CustomStyle.LogoStyle.Opacity}%`,
                    backgroundColor: this.state.question.CustomStyle.LogoStyle.backgroundColor !== 'transparent' ? this.state.question.CustomStyle.LogoStyle.backgroundColor : "",
                    backgroundImage: this.state.question.CustomStyle.LogoStyle.backgroundImage !== '' ? `url('${this.state.question.CustomStyle.LogoStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${this.state.question.CustomStyle.LogoStyle.BgPositionX}%`,
                    backgroundPositionY: `${this.state.question.CustomStyle.LogoStyle.BgPositionY}%`,
                    backgroundSize: `${this.state.question.CustomStyle.LogoStyle.BgSize}`,
                    backgroundRepeat: `${this.state.question.CustomStyle.LogoStyle.BgRepeat}`,
                    backgroundAttachment: `${this.state.question.CustomStyle.LogoStyle.BgAttachment}`,
                    marginLeft: `-${this.state.question.CustomStyle.LogoStyle.PaddingLeft}px`,
                    marginTop: `-${this.state.question.CustomStyle.LogoStyle.PaddingTop}px`,
                    marginRight: `-${this.state.question.CustomStyle.LogoStyle.PaddingRight}px`,
                    marginBottom: `-${this.state.question.CustomStyle.LogoStyle.PaddingBottom}px`,
                    zIndex: -1
                };
                Custom = this.state.question.CustomStyle.LogoStyle.Custom;

                if (this.state.question.CustomStyle.LogoStyle.CustomBackground) {
                    logoSrc = this.state.question.CustomStyle.LogoStyle.CustomBackground;
                }
                if (this.state.question.CustomStyle.LogoStyle.Margin) {
                    margin = this.state.question.CustomStyle.LogoStyle.Margin;
                }

                position = {
                    zIndex: 14
                };
                if (this.state.question.CustomStyle.LogoStyle.PositionX === "left") {
                    position = {
                        ...position,
                        left: 0
                    };
                } else {
                    position = {
                        ...position,
                        right: 0
                    };
                }

                if (this.state.question.CustomStyle.LogoStyle.PositionY === "top") {
                    position = {
                        ...position,
                        top: 0
                    };
                } else {
                    position = {
                        ...position,
                        bottom: 0
                    };
                }
            }
        }
        return <div className={`position-absolute m-${margin}`} id="game-sub-logo" style={position}>
            <div className={`position-relative overflow-hidden`} style={style}>
                <style dangerouslySetInnerHTML={{ __html: `#game-sub-logo{${Custom} }` }}></style>
                <div className="w-100 position-absolute" style={styleBackground} ></div>
                <img src={logoSrc} alt={``} />
            </div>
        </div>;
    }

    showScoreboardScreen = () => {
        if (this.state.showScoreboardScreen) {
            this.setState({
                showMyScoreScreen: false,
                showScoreboardScreen: false,
                className: this.state.question ? "slideout" : "slidein"

            });
        } else {
            this.setState({
                showMyScoreScreen: false,
                showScoreboardScreen: true,
                className: "slideout"
            });
        }
    }

    showMyScoreScreen = () => {
        if (this.state.showMyScoreScreen) {
            this.setState({
                showMyScoreScreen: false,
                showScoreboardScreen: false,
                className: this.state.question ? "slideout" : "slidein"

            });
        } else {
            this.setState({
                showScoreboardScreen: false,
                showMyScoreScreen: true,
                className: "slideout"
            });
        }
    }

    render() {
        const { t, lesson, onEdit } = this.props;

        let Cursor = "";
        let menuButtonStyle = "";
        let menuButtonStyleHover = "";
        let CustomSound = "";
        if (lesson && lesson.CustomStyle && lesson.CustomStyle.IntroScreen && lesson.CustomStyle.IntroScreen.ButtonOneStyle) {
            Cursor = "";
            if (lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Cursor !== 'custom' && lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Cursor !== 'none' && lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Cursor) {
                Cursor = process.env.PUBLIC_URL + lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Cursor;
            } else if (lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Cursor === 'custom' && lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.CursorCustom) {
                Cursor = lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.CursorCustom;
            }
            menuButtonStyle = `
                            z-index:999;
                            user-select: none;
                            transition-property: all;
                            transition-duration: 0.35s;
                            transition-timing-function: ease-in-out;
                            cursor: url('${Cursor}') 20 20, auto;
                            width: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.width}px;
                            height: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.height}px;
                            color: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.FontColor};
                            padding-left: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.PaddingLeft}px;
                            padding-top: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.PaddingTop}px;
                            padding-right: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.PaddingRight}px;
                            padding-bottom: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.PaddingBottom}px;
                            opacity: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.Opacity}%;
                            background-color: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.backgroundColor !== 'transparent' ? lesson.CustomStyle.IntroScreen.ButtonOneStyle.backgroundColor : ""};
                            background-image: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.backgroundImage !== '' ? `url('${lesson.CustomStyle.IntroScreen.ButtonOneStyle.backgroundImage}')` : ""};
                            background-position-x: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BgPositionX}%;
                            background-position-y: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BgPositionY}%;
                            background-size: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BgSize};
                            background-repeat: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BgRepeat};
                            background-attachment: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BgAttachment};
                            border-color: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderColor !== 'transparent' ? lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderColor : ""};
                            border-style: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderStyle};
                            border-left-width: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderWidthLeft}px;
                            border-right-width: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderWidthRight}px;
                            border-top-width: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderWidthTop}px;
                            border-bottom-width: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderWidthBottom}px;
                            border-radius: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusTop}${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusUnit} ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusRight}${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusUnit} ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusBottom}${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusUnit} ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusLeft}${lesson.CustomStyle.IntroScreen.ButtonOneStyle.BorderRadiusUnit};
                            box-shadow: ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.ShadowPositionX}px ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.ShadowPositionY}px ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.ShadowRadius}px 0px ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.shadowColor};
                            ${lesson.CustomStyle.IntroScreen.ButtonOneStyle.Custom} `;

            menuButtonStyleHover = `
                            cursor: url('${Cursor}') 20 20, auto;
                            color: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.FontColor};
                            width: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.width}px;
                            height: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.height}px;
                            padding-left: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.PaddingLeft}px;
                            padding-top: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.PaddingTop}px;
                            padding-right: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.PaddingRight}px;
                            padding-bottom: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.PaddingBottom}px;
                            opacity: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Opacity}%;
                            background-color: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.backgroundColor !== 'transparent' ? lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.backgroundColor : ""};
                            background-image: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.backgroundImage !== '' ? `url('${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.backgroundImage}')` : ""};
                            background-position-x: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BgPositionX}%;
                            background-position-y: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BgPositionY}%;
                            background-size: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BgSize};
                            background-repeat: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BgRepeat};
                            background-attachment: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BgAttachment};
                            border-color: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderColor !== 'transparent' ? lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderColor : ""};
                            border-style: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderStyle};
                            border-left-width: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderWidthLeft}px;
                            border-right-width: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderWidthRight}px;
                            border-top-width: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderWidthTop}px;
                            border-bottom-width: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderWidthBottom}px;
                            border-radius: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusTop}${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit} ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusRight}${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit} ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusBottom}${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit} ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusLeft}${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit};
                            box-shadow: ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.ShadowPositionX}px ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.ShadowPositionY}px ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.ShadowRadius}px 0px ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.shadowColor};
                            ${lesson.CustomStyle.IntroScreen.ButtonOneHoverStyle.Custom} `;
        }

        if (lesson && lesson.CustomStyle && lesson.CustomStyle.IntroScreen && lesson.CustomStyle.IntroScreen.GlobalStyle) {
            if (lesson.CustomStyle.IntroScreen.GlobalStyle.Cursor !== 'custom' && lesson.CustomStyle.IntroScreen.GlobalStyle.Cursor !== 'none' && lesson.CustomStyle.IntroScreen.GlobalStyle.Cursor) {
                Cursor = process.env.PUBLIC_URL + lesson.CustomStyle.IntroScreen.GlobalStyle.Cursor;
            } else if (lesson.CustomStyle.IntroScreen.GlobalStyle.Cursor === 'custom' && lesson.CustomStyle.IntroScreen.GlobalStyle.CursorCustom) {
                Cursor = lesson.CustomStyle.IntroScreen.GlobalStyle.CursorCustom;
            }

            if (lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound && lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound !== 'custom' && lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound !== 'random' && lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound !== 'none') {
                CustomSound = process.env.PUBLIC_URL + lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound;
            } else if (lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound && lesson.CustomStyle.IntroScreen.GlobalStyle.BackgroundSound === 'custom' && lesson.CustomStyle.IntroScreen.GlobalStyle.CustomBackgroundSound) {
                CustomSound = lesson.CustomStyle.IntroScreen.GlobalStyle.CustomBackgroundSound;
            }
        }

        return <>
            <Helmet>
                {
                    this.state.orientation === "landscape" ?
                        <meta name="viewport" content="width=1450, user-scalable=no " />
                        :
                        <meta name="viewport" content="width=770, user-scalable=no " />
                }
                <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/css/game.css?v=' + process.env.REACT_APP_VERSION} />
                <title>
                    {
                        this.state.level ?
                            t('Pages.Games.GameLevel') + " " + this.state.level + " "
                            :
                            lesson ? lesson.Name + " " : ""
                    }
                    | {t('alarabeyya')}
                </title>
            </Helmet>
            {
                this.state.showRate ?
                    <Rate
                        id={lesson.Id}
                        IsForLesson={true}
                        hideRate={this.hideRate}
                        showRate={this.showRate} />
                    : ""
            }
            <style dangerouslySetInnerHTML={{
                __html: `#bg_${lesson ? lesson.Id : ""}{overflow: hidden; background: url('${lesson ?
                    this.state.isIntroScreen === true &&
                        lesson.CustomStyle &&
                        lesson.CustomStyle.DashBoard &&
                        lesson.CustomStyle.DashBoard.GlobalStyle &&
                        lesson.CustomStyle.DashBoard.GlobalStyle.CustomBackground ?
                        lesson.CustomStyle.DashBoard.GlobalStyle.CustomBackground :
                        lesson.Background
                    : ""
                    }'); background-size:cover;background-position:center;}header{ display:none; }
                    .custom-menu-button_${lesson ? lesson.Id : ""}{${menuButtonStyle}} .custom-menu-button_${lesson ? lesson.Id : ""}:hover{${menuButtonStyleHover}`
            }}></style>
            <div className={`${this.state.className}`}>
                {
                    (this.state.isIntroScreen === true || this.state.showSplashScreen === false) ?
                        <div className={`position-absolute align-items-center justify-content-center text-center m-3 ${this.state.question ? "d-none" : "d-flex "}`} style={{
                            left: 0,
                            zIndex: 9
                        }}>
                            {
                                CustomSound ?
                                    <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                        onClick={() => this.muteSound()}
                                        title={t('Pages.Games.sound')}>
                                        {
                                            this.state.isMute === false ?
                                                <i className="fas fa-music"></i> :
                                                <i className="fas fa-music-slash"></i>

                                        }
                                    </div>
                                    : ""
                            }
                            {
                                onEdit ?
                                    <>
                                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                            onClick={() => this.props.showGameInfoModal()}
                                            title={t('Pages.Games.forms.LessonInfo.title')}>
                                            <i className="fas fa-cogs"></i>
                                        </div>

                                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                            onClick={() => this.props.showGameMenu()}
                                            title={t('Pages.Games.Add')}>
                                            <i className="fas fa-plus"></i>
                                        </div>

                                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                            onClick={() => this.props.showPagesModal()}
                                            title={t('Pages.Lessons.tools.pages')}>
                                            <i className="fas fa-book-reader"></i>
                                        </div>

                                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                            onClick={() => this.props.showSort()}
                                            title={t('ControlPanel.Indices.tools.sort')}>
                                            <i className="fas fa-sort-numeric-up"></i>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                            onClick={() => this.showScoreboardScreen()}
                                            title={t('Pages.Games.Scoreboard')}>
                                            <i className="fas fa-trophy-alt"></i>
                                        </div>
                                        {
                                            this.props.user ?
                                                <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                                    onClick={() => this.showMyScoreScreen()}
                                                    title={t('Pages.Games.MyScore')}>
                                                    <i className="fas fa-star-shooting"></i>
                                                </div>
                                                : ""
                                        }
                                        {
                                            !lesson.isRated && !this.state.hideRate && this.props.user && this.props.user.UserType && this.props.user.UserType.Id !== 5 ?
                                                <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                                    onClick={() => this.showRate()}
                                                    title={t('Pages.Lessons.tools.rate')}>
                                                    <i className="fas fa-star"></i>
                                                </div>
                                                : ""
                                        }
                                    </>
                            }
                            <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button custom-menu-button_${lesson ? lesson.Id : ""}`}
                                onClick={() => this.goToIntroScreen()}
                                title={t('Pages.Lessons.tools.back')}>
                                <i className="fas fa-power-off"></i>
                            </div>
                        </div>
                        : ""
                }
                {
                    lesson && lesson.CustomStyle && this.state.isIntroScreen !== true ?
                        <>
                            {
                                this.state.orientation === "portrait" ?
                                    <div className={`position-absolute w-100 h-100 d-flex justify-content-center align-items-center`} style={{
                                        zIndex: 9999
                                    }}>
                                        <ResultScreen
                                            type="rotate"
                                            CustomStyle={lesson.CustomStyle.StopScreen} />
                                    </div> : ""
                            }
                            <div id={`bg_${lesson.Id}`} className="w-100 h-100 position-relative" style={{
                                cursor: `url('${Cursor}') 20 20, auto`
                            }}>
                                {
                                    this.state.showSplashScreen === true ?
                                        <MoveBackgroundObject CustomStyle={lesson.CustomStyle.IntroScreen.GlobalStyle.SplashGameObject} Id={`${lesson.Id}_x`} /> : <MoveBackgroundObject CustomStyle={lesson.CustomStyle.IntroScreen.GlobalStyle} Id={lesson.Id} />
                                }
                                {this.renderPlayButton()}
                            </div>
                        </>
                        :
                        <>
                            {this.renderLogo()}
                            <LevelSelection
                                hideMove={this.state.showScoreboardScreen}
                                onEdit={onEdit}
                                canPlayAnyGame={this.props.canPlayAnyGame}
                                canSaveGame={this.props.canSaveGame}
                                currentGame={this.props.currentGame}
                                totalPlayGame={this.props.totalPlayGame}
                                LessonPage={this.state.LessonPage}
                                lesson={lesson}
                                showGame={this.showGame} />
                        </>
                }
            </div>
            {
                this.state.showScoreboardScreen || this.state.showMyScoreScreen ?
                    <>
                        <div className={`position-absolute align-items-center justify-content-center text-center m-3 ${this.state.question ? "d-none" : "d-flex "}`} style={{
                            left: 0,
                            zIndex: 9
                        }}>
                            <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button_${lesson ? lesson.Id : ""}`}
                                onClick={() => this.goToIntroScreen()}
                                title={t('Pages.Lessons.tools.back')}>
                                <i className="fas fa-power-off"></i>
                            </div>
                        </div>
                        {
                            this.state.orientation === "portrait" ?
                                <div className={`position-absolute w-100 h-100 d-flex justify-content-center align-items-center`} style={{
                                    zIndex: 9999
                                }}>
                                    <ResultScreen
                                        type="rotate"
                                        CustomStyle={lesson.CustomStyle.StopScreen} />
                                </div> : ""
                        }
                        {
                            this.state.showScoreboardScreen ?
                                <Scoreboard
                                    lesson={lesson}
                                    MissionId={this.props.MissionId}
                                    isMission={this.props.isMission} />
                                : <MyScoreboard
                                    lesson={lesson}
                                    user={this.props.user}
                                    MissionId={this.props.MissionId}
                                    isMission={this.props.isMission} />
                        }

                        {this.renderLogo()}
                    </>
                    : ""
            }
            {
                this.state.question ?
                    <>
                        <GameView
                            lesson={lesson}
                            goNext={() => this.goNext()}
                            isMute={this.state.isMute}
                            hasNext={!this.state.isLastIndex}
                            MissionId={this.props.MissionId}
                            isMission={this.props.isMission}
                            isCompetition={this.props.isCompetition}
                            updateGameScore={this.props.updateGameScore}
                            GameType={this.props.GameType}
                            LessonId={lesson.Id}
                            user={this.props.user}
                            onEdit={this.props.onEdit}
                            objectSacle={this.state.scale}
                            orientation={this.state.orientation}
                            level={this.state.level}
                            CustomStyle={lesson.CustomStyle}
                            Question={this.state.question}
                            goBack={() => this.goBack()} />
                        {this.renderLogo()}
                    </>
                    : ""
            }
        </>;
    }
}

export default withTranslation('common')(DashBoard);
import React from "react";

class ShootObject extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.Rocket = React.createRef();
        this.state = {
            Id: 0,
            height: 0,
            element: "",
            Rocket: React.createRef(),
            top: this.props.designerContainer.current && this.props.basket.current ? this.props.designerContainer.current.clientHeight - this.props.basket.current.clientHeight : 0,
            pullObject: {
                left: 0,
                top: 0,
                originTop: 0,
                width: 0
            },
            shootTop: 0,
            objectMovmentId: Math.floor(Math.random() * 100000)
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isGo === false && state.Rocket.current) {
            const Rocket = state.Rocket.current.getBoundingClientRect();
            state.shootTop = Rocket.top;
        }
        if (props.keyStatus === "up") {
            if (state.Id) {
                window.moveToInPixel(`objectMovment${state.Id}`, state.pullObject.originTop, state.pullObject.left, 100, 1);
            }
            state.Id = 0;
            state.height = 0;
        }
        return state;
    }


    componentDidMount() {
        this.setState({
            Rocket: this.Rocket
        });
        this.timer = setInterval(() => {
            if (this.Rocket && this.Rocket.current && this.props.designerContainer && this.props.designerContainer.current) {
                this.goUp();
            }
        }, 30);
    }

    goUp = () => {
        if (this.props.isGo === true) {
            this.checkIfCatch();

            if (this.state.Id) {
                let left = 0;
                let top = 0;
                let scale = 1;
                if (this.props.basket && this.props.basket.current) {
                    const basket = this.props.basket.current.getBoundingClientRect();
                    left = basket.left + (basket.width / 2) - (this.state.pullObject.width / 2);
                    top = basket.top;
                    scale = (basket.top - this.state.pullObject.top) / (basket.bottom - this.state.pullObject.originTop);
                    if (scale > 1.1) {
                        scale = 1.1;
                    }
                    scale = scale <= 0.65 ? 0.65 : scale;
                }

                this.setState({
                    pullObject: {
                        ...this.state.pullObject,
                        top: this.state.pullObject.top - 15
                    },
                    height: this.state.height - 15
                });
                window.moveToInPixel(`objectMovment${this.state.Id}`, this.state.pullObject.top, left, 30, scale);

                if (top >= this.state.pullObject.top) {
                    this.props.stopMove(this.state.Id, this.state.element);
                    this.setState({
                        Id: 0,
                        height: 0
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    checkIfCatch = () => {
        if (this.Rocket && this.Rocket.current) {
            const rocket = this.Rocket.current.getBoundingClientRect();

            for (var i = 0; i < this.props.items.length; ++i) {
                const element = this.props.items[i];

                if (element.jsx.ref.current && element.jsx.ref.current.designerContainer && element.jsx.ref.current.designerContainer.current && this.state.Id === 0) {
                    const object = element.jsx.ref.current.designerContainer.current.getBoundingClientRect();
                    if (object.x >= rocket.x && object.x <= (rocket.x + rocket.width) && object.y >= rocket.y && object.y <= (rocket.y + rocket.height)) {
                        window.stopMove(`objectMovment${element.Id}`);

                        let height = 0;
                        if (this.props.basket && this.props.basket.current) {
                            const basket = this.props.basket.current.getBoundingClientRect();
                            height = (object.y + object.height) - (basket.top + basket.height);
                        }

                        this.setState({
                            height,
                            Id: element.Id,
                            pullObject: {
                                left: object.x,
                                top: object.y,
                                originTop: object.y,
                                width: object.width,
                                height: object.height
                            },
                            element: element.jsx.props.param
                        });
                        break;
                    }
                }
            }
        }
    }

    render() {
        let left = 0;
        let width = 0;
        let top = 0;
        if (this.props.basket && this.props.basket.current) {
            const basket = this.props.basket.current.getBoundingClientRect();
            left = basket.left;
            top = basket.top + (basket.height / 2);
            width = basket.width;
        }

        if (this.props.designerContainer && this.props.designerContainer.current) {
            return <div
                className={`position-absolute objectMovment `}
                id={`objectMovment${this.state.objectMovmentId}`}
                style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    zIndex: 999,
                    height: `100%`,
                }} >
                <div className={`position-relative d-flex align-items-center flex-column  `} style={{
                    height: `100%`
                }}>
                    <div ref={this.Rocket} className="position-relative" style={{
                        width: `${width}px`,
                        height: `${this.state.height ? `${this.state.height}px` : "100%"}`
                    }}>
                        {
                            this.props.isGo === true ?
                                <>
                                    <div className="mx-auto position-absolute rings" ></div>
                                    <div className="mx-auto position-absolute rings" style={{
                                        animationDelay: '0.5s'
                                    }}></div>
                                    <div className="mx-auto position-absolute rings" style={{
                                        animationDelay: '1s'
                                    }}></div>
                                    <div className="mx-auto position-absolute rings" style={{
                                        animationDelay: '1.5s'
                                    }}></div>
                                </> : ""
                        }
                    </div>
                </div>
            </div>;
        }
        return "";
    }
}

export default ShootObject;
import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import api from '../../../../api/api';
import DragDrop from '../Forms/DragDrop';

class QuestionSortModal extends React.Component {

    state = { isFirst: false, show: false, itemsRight: [], itemsLeft: [], search: "", showPasteById: false, CopyId: "" }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        this.setState({
            show: false
        });
    }

    showPasteById = () => {
        this.setState({
            showPasteById: true
        });
    }
    handleClose = () => {
        this.setState({
            showPasteById: false
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isFirst && props.nodes) {
            if (props.oneSide) {
                state.itemsLeft = props.nodes;
            } else {
                state.itemsRight = props.nodes.filter(p => p.Position === true);
                state.itemsLeft = props.nodes.filter(p => p.Position === false);
            }

            state.isFirst = true;
        }
        state.show = props.show;
        return state;
    }

    setSortRight = itemsRight => {
        this.setState({
            itemsRight
        });
    }

    setSortLeft = itemsLeft => {
        this.setState({
            itemsLeft
        });
    }

    saveSort = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const sortedId = [];
        this.state.itemsRight.forEach((item, index) => {
            sortedId.push({
                id: item.Id,
                sort: index + 1
            });
        });
        this.state.itemsLeft.forEach((item, index) => {
            sortedId.push({
                id: item.Id,
                sort: index + 1
            });
        });

        var postData = {
            sortedIds: sortedId
        };

        api.post(this.props.API + this.props.IndexId,
            postData
        ).then(response => {
            this.props.setSort(sortedId);
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('ControlPanel.Indices.Sort.Title')}</div> <div className="h6">{t('ControlPanel.Indices.Sort.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    EditItem = item => {
        this.props.updateModal(item.Id, item.QuestionLessonId, item.Type.Id);
    }

    DeleteItem = id => {
        this.props.deleteModal(id);
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setCopyId = value => {
        this.setState({
            CopyId: value
        });
    }

    handleCopy = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/LessonCopy/${this.props.LessonId}`,
            {
                QuestionId: this.state.CopyId
            }
        ).then(response => {
            if (response.data.data.Id !== 0) {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-copy"></i> {t('Success.CopyLesson.Title')}</div> <div className="h6">{t('Success.CopyLesson.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.props.updateCurrentQuestion({ question: { ...response.data.data } });
                this.setState({
                    CopyId: "",
                    showPasteById: false,
                });
                this.props.handleClose();
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.CopyError.Title')}</div> <div className="h6">{t('Error.CopyError.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.setState({
                    CopyId: "",
                    showPasteById: false
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, oneSide } = this.props;
        return (
            <>
                {
                    this.state.showPasteById ?
                        <Modal
                            size={`md `}
                            show={this.state.showPasteById}
                            onHide={this.handleClose}
                            backdrop="static"
                            enforceFocus={false}
                            keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div className="h5 mb-0">
                                        <i className=" icon fas fa-clone"></i> {t("Pages.Lessons.Questions.forms.PasteById")}
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className='col-12'>
                                        <div className="widget widget-search"  >
                                            <div className="widget-body ">
                                                <div className="d-flex  mb-3">
                                                    <div className="p-2 flex-grow-1 " title={t("Pages.Lessons.Questions.forms.CopyId")}>
                                                        <form className="search-form">
                                                            <input type="number"
                                                                onChange={e => this.setCopyId(e.target.value)}
                                                                defaultValue={this.state.CopyId}
                                                                placeholder={t("Pages.Lessons.Questions.forms.CopyId")} />
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="form-group w-100 text-center">
                                                    <button className="custom-button" type="button" onClick={() => this.handleCopy()}>
                                                        <span>
                                                            <i className="icon fas fa-copy"></i>
                                                            {t('Pages.Lessons.tools.copy')}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                <Modal
                    size={`${oneSide ? "lg" : "xl"} `}
                    show={this.state.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    enforceFocus={false}
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="h5 mb-0">
                                <i className=" icon fas fa-sort-numeric-up"></i> {t("ControlPanel.Indices.tools.sort")}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='col-12 mb-3'>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 " title={t("Pages.Search.input.title")}>
                                                <form className="search-form">
                                                    <input type="text"
                                                        onChange={e => this.setSearch(e.target.value)}
                                                        defaultValue={this.state.search}
                                                        placeholder={t("Pages.Search.input.title")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>

                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.showPasteById()} title={t("Pages.Lessons.Questions.forms.PasteById")} className="btn btn-dark mx-1">
                                                    <i className="fas fa-clone"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                oneSide ? ""
                                    :
                                    <div className="col-6" style={{
                                        borderLeft: '3px dashed rgb(14 44 83)'
                                    }}>
                                        <div className="title h5 mt-2 mb-3 w-100">
                                            <i className="icon text-primary fad fa-arrow-alt-to-right"></i>
                                            {t('Pages.Lessons.pageRight')}
                                        </div>
                                        <DragDrop
                                            API={this.props.API}
                                            isGame={this.props.isGame}
                                            ShowGame={this.props.ShowGame}
                                            search={this.state.search}
                                            items={this.state.itemsRight}
                                            setSort={this.setSortRight}
                                            DeleteItem={this.DeleteItem}
                                            EditItem={this.EditItem} />
                                    </div>
                            }
                            <div className={`${oneSide ? "col-12" : "col-6"} `}>
                                {
                                    oneSide ? ""
                                        :
                                        <div className="title h5 mt-2 mb-3 w-100">
                                            <i className="icon text-danger fad fa-arrow-alt-to-left"></i>
                                            {t('Pages.Lessons.pageLeft')}
                                        </div>
                                }
                                <DragDrop
                                    API={this.props.API}
                                    isGame={this.props.isGame}
                                    ShowGame={this.props.ShowGame}
                                    search={this.state.search}
                                    items={this.state.itemsLeft}
                                    setSort={this.setSortLeft}
                                    DeleteItem={this.DeleteItem}
                                    EditItem={this.EditItem} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="w-100 row">
                            <div className="col-6">
                                <button className="btn btn-success" onClick={() => this.saveSort()} >
                                    <i className="icon fal fa-save"></i>
                                    {t('ControlPanel.Indices.form.save')}
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-danger" onClick={this.props.handleClose} >
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


}

export default withTranslation('common')(QuestionSortModal);
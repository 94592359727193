
import React from "react";
import { withTranslation } from 'react-i18next';

class Tools extends React.Component {

    constructor(props) {
        super(props)
        this.audio = new Audio();
        this.backgroundAudio = new Audio();
        this.volumeAudio = new Audio();
        this.yourTime = 0;
        this.timer = setInterval(() => {
            this.setState({
                yourTime: this.state.yourTime + 1
            });
        }, 1000);
        this.state = {
            isPlayBackgroundSound: true,//this.props.isPlayBackgroundSound,
            isPlayVolumeSound: true,
            yourTime: 0
        };
    }

    componentDidMount() {
        this.playBackgroundSound(true);//this.props.isPlayBackgroundSound
    }

    componentWillUnmount() {
        if (this.backgroundAudio && !this.backgroundAudio.paused) {
            this.backgroundAudio.pause();
        }
        clearInterval(this.timer);
    }

    getTime = Time => {
        if (this.props.startTime) {
            if (Time < this.state.yourTime) {
                return 0;
            }
            return Time - this.state.yourTime;
        }
        return Time;
    }

    playBackgroundSound = isPlayBackgroundSound => {
        const { gamesSetting } = this.props;
        if (this.props.startTime) {
            if (this.backgroundAudio && this.backgroundAudio.paused === false) {
                this.backgroundAudio.pause();
            } else {
                if (gamesSetting.BackgroundSound && gamesSetting.BackgroundSound !== 'custom' && gamesSetting.BackgroundSound !== 'random' && gamesSetting.BackgroundSound !== 'none') {
                    this.backgroundAudio = new Audio(process.env.PUBLIC_URL + gamesSetting.BackgroundSound);
                } else if (gamesSetting.BackgroundSound && gamesSetting.BackgroundSound === 'custom' && gamesSetting.CustomBackgroundSound) {
                    this.backgroundAudio = new Audio(gamesSetting.CustomBackgroundSound);
                }
                if (this.backgroundAudio && isPlayBackgroundSound) {
                    this.backgroundAudio.loop = true;
                    this.backgroundAudio.volume = 0.6;
                    this.backgroundAudio.play();
                }
            }
            this.setState({
                isPlayBackgroundSound
            });
        }
    }

    playVolumeSound = isPlayVolumeSound => {
        if (this.props.playVolumeSound) {
            if (this.state.isPlayVolumeSound) {
                if (this.audio && !this.audio.paused) {
                    this.audio.pause();
                }
            }
            this.props.playVolumeSound(isPlayVolumeSound);
            this.setState({
                isPlayVolumeSound
            });
        }
    }

    renderTry = (TryCount, TryShape, TryColor, TryColorCustom) => {
        var trys = [];
        let tryCount = TryCount - this.props.falseTry;
        const IconType = this.props.gamesSetting && this.props.gamesSetting.IconType ? this.props.gamesSetting.IconType : "fad";

        const digits = tryCount.toString().split('').reverse();

        if (tryCount > 3) {
            return <>
                <span className="d-block  d-sm-none">
                    <i className={`${IconType} ${IconType !== "fa-thin" ? 'text-stroke-1' : ""} fa-${TryShape} h3 text-${TryColor === "custom" ? "" : TryColor} p-1`} style={{
                        color: TryColorCustom
                    }}></i>
                    <i className={`${IconType === "fab" ? "fas" : IconType} ${IconType !== "fa-thin" ? 'text-stroke-1' : ""} fa-times h3 text-${TryColor === "custom" ? "" : TryColor} p-1`} style={{
                        color: TryColorCustom
                    }}></i>
                    {
                        digits.map((Number, index) => {
                            return <i key={`number-${index}-${Number}`} className={`h3 text-${TryColor === "custom" ? "" : TryColor} fas text-stroke-1  fa-${Number} py-1 font-weight-bold`} style={{
                                color: TryColorCustom
                            }}></i>
                        })
                    }
                </span>
                <span className="d-none d-sm-block">
                    <i className={`${IconType} ${IconType !== "fa-thin" ? 'text-stroke-1' : ""} fa-${TryShape} h1 text-${TryColor === "custom" ? "" : TryColor} p-1`} style={{
                        color: TryColorCustom
                    }}></i>
                    <i className={`${IconType === "fab" ? "fas" : IconType} ${IconType !== "fa-thin" ? 'text-stroke-1' : ""} fa-times h1 text-${TryColor === "custom" ? "" : TryColor} p-1`} style={{
                        color: TryColorCustom
                    }}></i>
                    {
                        digits.map((Number, index) => {
                            return <i key={`numbers-${index}-${Number}`} className={`h1 text-${TryColor === "custom" ? "" : TryColor} fas text-stroke-1 fa-${Number} py-1 font-weight-bold`} style={{
                                color: TryColorCustom
                            }}></i>
                        })
                    }
                </span>
            </>
        }
        for (let index = 0; index < TryCount; index++) {
            if (index < 3) {
                trys.push(
                    <span key={index} >
                        <span className="d-block  d-sm-none">
                            <i className={`${IconType} fa-${TryShape} h3 ${IconType !== "fa-thin" ? 'text-stroke-1' : ""} ${tryCount > index ? `text-${TryColor === "custom" ? "" : TryColor}` : "text-light"}  p-1`} style={{
                                color: TryColorCustom
                            }}></i>
                        </span>
                        <span className="d-none d-sm-block">
                            <i className={`${IconType} fa-${TryShape} h1 ${IconType !== "fa-thin" ? 'text-stroke-1' : ""} ${tryCount > index ? `text-${TryColor === "custom" ? "" : TryColor}` : "text-light"}  p-1`} style={{
                                color: TryColorCustom
                            }}></i>
                        </span>
                    </span>
                )
            }
        }
        return trys;
    }

    goBack = () => {
        if (this.props.goBack) {
            this.props.goBack();
        }
    }

    showInfo = () => {
        if (this.props.showInfo) {
            this.props.showInfo();
        }
    }

    render() {
        const { t, gamesSetting } = this.props;

        const TryShape = gamesSetting ? gamesSetting.TryShape : "";
        const TryColor = gamesSetting ? gamesSetting.TryColor : "";
        const TryColorCustom = gamesSetting ? gamesSetting.TryColorCustom : "";
        const TryCount = gamesSetting ? parseInt(gamesSetting.TryCount) + this.props.BounsTry : "";
        let Time = gamesSetting ? parseInt(gamesSetting.Time) + this.props.BounsTime : 0;
        if (this.props.StopTime) {
            Time += this.props.StopTime;
        }
        const IconType = gamesSetting && gamesSetting.IconType ? gamesSetting.IconType : "fad";

        const digits = this.getTime(Time).toString().split('').reverse();

        return <>
            <span className="d-block  d-sm-none">
                <div className="position-absolute w-100 p-1 d-flex justify-content-between align-items-center game-font" style={{
                    zIndex: '17'
                }}>
                    <div className="d-flex align-items-center px-1">
                        {this.renderTry(TryCount, TryShape, TryColor, TryColorCustom)}
                    </div>
                    <div className="d-flex flex-nowrap">
                        {
                            digits.map((Number, index) => {
                                return <i key={`time-${index}-${Number}`} className={`h3 di text-${TryColor === "custom" ? "" : TryColor} px-0 pr-1 ${IconType} fa-${Number} display-4 font-weight-bold ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                                    color: TryColorCustom
                                }} ></i>
                            })
                        }
                    </div>
                    <div className="d-flex align-items-center px-1">
                        <i onClick={() => this.playVolumeSound(!this.state.isPlayVolumeSound)} className={`${IconType} ${this.state.isPlayVolumeSound ? `fa-volume text-${TryColor === "custom" ? "" : TryColor}` : "fa-volume-mute text-light"} h3 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                        <i onClick={() => this.playBackgroundSound(!this.state.isPlayBackgroundSound)} className={`${IconType} ${this.state.isPlayBackgroundSound ? `fa-music text-${TryColor === "custom" ? "" : TryColor}` : "fa-music-slash text-light"} h3 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                        <i onClick={() => this.showInfo()} title={t('Pages.Lessons.tools.info')} className={`${IconType === "fab" ? "fas" : IconType} fa-question text-${TryColor === "custom" ? "" : TryColor} h3 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                        <i onClick={() => this.goBack()} title={t('Pages.Lessons.tools.exit')} className={`${IconType} fa-pause text-${TryColor === "custom" ? "" : TryColor} h3 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                    </div>
                </div>
            </span>
            <span className="d-none d-sm-block">
                <div className="position-absolute w-100 p-1 d-flex justify-content-between align-items-center game-font" style={{
                    zIndex: '17'
                }}>
                    <div className="d-flex align-items-center px-1">
                        {this.renderTry(TryCount, TryShape, TryColor, TryColorCustom)}
                    </div>
                    <div className="d-flex flex-nowrap">
                        {
                            digits.map((Number, index) => {
                                return <i key={`times-${index}-${Number}`} className={`h1 di text-${TryColor === "custom" ? "" : TryColor} fas fa-${Number} display-4 font-weight-bold  px-0 pr-1 text-stroke-1`} style={{
                                    color: TryColorCustom
                                }} ></i>
                            })
                        }
                    </div>
                    <div className="d-flex align-items-center px-1">
                        <i onClick={() => this.playVolumeSound(!this.state.isPlayVolumeSound)} className={`${IconType === "fab" ? "fas" : IconType} ${this.state.isPlayVolumeSound ? `fa-volume text-${TryColor === "custom" ? "" : TryColor}` : "fa-volume-mute text-light"} h1 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                        <i onClick={() => this.playBackgroundSound(!this.state.isPlayBackgroundSound)} className={`${IconType === "fab" ? "fas" : IconType} ${this.state.isPlayBackgroundSound ? `fa-music text-${TryColor === "custom" ? "" : TryColor}` : "fa-music-slash text-light"} h1 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                        <i onClick={() => this.showInfo()} title={t('Pages.Lessons.tools.info')} className={`${IconType === "fab" ? "fas" : IconType} fa-question text-${TryColor === "custom" ? "" : TryColor} h1 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                        <i onClick={() => this.goBack()} title={t('Pages.Lessons.tools.exit')} className={`${IconType === "fab" ? "fas" : IconType} fa-pause text-${TryColor === "custom" ? "" : TryColor} h1 p-1 cursor-pointer ${IconType !== "fa-thin" ? 'text-stroke-1' : ""}`} style={{
                            color: TryColorCustom
                        }}></i>
                    </div>
                </div>
            </span>
        </>
    }
}
export default withTranslation('common')(Tools);
import api from '../../../../api/api';

export const fetchStudentInClassRooms = id => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_LOADING' });
    const response = await api.get(`/StudentInClassRooms/${id}`);
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_INITIAL', studentInClassRooms: response.data.data });
}

export const fetchStudentInMultiClassRooms = ClassRoomId => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_LOADING' });
    const response = await api.post(`/StudentInClassRooms/`, {
        ClassRoomId
    });
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_INITIAL', studentInClassRooms: response.data.data });
}

export const updateStudentInClassRoom = (id, studentInClassRoom) => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_UPDATE_INFO', studentInClassRoom, id });
}

export const removeStudentInClassRooms = id => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_QUEUE_FOR_REMOVAL', id });
}

export const undoStudentInClassRooms = id => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_UNDO', id });
}

export const clearStudentInClassRooms = id => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_CLEAN_NODES', id });
}

export const addStudentInClassRoom = studentInClassRoom => async dispatch => {
    dispatch({ type: 'STUDENTS_IN_CLASSROOM_ADD', studentInClassRoom });
}
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";

import history from '../../../history';
import ShowQuestionModal from "../../Home/Tracks/ExamView/ShowQuestionModal";
import QuestionView from "../Question/View/QuestionView";

class ListItem extends React.Component {

    state = { moreInfo: false, pathList: [], showQuestion: false };

    constructor(props) {
        super(props);
        this.pathList = [];
        this._toastId = new React.createRef();
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    setIndex(node) {
        if (this.props.setIndex) {
            this.props.setIndex(node);
        } else {
            this.pathList = [];
            this.getNodePath(this.props.indices, this.props.item.Index.Id, 0);
            history.push(`/ControlPanel/Lessons/${node.id}`);

        }
    }

    renderPath(currentId) {
        this.pathList = [];
        this.getNodePath(this.props.indices, currentId, 1);
        if (this.pathList) {
            const length = this.pathList.length;
            return this.pathList.reverse().map((node, index) => {
                return (
                    <span
                        key={node.id}>
                        <Link
                            onClick={() => this.setIndex(node)}
                            to="#">
                            {node.label}
                        </Link>
                        {(length !== index + 1) ? <i className="fas fa-arrow-left text-info m-2"></i> : ""}
                    </span>
                );
            });
        }
        return "";
    }

    getNodePath(nodes, currentId, Level) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, currentId);
                if (isOpen) {
                    this.getNodePath(node.nodes, currentId, Level);
                    if (node.Level > Level) {
                        this.pathList.push(node);
                    }
                }
            });
        }
    }

    HasNode(node, currentId) {
        if (node) {
            return (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, currentId) {
        var result = false;
        nodes.forEach(node => {
            result = result || (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        });
        return result;
    }

    openViewWindow = lesson => {
        if (lesson) {
            if (lesson.Type === "Lessons") {
                window.openLessonView(lesson.Id);
            } else {
                window.openExamView(lesson.Id);
            }
        }
    }

    openLessonListView = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false, showQuestion: false });
    }

    openQuestionViewWindow = () => {
        this.setState({
            showQuestion: true
        });
    }

    renderQuestionTrueResult = () => {
        const question = this.props.item.Question;
        if (question) {
            return <QuestionView
                question={question}
                key={question.Id}
                index={1}
                showResult={false}
                onEdit={true}
                solveButton={false} />
        }
        return "";
    }

    render() {
        const { t, item } = this.props;

        return (
            <>
                {
                    this.state.showQuestion ?
                        <ShowQuestionModal renderQuestionView={this.renderQuestionTrueResult} showResult={true} handleClose={this.handleClose}></ShowQuestionModal>
                        : ""
                }
                <div className="col px-0 mx-0"  >
                    <div className="product-list-item" >
                        <div className="product-thumb">
                            {
                                item.Icon ?
                                    <LazyLoadImage src={item.Icon} alt={`${item.Name}`} />
                                    :
                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                            }
                            <div className="product-action-link">
                                <div className="my-1 mr-1" onClick={() => this.openQuestionViewWindow()} title={t("Pages.Lessons.action.view")}><i className="far fa-eye"></i></div>
                                <Link to={`/ControlPanel/Lesson/Edit/${item.LessonId}`} className="my-1 mr-1" title={t("Pages.Lessons.action.edit")} > <i className="far fa-edit"></i> </Link>
                                <div className="my-1 mr-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.Lessons.action.remove")}><i className="far fa-trash"></i></div>
                                <div className="my-1 mr-1" onClick={() => this.moreInfo()} title={t("Pages.Lessons.action.info")}><i className="far fa-info"></i></div>
                            </div>
                        </div>
                        <div className="product-content">
                            <h5 className="title"> {item.Name} </h5>
                            <h6 className="subtitle">
                                {this.renderPath(item.Index.Id)}
                            </h6>
                            <div className="d-flex justify-content-between align-items-center text-danger">
                                <p className="px-1 ml-auto">
                                    {item.Note}
                                </p>
                                {
                                    item.IsSolved ?
                                        <Link to="#" className="cate mr-1 btn btn-success " title={t("Pages.ErrorReport.solved")}>
                                            <i className="fal fa-badge-check "></i>
                                        </Link>
                                        :
                                        <Link to="#" className="cate mr-1 btn btn-light " onClick={() => this.props.isSolved(item, "IsSolved")} title={t("Pages.ErrorReport.solved") + " ؟ "}>
                                            <i className="fal fa-badge-check "></i>
                                        </Link>
                                }
                                {
                                    item.IsArchived ?
                                        <Link to="#" className="cate mr-1 btn btn-info " title={t("Pages.ErrorReport.archive")} onClick={() => this.props.isSolved(item, "IsArchived")}>
                                            <i className="fal fa-box-archive "></i>
                                        </Link>
                                        :
                                        <Link to="#" className="cate mr-1 btn btn-light " onClick={() => this.props.isSolved(item, "IsArchived")} title={t("Pages.ErrorReport.NotInArchive")}>
                                            <i className="fal fa-box-archive "></i>
                                        </Link>
                                }
                            </div>
                        </div>
                        {
                            this.state.moreInfo ?
                                <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                    borderTop: '1px dashed #92278f'
                                }}>
                                    <div className="text-center">
                                        <div className="title">
                                            {t("Pages.Lessons.DataEntry")}  :
                                            <div className="text-secondary">
                                                {item.User.Name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div className="title">
                                            {t("Pages.Lessons.CreationDate")}  :
                                            <div className="text-secondary">
                                                {item.CreationDate}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div className="title">
                                            {t("Pages.Lessons.UpdateDate")}  :
                                            <div className="text-secondary">
                                                {item.UpdateDate}
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation('common')(ListItem);
import React from "react";
import TextBox from "../../../TextBox";
import './style.scss';

class Pika extends React.Component {

    render() {
        return (
            <>
                <div className="pika-container d-flex justify-content-center flex-column align-items-center">
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className=" pika">
                                <div className="pika-head">
                                    <div className="pika-head-face">
                                        <div className="pika-eye pika-eye-left"></div>
                                        <div className="pika-eye pika-eye-right"></div>
                                        <div className="pika-nose"></div>
                                        <div className="pika-mouth"></div>
                                    </div>
                                    <div className="pika-ear pika-ear-left"></div>
                                    <div className="pika-ear pika-ear-right"></div>
                                </div>
                                <div className="pika-body">
                                    <div className="pika-body-body"></div>
                                    <div className="pika-arm pika-arm-left"></div>
                                    <div className="pika-arm pika-arm-right"></div>
                                    <div className="pika-leg pika-leg-left"></div>
                                    <div className="pika-leg pika-leg-right"></div>
                                    <div className="pika-tail"></div>
                                    <div className="Pika_strings">
                                        <div className="Pika_string"></div>
                                        <div className="Pika_string"></div>
                                    </div>
                                </div>
                                <div className="Pika_balloons">
                                    <div className="Pika_balloon Pika_cyan">
                                        <div className="Pika_string"></div>
                                    </div>
                                    <div className="Pika_balloon Pika_red">
                                        <div className="Pika_string"></div>
                                    </div>
                                    <div className="Pika_balloon Pika_pink">
                                        <div className="Pika_string"></div>
                                    </div>
                                    <div className="Pika_balloon Pika_blue">
                                        <div className="Pika_string"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TextBox {...this.props} />
                </div>
            </>
        );
    }
}

export default Pika;

import React from "react";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import api from '../../../api/api';
import QuestionTitle from "../../Admin/Question/View/QuestionTitle";
import { Helmet } from "react-helmet";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class ModalStatistic extends React.Component {

    state = { Question: null, Questions: [], markRang: [], classRooms: [], ClassRoomId: 0, studentsResult: [] };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();

        if (this.props.item && this.props.item.ClassTypeName) {
            this.state.classRooms = this.props.item.ClassTypeName.map(classRoom => {
                return {
                    value: classRoom.Id,
                    label: classRoom.Name
                };
            });
        }
        if (this.props.Questions) {
            this.state.Questions = this.props.Questions.map((question, index) => {
                return {
                    value: question.Id,
                    question: question,
                    index: index + 1,
                    label: <div dangerouslySetInnerHTML={{ __html: question.Name }}></div>
                };
            });
        }
        this.state.markRang = [{
            value: 1,
            label: '100',
            total: 0
        },
        {
            value: 2,
            label: '90-99',
            total: 0
        },
        {
            value: 3,
            label: '80-99',
            total: 0
        },
        {
            value: 4,
            label: '70-79',
            total: 0
        },
        {
            value: 5,
            label: '60-69',
            total: 0
        },
        {
            value: 6,
            label: '50-59',
            total: 0
        },
        {
            value: 7,
            label: '50 >',
            total: 0
        }];

        this.state.options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: this.props.item.Lesson.Name,
                },
            },
        };

        const labels = this.state.markRang.map(c => c.label);

        this.state.data = {
            labels: labels,
            datasets: [
                {
                    label: `${this.props.t('Pages.Missions.table.total')}`,
                    data: labels.map(() => 100),
                    backgroundColor: 'rgba(177, 255, 179, 0.5)',
                },
                {
                    label: `${this.props.t('Pages.Missions.table.female')}`,
                    data: labels.map(() => 100),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: `${this.props.t('Pages.Missions.table.male')}`,
                    data: labels.map(() => 100),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        };
    }

    componentDidMount() {
        let studentsResult = [];
        api.get(`/MissionStatistics/${this.props.Id}`).then(response => {
            studentsResult = response.data;

            const labels = this.state.markRang.map(c => c.label);

            let total = [];
            this.state.markRang.forEach(item => {
                total.push({ ...item });
            });

            let male = [];
            this.state.markRang.forEach(item => {
                male.push({ ...item });
            });

            let female = [];
            this.state.markRang.forEach(item => {
                female.push({ ...item });
            });

            studentsResult.forEach(student => {
                if (student && student.Mark >= 100) {
                    total[0].total++;
                } else if (student && student.Mark >= 90) {
                    total[1].total++;
                } else if (student && student.Mark >= 80) {
                    total[2].total++;
                } else if (student && student.Mark >= 70) {
                    total[3].total++;
                } else if (student && student.Mark >= 60) {
                    total[4].total++;
                } else if (student && student.Mark >= 50) {
                    total[5].total++;
                } else if (student && student.Mark < 50) {
                    total[6].total++;
                }

                if (student.User.Sex === "male") {
                    if (student && student.Mark >= 100) {
                        male[0].total++;
                    } else if (student && student.Mark >= 90) {
                        male[1].total++;
                    } else if (student && student.Mark >= 80) {
                        male[2].total++;
                    } else if (student && student.Mark >= 70) {
                        male[3].total++;
                    } else if (student && student.Mark >= 60) {
                        male[4].total++;
                    } else if (student && student.Mark >= 50) {
                        male[5].total++;
                    } else if (student && student.Mark < 50) {
                        male[6].total++;
                    }
                } else if (student.User.Sex === "female") {
                    if (student && student.Mark >= 100) {
                        female[0].total++;
                    } else if (student && student.Mark >= 90) {
                        female[1].total++;
                    } else if (student && student.Mark >= 80) {
                        female[2].total++;
                    } else if (student && student.Mark >= 70) {
                        female[3].total++;
                    } else if (student && student.Mark >= 60) {
                        female[4].total++;
                    } else if (student && student.Mark >= 50) {
                        female[5].total++;
                    } else if (student && student.Mark < 50) {
                        female[6].total++;
                    }
                }
            });

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: `${this.props.t('Pages.Missions.table.total')}`,
                        data: total.map(p => p.total),
                        backgroundColor: 'rgba(177, 255, 179, 0.5)',
                    },
                    {
                        label: `${this.props.t('Pages.Missions.table.female')}`,
                        data: female.map(p => p.total),
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: `${this.props.t('Pages.Missions.table.male')}`,
                        data: male.map(p => p.total),
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };

            this.setState({
                data,
                studentsResult
            });
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.label.includes(input);
        }
        return true;
    }

    filterOptionsQuestion = (candidate, input) => {
        if (input) {
            return candidate.data.question.Name.includes(input);
        }
        return true;
    }

    setSelectedQuestionOption = Question => {
        if (Question) {
            this.setState({
                Question
            });
        } else {
            this.setState({
                Question: null
            });
        }
        setTimeout(() => {
            this.setData();
        }, 300);
    }

    setSelectedOption = ClassRoom => {
        if (ClassRoom) {
            this.setState({
                ClassRoomId: ClassRoom.value
            });
        } else {
            this.setState({
                ClassRoomId: 0
            });
        }
        setTimeout(() => {
            this.setData();
        }, 300);
    }

    setSelectedMarkOption = Mark => {
        if (Mark) {
            this.setState({
                MarkId: Mark
            });
        } else {
            this.setState({
                MarkId: []
            });
        }
        setTimeout(() => {
            this.setData();
        }, 300);
    }

    setData = () => {

        let question = null;
        if (this.state.Question && this.state.Question.question) {
            question = this.state.Question.question;
        }
        const labels = this.state.markRang.map(c => c.label);

        let total = [];
        this.state.markRang.forEach(item => {
            total.push({ ...item });
        });

        let male = [];
        this.state.markRang.forEach(item => {
            male.push({ ...item });
        });

        let female = [];
        this.state.markRang.forEach(item => {
            female.push({ ...item });
        });

        this.state.studentsResult.forEach(student => {
            if (student.User.ClassRoom.ClassRoomId !== this.state.ClassRoomId && this.state.ClassRoomId !== 0) {
                return null;
            }

            if (question) {
                var solve = student.Solves.filter(p => p.Id === question.Id)[0];
                if (solve && solve.Rate >= 100) {
                    total[0].total++;
                } else if (solve && solve.Rate >= 90) {
                    total[1].total++;
                } else if (solve && solve.Rate >= 80) {
                    total[2].total++;
                } else if (solve && solve.Rate >= 70) {
                    total[3].total++;
                } else if (solve && solve.Rate >= 60) {
                    total[4].total++;
                } else if (solve && solve.Rate >= 50) {
                    total[5].total++;
                } else if (solve && solve.Rate < 50) {
                    total[6].total++;
                }

                if (student.User.Sex === "male") {
                    if (solve && solve.Rate >= 100) {
                        male[0].total++;
                    } else if (solve && solve.Rate >= 90) {
                        male[1].total++;
                    } else if (solve && solve.Rate >= 80) {
                        male[2].total++;
                    } else if (solve && solve.Rate >= 70) {
                        male[3].total++;
                    } else if (solve && solve.Rate >= 60) {
                        male[4].total++;
                    } else if (solve && solve.Rate >= 50) {
                        male[5].total++;
                    } else if (solve && solve.Rate < 50) {
                        male[6].total++;
                    }
                } else if (student.User.Sex === "female") {
                    if (solve && solve.Rate >= 100) {
                        female[0].total++;
                    } else if (solve && solve.Rate >= 90) {
                        female[1].total++;
                    } else if (solve && solve.Rate >= 80) {
                        female[2].total++;
                    } else if (solve && solve.Rate >= 70) {
                        female[3].total++;
                    } else if (solve && solve.Rate >= 60) {
                        female[4].total++;
                    } else if (solve && solve.Rate >= 50) {
                        female[5].total++;
                    } else if (solve && solve.Rate < 50) {
                        female[6].total++;
                    }
                }
            } else {
                if (student && student.Mark >= 100) {
                    total[0].total++;
                } else if (student && student.Mark >= 90) {
                    total[1].total++;
                } else if (student && student.Mark >= 80) {
                    total[2].total++;
                } else if (student && student.Mark >= 70) {
                    total[3].total++;
                } else if (student && student.Mark >= 60) {
                    total[4].total++;
                } else if (student && student.Mark >= 50) {
                    total[5].total++;
                } else if (student && student.Mark < 50) {
                    total[6].total++;
                }

                if (student.User.Sex === "male") {
                    if (student && student.Mark >= 100) {
                        male[0].total++;
                    } else if (student && student.Mark >= 90) {
                        male[1].total++;
                    } else if (student && student.Mark >= 80) {
                        male[2].total++;
                    } else if (student && student.Mark >= 70) {
                        male[3].total++;
                    } else if (student && student.Mark >= 60) {
                        male[4].total++;
                    } else if (student && student.Mark >= 50) {
                        male[5].total++;
                    } else if (student && student.Mark < 50) {
                        male[6].total++;
                    }
                } else if (student.User.Sex === "female") {
                    if (student && student.Mark >= 100) {
                        female[0].total++;
                    } else if (student && student.Mark >= 90) {
                        female[1].total++;
                    } else if (student && student.Mark >= 80) {
                        female[2].total++;
                    } else if (student && student.Mark >= 70) {
                        female[3].total++;
                    } else if (student && student.Mark >= 60) {
                        female[4].total++;
                    } else if (student && student.Mark >= 50) {
                        female[5].total++;
                    } else if (student && student.Mark < 50) {
                        female[6].total++;
                    }
                }
            }
        });

        const data = {
            labels: labels,
            datasets: [
                {
                    label: `${this.props.t('Pages.Missions.table.total')}`,
                    data: total.map(p => p.total),
                    backgroundColor: 'rgba(177, 255, 179, 0.5)',
                },
                {
                    label: `${this.props.t('Pages.Missions.table.female')}`,
                    data: female.map(p => p.total),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: `${this.props.t('Pages.Missions.table.male')}`,
                    data: male.map(p => p.total),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        };

        this.setState({
            data
        });
    }

    getClassList = item => {
        let result = "  ";
        item.ClassTypeName.forEach(classRoom => {
            if (this.state.ClassRoomId) {
                if (this.state.ClassRoomId === classRoom.Id) {
                    result += classRoom.Name + ", ";
                }
            } else {
                result += classRoom.Name + ", ";
            }
        });
        return result.substring(0, result.length - 2);
    }

    render() {
        const { t, item } = this.props;

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return <>

            <Helmet>
                <title>  {t('Pages.Missions.StatisticResult')}  | {item.Lesson.Name} |
                    (
                    {this.getClassList(item)}
                    )
                </title>
            </Helmet>
            <Modal
                centered
                key="ModalStatistic"
                size="xl"
                show={true}
                onHide={() => this.props.handleClose()}
                backdrop="static"
                keyboard={false}
                enforceFocus={false} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="icon fal fa-poll"></i>
                        {t('Pages.Missions.StatisticResult')}  |  <span className="font-weight-bold">  {item.Lesson.Name}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contact-form">
                        <div className="form-group" style={{
                            zIndex: 9999999
                        }}>
                            <Select
                                name="ClassRoomId"
                                title={t('Pages.Missions.ClassRoom.title')}
                                placeholder={t('Pages.Missions.ClassRoom.placeholder')}
                                onChange={e => this.setSelectedOption(e)}
                                isRtl={true}
                                isClearable={true}
                                isSearchable={true}
                                ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                options={this.state.classRooms}
                                filterOption={this.filterOptions}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#92278f',
                                        primary: '#92278f',
                                    },
                                })}
                            />
                        </div>
                        <div className="form-group " style={{
                            zIndex: 9999999
                        }}>
                            <Select
                                name="MarkId"
                                title={t('Pages.Missions.Mark.title')}
                                placeholder={t('Pages.Missions.Mark.placeholder')}
                                onChange={e => this.setSelectedMarkOption(e)}
                                isRtl={true}
                                isMulti={true}
                                isClearable={true}
                                isSearchable={true}
                                ref={MarkId => this.MarkId = MarkId}
                                options={this.state.markRang}
                                filterOption={this.filterOptions}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#92278f',
                                        primary: '#92278f',
                                    },
                                })}
                            />
                        </div>

                        <div className="form-group w-100">
                            <Select
                                name="QuestionId"
                                title={t('Pages.Missions.Question.title')}
                                placeholder={t('Pages.Missions.Question.placeholder')}
                                onChange={e => this.setSelectedQuestionOption(e)}
                                isRtl={true}
                                isClearable={true}
                                isSearchable={true}
                                ref={QuestionId => this.QuestionId = QuestionId}
                                options={this.state.Questions}
                                filterOption={this.filterOptionsQuestion}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#92278f',
                                        primary: '#92278f',
                                    },
                                })}
                            />
                        </div>
                        <div className="form-group mb-5 w-100">
                            <div className="d-flex justify-content-center w-auto">
                                <ReactToPrint content={() => this.componentRef}
                                    trigger={() => {
                                        return <div className="custom-button orange-button">
                                            <span>
                                                <i className="icon fal fa-print"></i> {t('Print')}
                                            </span>
                                        </div>;
                                    }} />
                            </div>
                        </div>
                    </div>

                    <div className="widget widget-category w-100" ref={el => (this.componentRef = el)}>
                        <div className="d-none d-print-block">
                            <div className="pt-3 px-3 mt-3 border-bottom border-dark mx-1">
                                <div className="d-flex justify-content-around align-items-center">
                                    <img src="/assets/images/logo/logo.png" alt={`${t('alarabeyya')}`} />
                                    <div className="h5 text-center">
                                        <div className="h3 title mt-2">
                                            {item.Lesson.Name}
                                        </div>
                                        {t('Pages.Missions.studentsResult')}
                                    </div>
                                    <div className="h5 text-right">
                                        {t('Date')} :
                                        {`${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`}
                                        <div className="h4 title mt-2">
                                            <span className="h5">
                                                {t("Teacher.Name")} :
                                            </span>
                                            {item.User.Name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-body" >
                            {
                                this.state.Question ?
                                    <QuestionTitle
                                        onEdit={false}
                                        index={this.state.Question.index + 1}
                                        title={this.state.Question.question.Name}
                                        mark={this.state.Question.question.Mark}
                                        showQuestionTrueResult={this.showQuestionTrueResult}
                                        showResult={true}></QuestionTitle>
                                    : ""
                            }
                            <Bar options={this.state.options} data={this.state.data} />
                            {
                                this.state.Question ?
                                    <>
                                        <table className="table table-striped table-hover table-bordered mt-3">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>
                                                        {t('Pages.Missions.table.student')}
                                                    </th>
                                                    <th>
                                                        {t('Pages.Missions.table.Username')}
                                                    </th>
                                                    <th>
                                                        {t('Pages.Missions.table.ClassRoom')}
                                                    </th>
                                                    <th>
                                                        {t('Pages.Missions.table.mark')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.studentsResult ? this.state.studentsResult.map(student => {
                                                        if (student.User.ClassRoom.ClassRoomId !== this.state.ClassRoomId && this.state.ClassRoomId !== 0) {
                                                            return null;
                                                        }
                                                        let display = true;
                                                        var solve = student.Solves.filter(p => p.Id === this.state.Question.question.Id)[0];
                                                        if (this.state.MarkId && this.state.MarkId.length > 0) {
                                                            display = false;
                                                            if (solve && solve.Rate) {
                                                                this.state.MarkId.forEach(mark => {
                                                                    if (mark.value === 1 && solve.Rate >= 100) {
                                                                        display = true;
                                                                    } else if (mark.value === 2 && solve.Rate >= 90 && solve.Rate <= 99) {
                                                                        display = true;
                                                                    } else if (mark.value === 3 && solve.Rate >= 80 && solve.Rate <= 89) {
                                                                        display = true;
                                                                    } else if (mark.value === 4 && solve.Rate >= 70 && solve.Rate <= 79) {
                                                                        display = true;
                                                                    } else if (mark.value === 5 && solve.Rate >= 60 && solve.Rate <= 69) {
                                                                        display = true;
                                                                    } else if (mark.value === 6 && solve.Rate >= 50 && solve.Rate <= 59) {
                                                                        display = true;
                                                                    } else if (mark.value === 7 && solve.Rate < 50) {
                                                                        display = true;
                                                                    }
                                                                });
                                                            }
                                                        }
                                                        if (display === false) {
                                                            return null;
                                                        }

                                                        return <tr key={`student-${student.User.Id}`}>
                                                            <td>
                                                                {student.User.Name}
                                                            </td>
                                                            <td>
                                                                {student.User.Username}
                                                            </td>
                                                            <td>
                                                                {
                                                                    student.User.ClassRoom ?
                                                                        <>
                                                                            {student.User.ClassRoom.ClassTypeName} - {student.User.ClassRoom.DivisionName}
                                                                        </> : ""
                                                                }
                                                            </td>
                                                            <td>

                                                                {
                                                                    solve && solve.Mark >= 0 ?
                                                                        <div className="font-weight-bold h4 d-flex justify-content-center" >
                                                                            <span className={`${(solve.Mark / solve.Total) >= 0.5 ? "text-success" : "text-danger"} `}>
                                                                                {solve.Mark}
                                                                            </span>
                                                                            /
                                                                            <span>
                                                                                {solve.Total}
                                                                            </span>
                                                                        </div>
                                                                        :
                                                                        t('Pages.Missions.table.NoAnswer')
                                                                }
                                                            </td>
                                                        </tr>
                                                    }) : null
                                                }
                                            </tbody>
                                        </table>
                                    </>
                                    : ""
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    }
}

export default withTranslation('common')(ModalStatistic);
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import InfoForm from './Forms/InfoForm';
import api from '../../../api/api';

class ListItem extends React.Component {

    state = { moreInfo: false };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    openViewWindow = Id => {
        if (Id) {
            window.openLessonView(Id);
        }
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    edit = () => {
        this.setState({ show: true });
    }

    onSubmit = (postData, _toastId) => {
        const { t, item } = this.props;

        api.put(`/Sliders/${item.Id}`,
            postData
        ).then(response => {
            this.props.Sync();

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Sliders.update_success_toast.Title')}</div> <div className="h6">{t('Pages.Sliders.update_success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.handleClose();

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, item } = this.props;
        if (item) {

            return (
                <>
                    {
                        this.state.show ?
                            <Modal
                                size="xl"
                                show={this.state.show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false} >
                                <Modal.Header closeButton>
                                    <Modal.Title> <i className="icon fal fa-image-polaroid"></i> {t('Pages.Sliders.form.title')} </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <InfoForm
                                        item={item}
                                        onSubmit={this.onSubmit} ></InfoForm>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >

                            <div className="product-thumb">
                                {
                                    item.Photo ?
                                        <LazyLoadImage src={item.Photo} alt={`${item.Title}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Title}`} />
                                }
                                <div className="product-action-link">
                                    <Link className="my-1" to={`/Slider/${item.Id}`} target="_blank" title={t("Pages.Sliders.action.view")}><i className="far fa-eye"></i></Link>
                                    <div className="my-1" onClick={() => this.edit()} title={t("Pages.Sliders.action.edit")}><i className="far fa-edit"></i></div>
                                    <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.Sliders.action.remove")}><i className="far fa-trash"></i></div>
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.Sliders.action.info")}><i className="far fa-info"></i></div>

                                </div>
                            </div>
                            <div className="product-content">
                                <h5 className="title"><Link to={`/Slider/${item.Id}`} target="_blank"> {item.Title} </Link></h5>
                                <div title={`${t('Pages.Sliders.ViewCount')}`} ><i className="fa-light fa-eye"></i> {item.ViewCount}</div>
                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Sliders.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Sliders.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Sliders.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(ListItem);
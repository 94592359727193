import React from "react";
import './style.scss';

class SnowfallBackground extends React.Component {

    render() {
        return (
            <div className="SnowfallBackground">
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
                <div className="snowflake"></div>
            </div>
        );
    }
}

export default SnowfallBackground;
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import moment from "moment";
import { Link } from "react-router-dom";
import 'moment/locale/ar';

import Footer from "../Footer/FooterBottom";
import {
    fetchSlider as fetch,
} from '../Admin/Sliders/actions';

class Slider extends React.Component {

    componentDidMount() {
        const { id } = this.props.match.params;
        moment.locale(`ar`);
        this.props.fetch(id);
        window.scrollToTop();
    }

    render() {
        const { t, sliders } = this.props;
        return (
            <>
                <section className="pos-rel padding-bottom pt-5 oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/images/about/4267.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <div className="teacher-wrapper pos-rel pt-5 bg-white" >
                            <div className="teacher-single-content">
                                {
                                    sliders && sliders.current ?
                                        <>
                                            <h4 className="title text-center">{sliders.current.Title}</h4>


                                            <ul className="meta-post justify-content-end">
                                                <li>
                                                    <Link to="#" title={`${t('Pages.Sliders.ViewCount')} : ${sliders.current.ViewCount}`}>
                                                        <i className="fa-light fa-eye"></i> {sliders.current.ViewCount}
                                                    </Link>
                                                </li>
                                                <li  >
                                                    <Link to="#" title={`${t('Pages.Sliders.UpdateDate')} : ${sliders.current.UpdateDate}`}>
                                                        <i className="fa-light fa-calendar-clock"></i> {moment(sliders.current.UpdateDate).fromNow()}
                                                    </Link>
                                                </li>
                                            </ul>

                                            <Helmet>
                                                <title>{sliders.current.Title} | {t('alarabeyya')}</title>
                                            </Helmet>
                                            <div className="w-100 text-center mt-3">
                                                <LazyLoadImage src={sliders.current.Photo} alt={`${sliders.current.Title}`} />
                                            </div>
                                            <div className="pt-4" dangerouslySetInnerHTML={{
                                                __html: sliders.current.Descrpition
                                            }}>

                                            </div>
                                        </>
                                        :
                                        <div className="product-content w-100 text-center ">
                                            <div className="display-1 text-danger">
                                                <i className="fad fa-sync-alt fa-spin"></i>
                                            </div>
                                            <h4 className="display-3 subtitle">
                                                {t("Loading")}
                                            </h4>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        sliders: state.sliders
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(Slider));
import React from "react";
import TextBox from "../../../TextBox";
import './style.scss';

class FlyingBee extends React.Component {

    render() {
        let color = "#FFB733";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const style = {
            backgroundColor: color,
            boxShadow: ` 20px 0 0 #000,  40px -3px 0 ${color}, 60px -7px 0 #000,  80px -11px 0 ${color}`
        };
        return <div className="FlyingBee_wrap_move">
            <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                    transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                }}>
                    <div className="FlyingBee_wrap" >
                        <div className="FlyingBee_wrap2">
                            <div>
                                <div className={`FlyingBee_body `} style={style}>
                                    <div className="FlyingBee_eyes">
                                        <div className="FlyingBee_pupil"></div>
                                    </div>
                                    <div className="FlyingBee_stinger"></div>
                                </div>
                                <div className="FlyingBee_wing1"></div>
                                <div className="FlyingBee_wing2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TextBox {...this.props} />
        </div>;
    }
}

export default FlyingBee;
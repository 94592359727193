import api from '../../../../api/api';

export const fetchClassRooms = (getAllClass = false) => async dispatch => {
    dispatch({ type: 'CLASSROOMS_LOADING' });
    const response = await api.get(`/ClassRooms/?getAllClass=` + getAllClass);
    dispatch({ type: 'CLASSROOMS_INITIAL', classRooms: response.data.data });
}

export const updateClassRoom = (id, classRoom) => async dispatch => {
    dispatch({ type: 'CLASSROOMS_UPDATE_INFO', classRoom, id });
}

export const removeClassRooms = (id) => async dispatch => {
    dispatch({ type: 'CLASSROOMS_QUEUE_FOR_REMOVAL', id });
}

export const undoClassRooms = (id) => async dispatch => {
    dispatch({ type: 'CLASSROOMS_UNDO', id });
}

export const clearClassRooms = (id) => async dispatch => {
    dispatch({ type: 'CLASSROOMS_CLEAN_NODES', id });
}

export const addClassRoom = classRoom => async dispatch => {
    dispatch({ type: 'CLASSROOMS_ADD_NEW', classRoom });
}

export const clearListClassRoom = () => async dispatch => {
    dispatch({ type: 'CLASSROOMS_CLEAN_LIST_NODES' });
}
import api from '../../../../api/api';

export const loadSchools = () => async dispatch => {
    dispatch({ type: 'SCHOOLS_LOADING' });
}

export const fetchSchools = postData => async dispatch => {
    dispatch({ type: 'SCHOOLS_LOADING' });
    const response = await api.post(`/SchoolsLookups/`, postData);
    dispatch({ type: 'SCHOOLS_INITIAL', schools: response.data.data });
}

export const updateSchool = (id, school) => async dispatch => {
    dispatch({ type: 'SCHOOLS_UPDATE_INFO', school, id });
}

export const removeSchools = (id) => async dispatch => {
    dispatch({ type: 'SCHOOLS_QUEUE_FOR_REMOVAL', id });
}

export const undoSchools = (id) => async dispatch => {
    dispatch({ type: 'SCHOOLS_UNDO', id });
}

export const clearSchools = (id) => async dispatch => {
    dispatch({ type: 'SCHOOLS_CLEAN_NODES', id });
}

export const addSchool = school => async dispatch => {
    dispatch({ type: 'SCHOOLS_ADD_NEW', school });
}
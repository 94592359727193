import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import {
    fetchTemplates as fetch,
    removeTemplate as remove,
    undoDeleteTemplate as undo,
    clearTemplate as clear
} from './actions';

import api from '../../../api/api';
import ListItem from "./ListItem";
import InfoForm from "./InfoForm";

class GameTemplates extends React.Component {

    state = { Id: 0, search: '', showModal: false, CustomStyle: [], Name: "" };

    componentDidMount() {
        this.Sync();
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = () => {
        this.props.fetch();
    }

    renderTemplates() {
        const { t, gameTemplates } = this.props;
        let isLoaded = false;

        if (gameTemplates) {
            isLoaded = gameTemplates.isLoaded;
        }

        let toRemove = [];
        if (gameTemplates) {
            toRemove = gameTemplates.toRemove;
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (gameTemplates && gameTemplates.gameTemplates && gameTemplates.gameTemplates.length > 0) {
            let filterTemplate = gameTemplates.gameTemplates.filter(v => v.Name.includes(search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Templates.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterTemplate.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Templates.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Templates.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterTemplate.map(item =>
                (toRemove && toRemove.includes(item.Id)) ? "" :
                    <ListItem
                        IsAdmin={true}
                        key={item.Id}
                        item={item}
                        duplicate={this.duplicate}
                        Sync={this.Sync}
                        edit={this.edit}
                        remove={this.showDelete} />
            );
        }

        if (gameTemplates && gameTemplates.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Templates.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Templates.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteTemplate(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteTemplate = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/GameCustomStyle/${id}`).then(response => {
            this.props.clear(id);
            this.Sync(this.state.QuestionTypeId);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Templates.toast_Delete.Title')}</div> <div className="h6">{t('Pages.Templates.toast_Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    changeQuestionType = type => {
        if (type) {
            this.Sync(type.value);
            this.setState({
                QuestionTypeId: type.value
            });
        }
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    showModal = () => {
        this.setState({
            showModal: true,
            CustomStyle: [],
            Name: "",
            Id: 0
        });
    }

    edit = item => {
        this.setState({
            showModal: true,
            CustomStyle: item.CustomStyle,
            Name: item.Name,
            Id: item.Id
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        let url = `/GameCustomStyle/`;
        let method = api.post;
        if (this.state.Id && this.state.Id !== "0") {
            url = `/GameCustomStyle/${this.state.Id}`;
            method = api.put;
        }

        method(url,
            postData
        ).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Templates.add_success_toast.Title')}</div> <div className="h6">{t('Pages.Templates.add_success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            this.handleClose();

            this.Sync();


        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    duplicate = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('Pages.Templates.clone_toast.Title')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.duplicateTemplate(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-clone"></i> {t('Pages.Templates.clone_toast.confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }

    }

    duplicateTemplate = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/GameCustomStyle/${id}`).then(response => {

            this.Sync();

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Templates.add_success_toast.Title')}</div> <div className="h6">{t('Pages.Templates.add_success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }


    render() {
        const { t, user } = this.props;

        return (
            <>

                {
                    this.state.showModal ?
                        <Modal
                            size="xl"
                            show={true}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="fas fa-plus"></i>{t(`Pages.Templates.Add`)} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <InfoForm onSubmit={this.onSubmit} CustomStyle={this.state.CustomStyle} Name={this.state.Name} />
                            </Modal.Body>
                        </Modal> :
                        ""
                }

                <Helmet>
                    <title>{t("Pages.Templates.GameTitle")} | {t('alarabeyya')}</title>
                    <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/css/game.css?v=' + process.env.REACT_APP_VERSION} />
                </Helmet>
                <section className="pos-rel padding-top padding-bottom">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">


                        <div className="row mb-30-none">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.Templates.GameTitle')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">

                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Templates.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div title={t("Pages.Templates.Add")} className="btn mx-1 btn-success" onClick={this.showModal}>
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div title={t("Pages.Templates.Sync")} className="btn mx-1 btn-danger" onClick={this.Sync}>
                                                    <i className="fas fa-sync"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderTemplates()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        gameTemplates: state.gameTemplates
    };
};

export default connect(mapStateToProps, { fetch, remove, undo, clear })(withTranslation('common')(GameTemplates));
import React from "react";
import TextBox from "../../../TextBox";
import './style.css';

class JellyFish extends React.Component {

    render() {
        const random = Math.floor(Math.random() * 1000);
        let color = "cyan";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const style = {
            backgroundColor: color
        };
        const borderColor = {
            borderColor: color
        };
        return <div className="position-relative">
            <style dangerouslySetInnerHTML={{
                __html: `.base_${random}:before,.base_${random}:after{background: radial-gradient(circle at 50% 120%, rgba(0, 255, 255, 0) 0, rgba(0, 255, 255, 0) 25%, ${color} 10%, ${color} 100%) !important;} .JellyFish-datouwang .JellyFish-body .JellyFish-base{ box-shadow: 3.5em 0 0 ${color} , 7em 0 0 ${color} !important;} `
            }}></style>
            <div className="d-flex justify-content-center align-items-center flex-column">
                <div className="d-flex justify-content-center JellyFish-datouwang-text mb-1 position-relative" >
                    <TextBox {...this.props} />
                </div>
                <div className="JellyFish-datouwang">
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className="JellyFish-arms">
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                                <div className={`JellyFish-arm arm_${random}`}></div>
                            </div>
                            <div className="JellyFish-tentacles">
                                <div className="JellyFish-tentacle" style={borderColor}></div>
                                <div className="JellyFish-tentacle" style={borderColor}></div>
                                <div className="JellyFish-tentacle" style={borderColor}></div>
                                <div className="JellyFish-tentacle" style={borderColor}></div>
                                <div className="JellyFish-tentacle" style={borderColor}></div>
                            </div>
                            <div className="JellyFish-guts"></div>
                            <div className="JellyFish-body" style={style}>
                                <div className={`JellyFish-base base_${random}`} style={style}></div>
                            </div>
                            <div className="JellyFish-eyes">
                                <div className="JellyFish-eye JellyFish-left">
                                    <div className="JellyFish-eyelid" style={style}></div>
                                </div>
                                <div className="JellyFish-eye JellyFish-right">
                                    <div className="JellyFish-eyelid" style={style}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default JellyFish;
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Field, reduxForm } from 'redux-form'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import Footer from "../../Footer/FooterBottom";
import api from '../../../api/api';
import { userIsActive } from '../../actions/';
import history from '../../../history';
import TopOfMountain from "../../Admin/Question/View/Background/TopOfMountain";

class ActivePage extends React.Component {

    state = { currentStatus: "" };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        // history.push("/ActivePage");
    }

    componentDidMount() {
        window.scrollToPosition("main-active-page-section");
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        if (!this.ActivePage.ref.current.getValue()) {
            errors.ActivePage = 'Pages.ActivePage.input.error';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ActivePage.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ActivePage.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        } else {

            api.post(`/Register/?isCheck=true`, {
                Code: this.ActivePage.ref.current.getValue()
            }).then(response => {
                if (response.data.code !== "success") {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ActivePage.error_info_toast.Title')}</div> <div className="h6">{t('Pages.ActivePage.error_info_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        currentStatus: "Wrong"
                    });
                } else {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ActivePage.success_toast.Title')}</div> <div className="h6">{t('Pages.ActivePage.success_toast.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.setState({
                        currentStatus: "True"
                    }, () => {
                        setTimeout(() => {
                            this.props.userIsActive();
                            const browserToken = response.data.data.browserToken;
                            localStorage.setItem('browserToken', browserToken);
                            history.push("/Profile");
                        }, 1000);
                    });

                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            });
        }
        e.preventDefault();
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.ActivePage.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel  call-overlay call-in-action oh" id="main-recognized-browser-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>

                    <TopOfMountain currentStatus={this.state.currentStatus} FinishMove={this.FinishMove} />

                    <div className="container padding-top padding-bottom" style={{
                        minHeight: '100vh'
                    }}>
                        <div className="account-wrapper p-3 pt-5">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="section-header mb-0">
                                        <span className="cate">{t('Pages.ActivePage.title')}</span>
                                        <h3 className="title">{t('Pages.ActivePage.subTitle')}</h3>
                                    </div>
                                </div>
                            </div>
                            <form method="POST" className="contact-form px-5 pb-5 pt-4" onSubmit={this.onSubmit} autoComplete="new-password">

                                <div className={`contact-form p-3 w-100 mb-5 inner-box p-3 pt-4  `} style={{
                                    background: 'rgb(170 233 255)',
                                    border: '1px solid rgb(0 191 255)',
                                    borderRadius: '10px'
                                }}  >
                                    <div>
                                        <div className="title h2 mb-0">
                                            <i className="icon fal fa-info"></i>
                                        </div>
                                        <p>
                                            {t('Pages.ActivePage.info')}
                                        </p>
                                    </div>
                                </div>

                                <div className="form-group w-100 mb-2">
                                    <Field
                                        ref={ActivePage => this.ActivePage = ActivePage}
                                        name="ActivePage"
                                        component={this.renderField}
                                        type="text"
                                        required={true}
                                        className="form-control"
                                        placeholder={t('Pages.ActivePage.input.Placeholder')}
                                        title={t('Pages.ActivePage.input.title')}
                                    />
                                </div>
                                <div className="form-group w-100">
                                    <button className="custom-button mt-1 w-100"><span> {t('Pages.ActivePage.Active')}</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.ActivePage) {
        errors.ActivePage = 'Pages.ActivePage.input.error';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const formActivePage = reduxForm({
    form: 'ActivePage',
    validate
});

export default connect(mapStateToProps, { userIsActive })(withTranslation('common')(formActivePage(ActivePage)));
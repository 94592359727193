import React from "react";
import GameObjects from "../../GameObjects";

class ErrorPage extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }
    render() {
        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/error/bg.png"})`,
            backgroundPositionX: 'right',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute user-select-none" style={{
                left: '5%',
                top: '5%'
            }}><GameObjects objectCss="Sun" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute user-select-none MoveEgale d-none d-sm-block" style={{
                transform: 'scaleX(1)'
            }} ><GameObjects objectCss="PaperPlane" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>


            <div className="position-absolute user-select-none" style={{
                right: '1%',
                bottom: '2%'
            }}><GameObjects objectCss="BoyWithBook" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="position-absolute user-select-none" style={{
                left: '1%',
                bottom: '2%'
            }}><GameObjects objectCss="GirlWithBook" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default ErrorPage;
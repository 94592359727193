import api from '../../../../api/api';

export const fetchTeachers = () => async dispatch => {
    dispatch({ type: 'TEACHERS_LOADING' });
    const response = await api.get(`/Teachers/`);
    dispatch({ type: 'TEACHERS_INITIAL', teachers: response.data.data });
}

export const updateTeacher = (id, teacher) => async dispatch => {
    dispatch({ type: 'TEACHERS_UPDATE_INFO', teacher, id });
}

export const removeTeachers = id => async dispatch => {
    dispatch({ type: 'TEACHERS_QUEUE_FOR_REMOVAL', id });
}

export const undoTeachers = id => async dispatch => {
    dispatch({ type: 'TEACHERS_UNDO', id });
}

export const clearTeachers = id => async dispatch => {
    dispatch({ type: 'TEACHERS_CLEAN_NODES', id });
}

export const addTeacher = teacher => async dispatch => {
    dispatch({ type: 'TEACHERS_ADD', teacher });
}
const questionsReducers = (state = [], action) => {
    switch (action.type) {
        case "QUESTION_SHOW_UPDATE_MODAL":
            return {
                id: action.id,
                typeId: action.typeId,
                question: state.question,
                showModal: true,
                answerToRemove: [],
                categoryToRemove: []
            };
        case "QUESTION_UPDATE_VERSION":
            return action.version
        case "QUESTION_UPDATE":
            return action.question;
        case "QUESTION_HIDE_UPDATE_MODAL":
            return {
                id: 0,
                typeId: state.typeId,
                question: null,
                showModal: false,
                answerToRemove: [],
                categoryToRemove: []
            };
        case "QUESTION_INITIAL":
            return {
                id: state.id,
                typeId: state.typeId,
                question: action.question,
                showModal: true,
                answerToRemove: [],
                categoryToRemove: []
            };
        case "QUESTION_ANSWER_QUEUE_FOR_REMOVAL":
            return {
                id: state.id,
                typeId: state.typeId,
                question: state.question,
                showModal: true,
                categoryToRemove: state.categoryToRemove,
                answerToRemove: [...state.answerToRemove, action.id],
            };
        case "QUESTION_ANSWER_CLEAN_NODES":
            return {
                question: state.question,
                answerToRemove: state.answerToRemove.filter(v => v !== action.id),
                id: state.id,
                typeId: state.typeId,
                categoryToRemove: state.categoryToRemove,
                showModal: true,
            };
        case "QUESTION_ANSWER_UNDO":
            return {
                id: state.id,
                typeId: state.typeId,
                question: state.question,
                categoryToRemove: state.categoryToRemove,
                showModal: true,
                answerToRemove: state.answerToRemove.filter(v => v !== action.id),
            };

        case "QUESTION_CATEGORY_QUEUE_FOR_REMOVAL":
            return {
                id: state.id,
                typeId: state.typeId,
                question: state.question,
                showModal: true,
                answerToRemove: state.answerToRemove,
                categoryToRemove: [...state.categoryToRemove, action.id],
            };
        case "QUESTION_CATEGORY_CLEAN_NODES":
            return {
                answerToRemove: state.answerToRemove,
                question: state.question,
                categoryToRemove: state.categoryToRemove.filter(v => v !== action.id),
                id: state.id,
                typeId: state.typeId,
                showModal: true,
            };
        case "QUESTION_CATEGORY_UNDO":
            return {
                id: state.id,
                typeId: state.typeId,
                answerToRemove: state.answerToRemove,
                question: state.question,
                showModal: true,
                categoryToRemove: state.categoryToRemove.filter(v => v !== action.id),
            };
        default:
            return state;
    }
}

export default questionsReducers;
import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import CanvasDraw from "react-canvas-draw";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class Paint extends React.Component {

    state = {
        brushRadius: 7, brushColor: '#0a0302', displayColorPicker: false
    };

    componentDidMount() {

    }

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false })
    };

    handleClickDisplayColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    moreBrushRadius = () => {
        if (this.state.brushRadius < 30) {
            this.setState({ brushRadius: this.state.brushRadius + 1 });
        }
    }

    lessBrushRadius = () => {
        if (this.state.brushRadius > 4) {
            this.setState({ brushRadius: this.state.brushRadius - 1 });
        }
    }

    setBrush = () => {
        this.setState({ brushColor: '#0a0302' });
    }

    setEraser = () => {
        this.canvasDraw.clear();
    }

    setUndo = () => {
        this.canvasDraw.undo();
    }

    handleChange = (color) => {
        this.setState({ brushColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
    };

    render() {
        const { t } = this.props;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <>
                <aside data-aos="fade-left" data-aos-easing="linear" style={{
                    zIndex: '22'
                }} >
                    <ul className="filter position-absolute" id="paint-tools">
                        <li>
                            <p className="title">
                                {t('Pages.Lessons.tools.painter.title')}
                            </p>
                        </li>
                        <li onClick={() => this.setBrush()}>
                            <Link to="#" className="cate" title={t('Pages.Lessons.tools.painter.brush')}>
                                <i className="fal fa-paint-brush"></i>
                            </Link>
                        </li>
                        <li onClick={() => this.moreBrushRadius()}>
                            <Link to="#" className="cate" title={t('Pages.Lessons.tools.painter.plus')}>
                                <i className="fal fa-sort-size-up-alt"></i>
                            </Link>
                        </li>
                        <li onClick={() => this.lessBrushRadius()}>
                            <Link to="#" className="cate" title={t('Pages.Lessons.tools.painter.minus')}>
                                <i className="fal fa-sort-size-down-alt"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="cate"
                                title={t('Pages.Lessons.tools.painter.tint')}
                                onClick={this.handleClickDisplayColorPicker}
                                style={{
                                    color: this.state.brushColor
                                }}>
                                <i className="fal fa-tint"></i>
                            </Link>
                            {
                                this.state.displayColorPicker ?
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                        <SketchPicker color={this.state.brushColor} onChange={this.handleChange} />
                                    </div> : null
                            }
                        </li>
                        <li onClick={() => this.setEraser()}>
                            <Link to="#" className="cate" title={t('Pages.Lessons.tools.painter.eraser')}>
                                <i className="fal fa-eraser"></i>
                            </Link>
                        </li>
                        <li onClick={() => this.setUndo()}>
                            <Link to="#" className="cate" title={t('Pages.Lessons.tools.painter.undo')}>
                                <i className="fal fa-undo"></i>
                            </Link>
                        </li>
                        <li onClick={() => this.props.showPaint()}>
                            <Link to="#" className="cate" title={t('Pages.Lessons.tools.painter.close')}>
                                <i className="fal fa-window-close"></i>
                            </Link>
                        </li>
                    </ul>
                </aside>
                <div onClick={this.handleCloseColorPicker} style={{
                }}>
                    <CanvasDraw
                        id="canvas"
                        ref={canvasDraw => (this.canvasDraw = canvasDraw)}
                        brushColor={this.state.brushColor}
                        lazyRadius={this.state.brushRadius}
                        brushRadius={this.state.brushRadius}
                        canvasWidth='100%'
                        canvasHeight='100%'
                        hideGrid
                        style={{
                            position: 'absolute',
                            background: 'transparent',
                            height: document.documentElement.scrollHeight + "px",
                            zIndex: '21'
                        }}></CanvasDraw>
                </div>

            </>
        );
    }
}

export default withTranslation('common')(Paint);
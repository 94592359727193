import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import Footer from "../../../Footer/FooterBottom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import GameObjects from "../../../Admin/Question/View/GameObjects";

class CompetitionInfo extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-game-info-section");
    }

    openViewWindow = () => {
        window.openCompetitionView();
    }

    render() {
        const { t, user, location } = this.props;

        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const datetime1 = new Date('2023-02-21T08:00:00');
        const startDate = datetime1.toLocaleString('en-UK', options);

        const datetime2 = new Date('2023-02-28T20:00:00');
        const endDate = datetime2.toLocaleString('en-UK', options);

        const now = new Date();
        const nowDateString = now.toLocaleString('en-UK', options);

        let canPaly = false;
        if (((startDate <= nowDateString) && (endDate >= nowDateString)) || (user && user.user && user.user.School && user.user.School.Id === 4)) {
            canPaly = true;
        }

        let isEnd = false;
        if (endDate < nowDateString) {
            isEnd = true;
        }
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Competition.Title')} | {t('alarabeyya')}
                    </title>
                    <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/css/game.css?v=' + process.env.REACT_APP_VERSION} />
                </Helmet>
                <style dangerouslySetInnerHTML={{
                    __html: `.level-button-custom::before {
                            background: rgba(201, 115, 59, 1);
                        }.level-button-custom::after {
                            background: rgba(156, 88, 34, 1);
                        }.level-button-custom span {
                            color: rgba(71, 26, 6, 1);
                        }.level-button-second::before {
                            background: rgba(71, 26, 6, 1);
                        }.level-button-second::after {
                            background: rgba(201, 115, 59, 1);
                        }.level-button-second span {
                            color: rgba(255, 255, 255, 1);
                        }`
                }}></style>
                <section className="position-relative w-100 oh" id="main-game-info-section" style={{
                    minHeight: '100vh',
                    backgroundColor: '#a8f4f8',
                    backgroundImage: 'url("/assets/images/CompetitionInfo/bg.png")',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain'
                }}>

                    <div className="w-100 position-absolute h-100 d-none d-xl-block">
                        <div className="position-absolute user-select-none MoveblackBird">
                            <img src={`${process.env.PUBLIC_URL + "/assets/images/Mountain/bird.png"}`} alt="" />
                        </div>

                        <div className="h-100 position-absolute " style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/CompetitionInfo/right.png"})`,
                            backgroundSize: 'contain',
                            backgroundPositionX: 'left',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'repeat-y',
                            width: '130px',
                            right: 0,
                            top: 0
                        }}></div>
                        <div className="h-100 position-absolute" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/CompetitionInfo/left.png"})`,
                            backgroundSize: 'contain',
                            backgroundPositionX: 'right',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'repeat-y',
                            width: '130px',
                            left: 0,
                            top: 0
                        }}></div>
                        <div className="position-absolute user-select-none" style={{
                            right: '55px',
                            top: '30%'
                        }}><GameObjects objectCss="BoyClimb" /></div>
                        <div className="position-absolute user-select-none" style={{
                            left: '55px',
                            top: '40%'
                        }}><GameObjects objectCss="GirlClimb" /></div>
                    </div>

                    <div className="text-right padding-bottom padding-top" >
                        <div className="container" >
                            <div className="row" >

                                <div className="col-lg-4 bg-white rounded mb-3">
                                    <div className="section-header left-style mb-olpo py-5 px-2">

                                        <span className="cate text-center mb-3">
                                            {t('Pages.Competition.Title')}
                                        </span>

                                        <p className="text-justify mr-0 ">
                                            نتعلّم ونلعب ونستمتع مع مسابقة أبطال اللّغة في موقع هيّا إلى العربيّة.
                                            <br />
                                            <br />
                                            مسابقة أبطال اللّغة، مسابقة قطريّة فريدة من نوعها في مجال اللغة العربيّة. وهي عبارة عن ألعاب تعليميّة ترفيهيّة في اللغة العربيّة من الصف الأوّل حتى الصف السّادس.
                                            تهدف المسابقة لبث روح التنافس بين المدارس وطلابها، وفي الوقت نفسه لتعود بالفائدة الكبيرة على طلابنا ولتكشف مدى معرفتهم بمجالات اللغة من خلال الألعاب والترفيه.
                                        </p>
                                        <div className="w-100 d-flex justify-content-center my-3">
                                            <GameObjects objectCss="CompetitionLogo" />
                                        </div>
                                        <div className="d-none">
                                            <div className={`contact-form p-3 w-100 mb-0 inner-box p-3 pt-4  mt-3`} style={{
                                                background: 'rgb(255 170 170)',
                                                border: '1px solid rgb(255 0 0)',
                                                borderRadius: '10px'
                                            }}  >
                                                <div>
                                                    {
                                                        canPaly ? "" :
                                                            <div className=" h5 text-center mb-2">
                                                                {isEnd ? t('Pages.Competition.Time.end') : t('Pages.Competition.Time.Title')}
                                                            </div>
                                                    }
                                                    <p className=" text-justify">
                                                        {t('Pages.Competition.Time.Body')}
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                canPaly ?
                                                    <>
                                                        {
                                                            (user && user.user && ((user.user.School && user.user.School.Id === 4) || user.user.UserType.Id === 5)) ?
                                                                <Link to="#" onClick={() => this.openViewWindow()} className=" custom-button orange-button w-100 mt-2">
                                                                    <span>
                                                                        <i className="icon fal fa-play"></i>
                                                                        {t('Pages.Games.PlayNow')}
                                                                    </span>
                                                                </Link>
                                                                : ""
                                                        }
                                                        <Link to="/CompetitionStage" className=" custom-button primary-button w-100 mt-2">
                                                            <span>
                                                                <i className="icon fal fa-crown"></i>
                                                                {t('Pages.Competition.Stage')}
                                                            </span>
                                                        </Link>
                                                    </> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">

                                    <div className="faq-wrapper mb--10">

                                        <div className="faq-item open">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    ملاحظات عامة
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'block' }} >
                                                <ul>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        فترة مسابقة أبطال اللغة تبدأ بتاريخ 21/02/2023 الساعة الثامنة صباحا وتنتهي بتاريخ 28/02/2023
                                                        في تمام الساعة الثامنة مساء.
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        مجموع عدد الألعاب لجميع الصفوف
                                                        <span className="font-weight-bold mx-1">
                                                            360 لعبة
                                                        </span>
                                                        .
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        الألعاب مبنيّة حسب مادة منهاج اللغة العربيّة.
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        يمكن للمعلم من خلال حسابه متابعة تقدّم طلاب مدرسته، من خلال لوحة التحكم الخاصة به عند الدخول على
                                                        <Link to="/Competition/Statistics" className="font-weight-bold mx-1">
                                                            إحصائيّات المسابقة
                                                            <i className="far fa-chart-scatter-bubble mx-1"></i>
                                                        </Link>
                                                        .
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    قواعد اللعبة
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <ul>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        تتكوّن مسابقة أبطال اللغة من
                                                        <span className="font-weight-bold mx-1">
                                                            60 لعبة
                                                            لكل صف
                                                        </span>
                                                        ، يتخلّلها 10 ألعاب ترفيهية.
                                                    </li>

                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        بعد كل 5 ألعاب تعليميّة على الطالب أن يجتاز اللعبة الترفيهيّة والتي تَتَحَدّد بـ 5 محاولات أو 10 محاولات.
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        لا يمكن للطالب أن ينتقل من مرحلة إلى أخرى حتى يُنهي المرحلة التي هو فيها، ويُستثنى من ذلك اللعبة
                                                        الترفيهية، فإنّهُ يجتازها بَعْدَ عدد من المحاولات.
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        أثناء مراحل المسابقة، يحصل الطالب بعد كل 30 نجمة
                                                        <i className="text-warning fas fa-stars mx-1"></i>
                                                        على بطاقة تُعبّر عن مستوى تقدّمه في
                                                        المسابقة.
                                                        (
                                                        <span className="font-weight-bold mx-1">
                                                            ملاحظة:
                                                        </span>
                                                        <span className="">
                                                            يُرجى من المعلم/ة توجيه التلميذات إلى تغيير الجنس من ذكر إلى أنثى في بياناتهنّ الشخصية؛ وذلك لوجود بطاقات خاصّة للأولاد وبطاقات خاصّة للبنات
                                                        </span>
                                                        )
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    إرشادات عامة
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <ul>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        يُنصح وبِشدَّةٍ أن تُلعب الألعاب التعليمية للمسابقة على الحاسوب وليس على الأجهزة الأخرى وذلك
                                                        لنجاعة العمل.
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        يُنصح أن تُلعب الألعاب التعليميّة للمسابقة على متصفح الخروم
                                                        <i className="fa-brands fa-chrome mx-1"></i>
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        في بعض أنواع الأجهزة قد يحتاج الطالب إلى تحديث الصفحة بَعْدَ عَدَدٍ من المحاولات في اللعبة
                                                        نفسها فيما لو شَعَر ببطء في اللعبة.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    كيفية الدخول إلى المسابقة
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <ul>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        في اليوم الأول للمسابقة يَدخل الطالب إلى حسابه، يجد مفتاحا مُخصَّصا للمسابقة بعنوان
                                                        (
                                                        <span className="font-weight-bold mx-1">
                                                            {t('Pages.Competition.Title')}
                                                        </span>
                                                        )
                                                        ، فيضغط عليه للدخول إلى المسابقة.

                                                        {
                                                            (user && user.user && ((user.user.School && user.user.School.Id === 4) || user.user.UserType.Id === 5)) ?
                                                                <div className="teacher-single-item d-flex flex-wrap justify-content-evenly align-items-center mb-0 p-2 " >
                                                                    <div className="d-flex align-items-center justify-content-center flex-column" >
                                                                        <div className="d-flex align-items-center flex-column">
                                                                            <div className="teacher-single-thumb  mt-4 ">
                                                                                <LazyLoadImage src={`${user.user.Photo ? user.user.Photo : "/assets/images/teacher/teacher-single.jpg"}`} alt={`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} />
                                                                            </div>

                                                                            <div className="w-100">
                                                                                <Link to="/Student/Game/Profile" className=" custom-button orange-button mt-2 mx-1" style={{
                                                                                    minWidth: '215px'
                                                                                }}>
                                                                                    <span>
                                                                                        {t('Pages.Competition.Title')}
                                                                                    </span>
                                                                                </Link>

                                                                                <Link to="/CompetitionStage" className=" custom-button primary-button mt-2 mx-1" style={{
                                                                                    minWidth: '215px'
                                                                                }}>
                                                                                    <span>
                                                                                        <i className="icon fal fa-crown"></i>
                                                                                        {t('Pages.Competition.Stage')}
                                                                                    </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="w-100 text-center">
                                                                    <LazyLoadImage src="/assets/images/CompetitionInfo/4.PNG" alt={""} style={{
                                                                        borderRadius: '2.25rem'
                                                                    }} />
                                                                </div>
                                                        }
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        <span className="font-weight-bold mx-1">
                                                            ملاحظة:
                                                        </span>
                                                        المعلم لا يُرسل شيئا للطالب، فقط على الطالب الدخول إلى حسابه ومن هناك يدخل إلى
                                                        المسابقة.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    كيف تُحسب النتيجة؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <ul>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        تُحسب نتيجة الطالب حسب ترتيب التالي:
                                                        <ul className="pr-4 mt-1">
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 text-warning fas fa-stars" title={t('Pages.Games.Table.Stars')}></i>
                                                                عدد النجوم
                                                                التي يحصل عليها.
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 text-info fas fa-user-clock" title={t('Pages.Games.Table.Time')}></i>
                                                                مُدّة مكوث
                                                                الطالب في اللعبة.

                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 text-danger fas fa-repeat-1-alt" title={t('Pages.Games.Table.Repate')}></i>
                                                                عدد محاولات
                                                                الطالب في اللعبة.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="text-justify">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        وعلى هذا فإن ترتيب تَقدُّم المتسابقين يكون كالآتي:
                                                        <span className="font-weight-bold mx-1">
                                                            من يحصل على أكبر عدد من النجوم، في وقتٍ
                                                            أقلّ مُحرز، بِأَقلّ عددٍ من المحاولات.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    الجوائز
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <div className="row">
                                                    <div className="text-justify col-md-6">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        للمدارس:
                                                        <ul className="pr-4 mt-1">
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-1"></i>
                                                                المرتبة الأولى
                                                                :
                                                                {
                                                                    location.location.country_code === "ps" || location.location.country_code === "il" ?
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            6000 شاقل
                                                                        </span>
                                                                        :
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            1700 دولار
                                                                        </span>
                                                                }
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-2" ></i>
                                                                المرتبة الثانية
                                                                :
                                                                {
                                                                    location.location.country_code === "ps" || location.location.country_code === "il" ?
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            4000 شاقل
                                                                        </span>
                                                                        :
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            1100 دولار
                                                                        </span>
                                                                }
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-3"></i>
                                                                المرتبة الثالثة
                                                                :
                                                                {
                                                                    location.location.country_code === "ps" || location.location.country_code === "il" ?
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            3000 شاقل
                                                                        </span>
                                                                        :
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            850 دولار
                                                                        </span>
                                                                }
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-4"></i>
                                                                المرتبة الرابعة
                                                                :
                                                                {
                                                                    location.location.country_code === "ps" || location.location.country_code === "il" ?
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            2000 شاقل
                                                                        </span>
                                                                        :
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            570 دولار
                                                                        </span>
                                                                }
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-5"></i>
                                                                المرتبة الخامسة
                                                                :
                                                                {
                                                                    location.location.country_code === "ps" || location.location.country_code === "il" ?
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            1000 شاقل
                                                                        </span>
                                                                        :
                                                                        <span className="font-weight-bold mx-1 cursor-pointer">
                                                                            290 دولار
                                                                        </span>
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="text-justify col-md-6">
                                                        <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                        للطلاب :
                                                        <ul className="pr-4 mt-1">
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-1"></i>
                                                                المرتبة الأولى
                                                                :
                                                                <span className="font-weight-bold mx-1 cursor-pointer">
                                                                    حاسوب نقّال
                                                                </span>
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-2" ></i>
                                                                المرتبة الثانية
                                                                :
                                                                <span className="font-weight-bold mx-1 cursor-pointer">
                                                                    جهاز تابلت
                                                                </span>
                                                            </li>
                                                            <li className="text-justify">
                                                                <i className="pr-3 pl-2 fa-regular fa-square-3"></i>
                                                                المرتبة الثالثة
                                                                :
                                                                <span className="font-weight-bold mx-1 cursor-pointer">
                                                                    ساعة ذكيّة
                                                                </span>
                                                            </li>
                                                            <li className={`contact-form p-3 w-100 mb-0 inner-box p-3`} style={{
                                                                background: 'rgb(170 228 255)',
                                                                border: '1px solid rgb(0 149 255)',
                                                                borderRadius: '10px'
                                                            }}  >
                                                                تُعطى الهديّة لطلّاب أصحاب المراتب الثلاثة الأولى من كل طبقة: من الأوّل حتّى السّادس
                                                            </li>
                                                        </ul>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title ">
                                                    توجيهات
                                                    و
                                                    مفاتيح عند الطالب
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <ul>

                                                    <li>
                                                        <div className="text-center font-weight-bold h4">
                                                            مسار الالعاب
                                                        </div>
                                                        <LazyLoadImage src="/assets/images/CompetitionInfo/3.png" alt={t('Pages.Games.GameLevel')} style={{
                                                            borderRadius: '2.25rem'
                                                        }} />
                                                        <ul className="mt-3">
                                                            <li className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center flex-nowrap flex-column">
                                                                    <div className={`custom-button level-button level-button-custom `}>
                                                                        <span>1</span>
                                                                    </div>
                                                                </div>
                                                                <p className="mr-2 text-justify">
                                                                    اللعبة التي باللون البنَيّ الفاتح  هي
                                                                    لعبة تعليمية يمكن للطالب أن يلعبها
                                                                    .
                                                                </p>
                                                            </li>
                                                            <li className="d-flex align-items-center my-4">
                                                                <div className="d-flex align-items-center flex-nowrap flex-column">
                                                                    <div className={`custom-button level-button-second level-button level-button-custom `}>
                                                                        <span>6</span>
                                                                    </div>
                                                                </div>
                                                                <p className="mr-2 text-justify">
                                                                    اللعبة التي باللون البنَيّ الغامق هي لعبة ترفيهية

                                                                    ،
                                                                    يستطيع الطالب
                                                                    أن
                                                                    يجتاز اللعبة الترفيهيّة بعد عدد من المحاولات
                                                                    والتي تَتَحَدّد بـ 5 محاولات أو 10 محاولات.
                                                                </p>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center flex-nowrap flex-column">
                                                                    <div className={`custom-button level-button level-button-custom `}>
                                                                        <div style={{
                                                                            cursor: "not-allowed",
                                                                            background: 'rgb(112 112 112 / 75%)',
                                                                            zIndex: 9,
                                                                            width: ' 100%',
                                                                            height: ' 100%',
                                                                            position: 'absolute'
                                                                        }}></div>
                                                                        <div className="position-absolute mb-1 ml-1 star-color" style={{
                                                                            zIndex: 9,
                                                                            left: 0,
                                                                            WebkitTextStroke: '1px #fff'
                                                                        }}>
                                                                            <i className="fas fa-lock"></i>
                                                                        </div>
                                                                        <span>10</span>
                                                                    </div>
                                                                </div>
                                                                <p className="mr-2 text-justify">
                                                                    اللعبة التي عليها قفل فلا يمكن للطالب أن يلعبها حتى يجتازَ ما قبلها
                                                                    .
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li className="mt-5">
                                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                                            <p className="text-justify mb-1">
                                                                <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                                تظهر للطالب لوحة تُبيّن له نتيجة ألعابه بالتفصيل من حيث الوقت والمحاولات والنجوم لكلّ لعبة، ليعرف أيّ الألعاب التي يمكنه أن يلعبها مرة أخرى، حتى يحصل على نتيجة أعلى من المرة السابقة.
                                                                ويكون الدخول إلى هذه اللوحة من خلال هذا المفتاح:
                                                            </p>
                                                            <div className={`mx-1 mb-3 d-flex align-items-center justify-content-center text-center h3 custom-menu-button`} style={{
                                                                zIndex: '9'
                                                            }}
                                                                title={t('Pages.Games.MyScore')}>
                                                                <i className="fas fa-star-shooting"></i>
                                                            </div>
                                                            <div className="text-center font-weight-bold h4">
                                                                نتيجة الطالب التفصيلية لكلّ لعبة له
                                                            </div>
                                                            <LazyLoadImage src="/assets/images/CompetitionInfo/1.png" alt={t('Pages.Games.MyScore')} style={{
                                                                borderRadius: '2.25rem'
                                                            }} />
                                                            <p className="text-justify mt-2">
                                                                يمكن للطالب الرجوع إلى الألعاب التي لَعِبها مرة أخرى ليحصد نجوما أكثر في حال حصل على نجمتين عليها أو أقلّ سابقا، وفي حال حصل على 3 نجوم في بعض الألعاب، فيمكنه أيضا أن يرجع إليها ليلعبها بوقت أقلّ حتى يحصل على علامة أعلى.
                                                            </p>
                                                        </div>
                                                    </li>

                                                    <li className="mt-5">
                                                        <p className="text-justify mb-1">
                                                            <i className="far fa-dot-circle pr-3 pl-2 dark-blue-text"></i>
                                                            ليتمكن الطالب من رؤية مراكز طلاب مدرسته، عليه الضغط على المفتاح التالي:
                                                        </p>
                                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                                            <div className={`mx-1 mb-3 d-flex align-items-center justify-content-center text-center h3 custom-menu-button`} style={{
                                                                zIndex: '9'
                                                            }}
                                                                title={t('Pages.Games.Scoreboard')}>
                                                                <i className="fas fa-trophy-alt"></i>
                                                            </div>
                                                        </div>
                                                        <div className="text-center font-weight-bold h4">
                                                            المراكز والنتائج
                                                        </div>
                                                        <LazyLoadImage src="/assets/images/CompetitionInfo/2.png" alt={t('Pages.Games.Scoreboard')} style={{
                                                            borderRadius: '2.25rem'
                                                        }} />
                                                    </li>

                                                    <li className="d-flex justify-content-evenly align-items-center mt-5">
                                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                                            <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button`} style={{
                                                                zIndex: '9'
                                                            }}
                                                                title={t('Pages.Games.sound')}>
                                                                <i className="fas fa-music"></i>
                                                            </div>
                                                            <div className="h5">
                                                                {t('Pages.Games.sound')}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                                            <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button`} style={{
                                                                zIndex: '9'
                                                            }}
                                                                title={t('Pages.Lessons.tools.back')}>
                                                                <i className="fas fa-power-off"></i>
                                                            </div>
                                                            <div className="h5">
                                                                {t('Pages.Lessons.tools.back')}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <p className="text-center h4 mt-4">
                                                    نرجو لطلابنا الفائدة والمتعة معا
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(CompetitionInfo));

import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import { Bar } from 'react-chartjs-2';

import api from '../../../api/api';
import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import { fetchClassRooms } from '../ClassRooms/actions';
import {
    fetchTeachers as fetch,
} from '../Teachers/actions/';

import {
    fetchStudentInMultiClassRooms,
} from '../StudentInClassRooms/actions/';

class SchoolStatistics extends React.Component {

    state = {
        ClassRoomId: [],
        StudentId: [],
        students: [],
        teachers: [],
        startDate: moment().add(-15, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        date: moment().add(-15, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
        isSearch: false,
        isLoaded: true,
        TeachersInfo: {},
        StudentsInfo: {},
        LessonInfo: {},
        FileInfo: {},
        MissionInfo: {},
        isFirst: true
    };

    constructor(props) {
        super(props);
        window.scrollToTop();
        this.props.fetchClassRooms();
        this.state.markRang = [{
            value: 1,
            label: '100',
            total: 0
        },
        {
            value: 2,
            label: '90-99',
            total: 0
        },
        {
            value: 3,
            label: '80-89',
            total: 0
        },
        {
            value: 4,
            label: '70-79',
            total: 0
        },
        {
            value: 5,
            label: '60-69',
            total: 0
        },
        {
            value: 6,
            label: '50-59',
            total: 0
        },
        {
            value: 7,
            label: '50 >',
            total: 0
        }];

        this.state.options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: this.props.t('Pages.Statistics.table.title'),
                },
            },
        };
        const labels = this.state.markRang.map(c => c.label);
        this.state.data = {
            labels: labels,
            datasets: [
                {
                    label: `${this.props.t('Pages.Statistics.table.total')}`,
                    data: labels.map(() => 0),
                    backgroundColor: 'rgba(177, 255, 179, 0.5)',
                },
                {
                    label: `${this.props.t('Pages.Statistics.table.female')}`,
                    data: labels.map(() => 0),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: `${this.props.t('Pages.Statistics.table.male')}`,
                    data: labels.map(() => 0),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ],
        };
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY'),
            date: picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY')
        });
    }

    Sync = () => {
        const ClassRoomId = this.state.ClassRoomId.map(c => c.value);
        this.setState({
            isSearch: true,
            isLoaded: false,
            TeachersInfo: {},
            StudentsInfo: {},
            LessonInfo: {},
            FileInfo: {},
            MissionInfo: {}
        });

        let TeacherId = [];
        if (this.TeacherId && this.TeacherId.state && this.TeacherId.state.value) {
            TeacherId = this.TeacherId.state.value.map(c => c.value);
        }
        let StudentId = [];
        if (this.StudentId && this.StudentId.state && this.StudentId.state.value) {
            StudentId = this.StudentId.state.value.map(c => c.value);
        }
        this.getStudentAndTeacherInfo(this.state.startDate, this.state.endDate, ClassRoomId, TeacherId, StudentId);
    }

    getStudentAndTeacherInfo = (startDate, endDate, ClassRoomId, TeacherId, StudentId) => {
        const { t } = this.props;

        var _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/SchoolStatistics/`,
            {
                ClassRoomId,
                startDate,
                endDate,
                TeacherId,
                StudentId
            }
        ).then(response => {

            if (response.data.Mission && response.data.Mission.students) {
                const missions = response.data.Mission.students;
                const labels = this.state.markRang.map(c => c.label);
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label: `${this.props.t('Pages.Statistics.table.total')}`,
                            data: [
                                missions.total.markIn100,
                                missions.total.markIn90,
                                missions.total.markIn80,
                                missions.total.markIn70,
                                missions.total.markIn60,
                                missions.total.markIn50,
                                missions.total.lessThan50
                            ],
                            backgroundColor: 'rgba(177, 255, 179, 0.5)',
                        },
                        {
                            label: `${this.props.t('Pages.Statistics.table.female')}`,
                            data: [
                                missions.female.markIn100,
                                missions.female.markIn90,
                                missions.female.markIn80,
                                missions.female.markIn70,
                                missions.female.markIn60,
                                missions.female.markIn50,
                                missions.female.lessThan50
                            ],
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: `${this.props.t('Pages.Statistics.table.male')}`,
                            data: [
                                missions.male.markIn100,
                                missions.male.markIn90,
                                missions.male.markIn80,
                                missions.male.markIn70,
                                missions.male.markIn60,
                                missions.male.markIn50,
                                missions.male.lessThan50
                            ],
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        }
                    ],
                };
                this.setState({
                    data
                });
            }

            this.setState({
                TeachersInfo: response.data.UserInfo ? response.data.UserInfo.teachers : {},
                StudentsInfo: response.data.UserInfo ? response.data.UserInfo.studnets : {},
                MissionInfo: response.data.Mission ? response.data.Mission : {},
                LessonInfo: response.data.Lesson ? response.data.Lesson : {},
                FileInfo: response.data.File ? response.data.File : {},
                isLoaded: true
            });

            toast.dismiss(_toastId);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.label.includes(input);
        }
        return true;
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isFirst && props.user && props.user.user && props.user.user.UserType.Id === 3) {
            state.isFirst = false;
            props.fetch();
        }

        if (props.classRooms && props.classRooms.classRooms) {
            state.classRooms = props.classRooms.classRooms.map(classRoom => {
                return {
                    value: classRoom.Id,
                    label: classRoom.Name
                };
            });
        }

        if (props.teachers && props.teachers.teachers) {
            state.teachers = props.teachers.teachers.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }

        if (props.studentInClassRooms && props.studentInClassRooms.studentInClassRooms) {
            state.students = props.studentInClassRooms.studentInClassRooms.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }

        return state;
    }

    setSelectedOption = ClassRoom => {
        if (ClassRoom) {
            this.setState({
                ClassRoomId: ClassRoom
            });
        } else {
            this.setState({
                ClassRoomId: []
            });
        }
        let ClassRoomId = [];
        if (ClassRoom) {
            ClassRoomId = ClassRoom.map(c => c.value);
        }
        this.props.fetchStudentInMultiClassRooms(ClassRoomId);
    }

    setSelectedOptionStudent = Student => {
        if (Student) {
            this.setState({
                StudentId: Student
            });
        } else {
            this.setState({
                StudentId: []
            });
        }
    }

    checkStudent = () => {
        let ClassRoomId = [];
        if (this.ClassRoomId && this.ClassRoomId.state && this.ClassRoomId.state.value) {
            ClassRoomId = this.ClassRoomId.state.value.map(c => c.value);
        }
        if (ClassRoomId.length === 0) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bell-exclamation"></i> {t('Pages.Statistics.no_classroom.Title')}</div> <div className="h6">{t('Pages.Statistics.no_classroom.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        const { t, user, teachers, studentInClassRooms } = this.props;

        let isLoadedTeacher = false;
        if (teachers) {
            isLoadedTeacher = teachers.isLoaded;
        }

        let isLoadedStudentInClassRooms = false;
        if (studentInClassRooms) {
            isLoadedStudentInClassRooms = studentInClassRooms.isLoaded;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("range.ThisYear")] = [moment().startOf('year'), moment().endOf('year')];

        if (moment().startOf('year').month("September") < moment()) {
            ranges[t("range.FirstSemester")] = [moment().startOf('year').month("September"), moment().endOf('year')];
            ranges[t("range.ThisStudyYear")] = [moment().startOf('year').month("September").startOf('month'), moment().toDate()];
        } else {
            ranges[t("range.FirstSemester")] = [moment().subtract(1, 'year').month("September"), moment().subtract(1, 'year').endOf('year')];
            if (moment().startOf('year') <= moment()) {
                ranges[t("range.SecondSemester")] = [moment().startOf('year'), moment().startOf('year').month("April").day(10)];
            }
            if (moment().startOf('year').month("April").day(15) <= moment()) {
                ranges[t("range.ThirdSemester")] = [moment().startOf('year').month("April").day(15), moment().startOf('year').month("June").endOf('month')];
            }
            ranges[t("range.ThisStudyYear")] = [moment().subtract(1, 'year').month("September").startOf('month'), moment().toDate()];
        }

        let classWidth = "";
        if (user && user.user && user.user.UserType.Id === 4) {
            classWidth = "w-100";
        }

        let TeacherId = [];
        if (this.TeacherId && this.TeacherId.state && this.TeacherId.state.value) {
            TeacherId = this.TeacherId.state.value.map(c => c.value);
        }

        let StudentId = [];
        if (this.StudentId && this.StudentId.state && this.StudentId.state.value) {
            StudentId = this.StudentId.state.value.map(c => c.value);
        }

        return (
            <>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.Statistics.title')}| {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.Statistics.title')}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="search-form contact-form align-items-center">

                                            <div className={`form-group ${classWidth}`} style={{
                                                zIndex: 9999999
                                            }}>
                                                <DateRangePicker
                                                    ref={Date => this.Date = Date}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        ranges: ranges,
                                                        autoUpdateInput: false,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        timePicker: false,
                                                        format: 'DD/MM/YYYY',
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        minYear: 2023,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            customRangeLabel: t('range.customRange'),
                                                            applyLabel: t('Apply'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.date}
                                                        ref={Dates => this.Dates = Dates}
                                                        name="Date"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        placeholder={t('SearchDate.Placeholder')}
                                                        title={t('SearchDate.title')}
                                                    />
                                                </DateRangePicker>
                                            </div>

                                            {
                                                user && user.user && user.user.UserType.Id === 3 ?
                                                    <div className="form-group" style={{
                                                        zIndex: 9999999
                                                    }}>
                                                        <div>
                                                            <Select
                                                                isClearable={true}
                                                                name="TeacherId"
                                                                placeholder={t('Pages.Statistics.TeacherSearch')}
                                                                isRtl={true}
                                                                isMulti={true}
                                                                isLoading={!isLoadedTeacher}
                                                                isSearchable={true}
                                                                ref={TeacherId => this.TeacherId = TeacherId}
                                                                options={this.state.teachers}
                                                                filterOption={this.filterOptions}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: 0,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#92278f',
                                                                        primary: '#92278f',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    : ""
                                            }

                                            <div className="form-group " style={{
                                                zIndex: 9999998
                                            }}>
                                                <Select
                                                    name="ClassRoomId"
                                                    title={t('Pages.Missions.ClassRoom.title')}
                                                    placeholder={t('Pages.Missions.ClassRoom.placeholder')}
                                                    onChange={e => this.setSelectedOption(e)}
                                                    isRtl={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                    options={this.state.classRooms}
                                                    filterOption={this.filterOptions}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#92278f',
                                                            primary: '#92278f',
                                                        },
                                                    })}
                                                />
                                            </div>

                                            <div className="form-group " onClick={() => this.checkStudent()} style={{
                                                zIndex: 9999998
                                            }}>
                                                <Select
                                                    name="StudentId"
                                                    title={t('Pages.Statistics.StudentSearch')}
                                                    placeholder={t('Pages.Statistics.StudentSearch')}
                                                    onChange={e => this.setSelectedOptionStudent(e)}

                                                    isRtl={true}
                                                    isMulti={true}
                                                    isLoading={!isLoadedStudentInClassRooms}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    ref={StudentId => this.StudentId = StudentId}
                                                    options={this.state.students}
                                                    filterOption={this.filterOptions}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#92278f',
                                                            primary: '#92278f',
                                                        },
                                                    })}
                                                />
                                            </div>

                                        </div>

                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Sync()} title={t("Pages.SchoolsControl.Sync")} >
                                                <span>
                                                    {
                                                        this.state.isLoaded ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>

                                            <ReactToPrint content={() => this.componentRef}
                                                trigger={() => {
                                                    return <div className="custom-button orange-button mx-1">
                                                        <span>
                                                            <i className="icon fal fa-print"></i> {t('Print')}
                                                        </span>
                                                    </div>;
                                                }} />
                                        </div>
                                        <style dangerouslySetInnerHTML={{
                                            __html: `.css-e56m7-control {   height: auto !important;  }`
                                        }}></style>
                                    </div>
                                </div>
                                {
                                    !this.state.isSearch ? "" :
                                        <div ref={el => (this.componentRef = el)}>
                                            <div className={`contact-form p-3 w-100 mb-4 inner-box p-3 pt-4 text-center `} style={{
                                                background: 'rgb(170 233 255)',
                                                border: '1px solid rgb(0 191 255)',
                                                borderRadius: '10px'
                                            }}  >
                                                <div className="w-100">
                                                    <div className="title h4 mb-1 d-flex align-items-center justify-content-center">
                                                        <i className="icon fal fa-bell-exclamation  display-4"></i>
                                                        <div className="h1 mx-3">
                                                            {t('Pages.Statistics.note.title')}
                                                        </div>
                                                    </div>
                                                    <p>
                                                        {t('Pages.Statistics.note.body')} <span className="font-weight-bold">{this.state.startDate}</span>
                                                    </p>
                                                    <p>
                                                        {t('Pages.Statistics.note.mission')} <br /><span className="font-weight-bold">{this.state.startDate}</span> -
                                                        <span className="font-weight-bold">{this.state.endDate}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row clearfix justify-content-center">

                                                {
                                                    TeacherId.length > 0 ? "" :
                                                        <div className="featured-block col-12" title={t('Pages.Statistics.students')}>
                                                            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                                                <span className="border-one"></span>
                                                                <span className="border-two"></span>

                                                                <div className="h3 d-flex justify-content-center align-items-center">
                                                                    <div className="icon-box d-flex justify-content-center flex-column m-3" style={{
                                                                        width: "50px",
                                                                        height: "50px"
                                                                    }}>
                                                                        <span className="icon"> <i className="fal fa-user-graduate h4 text-white"></i></span>
                                                                    </div> {t('Pages.Statistics.students')}
                                                                </div>

                                                                {
                                                                    this.state.StudentsInfo && this.state.StudentsInfo.total ?
                                                                        <div className="row clearfix justify-content-between mt-0 mx-0 w-100">

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-info">
                                                                                                {t('Pages.Statistics.Total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.male')}
                                                                                            </th>
                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.female')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.StudentsInfo.total.male}</td>
                                                                                            <td>{this.state.StudentsInfo.total.female}</td>
                                                                                            <td>{this.state.StudentsInfo.total.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-success">
                                                                                                {t('Pages.Statistics.Active')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.male')}
                                                                                            </th>
                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.female')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.StudentsInfo.active.male}</td>
                                                                                            <td>{this.state.StudentsInfo.active.female}</td>
                                                                                            <td>{this.state.StudentsInfo.active.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-warning">
                                                                                                {t('Pages.Statistics.Lessons')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th title={this.props.t('Pages.Statistics.table.uniqe')}>
                                                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                                                                {this.props.t('Pages.Statistics.table.uniqe')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.StudentsInfo.Lesson.uniqe}</td>
                                                                                            <td>{this.state.StudentsInfo.Lesson.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-danger">
                                                                                                {t('Pages.Statistics.Files')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th title={this.props.t('Pages.Statistics.table.uniqe')}>
                                                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                                                                {this.props.t('Pages.Statistics.table.uniqe')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.StudentsInfo.Files.uniqe}</td>
                                                                                            <td>{this.state.StudentsInfo.Files.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        <div className="product-list-item p-0 m-0" >
                                                                            <div className="product-content w-100 text-center p-0">
                                                                                <div className="display-1 text-danger">
                                                                                    <i className="fad fa-sync-alt fa-spin"></i>
                                                                                </div>
                                                                                <h4 className="display-3 subtitle">
                                                                                    {t("Pages.Missions.Loading")}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                                {
                                                    StudentId.length > 0 ? "" :
                                                        <div className="featured-block col-12" title={t('Pages.Statistics.teachers')}>
                                                            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                                                <span className="border-one"></span>
                                                                <span className="border-two"></span>

                                                                <div className="h3 d-flex justify-content-center align-items-center">
                                                                    <div className="icon-box d-flex justify-content-center flex-column m-3" style={{
                                                                        width: "50px",
                                                                        height: "50px"
                                                                    }}>
                                                                        <span className="icon"> <i className="fal fa-chalkboard-teacher h4 text-white"></i></span>
                                                                    </div> {t('Pages.Statistics.teachers')}
                                                                </div>

                                                                {
                                                                    this.state.TeachersInfo && this.state.TeachersInfo.total ?
                                                                        <div className="row clearfix justify-content-between mt-0 mx-0 w-100">

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-info">
                                                                                                {t('Pages.Statistics.Total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.teacher.male')}
                                                                                            </th>
                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.teacher.female')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.TeachersInfo.total.male}</td>
                                                                                            <td>{this.state.TeachersInfo.total.female}</td>
                                                                                            <td>{this.state.TeachersInfo.total.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-success">
                                                                                                {t('Pages.Statistics.Active')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.teacher.male')}
                                                                                            </th>
                                                                                            <th>
                                                                                                {this.props.t('Pages.Statistics.table.teacher.female')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.TeachersInfo.active.male}</td>
                                                                                            <td>{this.state.TeachersInfo.active.female}</td>
                                                                                            <td>{this.state.TeachersInfo.active.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-warning">
                                                                                                {t('Pages.Statistics.Lessons')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th title={this.props.t('Pages.Statistics.table.uniqe')}>
                                                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                                                                {this.props.t('Pages.Statistics.table.uniqe')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.TeachersInfo.Lesson.uniqe}</td>
                                                                                            <td>{this.state.TeachersInfo.Lesson.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <table className="table table-striped table-hover table-bordered mt-0">
                                                                                    <thead>
                                                                                        <tr className="text-center ">
                                                                                            <th colSpan={3} className="text-danger">
                                                                                                {t('Pages.Statistics.Files')}
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr className="text-center">

                                                                                            <th title={this.props.t('Pages.Statistics.table.uniqe')}>
                                                                                                <i className="icon text-danger fad fa-fingerprint"></i>
                                                                                                {this.props.t('Pages.Statistics.table.uniqe')}
                                                                                            </th>
                                                                                            <th title={this.props.t('Pages.Statistics.table.total')}>
                                                                                                <i className="icon fad fa-sigma text-success"></i>
                                                                                                {this.props.t('Pages.Statistics.table.total')}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{this.state.TeachersInfo.Files.uniqe}</td>
                                                                                            <td>{this.state.TeachersInfo.Files.total}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        <div className="product-list-item p-0 m-0" >
                                                                            <div className="product-content w-100 text-center p-0">
                                                                                <div className="display-1 text-danger">
                                                                                    <i className="fad fa-sync-alt fa-spin"></i>
                                                                                </div>
                                                                                <h4 className="display-3 subtitle">
                                                                                    {t("Pages.Missions.Loading")}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                                <div className="featured-block col-md-12" title={t('Pages.Statistics.Missions')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="h3 d-flex justify-content-center align-items-center">
                                                            <div className="icon-box d-flex justify-content-center flex-column m-3" style={{
                                                                width: "50px",
                                                                height: "50px"
                                                            }}>
                                                                <span className="icon"> <i className="fal fa-bullseye-arrow h4 text-white"></i></span>
                                                            </div> {t('Pages.Statistics.Missions')} :<span className="text-info mx-1">
                                                                {this.state.MissionInfo.total}
                                                            </span>
                                                        </div>
                                                        {
                                                            this.state.MissionInfo && (this.state.MissionInfo.total || this.state.MissionInfo.total === 0) ?
                                                                <div className="row clearfix justify-content-between mx-0 w-100" >

                                                                    <div className="col-md-6">
                                                                        <table className="table table-striped table-hover table-bordered mt-0">
                                                                            <thead>
                                                                                <tr className="text-center ">
                                                                                    <th colSpan={3} className="text-warning">
                                                                                        {t('Pages.Statistics.StudentCount')}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr className="text-center">

                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.male')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.female')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.total')}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.state.MissionInfo.students.male.send}</td>
                                                                                    <td>{this.state.MissionInfo.students.female.send}</td>
                                                                                    <td>{this.state.MissionInfo.students.total.send}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <table className="table table-striped table-hover table-bordered mt-0">
                                                                            <thead>
                                                                                <tr className="text-center ">
                                                                                    <th colSpan={3} className="text-info">
                                                                                        {t('Pages.Statistics.SolveCount')}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr className="text-center">

                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.male')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.female')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.total')}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.state.MissionInfo.students.male.submit}</td>
                                                                                    <td>{this.state.MissionInfo.students.female.submit}</td>
                                                                                    <td>{this.state.MissionInfo.students.total.submit}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <table className="table table-striped table-hover table-bordered mt-0">
                                                                            <thead>
                                                                                <tr className="text-center ">
                                                                                    <th colSpan={3} className="text-success">
                                                                                        {t('Pages.Statistics.status.success')}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr className="text-center">

                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.male')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.female')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.total')}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.state.MissionInfo.students.male.total}</td>
                                                                                    <td>{this.state.MissionInfo.students.female.total}</td>
                                                                                    <td>{this.state.MissionInfo.students.total.total}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <table className="table table-striped table-hover table-bordered mt-0">
                                                                            <thead>
                                                                                <tr className="text-center ">
                                                                                    <th colSpan={3} className="text-danger">
                                                                                        {t('Pages.Statistics.status.failed')}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr className="text-center">

                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.male')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.female')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.total')}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.state.MissionInfo.students.male.lessThan50}</td>
                                                                                    <td>{this.state.MissionInfo.students.female.lessThan50}</td>
                                                                                    <td>{this.state.MissionInfo.students.total.lessThan50}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <Bar options={this.state.options} data={this.state.data} />
                                                                    </div>

                                                                    <div className="col-12 mt-3">
                                                                        <table className="table table-striped table-hover table-bordered mt-0">
                                                                            <thead>
                                                                                <tr className="text-center ">
                                                                                    <th colSpan={3} className="text-primary">
                                                                                        <i className="fal fa-badge-percent mb-0 ml-1"></i>
                                                                                        {t('Pages.Statistics.AverageRate')}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr className="text-center">

                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.male')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.female')}
                                                                                    </th>
                                                                                    <th>
                                                                                        {this.props.t('Pages.Statistics.table.total')}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        {this.state.MissionInfo.students.male.rate}
                                                                                        <i className="fal fa-percent mb-0 mr-1"></i>
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.state.MissionInfo.students.female.rate}
                                                                                        <i className="fal fa-percent mb-0 mr-1"></i>
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.state.MissionInfo.students.total.rate}
                                                                                        <i className="fal fa-percent mb-0 mr-1"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="product-list-item p-0 m-0" >
                                                                    <div className="product-content w-100 text-center p-0">
                                                                        <div className="display-1 text-danger">
                                                                            <i className="fad fa-sync-alt fa-spin"></i>
                                                                        </div>
                                                                        <h4 className="display-3 subtitle">
                                                                            {t("Pages.Missions.Loading")}
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="featured-block col-md-6" title={t('Pages.Statistics.SharedLessons')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>

                                                        <div className="h3 d-flex justify-content-center align-items-center">
                                                            <div className="icon-box d-flex justify-content-center flex-column m-3" style={{
                                                                width: "50px",
                                                                height: "50px"
                                                            }}>
                                                                <span className="icon"> <i className="fal fa-mind-share h4 text-white"></i></span>
                                                            </div> {t('Pages.Statistics.SharedLessons')}
                                                        </div>

                                                        {
                                                            this.state.LessonInfo && (this.state.LessonInfo.total || this.state.LessonInfo.total === 0) ?
                                                                <div className="row clearfix justify-content-between mt-0 mx-0 w-100">
                                                                    <table className="table table-striped table-hover table-bordered mt-0">
                                                                        <thead>
                                                                            <tr className="text-center ">
                                                                                <th colSpan={3} className="text-info">
                                                                                    {t('Pages.Statistics.StudentCount')}
                                                                                </th>
                                                                            </tr>
                                                                            <tr className="text-center">

                                                                                <th>
                                                                                    {this.props.t('Pages.Statistics.table.male')}
                                                                                </th>
                                                                                <th>
                                                                                    {this.props.t('Pages.Statistics.table.female')}
                                                                                </th>
                                                                                <th>
                                                                                    {this.props.t('Pages.Statistics.table.total')}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {this.state.LessonInfo.male}
                                                                                </td>
                                                                                <td>
                                                                                    {this.state.LessonInfo.female}
                                                                                </td>
                                                                                <td>
                                                                                    {this.state.LessonInfo.total}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                <div className="product-list-item p-0 m-0" >
                                                                    <div className="product-content w-100 text-center p-0">
                                                                        <div className="display-1 text-danger">
                                                                            <i className="fad fa-sync-alt fa-spin"></i>
                                                                        </div>
                                                                        <h4 className="display-3 subtitle">
                                                                            {t("Pages.Missions.Loading")}
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="featured-block col-md-6" title={t('Pages.Statistics.SharedFiles')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>

                                                        <div className="h3 d-flex justify-content-center align-items-center">
                                                            <div className="icon-box d-flex justify-content-center flex-column m-3" style={{
                                                                width: "50px",
                                                                height: "50px"
                                                            }}>
                                                                <span className="icon"> <i className="fal fa-head-side-brain h4 text-white"></i></span>
                                                            </div> {t('Pages.Statistics.SharedFiles')}
                                                        </div>
                                                        {
                                                            this.state.FileInfo && (this.state.FileInfo.total || this.state.FileInfo.total === 0) ?
                                                                <div className="row clearfix justify-content-between mt-0 mx-0 w-100">
                                                                    <table className="table table-striped table-hover table-bordered mt-0">
                                                                        <thead>
                                                                            <tr className="text-center ">
                                                                                <th colSpan={3} className="text-info">
                                                                                    {t('Pages.Statistics.StudentCount')}
                                                                                </th>
                                                                            </tr>
                                                                            <tr className="text-center">

                                                                                <th>
                                                                                    {this.props.t('Pages.Statistics.table.male')}
                                                                                </th>
                                                                                <th>
                                                                                    {this.props.t('Pages.Statistics.table.female')}
                                                                                </th>
                                                                                <th>
                                                                                    {this.props.t('Pages.Statistics.table.total')}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {this.state.FileInfo.male}
                                                                                </td>
                                                                                <td>
                                                                                    {this.state.FileInfo.female}
                                                                                </td>
                                                                                <td>
                                                                                    {this.state.FileInfo.total}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                <div className="product-list-item p-0 m-0" >
                                                                    <div className="product-content w-100 text-center p-0">
                                                                        <div className="display-1 text-danger">
                                                                            <i className="fad fa-sync-alt fa-spin"></i>
                                                                        </div>
                                                                        <h4 className="display-3 subtitle">
                                                                            {t("Pages.Missions.Loading")}
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>
                        </div>

                    </div>
                </section >
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        teachers: state.teachers,
        classRooms: state.classRooms,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetch, fetchStudentInMultiClassRooms, fetchClassRooms })(withTranslation('common')(SchoolStatistics));
import React from "react";
import { withTranslation } from 'react-i18next'
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from "react-toastify";
import Select from 'react-select';
import ReactPixel from 'react-facebook-pixel';

import 'bootstrap-daterangepicker/daterangepicker.css';
import configData from "../../../../Config/countries.json";

class SchoolUserForm extends React.Component {

    state = { captchaErorr: null, IsSchool: false, errorList: [], countries: [], IsTeacher: false, showTermsAndConditions: false, CountryIndex: -1 };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });

        ReactPixel.init('484815450795869');
        ReactPixel.track('PageView', "School Register Teacher Form");
    }

    componentDidMount() {
        this.props.initialize({ Address: this.state.Address });
        if (this.props.data) {
            this.props.initialize({
                FirstName: this.props.data.FirstName,
                LastName: this.props.data.LastName,
                Country: this.props.data.Country ? this.props.data.Country : this.props.data.country_code,
                Address: this.props.data.Address ? this.props.data.Address : this.props.Address
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.country_code) {
            var index = state.countries.findIndex(p => p.value === props.country_code);
            state.CountryIndex = index;
            state.Address = props.Address;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Register.error_AllInformationIsRequired_toast.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onSubmit = () => {
        this.props.onSubmit({
            ...this.props.formValues,
            Country: this.Country.state.value.value
        });
    }
    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;
        return (
            <>
                <div className="contact-form-area">

                    <h4 className="title d-flex justify-content-center align-items-center mb-2">
                        <i className="icon fa-solid fa-chalkboard-user px-1 text-primary"></i>
                        معلومات المعلم المسؤل عن الحساب
                    </h4>
                    <div className="mx-2 mb-4 text-center" style={{
                        fontSize: '18px'
                    }}>
                        (نحتاج هذه البيانات لكي نتمكن من التواصل معك من أجل عقد الاستشارة المجانية )
                    </div>

                    <form className="contact-form" name="SchoolUserForm" autoComplete="new-password" method="POST" onSubmit={
                        handleSubmit(values => {
                            return new Promise(resolve => resolve())
                                .then(() => {
                                    const errors = validate(values, this.props);
                                    return Object.keys(errors).length !== 0;
                                })
                                .then(errors => {
                                    if (errors) {
                                        this.checkSubmit();
                                    } else {
                                        this.onSubmit();
                                    }
                                })
                        })
                    }>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.FirstName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={FirstName => this.FirstName = FirstName}
                                    name="FirstName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.FirstName.Placeholder')}
                                    title={t('Pages.Register.FirstName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.LastName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={LastName => this.LastName = LastName}
                                    name="LastName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.LastName.Placeholder')}
                                    title={t('Pages.Register.LastName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.Country.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"></i></label>
                            <div>
                                <Select
                                    defaultValue={this.state.CountryIndex >= 0 ? {
                                        label: this.state.countries[this.state.CountryIndex].label,
                                        value: this.state.countries[this.state.CountryIndex].value
                                    } : ""}
                                    name="Country"
                                    placeholder={t('Pages.Register.Country.Placeholder')}
                                    isRtl={true}
                                    isSearchable={true}
                                    ref={Country => this.Country = Country}
                                    options={this.state.countries}
                                    filterOption={this.filterOptions}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#92278f',
                                            primary: '#92278f',
                                        },
                                    })}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.Address.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"></i></label>
                            <div>
                                <Field
                                    ref={Address => this.Address = Address}
                                    name="Address"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.Address.Placeholder')}
                                    title={t('Pages.Register.Address.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group ">
                            <label className='text-dark font-size-h5'><i className="fa-regular fa-at px-1"></i> اَلبرِيد الإلكْترونيِّ لِلْمعَلِّم المسْؤول<i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={Email => this.Email = Email}
                                    name="Email"
                                    component={this.renderField}
                                    onBlur={e => this.checkIfExist(e.target.value)}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.Email.Placeholder')}
                                    title={t('Pages.Register.Email.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.Phone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"></i></label>
                            <div>
                                <Field
                                    ref={Phone => this.Phone = Phone}
                                    name="Phone"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.Phone.Placeholder')}
                                    title={t('Pages.Register.Phone.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>


                        <div className="form-group w-100 text-center d-flex justify-content-around align-items-center">
                            <button type="button" className={`custom-button danger-button `} onClick={() => this.props.goBack()} >
                                <span className="d-flex justify-content-center align-items-center">
                                    <i className="icon fad fa-arrow-right px-1"></i>
                                    {t('Pages.Register.Previous')}
                                </span>
                            </button>

                            <button className={`custom-button ${this.props.data ? "success-button" : (pristine || submitting) ? "secondary-button" : "success-button"}  `} disabled={(pristine || submitting) && !this.props.data} >
                                <span className="d-flex justify-content-center align-items-center">
                                    {t('Pages.Register.Next')}
                                    <i className="icon fad fa-arrow-left px-1"></i>
                                </span>
                            </button>
                        </div>

                    </form>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('SchoolUserForm')(state)
    };
};

const validate = values => {
    const errors = {};

    if (!values.Email) {
        errors.Email = 'Pages.Register.Email.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.Email)) {
        errors.Email = 'Pages.Register.Email.error_notmatch';
    }

    return errors;
}

const formSchoolUserForm = reduxForm({
    form: 'SchoolUserForm',
    validate
});

export default connect(mapStateToProps, {})(withTranslation('common')(formSchoolUserForm(SchoolUserForm)));

import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

class ClassRoomItem extends React.Component {

    state = { showStudent: false, selectedStudent: [], selectAll: false, isFirst: true };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedStudent && state.isFirst) {

            state.isFirst = false;
            state.selectedStudent = props.selectedStudent;

            if (state.selectedStudent.length > 0) {
                var selectAll = true;
                props.classRoom.Students.forEach(student => {
                    if (!state.selectedStudent.includes(student.Id)) {
                        selectAll = false;
                    }
                });
                state.selectAll = selectAll;
            }
        }
        return state;
    }

    showStudent = () => {
        this.setState({
            showStudent: !this.state.showStudent
        });
    }

    SelectStudent = Id => {
        const { classRoom } = this.props;
        let selectedStudent = this.state.selectedStudent;
        selectedStudent.push(Id);
        let selectAll = false;
        if (selectedStudent.length === classRoom.Students.length) {
            selectAll = true;
        }
        this.setState({
            selectedStudent,
            selectAll
        });
        this.props.setSelect(selectedStudent);
    }
    DeselectStudent = Id => {
        let selectedStudent = this.state.selectedStudent;
        selectedStudent = selectedStudent.filter(function (ele) {
            return ele !== Id;
        });
        this.setState({
            selectedStudent,
            selectAll: false
        });
        this.props.setDeselect([Id]);
    }

    selectAll = value => {
        if (value) {
            const { classRoom } = this.props;
            let selectedStudent = [];
            classRoom.Students && classRoom.Students.forEach(student => {
                selectedStudent.push(student.Id);
            });
            this.setState({
                selectedStudent
            });
            this.props.setSelect(selectedStudent);

        } else {
            this.props.setDeselect(this.state.selectedStudent);
            this.setState({
                selectedStudent: []
            });
        }
        this.setState({
            selectAll: value
        });
    }

    render() {
        const { t, classRoom } = this.props;

        return <li>
            <Link to="#" >
                <span>
                    {
                        this.state.selectAll ?
                            <i className="icon fal fa-check-square" title={t('Pages.Missions.deselect')} onClick={() => this.selectAll(false)}></i>
                            :
                            <i className="icon fal fa-square" title={t('Pages.Missions.select')} onClick={() => this.selectAll(true)}></i>
                    }
                    <span onClick={this.showStudent}>
                        <i className="icon fal fa-users-class" ></i> {classRoom.Name}
                    </span>
                </span>
            </Link>
            {
                this.state.showStudent ?
                    <div className="widget-body pl-0 pb-0" >
                        <ul>
                            {
                                classRoom.Students && classRoom.Students.map(student => {
                                    return <li key={`student-${student.Id}`}>
                                        <Link to="#" >
                                            <span onClick={() =>
                                                this.state.selectedStudent.includes(student.Id) ?
                                                    this.DeselectStudent(student.Id) :
                                                    this.SelectStudent(student.Id)
                                            }>
                                                {
                                                    this.state.selectedStudent.includes(student.Id) ?
                                                        <i className="icon fal fa-check-square" title={t('Pages.Missions.deselect')}></i>
                                                        :
                                                        <i className="icon fal fa-square" title={t('Pages.Missions.select')}></i>
                                                }
                                                <i className="icon fal fa-user-graduate" ></i> {student.Name}
                                            </span>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    : ""
            }
        </li>

    }

}
export default withTranslation('common')(ClassRoomItem);
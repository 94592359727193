import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import InfoForm from '../Forms/InfoForm';
import api from '../../../../api/api';

class LessonInfo extends React.Component {

    state = {
        isLoaded: false,
        width: 100,
        BorderWidth: 3,
        TimeMinutes: 0,
        TimeHour: 0,
        TimesUpSound: 'none',
        CustomTimesUpSound: '',
        Confetti: 'none',
        CustomConfetti: '',
        ConfettiTimesUp: 'none',
        CustomConfettiTimesUp: '',
        TimeType: "continue",
        BorderStyle: 'dashed',
        BorderColor: 'rgba(68, 68, 68, 1)',
        TimeBorderColor: 'rgba(146, 39, 143, 1)',
        BackgroundBorderColor: 'rgba(255, 255, 255, 1)',
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    onSubmit = (e) => {
        this.submitMainInfo();
        e.preventDefault();
    };

    submitMainInfo = () => {

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.Name && this.props.IndexId) {

            let CustomStyle = {
                FontSize: this.props.FontSize,
                questionSeparated: {
                    BorderStyle: this.state.BorderStyle,
                    BorderColor: this.state.BorderColor,
                    BorderWidth: this.state.BorderWidth,
                    width: this.state.width
                },
                Clock: {
                    CustomTimesUpSound: this.state.CustomTimesUpSound,
                    TimesUpSound: this.state.TimesUpSound,
                    TimeMinutes: this.state.TimeMinutes,
                    TimeHour: this.state.TimeHour,
                    TimeType: this.state.TimeType,
                    TimeBorderColor: this.state.TimeBorderColor,
                    BackgroundBorderColor: this.state.BackgroundBorderColor,
                    ConfettiTimesUp: this.state.ConfettiTimesUp,
                    CustomConfettiTimesUp: this.state.CustomConfettiTimesUp
                },
                ExamBackground: {
                    Confetti: this.state.Confetti,
                    CustomConfetti: this.state.CustomConfetti
                },
                MaterialsObject: this.state.MaterialsObject
            };

            var postData = {
                Name: this.props.Name,
                Bio: this.props.Bio,
                Icon: this.props.Icon,
                Background: this.props.Background,
                Sound: this.props.Sound,
                IsActive: this.props.IsActive,
                IsPublic: this.props.IsPublic,
                IndexId: this.props.IndexId,
                Url: this.props.Url,
                CustomStyle
            };

            let url = `/Lessons/`;
            let method = api.post;
            if (this.props.Id && this.props.Id !== "0") {
                url = `/Lessons/${this.props.Id}`;
                method = api.put;
            }

            method(url,
                postData
            ).then(response => {

                if (this.props.Id !== 0 || response.data.data.Id !== 0) {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.forms.Success.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    if (this.props.Id === "0") {
                        this.props.setId(response.data.data.Id);
                    }
                    this.props.updateLessons({
                        ...postData,
                        Index: {
                            Id: this.props.IndexId
                        }
                    });

                    this.props.handleClose();

                } else {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.forms.error.Title')}</div> <div className="h6">{t('Pages.Lessons.forms.error.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.AllInformationIsRequired.Title')}</div> <div className="h6">{t('Error.AllInformationIsRequired.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.questionSeparated && !state.isLoaded) {
            state.BorderStyle = props.questionSeparated.BorderStyle;
            state.BorderWidth = props.questionSeparated.BorderWidth;
            state.BorderColor = props.questionSeparated.BorderColor;
            state.width = props.questionSeparated.width;
            state.type = props.type ? `Pages.${props.type}.forms.LessonInfo` : "Pages.Lessons.forms.LessonInfo";
        }

        if (props.ExamBackground && !state.isLoaded) {
            state.Confetti = props.ExamBackground.Confetti;
            state.CustomConfetti = props.ExamBackground.CustomConfetti;
        }

        if (props.Clock && !state.isLoaded) {
            state.TimeBorderColor = props.Clock.TimeBorderColor;
            state.BackgroundBorderColor = props.Clock.BackgroundBorderColor;
            state.CustomTimesUpSound = props.Clock.CustomTimesUpSound;
            state.TimesUpSound = props.Clock.TimesUpSound;
            state.TimeType = props.Clock.TimeType;
            state.TimeHour = props.Clock.TimeHour;
            state.TimeMinutes = props.Clock.TimeMinutes;
            state.CustomConfettiTimesUp = props.Clock.CustomConfettiTimesUp;
            state.ConfettiTimesUp = props.Clock.ConfettiTimesUp;
        }
        if (props.MaterialsObject && !state.isLoaded) {
            state.MaterialsObject = props.MaterialsObject;
        }
        return state;
    }

    setTimeBorderColor = TimeBorderColor => {
        this.setState({
            TimeBorderColor,
            isLoaded: true
        });
    }
    setBackgroundBorderColor = BackgroundBorderColor => {
        this.setState({
            BackgroundBorderColor,
            isLoaded: true
        });
    }
    setTimeHour = TimeHour => {
        if (TimeHour > 0 && TimeHour < 24) {
            this.setState({
                TimeHour,
                isLoaded: true
            });
        } else {
            this.setState({
                TimeHour: 0,
                isLoaded: true
            });
        }
    }
    setTimeMinutes = TimeMinutes => {
        if (TimeMinutes > 0 && TimeMinutes < 60) {
            this.setState({
                TimeMinutes,
                isLoaded: true
            });
        } else {
            this.setState({
                TimeMinutes: 0,
                isLoaded: true
            });
        }
    }
    setTimeType = TimeType => {
        this.setState({
            TimeType,
            isLoaded: true
        });
    }

    setCustomTimesUpSound = CustomTimesUpSound => {
        this.setState({
            CustomTimesUpSound,
            isLoaded: true
        });
    }
    setTimesUpSound = TimesUpSound => {
        this.setState({
            TimesUpSound,
            isLoaded: true
        });
    }

    setQuestionConfetti = Confetti => {
        this.setState({
            Confetti,
            isLoaded: true
        });
    }
    setQuestionCustomConfetti = CustomConfetti => {
        this.setState({
            CustomConfetti,
            isLoaded: true
        });
    }

    setMaterialsObject = MaterialsObject => {
        this.setState({
            MaterialsObject,
            isLoaded: true
        });
    }

    setQuestionConfettiTimesUp = ConfettiTimesUp => {
        this.setState({
            ConfettiTimesUp,
            isLoaded: true
        });
    }
    setQuestionCustomConfettiTimesUp = CustomConfettiTimesUp => {
        this.setState({
            CustomConfettiTimesUp,
            isLoaded: true
        });
    }

    setBorderColor = BorderColor => {
        this.setState({
            BorderColor,
            isLoaded: true
        });
    }
    setBorderStyle = BorderStyle => {
        this.setState({
            BorderStyle,
            isLoaded: true
        });
    }
    setBorderWidth = BorderWidth => {
        this.setState({
            BorderWidth,
            isLoaded: true
        });
    }
    setWidth = width => {
        this.setState({
            width,
            isLoaded: true
        });
    }

    render() {
        const { t, show, handleClose } = this.props;

        return (
            <div>
                <Modal
                    size="xl"
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    enforceFocus={false} >
                    <Modal.Header closeButton>
                        <Modal.Title> <i className="icon fal fa-text"></i>{t(`${this.state.type}.title`)} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InfoForm
                            key={this.props.Id}
                            Id={this.props.Id}
                            Name={this.props.Name}
                            Sound={this.props.Sound}
                            Background={this.props.Background}
                            FontSize={this.props.FontSize}
                            IsPublic={this.props.IsPublic}
                            IsActive={this.props.IsActive}
                            Confetti={this.state.Confetti}
                            CustomConfetti={this.state.CustomConfetti}
                            ConfettiTimesUp={this.state.ConfettiTimesUp}
                            CustomConfettiTimesUp={this.state.CustomConfettiTimesUp}
                            CustomTimesUpSound={this.state.CustomTimesUpSound}
                            TimesUpSound={this.state.TimesUpSound}
                            MaterialsObject={this.state.MaterialsObject}
                            TimeHour={this.state.TimeHour}
                            TimeMinutes={this.state.TimeMinutes}
                            Icon={this.props.Icon}
                            Url={this.props.Url}
                            IndexId={this.props.IndexId}
                            type={this.props.type}
                            typeTreeView={this.props.typeTreeView}
                            BorderStyle={this.state.BorderStyle}
                            BorderWidth={this.state.BorderWidth}
                            TimeBorderColor={this.state.TimeBorderColor}
                            BackgroundBorderColor={this.state.BackgroundBorderColor}
                            BorderColor={this.state.BorderColor}
                            Bio={this.props.Bio}
                            width={this.state.width}
                            TimeType={this.state.TimeType}
                            setMaterialsObject={this.setMaterialsObject}
                            setQuestionConfetti={this.setQuestionConfetti}
                            setQuestionCustomConfetti={this.setQuestionCustomConfetti}
                            setQuestionConfettiTimesUp={this.setQuestionConfettiTimesUp}
                            setQuestionCustomConfettiTimesUp={this.setQuestionCustomConfettiTimesUp}
                            onSubmit={this.onSubmit}
                            setTimeBorderColor={this.setTimeBorderColor}
                            setBackgroundBorderColor={this.setBackgroundBorderColor}
                            setBorderColor={this.setBorderColor}
                            setBorderStyle={this.setBorderStyle}
                            setBorderWidth={this.setBorderWidth}
                            setTimeHour={this.setTimeHour}
                            setTimeType={this.setTimeType}
                            setTimeMinutes={this.setTimeMinutes}
                            setWidth={this.setWidth}
                            setTimesUpSound={this.setTimesUpSound}
                            setCustomTimesUpSound={this.setCustomTimesUpSound}
                            setUrl={this.props.setUrl}
                            setBio={this.props.setBio}
                            setIsPublic={this.props.setIsPublic}
                            setIsActive={this.props.setIsActive}
                            setIndexId={this.props.setIndexId}
                            setBackground={this.props.setBackground}
                            setSound={this.props.setSound}
                            setFontSize={this.props.setFontSize}
                            setIcon={this.props.setIcon}
                            setName={this.props.setName} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="form-group w-100 text-center">
                            <button className="custom-button" type="button" onClick={() => this.submitMainInfo()}><span> {t('Save')}</span></button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withTranslation('common')(LessonInfo);
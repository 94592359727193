import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import Select from 'react-select';

import Footer from "../../../Footer/FooterBottom";
import api from '../../../../api/api';
import { fetchSchools } from '../../Users/actions';

class CompetitionStatistics extends React.Component {

    state = {
        data: [],
        MoreData: [],
        StudentResultData: [],
        schools: [],
        SchoolResultData: [],
        ClassTypeId: 0,
        showMoreInfo: false,
        showStudentResult: false,
        showSchoolList: false,
        showStudentResultText: "",
        Count: 0,
        TotalTime: 0,
        WinCount: 0,
        TryNumber: 0,
        TimeIsUpCount: 0
    };

    constructor(props) {
        super(props);
        this.props.fetchSchools();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.schools && props.schools.schools) {
            state.schools = props.schools.schools.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }
        return state;
    }

    componentDidMount() {
        this.Sync();
        window.scrollToPosition("main-competition-statistics-section");
    }

    Sync = () => {

        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const SchoolId = this.SchoolId && this.SchoolId.state && this.SchoolId.state.value ? this.SchoolId.state.value.value : 0;

        api.get(`/CompetitionResultStatistics/?SchoolId=${SchoolId}`).then(response => {

            let Count = 0;
            let TotalTime = 0;
            let TimeIsUpCount = 0;
            let WinCount = 0;
            let TryNumber = 0;

            response.data.data.forEach(item => {
                Count += item.Value.Count;
                TotalTime += item.Value.TotalTime;
                WinCount += item.Value.WinCount;
                TimeIsUpCount += item.Value.TimeIsUpCount;
                TryNumber += item.Value.TryNumber;
            });

            this.setState({
                data: response.data.data,
                Count,
                TotalTime,
                WinCount,
                TryNumber,
                TimeIsUpCount
            });

            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    calcTime = Time => {
        let result = "";
        if (Time >= 3600) {
            result += (parseInt(Time / 3600) > 9 ? parseInt(Time / 3600) : "0" + parseInt(Time / 3600)) + ":";
            Time = Time % 3600;
        } else {
            result += "00:";
        }

        if (Time >= 60) {
            result += (parseInt(Time / 60) > 9 ? parseInt(Time / 60) : "0" + parseInt(Time / 60)) + ":";
            Time = Time % 60;
        } else {
            result += "00:";
        }

        if (Time > 0) {
            result += parseInt(Time) > 9 ? parseInt(Time) : "0" + parseInt(Time);
        } else {
            result += "00";
        }
        return result;
    }

    calcDateTime = Time => {
        const { t } = this.props;
        let result = "";
        if (Time >= 86400) {
            result = parseInt(Time / 86400) + " " + t("Pages.ComingSoon.days") + " - ";
            Time = Time % 86400;
        }
        result += this.calcTime(Time);
        return result;
    }

    renderClassResult() {
        const { t, user } = this.props;

        return this.state.data.sort((a, b) => a.Key > b.Key ? 1 : -1).map((item, index) => {
            return <div className="featured-block col-lg-4 col-sm-6" key={`index-${index}`} >
                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                    <span className="border-one"></span>
                    <span className="border-two"></span>
                    <div className="icon-box d-flex justify-content-center flex-column" style={{
                        width: "auto",
                        borderRadius: "0px"
                    }}>
                        <span className=" h1 m-0 text-white">{t('Pages.Competition.ClassRoom.' + item.Key)}  </span>
                    </div>

                    <div className="row clearfix justify-content-between mt-0 mx-0 w-100 pt-2">
                        <table className="table table-striped table-hover table-bordered my-0" style={{
                            zIndex: 999
                        }}>
                            <thead>
                                <tr className="text-center">
                                    <th title={t('Pages.Competition.table.users')}>
                                        <i className="icon fa-light fa-users"></i>
                                        <span className="d-none d-lg-inline-block">
                                            {t('Pages.Competition.table.users')}
                                        </span>
                                    </th>
                                    <th title={t('Pages.Games.Table.Time')}>
                                        <i className="icon fa-light fa-clock"></i>
                                        <span className="d-none d-lg-inline-block">
                                            {t('Pages.Competition.table.TotalTime')}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{item.Value.Count}</td>
                                    <td>{this.calcDateTime(item.Value.TotalTime)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="row clearfix justify-content-between mt-0 mx-0 w-100 pt-2">
                        <table className="table table-striped table-hover table-bordered my-0" style={{
                            zIndex: 999
                        }}>
                            <thead>
                                <tr className="text-center ">
                                    <th colSpan={4} className="text-info">
                                        <i className="icon fas fa-repeat-1-alt"></i>
                                        {t('Pages.Competition.TryCount')}
                                    </th>
                                </tr>
                                <tr className="text-center">
                                    <th rowSpan={2} title={t('Pages.Competition.table.win')}>
                                        <i className="icon fa-light fa-chart-line-up"></i>
                                    </th>
                                    <th colSpan={2} title={t('Pages.Competition.table.loss')}>
                                        <i className="icon fa-light fa-chart-line-down"></i>
                                    </th>
                                    <th rowSpan={2} title={t('Pages.Competition.table.total')}>
                                        <i className="icon fa-light fa-sigma"></i>
                                    </th>
                                </tr>
                                <tr>
                                    <th title={t('Pages.Competition.table.wrongAnswer')}>
                                        <i className="icon fa-light fa-bomb"></i>
                                    </th>
                                    <th title={t('Pages.Competition.table.TimeIsUp')}>
                                        <i className="icon fa-regular fa-hourglass-clock"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{item.Value.WinCount}</td>
                                    <td>{item.Value.TryNumber - item.Value.WinCount - item.Value.TimeIsUpCount}</td>
                                    <td>{item.Value.TimeIsUpCount}</td>
                                    <td>{item.Value.TryNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-2">

                        <div className="d-flex justify-content-evenly align-items-center">
                            <div title={t('Pages.Competition.MoreResult')}
                                className={` text-center custom-button info-button ml-1`}
                                onClick={() => this.showMoreInfo(item.Key)} style={{
                                    minWidth: '105px'
                                }}>
                                <span className="d-flex w-100 justify-content-center align-items-center px-3 text-truncate">
                                    <i className="icon far fa-info"></i> {t('Pages.Competition.MoreResult')}
                                </span>
                            </div>

                            <div title={t('Pages.Competition.StudentResult')}
                                className={` text-center custom-button orange-button mr-1`}
                                onClick={() => this.showStudentResult(item.Key, true)} style={{
                                    minWidth: '105px'
                                }}>
                                <span className="d-flex w-100 justify-content-center align-items-center px-3 text-truncate">
                                    <i className="icon far fa-list"></i>{t('Pages.Competition.StudentResult')}
                                </span>
                            </div>
                        </div>
                        {
                            user.user && user.user.UserType.Id === 1 ?
                                <div title={t('Pages.Competition.StudentAllResult')}
                                    className={` text-center custom-button pink-button mt-3`}
                                    onClick={() => this.showStudentResult(item.Key, false)} style={{
                                        minWidth: '110px'
                                    }}>
                                    <span className="d-flex w-100 justify-content-center align-items-center px-3 text-truncate">
                                        <i className="icon far fa-list"></i>{t('Pages.Competition.StudentAllResult')}
                                    </span>
                                </div>
                                : ""
                        }

                    </div>
                </div>
            </div>
        });
    }

    showMoreInfo = ClassTypeId => {
        if (ClassTypeId) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            const SchoolId = this.SchoolId && this.SchoolId.state && this.SchoolId.state.value ? this.SchoolId.state.value.value : 0;

            api.get(`/CompetitionResultStatistics/${ClassTypeId}?SchoolId=${SchoolId}`).then(response => {
                this.setState({
                    MoreData: response.data.data,
                    ClassTypeId,
                    showMoreInfo: !this.state.showMoreInfo
                });
                toast.dismiss(_toastId);
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            this.setState({
                ClassTypeId,
                showMoreInfo: !this.state.showMoreInfo
            });
        }
    }

    showStudentResult = (ClassTypeId, ShowAll) => {
        if (ClassTypeId) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            const SchoolId = this.SchoolId && this.SchoolId.state && this.SchoolId.state.value ? this.SchoolId.state.value.value : 0;

            let postData = {
                SchoolId,
                ShowAll
            };

            api.post(`/CompetitionResultStatistics/${ClassTypeId}`, postData).then(response => {
                this.setState({
                    StudentResultData: response.data.data,
                    ClassTypeId,
                    showStudentResult: !this.state.showStudentResult,
                    showStudentResultText: ShowAll ? this.props.t('Pages.Competition.StudentResult') : this.props.t('Pages.Competition.StudentAllResult')
                });
                toast.dismiss(_toastId);
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            this.setState({
                ClassTypeId,
                showStudentResult: !this.state.showStudentResult,
                showStudentResultText: ShowAll ? this.props.t('Pages.Competition.StudentResult') : this.props.t('Pages.Competition.StudentAllResult')
            });
        }
    }

    renderMoreInfoTable = () => {
        if (this.state.MoreData && this.state.MoreData.length === 0) {
            return "";
        }
        const { t } = this.props;
        return this.state.MoreData.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map((item, index) => {
            return <tr key={`${item.LessonQuestionId}-index-${index}`} className="text-center">
                <td >{index + 1}</td>

                <td>
                    {item.IsPassed}
                </td>
                <td>
                    {Math.abs(item.Count - item.IsPassed)}
                </td>
                <td>
                    {Math.abs(item.Count - Math.abs(item.Count - item.IsPassed))}
                </td>
                <td>{item.WinCount}</td>
                <td>{item.TryNumber - item.WinCount - item.TimeIsUpCount}</td>
                <td>{item.TimeIsUpCount}</td>
                <td>{item.TryNumber}</td>
                <td title={t('Pages.Games.Table.Time')}>{this.calcTime(item.Time)}</td>
                <td title={t('Pages.Games.Table.Time')}>{this.calcTime(item.Time / item.IsPassed)}</td>
                <td title={t('Pages.Games.Table.Time')}>{this.calcTime(item.TotalTime)}</td>
                <td title={t('Pages.Games.Table.Time')}>{this.calcTime(item.TotalTime / item.Count)}</td>
                <td>
                    {item.Star}
                </td>
                <td>
                    {(item.Star / item.IsPassed).toFixed(2)}
                </td>
            </tr>
        });
    }

    renderRow = () => {
        return this.state.StudentResultData.sort((a, b) => a.TryNumber > b.TryNumber ? 1 : -1).sort((a, b) => a.Time < b.Time ? 1 : -1).sort((a, b) => a.Star < b.Star ? 1 : -1).map((item, index) => {
            const Time = this.calcTime(item.Time);
            const TotalTime = this.calcTime(item.TotalTime);
            return <tr key={`rows-${index}`}>
                <td className="text-center">{index + 1}</td>
                <td>{item.User.Name}</td>
                <td>{item.User.SchoolName}</td>
                <td className="text-center">{item.Star}</td>
                <td className="text-center">{Time}</td>
                <td className="text-center">{TotalTime}</td>
                <td className="text-center">{item.TryNumber}</td>
            </tr>
        })
    }

    showSchoolList = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/CompetitionResult/`).then(response => {
            this.setState({
                SchoolResultData: response.data.data,
                showSchoolList: true
            });
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, schools, user } = this.props;

        let isLoadedSchool = false;
        if (schools) {
            isLoadedSchool = schools.isLoaded;
        }

        return (
            <>
                {
                    this.state.showMoreInfo ?
                        <Modal
                            size="xl"
                            centered
                            show={this.state.showMoreInfo}
                            onHide={() => this.showMoreInfo(0)}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-info-circle"></i>
                                    {t('Pages.Competition.ClassRoom.' + this.state.ClassTypeId)}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="px-1">
                                <div className="widget widget-category ">
                                    <div className="widget-body p-0">
                                        <table className="table table-striped table-hover table-bordered table-sm my-0 w-100 bg-white">
                                            <thead>
                                                <tr className="text-center">
                                                    <th rowSpan={3} title={t('Pages.Games.Table.Level')} className="align-middle">
                                                        <i className="icon fa-light fa-sort-numeric-down"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Games.Table.Level')}
                                                        </span>
                                                    </th>
                                                    <th rowSpan={2} colSpan={3} title={t('Pages.Competition.table.users')} className="align-middle">
                                                        <i className="icon fa-light fa-users"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Competition.table.usersTitle')}
                                                        </span>
                                                    </th>
                                                    <th colSpan={4} className="text-info">
                                                        <i className="icon fas fa-repeat-1-alt"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Competition.TryCount')}
                                                        </span>

                                                    </th>
                                                    <th colSpan={4} title={t('Pages.Competition.Table.Time')} className="align-middle">
                                                        <i className="icon fa-light fa-clock-desk"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Competition.table.Time')}
                                                        </span>
                                                    </th>
                                                    <th rowSpan={2} colSpan={2} title={t('Pages.Competition.table.Stars')} className="align-middle">
                                                        <i className="icon fa-light fa-stars"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Competition.table.Stars')}
                                                        </span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th rowSpan={2} title={t('Pages.Competition.table.win')} className="text-center align-middle">
                                                        <i className="icon fa-light fa-chart-line-up"></i>
                                                        {t('Pages.Competition.table.win')}
                                                    </th>
                                                    <th colSpan={2} title={t('Pages.Competition.table.loss')} className="text-center">
                                                        <i className="icon fa-light fa-chart-line-down"></i>
                                                        {t('Pages.Competition.table.loss')}
                                                    </th>
                                                    <th rowSpan={2} title={t('Pages.Competition.table.total')} className="text-center align-middle">
                                                        <i className="icon fa-light fa-sigma"></i>
                                                        {t('Pages.Competition.table.total')}
                                                    </th>

                                                    <th colSpan={2} title={t('Pages.Competition.table.RecordedTime')} className="text-center">
                                                        <i className="icon fa-light fa-trophy"></i>
                                                        {t('Pages.Competition.table.RecordedTime')}
                                                    </th>

                                                    <th colSpan={2} title={t('Pages.Competition.table.TotalTimeTitle')} className="text-center">
                                                        <i className="icon fa-light fa-clock"></i>
                                                        {t('Pages.Competition.table.TotalTimeTitle')}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th title={t('Pages.Competition.table.winners')} className="text-center text-success">
                                                        <i className="icon fa-light fa-medal"></i>
                                                        {t('Pages.Competition.table.winners')}
                                                    </th>
                                                    <th title={t('Pages.Competition.table.lossers')} className="text-center text-danger">
                                                        <i className="icon fa-light fa-heart-crack"></i>
                                                        {t('Pages.Competition.table.lossers')}
                                                    </th>
                                                    <th title={t('Pages.Competition.table.total')} className="text-center">
                                                        <i className="icon fa-light fa-sigma"></i>
                                                        {t('Pages.Competition.table.total')}
                                                    </th>

                                                    <th title={t('Pages.Competition.table.wrongAnswer')} className="text-center">
                                                        <i className="icon fa-light fa-bomb"></i>
                                                        {t('Pages.Competition.table.wrongAnswerTitle')}
                                                    </th>
                                                    <th title={t('Pages.Competition.table.TimeIsUp')} className="text-center">
                                                        <i className="icon fa-regular fa-hourglass-clock"></i>
                                                        {t('Pages.Competition.table.Time')}
                                                    </th>
                                                    <th className="text-center align-middle">
                                                        <i className="icon fa-light fa-tally"></i>
                                                        {t('Pages.Competition.table.Count')}
                                                    </th>
                                                    <th className="text-center align-middle">
                                                        <i className="icon fa-light fa-percent"></i>
                                                        {t('Pages.Competition.table.Rate')}
                                                    </th>
                                                    <th className="text-center align-middle">
                                                        <i className="icon fa-light fa-tally"></i>
                                                        {t('Pages.Competition.table.Count')}
                                                    </th>
                                                    <th className="text-center align-middle">
                                                        <i className="icon fa-light fa-percent"></i>
                                                        {t('Pages.Competition.table.Rate')}
                                                    </th>
                                                    <th className="text-center align-middle">
                                                        <i className="icon fa-light fa-tally"></i>
                                                        {t('Pages.Competition.table.Count')}
                                                    </th>
                                                    <th className="text-center align-middle">
                                                        <i className="icon fa-light fa-percent"></i>
                                                        {t('Pages.Competition.table.Rate')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderMoreInfoTable()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-flex justify-content-center w-100 align-items-center  ">
                                    <button className="btn-danger btn mb-1" onClick={() => this.showMoreInfo(0)} >
                                        <i className="icon fal fa-xmark"></i>
                                        {t('cancel')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                {
                    this.state.showStudentResult ?
                        <Modal
                            size="xl"
                            centered
                            show={this.state.showStudentResult}
                            onHide={() => this.showStudentResult(0)}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-list"></i>
                                    {this.state.showStudentResultText}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <div className="widget widget-category ">
                                    <div className="widget-body">
                                        <table className="table table-striped table-bordered  table-hover table-sm w-100 bg-white">
                                            <thead>
                                                <tr className="text-center">
                                                    <th className="h4 align-middle text-center" rowSpan={2} title={t('Pages.Games.Table.Order')}><i className="text-success fas fa-sort-numeric-down"></i></th>
                                                    <th className="h4 align-middle text-center" rowSpan={2} title={t('Pages.Games.Table.Name')}><i className="text-primary mx-1 fas fa-user-graduate"></i> {t('Pages.Games.Table.Name')}</th>
                                                    <th className="h4 align-middle text-center" rowSpan={2} title={t('Pages.Games.Table.School')}><i className="text-default mx-1 fas fa-school"></i> {t('Pages.Games.Table.School')}</th>
                                                    <th className="h4 align-middle text-center" rowSpan={2} title={t('Pages.Games.Table.Stars')}><i className="text-warning fas fa-stars"></i></th>
                                                    <th className="align-middle text-center" colSpan={2} title={t('Pages.Games.Table.UserTime')}>
                                                        {t('Pages.Games.Table.UserTime')}
                                                    </th>
                                                    <th className="h4 align-middle text-center" rowSpan={2} title={t('Pages.Games.Table.Repate')}><i className="text-danger fas fa-repeat-1-alt"></i></th>
                                                </tr>
                                                <tr>
                                                    <th className="h4 align-middle text-center" title={t('Pages.Games.Table.Time')}><i className="text-info fas fa-user-clock"></i></th>
                                                    <th className="h4 align-middle text-center" title={t('Pages.Games.Table.TotalTime')}><i className="text-dark fas fa-clock"></i></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderRow()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-flex justify-content-center w-100 align-items-center  ">
                                    <button className="btn-danger btn mb-1" onClick={() => this.showStudentResult(0)} >
                                        <i className="icon fal fa-xmark"></i>
                                        {t('cancel')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                {
                    this.state.showSchoolList ?
                        <Modal
                            size="xl"
                            centered
                            show={this.state.showSchoolList}
                            onHide={() => this.setState({ showSchoolList: false })}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-list-ol"></i>
                                    {t('Pages.UserControl.schools')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <div className="widget widget-category ">
                                    <div className="widget-body">
                                        <table className="table table-striped table-bordered  table-hover  w-100 bg-white">
                                            <thead>
                                                <tr className="text-center">
                                                    <th className="align-middle text-center" title={t('Pages.Games.Table.Order')}><i className="text-success fas fa-sort-numeric-down"></i></th>
                                                    <th className="align-middle text-center" title={t('Pages.Games.Table.School')}><i className="text-default mx-1 fas fa-school"></i> {t('Pages.Games.Table.School')}</th>
                                                    <th className="align-middle text-center" title={t('Pages.Games.Table.Stars')}><i className="text-warning fas fa-stars"></i> {t('Pages.Games.Table.Stars')}</th>
                                                    <th className="align-middle text-center" title={t('Pages.Games.Table.StudentCount')}><i className="text-primary  fas fa-graduation-cap"></i> {t('Pages.Games.Table.StudentCount')}</th>
                                                    <th className="align-middle text-center" title={t('Pages.Games.Table.Average')}><i className="text-info fas fa-percent"></i> {t('Pages.Games.Table.Average')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.SchoolResultData && this.state.SchoolResultData.length > 0 ?
                                                        this.state.SchoolResultData.map((item, index) => {
                                                            return <tr key={`SchoolResultData_${index}`}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.Name}</td>
                                                                <td>{item.TotalStar}</td>
                                                                <td>{item.StudentCountInSchool}</td>
                                                                <td>{item.AvergeStars}</td>
                                                            </tr>
                                                        })
                                                        : ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-flex justify-content-center w-100 align-items-center  ">
                                    <button className="btn-danger btn mb-1" onClick={() => this.setState({ showSchoolList: false })} >
                                        <i className="icon fal fa-xmark"></i>
                                        {t('cancel')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                <Helmet>
                    <title>
                        {t('Pages.UserControl.CompetitionStatistics')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top oh" id="main-competition-statistics-section">
                    <div className="container">

                        <div className="row ">
                            <div className="col-lg-12 mb-50">

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">

                                        <div className="widget widget-search" >
                                            <div className="widget-body">

                                                <div className="section-header my-3">
                                                    <h3 className="title">{t('Pages.UserControl.CompetitionStatistics')}</h3>
                                                </div>
                                                {
                                                    user.user && user.user.UserType.Id === 1 ?
                                                        <div className="search-form contact-form align-items-center d-flex justify-content-center">
                                                            <div className="form-group mb-0" style={{
                                                                zIndex: 9999998
                                                            }}>
                                                                <Select
                                                                    isClearable={true}
                                                                    name="SchoolId"
                                                                    placeholder={t('Pages.UsersControl.advancedSearch.School')}
                                                                    isRtl={true}
                                                                    isLoading={!isLoadedSchool}
                                                                    isSearchable={true}
                                                                    ref={SchoolId => this.SchoolId = SchoolId}
                                                                    options={this.state.schools}
                                                                    filterOption={this.filterOptions}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#92278f',
                                                                            primary: '#92278f',
                                                                        },
                                                                    })}
                                                                />

                                                            </div>

                                                            <div className=" form-group w-100">
                                                                <div className="d-flex justify-content-evenly">
                                                                    <div className="custom-button primary-button mt-3" onClick={() => this.Sync()} title={t("Pages.SchoolsControl.Sync")} >
                                                                        <span>
                                                                            <i className="icon fal fa-search"></i>
                                                                            {t('Pages.UsersControl.advancedSearch.send')}
                                                                        </span>
                                                                    </div>
                                                                    <div className="custom-button purple-button mt-3" onClick={() => this.showSchoolList()} title={t("Pages.SchoolsControl.Sync")} >
                                                                        <span>
                                                                            <i className="icon fal fa-list-ol"></i>
                                                                            {t('Pages.UserControl.schools')}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.data && this.state.data.length === 0 ?
                                        <div className="row clearfix justify-content-center mt-4">
                                            <div className="col-lg-8">
                                                <div className="widget widget-search w-100" >
                                                    <div className="widget-body p-5">
                                                        <div className="h3 text-center title">
                                                            {t("NoInfo")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row clearfix justify-content-center mt-4">
                                                {this.renderClassResult()}
                                            </div>

                                            <div className="widget widget-search" >
                                                <div className="widget-body">

                                                    <div className="section-header my-3">
                                                        <h3 className="title">{t('Pages.Competition.Total')}</h3>
                                                    </div>

                                                    <div className="row">

                                                        <div className="col-md-6 col-sm-12 row clearfix justify-content-between mt-0 mx-0 w-100 pt-2">
                                                            <table className="table table-striped table-hover table-bordered my-0" style={{
                                                                zIndex: 999
                                                            }}>
                                                                <thead>
                                                                    <tr className="text-center">
                                                                        <th title={t('Pages.Competition.table.users')}>
                                                                            <i className="icon fa-light fa-users"></i>
                                                                            <span className="d-none d-lg-inline-block">
                                                                                {t('Pages.Competition.table.users')}
                                                                            </span>
                                                                        </th>
                                                                        <th title={t('Pages.Games.Table.Time')}>
                                                                            <i className="icon fa-light fa-clock"></i>
                                                                            <span className="d-none d-lg-inline-block">
                                                                                {t('Pages.Competition.table.TotalTime')}
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="text-center">
                                                                        <td>{this.state.Count}</td>
                                                                        <td >{this.calcDateTime(this.state.TotalTime)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 row clearfix justify-content-between mt-0 mx-0 w-100 pt-2">
                                                            <table className="table table-striped table-hover table-bordered my-0" style={{
                                                                zIndex: 999
                                                            }}>
                                                                <thead>
                                                                    <tr className="text-center">
                                                                        <th rowSpan={2} title={t('Pages.Competition.table.win')} className="align-middle">
                                                                            <i className="icon fa-light fa-chart-line-up"></i>
                                                                            {t('Pages.Competition.table.win')}
                                                                        </th>
                                                                        <th colSpan={2} title={t('Pages.Competition.table.loss')}>
                                                                            <i className="icon fa-light fa-chart-line-down"></i>
                                                                            {t('Pages.Competition.table.loss')}
                                                                        </th>
                                                                        <th rowSpan={2} title={t('Pages.Competition.table.total')} className="align-middle">
                                                                            <i className="icon fa-light fa-sigma"></i>
                                                                            {t('Pages.Competition.table.total')}
                                                                        </th>
                                                                    </tr>
                                                                    <tr className="text-center">
                                                                        <th title={t('Pages.Competition.table.wrongAnswer')}>
                                                                            <i className="icon fa-light fa-bomb"></i>
                                                                            {t('Pages.Competition.table.wrongAnswerTitle')}
                                                                        </th>
                                                                        <th title={t('Pages.Competition.table.TimeIsUp')}>
                                                                            <i className="icon fa-regular fa-hourglass-clock"></i>
                                                                            {t('Pages.Competition.table.Time')}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="text-center">
                                                                        <td>{this.state.WinCount}</td>
                                                                        <td>{this.state.TryNumber - this.state.WinCount - this.state.TimeIsUpCount}</td>
                                                                        <td>{this.state.TimeIsUpCount}</td>
                                                                        <td>{this.state.TryNumber}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        schools: state.schools,
        user: state.user
    };
};
export default connect(mapStateToProps, { fetchSchools })(withTranslation('common')(CompetitionStatistics));


import React from "react";

import GameObjects from "../../GameObjects";
import './style.css';

class Mountain extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Mountain/mounten.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'conta',
            top: '0'
        }}>

            <div className="position-absolute user-select-none MoveblackBird"><img src={`${process.env.PUBLIC_URL + "/assets/images/Mountain/bird.png"}`} alt="" /></div>

            <div className="position-absolute user-select-none d-none d-sm-block" style={{
                right: 'calc(50% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none d-none d-sm-block" style={{
                right: '2%',
                top: '2%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>


            <div className="position-absolute user-select-none MoveDuck d-none d-sm-block" style={{
                left: '5%',
                bottom: '5%'
            }}><GameObjects objectCss="Duck" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute MoveBoat" style={{
                right: '5%',
                bottom: '15%'
            }}>
                <div className="position-relative d-flex justify-content-center ">
                    <div className="position-absolute">
                        <GameObjects objectCss="WaterCircles" />
                    </div>
                    <div className="position-absolute" style={{
                        top: '-120px'
                    }}>
                        <GameObjects objectCss="BoyInBox" status={this.props.currentStatus} FinishMove={this.FinishMove} />
                    </div>
                </div>
            </div>

            <div className="position-absolute user-select-none MoveEgale d-none d-sm-block" ><GameObjects objectCss="Egale" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute user-select-none MoveBlueBird" style={{
                left: '5%',
                top: '5%'
            }}><GameObjects objectCss="BlueBird" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default Mountain;
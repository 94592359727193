const studentInClassRoomsReducers = (state = [], action) => {
    switch (action.type) {
        case "STUDENTS_IN_CLASSROOM_LOADING":
            return {
                studentInClassRooms: state.studentInClassRooms,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "STUDENTS_IN_CLASSROOM_INITIAL":
            return {
                studentInClassRooms: action.studentInClassRooms,
                toRemove: [],
                isLoaded: true
            };
        case "STUDENTS_IN_CLASSROOM_UPDATE_INFO":
            return {
                studentInClassRooms: UpdateStudentInClassRoomData(action.studentInClassRoom, state.studentInClassRooms, action.id),
                toRemove: [],
                isLoaded: true
            };
        case "STUDENTS_IN_CLASSROOM_QUEUE_FOR_REMOVAL":
            return {
                studentInClassRooms: state.studentInClassRooms,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "STUDENTS_IN_CLASSROOM_CLEAN_NODES":
            return {
                studentInClassRooms: state.toRemove.some(v => v === action.id) ? state.studentInClassRooms.filter(v => v.Id !== action.id) : state.studentInClassRooms,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "STUDENTS_IN_CLASSROOM_UNDO":
            return {
                studentInClassRooms: state.studentInClassRooms,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "STUDENTS_IN_CLASSROOM_ADD":
            return {
                studentInClassRooms: AddStudentInClassRoomData(action.studentInClassRoom, state.studentInClassRooms),
                toRemove: state.toRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

function AddStudentInClassRoomData(newInfo, studentInClassRooms) {
    let copy = [];
    studentInClassRooms.map(studentInClassRoom => {
        copy.push({
            ...studentInClassRoom
        });
        return '';
    });
    copy.push({
        ...newInfo
    });
    return copy;
}

function UpdateStudentInClassRoomData(newInfo, studentInClassRooms, id) {
    let copy = [];
    studentInClassRooms.map(studentInClassRoom => {
        if (studentInClassRoom.Id === id) {
            studentInClassRoom = {
                ...studentInClassRoom,
                ...newInfo,
                Name: newInfo.FirstName + " " + newInfo.SecondName + " " + newInfo.LastName
            };
        }
        copy.push({
            ...studentInClassRoom
        });
        return '';
    });
    return copy;
}

export default studentInClassRoomsReducers;

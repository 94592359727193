import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import { updateQuestion } from '../actions/';
import configData from "../../../Config/QuestionLevel.json";

class Info extends React.Component {

    state = { QuestionId: 0, title: '', Position: false, first: true, Level: '', errorList: [] };

    constructor(props) {
        super(props);
        this._editor = new React.createRef();
        this.QuestionLevel = configData.QuestionLevel;
    }

    static getDerivedStateFromProps(props, state) {
        state.QuestionId = props.QuestionId;
        state.title = props.title;
        state.Level = props.Level;
        state.Mark = props.mark;
        state.Position = props.Position;

        if (state.title && state.first) {
            state.first = false;
            setTimeout(() => {
                window.createRedactor("#QuestionTitle", state.title);
            }, 400);
        }
        return state;
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.state.QuestionId === 0) {
                window.createRedactor("#QuestionTitle", "");
            }
        }, 700);
    }

    renderField = ({
        input,
        title,
        type,
        defaultValue,
        id,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} id={id} value={defaultValue} title={title} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );

    validateQuestionMark = value => {
        const { t } = this.props;
        if (!value || value <= 0) {
            return t('Pages.Lessons.Questions.forms.QuestionMark.placeholder');
        }
    }

    setMarkValue = value => {
        this.setState({
            Mark: value
        });
        this.props.setMark(value);
        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Mark: value
        }
        this.props.updateQuestion(copy);
    }

    setLevel = value => {
        var index = value.nativeEvent.target.selectedIndex;
        let Level = parseInt(value.nativeEvent.target.value);
        this.setState({
            Level: Level
        });
        this.props.setLevel(Level);
        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Level: {
                Id: Level,
                Name: value.nativeEvent.target[index].text
            }
        }
        this.props.updateQuestion(copy);
    }

    setPosition = value => {
        this.setState({
            Position: value
        });
        this.props.setPosition(value);
        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Position: value
        }
        this.props.updateQuestion(copy);
    }

    renderQuestionLevel() {
        return this.QuestionLevel.map(level => {
            return <option key={level.value} value={level.value}  > {level.label}</option >;
        });
    }

    submitMainInfo = () => {
        const { t } = this.props;
        var QuestionTitle = window.getRedactor("#QuestionTitle");

        var errors = this.validate(this.state.Mark, QuestionTitle);
        if (errors.length !== 0) {
            var errorList = errors.map((error, index) => {
                return <li key={`error-${index}`}>{error.placeholder}.</li>;
            });
            this.setState({ errorList: errorList });

            this.props.toast.error(t('Error.AllInformationIsRequired.Body'), {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } else {
            this.setState({ errorList: [] });
            this.props.submitMainInfo(QuestionTitle, this.state.Mark, this.Level ? this.Level.value : 1, this.props.Position);
        }
    }

    validate(Mark = '', QuestionTitle = '') {
        const { t, typeId } = this.props;
        const errors = [];

        if (typeId !== 11 && (Mark.length === 0 || Mark <= 0)) {
            errors.push({ placeholder: t('Pages.Lessons.Questions.forms.QuestionMark.placeholder'), name: 'Mark' });
        }
        if (QuestionTitle.length === 0) {
            errors.push({ placeholder: t('Pages.Lessons.Questions.forms.QuestionTitle.placeholder'), name: 'QuestionTitle' });
        }
        return errors;
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div className={`alert alert-danger mb-5 p-2 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                    <div className="alert-text">
                        <ul className="list-unstyled">
                            <li>
                                <ul>
                                    {this.state.errorList}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                {
                    this.props.isForGame ? "" :
                        <div className="title h5 mt-2 mb-3 w-100"> <i className="icon fal fa-info"></i> {t('Pages.Lessons.Questions.forms.Info')}</div>
                }
                <div className="form-group w-100 mb-1">
                    <label className="text-dark font-size-h5" htmlFor="QuestionTitle">
                        <i className="icon fas fa-text"></i>
                        {
                            this.props.isForGame ?
                                t('Pages.Games.forms.LessonInfo.QuestionInfo')
                                :
                                t('Pages.Lessons.Questions.forms.QuestionTitle.title')
                        }
                    </label>
                    <textarea id="QuestionTitle" autoComplete="off" placeholder={t('Pages.Lessons.Questions.forms.QuestionTitle.placeholder')} className="form-control" ref={this._editor} ></textarea>
                </div>

                {
                    this.props.isForGame ?
                        <>
                            <div className="w-100">
                                <ul className="filter mb-2">
                                    <li>
                                        <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                            <input
                                                type='radio'
                                                checked={this.state.Position ? true : false}
                                                onChange={e => this.setPosition(true)}
                                                className="custom-control-input"
                                                title={t('Pages.Games.forms.LessonInfo.IsForFun')}
                                                name="TypeOfGameQuestion"
                                                id="IsForFun" />
                                            <label className="custom-control-label pt-1" htmlFor="IsForFun" >
                                                <i className="icon fa-regular fa-gamepad-modern"></i>
                                                {t('Pages.Games.forms.LessonInfo.IsForFun')}
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                            <input
                                                type='radio'
                                                checked={!this.state.Position ? true : false}
                                                onChange={e => this.setPosition(false)}
                                                title={t('Pages.Games.forms.LessonInfo.IsQuestion')}
                                                className="custom-control-input"
                                                name="TypeOfGameQuestion"
                                                id="IsQuestion" />
                                            <label className="custom-control-label pt-1" htmlFor="IsQuestion" >
                                                <i className="icon fa-regular fa-block-question"></i>
                                                {t('Pages.Games.forms.LessonInfo.IsQuestion')}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </>
                        : ""
                }

                {
                    this.props.typeId !== 11 ?
                        <>
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor="Mark"> <i className="icon fas fa-tally"></i>
                                    {
                                        this.state.Position ?
                                            t('Pages.Games.forms.LessonInfo.TryCount')
                                            :
                                            t('Pages.Lessons.Questions.forms.QuestionMark.title')
                                    }
                                </label>
                                <Field
                                    ref={Mark => this.Mark = Mark}
                                    id="Mark"
                                    name="Mark"
                                    component={this.renderField}
                                    defaultValue={this.state.Mark}
                                    type="number"
                                    required={true}
                                    onChange={e => this.setMarkValue(e.target.value)}
                                    validate={this.validateQuestionMark}
                                    placeholder={this.state.Position ? t('Pages.Games.forms.LessonInfo.TryCountInfo') : t('Pages.Lessons.Questions.forms.QuestionMark.placeholder')}
                                    title={this.state.Position ? t('Pages.Games.forms.LessonInfo.TryCount') : t('Pages.Lessons.Questions.forms.QuestionMark.title')}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-dark font-size-h5" htmlFor="Level"> <i className="icon fas fa-water-rise"></i> {t('Pages.Lessons.Questions.forms.QuestionLevel.title')}</label>
                                <select
                                    id="Level"
                                    required={true}
                                    ref={Level => this.Level = Level}
                                    value={this.state.Level}
                                    onChange={e => this.setLevel(e)}
                                    placeholder={t('Pages.Lessons.Questions.forms.QuestionLevel.placeholder')}
                                    title={t('Pages.Lessons.Questions.forms.QuestionLevel.title')}>
                                    {this.renderQuestionLevel()}
                                </select>
                            </div>
                        </>
                        : ""
                }

                {
                    this.props.isForGame ? "" :
                        <>
                            <div className="w-100">
                                <ul className="filter mb-2">
                                    <li>
                                        <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                            <input
                                                type='radio'
                                                checked={this.state.Position ? true : false}
                                                onChange={e => this.setPosition(true)}
                                                className="custom-control-input"
                                                title={t('Pages.Lessons.Questions.forms.tools.positionRight')}
                                                name="positionQuestion"
                                                id="positionRight" />
                                            <label className="custom-control-label pt-1" htmlFor="positionRight" >
                                                <i className="icon fad fa-arrow-alt-from-left"></i>
                                                {t('Pages.Lessons.Questions.forms.tools.positionRight')}
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                            <input
                                                type='radio'
                                                checked={!this.state.Position ? true : false}
                                                onChange={e => this.setPosition(false)}
                                                title={t('Pages.Lessons.Questions.forms.tools.positionLeft')}
                                                className="custom-control-input"
                                                name="positionQuestion"
                                                id="positionLeft" />
                                            <label className="custom-control-label pt-1" htmlFor="positionLeft" >
                                                <i className="icon fad fa-arrow-alt-from-right"></i>
                                                {t('Pages.Lessons.Questions.forms.tools.positionLeft')}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </>
                }

                <div className="form-group w-100 text-center">
                    <button className="custom-button" title={t('Pages.Lessons.Questions.forms.tools.saveAndContinue')} type="button" onClick={() => this.submitMainInfo()}>
                        <span>
                            <i className="icon far fa-save"></i>
                            {
                                this.props.isForGame ?
                                    t('Pages.Games.forms.LessonInfo.saveAndContinue')
                                    :
                                    t('Pages.Lessons.Questions.forms.tools.saveAndContinue')
                            }
                        </span>
                    </button>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(Info));

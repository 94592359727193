import api from '../../../../api/api';

export const fetchSliders = () => async dispatch => {
    dispatch({ type: 'SLIDERS_LOADING' });
    const response = await api.get(`/Sliders`);
    dispatch({ type: 'SLIDERS_INITIAL', sliders: response.data.data });
}

export const fetchSlider = id => async dispatch => {
    try {
        const response = await api.get(`/Sliders/${id}`);
        dispatch({ type: 'SLIDER_INITIAL', slider: response.data.data });
    } catch {
        window.location.href = "/404.html";
    }
}

export const fetchSlidersByLimit = limit => async dispatch => {
    dispatch({ type: 'SLIDERS_LOADING' });
    const response = await api.get(`/Sliders/?limit=${limit}&byLimit=${true}`);
    dispatch({ type: 'SLIDERS_INITIAL', sliders: response.data.data });
}

export const removeSlider = (id) => async dispatch => {
    dispatch({ type: 'SLIDER_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteSlider = (id) => async dispatch => {
    dispatch({ type: 'SLIDER_UNDO', id });
}

export const clearSlider = (id) => async dispatch => {
    dispatch({ type: 'SLIDER_CLEAN_NODES', id });
}

import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Footer from "../../../Footer/FooterBottom";

class ContactSupportConfirmation extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.ContactSupportConfirmation")} | {t('alarabeyya')}</title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>

                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="teacher-wrapper pos-rel pt-5  " style={{
                            minWidth: '50%'
                        }}>
                            <div className="teacher-single-top h-100">
                                <div className="product-content w-100 text-center ">
                                    <div className="display-1 text-success">
                                        <i className="fad fa-shield-check"></i>
                                    </div>
                                    <h4 className=" mb-3">
                                        تمَّ تَحدِيد اَلموْعِد بِنجاح
                                    </h4>
                                    <p className="text-justify">
                                        سَيتِم التَّواصل معك على ال zoom فِي اَلموْعِد اَلذِي قُمْت بِتحْديده ( الرَّجَاء التَّواجد بِجانب كُمْبيوتر \ شَبكَة إِنْترْنتَ قَبْل بَدْء الاسْتشارة )
                                    </p>
                                    <p className="text-justify">
                                        <span className="font-weight-bold">
                                            مُلَاحظَة
                                        </span>
                                        : فِي حال عدم الالْتزام بِالْوَقْتِ اَلمُحدد لِأكْثر مِن مرَّتيْنِ دُون إِلغَاء اَلموْعِد ، سَيتِم إِلغَاء إِمْكانيَّة حَجْز اِسْتشارات مَجانِية مِن حِسابك .
                                    </p>
                                </div>
                                <div className="d-flex justify-content-center mt-4 align-items-center  ">
                                    <Link to="/" className="custom-button m-1" >
                                        <span>
                                            <i className="icon fal fa-home"></i>
                                            {t('Home')}
                                        </span>
                                    </Link>
                                    <Link to="/Contact" className="custom-button info-button m-1" >
                                        <span>
                                            <i className="icon fal fa-envelope"></i>
                                            {t('Contact')}
                                        </span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default withTranslation('common')(ContactSupportConfirmation);
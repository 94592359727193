import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class Helicopter extends React.Component {

    render() {
        let color = "rgba(255,94,63,1)";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const styleCockpit = {
            backgroundColor: color
        };
        const styleTail = {
            borderLeft: `330px solid ${color}`
        };
        const styleMain = {
            borderBottom: `70px solid ${color}`
        };
        return (
            <>
                <div className="helicopter-wrapper mx-auto">

                    <div className="helicopter">
                        <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                            <div style={{
                                transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                                transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                            }}>
                                <div className="cockpit" style={styleCockpit}></div>
                                <div className="tail" style={styleTail}></div>
                                <div className="main" style={styleMain}></div>
                                <div className="rotor">
                                    <div className="rotator">
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="smallrotor">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TextBox {...this.props} />
                </div>
            </>
        );
    }
}

export default Helicopter;


import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import api from '../../../api/api';
import { ErrorReportBySocket } from '../../../api/socket.io';

class ErrorReportModal extends React.Component {

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    onSubmit = () => {

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.questionId && this.Note.value) {
            var postData = {
                questionId: this.props.questionId,
                LessonId: this.props.LessonId,
                Note: this.Note.value
            };
            api.post(`/ErrorReport/`,
                postData
            ).then(response => {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-exclamation-triangle"></i>
                            {t('Pages.ErrorReport.toast.Success.Title')}
                        </div>
                        <div className="h6">
                            {t('Pages.ErrorReport.toast.Success.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                ErrorReportBySocket();

                this.props.hideErrorModal();

            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.AllInformationIsRequired.Title')}</div> <div className="h6">{t('Error.AllInformationIsRequired.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    render() {
        const { t } = this.props;

        return <Modal
            size="xl"
            centered
            show={true}
            onHide={this.props.hideErrorModal}
            backdrop="static"
            keyboard={false}
            enforceFocus={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="icon far fa-tools"></i>
                    {t('Pages.ErrorReport.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="widget widget-category ">
                    <div className="widget-body">
                        <div className="form-group w-100">
                            <label className='text-dark font-size-h5'>
                                {t('Pages.ErrorReport.Note.title')}
                                <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i>
                            </label>
                            <div>
                                <textarea
                                    style={{
                                        resize: "vertical"
                                    }}
                                    ref={Note => this.Note = Note}
                                    name="Note"
                                    placeholder={t('Pages.ErrorReport.Note.Placeholder')}
                                    title={t('Pages.ErrorReport.Note.title')}
                                    className="form-control"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group w-100 text-center">
                    <button className="custom-button" type="button" onClick={() => this.onSubmit()}><span> {t('Pages.ErrorReport.Save')}</span></button>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(ErrorReportModal));
const indicesReducers = (state = [], action) => {
    switch (action.type) {
        case "INDICES_INITIAL":
            return {
                nodes: action.nodes,
                toRemove: []
            };
        case "INDICES_QUEUE_FOR_REMOVAL":
            return {
                nodes: state.nodes,
                toRemove: [...state.toRemove, action.id]
            };
        case "INDICES_CLEAN_NODES":
            return {
                nodes: state.toRemove.some(v => v === action.id) ? removeNodeById(state.nodes, action.id) : state.nodes,
                toRemove: state.toRemove.filter(v => v !== action.id)
            };
        case "INDICES_UNDO":
            return {
                nodes: state.nodes,
                toRemove: state.toRemove.filter(v => v !== action.id)
            };
        case "INDICES_UPDATE":
            return {
                nodes: UpdateNode(action.newNode, state.nodes, action.id),
                toRemove: state.toRemove
            };
        case "INDICES_UPDATE_REMOVE_COUNT":
            return {
                nodes: removeCount(state.nodes, action.id),
                toRemove: state.toRemove
            };
        case "INDICES_CREATE":
            return {
                nodes: CreateNewNode(action.newNode, state.nodes, action.id),
                toRemove: state.toRemove
            };
        default:
            return state;
    }
}

function CreateNewNode(newNode, nodes, id) {
    let copy = [];
    if (!id) {
        if (nodes) {
            copy = [
                ...nodes,
                {
                    label: newNode.label,
                    id: newNode.id,
                    icon: newNode.icon,
                    isActive: newNode.isActive,
                    isOpen: false,
                    count: 0,
                    nodes: []
                }
            ];
        } else {
            copy = [
                {
                    label: newNode.label,
                    id: newNode.id,
                    icon: newNode.icon,
                    isActive: newNode.isActive,
                    isOpen: false,
                    count: 0,
                    nodes: []
                }
            ];
        }
    } else {
        nodes.map(node => {
            if (node.id === id) {
                node.nodes = [
                    ...node.nodes,
                    {
                        label: newNode.label,
                        id: newNode.id,
                        icon: newNode.icon,
                        isActive: newNode.isActive,
                        isOpen: false,
                        count: 0,
                        nodes: []
                    }
                ];
            }
            copy.push({
                ...node,
                nodes: CreateNewNode(newNode, node.nodes, id)
            });
            return '';
        });
    }
    return copy;
}

function UpdateNode(newNode, nodes, id) {
    let copy = [];
    nodes.map(node => {
        if (node.id === id) {
            node = {
                ...node,
                label: newNode.label,
                id: newNode.id,
                icon: newNode.icon,
                isActive: newNode.isActive,
                isOpen: newNode.isOpen,
            };
        }
        copy.push({
            ...node,
            nodes: UpdateNode(newNode, node.nodes, id)
        });
        return '';
    });
    return copy;
}

function removeNodeById(nodes, id) {
    let copy = [];
    nodes.map(node => {
        if (node.id !== id) {
            copy.push({
                label: node.label,
                id: node.id,
                icon: node.icon,
                isOpen: node.isOpen,
                isActive: node.isActive,
                count: node.count,
                nodes: removeNodeById(node.nodes, id)
            });
        }
        return '';
    });
    return copy;
}

function removeCount(nodes, id) {
    let copy = [];
    nodes.map(node => {
        if (node.id === id) {
            node = {
                ...node,
                count: node.count - 1,
            };
        }
        copy.push({
            ...node,
            nodes: removeCount(node.nodes, id)
        });
        return '';
    });
    return copy;
}

export default indicesReducers;
import React from 'react';
import { withTranslation } from 'react-i18next';
import DragDrop from './DragDrop';
import Modal from 'react-bootstrap/Modal';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

import api from '../../../../api/api';
import SubItems from './SubItems';
import configData from "../../../Config/objects.json";
import MovePreview from '../View/GameObjects/MovePreview';
import SubAnswerItem from './SubAnswerItem';
import configSound from "../../../Config/game_sound.json";
import CustomMovePaint from './CustomMovePaint';

class Items extends React.Component {

    state = {
        items: [],
        FillIndex: 0,
        displayDropColorPickerId: 0,
        displayDropColorPickerIndex: 0,
        displayDropColorPicker: false,
        show: false,
        showSubAnswer: false,
        showEditStyle: false,
        showPaint: false,
        EditItem: null,
        EditStyle: null,
        CurrentAnswer: "",
        currentMovmentIndex: 0,
        currentMovmentSort: 0,
        CustomStyle: {
            Sound: [],
            Answers: [],
            Movments: []
        }
    }

    constructor(props) {
        super(props);
        this._editor = new React.createRef();
        this.audio = new Audio();
        this.Objects = configData.Objects.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else return 1;
        });
        this.ShottingObjects = configData.ShottingObjects.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else return 1;
        });
        this.Cursor = configData.Cursor;
        this.Category = configData.Category;
        this.True_Sound = configSound.True_Sound;
        this.False_Sound = configSound.False_Sound;
    }

    componentDidMount() {
        if (this.props.RedactorId) {
            window.createRedactor(`#${this.props.RedactorId}`);
        }
    }

    renderCursor = () => {
        return this.Cursor.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.items) {
            let items = [];

            let mainItem = props.items;
            if (props.FillIndex && props.categoryId && props.fills) {
                mainItem = mainItem.filter(p => props.fills.some(f => f.Sort === props.FillIndex && f.CategoryId === props.categoryId && f.AnswerId === p.Id)).map(answer => {
                    return answer;
                });
            }
            mainItem.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                items.push({
                    ...item,
                    id: item.Id,
                    content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + item.Name + "</div>",
                    html: item.Name,
                    adjustXPos: 0,
                    adjustYPos: 0,
                    CustomStyle: item.CustomStyle ? item.CustomStyle : state.CustomStyle
                });
            });
            state.items = items;
        }
        return state;
    }

    saveSort = () => {
        const { t, toast } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const sortedId = [];
        this.state.items.forEach((item, index) => {
            sortedId.push({
                id: item.id,
                sort: index
            });
        });

        var postData = {
            sortedIds: sortedId
        };

        api.post(`${this.props.API}` + this.props.QuestionId,
            postData
        ).then(response => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('ControlPanel.Indices.Sort.Title')}</div> <div className="h6">{t('ControlPanel.Indices.Sort.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    setSort = value => {

        let canContinue = true;
        if (
            (this.props.typeId === 19 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 20 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 23 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 24 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 25 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 26 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 27 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 28 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 29 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 30 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 31 && this.props.isSubAnswer === true)) {
            canContinue = false;
        }

        if (canContinue) {

            this.setState({
                items: value
            });
            this.props.setSort(value);
        }
    }
    AddItem = () => {
        const { t, toast } = this.props;

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var items = [];

        if (
            (
                this.props.typeId === 5 ||
                this.props.typeId === 19 ||
                this.props.typeId === 20 ||
                this.props.typeId === 23 ||
                this.props.typeId === 24 ||
                this.props.typeId === 25 ||
                this.props.typeId === 26 ||
                this.props.typeId === 27 ||
                this.props.typeId === 28 ||
                this.props.typeId === 29 ||
                this.props.typeId === 30 ||
                this.props.typeId === 31
            )
            && this.props.API === "/QuestionAnswers/" && this.props.question && this.props.question.question && this.props.question.question.Answers) {
            this.props.question.question.Answers.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                items.push({
                    id: item.Id,
                    content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + item.Name + "</div>",
                    html: item.Name,
                    adjustXPos: 0,
                    adjustYPos: 0,
                    CustomStyle: item.CustomStyle ? item.CustomStyle : this.state.CustomStyle
                });
            });
        } else {
            this.props.items.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                items.push({
                    id: item.Id,
                    content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + item.Name + "</div>",
                    html: item.Name,
                    adjustXPos: 0,
                    adjustYPos: 0,
                    CustomStyle: item.CustomStyle ? item.CustomStyle : this.state.CustomStyle
                });
            });
        }

        var html = this.state.CurrentAnswer;
        if (this.props.RedactorId) {
            html = window.getRedactor(`#${this.props.RedactorId}`);
        }

        var postData = {
            Name: html,
            CategoryId: this.props.categoryId,
            FillIndex: this.props.FillIndex,
            QuestionId: this.props.QuestionId
        };

        if (!html) {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.FillNoAnswer.title')}</div> </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        api.post(
            `${this.props.API}`,
            postData
        ).then(response => {
            if (response.data.data.Id !== 0) {
                toast.update(toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });

                let fills = this.props.fills;
                if (
                    this.props.typeId === 5 ||
                    this.props.typeId === 6 ||
                    this.props.typeId === 7 ||
                    this.props.typeId === 9 ||
                    this.props.typeId === 14 ||
                    this.props.typeId === 19 ||
                    this.props.typeId === 20 ||
                    this.props.typeId === 23 ||
                    this.props.typeId === 24 ||
                    this.props.typeId === 25 ||
                    this.props.typeId === 26 ||
                    this.props.typeId === 27 ||
                    this.props.typeId === 28 ||
                    this.props.typeId === 29 ||
                    this.props.typeId === 30 ||
                    this.props.typeId === 31 ||
                    this.props.typeId === 32
                ) {
                    fills.push({
                        Id: response.data.data.FillId,
                        AnswerId: response.data.data.Id,
                        CategoryId: this.props.categoryId,
                        Sort: this.props.FillIndex
                    });

                    if (this.props.question && this.props.question.question) {
                        var copy = this.props.question;
                        copy.question = {
                            ...copy.question,
                            Fills: fills
                        }
                        this.props.updateQuestion(copy);
                    }
                }

                this.props.updateQuestionInfo([
                    ...items,
                    {
                        id: response.data.data.Id,
                        content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + html + "</div>",
                        html: html,
                        adjustXPos: 0,
                        adjustYPos: 0,
                        Sort: items.length,
                        True: 0,
                        CustomStyle: this.state.CustomStyle
                    }]);

                this.setState({
                    CurrentAnswer: '',
                    items: [
                        ...items,
                        {
                            id: response.data.data.Id,
                            content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + html + "</div>",
                            html: html,
                            adjustXPos: 0,
                            adjustYPos: 0,
                            Sort: items.length,
                            True: 0,
                            CustomStyle: this.state.CustomStyle
                        }]
                });

                this.CleanItem();
            } else {
                toast.update(toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.error.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.error.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    CleanItem = () => {
        if (this.props.RedactorId) {
            window.cleanRedactor(`#${this.props.RedactorId}`);
        }
    }
    EditItem = item => {
        this.setState({
            EditItem: item,
            CurrentAnswer: item.html,
            show: true
        });
        if (this.props.RedactorId) {
            setTimeout(
                () => {
                    window.createRedactor(`#Edit${this.props.RedactorId}`, item.Name);
                },
                500
            );
        }
    }
    DeleteItem = value => {
        this.props.showDelete(value);
        if (this.props.typeId === 9 && this.props.RedactorId) {
            setTimeout(
                () => {
                    window.createRedactor(`#${this.props.RedactorId}`);
                },
                250
            )
        }
    }

    showPaint = () => {
        this.setState({
            showPaint: !this.state.showPaint
        });
    }

    handleClose = () => {
        this.setState({
            show: false,
            showEditStyle: false
        });
    }
    playSound = (id, index) => {
        const CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Sound.filter(p => p.Id === id && p.Sort === index)[0];
        if (hasItem) {
            if (this.audio && !this.audio.paused) {
                this.audio.pause();
            }
            if (hasItem.Url) {
                this.audio = new Audio(hasItem.Url);
                this.audio.play();
            }
        }
    }

    handleSubmitQuestionEditItems = () => {
        const { t, toast } = this.props;

        var html = this.state.CurrentAnswer;
        if (this.props.RedactorId) {
            html = window.getRedactor(`#Edit${this.props.RedactorId}`);
        }

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (!html) {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.FillNoAnswer.title')}</div> </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        var items = [];

        var itemList = this.props.items;
        if (
            (this.props.typeId === 19 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 20 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 23 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 24 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 25 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 26 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 27 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 28 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 29 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 30 && this.props.isSubAnswer === true) ||
            (this.props.typeId === 31 && this.props.isSubAnswer === true)) {
            itemList = this.props.answers;
        }

        itemList.sort((a, b) => a.Sort - b.Sort).forEach(item => {
            if (item.Id === this.state.EditItem.id) {
                items.push({
                    id: item.Id,
                    content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + html + "</div>",
                    html: html,
                    adjustXPos: 0,
                    adjustYPos: 0,
                    CustomStyle: item.CustomStyle ? item.CustomStyle : this.state.CustomStyle
                });
            } else {
                items.push({
                    id: item.Id,
                    content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + item.Name + "</div>",
                    html: item.Name,
                    adjustXPos: 0,
                    adjustYPos: 0,
                    CustomStyle: item.CustomStyle ? item.CustomStyle : this.state.CustomStyle
                });
            }
        });

        var postData = {
            Name: html,
            QuestionId: this.props.QuestionId
        };

        api.put(
            `${this.props.API}` + this.state.EditItem.id,
            postData
        ).then(response => {

            this.props.updateQuestionInfo([...items]);
            this.setState({
                show: false,
                CurrentAnswer: '',
                items: [...items]
            });

            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    EditStyle = item => {
        this.setState({
            EditStyle: item,
            showEditStyle: true,
            CustomStyle: item.CustomStyle
        });
    }

    handleSoundChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Sound.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Sound.push({
                Id: id,
                Sort: index,
                Url: e.target.value
            });
        } else {
            hasItem.Url = e.target.value;
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswerChangeIsTrue = (checked, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Width: 250,
                Percentage: 100,
                HamzaIsChecked: false,
                IsChecked: checked,
                Placeholder: '',
                AnswerClass: "dark",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1
            });
        } else {
            hasItem.IsChecked = checked;
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswerChangeHamzaIsChecked = (checked, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Width: 250,
                Percentage: 100,
                Placeholder: '',
                HamzaIsChecked: checked,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.HamzaIsChecked = checked;
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswerClassChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Width: 250,
                Placeholder: '',
                Percentage: 100,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: e.target.value
            });
        } else {
            hasItem.AnswerClass = e.target.value;
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswerPlaceholderChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: e.target.value.trim(),
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.Placeholder = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswerChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: e.target.value.trim(),
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.Answer = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handlePercentageChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        let Percentage = 100;
        if (e.target.value <= 100 && e.target.value >= 50) {
            Percentage = parseInt(e.target.value);
        }
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Width: 250,
                Percentage: Percentage,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark",
                movments: []
            });
        } else {
            hasItem.Percentage = Percentage;
        }
        this.setState({
            CustomStyle
        });
    }
    handleWidthChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        let Width = 250;
        if (e.target.value <= 500 && e.target.value >= 50) {
            Width = parseInt(e.target.value);
        }
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Width: Width,
                Placeholder: '',
                Percentage: 100,
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.Width = Width;
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswersCols = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        let AnswersCols = 1;
        if (e.target.value <= 6 && e.target.value >= 1) {
            AnswersCols = parseInt(e.target.value);
        }
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: AnswersCols,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersCols = AnswersCols;
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswerOrder = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersSelectType: "radio",
                AnswerOrder: e.target.value.trim(),
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswerOrder = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswersSelectType = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersTime: 3,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersSelectObject: "",
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectType: e.target.value.trim(),
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersSelectType = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    handleTypeChange = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: e.target.value.trim(),
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersTime: 3,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersSelectObject: "",
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.type = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    handleSubmitQuestionEditStyleItems = () => {
        const { t, toast } = this.props;

        var items = [];

        if (
            (
                this.props.typeId === 19 ||
                this.props.typeId === 20 ||
                this.props.typeId === 23 ||
                this.props.typeId === 24 ||
                this.props.typeId === 25 ||
                this.props.typeId === 26 ||
                this.props.typeId === 27 ||
                this.props.typeId === 28 ||
                this.props.typeId === 29 ||
                this.props.typeId === 30 ||
                this.props.typeId === 31
            ) && this.props.API === "/QuestionAnswers/") {
            this.props.question.question.Answers.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                items.push({
                    id: item.Id,
                    content: `<div class='d-inline-flex'><div class="align-self-cente"><i class='icon fa fa-arrows-alt'></i></div> ` + item.Name + "</div>",
                    html: item.Name,
                    adjustXPos: 0,
                    adjustYPos: 0,
                    CustomStyle: item.CustomStyle ? item.CustomStyle : this.state.CustomStyle
                });
            });
        } else {
            items = this.state.items;
        }

        const newList = items.map((item) => {
            if (item.id === this.state.EditStyle.id) {
                const updatedItem = {
                    ...item,
                    CustomStyle: this.state.CustomStyle
                };
                return updatedItem;
            }
            return item;
        });

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.typeId === 4 || this.props.typeId === 5 || this.props.typeId === 6 || this.props.typeId === 32) {
            if (this.state.EditStyle && this.state.EditStyle.html) {
                var count = (this.state.EditStyle.html.match(/\(_\)/g) || []).length;
                var id = this.state.EditStyle.id;
                let noEmptyAnswer = true;
                for (let index = 0; index < count && noEmptyAnswer; index++) {
                    if (this.state.CustomStyle.Answers) {
                        var hasItem = this.state.CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
                        if (hasItem && hasItem.Answer !== '') {
                        } else {
                            noEmptyAnswer = false;
                        }
                    } else {
                        noEmptyAnswer = false;
                    }
                }
                if (!noEmptyAnswer) {
                    toast.update(toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.FillNoAnswer.title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.FillNoAnswer.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return;
                }
            }
        }

        var postData = {
            CustomStyle: this.state.CustomStyle,
            QuestionId: this.props.QuestionId
        };

        api.put(
            `${this.props.API}` + this.state.EditStyle.id + `?QuestionStyle=${true}`,
            postData
        ).then(response => {

            this.props.updateQuestionInfo(newList);
            this.setState({
                showEditStyle: false,
                items: newList
            });

            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    setItemOption = (index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersTime: 3 + index,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectType: "radio",
                AnswersSelectObject: "",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
            this.setState({
                CustomStyle
            });
        }
    }

    handleAnswersSelectObject = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersTime: 3,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectType: "radio",
                AnswersSelectObject: e.trim(),
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersSelectObject = e.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswersSelectObjectSrcHover = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                AnswersTime: 3,
                CustomColor: ``,
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: e.target.value.trim(),
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersSelectObjectSrcHover = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    handleAnswersSelectObjectSrc = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                AnswersTime: 3,
                CustomColor: ``,
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersSelectObjectSrc: e.target.value.trim(),
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersSelectObjectSrc = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    handleAnswersMainRotate = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: e.target.value.trim(),
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.rotate = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswersSelectIsSpin = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: e.target.value.trim(),
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.isSpin = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleTransformOriginX = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                flipX: false,
                isSpin: false,
                transformOriginX: e.target.value.trim(),
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.transformOriginX = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleTransformOriginY = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                flipX: false,
                isSpin: false,
                transformOriginY: e.target.value.trim(),
                transformOriginX: "center",
                type: "select",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.transformOriginY = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswersFlipX = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                flipX: e.target.value.trim(),
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.flipX = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleScale = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                flipX: false,
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                scale: e.target.value.trim(),
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.scale = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    handleAnswersTime = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: e.target.value.trim(),
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersTime = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleAnswersMoveType = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersMoveType: e.target.value.trim(),
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.AnswersMoveType = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleBounsTime = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                BounsTime: e.target.value.trim(),
                BounsTry: 0,
                delay: 0,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.BounsTime = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleDelay = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                BounsTry: 0,
                BounsTime: 0,
                delay: e.target.value.trim(),
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.delay = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleBounsTry = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                BounsTry: e.target.value.trim(),
                BounsTime: 0,
                delay: 0,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.BounsTry = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleCursor = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: e.target.value.trim(),
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.Cursor = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleCursorCustom = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: e.target.value.trim(),
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.CursorCustom = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleClickCursor = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: e.target.value.trim(),
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.ClickCursor = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    handleClickCursorCustom = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: e.target.value.trim(),
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.ClickCursorCustom = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    setTrueSound = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: e.target.value.trim(),
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.TrueSound = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    setCustomTrueSound = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: e.target.value.trim(),
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.CustomTrueSound = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    setFalseSound = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                FalseSound: e.target.value.trim(),
                CustomTrueSound: "",
                TrueSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.FalseSound = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }
    setCustomFalseSound = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                BounsTime: 0,
                BounsTry: 0,
                delay: 0,
                AnswersTime: 0,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: e.target.value.trim(),
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.CustomFalseSound = e.target.value.trim();
        }
        this.setState({
            CustomStyle
        });
    }

    handleAddAnswer = (e, index, id) => {
        this.setState({
            showSubAnswer: true,
            FillIndex: index
        });
    }
    handleCloseSubAnswer = () => {
        this.setState({
            showSubAnswer: false,
            FillIndex: 0
        });
    }
    changeCurrentAnswer = e => {
        this.setState({
            CurrentAnswer: e.target.value
        });
    }

    setDrawMovment = (lines, index, id) => {
        let CustomStyle = this.state.CustomStyle;
        CustomStyle.Movments = [];

        const hasSetting = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        var transformOriginX = "right";
        var transformOriginY = "top";
        if (hasSetting) {
            transformOriginX = hasSetting.transformOriginX;
            transformOriginY = hasSetting.transformOriginY;
        }

        for (let i = 0; i < lines.length && lines.length > 1; i++) {
            const element = lines[i];
            const subIndx = Math.round(i / (lines.length - 1) * 100);//.toFixed(2);

            var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === subIndx)[0];
            if (!hasItem) {
                CustomStyle.Movments.push({
                    Id: id,
                    Sort: index,
                    Index: subIndx,
                    AnswersOpacity: 100,
                    AnswersScale: 1,
                    AnswersTranslateY: element.y,
                    AnswersTranslatePositionY: transformOriginY ? transformOriginY : "top",
                    AnswersTranslatePositionX: transformOriginX ? transformOriginX : "right",
                    AnswersTranslateX: element.x,
                    AnswersRotate: 0,//element.rotate,
                    AnswersTransformOrigin: "center"
                });
            }
        }
        this.setState({
            CustomStyle
        });
    }

    handleAnswersOpacity = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        let AnswersOpacity = 100;
        if (e.target.value <= 100 && e.target.value >= 0) {
            AnswersOpacity = parseInt(e.target.value);
        }

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: AnswersOpacity,
                AnswersScale: 1,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: "right",
                AnswersTranslateX: 0,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersOpacity = AnswersOpacity;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersScale = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        let AnswersScale = 1;
        if (e.target.value <= 10 && e.target.value >= 0) {
            AnswersScale = parseFloat(e.target.value);
        }

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: AnswersScale,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: "right",
                AnswersTranslateX: 0,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersScale = AnswersScale;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersTranslatePositionY = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: e.target.value,
                AnswersTranslateX: 0,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersTranslatePositionY = e.target.value;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersTranslatePositionX = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: e.target.value,
                AnswersTranslateX: 0,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersTranslatePositionX = e.target.value;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersTranslateY = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersTranslateY: e.target.value,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: "right",
                AnswersTranslateX: 0,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersTranslateY = e.target.value;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersTranslateX = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: "right",
                AnswersTranslateX: e.target.value,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersTranslateX = e.target.value;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersRotate = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: "right",
                AnswersTranslateX: 0,
                AnswersRotate: e.target.value,
                AnswersTransformOrigin: "center"
            });
        } else {
            hasItem.AnswersRotate = e.target.value;
        }

        this.setState({
            CustomStyle
        });
    }
    handleAnswersTransformOrigin = (e, index, id) => {
        let CustomStyle = this.state.CustomStyle;

        var hasItem = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
        if (!hasItem) {
            CustomStyle.Movments.push({
                Id: id,
                Sort: index,
                Index: this.state.currentMovmentIndex,
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersTranslateY: 0,
                AnswersTranslatePositionY: "top",
                AnswersTranslatePositionX: "right",
                AnswersTranslateX: 0,
                AnswersRotate: 0,
                AnswersTransformOrigin: e.target.value
            });
        } else {
            hasItem.AnswersTransformOrigin = e.target.value;
        }

        this.setState({
            CustomStyle
        });
    }

    handleClickDisplayDropColorPicker = (index, id) => {
        this.setState({
            displayDropColorPicker: !this.state.displayDropColorPicker,
            displayDropColorPickerId: id,
            displayDropColorPickerIndex: index,
        });
    }
    handleCloseColorPicker = () => {
        this.setState({
            displayDropColorPicker: false
        })
    }

    ClearDropCustomColor = (index, id) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: id,
                Sort: index,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersTime: 3,
                CustomColor: ``,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                BounsTime: "",
                BounsTry: 0,
                delay: 0,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.CustomColor = ``;
        }
        this.setState({
            CustomStyle
        });
    }

    handleDropColorChange = (color) => {
        let CustomStyle = this.state.CustomStyle;
        var hasItem = CustomStyle.Answers.filter(p => p.Id === this.state.displayDropColorPickerId && p.Sort === this.state.displayDropColorPickerIndex)[0];
        if (!hasItem) {
            CustomStyle.Answers.push({
                Id: this.state.displayDropColorPickerId,
                Sort: this.state.displayDropColorPickerIndex,
                Answer: '',
                Percentage: 100,
                Width: 250,
                Placeholder: '',
                HamzaIsChecked: false,
                IsChecked: true,
                AnswersTime: 3,
                AnswersSelectObjectSrc: "",
                AnswersSelectObjectSrcHover: "",
                Cursor: "none",
                CursorCustom: "",
                ClickCursor: "none",
                ClickCursorCustom: "",
                AnswersSelectObject: "",
                CustomColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                AnswersSelectType: "radio",
                AnswerOrder: "orderd",
                BounsTime: "",
                BounsTry: 0,
                delay: 0,
                AnswersMoveType: "random",
                isSpin: false,
                transformOriginX: "center",
                type: "select",
                transformOriginY: "center",
                TrueSound: "",
                CustomTrueSound: "",
                FalseSound: "",
                CustomFalseSound: "",
                flipX: false,
                scale: 1,
                rotate: 0,
                AnswersCols: 1,
                AnswerClass: "dark"
            });
        } else {
            hasItem.CustomColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        }
        this.setState({
            CustomStyle
        });
    }

    renderAnswerOption(index, id) {
        if (this.props.fills && this.props.answers) {
            return this.props.answers.filter(p => this.props.fills.some(f => f.Sort === index && f.CategoryId === id && f.AnswerId === p.Id)).map(answer => {
                return <option key={answer.Id} value={answer.Id} >{answer.Name}</option>;
            });
        }
        return "";
    }
    renderMoreSetting() {
        if (this.state.EditStyle && this.state.EditStyle.html) {
            const { t } = this.props;
            let result = [];
            let splitAnswer = "";
            let clause = "";
            var count = 0;
            if ((this.props.typeId === 12 ||
                this.props.typeId === 15 ||
                this.props.typeId === 16 ||
                this.props.typeId === 17 ||
                this.props.typeId === 18 ||
                this.props.typeId === 21 ||
                this.props.typeId === 22) && this.props.shootGame !== true) {
                count = (this.state.EditStyle.html.match(/\(*_\)/g) || []).length;
                splitAnswer = this.state.EditStyle.html.split('(*_)');
            } else {
                count = (this.state.EditStyle.html.match(/\(_\)/g) || []).length;
            }
            if (this.props.typeId === 7 ||
                this.props.typeId === 9 ||
                this.props.typeId === 14 ||
                this.props.typeId === 19 ||
                this.props.typeId === 20 ||
                this.props.typeId === 23 ||
                this.props.typeId === 24 ||
                this.props.typeId === 25 ||
                this.props.typeId === 26 ||
                this.props.typeId === 27 ||
                this.props.typeId === 28 ||
                this.props.typeId === 29 ||
                this.props.typeId === 30 ||
                this.props.typeId === 31 ||
                (this.props.typeId === 16 && this.props.shootGame === true) ||
                (this.props.typeId === 15 && this.props.isCategoryGame === true) ||
                (this.props.typeId === 22 && this.props.isCategoryGame === true)
            ) {
                count = 1;
            }

            const styles = reactCSS({
                'default': {
                    color: {
                        width: '36px',
                        height: '14px',
                        borderRadius: '2px',
                    },
                    swatch: {
                        padding: '5px',
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                    },
                    popover: {
                        position: 'absolute',
                        zIndex: '12',
                    },
                    cover: {
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                    },
                },
            });

            var id = this.state.EditStyle.id;

            const CustomStyle = this.state.CustomStyle;
            for (let index = 0; index < count; index++) {

                if (this.props.typeId === 15 ||
                    this.props.typeId === 16 ||
                    this.props.typeId === 17 ||
                    this.props.typeId === 18 ||
                    this.props.typeId === 19 ||
                    this.props.typeId === 20 ||
                    this.props.typeId === 21 ||
                    this.props.typeId === 22 ||
                    this.props.typeId === 23 ||
                    this.props.typeId === 24 ||
                    this.props.typeId === 25 ||
                    this.props.typeId === 26 ||
                    this.props.typeId === 27 ||
                    this.props.typeId === 28 ||
                    this.props.typeId === 29 ||
                    this.props.typeId === 30 ||
                    this.props.typeId === 31) {
                    this.setItemOption(index, id);
                }

                let url = "",
                    Answer = "",
                    AnswersSelectType = (this.props.typeId === 15 || this.props.typeId === 16 || this.props.typeId === 22) ? "0" : "radio",
                    AnswersSelectObject = "",
                    AnswersSelectObjectSrc = "",
                    AnswersSelectObjectSrcHover = "",
                    CustomColor = "",
                    AnswersTranslateY = 0,
                    AnswersTranslatePositionX = "right",
                    AnswersTranslatePositionY = "top",
                    AnswersTranslateX = 0,
                    BounsTime = 0,
                    BounsTry = 0,
                    AnswersTransformOrigin = "center",
                    AnswersRotate = 0,
                    AnswersScale = 1,
                    AnswersTime = 3,
                    AnswersOpacity = 100,
                    AnswerOrder = "orderd",
                    AnswersMoveType = "random",
                    AnswersCols = 1,
                    Placeholder = "",
                    Percentage = 100,
                    IsChecked = true,
                    AnswerClass = "dark",
                    Cursor = "none",
                    CursorCustom = "",
                    ClickCursor = "none",
                    ClickCursorCustom = "",
                    TrueSound = "",
                    CustomTrueSound = "",
                    FalseSound = "",
                    CustomFalseSound = "",
                    isSpin = false,
                    rotate = 0,
                    flipX = false,
                    delay = 0,
                    scale = 1,
                    Width = 250,
                    type = "select",
                    transformOriginX = "center",
                    transformOriginY = "center",
                    HamzaIsChecked = false;

                if (CustomStyle.Sound) {
                    const hasItem = CustomStyle.Sound.filter(p => p.Id === id && p.Sort === index)[0];
                    if (hasItem) {
                        url = hasItem.Url;
                    }
                }

                if (CustomStyle.Answers) {
                    const hasAnswer = CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
                    if (hasAnswer) {
                        Answer = hasAnswer.Answer;
                        transformOriginX = hasAnswer.transformOriginX;
                        transformOriginY = hasAnswer.transformOriginY;
                        Percentage = hasAnswer.Percentage;
                        IsChecked = hasAnswer.IsChecked;
                        AnswerClass = hasAnswer.AnswerClass;
                        Width = hasAnswer.Width;
                        type = hasAnswer.type;
                        Placeholder = hasAnswer.Placeholder;
                        HamzaIsChecked = hasAnswer.HamzaIsChecked;
                        AnswersSelectType = hasAnswer.AnswersSelectType;
                        AnswersCols = hasAnswer.AnswersCols;
                        isSpin = hasAnswer.isSpin;
                        rotate = hasAnswer.rotate;
                        flipX = hasAnswer.flipX;
                        delay = hasAnswer.delay;
                        scale = hasAnswer.scale;
                        AnswerOrder = hasAnswer.AnswerOrder;
                        AnswersSelectObject = hasAnswer.AnswersSelectObject;
                        AnswersSelectObjectSrc = hasAnswer.AnswersSelectObjectSrc;
                        AnswersSelectObjectSrcHover = hasAnswer.AnswersSelectObjectSrcHover;
                        AnswersTime = hasAnswer.AnswersTime;
                        AnswersMoveType = hasAnswer.AnswersMoveType;
                        BounsTime = hasAnswer.BounsTime;
                        BounsTry = hasAnswer.BounsTry;
                        CustomColor = hasAnswer.CustomColor;
                        Cursor = hasAnswer.Cursor;
                        CursorCustom = hasAnswer.CursorCustom;
                        ClickCursor = hasAnswer.ClickCursor;
                        ClickCursorCustom = hasAnswer.ClickCursorCustom;
                        TrueSound = hasAnswer.TrueSound;
                        CustomTrueSound = hasAnswer.CustomTrueSound;
                        FalseSound = hasAnswer.FalseSound;
                        CustomFalseSound = hasAnswer.CustomFalseSound;
                    }
                }

                if (CustomStyle.Movments) {
                    const hasMovment = CustomStyle.Movments.filter(p => p.Id === id && p.Sort === index && p.Index === this.state.currentMovmentIndex)[0];
                    if (hasMovment) {
                        AnswersTranslatePositionX = hasMovment.AnswersTranslatePositionX;
                        AnswersTranslateY = hasMovment.AnswersTranslateY;
                        AnswersTranslatePositionY = hasMovment.AnswersTranslatePositionY ? hasMovment.AnswersTranslatePositionY : "top";
                        AnswersTranslateX = hasMovment.AnswersTranslateX;
                        AnswersTransformOrigin = hasMovment.AnswersTransformOrigin;
                        AnswersRotate = hasMovment.AnswersRotate;
                        AnswersScale = hasMovment.AnswersScale;
                        AnswersOpacity = hasMovment.AnswersOpacity;
                    }
                }

                if (
                    (
                        this.props.typeId === 12 ||
                        this.props.typeId === 15 ||
                        this.props.typeId === 16 ||
                        this.props.typeId === 17 ||
                        this.props.typeId === 18 ||
                        this.props.typeId === 21 ||
                        this.props.typeId === 22
                    )
                    && this.props.shootGame !== true
                    && this.props.isCategoryGame !== true) {
                    clause = splitAnswer[index + 1] ? splitAnswer[index + 1].split('(_*)')[0] : ""
                }

                let category = 1;
                var item = this.Objects.filter(p => p.value === AnswersSelectObject)[0];
                if (item) {
                    category = item.category;
                }

                result.push(
                    <div className="w-100" key={index}>
                        <div className="w-100">
                            <div className="form-group w-100 text-center">
                                <label className="text-dark h6" htmlFor={`${id}-${index}-sound`} >
                                    <i className="icon fal fa-keyboard"></i>
                                    {
                                        (
                                            this.props.typeId === 7 ||
                                            this.props.typeId === 14 ||
                                            this.props.typeId === 19 ||
                                            this.props.typeId === 20 ||
                                            this.props.typeId === 23 ||
                                            this.props.typeId === 24 ||
                                            this.props.typeId === 25 ||
                                            this.props.typeId === 26 ||
                                            this.props.typeId === 27 ||
                                            this.props.typeId === 28 ||
                                            this.props.typeId === 29 ||
                                            this.props.typeId === 30 ||
                                            this.props.typeId === 31
                                        ) ?
                                            <>
                                                {t('Pages.Lessons.Questions.forms.AnswerOptions')}
                                            </>
                                            :
                                            (this.props.typeId === 9) ?
                                                <>
                                                    {t('Pages.Lessons.Questions.forms.ColumnOptions')}
                                                </>
                                                :
                                                (this.props.typeId === 16 && this.props.shootGame === true) ||
                                                    (this.props.typeId === 15 && this.props.isCategoryGame === true) ||
                                                    (this.props.typeId === 22 && this.props.isCategoryGame === true) ?
                                                    <>
                                                        {t('Pages.Lessons.Questions.forms.CustomOptions')}
                                                    </>
                                                    :
                                                    (
                                                        this.props.typeId === 12 ||
                                                        this.props.typeId === 15 ||
                                                        this.props.typeId === 16 ||
                                                        this.props.typeId === 17 ||
                                                        this.props.typeId === 18 ||
                                                        this.props.typeId === 21 ||
                                                        this.props.typeId === 22
                                                    ) && this.props.shootGame !== true ?
                                                        <>
                                                            {t('Pages.Lessons.Questions.forms.Clauseptions')} {index + 1}
                                                            <div className="text-danger" dangerouslySetInnerHTML={{ __html: clause }}></div>
                                                        </>
                                                        :
                                                        <>
                                                            {t('Pages.Lessons.Questions.forms.FillOptions')} {index + 1}
                                                        </>
                                    }
                                </label>
                            </div>
                            <div className=" row row-cols-4">
                                {
                                    (this.props.typeId === 32) ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6 " htmlFor={`${id}-${index}-text`}><i className="fal fa-text"></i> {t('Pages.Lessons.Questions.forms.FillType.title')}</label>
                                                <div className="search-form ">
                                                    <select
                                                        onChange={e => this.handleTypeChange(e, index, id)}
                                                        className="w-100"
                                                        id={`${id}-${index}-text`}
                                                        name={`${id}-${index}-text`}
                                                        defaultValue={type}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillType.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillType.title')}>
                                                        <option value="select">{t('Pages.Lessons.Questions.forms.FillType.select')}</option>
                                                        <option value="text">{t('Pages.Lessons.Questions.forms.FillType.text')}</option>
                                                        <option value="choose">{t('Pages.Lessons.Questions.forms.FillType.choose')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 7) ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswerClassChange(e, index, id)}
                                                        className="w-100"
                                                        defaultValue={AnswerClass}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.title')}>
                                                        <option value="primary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.primary')}</option>
                                                        <option value="success">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.success')}</option>
                                                        <option value="warning">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.warning')}</option>
                                                        <option value="info">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.info')}</option>
                                                        <option value="danger">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.danger')}</option>
                                                        <option value="dark">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.dark')}</option>
                                                        <option value="secondary">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectClass.secondary')}</option>
                                                    </select>
                                                    <button type="button" className={`text-${AnswerClass}`}>
                                                        <i className="fas fa-tint"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="icon fas fa-check-square"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.title')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectType(e, index, id)}
                                                        className="w-100"
                                                        value={AnswersSelectType}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.title')}>
                                                        <option value="radio">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.radio')}</option>
                                                        <option value="checkbox">{t('Pages.Lessons.Questions.forms.tools.AnswersSelectType.checkbox')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-check-square"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="icon fas fa-sort-amount-down"></i> {t('Pages.Lessons.Questions.forms.tools.AnswerOrder.title')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswerOrder(e, index, id)}
                                                        className="w-100"
                                                        value={AnswerOrder}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerOrder.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswerOrder.title')}>
                                                        <option value="orderd">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.orderd')}</option>
                                                        <option value="notorderd">{t('Pages.Lessons.Questions.forms.tools.AnswerOrder.notorderd')}</option>
                                                    </select>
                                                    <button type="button">
                                                        <i className="fas fa-sort-amount-down"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="icon fas fa-line-columns"></i> {t('Pages.Lessons.Questions.forms.tools.AnswersCols')}</label>
                                                <div className="search-form">
                                                    <input
                                                        type="text"
                                                        className="w-100"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswersCols')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswersCols')}
                                                        value={AnswersCols}
                                                        onChange={e => this.handleAnswersCols(e, index, id)} />
                                                    <button type="button">
                                                        <i className="fas fa-line-columns"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="w-100 d-block" style={{
                                                maxWidth: '50%',
                                                margin: '0 auto'
                                            }}>
                                                <button type="button" className="btn btn-info" title={t('Pages.Lessons.Questions.forms.FillAnswer.add')} onClick={e => this.handleAddAnswer(e, index + 1, id)}>
                                                    <i className="icon fas fa-plus"  ></i>
                                                    {t('Pages.Lessons.Questions.forms.FillAnswer.add')}
                                                </button>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 3 || this.props.typeId === 4 || this.props.typeId === 5 || this.props.typeId === 6 || this.props.typeId === 32) ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6" htmlFor={`${id}-${index}-sound`}><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.FillSound.title')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="url"
                                                        defaultValue={url}
                                                        id={`${id}-${index}-sound`}
                                                        name={`${id}-${index}-sound`}
                                                        onChange={e => this.handleSoundChange(e, index, id)}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillSound.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillSound.title')} />
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={e => this.playSound(id, index)}>
                                                        <i className="fas fa-volume"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </> : ""
                                }
                                {
                                    (this.props.typeId === 4 || type === "text") ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6 " htmlFor={`${id}-${index}-text`}><i className="fal fa-text"></i> {t('Pages.Lessons.Questions.forms.FillAnswer.title')}</label>
                                                <div className="search-form ">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        defaultValue={Answer}
                                                        id={`${id}-${index}-text`}
                                                        name={`${id}-${index}-text`}
                                                        onChange={e => this.handleAnswerChange(e, index, id)}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillAnswer.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillAnswer.title')} />
                                                    <button type="button">
                                                        <i className="fas fa-text"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col">
                                                <label className="text-dark h6 " htmlFor={`${id}-${index}-percentage`}><i className="fal fa-percentage"></i> {t('Pages.Lessons.Questions.forms.FillAnswerRate.title')}</label>
                                                <div className="search-form ">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        defaultValue={Percentage}
                                                        id={`${id}-${index}-percentage`}
                                                        name={`${id}-${index}-percentage`}
                                                        onChange={e => this.handlePercentageChange(e, index, id)}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillAnswerRate.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillAnswerRate.title')} />
                                                    <button type="button">
                                                        <i className="fas fa-percentage"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col">
                                                <div className={`custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-danger`}>
                                                    <input
                                                        defaultChecked={IsChecked}
                                                        type="checkbox"
                                                        onChange={e => this.handleAnswerChangeIsTrue(e.target.checked, index, id)}
                                                        className="custom-control-input"
                                                        name={`${id}-${index}-checkboxAnswer`}
                                                        id={`${id}-${index}-checkboxAnswer`} />
                                                    <label className="custom-control-label" title={t('Pages.Lessons.Questions.forms.FillAnswerCheck.placeholder')} htmlFor={`${id}-${index}-checkboxAnswer`} >
                                                        {t('Pages.Lessons.Questions.forms.FillAnswerCheck.title')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group col">
                                                <div className={`custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-danger`}>
                                                    <input
                                                        defaultChecked={HamzaIsChecked}
                                                        type="checkbox"
                                                        onChange={e => this.handleAnswerChangeHamzaIsChecked(e.target.checked, index, id)}
                                                        className="custom-control-input"
                                                        name={`${id}-${index}-checkboxHamzaIsChecked`}
                                                        id={`${id}-${index}-checkboxHamzaIsChecked`} />
                                                    <label className="custom-control-label" title={t('Pages.Lessons.Questions.forms.FillAnswerHamzaIsChecked.placeholder')} htmlFor={`${id}-${index}-checkboxHamzaIsChecked`} >
                                                        {t('Pages.Lessons.Questions.forms.FillAnswerHamzaIsChecked.title')}
                                                    </label>
                                                </div>
                                            </div>

                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 5 || this.props.typeId === 6 || type !== "text") && this.props.typeId !== 4 ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6 " htmlFor={`${id}-${index}-text`}><i className="fal fa-text"></i> {t('Pages.Lessons.Questions.forms.FillAnswer.title')}</label>
                                                <div className="search-form ">
                                                    <select
                                                        onChange={e => this.handleAnswerChange(e, index, id)}
                                                        className="w-100"
                                                        id={`${id}-${index}-text`}
                                                        name={`${id}-${index}-text`}
                                                        defaultValue={Answer}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillAnswer.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillAnswer.title')}>
                                                        <option disabled value=""></option>
                                                        {this.renderAnswerOption(index + 1, id)}
                                                    </select>
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.FillAnswer.add')} onClick={e => this.handleAddAnswer(e, index + 1, id)}>
                                                        <i className="fas fa-plus"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 4 || this.props.typeId === 5 || this.props.typeId === 6 || this.props.typeId === 32) ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6 " htmlFor={`${id}-${index}-text`}><i className="fal fa-candle-holder"></i> {t('Pages.Lessons.Questions.forms.FillAnswerPlaceholder.title')}</label>
                                                <div className="search-form ">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        defaultValue={Placeholder}
                                                        id={`${id}-${index}-Placeholder`}
                                                        name={`${id}-${index}-Placeholder`}
                                                        onChange={e => this.handleAnswerPlaceholderChange(e, index, id)}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillAnswerPlaceholder.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillAnswerPlaceholder.title')} />
                                                    <button type="button">
                                                        <i className="fas fa-candle-holder"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.FillAnswerClass.title')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswerClassChange(e, index, id)}
                                                        className="w-100"
                                                        defaultValue={AnswerClass}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillAnswerClass.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillAnswerClass.title')}>
                                                        <option value="primary">{t('Pages.Lessons.Questions.forms.FillAnswerClass.primary')}</option>
                                                        <option value="success">{t('Pages.Lessons.Questions.forms.FillAnswerClass.success')}</option>
                                                        <option value="warning">{t('Pages.Lessons.Questions.forms.FillAnswerClass.warning')}</option>
                                                        <option value="info">{t('Pages.Lessons.Questions.forms.FillAnswerClass.info')}</option>
                                                        <option value="danger">{t('Pages.Lessons.Questions.forms.FillAnswerClass.danger')}</option>
                                                        <option value="dark">{t('Pages.Lessons.Questions.forms.FillAnswerClass.dark')}</option>
                                                        <option value="secondary">{t('Pages.Lessons.Questions.forms.FillAnswerClass.secondary')}</option>
                                                    </select>
                                                    <button type="button" className={`text-${AnswerClass}`}>
                                                        <i className="fas fa-tint"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col">
                                                <label className="text-dark h6 " htmlFor={`${id}-${index}-width`}><i className="icon fas fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.width')}</label>
                                                <div className="search-form ">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        defaultValue={Width}
                                                        id={`${id}-${index}-width`}
                                                        name={`${id}-${index}-width`}
                                                        onChange={e => this.handleWidthChange(e, index, id)}
                                                        placeholder={t('Pages.Lessons.Questions.forms.FillAnswerRate.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.FillAnswerRate.title')} />
                                                    <button type="button">
                                                        <i className="fas fa-width"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 9) ?
                                        <>
                                            <div className="w-100 d-block" style={{
                                                maxWidth: '50%',
                                                margin: '0 auto'
                                            }}>
                                                <button type="button" className="btn btn-info" title={t('Pages.Lessons.Questions.forms.FillAnswer.add')} onClick={e => this.handleAddAnswer(e, index + 1, id)}>
                                                    <i className="icon fas fa-plus"  ></i>
                                                    {t('Pages.Lessons.Questions.forms.FillAnswer.add')}
                                                </button>
                                            </div>
                                        </> : ""
                                }
                                {
                                    (this.props.typeId === 12) ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-mouse"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.ClickType.title')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectType(e, index, id)}
                                                        className="w-100"
                                                        value={AnswersSelectType}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.ClickType.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.ClickType.title')}>
                                                        <option value="none">{t('Pages.Lessons.Questions.forms.tools.ClickType.none')}</option>
                                                        <option value="click">{t('Pages.Lessons.Questions.forms.tools.ClickType.click')}</option>
                                                        <option value="dbclick">{t('Pages.Lessons.Questions.forms.tools.ClickType.dbclick')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-mouse"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 14) ?
                                        <>
                                            <div className="w-100 d-block" style={{
                                                maxWidth: '50%',
                                                margin: '0 auto'
                                            }}>
                                                <button type="button" className="btn btn-info" title={t('Pages.Lessons.Questions.forms.FillAnswer.add')} onClick={e => this.handleAddAnswer(e, index + 1, id)}>
                                                    <i className="icon fas fa-plus"  ></i>
                                                    {t('Pages.Lessons.Questions.forms.FillAnswer.add')}
                                                </button>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    ((
                                        this.props.typeId === 15 ||
                                        this.props.typeId === 16 ||
                                        this.props.typeId === 17 ||
                                        this.props.typeId === 18 ||
                                        this.props.typeId === 21 ||
                                        this.props.typeId === 22 ||
                                        (this.props.typeId === 19 && this.props.isCategory === false) ||
                                        (this.props.typeId === 20 && this.props.isCategory === false) ||
                                        (this.props.typeId === 23 && this.props.isCategory === false) ||
                                        (this.props.typeId === 24 && this.props.isCategory === false) ||
                                        (this.props.typeId === 25 && this.props.isCategory === false) ||
                                        (this.props.typeId === 26 && this.props.isCategory === false) ||
                                        (this.props.typeId === 27 && this.props.isCategory === false) ||
                                        (this.props.typeId === 28 && this.props.isCategory === false) ||
                                        (this.props.typeId === 29 && this.props.isCategory === false) ||
                                        (this.props.typeId === 30 && this.props.isCategory === false) ||
                                        (this.props.typeId === 31 && this.props.isCategory === false)
                                    ) && this.props.shootGame !== true && this.props.isCategoryGame !== true) ?
                                        <>

                                            {
                                                this.props.typeId === 20 ? "" :
                                                    <div className="form-group col">
                                                        <label className="text-dark h6">
                                                            <i className="icon fas fa-mouse"></i>
                                                            {t('Pages.Lessons.Questions.forms.tools.ChooseType.title')}
                                                        </label>
                                                        <div className="search-form">
                                                            <select
                                                                onChange={e => this.handleAnswersSelectType(e, index, id)}
                                                                className="w-100"
                                                                value={AnswersSelectType}
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.ChooseType.placeholder')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.ChooseType.title')}>
                                                                <option value="0">{t('Pages.Lessons.Questions.forms.tools.ChooseType.none')}</option>
                                                                {
                                                                    this.props.categories ? this.props.categories.map(item => {
                                                                        return <option key={item.Id} value={item.Id} dangerouslySetInnerHTML={{ __html: item.Name }}></option>
                                                                    }) : ""
                                                                }
                                                                {
                                                                    this.props.typeId === 17 ||
                                                                        this.props.typeId === 18 ||
                                                                        this.props.typeId === 19 ||
                                                                        this.props.typeId === 21 ||
                                                                        this.props.typeId === 23 ||
                                                                        this.props.typeId === 24 ||
                                                                        this.props.typeId === 25 ||
                                                                        this.props.typeId === 26 ||
                                                                        this.props.typeId === 27 ||
                                                                        this.props.typeId === 28 ||
                                                                        this.props.typeId === 29 ||
                                                                        this.props.typeId === 30 ||
                                                                        this.props.typeId === 31 ?
                                                                        <option value="1">{t('Pages.Lessons.Questions.forms.tools.ChooseType.true')}</option>
                                                                        : ""
                                                                }
                                                            </select>
                                                            <button type="button" >
                                                                <i className="fas fa-mouse"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                            }

                                            <div className="form-group col">
                                                <label className="text-dark h6" htmlFor={`${id}-${index}-sound`}><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.objects.sound.title')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="url"
                                                        defaultValue={url}
                                                        id={`${id}-${index}-sound`}
                                                        name={`${id}-${index}-sound`}
                                                        onChange={e => this.handleSoundChange(e, index, id)}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.sound.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.sound.title')} />
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={e => this.playSound(id, index)}>
                                                        <i className="fas fa-volume"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-box-ballot"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.objects.ObjectCategory')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.setGameObjectCategory(e.target.value, index, id)}
                                                        className="w-100"
                                                        value={category ? category : 'none'}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                        {this.renderObjectCategory()}
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-box-ballot"></i>
                                                    </button>
                                                </div>
                                            </div>


                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-ufo"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.AnswerHolder.title')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectObject(e.target.value, index, id)}
                                                        className="w-100"
                                                        value={AnswersSelectObject}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerHolder.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswerHolder.title')}>
                                                        <option value="">{t('Pages.Lessons.Questions.forms.tools.AnswerHolder.default')}</option>
                                                        {this.renderObject(category)}
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-ufo"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                AnswersSelectObject === "custom" ?
                                                    <>
                                                        <div className="form-group col">
                                                            <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                    value={AnswersSelectObjectSrc}
                                                                    onChange={e => this.handleAnswersSelectObjectSrc(e, index, id)}
                                                                />
                                                                <button type="button" >
                                                                    <i className="fas fa-image"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group col">
                                                            <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                    value={AnswersSelectObjectSrcHover}
                                                                    onChange={e => this.handleAnswersSelectObjectSrcHover(e, index, id)}
                                                                />
                                                                <button type="button" >
                                                                    <i className="fas fa-image"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="form-group col " >
                                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                        <div className="search-form">
                                                            <input
                                                                className="w-100 form-control"
                                                                type="text"
                                                                placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                value={CustomColor}
                                                                onChange={() => this.ClearDropCustomColor(index, id)}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={e => this.handleClickDisplayDropColorPicker(index, id)}
                                                                style={{
                                                                    color: CustomColor !== 'transparent' ? CustomColor : "",
                                                                }}>
                                                                <i className="fas fa-tint"></i>
                                                            </button>
                                                            {
                                                                this.state.displayDropColorPicker &&
                                                                    this.state.displayDropColorPickerId === id &&
                                                                    this.state.displayDropColorPickerIndex === index ?
                                                                    <div style={styles.popover}>
                                                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                        <SketchPicker color={CustomColor} onChange={this.handleDropColorChange} />
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                            }

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-clock"></i> {t('Pages.Lessons.Questions.forms.tools.move.Time')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        step="1"
                                                        min="1"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Time')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Time')}
                                                        value={AnswersTime}
                                                        onChange={e => this.handleAnswersTime(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-clock"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-alarm-snooze"></i> {t('Pages.Lessons.Questions.forms.tools.move.delay')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        step="1"
                                                        min="1"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.delay')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.delay')}
                                                        value={delay}
                                                        onChange={e => this.handleDelay(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-alarm-snooze"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='w-100 col-12'></div>

                                            <div className='w-100 col-12'>
                                                <div className='row  row-cols-2'>
                                                    <div className='col'>
                                                        <div className='w-100 col-12 mb-3 text-center text-info'>
                                                            {t('Pages.Lessons.Questions.forms.tools.objects.Cursor_Hover')}
                                                        </div>
                                                        <div className='row  row-cols-2'>
                                                            <div className="form-group col">
                                                                <label className="text-dark h6"><i className="icon fas fa-mouse"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Cursor')}</label>
                                                                <div className="search-form">
                                                                    <select
                                                                        onChange={e => this.handleCursor(e, index, id)}
                                                                        className="w-100"
                                                                        value={Cursor ? Cursor : 'none'}
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                                        {this.renderCursor()}
                                                                    </select>
                                                                    <button type="button" >
                                                                        <i className="fas fa-mouse"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="form-group col">
                                                                <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}</label>
                                                                <div className="search-form">
                                                                    <input
                                                                        className="w-100 form-control"
                                                                        type="text"
                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                                        value={CursorCustom ? CursorCustom : ''}
                                                                        onChange={e => this.handleCursorCustom(e, index, id)}
                                                                    />
                                                                    <button type="button" >
                                                                        <i className="fas fa-image"  ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col'>

                                                        {
                                                            this.props.typeId === 15 ?
                                                                <>
                                                                    <div className='w-100 col-12 mb-3 text-center text-info'>
                                                                        {t('Pages.Lessons.Questions.forms.tools.objects.Cursor_Click')}
                                                                    </div>
                                                                    <div className='row  row-cols-2'>

                                                                        <div className="form-group col">
                                                                            <label className="text-dark h6"><i className="icon fas fa-mouse"></i> {t('Pages.Lessons.Questions.forms.tools.objects.Cursor')}</label>
                                                                            <div className="search-form">
                                                                                <select
                                                                                    onChange={e => this.handleClickCursor(e, index, id)}
                                                                                    className="w-100"
                                                                                    value={ClickCursor ? ClickCursor : 'none'}
                                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                                                    {this.renderCursor()}
                                                                                </select>
                                                                                <button type="button" >
                                                                                    <i className="fas fa-mouse"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col">
                                                                            <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}</label>
                                                                            <div className="search-form">
                                                                                <input
                                                                                    className="w-100 form-control"
                                                                                    type="text"
                                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.CursorCustom')}
                                                                                    value={ClickCursorCustom ? ClickCursorCustom : ''}
                                                                                    onChange={e => this.handleClickCursorCustom(e, index, id)}
                                                                                />
                                                                                <button type="button" >
                                                                                    <i className="fas fa-image"  ></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='w-100 col-12'></div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-stars"></i> {t('Pages.Lessons.Questions.forms.tools.objects.BounsTry')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.BounsTry')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.BounsTry')}
                                                        value={BounsTry}
                                                        onChange={e => this.handleBounsTry(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-stars"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-alarm-plus"></i> {t('Pages.Lessons.Questions.forms.tools.objects.BounsTime')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.BounsTime')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.BounsTime')}
                                                        value={BounsTime}
                                                        onChange={e => this.handleBounsTime(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-alarm-plus"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-spin fa-spinner"></i> {t('Pages.Lessons.Questions.forms.tools.move.spin')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectIsSpin(e, index, id)}
                                                        className="w-100"
                                                        value={isSpin}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.spin')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.spin')}>
                                                        <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_spin')}</option>
                                                        <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.spin')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fal fa-spin fa-spinner"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-sync-alt"></i> {t('Pages.Lessons.Questions.forms.tools.move.Rotate')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                        value={rotate}
                                                        onChange={e => this.handleAnswersMainRotate(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-sync-alt"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-retweet"></i> {t('Pages.Lessons.Questions.forms.tools.move.flipX')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersFlipX(e, index, id)}
                                                        className="w-100"
                                                        value={flipX}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.flipX')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.flipX')}>
                                                        <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_flipX')}</option>
                                                        <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.flipX')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fal fa-retweet"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-expand-arrows"></i>{t('Pages.Lessons.Questions.forms.tools.move.Scale')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                        value={scale}
                                                        onChange={e => this.handleScale(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-expand-arrows"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-ruler-combined"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.x')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleTransformOriginX(e, index, id)}
                                                        className="w-100"
                                                        value={transformOriginX}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.x')}>
                                                        <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                        <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>
                                                        <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-ruler-combined"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-ruler-combined"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.y')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleTransformOriginY(e, index, id)}
                                                        className="w-100"
                                                        value={transformOriginY}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.y')}>
                                                        <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                        <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                                        <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-ruler-combined"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            {
                                                (
                                                    this.props.typeId === 17 ||
                                                    this.props.typeId === 21 ||
                                                    this.props.typeId === 22 ||
                                                    this.props.typeId === 23 ||
                                                    this.props.typeId === 26 ||
                                                    this.props.typeId === 27 ||
                                                    this.props.typeId === 31
                                                ) ? "" :
                                                    <>
                                                        <div className="form-group col">
                                                            <label className="text-dark h6">
                                                                <i className="icon fas fa-arrows"></i>
                                                                {t('Pages.Lessons.Questions.forms.tools.move.MoveType.title')}
                                                            </label>
                                                            <div className="search-form">
                                                                <select
                                                                    onChange={e => this.handleAnswersMoveType(e, index, id)}
                                                                    className="w-100"
                                                                    value={AnswersMoveType}
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.move.MoveType.placeholder')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.move.MoveType.title')}>
                                                                    <option value="random">{t('Pages.Lessons.Questions.forms.tools.move.MoveType.random')}</option>
                                                                    {
                                                                        this.props.typeId === 18 ?
                                                                            <option value="fall">{t('Pages.Lessons.Questions.forms.tools.move.MoveType.fallDown')}</option>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        this.props.typeId === 28 ?
                                                                            <option value="fall">{t('Pages.Lessons.Questions.forms.tools.move.MoveType.fallUp')}</option>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        (
                                                                            this.props.typeId === 15 ||
                                                                            this.props.typeId === 18 ||
                                                                            this.props.typeId === 20 ||
                                                                            this.props.typeId === 24 ||
                                                                            this.props.typeId === 25 ||
                                                                            this.props.typeId === 28 ||
                                                                            this.props.typeId === 29 ||
                                                                            this.props.typeId === 30
                                                                        ) ?
                                                                            <option value="horizontal">{t('Pages.Lessons.Questions.forms.tools.move.MoveType.horizontal')}</option>
                                                                            : ""
                                                                    }
                                                                    <option value="custom">{t('Pages.Lessons.Questions.forms.tools.move.MoveType.custom')}</option>
                                                                </select>
                                                                <button type="button" >
                                                                    <i className="fas fa-arrows"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {
                                                            AnswersMoveType === "custom" ?
                                                                <>
                                                                    <div className='w-100 col-12 text-center text-info d-flex justify-content-center align-items-center  mb-3'>
                                                                        {t('Pages.Lessons.Questions.forms.tools.objects.CustomMove')}
                                                                        <i onClick={this.showPaint} className="icon fa-light fa-paint-roller btn btn-dark mx-2" title={t('Pages.Lessons.Questions.forms.tools.objects.Paint')}></i>
                                                                    </div>
                                                                    {
                                                                        this.state.showPaint ?
                                                                            <CustomMovePaint index={index} id={id} setDrawMovment={this.setDrawMovment} />
                                                                            :
                                                                            <>
                                                                                <div className="col-12 mb-3">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div>{t('Pages.Lessons.Questions.forms.tools.move.start')}</div>
                                                                                        <div>{t('Pages.Lessons.Questions.forms.tools.move.end')}</div>
                                                                                    </div>
                                                                                    <div className="ani-timeline p-0 w-100 ">
                                                                                        {this.renderObjectMovment(id, index)}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12 mb-3 row row-cols-2">


                                                                                    <div className="form-group col mb-1">
                                                                                        <div className=" row row-cols-2">
                                                                                            <div className="form-group col">
                                                                                                <label className="text-dark h6"><i className="fal fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.move.TranslateX')}</label>
                                                                                                <div className="search-form">
                                                                                                    <input
                                                                                                        className="w-100 form-control"
                                                                                                        type="number"
                                                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.TranslateX')}
                                                                                                        title={t('Pages.Lessons.Questions.forms.tools.move.TranslateX')}
                                                                                                        value={AnswersTranslateX}
                                                                                                        onChange={e => this.handleAnswersTranslateX(e, index, id)}
                                                                                                    />
                                                                                                    <button type="button" >
                                                                                                        <i className="fas fa-arrows-h"  ></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-group col">
                                                                                                <label className="text-dark h6">
                                                                                                    <i className="icon fas fa-ruler-combined"></i>
                                                                                                    {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}
                                                                                                </label>
                                                                                                <div className="search-form">
                                                                                                    <select
                                                                                                        onChange={e => this.handleAnswersTranslatePositionX(e, index, id)}
                                                                                                        className="w-100"
                                                                                                        value={AnswersTranslatePositionX}
                                                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                                                                        title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}>
                                                                                                        <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>
                                                                                                        <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                                                                                    </select>
                                                                                                    <button type="button" >
                                                                                                        <i className="fas fa-ruler-combined"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="form-group col mb-1">
                                                                                        <div className=" row row-cols-2">
                                                                                            <div className="form-group col">
                                                                                                <label className="text-dark h6"><i className="fal fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.move.TranslateY')}</label>
                                                                                                <div className="search-form">
                                                                                                    <input
                                                                                                        className="w-100 form-control"
                                                                                                        type="number"
                                                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.TranslateY')}
                                                                                                        title={t('Pages.Lessons.Questions.forms.tools.move.TranslateY')}
                                                                                                        value={AnswersTranslateY}
                                                                                                        onChange={e => this.handleAnswersTranslateY(e, index, id)}
                                                                                                    />
                                                                                                    <button type="button" >
                                                                                                        <i className="fas fa-arrows-v"  ></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-group col">
                                                                                                <label className="text-dark h6">
                                                                                                    <i className="icon fas fa-ruler-combined"></i>
                                                                                                    {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}
                                                                                                </label>
                                                                                                <div className="search-form">
                                                                                                    <select
                                                                                                        onChange={e => this.handleAnswersTranslatePositionY(e, index, id)}
                                                                                                        className="w-100"
                                                                                                        value={AnswersTranslatePositionY}
                                                                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                                                                        title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}>
                                                                                                        <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                                                                                        <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                                                                                    </select>
                                                                                                    <button type="button" >
                                                                                                        <i className="fas fa-ruler-combined"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col">
                                                                                    <label className="text-dark h6"><i className="fal fa-braille"></i> {t('Pages.Lessons.Questions.forms.tools.move.Opacity')}</label>
                                                                                    <div className="search-form">
                                                                                        <input
                                                                                            className="w-100 form-control"
                                                                                            type="number"
                                                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.Opacity')}
                                                                                            title={t('Pages.Lessons.Questions.forms.tools.move.Opacity')}
                                                                                            value={AnswersOpacity}
                                                                                            onChange={e => this.handleAnswersOpacity(e, index, id)}
                                                                                        />
                                                                                        <button type="button" >
                                                                                            <i className="fas fa-braille"  ></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col">
                                                                                    <label className="text-dark h6"><i className="fal fa-expand-arrows"></i> {t('Pages.Lessons.Questions.forms.tools.move.Scale')}</label>
                                                                                    <div className="search-form">
                                                                                        <input
                                                                                            className="w-100 form-control"
                                                                                            type="number"
                                                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                                                            title={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                                                            value={AnswersScale}
                                                                                            onChange={e => this.handleAnswersScale(e, index, id)}
                                                                                        />
                                                                                        <button type="button" >
                                                                                            <i className="fas fa-expand-arrows"  ></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col">
                                                                                    <label className="text-dark h6"><i className="fal fa-sync-alt"></i> {t('Pages.Lessons.Questions.forms.tools.move.Rotate')}</label>
                                                                                    <div className="search-form">
                                                                                        <input
                                                                                            className="w-100 form-control"
                                                                                            type="number"
                                                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                                                            title={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                                                            value={AnswersRotate}
                                                                                            onChange={e => this.handleAnswersRotate(e, index, id)}
                                                                                        />
                                                                                        <button type="button" >
                                                                                            <i className="fas fa-sync-alt"  ></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group col">
                                                                                    <label className="text-dark h6">
                                                                                        <i className="icon fas fa-ruler-combined"></i>
                                                                                        {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}
                                                                                    </label>
                                                                                    <div className="search-form">
                                                                                        <select
                                                                                            onChange={e => this.handleAnswersTransformOrigin(e, index, id)}
                                                                                            className="w-100"
                                                                                            value={AnswersTransformOrigin}
                                                                                            placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                                                                            title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}>
                                                                                            <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                                                                            <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                                                                            <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                                                                            <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                                                                            <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>

                                                                                        </select>
                                                                                        <button type="button" >
                                                                                            <i className="fas fa-ruler-combined"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </>
                                                                :
                                                                ""
                                                        }
                                                    </>
                                            }
                                            <MovePreview
                                                id={id}
                                                index={index}
                                                Time={AnswersTime}
                                                Cursor={Cursor}
                                                rotate={rotate}
                                                isSpin={isSpin}
                                                flipX={flipX}
                                                delay={delay}
                                                scale={scale}
                                                transformOriginX={transformOriginX}
                                                transformOriginY={transformOriginY}
                                                CursorCustom={CursorCustom}
                                                ClickCursor={ClickCursor}
                                                ClickCursorCustom={ClickCursorCustom}
                                                Movments={AnswersMoveType === "custom" || AnswersMoveType === "paint" ? CustomStyle.Movments : []}
                                                ObjectSrc={AnswersSelectObjectSrc}
                                                ObjectHoverSrc={AnswersSelectObjectSrcHover}
                                                customColor={CustomColor}
                                                objectCss={AnswersSelectObject !== "" ? AnswersSelectObject : "SpaceShip"}
                                                style={{
                                                    background: '#fff',
                                                    padding: '5px'
                                                }}
                                                text={clause}></MovePreview>

                                        </> : ""
                                }
                                {
                                    this.props.typeId === 16 && this.props.shootGame === true ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-ufo"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.ShottingType.title')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectObject(e.target.value, index, id)}
                                                        className="w-100"
                                                        value={AnswersSelectObject}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.ShottingType.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.ShottingType.title')}>
                                                        {this.renderShottingObject()}
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-ufo"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                        value={AnswersSelectObjectSrc}
                                                        onChange={e => this.handleAnswersSelectObjectSrc(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-image"  ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    (this.props.typeId === 15 && this.props.isCategoryGame === true) ||
                                        (this.props.typeId === 22 && this.props.isCategoryGame === true) ?
                                        <>
                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-box-ballot"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.objects.ObjectCategory')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.setGameObjectCategory(e.target.value, index, id)}
                                                        className="w-100"
                                                        value={category ? category : 'none'}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.objects.placeholder')}>
                                                        {this.renderObjectCategory()}
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-box-ballot"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6">
                                                    <i className="icon fas fa-ufo"></i>
                                                    {t('Pages.Lessons.Questions.forms.tools.AnswerHolder.title')}
                                                </label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectObject(e.target.value, index, id)}
                                                        className="w-100"
                                                        value={AnswersSelectObject}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.AnswerHolder.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.AnswerHolder.title')}>
                                                        <option value="">{t('Pages.Lessons.Questions.forms.tools.AnswerHolder.default')}</option>
                                                        {this.renderObject(category)}
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fas fa-ufo"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            {
                                                AnswersSelectObject === "custom" ?
                                                    <>
                                                        <div className="form-group col">
                                                            <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrc')}
                                                                    value={AnswersSelectObjectSrc}
                                                                    onChange={e => this.handleAnswersSelectObjectSrc(e, index, id)}
                                                                />
                                                                <button type="button" >
                                                                    <i className="fas fa-image"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group col">
                                                            <label className="text-dark h6"><i className="fal fa-image"></i> {t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.objects.DropObjectSrcHover')}
                                                                    value={AnswersSelectObjectSrcHover}
                                                                    onChange={e => this.handleAnswersSelectObjectSrcHover(e, index, id)}
                                                                />
                                                                <button type="button" >
                                                                    <i className="fas fa-image"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="form-group col " >
                                                            <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                                    value={CustomColor}
                                                                    onChange={() => this.ClearDropCustomColor(index, id)}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={e => this.handleClickDisplayDropColorPicker(index, id)}
                                                                    style={{
                                                                        color: CustomColor !== 'transparent' ? CustomColor : "",
                                                                    }}>
                                                                    <i className="fas fa-tint"></i>
                                                                </button>
                                                                {
                                                                    this.state.displayDropColorPicker &&
                                                                        this.state.displayDropColorPickerId === id &&
                                                                        this.state.displayDropColorPickerIndex === index ?
                                                                        <div style={styles.popover}>
                                                                            <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                            <SketchPicker color={CustomColor} onChange={this.handleDropColorChange} />
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group col " >
                                                        </div>
                                                    </>
                                            }

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-spin fa-spinner"></i> {t('Pages.Lessons.Questions.forms.tools.move.spin')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersSelectIsSpin(e, index, id)}
                                                        className="w-100"
                                                        value={isSpin}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.spin')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.spin')}>
                                                        <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_spin')}</option>
                                                        <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.spin')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fal fa-spin fa-spinner"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-sync-alt"></i> {t('Pages.Lessons.Questions.forms.tools.move.Rotate')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                                        value={rotate}
                                                        onChange={e => this.handleAnswersMainRotate(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-sync-alt"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-retweet"></i> {t('Pages.Lessons.Questions.forms.tools.move.flipX')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.handleAnswersFlipX(e, index, id)}
                                                        className="w-100"
                                                        value={flipX}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.flipX')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.flipX')}>
                                                        <option value={false}>{t('Pages.Lessons.Questions.forms.tools.move.no_flipX')}</option>
                                                        <option value={true}>{t('Pages.Lessons.Questions.forms.tools.move.flipX')}</option>
                                                    </select>
                                                    <button type="button" >
                                                        <i className="fal fa-retweet"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col">
                                                <label className="text-dark h6"><i className="fal fa-expand-arrows"></i>{t('Pages.Lessons.Questions.forms.tools.move.Scale')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="number"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                                        value={scale}
                                                        onChange={e => this.handleScale(e, index, id)}
                                                    />
                                                    <button type="button" >
                                                        <i className="fas fa-expand-arrows"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col mb-3">
                                                <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.true_sounds')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.setTrueSound(e, index, id)}
                                                        className="w-100"
                                                        value={TrueSound}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                        <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                        <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                        {this.renderTrueSound()}
                                                    </select>
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playSound(TrueSound && TrueSound !== "none" && TrueSound !== "custom" ? process.env.PUBLIC_URL + TrueSound : "")}>
                                                        <i className="fas fa-volume"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col mb-3 ">
                                                <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                        value={CustomTrueSound}
                                                        onChange={e => this.setCustomTrueSound(e, index, id)}
                                                    />
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playSound(TrueSound === "custom" ? CustomTrueSound : "")}>
                                                        <i className="fas fa-volume"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col mb-3">
                                                <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.false_sounds')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.setFalseSound(e, index, id)}
                                                        className="w-100"
                                                        value={FalseSound}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                        <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                        <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                        {this.renderFalseSound()}
                                                    </select>
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playSound(FalseSound && FalseSound !== "none" && FalseSound !== "custom" ? process.env.PUBLIC_URL + FalseSound : "")}>
                                                        <i className="fas fa-volume"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group col mb-3 ">
                                                <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                        value={CustomFalseSound}
                                                        onChange={e => this.setCustomFalseSound(e, index, id)}
                                                    />
                                                    <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playSound(FalseSound === "custom" ? CustomFalseSound : "")}>
                                                        <i className="fas fa-volume"  ></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </> : ""
                                }
                            </div>
                            {
                                (this.props.typeId === 19 && this.props.isCategory === true) ||
                                    (this.props.typeId === 20 && this.props.isCategory === true) ||
                                    (this.props.typeId === 23 && this.props.isCategory === true) ||
                                    (this.props.typeId === 24 && this.props.isCategory === true) ||
                                    (this.props.typeId === 25 && this.props.isCategory === true) ||
                                    (this.props.typeId === 26 && this.props.isCategory === true) ||
                                    (this.props.typeId === 27 && this.props.isCategory === true) ||
                                    (this.props.typeId === 28 && this.props.isCategory === true) ||
                                    (this.props.typeId === 29 && this.props.isCategory === true) ||
                                    (this.props.typeId === 30 && this.props.isCategory === true) ||
                                    (this.props.typeId === 31 && this.props.isCategory === true) ?
                                    <SubAnswerItem
                                        {...this.props}
                                        isSubAnswer={true}
                                        hideSort={this.props.isGame}
                                        FillIndex={this.state.FillIndex}
                                        categoryId={this.state.EditStyle ? this.state.EditStyle.id : ""}
                                    />
                                    : ""
                            }
                        </div>
                        <div className="widget-header p-1 w-100 mb-3"></div>
                    </div >
                );
            }

            return result;
        }
        return "";
    }

    renderObjectMovment = (id, Sort) => {
        let movments = [];

        for (let index = 0; index <= 100; index++) {
            var hasItem = this.state.CustomStyle.Movments.filter(p => p.Id === id && p.Sort === Sort && p.Index === index)[0];

            movments.push(<div className={`ani-timekey ${hasItem ? "hasstyle" : ""} ${this.state.currentMovmentSort === Sort && this.state.currentMovmentIndex === index ? "selected" : ""}`} title={index} onClick={() => this.setMovment(Sort, index)} key={index}> {this.state.currentMovmentSort === Sort && this.state.currentMovmentIndex === index ? <span>{index}</span> : ""}</div>);
        }
        return movments;
    }

    setMovment = (currentMovmentSort, currentMovmentIndex) => {
        this.setState({
            currentMovmentIndex,
            currentMovmentSort
        });
    }

    renderObject = category => {
        return this.Objects.filter(p => (p.type === -1 || p.type === 2 || p.type === this.props.ObjectType) && p.category === category).map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderShottingObject = () => {
        return this.ShottingObjects.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderObjectCategory = () => {
        return this.Category.map(item => {
            return <option key={item.value} value={item.value}> {item.name}</option >;
        });
    }

    renderTrueSound() {
        return this.True_Sound.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderFalseSound() {
        return this.False_Sound.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }

    playSound = (Sound) => {
        if (Sound) {
            if (this.audio && !this.audio.paused) {
                this.audio.pause();
            } else {
                this.audio = new Audio(Sound);
                this.audio.play();
            }
        }
    }

    setGameObjectCategory = (ObjectCategory, index, id) => {
        var item = this.Objects.filter(p => (p.type === 1 || p.type === -1 || p.type === this.props.ObjectType) && p.category === parseInt(ObjectCategory))[0];
        if (item) {
            this.handleAnswersSelectObject(item.value, index, id);
        }
    }

    render() {
        const { t, EditText, title, text, sort } = this.props;
        return (
            <>
                {
                    (this.props.answers) ?
                        <SubItems
                            {...this.props}
                            show={this.state.showSubAnswer}
                            updateQuestionInfo={this.props.updateQuestionInfoAnswer}
                            showDelete={this.props.showDeleteAnswer}
                            RedactorId={this.props.AnswerRedactorId}
                            items={this.props.answers}
                            setSort={this.props.setSortAnswer}
                            categoryId={this.state.EditStyle ? this.state.EditStyle.id : ""}
                            FillIndex={this.state.FillIndex}
                            handleCloseSubAnswer={this.handleCloseSubAnswer}
                        />
                        : ""
                }
                {
                    this.state.show ?
                        <Modal
                            size="lg"
                            show={this.state.show}
                            onHide={this.handleClose}
                            backdrop="static"
                            autoFocus={true}
                            enforceFocus={false}
                            keyboard={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <div className="h5 mb-0"> <i className="icon fal fa-edit"></i> {EditText}</div></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12">
                                    {
                                        this.props.RedactorId ?
                                            <textarea id={`Edit${this.props.RedactorId}`} autoComplete="off" className="form-control"  ></textarea>
                                            :
                                            <>
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    value={this.state.CurrentAnswer}
                                                    onChange={e => this.changeCurrentAnswer(e)}
                                                    className="form-control mb-5"
                                                />
                                            </>
                                    }
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="w-100 row">
                                    <div className="col-6">
                                        <button className="btn btn-success" onClick={this.handleSubmitQuestionEditItems} >
                                            <i className="icon fal fa-edit"></i>
                                            {t('Pages.Lessons.Questions.forms.Edit')}
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-danger" onClick={this.handleClose} >
                                            <i className="icon fas fa-times"></i>
                                            {t('Close')}
                                        </button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                {
                    this.state.showEditStyle ?
                        <Modal
                            size="xl"
                            show={this.state.showEditStyle}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            autoFocus={true}
                            enforceFocus={false}>
                            <Modal.Header closeButton>
                                <Modal.Title> <div className="h5 mb-0"> <i className="icon fal fa-cogs"></i> {this.props.settingTitle} </div></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='widget widget-search' >
                                    <div className='widget-body p-3'>
                                        <div className="contact-form">
                                            {this.renderMoreSetting()}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="w-100 row">
                                    <div className="col-6">
                                        <button className="btn btn-success" onClick={this.handleSubmitQuestionEditStyleItems} >
                                            <i className="icon fal fa-edit"></i>
                                            {t('Pages.Lessons.Questions.forms.Edit')}
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-danger" onClick={this.handleClose} >
                                            <i className="icon fas fa-times"></i>
                                            {t('Close')}
                                        </button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                {
                    this.props.max === this.state.items.length ? "" :
                        <>
                            <div className="title h5 mt-2 mb-3 w-100"> <i className="icon fal fa-badge-check"></i> {title}</div>
                            <div className="form-group w-100 mb-1">
                                <label className="text-dark font-size-h5" htmlFor="QuestionTitle"> <i className="icon fas fa-text"></i> {text}</label>
                                <div className="row">

                                    {
                                        this.props.RedactorId ?
                                            <>
                                                <div className="col-11">
                                                    <textarea id={`${this.props.RedactorId}`} autoComplete="off" className="form-control" ref={this._editor} ></textarea>
                                                </div>
                                                <div className="col-1">
                                                    <button type="button" className="btn btn-block btn-sm btn-success" onClick={() => this.AddItem()} title={t('Pages.Lessons.Questions.forms.Add')}><i className=" fas fa-check"></i> </button>
                                                    <button type="button" className="btn btn-block btn-sm btn-danger" onClick={() => this.CleanItem()} title={t('Pages.Lessons.Questions.forms.Close')}><i className=" fas fa-times"></i> </button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="col-10">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        value={this.state.CurrentAnswer}
                                                        onChange={e => this.changeCurrentAnswer(e)}
                                                        className="form-control mb-5"
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <button type="button" className="btn btn-block btn-sm btn-success" onClick={() => this.AddItem()} title={t('Pages.Lessons.Questions.forms.Add')}><i className=" fas fa-check"></i> </button>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </>
                }

                <div className="title h5 mt-2 mb-3 w-100"> <i className="icon fal fa-sort-amount-down"></i> {sort}</div>
                <div className="form-group w-100 position-relative">
                    <DragDrop
                        trueAnswer={this.props.trueAnswer}
                        moreSetting={this.props.moreSetting}
                        settingTitle={this.props.settingTitle}
                        items={this.state.items}
                        setSort={this.setSort}
                        DeleteItem={this.DeleteItem}
                        EditItem={this.EditItem}
                        EditTrueAnswer={this.EditTrueAnswer}
                        EditStyle={this.EditStyle} />
                </div>
                {
                    this.props.hideSort === true ? "" :
                        <div className="form-group w-100 text-center">
                            <button className="custom-button" title={t('Pages.Lessons.Questions.forms.tools.saveSort')} type="button" onClick={() => this.saveSort()}>
                                <span>
                                    <i className="icon far fa-save"></i>
                                    {t('Pages.Lessons.Questions.forms.tools.saveSort')}
                                </span>
                            </button>
                        </div>
                }

            </>
        );
    }
}

export default withTranslation('common')(Items);
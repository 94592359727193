import React from 'react'
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import 'bootstrap-daterangepicker/daterangepicker.css';

import configData from "../../Config/countries.json";

class EditProfileForm extends React.Component {

    state = { initialize: false, IsLocked: false, IsTeacher: false, errorList: [], Sex: "female", countries: [], touchedBirthday: false, errorBirthday: '', CountryIndex: -1 }

    constructor(props) {
        super(props);
        moment.locale('en');
        this._toastId = new React.createRef();
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.user && Object.keys(props.user).length !== 0) {

            var index = state.countries.findIndex(p => p.value === props.user.Country);

            setTimeout(() => {
                props.initialize({
                    Address: props.user.Address,
                    FirstName: props.user.FirstName,
                    MaxSubUser: props.user.MaxSubUser,
                    SecondName: props.user.SecondName,
                    LastName: props.user.LastName,
                    Phone: props.user.Phone,
                    Birthday: props.user.Birthday,
                    Sex: props.user.Sex,
                    Email: props.user.Email,
                    Country: props.user.Country,
                });
            }, 500);

            state.IsLocked = props.user.IsLocked;
            state.IsTeacher = props.user.IsTeacher;
            state.Sex = props.user.Sex;
            state.Birthday = props.user.Birthday.replaceAll("-", "/");
            state.CountryIndex = index;
            state.initialize = true;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setSex = Sex => {
        this.setState({
            Sex
        });
    }
    setIsLocked = IsLocked => {
        this.setState({
            IsLocked
        });
    }
    setIsTeacher = IsTeacher => {
        this.setState({
            IsTeacher
        });
    }
    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }
    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            touchedBirthday: false,
            errorBirthday: ''
        });
    }
    onBlurBirthday = e => {
        const value = e.target.value;
        this.validateBirthday(value);
    }
    validateBirthday = value => {
        const { t } = this.props;
        if (!value) {
            this.setState({
                touchedBirthday: true,
                errorBirthday: t('Pages.Register.Birthday.error')
            });
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)) {
            this.setState({
                touchedBirthday: true,
                errorBirthday: t('Pages.Register.Birthday.error_notmatch')
            });
        }
    }
    validate = () => {
        const errors = {};

        if (!this.Country.state.value || !this.Country.state.value.value) {
            errors.Country = 'Pages.Register.Country.error';
        }

        if (!this.Address.ref.current.getValue()) {
            errors.Address = 'Pages.Register.Address.error';
        }

        if (!this.FirstName.ref.current.getValue()) {
            errors.FirstName = 'Pages.Register.FirstName.error';
        }


        if (!this.LastName.ref.current.getValue()) {
            errors.LastName = 'Pages.Register.LastName.error';
        }

        if (this.props.isAdmin) {
            if (!this.Email.ref.current.getValue()) {
                errors.Email = 'Pages.Register.Email.error';
            } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(this.Email.ref.current.getValue())) {
                errors.Email = 'Pages.Register.Email.error_notmatch';
            }
            if (this.MaxSubUser && this.MaxSubUser.ref && this.MaxSubUser.ref.current && !this.MaxSubUser.ref.current.getValue()) {
                errors.MaxSubUser = 'Pages.Register.MaxSubUser.error';
            }

        }

        if (this.props.isChild && this.props.isChild === true) {
        } else {
            if (!this.Phone.ref.current.getValue()) {
                errors.Phone = 'Pages.Register.Phone.error';
            }
        }

        if (!this.Birthday.ref.value) {
            errors.Birthday = 'Pages.Register.Birthday.error';
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(this.Birthday.ref.value)) {
            errors.Birthday = 'Pages.Register.Birthday.error_notmatch';
        }

        return errors;
    }

    onSubmit = e => {
        const { t, isAdmin } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                FirstName: this.FirstName.ref.current.getValue(),
                MaxSubUser: isAdmin && this.MaxSubUser && this.MaxSubUser.ref && this.MaxSubUser.ref.current ? this.MaxSubUser.ref.current.getValue() : "",
                LastName: this.LastName.ref.current.getValue(),
                SecondName: this.SecondName.ref.current.getValue(),
                Email: isAdmin ? this.Email.ref.current.getValue() : "",
                Birthday: this.Birthday.ref.value,
                Sex: this.state.Sex,
                IsLocked: this.state.IsLocked,
                IsTeacher: this.state.IsTeacher,
                Country: this.Country.state.value.value,
                Address: this.Address.ref.current.getValue(),
                Phone: (this.props.isChild && this.props.isChild === true) ? "" : this.Phone.ref.current.getValue()
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);

        }
        e.preventDefault();
    }

    render() {
        const { submitting, t, isAdmin, isTeacher } = this.props;

        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row row-col-3 w-100 mx-0">
                        <div className="form-group col-md">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.FirstName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={FirstName => this.FirstName = FirstName}
                                    name="FirstName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.FirstName.Placeholder')}
                                    title={t('Pages.Register.FirstName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group col-md">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SecondName.title')}</label>
                            <div>
                                <Field
                                    ref={SecondName => this.SecondName = SecondName}
                                    name="SecondName"
                                    component={this.renderField}
                                    required={false}
                                    type="text"
                                    placeholder={t('Pages.Register.SecondName.Placeholder')}
                                    title={t('Pages.Register.SecondName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group col-md">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.LastName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={LastName => this.LastName = LastName}
                                    name="LastName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.LastName.Placeholder')}
                                    title={t('Pages.Register.LastName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Birthday.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <DateRangePicker
                                ref={Birthday => this.Birthday = Birthday}
                                initialSettings={{
                                    drops: 'auto',
                                    opens: 'center',
                                    autoUpdateInput: false,
                                    cancelClass: "btn btn-sm btn-danger",
                                    singleDatePicker: true,
                                    showDropdowns: true,
                                    minYear: 1951,
                                    maxDate: `${moment().add(-5, 'y').format('DD/MM/YYYY')}`,
                                    locale: {
                                        format: 'DD/MM/YYYY',
                                        cancelLabel: t('Close'),
                                        firstDay: 6,
                                        applyLabel: t('Apply'),
                                        monthNames: [
                                            `${t("months.January")}`,
                                            `${t("months.February")}`,
                                            `${t("months.March")}`,
                                            `${t("months.April")}`,
                                            `${t("months.May")}`,
                                            `${t("months.June")}`,
                                            `${t("months.July")}`,
                                            `${t("months.August")}`,
                                            `${t("months.September")}`,
                                            `${t("months.October")}`,
                                            `${t("months.November")}`,
                                            `${t("months.December")}`
                                        ],
                                        daysOfWeek: [
                                            t("daysOfWeek.Sunday"),
                                            t("daysOfWeek.Monday"),
                                            t("daysOfWeek.Tuesday"),
                                            t("daysOfWeek.Wednesday"),
                                            t("daysOfWeek.Thursday"),
                                            t("daysOfWeek.Friday"),
                                            t("daysOfWeek.Saturday")
                                        ],
                                    },
                                }}
                                onApply={this.handleApplyDate} >
                                <input
                                    defaultValue={this.state.Birthday}
                                    name="Birthday"
                                    autoComplete="off"
                                    readOnly={true}
                                    required
                                    type="text"
                                    onBlur={e => this.onBlurBirthday(e)}
                                    className={`form-control ${(this.state.touchedBirthday && this.state.errorBirthday) ? 'is-invalid' : ''}`}
                                    placeholder={t('Pages.Register.Birthday.Placeholder')}
                                    title={t('Pages.Register.Birthday.title')}
                                />
                            </DateRangePicker>
                            {this.state.touchedBirthday && this.state.errorBirthday && <div className="invalid-feedback">{this.state.errorBirthday}</div>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Sex.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <ul className="filter mb-2 px-0 w-100">
                            <li className="p-0 border-0">
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.Sex === "male" ? true : false}
                                        onChange={e => this.setSex("male")}
                                        className="custom-control-input"
                                        title={t('Pages.Register.Sex.male')}
                                        name="Sex"
                                        id="male" />
                                    <label className="custom-control-label pt-1" htmlFor="male" >
                                        <i className="icon fad fa-male"></i>
                                        {t('Pages.Register.Sex.male')}
                                    </label>
                                </div>
                            </li>
                            <li className="p-0">
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.Sex === "female" ? true : false}
                                        onChange={e => this.setSex("female")}
                                        title={t('Pages.Register.Sex.female')}
                                        className="custom-control-input"
                                        name="Sex"
                                        id="female" />
                                    <label className="custom-control-label pt-1" htmlFor="female" >
                                        <i className="icon fad fa-female"></i>
                                        {t('Pages.Register.Sex.female')}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Country.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Select
                                defaultValue={this.state.CountryIndex > -1 ? {
                                    label: this.state.countries[this.state.CountryIndex].label,
                                    value: this.state.countries[this.state.CountryIndex].value
                                } : ""}
                                name="Country"
                                placeholder={t('Pages.Register.Country.Placeholder')}
                                isRtl={true}
                                isSearchable={true}
                                ref={Country => this.Country = Country}
                                options={this.state.countries}
                                filterOption={this.filterOptions}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#92278f',
                                        primary: '#92278f',
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Address.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Address => this.Address = Address}
                                name="Address"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.Address.Placeholder')}
                                title={t('Pages.Register.Address.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {
                        this.props.isChild && this.props.isChild === true ? "" :
                            <div className="form-group">
                                <label className='text-dark font-size-h5'>{t('Pages.Register.Phone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                <div>
                                    <Field
                                        ref={Phone => this.Phone = Phone}
                                        name="Phone"
                                        component={this.renderField}
                                        type="text"
                                        required={true}
                                        placeholder={t('Pages.Register.Phone.Placeholder')}
                                        title={t('Pages.Register.Phone.title')}
                                        className="form-control number"
                                    />
                                </div>
                            </div>
                    }
                    {
                        isAdmin ?
                            <>
                                <div className="form-group">
                                    <label className='text-dark font-size-h5'>{t('Pages.Register.Email.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={Email => this.Email = Email}
                                            name="Email"
                                            component={this.renderField}
                                            type="email"
                                            required={true}
                                            placeholder={t('Pages.Register.Email.Placeholder')}
                                            title={t('Pages.Register.Email.title')}
                                            className="form-control number"
                                        />
                                    </div>
                                </div>

                                {
                                    this.props.user && this.props.user.UserType && this.props.user.UserType.Id === 2 ?
                                        <>
                                            <div className="bg-white w-100 p-1 mb-2"></div>
                                            <div className="form-group">
                                                <label className='text-dark font-size-h5'>{t('Pages.Register.MaxSubUser.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                                <div>
                                                    <Field
                                                        ref={MaxSubUser => this.MaxSubUser = MaxSubUser}
                                                        name="MaxSubUser"
                                                        component={this.renderField}
                                                        type="number"
                                                        min="1"
                                                        step="1"
                                                        required={true}
                                                        placeholder={t('Pages.Register.MaxSubUser.Placeholder')}
                                                        title={t('Pages.Register.MaxSubUser.title')}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }

                                <div className="bg-white w-100 p-1 mb-2"></div>
                                <div className="form-group">
                                    <label className="text-dark font-size-h5" htmlFor="IsLocked">
                                        <i className="icon fas fa-user-lock"></i> {t(`Pages.UsersControl.IsLocked`)}
                                    </label>
                                    <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-dark">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={e => this.setIsLocked(e.target.checked)}
                                            ref={IsLocked => this.IsLocked = IsLocked}
                                            defaultChecked={this.state.IsLocked}
                                            name="IsLocked"
                                            id="IsLocked" />
                                        <label className="custom-control-label" htmlFor="IsLocked" >
                                            {t(`Pages.UsersControl.IsLocked`)}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="text-dark font-size-h5" htmlFor="IsTeacher">
                                        <i className="icon fas fa-chalkboard-teacher text-info"></i> {t(`Pages.UsersControl.IsTeacher`)}
                                    </label>
                                    <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-info">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={e => this.setIsTeacher(e.target.checked)}
                                            ref={IsTeacher => this.IsTeacher = IsTeacher}
                                            defaultChecked={this.state.IsTeacher}
                                            name="IsTeacher"
                                            id="IsTeacher" />
                                        <label className="custom-control-label" htmlFor="IsTeacher" >
                                            {t(`Pages.UsersControl.IsTeacher`)}
                                        </label>
                                    </div>
                                </div>
                            </> :
                            isTeacher ?
                                <>
                                    <div className="form-group">
                                        <label className="text-dark font-size-h5" htmlFor="IsLocked">
                                            <i className="icon fas fa-user-lock"></i> {t(`Pages.UsersControl.IsLocked`)}
                                        </label>
                                        <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-dark">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={e => this.setIsLocked(e.target.checked)}
                                                ref={IsLocked => this.IsLocked = IsLocked}
                                                defaultChecked={this.state.IsLocked}
                                                name="IsLocked"
                                                id="IsLocked" />
                                            <label className="custom-control-label" htmlFor="IsLocked" >
                                                {t(`Pages.UsersControl.IsLocked`)}
                                            </label>
                                        </div>
                                    </div>
                                </>
                                : ""
                    }


                    <div className="form-group w-100 text-center">
                        <button className="custom-button" disabled={submitting} >
                            <span>
                                <i className="icon fal fa-save"></i> {t('Pages.ProfileEdit.Send')}
                            </span>
                        </button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.Address) {
        errors.Address = 'Pages.Register.Address.error';
    }

    if (!values.FirstName) {
        errors.FirstName = 'Pages.Register.FirstName.error';
    }
    if (!values.MaxSubUser) {
        errors.MaxSubUser = 'Pages.Register.MaxSubUser.error';
    }

    if (!values.LastName) {
        errors.LastName = 'Pages.Register.LastName.error';
    }

    if (!values.Phone) {
        errors.Phone = 'Pages.Register.Phone.error';
    }

    if (!values.Birthday) {
        errors.Birthday = 'Pages.Register.Birthday.error';
    } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(values.Birthday)) {
        errors.Birthday = 'Pages.Register.Birthday.error_notmatch';
    }

    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'EditProfileForm',
    validate
})(EditProfileForm))
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

import {
    fetchSlidersByLimit as fetch,
} from '../../Admin/Sliders/actions';

class CarouselComponent extends React.Component {

    componentDidMount() {
        this.props.fetch(3);
    }

    renderItems() {
        const { sliders } = this.props;
        return sliders.sliders.map(slider => {
            return <div key={slider.Id}>
                <LazyLoadImage className="w-100" src={slider.Photo} alt={`${slider.Title}`} />
                <p className="legend">
                    <Link to={`/Slider/${slider.Id}`} target="_blank" rel="noreferrer">
                        {slider.Title}
                    </Link>
                </p>
            </div>
        });
    }

    render() {
        const { t, sliders } = this.props;
        if (sliders && sliders.sliders) {
            return (
                <Carousel
                    showArrows={false}
                    interval={10000}
                    showStatus={false}
                    infiniteLoop={true}
                    showThumbs={false}
                    autoPlay={true}>
                    {this.renderItems()}
                </Carousel>
            );
        }
        return <div className="product-content w-100 text-center ">
            <div className="display-3 text-danger">
                <i className="fad fa-sync-alt fa-spin"></i>
            </div>
            <h4 className="display-6 subtitle">
                {t("Loading")}
            </h4>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        sliders: state.sliders
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(CarouselComponent));
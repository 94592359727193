const ExamReducers = (state = [], action) => {
    switch (action.type) {
        case "EXAM_LOADING":
            return {
                current: [],
                marks: [],
                isLoaded: false
            };
        case "EXAM_INITIAL":
            return {
                current: action.exam,
                marks: [],
                isLoaded: true
            };
        case "EXAM_SAVE_ANSWER":
            return {
                current: UpdateAnswer(action.id, action.answers, action.typeId, state.current, action.userMark),
                marks: UpdateMark(action.id, action.mark, state.marks, action.userMark),
                isLoaded: true
            };
        default:
            return state;
    }
}

function UpdateMark(questionId, newMark, marks, userMark) {
    let copy = [];
    let update = false;
    marks.map(mark => {
        if (mark.Id === questionId) {
            mark.mark = {
                ...newMark
            };
            mark.userMark = userMark;
            update = true;
        }
        copy.push({
            ...mark
        });
        return '';
    });
    if (!update) {
        copy.push({
            userMark,
            mark: newMark,
            Id: questionId
        });
    }
    return copy;
}

function UpdateAnswer(questionId, answers, typeId, answerList, userMark) {
    let copy = [];
    let update = false;
    answerList.map(answer => {
        if (answer.Id === questionId) {
            answer = {
                ...answer,
                mark: userMark,
                answers
            };
            update = true;
        }
        copy.push({
            ...answer
        });
        return '';
    });
    if (!update) {
        copy.push({
            answers,
            mark: userMark,
            Id: questionId,
            typeId
        });
    }
    return copy;
}


export default ExamReducers;
const FileManagerReducers = (state = [], action) => {
    switch (action.type) {
        case "FILE_MANAGER_LOADING":
            return {
                files: state.files,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "FILE_MANAGER_INITIAL":
            return {
                files: action.files,
                toRemove: [],
                isLoaded: true
            };
        case "FILE_MANAGER_QUEUE_FOR_REMOVAL":
            return {
                files: state.files,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "FILE_MANAGER_CLEAN_NODES":
            return {
                files: state.toRemove.some(v => v === action.id) ? state.files.filter(v => v.Id !== action.id) : state.files,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "FILE_MANAGER_UNDO":
            return {
                files: state.files,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default FileManagerReducers;
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Select from 'react-select';

import configData from "../../Config/countries.json";
import EditProfileForm from '../../Home/Forms/EditProfileForm';
import ChangePasswordForm from '../../Home/Forms/ChangePasswordForm';
import api from '../../../api/api';
import { ShareFileBySocket, ShareLessonBySocket } from '../../../api/socket.io';

class StudentInClassRoomItem extends React.Component {

    state = {
        moreInfo: false,
        showEditProfile: false,
        currentUser: null,
        showChangePassword: false,
        isShared: false,
        isFirst: false,
        showMove: false,
        classRooms: []
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isFirst && props.item && props.forShare) {
            if (props.item.LessonPermissions && props.LessonId) {
                state.isShared = props.item.LessonPermissions.some(p => p.LessonId === props.LessonId);
            } else if (props.item.FilePermissions && props.FileId) {
                state.isShared = props.item.FilePermissions.some(p => p.FileId === props.FileId);
            }
            state.isFirst = true;
        }
        if (props.classRooms) {
            state.classRooms = props.classRooms.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }
        return state;
    }

    changePassword = user => {
        this.setState({
            showChangePassword: true,
            currentUser: user
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    edit = user => {
        this.setState({
            showEditProfile: true,
            currentUser: user
        });
    }

    move = () => {
        this.setState({
            showMove: true
        });
    }

    showAddNewBill = () => {
        this.setState({
            showAddNewBill: true
        });
    }

    handleClose = () => {
        this.setState({
            showEditProfile: false,
            showBills: false,
            showChangePassword: false,
            showMove: false,
            currentUser: null
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/StudentInClassRooms/${this.state.currentUser.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.updateStudentInClassRoom(this.state.currentUser.Id, postData);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitChangePassword = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/StudentInClassRooms/${this.state.currentUser.Id}?ChangePassword=true`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    shareLesson = (StudentId, LessonId) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/LessonPermissions/${StudentId}?LessonId=${LessonId}&isAdd=${true}`, {}
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-mind-share"></i>
                            {this.props.type === "Games" ? t("Success.GamesPermissionShow.Title") : t("Success.LessonPermissionShow.Title")}
                        </div>
                        <div className="h6">
                            {this.props.type === "Games" ? t("Success.GamesPermissionShow.Body") : t("Success.LessonPermissionShow.Body")}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.setState({ isShared: true });

                ShareLessonBySocket([StudentId], this.props.Lesson, this.props.type);

            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    hideLesson = (StudentId, LessonId) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/LessonPermissions/${StudentId}?LessonId=${LessonId}&isAdd=${false}`, {}
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-mind-share"></i>
                            {this.props.type === "Games" ? t("Success.GamesPermissionHide.Title") : t("Success.LessonPermissionHide.Title")}
                        </div>
                        <div className="h6">
                            {this.props.type === "Games" ? t("Success.GamesPermissionHide.Body") : t("Success.LessonPermissionHide.Body")}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.setState({ isShared: false });
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    shareFile = (StudentId, FileId) => {
        const { t, File } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/EducationalFilesPermissions/${StudentId}?FileId=${FileId}&isAdd=${true}`, {}
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-mind-share"></i> {t('Success.LessonPermissionShow.Title')}</div> <div className="h6">{t('Success.LessonPermissionShow.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.setState({ isShared: true });
                ShareFileBySocket([StudentId], File);
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    hideFile = (StudentId, FileId) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/EducationalFilesPermissions/${StudentId}?FileId=${FileId}&isAdd=${false}`, {}
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-mind-share"></i> {t('Success.LessonPermissionHide.Title')}</div> <div className="h6">{t('Success.LessonPermissionHide.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.setState({ isShared: false });
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    onSubmitChangeClassRoom = () => {
        const { t, item } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.ClassRoomId && this.ClassRoomId.state && this.ClassRoomId.state.value && this.ClassRoomId.state.value) {
            let postData = {
                ClassRoomId: this.ClassRoomId.state.value.value
            };

            api.post(`/ChangeStudentClass/${item.Id}`,
                postData
            ).then(response => {

                this.props.Sync();

                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-exchange"></i>
                            {t('Pages.UsersControl.toast.ChangeClassRoom.Title')}
                        </div>
                        <div className="h6">
                            {t('Pages.UsersControl.toast.ChangeClassRoom.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();


            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.toast.ChangeClassRoom.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        const { t, item, forShare, LessonId, FileId } = this.props;
        if (item) {

            let Country = "";
            if (item.Country) {
                Country = this.state.countries.filter(p => p.value === item.Country)[0];
            }

            return (
                <>
                    {
                        this.state.showChangePassword ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showChangePassword}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-key text-primary icon"></i> {t("Pages.StudentInClassRoomsControl.action.change_password")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <ChangePasswordForm isTeacher={this.props.isTeacher} onSubmit={this.onSubmitChangePassword}></ChangePasswordForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showEditProfile ?
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showEditProfile}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-edit text-primary icon"></i> {t("Pages.StudentInClassRoomsControl.action.edit")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <EditProfileForm isAdmin={false} user={item} onSubmit={this.onSubmit} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showMove ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showMove}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-exchange text-primary icon"></i>
                                            {t("Pages.StudentInClassRoomsControl.action.move")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category mb-2">
                                        <div className="widget-body">
                                            <div className="form-group w-100">
                                                <div>
                                                    <Select
                                                        isClearable={true}
                                                        name="ClassRoomId"
                                                        placeholder={t('Pages.ClassRoomsControl.title')}
                                                        isRtl={true}
                                                        isSearchable={true}
                                                        ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                        options={this.state.classRooms}
                                                        filterOption={this.filterOptions}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#92278f',
                                                                primary: '#92278f',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group w-100 text-center">
                                                <button className="custom-button" onClick={() => this.onSubmitChangeClassRoom()}>
                                                    <span>
                                                        <i className="icon fal fa-exchange"></i> {t('Pages.StudentInClassRoomsControl.action.move_action')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            {
                                item.IsActive ? "" :
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.StudentInClassRoomsControl.NotActive')}>
                                        <i className="fas fa-lock"></i>
                                    </div>
                            }
                            {
                                item.IsLocked ?
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.StudentInClassRoomsControl.IsLocked")}>
                                        <i className="fas fa-user-lock"></i>
                                    </div> : ""

                            }
                            <div className="product-thumb">
                                {
                                    item.Photo ?
                                        <LazyLoadImage src={item.Photo} alt={`${item.Name}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                }
                                <div className="product-action-link">
                                    {
                                        forShare ?
                                            <>
                                                {
                                                    this.state.isShared ?
                                                        <div className="my-1" onClick={() =>
                                                            FileId ?
                                                                this.hideFile(item.Id, FileId) :
                                                                this.hideLesson(item.Id, LessonId)
                                                        } title={t("Pages.StudentInClassRoomsControl.action.hide")}>
                                                            <i className="fad text-danger fa-mind-share"></i>
                                                        </div>
                                                        :
                                                        <div className="my-1" onClick={() =>
                                                            FileId ?
                                                                this.shareFile(item.Id, FileId) :
                                                                this.shareLesson(item.Id, LessonId)
                                                        } title={t("Pages.StudentInClassRoomsControl.action.share")}>
                                                            <i className="far fa-mind-share"></i>
                                                        </div>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="my-1" onClick={() => this.edit(item)} title={t("Pages.StudentInClassRoomsControl.action.edit")}>
                                                    <i className="far fa-user-edit"></i>
                                                </div>
                                                <div className="my-1 text-info" onClick={() => this.move(item)} title={t("Pages.StudentInClassRoomsControl.action.move")}>
                                                    <i className="far fa-exchange"></i>
                                                </div>
                                                <div className="my-1" onClick={() => this.changePassword(item)} title={t("Pages.StudentInClassRoomsControl.action.change_password")}>
                                                    <i className="far fa-key"></i>
                                                </div>
                                                <div className="my-1 text-danger" onClick={() => this.props.remove(item.Id)} title={t("Pages.StudentInClassRoomsControl.action.remove")}>
                                                    <i className="far fa-user-slash"></i>
                                                </div>
                                            </>
                                    }
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.StudentInClassRoomsControl.action.info")}>
                                        <i className="far fa-info"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title">
                                    {
                                        forShare ? item.Name : <Link to="#" onClick={() => this.edit(item)}> {item.Name} </Link>
                                    }
                                </h5>
                                <p>
                                    {
                                        item.Birthday ?
                                            <span title={t('Pages.Register.Birthday.title')}>
                                                <i className="icon fal fa-calendar-alt"></i>
                                                {item.Birthday}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Sex ?
                                            <span className="mx-2" title={t('Pages.Register.Sex.title')} >
                                                <i className={`icon text-info fad fa-${item.Sex}`}></i>
                                                {t('Pages.Register.Sex.' + item.Sex)}
                                            </span>
                                            : ""
                                    }
                                </p>
                                <p>
                                    {
                                        item.Username ?
                                            <span title={t('Pages.Register.Username.title')} >
                                                <i className="icon fal fa-hashtag"></i>
                                                {item.Username}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Email ?
                                            <>
                                                {
                                                    item.Username ? " - " : ""
                                                }
                                                <a href={`mailto:${item.Email}`} title={t('Pages.Register.Email.title')}>
                                                    <i className="icon fal fa-envelope-open-text"></i>
                                                    {item.Email}
                                                </a>
                                            </>
                                            : ""
                                    }
                                    {
                                        item.Phone ?
                                            <>
                                                {
                                                    item.Email ? " - " : ""
                                                }
                                                <a className="mx-2" href={`Tel:${item.Phone}`} title={t('Pages.Register.Phone.title')}>
                                                    <i className="icon fal fa-phone-alt"></i>
                                                    <span dir="ltr">
                                                        {item.Phone}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            Country ?
                                                <span title={t('Pages.Register.Country.title')} >
                                                    {Country.label}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.Address ?
                                                <span title={t('Pages.Register.Address.title')} >
                                                    {
                                                        Country ? " - " : ""
                                                    }
                                                    {item.Address}
                                                </span>
                                                : ""
                                        }
                                        <br />
                                        <span className="mt-2">
                                            {
                                                item.School ?
                                                    <span title={t('Pages.Register.SchoolName.title')}>
                                                        <i className="icon fal fa-school"></i>
                                                        {item.School.Name + " - "}
                                                        <span className="text-danger" >
                                                            {
                                                                item.UserType ?
                                                                    <>
                                                                        {item.UserType.Name}
                                                                    </>
                                                                    : ""
                                                            }
                                                        </span>
                                                    </span>
                                                    : ""
                                            }
                                            {
                                                item.ClassRoom ?
                                                    <span className="mx-2 text-info" title={t('Pages.StudentInClassRoomsControl.title')}>
                                                        <i className="icon fal fa-users-class"></i>
                                                        {item.ClassRoom.Name}
                                                    </span> : ""
                                            }
                                        </span>

                                    </p>
                                    {
                                        item.IsStudentInClassRoom ?
                                            <div className="d-flex align-items-center mr-1" >
                                                <span className="cate btn btn-light " title={t("Pages.StudentInClassRoomsControl.IsStudentInClassRoom")}>
                                                    <i className="fad fa-chalkboard-teacher text-primary"></i>
                                                </span>
                                            </div> : ""
                                    }
                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Profile.LastLogin.title")}  :
                                                <div className="text-secondary">
                                                    {item.LastLogin}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}


export default withTranslation('common')(StudentInClassRoomItem);
import api from '../../../../api/api';

export const fetchRegistrationSchools = postData => async dispatch => {
    dispatch({ type: 'REGISTRATION_SCHOOLS_LOADING' });
    const response = await api.post(`/RegistrationSchools/`, postData);
    dispatch({ type: 'REGISTRATION_SCHOOLS_INITIAL', schools: response.data.data });
}

export const loadRegistrationSchools = () => async dispatch => {
    dispatch({ type: 'REGISTRATION_SCHOOLS_LOADING' });
}

export const removeRegistrationSchools = id => async dispatch => {
    dispatch({ type: 'REGISTRATION_SCHOOLS_QUEUE_FOR_REMOVAL', id });
}

export const undoRegistrationSchools = id => async dispatch => {
    dispatch({ type: 'REGISTRATION_SCHOOLS_UNDO', id });
}

export const clearRegistrationSchools = id => async dispatch => {
    dispatch({ type: 'REGISTRATION_SCHOOLS_CLEAN_NODES', id });
}
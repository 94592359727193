import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class Cherry extends React.Component {

    render() {
        return <div className="position-relative">

            <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                    transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                }}>
                    <div className={`mb-2`}>
                        <div className="d-flex justify-content-center">
                            <div className="Fruit-cherry">
                                <div className="Fruit-cherry-join "></div>
                                <div className="Fruit-cherry2"></div>
                            </div>
                            <div className="Fruit-cherry">
                                <div className="Fruit-cherry1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TextBox {...this.props} />
        </div>;
    }
}

export default Cherry;

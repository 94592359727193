import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import GamePlay from "./GamePlay";
import ResultScreen from "./Screen/ResultScreen";
import configData from "../../Config/objects.json";

class GamesView extends React.Component {

    state = {
        scale: 1,
        pause: false,
        showInfo: false,
        orientation: "portrait",
        cursorPosition: {
            top: 0,
            left: 0
        },
        cursorClickPosition: {
            top: 0,
            left: 0
        },
        clickNumber: 0,
        keyStatus: ""
    };

    constructor(props) {
        super(props);
        this.designerContainer = React.createRef();
        this.Objects = configData.Objects;
        this.isMouseDown = "";
    }

    componentWillUnmount() {
        setTimeout(function () {
            window.scrollTo(0, 1);
        }, 0);
    }

    componentDidMount() {
        this.boundingClientRect = this.designerContainer.current.getBoundingClientRect();
    }

    replay = () => {
        this.setState({
            pause: false,
            showInfo: false
        });
    }
    goBack = () => {
        this.setState({
            pause: true
        });
    }
    showInfo = () => {
        this.setState({
            pause: true,
            showInfo: true
        });
    }

    handleMove = event => {
        if ((this.props.Question.Type.Id === 17 ||
            this.props.Question.Type.Id === 18 ||
            this.props.Question.Type.Id === 19 ||
            this.props.Question.Type.Id === 21 ||
            this.props.Question.Type.Id === 22 ||
            this.props.Question.Type.Id === 23 ||
            this.props.Question.Type.Id === 25 ||
            this.props.Question.Type.Id === 26 ||
            this.props.Question.Type.Id === 27 ||
            this.props.Question.Type.Id === 28 ||
            this.props.Question.Type.Id === 29 ||
            this.props.Question.Type.Id === 30 ||
            this.props.Question.Type.Id === 31) && this.designerContainer && this.designerContainer.current) {
            let height = this.designerContainer.current.scrollHeight;
            let rect = this.boundingClientRect;

            var MaxTop = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.MaxTop : 100;
            var MinTop = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.MinTop : 0;

            let top = event.clientY;
            if (MaxTop && top >= (height * MaxTop / 100)) {
                top = height * MaxTop / 100;
            } else if (MinTop && top <= (height * MinTop / 100)) {
                top = height * MinTop / 100;
            }

            this.setState({
                cursorPosition: {
                    left: event.clientX - rect.left,
                    top
                }
            });
        }
    }
    handleTouchMove = event => {
        if ((this.props.Question.Type.Id === 17 ||
            this.props.Question.Type.Id === 18 ||
            this.props.Question.Type.Id === 19 ||
            this.props.Question.Type.Id === 21 ||
            this.props.Question.Type.Id === 22 ||
            this.props.Question.Type.Id === 23 ||
            this.props.Question.Type.Id === 25 ||
            this.props.Question.Type.Id === 26 ||
            this.props.Question.Type.Id === 27 ||
            this.props.Question.Type.Id === 28 ||
            this.props.Question.Type.Id === 29 ||
            this.props.Question.Type.Id === 30 ||
            this.props.Question.Type.Id === 31) && this.designerContainer && this.designerContainer.current) {
            let rect = this.boundingClientRect;
            let height = this.designerContainer.current.scrollHeight;

            var MaxTop = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.MaxTop : 100;
            var MinTop = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.MinTop : 0;

            let top = event.nativeEvent.targetTouches[0].clientY;
            if (MaxTop && top >= (height * MaxTop / 100)) {
                top = height * MaxTop / 100;
            } else if (MinTop && top <= (height * MinTop / 100)) {
                top = height * MinTop / 100;
            }
            this.setState({
                cursorPosition: {
                    left: event.nativeEvent.targetTouches[0].clientX - rect.left,
                    top
                }
            });
        }
    }

    onClick = event => {
        if ((
            this.props.Question.Type.Id === 18 ||
            this.props.Question.Type.Id === 21 ||
            this.props.Question.Type.Id === 22 ||
            this.props.Question.Type.Id === 23 ||
            this.props.Question.Type.Id === 24 ||
            this.props.Question.Type.Id === 25 ||
            this.props.Question.Type.Id === 26 ||
            this.props.Question.Type.Id === 27 ||
            this.props.Question.Type.Id === 28 ||
            this.props.Question.Type.Id === 30
        ) && !this.state.pause && this.designerContainer && this.designerContainer.current) {
            let rect = this.boundingClientRect;
            let height = this.designerContainer.current.scrollHeight;

            var MaxTop = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.MaxTop : 100;
            var MinTop = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.MinTop : 0;

            let top = event.clientY;
            if (MaxTop && top >= (height * MaxTop / 100)) {
                top = height * MaxTop / 100;
            } else if (MinTop && top <= (height * MinTop / 100)) {
                top = height * MinTop / 100;
            }
            this.setState({
                cursorClickPosition: {
                    left: event.clientX - rect.left,
                    top
                },
                clickNumber: this.state.clickNumber + 1
            });
        }
    }

    onMouseDown = () => {
        if (this.props.Question.Type.Id === 29 && this.designerContainer && this.designerContainer.current) {
            this.setState({
                clickNumber: this.state.clickNumber + 1,
                keyStatus: "down"
            });
            this.isMouseDown = setInterval(() => {
                this.setState({
                    clickNumber: this.state.clickNumber + 1
                });
            }, 1000);
        }
    }
    onMouseUp = () => {
        if (this.props.Question.Type.Id === 29 && this.designerContainer && this.designerContainer.current) {
            this.setState({
                clickNumber: this.state.clickNumber + 1,
                keyStatus: "up"
            });
            clearInterval(this.isMouseDown);
        }
    }

    getRandomObject = objectCss => {
        const itemsObject = this.Objects.filter(p => p.RandomType === objectCss);
        if (itemsObject && itemsObject.length && itemsObject.length > 0) {
            const itemObject = itemsObject[Math.floor(Math.random() * itemsObject.length)];
            if (itemObject) {
                objectCss = itemObject.value;
            }
        }
        return objectCss;
    }

    getExplosionObject = ExplosionObject => {
        let objectCss = "Shoot";
        let ObjectSrc = "";
        let MoveSound = "/assets/sounds/game/move/bomb.wav";
        let isSpan = false;
        if (ExplosionObject === "RocketExplosion") {
            objectCss = "Missile";
            MoveSound = "/assets/sounds/game/move/shoot.wav";
        } else if (ExplosionObject === "BoomExplosion") {
            objectCss = "custom";
            ObjectSrc = "/assets/images/explosion/arrow_up.png";
            MoveSound = "/assets/sounds/game/move/arrow.mp3";
        } else if (ExplosionObject === "ThunderExplosion") {
            objectCss = "Thunder";
            MoveSound = "/assets/sounds/game/move/Thunder.mp3";
        } else if (ExplosionObject === "StoneExplosion") {
            objectCss = "custom";
            ObjectSrc = "/assets/images/explosion/stone.png";
            MoveSound = "/assets/sounds/game/move/stone_arrow.mpeg";
            isSpan = true;
        } else if (ExplosionObject === "WaterExplosion") {
            objectCss = "custom";
            ObjectSrc = "/assets/images/explosion/missileVector.png";
            MoveSound = "/assets/sounds/game/move/water.mpeg";
        } else if (ExplosionObject === "GazExplosion") {
            objectCss = "FierShoot";
            MoveSound = "/assets/sounds/game/move/bomb.wav";
        } else if (ExplosionObject === "MagicExplosion") {
            objectCss = "MagicShoot";
            MoveSound = "/assets/sounds/game/move/magic.mpeg";
        }
        return { objectCss, ObjectSrc, MoveSound, isSpan };
    }

    render() {
        const { Question } = this.props;

        if (Question &&
            Question.CustomStyle &&
            Question.CustomStyle.gamesSetting) {

            let Cursor = "";
            if (Question.CustomStyle.gamesSetting.Cursor !== 'custom' && Question.CustomStyle.gamesSetting.Cursor !== 'none' && Question.CustomStyle.gamesSetting.Cursor) {
                Cursor = process.env.PUBLIC_URL + Question.CustomStyle.gamesSetting.Cursor;
            } else if (Question.CustomStyle.gamesSetting.Cursor === 'custom' && Question.CustomStyle.gamesSetting.CursorCustom) {
                Cursor = Question.CustomStyle.gamesSetting.CursorCustom;
            }

            let cssCursor = "";
            if (Question.Type.Id === 17 ||
                Question.Type.Id === 18 ||
                Question.Type.Id === 21 ||
                Question.Type.Id === 24 ||
                Question.Type.Id === 25 ||
                Question.Type.Id === 26 ||
                Question.Type.Id === 27 ||
                Question.Type.Id === 28 ||
                Question.Type.Id === 29 ||
                Question.Type.Id === 30 ||
                Question.Type.Id === 31) {
                cssCursor = "none";
            } else if (Question.Type.Id === 16 || Question.Type.Id === 19 || Question.Type.Id === 22 || Question.Type.Id === 23) {
                cssCursor = Cursor ? `url('${Cursor}') 20 20, auto` : "crosshair"
            } else {
                cssCursor = Cursor ? `url('${Cursor}') 20 20, auto` : "auto"
            }

            let styleBackground = {
                userSelect: 'none',
                height: "100vh",
                opacity: `${Question.CustomStyle.gamesSetting.Opacity}%`,
                backgroundColor: Question.CustomStyle.gamesSetting.backgroundColor !== 'transparent' ? Question.CustomStyle.gamesSetting.backgroundColor : "",
                backgroundImage: Question.CustomStyle.gamesSetting.backgroundImage !== '' ? `url('${Question.CustomStyle.gamesSetting.backgroundImage}')` : "",
                backgroundPositionX: `${Question.CustomStyle.gamesSetting.BgPositionX}%`,
                backgroundPositionY: `${Question.CustomStyle.gamesSetting.BgPositionY}%`,
                backgroundSize: `${Question.CustomStyle.gamesSetting.BgSize}`,
                backgroundRepeat: `${Question.CustomStyle.gamesSetting.BgRepeat}`,
                backgroundAttachment: `${Question.CustomStyle.gamesSetting.BgAttachment}`,
                cursor: cssCursor,
                filter: this.state.pause ? "blur(4px)" : ""
            };

            return <>
                <Helmet>
                    {
                        Question.CustomStyle.gamesSetting.backgroundImage !== '' ?
                            <link rel="prefetch" as="image" href={Question.CustomStyle.gamesSetting.backgroundImage} />
                            : ""
                    }
                    {
                        this.props.orientation === "landscape" ?
                            <meta name="viewport" content="width=1450, user-scalable=0" />
                            :
                            <meta name="viewport" content="width=770, user-scalable=0" />
                    }
                </Helmet>
                {
                    (Question.Type.Id === 21 || Question.Type.Id === 22 || Question.Type.Id === 23 || Question.Type.Id === 24 || Question.Type.Id === 26 || Question.Type.Id === 29 || Question.Type.Id === 30 || Question.Type.Id === 29) && this.props.orientation === "portrait" ?
                        <div className={`position-absolute w-100 h-100 d-flex justify-content-center align-items-center`} style={{
                            cursor: `url('${Cursor}') 20 20, auto`,
                            zIndex: 9999
                        }}>
                            <ResultScreen
                                type="rotate"
                                CustomStyle={this.props.CustomStyle.StopScreen} />
                        </div>
                        : ""
                }
                {
                    this.state.pause && !this.state.showInfo ?
                        <div className={`position-absolute w-100 h-100 d-flex justify-content-center align-items-center`} style={{
                            cursor: `url('${Cursor}') 20 20, auto`,
                            zIndex: 9999
                        }}>
                            <ResultScreen
                                type="stop"
                                reStartGame={() => this.replay()}
                                goBack={() => this.props.goBack()}
                                CustomStyle={this.props.CustomStyle.StopScreen} />
                        </div>
                        : ""
                }
                {
                    this.state.pause && this.state.showInfo ?
                        <div className={`position-absolute w-100 h-100 d-flex justify-content-center align-items-center`} style={{
                            cursor: `url('${Cursor}') 20 20, auto`,
                            zIndex: 9999
                        }}>
                            <ResultScreen
                                type="level"
                                Time={Question.CustomStyle.gamesSetting.Time}
                                TimesUp={false}
                                isPaused={true}
                                level={this.props.level}
                                text={Question.Name}
                                LevelOfGame={Question.Level}
                                reStartGame={() => this.replay()}
                                goBack={() => this.replay()}
                                CustomStyle={this.props.CustomStyle.StartScreen} />
                        </div>
                        : ""
                }
                <section
                    ref={this.designerContainer}
                    className={`pos-rel w-100 oh p-0 ${this.state.Background !== '' ? "" : " call-in-action call-overlay min-h-full"}`}
                    style={styleBackground}
                    onMouseMove={e => this.handleMove(e)}
                    onClick={e => this.onClick(e)}
                    onDrag={e => {
                        this.handleTouchMove(e)
                    }}
                    onTouchMove={e => {
                        this.handleTouchMove(e)
                    }}
                    onMouseDown={e => this.onMouseDown(e)}
                    onMouseUp={e => this.onMouseUp(e)}
                >
                    <style dangerouslySetInnerHTML={{ __html: `.gameTitlePerview{${Question.CustomStyle.questionStyle.Custom}}` }}></style>
                    <GamePlay
                        {...this.props}
                        user={this.props.user.user}
                        pause={this.state.pause}
                        keyStatus={this.state.keyStatus}
                        clickNumber={this.state.clickNumber}
                        cursorPosition={this.state.cursorPosition}
                        cursorClickPosition={this.state.cursorClickPosition}
                        designerContainer={this.designerContainer}
                        getRandomObject={this.getRandomObject}
                        getExplosionObject={this.getExplosionObject}
                        replay={this.replay}
                        showInfo={this.showInfo}
                        goBack={this.goBack} />
                </section>
            </>
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(GamesView));
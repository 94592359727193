import React from "react";
import { withTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import Rating from "react-rating";
import { toast } from 'react-toastify';

import api from '../../../api/api';

class Rate extends React.Component {

    state = { rate: 5 }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    rate = () => {
        const { t } = this.props;

        let postData = {
            Rate: this.state.rate,
            IsForLesson: this.props.IsForLesson
        }

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/Rate/${this.props.id}`, postData).then(response => {

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-star"></i> {t('Success.rate.Title')}</div> <div className="h6">{t('Success.rate.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.props.hideRate();

        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    setRate = rate => {
        this.setState({
            rate
        });
    }

    render() {
        const { t } = this.props;

        return <Modal
            centered
            size="md"
            show={true}
            onHide={this.props.showRate}
            backdrop="static"
            keyboard={false}
            enforceFocus={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="icon fas fa-star text-warning"></i>
                    {t('Pages.Lessons.tools.rate')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <Rating
                        initialRating={this.state.rate}
                        quiet={false}
                        direction="rtl"
                        emptySymbol="fal fa-star fa-3x text-warning"
                        fullSymbol="fas fa-star fa-3x text-warning"
                        fractions={2}
                        onChange={(rate) => this.setRate(rate)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-center w-100 align-items-center  ">
                    <button className="custom-button" onClick={() => this.rate()} >
                        <span>
                            <i className="icon fas fa-star"></i>
                            {t('Pages.Lessons.tools.rate')}
                        </span>
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

export default withTranslation('common')(Rate);
import React from "react";
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class DragDrop extends React.Component {

    state = { items: [] };
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.setState({
            items: this.props.items
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.items) {
            state.items = props.items;
        }
        return state;
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });

        this.props.setSort(items);
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    getListStyle = isDraggingOver => ({
        background: !isDraggingOver ? "rgb(246 250 249)" : "#fff",
        padding: 10,
        border: ' 5px dashed rgb(255, 255, 255)'
    });

    getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 16,
        margin: `0 0 ${8}px 0`,
        position: 'static !important',
        // change background colour if dragging
        borderBottom: isDragging ? "2px dashed lightgreen" : "2px solid rgb(14, 44, 83)",
    });

    render() {
        if (this.state.items) {
            return (
                <>
                    <DragDropContext onDragEnd={this.onDragEnd} className="position-relative align-self-cente">
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={this.getListStyle(snapshot.isDraggingOver)} >
                                    {this.state.items.map((item, index) => (
                                        <Draggable key={item.Id} draggableId={`l${item.Id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )} >
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <i className=" icon fas fa-arrows-alt"></i> <div dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            );
        }
        return "";
    }
}
export default withTranslation('common')(DragDrop);
import React from "react";
import {
    Switch,
    Route
} from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Detector } from "react-detect-offline";

import HeaderTop from "./Header/HeaderTop";
import HeaderBottom from "./Header/HeaderBottom";

import Home from "./Home/Home";
import HomeSlider from "./Home/HomeSlider";
import About from "./Home/About";
import Contact from "./Home/Contact";
import Slider from "./Home/Slider";
import NotFound from "./Home/NotFound";
import NoPermissions from "./Home/NoPermissions";
import Tracks from "./Home/Tracks/Tracks";
import Books from "./Home/Tracks/Books";
import BookLessons from "./Home/Tracks/BookLessons";
import Lessons from "./Home/Tracks/Lessons";
import Files from "./Home/Tracks/Files";
import LessonView from "./Home/Tracks/LessonView/LessonView";
import PDFView from "./Home/Tracks/LessonView/PDFView";
import FilePDFView from "./Home/Tracks/LessonView/FilePDFView";
import Exams from "./Home/Tracks/Exams";
import Quizzes from "./Home/Tracks/Quizzes";
import ExamView from "./Home/Tracks/ExamView/";
import GameView from "./Home/Tracks/GameView/";
import CompetitionView from "./Home/Tracks/CompetitionView/";
import Register from "./Home/Register";
import SingleRegister from "./Home/Registration/SingleRegister/";
import SchoolRegister from "./Home/Registration/SchoolRegister/";
import ContactSupportConfirmation from "./Home/Registration/SchoolRegister/ContactSupportConfirmation.js";
import Registration from "./Home/Registration/";
import RegistrationSchool from "./Home/Registration/RegistrationSchool.js";
import Login from "./Home/Login";
import PayBill from "./Home/PayBill";
import Search from "./Home/Search";
import AfterPurchase from "./Home/AfterPurchase";
import PayproAfterPurchase from "./Home/PayproAfterPurchase";
import ForgetPassword from "./Home/ForgetPassword";
import ResetPassword from "./Home/ResetPassword";
import Games from "./Home/Tracks/Games";
import CompetitionStage from "./Home/Tracks/CompetitionStage/";
import CompetitionInfo from "./Home/Tracks/CompetitionStage/CompetitionInfo.js";
import Prices from "./Home/Prices";
import UserGuide from "./Home/UserGuide/";
import Article from "./Home/UserGuide/Article.js";

import Logout from "./Home/User/Logout";
import Profile from "./Home/User/Profile";
import ProfileEdit from "./Home/User/ProfileEdit";
import ActivePage from "./Home/User/ActivePage";
import RecognizedBrowser from "./Home/User/RecognizedBrowser";
import ProfileChangePassword from "./Home/User/ChangePassword";
import ChangeProfilePicture from "./Home/User/ProfilePicture";
import UserFavoriteLesson from "./Home/User/UserFavoriteLesson";
import UserFavoriteFile from "./Home/User/UserFavoriteFile";
import UserHistoryLessons from "./Home/User/UserHistoryLessons";
import UserHistoryFiles from "./Home/User/UserHistoryFiles";
import UserBills from "./Home/User/UserBills";
import UserControl from "./Home/User/UserControl";
import UserRenwal from "./Home/User/Renwal";
import SubUsers from "./Home/User/SubUsers";
import UserMarks from "./Home/User/UserMarks";

import Indices from "./Admin/Indices/Indices";
import ControlPanel from "./Admin/ControlPanel";
import LessonEdit from "./Admin/Lessons/LessonEdit";
import QuizzesEdit from "./Admin/Quizzes/QuizzesEdit";
import ExamsEdit from "./Admin/Exams/ExamsEdit";
import BookLessonEdit from "./Admin/Books/BookLessonEdit";
import LessonsList from "./Admin/Lessons/Lessons";
import FilesList from "./Admin/Files/Files";
import BooksList from "./Admin/Books/Books";
import ExamsList from "./Admin/Exams/Exams";
import SelfDevelopmentList from "./Admin/SelfDevelopment/";
import SelfDevelopmentEdit from "./Admin/SelfDevelopment/SelfDevelopmentEdit";

import BookLessonsList from "./Admin/Books/BookLessons";
import QuizzesList from "./Admin/Quizzes/Quizzes";
import FunList from "./Admin/Games";
import GamesEdit from "./Admin/Games/GamesEdit";

import UsersList from "./Admin/Users/";
import SchoolsList from "./Admin/Schools/";
import SchoolsRegistrationList from "./Admin/RegistrationSchools/";
import OutSchoolsRegistrationList from "./Admin/RegistrationOutSchools/";
import MailBoxList from "./Admin/MailBox/";
import SearchResultList from "./Admin/SearchResult/";
import SlidersList from "./Admin/Sliders/";
import EmailContentList from "./Admin/EmailContent/";
import SchoolRank from "./Admin/SchoolRank/";
import OnlineUser from "./Admin/OnlineUser/";
import StudentRank from "./Admin/StudentRank/";
import UserRank from "./Admin/UserRank/";
import FileManagerList from "./Admin/FileManager/";
import BillsList from "./Admin/Bills/";
import SendEmail from "./Admin/SendEmail/";
import Templates from "./Admin/Templates/";
import GameTemplates from "./Admin/GameTemplates/";
import ErrorReport from "./Admin/ErrorReport/";
import UsersStatistics from "./Admin/Statistics/Users/";
import LessonsStatistics from "./Admin/Statistics/Lessons/";
import CompetitionStatistics from "./Admin/Statistics/Competition/";


import ClassRoomsList from "./School/ClassRooms/";
import TeachersList from "./School/Teachers/";
import MissionsList from "./School/Missions/";
import SharedLessons from "./School/SharedLessons/";
import SharedFiles from "./School/SharedFiles/";
import StudentRanksList from "./School/Ranks/";
import SchoolStatistics from "./School/Statistics/";
import TeachersStatistics from "./School/Teachers/Statistics.js";

import StudentBooksList from "./School/Student/Books";
import StudentLessonsList from "./School/Student/Lessons";
import StudentFilesList from "./School/Student/Files";
import StudentExamsList from "./School/Student/Exams";
import StudentQuizzesList from "./School/Student/Quizzes";
import StudentMissionsList from "./School/Student/Missions";
import StudentDashBoard from "./School/Student/DashBoard";
import StudentGames from "./School/Student/Games";
import StudentCompetitionProfile from "./School/Student/CompetitionProfile";

import MissionView from './Home/Tracks/MissionView';

import history from '../history';
import { checkLogin } from './actions/';
import { UserChangeCurrentPage, SetSocket, registerOnilneUser } from '../api/socket.io';
import api from "../api/api";
import PricesSchool from "./Home/PricesSchool.js";
import AfterSprintful from "./Home/AfterSprintful.js";


class Layout extends React.Component {

    state = {
        isFirst: true,
        setCurrentPage: this.setCurrentPage.bind(this)
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        const version = localStorage.getItem('version');
        api.get(`/Register/?id=` + version).then(response => {
            if (version !== response.data.version) {
                localStorage.setItem('version', response.data.version);
                window.location.reload(true);
            }
        });
    }

    componentDidMount() {
        this.props.checkLogin();
    }

    renderAllUserComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (SComponent !== ActivePage &&
                SComponent !== Logout &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === false) {
                return <ActivePage {...props} socket={this.props.socket} />;
            } else if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    renderLoginComponent = (SComponent, props) => {
        if (this.props.user && this.props.user.isLogin === true) {
            return <Profile {...props} socket={this.props.socket} />;
        }
        return <SComponent {...props} socket={this.props.socket} />
    }

    static getDerivedStateFromProps(props, state) {
        setTimeout(() => {
            window.AOS.init();
        }, 500);

        if (props.user && props.user.user) {
            if (state.isFirst) {
                window.addEventListener("focus", () => state.setCurrentPage(document.title, window.location.pathname, props.user.user.Id));
                state.isFirst = false;
            }
            history.listen((location) => {
                setTimeout(() => {
                    state.setCurrentPage(document.title, location.pathname, props.user.user.Id);
                }, 500);
            });
            props.socket.on('connect', () => {
                registerOnilneUser(props.user.user);
                SetSocket(props.user.user.Id, props.socket.id);
            });
            props.socket.io.on('reconnect', () => {
                registerOnilneUser(props.user.user);
                SetSocket(props.user.user.Id, props.socket.id);
            });
        }

        return state;
    }

    setCurrentPage(title, pathname, UserId) {
        UserChangeCurrentPage(title, pathname, UserId);
    }

    onChangeStatus = online => {
        const { t } = this.props;
        if (this._toastId && this._toastId.current) {
            toast.dismiss(this._toastId.current);
        }
        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: 500
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            closeOnClick: true,
            autoClose: 500
        });
        if (online) {
            toast.dismiss(this._toastId.current);
        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                autoClose: 5000
            });
        }

        return "";
    }

    renderAdminComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id !== 1
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    renderManagerComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id !== 1
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    renderSchoolAdminComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id !== 3
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id === 3 &&
                this.props.user.user.School &&
                this.props.user.user.School.Id === 1
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    renderStudentComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id !== 5
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    renderUserComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id !== 2
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    renderTeacherComponent = (SComponent, props) => {
        if (!this.props.user || this.props.user.length === 0 || this.props.user.isLogin === false) {
            return <Login {...props} socket={this.props.socket} />;
        } else {
            if (
                SComponent === ActivePage &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.IsActive === true &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                SComponent !== RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code === "browser_is_not_recognized") {
                return <RecognizedBrowser  {...props} socket={this.props.socket} />;
            } else if (
                SComponent === RecognizedBrowser &&
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.code !== "browser_is_not_recognized") {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                this.props.user.user.UserType.Id !== 3 &&
                this.props.user.user.UserType.Id !== 4
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            } else if (
                this.props.user &&
                this.props.user.user &&
                this.props.user.user.UserType &&
                (this.props.user.user.UserType.Id === 3 || this.props.user.user.UserType.Id === 4) &&
                this.props.user.user.School &&
                this.props.user.user.School.Id === 1
            ) {
                return <Profile {...props} socket={this.props.socket} />;
            }
            return <SComponent {...props} socket={this.props.socket} />
        }
    }

    render() {
        const { user } = this.props;

        const version = localStorage.getItem('version');
        return (
            <>
                <header>
                    <HeaderTop isLogin={user.isLogin} user={user.user}></HeaderTop>
                    <HeaderBottom />
                </header>
                <div id="PageTop">
                    <Detector
                        polling={{
                            url: process.env.REACT_APP_SITE_URL + "/api/Register/?id=" + version
                        }}
                        render={({ online }) => this.onChangeStatus(online)} />
                    <Switch>

                        <Route exact path="/Tracks" component={Tracks} />
                        <Route exact path="/Tracks/Books" component={Books} />
                        <Route exact path="/Tracks/Books/:indexId" component={Books} />
                        <Route exact path="/Tracks/Lessons" component={Lessons} />
                        <Route exact path="/Tracks/Lessons/:indexId" component={Lessons} />
                        <Route exact path="/Tracks/BookLessons" component={BookLessons} />
                        <Route exact path="/Tracks/BookLessons/:indexId" component={BookLessons} />
                        <Route exact path="/Tracks/Files" component={Files} />
                        <Route exact path="/Tracks/Files/:indexId" component={Files} />
                        <Route exact path="/Tracks/Exams" component={Exams} />
                        <Route exact path="/Tracks/Exams/:indexId" component={Exams} />
                        <Route exact path="/Tracks/Quizzes" component={Quizzes} />
                        <Route exact path="/Tracks/Quizzes/:indexId" component={Quizzes} />

                        <Route exact path="/" component={Home} />
                        <Route exact path="/Home" component={Home} />
                        <Route exact path="/HomeSlider" component={HomeSlider} />
                        <Route exact path="/About" component={About} />
                        <Route exact path="/Slider/:id" component={Slider} />

                        <Route exact path="/Books/:id" component={Books} />
                        <Route exact path="/Books/:id/:indexId" component={Books} />
                        <Route exact path="/BookLessons/:id/:indexId" component={BookLessons} />
                        <Route exact path="/Lessons/:id" component={Lessons} />
                        <Route exact path="/Lessons/:id/:indexId" component={Lessons} />
                        <Route exact path="/Files/:id" component={Files} />
                        <Route exact path="/Files/:id/:indexId" component={Files} />
                        <Route exact path="/Exams/:id" component={Exams} />
                        <Route exact path="/Exams/:id/:indexId" component={Exams} />
                        <Route exact path="/Quizzes/:id" component={Quizzes} />
                        <Route exact path="/Quizzes/:id/:indexId" component={Quizzes} />

                        <Route exact path="/ExamView/:id" component={ExamView} />
                        <Route exact path="/SelfDevelopment/:id" component={ExamView} />
                        <Route exact path="/GameView/:id" component={GameView} />
                        <Route exact path="/LessonView/:id" component={LessonView} />
                        <Route exact path="/PDFView/:id" component={PDFView} />
                        <Route exact path="/FilePDFView/:id" component={FilePDFView} />
                        <Route exact path="/Logout" component={Logout} />
                        <Route exact path="/Search" component={Search} />
                        <Route exact path="/Search/:id" component={Search} />
                        <Route exact path="/PayproAfterPurchase/" component={PayproAfterPurchase} />
                        <Route exact path="/AfterPurchase/" component={AfterPurchase} />
                        <Route exact path="//AfterPurchase/" component={AfterPurchase} />
                        <Route exact path="/After/Sprintful" component={AfterSprintful} />
                        <Route exact path="/NoPermissions" component={NoPermissions} />
                        <Route exact path="/Contact" component={Contact} />
                        <Route exact path="/PayBill/" component={PayBill} />
                        <Route exact path="/Games/" component={Games} />
                        <Route exact path="/Games/:indexId" component={Games} />
                        <Route exact path="/CompetitionStage/" component={CompetitionStage} />
                        <Route exact path="/CompetitionInfo/" component={CompetitionInfo} />
                        <Route exact path="/Prices" component={Prices} />
                        <Route exact path="/Prices/School" component={PricesSchool} />
                        <Route exact path="/ContactSupportConfirmation" component={ContactSupportConfirmation} />
                        <Route exact path="/User/UserGuide/" component={UserGuide} />
                        <Route exact path="/User/UserGuide/:id" component={UserGuide} />
                        <Route exact path="/User/UserGuides/Article/:id" component={Article} />

                        <Route exact path="/ResetPassword/" component={(props) => this.renderLoginComponent(ResetPassword, props)} />
                        <Route exact path="/Register" component={(props) => this.renderLoginComponent(Register, props)} />
                        <Route exact path="/Register/Single" component={(props) => this.renderLoginComponent(SingleRegister, props)} />
                        <Route exact path="/Register/School" component={(props) => this.renderLoginComponent(SchoolRegister, props)} />
                        <Route exact path="/Registration" component={(props) => this.renderLoginComponent(Registration, props)} />
                        <Route exact path="/Registration/School" component={(props) => this.renderLoginComponent(RegistrationSchool, props)} />
                        <Route exact path="/Login" component={(props) => this.renderLoginComponent(Login, props)} />
                        <Route exact path="/ForgetPassword" component={(props) => this.renderLoginComponent(ForgetPassword, props)} />

                        {/* //All Users */}
                        <Route exact path="/User/Mark" component={(props) => this.renderAllUserComponent(UserMarks, props)} />
                        <Route exact path="/User/History/Lessons" component={(props) => this.renderAllUserComponent(UserHistoryLessons, props)} />
                        <Route exact path="/User/History/Files" component={(props) => this.renderAllUserComponent(UserHistoryFiles, props)} />
                        <Route exact path="/User/Favorite/Files" component={(props) => this.renderAllUserComponent(UserFavoriteFile, props)} />
                        <Route exact path="/User/Favorite/Lesson" component={(props) => this.renderAllUserComponent(UserFavoriteLesson, props)} />
                        <Route exact path="/RecognizedBrowser" component={(props) => this.renderAllUserComponent(RecognizedBrowser, props)} />
                        <Route exact path="/ActivePage" component={(props) => this.renderAllUserComponent(ActivePage, props)} />
                        <Route exact path="/Profile" component={(props) => this.renderAllUserComponent(Profile, props)} />
                        <Route exact path="/Profile/Edit" component={(props) => this.renderAllUserComponent(ProfileEdit, props)} />
                        <Route exact path="/Profile/ChangePassword" component={(props) => this.renderAllUserComponent(ProfileChangePassword, props)} />
                        <Route exact path="/Profile/Picture" component={(props) => this.renderAllUserComponent(ChangeProfilePicture, props)} />
                        <Route exact path="/User/Control" component={(props) => this.renderAllUserComponent(UserControl, props)} />
                        <Route exact path="/User/Renwal" component={(props) => this.renderAllUserComponent(UserRenwal, props)} />

                        {/* //admin */}
                        <Route exact path="/ControlPanel/Quizzes/Edit/:id" component={(props) => this.renderManagerComponent(QuizzesEdit, props)} />
                        <Route exact path="/ControlPanel/Exams/Edit/:id" component={(props) => this.renderManagerComponent(ExamsEdit, props)} />
                        <Route exact path="/ControlPanel/Books/Edit/:id" component={(props) => this.renderManagerComponent(BookLessonEdit, props)} />
                        <Route exact path="/ControlPanel/Lesson/Edit/:id" component={(props) => this.renderManagerComponent(LessonEdit, props)} />
                        <Route exact path="/ControlPanel/Games/Edit/:id" component={(props) => this.renderManagerComponent(GamesEdit, props)} />
                        <Route exact path="/ControlPanel/Indices" component={(props) => this.renderManagerComponent(Indices, props)} />
                        <Route exact path="/ControlPanel/Exams/:id" component={(props) => this.renderManagerComponent(ExamsList, props)} />
                        <Route exact path="/ControlPanel/Exams" component={(props) => this.renderManagerComponent(ExamsList, props)} />
                        <Route exact path="/ControlPanel/Quizzes/:id" component={(props) => this.renderManagerComponent(QuizzesList, props)} />
                        <Route exact path="/ControlPanel/Quizzes" component={(props) => this.renderManagerComponent(QuizzesList, props)} />
                        <Route exact path="/ControlPanel/Lessons/:id" component={(props) => this.renderManagerComponent(LessonsList, props)} />
                        <Route exact path="/ControlPanel/Lessons" component={(props) => this.renderManagerComponent(LessonsList, props)} />
                        <Route exact path="/ControlPanel/Files" component={(props) => this.renderManagerComponent(FilesList, props)} />
                        <Route exact path="/ControlPanel/Files/:id" component={(props) => this.renderManagerComponent(FilesList, props)} />
                        <Route exact path="/ControlPanel/Books" component={(props) => this.renderManagerComponent(BooksList, props)} />
                        <Route exact path="/ControlPanel/Books/:id" component={(props) => this.renderManagerComponent(BooksList, props)} />
                        <Route exact path="/ControlPanel/BookLessons/:id" component={(props) => this.renderManagerComponent(BookLessonsList, props)} />
                        <Route exact path="/ControlPanel" component={(props) => this.renderManagerComponent(ControlPanel, props)} />
                        <Route exact path="/ControlPanel/Games" component={(props) => this.renderManagerComponent(FunList, props)} />
                        <Route exact path="/ControlPanel/Games/:id" component={(props) => this.renderManagerComponent(FunList, props)} />
                        <Route exact path="/ControlPanel/SelfDevelopment/:id" component={(props) => this.renderManagerComponent(SelfDevelopmentList, props)} />
                        <Route exact path="/ControlPanel/SelfDevelopment" component={(props) => this.renderManagerComponent(SelfDevelopmentList, props)} />
                        <Route exact path="/ControlPanel/SelfDevelopment/Edit/:id" component={(props) => this.renderManagerComponent(SelfDevelopmentEdit, props)} />

                        {/* //admin */}
                        <Route exact path="/ControlPanel/Users" component={(props) => this.renderAdminComponent(UsersList, props)} />
                        <Route exact path="/ControlPanel/Schools" component={(props) => this.renderAdminComponent(SchoolsList, props)} />
                        <Route exact path="/ControlPanel/Schools/Registration" component={(props) => this.renderAdminComponent(SchoolsRegistrationList, props)} />
                        <Route exact path="/ControlPanel/OutSchools/Registration" component={(props) => this.renderAdminComponent(OutSchoolsRegistrationList, props)} />
                        <Route exact path="/ControlPanel/MailBox" component={(props) => this.renderAdminComponent(MailBoxList, props)} />
                        <Route exact path="/ControlPanel/SearchResult" component={(props) => this.renderAdminComponent(SearchResultList, props)} />
                        <Route exact path="/ControlPanel/Sliders" component={(props) => this.renderAdminComponent(SlidersList, props)} />
                        <Route exact path="/ControlPanel/EmailContent" component={(props) => this.renderAdminComponent(EmailContentList, props)} />
                        <Route exact path="/ControlPanel/FileManager" component={(props) => this.renderAdminComponent(FileManagerList, props)} />
                        <Route exact path="/ControlPanel/Bills" component={(props) => this.renderAdminComponent(BillsList, props)} />
                        <Route exact path="/ControlPanel/SendEmail" component={(props) => this.renderAdminComponent(SendEmail, props)} />
                        <Route exact path="/ControlPanel/Templates" component={(props) => this.renderAdminComponent(Templates, props)} />
                        <Route exact path="/ControlPanel/Game/Templates" component={(props) => this.renderAdminComponent(GameTemplates, props)} />
                        <Route exact path="/ControlPanel/SchoolRank" component={(props) => this.renderAdminComponent(SchoolRank, props)} />
                        <Route exact path="/ControlPanel/StudentRank" component={(props) => this.renderAdminComponent(StudentRank, props)} />
                        <Route exact path="/ControlPanel/UserRank" component={(props) => this.renderAdminComponent(UserRank, props)} />
                        <Route exact path="/ControlPanel/Statistics" component={(props) => this.renderAdminComponent(UsersStatistics, props)} />
                        <Route exact path="/ControlPanel/OnlineUser" component={(props) => this.renderAdminComponent(OnlineUser, props)} />
                        <Route exact path="/ControlPanel/ErrorReport" component={(props) => this.renderAdminComponent(ErrorReport, props)} />
                        <Route exact path="/ControlPanel/Competition/Statistics" component={(props) => this.renderAdminComponent(CompetitionStatistics, props)} />
                        <Route exact path="/ControlPanel/Statistics/Lessons" component={(props) => this.renderAdminComponent(LessonsStatistics, props)} />

                        {/* //school Admin */}
                        <Route exact path="/ControlPanel/ClassRooms" component={(props) => this.renderSchoolAdminComponent(ClassRoomsList, props)} />
                        <Route exact path="/ControlPanel/Teachers" component={(props) => this.renderSchoolAdminComponent(TeachersList, props)} />

                        {/* //school teacher */}
                        <Route exact path="/ControlPanel/Missions" component={(props) => this.renderTeacherComponent(MissionsList, props)} />
                        <Route exact path="/ControlPanel/Shared/Lessons/" component={(props) => this.renderTeacherComponent(SharedLessons, props)} />
                        <Route exact path="/ControlPanel/Shared/Files/" component={(props) => this.renderTeacherComponent(SharedFiles, props)} />
                        <Route exact path="/ControlPanel/School/StudentRank" component={(props) => this.renderTeacherComponent(StudentRanksList, props)} />
                        <Route exact path="/ControlPanel/School/Statistics" component={(props) => this.renderTeacherComponent(SchoolStatistics, props)} />
                        <Route exact path="/ControlPanel/Teachers/Statistics" component={(props) => this.renderTeacherComponent(TeachersStatistics, props)} />
                        <Route exact path="/ControlPanel/School/Lessons/Statistics" component={(props) => this.renderTeacherComponent(LessonsStatistics, props)} />
                        <Route exact path="/Competition/Statistics" component={(props) => this.renderTeacherComponent(CompetitionStatistics, props)} />

                        {/* //school student */}
                        <Route exact path="/Student/Books" component={(props) => this.renderStudentComponent(StudentBooksList, props)} />
                        <Route exact path="/Student/Lessons" component={(props) => this.renderStudentComponent(StudentLessonsList, props)} />
                        <Route exact path="/Student/Files" component={(props) => this.renderStudentComponent(StudentFilesList, props)} />
                        <Route exact path="/Student/Exams" component={(props) => this.renderStudentComponent(StudentExamsList, props)} />
                        <Route exact path="/Student/Quizzes" component={(props) => this.renderStudentComponent(StudentQuizzesList, props)} />
                        <Route exact path="/Student/Missions" component={(props) => this.renderStudentComponent(StudentMissionsList, props)} />
                        <Route exact path="/MissionView/:id" component={(props) => this.renderStudentComponent(MissionView, props)} />
                        <Route exact path="/Student/DashBoard/" component={(props) => this.renderStudentComponent(StudentDashBoard, props)} />
                        <Route exact path="/Student/Games/" component={(props) => this.renderStudentComponent(StudentGames, props)} />
                        <Route exact path="/Student/Game/Profile" component={(props) => this.renderStudentComponent(StudentCompetitionProfile, props)} />
                        <Route exact path="/CompetitionView" component={(props) => this.renderStudentComponent(CompetitionView, props)} />

                        {/* //Register Single User */}
                        <Route exact path="/User/SubUsers" component={(props) => this.renderUserComponent(SubUsers, props)} />
                        <Route exact path="/User/Bills" component={(props) => this.renderUserComponent(UserBills, props)} />

                        <Route component={NotFound} />
                    </Switch>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, { checkLogin })(withTranslation('common')(Layout));
import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

class TextFiled extends React.Component {

    state = {
        hoverStyle: '',
        style: '',
        text: '',
        isTrue: 2,
        Sort: 0,
        AnswerId: 0,
        hover: false,
        onEdit: false
    };

    constructor(props) {
        super(props);
        this.audio = new Audio();
    }

    componentDidMount() {
        const Sort = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("Sort"));
        const isTrue = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("isTrue"));
        this.setState({
            Sort,
            isTrue
        });

        if (this.props.answersList) {
            var answer = this.props.answersList.filter(a => a.Sort === Sort && a.Id === this.props.CategoryId)[0];
            if (answer) {
                this.setState({
                    text: answer.Answer
                });
            }
        }
    }

    playSound = () => {
        const CustomStyle = this.props.CustomStyle;
        if (CustomStyle && CustomStyle.Sound) {
            var hasItem = CustomStyle.Sound.filter(p => p.Id === this.props.CategoryId && p.Sort === (this.state.Sort - 1))[0];
            if (hasItem) {
                if (this.audio && !this.audio.paused) {
                    this.audio.pause();
                }
                if (hasItem.Url) {
                    this.audio = new Audio(hasItem.Url);
                    this.audio.play();
                }
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.answersList = props.answersList;
        return state;
    }

    emptyAnswer = () => {
        this.setState({
            text: ''
        });
        this.props.handleAnswerChange();
    }

    handleAnswerChange = e => {
        this.setState({
            text: e.target.value
        });
        this.props.handleAnswerChange();
        setTimeout(
            () => {
                window.webuiPopover();
            },
            500
        );
    }

    render() {
        const { t } = this.props;

        let answerIsTrue = "";
        if (this.state.isTrue === 1) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="mx-1 fad fa-check-circle text-success h6"></i>;
        } else if (this.state.isTrue === 0) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="mx-1 fas fa-exclamation-triangle text-danger h6"></i>;
        }

        let sound = "", width = 250, AnswerClass = "dark", Placeholder = "";
        if (this.props.CustomStyle) {
            const CustomStyle = this.props.CustomStyle;
            if (CustomStyle.Sound) {
                var hasItem = CustomStyle.Sound.filter(p => p.Id === this.props.CategoryId && p.Sort === (this.state.Sort - 1))[0];
                if (hasItem && hasItem.Url) {
                    sound = <button type="button" className="fillTextButton" onClick={e => this.playSound()}><i className={`fad ${this.props.SoundIconType} m-0 fa-volume text-${this.props.SoundIconClass} h5 cursor-pointer`} title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} ></i> </button>;
                }
            }
            if (CustomStyle.Answers) {
                var hasItemAnswer = CustomStyle.Answers.filter(p => p.Id === this.props.CategoryId && p.Sort === (this.state.Sort - 1))[0];
                if (hasItemAnswer) {
                    if (hasItemAnswer.Width) {
                        width = hasItemAnswer.Width;
                    }
                    if (hasItemAnswer.AnswerClass) {
                        AnswerClass = hasItemAnswer.AnswerClass;
                    }
                    if (hasItemAnswer.Placeholder) {
                        Placeholder = hasItemAnswer.Placeholder;
                    }
                }
            }
        }

        return (
            <>
                <span className="position-relative ">
                    <input
                        className={`m-1 fillTextInput text-${AnswerClass}`}
                        type="text"
                        id={`TextFiledAnswer-${this.props.CategoryId}-${this.state.Sort}`}
                        style={{
                            width: `${width}px`,
                            padding: `0 ${width / 15}px`
                        }}
                        value={this.state.text}
                        autoComplete="off"
                        onChange={e => this.handleAnswerChange(e)}
                        placeholder={Placeholder}
                        title={t('Pages.Lessons.Questions.forms.FillTextAnswer.title')} />
                    {
                        sound
                    }
                    {/* {
                        (this.state.text === '') ? sound :

                            <button type="button" className="fillTextButton d-none" onClick={e => this.emptyAnswer()}><i className={`text-danger mx-1 fad fa-ban cursor-pointer`} title={t('Pages.Lessons.Questions.forms.tools.emptyAnswer')}  ></i> </button>
                    } */}
                </span>
                {answerIsTrue}
            </>
        );
    }
}
export default withTranslation('common')(TextFiled);
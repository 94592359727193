import React from "react";
import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

import LottiiePalyer from "../../Loading/LottiiePalyer";

class ImportExcelForm extends React.Component {

    state = { errorList: [], showProcessing: false };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        const name = this.ExcelFile.value;
        let splitName = name.split('.');
        let ext = splitName[splitName.length - 1];
        if (ext !== "xlsx" && ext !== "xls") {
            errors.ExcelFile = 'Pages.ClassRoomsControl.Form.ExcelFile.error';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const formData = new FormData();
            formData.append(
                "myFile",
                this.state.SelectedFile
            );

            this.setState({ errorList: [], showProcessing: true });

            this.props.onSubmit(formData, this._toastId.current);
        }
        e.preventDefault();
    }

    onFileChange = event => {
        if (event.target.files[0]) {
            const name = event.target.files[0].name;
            let splitName = name.split('.');
            let ext = splitName[splitName.length - 1];
            if (ext === "xlsx" || ext === "xls") {
                this.setState({ SelectedFile: event.target.files[0], FileName: name, errorState: "text-success" });
            } else {
                const { t } = this.props;
                this.setState({ FileName: t('Pages.ClassRoomsControl.Form.ExcelFile.error'), errorState: 'text-danger' });
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {
                    this.state.showProcessing ?
                        <>
                            <div className="text-center title h4"> <i className="fa-spin fas fa-spinner "></i>  {t('Processing')}</div>
                            <LottiiePalyer></LottiiePalyer>
                        </> :
                        <>
                            <div className="px-1 mb-3">
                                <span className="btn btn-dark" onClick={() => this.props.download()}>
                                    <i className="icon fal fa-download"></i>
                                    {t('Pages.ClassRoomsControl.Form.download')}
                                </span>
                            </div>
                            <form method="POST" encType="multipart/form-data" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                                <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                                    <h4 className="alert-heading">{t('ErrorsList')}</h4>
                                    <div className="border-bottom border-white opacity-20"></div>
                                    <div className="alert-text">
                                        <ul className="list-unstyled mt-4">
                                            <li>
                                                <ul>
                                                    {this.state.errorList}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="form-group w-100 pos-rel" style={{
                                    height: '200px'
                                }}>
                                    <label className='text-dark font-size-h5 text-right w-100' style={{
                                        height: '100%'
                                    }} >{t('Pages.ClassRoomsControl.Form.ExcelFile.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div id="ExcelFileContainer">
                                        <i className="fad fa-file-excel text-success my-2"></i>
                                        {t('Pages.ClassRoomsControl.Form.ExcelFile.Placeholder')}
                                    </div>
                                    <input
                                        ref={ExcelFile => this.ExcelFile = ExcelFile}
                                        defaultValue=""
                                        id="ExcelFile"
                                        name="ExcelFile"
                                        onChange={this.onFileChange}
                                        type="file"
                                        required={true}
                                        className="form-control"
                                        placeholder={t('Pages.ClassRoomsControl.Form.ExcelFile.Placeholder')}
                                        title={t('Pages.ClassRoomsControl.Form.ExcelFile.title')}
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </div>

                                <label className={` font-size-h5 w-100 text-center pb-2 ${this.state.errorState}`} > {this.state.FileName}</label>

                                <div className="form-group w-100">
                                    <button className="custom-button mt-1 w-100"><span> <i className="fal fa-save"></i> {t('Pages.ClassRoomsControl.Form.Upload')}</span></button>
                                </div>
                            </form>
                        </>
                }

            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.ExcelFile) {
        errors.ExcelFile = 'Pages.ClassRoomsControl.Form.ExcelFile.error';
    }

    return errors;
}

const formImportExcel = reduxForm({
    form: 'ImportExcel',
    validate
});

export default withTranslation('common')(formImportExcel(ImportExcelForm));

import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import Background from "../Background/";
import GameObjects from "../GameObjects/";
import Tools from "./Tools";
import MoveBackgroundObject from "./MoveBackground";

class FallDownView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle
    };

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        return state;
    }

    render() {
        const { t, ViewGameResult, examAnswers } = this.props;


        let styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${this.state.CustomStyle.answerStyle.width}px`,
            minHeight: `${this.state.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`,
        };

        const CustomBackground = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.CustomBackground : "";

        const MoveObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObject : "";
        const MoveObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrc : "";
        const MoveObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrcHover : "";
        const MoveCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveCustomColor : "";

        const DropObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObject : "";
        const DropObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObjectSrc : "";
        const DropObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const DropCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropCustomColor : "";

        const Rotate = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.Rotate : "";
        const FlipX = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.FlipX : "";

        let Cursor = "";
        if (this.state.CustomStyle.gamesSetting.Cursor !== 'custom' && this.state.CustomStyle.gamesSetting.Cursor !== 'none' && this.state.CustomStyle.gamesSetting.Cursor) {
            Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.gamesSetting.Cursor;
        } else if (this.state.CustomStyle.gamesSetting.Cursor === 'custom' && this.state.CustomStyle.gamesSetting.CursorCustom) {
            Cursor = this.state.CustomStyle.gamesSetting.CursorCustom;
        }

        let styleBackground = {
            height: '500px',
            opacity: `${this.state.CustomStyle.gamesSetting.Opacity}%`,
            backgroundColor: this.state.CustomStyle.gamesSetting.backgroundColor !== 'transparent' ? this.state.CustomStyle.gamesSetting.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.gamesSetting.backgroundImage !== '' ? `url('${this.state.CustomStyle.gamesSetting.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.gamesSetting.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.gamesSetting.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.gamesSetting.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.gamesSetting.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.gamesSetting.BgAttachment}`,
            cursor: `url('${Cursor}') 20 20, auto`
        };

        if (ViewGameResult) {
            return <>
                <div className="widget">
                    <div className="widget-body">
                        <table className="table table-striped table-hover table-bordered">
                            <thead>
                                <tr className="text-center">
                                    <th>
                                        {t('Pages.Missions.table.answers')}
                                    </th>
                                    <th colSpan={2}>
                                        {t('Pages.Missions.table.userAnswer')}
                                    </th>
                                    <th>
                                        {t('Pages.Missions.table.result')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    examAnswers && examAnswers[0] ?
                                        examAnswers[0].map(item => {
                                            const answer = this.props.answers.filter(p => p.Id === item.Answer.Id)[0];
                                            const html = answer.Name;
                                            const splitAnswer = html.split('(*_)');
                                            const count = (html.match(/\(*_\)/g) || []).length;
                                            let words = [];
                                            for (let index = 0; index < count; index++) {
                                                let text = splitAnswer[index + 1] ? splitAnswer[index + 1].split('(_*)')[0] : "";
                                                words.push(text);
                                            }
                                            return <tr className="text-center" key={`Answer-${item.Answer.Id}-${item.Answer.Sort}`}>
                                                <td className="text-center" dangerouslySetInnerHTML={{
                                                    __html: words[item.Answer.Sort]
                                                }}></td>

                                                <td className="text-center" colSpan={2}>
                                                    {item.isTrue ? t('Pages.Lessons.Questions.forms.trueAnswer') : t('Pages.Lessons.Questions.forms.falseAnswer')}
                                                    <i className="far mx-2 fa-minus text-dark "></i>
                                                    {item.isIn ? t('Pages.Lessons.Questions.forms.selectedAnswer') : t('Pages.Lessons.Questions.forms.notSelectedAnswer')}
                                                </td>
                                                <td className="text-center">{item.isTrue && item.isIn ?
                                                    <i className="fas fa-check-square text-success mx-2" title={t('Pages.Lessons.Questions.forms.trueAnswer')}></i>
                                                    :
                                                    <i className="fas fa-times-square text-danger mx-2" title={t('Pages.Lessons.Questions.forms.falseAnswer')}></i>
                                                }</td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={4}>{t('Pages.Missions.table.NoAnswer')}</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="widget">
                    <div className="widget-body">
                        <table className="table table-striped table-hover table-bordered">
                            {this.props.renderFooter()}
                        </table>
                    </div>
                </div >
            </>
        }

        return (
            <div className={`w-100  mx-auto`} style={{
                userSelect: 'none'
            }}>
                <div className="bg-white w-100 position-relative"  >
                    <div className="w-100 position-relative oh" style={styleBackground}>
                        <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${this.state.CustomStyle.answerStyle.Custom} } .dragdrop-static-box{${this.state.CustomStyle.answerBoxStyle.Custom}}` }}></style>
                        <Tools
                            BounsTime={0}
                            BounsTry={0}
                            falseTry={0}
                            gamesSetting={this.state.CustomStyle.gamesSetting}></Tools>

                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column" style={{
                            zIndex: '15'
                        }}>
                            <div className=" w-100 position-relative d-flex justify-content-around align-items-center" >
                                <div>
                                    <GameObjects
                                        objectCss={MoveObject}
                                        ObjectSrc={MoveObjectSrc}
                                        ObjectHoverSrc={MoveObjectSrcHover}
                                        customColor={MoveCustomColor}
                                        style={styleAnswer}
                                        text={t("Pages.Games.Answer")}
                                        type="dragdrop-move"
                                    ></GameObjects>
                                </div>
                                <div>
                                    <GameObjects
                                        rotate={Rotate}
                                        flipX={FlipX}
                                        objectCss={DropObject}
                                        ObjectSrc={DropObjectSrc}
                                        ObjectHoverSrc={DropObjectSrcHover}
                                        customColor={DropCustomColor}
                                        style={{}}
                                        text={""}
                                        type="dragdrop-static"
                                    ></GameObjects>
                                </div>
                            </div>
                        </div>
                        <Background absolute={true} CustomBackground={CustomBackground} />
                        <MoveBackgroundObject CustomStyle={this.props.CustomStyle} Id={this.props.Id} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(FallDownView));
import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

import 'bootstrap-daterangepicker/daterangepicker.css';

import {
    fetchContacts as fetch,
    removeContacts as remove,
    undoContacts as undo,
    clearContacts as clear,
    seenContacts as seen,
    fetchReplies,
    addReply
} from './actions';
import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import MailItem from "./MailItem";
import api from '../../../api/api';

class MailBoxList extends React.Component {

    constructor(props) {
        super(props);
        window.scrollToTop();
        moment.locale('en');
        this.state = {
            search: '',
            date: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY')
        };
    }

    componentDidMount() {
        this.props.fetch(moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY'));
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));

        this.props.fetch(picker.startDate.format('DD/MM/YYYY'), picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    Sync = () => {
        this.props.fetch(this.state.startDate, this.state.endDate);
    }

    renderMails() {
        const { t, contactUs } = this.props;
        let isLoaded = false;

        if (contactUs) {
            isLoaded = contactUs.isLoaded;
        }

        let toRemove = [];
        if (contactUs) {
            toRemove = contactUs.toRemove;
        }

        if (contactUs && contactUs.contacts && contactUs.contacts.length > 0) {
            let filterContactUs = contactUs.contacts.filter(v => v.Sender.includes(this.state.search) || v.Title.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.MailBox.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterContactUs.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.MailBox.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.MailBox.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterContactUs.map(contact =>
                (toRemove && toRemove.includes(contact.Id)) ? "" :
                    <MailItem
                        key={contact.Id}
                        item={contact}
                        replies={contactUs.replies}
                        isLoaded={contactUs.repliesIsLoaded}
                        fetchReplies={this.props.fetchReplies}
                        IsSeen={this.IsSeen}
                        addReply={this.addReply}
                        remove={this.showDelete}
                    ></MailItem>
            );
        }

        if (contactUs && contactUs.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.MailBox.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.MailBox.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    addReply = item => {
        this.props.addReply(item.Id);
    }

    IsSeen = item => {
        this.props.seen(item.Id);
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteMail(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }
    deleteMail = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/ContactUs/${id}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.MailBox.toast.Delete.Title')}</div> <div className="h6">{t('Pages.MailBox.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, contactUs } = this.props;
        let isLoaded = false;
        if (contactUs) {
            isLoaded = contactUs.isLoaded;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">

                        <Helmet>
                            <title>
                                {t('Pages.MailBox.title')} | {t('alarabeyya')}
                            </title>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                        </Helmet>

                        <div className="row mt-30 mb-30-none">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.MailBox.title')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form d-flex">
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>
                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.MailBox.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.Sync()} title={t("Pages.MailBox.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderMails()}
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user,
        contactUs: state.contactUs
    };
};

export default connect(mapStateToProps, { fetch, seen, remove, undo, clear, fetchReplies, addReply })(withTranslation('common')(MailBoxList));

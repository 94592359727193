import React from "react";
import GameObjects from "../../GameObjects";
import './style.css'
class Grass extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Grass/bg.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute d-none d-sm-block" style={{
                bottom: `calc(50% - 255px)`,
                right: `10%`
            }}>
                <div className="position-relative">
                    <div className="position-absolute" style={{
                        top: `-110px`,
                        right: `-150px`
                    }}>
                        <img alt="book" src="/assets/images/Grass/b1.png" />
                    </div>
                    <div className="position-absolute" style={{
                        top: `-110px`,
                        left: `-150px`
                    }}>
                        <img alt="book" src="/assets/images/Grass/b2.png" />
                    </div>
                    <div className="position-absolute" style={{
                        bottom: `-130px`,
                        left: `-150px`
                    }}>
                        <img alt="book" src="/assets/images/Grass/b3.png" />
                    </div>
                    <div className="position-absolute" style={{
                        bottom: `-130px`,
                        right: `-150px`
                    }}>
                        <img alt="book" src="/assets/images/Grass/b4.png" />
                    </div>
                    <GameObjects objectCss="GirlOnGrass" status={this.props.currentStatus} FinishMove={this.FinishMove} />
                </div>
            </div>

            <div className="position-absolute user-select-none MoveButterfly1 d-none d-md-block" style={{
                left: '5%',
                top: '5%'
            }}><GameObjects objectCss="Butterfly" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute user-select-none MoveButterfly2 d-none d-md-block" style={{
                right: '5%',
                bottom: '5%'
            }}><GameObjects objectCss="Butterfly" customColor="#f00" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
        </div>
    }
}
export default Grass;
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import api, { apiBlob } from '../../../api/api';
import NameForm from "./Forms/NameForm";

class ListItem extends React.Component {

    state = { moreInfo: false, rename: false, show: false };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    handleClose = () => {
        this.setState({ rename: false, show: false });
    }

    rename = () => {
        this.setState({ rename: true });
    }

    edit = () => {
        this.setState({ show: true });
    }

    openFolder = () => {
        const { item } = this.props;
        if (item.type === "folder") {
            this.props.openFolder(item.id);
        }
    }

    renderThumb = () => {
        const { t, item } = this.props;
        return <div className="product-thumb">
            {
                item.type === "folder" ?
                    <div className="ffolder small yellow" onClick={() => this.openFolder()}>
                    </div>
                    :
                    item.attributes.extension.toLowerCase() === "gif" ||
                        item.attributes.extension.toLowerCase() === "jpg" ||
                        item.attributes.extension.toLowerCase() === "jepg" ||
                        item.attributes.extension.toLowerCase() === "png" ?
                        <LazyLoadImage src={process.env.REACT_APP_SITE_URL + item.attributes.path} alt={`${item.id}`} />
                        :

                        item.attributes.extension.toLowerCase() === "avi" ||
                            item.attributes.extension.toLowerCase() === "mkv" ||
                            item.attributes.extension.toLowerCase() === "mp4" ||
                            item.attributes.extension.toLowerCase() === "webm"
                            ?
                            <video className="w-100" controls>
                                <source src={process.env.REACT_APP_SITE_URL + item.attributes.path} />
                            </video>
                            :
                            item.attributes.extension.toLowerCase() === "ogg" ||
                                item.attributes.extension.toLowerCase() === "mp3" ||
                                item.attributes.extension.toLowerCase() === "wav"
                                ?
                                <audio className="w-100" controls>
                                    <source src={process.env.REACT_APP_SITE_URL + item.attributes.path} />
                                </audio >
                                :

                                <div className={`fi fi-size-xl fi-${item.attributes.extension}`}>
                                    <div className="fi-content">{item.attributes.extension}</div>
                                </div>
            }
            <div className="product-action-link">
                {
                    item.type === "folder" ?
                        <div className="my-1" onClick={() => this.openFolder()} title={t("Pages.FileManager.action.open")}>
                            <i className="far fa-folder-open"></i>
                        </div>
                        :
                        <>
                            <div className="my-1" onClick={() => this.download()} title={t("Pages.FileManager.action.download")}>
                                <i className="far fa-download"></i>
                            </div>
                            <div className="my-1" onClick={() => this.getLink()} title={t("Pages.FileManager.action.link")}>
                                <i className="far fa-link"></i>
                            </div>
                            {
                                item.attributes.extension.toLowerCase() === "gif" ||
                                    item.attributes.extension.toLowerCase() === "jpg" ||
                                    item.attributes.extension.toLowerCase() === "jepg" ||
                                    item.attributes.extension.toLowerCase() === "pdf" ||
                                    item.attributes.extension.toLowerCase() === "mp4" ||
                                    item.attributes.extension.toLowerCase() === "webm" ||
                                    item.attributes.extension.toLowerCase() === "avi" ||
                                    item.attributes.extension.toLowerCase() === "m4v" ||
                                    item.attributes.extension.toLowerCase() === "ogg" ||
                                    item.attributes.extension.toLowerCase() === "mp3" ||
                                    item.attributes.extension.toLowerCase() === "wav" ||
                                    item.attributes.extension.toLowerCase() === "png" ?
                                    <div className="my-1" onClick={() => this.edit()} title={t("Pages.FileManager.action.view")}>
                                        <i className="far fa-eye"></i>
                                    </div>
                                    : ""
                            }
                        </>
                }
                <div className="my-1" onClick={() => this.rename()} title={t("Pages.FileManager.action.rename")}>
                    <i className="far fa-exchange-alt"></i>
                </div>
                <div className="my-1" onClick={() => this.props.remove(item)} title={t("Pages.FileManager.action.remove")}><i className="far fa-trash"></i></div>
            </div>
        </div>
    }

    getLink = () => {
        const { t, item } = this.props;
        navigator.clipboard.writeText(process.env.REACT_APP_SITE_URL + item.attributes.path);

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        toast.update(_toastId, {
            render: () => <div><div className="h5"> <i className="icon fal fa-copy"></i> {t('Pages.FileManager.get_link_success_toast.Title')}</div> <div className="h6">{t('Pages.FileManager.get_link_success_toast.Body')}</div></div>,
            type: toast.TYPE.SUCCESS,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    download = () => {
        const { t, item } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        apiBlob.post(`/FileManager/?path=${item.attributes.path}`).then(response => {

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            const blob = new Blob([response.data]),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = item.attributes.name + '.' + item.attributes.extension;
            a.click();
            window.URL.revokeObjectURL(url);

            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitRename = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/FileManager/?path=${this.props.path}`,
            postData
        ).then(response => {
            this.props.Sync(this.props.path);

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-exchange-alt"></i>
                        {t('Pages.FileManager.rename_success_toast.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.FileManager.rename_success_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                rename: false
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, item } = this.props;
        if (item) {

            return (
                <>
                    {
                        this.state.rename ?
                            <Modal
                                size="md"
                                show={this.state.rename}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false} >
                                <Modal.Header closeButton>
                                    <Modal.Title> <i className="icon fal fa-exchange-alt"></i> {t('Pages.FileManager.action.rename')} </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <NameForm
                                                id={item.id}
                                                Title={item.attributes.name}
                                                path={this.props.path}
                                                onSubmit={this.onSubmitRename}
                                            ></NameForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        item.type === "folder" ? "" :
                            this.state.show ?
                                <Modal
                                    size="xl"
                                    show={this.state.show}
                                    onHide={this.handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    enforceFocus={false} >
                                    <Modal.Header closeButton>
                                        <Modal.Title> <i className="icon fal fa-eye"></i> {item.attributes.name} </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="text-center">
                                            {
                                                item.attributes.extension.toLowerCase() === "gif" ||
                                                    item.attributes.extension.toLowerCase() === "jpg" ||
                                                    item.attributes.extension.toLowerCase() === "jepg" ||
                                                    item.attributes.extension.toLowerCase() === "png" ?
                                                    <LazyLoadImage src={process.env.REACT_APP_SITE_URL + item.attributes.path} alt={`${item.id}`} />
                                                    : ""
                                            }
                                            {
                                                item.attributes.extension.toLowerCase() === "pdf" ?
                                                    <iframe className="w-100" scrolling="no" height="700px" frameBorder="0" title={item.attributes.name} key={`s-${item.attributes.name}`} src={process.env.REACT_APP_SITE_URL + item.attributes.path}></iframe>
                                                    : ""
                                            }
                                            {
                                                item.attributes.extension.toLowerCase() === "avi" ||
                                                    item.attributes.extension.toLowerCase() === "mkv" ||
                                                    item.attributes.extension.toLowerCase() === "mp4" ||
                                                    item.attributes.extension.toLowerCase() === "webm"
                                                    ?
                                                    <video className="w-100" controls>
                                                        <source src={process.env.REACT_APP_SITE_URL + item.attributes.path} />
                                                    </video>
                                                    : ""
                                            }
                                            {
                                                item.attributes.extension.toLowerCase() === "ogg" ||
                                                    item.attributes.extension.toLowerCase() === "mp3" ||
                                                    item.attributes.extension.toLowerCase() === "wav"
                                                    ?
                                                    <audio className="w-100" controls>
                                                        <source src={process.env.REACT_APP_SITE_URL + item.attributes.path} />
                                                    </audio >
                                                    : ""
                                            }
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                : ""

                    }
                    <div className="col-xl-4 col-md-6">
                        <div className="shop-item" title={item.id}>
                            {this.renderThumb()}
                            <div className="product-content">
                                <h5 className="title" onClick={() => item.type === "folder" ? this.openFolder() : this.handleClose()}>{item.attributes.name} </h5>
                            </div>
                        </div>
                        <div className="product-list-item" title={item.id}>
                            {this.renderThumb()}
                            <div className="product-content">
                                <h5 className="title" onClick={() => item.type === "folder" ? this.openFolder() : this.handleClose()}>{item.attributes.name} </h5>
                                <p>
                                    {
                                        item.attributes.size ?
                                            <span title={t('Pages.FileManager.size')}>
                                                <i className="icon fal fa-hdd"></i>
                                                {Math.round(item.attributes.size / 1024, 2)} KB
                                            </span>
                                            : ""
                                    }
                                </p>
                                <div className="d-flex justify-content-between ">
                                    <p>
                                        <span className="ml-3" title={t('Pages.FileManager.CreationDate')} >
                                            <i className="icon fal fa-calendar-alt"></i>
                                            {item.attributes.created}
                                        </span>

                                        <span className="ml-3" title={t('Pages.FileManager.UpdateDate')} >
                                            <i className="icon fal fa-calendar-alt"></i>
                                            {item.attributes.modified}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(ListItem);
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from "moment";

import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import { fetchRanks as fetch } from '../../Home/actions/';
import DateRangePicker from "react-bootstrap-daterangepicker";
import configData from "../../Config/countries.json";

class UserRank extends React.Component {

    state = {
        countries: [],
        PointMonth: parseInt(moment().format('MM')),
        PointYear: parseInt(moment().format('YYYY'))
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.map(item => {
            return {
                value: item.value,
                name: item.label,
                img: <LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />,
            };
        });
    }

    componentDidMount() {
        this.Sync();
        window.scrollToTop();
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/YYYY'));
        this.setState({
            PointMonth: parseInt(picker.startDate.format('MM')),
            PointYear: parseInt(picker.startDate.format('YYYY'))
        });
    }

    renderStudents() {
        const { t, ranks } = this.props;

        if (ranks &&
            ranks.ranks &&
            ranks.ranks.Point &&
            ranks.ranks.Point.length > 0) {

            const ListOfColor = ["warning", "primary ", "orange"];
            const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd"];

            var filterSchools = ranks.ranks.Point.filter(p => p.Month === this.state.PointMonth && p.Year === this.state.PointYear)[0];
            if (filterSchools) {
                filterSchools = filterSchools.MostUsersHavePoints;
                return filterSchools.map((item, index) => {

                    let Country = "";
                    if (item.Country && item.Country !== 'il') {
                        Country = this.state.countries.filter(p => p.value === item.Country)[0];
                    }

                    return <div className="col px-0 mx-0 w-100" key={`MostUsersHavePoints-${item.Id}`}>
                        <div className="product-list-item" style={{
                            minHeight: '160px',
                            backgroundColor: index < 3 ? ListOfBackgroundColor[index] : ""
                        }}>
                            <div className="product-content py-1 w-100">
                                <div className="d-flex justify-content-between px-2">
                                    <div className="d-flex flex-column justify-content-center">
                                        <h5 className="title ">
                                            {item.FirstName ? item.FirstName + " " : ""}
                                            {item.SecondName ? item.SecondName + " " : ""}
                                            {item.LastName ? item.LastName + " " : ""}
                                        </h5>
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.img}
                                                        </span>
                                                        : ""
                                                }
                                            </div>
                                            <div className="mr-1">
                                                {
                                                    Country ?
                                                        <span title={t('Pages.Register.Country.title')} >
                                                            {Country.name}
                                                        </span>
                                                        : ""
                                                }
                                                {
                                                    item.Address ?
                                                        <p title={t('Pages.Register.Address.title')} >
                                                            {item.Address}
                                                        </p>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <p className="my-0">
                                            <span className="mt-1">
                                                {
                                                    item.Name ?
                                                        <span title={t('Pages.Register.SchoolName.title')}>
                                                            <i className="icon fal fa-school"></i>
                                                            {item.Name}
                                                        </span>
                                                        : ""
                                                }
                                            </span>
                                        </p>
                                        <p className="my-0">
                                            <span className={`h4 text-${index < 3 ? ListOfColor[index] : ""}`} title={t('Pages.Home.Point')}>
                                                <i className={`icon h5 fad fa-circle text-${index < 3 ? ListOfColor[index] : ""}`}></i>
                                                {Math.round(item.Point * 100)}
                                            </span>
                                        </p>
                                    </div>
                                    {
                                        index <= 5 ?
                                            <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                                <LazyLoadImage src={`/assets/images/archive/sa${4 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 100)} `} />
                                            </div>
                                            :
                                            <div className="d-flex text-dark h2 align-items-center mr-1 justify-content-center" >
                                                <i className="icon text-danger fad fa-hashtag"></i> {index + 1}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                });
            }
        }

        if (ranks && ranks.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.SchoolsControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.SchoolsControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    Sync = () => {
        this.props.fetch();
    }

    render() {
        const { t, user, ranks } = this.props;

        let isLoaded = false;
        if (ranks) {
            isLoaded = ranks.isLoaded;
        }

        let ranges = {};
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("range.2MonthAgo")] = [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')];
        ranges[t("range.3MonthAgo")] = [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')];

        return (
            <>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.UserControl.UserRank')}| {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.UserControl.UserRank')} </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">

                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            autoUpdateInput: true,
                                                            ranges: ranges,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            opens: 'center',
                                                            applyButtonClasses: "btn-primary",
                                                            locale: {
                                                                format: 'MM/YYYY',
                                                                customRangeLabel: t('range.customRange'),
                                                                cancelLabel: t('Close'),
                                                                applyLabel: t('Apply'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>
                                                    <style dangerouslySetInnerHTML={{
                                                        __html: ".drp-calendar,.drp-buttons{display:none !important;visibility: hidden;} .ranges ul li:last-child{display:none !important;}"
                                                    }}></style>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.Sync()} title={t("Pages.SchoolsControl.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderStudents()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ranks: state.ranks,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(UserRank));

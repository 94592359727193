import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";

class QuestionPadding extends React.Component {

    state = {
        Padding: 0,
        PaddingTop: 0,
        PaddingRight: 0,
        PaddingLeft: 0,
        PaddingBottom: 0,
    };

    componentDidMount() {
        this.setState({ Padding: this.props.Padding });
        this.setState({ PaddingTop: this.props.PaddingTop });
        this.setState({ PaddingRight: this.props.PaddingRight });
        this.setState({ PaddingLeft: this.props.PaddingLeft });
        this.setState({ PaddingBottom: this.props.PaddingBottom });
    }

    static getDerivedStateFromProps(props, state) {
        state.Padding = props.Padding;
        state.PaddingTop = props.PaddingTop;
        state.PaddingRight = props.PaddingRight;
        state.PaddingLeft = props.PaddingLeft;
        state.PaddingBottom = props.PaddingBottom;
        return state;
    }

    render() {

        const { t } = this.props;
        return (
            <>
                <li id="PopoverPadding" title={t('Pages.Lessons.Questions.forms.tools.padding')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="fal fa-expand-arrows-alt"></i>
                            {t('Pages.Lessons.Questions.forms.tools.padding')}
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="fal fa-expand-arrows-alt icon"></i>
                                {t('Pages.Lessons.Questions.forms.tools.padding')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">

                                    <div className="form-group mb-2 w-100">
                                        <label className="text-dark h6"><i className="fal fa-expand-arrows-alt icon"></i> {t('Pages.Lessons.Questions.forms.tools.padding')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.padding')}
                                                title={t('Pages.Lessons.Questions.forms.tools.padding')}
                                                value={this.state.Padding}
                                                onChange={e => this.props.setQuestionPadding(e.target.value)}
                                            />
                                            <button type="button">
                                                <i className="fas fa-expand-arrows-alt"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="widget-header p-1 w-100 mb-3"></div>

                                    <div className="d-flex justify-content-center w-100">
                                        <div className="form-group mb-2">
                                            <div className="search-form">
                                                <input
                                                    style={{
                                                        width: '150px',
                                                        height: '50px',
                                                    }}
                                                    type="text"
                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.Padding.top')}
                                                    title={t('Pages.Lessons.Questions.forms.tools.Padding.top')}
                                                    value={this.state.PaddingTop}
                                                    onChange={e => this.props.setQuestionPaddingTop(e.target.value)} />

                                                <button type="button">
                                                    <i className="fa-expand-arrows-alt fas"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex justify-content-center justify-content-between mb-2">
                                        <div className="d-flex align-content-center flex-wrap">
                                            <input
                                                style={{
                                                    width: '80px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    padding: '0px',
                                                    marginBottom: 'auto'
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.Padding.right')}
                                                title={t('Pages.Lessons.Questions.forms.tools.Padding.right')}
                                                value={this.state.PaddingRight}
                                                onChange={e => this.props.setQuestionPaddingRight(e.target.value)} />
                                        </div>
                                        <div className="d-flex align-content-center justify-content-center flex-wrap" style={{
                                            borderStyle: `solid`,
                                            borderLeftWidth: this.state.PaddingLeft > 50 ? `50px` : `${this.state.PaddingLeft}px`,
                                            borderRightWidth: this.state.PaddingRight > 50 ? `50px` : `${this.state.PaddingRight}px`,
                                            borderTopWidth: this.state.PaddingTop > 50 ? `50px` : `${this.state.PaddingTop}px`,
                                            borderBottomWidth: this.state.PaddingBottom > 50 ? `50px` : `${this.state.PaddingBottom}px`,
                                            width: '150px',
                                            height: '150px',
                                            backgroundColor: 'rgb(136 136 136 / 60%)',
                                            backgroundImage: '-webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)',
                                        }}> </div>
                                        <div className="d-flex align-content-center flex-wrap">
                                            <input
                                                style={{
                                                    width: '80px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    padding: '0px',
                                                    marginBottom: 'auto'
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.Padding.left')}
                                                title={t('Pages.Lessons.Questions.forms.tools.Padding.left')}
                                                value={this.state.PaddingLeft}
                                                onChange={e => this.props.setQuestionPaddingLeft(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center w-100">
                                        <div className="form-group ">
                                            <div className="search-form">
                                                <input
                                                    style={{
                                                        width: '150px',
                                                        height: '50px',
                                                    }}
                                                    type="text"
                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.Padding.bottom')}
                                                    title={t('Pages.Lessons.Questions.forms.tools.Padding.bottom')}
                                                    value={this.state.PaddingBottom}
                                                    onChange={e => this.props.setQuestionPaddingBottom(e.target.value)} />

                                                <button type="button">
                                                    <i className="fa-expand-arrows-alt fas"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(QuestionPadding);
import api from '../../../../api/api';

export const fetchEmailContents = () => async dispatch => {
    dispatch({ type: 'EMAIL_CONTENT_LOADING' });
    const response = await api.get(`/EmailContents`);
    dispatch({ type: 'EMAIL_CONTENT_INITIAL', emails: response.data.data });
}

export const fetchEmailContent = id => async dispatch => {
    dispatch({ type: 'EMAIL_CONTENT_LOADING' });
    const response = await api.get(`/EmailContents/${id}`);
    dispatch({ type: 'EMAIL_CONTENT_INITIAL', emails: response.data.data });
}
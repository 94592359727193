import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from './component/App';
import { Router } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './component/reducers';
import history from './component/history';
import registerServiceWorker from './registerServiceWorker';
import { I18nextProvider } from "react-i18next";
import i18n from 'i18next'
import common_ar from "./language/ar.json";
import common_he from "./language/he.json";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import ReactPixel from 'react-facebook-pixel';

i18n
    .init({
        interpolation: { escapeValue: false },
        lng: 'ar',
        resources: {
            ar: {
                common: common_ar,
                title: 'اللغة العربية',
                icon: '/assets/media/svg/flags/008-saudi-arabia.svg',
                stylePath: '/custom/ar.css'
            },
            he: {
                common: common_he,
                title: 'עִברִית',
                icon: '/assets/media/svg/flags/155-israel.svg',
                stylePath: '/custom/he.css'
            },
        },
        react: {
            useSuspense: true,
        }
    });

moment.locale('en');

ReactGA.initialize([{ trackingId: 'G-8CJQX8S6PL' }, { trackingId: 'AW-16621020846' }]);
ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    // title: "Landing Pagexxxx"
});

ReactPixel.init('484815450795869');
ReactPixel.pageView();

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Router history={history} >
                <Suspense fallback="loading">
                    <App />
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl
                        pauseOnFocusLoss
                        draggable={true}
                        pauseOnHover
                    />
                </Suspense>
            </Router>
        </I18nextProvider>
    </Provider>,
    document.getElementById("root")
);

registerServiceWorker();
import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class FruitOrange extends React.Component {

    render() {
        return <div className="position-relative">
            <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                    transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                }}>
                    <div className={`Fruit-fruit-orange mx-auto position-relative  mb-2 `}>
                        <div className="Fruit-fruit-orange-slices"></div>
                    </div>
                </div>
            </div>
            <TextBox {...this.props} />
        </div>;
    }
}

export default FruitOrange;
import React from "react";
import { withTranslation } from 'react-i18next';

import history from "../../../history";
import Oacen from "../../Admin/Question/View/Background/Oacen";

class TracksBg extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-tracks-section");
    }

    goTo = path => {
        history.push(path);
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <section className="position-relative oh" id="main-tracks-section" style={{
                    backgroundColor: '#24bef4',
                    minHeight: '100vh'
                }}>
                    <Oacen />

                    <div className="container padding-top padding-bottom" style={{
                        minHeight: '100vh'
                    }}>
                        <div className="d-flex align-items-center justify-content-between flex-column w-100">
                            <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear" style={{
                                zIndex: 1
                            }}>
                                <div className="section-header mb-4">
                                    <span className="cate custome-title"> {this.props.title}</span>
                                    <div className="display-4 h3 custome-title" ><i className="mx-3 fal fa-book "></i> {this.props.subTitle}</div>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-30-none -mx-10 ">
                                <div className="d-flex justify-content-center flex-wrap">
                                    <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                        maxWidth: '230px'
                                    }} onClick={() => this.goTo(`${this.props.perfixUrl}/Books`)}>
                                        <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffbf'
                                        }}>
                                            <i className="h3 display-2 icon-color fal fa-book "></i>
                                            <h5 className="title cate mb-2">{t('PageHeader.Books')}</h5>
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                lineHeight: '20px'
                                            }}>{t('PageHeader.Books_desc')} </p>
                                        </div>
                                    </div>

                                    <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                        maxWidth: '230px'
                                    }} onClick={() => this.goTo(`${this.props.perfixUrl}/Lessons`)}>
                                        <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffbf'
                                        }}>
                                            <i className="h3 display-2 icon-color fal fa-layer-group "></i>
                                            <h5 className="title cate mb-2">{t('PageHeader.Lessons')}</h5>
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                lineHeight: '20px'
                                            }}>{t('PageHeader.Lessons_desc')} </p>
                                        </div>
                                    </div>

                                    <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                        maxWidth: '230px'
                                    }} onClick={() => this.goTo(`${this.props.perfixUrl}/Files`)}>
                                        <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffbf'
                                        }}>
                                            <i className="h3 display-2 icon-color fal fa-paperclip "></i>
                                            <h5 className="title cate mb-2">{t('PageHeader.Files')}</h5>
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                lineHeight: '20px'
                                            }}>{t('PageHeader.Files_desc')} </p>
                                        </div>
                                    </div>

                                    <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                        maxWidth: '230px'
                                    }} onClick={() => this.goTo(`${this.props.perfixUrl}/Exams`)}>
                                        <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffbf'
                                        }}>
                                            <i className="h3 display-2 icon-color fal fa-pencil-alt "></i>
                                            <h5 className="title cate mb-2">{t('PageHeader.Exams')}</h5>
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                lineHeight: '20px'
                                            }}>{t('PageHeader.Exams_desc')} </p>
                                        </div>
                                    </div>

                                    <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                        maxWidth: '230px'
                                    }} onClick={() => this.goTo(`${this.props.perfixUrl}/Quizzes`)}>
                                        <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffbf'
                                        }}>
                                            <i className="h3 display-2 icon-color fal fa-question "></i>
                                            <h5 className="title cate mb-2">{t('PageHeader.Quizzes')}</h5>
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                lineHeight: '20px'
                                            }}>{t('PageHeader.Quizzes_desc')} </p>
                                        </div>
                                    </div>

                                    {
                                        this.props.onEdit ?
                                            <>
                                                <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                                    width: '230px'
                                                }} onClick={() => this.goTo(`${this.props.perfixUrl}/Games/`)}>
                                                    <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffbf'
                                                    }}>
                                                        <i className="h3 display-2 icon-color fal fa-gamepad-alt "></i>
                                                        <h5 className="title cate mb-2">{t('PageHeader.FunPath')}</h5>
                                                        <p style={{
                                                            fontSize: '14px',
                                                            color: '#666',
                                                            lineHeight: '20px'
                                                        }}>{t('PageHeader.FunPath_desc')} </p>
                                                    </div>
                                                </div>
                                                <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                                    width: '230px'
                                                }} onClick={() => this.goTo(`${this.props.perfixUrl}/SelfDevelopment/`)}>
                                                    <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffbf'
                                                    }}>
                                                        <i className="h3 display-2 icon-color fal fa-cubes "></i>
                                                        <h5 className="title cate mb-2">{t('PageHeader.SelfDevelopment')}</h5>
                                                        <p style={{
                                                            fontSize: '14px',
                                                            color: '#666',
                                                            lineHeight: '20px'
                                                        }}>{t('PageHeader.SelfDevelopment_desc')} </p>
                                                    </div>
                                                </div>
                                                <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                                    width: '230px'
                                                }} onClick={() => this.goTo(`${this.props.perfixUrl}/Indices/`)}>
                                                    <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffbf'
                                                    }}>
                                                        <i className="h3 display-2 icon-color fal fa-indent "></i>
                                                        <h5 className="title cate mb-2">{t('ControlPanel.Indices.title')}</h5>
                                                        <p style={{
                                                            fontSize: '14px',
                                                            color: '#666',
                                                            lineHeight: '20px'
                                                        }}>{t('ControlPanel.Indices.title')} </p>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default withTranslation('common')(TracksBg);
import React from "react";
import ScrollArea from "react-scrollbar";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import GameObjects from "../../Question/View/GameObjects";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FootballStadium from "../../Question/View/Background/FootballStadium";

class MyScoreboard extends React.Component {

    state = { UserStarCount: 0, StarCount: 0 };

    componentDidMount() {
        const { lesson } = this.props;
        if (lesson.Questions && lesson.Questions.length && lesson.Questions.length > 0) {
            let UserStarCount = 0;
            lesson.Questions.forEach(item => {
                if (lesson.scores && lesson.scores.Levels) {
                    const level = lesson.scores.Levels.filter(p => item.QuestionLessonId === p.LessonQuestionId || item.Id === p.QuestionId)[0];
                    if (level) {
                        UserStarCount += level.Star;
                    }
                }
            });
            this.setState({
                UserStarCount,
                StarCount: lesson.Questions.length * 3
            });
        }
    }

    renderRow = () => {
        const { t, lesson } = this.props;

        if (lesson.Questions && lesson.Questions.length && lesson.Questions.length > 0) {
            return lesson.Questions.map((item, index) => {
                let starCount = 0;
                let TryNumber = 0;
                let Time = 0;
                let Score = 0;
                let isPlay = false;
                if (lesson.scores && lesson.scores.Levels) {
                    const level = lesson.scores.Levels.filter(p => item.QuestionLessonId === p.LessonQuestionId || item.Id === p.QuestionId)[0];
                    if (level) {
                        starCount = level.Star;
                        Time = level.Time;
                        TryNumber = level.TryNumber;
                        Score = level.Score;
                        isPlay = true;
                    }
                }
                const UserTime = this.calcTime(Time);

                if (isPlay) {
                    return <tr>
                        <td className="text-center">{t('Pages.Games.Table.Level')} {index + 1}</td>
                        <td className="text-center" title={t('Pages.Games.Table.Stars')}>
                            {
                                starCount >= 3 ? <i className="text-warning fas fa-star"></i> : <i className="text-secondary fas fa-star"></i>
                            }
                            {
                                starCount >= 2 ? <i className="text-warning fas fa-star"></i> : <i className="text-secondary fas fa-star"></i>
                            }
                            {
                                starCount >= 1 ? <i className="text-warning fas fa-star"></i> : <i className="text-secondary fas fa-star"></i>
                            }
                        </td>
                        <td className="text-center" title={t('Pages.Games.Table.Time')}>{UserTime}</td>
                        <td className="text-center" title={t('Pages.Games.Table.Repate')}>{TryNumber}</td>
                        <td className="text-center" title={t('Pages.Games.Table.Point')}>{Score}</td>
                    </tr>
                } else {
                    return <tr>
                        <td className="text-center">{t('Pages.Games.Table.Level')} {index + 1}</td>
                        <td colSpan={4} className="text-center">
                            {t('Pages.Games.NotPlay')}
                        </td>
                    </tr>
                }

            })
        }
        return "";
    }

    calcTime = Time => {
        let result = "";
        if (Time >= 3600) {
            result = (parseInt(Time / 3600) > 9 ? parseInt(Time / 3600) : "0" + parseInt(Time / 3600)) + ":";
            Time = Time % 3600;
        } else {
            result += "00:";
        }

        if (Time >= 60) {
            result += (parseInt(Time / 60) > 9 ? parseInt(Time / 60) : "0" + parseInt(Time / 60)) + ":";
            Time = Time % 60;
        } else {
            result += "00:";
        }

        if (Time > 0) {
            result += parseInt(Time) > 9 ? parseInt(Time) : "0" + parseInt(Time);
        } else {
            result += "00";
        }
        return result;
    }

    renderLevel = () => {
        let Level = 0;
        let prefix = this.props.user && this.props.user.Sex === "female" ? "Girl" : "";
        if (this.state.StarCount && this.state.UserStarCount) {
            Level = Math.floor(this.state.UserStarCount / (this.state.StarCount / 6));
            if (Level < 0) {
                Level = 0;
            } else if (Level > 6) {
                Level = 6;
            }
        }
        return <div className="position-relative">
            <div className="display-3 custome-title position-absolute w-auto d-none" style={{
                color: '#ed651e',
                bottom: 0,
                left: '10%',
                zIndex: 9
            }}>
                {Level + 1}
            </div>

            <div className="position-absolute w-100 px-5 pt-2" style={{
                color: '#ed651e',
                zIndex: 9
            }}>
                <div className="w-100 d-flex justify-content-around align-items-end">
                    <div className="display-4">
                        <i className={`right-star fa-solid fa-star ${Level >= 5 ? Level >= 6 ? 'star-color' : 'fa-regular fa-star-half-stroke star-color' : 'empty-star'}`}></i>
                    </div>
                    <div className="display-4 pb-2">
                        <i className={`fa-solid fa-star ${Level >= 3 ? Level >= 4 ? 'star-color' : 'fa-regular fa-star-half-stroke star-color' : 'empty-star'}`}></i>
                    </div>
                    <div className="display-4">
                        <i className={`left-star fa-solid fa-star ${Level >= 1 ? Level >= 2 ? 'star-color' : 'fa-regular fa-star-half-stroke star-color' : 'empty-star'}`}></i>
                    </div>
                </div>
            </div>

            <GameObjects objectCss={`${prefix}Level${Level}`} />
        </div>;
    }

    render() {
        const { t, user } = this.props;
        return (<>
            <Helmet>
                <title>{t("Pages.Games.MyScore")} | {t('alarabeyya')}</title>
            </Helmet>
            <section className="position-relative oh" id="main-game-profile-section" style={{
                minHeight: '100vh',
                height: '100vh',
                backgroundColor: '#74caee'
            }}>
                <FootballStadium />
                <div className="container padding-top padding-bottom ">
                    <div className="row ">
                        <div className="col-lg-4 mb-50">
                            <aside>
                                {
                                    user ?
                                        <div className="teacher-wrapper p-4 mt-0">
                                            <div className="teacher-single-top bg-white">
                                                <div className="teacher-single-item d-flex w-75 mx-auto justify-content-center align-items-center m-0" >
                                                    <div className="teacher-single-thumb d-flex w-100 ">
                                                        <LazyLoadImage src={`${user.Photo ? user.Photo : "/assets/images/teacher/teacher-single.jpg"}`} alt={`${user.FirstName} ${user.SecondName} ${user.LastName}`} />
                                                    </div>
                                                </div>
                                                <h3 className="title pt-4 text-center">
                                                    {`${user.FirstName} ${user.SecondName} ${user.LastName}`}
                                                </h3>
                                            </div>
                                        </div>
                                        : ""
                                }
                                <div className="teacher-wrapper p-4 mt-3">
                                    <div className="teacher-single-top bg-white ">
                                        {this.renderLevel()}
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-lg-8 mb-50">
                            <div className={`row mt-0`}>
                                <div className="col-12" >
                                    <div className="widget widget-search" style={{
                                        boxShadow: '0px 0px 10px 0px rgb(20 19 19 / 64%)',
                                        backgroundColor: '#fafafa91'
                                    }}>

                                        <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                                            <div className="section-header mb-1 mt-3">
                                                <div className=" h1 custome-title" style={{
                                                    color: '#ed651e'
                                                }}><i className="mx-3 fa-solid fa-trophy-star "></i>
                                                    {t('Pages.Games.MyScore')}
                                                </div>
                                            </div>
                                        </div>

                                        <ScrollArea
                                            horizontal={false}
                                            className={`area w-100 lazyLoading  px-2 `}
                                            smoothScrolling={true}
                                            style={{
                                                height: '65vh',
                                                borderRadius: '10px'
                                            }}
                                            ref={this.scrollArea}
                                            contentClassName="content "
                                            onScroll={this.handleScroll} >
                                            <div className="widget-body p-2 oh" >
                                                <table className="table table-striped table-bordered  table-hover table-sm w-100 bg-white">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th className="h3" title={t('Pages.Games.Table.Level')}><i className="text-success fas fa-sort-numeric-down"></i></th>
                                                            <th className="h3" title={t('Pages.Games.Table.Stars')}><i className="text-warning fas fa-stars"></i></th>
                                                            <th className="h3" title={t('Pages.Games.Table.Time')}><i className="text-info fas fa-user-clock"></i></th>
                                                            <th className="h3" title={t('Pages.Games.Table.Repate')}><i className="text-danger fas fa-repeat-1-alt"></i></th>
                                                            <th className="h3" title={t('Pages.Games.Table.Point')}><i className="text-primary mx-1 fas fa-coin-vertical"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderRow()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ScrollArea>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
}
export default withTranslation('common')(MyScoreboard);
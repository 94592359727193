import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import history from '../../history';

class HeaderTop extends React.Component {

    state = { hover: false, showSearch: false };

    search = (e) => {
        if (e) {
            if (e.key === 'Enter') {
                history.push(`/Search/${this.Search.value}`);
                this.showSearch();
            }
        } else {
            history.push(`/Search/${this.Search.value}`);
            this.showSearch();
        }
    }

    showSearch = () => {
        this.setState({
            showSearch: !this.state.showSearch
        });
        this.Search.value = "";
    }

    setHover = () => {
        this.setState({
            hover: !this.state.hover
        });
    }

    render() {
        const { t, isLogin, user, notifications } = this.props;
        let SMSBalance = 0;
        if (notifications && notifications.Notification && user && user.UserType && user.UserType.Id === 1) {
            SMSBalance = notifications.Notification.SMSBalance;
        }
        return (
            <>
                <div className={`header-form ${this.state.showSearch ? "active" : ""}`}>
                    <div className="bg-lay" onClick={() => this.showSearch()}>
                        <div className="cross">
                            <i className="fas fa-times"></i>
                        </div>
                    </div>
                    <div className="form-container" autoComplete="new-password">
                        <input
                            onKeyDown={this.search}
                            ref={Search => this.Search = Search}
                            autoComplete="off"
                            type="text"
                            title={t('Pages.Search.input.title')}
                            placeholder={t('Pages.Search.input.Placeholder')} />
                        <button onClick={() => this.search()} type="submit"><i className="icon fas fa-search"></i> {t('Pages.Search.submit')}</button>
                    </div>
                </div>
                <div className="header-top">
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="left">
                                {/* <li>
                                    <a href={`mailto:${t('HeaderTop.email')}`}>
                                        <i className="fal fa-envelope-open-text"></i>
                                        {t('HeaderTop.email')}
                                    </a>
                                </li>
                                <li>
                                    <a href={`Tel:${t('HeaderTop.phone')}`}>
                                        <i className="fal fa-phone-alt"></i>
                                        <span dir="ltr">
                                            {t('HeaderTop.phone')}
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href={`Tel:${t('HeaderTop.phone1')}`}>
                                        <i className="fal fa-phone-alt"></i>
                                        <span dir="ltr">
                                            {t('HeaderTop.phone1')}
                                        </span>
                                    </a>
                                </li> */}
                                <li>
                                    <Link to="/" title={t('Home')}>
                                        <i className="fal fa-home"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Contact" title={t('Contact')}>
                                        <i className="fal fa-envelope"></i>
                                    </Link>
                                </li>
                            </ul>
                            <ul className="social-icons">

                                {
                                    isLogin === true ?
                                        <>
                                            {
                                                user.UserType.Id === 1 ?
                                                    <>
                                                        {
                                                            SMSBalance ?
                                                                <li>
                                                                    <Link className="mx-2 w-auto" to="#" title={`${t('HeaderTop.SMSBalance')}`} >
                                                                        {SMSBalance} <i className="fal fa-dollar-sign"></i>
                                                                    </Link>
                                                                </li>
                                                                : ""
                                                        }
                                                        <li>
                                                            <Link to="/ControlPanel" title={`${t('ControlPanel.title')}`}> <i className="fad fa-keyboard"></i> </Link>
                                                        </li>
                                                    </>
                                                    :
                                                    ""
                                            }
                                            <li>
                                                <Link to="/User/Control" title={`${t('Pages.UserControl.title')}`}> <i className="fad fa-game-board-alt"></i> </Link>
                                            </li>
                                            <li>
                                                <Link to="/Profile" className="mx-2 w-auto" title={`${t('Pages.Profile.title')}`}> <i className="icon fad fa-user"></i> {user.FirstName} </Link>
                                            </li>
                                            {
                                                user.UserType.Id === 5 ? "" :
                                                    <li>
                                                        <Link to="#" title={`${t('Pages.Search.title')}`} onClick={() => this.showSearch()}> <i className="fal fa-search"></i></Link>
                                                    </li>
                                            }
                                            <li>
                                                <Link to="/Logout" title={`${t('Logout')}`}> <i className="far fa-power-off"></i> </Link>
                                            </li>
                                        </>
                                        :
                                        <>
                                            <li>
                                                <Link to="#" title={`${t('Pages.Search.title')}`} onClick={() => this.showSearch()}> <i className="fal fa-search"></i>  </Link>
                                            </li>
                                            <li>
                                                <Link to="/Login" title={`${t('Login')}`} onMouseEnter={() => this.setHover()} onMouseLeave={() => this.setHover()}> <i className={` ${this.state.hover ? "fa-light fa-person-to-portal" : "fal fa-sign-in-alt"}`}></i> </Link>
                                            </li>
                                        </>
                                }
                                <li>
                                    <Link to="#" className="expand-button" title={t('Pages.Lessons.Questions.forms.fullscreen')}>
                                        <i className="fas fa-expand"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(HeaderTop));
import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class QuestionBorder extends React.Component {

    state = {
        BorderRadius: 0,
        BorderRadiusTop: 0,
        BorderRadiusRight: 0,
        BorderRadiusLeft: 0,
        BorderRadiusBottom: 0,
        BorderWidth: 0,
        BorderWidthTop: 0,
        BorderWidthRight: 0,
        BorderWidthLeft: 0,
        BorderWidthBottom: 0,
        BorderRadiusUnit: 'px',
        BorderStyle: '',
        BorderColor: '',
        displayColorPicker: false
    };

    componentDidMount() {
        this.setState({ BorderStyle: this.props.BorderStyle });
        this.setState({ BorderColor: this.props.BorderColor });
        this.setState({ BorderWidth: this.props.BorderWidth });
        this.setState({ BorderRadius: this.props.BorderRadius });
        this.setState({ BorderRadiusUnit: this.props.BorderRadiusUnit });
        this.setState({ BorderRadiusTop: this.props.BorderRadiusTop });
        this.setState({ BorderRadiusRight: this.props.BorderRadiusRight });
        this.setState({ BorderRadiusLeft: this.props.BorderRadiusLeft });
        this.setState({ BorderRadiusBottom: this.props.BorderRadiusBottom });
        this.setState({ BorderWidthTop: this.props.BorderWidthTop });
        this.setState({ BorderWidthRight: this.props.BorderWidthRight });
        this.setState({ BorderWidthLeft: this.props.BorderWidthLeft });
        this.setState({ BorderWidthBottom: this.props.BorderWidthBottom });
    }

    static getDerivedStateFromProps(props, state) {
        state.BorderStyle = props.BorderStyle;
        state.BorderColor = props.BorderColor;
        state.BorderRadiusUnit = props.BorderRadiusUnit;
        state.BorderRadius = props.BorderRadius;
        state.BorderRadiusTop = props.BorderRadiusTop;
        state.BorderRadiusRight = props.BorderRadiusRight;
        state.BorderRadiusLeft = props.BorderRadiusLeft;
        state.BorderRadiusBottom = props.BorderRadiusBottom;
        state.BorderWidth = props.BorderWidth;
        state.BorderWidthTop = props.BorderWidthTop;
        state.BorderWidthRight = props.BorderWidthRight;
        state.BorderWidthLeft = props.BorderWidthLeft;
        state.BorderWidthBottom = props.BorderWidthBottom;
        return state;
    }

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false })
    };

    handleClickDisplayColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleColorChange = (color) => {
        this.setState({ BorderColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setQuestionBorderColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        const { t } = this.props;
        return (
            <>
                <li id="PopoverBorder" title={t('Pages.Lessons.Questions.forms.tools.border')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="fas fa-border-style"></i>
                            {t('Pages.Lessons.Questions.forms.tools.border')}
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="icon fas fa-border-style"></i>
                                {t('Pages.Lessons.Questions.forms.tools.border')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                readOnly={true}
                                                value={this.state.BorderColor}
                                            />
                                            <button
                                                type="button"
                                                onClick={this.handleClickDisplayColorPicker}
                                                style={{
                                                    color: this.state.BorderColor !== 'transparent' ? this.state.BorderColor : "",
                                                }}>
                                                <i className="fas fa-tint"></i>
                                            </button>
                                            {
                                                this.state.displayColorPicker ?
                                                    <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                        <SketchPicker color={this.state.BorderColor} onChange={this.handleColorChange} />
                                                    </div> : null
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-border-style"></i> {t('Pages.Lessons.Questions.forms.tools.BorderStyle.title')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setQuestionBorderStyle(e.target.value)}
                                                className="w-100"
                                                value={this.state.BorderStyle}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderStyle.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderStyle.title')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.none')}</option>
                                                <option value="solid">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.solid')}</option>
                                                <option value="dashed">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.dashed')}</option>
                                                <option value="dotted">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.dotted')}</option>
                                                <option value="double">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.double')}</option>
                                            </select>
                                            <button type="button">
                                                <i className="fas fa-border-style"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">

                                        <div className="form-group mb-3">
                                            <label className="text-dark h6"><i className="icon fas fa-border-all"></i> {t('Pages.Lessons.Questions.forms.tools.BorderWidth.title')}</label>
                                            <div className="search-form">
                                                <input
                                                    className="w-100 form-control"
                                                    type="text"
                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.placeholder')}
                                                    title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.title')}
                                                    value={this.state.BorderWidth}
                                                    onChange={e => this.props.setQuestionBorderWidth(e.target.value)}
                                                />
                                                <button type="button">
                                                    <i className="fas fa-border-all"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 mx-3">
                                            <label className="text-dark h6"><i className="icon fas fa-spinner-third"></i> {t('Pages.Lessons.Questions.forms.tools.BorderRadius.title')}</label>
                                            <div className="search-form">
                                                <input className="w-100 form-control" type="text"
                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.BorderRadius.title')}
                                                    title={t('Pages.Lessons.Questions.forms.tools.BorderRadius.title')}
                                                    defaultValue={this.state.BorderRadius}
                                                    onChange={e => this.props.setQuestionBorderRadius(e.target.value)} />
                                                <button type="button">
                                                    <i className="fas fa-spinner-third"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-dark h6"><i className="icon fad fa-spinner-third"></i> {t('Pages.Lessons.Questions.forms.tools.BorderRadius.unit.title')}</label>
                                            <div className="search-form">
                                                <select
                                                    onChange={e => this.props.setQuestionBorderRadiusUnit(e.target.value)}
                                                    className="w-100"
                                                    value={this.state.BorderRadiusUnit}
                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.BorderRadius.unit.title')}
                                                    title={t('Pages.Lessons.Questions.forms.tools.BorderRadius.unit.title')}>
                                                    <option value="px">{t('Pages.Lessons.Questions.forms.tools.BorderRadius.unit.px')}</option>
                                                    <option value="%">{t('Pages.Lessons.Questions.forms.tools.BorderRadius.unit.rate')}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="widget-header p-1 w-100 mb-3"></div>

                                    <div className="form-group mb-2 ">
                                        <div className="search-form">
                                            <input
                                                style={{
                                                    width: '150px',
                                                    height: '50px',
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.top')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.top')}
                                                value={this.state.BorderWidthTop}
                                                onChange={e => this.props.setQuestionBorderWidthTop(e.target.value)} />

                                            <button type="button">
                                                <i className="fas fa-border-all"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-2 ">
                                        <div className="search-form">
                                            <input
                                                style={{
                                                    width: '150px',
                                                    height: '50px',
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderRadius.top')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderRadius.top')}
                                                value={this.state.BorderRadiusTop}
                                                onChange={e => this.props.setQuestionBorderRadiusTop(e.target.value)} />

                                            <button type="button">
                                                <i className="fas fa-spinner-third"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex justify-content-center justify-content-between mb-2">
                                        <div className="d-flex align-items-start flex-column">
                                            <input
                                                style={{
                                                    width: '80px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    padding: '0px',
                                                    marginBottom: 'auto'
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.right')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.right')}
                                                value={this.state.BorderWidthRight}
                                                onChange={e => this.props.setQuestionBorderWidthRight(e.target.value)} />
                                            <input
                                                style={{
                                                    width: '80px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    padding: '0px'
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderRadius.right')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderRadius.right')}
                                                value={this.state.BorderRadiusRight}
                                                onChange={e => this.props.setQuestionBorderRadiusRight(e.target.value)} />
                                        </div>
                                        <div className="d-flex align-content-center justify-content-center flex-wrap" style={{
                                            borderColor: this.state.BorderColor !== 'transparent' ? this.state.BorderColor : "",
                                            borderStyle: `${this.state.BorderStyle}`,
                                            borderLeftWidth: `${this.state.BorderWidthLeft}px`,
                                            borderRightWidth: `${this.state.BorderWidthRight}px`,
                                            borderTopWidth: `${this.state.BorderWidthTop}px`,
                                            borderBottomWidth: `${this.state.BorderWidthBottom}px`,
                                            borderRadius: `${this.state.BorderRadiusTop}${this.state.BorderRadiusUnit} ${this.state.BorderRadiusRight}${this.state.BorderRadiusUnit} ${this.state.BorderRadiusBottom}${this.state.BorderRadiusUnit} ${this.state.BorderRadiusLeft}${this.state.BorderRadiusUnit}`,
                                            width: '150px',
                                            height: '150px',
                                            backgroundColor: 'rgb(136 136 136 / 60%)',
                                            backgroundImage: '-webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)',
                                        }}> </div>
                                        <div className="d-flex align-items-start flex-column">
                                            <input
                                                style={{
                                                    width: '80px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    padding: '0px',
                                                    marginBottom: 'auto'
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.left')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.left')}
                                                value={this.state.BorderWidthLeft}
                                                onChange={e => this.props.setQuestionBorderWidthLeft(e.target.value)} />

                                            <input
                                                style={{
                                                    width: '80px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    padding: '0px'
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderRadius.left')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderRadius.left')}
                                                value={this.state.BorderRadiusLeft}
                                                onChange={e => this.props.setQuestionBorderRadiusLeft(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <div className="search-form">
                                            <input
                                                style={{
                                                    width: '150px',
                                                    height: '50px',
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.bottom')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.bottom')}
                                                value={this.state.BorderWidthBottom}
                                                onChange={e => this.props.setQuestionBorderWidthBottom(e.target.value)} />

                                            <button type="button">
                                                <i className="fas fa-border-all"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <div className="search-form">
                                            <input
                                                style={{
                                                    width: '150px',
                                                    height: '50px',
                                                }}
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.BorderRadius.bottom')}
                                                title={t('Pages.Lessons.Questions.forms.tools.BorderRadius.bottom')}
                                                value={this.state.BorderRadiusBottom}
                                                onChange={e => this.props.setQuestionBorderRadiusBottom(e.target.value)} />

                                            <button type="button">
                                                <i className="fas fa-spinner-third"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(QuestionBorder);
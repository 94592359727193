import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

import Items from './Items';

class SubItems extends React.Component {

    state = {
        show: false,
        items: []
    }

    static getDerivedStateFromProps(props, state) {
        state.show = props.show;
        state.items = props.items;
        return state;
    }

    render() {
        const { t } = this.props;
        if (this.props.handleCloseSubAnswer) {
            return (
                <>
                    <Modal
                        key={`submodal-${this.props.FillIndex}`}
                        size="lg"
                        show={this.state.show}
                        onHide={this.props.handleCloseSubAnswer}
                        backdrop="static"
                        enforceFocus={false}
                        keyboard={false}
                        autoFocus={true}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title> <div className="h5 mb-0"> <i className="icon fal fa-text"></i> {t('Pages.Lessons.Questions.forms.Answers')}</div></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Items
                                {...this.props}
                                EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                                title={t('Pages.Lessons.Questions.forms.Answers')}
                                text={t('Pages.Lessons.Questions.forms.Answer')}
                                sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                                API="/QuestionAnswers/"
                                max={false}
                                items={this.state.items} />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="w-100 row">
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={this.props.handleCloseSubAnswer} >
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(SubItems);
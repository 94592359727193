
import React from "react";

import roundsData from "../../../../Config/rounds.json";
import GameObjects from "../../../Question/View/GameObjects";
import ShootObject from "../ShootingHorizontal/ShootObject";
import HorizontalMove from "../ShootingHorizontal/HorizontalMove";
import PopUps from "../PopUps";

class ShootingHorizontalMultiSelect extends React.Component {

    constructor(props) {
        super(props);
        this.selectedCount = 0;
        this.selectableCount = 0;
        this.stageCount = 0;
        this.randomKey = Math.floor(Math.random() * 100000);
        this.state = {
            userAnswers: [],
            randomKey: this.randomKey,
            showItems: [],
            shootList: [],
            ShootNumber: 0,
            currentStageIndex: 0,
            currentCategoryId: 0,
            currentCategory: {},
            currentStatus: "",
            removeState: false,
            position: {
                x: 0,
                y: 0
            },
            BounsTry: 0,
            BounsTime: 0,
            showBounsTime: false,
            showBounsTry: false,
            clickNumber: this.props.clickNumber,
            handleShoot: this.handleShoot.bind(this),
            stageName: {}
        };
        this.Rounds = roundsData.Rounds;
        this.audio = new Audio();
        this.designerContainer = React.createRef();
        this.RocketLauncher = React.createRef();
        this.boundingClientRect = null;
        this.randomList = [];
    }

    componentWillUnmount() {
        this.randomKey = Math.floor(Math.random() * 100000);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.clickNumber !== state.clickNumber) {
            state.clickNumber = props.clickNumber;
            state.handleShoot(props.cursorClickPosition);
        }
        return state;
    }

    componentDidMount() {
        this.stageCount = this.props.Question.Categories.length;
        this.nextStage();
    }

    playMoveSound = MoveSound => {
        if (MoveSound) {
            if (this.audio && !this.audio.paused === false) {
                this.audio.pause();
            }
            this.audio = new Audio(MoveSound);
            this.audio.play();
        }
    }

    nextStage = () => {
        if (this.props.Question.Categories) {
            var currentCategory = this.props.Question.Categories.sort((a, b) => a.Sort - b.Sort)[this.state.currentStageIndex];
            if (currentCategory) {
                if (this.state.currentStageIndex > 0) {
                    this.props.playStageSound();
                }
                this.setState({
                    top: 0,
                    left: 0,
                    removeState: true,
                    currentCategory,
                    currentStageIndex: this.state.currentStageIndex + 1,
                    stageName: this.Rounds[this.stageCount === (this.state.currentStageIndex + 1) ? 0 : (this.state.currentStageIndex + 1)],
                    showItems: this.setItemToShow(currentCategory.Id)
                });
                setTimeout(() => {
                    this.setState({
                        removeState: false
                    });
                }, 3000);
            } else if (this.randomKey === this.state.randomKey) {
                this.props.showEndGame();
            }
        }
    };

    getRandomNumber = (max = 80, min = 0, depth = 0) => {
        let number = Math.floor(Math.random() * (max - min + 1)) + min;
        if (depth === 10) {
            return number;
        }
        var inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * (max - min + 1)) + min;
            inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (this.randomList.length > 0) {
            const lastItem = this.randomList[this.randomList.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (this.randomList.length > 1) {
                const beforeItem = this.randomList[this.randomList.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
            if (number > max || number < min) {
                number = this.getRandomNumber(max, min, depth + 1);
            }
        }
        return number;
    }

    setItemToShow = currentId => {
        const { Question } = this.props;

        const answers = Question.Fills && Question.Answers ? Question.Answers.filter(p => Question.Fills.some(f => f.CategoryId === currentId && f.AnswerId === p.Id)).map(answer => {
            return answer;
        }) : [];
        const count = answers.length;
        let items = [];
        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerStyle.BorderRadiusTop}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusRight}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusBottom}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusLeft}${Question.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerStyle.ShadowPositionX}px ${Question.CustomStyle.answerStyle.ShadowPositionY}px ${Question.CustomStyle.answerStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerStyle.BgAttachment}`,
            zIndex: 1
        };

        var MaxTop = Question.CustomStyle.gamesSetting ? parseFloat(Question.CustomStyle.gamesSetting.MaxTop) : 100;
        var MinTop = Question.CustomStyle.gamesSetting ? parseFloat(Question.CustomStyle.gamesSetting.MinTop) : 0;

        this.randomList = [];
        for (let index = 0; index < count; index++) {
            this.randomList.push(this.getRandomNumber(MaxTop, MinTop));
        }
        for (let index = 0; index < count; index++) {
            const answer = answers[index];
            const CustomStyle = answer.CustomStyle;
            var ref = React.createRef();
            let isAdded = false;
            if (CustomStyle && CustomStyle.Answers && CustomStyle.Movments) {
                const param = CustomStyle.Answers.filter(p => p.Id === answer.Id)[0];
                const Movments = CustomStyle.Movments.filter(p => p.Id === answer.Id);
                if (param) {
                    let customColor = param.CustomColor;
                    let objectCss = param.AnswersSelectObject;
                    let ObjectSrc = param.AnswersSelectObjectSrc;
                    let ObjectHoverSrc = param.AnswersSelectObjectSrcHover;
                    if (objectCss === "") {
                        objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                        ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                        ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                        if (!customColor) {
                            customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                        }
                    }
                    if (param.AnswersSelectType !== "radio" && /\d/.test(param.AnswersSelectType) && param.AnswersSelectType !== "0") {
                        this.selectableCount++;
                    }

                    objectCss = this.props.getRandomObject(objectCss);
                    isAdded = true;
                    const top = this.randomList[index];
                    items.push({
                        Id: answer.Id,
                        jsx: <HorizontalMove
                            playMoveSound={this.playMoveSound}
                            designerContainer={this.props.designerContainer}
                            RocketLauncher={this.RocketLauncher}
                            item={answer}
                            id={param.Id}
                            top={top}
                            rotate={param.rotate}
                            isSpin={param.isSpin}
                            delay={param.delay}
                            flipX={param.flipX}
                            scale={param.scale}
                            transformOriginX={param.transformOriginX}
                            transformOriginY={param.transformOriginY}
                            randomKey={this.randomKey}
                            ref={ref}
                            key={`objectId-${param.Id}-${param.Sort}`}
                            param={param}
                            index={param.Sort}
                            style={styleAnswer}
                            ObjectSrc={ObjectSrc}
                            customColor={customColor}
                            ObjectHoverSrc={ObjectHoverSrc}
                            MoveType={param.AnswersMoveType}
                            Movments={Movments}
                            handelDropObject={this.handelDropObject}
                            Time={param.AnswersTime}
                            objectCss={objectCss}
                            text={answer.Name} />
                    });

                }
            }
            if (!isAdded) {
                var param = {
                    ...answer,
                    rotate: "",
                    isSpin: "",
                    flipX: false,
                    scale: 1,
                    transformOriginX: "",
                    transformOriginY: "",
                    Sort: 0,
                    AnswersTime: 3
                }
                let objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                const ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                const ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                const customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                const top = this.randomList[index];
                objectCss = this.props.getRandomObject(objectCss);
                items.push({
                    Id: answer.Id,
                    jsx: <HorizontalMove
                        playMoveSound={this.playMoveSound}
                        designerContainer={this.props.designerContainer}
                        RocketLauncher={this.RocketLauncher}
                        item={answer}
                        id={param.Id}
                        top={top}
                        rotate={param.rotate}
                        isSpin={param.isSpin}
                        delay={param.delay}
                        flipX={param.flipX}
                        scale={param.scale}
                        transformOriginX={param.transformOriginX}
                        transformOriginY={param.transformOriginY}
                        randomKey={this.randomKey}
                        ref={ref}
                        key={`objectId-${param.Id}-${param.Sort}`}
                        param={param}
                        index={param.Sort}
                        style={styleAnswer}
                        ObjectSrc={ObjectSrc}
                        customColor={customColor}
                        ObjectHoverSrc={ObjectHoverSrc}
                        MoveType={param.AnswersMoveType}
                        Movments={""}
                        handelDropObject={this.handelDropObject}
                        Time={param.AnswersTime}
                        objectCss={objectCss}
                        text={answer.Name} />
                });
            }
        }
        return items;
    };

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    handleShoot = position => {
        let shootList = this.state.shootList;
        if (shootList.length === 0) {
            const ExplosionObject = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.ExplosionObject : "";
            let { objectCss, ObjectSrc, MoveSound, isSpan } = this.props.getExplosionObject(ExplosionObject);

            const ShootObjectSrc = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.ShootObjectSrc : "";
            if (ShootObjectSrc) {
                ObjectSrc = ShootObjectSrc;
            }
            const ShootObjectSound = this.props.Question.CustomStyle.gamesSetting ? this.props.Question.CustomStyle.gamesSetting.ShootObjectSound : "";
            if (ShootObjectSound) {
                MoveSound = ShootObjectSound;
            }
            shootList.push({
                Id: this.state.ShootNumber + 1,
                jsx: <ShootObject
                    Time={0.5}
                    scale={this.props.scale}
                    ExplosionType={ExplosionObject}
                    ExplosionObject={ExplosionObject}
                    objectCss={objectCss}
                    ObjectSrc={ObjectSrc}
                    MoveSound={MoveSound}
                    top={position.top}
                    isSpan={isSpan}
                    isPlayVolumeSound={this.props.isPlayVolumeSound}
                    items={this.state.showItems}
                    Id={this.state.ShootNumber + 1}
                    handleRemoveShoot={this.handleRemoveShoot}
                    designerContainer={this.props.designerContainer}
                />
            });
            this.setState({
                shootList,
                currentStatus: "Click",
                ShootNumber: this.state.ShootNumber + 1
            });
        }
    }

    removeBounsMove = () => {
        setTimeout(() => {
            this.setState({
                BounsTry: 0,
                BounsTime: 0,
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
        }, 4 * 1000);
    }

    handelDropObject = (dropItem, isIn) => {
        this.handleRemoveShoot(0, dropItem, isIn);
    }

    handleRemoveShoot = (Id, dropItem, isIn) => {
        let shootList = this.state.shootList;
        shootList = shootList.filter(p => p.Id !== Id);

        if (dropItem) {
            let showItems = this.state.showItems.filter(p => p.Id !== dropItem.Id);
            const hasAnswer = parseInt(dropItem.AnswersSelectType);

            if (this.props.isMission === true && ((hasAnswer && isIn) || (!hasAnswer && isIn) || (hasAnswer && !isIn))) {
                let userAnswers = this.state.userAnswers.slice();
                userAnswers.push({
                    Answer: dropItem,
                    Category: this.state.currentCategory,
                    isIn,
                    isTrue: hasAnswer ? true : false
                });
                this.setState({
                    userAnswers
                });
                this.props.saveAnswer(userAnswers);
            }

            if (hasAnswer && isIn) {
                if (parseInt(dropItem.BounsTry) > 0 && parseInt(dropItem.BounsTime) > 0) {
                    this.setState({
                        removeState: false,
                        BounsTry: dropItem.BounsTry,
                        BounsTime: dropItem.BounsTime,
                        showBounsTime: true,
                        showBounsTry: true,
                        currentStatus: "True",
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addBounsTimeAndTry(parseInt(dropItem.BounsTime), parseInt(dropItem.BounsTry), true);
                    this.removeBounsMove();
                } else if (parseInt(dropItem.BounsTry) > 0) {
                    this.setState({
                        removeState: false,
                        BounsTry: dropItem.BounsTry,
                        showBounsTry: true,
                        BounsTime: 0,
                        showBounsTime: false,
                        currentStatus: "True",
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addBounsTry(parseInt(dropItem.BounsTry), true);
                    this.removeBounsMove();
                } else if (parseInt(dropItem.BounsTime) > 0) {
                    this.setState({
                        removeState: false,
                        BounsTime: dropItem.BounsTime,
                        showBounsTime: true,
                        currentStatus: "True",
                        BounsTry: 0,
                        showBounsTry: false,
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addBounsTime(parseInt(dropItem.BounsTime), true);
                    this.removeBounsMove();
                } else {
                    this.setState({
                        removeState: false,
                        BounsTime: 0,
                        showBounsTime: false,
                        currentStatus: "True",
                        BounsTry: 0,
                        showBounsTry: false,
                        position: {
                            x: this.state.left,
                            y: this.state.top
                        },
                        showItems
                    });
                    this.props.addTrueTry(true);
                }
            } else if ((!hasAnswer && isIn) || (hasAnswer && !isIn)) {
                this.setState({
                    BounsTry: 0,
                    BounsTime: 0,
                    showBounsTime: false,
                    removeState: false,
                    showBounsTry: false,
                    currentStatus: "Wrong",
                    showItems
                });
                this.props.addFalseTry(true);
            }

            if (hasAnswer > 0) {
                this.selectedCount++;
            }
            if (this.selectedCount === this.selectableCount) {
                this.setState({
                    shootList,
                    removeState: false,
                    showBounsTime: false,
                    showBounsTry: false
                });
                this.selectedCount = 0;
                this.selectableCount = 0;
                this.nextStage();
            }
            // else {
            //     setTimeout(() => {
            //         this.setState({
            //             shootList
            //         });
            //     }, 400);
            // }
        }
        this.setState({
            shootList
        });
    }

    render() {
        const { Question } = this.props;

        const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
        const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";
        const objectCss = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObject : "";
        const ObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrc : "";
        const ObjectHoverSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const customColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropCustomColor : "";
        const Rotate = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.Rotate : "";
        const FlipX = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.FlipX : "";
        const AnswerOrder = Question.CustomStyle.answerBoxStyle ? Question.CustomStyle.answerBoxStyle.AnswerOrder : "";
        const AnswersColsMargin = Question.CustomStyle.answerBoxStyle ? Question.CustomStyle.answerBoxStyle.AnswersColsMargin : "";

        const styleAnswer = {
            minWidth: `${Question.CustomStyle.answerBoxStyle.width}%`,
            maxWidth: '100%',
            minHeight: `${Question.CustomStyle.answerBoxStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerBoxStyle.BorderRadiusTop}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusRight}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusBottom}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusLeft}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerBoxStyle.ShadowPositionX}px ${Question.CustomStyle.answerBoxStyle.ShadowPositionY}px ${Question.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerBoxStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerBoxStyle.BgAttachment}`,
            marginTop: AnswerOrder === "top" ? '85px' : 'unset',
            lineHeight: 1.5
        };

        let objectHeight = 0;
        let position = {
            x: 0,
            y: 0
        };
        if (this.RocketLauncher && this.RocketLauncher.current) {
            const RocketLauncher = this.RocketLauncher.current.getBoundingClientRect();
            objectHeight = (RocketLauncher.height / 2);
            position.x = RocketLauncher.x;
            position.y = RocketLauncher.y + objectHeight;
        }

        return <div className="w-100 h-100 position-absolute oh" id={`Question-${Question.Id}`}   >
            <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${Question.CustomStyle.answerStyle.Custom}} .dragdrop-static-box{${Question.CustomStyle.answerBoxStyle.Custom}}` }}></style>
            <PopUps
                removeState={this.state.removeState}
                showBounsTry={this.state.showBounsTry}
                showBounsTime={this.state.showBounsTime}
                bounsTry={this.state.BounsTry}
                bounsTime={this.state.BounsTime}
                stageCount={this.stageCount}
                TryColorCustom={TryColorCustom}
                TryColor={TryColor}
                label={this.state.stageName.label}
                position={position}
            />
            {
                this.state.showItems ?
                    this.state.showItems.map((item, key) => {
                        return <span key={item.Id} >{item.jsx} </span>
                    })
                    : ""
            }
            {
                this.state.shootList ?
                    this.state.shootList.map(item => {
                        return <span key={`${this.state.currentStageIndex}-${item.Id}`}>{item.jsx}</span>
                    }) : ""
            }

            <div ref={this.refTop} className={`position-absolute w-100 d-flex justify-content-center  mb-${AnswersColsMargin}`}
                style={{
                    zIndex: '15',
                    top: AnswerOrder === "top" ? '0' : 'unset',
                    bottom: AnswerOrder !== "top" ? '0' : 'unset'
                }}>
                <div className="d-flex justify-content-center align-items-center flex-wrap mx-3" style={styleAnswer} dangerouslySetInnerHTML={{
                    __html: this.state.currentCategory.Name
                }}></div>
            </div>

            <div className="position-absolute"
                style={{
                    zIndex: '15',
                    right: '1%',
                    touchAction: 'none',
                    transform: `scale(${this.props.scale})`,
                    top: `${this.props.cursorPosition.top - objectHeight}px`
                }}>
                <div ref={this.RocketLauncher}>
                    <GameObjects
                        {...this.props}
                        rotate={Rotate}
                        flipX={FlipX}
                        ShootNumber={this.state.ShootNumber}
                        FinishMove={this.FinishMove}
                        status={this.state.currentStatus}
                        objectCss={objectCss}
                        ObjectSrc={ObjectSrc}
                        customColor={customColor}
                        ObjectHoverSrc={ObjectHoverSrc}
                        type="dragdrop-move" />
                </div>
            </div>
        </div>;
    }
}

export default ShootingHorizontalMultiSelect;
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import TeacherItem from "./TeacherItem";
import {
    fetchTeachers as fetch,
    removeTeachers as remove,
    undoTeachers as undo,
    clearTeachers as clear,
    updateTeacher as update,
    addTeacher as add
} from './actions/';
import api from '../../../api/api';
import SingleRegFrom from '../../Home/Forms/SingleRegFrom';

class TeachersList extends React.Component {

    state = { showAdd: false, search: '' };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        window.scrollToTop();
        this.props.fetch();
    }

    showAdd = () => {
        this.setState({
            showAdd: true
        });
    }
    handleCloseAdd = () => {
        this.setState({
            showAdd: false,
        });
    }
    Sync = () => {
        this.props.fetch();
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteTeacher(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteTeacher = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Teachers/${id}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.TeachersControl.toast.Delete.Title')}</div> <div className="h6">{t('Pages.TeachersControl.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    renderTeachers() {
        const { t, teachers } = this.props;
        let isLoaded = false;
        let toRemove = [];

        if (teachers) {
            toRemove = teachers.toRemove;
            isLoaded = teachers.isLoaded;
        }

        if (teachers && teachers.teachers && teachers.teachers.length > 0) {
            let filterTeachers = teachers.teachers.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.TeachersControl.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterTeachers.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.TeachersControl.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.TeachersControl.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterTeachers.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(teacher =>
                (toRemove && toRemove.includes(teacher.Id)) ? "" :
                    <TeacherItem
                        key={teacher.Id}
                        item={teacher}
                        updateTeacher={this.props.update}
                        remove={this.showDelete}
                    ></TeacherItem>
            );
        }

        if (teachers && teachers.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.TeachersControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }


        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.TeachersControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/Teachers/`,
            postData
        ).then(response => {
            if (response.data.code === "exists_username") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Username.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Username.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "exists_email") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "max_count") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.max_count_toast.Title')}</div> <div className="h6">{t('Pages.Register.max_count_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "success") {

                this.props.add(response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.TeachersControl.toast.Add.Title')}</div> <div className="h6">{t('Pages.TeachersControl.toast.Add.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleCloseAdd();
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, teachers } = this.props;

        let isLoaded = false;
        if (teachers) {
            isLoaded = teachers.isLoaded;
        }

        return (
            <>

                {
                    this.state.showAdd ?
                        <>
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showAdd}
                                onHide={this.handleCloseAdd}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-plus text-primary icon"></i> {t("Pages.TeachersControl.Add")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <SingleRegFrom
                                                country_code={user.user ? user.user.Country : ""}
                                                sendText={t('Pages.TeachersControl.Save')}
                                                isForTeacher={true}
                                                showEmail={true}
                                                onSubmit={this.onSubmit} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </> : ""
                }

                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.TeachersControl.title')} | {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.TeachersControl.title')}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.TeachersControl.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.showAdd()} title={t("Pages.TeachersControl.Add")} className="btn btn-primary mx-1">
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.TeachersControl.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderTeachers()}

                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        teachers: state.teachers
    };
};


export default connect(mapStateToProps, { fetch, remove, clear, undo, update, add })(withTranslation('common')(TeachersList));

import React from "react";
import GameObjects from "../../GameObjects";

class DashBoardBg extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }
    render() {
        const { user } = this.props;

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url('/assets/images/DashBoard/bg.png')`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute d-none d-sm-block mx-1" style={{
                top: '12%',
                left: '0%'
            }}>
                <GameObjects objectCss="WallClock" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-sm-block mx-3" style={{
                top: 'calc(50% - 150px)',
                right: '0%'
            }}>
                <GameObjects objectCss="Window" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-sm-block" style={{
                bottom: '7%',
                right: '10%'
            }}>
                <GameObjects objectCss={user && user.user && user.user.Sex === "male" ? "BoyReadBook" : "GirlPaint"} status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>
            <div className="position-absolute d-none d-sm-block" style={{
                bottom: '8%',
                left: '10%'
            }}>
                <GameObjects objectCss={user && user.user && user.user.Sex === "female" ? "GirlReadBook" : "BoyPaint"} status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>
        </div>
    }
}
export default DashBoardBg;
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import StudentInClassRoom from '../../School/StudentInClassRooms/';
import EditClassRoomForm from '../../Home/Forms/EditClassRoomForm';
import api from '../../../api/api';

class ClassRoomItem extends React.Component {

    state = {
        moreInfo: false,
        showEdit: false,
        showStudentInClassRooms: false,
        currentItem: null
    };

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    edit = item => {
        this.setState({
            showEdit: true,
            currentItem: item
        });
    }

    handleCloseEdit = () => {
        this.setState({
            showEdit: false,
            currentItem: null
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/ClassRooms/${this.state.currentItem.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.update(this.state.currentItem.Id, response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ClassRoomsControl.toast.Update.Title')}</div> <div className="h6">{t('Pages.ClassRoomsControl.toast.Update.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleCloseEdit();

            } else if (response.data.code === "exist_class") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ClassRoomsControl.toast.ExistClass.Title')}</div> <div className="h6">{t('Pages.ClassRoomsControl.toast.ExistClass.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showStudentInClassRooms = () => {
        this.setState({
            showStudentInClassRooms: true
        });
    }

    handleCloseStudentInClassRooms = () => {
        this.setState({
            showStudentInClassRooms: false
        });
    }

    render() {
        const { t, item, teachers } = this.props;
        if (item) {

            return (
                <>

                    {
                        this.state.showStudentInClassRooms ?
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showStudentInClassRooms}
                                onHide={this.handleCloseStudentInClassRooms}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-users-class text-primary icon"></i> {t("Pages.ClassRoomsControl.StudentInClassRooms.show")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <StudentInClassRoom isTeacher={true} classRoomId={item.Id} />
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showEdit ?
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showEdit}
                                onHide={this.handleCloseEdit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-edit text-primary icon"></i> {t("Pages.ClassRoomsControl.action.edit")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <EditClassRoomForm
                                                teachers={teachers}
                                                classRoom={item}
                                                onSubmit={this.onSubmit}></EditClassRoomForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }


                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            <div className="product-thumb">
                                <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                <div className="product-action-link">
                                    <div className="my-1" onClick={() => this.edit(item)} title={t("Pages.ClassRoomsControl.action.edit")}><i className="far fa-edit"></i></div>
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.ClassRoomsControl.action.info")}><i className="far fa-info"></i></div>
                                    <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.ClassRoomsControl.action.remove")}><i className="far fa-trash"></i></div>
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title"><Link to="#" onClick={() => this.edit(item)}> {item.Name} </Link></h5>
                                <p>
                                    <span title={t('Pages.ClassRoomsControl.Form.Teacher.title')}>
                                        <i className="icon fal fa-chalkboard-teacher"></i>
                                        {item.Teacher ? item.Teacher.Name : t('Pages.ClassRoomsControl.Form.Teacher.NotSelected')}
                                    </span>

                                    <span className="mx-3" title={t('Pages.ClassRoomsControl.studentCount')}>
                                        <i className="icon fal fa-user-graduate"></i>
                                        {item.StudentCount + "  " + t('Pages.ClassRoomsControl.student')}
                                    </span>
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            item.AcademicYear ?
                                                <span title={t('Pages.ClassRoomsControl.AcademicYear')} >
                                                    <i className="icon fad fa-calendar-alt"></i>
                                                    {item.AcademicYear.Name}
                                                </span>
                                                : ""
                                        }
                                    </p>

                                    <div className="d-flex align-items-center mr-1" >
                                        <span className="cate btn btn-light " onClick={() => this.showStudentInClassRooms()} title={t("Pages.ClassRoomsControl.StudentInClassRooms.show")}>
                                            <i className="fad fa-users-class text-primary"></i>
                                        </span>
                                    </div>

                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(ClassRoomItem);
import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';

import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import {
    fetchEmailContents as fetch
} from './actions';
import ListItem from "./ListItem";

class EmailContentList extends React.Component {

    state = { search: '' };

    componentDidMount() {
        this.props.fetch();
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = () => {
        this.props.fetch();
    }

    renderEmailContents() {
        const { t, emailContents } = this.props;
        let isLoaded = false;

        if (emailContents) {
            isLoaded = emailContents.isLoaded;
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (emailContents && emailContents.emails && emailContents.emails.length > 0) {
            let filterSlider = emailContents.emails.filter(v => v.Title.includes(search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.EmailContents.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterSlider.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.EmailContents.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.EmailContents.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterSlider.map(email =>
                <ListItem
                    key={email.Id}
                    item={email}
                    Sync={this.Sync}></ListItem>
            );
        }

        if (emailContents && emailContents.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.EmailContents.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.EmailContents.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    handleClose = () => {
        this.setState({
            showEditFormModal: false
        });
    }

    render() {
        const { t, user, emailContents } = this.props;

        let isLoaded = false;
        if (emailContents) {
            isLoaded = emailContents.isLoaded;
        }

        return (
            <>

                <section className="pos-rel padding-top padding-bottom">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("Pages.EmailContents.title")} | {t('alarabeyya')}</title>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                        </Helmet>

                        <div className="row mb-30-none">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.EmailContents.title')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.EmailContents.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.Sync()} title={t("Pages.EmailContents.Sync")} className="btn mx-1 btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderEmailContents()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        emailContents: state.emailContents
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(EmailContentList));
import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

import GameObjects from "../../GameObjects";
import './style.css';

class Rank extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100 oh`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Rank/bg.png?v=1"})`,
            backgroundColor: '#3cc5fa',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            top: '0'
        }}>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud4" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(55% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud5" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud6" scale={0.5} /></div>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud8" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(70% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud9" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud4" scale={0.5} /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(50% - 169px)',
                top: '40%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud7" scale={0.5} /></div>

            <div className="position-absolute user-select-none MoveAirplane-Rank"><GameObjects objectCss="Airplane" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute w-100 overflow-hidden-lg align-items-center justify-content-center user-select-none d-none d-sm-block" style={{
                pointerEvents: 'none',
                top: '0',
                right: '0',
                height: '100%',
                width: '100%',
                flexShrink: '0'
            }}>
                <div style={{
                    position: "sticky",
                    top: 0
                }}>
                    <Player
                        autoplay
                        loop
                        src={process.env.PUBLIC_URL + "/assets/effect/success/53513-confetti.json"}
                        style={{ height: '100%' }}
                    ></Player>
                </div>
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `2%`,
                left: `0%`
            }}>
                <GameObjects objectCss="BoyJump" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `2%`,
                left: `17%`
            }}>
                <GameObjects objectCss="BoyWithTrophy" />
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `2%`,
                right: `17%`
            }}>
                <GameObjects objectCss="GirlHappy" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `2%`,
                right: `0%`
            }}>
                <GameObjects objectCss="GirlHappyWave" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>
            <div className="position-absolute user-select-none MoveBlueBird-Rank" style={{
                left: '5%',
                top: '5%'
            }}><GameObjects objectCss="RedBird" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default Rank;
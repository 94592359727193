import React from 'react'
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import configData from "../../Config/classType.json";

class EditClassRoomForm extends React.Component {

    state = { initialize: false, errorList: [], ClassType: [], Division: [], teachers: [], TeacherIndex: -1 }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.state.ClassType = configData.ClassType.sort((a, b) => a.Id > b.Id ? 1 : -1);
        this.state.Division = configData.Division.sort((a, b) => a.Id > b.Id ? 1 : -1);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.teachers && Object.keys(props.teachers).length !== 0) {

            state.teachers = props.teachers.teachers.sort((a, b) => a.Name > b.Name ? 1 : -1).map(item => {
                return {
                    key: `teacherId-${item.Id}`,
                    value: item.Id,
                    name: item.Name,
                    label: <div className="d-flex justify-between align-items-center"><LazyLoadImage src={item.Photo} alt={item.Name} style={{ width: '75px' }} />  {item.Name}</div>,
                };
            });

            if (props.classRoom) {
                let id = props.classRoom.Teacher ? props.classRoom.Teacher.Id : 0;
                var index = state.teachers.findIndex(p => p.value === id);
                setTimeout(() => {
                    props.initialize({
                        ClassTypeId: props.classRoom.ClassType.Id,
                        DivisionId: props.classRoom.Division.Id,
                        TeacherId: id,
                    });
                }, 500);
                state.TeacherIndex = index;
            }

            state.initialize = true;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }
    validate = () => {
        const errors = {};

        if (!this.TeacherId.state.value || !this.TeacherId.state.value.value) {
            errors.TeacherId = 'Pages.ClassRoomsControl.Form.Teacher.error';
        }

        if (!this.ClassTypeId.ref.current.getValue()) {
            errors.ClassTypeId = 'Pages.ClassRoomsControl.Form.ClassType.error';
        }

        if (!this.DivisionId.ref.current.getValue()) {
            errors.DivisionId = 'Pages.ClassRoomsControl.Form.Division.error';
        }

        return errors;
    }
    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                ClassTypeId: this.ClassTypeId.ref.current.getValue(),
                TeacherId: this.TeacherId.state.value.value,
                DivisionId: this.DivisionId.ref.current.getValue(),
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);

        }
        e.preventDefault();
    }

    renderClassTypeOption() {
        return this.state.ClassType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }
    renderDivisionOption() {
        return this.state.Division.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    render() {
        const { submitting, t } = this.props;

        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="w-100 d-flex justify-content-center">
                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.ClassRoomsControl.Form.Teacher.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div style={{
                                position: 'relative',
                                zIndex: 99
                            }}>
                                <Select
                                    defaultValue={this.state.TeacherIndex > -1 ? {
                                        label: this.state.teachers[this.state.TeacherIndex].label,
                                        value: this.state.teachers[this.state.TeacherIndex].value
                                    } : ""}
                                    name="TeacherId"
                                    placeholder={t('Pages.ClassRoomsControl.Form.Teacher.Placeholder')}
                                    title={t('Pages.ClassRoomsControl.Form.Teacher.title')}
                                    isRtl={true}
                                    isSearchable={true}
                                    ref={TeacherId => this.TeacherId = TeacherId}
                                    options={this.state.teachers}
                                    filterOption={this.filterOptions}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#92278f',
                                            primary: '#92278f',
                                        },
                                    })}
                                />

                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.ClassRoomsControl.Form.ClassType.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                required={true}
                                ref={ClassTypeId => this.ClassTypeId = ClassTypeId}
                                component="select"
                                name="ClassTypeId"
                                placeholder={t('Pages.ClassRoomsControl.Form.ClassType.Placeholder')}
                                title={t('Pages.ClassRoomsControl.Form.ClassType.title')}
                                className="form-control"
                            >
                                <option></option>
                                {this.renderClassTypeOption()}
                            </Field>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.ClassRoomsControl.Form.Division.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                required={true}
                                ref={DivisionId => this.DivisionId = DivisionId}
                                component="select"
                                name="DivisionId"
                                placeholder={t('Pages.ClassRoomsControl.Form.Division.Placeholder')}
                                title={t('Pages.ClassRoomsControl.Form.Division.title')}
                                className="form-control"
                            >
                                <option></option>
                                {this.renderDivisionOption()}
                            </Field>
                        </div>
                    </div>

                    <div className="form-group w-100 text-center">
                        <button className="custom-button" type="button" onClick={e => this.onSubmit(e)} disabled={submitting} >
                            <span>
                                <i className="icon fal fa-save"></i> {t('Pages.ProfileEdit.Send')}
                            </span>
                        </button>
                    </div>

                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.TeacherId) {
        errors.TeacherId = 'Pages.ClassRoomsControl.Form.Teacher.error';
    }

    if (!values.ClassTypeId) {
        errors.ClassTypeId = 'Pages.ClassRoomsControl.Form.ClassType.error';
    }

    if (!values.DivisionId) {
        errors.DivisionId = 'Pages.ClassRoomsControl.Form.Division.error';
    }

    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'EditClassRoomForm',
    validate
})(EditClassRoomForm))
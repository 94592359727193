const SlidersReducers = (state = [], action) => {
    switch (action.type) {
        case "SLIDERS_LOADING":
            return {
                sliders: state.sliders,
                current: null,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "SLIDERS_INITIAL":
            return {
                sliders: action.sliders,
                current: null,
                toRemove: [],
                isLoaded: true
            };
        case "SLIDER_INITIAL":
            return {
                sliders: state.sliders,
                current: action.slider,
                toRemove: [],
                isLoaded: true
            };
        case "SLIDER_QUEUE_FOR_REMOVAL":
            return {
                sliders: state.sliders,
                current: null,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "SLIDER_CLEAN_NODES":
            return {
                sliders: state.toRemove.some(v => v === action.id) ? state.sliders.filter(v => v.Id !== action.id) : state.sliders,
                current: null,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "SLIDER_UNDO":
            return {
                sliders: state.sliders,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default SlidersReducers;
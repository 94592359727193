import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactPixel from 'react-facebook-pixel';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

import Footer from "../../Footer/FooterBottom";
import Backgrounds from "../../Admin/Question/View/Background";

import fileImg from "./file.png";
import mainVideo from "./1.mp4";
import examVideo from "./2.mp4";
import gameVideo from "./3.mp4";
import VideoPlayer from "./VideoPlayer";
import ComingSoon from "../ComingSoon";

class RegistrationSchool extends React.Component {

    constructor(props) {
        super(props);
        ReactPixel.init('484815450795869');
        ReactPixel.track('PageView', "RegistrationSchool");
    }

    render() {
        const { t } = this.props;
        const date = moment().month('september').endOf('month');

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.title')}  | {t('alarabeyya')}
                    </title>
                    <script type="text/javascript" src="https://app.sprintful.com/widget/v1.js"></script>
                    <link href="https://app.sprintful.com/widget/v1.css" rel="stylesheet"></link>

                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% );overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}.footer-bottom{display:none;}` }}></style>
                <section className="pos-rel call-in-action call-overlay oh" id="register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    backgroundAttachment: 'fixed',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom pt-4" style={{
                        minHeight: '100vh'
                    }}>
                        <span className="d-none d-md-block">
                            <Backgrounds CustomBackground="Registration" />
                        </span>
                        <div className="container" >


                            <div className="teacher-wrapper pos-rel pt-md-5 pt-sm-2 rounded main-bg" style={{
                                background: '#fff'
                            }}>
                                <div className="d-flex justify-content-center mb-2 pt-3" >
                                    <LazyLoadImage src="/assets/images/logo/login-logo.png" alt={`${t('alarabeyya')}`} />
                                </div>
                                <div className=" teacher-single-content px-1 d-flex flex-column pt-3">
                                    <h3 className="title text-center font-weight-bold h1">
                                        الموقع الأوّل من نوعه لتعليم اللغة العربيّة في الشرق الأوسط
                                    </h3>
                                    <div className="w-100 text-center h3 p-3 rounded"  >
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className=" text-center">
                                            ملفّات ورقيّة ودروس محوسبة وألعاب تعليميّة..
                                        </p>
                                    </div>
                                    <VideoPlayer video={mainVideo} />
                                    <div className="form-group w-100 text-center d-flex flex-column justify-content-center align-items-center mt-3 order-sm-3 order-md-2">
                                        <Link to="/Register/School" className={`custom-button `}>
                                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                                <div className="d-flex flex-column py-1" >
                                                    <span style={{
                                                        lineHeight: '30px'
                                                    }}>
                                                        30 دقيقة استشارة مجّانيّة
                                                    </span>
                                                    <span className="px-0" style={{
                                                        lineHeight: '25px',
                                                        fontSize: '16px'
                                                    }}>
                                                        التسجيل يستغرق أقلّ مِن 120 ثانية
                                                    </span>
                                                </div>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="row clearfix justify-content-center mt-4">
                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-download display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            تمَّ تَحمِيل 612،538 +
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            قام مُستخدمو هيّا إلى العربيّة بتحميل أكثر من 600 ألف ملفّ.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-users display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            200 ألف مُستخدِم
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            عدد مُستخدمي المواقع التعليميّة التابعة لنا أكثر من 200 ألف مستخدِم.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-globe display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            24 دولة
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            لدينا زبائن في أكثر من 24 دولة حول العالم.
                                        </p>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-3 col-md-6 col-sm-12"  >
                                    <div className="inner-box wow fadeInLeft animated d-flex h-100 flex-column justify-content-around" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                        <span className="border-one"></span>
                                        <span className="border-two"></span>
                                        <div className="icon-box d-flex justify-content-center flex-column ">
                                            <span className="icon"> <i className=" fa-light fa-presentation-screen display-4 text-white"></i></span>
                                        </div>
                                        <h5 >
                                            2500 ملف+
                                        </h5>
                                        <p className=" text-dark" style={{
                                            fontSize: '16px'
                                        }}  >
                                            ستجد أكثر من 2500 ملفّ، بالإضافة إلى الملفّات التي نضيفها شهريًّا.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded pt-md-5 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className=" teacher-single-content p-3 ">
                                    <h3 className="title text-center">
                                        عند الاشتراك في الموقع، سيحصل طاقم اللغة العربيّة في المدرسة على:
                                    </h3>
                                    <h4 className="title mt-4 font-weight-bold mb-3 text-center">
                                        1#  أوراق عمل وامتحانات
                                    </h4>
                                    <h5 className="title text-center mb-3">
                                        "   ممكن تحميلها وطباعتها "
                                    </h5>
                                    <img src={fileImg} className="w-100" alt="img" />
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            موقع "هيّا إلى العربيّة" يحتوي على أكثر من
                                            <span className=" font-weight-bold mx-1">
                                                2500 ملفّ
                                            </span>
                                            (
                                            أوراق عمل وامتحانات
                                            )
                                            قابلة للتحميل في&nbsp;
                                            <span className=" font-weight-bold">
                                                جميع مواضيع اللغة العربيّة.
                                            </span>
                                            (كلُّ موضوعٍ قد يخطر على بالك للمرحلتين الابتدائيّة والإعداديّة مُتوفّر داخل الموقع).
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            طبعًا مع الأخذ بالحسبان مواضيع مناسبة لجميع الفئات والمجتمعات.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="font-weight-bold">
                                            باختصار، بَدلًا من حرق ساعات من حياتك في تجهيز الوظائف والامتحانات وأوراق العمل، يمكنك ببساطة تحميل الملفات بنقرة زر.
                                        </p>
                                    </div>

                                    <h4 className="title mt-5 font-weight-bold mb-3 text-center">
                                        2#  أَلعَاب تعْليميَّة
                                    </h4>

                                    <video className="w-100" controls muted>
                                        <source src={examVideo} type="video/mp4" />
                                    </video>

                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            موقع "هيّا إلى العربيّة" يحتوي على ألعاب تعليميّة
                                            <span className=" font-weight-bold mx-1">
                                                قمنا ببرمجتها من الصفر وغير متوفّرة في أيّ موقع آخر
                                            </span>.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            هذه الألعاب حصريّة لموقع "هيّا إلى العربيّة" ومن المستحيل أن تجدها في أيّ مكان آخر على الإنترنتّ؛ والسبب بكلّ بساطة هو أنّنا قمنا ببنائها من الصفر، ابتداءً من الفكرة وصولًا إلى البرمجة.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="font-weight-bold">
                                            لقد استغرق موقع 'هيّا إلى العربيّة' أكثر من 10 سنوات من العمل الجادّ، واستثمارات تفوق 5 ملايين دولار للوصول إلى المستوى الحالي. لهذا السبب، يُعدّ من الصعب جدًّا على الشركات الصغيرة محاكاة ما نقدّمه من خدمات ومحتوى.
                                        </p>
                                    </div>

                                    <h4 className="title mt-5 font-weight-bold mb-3 text-center ">
                                        3# دروس محوسبة تشمل جميع مواضيع اللغة العربية
                                    </h4>
                                    <video className="w-100" controls muted>
                                        <source src={gameVideo} type="video/mp4" />
                                    </video>
                                    <div className="w-100 mt-3 h5">
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="text-justify">
                                            <i className="fa-solid fa-circle-small mx-2"></i>
                                            الملفّات المحوسبة هي امتحانات وأوراق عمل يمكن حلّها عن طريق الكمبيوتر، حيث يستطيع الطالب التعلّم بمفرده ويقوم الموقع بالتصحيح تلقائيًّا، ممّا يساعد المعلّم أو وليّ الأمر على اختصار وقت فحص الإجابات من عدَّةِ ساعات إلى عدّةِ ثوانٍ.
                                        </p>
                                        <p style={{
                                            lineHeight: '30px'
                                        }} className="font-weight-bold">
                                            باختصار، الموقع يُقدِّم قائمةً تحتوي على الإجابات الصحيحة والمغلوطة، بالإضافة إلى كشف علامة الطالب بعد ثوانٍ من إنهاء ورقة العمل أو الامتحان المحوسب.
                                        </p>
                                    </div>

                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded pt-md-5 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className=" teacher-single-content p-3 ">

                                    <h4 className="title font-weight-bold mb-3 text-center">
                                        سنة مجاناً
                                    </h4>
                                    <div className="d-flex justify-content-around flex-wrap h4 font-weight-bold">
                                        هذا الخصم متاح حصريًّا بمناسبة بداية العام الدراسيّ الجديد وسينتهي في تاريخ 2024\09\30
                                    </div>

                                    <ComingSoon date={date} />

                                </div>
                            </div>

                            <div className="teacher-wrapper pos-rel mt-4 rounded p-2 pt-md-3 pt-sm-2" style={{
                                background: '#fff8eb'
                            }}>
                                <div className="about-content mb-3">
                                    <div className="faq-wrapper mb--10">
                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يمكن إرسالُ مهامٍّ محوسبة للطلّاب؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، يمكن إرسالُ مهامٍّ محوسبة لكلّ طلاب الصفّ أو جزء منهم، لكن هذه الخاصيّة متوفّرة فقط في اشتراكات المدارس وليست في الاشتراكات الفرديّة.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    ما الفرق بين الاشتراك الفرديّ واشتراك المدارس؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    الاشتراك الفرديّ يسمح بدخول شخص واحد فقط للموقع، بينما في اشتراك المدارس يمكنك فتح حساب لكل طاقم اللغة العربية، بالإضافة إلى تحميل جميع أسماء الطلاب وفتح حساب منفرد لكل طالب، وهذا يمنحك إمكانيّة إرسال مهامّ محوسبة مباشرة للطلاب.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل توجد مواد ملائمة لجميع المستويات؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، الموقع مُقسّم حسب الصفوف، ولكل صفّ برنامج منفرد يتضمّن مواد مختلفة تتناسب مع المستوى التعليميّ لكل صف.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يستطيع عدّة معلّمين الدخول إلى الموقع بنفس الوقت من حساب المدرسة؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نعم، بالتأكيد. يحصل كلّ معلّم على اسم مستخدم وكلمة مرور مختلفة، ممّا يعني أنّ حساب كلّ معلّم يكون منفصلًا تمامًا عن باقي المعلّمين في المدرسة.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="faq-item">
                                            <div className="faq-title">
                                                <span className="right-icon"></span>
                                                <h5 className="title">
                                                    هل يوجد ضمان (كيف أتأكّد أنّ الموقع مناسب لاحتياجاتي)؟
                                                </h5>
                                            </div>
                                            <div className="faq-content" style={{ display: 'none' }}>
                                                <p style={{
                                                    lineHeight: '30px'
                                                }} className=" text-justify">
                                                    نحن واثقون تمامًا من جودة الخدمة التي نقدّمها، ونعلم أنّ المدرسة التي تجرّب موقع 'هيّا إلى العربية' ستصبح مدمِنة على استخدامه. لذلك، إذا لم تكن راضيًا لأيّ سبب، يمكنك استرداد المبلغ كاملًا خلال 30 يومًا من تاريخ الاشتراك.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group w-100 text-center d-flex flex-column justify-content-center align-items-center mt-5 order-sm-3 order-md-2">
                                    <Link to="/Register/School" className={`custom-button `}>
                                        <span className="d-flex justify-content-center align-items-center flex-wrap">
                                            <div className="d-flex flex-column py-1" >
                                                <span style={{
                                                    lineHeight: '30px'
                                                }}>
                                                    30 دقيقة استشارة مجّانيّة
                                                </span>
                                                <span className="px-0" style={{
                                                    lineHeight: '25px',
                                                    fontSize: '16px'
                                                }}>
                                                    التسجيل يستغرق أقلّ مِن 120 ثانية
                                                </span>
                                            </div>
                                        </span>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    </div>
                </section >
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(RegistrationSchool));
import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class QuestionShadow extends React.Component {

    state = {
        shadowColor: 'rgba(0,0,0,0.5)',
        ShadowPositionX: 0,
        ShadowPositionY: 0,
        ShadowRadius: 0,
        displayColorPicker: false
    };

    componentDidMount() {
        this.setState({ shadowColor: this.props.shadowColor });
        this.setState({ ShadowRadius: this.props.ShadowRadius });
        this.setState({ ShadowPositionY: this.props.ShadowPositionY });
        this.setState({ ShadowPositionX: this.props.ShadowPositionX });
    }

    static getDerivedStateFromProps(props, state) {
        state.shadowColor = props.shadowColor;
        state.ShadowRadius = props.ShadowRadius;
        state.ShadowPositionX = props.ShadowPositionX;
        state.ShadowPositionY = props.ShadowPositionY;
        return state;
    }

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false })
    };

    handleClickDisplayColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleColorChange = (color) => {
        this.setState({ shadowColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setQuestionShadowColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setQuestionShadowRadius = value => {
        this.setState({ ShadowRadius: value });
        this.props.setQuestionShadowRadius(value);
    }

    setQuestionShadowPositionY = value => {
        this.setState({ ShadowPositionY: value });
        this.props.setQuestionShadowPositionY(value);

    }

    setQuestionShadowPositionX = value => {
        this.setState({ ShadowPositionX: value });
        this.props.setQuestionShadowPositionX(value);
    }

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        const { t } = this.props;
        return (
            <>
                <li id="PopoverShadow" title={t('Pages.Lessons.Questions.forms.tools.shadow')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="fas fa-eclipse-alt"></i>
                            {t('Pages.Lessons.Questions.forms.tools.shadow')}
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="icon fas fa-eclipse-alt"></i>
                                {t('Pages.Lessons.Questions.forms.tools.shadow')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">
                                    <div className="form-group mb-3">
                                        <label className="text-dark h6">
                                            <i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                readOnly={true}
                                                value={this.state.shadowColor}
                                            />
                                            <button
                                                type="button"
                                                onClick={this.handleClickDisplayColorPicker}
                                                style={{
                                                    color: this.state.shadowColor !== 'transparent' ? this.state.shadowColor : "",
                                                }}>
                                                <i className="fas fa-tint"></i>
                                            </button>
                                            {
                                                this.state.displayColorPicker ?
                                                    <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                        <SketchPicker color={this.state.shadowColor} onChange={this.handleColorChange} />
                                                    </div> : null
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-adjust"></i> {t('Pages.Lessons.Questions.forms.tools.ShadowRadius')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="number"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.ShadowRadius')}
                                                title={t('Pages.Lessons.Questions.forms.tools.ShadowRadius')}
                                                value={this.state.ShadowRadius}
                                                onChange={e => this.setQuestionShadowRadius(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-adjust"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-h"></i> {t('Pages.Lessons.Questions.forms.tools.ShadowPositionX')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="number"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.ShadowPositionX')}
                                                title={t('Pages.Lessons.Questions.forms.tools.ShadowPositionX')}
                                                value={this.state.ShadowPositionX}
                                                onChange={e => this.setQuestionShadowPositionX(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-arrows-alt-h"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="text-dark h6"><i className="icon fas fa-arrows-alt-v"></i> {t('Pages.Lessons.Questions.forms.tools.ShadowPositionY')}</label>
                                        <div className="search-form">
                                            <input className="w-100 form-control" type="number"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.ShadowPositionY')}
                                                title={t('Pages.Lessons.Questions.forms.tools.ShadowPositionY')}
                                                value={this.state.ShadowPositionY}
                                                onChange={e => this.setQuestionShadowPositionY(e.target.value)} />
                                            <button type="button">
                                                <i className="fas fa-arrows-alt-v"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(QuestionShadow);
const ServicesReducers = (state = [], action) => {
    switch (action.type) {
        case "SERVICES_EMPTY":
            return {
                services: []
            };
        case "SERVICES_INITIAL":
            return {
                services: action.data
            };

        default:
            return state;
    }
}

export default ServicesReducers;
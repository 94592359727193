import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';

import effectData from "../Config/effect.json";

class LottiiePalyer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            src: ""
        };
        this.loading = effectData.loading;
    }

    componentDidMount() {
        let src = this.loading[Math.floor(Math.random() * this.loading.length)].url;
        this.setState({
            src
        });
    }

    render() {
        return (
            <>
                {
                    this.state.src ?
                        <div className="w-100 d-flex align-items-center justify-content-center" style={{
                            pointerEvents: 'none',
                            background: '#fff',
                            maxHeight: '75vh',
                        }}>
                            <Player
                                autoplay
                                loop
                                src={this.state.src}
                                style={{ width: '100%', height: '100%' }}
                            ></Player>
                        </div> : ""
                }
            </>
        );
    }
}

export default LottiiePalyer;
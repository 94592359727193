import React from "react";
import GameObjects from "../../GameObjects";

class Oacen extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    renderWave(options = {}) {
        return <svg xmlns="http://www.w3.org/2000/svg" width={options.width ? options.width : 400} height="50" viewBox={`0 0 ${options.width ? options.width : 400} 50`}><path className="wave-path" fill="none" stroke="#fff" strokeWidth={options.strokeWidth ? options.strokeWidth : 4} d="M365 23c-10.094 0-13.036 10-26.071 10-13.036 0-13.036-10-26.072-10s-13.036 10-26.071 10c-13.036 0-13.036-10-26.072-10-13.035 0-13.035 10-26.071 10-13.036 0-13.036-10-26.072-10-13.035 0-13.035 10-26.071 10-13.036 0-13.036-10-26.071-10-13.036 0-13.036 10-26.072 10s-13.036-10-26.071-10C91.25 23 91.25 33 78.214 33 65.18 33 65.18 23 52.143 23 39.107 23 39.107 33 26.07 33 13.036 33 10 23 0 23" /></svg>
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundColor: '#b8f0ff',
            height: '100vh',
            top: '0'
        }}>
            <div className="position-absolute track-bg-1" ></div>
            <div className="position-absolute track-bg-2" ></div>
            <div className="position-absolute track-bg-3" ></div>
            <>
                <div className="position-absolute MoveWave" style={{
                    top: '60%',
                    right: '5%'
                }}>{this.renderWave()}</div>
                <div className="position-absolute MoveWave" style={{
                    top: '80%',
                    right: '15%'
                }}>{this.renderWave({ width: 150 })}</div>
                <div className="position-absolute MoveWave" style={{
                    bottom: '10%',
                    left: '15%'
                }}>{this.renderWave({ width: 350 })}</div>
                <div className="position-absolute MoveWave" style={{
                    bottom: '50%',
                    left: '0%'
                }}>{this.renderWave({ width: 250 })}</div>
                <div className="position-absolute MoveWave d-none d-md-block" style={{
                    top: '20%',
                    left: '2%'
                }}>{this.renderWave({ width: 350 })}</div>
                <div className="position-absolute MoveWave d-none d-md-block" style={{
                    top: '10%',
                    right: '12%'
                }}>{this.renderWave({ width: 350 })}</div>
                <div className="position-absolute MoveWave" style={{
                    top: 'calc(50% - 50px)',
                    right: 'calc(50% - 200px)'
                }}>{this.renderWave()}</div>
            </>


            <div className="position-absolute MoveBoat" style={{
                top: '15%',
                right: '15%'
            }}>
                <div className="position-relative d-flex justify-content-center ">
                    <div className="position-absolute">
                        <GameObjects objectCss="WaterCircles" />
                    </div>
                    <div className="position-absolute" style={{
                        top: '-50px'
                    }}>
                        <GameObjects objectCss="BoysInBoat" status={this.props.currentStatus} FinishMove={this.FinishMove} />
                    </div>
                </div>
            </div>

            <div className="position-absolute" style={{
                bottom: '25%',
                right: '50%'
            }}>
                <div className="position-relative d-flex justify-content-center">
                    <div className="position-absolute">
                        <GameObjects objectCss="WaterCircles" />
                    </div>
                    <div className="position-absolute" style={{
                        top: '-140px',
                        right: '-120px'
                    }}>
                        <GameObjects objectCss="PinkWhale" status={this.props.currentStatus} FinishMove={this.FinishMove} />
                    </div>
                </div>
            </div>
        </div>

    }
}
export default Oacen;
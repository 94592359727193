import React from "react";

import './style.css';

class OacenMove extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100 oh`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/OacenMove/bg.png?v=1"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: 'cover',
            top: '0'
        }}>
            <div class='is-playing-OacenMove w-100 h-100 oh'></div>
        </div>
    }
}
export default OacenMove;
import React from "react";
import './style.css';

class WinterIsland extends React.Component {
    render() {
        return <div className="WinterIsland">
            <div className="WinterIsland-sky">
                <div className="WinterIsland-deep_sky"></div>
            </div>
            <div className="WinterIsland-c1">
                <div className="WinterIsland-cloud"></div>
            </div>
            <div className="WinterIsland-c2">
                <div className="WinterIsland-cloud"></div>
            </div>
            <div className="WinterIsland-c3">
                <div className="WinterIsland-cloud"></div>
            </div>
            <div className="WinterIsland-mountain_a"></div>
            <div className="WinterIsland-mountain_b"></div>
            <div className="WinterIsland-house WinterIsland-right">
                <div className="WinterIsland-fence"></div>
                <div className="WinterIsland-wall"></div>
                <div className="WinterIsland-roof WinterIsland-left"></div>
                <div className="WinterIsland-roof WinterIsland-right"></div>
                <div className="WinterIsland-door"></div>
            </div>
            <div className="WinterIsland-house WinterIsland-left">
                <div className="WinterIsland-fence"></div>
                <div className="WinterIsland-wall"></div>
                <div className="WinterIsland-roof WinterIsland-left"></div>
                <div className="WinterIsland-roof WinterIsland-right"></div>
                <div className="WinterIsland-door"></div>
            </div>
            <div className="WinterIsland-tree_back"></div>
            <div className="WinterIsland-tree"></div>
            <div className="WinterIsland-postbox_a">
                <div className="WinterIsland-hole"></div>
            </div>
            <div className="WinterIsland-postbox_b">
                <div className="WinterIsland-hole"></div>
            </div>
            <div className="WinterIsland-windmill">
                <div className="WinterIsland-tower"></div>
                <div className="WinterIsland-t1"></div>
                <div className="WinterIsland-t2"></div>
                <div className="WinterIsland-blade">
                    <div className="WinterIsland-windblade"></div>
                    <div className="WinterIsland-windblade WinterIsland-windblade2"></div>
                    <div className="WinterIsland-windblade WinterIsland-windblade3"></div>
                    <div className="WinterIsland-windblade WinterIsland-windblade4"></div>
                </div>
            </div>
            <div className="WinterIsland-allsnows">
                <div className="WinterIsland-snow1"></div>
                <div className="WinterIsland-snow2"></div>
                <div className="WinterIsland-snow3"></div>
                <div className="WinterIsland-snow4"></div>
                <div className="WinterIsland-snow5"></div>
                <div className="WinterIsland-snow6"></div>
                <div className="WinterIsland-snow7"></div>
                <div className="WinterIsland-snow8"></div>
                <div className="WinterIsland-snow9"></div>
                <div className="WinterIsland-snow10"></div>
                <div className="WinterIsland-snow11"></div>
                <div className="WinterIsland-snow12"></div>
                <div className="WinterIsland-snow13"></div>
                <div className="WinterIsland-snow14"></div>
                <div className="WinterIsland-snow15"></div>
                <div className="WinterIsland-snow16"></div>
                <div className="WinterIsland-snow17"></div>
                <div className="WinterIsland-snow18"></div>
                <div className="WinterIsland-snow19"></div>
                <div className="WinterIsland-snow20"></div>
            </div>
            <div className="WinterIsland-ground">
                <div className="WinterIsland-g1"></div>
                <div className="WinterIsland-g2"></div>
                <div className="WinterIsland-g3"></div>
                <div className="WinterIsland-ice">
                    <div className="WinterIsland-glare"></div>
                    <div className="WinterIsland-ice_shadow"></div>
                </div>

            </div>
        </div>
    }
}

export default WinterIsland;
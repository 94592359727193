import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class Berry extends React.Component {

    render() {
        let color = "#d33653";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const style = {
            backgroundColor: color
        };
        return <div className="position-relative">
            <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                    transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                }}>
                    <div className={`Fruit-berry mx-auto position-relative mb-2`}>
                        <div className="w-100 d-flex align-items-center flex-column">
                            <div className="Fruit-raspberry-six-pointed-star"></div>
                            <div className="Fruit-raspberry" style={style}>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                                <div className="Fruit-raspberry-ball Fruit-berry-ball" style={style}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TextBox {...this.props} />
        </div >;
    }
}

export default Berry;
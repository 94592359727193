import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

import Items from '../Forms/Items';
import Info from '../Forms/Info';
import api from '../../../../api/api';

import SelectDesigner from '../Forms/SelectDesigner';
import configData from "../../../Config/CustomStyle.json";

class EditModal extends React.Component {

    state = {
        QuestionId: 0, QuestionLessonId: 0, title: '', Level: '', Position: false, showAnswers: false, mark: 0, answers: [], categories: [], fills: [],
        CustomStyle: configData.CustomStyle
    };

    static getDerivedStateFromProps(props, state) {
        if (props.question) {
            if (props.question.id && props.question.id !== 0 && props.question.question) {
                state.QuestionId = props.question.question.Id;
                state.QuestionLessonId = props.question.question.QuestionLessonId;
                state.title = props.question.question.Name;
                state.Level = props.question.question.Level.Id;
                state.mark = props.question.question.Mark;
                state.Position = props.question.question.Position;
                state.CustomStyle = props.question.question.CustomStyle;

                let fills = [];
                if (props.question.question.Fills) {
                    props.question.question.Fills.forEach((node) => {
                        fills.push(node);
                    });
                }
                state.fills = fills;

                let answers = [];
                if (props.question.question.Answers) {
                    props.question.question.Answers.forEach((node) => {
                        if (!props.question.answerToRemove || !props.question.answerToRemove.includes(node.Id)) {
                            answers.push(node);
                        }
                    });
                }
                state.answers = answers;

                let categories = [];
                if (props.question.question.Categories) {
                    props.question.question.Categories.forEach((node) => {
                        if (!props.question.categoryToRemove || !props.question.categoryToRemove.includes(node.Id)) {
                            categories.push(node);
                        }
                    });
                }
                state.categories = categories;
            }
            if (!props.question.question) {
                state = {
                    QuestionId: 0, QuestionLessonId: 0, title: '', Level: '', Position: false, showAnswers: false, mark: 0, answers: [],
                    CustomStyle: configData.CustomStyle
                };
            }
            return state;
        }
        return state;
    }
    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    setPosition = value => {
        this.setState({
            Position: value
        });
    }
    setLevel = value => {
        this.setState({
            Level: value
        });
    }
    setMark = value => {
        this.setState({
            mark: value
        });
    }
    setQuestionId = value => {
        this.setState({ QuestionId: value });
    }

    submitMainInfo = (title, Mark, Level, Position) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var postData = {
            Name: title,
            Mark: Mark,
            Position: Position,
            LevelId: Level,
            LessonId: this.props.LessonId,
            TypeId: this.props.typeId,
            QuestionId: this.state.QuestionId,
            QuestionLessonId: this.state.QuestionLessonId,
            CustomStyle: this.state.CustomStyle
        };

        let url = `/Questions/`;
        let method = api.post;
        if (this.state.QuestionId) {
            url = `/Questions/${this.state.QuestionLessonId}`;
            method = api.put;
        }

        method(url,
            postData
        ).then(response => {
            if (this.state.QuestionId !== 0 || response.data.data.Id !== 0) {

                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });

                let QuestionId = this.state.QuestionId;
                let QuestionLessonId = this.state.QuestionLessonId;

                if (!this.state.QuestionId || this.state.QuestionId === 0) {
                    QuestionId = response.data.data.Id;
                    QuestionLessonId = response.data.data.QuestionLessonId;

                    var copy = this.props.question;
                    copy.id = response.data.data.Id;
                    copy.question = {
                        ...copy.question,
                        CustomStyle: this.state.CustomStyle,
                        Name: title,
                        Mark: Mark,
                        Position: Position,
                        QuestionLessonId: response.data.data.QuestionLessonId,
                        Level:
                        {
                            Id: Level
                        },
                        Type: {
                            Id: this.props.typeId
                        },
                        Id: QuestionId
                    }
                    this.props.updateCurrentQuestion(copy);

                    this.setState({
                        QuestionId: response.data.data.Id,
                        QuestionLessonId: response.data.data.QuestionLessonId,
                        showAnswers: true
                    });

                } else {
                    this.setState({
                        showAnswers: true
                    });
                }

                this.props.setQuestionValue({
                    Name: title,
                    Position: Position,
                    Mark: Mark,
                    QuestionLessonId,
                    Level:
                    {
                        Id: Level
                    },
                    Type: {
                        Id: this.props.typeId
                    },
                    Id: QuestionId
                });

            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.error.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.error.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    onSubmit = (e) => {
        this.submitMainInfo(this.state.title, this.state.mark, this.state.Level, this.state.Position);
        e.preventDefault();
    };

    setAnswersSort = answers => {
        let answerResult = [];
        answers.forEach((answer, index) => {
            answerResult.push({
                ...answer,
                Id: answer.id,
                Name: answer.html,
                Sort: index + 1,
            });
        });
        this.props.setQuestionValue({
            Answers: answerResult
        });

        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Answers: answerResult
        }
        this.props.updateCurrentQuestion(copy);

        this.setState({
            answers
        });
    }
    showDeleteAnswer = id => {
        const { t } = this.props;

        this.props.removeQuestionAnswer(id);

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDeleteAnswer(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteQuestionAnswer(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-recycle"></i> {t('confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.DARK,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    undoDeleteAnswer = id => {
        this.props.undoRemoveQuestionAnswer(id);
    }
    deleteQuestionAnswer = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/QuestionAnswers/${id}?QuestionId=${this.state.QuestionId}`).then(response => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Delete_toast.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Delete_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            let answerResult = [];
            this.props.question.question.Answers.forEach((node) => {
                if (node.Id !== id) {
                    answerResult.push(node);
                }
            });

            let setQuestionValue = {
                Id: this.state.QuestionId,
                Answers: answerResult
            };
            var copy = this.props.question;
            copy.question = {
                ...copy.question,
                Answers: answerResult
            };

            if (this.props.question.question.Type.Id === 3 ||
                this.props.question.question.Type.Id === 5 ||
                this.props.question.question.Type.Id === 6 ||
                this.props.question.question.Type.Id === 7 ||
                this.props.question.question.Type.Id === 9 ||
                this.props.question.question.Type.Id === 14 ||
                this.props.question.question.Type.Id === 19 ||
                this.props.question.question.Type.Id === 20 ||
                this.props.question.question.Type.Id === 23 ||
                this.props.question.question.Type.Id === 24 ||
                this.props.question.question.Type.Id === 25 ||
                this.props.question.question.Type.Id === 26 ||
                this.props.question.question.Type.Id === 28 ||
                this.props.question.question.Type.Id === 29 ||
                this.props.question.question.Type.Id === 30 ||
                this.props.question.question.Type.Id === 31 ||
                this.props.question.question.Type.Id === 32
            ) {
                var Fills = this.props.question.question.Fills.filter(p => p.AnswerId !== id);
                setQuestionValue = {
                    ...setQuestionValue,
                    Fills
                };
                copy.question = {
                    ...copy.question,
                    Fills
                };
            }

            this.props.setQuestionValue({
                ...setQuestionValue
            });
            this.props.updateCurrentQuestion(copy);
            this.props.clearQuestionAnswer(id);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    updateQuestionAnswerInfo = (answers) => {
        let answerResult = [];
        answers.forEach((answer) => {
            answerResult.push({
                Id: answer.id,
                Name: answer.html,
                Sort: answer.Sort,
                True: answer.True,
                CustomStyle: answer.CustomStyle
            });
        });

        this.props.setQuestionValue({
            Id: this.state.QuestionId,
            Answers: answerResult
        });

        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Answers: answerResult
        }

        this.props.updateCurrentQuestion(copy);

        this.setState({
            answers: answers
        });
    }

    setCategoriesSort = categories => {
        let categoryResult = [];
        categories.forEach((category, index) => {
            categoryResult.push({
                ...category,
                Id: category.id,
                Name: category.html,
                Sort: index + 1,
            });
        });
        this.props.setQuestionValue({
            Categories: categoryResult
        });

        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Categories: categoryResult
        }
        this.props.updateCurrentQuestion(copy);

        this.setState({
            categories
        });
    }
    showDeleteCategory = id => {
        const { t } = this.props;

        this.props.removeQuestionCategory(id);

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDeleteCategory(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteQuestionCategory(id); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-recycle"></i> {t('confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.DARK,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    undoDeleteCategory = id => {
        this.props.undoRemoveQuestionCategory(id);
    }
    deleteQuestionCategory = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/QuestionCategories/${id}?QuestionId=${this.state.QuestionId}`).then(response => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Delete_toast.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Delete_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            let categoryResult = [];
            this.props.question.question.Categories.forEach((node) => {
                if (node.Id !== id) {
                    categoryResult.push(node);
                }
            });

            let setQuestionValue = {
                Id: this.state.QuestionId,
                Categories: categoryResult
            };
            var copy = this.props.question;
            copy.question = {
                ...copy.question,
                Categories: categoryResult
            };

            if (this.props.question.question.Type.Id === 3 || this.props.question.question.Type.Id === 8) {
                var Fills = this.props.question.question.Fills.filter(p => p.CategoryId !== id);
                setQuestionValue = {
                    ...setQuestionValue,
                    Fills
                };
                copy.question = {
                    ...copy.question,
                    Fills
                };
            }

            this.props.setQuestionValue({
                ...setQuestionValue
            });
            this.props.updateCurrentQuestion(copy);
            this.props.clearQuestionAnswer(id);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    updateQuestionCategoryInfo = (categories) => {
        let categoryResult = [];
        categories.forEach((category) => {
            categoryResult.push({
                Id: category.id,
                Name: category.html,
                Sort: category.Sort,
                True: category.True,
                CustomStyle: category.CustomStyle
            });
        });

        this.props.setQuestionValue({
            Id: this.state.QuestionId,
            Categories: categoryResult
        });

        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Categories: categoryResult
        }

        this.props.updateCurrentQuestion(copy);

        this.setState({
            categories
        });
    }

    updateQuestionTrueAnswer = (answers) => {
        this.props.setQuestionValue({
            Id: this.state.QuestionId,
            Answers: answers
        });

        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            Answers: answers
        }
        this.props.updateCurrentQuestion(copy);

        this.setState({
            answers: answers
        });
    }

    updateQuestionStyle = CustomStyle => {
        this.props.setQuestionValue({
            Id: this.state.QuestionId,
            CustomStyle: CustomStyle
        });
        var copy = this.props.question;
        copy.question = {
            ...copy.question,
            CustomStyle: CustomStyle
        }
        this.props.updateCurrentQuestion(copy);
        this.setState({
            CustomStyle: CustomStyle
        });
    }

    renderForm() {
        const { t } = this.props;
        if (this.state.QuestionId) {
            if (this.props.typeId === 1) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionAnswerInfo}
                            showDelete={this.showDeleteAnswer}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                            title={t('Pages.Lessons.Questions.forms.Answers')}
                            text={t('Pages.Lessons.Questions.forms.Answer')}
                            sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                            RedactorId="QuestionAnswers"
                            API="/QuestionAnswers/"
                            toast={toast}
                            items={this.state.answers}
                            setSort={this.setAnswersSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 2) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionAnswerInfo}
                            showDelete={this.showDeleteAnswer}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                            title={t('Pages.Lessons.Questions.forms.Answers')}
                            text={t('Pages.Lessons.Questions.forms.Answer')}
                            sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                            RedactorId="QuestionAnswers"
                            API="/QuestionAnswers/"
                            toast={toast}
                            items={this.state.answers}
                            setSort={this.setAnswersSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 3) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionAnswerInfo}
                            showDelete={this.showDeleteAnswer}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                            title={t('Pages.Lessons.Questions.forms.Answers')}
                            text={t('Pages.Lessons.Questions.forms.Answer')}
                            sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                            RedactorId="QuestionAnswers"
                            API="/QuestionAnswers/"
                            toast={toast}
                            items={this.state.answers}
                            setSort={this.setAnswersSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 4 || this.props.typeId === 5) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}

                            updateQuestion={this.props.updateCurrentQuestion}
                            updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            question={this.props.question}
                            fills={this.state.fills}
                            answers={this.state.answers}
                            setSortAnswer={this.setAnswersSort}
                        >
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 6) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}

                            updateQuestion={this.props.updateCurrentQuestion}
                            updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            question={this.props.question}
                            fills={this.state.fills}
                            answers={this.state.answers}
                            setSortAnswer={this.setAnswersSort}
                        >
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 7) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            AnswerRedactorId="QuestionAnswer"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}

                            updateQuestion={this.props.updateCurrentQuestion}
                            updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            question={this.props.question}
                            fills={this.state.fills}
                            answers={this.state.answers}
                            setSortAnswer={this.setAnswersSort}
                        >
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 8) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={false}
                            settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionAnswerInfo}
                            showDelete={this.showDeleteAnswer}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                            title={t('Pages.Lessons.Questions.forms.Answers')}
                            text={t('Pages.Lessons.Questions.forms.Answer')}
                            sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                            RedactorId="QuestionAnswers"
                            API="/QuestionAnswers/"
                            toast={toast}
                            items={this.state.answers}
                            setSort={this.setAnswersSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 9) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Columns')}
                            text={t('Pages.Lessons.Questions.forms.Column')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            AnswerRedactorId="QuestionAnswer"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}
                            max={2}
                            updateQuestion={this.props.updateCurrentQuestion}
                            updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            question={this.props.question}
                            fills={this.state.fills}
                            answers={this.state.answers}
                            setSortAnswer={this.setAnswersSort}
                        >
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 10) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={false}
                            settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionAnswerInfo}
                            showDelete={this.showDeleteAnswer}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                            title={t('Pages.Lessons.Questions.forms.Answers')}
                            text={t('Pages.Lessons.Questions.forms.Answer')}
                            sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                            RedactorId="QuestionAnswers"
                            API="/QuestionAnswers/"
                            toast={toast}
                            items={this.state.answers}
                            setSort={this.setAnswersSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 11) {
                return (
                    <>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 12) {
                return <>
                    <Items
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                        items={this.state.categories}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        question={this.props.question}
                        fills={this.state.fills}
                        answers={this.state.answers}
                        setSortAnswer={this.setAnswersSort}
                    />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        categoryStyle={t('Pages.Lessons.Questions.forms.tools.ClickType.dbclick')}
                        answerBoxStyleTitle={t('Pages.Lessons.Questions.forms.tools.ClickType.click')}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 13) {
                return <>
                    <Items
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        API="/QuestionCategories/"
                        toast={toast}
                        moreSetting={false}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        setSort={this.setCategoriesSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <Items
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        items={this.state.answers}
                        setSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 14) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            AnswerRedactorId="QuestionAnswer"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}

                            updateQuestion={this.props.updateCurrentQuestion}
                            updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            question={this.props.question}
                            fills={this.state.fills}
                            answers={this.state.answers}
                            setSortAnswer={this.setAnswersSort}
                        >
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 15) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        API="/QuestionCategories/"
                        toast={toast}
                        ObjectType={2}
                        moreSetting={true}
                        isCategoryGame={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        setSort={this.setCategoriesSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <Items
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        ObjectType={1}
                        isGame={true}
                        moreSetting={true}
                        isCategoryGame={false}
                        categories={this.state.categories}
                        items={this.state.answers}
                        setSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 16) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        API="/QuestionCategories/"
                        toast={toast}
                        ObjectType={2}
                        moreSetting={true}
                        shootGame={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        setSort={this.setCategoriesSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        setSort={this.setAnswersSort}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        ObjectType={1}
                        moreSetting={true}
                        categories={this.state.categories}
                        items={this.state.answers}
                    />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 17) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        ObjectType={1}
                        moreSetting={true}
                        categories={this.state.categories}
                        items={this.state.answers}
                        setSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 18) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        ObjectType={1}
                        moreSetting={true}
                        categories={this.state.categories}
                        items={this.state.answers}
                        setSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 19) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 20) {
                return (
                    <>
                        <Items
                            isGame={true}
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            AnswerRedactorId="QuestionAnswer"
                            API="/QuestionCategories/"
                            question={this.props.question}
                            isCategory={true}
                            toast={toast}
                            trueAnswer={true}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                            items={this.state.categories}
                            answers={this.state.answers}
                            setSort={this.setCategoriesSort}
                            updateQuestion={this.props.updateCurrentQuestion}
                            fills={this.state.fills}
                            updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            setAnswersSort={this.setAnswersSort}>
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            isGame={true}
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            } else if (this.props.typeId === 21) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        ObjectType={1}
                        moreSetting={true}
                        categories={this.state.categories}
                        items={this.state.answers}
                        setSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 22) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        API="/QuestionCategories/"
                        toast={toast}
                        ObjectType={2}
                        moreSetting={true}
                        isCategoryGame={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        setSort={this.setCategoriesSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionAnswerInfo}
                        showDelete={this.showDeleteAnswer}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditAnswer')}
                        title={t('Pages.Lessons.Questions.forms.Answers')}
                        text={t('Pages.Lessons.Questions.forms.Answer')}
                        sort={t('Pages.Lessons.Questions.forms.OrderAnswers')}
                        RedactorId="QuestionAnswers"
                        API="/QuestionAnswers/"
                        toast={toast}
                        ObjectType={1}
                        moreSetting={true}
                        isCategoryGame={false}
                        categories={this.state.categories}
                        items={this.state.answers}
                        setSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 23) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 24) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 25) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 26 || this.props.typeId === 31) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 27) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 28) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 29) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 30) {
                return <>
                    <Items
                        isGame={true}
                        typeId={this.props.typeId}
                        updateQuestionInfo={this.updateQuestionCategoryInfo}
                        showDelete={this.showDeleteCategory}
                        QuestionId={this.state.QuestionId}
                        EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                        title={t('Pages.Lessons.Questions.forms.Categories')}
                        text={t('Pages.Lessons.Questions.forms.Category')}
                        sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                        RedactorId="QuestionCategories"
                        AnswerRedactorId="QuestionAnswer"
                        API="/QuestionCategories/"
                        question={this.props.question}
                        isCategory={true}
                        toast={toast}
                        moreSetting={true}
                        settingTitle={t('Pages.Lessons.Questions.forms.MoreSetting')}
                        items={this.state.categories}
                        answers={this.state.answers}
                        setSort={this.setCategoriesSort}
                        updateQuestion={this.props.updateCurrentQuestion}
                        fills={this.state.fills}
                        updateQuestionAnswerInfo={this.updateQuestionAnswerInfo}
                        showDeleteAnswer={this.showDeleteAnswer}
                        setAnswersSort={this.setAnswersSort} />
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <SelectDesigner
                        isGame={true}
                        FontSize={this.props.FontSize}
                        updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                        updateQuestionStyle={this.updateQuestionStyle}
                        question={this.props.question}
                        toast={toast}
                        typeId={this.props.typeId}
                        CustomStyle={this.state.CustomStyle}
                        QuestionId={this.state.QuestionId}
                        title={this.state.title}
                        answers={this.state.answers}
                        mark={this.state.mark} />
                </>
            } else if (this.props.typeId === 32) {
                return (
                    <>
                        <Items
                            typeId={this.props.typeId}
                            updateQuestionInfo={this.updateQuestionCategoryInfo}
                            showDelete={this.showDeleteCategory}
                            QuestionId={this.state.QuestionId}
                            EditText={t('Pages.Lessons.Questions.forms.EditCategory')}
                            title={t('Pages.Lessons.Questions.forms.Categories')}
                            text={t('Pages.Lessons.Questions.forms.Category')}
                            sort={t('Pages.Lessons.Questions.forms.OrderCategories')}
                            RedactorId="QuestionCategories"
                            API="/QuestionCategories/"
                            toast={toast}
                            moreSetting={true}
                            settingTitle={t('Pages.Lessons.Questions.forms.Answers')}
                            items={this.state.categories}
                            setSort={this.setCategoriesSort}

                            updateQuestion={this.props.updateCurrentQuestion}
                            updateQuestionInfoAnswer={this.updateQuestionAnswerInfo}
                            showDeleteAnswer={this.showDeleteAnswer}
                            question={this.props.question}
                            fills={this.state.fills}
                            answers={this.state.answers}
                            setSortAnswer={this.setAnswersSort}
                        >
                        </Items>
                        <div className="bg-white w-100 p-1 mb-2"></div>
                        <SelectDesigner
                            FontSize={this.props.FontSize}
                            updateQuestionTrueAnswer={this.updateQuestionTrueAnswer}
                            updateQuestionStyle={this.updateQuestionStyle}
                            question={this.props.question}
                            toast={toast}
                            typeId={this.props.typeId}
                            CustomStyle={this.state.CustomStyle}
                            QuestionId={this.state.QuestionId}
                            title={this.state.title}
                            answers={this.state.answers}
                            mark={this.state.mark}>
                        </SelectDesigner>
                    </>
                );
            }
        }
        return "";
    }

    render() {
        const { t, show, handleClose, title } = this.props;

        if (show) {
            return (
                <>
                    <Modal
                        size="xl"
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title> <i className="icon fal fa-question"></i> {title} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form method="POST" className="contact-form" onSubmit={this.onSubmit}>
                                <Info
                                    isForGame={this.props.isForGame}
                                    QuestionId={this.state.QuestionId}
                                    title={this.state.title}
                                    mark={this.state.mark}
                                    Level={this.state.Level}
                                    Position={this.state.Position}
                                    toast={toast}
                                    typeId={this.props.typeId}
                                    setLevel={this.setLevel}
                                    setPosition={this.setPosition}
                                    setMark={this.setMark}
                                    submitMainInfo={this.submitMainInfo}>
                                </Info>
                                <div className="bg-white w-100 p-1 mb-2"></div>
                                {this.renderForm()}
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12">
                                <button className="btn btn-danger" onClick={handleClose}>
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(reduxForm({
    form: 'Contact'
})(EditModal));

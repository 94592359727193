import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { userLogout, checkLogin } from '../../actions/';
import history from '../../../history';
import { setToken } from '../../../api/api';

class Logout extends React.Component {

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        localStorage.removeItem("token");
        this.props.userLogout(this.props.user);
        setToken("");

        let prevToken = localStorage.getItem('prevToken');
        if (prevToken) {
            localStorage.setItem('token', prevToken);
            setToken(prevToken);
            localStorage.removeItem("prevToken");
            this.props.checkLogin();

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> {t('Pages.Login.PrevLgoin_toast.Title')}</div> <div className="h6">{t('Pages.Login.PrevLgoin_toast.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            history.push("/ControlPanel/Users");
        } else {


            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-sad-tear
            "></i> {t('Pages.Login.Logout_toast.Title')}</div> <div className="h6">{t('Pages.Login.Logout_toast.Body')}</div></div>,
                type: toast.TYPE.DARK,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            history.push("/Login");
        }
    }

    render() {
        return "";
    }

}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, { userLogout, checkLogin })(withTranslation('common')(Logout));
import socketIOClient from "socket.io-client";

export const socket = socketIOClient(process.env.REACT_APP_NODE_SITE_URL);

export const registerOnilneUser = user => {
    socket.send(JSON.stringify({
        type: "REGISTER_USER",
        content: {
            user,
            socketId: socket.id
        }
    }));
}

export const getOnilneUser = () => {
    var result = {};
    socket.send(JSON.stringify({
        type: "GET_ONLINE_USERS"
    }));
    return result
}

export const UserLogout = user => {
    socket.send(JSON.stringify({
        type: "OFFLINE_USER",
        content: { user }
    }));
}

export const UserChangeCurrentPage = (title, pathname, UserId) => {
    setTimeout(() => {
        socket.send(JSON.stringify({
            type: "SET_PAGE_TITLE",
            content: {
                title: title,
                location: pathname,
                UserId
            }
        }));
    }, 1000);
}

export const SendEmailBySocket = (Title, Message, Users) => {
    socket.send(JSON.stringify({
        type: "SEND_EMAIL",
        content: {
            Title,
            Message,
            Users
        }
    }));
}

export const UpdateGameScoreBySocket = UserId => {
    socket.send(JSON.stringify({
        type: "UPDATE_GAME_SCORE",
        content: {
            UserId
        }
    }));
}

export const SetSocket = (UserId, socketId) => {
    socket.send(JSON.stringify({
        type: "SET_SOCKET_ID",
        content: {
            UserId,
            socketId
        }
    }));
}

export const ShareFileBySocket = (StudentId, File) => {
    socket.send(JSON.stringify({
        type: "SHARE_FILE",
        content: {
            StudentId,
            data: File
        }
    }));
}

export const ShareLessonBySocket = (StudentId, Lesson, IndexType) => {
    socket.send(JSON.stringify({
        type: "SHARE_LESSON",
        content: {
            StudentId,
            data: Lesson,
            IndexType
        }
    }));
}

export const ShareMissionBySocket = (StudentId, Mission) => {
    socket.send(JSON.stringify({
        type: "SHARE_MISSION",
        content: {
            StudentId,
            data: Mission
        }
    }));
}

export const notificationIsView = (notificationType, UserId) => {
    socket.send(JSON.stringify({
        type: "NOTIFICATION_ISVIEW",
        content: {
            notificationType,
            UserId
        }
    }));
}

export const SendContactBySocket = Title => {
    socket.send(JSON.stringify({
        type: "SEND_CONTACTUS",
        content: {
            Title
        }
    }));
}

export const SchoolRegisterBySocket = Title => {
    socket.send(JSON.stringify({
        type: "SCHOOL_REGISTER",
        content: {
            Title
        }
    }));
}

export const ErrorReportBySocket = () => {
    socket.send(JSON.stringify({
        type: "ERROR_REPORT"
    }));
}

export const ErrorReportIsSolveBySocket = ErrorRepor => {
    socket.send(JSON.stringify({
        type: "ERROR_REPORT_IS_SOLVE",
        content: {
            ErrorRepor
        }
    }));
}

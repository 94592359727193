import api from "../../../../../api/api";

export const fetchUsers = () => async dispatch => {
    dispatch({ type: 'USERS_LOADING' });
    const response = await api.get(`/SubUsers/`);
    dispatch({ type: 'USERS_INITIAL', users: response.data.data });
}

export const updateUser = (id, user) => async dispatch => {
    dispatch({ type: 'USERS_UPDATE_INFO', user, id });
}

export const servicesInitial = id => async dispatch => {
    dispatch({ type: 'SERVICES_EMPTY' });
    const response = await api.get(`/SubUsers/${id}?ByService=true`);
    dispatch({ type: 'SERVICES_INITIAL', data: response.data.data });
}
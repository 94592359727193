import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

class SubMenu extends React.Component {


    render() {
        const { t } = this.props;

        return (
            <>
                <ul className="filter" id="SubMenu">
                    <li>
                        <Link to={`/Tracks/Books`} className="cate">
                            <i className="fas fa-book"></i>
                            {t('PageHeader.Books')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/Tracks/Lessons`} className="cate">
                            <i className="fas fa-layer-group"></i>
                            {t('PageHeader.Lessons')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/Tracks/Files`} className="cate">
                            <i className="fas fa-paperclip"></i>
                            {t('PageHeader.Files')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/Tracks/Exams`} className="cate">
                            <i className="fas fa-pencil-alt"></i>
                            {t('PageHeader.Exams')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/Tracks/Quizzes`} className="cate">
                            <i className="fal fa-question"></i>
                            {t('PageHeader.Quizzes')}
                        </Link>
                    </li>
                </ul>
            </>
        );
    }
}

export default withTranslation('common')(SubMenu);
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import jsPlumb from 'jsplumb';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import QuestionTitle from '../QuestionTitle/';

class MatchesView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        answers: [],
        fills: [],
        Matches: [],
        answersList: [],
        setMatches: this.setMatches.bind(this),
        updateSize: this.updateSize.bind(this),
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();

        this.targetRef = React.createRef();
        this.targetRef.current = [];

        this.sourceRef = React.createRef();
        this.sourceRef.current = [];

        this.instance = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });

        setTimeout(
            () => {
                this.renderJsPlumb();
            },
            500
        );
    }

    updateSize() {
        if (this.props.setQuestionDesignerContainer && this.designerContainer.current) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        if (this.instance && this.instance.current) {
            this.instance.current.deleteEveryConnection();
        }

        setTimeout(
            () => {
                this.renderJsPlumb()
            },
            100
        );
    }

    setMatches() {
        if ((this.props.onEdit === true || this.props.examAnswers) && this.state.Matches && this.instance.current) {
            this.state.Matches.forEach(item => {
                this.instance.current.connect({
                    source: "jsPlumb_Match_" + this.props.randomId + "_" + item.Source,
                    target: "jsPlumb_Match_" + this.props.randomId + "_" + item.Target,
                    anchors: ["LeftMiddle", "RightMiddle"],
                    paintStyle: { stroke: "#5c96bc", strokeWidth: 2, outlineStroke: "transparent", outlineWidth: 3 },
                });
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.answers = props.answers;
        state.categories = props.categories;
        state.QuestionId = props.Id;
        state.version = props.version;

        if (props.question && state.updateFrame !== props.question && state.updateSize) {
            state.updateFrame = props.question;
            state.updateSize();
        }

        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        let fills = [];
        if (props.fills) {
            props.fills.forEach((node) => {
                const hasAnswer = answerList.filter(p => node.AnswerId === p.Id)[0];
                if (hasAnswer) {
                    const hasCategory = state.categories.filter(p => node.CategoryId === p.Id)[0];
                    if (hasCategory) {
                        fills.push(node);
                    }
                }
            });
        }
        state.fills = fills;

        if (props.onEdit === true && props.Matches) {
            state.Matches = props.Matches;
            state.answersList = props.Matches;
        }

        if (props.examAnswers) {
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answersList = hasAnswer.answers;
                state.Matches = hasAnswer.answers;
                if (props.showResult && hasAnswer.answers) {

                    const { t, mark } = props;
                    let trueAnswer = 0;
                    const trueAnswers = props.Matches.length;
                    const answersList = hasAnswer.answers;

                    props.Matches.forEach(item => {
                        var hasResult = answersList.filter(p => p.Source === item.Source && p.Target === item.Target)[0];
                        if (hasResult) {
                            trueAnswer++;
                        }
                    });

                    if (trueAnswer === 0) {
                        state.mark = {
                            type: 'danger',
                            userMark: 0,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else if (trueAnswers === trueAnswer) {
                        state.mark = {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        };
                    } else {
                        let userMark = (mark * trueAnswer / trueAnswers).toFixed(2);
                        state.mark = {
                            type: userMark >= (mark / 2) ? 'warning' : 'danger',
                            userMark: userMark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    }
                }
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }

        return state;
    }

    renderCategories() {
        if (this.state.categories) {
            const { Id } = this.props;
            let categorys = this.state.categories.sort((a, b) => a.Sort - b.Sort);

            let ColsWidthRight = 4;
            if (this.state.CustomStyle.questionTypeStyle.ColsWidthRight &&
                this.state.CustomStyle.questionTypeStyle.ColsWidthRight > 0 &&
                this.state.CustomStyle.questionTypeStyle.ColsWidthRight < 6) {
                ColsWidthRight = this.state.CustomStyle.questionTypeStyle.ColsWidthRight;
            }

            let ColsWidthLeft = 4;
            if (this.state.CustomStyle.questionTypeStyle.ColsWidthLeft &&
                this.state.CustomStyle.questionTypeStyle.ColsWidthLeft > 0 &&
                this.state.CustomStyle.questionTypeStyle.ColsWidthLeft < 6) {
                ColsWidthLeft = this.state.CustomStyle.questionTypeStyle.ColsWidthLeft;
            }

            return (
                <div className={`row`} style={{
                    height: '100%',
                    position: 'relative'
                }}>
                    {
                        categorys[0] ?
                            <div className={`col-${ColsWidthRight} text-center`} id={`item_right_${Id}`}>
                                {this.renderColumn(categorys[0], true)}
                            </div>
                            : ""
                    }
                    <div className="col" ></div>
                    {
                        categorys[1] ?
                            <div className={`col-${ColsWidthLeft} text-center`} id={`item_left_${Id}`} >
                                {this.renderColumn(categorys[1], false)}
                            </div>
                            : ""
                    }

                    <style dangerouslySetInnerHTML={{ __html: `.SelectDesignerPreview_${Id}{${this.state.CustomStyle.categoryStyle.Custom} }` }}></style>
                    <style dangerouslySetInnerHTML={{ __html: `.SelectDesignerAnswerPreview_${Id}{${this.state.CustomStyle.answerStyle.Custom} }` }}></style>
                </div >
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        } else if (showResult) {
            return (
                <>
                    {this.renderMark()}
                </>
            );
        }
        return "";
    }

    ResetAnswer = () => {
        if (this.instance.current) {
            this.instance.current.deleteEveryConnection();
        }
        this.setState({
            answersList: [],
            version: this.state.version + 1,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });
    }

    CheckAnswer = () => {
        const { t, mark, toast } = this.props;
        let trueAnswer = 0;
        const trueAnswers = this.props.Matches.length;
        const answersList = this.state.answersList;
        const answersCount = answersList.length;

        this.props.Matches.forEach(item => {
            var hasResult = answersList.filter(p => p.Source === item.Source && p.Target === item.Target)[0];
            if (hasResult) {
                trueAnswer++;
            }
        });

        if (answersCount === 0) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-lightbulb-on"></i> {t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (trueAnswer === 0) {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        } else if (trueAnswers === trueAnswer) {
            this.setState({
                mark: {
                    type: 'success',
                    userMark: mark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.success.Title')
                }
            });
            this.props.playTrueSound();
            this.props.showConfetti();
        } else {
            let userMark = (mark * trueAnswer / trueAnswers).toFixed(2);
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: userMark >= (mark / 2) ? 'warning' : 'danger',
                    userMark: userMark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        }
    }

    renderMark() {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        }
        return "";
    }

    renderColumn(item, isSource) {

        const style = {
            paddingLeft: `${this.state.CustomStyle.categoryStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.categoryStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.categoryStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.categoryStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.categoryStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.categoryStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.categoryStyle.BorderRadiusTop}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusRight}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.categoryStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryStyle.shadowColor}`,
        };

        const styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.categoryStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.categoryStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.categoryStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.categoryStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.categoryStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.categoryStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.categoryStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.categoryStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.categoryStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.categoryStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.categoryStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.categoryStyle.BgAttachment}`,
        };
        let AnswersMargin = this.state.CustomStyle.questionTypeStyle.AnswersMargin;

        let answerIds = this.state.fills.filter(p => p.CategoryId === item.Id);
        return (
            <>
                <div className={`mb-${AnswersMargin} position-relative overflow-hidden`} style={style}>
                    <div className={`w-100 position-absolute SelectDesignerPreview_${this.props.Id}`} style={styleBackground}> </div>
                    <div className={`m-auto position-relative overflow-x-hidden`} dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                </div>
                {this.renderAnswers(answerIds, isSource)}
            </>
        );
    }

    renderAnswers(answerIds, isSource) {
        const { randomId, t } = this.props;
        let BorderStyle = this.state.CustomStyle.questionTypeStyle.BorderStyle;
        let borderRadius = '0px';
        if (BorderStyle === "circle") {
            borderRadius = '10px';
        } else if (BorderStyle === "round") {
            borderRadius = '5px';
        }

        let AnswersSelectClass = "warning";
        if (this.state.CustomStyle.questionTypeStyle.AnswersSelectClass) {
            AnswersSelectClass = this.state.CustomStyle.questionTypeStyle.AnswersSelectClass;
        }

        const AnswersCols = this.state.CustomStyle.questionTypeStyle.AnswersCols;

        const style = {
            paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,
        };

        const styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.answerStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.answerStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.answerStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`,
        };

        let answers = this.state.answers.sort((a, b) => a.Sort - b.Sort).filter(p => answerIds.some(a => a.AnswerId === p.Id));
        let ref = this.addTargetRef;
        let more = "";
        let connect = "";
        if (isSource) {
            ref = this.addSourceRef;
            more = <i className={`ep fa my-auto mr-0 p-2 cursor-pointer bg-${AnswersSelectClass}`} style={{
                width: '20px',
                height: '20px',
                position: 'relative',
                border: '1px solid #3e444a',
                borderRadius
            }}></i>;
        } else {
            connect = <i className={`ep fa my-auto ml-1 mr-0 cursor-pointer p-2 bg-${AnswersSelectClass}`} style={{
                width: '20px',
                height: '20px',
                position: 'relative',
                border: '1px solid #3e444a',
                borderRadius
            }}></i>;
        }

        return answers.map(item => {
            let isAnswerTrue = "";
            if (this.state.mark.isSolved && isSource && this.props.Matches) {
                isAnswerTrue = <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="mr-1 my-auto position-relative fas fa-exclamation-triangle text-danger h6"></i>;
                var currentAnswer = this.state.answersList.filter(p => p.Source === item.Id)[0];
                if (currentAnswer) {
                    var hasAnswer = this.props.Matches.some(p => p.Source === currentAnswer.Source && p.Target === currentAnswer.Target);
                    if (hasAnswer) {
                        isAnswerTrue = <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="mr-1 my-auto position-relative fad fa-check-circle text-success h6"></i>;
                    }
                }
            }
            return (
                <div className={`text-right d-flex mt-${AnswersCols} position-relative overflow-hidden`} id={`jsPlumb_Match_${randomId}_${item.Id}`} aid={item.Id} key={item.Id} ref={ref} style={style}>
                    <div className={`w-100 position-absolute SelectDesignerAnswerPreview_${this.props.Id}`} style={styleBackground}> </div>
                    {connect}
                    <div className="ml-auto w-100">
                        <div className={`m-auto position-relative overflow-x-hidden`} dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                    </div>
                    {more}
                    {isAnswerTrue}
                </div>
            )
        });
    }

    addTargetRef = el => {
        if (el && !this.targetRef.current.includes(el)) {
            this.targetRef.current.push(el);
        }
    }

    addSourceRef = el => {
        if (el && !this.sourceRef.current.includes(el)) {
            this.sourceRef.current.push(el);
        }
    }

    seTargetAnswer = (Source, Target) => {
        let answersList = this.state.answersList.slice();
        const index = answersList.findIndex(a => a.Source === Source && a.Target === Target);
        if (index !== -1) {
            answersList.splice(index, 1);
        }
        answersList.push({
            Target,
            Source
        });
        this.setState({
            answersList,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });


        if (this.props.question && this.props.question.question) {
            var copy = this.props.question;
            copy.question = {
                ...copy.question,
                Matches: answersList
            }
            this.props.updateQuestion(copy);
            this.props.setMatches(answersList);
        }

        this.saveExamAnswer(answersList);
    }

    saveExamAnswer(answersList) {
        if (this.props.saveExamAnswer) {
            let trueAnswer = 0;
            let falseAnswer = 0;
            const trueResultCount = this.props.Matches.length;
            this.props.Matches.forEach(item => {
                var hasResult = answersList.filter(p => p.Source === item.Source && p.Target === item.Target)[0];
                if (hasResult) {
                    trueAnswer++;
                }
            });

            let userMark = 0;
            const mark = this.props.mark
            if (trueResultCount === trueAnswer) {
                userMark = mark;
            } else {
                userMark = (mark * trueAnswer / trueResultCount).toFixed(2);
            }

            this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answersList, {
                trueResultCount,
                trueAnswer,
                falseAnswer,
                questionMark: this.props.mark
            }, userMark);
        }
    }

    removeTargetAnswer = (Source, Target) => {
        let answersList = this.state.answersList.slice();
        const index = answersList.findIndex(a => a.Source === Source && a.Target === Target);
        if (index !== -1) {
            answersList.splice(index, 1);
        }
        this.setState({
            answersList,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });


        if (this.props.question && this.props.question.question) {
            var copy = this.props.question;
            copy.question = {
                ...copy.question,
                Matches: answersList
            }
            this.props.updateQuestion(copy);
            this.props.setMatches(answersList);
        }
        this.saveExamAnswer(answersList);
    }

    renderJsPlumb() {
        const { Id, randomId } = this.props;

        const jsPlumbObj = jsPlumb.jsPlumb;
        const sourceRef = this.sourceRef.current;
        const targetRef = this.targetRef.current;
        const seTargetAnswer = this.seTargetAnswer;
        const removeTargetAnswer = this.removeTargetAnswer;
        let instance = this.instance.current;

        try {
            jsPlumbObj.ready(function () {
                jsPlumbObj.setContainer(`SelectDesignerPreview_${randomId}_${Id}`);
                instance = jsPlumbObj.getInstance({
                    cursor: 'move',
                    Endpoint: ["Dot", { radius: 1 }],
                    HoverPaintStyle: { stroke: "#1e8151", strokeWidth: 2 },
                    ConnectionOverlays: [
                        ["Arrow", {
                            location: 1,
                            id: "arrow",
                            length: 14,
                            foldback: 0.8
                        }]
                    ],
                    Container: `SelectDesignerPreview_${randomId}_${Id}`
                });
                instance.bind("click", function (c) {

                    const Source = parseInt(c.source.attributes['aid'].value);
                    const Target = parseInt(c.target.attributes['aid'].value);
                    instance.deleteConnection(c);
                    removeTargetAnswer(Source, Target);
                });
                instance.bind("connection", function (c) {
                    const Source = parseInt(c.source.attributes['aid'].value);
                    const Target = parseInt(c.target.attributes['aid'].value);
                    seTargetAnswer(Source, Target);
                });
                let initNode = function (el, isSourceType) {
                    if (isSourceType) {
                        instance.makeSource(el, {
                            filter: ".ep",
                            anchor: "Continuous",
                            connectorStyle: { stroke: "#5c96bc", strokeWidth: 2, outlineStroke: "transparent", outlineWidth: 3 },
                            connectionType: "basic",
                            extract: {
                                "action": "the-action"
                            },
                            maxConnections: 1,
                            onMaxConnections: function () {
                                return false;
                            }
                        });
                    } else {
                        instance.makeTarget(el, {
                            dropOptions: { hoverClass: "dragHover" },
                            anchor: "Continuous",
                            maxConnections: 1,
                            onMaxConnections: function (info, e) {
                                return false;
                            },
                            allowLoopback: false
                        });
                    }
                    instance.fire("jsPlumbDemoNodeAdded", el);
                };
                sourceRef.forEach(item => {
                    initNode(item, true);
                });
                targetRef.forEach(item => {
                    initNode(item, false);
                });
                jsPlumbObj.fire("jsPlumbDemoLoaded", instance);
            });

            this.instance.current = instance;
            setTimeout(
                () => {
                    this.setMatches()
                },
                500
            );
        } catch { }
    }

    render() {
        const { isExamQuestion, title, mark, index, Id, randomId, onEdit, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'absolute') {
            style = { ...style, height: `${this.state.CustomStyle.questionStyle.height}px` };
        }

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`} id={`matchView-${randomId}`} >
                <div className="bg-white w-100 p-3 form-group w-100 position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} id={`SelectDesignerPreview_${randomId}_${Id}`} style={style} >
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`}> </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            {this.renderCategories()}
                        </div>
                    </div>
                    {isExamQuestion === true ? showResult === true ? this.renderMark() : "" : ""}
                    {isExamQuestion !== true ? this.renderControlButton() : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(MatchesView));
import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

import Rate from '../../Home/Tracks/Rate';

class Tools extends React.Component {

    state = { showRate: false, hideRate: false }

    showRate = () => {
        this.setState({
            showRate: !this.state.showRate
        });
    }

    hideRate = () => {
        this.setState({
            showRate: false,
            hideRate: true
        });
    }

    render() {
        const { t, LessonId, isOnEdit, HasLesson, Sound, bothSides, Url, isPDF, bookView, widthForBoth, user, isRated } = this.props;

        return (
            <>
                {
                    this.state.showRate ?
                        <Rate
                            id={LessonId}
                            IsForLesson={true}
                            hideRate={this.hideRate}
                            showRate={this.showRate} />
                        : ""
                }
                <ul className="filter" id="tools" >
                    {
                        isOnEdit ?
                            <>
                                <li className="mb-3 w-100" style={{
                                    border: '0px',
                                    fontSize: '24px',
                                    fontWeight: '500'
                                }}>
                                    {this.props.lessonTitle}
                                </li>
                                <li onClick={() => this.props.goBack()} title={t('Pages.Lessons.tools.back')}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-chevron-double-right"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.showLessonInfoModal()} title={t('Pages.Lessons.tools.setting')}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-cogs"></i>
                                    </Link>
                                </li>
                            </> : ""
                    }
                    {
                        (isOnEdit && LessonId && LessonId !== "0") ?
                            <>
                                <li onClick={() => this.props.hideBookMenw()} title={t('Pages.Lessons.tools.questionsList')}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-question"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.showPagesModal()} title={t('Pages.Lessons.tools.pages')}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-book-reader"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.showSort()} title={t("ControlPanel.Indices.tools.sort")}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-sort-numeric-up"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.openViewWindow()} title={t("Pages.Lessons.tools.view")}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-eye"></i>
                                    </Link>
                                </li>
                                {
                                    Url ?
                                        <li onClick={() => this.props.openPDFViewWindow()} title={t("Pages.Lessons.tools.viewPDF")}>
                                            <Link to="#" className="cate" >
                                                <i className="fad fa-file-pdf"></i>
                                            </Link>
                                        </li>
                                        : ""
                                }
                            </>
                            : ""
                    }
                    {
                        (!isOnEdit && LessonId && LessonId !== "0" && !isPDF) ?
                            <>
                                {
                                    this.props.moreFontSize ?
                                        <li onClick={() => this.props.moreFontSize()} title={t('Pages.Lessons.tools.plus')}>
                                            <Link to="#" className="cate">
                                                <i className="fas fa-plus-square"></i>
                                            </Link>
                                        </li>
                                        : ""
                                }
                                {
                                    this.props.resetFontSize ?
                                        <li onClick={() => this.props.resetFontSize()} title={t('Pages.Lessons.tools.reset')}>
                                            <Link to="#" className="cate">
                                                <i className="fas fa-sync"></i>
                                            </Link>
                                        </li>
                                        : ""
                                }
                                {
                                    this.props.lessFontSize ?
                                        <li onClick={() => this.props.lessFontSize()} title={t('Pages.Lessons.tools.minus')}>
                                            <Link to="#" className="cate">
                                                <i className="fas fa-minus-square"></i>
                                            </Link>
                                        </li>
                                        : ""
                                }
                            </>
                            : ""
                    }
                    {
                        (!isOnEdit && LessonId && LessonId !== "0" && bothSides) ?
                            <>
                                <li onClick={() => this.props.hideLeftSide()} title={t('Pages.Lessons.tools.leftSide')}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-arrow-alt-from-left"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.showAllSides()} title={t('Pages.Lessons.tools.allSide')}>
                                    <Link to="#" className="cate" >
                                        <i className="fal fa-book-open"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.hideRightSide()} title={t('Pages.Lessons.tools.rightSide')}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-arrow-alt-from-right"></i>
                                    </Link>
                                </li>
                            </>
                            : ""
                    }
                    {
                        (!isOnEdit && LessonId && LessonId !== "0" && !isPDF && Url) ?
                            <>
                                <li onClick={() => this.props.openPDFViewWindow()} title={t("Pages.Lessons.tools.viewPDF")}>
                                    <Link to="#" className="cate" >
                                        <i className="fad fa-file-pdf"></i>
                                    </Link>
                                </li>
                            </>
                            : ""
                    }
                    {
                        (!isOnEdit && LessonId && LessonId !== "0" && isPDF) ?
                            <>
                                <li onClick={() => this.props.moreSize()} title={t('Pages.Lessons.tools.scaleUp')}>
                                    <Link to="#" className="cate">
                                        <i className="fas fa-plus-square"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.resetSize()} title={t('Pages.Lessons.tools.scaleReset')}>
                                    <Link to="#" className="cate">
                                        <i className="fas fa-sync"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.lessSize()} title={t('Pages.Lessons.tools.scaleDown')}>
                                    <Link to="#" className="cate">
                                        <i className="fas fa-minus-square"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.first()} title={t('Pages.Lessons.tools.first')}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-fast-forward"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.prev()} title={t('Pages.Lessons.tools.prev')}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.next()} title={t('Pages.Lessons.tools.next')}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-long-arrow-alt-left"></i>
                                    </Link>
                                </li>
                                <li onClick={() => this.props.last()} title={t('Pages.Lessons.tools.last')}>
                                    <Link to="#" className="cate" >
                                        <i className="fas fa-fast-backward"></i>
                                    </Link>
                                </li>
                                {
                                    widthForBoth ?
                                        bookView ?
                                            <li onClick={() => this.props.oneSide()} title={t('Pages.Lessons.tools.oneSide')}>
                                                <Link to="#" className="cate" >
                                                    <i className="fal fa-presentation"></i>
                                                </Link>
                                            </li>
                                            :
                                            <li onClick={() => this.props.twoSide()} title={t('Pages.Lessons.tools.twoSide')}>
                                                <Link to="#" className="cate" >
                                                    <i className="fal fa-book-open"></i>
                                                </Link>
                                            </li>
                                        : ""
                                }
                                {
                                    (HasLesson && HasLesson !== "0") ?
                                        <>
                                            <li onClick={() => this.props.openViewWindow()} title={t("Pages.Lessons.tools.view")}>
                                                <Link to="#" className="cate" >
                                                    <i className="fad fa-eye"></i>
                                                </Link>
                                            </li>
                                        </>
                                        : ""
                                }

                                {
                                    this.props.token ?
                                        <li onClick={() => this.props.download()} title={t("Pages.Files.action.download")}>
                                            <Link to="#" className="cate" >
                                                <i className="far fa-download"></i>
                                            </Link>
                                        </li>
                                        : ""
                                }
                            </>
                            : ""
                    }
                    {
                        (!isOnEdit && LessonId && LessonId !== "0") ?
                            <>
                                <li onClick={() => this.props.showPaint()} title={t('Pages.Lessons.tools.painter.title')}>
                                    <Link to="#" className="cate">
                                        <i className="fas fa-palette"></i>
                                    </Link>
                                </li>
                                <li title={t('Pages.Lessons.Questions.forms.fullscreen')} className="expand-button">
                                    <i className="fas fa-expand"></i>
                                </li>
                            </>
                            : ""
                    }
                    {
                        (LessonId && LessonId !== "0" && Sound) ?
                            <>
                                <li onClick={() => this.props.showSound()} title={t('Pages.Lessons.tools.volume')}>
                                    <Link to="#" className="cate">
                                        <i className="fad fa-volume"></i>
                                    </Link>
                                </li>
                            </>
                            : ""
                    }
                    {
                        !isOnEdit && !isRated && !this.state.hideRate && user && user.user && user.user.UserType && user.user.UserType.Id !== 5 ?
                            <>
                                <li onClick={() => this.showRate()} title={t('Pages.Lessons.tools.rate')}>
                                    <Link to="#" className="cate">
                                        <i className="fad fa-star text-warning"></i>
                                    </Link>
                                </li>
                            </>
                            : ""
                    }
                </ul>
            </>
        );
    }
}

export default withTranslation('common')(Tools);
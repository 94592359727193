import React from "react";

class ShootObject extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.rope = React.createRef();
        this.Rocket = React.createRef();
        this.state = {
            Id: 0,
            height: 0,
            rotate: 0,
            element: "",
            Rocket: React.createRef(),
            rotateFactor: 1,
            top: this.props.designerContainer.current && this.props.basket.current ? this.props.designerContainer.current.clientHeight - this.props.basket.current.clientHeight : 0,
            zIndex: 14,
            factor: 1,
            pullObject: {
                width: 0,
                height: 0
            },
            shootTop: 0,
            speed: this.props.designerContainer.current ? this.props.designerContainer.current.clientHeight / this.props.Time : 0,
            objectMovmentId: Math.floor(Math.random() * 100000)
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isGo === false && state.Rocket.current) {
            const Rocket = state.Rocket.current.getBoundingClientRect();
            state.shootTop = Rocket.top;//- props.OffsetTop;
        }
        return state;
    }


    componentDidMount() {
        this.setState({
            Rocket: this.Rocket
        });
        this.timer = setInterval(() => {
            if (this.Rocket && this.Rocket.current && this.props.designerContainer && this.props.designerContainer.current) {
                this.goUp();
            }
        }, 30);
    }

    goUp = () => {
        if (this.props.isGo === true) {
            let factor = this.state.factor;
            if (this.state.height <= 100 && this.state.height >= 0) {
                this.setState({
                    height: this.state.height + (this.state.speed / (30 * (this.state.Id === 0 ? 1 : 3)) * factor)
                });
                if (factor === 1) {
                    this.checkIfCatch();
                }

                if (this.Rocket && this.Rocket.current) {
                    const Rocket = this.Rocket.current.getBoundingClientRect();
                    let top = Rocket.top - (this.state.pullObject.height / 2);
                    if (this.props.direction === "bottom") {
                        top = Rocket.top + (this.state.pullObject.height / 4);
                    }
                    window.moveToInPixel(`objectMovment${this.state.Id}`, top, Rocket.left - (this.state.pullObject.width / 2), 30);
                }
            } else {
                if (factor === -1) {
                    this.props.stopMove(this.state.Id, this.state.element);
                    this.setState({
                        Id: 0,
                        element: "",
                        IsIn: false,
                    });
                }
                this.setState({
                    factor: factor * -1,
                    height: factor === 1 ? 100 : 0
                });
            }
            this.checkIfOut();
        } else {
            if (this.state.rotate <= 85 && this.state.rotate >= -85) {
                this.setState({
                    rotate: this.state.rotate + (this.state.speed / 100 * this.state.rotateFactor)
                });
            } else {
                this.setState({
                    rotateFactor: this.state.rotateFactor * -1,
                    rotate: this.state.rotateFactor === 1 ? 85 : -85
                });
            }
        }
    }

    checkIfOut = () => {
        if (this.Rocket && this.Rocket.current && this.props.designerContainer && this.props.designerContainer.current) {
            const rocket = this.Rocket.current.getBoundingClientRect();
            const width = this.props.designerContainer.current.scrollWidth;
            const height = this.props.designerContainer.current.scrollHeight;
            if (rocket.x < 0 || rocket.x > width || rocket.y < 0 || rocket.y > height) {
                this.setState({
                    factor: -1
                });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    checkIfCatch = () => {
        if (this.Rocket && this.Rocket.current) {
            const rocket = this.Rocket.current.getBoundingClientRect();

            for (var i = 0; i < this.props.items.length; ++i) {
                const element = this.props.items[i];

                if (element.jsx.ref.current && element.jsx.ref.current.designerContainer && element.jsx.ref.current.designerContainer.current) {
                    const object = element.jsx.ref.current.designerContainer.current.getBoundingClientRect();
                    const width = element.jsx.ref.current.designerContainer.current.scrollWidth;
                    const height = element.jsx.ref.current.designerContainer.current.scrollHeight;
                    if (rocket.x >= object.x && rocket.x <= (object.x + width)
                        &&
                        rocket.y >= object.y && rocket.y <= (object.y + height - 40)
                    ) {
                        window.stopMove(`objectMovment${element.Id}`);
                        this.setState({
                            Id: element.Id,
                            pullObject: {
                                width,
                                height
                            },
                            element: element.jsx.props.param,
                            factor: -1
                        });
                        break;
                    }
                }
            }
        }
    }

    render() {
        const rotate = this.state.rotate;
        let objectHeight = 0;
        let top = 0;
        let width = 0;
        if (this.Rocket && this.Rocket.current && this.props.basket && this.props.basket.current) {
            objectHeight = this.Rocket.current.scrollHeight;
            width = this.Rocket.current.clientWidth / 2;
            const { innerHeight } = window;
            top = innerHeight - this.props.basket.current.getBoundingClientRect().top - parseFloat(this.props.OffsetTop) + parseFloat(this.props.OffsetTopObject);
        }


        let style = {
            bottom: `${this.props.isGo ? top - objectHeight : this.props.basket.current ? top : 0}px`,
        };
        if (this.props.direction === "bottom") {
            style = {
                top: `${(this.props.basket.current ?
                    this.props.isGo ?
                        this.props.basket.current.scrollHeight + parseFloat(this.props.OffsetTop)
                        //    this.state.shootTop - (2 * this.props.OffsetTop * Math.sin(Math.abs(rotate) / 2))
                        :
                        this.props.basket.current.scrollHeight + parseFloat(this.props.OffsetTop) : 0)}px`,
            };
        }

        let img = this.props.ShootObjectSrc;
        if (this.state.factor === -1) {
            img = this.props.ShootObjectSrcHover;
        }

        if (this.props.designerContainer && this.props.designerContainer.current) {
            return <div
                className={`position-absolute objectMovment `}
                id={`objectMovment${this.state.objectMovmentId}`}
                style={{
                    left: `calc(50% - ${width}px)`,
                    zIndex: 15,
                    height: `${this.state.height}%`,
                    transformOrigin: `center ${this.props.direction === "bottom" ? "top" : "bottom"} `,
                    // transformOrigin: `center ${this.props.direction === "bottom" ? this.props.OffsetTop + "px" : "-" + this.props.OffsetTop + "px"} `,
                    transform: this.props.isGo ? `rotate(${rotate}deg)` : "",
                    ...style
                }} >
                <div className={`position-relative d-flex align-items-center flex-column  `} style={{
                    height: `100%`,
                    // margin: `${this.props.isGo ? 0 : this.props.OffsetTopObject}px 0px`,
                    // top: this.props.isGo ? this.props.OffsetTop + "px" : "-" + this.props.OffsetTop + "px"
                }}>
                    {
                        this.props.direction === "bottom" ?
                            <>
                                <div style={{
                                    width: "2px",
                                    background: `${this.props.RopeColor}`,
                                    height: `100%`,
                                }}></div>
                                <div ref={this.Rocket} style={{
                                    transform: this.props.isGo ? "" : ` rotate(${rotate}deg)`,
                                    transformOrigin: `${this.props.direction === "top" ? "bottom" : "top"}  `,
                                }}>
                                    <img src={img} alt="" style={{
                                        transform: this.props.FlipY === "true" ? "scaleY(-1)" : ""
                                    }} />
                                </div>
                            </>
                            :
                            <>
                                <div ref={this.Rocket} style={{
                                    transform: this.props.isGo ? "" : ` rotate(${rotate}deg)`,
                                    transformOrigin: `${this.props.direction === "top" ? "bottom" : "top"}  `,
                                }}>
                                    <img src={img} alt="" style={{
                                        transform: this.props.FlipY === "true" ? "scaleY(-1)" : ""
                                    }} />
                                </div>
                                <div style={{
                                    width: "3px",
                                    background: `${this.props.RopeColor}`,
                                    height: `100%`,
                                }}></div>
                            </>
                    }

                </div>
            </div>;
        }
        return "";
    }
}

export default ShootObject;
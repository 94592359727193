import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import { userInitial } from '../../actions/';
import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import { fetchIndices } from '../../Admin/Indices/actions/';
import LessonList from './LessonList';
import ClassRoom from "../../Admin/Question/View/Background/ClassRoom";

class Exams extends React.Component {

    constructor(props) {
        super(props);
        this.props.fetchIndices();
        window.scrollToPosition("main-exams-section");
    }

    render() {
        const { t, user, indices } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('PageHeader.Exams')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="position-relative" id="main-exams-section" style={{
                    minHeight: '100vh'
                }}>
                    <ClassRoom />
                    <div className="container padding-top padding-bottom">
                        <div className="row ">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="section-header mb-4">
                                        <div className="display-4 h3 custome-title" ><i className="mx-3 fal fa-pencil-alt "></i> {t('PageHeader.Exams')} </div>
                                    </div>
                                </div>
                                <LessonList
                                    key="Exams-List"
                                    indices={indices}
                                    user={this.props.user}
                                    type="Exams"
                                ></LessonList>
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices,
        user: state.user
    };
};

export default connect(mapStateToProps, { userInitial, fetchIndices })(withTranslation('common')(Exams));

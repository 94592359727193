import api from '../../../../api/api';

export const fetchTemplates = () => async dispatch => {
    dispatch({ type: 'GAME_TEMPLATES_LOADING' });
    const response = await api.get(`/GameCustomStyle/`);
    dispatch({ type: 'GAME_TEMPLATES_INITIAL', gameTemplates: response.data.data });
}

export const removeTemplate = (id) => async dispatch => {
    dispatch({ type: 'GAME_TEMPLATE_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteTemplate = (id) => async dispatch => {
    dispatch({ type: 'GAME_TEMPLATE_UNDO', id });
}

export const clearTemplate = (id) => async dispatch => {
    dispatch({ type: 'GAME_TEMPLATE_CLEAN_NODES', id });
}
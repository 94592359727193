const EmailContentReducers = (state = [], action) => {
    switch (action.type) {
        case "EMAIL_CONTENT_LOADING":
            return {
                emails: state.emails,
                isLoaded: false
            };
        case "EMAIL_CONTENT_INITIAL":
            return {
                emails: action.emails,
                isLoaded: true
            };
        default:
            return state;
    }
}

export default EmailContentReducers;
import React from "react";
import GameObjects from "../../GameObjects";

class TopOfMountain extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {
        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/RecognizedBrowser/bg.png"})`,
            backgroundPositionX: 'right',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud2" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(55% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(20% - 169px)',
                top: '50%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '20%',
                top: '30%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud1" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: 'calc(70% - 169px)',
                top: '0%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s normal infinite`
            }}><GameObjects objectCss="Cloud2" /></div>
            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: 'calc(50% - 169px)',
                top: '70%',
                animation: `dropObjectMovment linear forwards 4s normal infinite`
            }}><GameObjects objectCss="Cloud3" /></div>

            <div className="position-absolute user-select-none MoveEgale d-none d-sm-block" ><GameObjects objectCss="Egale" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="position-absolute user-select-none" style={{
                right: '10%',
                bottom: '15%'
            }}><GameObjects objectCss="BoyWithtTeasure" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="position-absolute user-select-none" style={{
                right: '5%',
                bottom: '15%'
            }}><GameObjects objectCss="GirlThinking" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default TopOfMountain;
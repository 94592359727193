import api from '../../../../api/api';

export const fetchTemplates = id => async dispatch => {
    dispatch({ type: 'TEMPLATES_LOADING' });
    const response = await api.get(`/QuestionStyles/${id}`);
    dispatch({ type: 'TEMPLATES_INITIAL', templates: response.data.data });
}

export const removeTemplate = (id) => async dispatch => {
    dispatch({ type: 'TEMPLATE_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteTemplate = (id) => async dispatch => {
    dispatch({ type: 'TEMPLATE_UNDO', id });
}

export const clearTemplate = (id) => async dispatch => {
    dispatch({ type: 'TEMPLATE_CLEAN_NODES', id });
}
import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class CartoonBox extends React.Component {

    constructor(props) {
        super(props);
        this.random = Math.ceil(Math.random() * 500);
    }

    render() {
        let color = "#FFB733";
        let backgroundColor = {};
        let style = "";
        if (this.props.customColor) {
            color = this.props.customColor;
            style = `.CartoonBox_side_${this.random}:before, .CartoonBox_side_${this.random}:after{background-color:${color}; border-color:${color};}`;
            backgroundColor = {
                backgroundColor: color
            };
        }
        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: style }}></style>

                <div className="CartoonBox_container" style={{ width: '240px' }}>
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`} style={{ height: '40px' }}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className="CartoonBox_waper">
                                <div className="CartoonBox_noodles">
                                    <div className="CartoonBox_cap CartoonBox_right"></div>
                                    <div className="CartoonBox_cap CartoonBox_back"></div>
                                    <div className={`CartoonBox_side CartoonBox_side_${this.random} CartoonBox_right`}></div>
                                    <div className={`CartoonBox_side CartoonBox_side_${this.random} CartoonBox_back`}></div>
                                    <div className={`CartoonBox_side CartoonBox_side_${this.random} CartoonBox_left`} style={backgroundColor}></div>
                                    <div className={`CartoonBox_side CartoonBox_side_${this.random} CartoonBox_front`} style={backgroundColor}>
                                        <div className="CartoonBox_eyes"></div>
                                        <div className="CartoonBox_mouth"></div>
                                    </div>
                                    <div className="CartoonBox_cap CartoonBox_left"></div>
                                    <div className="CartoonBox_cap CartoonBox_front"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TextBox {...this.props} />
                </div>
            </>
        );
    }
}

export default CartoonBox;

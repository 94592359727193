import React from "react";
import { withTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import LessonItem from "../LessonItem";
import ListItem from "../../../Admin/Files/ListItem";
import api, { apiBlob } from "../../../../api/api";

class MaterialItem extends React.Component {

    state = { data: null };

    componentDidMount() {
        const { item } = this.props;
        api.post(`LessonInfo/${item.id}?isFile=${item.type === "File" ? true : false}`).then(response => {
            if (item.type === "File") {
                this.setState({
                    data: response.data.data[0]
                });
            } else {
                this.setState({
                    data: response.data.data
                });
            }

        });
    }

    View = item => {
        if (item.type === "Exams" || item.type === "Quizzes") {
            window.openExamView(item.id);
        } else if (item.type === "Games" || item.type === "Fun") {
            window.openGameView(item.id);
        } else if (item.type === "SelfDevelopment") {
            window.openSelfDevelopmentView(item.id);
        } else {
            window.openLessonView(item.id);
        }
    }

    Download = item => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        apiBlob.post(`/EducationalFileById/${item.id}`).then(response => {
            if (response) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";
                const blob = new Blob([response.data]),
                    url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = item.title + '.' + item.extension;
                a.click();
                window.URL.revokeObjectURL(url);
            }
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.dismiss(_toastId);
        });
    }

    renderDefaultItem = () => {
        const { t, item } = this.props;
        return <div className={`col`}  >
            <div className="product-list-item" >
                <div className="product-thumb"  >
                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.title}`} />
                    <div className="product-action-link">
                        {
                            item.type === "File" ?
                                <div className="my-1 mr-1" onClick={() => this.Download(item)} title={t("Pages.Lessons.Questions.forms.tools.download")}>
                                    <i className="far fa-download"></i>
                                </div>
                                :
                                <div className="my-1 mr-1" onClick={() => this.View(item)} title={t("Pages.Lessons.Questions.forms.tools.view")}>
                                    <i className="far fa-eye"></i>
                                </div>
                        }
                    </div>
                </div>
                <div className="product-content">
                    <h5 className="title mb-0">
                        {
                            item.type === "File" ?
                                <Link to="#" className="text-truncate py-2 w-100 mb-0" title={item.title} onClick={() => this.Download(item)}>
                                    {item.title}
                                </Link>
                                :
                                <Link to="#" className="text-truncate py-2 w-100 mb-0" title={item.title} onClick={() => this.View(item)}>
                                    {item.title}
                                </Link>
                        }
                    </h5>
                </div>
            </div>
        </div>
    }

    render() {
        const { item, user, indices } = this.props;
        return <>
            {
                this.state.data ?
                    item.type === "File" ?
                        <>
                            <ListItem
                                user={user}
                                IsAdmin={false}
                                indices={indices ? indices.nodes : null}
                                key={this.state.data.id}
                                item={this.state.data}
                            />
                        </>
                        :
                        <>
                            <LessonItem
                                user={user}
                                IsAdmin={false}
                                indices={indices ? indices.nodes : null}
                                type={item.type}
                                key={this.state.data.id}
                                lesson={this.state.data}
                            />
                        </>
                    :
                    this.renderDefaultItem()
            }
        </>;
    }
}
export default withTranslation('common')(MaterialItem);
import React from "react";
import BounsTry from './BounsTry';
import BounsTime from './BounsTime';
import StageName from './StageName';

const PopUps = (props) => {

    const { bounsTry, bounsTime, stageCount, removeState } = props;
    return <>
        {
            removeState && stageCount >= 1 ? <StageName  {...props} /> : ""
        }
        {
            bounsTime >= 1 ? <BounsTime count={bounsTime}  {...props} /> : ""
        }
        {
            bounsTry >= 1 ? <BounsTry count={bounsTry} {...props} /> : ""
        }
    </>;
}
export default PopUps;
import React from "react";
import { withTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { toast } from 'react-toastify';

class InfoForm extends React.Component {

    state = {
        IsActive: true,
        errorList: [],
        initialize: false
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        window.createRedactor(`#Descrpition`);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.item && Object.keys(props.item).length !== 0) {

            state.IsActive = props.item.IsActive;
            setTimeout(() => {
                props.initialize({
                    Title: props.item.Title,
                    Photo: props.item.Photo,
                    Descrpition: props.item.Descrpition,
                });
                window.setRedactor("#Descrpition", props.item.Descrpition);
            }, 700);

            state.initialize = true;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </div>
    );

    validate = () => {
        const errors = {};
        if (!this.Title.ref.current.getValue()) {
            errors.Title = 'Pages.Sliders.form.Title.error';
        }
        if (!this.Photo.ref.current.getValue()) {
            errors.Photo = 'Pages.Sliders.form.Photo.error';
        }
        const Descrpition = window.getRedactor(`#Descrpition`);
        if (!Descrpition) {
            errors.Descrpition = 'Pages.Sliders.form.Descrpition.error';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const Descrpition = window.getRedactor(`#Descrpition`);

            let postData = {
                Title: this.Title.ref.current.getValue(),
                Photo: this.Photo.ref.current.getValue(),
                IsActive: this.state.IsActive,
                Descrpition
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);
        }
        e.preventDefault();
    }

    setIsActive = () => {
        this.setState({
            IsActive: !this.state.IsActive
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Title"> <i className="icon fas fa-heading"></i> {t('Pages.Sliders.form.Title.title')}</label>
                        <Field
                            ref={Title => this.Title = Title}
                            id="Title"
                            name="Title"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t('Pages.Sliders.form.Title.placeholder')}
                            title={t('Pages.Sliders.form.Title.title')}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Photo"> <i className="icon fas fa-image"></i> {t('Pages.Sliders.form.Photo.title')}</label>
                        <Field
                            ref={Photo => this.Photo = Photo}
                            id="Photo"
                            name="Photo"
                            component={this.renderField}
                            type="url"
                            placeholder={t('Pages.Sliders.form.Photo.placeholder')}
                            title={t('Pages.Sliders.form.Photo.title')}
                            className="form-control"
                        />
                    </div>
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group w-100">
                        <label className="text-dark font-size-h5" htmlFor="Descrpition"> <i className="icon fas fa-text"></i> {t('Pages.Sliders.form.Descrpition.title')}</label>
                        <textarea
                            ref={Descrpition => this.Descrpition = Descrpition}
                            id={`Descrpition`}
                            name="Descrpition"
                            placeholder={t('Pages.Sliders.form.Descrpition.placeholder')}
                            title={t('Pages.Sliders.form.Descrpition.title')}
                            autoComplete="off"
                            className="form-control" ></textarea>
                    </div>
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="IsActive">
                            <i className="icon fas fa-lock-alt"></i>
                            {t(`Pages.Sliders.IsActive.title`)}
                        </label>
                        <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-success">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={e => this.setIsActive()}
                                ref={IsActive => this.IsActive = IsActive}
                                defaultChecked={this.state.IsActive}
                                name="IsActive"
                                id="IsActive" />
                            <label className="custom-control-label" htmlFor="IsActive" >
                                {t(`Pages.Sliders.IsActive.placeholder`)}
                            </label>
                        </div>
                    </div>
                    <div className="form-group w-100 text-center">
                        <button className="custom-button" type="submit" ><span> {t('Save')}</span></button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.Title) {
        errors.Title = 'Pages.Sliders.form.Title.error';
    }
    if (!values.Photo) {
        errors.Photo = 'Pages.Sliders.form.Photo.error';
    }
    if (!values.Descrpition) {
        errors.Descrpition = 'Pages.Sliders.form.Descrpition.error';
    }
    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'SliderForm',
    validate
})(InfoForm));

import React from "react";

import SpaceShip from "./SpacesShip/SpaceShip";
import AlliedSpaceShip from "./SpacesShip/AlliedSpaceShip";
import ColoredSpaceShip from "./SpacesShip/ColoredSpaceShip";
import AlienBlue from "./SpacesShip/AlienBlue";
import AlienPink from "./SpacesShip/AlienPink";
import AlienGreen from "./SpacesShip/AlienGreen";
import AlienGray from "./SpacesShip/AlienGray";
import AlienOringe from "./SpacesShip/AlienOringe";
import AlienSqureBlue from "./SpacesShip/AlienSqureBlue";
import ShootDownVerticalSpaceShip from "./SpacesShip/ShootDownVerticalSpaceShip";

import Missile from "./vehicle/Missile";
import Rocket from "./vehicle/Rocket";
import RedCar from "./vehicle/RedCar";

import F16 from "./Airplane/F16";
import Airplane from "./Airplane/Airplane";
import HotBalloon from "./Airplane/HotBalloon";
import Helicopter from "./Airplane/Helicopter";
import RescuePlane from "./Airplane/RescuePlane";
import RescuePlane2 from "./Airplane/RescuePlane2";
import HotBalloonAirShip from "./Airplane/HotBalloonAirShip";
import DoubleHelicopter from "./Airplane/DoubleHelicopter";
import Drones from "./Airplane/Drones";
import F16Min from "./Airplane/F16Min";
import HotBalloonAirShipMin from "./Airplane/HotBalloonAirShipMin";
import AirplaneMin from "./Airplane/AirplaneMin";

import Tank from "./vehicle/Tank";
import Ship from "./vehicle/Ship";
import Submarine from "./vehicle/Submarine/";
import TankRocket from "./vehicle/TankRocket";
import SubmarineFish from "./vehicle/SubmarineFish";
import RocketLauncher from "./vehicle/RocketLauncher";
import FishingTrawler from "./vehicle/FishingTrawler";
import SubmarineWithGun from "./vehicle/SubmarineWithGun";

import FlyingBee from "./animal/flying/FlyingBee";
import Bat from "./animal/flying/Bat";
import Pika from "./animal/flying/Pika";
import Fly from "./animal/flying/Fly";
import Butterfly from "./animal/flying/Butterfly";
import Bee from "./animal/flying/Bee";

import BlueBird from "./animal/Birds/BlueBird";
import RedBird from "./animal/Birds/RedBird";
import Egale from "./animal/Birds/Egale";
import Owls from "./animal/Birds/Owls";
import Duck from "./animal/Birds/Duck";

import BlueParrot from "./animal/Birds/BlueParrot";
import RedParrot from "./animal/Birds/RedParrot";
import GreenParrot from "./animal/Birds/GreenParrot";
import YallowParrot from "./animal/Birds/YallowParrot";
import ReoParrot from "./animal/Birds/ReoParrot";

import Monky from "./animal/other/Monky";
import RabbitWithGun from "./animal/other/Rabbit";
import Frog from "./animal/other/Frog";
import Dragon from "./animal/other/Dragon";

import RunningLion from "./animal/RunningAnimal/RunningLion";
import RunningRhino from "./animal/RunningAnimal/RunningRhino";
import RunningMonkey from "./animal/RunningAnimal/RunningMonkey";
import RunningTiger from "./animal/RunningAnimal/RunningTiger";
import RunningBear from "./animal/RunningAnimal/RunningBear";
import RunningLeopard from "./animal/RunningAnimal/RunningLeopard";

import Shell from "./animal/sea/Shell";
import JellyFish from "./animal/sea/JellyFish";
import Fish from "./animal/sea/Fish";
import PinkWhale from "./animal/sea/PinkWhale";
import DiveFish from "./animal/sea/DiveFish";
import Crab from "./animal/sea/Crab";

import OrangeFish from "./animal/sea/OrangeFish";
import BlueFish from "./animal/sea/BlueFish";
import PurpleFish from "./animal/sea/PurpleFish";
import GreenFish from "./animal/sea/GreenFish";
import RedFish from "./animal/sea/RedFish";
import YellowFish from "./animal/sea/YellowFish";
import BlueFish2 from "./animal/sea/BlueFish2";
import OrangeFish2 from "./animal/sea/OrangeFish2";
import PurpleFish2 from "./animal/sea/PurpleFish2";

import Kid from "./Character/Kid";
import Boy from "./Character/Boy";
import KidWithArch from "./Character/KidWithArch";
import BoyWithGun from "./Character/BoyWithGun";
import Boy2 from "./Character/Boy2";
import BoyWithAirTrafficController from "./Character/BoyWithAirTrafficController";
import BoyLookingUp from "./Character/BoyLookingUp";
import BoyWaving from "./Character/BoyWaving";
import DropBoy from "./Character/DropBoy";
import BoyWithComputer from "./Character/BoyWithComputer";
import BoysTugOfWar from "./Character/BoysTugOfWar";
import BoysPlayWithSand from "./Character/BoysPlayWithSand";
import BoysInBoat from "./Character/BoysInBoat";
import BoyWithController from "./Character/BoyWithController";
import BoyWithMagnifier from "./Character/BoyWithMagnifier";
import BoyOnPincel from "./Character/BoyOnPincel";
import BoyReadBook from "./Character/BoyReadBook";
import BoyPaint from "./Character/BoyPaint";
import KidDiver from "./Character/KidDiver";
import BoyInBox from "./Character/BoyInBox";
import BoysTakeSalfe from "./Character/BoysTakeSalfe";
import BoySitAndReadBook from "./Character/BoySitAndReadBook";
import BoySitAndReadBook1 from "./Character/BoySitAndReadBook1";
import BoyUseComputer from "./Character/BoyUseComputer";
import BoyJump from "./Character/BoyJump";
import BoyWithTrophy from "./Character/BoyWithTrophy";
import BoyOnPincelDefault from "./Character/BoyOnPincelDefault";
import BoyWithtTeasure from "./Character/BoyWithtTeasure";
import BoyOnWindowRead from "./Character/BoyOnWindowRead";
import BoyOnWindowWithTablet from "./Character/BoyOnWindowWithTablet";
import BoyOnWindowPointTo from "./Character/BoyOnWindowPointTo";
import BoyOnBook from "./Character/BoyOnBook";
import BoyWithBook from "./Character/BoyWithBook";
import BoyStandingWithHisHandOnHisWaist from "./Character/BoyStandingWithHisHandOnHisWaist";
import BoyFlyingWithBalloon from "./Character/BoyFlyingWithBalloon";
import BoyDiving from "./Character/BoyDiving";
import FootBallTeam from "./Character/FootBallTeam";
import BoyClimb from "./Character/BoyClimb";
import BoyTakeSelfie from "./Character/BoyTakeSelfie";
import BoyWithBooks from "./Character/BoyWithBooks";
import BoyWithCertificate from "./Character/BoyWithCertificate";
import Hunter from "./Character/Hunter";
import Witch from "./Character/Witch";
import BoyInSpace from "./Character/BoyInSpace";
import BoyRunning from "./Character/BoyRunning";
import BoyWavingInSnow from "./Character/BoyWavingInSnow";
import BoySkatesOnSnow from "./Character/BoySkatesOnSnow";
import BoyWavingInSpace from "./Character/BoyWavingInSpace";
import BoyWavingInDesert from "./Character/BoyWavingInDesert";
import BoyRunningInJungle from "./Character/BoyRunningInJungle";

import Girl from "./Character/Girl";
import GirlWaving from "./Character/GirlWaving";
import DropGirl from "./Character/DropGirl";
import GirlInCamp from "./Character/GirlInCamp";
import GirlOnPincel from "./Character/GirlOnPincel";
import GirlPaint from "./Character/GirlPaint";
import GirlReadBook from "./Character/GirlReadBook";
import GirlUseComputer from "./Character/GirlUseComputer";
import GirlTeacher from "./Character/GirlTeacher";
import GirlPointingTo from "./Character/GirlPointingTo";
import GirlHoldsBooks from "./Character/GirlHoldsBooks";
import TowGirlsStandTogather from "./Character/TowGirlsStandTogather";
import GirlHappyWave from "./Character/GirlHappyWave";
import GirlHappy from "./Character/GirlHappy";
import GirlThinking from "./Character/GirlThinking";
import GirlOnPincelDefault from "./Character/GirlOnPincelDefault";
import GirlCheers from "./Character/GirlCheers";
import GirlOnWindowLooking from "./Character/GirlOnWindowLooking";
import GirlOnWindowSing from "./Character/GirlOnWindowSing";
import GirlOnWindowDrink from "./Character/GirlOnWindowDrink";
import GirlOnBook from "./Character/GirlOnBook";
import GirlWithBook from "./Character/GirlWithBook";
import GirlOnGrass from "./Character/GirlOnGrass";
import GirlTakeSelfie from "./Character/GirlTakeSelfie";
import GirlWithCertificate from "./Character/GirlWithCertificate";
import GirlClimb from "./Character/GirlClimb";
import GirlWaveWithBook from "./Character/GirlWaveWithBook";
import GirlRunning from "./Character/GirlRunning";
import GirlInSpace from "./Character/GirlInSpace";
import GirlWavingInSnow from "./Character/GirlWavingInSnow";
import GirlSkatesOnSnow from "./Character/GirlSkatesOnSnow";
import GirlWavingInSpace from "./Character/GirlWavingInSpace";
import GirlWavingInDesert from "./Character/GirlWavingInDesert";
import GirlRunningInJungle from "./Character/GirlRunningInJungle";

import ChildInLabs from "./Character/ChildInLabs";
import KidsOnCamel from "./Character/KidsOnCamel";

import Apple from "./Fruit/Apple";
import Orange from "./Fruit/Orange";
import Banana from "./Fruit/Banana";
import Lemon from "./Fruit/Lemon";
import Pear from "./Fruit/Pear";
import Strawberry from "./Fruit/Strawberry";
import Blueberry from "./Fruit/Blueberry";
import Peach from "./Fruit/Peach";
import Cherry from "./Fruit/Cherry";
import Watermelon from "./Fruit/Watermelon";
import Avocado from "./Fruit/Avocado";
import FruitOrange from "./Fruit/FruitOrange";
import FruitLemon from "./Fruit/FruitLemon";
import Berry from "./Fruit/Berry";
import BananaPic from "./Fruit/Banana_Pic";

import CartoonBox from "./Other/CartoonBox";
import Planet from "./Other/Planet";
import Ghost from "./Other/Ghost";
import Basket from "./Other/Basket";
import Basket2 from "./Other/Basket2";
import Shoot from "./Other/Shoot";
import BeeHouse from "./Other/BeeHouse";
import Balloon from "./Other/Balloon";
import Flower from "./Other/Flower";
import Bucket from "./Other/Bucket";
import Gun from "./Other/Gun";
import Thunder from "./Other/Thunder";
import GhostHouse from "./Other/GhostHouse";
import Bag from "./Other/Bag";
import DionaeaMuscipula from "./Other/DionaeaMuscipula";
import Barrel from "./Other/Barrel";
import ChickenHouse from "./Other/ChickenHouse";
import FireStone from "./Other/FireStone";
import ZooHouse from "./Other/ZooHouse";
import Bubble from "./Other/Bubble";
import Nest from "./Other/Nest";
import EmptyNest from "./Other/EmptyNest";
import NestWithEgg from "./Other/NestWithEgg";
import HoneyBarrel from "./Other/HoneyBarrel";
import Rock from "./Other/Rock";
import Mirror from "./Other/Mirror";
import Pillow from "./Other/Pillow";
import RedPhone from "./Other/RedPhone";
import WallClock from "./Other/WallClock";
import Window from "./Other/Window";
import Sun from "./Other/Sun";
import FierShoot from "./Other/FierShoot";
import Magic from "./Other/Magic";
import CompetitionLogo from "./Other/CompetitionLogo";

import Soldier1 from "./Soldiers/Soldier1";
import SoldierPurple from "./Soldiers/SoldierPurple";
import SoldierWithGun from "./Soldiers/SoldierWithGun";

import BoyRunningAway from "./RunningAway/BoyRunningAway";
import BoyRunningAway2 from "./RunningAway/BoyRunningAway2";
import GirlRunningAway from "./RunningAway/GirlRunningAway";
import GirlRunningAway2 from "./RunningAway/GirlRunningAway2";

import Explosion from "./ExplosionType/Explosion";
import RocketExplosion from "./ExplosionType/RocketExplosion";
import ThunderExplosion from "./ExplosionType/ThunderExplosion";
import BoomExplosion from "./ExplosionType/BoomExplosion";
import Smoke from "./ExplosionType/Smoke";
import GazExplosion from "./ExplosionType/GazExplosion";
import WaterCircles from "./ExplosionType/WaterCircles";

import SwimmerBoy from "./Swimmer/SwimmerBoy";
import SwimmerGirl from "./Swimmer/SwimmerGirl";

import Cloud1 from "./Clouds/Cloud1";
import Cloud2 from "./Clouds/Cloud2";
import Cloud3 from "./Clouds/Cloud3";
import Cloud4 from "./Clouds/Cloud4";
import Cloud5 from "./Clouds/Cloud5";
import Cloud6 from "./Clouds/Cloud6";
import Cloud7 from "./Clouds/Cloud7";
import Cloud8 from "./Clouds/Cloud8";
import Cloud9 from "./Clouds/Cloud9";

import ColorPaperPlane1 from "./ColorPaperPlanes/ColorPaperPlane1";
import ColorPaperPlane2 from "./ColorPaperPlanes/ColorPaperPlane2";
import ColorPaperPlane3 from "./ColorPaperPlanes/ColorPaperPlane3";
import ColorPaperPlane4 from "./ColorPaperPlanes/ColorPaperPlane4";
import ColorPaperPlane5 from "./ColorPaperPlanes/ColorPaperPlane5";
import ColorPaperPlane6 from "./ColorPaperPlanes/ColorPaperPlane6";
import ColorPaperPlane7 from "./ColorPaperPlanes/ColorPaperPlane7";
import ColorPaperPlane8 from "./ColorPaperPlanes/ColorPaperPlane8";
import ColorPaperPlane9 from "./ColorPaperPlanes/ColorPaperPlane9";
import ColorPaperPlane10 from "./ColorPaperPlanes/ColorPaperPlane10";
import ColorPaperPlane11 from "./ColorPaperPlanes/ColorPaperPlane11";

import WarriorWithAxe from "./Worriers/WarriorWithAxe";
import WarriorWithSpear from "./Worriers/WarriorWithSpear";
import WarriorWithSword from "./Worriers/WarriorWithSword";
import WarriorWithHammer from "./Worriers/WarriorWithHammer";
import WarriorWithArrow from "./Worriers/WarriorWithArrow";
import WarriorWithIronBall from "./Worriers/WarriorWithIronBall";

import PaperPlane from "./Papers/PaperPlane";
import ColorPaperFan from "./Papers/ColorPaperFan";
import ColorPaperFanMin from "./Papers/ColorPaperFanMin";

import Level0 from "./Cards/Boy/Level0";
import Level1 from "./Cards/Boy/Level1";
import Level2 from "./Cards/Boy/Level2";
import Level3 from "./Cards/Boy/Level3";
import Level4 from "./Cards/Boy/Level4";
import Level5 from "./Cards/Boy/Level5";
import Level6 from "./Cards/Boy/Level6";

import GirlLevel0 from "./Cards/Girl/Level0";
import GirlLevel1 from "./Cards/Girl/Level1";
import GirlLevel2 from "./Cards/Girl/Level2";
import GirlLevel3 from "./Cards/Girl/Level3";
import GirlLevel4 from "./Cards/Girl/Level4";
import GirlLevel5 from "./Cards/Girl/Level5";
import GirlLevel6 from "./Cards/Girl/Level6";

import RedBox from "./Boxs/RedBox";
import PinkBox from "./Boxs/PinkBox";
import WhiteBox from "./Boxs/WhiteBox";
import BlackBox from "./Boxs/BlackBox";
import BrownBox from "./Boxs/BrownBox";
import GreenBox from "./Boxs/GreenBox";
import PurpleBox from "./Boxs/PurpleBox";
import YallowBox from "./Boxs/YallowBox";
import LiteGreenBox from "./Boxs/LiteGreenBox";
import LiteBrownBox from "./Boxs/LiteBrownBox";
import LitePurpleBox from "./Boxs/LitePurpleBox";
import WoodenColorBox from "./Boxs/WoodenColorBox";

import RedGelatinous from "./Gelatinous/RedGelatinous";
import BlueGelatinous from "./Gelatinous/BlueGelatinous";
import GreenGelatinous from "./Gelatinous/GreenGelatinous";
import PurpleGelatinous from "./Gelatinous/PurpleGelatinous";
import OrangeGelatinous from "./Gelatinous/OrangeGelatinous";
import ColoredGelatinous from "./Gelatinous/ColoredGelatinous";
import OrangeGradientGelatinous from "./Gelatinous/OrangeGradientGelatinous";

import Worm from "./Insects/Worm";
import BeeImg from "./Insects/BeeImg";
import Spider from "./Insects/Spider";
import Ladybug from "./Insects/Ladybug";
import Dragonfly from "./Insects/Dragonfly";
import Grasshopper from "./Insects/Grasshopper";
import ButterflyImg from "./Insects/ButterflyImg";

import CustomImg from "./CustomImg/";
import GirlPullUpGame from "./Character/GirlPullUpGame";
import CatchMachine from "./Other/CatchMachine";
import Shark from "./animal/sea/Shark";
import ReadGroupWithTeacher from "./Character/ReadGroupWithTeacher";
import Dog from "./animal/other/Dog";

class GameObjects extends React.Component {

    render() {
        const { objectCss } = this.props;

        if (objectCss === "FlyingBee") {
            return <FlyingBee {...this.props} />
        } else if (objectCss === "SpaceShip") {
            return <SpaceShip {...this.props} />
        } else if (objectCss === "HotBalloon") {
            return <HotBalloon {...this.props} />
        } else if (objectCss === "Ghost") {
            return <Ghost {...this.props} />
        } else if (objectCss === "Bat") {
            return <Bat {...this.props} />
        } else if (objectCss === "Pika") {
            return <Pika {...this.props} />
        } else if (objectCss === "Submarine") {
            return <Submarine {...this.props} />
        } else if (objectCss === "SubmarineFish") {
            return <SubmarineFish {...this.props} />
        } else if (objectCss === "Helicopter") {
            return <Helicopter {...this.props} />
        } else if (objectCss === "Planet") {
            return <Planet {...this.props} />
        } else if (objectCss === "CartoonBox") {
            return <CartoonBox {...this.props} />
        } else if (objectCss === "Fish") {
            return <Fish {...this.props} />
        } else if (objectCss === "JellyFish") {
            return <JellyFish {...this.props} />
        } else if (objectCss === "Avocado") {
            return <Avocado {...this.props} />
        } else if (objectCss === "Watermelon") {
            return <Watermelon {...this.props} />
        } else if (objectCss === "Berry") {
            return <Berry {...this.props} />
        } else if (objectCss === "Cherry") {
            return <Cherry {...this.props} />
        } else if (objectCss === "Peach") {
            return <Peach {...this.props} />
        } else if (objectCss === "Blueberry") {
            return <Blueberry {...this.props} />
        } else if (objectCss === "Strawberry") {
            return <Strawberry {...this.props} />
        } else if (objectCss === "Pear") {
            return <Pear {...this.props} />
        } else if (objectCss === "FruitLemon") {
            return <FruitLemon {...this.props} />
        } else if (objectCss === "Lemon") {
            return <Lemon {...this.props} />
        } else if (objectCss === "Banana") {
            return <Banana {...this.props} />
        } else if (objectCss === "FruitOrange") {
            return <FruitOrange {...this.props} />
        } else if (objectCss === "Orange") {
            return <Orange {...this.props} />
        } else if (objectCss === "Shell") {
            return <Shell {...this.props} />
        } else if (objectCss === "ShellBlue") {
            return <Shell isBlue={true} {...this.props} />
        } else if (objectCss === "Apple" || objectCss === "RandomFruit") {
            return <Apple {...this.props} />
        } else if (objectCss === "Basket") {
            return <Basket {...this.props} />
        } else if (objectCss === "Basket2") {
            return <Basket2 {...this.props} />
        } else if (objectCss === "Missile") {
            return <Missile {...this.props} />
        } else if (objectCss === "Shoot") {
            return <Shoot {...this.props} />
        } else if (objectCss === "FierShoot") {
            return <FierShoot {...this.props} />
        } else if (objectCss === "Rocket") {
            return <Rocket {...this.props} />
        } else if (objectCss === "AlliedSpaceShip" || objectCss === "RandomdSpaceShip") {
            return <AlliedSpaceShip {...this.props} />
        } else if (objectCss === "AlliedSpaceShip_Blue") {
            return <AlliedSpaceShip color="blue" {...this.props} />
        } else if (objectCss === "AlliedSpaceShip_Yallow") {
            return <AlliedSpaceShip color="yallow" {...this.props} />
        } else if (objectCss === "BeeHouse") {
            return <BeeHouse {...this.props} />
        } else if (objectCss === "Fly") {
            return <Fly {...this.props} />
        } else if (objectCss === "Balloon") {
            return <Balloon {...this.props} />
        } else if (objectCss === "Kid") {
            return <Kid {...this.props} />
        } else if (objectCss === "Butterfly") {
            return <Butterfly {...this.props} />
        } else if (objectCss === "Monky") {
            return <Monky {...this.props} />
        } else if (objectCss === "Banana_Pic") {
            return <BananaPic {...this.props} />
        } else if (objectCss === "Flower") {
            return <Flower {...this.props} />
        } else if (objectCss === "KidWithArch") {
            return <KidWithArch {...this.props} hasEffect={true} />
        } else if (objectCss === "KidWithArchWithoutEffect") {
            return <KidWithArch {...this.props} hasEffect={false} />
        } else if (objectCss === "Girl") {
            return <Girl {...this.props} />
        } else if (objectCss === "Boy") {
            return <Boy {...this.props} />
        } else if (objectCss === "BoyWithGun") {
            return <BoyWithGun {...this.props} />
        } else if (objectCss === "Bucket") {
            return <Bucket {...this.props} />
        } else if (objectCss === "Gun") {
            return <Gun {...this.props} />
        } else if (objectCss === "Thunder") {
            return <Thunder {...this.props} />
        } else if (objectCss === "GhostHouse") {
            return <GhostHouse {...this.props} />
        } else if (objectCss === "Bag") {
            return <Bag {...this.props} />
        } else if (objectCss === "RabbitWithGun") {
            return <RabbitWithGun {...this.props} />
        } else if (objectCss === "SubmarineWithGun") {
            return <SubmarineWithGun {...this.props} />
        } else if (objectCss === "DionaeaMuscipula") {
            return <DionaeaMuscipula {...this.props} />
        } else if (objectCss === "Barrel") {
            return <Barrel {...this.props} />
        } else if (objectCss === "ChickenHouse") {
            return <ChickenHouse {...this.props} />
        } else if (objectCss === "Boy2") {
            return <Boy2 {...this.props} />
        } else if (objectCss === "FireStone") {
            return <FireStone {...this.props} />
        } else if (objectCss === "ZooHouse") {
            return <ZooHouse {...this.props} />
        } else if (objectCss === "Airplane") {
            return <Airplane {...this.props} />
        } else if (objectCss === "AirplaneMin") {
            return <AirplaneMin {...this.props} />
        } else if (objectCss === "BoyWithAirTrafficController") {
            return <BoyWithAirTrafficController {...this.props} />
        } else if (objectCss === "Ship") {
            return <Ship {...this.props} />
        } else if (objectCss === "Bubble") {
            return <Bubble {...this.props} />
        } else if (objectCss === "OrangeFish" || objectCss === "RandomFish") {
            return <OrangeFish {...this.props} />
        } else if (objectCss === "BlueFish") {
            return <BlueFish {...this.props} />
        } else if (objectCss === "PurpleFish") {
            return <PurpleFish {...this.props} />
        } else if (objectCss === "GreenFish") {
            return <GreenFish {...this.props} />
        } else if (objectCss === "RedFish") {
            return <RedFish {...this.props} />
        } else if (objectCss === "YellowFish") {
            return <YellowFish {...this.props} />
        } else if (objectCss === "BlueFish2") {
            return <BlueFish2 {...this.props} />
        } else if (objectCss === "OrangeFish2") {
            return <OrangeFish2 {...this.props} />
        } else if (objectCss === "PurpleFish2") {
            return <PurpleFish2 {...this.props} />
        } else if (objectCss === "Tank") {
            return <Tank {...this.props} />
        } else if (objectCss === "BlueBird") {
            return <BlueBird {...this.props} />
        } else if (objectCss === "ReoParrot") {
            return <ReoParrot {...this.props} />
        } else if (objectCss === "RedBird" || objectCss === "RandomBird") {
            return <RedBird {...this.props} />
        } else if (objectCss === "BoyLookingUp") {
            return <BoyLookingUp {...this.props} />
        } else if (objectCss === "Nest") {
            return <Nest {...this.props} />
        } else if (objectCss === "BlueParrot" || objectCss === "RandomParrot") {
            return <BlueParrot {...this.props} />
        } else if (objectCss === "RedParrot") {
            return <RedParrot {...this.props} />
        } else if (objectCss === "GreenParrot") {
            return <GreenParrot {...this.props} />
        } else if (objectCss === "YallowParrot") {
            return <YallowParrot {...this.props} />
        } else if (objectCss === "Bee") {
            return <Bee {...this.props} />
        } else if (objectCss === "HoneyBarrel") {
            return <HoneyBarrel {...this.props} />
        } else if (objectCss === "RescuePlane") {
            return <RescuePlane {...this.props} />
        } else if (objectCss === "BoyRunningAway" || objectCss === "RandomRunningAway") {
            return <BoyRunningAway {...this.props} />
        } else if (objectCss === "BoyRunningAway2") {
            return <BoyRunningAway2 {...this.props} />
        } else if (objectCss === "BoomExplosion") {
            return <BoomExplosion {...this.props} />
        } else if (objectCss === "Explosion") {
            return <Explosion {...this.props} />
        } else if (objectCss === "RocketExplosion") {
            return <RocketExplosion {...this.props} />
        } else if (objectCss === "ThunderExplosion") {
            return <ThunderExplosion {...this.props} />
        } else if (objectCss === "GirlRunningAway") {
            return <GirlRunningAway {...this.props} />
        } else if (objectCss === "GirlRunningAway2") {
            return <GirlRunningAway2 {...this.props} />
        } else if (objectCss === "SwimmerBoy" || objectCss === "RandomSwimmer") {
            return <SwimmerBoy {...this.props} />
        } else if (objectCss === "SwimmerGirl") {
            return <SwimmerGirl {...this.props} />
        } else if (objectCss === "RescuePlane2") {
            return <RescuePlane2 {...this.props} />
        } else if (objectCss === "RocketLauncher") {
            return <RocketLauncher {...this.props} />
        } else if (objectCss === "F16") {
            return <F16 {...this.props} />
        } else if (objectCss === "F16Min") {
            return <F16Min {...this.props} />
        } else if (objectCss === "TankRocket") {
            return <TankRocket {...this.props} />
        } else if (objectCss === "Smoke") {
            return <Smoke {...this.props} />
        } else if (objectCss === "EmptyNest") {
            return <EmptyNest {...this.props} />
        } else if (objectCss === "NestWithEgg") {
            return <NestWithEgg {...this.props} />
        } else if (objectCss === "HotBalloonAirShip") {
            return <HotBalloonAirShip {...this.props} />
        } else if (objectCss === "HotBalloonAirShipMin") {
            return <HotBalloonAirShipMin {...this.props} />
        } else if (objectCss === "DoubleHelicopter") {
            return <DoubleHelicopter {...this.props} />
        } else if (objectCss === "Egale") {
            return <Egale {...this.props} />
        } else if (objectCss === "Rock") {
            return <Rock {...this.props} />
        } else if (objectCss === "Mirror") {
            return <Mirror {...this.props} />
        } else if (objectCss === "Pillow") {
            return <Pillow {...this.props} />
        } else if (objectCss === "GazExplosion") {
            return <GazExplosion {...this.props} />
        } else if (objectCss === "Owls") {
            return <Owls {...this.props} />
        } else if (objectCss === "Soldier1" || objectCss === "RandomSoldier") {
            return <Soldier1 {...this.props} />
        } else if (objectCss === "SoldierPurple") {
            return <SoldierPurple {...this.props} />
        } else if (objectCss === "SoldierWithGun") {
            return <SoldierWithGun {...this.props} />
        } else if (objectCss === "Frog") {
            return <Frog {...this.props} />
        } else if (objectCss === "BoyWaving") {
            return <BoyWaving {...this.props} />
        } else if (objectCss === "GirlWaving") {
            return <GirlWaving {...this.props} />
        } else if (objectCss === "DropBoy") {
            return <DropBoy {...this.props} />
        } else if (objectCss === "DropGirl") {
            return <DropGirl {...this.props} />
        } else if (objectCss === "BoyWithComputer") {
            return <BoyWithComputer {...this.props} />
        } else if (objectCss === "RandomCloud" || objectCss === "Cloud1") {
            return <Cloud1 {...this.props} />
        } else if (objectCss === "Cloud2") {
            return <Cloud2 {...this.props} />
        } else if (objectCss === "Cloud3") {
            return <Cloud3 {...this.props} />
        } else if (objectCss === "Cloud4") {
            return <Cloud4 {...this.props} />
        } else if (objectCss === "Cloud5") {
            return <Cloud5 {...this.props} />
        } else if (objectCss === "Cloud6") {
            return <Cloud6 {...this.props} />
        } else if (objectCss === "Cloud7") {
            return <Cloud7 {...this.props} />
        } else if (objectCss === "Cloud8") {
            return <Cloud8 {...this.props} />
        } else if (objectCss === "Cloud9") {
            return <Cloud9 {...this.props} />
        } else if (objectCss === "BoysTugOfWar") {
            return <BoysTugOfWar {...this.props} />
        } else if (objectCss === "BoysPlayWithSand") {
            return <BoysPlayWithSand {...this.props} />
        } else if (objectCss === "PinkWhale") {
            return <PinkWhale {...this.props} />
        } else if (objectCss === "BoysInBoat") {
            return <BoysInBoat {...this.props} />
        } else if (objectCss === "DiveFish") {
            return <DiveFish {...this.props} />
        } else if (objectCss === "Crab") {
            return <Crab {...this.props} />
        } else if (objectCss === "WaterCircles") {
            return <WaterCircles {...this.props} />
        } else if (objectCss === "RedPhone") {
            return <RedPhone {...this.props} />
        } else if (objectCss === "BoyWithController") {
            return <BoyWithController {...this.props} />
        } else if (objectCss === "GirlInCamp") {
            return <GirlInCamp {...this.props} />
        } else if (objectCss === "Drones") {
            return <Drones {...this.props} />
        } else if (objectCss === "BoyWithMagnifier") {
            return <BoyWithMagnifier {...this.props} />
        } else if (objectCss === "BoyOnPincel") {
            return <BoyOnPincel {...this.props} />
        } else if (objectCss === "GirlOnPincel") {
            return <GirlOnPincel {...this.props} />
        } else if (objectCss === "BoyReadBook") {
            return <BoyReadBook {...this.props} />
        } else if (objectCss === "BoyPaint") {
            return <BoyPaint {...this.props} />
        } else if (objectCss === "GirlPaint") {
            return <GirlPaint {...this.props} />
        } else if (objectCss === "GirlReadBook") {
            return <GirlReadBook {...this.props} />
        } else if (objectCss === "WallClock") {
            return <WallClock {...this.props} />
        } else if (objectCss === "Window") {
            return <Window {...this.props} />
        } else if (objectCss === "KidDiver") {
            return <KidDiver {...this.props} />
        } else if (objectCss === "BoyInBox") {
            return <BoyInBox {...this.props} />
        } else if (objectCss === "Duck") {
            return <Duck {...this.props} />
        } else if (objectCss === "TowGirlsStandTogather") {
            return <TowGirlsStandTogather {...this.props} />
        } else if (objectCss === "BoysTakeSalfe") {
            return <BoysTakeSalfe  {...this.props} />
        } else if (objectCss === "GirlHoldsBooks") {
            return <GirlHoldsBooks  {...this.props} />
        } else if (objectCss === "GirlPointingTo") {
            return <GirlPointingTo  {...this.props} />
        } else if (objectCss === "BoySitAndReadBook") {
            return <BoySitAndReadBook  {...this.props} />
        } else if (objectCss === "BoySitAndReadBook1") {
            return <BoySitAndReadBook1  {...this.props} />
        } else if (objectCss === "BoyUseComputer") {
            return <BoyUseComputer  {...this.props} />
        } else if (objectCss === "GirlUseComputer") {
            return <GirlUseComputer  {...this.props} />
        } else if (objectCss === "GirlTeacher") {
            return <GirlTeacher  {...this.props} />
        } else if (objectCss === "GirlHappyWave") {
            return <GirlHappyWave  {...this.props} />
        } else if (objectCss === "GirlHappy") {
            return <GirlHappy  {...this.props} />
        } else if (objectCss === "BoyJump") {
            return <BoyJump  {...this.props} />
        } else if (objectCss === "BoyWithTrophy") {
            return <BoyWithTrophy  {...this.props} />
        } else if (objectCss === "BoyOnPincelDefault") {
            return <BoyOnPincelDefault  {...this.props} />
        } else if (objectCss === "GirlOnPincelDefault") {
            return <GirlOnPincelDefault  {...this.props} />
        } else if (objectCss === "BoyWithtTeasure") {
            return <BoyWithtTeasure  {...this.props} />
        } else if (objectCss === "GirlThinking") {
            return <GirlThinking  {...this.props} />
        } else if (objectCss === "BoyWithBook") {
            return <BoyWithBook  {...this.props} />
        } else if (objectCss === "GirlWithBook") {
            return <GirlWithBook  {...this.props} />
        } else if (objectCss === "PaperPlane") {
            return <PaperPlane  {...this.props} />
        } else if (objectCss === "Sun") {
            return <Sun  {...this.props} />
        } else if (objectCss === "BoyStandingWithHisHandOnHisWaist") {
            return <BoyStandingWithHisHandOnHisWaist  {...this.props} />
        } else if (objectCss === "GirlCheers") {
            return <GirlCheers  {...this.props} />
        } else if (objectCss === "GirlOnWindowLooking") {
            return <GirlOnWindowLooking  {...this.props} />
        } else if (objectCss === "GirlOnWindowSing") {
            return <GirlOnWindowSing  {...this.props} />
        } else if (objectCss === "GirlOnWindowDrink") {
            return <GirlOnWindowDrink  {...this.props} />
        } else if (objectCss === "BoyOnWindowRead") {
            return <BoyOnWindowRead  {...this.props} />
        } else if (objectCss === "BoyOnWindowWithTablet") {
            return <BoyOnWindowWithTablet  {...this.props} />
        } else if (objectCss === "BoyOnWindowPointTo") {
            return <BoyOnWindowPointTo  {...this.props} />
        } else if (objectCss === "BoyOnBook") {
            return <BoyOnBook  {...this.props} />
        } else if (objectCss === "GirlOnBook") {
            return <GirlOnBook  {...this.props} />
        } else if (objectCss === "GirlOnGrass") {
            return <GirlOnGrass  {...this.props} />
        } else if (objectCss === "BoyFlyingWithBalloon") {
            return <BoyFlyingWithBalloon  {...this.props} />
        } else if (objectCss === "ColorPaperFan") {
            return <ColorPaperFan  {...this.props} />
        } else if (objectCss === "ColorPaperFanMin") {
            return <ColorPaperFanMin  {...this.props} />
        } else if (objectCss === "WarriorWithSword" || objectCss === "RandomWarrior") {
            return <WarriorWithSword  {...this.props} />
        } else if (objectCss === "WarriorWithAxe") {
            return <WarriorWithAxe  {...this.props} />
        } else if (objectCss === "WarriorWithSpear") {
            return <WarriorWithSpear  {...this.props} />
        } else if (objectCss === "WarriorWithHammer") {
            return <WarriorWithHammer  {...this.props} />
        } else if (objectCss === "WarriorWithArrow") {
            return <WarriorWithArrow  {...this.props} />
        } else if (objectCss === "WarriorWithIronBall") {
            return <WarriorWithIronBall  {...this.props} />
        } else if (objectCss === "ColorPaperPlane1" || objectCss === "RandomColorPaperPlane") {
            return <ColorPaperPlane1  {...this.props} />
        } else if (objectCss === "ColorPaperPlane2") {
            return <ColorPaperPlane2  {...this.props} />
        } else if (objectCss === "ColorPaperPlane3") {
            return <ColorPaperPlane3  {...this.props} />
        } else if (objectCss === "ColorPaperPlane4") {
            return <ColorPaperPlane4  {...this.props} />
        } else if (objectCss === "ColorPaperPlane5") {
            return <ColorPaperPlane5  {...this.props} />
        } else if (objectCss === "ColorPaperPlane6") {
            return <ColorPaperPlane6  {...this.props} />
        } else if (objectCss === "ColorPaperPlane7") {
            return <ColorPaperPlane7  {...this.props} />
        } else if (objectCss === "ColorPaperPlane8") {
            return <ColorPaperPlane8  {...this.props} />
        } else if (objectCss === "ColorPaperPlane9") {
            return <ColorPaperPlane9  {...this.props} />
        } else if (objectCss === "ColorPaperPlane10") {
            return <ColorPaperPlane10  {...this.props} />
        } else if (objectCss === "ColorPaperPlane11") {
            return <ColorPaperPlane11  {...this.props} />
        } else if (objectCss === "Dragon") {
            return <Dragon  {...this.props} />
        } else if (objectCss === "BoyDiving") {
            return <BoyDiving  {...this.props} />
        } else if (objectCss === "FootBallTeam") {
            return <FootBallTeam  {...this.props} />
        } else if (objectCss === "Level0") {
            return <Level0  {...this.props} />
        } else if (objectCss === "Level1") {
            return <Level1  {...this.props} />
        } else if (objectCss === "Level2") {
            return <Level2  {...this.props} />
        } else if (objectCss === "Level3") {
            return <Level3  {...this.props} />
        } else if (objectCss === "Level4") {
            return <Level4  {...this.props} />
        } else if (objectCss === "Level5") {
            return <Level5  {...this.props} />
        } else if (objectCss === "Level6") {
            return <Level6  {...this.props} />
        } else if (objectCss === "GirlLevel0") {
            return <GirlLevel0  {...this.props} />
        } else if (objectCss === "GirlLevel1") {
            return <GirlLevel1  {...this.props} />
        } else if (objectCss === "GirlLevel2") {
            return <GirlLevel2  {...this.props} />
        } else if (objectCss === "GirlLevel3") {
            return <GirlLevel3  {...this.props} />
        } else if (objectCss === "GirlLevel4") {
            return <GirlLevel4  {...this.props} />
        } else if (objectCss === "GirlLevel5") {
            return <GirlLevel5  {...this.props} />
        } else if (objectCss === "GirlLevel6") {
            return <GirlLevel6  {...this.props} />
        } else if (objectCss === "Hunter") {
            return <Hunter  {...this.props} />
        } else if (objectCss === "RunningLion" || objectCss === "RandomRunningAnimal") {
            return <RunningLion  {...this.props} />
        } else if (objectCss === "RunningBear") {
            return <RunningBear  {...this.props} />
        } else if (objectCss === "RunningTiger") {
            return <RunningTiger  {...this.props} />
        } else if (objectCss === "RunningLeopard") {
            return <RunningLeopard  {...this.props} />
        } else if (objectCss === "RunningMonkey") {
            return <RunningMonkey  {...this.props} />
        } else if (objectCss === "RunningRhino") {
            return <RunningRhino  {...this.props} />
        } else if (objectCss === "Witch") {
            return <Witch  {...this.props} />
        } else if (objectCss === "MagicShoot") {
            return <Magic  {...this.props} />
        } else if (objectCss === "CompetitionLogo") {
            return <CompetitionLogo  {...this.props} />
        } else if (objectCss === "BoyClimb") {
            return <BoyClimb  {...this.props} />
        } else if (objectCss === "GirlClimb") {
            return <GirlClimb  {...this.props} />
        } else if (objectCss === "BoyTakeSelfie") {
            return <BoyTakeSelfie  {...this.props} />
        } else if (objectCss === "GirlTakeSelfie") {
            return <GirlTakeSelfie  {...this.props} />
        } else if (objectCss === "BoyWithCertificate") {
            return <BoyWithCertificate  {...this.props} />
        } else if (objectCss === "GirlWithCertificate") {
            return <GirlWithCertificate  {...this.props} />
        } else if (objectCss === "BoyWithBooks") {
            return <BoyWithBooks  {...this.props} />
        } else if (objectCss === "GirlWaveWithBook") {
            return <GirlWaveWithBook  {...this.props} />
        } else if (objectCss === "GreenBox" || objectCss === "RandomBox") {
            return <GreenBox  {...this.props} />
        } else if (objectCss === "RedBox") {
            return <RedBox  {...this.props} />
        } else if (objectCss === "PinkBox") {
            return <PinkBox  {...this.props} />
        } else if (objectCss === "WhiteBox") {
            return <WhiteBox  {...this.props} />
        } else if (objectCss === "BlackBox") {
            return <BlackBox  {...this.props} />
        } else if (objectCss === "YallowBox") {
            return <YallowBox  {...this.props} />
        } else if (objectCss === "BrownBox") {
            return <BrownBox  {...this.props} />
        } else if (objectCss === "PurpleBox") {
            return <PurpleBox  {...this.props} />
        } else if (objectCss === "LiteGreenBox") {
            return <LiteGreenBox  {...this.props} />
        } else if (objectCss === "LiteBrownBox") {
            return <LiteBrownBox  {...this.props} />
        } else if (objectCss === "LitePurpleBox") {
            return <LitePurpleBox  {...this.props} />
        } else if (objectCss === "WoodenColorBox") {
            return <WoodenColorBox  {...this.props} />
        } else if (objectCss === "FishingTrawler") {
            return <FishingTrawler  {...this.props} />
        } else if (objectCss === "ChildInLabs") {
            return <ChildInLabs  {...this.props} />
        } else if (objectCss === "BoyRunning") {
            return <BoyRunning  {...this.props} />
        } else if (objectCss === "GirlRunning") {
            return <GirlRunning  {...this.props} />
        } else if (objectCss === "BoyWavingInSnow") {
            return <BoyWavingInSnow  {...this.props} />
        } else if (objectCss === "GirlWavingInSnow") {
            return <GirlWavingInSnow  {...this.props} />
        } else if (objectCss === "GirlSkatesOnSnow") {
            return <GirlSkatesOnSnow  {...this.props} />
        } else if (objectCss === "BoySkatesOnSnow") {
            return <BoySkatesOnSnow  {...this.props} />
        } else if (objectCss === "BoyWavingInDesert") {
            return <BoyWavingInDesert  {...this.props} />
        } else if (objectCss === "GirlWavingInDesert") {
            return <GirlWavingInDesert  {...this.props} />
        } else if (objectCss === "KidsOnCamel") {
            return <KidsOnCamel  {...this.props} />
        } else if (objectCss === "GirlWavingInSpace") {
            return <GirlWavingInSpace  {...this.props} />
        } else if (objectCss === "BoyWavingInSpace") {
            return <BoyWavingInSpace  {...this.props} />
        } else if (objectCss === "GirlInSpace") {
            return <GirlInSpace  {...this.props} />
        } else if (objectCss === "BoyInSpace") {
            return <BoyInSpace  {...this.props} />
        } else if (objectCss === "BoyRunningInJungle") {
            return <BoyRunningInJungle  {...this.props} />
        } else if (objectCss === "GirlRunningInJungle") {
            return <GirlRunningInJungle  {...this.props} />
        } else if (objectCss === "RedGelatinous" || objectCss === "Gelatinous") {
            return <RedGelatinous  {...this.props} />
        } else if (objectCss === "BlueGelatinous") {
            return <BlueGelatinous  {...this.props} />
        } else if (objectCss === "GreenGelatinous") {
            return <GreenGelatinous  {...this.props} />
        } else if (objectCss === "PurpleGelatinous") {
            return <PurpleGelatinous  {...this.props} />
        } else if (objectCss === "OrangeGelatinous") {
            return <OrangeGelatinous  {...this.props} />
        } else if (objectCss === "ColoredGelatinous") {
            return <ColoredGelatinous  {...this.props} />
        } else if (objectCss === "OrangeGradientGelatinous") {
            return <OrangeGradientGelatinous  {...this.props} />
        } else if (objectCss === "RedCar") {
            return <RedCar  {...this.props} />
        } else if (objectCss === "ColoredSpaceShip") {
            return <ColoredSpaceShip  {...this.props} />
        } else if (objectCss === "AlienBlue" || objectCss === "RandomAlien") {
            return <AlienBlue  {...this.props} />
        } else if (objectCss === "AlienPink") {
            return <AlienPink  {...this.props} />
        } else if (objectCss === "AlienGreen") {
            return <AlienGreen  {...this.props} />
        } else if (objectCss === "AlienGray") {
            return <AlienGray  {...this.props} />
        } else if (objectCss === "AlienOringe") {
            return <AlienOringe  {...this.props} />
        } else if (objectCss === "AlienSqureBlue") {
            return <AlienSqureBlue  {...this.props} />
        } else if (objectCss === "ShootDownVerticalSpaceShip") {
            return <ShootDownVerticalSpaceShip  {...this.props} />
        } else if (objectCss === "Grasshopper" || objectCss === "Insects") {
            return <Grasshopper  {...this.props} />
        } else if (objectCss === "Worm") {
            return <Worm  {...this.props} />
        } else if (objectCss === "Ladybug") {
            return <Ladybug  {...this.props} />
        } else if (objectCss === "Dragonfly") {
            return <Dragonfly  {...this.props} />
        } else if (objectCss === "BeeImg") {
            return <BeeImg  {...this.props} />
        } else if (objectCss === "ButterflyImg") {
            return <ButterflyImg  {...this.props} />
        } else if (objectCss === "Spider") {
            return <Spider  {...this.props} />
        } else if (objectCss === "GirlPullUpGame") {
            return <GirlPullUpGame  {...this.props} />
        } else if (objectCss === "CatchMachine") {
            return <CatchMachine  {...this.props} />
        } else if (objectCss === "Shark") {
            return <Shark  {...this.props} />
        } else if (objectCss === "ReadGroupWithTeacher") {
            return <ReadGroupWithTeacher  {...this.props} />
        } else if (objectCss === "Dog") {
            return <Dog  {...this.props} />
        }
        return <CustomImg {...this.props} />;
    }
}

export default GameObjects;
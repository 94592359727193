import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";
import { Player } from '@lottiefiles/react-lottie-player';

import configData from "../../../../Config/game_sound.json";
import effectData from "../../../../Config/effect.json";

class GameSound extends React.Component {

    state = {
        TrueSound: '',
        CustomTrueSound: '',
        FalseSound: '',
        CustomFalseSound: ''
    };

    constructor(props) {
        super(props);
        this.Background = configData.Background;
        this.Start = configData.Start;
        this.GameOver = configData.GameOver;
        this.True_Sound = configData.True_Sound;
        this.Stage = configData.Stage;
        this.False_Sound = configData.False_Sound;
        this.Win = configData.Win;
        this.Bonus = configData.Bonus;

        this.success = effectData.success;
        this.error = effectData.error;
        this.audio = new Audio();
    }

    static getDerivedStateFromProps(props, state) {
        state.TrueSound = props.TrueSound;
        state.CustomTrueSound = props.CustomTrueSound;
        state.FalseSound = props.FalseSound;
        state.CustomFalseSound = props.CustomFalseSound;
        state.Confetti = props.Confetti;
        state.CustomConfetti = props.CustomConfetti;
        state.ConfettiFalse = props.ConfettiFalse;
        state.CustomConfettiFalse = props.CustomConfettiFalse;

        state.StartSound = props.StartSound;
        state.CustomStartSound = props.CustomStartSound;
        state.StageSound = props.StageSound;
        state.CustomStageSound = props.CustomStageSound;
        state.BonusSound = props.BonusSound;
        state.CustomBonusSound = props.CustomBonusSound;
        state.BonusTimeSound = props.BonusTimeSound;
        state.CustomBonusTimeSound = props.CustomBonusTimeSound;
        state.GameOverSound = props.GameOverSound;
        state.CustomGameOverSound = props.CustomGameOverSound;
        state.WinSound = props.WinSound;
        state.CustomWinSound = props.CustomWinSound;
        state.BackgroundSound = props.BackgroundSound;
        state.CustomBackgroundSound = props.CustomBackgroundSound;

        return state;
    }

    renderStageSound() {
        return this.Stage.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }

    renderConfettiFalse() {
        return this.error.map(item => {
            return <option key={item.url} value={item.url}> {item.name}</option >;
        });
    }
    renderConfetti() {
        return this.success.map(item => {
            return <option key={item.url} value={item.url}> {item.name}</option >;
        });
    }

    renderTrueSound() {
        return this.True_Sound.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderFalseSound() {
        return this.False_Sound.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderBackgroundSound() {
        return this.Background.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderStartSound() {
        return this.Start.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderGameOverSound() {
        return this.GameOver.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderWinSound() {
        return this.Win.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }
    renderBonusSound() {
        return this.Bonus.map(sound => {
            return <option key={sound.url} value={sound.url}> {sound.name}</option >;
        });
    }

    playBonusSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.BonusSound !== 'custom' && this.state.BonusSound !== 'random' && this.state.BonusSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.BonusSound);
                this.audio.play();
            } else if (this.state.BonusSound === 'custom' && this.state.CustomBonusSound) {
                this.audio = new Audio(this.state.CustomBonusSound);
                this.audio.play();
            }
        }
    }

    playBonusTimeSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.BonusTimeSound !== 'custom' && this.state.BonusTimeSound !== 'random' && this.state.BonusTimeSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.BonusTimeSound);
                this.audio.play();
            } else if (this.state.BonusTimeSound === 'custom' && this.state.CustomBonusTimeSound) {
                this.audio = new Audio(this.state.CustomBonusTimeSound);
                this.audio.play();
            }
        }
    }

    playWinSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.WinSound !== 'custom' && this.state.WinSound !== 'random' && this.state.WinSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.WinSound);
                this.audio.play();
            } else if (this.state.WinSound === 'custom' && this.state.CustomWinSound) {
                this.audio = new Audio(this.state.CustomWinSound);
                this.audio.play();
            }
        }
    }
    playGameOverSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.GameOverSound !== 'custom' && this.state.GameOverSound !== 'random' && this.state.GameOverSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.GameOverSound);
                this.audio.play();
            } else if (this.state.GameOverSound === 'custom' && this.state.CustomGameOverSound) {
                this.audio = new Audio(this.state.CustomGameOverSound);
                this.audio.play();
            }
        }
    }
    playStartSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.StartSound !== 'custom' && this.state.StartSound !== 'random' && this.state.StartSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.StartSound);
                this.audio.play();
            } else if (this.state.StartSound === 'custom' && this.state.CustomStartSound) {
                this.audio = new Audio(this.state.CustomStartSound);
                this.audio.play();
            }
        }
    }
    playBackgroundSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.BackgroundSound !== 'custom' && this.state.BackgroundSound !== 'random' && this.state.BackgroundSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.BackgroundSound);
                this.audio.play();
            } else if (this.state.BackgroundSound === 'custom' && this.state.CustomBackgroundSound) {
                this.audio = new Audio(this.state.CustomBackgroundSound);
                this.audio.play();
            }
        }
    }
    playTrueSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.TrueSound !== 'custom' && this.state.TrueSound !== 'random' && this.state.TrueSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.TrueSound);
                this.audio.play();
            } else if (this.state.TrueSound === 'custom' && this.state.CustomTrueSound) {
                this.audio = new Audio(this.state.CustomTrueSound);
                this.audio.play();
            }
        }
    }
    playFalseSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        } else {
            if (this.state.FalseSound !== 'custom' && this.state.FalseSound !== 'random' && this.state.FalseSound !== 'none') {
                this.audio = new Audio(process.env.PUBLIC_URL + this.state.FalseSound);
                this.audio.play();
            } else if (this.state.FalseSound === 'custom' && this.state.CustomFalseSound) {
                this.audio = new Audio(this.state.CustomFalseSound);
                this.audio.play();
            }
        }
    }

    renderPalyer() {
        if (this.state.Confetti && this.state.showConfetti) {
            let src = "";
            if (this.state.Confetti !== 'custom' && this.state.Confetti !== 'random' && this.state.Confetti !== 'none') {
                src = process.env.PUBLIC_URL + this.state.Confetti;
            } else if (this.state.Confetti === 'custom' && this.state.CustomConfetti) {
                src = this.state.CustomConfetti;
            }
            if (src) {
                return (
                    <div className="position-absolute w-100" style={{
                        pointerEvents: 'none',
                        top: '0',
                        right: '0',
                        zIndex: 999999999
                    }}>
                        <Player
                            onEvent={event => {
                                if (event === 'complete') {
                                    this.setState({
                                        showConfetti: false
                                    });
                                }
                            }}
                            autoplay
                            loop={false}
                            src={src}
                            style={{ height: '100%', width: '100%' }}   ></Player>
                    </div>
                );
            }
        }
    }
    playConfetti = () => {
        this.setState({
            showConfetti: !this.state.showConfetti
        });
    }
    playConfettiFalse = () => {
        this.setState({
            showConfettiFalse: !this.state.showConfettiFalse
        });
    }
    renderPalyerFalse() {
        if (this.state.ConfettiFalse && this.state.showConfettiFalse) {
            let src = "";
            if (this.state.ConfettiFalse !== 'custom' && this.state.ConfettiFalse !== 'random' && this.state.ConfettiFalse !== 'none') {
                src = process.env.PUBLIC_URL + this.state.ConfettiFalse;
            } else if (this.state.ConfettiFalse === 'custom' && this.state.CustomConfettiFalse) {
                src = this.state.CustomConfettiFalse;
            }
            if (src) {
                return (
                    <div className="position-absolute w-100" style={{
                        pointerEvents: 'none',
                        top: '0',
                        right: '0',
                        zIndex: 999999999
                    }}>
                        <Player
                            onEvent={event => {
                                if (event === 'complete') {
                                    this.setState({
                                        showConfettiFalse: false
                                    });
                                }
                            }}
                            autoplay
                            loop={false}
                            src={src}
                            style={{ height: '100%', width: '100%' }}   ></Player>
                    </div>
                );
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.renderPalyer()}
                {this.renderPalyerFalse()}

                <li id="PopoverSounds" title={t('Pages.Lessons.Questions.forms.tools.sounds.menu')}>
                    <Popover overlayColor="rgba(0,0,0,0)">
                        <Link to="#" className="cate">
                            <i className="fal fa-volume"></i>
                            {t('Pages.Lessons.Questions.forms.tools.sounds.menu')}
                        </Link>
                        <div className='widget widget-search' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="fal fa-volume icon"></i>
                                {t('Pages.Lessons.Questions.forms.tools.sounds.menu')}
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.BackgroundSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameBackgroundSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.BackgroundSound ? this.state.BackgroundSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderBackgroundSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBackgroundSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomBackgroundSound ? this.state.CustomBackgroundSound : ''}
                                                onChange={e => this.props.setBackgroundGameCustomSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBackgroundSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.WinSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameWinSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.WinSound ? this.state.WinSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderWinSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playWinSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomWinSound ? this.state.CustomWinSound : ''}
                                                onChange={e => this.props.setWinGameCustomSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playWinSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.StartSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameStartSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.StartSound ? this.state.StartSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderStartSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playStartSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomStartSound ? this.state.CustomStartSound : ''}
                                                onChange={e => this.props.setGameCustomStartSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playStartSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.GameOverSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameOverSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.GameOverSound ? this.state.GameOverSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderGameOverSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playGameOverSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomGameOverSound ? this.state.CustomGameOverSound : ''}
                                                onChange={e => this.props.setCustomGameOverSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playGameOverSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.true_sounds')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setQuestionTrueSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.TrueSound ? this.state.TrueSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderTrueSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playTrueSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomTrueSound ? this.state.CustomTrueSound : ''}
                                                onChange={e => this.props.setQuestionCustomTrueSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playTrueSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.false_sounds')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setQuestionFalseSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.FalseSound ? this.state.FalseSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderFalseSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playFalseSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomFalseSound ? this.state.CustomFalseSound : ''}
                                                onChange={e => this.props.setQuestionCustomFalseSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playFalseSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.BonusSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameBonusSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.BonusSound ? this.state.BonusSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderBonusSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBonusSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomBonusSound ? this.state.CustomBonusSound : ''}
                                                onChange={e => this.props.setCustomGameBonusSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBonusSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.BonusTimeSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameBonusTimeSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.BonusTimeSound ? this.state.BonusTimeSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderBonusSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBonusTimeSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomBonusTimeSound ? this.state.CustomBonusTimeSound : ''}
                                                onChange={e => this.props.setCustomGameBonusTimeSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playBonusTimeSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label className="text-dark h6"><i className="icon fas fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.StageSound')}</label>
                                        <div className="search-form">
                                            <select
                                                onChange={e => this.props.setGameStageSound(e.target.value)}
                                                className="w-100"
                                                value={this.state.StageSound ? this.state.StageSound : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                {this.renderStageSound()}
                                            </select>
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playStageSound()}>
                                                <i className="fas fa-volume"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                value={this.state.CustomStageSound ? this.state.CustomStageSound : ''}
                                                onChange={e => this.props.setStageGameCustomSound(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} onClick={() => this.playStageSound()}>
                                                <i className="fas fa-volume"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="widget-header p-1 w-100 mb-3"></div>
                                    <div className="title w-100 text-center">
                                        {t('Pages.Lessons.Questions.forms.tools.sounds.true_sounds')}
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.title')}</label>
                                        <div className="search-form">

                                            <select
                                                onChange={e => this.props.setQuestionConfetti(e.target.value)}
                                                className="w-100"
                                                value={this.state.Confetti ? this.state.Confetti : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.none')}</option>
                                                <option value="random">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.random')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</option>
                                                {this.renderConfetti()}
                                            </select>

                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfetti()}>
                                                <i className="fas fa-violin"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                value={this.state.CustomConfetti ? this.state.CustomConfetti : ''}
                                                onChange={e => this.props.setQuestionCustomConfetti(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfetti()}>
                                                <i className="fas fa-violin"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="title w-100 text-center">
                                        {t('Pages.Lessons.Questions.forms.tools.sounds.false_sounds')}
                                    </div>

                                    <div className="form-group mb-3 ">
                                        <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.title')}</label>
                                        <div className="search-form">

                                            <select
                                                onChange={e => this.props.setQuestionConfettiFalse(e.target.value)}
                                                className="w-100"
                                                value={this.state.ConfettiFalse ? this.state.ConfettiFalse : 'none'}
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.none')}</option>
                                                <option value="random">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.random')}</option>
                                                <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</option>
                                                {this.renderConfettiFalse()}
                                            </select>

                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfettiFalse()}>
                                                <i className="fas fa-violin"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</label>
                                        <div className="search-form">
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                value={this.state.CustomConfettiFalse ? this.state.CustomConfettiFalse : ''}
                                                onChange={e => this.props.setQuestionCustomConfettiFalse(e.target.value)}
                                            />
                                            <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfettiFalse()}>
                                                <i className="fas fa-violin"  ></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(GameSound);
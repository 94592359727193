import React from "react";
import './style.css';

class BeaconInLightHouse extends React.Component {
    render() {
        return <div className="BeaconInLightHouse">
            <div className="BeaconInLightHouse-lighthouse">
                <div className="BeaconInLightHouse-top">
                    <div className="BeaconInLightHouse-lightning-rod"></div>
                    <div className="BeaconInLightHouse-cupola"></div>
                    <div className="BeaconInLightHouse-light-room"></div>
                </div>
                <div className="BeaconInLightHouse-last-story">
                </div>
                <div className="BeaconInLightHouse-stories">
                    <div className="BeaconInLightHouse-window"></div>
                </div>
                <div className="BeaconInLightHouse-door"></div>
                <div className="BeaconInLightHouse-road"></div>
            </div>
        </div>
    }
}

export default BeaconInLightHouse;
import React from "react";

const BounsTry = (props) => {

    const { TryColor, position, count, TryColorCustom } = props;

    return <div className={`text-${TryColor === "custom" ? "" : TryColor} position-absolute display-4 font-weight-bold game-font`} style={{
        zIndex: 99,
        WebkitTextStroke: "3px #000",
        animation: `plus-up-text ease forwards 6s normal`,
        top: `${position.y}px`,
        left: `${position.x}px`,
        color: TryColorCustom
    }}>
        {count} <i className="fas fa-heart"></i>
    </div>
}
export default BounsTry;
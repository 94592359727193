import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Footer from "../../Footer/FooterBottom";
import { userInitial } from '../../actions/';
import UserSubMenu from './UserSubMenu';
import api from '../../../api/api';
import history from '../../../history';
import EditProfileForm from '../Forms/EditProfileForm';
import Labs from "../../Admin/Question/View/Background/Labs";

class ProfileEdit extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-profile-edit-section");
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/User/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.userInitial(response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                history.push("/Profile");
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.ProfileEdit.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>

                <section className="pos-rel padding-bottom padding-top pt-4" id="main-profile-edit-section" style={{
                    minHeight: '100vh'
                }}>
                    <Labs user={user} />
                    <div className="container">

                        <div className="row mt-30 mb-30-none">
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-category">
                                    <div className="widget-header d-flex align-items-center justify-content-between">
                                        <h5 className="title">
                                            <i className="fal fa-edit "></i> {t('Pages.ProfileEdit.title')}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <EditProfileForm user={user ? user.user : ""} onSubmit={this.onSubmit} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, { userInitial })(withTranslation('common')(ProfileEdit));
import React from "react";
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class BillItem extends React.Component {

    state = {
        moreInfo: false
    };

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    renderTableBodyBillView() {
        const { item } = this.props;
        return item.Items.map(service => {
            return <tr key={`billView-${service.Service.Id}`}>
                <td>
                    {service.Service.Name}
                </td>
                <td>
                    {service.Service.Price}
                </td>
            </tr>
        });
    }

    render() {
        const { t, item } = this.props;
        return (
            <div className="col px-0 mx-0" key={item.Id}>
                <div className="product-list-item" >
                    {
                        item.IsActive ? "" :
                            <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.myBills.NotActive')}>
                                <i className="fas fa-lock"></i>
                            </div>
                    }
                    {
                        item.IsVisa ?
                            <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.myBills.IsVisa")}>
                                <i className="fab fa-cc-visa"></i>
                            </div> : ""

                    }
                    <div className="product-thumb">
                        {
                            item.ForUser.Photo ?
                                <LazyLoadImage src={item.ForUser.Photo} alt={`${item.ForUser.Name}`} />
                                :
                                <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.ForUser.Name}`} />
                        }

                        <div className="product-action-link">
                            <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.myBills.action.info")}><i className="far fa-info"></i></div>
                        </div>
                    </div>
                    <div className="product-content py-3">
                        <h5 className="title">
                            {item.ForUser.Name}
                        </h5>
                        <p>
                            <span className="h5 title">
                                {t('Pages.myBills.Price')} :
                                {item.Price}
                                {
                                    item.IsNIS ?
                                        <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i> :
                                        <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                                }
                            </span>
                            {
                                item.Discount ?
                                    <>
                                        <span className="text-danger h5 title mx-3" title={t('Pages.myBills.Discount')}>
                                            {t('Pages.myBills.Discount')} :
                                            {item.Discount}
                                            {
                                                item.IsNIS ?
                                                    <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i> :
                                                    <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                                            }
                                        </span>
                                        <span className="h5 title" title={t('Pages.myBills.NatPrice')}>
                                            {t('Pages.myBills.NatPrice')} :
                                            {item.Price - item.Discount}
                                            {
                                                item.IsNIS ?
                                                    <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i> :
                                                    <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                                            }
                                        </span>
                                    </>
                                    : ""
                            }


                        </p>
                        {
                            item.Note ?
                                <p title={t('Pages.myBills.Note')}>
                                    <i className="icon fal fa-quote-right"></i>
                                    {item.Note}
                                </p>
                                : ""
                        }
                        <p>
                            {
                                item.ActivateUntilDate ?
                                    <span title={t('Pages.myBills.ActivateUntilDate')}>
                                        <i className="icon fal fa-calendar-alt"></i>
                                        {item.ActivateUntilDate}
                                    </span>
                                    : ""
                            }
                        </p>
                        <p>
                            {
                                item.Payment ?
                                    <span title={t('Pages.myBills.Payment')}>
                                        <i className="icon fal fa-file-invoice"></i>
                                        {item.Payment}
                                    </span>
                                    : ""
                            }
                        </p>
                    </div>
                    {
                        this.state.moreInfo ?
                            <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                borderTop: '1px dashed #92278f'
                            }}>
                                <table className="table table-striped table-hover table-bordered">
                                    <thead>
                                        <tr className="text-center">
                                            <th>
                                                {t('Pages.myBills.Service')}
                                            </th>
                                            <th>
                                                {t('Pages.myBills.Price')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableBodyBillView()}
                                    </tbody>
                                </table>
                            </div> : ""
                    }
                </div>
            </div>
        );
    }
}


export default withTranslation('common')(BillItem);
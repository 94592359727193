import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactHtml from 'raw-html-react';

import QuestionTitle from '../QuestionTitle/';
import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import ClickFiled from '../FillFiled/ClickFiled';

class ClickView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        answers: [],
        answersList: [],
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.answers = props.answers;
        state.categories = props.categories;
        state.QuestionId = props.Id;
        state.version = props.version;

        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        let fills = [];
        if (props.fills) {
            props.fills.forEach((node) => {
                const hasAnswer = answerList.filter(p => node.AnswerId === p.Id)[0];
                if (hasAnswer) {
                    const hasCategory = state.categories.filter(p => node.CategoryId === p.Id)[0];
                    if (hasCategory) {
                        fills.push(node);
                    }
                }
            });
        }
        state.fills = fills;

        if (props.onEdit === true && props.categories) {
            let trueAnswer = [];
            props.categories.forEach(category => {
                category.CustomStyle?.Answers.forEach(answer => {
                    var result = { ...answer };
                    if (result.AnswersSelectType !== 'none') {
                        result.SelectType = result.AnswersSelectType;
                        result.Sort += 1;
                        trueAnswer.push(result);
                    }
                });
            });
            state.answersList = trueAnswer;
        }

        if (props.examAnswers) {
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answersList = hasAnswer.answers;
                if (props.showResult) {
                    const { t, mark } = props;

                    let trueAnswer = 0;
                    let falseAnswer = 0;
                    let trueAnswers = 0;
                    state.categories.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                        const count = item.Name.split("(*_)").length;
                        const Answers = item.CustomStyle.Answers.filter(p => p.Id === item.Id && p.AnswersSelectType !== 'none');
                        trueAnswers += Answers.length;

                        for (let index = 0; index < count; index++) {
                            const hasAnswer = state.answersList.filter(p => p.Id === item.Id && p.Sort === index && p.SelectType !== 'none')[0];

                            if (hasAnswer && hasAnswer.isTrue) {
                                trueAnswer++;
                            } else if (hasAnswer && hasAnswer.SelectType !== 'none') {
                                falseAnswer++;
                            }
                        }
                    });

                    let userMark = 0;

                    if (trueAnswers < (falseAnswer + trueAnswer) && trueAnswer >= falseAnswer) {
                        userMark = (mark * (trueAnswer - falseAnswer) / trueAnswers).toFixed(2);
                    } else if (trueAnswers >= (falseAnswer + trueAnswer)) {
                        userMark = (mark * trueAnswer / trueAnswers).toFixed(2);
                    } else if (trueAnswer >= falseAnswer) {
                        userMark = (mark * (trueAnswer - falseAnswer) / trueAnswers).toFixed(2);
                    }

                    if (trueAnswers === trueAnswer && falseAnswer === 0) {
                        state.mark = {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        };
                    } else if (trueAnswer === 0) {
                        state.mark = {
                            type: 'danger',
                            userMark: 0,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else if (falseAnswer >= trueAnswer) {

                        state.mark = {
                            type: 'danger',
                            userMark: userMark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else {
                        state.mark = {
                            type: 'warning',
                            userMark: userMark,
                            isSolved: true,
                            text: (trueAnswers < (falseAnswer + trueAnswer)) ? t('Pages.Lessons.Questions.forms.Options.error.more') : t('Pages.Lessons.Questions.forms.Options.error.missed'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    }

                }
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }

        return state;

    }

    ResetAnswer = () => {
        this.setState({
            answersList: [],
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });
    }

    renderMark() {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        }
        return "";
    }

    CheckAnswer = () => {
        const { t, mark, toast } = this.props;
        const {
            trueAnswers,
            answersCount,
            trueAnswer,
            falseAnswer,
            answersList,
            userMark
        } = this.GetAnswerList();

        if (answersCount === 0) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-lightbulb-on"></i> {t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (trueAnswers === trueAnswer && falseAnswer === 0) {
            this.setState({
                answersList,
                mark: {
                    type: 'success',
                    userMark: mark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                    title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                }
            });
            this.props.playTrueSound();
            this.props.showConfetti();
        } else if (trueAnswer === 0) {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        } else if (falseAnswer >= trueAnswer) {
            this.setState({
                answersList,
                mark: {
                    type: 'danger',
                    userMark: userMark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
            this.props.playFalseSound();
        } else {
            this.setState({
                answersList,
                mark: {
                    type: 'warning',
                    userMark: userMark,
                    isSolved: true,
                    text: (trueAnswers < (falseAnswer + trueAnswer)) ? t('Pages.Lessons.Questions.forms.Options.error.more') : t('Pages.Lessons.Questions.forms.Options.error.missed'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
            this.props.playFalseSound();
        }
    }

    GetAnswerList = () => {
        let trueAnswer = 0;
        let falseAnswer = 0;
        let trueAnswers = 0;
        let answersCount = this.state.answersList.length;
        let answersList = [];

        this.state.categories.sort((a, b) => a.Sort - b.Sort).forEach(item => {
            const count = item.Name.split("(*_)").length;
            const Answers = item.CustomStyle.Answers.filter(p => p.Id === item.Id && p.AnswersSelectType !== 'none');
            trueAnswers += Answers.length;

            for (let index = 0; index < count; index++) {
                let isTrue = false;
                const questionAnswer = Answers.filter(p => p.Id === item.Id && p.Sort === index - 1)[0];
                const hasAnswer = this.state.answersList.filter(p => p.Id === item.Id && p.Sort === index && p.SelectType !== 'none')[0];

                if (questionAnswer && hasAnswer) {
                    if (questionAnswer.AnswersSelectType === hasAnswer.SelectType) {
                        isTrue = true;
                    }
                }
                if (hasAnswer) {
                    answersList.push({
                        Id: item.Id,
                        Sort: index,
                        SelectType: hasAnswer.SelectType,
                        isTrue
                    });
                }
                if (hasAnswer && hasAnswer.isTrue) {
                    trueAnswer++;
                } else if (hasAnswer && hasAnswer.SelectType !== 'none') {
                    falseAnswer++;
                }
            }
        });


        let userMark = 0;
        const mark = this.props.mark

        if (trueAnswers < (falseAnswer + trueAnswer) && trueAnswer >= falseAnswer) {
            userMark = (mark * (trueAnswer - falseAnswer) / trueAnswers).toFixed(2);
        } else if (trueAnswers >= (falseAnswer + trueAnswer)) {
            userMark = (mark * trueAnswer / trueAnswers).toFixed(2);
        } else if (trueAnswer >= falseAnswer) {
            userMark = (mark * (trueAnswer - falseAnswer) / trueAnswers).toFixed(2);
        }

        return {
            trueAnswers,
            answersCount,
            trueAnswer,
            falseAnswer,
            answersList,
            userMark
        };
    }

    handleAnswerChange = (CategoryId, Sort, SelectType) => {
        let answersList = this.state.answersList;
        let isTrue = false;

        const item = this.state.categories.filter(p => p.Id === CategoryId)[0];
        if (item && item.CustomStyle && item.CustomStyle.Answers.filter(p => p.Id === CategoryId && p.AnswersSelectType === SelectType && p.Sort === Sort - 1)[0]) {
            isTrue = true;
        }
        if (answersList.some(p => p.Id === CategoryId && p.Sort === Sort)) {
            var answer = answersList.filter(p => p.Id === CategoryId && p.Sort === Sort)[0];
            answer.SelectType = SelectType;
            answer.isTrue = isTrue;
        } else {
            answersList.push({
                Id: CategoryId,
                Sort: Sort,
                SelectType,
                isTrue
            });
        }

        if (this.props.isExamQuestion !== true) {
            let AutoCorrection = false;
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
                if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                    AutoCorrection = true;
                    if (SelectType !== "none") {
                        if (isTrue) {
                            this.props.playTrueSound();
                        } else {
                            this.props.playFalseSound();
                        }
                    }
                }
                this.setState({
                    answersList,
                    mark: {
                        type: '',
                        userMark: 0,
                        isSolved: AutoCorrection
                    }
                });
            }
        } else {
            this.setState({
                answersList
            });
        }
        this.saveExamAnswer();
    }

    saveExamAnswer() {
        if (this.props.saveExamAnswer) {
            setTimeout(() => {
                const {
                    trueAnswers,
                    trueAnswer,
                    falseAnswer,
                    answersList,
                    userMark } = this.GetAnswerList();
                this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answersList, {
                    trueResultCount: trueAnswers,
                    trueAnswer,
                    falseAnswer,
                    questionMark: this.props.mark
                }, userMark);
            }, 500);
        }
    }

    renderCategoris() {
        if (this.state.categories) {
            let categories = this.state.categories;

            const dbclickStyle = this.state.CustomStyle && this.state.CustomStyle.categoryStyle ? {
                paddingLeft: `${this.state.CustomStyle.categoryStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.categoryStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.categoryStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.categoryStyle.PaddingBottom}px`,

                borderColor: this.state.CustomStyle.categoryStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.categoryStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.categoryStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.categoryStyle.BorderRadiusTop}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusRight}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.categoryStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryStyle.shadowColor}`,

                opacity: `${this.state.CustomStyle.categoryStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.categoryStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.categoryStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.categoryStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.categoryStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.categoryStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.categoryStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.categoryStyle.BgAttachment}`,
            } : "";

            const clickStyle = this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? {
                paddingLeft: `${this.state.CustomStyle.answerBoxStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.answerBoxStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.answerBoxStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.answerBoxStyle.PaddingBottom}px`,

                borderColor: this.state.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.answerBoxStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.answerBoxStyle.BorderRadiusTop}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusRight}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.answerBoxStyle.ShadowPositionX}px ${this.state.CustomStyle.answerBoxStyle.ShadowPositionY}px ${this.state.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerBoxStyle.shadowColor}`,

                opacity: `${this.state.CustomStyle.answerBoxStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.answerBoxStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.answerBoxStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.answerBoxStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.answerBoxStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.answerBoxStyle.BgAttachment}`,
            } : "";

            let AutoCorrection = false;
            if (this.props.isExamQuestion !== true) {
                if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
                    if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                        AutoCorrection = true;
                    }
                }
            }

            const length = this.state.categories.length;
            let AnswersMargin = 0;
            if (this.state.CustomStyle.questionTypeStyle && this.state.CustomStyle.questionTypeStyle.AnswersMargin) {
                AnswersMargin = this.state.CustomStyle.questionTypeStyle.AnswersMargin;
            }

            var twoType = false;
            categories.forEach(item => {
                const hasDBClick = item.CustomStyle ? item.CustomStyle.Answers.some(p => p.AnswersSelectType === 'dbclick') : "";
                if (hasDBClick) {
                    twoType = true;
                }
            });

            return (
                <>
                    <div style={{
                        height: '100%',
                        position: 'relative'
                    }}>
                        {
                            categories.sort((a, b) => a.Sort - b.Sort).map((item, cindex) => {

                                if (cindex + 1 === length) {
                                    AnswersMargin = 0;
                                }
                                const count = item.Name.split("(*_)").length;
                                var html = item.Name;

                                let splitedAnswer = html.split('(*_)');

                                var first = true;
                                var words = "";

                                const CustomStyle = item.CustomStyle;

                                for (let index = 0; index < count; index++) {
                                    let element = splitedAnswer[index];
                                    let isTrue = "";
                                    if (this.state.mark.isSolved === true) {
                                        var hasAnswer = this.state.answersList ? this.state.answersList.filter(p => p.Id === item.Id && p.Sort === index && p.SelectType !== 'none')[0] : "";
                                        const questionAnswer = item.CustomStyle && item.CustomStyle.Answers ? item.CustomStyle.Answers.filter(p => p.Id === item.Id && p.Sort === index - 1)[0] : "";

                                        if (hasAnswer && hasAnswer.isTrue) {
                                            isTrue = 1;
                                        } else if (hasAnswer && hasAnswer.SelectType !== 'none' && AutoCorrection === true) {
                                            isTrue = 0;
                                        } else if (questionAnswer && questionAnswer.AnswersSelectType !== 'none' && AutoCorrection === false) {
                                            isTrue = 0;
                                        } else if (hasAnswer && hasAnswer.SelectType !== 'none') {
                                            isTrue = 0;
                                        }
                                    }

                                    if (first) {
                                        first = false;
                                        words = element;
                                    } else {
                                        element = element.split('(_*)');
                                        words += `<span text='${element[0]}' version='${this.props.version} ' isTrue='${isTrue}' Sort='${index} ' data-react-component="ClickFiled"></span>`;
                                        words += element[1];
                                    }
                                }

                                return <div className={`row with-div-100 col-12 mx-0 mb-${AnswersMargin}`} key={item.Id} >
                                    <ReactHtml
                                        twoType={twoType}
                                        clickStyle={clickStyle}
                                        dbclickStyle={dbclickStyle}
                                        html={words}
                                        handleAnswerChange={this.handleAnswerChange}
                                        CustomStyle={CustomStyle}
                                        componentMap={{ ClickFiled }}
                                        CategoryId={item.Id}
                                        QuestionId={this.state.QuestionId}
                                        answersList={this.state.answersList} />
                                </div>;
                            })
                        }
                    </div>
                </>
            );
        }
        return "";
    }

    render() {
        const { title, mark, index, Id, onEdit, isExamQuestion, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'absolute') {
            style = { ...style, height: `${this.state.CustomStyle.questionStyle.height}px` };
        }

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        let AutoCorrection = false;
        if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                AutoCorrection = true;
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`}>

                <div className="bg-white w-100 p-3 form-group w-100 position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} style={style} >
                            <div className="w-100 position-absolute " style={styleBackground} id={`SelectDesignerPreview_${Id}`} > </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            {
                                (this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'circle' ||
                                    this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate === 'rounded') ?
                                    <style dangerouslySetInnerHTML={{ __html: `.checkboxAnswer-${Id}::after,.checkboxAnswer-${Id}::before{display:none}` }}></style>
                                    : ""
                            }
                            <div className="mt-1">
                                {this.renderCategoris()}
                            </div>
                        </div>
                    </div>
                    {showResult === true ? this.renderMark() : ""}
                    {isExamQuestion !== true ? AutoCorrection === false ? this.renderControlButton() : "" : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(ClickView));
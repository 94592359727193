import React from "react";
import { Link } from "react-router-dom";

class VideoPlayer extends React.Component {

    state = {
        muted: true,
        loop: true,
        controls: false
    };

    playVideo = () => {
        this.setState({
            muted: false,
            loop: false,
            controls: true
        }, () => {
            this.mainVideo.current.currentTime = 0;
        });
    }

    componentDidMount() {
        this.mainVideo = React.createRef();
    }

    render() {
        if (this.props.video) {
            return (<div className="w-100 text-center mt-3 order-sm-2 order-md-3">
                <div className="position-relative">
                    {
                        this.state.controls ? "" :
                            <>
                                <div to="#" className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" style={{
                                    zIndex: 99998
                                }}>
                                    <i className="cursor-pointer fa-solid fa-play fa-beat text-white display-1" onClick={() => this.playVideo()} ></i>
                                </div>
                                <div to="#" className="position-absolute m-2" onClick={() => this.playVideo()} style={{
                                    zIndex: 99999
                                }}>
                                    <Link to="#" className="btn btn-sm btn-dark" >
                                        <i className="fa-solid fa-volume-slash mx-2"></i>
                                        اضغط لسماع الصوت
                                    </Link>
                                </div>
                            </>
                    }
                    <video ref={this.mainVideo} className="w-100" autoPlay playsInline controls={this.state.controls} loop={this.state.loop} muted={this.state.muted}>
                        <source src={this.props.video} type="video/mp4" />
                    </video>
                </div>
            </div>
            );
        }
        return "";
    }
}

export default VideoPlayer;
import React from "react";
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";

import InfoForm from './InfoForm';
import history from '../../../history';
import Loading from "../../Loading/";
import DashBoard from './Screen/DashBoard';
import {
    fetchLesson,
    updateLessonQuestion,
    updateLessons,
    removeLessonQuestion,
    undoDeleteLessonQuestion,
    clearLessonQuestion,
    setLessonQuestionsSort,
    createNewLessonQuestion,
    removeLessonPage,
    undoDeleteLessonPage,
    clearLessonPage
} from '../Lessons/actions/';
import { fetchQuestion, showUpdateModal, updateQuestion } from '../Question/actions/';
import api from '../../../api/api';
import List from "../Question/List";
import QuestionSortModal from "../Lessons/Modal/QuestionSortModal";
import LessonPage from "../Lessons/Modal/LessonPage";

class GamesEdit extends React.Component {

    state = {
        showGameInfoModal: false,
        Id: '0',
        showDragDropModal: false,
        showPagesModal: false,
        IndexId: 0,
        Questions: [],
        LessonPage: [],
        showGameId: 0
    };

    static getDerivedStateFromProps(props, state) {
        if (props.lessons && props.lessons.current) {
            state.Id = props.lessons.current.Id;
            state.IndexId = props.lessons.current.Index.Id
            state.Questions = props.lessons.current.Questions ? props.lessons.current.Questions.sort((a, b) => a.Sort - b.Sort) : [];

            let LessonPage = [];
            if (props.lessons.current.LessonPage) {
                let toRemove = props.lessons.PageToRemove;
                props.lessons.current.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                    if (!toRemove || !toRemove.includes(item.Id)) {
                        LessonPage = [...LessonPage, item];
                    }
                });
            }
            state.LessonPage = LessonPage;
        }

        return state;
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            Id: id
        });
        if (id !== '0') {
            this.props.fetchLesson(id);
        } else {
            this.setState({
                showGameInfoModal: true
            });
        }
    }

    showGameInfoModal = () => {
        this.setState({ showGameInfoModal: true });
    }

    handleClose = () => {
        let showGameInfoModal = true;
        if (this.state.Id && this.state.Id !== "0") {
            showGameInfoModal = false;
        } else {
            history.push(`/ControlPanel/Games/0`);
        }
        this.setState({
            showGameInfoModal,
            showDragDropModal: false,
            showPagesModal: false
        });

    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        let url = `/Lessons/`;
        let method = api.post;
        if (this.state.Id && this.state.Id !== "0") {
            url = `/Lessons/${this.state.Id}`;
            method = api.put;
        }

        method(url,
            postData
        ).then(response => {

            if (this.state.Id !== 0 || response.data.data.Id !== 0) {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.forms.Success.Title')}</div> <div className="h6">{t('Pages.Lessons.forms.Success.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });

                this.setState({
                    Id: response.data.data.Id
                });
                window.history.replaceState(null, postData.Name, "/ControlPanel/Games/Edit/" + response.data.data.Id);

                if (this.state.Id && this.state.Id !== "0") {
                    this.props.updateLessons({
                        ...postData,
                        Index: {
                            Id: postData.IndexId
                        }
                    });
                } else {
                    this.props.updateLessons({
                        ...response.data.data
                    });
                }


                this.handleClose();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.forms.error.Title')}</div> <div className="h6">{t('Pages.Lessons.forms.error.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    goBack = () => {
        if (this.state.question) {
            this.setState({
                question: ""
            });
        } else {
            history.push(`/ControlPanel/Games/${this.state.IndexId}`);
        }
    }

    setQuestionValue = (newQuestion) => {
        this.props.updateLessonQuestion(newQuestion);
        this.setState({
            resetQuestion: true
        });
    }
    updateQuestion = question => {
        this.props.updateQuestion(question);
        let hasQuestion = this.state.Questions.some(p => p.Id === question.id);
        if (!hasQuestion) {
            this.props.createNewLessonQuestion(question.question);
        }
    }

    onEditHandel = id => {
        var question = this.props.lessons.current.Questions.filter(p => p.Id === id)[0];
        if (question) {
            this.showUpdateModal(question.Id, question.QuestionLessonId, question.Type.Id);
        }
    }


    showGameMenu = () => {
        window.showCart();
    }

    showUpdateModal = (Id, QuestionLessonId, TypeId) => {
        if (Id) {
            this.props.fetchQuestion(QuestionLessonId);
        }
        this.props.showUpdateModal(Id, TypeId);
    }

    onRemoveHandel = id => {
        if (id) {
            this.props.removeLessonQuestion(id);

            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDeleteQuestion(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteQuestion(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }
    deleteQuestion = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        this.props.clearLessonQuestion(id);

        api.delete(`/Questions/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Delete_Question.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Delete_Question.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            this.handleClose();
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    undoDeleteQuestion = id => {
        this.props.undoDeleteLessonQuestion(id);
    }

    showSort = () => {
        this.setState({
            showDragDropModal: true
        });
    }
    setSort = items => {
        items.forEach(item => {
            this.props.setLessonQuestionsSort(item.id, item.sort);
        });
        this.handleClose();
    }
    showPagesModal = () => {
        this.setState({
            showPagesModal: true
        });
    }

    ShowGame = showGameId => {
        this.setState({
            showGameId
        }, () => { this.handleClose(); });
    }

    HideGame = () => {
        this.setState({
            showGameId: 0
        });
    }

    render() {
        const { lessons, t } = this.props;
        if ((lessons.length === 0 || !lessons.current) && this.state.Id !== '0') {
            return <Loading showGame={true} />
        }
        return <>
            {
                this.state.showGameInfoModal ?
                    <Modal
                        size="xl"
                        show={true}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title> <i className="icon fal fa-text"></i>{t(`Pages.Games.forms.LessonInfo.title`)} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InfoForm
                                lesson={lessons && lessons.current ? lessons.current : []}
                                onSubmit={this.onSubmit}
                            />
                        </Modal.Body>
                    </Modal> :
                    ""
            }
            {
                this.state.showDragDropModal ?
                    <QuestionSortModal
                        LessonId={this.state.Id}
                        isGame={true}
                        ShowGame={this.ShowGame}
                        oneSide={true}
                        show={this.state.showDragDropModal}
                        nodes={this.state.Questions}
                        IndexId={this.state.Id}
                        API={`/Questions/`}
                        updateModal={this.showUpdateModal}
                        deleteModal={this.onRemoveHandel}
                        setSort={this.setSort}
                        handleClose={this.handleClose}
                        updateCurrentQuestion={this.updateQuestion} />
                    : ""
            }
            {
                this.state.showPagesModal ?
                    <LessonPage
                        oneSide={true}
                        isGame={true}
                        lesson={lessons.current}
                        LessonId={this.state.Id}
                        updateLessons={this.props.updateLessons}
                        clearLessonPage={this.props.clearLessonPage}
                        LessonPage={this.state.LessonPage}
                        removeLessonPage={this.props.removeLessonPage}
                        undoDeleteLessonPage={this.props.undoDeleteLessonPage}
                        handleClose={this.handleClose}
                        show={this.state.showPagesModal} /> : ""
            }

            <List
                isForGame={true}
                LessonId={this.state.Id}
                setQuestionValue={this.setQuestionValue}
                updateCurrentQuestion={this.updateQuestion} />

            <Helmet>
                <link rel="stylesheet" type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
            </Helmet>

            <DashBoard
                lesson={lessons.current}
                onEdit={true}
                showGameId={this.state.showGameId}
                HideGame={this.HideGame}
                showGameInfoModal={this.showGameInfoModal}
                showPagesModal={this.showPagesModal}
                showGameMenu={this.showGameMenu}
                showSort={this.showSort}
                goBack={this.goBack}
            />

        </>;
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons,
        question: state.questions
    };
};

export default connect(mapStateToProps, { fetchLesson, fetchQuestion, updateLessons, showUpdateModal, updateLessonQuestion, updateQuestion, createNewLessonQuestion, setLessonQuestionsSort, removeLessonQuestion, undoDeleteLessonQuestion, clearLessonQuestion, removeLessonPage, undoDeleteLessonPage, clearLessonPage })(withTranslation('common')(GamesEdit));
import React, { useEffect, useRef } from "react";
import Spritesheet from 'react-responsive-spritesheet';

import img from './images/3.png';
import sound from './sounds/thunder.wav';

const audio = new Audio(sound);

const ThunderExplosion = (props) => {

    const { top, left, isMute } = props;

    const spritesheeInstance = useRef(null);

    useEffect(() => {
        if (audio && !audio.paused) {
            audio.pause();
        }
        if (spritesheeInstance && spritesheeInstance.current) {
            spritesheeInstance.current.goToAndPlay(1);
            spritesheeInstance.current.play();
        }
        if (audio && isMute !== true) {
            audio.currentTime = 0.7;
            audio.play();
        }
    });

    return <div className="position-absolute " style={{
        top: top,
        left: left,
        userSelect: 'none',
        zIndex: 999,
        transform: 'translateX(-50%) translateY(-40%)',
        pointerEvents: 'none'
    }}>
        <Spritesheet
            image={img}
            widthFrame={728 / 4}
            heightFrame={824 / 4}
            steps={16}
            fps={25}
            autoplay={spritesheeInstance.current ? false : true}
            loop={true}
            getInstance={spritesheet => {
                spritesheeInstance.current = spritesheet;
            }}
            onLoopComplete={spritesheet => {
                if (props.stop) {
                    props.stop();
                }
                spritesheet.pause();
            }}
        />
    </div>;

}
export default ThunderExplosion;






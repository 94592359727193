import React from "react";
import { withTranslation } from 'react-i18next';
import GameObjects from "./index";
import Backgrounds from "../Background/";

class MovePreview extends React.Component {

    renderMoveStyle = currentMovment => {
        let result = "";
        currentMovment.sort((a, b) => a.Index - b.Index).forEach(item =>
            result += item.Index + "% {transform-origin:" + (item.AnswersTransformOrigin ? item.AnswersTransformOrigin : "100%") + "; transform:  scale(" + item.AnswersScale + ") rotate(" + item.AnswersRotate + "deg); opacity:" + item.AnswersOpacity / 100 + `; ${item.AnswersTranslatePositionY === "bottom" ? "bottom" : "top"} :  ` + item.AnswersTranslateY + `%; ${item.AnswersTranslatePositionX === "left" ? "left" : "right"} :` + item.AnswersTranslateX + "% }"
        );
        return result;
    }

    render() {
        const { Movments, id, Time, index, delay, Cursor, CursorCustom, ClickCursor, ClickCursorCustom, isForBackground, Background } = this.props;



        let currentCursor = "";
        if (Cursor && Cursor !== "none" && Cursor !== "custom") {
            currentCursor = process.env.PUBLIC_URL + Cursor;
        } else if (Cursor && Cursor === "custom") {
            currentCursor = CursorCustom;
        }


        let hoverCursor = "";
        if (ClickCursor && ClickCursor !== "none" && ClickCursor !== "custom") {
            hoverCursor = process.env.PUBLIC_URL + ClickCursor;
        } else if (ClickCursor && ClickCursor === "custom") {
            hoverCursor = ClickCursorCustom;
        }

        let currentMovment = Movments.filter(p => p.Id === id && p.Sort === index);
        if (isForBackground === true) {
            currentMovment = Movments;
        }

        let styleBackground = {
            height: '500px',
            opacity: `${this.props.Opacity}%`,
            backgroundColor: this.props.backgroundColor !== 'transparent' ? this.props.backgroundColor : "",
            backgroundImage: this.props.backgroundImage !== '' ? `url('${this.props.backgroundImage}')` : "",
            backgroundPositionX: `${this.props.BgPositionX}%`,
            backgroundPositionY: `${this.props.BgPositionY}%`,
            backgroundSize: `${this.props.BgSize}`,
            backgroundRepeat: `${this.props.BgRepeat}`,
            backgroundAttachment: `${this.props.BgAttachment}`,
            cursor: `url('${currentCursor}') 20 20, auto`
        };

        if (Background) {
            return <div className={`col-12 w-100 mx-auto bg-white`} >
                <div className=" oh border border-dark w-100 rounded position-relative" style={styleBackground}>
                    <Backgrounds CustomBackground={Background} absolute={true} />
                </div>
            </div >;
        }

        return <div className={`col-12 w-100 mx-auto bg-white`} >
            <div className=" oh border border-dark w-100 rounded position-relative" style={styleBackground}>
                {
                    currentMovment ?
                        <style type="text/css" dangerouslySetInnerHTML={
                            {
                                __html: `
                            @keyframes objectMovment-${id}-${index} { ${this.renderMoveStyle(currentMovment)} }`
                            }
                        }></style>
                        : ""
                }
                <div className={`position-absolute objectMovment-${id}-${index} ${this.props.objectCss === "html" ? "w-100" : ""}`} style={{
                    right: currentMovment && currentMovment.length > 0 ? 'unset' : '45%',
                    top: currentMovment && currentMovment.length > 0 ? 'unset' : '20%',
                    animationDelay: `${delay}s`,
                    animationName: `objectMovment-${id}-${index} `,
                    animationIterationCount: 'infinite',
                    animationDuration: `${Time}s`,
                    animationFillMode: 'forwards',
                    animationTimingFunction: 'linear',
                    cursor: `url('${hoverCursor}') 20 20, auto`
                }}>
                    {
                        this.props.objectCss === "html" && this.props.HTML ? <div dangerouslySetInnerHTML={{ __html: this.props.HTML }}></div> : <GameObjects {...this.props} />
                    }
                </div>
            </div>
        </div >;
    }
}

export default withTranslation('common')(MovePreview);
import React from "react";
import TextBox from "../../../TextBox";
import './style.scss';

class Butterfly extends React.Component {

    render() {
        let color = "dodgerblue";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const style = {
            backgroundColor: color
        };
        return (<>
            <div className="position-relative d-flex justify-content-center flex-column align-items-center" style={{
                direction: 'ltr',
                height: '160px',
                transform: 'scale(0.7)'
            }}>
                <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                    <div style={{
                        transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                        transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                    }}>
                        <div className="Butterfly_butterfly">
                            <div className="Butterfly_wing" style={style}>
                                <div className="Butterfly_bit" style={style}></div>
                                <div className="Butterfly_bit" style={style}></div>
                            </div>

                            <div className="Butterfly_wing" style={style}>
                                <div className="Butterfly_bit" style={style}></div>
                                <div className="Butterfly_bit" style={style}></div>
                            </div>
                        </div>
                        <div className="Butterfly_shadow"></div>
                    </div>
                </div>
            </div>
            <TextBox {...this.props} />
        </>);
    }
}

export default Butterfly;

import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import DropTarget from '../DragDrop/DropTarget';

class DropableFiled extends React.Component {

    state = {
        hoverStyle: '',
        style: '',
        text: '',
        isTrue: 2,
        Sort: 0,
        AnswerId: 0,
        hover: false,
        onEdit: false
    };

    constructor(props) {
        super(props);
        this.audio = new Audio();
    }

    componentDidMount() {
        const AnswerId = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("AnswerId"));
        const Sort = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("Sort"));
        const isTrue = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("isTrue"));
        this.setState({
            Sort,
            isTrue
        });
        if (AnswerId && AnswerId !== 0) {
            var answer = this.state.answers.filter(p => p.Id === AnswerId)[0];
            let text = '';
            if (answer) {
                text = answer.Name
            }
            this.setState({
                text: text,
                isTrue,
                AnswerId
            });
        }
    }

    playSound = () => {
        const CustomStyle = this.props.CustomStyle;
        if (CustomStyle && CustomStyle.Sound) {
            var hasItem = CustomStyle.Sound.filter(p => p.Id === this.props.CategoryId && p.Sort === (this.state.Sort - 1))[0];
            if (hasItem) {
                if (this.audio && !this.audio.paused) {
                    this.audio.pause();
                }
                if (hasItem.Url) {
                    this.audio = new Audio(hasItem.Url);
                    this.audio.play();
                }
            }
        }
        setTimeout(
            () => {
                window.webuiPopover();
            },
            500
        );
    }

    static getDerivedStateFromProps(props, state) {
        state.style = props.style;
        state.hoverStyle = props.hoverStyle;
        state.onEdit = props.onEdit;
        state.answers = props.answers;
        return state;
    }

    onHit = e => {
        if (e) {
            let Sort = 0;
            if (e.path && e.path[2] && e.path[2].attributes && e.path[2].attributes.sort) {
                Sort = parseInt(e.path[2].attributes.sort.value);
            } else {
                Sort = this.state.Sort;
            }

            this.setState({
                text: e.dragData.Name,
                hover: false,
                Sort
            });
            this.props.setDropTargetAnswer(e.dragData, this.props.CategoryId, Sort);
        }
    }

    emptyAnswer = () => {
        this.setState({
            text: '',
            hover: false
        });
        this.props.emptyAnswer(this.props.CategoryId, this.state.Sort);
    }

    onDragEnter = e => {
        this.setState({
            hover: true
        });
    }

    onDragLeave = e => {
        this.setState({
            hover: false
        });
    }

    render() {
        const { t } = this.props;
        let style = {
        };
        if (this.state.text === '') {
            style = {
                minWidth: '100px'
            };
        }

        let mainStyle = this.state.style;
        mainStyle = {
            display: 'inline-block',
            position: 'relative',
            ...mainStyle,
        };

        if (this.state.text !== '') {
            mainStyle = {
                ...mainStyle,
                width: 'auto',
                minWidth: 'auto'
            }
        }

        if (this.state.hover) {
            let hover = this.state.hoverStyle;
            mainStyle = {
                ...mainStyle,
                ...hover
            };
        }
        let answerIsTrue = "";
        if (this.state.isTrue === 1) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="mx-1 fad fa-check-circle text-success h6"></i>;
        } else if (this.state.isTrue === 0) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="mx-1 fas fa-exclamation-triangle text-danger h6"></i>;
        }


        let sound = "";
        if (this.props.CustomStyle) {
            const CustomStyle = this.props.CustomStyle;
            if (CustomStyle.Sound) {
                var hasItem = CustomStyle.Sound.filter(p => p.Id === this.props.CategoryId && p.Sort === (this.state.Sort - 1));
                if (hasItem && hasItem.Url) {
                    sound = <button type="button" className="fillButton" onClick={e => this.playSound()}><i className={`fad ${this.props.SoundIconType} m-0 fa-volume text-${this.props.SoundIconClass} h5 cursor-pointer`} title={t('Pages.Lessons.Questions.forms.tools.sounds.listen')} ></i> </button>;
                }
            }
        }

        return (
            <>
                <DropTarget targetKey={`QuestionId-${this.props.QuestionId}`} onDragLeave={this.onDragLeave} onDragEnter={this.onDragEnter} onHit={this.onHit} CategoryId={this.props.CategoryId} >
                    <span style={mainStyle} >
                        <div className="d-inline-block my-10" style={style} dangerouslySetInnerHTML={{ __html: this.state.text }}>
                        </div>
                        {
                            (this.state.text === '') ? sound :
                                <i className='text-danger mx-1 fad fa-ban cursor-pointer' title={t('Pages.Lessons.Questions.forms.tools.emptyAnswer')} onClick={this.emptyAnswer}></i>
                        }
                    </span>
                </DropTarget>
                {answerIsTrue}
            </>
        );
    }
}
export default withTranslation('common')(DropableFiled);
import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ScreenSetting from '../Question/Forms/Designer/ScreenSetting';
import ResultScreen from '../Games/Screen/ResultScreen';
import MoveBackgroundObject from '../Question/View/GamePreview/MoveBackground';

class InfoForm extends React.Component {

    state = {
        Id: 0,
        width: 100,
        BorderWidth: 3,
        BorderStyle: 'dashed',
        BorderColor: 'rgba(68, 68, 68, 1)',
        IndexId: 0,
        initialize: false,
        IsActive: false,
        IsPublic: false,
        screen: 6,
        displayColorPicker: false,
        showSplashScreen: false,
        errorList: [],
        CopyStyle: "",
        CustomStyle: {
            GlobalStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                width: 80,
                MainColor: '',
                SecondColor: '',
                FontColor: '',
                IconType: 'fas',
                Icon: 'arrow',
                IconColor: '',
                IconPosition: 'in',
                IconCustomColor: 'rgba(0, 0,   0,  1)',
                IconHoverColor: 'rgba(0, 0,   0,  1)'
            },
            LabelStyle: {
                shadowColor: 'rgba(0, 0,   0,  0.5)',
                ShadowPositionX: 0,
                ShadowPositionY: 0,
                ControlColor: '',
                ShadowRadius: 0,
                Padding: 0,
                PaddingTop: 0,
                PaddingRight: 0,
                PaddingLeft: 0,
                PaddingBottom: 0,
                BorderStyle: 'none',
                BorderColor: '',
                BorderWidthTop: 0,
                BorderWidthRight: 0,
                BorderWidthLeft: 0,
                BorderWidthBottom: 0,
                BorderWidth: 0,
                BorderRadius: 0,
                BorderRadiusTop: 0,
                BorderRadiusRight: 0,
                BorderRadiusLeft: 0,
                BorderRadiusBottom: 0,
                BorderRadiusUnit: 'px',
                backgroundColor: '',
                backgroundImage: '',
                BgAttachment: 'scroll',
                BgSize: 'auto',
                Custom: '',
                BgRepeat: 'no-repeat',
                Opacity: 100,
                BgPositionX: 0,
                BgPositionY: 0,
                height: 70,
                Margin: 0,
                width: 80,
                MainColor: '',
                SecondColor: '',
                MarginBottom: 0,
                FontColor: ''
            },
            IntroScreen: {
                GlobalStyle: {},
                LabelStyle: {},
                ScoreStyle: {},
                ButtonOneStyle: {},
                ButtonOneHoverStyle: {},
                ButtonSecondStyle: {},
                ButtonSecondHoverStyle: {}
            },
            DashBoard: {
                GlobalStyle: {},
                LabelStyle: {},
                ScoreStyle: {},
                ButtonOneStyle: {},
                ButtonOneHoverStyle: {},
                ButtonSecondStyle: {},
                ButtonSecondHoverStyle: {}
            },
            WinScreen: {
                GlobalStyle: {},
                LabelStyle: {},
                ScoreStyle: {},
                ButtonOneStyle: {},
                ButtonOneHoverStyle: {},
                ButtonSecondStyle: {},
                ButtonSecondHoverStyle: {}
            },
            StopScreen: {
                GlobalStyle: {},
                LabelStyle: {},
                ScoreStyle: {},
                ButtonOneStyle: {},
                ButtonOneHoverStyle: {},
                ButtonSecondStyle: {},
                ButtonSecondHoverStyle: {}
            },
            ErrorScreen: {
                GlobalStyle: {},
                LabelStyle: {},
                ScoreStyle: {},
                ButtonOneStyle: {},
                ButtonOneHoverStyle: {},
                ButtonSecondStyle: {},
                ButtonSecondHoverStyle: {}
            },
            StartScreen: {
                GlobalStyle: {},
                LabelStyle: {},
                ScoreStyle: {},
                ButtonOneStyle: {},
                ButtonOneHoverStyle: {},
                ButtonSecondStyle: {},
                ButtonSecondHoverStyle: {}
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.Name) {

            setTimeout(() => {
                props.initialize({
                    Name: props.Name
                });
            }, 500);
            state.CustomStyle = props.CustomStyle;
            state.initialize = true;
        }
        return state;
    }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setScreen = screen => {
        this.setState({
            screen
        });
    }

    showSplashScreen = () => {
        this.setState({
            showSplashScreen: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    showSplashScreen: false
                });
            }, this.state.CustomStyle.IntroScreen.GlobalStyle.SplashTime ? parseInt(this.state.CustomStyle.IntroScreen.GlobalStyle.SplashTime) * 1000 : 4000);
        });
    }

    UpdateCustomStyle = NewCustomStyle => {
        if (this.state.screen === 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    DashBoard: {
                        ...NewCustomStyle,
                    }
                }
            });
        } else if (this.state.screen === 2) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    WinScreen: {
                        ...NewCustomStyle,
                    }
                }
            });
        } else if (this.state.screen === 3) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ErrorScreen: {
                        ...NewCustomStyle,
                    }
                }
            });
        } else if (this.state.screen === 4) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    StopScreen: {
                        ...NewCustomStyle,
                    }
                }
            });
        } else if (this.state.screen === 5) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    StartScreen: {
                        ...NewCustomStyle,
                    }
                }
            });
        } else if (this.state.screen === 6) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    IntroScreen: {
                        ...NewCustomStyle,
                    }
                }
            });
        }
    }

    CopyStyle = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.state.screen === 1) {
            this.setState({
                CopyStyle: this.state.CustomStyle.DashBoard
            });
        } else if (this.state.screen === 2) {
            this.setState({
                CopyStyle: this.state.CustomStyle.WinScreen,
            });
        } else if (this.state.screen === 3) {
            this.setState({
                CopyStyle: this.state.CustomStyle.ErrorScreen
            });
        } else if (this.state.screen === 4) {
            this.setState({
                CopyStyle: this.state.CustomStyle.StopScreen
            });
        } else if (this.state.screen === 5) {
            this.setState({
                CopyStyle: this.state.CustomStyle.StartScreen
            });
        } else if (this.state.screen === 6) {
            this.setState({
                CopyStyle: this.state.CustomStyle.IntroScreen
            });
        }

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-exclamation-triangle"></i>
                    {t('Pages.Templates.copy_to_clipboard.Title')}
                </div>
                <div className="h6">{t('Pages.Templates.copy_to_clipboard.Body')}
                </div>
            </div>,
            type: toast.TYPE.INFO,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    }

    PasteStyle = () => {
        const { t } = this.props;

        if (this.state.CopyStyle) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('CopyProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.PasteStyleConfirm(); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-paste"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    PasteStyleConfirm = () => {
        const { t } = this.props;

        if (this.state.screen === 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    DashBoard: { ...this.state.CopyStyle }
                }
            });
        } else if (this.state.screen === 2) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    WinScreen: { ...this.state.CopyStyle }
                }
            });
        } else if (this.state.screen === 3) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    ErrorScreen: { ...this.state.CopyStyle }
                }
            });
        } else if (this.state.screen === 4) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    StopScreen: { ...this.state.CopyStyle }
                }
            });
        } else if (this.state.screen === 5) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    StartScreen: { ...this.state.CopyStyle }
                }
            });
        } else if (this.state.screen === 6) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    IntroScreen: { ...this.state.CopyStyle }
                }
            });
        }

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-paste"></i>
                    {t('Pages.Templates.paste_style.Title')}
                </div>
                <div className="h6">{t('Pages.Templates.paste_style.Body')}
                </div>
            </div>,
            type: toast.TYPE.DARK,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    }

    validate = () => {
        const errors = {};

        if (!this.Name.ref.current.getValue()) {
            errors.Name = 'Pages.Templates.form.Title.error';
        }
        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                Name: this.Name.ref.current.getValue(),
                CustomStyle: this.state.CustomStyle
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);

        }
        e.preventDefault();
    }

    renderPlayButton = () => {
        const IntroScreen = this.state.CustomStyle.IntroScreen;
        if (IntroScreen && IntroScreen.GlobalStyle) {

            let Cursor = "";
            let buttonStyle = "";
            let buttonStyleHover = "";
            if (IntroScreen.GlobalStyle) {

                Cursor = "";
                if (IntroScreen.GlobalStyle.Cursor !== 'custom' && IntroScreen.GlobalStyle.Cursor !== 'none' && IntroScreen.GlobalStyle.Cursor) {
                    Cursor = process.env.PUBLIC_URL + IntroScreen.GlobalStyle.Cursor;
                } else if (IntroScreen.GlobalStyle.Cursor === 'custom' && IntroScreen.GlobalStyle.CursorCustom) {
                    Cursor = IntroScreen.GlobalStyle.CursorCustom;
                }

                buttonStyle = `max-width: 100%;
                            user-select: none;
                            transition-property: all;
                            transition-duration: 0.35s;
                            transition-timing-function: ease-in-out;
                            cursor: url('${Cursor}') 20 20, auto;
                            color: ${IntroScreen.GlobalStyle.FontColor};
                            padding-left: ${IntroScreen.GlobalStyle.PaddingLeft}px;
                            padding-top: ${IntroScreen.GlobalStyle.PaddingTop}px;
                            padding-right: ${IntroScreen.GlobalStyle.PaddingRight}px;
                            padding-bottom: ${IntroScreen.GlobalStyle.PaddingBottom}px;
                            opacity: ${IntroScreen.GlobalStyle.Opacity}%;
                            background-color: ${IntroScreen.GlobalStyle.backgroundColor !== 'transparent' ? IntroScreen.GlobalStyle.backgroundColor : ""};
                            background-image: ${IntroScreen.GlobalStyle.backgroundImage !== '' ? `url('${IntroScreen.GlobalStyle.backgroundImage}')` : ""};
                            background-position-x: ${IntroScreen.GlobalStyle.BgPositionX}%;
                            background-position-y: ${IntroScreen.GlobalStyle.BgPositionY}%;
                            background-size: ${IntroScreen.GlobalStyle.BgSize};
                            background-repeat: ${IntroScreen.GlobalStyle.BgRepeat};
                            background-attachment: ${IntroScreen.GlobalStyle.BgAttachment};
                            border-color: ${IntroScreen.GlobalStyle.BorderColor !== 'transparent' ? IntroScreen.GlobalStyle.BorderColor : ""};
                            border-style: ${IntroScreen.GlobalStyle.BorderStyle};
                            border-left-width: ${IntroScreen.GlobalStyle.BorderWidthLeft}px;
                            border-right-width: ${IntroScreen.GlobalStyle.BorderWidthRight}px;
                            border-top-width: ${IntroScreen.GlobalStyle.BorderWidthTop}px;
                            border-bottom-width: ${IntroScreen.GlobalStyle.BorderWidthBottom}px;
                            border-radius: ${IntroScreen.GlobalStyle.BorderRadiusTop}${IntroScreen.GlobalStyle.BorderRadiusUnit} ${IntroScreen.GlobalStyle.BorderRadiusRight}${IntroScreen.GlobalStyle.BorderRadiusUnit} ${IntroScreen.GlobalStyle.BorderRadiusBottom}${IntroScreen.GlobalStyle.BorderRadiusUnit} ${IntroScreen.GlobalStyle.BorderRadiusLeft}${IntroScreen.GlobalStyle.BorderRadiusUnit};
                            box-shadow: ${IntroScreen.GlobalStyle.ShadowPositionX}px ${IntroScreen.GlobalStyle.ShadowPositionY}px ${IntroScreen.GlobalStyle.ShadowRadius}px 0px ${IntroScreen.GlobalStyle.shadowColor};
                            ${IntroScreen.GlobalStyle.Custom} `;

                buttonStyleHover = `max-width: 100%;
                            color: ${IntroScreen.LabelStyle.FontColor};
                            cursor: url('${Cursor}') 20 20, auto;
                            padding-left: ${IntroScreen.LabelStyle.PaddingLeft}px;
                            padding-top: ${IntroScreen.LabelStyle.PaddingTop}px;
                            padding-right: ${IntroScreen.LabelStyle.PaddingRight}px;
                            padding-bottom: ${IntroScreen.LabelStyle.PaddingBottom}px;
                            opacity: ${IntroScreen.LabelStyle.Opacity}%;
                            background-color: ${IntroScreen.LabelStyle.backgroundColor !== 'transparent' ? IntroScreen.LabelStyle.backgroundColor : ""};
                            background-image: ${IntroScreen.LabelStyle.backgroundImage !== '' ? `url('${IntroScreen.LabelStyle.backgroundImage}')` : ""};
                            background-position-x: ${IntroScreen.LabelStyle.BgPositionX}%;
                            background-position-y: ${IntroScreen.LabelStyle.BgPositionY}%;
                            background-size: ${IntroScreen.LabelStyle.BgSize};
                            background-repeat: ${IntroScreen.LabelStyle.BgRepeat};
                            background-attachment: ${IntroScreen.LabelStyle.BgAttachment};
                            border-color: ${IntroScreen.LabelStyle.BorderColor !== 'transparent' ? IntroScreen.LabelStyle.BorderColor : ""};
                            border-style: ${IntroScreen.LabelStyle.BorderStyle};
                            border-left-width: ${IntroScreen.LabelStyle.BorderWidthLeft}px;
                            border-right-width: ${IntroScreen.LabelStyle.BorderWidthRight}px;
                            border-top-width: ${IntroScreen.LabelStyle.BorderWidthTop}px;
                            border-bottom-width: ${IntroScreen.LabelStyle.BorderWidthBottom}px;
                            border-radius: ${IntroScreen.LabelStyle.BorderRadiusTop}${IntroScreen.LabelStyle.BorderRadiusUnit} ${IntroScreen.LabelStyle.BorderRadiusRight}${IntroScreen.LabelStyle.BorderRadiusUnit} ${IntroScreen.LabelStyle.BorderRadiusBottom}${IntroScreen.LabelStyle.BorderRadiusUnit} ${IntroScreen.LabelStyle.BorderRadiusLeft}${IntroScreen.LabelStyle.BorderRadiusUnit};
                            box-shadow: ${IntroScreen.LabelStyle.ShadowPositionX}px ${IntroScreen.LabelStyle.ShadowPositionY}px ${IntroScreen.LabelStyle.ShadowRadius}px 0px ${IntroScreen.LabelStyle.shadowColor};
                            ${IntroScreen.LabelStyle.Custom} `;
            }

            let menuButtonStyle = "";
            let menuButtonStyleHover = "";
            if (IntroScreen.ButtonOneStyle) {
                menuButtonStyle = `
                            z-index:999;
                            user-select: none;
                            transition-property: all;
                            transition-duration: 0.35s;
                            transition-timing-function: ease-in-out;
                            color: ${IntroScreen.ButtonOneStyle.FontColor};
                            width: ${IntroScreen.ButtonOneStyle.width}px;
                            height: ${IntroScreen.ButtonOneStyle.height}px;
                            padding-left: ${IntroScreen.ButtonOneStyle.PaddingLeft}px;
                            padding-top: ${IntroScreen.ButtonOneStyle.PaddingTop}px;
                            padding-right: ${IntroScreen.ButtonOneStyle.PaddingRight}px;
                            padding-bottom: ${IntroScreen.ButtonOneStyle.PaddingBottom}px;
                            opacity: ${IntroScreen.ButtonOneStyle.Opacity}%;
                            background-color: ${IntroScreen.ButtonOneStyle.backgroundColor !== 'transparent' ? IntroScreen.ButtonOneStyle.backgroundColor : ""};
                            background-image: ${IntroScreen.ButtonOneStyle.backgroundImage !== '' ? `url('${IntroScreen.ButtonOneStyle.backgroundImage}')` : ""};
                            background-position-x: ${IntroScreen.ButtonOneStyle.BgPositionX}%;
                            background-position-y: ${IntroScreen.ButtonOneStyle.BgPositionY}%;
                            background-size: ${IntroScreen.ButtonOneStyle.BgSize};
                            background-repeat: ${IntroScreen.ButtonOneStyle.BgRepeat};
                            background-attachment: ${IntroScreen.ButtonOneStyle.BgAttachment};
                            border-color: ${IntroScreen.ButtonOneStyle.BorderColor !== 'transparent' ? IntroScreen.ButtonOneStyle.BorderColor : ""};
                            border-style: ${IntroScreen.ButtonOneStyle.BorderStyle};
                            border-left-width: ${IntroScreen.ButtonOneStyle.BorderWidthLeft}px;
                            border-right-width: ${IntroScreen.ButtonOneStyle.BorderWidthRight}px;
                            border-top-width: ${IntroScreen.ButtonOneStyle.BorderWidthTop}px;
                            border-bottom-width: ${IntroScreen.ButtonOneStyle.BorderWidthBottom}px;
                            border-radius: ${IntroScreen.ButtonOneStyle.BorderRadiusTop}${IntroScreen.ButtonOneStyle.BorderRadiusUnit} ${IntroScreen.ButtonOneStyle.BorderRadiusRight}${IntroScreen.ButtonOneStyle.BorderRadiusUnit} ${IntroScreen.ButtonOneStyle.BorderRadiusBottom}${IntroScreen.ButtonOneStyle.BorderRadiusUnit} ${IntroScreen.ButtonOneStyle.BorderRadiusLeft}${IntroScreen.ButtonOneStyle.BorderRadiusUnit};
                            box-shadow: ${IntroScreen.ButtonOneStyle.ShadowPositionX}px ${IntroScreen.ButtonOneStyle.ShadowPositionY}px ${IntroScreen.ButtonOneStyle.ShadowRadius}px 0px ${IntroScreen.ButtonOneStyle.shadowColor};
                            ${IntroScreen.ButtonOneStyle.Custom} `;

                Cursor = "";
                if (IntroScreen.ButtonOneHoverStyle.Cursor !== 'custom' && IntroScreen.ButtonOneHoverStyle.Cursor !== 'none' && IntroScreen.ButtonOneHoverStyle.Cursor) {
                    Cursor = process.env.PUBLIC_URL + IntroScreen.ButtonOneHoverStyle.Cursor;
                } else if (IntroScreen.ButtonOneHoverStyle.Cursor === 'custom' && IntroScreen.ButtonOneHoverStyle.CursorCustom) {
                    Cursor = IntroScreen.ButtonOneHoverStyle.CursorCustom;
                }
                menuButtonStyleHover = `
                            width: ${IntroScreen.ButtonOneHoverStyle.width}px;
                            height: ${IntroScreen.ButtonOneHoverStyle.height}px;
                            color: ${IntroScreen.ButtonOneHoverStyle.FontColor};
                            cursor: url('${Cursor}') 20 20, auto;
                            padding-left: ${IntroScreen.ButtonOneHoverStyle.PaddingLeft}px;
                            padding-top: ${IntroScreen.ButtonOneHoverStyle.PaddingTop}px;
                            padding-right: ${IntroScreen.ButtonOneHoverStyle.PaddingRight}px;
                            padding-bottom: ${IntroScreen.ButtonOneHoverStyle.PaddingBottom}px;
                            opacity: ${IntroScreen.ButtonOneHoverStyle.Opacity}%;
                            background-color: ${IntroScreen.ButtonOneHoverStyle.backgroundColor !== 'transparent' ? IntroScreen.ButtonOneHoverStyle.backgroundColor : ""};
                            background-image: ${IntroScreen.ButtonOneHoverStyle.backgroundImage !== '' ? `url('${IntroScreen.ButtonOneHoverStyle.backgroundImage}')` : ""};
                            background-position-x: ${IntroScreen.ButtonOneHoverStyle.BgPositionX}%;
                            background-position-y: ${IntroScreen.ButtonOneHoverStyle.BgPositionY}%;
                            background-size: ${IntroScreen.ButtonOneHoverStyle.BgSize};
                            background-repeat: ${IntroScreen.ButtonOneHoverStyle.BgRepeat};
                            background-attachment: ${IntroScreen.ButtonOneHoverStyle.BgAttachment};
                            border-color: ${IntroScreen.ButtonOneHoverStyle.BorderColor !== 'transparent' ? IntroScreen.ButtonOneHoverStyle.BorderColor : ""};
                            border-style: ${IntroScreen.ButtonOneHoverStyle.BorderStyle};
                            border-left-width: ${IntroScreen.ButtonOneHoverStyle.BorderWidthLeft}px;
                            border-right-width: ${IntroScreen.ButtonOneHoverStyle.BorderWidthRight}px;
                            border-top-width: ${IntroScreen.ButtonOneHoverStyle.BorderWidthTop}px;
                            border-bottom-width: ${IntroScreen.ButtonOneHoverStyle.BorderWidthBottom}px;
                            border-radius: ${IntroScreen.ButtonOneHoverStyle.BorderRadiusTop}${IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit} ${IntroScreen.ButtonOneHoverStyle.BorderRadiusRight}${IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit} ${IntroScreen.ButtonOneHoverStyle.BorderRadiusBottom}${IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit} ${IntroScreen.ButtonOneHoverStyle.BorderRadiusLeft}${IntroScreen.ButtonOneHoverStyle.BorderRadiusUnit};
                            box-shadow: ${IntroScreen.ButtonOneHoverStyle.ShadowPositionX}px ${IntroScreen.ButtonOneHoverStyle.ShadowPositionY}px ${IntroScreen.ButtonOneHoverStyle.ShadowRadius}px 0px ${IntroScreen.ButtonOneHoverStyle.shadowColor};
                            ${IntroScreen.ButtonOneHoverStyle.Custom} `;
            }

            const Name = this.Name && this.Name.ref && this.Name.ref.current ? this.Name.ref.current.getValue() : this.props.t(`Pages.Templates.form.Title.title`);

            return <>
                <style dangerouslySetInnerHTML={{ __html: `@keyframes objectMovment-${this.state.Id} { 0% {visibility:visible;transform-origin:center; transform:  scale(0.95) rotate(0deg); opacity:1; top :  5%; right :0% }50% {visibility:visible;transform-origin:center; transform:  scale(1.1) rotate(0deg); opacity:1; top :  2%; right :0% }100% {visibility:visible;transform-origin:center; transform:  scale(0.95) rotate(0deg); opacity:1; top :  5%; right :0% } } #playbutton_${this.state.Id}{${buttonStyle}} #playbutton_${this.state.Id}:hover{${buttonStyleHover}} .custom-menu-button_${this.state.Id}{${menuButtonStyle}} .custom-menu-button_${this.state.Id}:hover{${menuButtonStyleHover}}` }}></style>
                <div className={` ${this.state.showSplashScreen === true ? "goOut" : ""} w-100 position-absolute h-100 `} >

                    <div className={`position-absolute align-items-center justify-content-center text-center m-3 d-flex`} style={{
                        left: 0,
                        zIndex: 9
                    }}>
                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button_${this.state.Id}`}>
                            <i className="fas fa-music"></i>
                        </div>
                        <div className={`mx-1 my-0 d-flex align-items-center justify-content-center text-center h3 custom-menu-button_${this.state.Id}`}>
                            <i className="fas fa-power-off"></i>
                        </div>
                    </div>

                    <div className='d-flex align-items-center h-100 flex-column' style={{
                        justifyContent: 'space-evenly'
                    }}>
                        <div className="display-3 game-font h3 w-100 text-center position-relative mb-0 ">
                            <div className={` objectMovment-${this.state.Id} w-100`} style={{
                                visibility: 'hidden',
                                animationName: `objectMovment-${this.state.Id}`,
                                animationIterationCount: 'infinite',
                                animationDuration: '60s',
                                animationFillMode: 'forwards',
                                animationTimingFunction: 'linear'
                            }}>
                                <div className="w-100 d-flex justify-content-center" >
                                    <div className=" w-50 d-flex justify-content-center" style={{
                                        visibility: 'hidden',
                                        animationName: `objectMovment-${this.state.Id}`,
                                        animationIterationCount: 'infinite',
                                        animationDuration: '30s',
                                        animationFillMode: 'forwards',
                                        animationTimingFunction: 'linear'
                                    }}>
                                        <img
                                            src="/assets/images/logo/game.png"
                                            className="w-50"
                                            alt={`Logo`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div id={`playbutton_${this.state.Id}`} className='m-5' onClick={() => this.showSplashScreen()}>
                                <i style={{
                                    fontSize: `${IntroScreen.GlobalStyle.FontSize}pt`,
                                    color: `${IntroScreen.GlobalStyle.FontColor}`
                                }} className={`icon ml-3 ${IntroScreen.GlobalStyle.IconType} fa-${IntroScreen.GlobalStyle.Icon ? IntroScreen.GlobalStyle.Icon : 'play'}`} />
                            </div>
                        </div>

                        <div className="display-2 game-font h3 w-100 mb-5 text-center"
                            style={{
                                fontSize: `${IntroScreen.GlobalStyle.Size}pt`,
                                color: `${IntroScreen.GlobalStyle.Color}`,
                                filter: `
                                drop-shadow(${IntroScreen.GlobalStyle.StrokeWidth}px ${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})
                                drop-shadow(-${IntroScreen.GlobalStyle.StrokeWidth}px -${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})
                                drop-shadow(-${IntroScreen.GlobalStyle.StrokeWidth}px ${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})
                                drop-shadow(${IntroScreen.GlobalStyle.StrokeWidth}px -${IntroScreen.GlobalStyle.StrokeWidth}px 0px ${IntroScreen.GlobalStyle.StrokeColor})`
                            }}>
                            {Name}
                        </div>
                    </div>
                </div>
            </>
        }
        return "";
    }

    renderLogo = () => {
        let style = {};
        let styleBackground = {};
        let margin = 2;
        let Custom = "";
        let logoSrc = "/assets/images/logo/black.png";
        let position = {
            bottom: 0,
            right: 0,
            zIndex: 14
        };
        if (this.state.CustomStyle && this.state.CustomStyle.DashBoard) {
            if (this.state.CustomStyle.DashBoard.LogoStyle) {
                style = {
                    minHeight: `${this.state.CustomStyle.DashBoard.LogoStyle.height}px`,
                    width: `${this.state.CustomStyle.DashBoard.LogoStyle.width}%`,
                    maxWidth: '100%',
                    color: this.state.CustomStyle.DashBoard.LogoStyle.FontColor,
                    paddingLeft: `${this.state.CustomStyle.DashBoard.LogoStyle.PaddingLeft}px`,
                    paddingTop: `${this.state.CustomStyle.DashBoard.LogoStyle.PaddingTop}px`,
                    paddingRight: `${this.state.CustomStyle.DashBoard.LogoStyle.PaddingRight}px`,
                    paddingBottom: `${this.state.CustomStyle.DashBoard.LogoStyle.PaddingBottom}px`,
                    borderColor: this.state.CustomStyle.DashBoard.LogoStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.DashBoard.LogoStyle.BorderColor : "",
                    borderStyle: `${this.state.CustomStyle.DashBoard.LogoStyle.BorderStyle}`,
                    borderLeftWidth: `${this.state.CustomStyle.DashBoard.LogoStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${this.state.CustomStyle.DashBoard.LogoStyle.BorderWidthRight}px`,
                    borderTopWidth: `${this.state.CustomStyle.DashBoard.LogoStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${this.state.CustomStyle.DashBoard.LogoStyle.BorderWidthBottom}px`,
                    borderRadius: `${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusTop}${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusRight}${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusBottom}${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusLeft}${this.state.CustomStyle.DashBoard.LogoStyle.BorderRadiusUnit}`,
                    boxShadow: `${this.state.CustomStyle.DashBoard.LogoStyle.ShadowPositionX}px ${this.state.CustomStyle.DashBoard.LogoStyle.ShadowPositionY}px ${this.state.CustomStyle.DashBoard.LogoStyle.ShadowRadius}px 0px ${this.state.CustomStyle.DashBoard.LogoStyle.shadowColor}`,
                    marginBottom: `${this.state.CustomStyle.DashBoard.LogoStyle.Margin}px`,
                    zIndex: 9
                };

                styleBackground = {
                    height: `100%`,
                    opacity: `${this.state.CustomStyle.DashBoard.LogoStyle.Opacity}%`,
                    backgroundColor: this.state.CustomStyle.DashBoard.LogoStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.DashBoard.LogoStyle.backgroundColor : "",
                    backgroundImage: this.state.CustomStyle.DashBoard.LogoStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.DashBoard.LogoStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${this.state.CustomStyle.DashBoard.LogoStyle.BgPositionX}%`,
                    backgroundPositionY: `${this.state.CustomStyle.DashBoard.LogoStyle.BgPositionY}%`,
                    backgroundSize: `${this.state.CustomStyle.DashBoard.LogoStyle.BgSize}`,
                    backgroundRepeat: `${this.state.CustomStyle.DashBoard.LogoStyle.BgRepeat}`,
                    backgroundAttachment: `${this.state.CustomStyle.DashBoard.LogoStyle.BgAttachment}`,
                    marginLeft: `-${this.state.CustomStyle.DashBoard.LogoStyle.PaddingLeft}px`,
                    marginTop: `-${this.state.CustomStyle.DashBoard.LogoStyle.PaddingTop}px`,
                    marginRight: `-${this.state.CustomStyle.DashBoard.LogoStyle.PaddingRight}px`,
                    marginBottom: `-${this.state.CustomStyle.DashBoard.LogoStyle.PaddingBottom}px`,
                    zIndex: -1
                };
                Custom = this.state.CustomStyle.DashBoard.LogoStyle.Custom;

                if (this.state.CustomStyle.DashBoard.LogoStyle.CustomBackground) {
                    logoSrc = this.state.CustomStyle.DashBoard.LogoStyle.CustomBackground;
                }
                if (this.state.CustomStyle.DashBoard.LogoStyle.Margin) {
                    margin = this.state.CustomStyle.DashBoard.LogoStyle.Margin;
                }

                position = {
                    zIndex: 14
                };
                if (this.state.CustomStyle.DashBoard.LogoStyle.PositionX === "left") {
                    position = {
                        ...position,
                        left: 0
                    };
                } else {
                    position = {
                        ...position,
                        right: 0
                    };
                }

                if (this.state.CustomStyle.DashBoard.LogoStyle.PositionY === "top") {
                    position = {
                        ...position,
                        top: 0
                    };
                } else {
                    position = {
                        ...position,
                        bottom: 0
                    };
                }
            }
        }
        return <div className={`position-absolute m-${margin}`} id="game-sub-logo" style={position}>
            <div className={`position-relative overflow-hidden`} style={style}>
                <style dangerouslySetInnerHTML={{ __html: `#game-sub-logo{${Custom} }` }}></style>
                <div className="w-100 position-absolute" style={styleBackground} ></div>
                <img src={logoSrc} alt={``} />
            </div>
        </div>;
    }

    render() {
        const { submitting, t } = this.props;

        let style = {};
        let styleBackground = {};
        let align = "x";
        let ControlColor = "custom";
        let MainColor = "";
        let SecondColor = "";
        let FontColor = "";
        let SecondControlColor = "custom";
        let SecondMainColor = "";
        let SecondSecondColor = "";
        let SecondFontColor = "";
        let Repeat = "";
        let Cursor = "";
        let GlobalStyleCustom = "";

        let LabelStyleCustom = "";
        let styleLabelStyle = {};
        let styleBackgroundLabelStyle = {};
        let alignLabelStyle = "x";
        let FontColorLabelStyle = "";
        let MarginBottomLabelStyle = 0;

        let IconType = "fas";
        let Icon = "caret";
        let IconColor = "";
        let IconCustomColor = "";
        let IconHoverColor = "";
        let IconPosition = "in";

        if (this.state.CustomStyle && this.state.CustomStyle.DashBoard) {
            if (this.state.CustomStyle.DashBoard.GlobalStyle) {
                style = {
                    minHeight: `${this.state.CustomStyle.DashBoard.GlobalStyle.height}px`,
                    width: `${this.state.CustomStyle.DashBoard.GlobalStyle.width}%`,
                    maxWidth: '100%',
                    paddingLeft: `${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingLeft}px`,
                    paddingTop: `${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingTop}px`,
                    paddingRight: `${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingRight}px`,
                    paddingBottom: `${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingBottom}px`,
                    borderColor: this.state.CustomStyle.DashBoard.GlobalStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.DashBoard.GlobalStyle.BorderColor : "",
                    borderStyle: `${this.state.CustomStyle.DashBoard.GlobalStyle.BorderStyle}`,
                    borderLeftWidth: `${this.state.CustomStyle.DashBoard.GlobalStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${this.state.CustomStyle.DashBoard.GlobalStyle.BorderWidthRight}px`,
                    borderTopWidth: `${this.state.CustomStyle.DashBoard.GlobalStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${this.state.CustomStyle.DashBoard.GlobalStyle.BorderWidthBottom}px`,
                    borderRadius: `${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusTop}${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusRight}${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusBottom}${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusLeft}${this.state.CustomStyle.DashBoard.GlobalStyle.BorderRadiusUnit}`,
                    boxShadow: `${this.state.CustomStyle.DashBoard.GlobalStyle.ShadowPositionX}px ${this.state.CustomStyle.DashBoard.GlobalStyle.ShadowPositionY}px ${this.state.CustomStyle.DashBoard.GlobalStyle.ShadowRadius}px 0px ${this.state.CustomStyle.DashBoard.GlobalStyle.shadowColor}`,
                };
                if (this.state.CustomStyle.DashBoard.GlobalStyle.AnswersAlign) {
                    if (this.state.CustomStyle.DashBoard.GlobalStyle.AnswersAlign === 'left') {
                        align = "l";
                    } else if (this.state.CustomStyle.DashBoard.GlobalStyle.AnswersAlign === 'right') {
                        align = "r";
                    }
                }
                if (this.state.CustomStyle.DashBoard.GlobalStyle.ControlColor) {
                    ControlColor = this.state.CustomStyle.DashBoard.GlobalStyle.ControlColor;
                }
                if (this.state.CustomStyle.DashBoard.GlobalStyle.ControlColor === "custom") {
                    MainColor = this.state.CustomStyle.DashBoard.GlobalStyle.MainColor;
                    FontColor = this.state.CustomStyle.DashBoard.GlobalStyle.FontColor;
                    SecondColor = this.state.CustomStyle.DashBoard.GlobalStyle.SecondColor;
                }

                if (this.state.CustomStyle.DashBoard.GlobalStyle.SecondControlColor) {
                    SecondControlColor = this.state.CustomStyle.DashBoard.GlobalStyle.SecondControlColor;
                }
                if (this.state.CustomStyle.DashBoard.GlobalStyle.SecondControlColor === "custom") {
                    SecondMainColor = this.state.CustomStyle.DashBoard.GlobalStyle.SecondMainColor;
                    SecondFontColor = this.state.CustomStyle.DashBoard.GlobalStyle.SecondFontColor;
                    SecondSecondColor = this.state.CustomStyle.DashBoard.GlobalStyle.SecondSecondColor;
                }
                Repeat = this.state.CustomStyle.DashBoard.GlobalStyle.Repeat;

                styleBackground = {
                    height: `100%`,
                    opacity: `${this.state.CustomStyle.DashBoard.GlobalStyle.Opacity}%`,
                    backgroundColor: this.state.CustomStyle.DashBoard.GlobalStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.DashBoard.GlobalStyle.backgroundColor : "",
                    backgroundImage: this.state.CustomStyle.DashBoard.GlobalStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.DashBoard.GlobalStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${this.state.CustomStyle.DashBoard.GlobalStyle.BgPositionX}%`,
                    backgroundPositionY: `${this.state.CustomStyle.DashBoard.GlobalStyle.BgPositionY}%`,
                    backgroundSize: `${this.state.CustomStyle.DashBoard.GlobalStyle.BgSize}`,
                    backgroundRepeat: `${this.state.CustomStyle.DashBoard.GlobalStyle.BgRepeat}`,
                    backgroundAttachment: `${this.state.CustomStyle.DashBoard.GlobalStyle.BgAttachment}`,
                    marginLeft: `-${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingLeft}px`,
                    marginTop: `-${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingTop}px`,
                    marginRight: `-${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingRight}px`,
                    marginBottom: `-${this.state.CustomStyle.DashBoard.GlobalStyle.PaddingBottom}px`,
                };

                if (this.state.CustomStyle.DashBoard.GlobalStyle.Cursor !== 'custom' && this.state.CustomStyle.DashBoard.GlobalStyle.Cursor !== 'none' && this.state.CustomStyle.DashBoard.GlobalStyle.Cursor) {
                    Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.DashBoard.GlobalStyle.Cursor;
                } else if (this.state.CustomStyle.DashBoard.GlobalStyle.Cursor === 'custom' && this.state.CustomStyle.DashBoard.GlobalStyle.CursorCustom) {
                    Cursor = this.state.CustomStyle.DashBoard.GlobalStyle.CursorCustom;
                }

                if (this.state.CustomStyle.DashBoard.GlobalStyle.IconPosition) {
                    IconPosition = this.state.CustomStyle.DashBoard.GlobalStyle.IconPosition;
                }
                if (this.state.CustomStyle.DashBoard.GlobalStyle.Icon) {
                    Icon = this.state.CustomStyle.DashBoard.GlobalStyle.Icon;
                }
                if (this.state.CustomStyle.DashBoard.GlobalStyle.IconType) {
                    IconType = this.state.CustomStyle.DashBoard.GlobalStyle.IconType;
                }

                if (this.state.CustomStyle.DashBoard.GlobalStyle.IconHoverColor) {
                    IconHoverColor = this.state.CustomStyle.DashBoard.GlobalStyle.IconHoverColor;
                }
                if (this.state.CustomStyle.DashBoard.GlobalStyle.IconColor === "custom" && this.state.CustomStyle.DashBoard.GlobalStyle.IconCustomColor) {
                    IconCustomColor = this.state.CustomStyle.DashBoard.GlobalStyle.IconCustomColor;
                } else if (this.state.CustomStyle.DashBoard.GlobalStyle.IconColor) {
                    IconColor = this.state.CustomStyle.DashBoard.GlobalStyle.IconColor;
                }
                GlobalStyleCustom = this.state.CustomStyle.DashBoard.GlobalStyle.Custom;
            }
            if (this.state.CustomStyle.DashBoard.LabelStyle) {
                styleLabelStyle = {
                    minHeight: `${this.state.CustomStyle.DashBoard.LabelStyle.height}px`,
                    width: `${this.state.CustomStyle.DashBoard.LabelStyle.width}%`,
                    maxWidth: '100%',
                    color: this.state.CustomStyle.DashBoard.LabelStyle.FontColor,
                    paddingLeft: `${this.state.CustomStyle.DashBoard.LabelStyle.PaddingLeft}px`,
                    paddingTop: `${this.state.CustomStyle.DashBoard.LabelStyle.PaddingTop}px`,
                    paddingRight: `${this.state.CustomStyle.DashBoard.LabelStyle.PaddingRight}px`,
                    paddingBottom: `${this.state.CustomStyle.DashBoard.LabelStyle.PaddingBottom}px`,
                    borderColor: this.state.CustomStyle.DashBoard.LabelStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.DashBoard.LabelStyle.BorderColor : "",
                    borderStyle: `${this.state.CustomStyle.DashBoard.LabelStyle.BorderStyle}`,
                    borderLeftWidth: `${this.state.CustomStyle.DashBoard.LabelStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${this.state.CustomStyle.DashBoard.LabelStyle.BorderWidthRight}px`,
                    borderTopWidth: `${this.state.CustomStyle.DashBoard.LabelStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${this.state.CustomStyle.DashBoard.LabelStyle.BorderWidthBottom}px`,
                    borderRadius: `${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusTop}${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusRight}${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusBottom}${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusUnit} ${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusLeft}${this.state.CustomStyle.DashBoard.LabelStyle.BorderRadiusUnit}`,
                    boxShadow: `${this.state.CustomStyle.DashBoard.LabelStyle.ShadowPositionX}px ${this.state.CustomStyle.DashBoard.LabelStyle.ShadowPositionY}px ${this.state.CustomStyle.DashBoard.LabelStyle.ShadowRadius}px 0px ${this.state.CustomStyle.DashBoard.LabelStyle.shadowColor}`,
                    marginBottom: `${this.state.CustomStyle.DashBoard.LabelStyle.Margin}px`,
                    zIndex: 9
                };
                if (this.state.CustomStyle.DashBoard.LabelStyle.AnswersAlign) {
                    if (this.state.CustomStyle.DashBoard.LabelStyle.AnswersAlign === 'left') {
                        alignLabelStyle = "l";
                    } else if (this.state.CustomStyle.DashBoard.LabelStyle.AnswersAlign === 'right') {
                        alignLabelStyle = "r";
                    }
                }
                FontColorLabelStyle = this.state.CustomStyle.DashBoard.LabelStyle.FontColor;
                MarginBottomLabelStyle = this.state.CustomStyle.DashBoard.LabelStyle.MarginBottom;
                styleBackgroundLabelStyle = {
                    height: `100%`,
                    opacity: `${this.state.CustomStyle.DashBoard.LabelStyle.Opacity}%`,
                    backgroundColor: this.state.CustomStyle.DashBoard.LabelStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.DashBoard.LabelStyle.backgroundColor : "",
                    backgroundImage: this.state.CustomStyle.DashBoard.LabelStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.DashBoard.LabelStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${this.state.CustomStyle.DashBoard.LabelStyle.BgPositionX}%`,
                    backgroundPositionY: `${this.state.CustomStyle.DashBoard.LabelStyle.BgPositionY}%`,
                    backgroundSize: `${this.state.CustomStyle.DashBoard.LabelStyle.BgSize}`,
                    backgroundRepeat: `${this.state.CustomStyle.DashBoard.LabelStyle.BgRepeat}`,
                    backgroundAttachment: `${this.state.CustomStyle.DashBoard.LabelStyle.BgAttachment}`,
                    marginLeft: `-${this.state.CustomStyle.DashBoard.LabelStyle.PaddingLeft}px`,
                    marginTop: `-${this.state.CustomStyle.DashBoard.LabelStyle.PaddingTop}px`,
                    marginRight: `-${this.state.CustomStyle.DashBoard.LabelStyle.PaddingRight}px`,
                    marginBottom: `-${this.state.CustomStyle.DashBoard.LabelStyle.PaddingBottom}px`,
                    zIndex: -1
                };
                LabelStyleCustom = this.state.CustomStyle.DashBoard.LabelStyle.Custom;
            }
        }

        let Background = "";
        if (this.Background && this.Background.ref && this.Background.ref.current) {
            Background = this.Background.ref.current.getValue();
        }

        if (this.state.screen === 6) {
            Cursor = "";
            if (this.state.CustomStyle.IntroScreen && this.state.CustomStyle.IntroScreen.GlobalStyle) {
                if (this.state.CustomStyle.IntroScreen.GlobalStyle.Cursor !== 'custom' && this.state.CustomStyle.IntroScreen.GlobalStyle.Cursor !== 'none' && this.state.CustomStyle.IntroScreen.GlobalStyle.Cursor) {
                    Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.IntroScreen.GlobalStyle.Cursor;
                } else if (this.state.CustomStyle.IntroScreen.GlobalStyle.Cursor === 'custom' && this.state.CustomStyle.IntroScreen.GlobalStyle.CursorCustom) {
                    Cursor = this.state.CustomStyle.IntroScreen.GlobalStyle.CursorCustom;
                }
            }
        } else if (this.state.screen === 1) {
            if (this.state.CustomStyle.DashBoard.GlobalStyle.CustomBackground) {
                Background = this.state.CustomStyle.DashBoard.GlobalStyle.CustomBackground;
            }
        }

        return (
            <>
                <form method="POST" id="GameTemplatesForm" className="contact-form" autoComplete="new-password" >

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Name"> <i className="icon fas fa-heading"></i> {t(`Pages.Templates.form.Title.title`)} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <Field
                            ref={Name => this.Name = Name}
                            id="Name"
                            name="Name"
                            component={this.renderField}
                            type="text"
                            required={true}
                            placeholder={t(`Pages.Templates.form.Title.placeholder`)}
                            title={t(`Pages.Templates.form.Title.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="bg-white w-100 p-1 mb-2"></div>

                    <div className="w-100">
                        <ul className="filter mb-2">
                            <li>
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.screen === 6 ? true : false}
                                        onChange={() => this.setScreen(6)}
                                        className="custom-control-input"
                                        title={t('Pages.Games.Screen.main')}
                                        name="screen"
                                        id="screen-main" />
                                    <label className="custom-control-label pt-1" htmlFor="screen-main" >
                                        <i className="icon fad fa-home"></i>
                                        {t('Pages.Games.Screen.main')}
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.screen === 1 ? true : false}
                                        onChange={() => this.setScreen(1)}
                                        className="custom-control-input"
                                        title={t('Pages.Games.Screen.levels')}
                                        name="screen"
                                        id="screen-levels" />
                                    <label className="custom-control-label pt-1" htmlFor="screen-levels" >
                                        <i className="icon fad fa-star"></i>
                                        {t('Pages.Games.Screen.levels')}
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.screen === 5 ? true : false}
                                        onChange={() => this.setScreen(5)}
                                        title={t('Pages.Games.Screen.level')}
                                        className="custom-control-input"
                                        name="screen"
                                        id="screen-start" />
                                    <label className="custom-control-label pt-1" htmlFor="screen-start" >
                                        <i className="icon fad fa-question"></i>
                                        {t('Pages.Games.Screen.level')}
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.screen === 2 ? true : false}
                                        onChange={() => this.setScreen(2)}
                                        title={t('Pages.Games.Screen.win')}
                                        className="custom-control-input"
                                        name="screen"
                                        id="screen-win" />
                                    <label className="custom-control-label pt-1" htmlFor="screen-win" >
                                        <i className="icon fad fa-check"></i>
                                        {t('Pages.Games.Screen.win')}
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.screen === 3 ? true : false}
                                        onChange={() => this.setScreen(3)}
                                        title={t('Pages.Games.Screen.error')}
                                        className="custom-control-input"
                                        name="screen"
                                        id="screen-error" />
                                    <label className="custom-control-label pt-1" htmlFor="screen-error" >
                                        <i className="icon fad fa-exclamation"></i>
                                        {t('Pages.Games.Screen.error')}
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                    <input
                                        type='radio'
                                        checked={this.state.screen === 4 ? true : false}
                                        onChange={() => this.setScreen(4)}
                                        title={t('Pages.Games.Screen.stop')}
                                        className="custom-control-input"
                                        name="screen"
                                        id="screen-stop" />
                                    <label className="custom-control-label pt-1" htmlFor="screen-stop" >
                                        <i className="icon fad fa-stopwatch"></i>
                                        {t('Pages.Games.Screen.stop')}
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex justify-content-around'>
                                    <div title={t('Pages.Lessons.Questions.forms.tools.copyStyleButton')} onClick={() => this.CopyStyle()}>
                                        <i className="fad fa-copy text-warning" style={{
                                            fontSize: '24px',
                                            verticalAlign: 'sub'
                                        }}></i>
                                    </div>
                                    {
                                        this.state.CopyStyle ?
                                            <div title={t('Pages.Lessons.Questions.forms.tools.pasteStyle')} onClick={() => this.PasteStyle()}>
                                                <i className="fad fa-paste" style={{
                                                    fontSize: '24px',
                                                    verticalAlign: 'sub'
                                                }}></i>
                                            </div>
                                            : ""
                                    }

                                </div>
                            </li>
                        </ul>
                    </div>


                    {
                        this.state.screen === 1 ?
                            <ScreenSetting typeId={this.state.screen} isStop={true} isDashBoard={true} CustomStyle={this.state.CustomStyle.DashBoard} update={this.UpdateCustomStyle} />
                            : ""
                    }
                    {
                        this.state.screen === 2 ?
                            <ScreenSetting typeId={this.state.screen} CustomStyle={this.state.CustomStyle.WinScreen} update={this.UpdateCustomStyle} />
                            : ""
                    }
                    {
                        this.state.screen === 3 ?
                            <ScreenSetting typeId={this.state.screen} CustomStyle={this.state.CustomStyle.ErrorScreen} update={this.UpdateCustomStyle} />
                            : ""
                    }
                    {
                        this.state.screen === 4 ?
                            <ScreenSetting typeId={this.state.screen} isStop={true} CustomStyle={this.state.CustomStyle.StopScreen} update={this.UpdateCustomStyle} />
                            : ""
                    }
                    {
                        this.state.screen === 5 ?
                            <ScreenSetting typeId={this.state.screen} CustomStyle={this.state.CustomStyle.StartScreen} update={this.UpdateCustomStyle} />
                            : ""
                    }
                    {
                        this.state.screen === 6 ?
                            <ScreenSetting typeId={this.state.screen} isStop={true} isIntroScreen={true} CustomStyle={this.state.CustomStyle.IntroScreen} update={this.UpdateCustomStyle} />
                            : ""
                    }
                    <div className={`w-100  mx-auto`} >
                        <div className="bg-white w-100 position-relative"  >
                            <div className="w-100 position-relative oh game-font" style={{
                                backgroundImage: `url("${Background}")`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100%",
                                minHeight: '600px',
                                backgroundPosition: 'center',
                                border: '10px solid #fff',
                                cursor: `url('${Cursor}') 20 20, auto`
                            }}>
                                {
                                    this.state.screen === 1 ?
                                        <>
                                            <MoveBackgroundObject CustomStyle={this.state.CustomStyle.DashBoard.GlobalStyle} Id={this.state.Id} />
                                            <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column" style={{
                                                zIndex: '15'
                                            }}>

                                                {this.renderLogo()}
                                                <div className="row d-flex flex-wrap justify-content-center">
                                                    <div className={`m${alignLabelStyle}-auto position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={styleLabelStyle}>
                                                        <div className="w-100 position-absolute" style={styleBackgroundLabelStyle} id={`SelectDesignerPreviewLabelStyle_${this.state.Id}`}> </div>
                                                        <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyle_${this.state.Id}{${LabelStyleCustom} }` }}></style>
                                                        <div className="h3" style={{
                                                            color: `${FontColorLabelStyle}`,
                                                            marginBottom: `${MarginBottomLabelStyle}px`
                                                        }}>
                                                            {t('Pages.Games.LevelSelection')}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" w-100 position-relative d-flex justify-content-around align-items-center" >

                                                    {
                                                        IconPosition === "out" ?
                                                            <div className="row d-flex w-100 h-100 align-items-center position-absolute justify-content-between" >
                                                                <div className="mx-2">
                                                                    <div className=" h1 custom-pointer" style={{
                                                                        fontSize: '6rem',
                                                                        zIndex: 9999
                                                                    }}
                                                                        title={t('Pages.Games.prev')}>
                                                                        <span style={{
                                                                            color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                        }}>
                                                                            <i className={`text-${IconColor} ${IconType} fa-${Icon}-right custom-hover`}></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-2">
                                                                    <div className=" h1 custom-pointer" style={{
                                                                        fontSize: '6rem',
                                                                        zIndex: 9999
                                                                    }}
                                                                        title={t('Pages.Games.next')}>
                                                                        <span style={{
                                                                            color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                        }}>
                                                                            <i className={`text-${IconColor} ${IconType} fa-${Icon}-left custom-hover`}></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    <div className={`m${align}-auto position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={style}>
                                                        <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${this.state.Id}`}> </div>
                                                        <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${this.state.Id}{${GlobalStyleCustom} }.level-button::before{background: ${MainColor};}.level-button::after{background: ${SecondColor};}.level-button span{color:${FontColor}}.custom-hover:hover{color:${IconHoverColor} !important;} .level-button-second::before{background: ${SecondMainColor};}.level-button-second::after{background: ${SecondSecondColor};}.level-button-second span{color:${SecondFontColor}}` }}></style>

                                                        <div className={`${IconPosition === "in" ? "d-flex w-100 justify-content-between" : ""}`}>

                                                            <div className={`mx-2 ${IconPosition === "in" ? "" : "d-none"}`} style={{
                                                                zIndex: 9999
                                                            }}>
                                                                <div className=" h1 custom-pointer" style={{
                                                                    fontSize: '6rem'
                                                                }}
                                                                    title={t('Pages.Games.prev')}>
                                                                    <span style={{
                                                                        color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                    }}>
                                                                        <i className={`text-${IconColor} ${IconType} fa-${Icon}-right custom-hover`}></i>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="row d-flex flex-wrap" style={{
                                                                justifyContent: 'space-evenly'
                                                            }}>
                                                                <div className="m-3">
                                                                    <div className={`custom-button ${ControlColor}-button level-button `}>
                                                                        <span>1</span>
                                                                    </div>
                                                                </div>

                                                                <div className="m-3">
                                                                    <div className={`custom-button ${SecondControlColor}-button level-button-second  `}>
                                                                        <span>{Repeat}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={`mx-2 ${IconPosition === "in" ? "" : "d-none"}`} style={{
                                                                zIndex: 9999
                                                            }}>
                                                                <div className=" h1 custom-pointer" style={{
                                                                    fontSize: '6rem'
                                                                }}
                                                                    title={t('Pages.Games.next')}>
                                                                    <span style={{
                                                                        color: IconCustomColor !== 'transparent' ? IconCustomColor : "",
                                                                    }}>
                                                                        <i className={`text-${IconColor} ${IconType} fa-${Icon}-left custom-hover`}></i>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                                {
                                    this.state.screen === 2 ?
                                        <ResultScreen
                                            type="success"
                                            reStartGame={() => {
                                                return false
                                            }}
                                            goBack={() => {
                                                return false
                                            }}
                                            goNext={() => {
                                                return false
                                            }}
                                            hasNext={true}
                                            starCount={2}
                                            Score={291300}
                                            CustomStyle={this.state.CustomStyle.WinScreen} />
                                        : ""
                                }
                                {
                                    this.state.screen === 3 ?
                                        <ResultScreen
                                            type="error"
                                            reStartGame={() => {
                                                return false
                                            }}
                                            goBack={() => {
                                                return false
                                            }}
                                            goNext={() => {
                                                return false
                                            }}
                                            hasNext={true}
                                            starCount={1}
                                            Score={50}
                                            CustomStyle={this.state.CustomStyle.ErrorScreen} />
                                        : ""
                                }
                                {
                                    this.state.screen === 4 ?
                                        <ResultScreen
                                            type="stop"
                                            reStartGame={() => {
                                                return false
                                            }}
                                            goBack={() => {
                                                return false
                                            }}
                                            Score={291300}
                                            CustomStyle={this.state.CustomStyle.StopScreen} />
                                        : ""
                                }
                                {
                                    this.state.screen === 5 ?
                                        <ResultScreen
                                            type="level"
                                            level={1}
                                            text={t('Pages.Games.SampleText')}
                                            reStartGame={() => {
                                                return false
                                            }}
                                            goBack={() => {
                                                return false
                                            }}
                                            Score={291300}
                                            CustomStyle={this.state.CustomStyle.StartScreen} />
                                        : ""
                                }
                                {
                                    this.state.screen === 6 ?
                                        <>
                                            <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/css/game.css?v=' + process.env.REACT_APP_VERSION} />
                                            {
                                                this.state.showSplashScreen === true ?
                                                    <MoveBackgroundObject CustomStyle={this.state.CustomStyle.IntroScreen.GlobalStyle.SplashGameObject} Id={`${this.state.Id}_x`} /> :
                                                    <MoveBackgroundObject CustomStyle={this.state.CustomStyle.IntroScreen.GlobalStyle} Id={this.state.Id} />
                                            }
                                            {this.renderPlayButton()}
                                        </>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>

                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group w-100 text-center">
                        <button className="custom-button" disabled={submitting} onClick={this.onSubmit}>
                            <span>
                                <i className="icon fal fa-save"></i> {t('Pages.ProfileEdit.Send')}
                            </span>
                        </button>
                    </div>

                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.Name) {
        errors.Name = 'Pages.Games.forms.LessonInfo.Name.error';
    }

    return errors;
}

const mapStateToProps = state => {
    return {
    };
};


export default connect(mapStateToProps, {})(withTranslation('common')(reduxForm({
    form: 'EditGameForm',
    validate
})(InfoForm)));
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import { Link } from "react-router-dom";

import Footer from "../Footer/FooterBottom";

class PricesSchool extends React.Component {

    constructor(props) {
        super(props);
        ReactPixel.init('484815450795869');
        ReactPixel.track('PageView', "PricesSchool");
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.Prices")} | {t('alarabeyya')}</title>
                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% );overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}.footer-bottom{display:none;}` }}></style>
                <section className="pos-rel call-in-action call-overlay oh" id="register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    backgroundAttachment: 'fixed',
                    minHeight: '100vh'
                }}>
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>

                    <div className="container h-100  pt-5">

                        <div className="row clearfix justify-content-center">

                            <div className="featured-block col-sm-6 " >
                                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                    <span className="border-one"></span>
                                    <span className="border-two"></span>

                                    <div className="d-flex justify-content-center pt-3" >
                                        <LazyLoadImage src="/assets/images/logo/login-logo.png" alt={`${t('alarabeyya')}`} />
                                    </div>

                                    <h5 className="cursor-pointer mb-3"> {t('Pages.Register.School.title')}</h5>

                                    <div className="d-flex justify-content-around flex-wrap">
                                        <Link to="/Register/School" className={`custom-button `} >
                                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                                <div className="d-flex flex-column py-1" >
                                                    <span style={{
                                                        lineHeight: '30px'
                                                    }}>
                                                        30 دقيقة استشارة مجّانيّة
                                                    </span>
                                                    <span className="px-0" style={{
                                                        lineHeight: '25px',
                                                        fontSize: '16px'
                                                    }}>
                                                        التسجيل يستغرق أقلّ مِن 120 ثانية
                                                    </span>
                                                </div>
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="list w-100 text-right">
                                        <ul>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                إمكانيّة تحميل أيّ ملف من الموقع لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح كلّ الملفات المحوسبة لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح كلّ الألعاب المحوسبة لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح جميع محتويات الموقع لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                فتح حساب منفرد لكلّ معلّم لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                إمكانيّة إرسال مهامّ محوسبة للطلّاب لمدّة سنة.
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                صفر مخاطر: إذا لم تكن راضيًا لأيّ سبب، يمكنك استرجاع كامل المبلغ خلال 30 يومًا من الاشتراك.
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default withTranslation('common')(PricesSchool);
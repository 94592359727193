import React from "react";
import './style.css';
import bodyImg from './bat-body.png';
import wingImg from './bat-wing.png';
import TextBox from "../../../TextBox";

class Bat extends React.Component {

    render() {
        return (
            <>
                <div className="Bat_wrap mx-auto">
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className={`Bat_bat `}>
                                <div className="Bat_eyes">
                                    <div className="Bat_pupil"></div>
                                </div>
                                <img className="Bat_wing Bat_leftwing" alt="" src={wingImg} />
                                <img className="Bat_body" src={bodyImg} alt="bat" />
                                <img className="Bat_wing Bat_rightwing" alt="" src={wingImg} />
                            </div>
                        </div>
                    </div>

                    <div style={{
                        marginTop: "-15px"
                    }}>
                        <TextBox {...this.props} />
                    </div>
                </div>
            </>
        );
    }
}

export default Bat;

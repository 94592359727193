const SearchReducers = (state = [], action) => {
    switch (action.type) {
        case "SEARCH_LOADING":
            return {
                Lessons: [],
                Books: [],
                Exams: [],
                Files: [],
                Games: [],
                Quizzes: [],
                isLoaded: false
            };
        case "SEARCH_INITIAL":
            return {
                Lessons: action.data.Lessons,
                Books: action.data.Books,
                Exams: action.data.Exams,
                Files: action.data.Files,
                Games: action.data.Games,
                Quizzes: action.data.Quizzes,
                isLoaded: true
            };

        case "SEARCH_FILES_UPDATE_FAVORITE":
            return {
                Files: UpdateSearchFavorite(state.Files, action.id),
                Lessons: state.Lessons,
                Books: state.Books,
                Exams: state.Exams,
                Games: state.Games,
                Quizzes: state.Quizzes,
                isLoaded: true
            };

        case "SEARCH_LESSONS_UPDATE_FAVORITE":
            return {
                Files: state.Files,
                Lessons: UpdateSearchFavorite(state.Lessons, action.id),
                Books: UpdateSearchFavorite(state.Books, action.id),
                Exams: UpdateSearchFavorite(state.Exams, action.id),
                Games: UpdateSearchFavorite(state.Games, action.id),
                Quizzes: UpdateSearchFavorite(state.Quizzes, action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}


function UpdateSearchFavorite(items, id) {
    let copy = [];
    items.map(item => {
        if (item.Id === id) {
            item = {
                ...item,
                isFavorite: !item.isFavorite
            };
        }
        copy.push({
            ...item
        });
        return '';
    });
    return copy;
}

export default SearchReducers;
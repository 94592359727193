import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import QuestionBackground from './Designer/QuestionBackground';
import QuestionBorder from './Designer/QuestionBorder';
import QuestionPadding from './Designer/QuestionPadding';
import QuestionAnswer from './Designer/QuestionAnswer';
import QuestionShadow from './Designer/QuestionShadow';
import QuestionSound from './Designer/QuestionSound';
import GameSetting from './Designer/GameSetting';
import QuestionView from '../View/QuestionView';
import { updateQuestion } from '../actions/';
import { fetchTemplates } from '../../Templates/actions/';
import api from '../../../../api/api';
import configData from "../../../Config/CustomStyle.json";
import ListItem from "../../Templates/ListItem";
import GameSound from "./Designer/GameSound";
import BackgroundGameObject from "./Designer/BackgroundGameObject";

class SelectDesigner extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        position: {
            x: 0,
            y: 0
        },
        version: 1,
        SizeView: 100,
        showCopyStyleModal: false,
        showSaveQuestionStyleModal: false,
        showDownloadQuestionStyleModal: false,
        Title: ''
    };

    updateVersion = () => {
        let version = this.state.version;
        this.setState({
            version: version + 1
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        return state;
    }

    setGameStartSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    StartSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                StartSound: sound
            }
        });
    }
    setGameCustomStartSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomStartSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomStartSound: sound
            }
        });
    }

    setBonusSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BonusSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BonusSound: sound
            }
        });
    }
    setCustomBonusSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomBonusSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomBonusSound: sound
            }
        });
    }

    setBonusTimeSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BonusTimeSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BonusTimeSound: sound
            }
        });
    }
    setCustomBonusTimeSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomBonusTimeSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomBonusTimeSound: sound
            }
        });
    }

    setGameOverSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    GameOverSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                GameOverSound: sound
            }
        });
    }
    setCustomGameOverSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomGameOverSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomGameOverSound: sound
            }
        });
    }
    setGameStageSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    StageSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                StageSound: sound
            }
        });
    }
    setStageGameCustomSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomStageSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomStageSound: sound
            }
        });
    }
    setGameWinSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    WinSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                WinSound: sound
            }
        });
    }
    setWinGameCustomSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomWinSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomWinSound: sound
            }
        });
    }
    setGameBackgroundSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BackgroundSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BackgroundSound: sound
            }
        });
    }
    setBackgroundGameCustomSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomBackgroundSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomBackgroundSound: sound
            }
        });
    }

    setGameTrueSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TrueSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TrueSound: sound
            }
        });
    }
    setGameCustomTrueSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomTrueSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomTrueSound: sound
            }
        });
    }
    setGameConfetti = Confetti => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    Confetti
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                Confetti
            }
        });
    }
    setGameCustomConfetti = CustomConfetti => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomConfetti
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomConfetti
            }
        });
    }
    setGameConfettiFalse = ConfettiFalse => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    ConfettiFalse
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                ConfettiFalse
            }
        });
    }
    setGameCustomConfettiFalse = CustomConfettiFalse => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomConfettiFalse
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomConfettiFalse
            }
        });
    }
    setGameFalseSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    FalseSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                FalseSound: sound
            }
        });
    }
    setGameCustomFalseSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomFalseSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomFalseSound: sound
            }
        });
    }

    setQuestionTrueSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    TrueSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                TrueSound: sound
            }
        });
    }
    setQuestionCustomTrueSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    CustomTrueSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                CustomTrueSound: sound
            }
        });
    }
    setQuestionConfetti = Confetti => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    Confetti
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                Confetti
            }
        });
    }
    setQuestionCustomConfetti = CustomConfetti => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    CustomConfetti
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                CustomConfetti
            }
        });
    }
    setQuestionConfettiFalse = ConfettiFalse => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    ConfettiFalse
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                ConfettiFalse
            }
        });
    }
    setQuestionCustomConfettiFalse = CustomConfettiFalse => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    CustomConfettiFalse
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                CustomConfettiFalse
            }
        });
    }
    setQuestionFalseSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    FalseSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                FalseSound: sound
            }
        });
    }
    setQuestionCustomFalseSound = sound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionSound: {
                    ...this.state.CustomStyle.questionSound,
                    CustomFalseSound: sound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionSound: {
                ...this.state.CustomStyle.questionSound,
                CustomFalseSound: sound
            }
        });
    }

    setQuestionDesignerContainer = width => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                designerContainer: width
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            designerContainer: width
        });
    }

    setQuestionAnswersSelectClass = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersSelectClass: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersSelectClass: value
            }
        });
    }
    setQuestionAnswersSelectTemplate = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersSelectTemplate: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersSelectTemplate: value
            }
        });
    }
    setQuestionAnswersSeparated = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersSeparated: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersSeparated: value
            }
        });
    }
    setQuestionAnswersSelectType = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersSelectType: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersSelectType: value
            }
        });

        if (value === "radio") {
            if (this.props.question.question && this.props.question.question.Answers) {
                let answerResult = [];
                let Checked = true;
                this.props.question.question.Answers.forEach((answer) => {
                    answerResult.push({
                        ...answer,
                        True: (answer.True === 1 && Checked) ? 1 : 0
                    });

                    if (answer.True === 1) {
                        Checked = false;
                    }
                });

                var copy = this.props.question;
                copy.question = {
                    ...copy.question,
                    Answers: answerResult
                };
                this.props.updateQuestion(copy);

                const { t, toast } = this.props;
                let _toastId = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });
                toast.update(_toastId, {
                    render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                    autoClose: false,
                    closeOnClick: false,
                });

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-engine-warning"></i> {t('Pages.Lessons.Questions.forms.Change_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Change_Answer.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }

    }
    setQuestionAnswerOrder = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswerOrder: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswerOrder: value
            }
        });
    }
    setQuestionAnswersCols = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersCols: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersCols: value
            }
        });
    }
    setQuestionAutoCorrection = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AutoCorrection: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AutoCorrection: value
            }
        });
    }
    setQuestionAnswersAlign = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersAlign: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersAlign: value
            }
        });
    }
    setQuestionTypeBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                BorderStyle: value
            }
        });
    }
    setQuestionTypeBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                BorderColor: value
            }
        });
    }
    setQuestionTypeBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    BorderWidth: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                BorderWidth: value
            }
        });
    }
    setQuestionAnswersMargin = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersMargin: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersMargin: value
            }
        });
    }
    setAnswersColsMargin = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    AnswersColsMargin: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                AnswersColsMargin: value
            }
        });
    }
    setColsWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    ColsWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                ColsWidthRight: value
            }
        });
    }
    setColsWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionTypeStyle: {
                    ...this.state.CustomStyle.questionTypeStyle,
                    ColsWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionTypeStyle: {
                ...this.state.CustomStyle.questionTypeStyle,
                ColsWidthLeft: value
            }
        });
    }

    setQuestionPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });
    }
    setQuestionPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                PaddingTop: value
            }
        });
    }
    setQuestionPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                PaddingRight: value
            }
        });
    }
    setQuestionPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                PaddingLeft: value
            }
        });
    }
    setQuestionPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                PaddingBottom: value
            }
        });
    }
    setQuestionBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderRadiusUnit: value
            }
        });
    }
    setQuestionBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });
    }
    setQuestionBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderWidthTop: value
            }
        });
    }
    setQuestionBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderWidthRight: value
            }
        });
    }
    setQuestionBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderWidthLeft: value
            }
        });
    }
    setQuestionBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderWidthBottom: value
            }
        });
    }
    setQuestionBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });
    }
    setQuestionBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderRadiusTop: value
            }
        });
    }
    setQuestionBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderRadiusRight: value
            }
        });
    }
    setQuestionBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderRadiusLeft: value
            }
        });
    }
    setQuestionBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderRadiusBottom: value
            }
        });
    }
    setQuestionBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderColor: value
            }
        });
    }
    setQuestionBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BorderStyle: value
            }
        });
    }
    setQuestionCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                Custom: value
            }
        });
    }
    setQuestionBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BgSize: value
            }
        });
    }
    setQuestionBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BgAttachment: value
            }
        });
    }
    setQuestionBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                BgRepeat: value
            }
        });
    }
    setQuestionBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                backgroundColor: value
            }
        });
    }
    setQuestionShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                shadowColor: value
            }
        });
    }
    setQuestionShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowPositionX: 100
                }
            });
        }
    }
    setQuestionShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowPositionY: 100
                }
            });
        }
    }
    setQuestionShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    ShadowRadius: value
                }
            });
        }
    }
    setQuestionHeight = value => {

        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    height: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                height: value
            }
        });

        if (this.props.answers) {
            let percentge = (value - this.state.height) / this.state.height;
            for (let item of this.props.answers.entries()) {
                item.adjustYPos += item.adjustYPos * percentge;
            }
        }
    }
    setQuestionWidth = value => {
        if ((value >= 10 && value <= 100) || value === 'auto') {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        width: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    width: value
                }
            });
        }
        else if (value < 10) {
            value = 10;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        width: 10
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    width: 10
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        width: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    width: 100
                }
            });
        }

        if (this.props.answers) {
            let percentge = (value - this.state.width) / 100;
            for (let item of this.props.answers.entries()) {
                if (item.adjustXPos > 0) {
                    item.adjustXPos = 0;
                }
                item.adjustXPos += item.adjustXPos * percentge;
            }
        }
    }
    setQuestionBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            questionStyle: {
                ...this.state.CustomStyle.questionStyle,
                backgroundImage: value
            }
        });
    }
    setQuestionOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    Opacity: value
                }
            });
        }
    }
    setQuestionBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BgPositionX: value
                }
            });
        }
    }
    setQuestionBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    questionStyle: {
                        ...this.state.CustomStyle.questionStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                questionStyle: {
                    ...this.state.CustomStyle.questionStyle,
                    BgPositionY: value
                }
            });
        }
    }

    setGameCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                Custom: value
            }
        });
    }

    setGameBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                backgroundColor: value
            }
        });
    }

    setGameBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                backgroundImage: value
            }
        });
    }

    setGameBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    gamesSetting: {
                        ...this.state.CustomStyle.gamesSetting,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BgPositionX: value
                }
            });
        }
    }
    setGameBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    gamesSetting: {
                        ...this.state.CustomStyle.gamesSetting,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BgPositionY: value
                }
            });
        }
    }
    setGameBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BgSize: value
            }
        });
    }
    setGameBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BgAttachment: value
            }
        });
    }
    setGameBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BgRepeat: value
            }
        });
    }
    setGameOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    gamesSetting: {
                        ...this.state.CustomStyle.gamesSetting,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    Opacity: value
                }
            });
        }
    }

    setGameDropObject = DropObject => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    DropObject
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                DropObject
            }
        });
    }

    setGameMoveObject = MoveObject => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    MoveObject
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                MoveObject
            }
        });
    }

    setGameTryColor = TryColor => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TryColor
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TryColor
            }
        });
    }
    setGameTryColorCustom = TryColorCustom => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TryColorCustom
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TryColorCustom
            }
        });
    }
    setGameExplosionObject = ExplosionObject => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    ExplosionObject
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                ExplosionObject
            }
        });
    }

    setDropCustomColor = DropCustomColor => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    DropCustomColor
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                DropCustomColor
            }
        });
    }

    setMoveCustomColor = MoveCustomColor => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    MoveCustomColor
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                MoveCustomColor
            }
        });
    }

    setGameTryShape = TryShape => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TryShape
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TryShape
            }
        });
    }

    setGameTime = Time => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    Time
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                Time
            }
        });
    }

    setGameTryCount = TryCount => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TryCount
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TryCount
            }
        });
    }

    setGameDropObjectSrc = DropObjectSrc => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    DropObjectSrc
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                DropObjectSrc
            }
        });
    }

    setGameDropObjectSrcHover = DropObjectSrcHover => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    DropObjectSrcHover
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                DropObjectSrcHover
            }
        });
    }

    setGameMoveObjectSrc = MoveObjectSrc => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    MoveObjectSrc
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                MoveObjectSrc
            }
        });
    }

    setCursor = Cursor => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    Cursor
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                Cursor
            }
        });
    }

    setCursorCustom = CursorCustom => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CursorCustom
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CursorCustom
            }
        });
    }

    setGameMoveObjectSrcHover = MoveObjectSrcHover => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    MoveObjectSrcHover
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                MoveObjectSrcHover
            }
        });
    }

    setGameMoveObjectFlipY = FlipY => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    FlipY
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                FlipY
            }
        });
    }

    setGameMoveObjectFlipX = FlipX => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    FlipX
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                FlipX
            }
        });
    }

    setBackgroundGameObject = BackgroundGameObject => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    BackgroundGameObject
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                BackgroundGameObject
            }
        });
    }

    setGameShootObjectOption = ShootObjectOption => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    ShootObjectOption
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                ShootObjectOption
            }
        });
    }

    setShootObjectSound = ShootObjectSound => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    ShootObjectSound
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                ShootObjectSound
            }
        });
    }

    setGameMinTop = MinTop => {
        if (MinTop >= 0 && MinTop <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    gamesSetting: {
                        ...this.state.CustomStyle.gamesSetting,
                        MinTop
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    MinTop
                }
            });
        }
    }

    setGameMaxTop = MaxTop => {
        if (MaxTop >= 0 && MaxTop <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    gamesSetting: {
                        ...this.state.CustomStyle.gamesSetting,
                        MaxTop
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    MaxTop
                }
            });
        }
    }

    setGameTranslateX = TranslateX => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TranslateX
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TranslateX
            }
        });
    }

    setGameOffsetTopObject = OffsetTopObject => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    OffsetTopObject
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                OffsetTopObject
            }
        });
    }

    setGameOffsetTop = OffsetTop => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    OffsetTop
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                OffsetTop
            }
        });
    }

    setGameyRopeColor = RopeColor => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    RopeColor
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                RopeColor
            }
        });
    }

    setGameShootObjectSrc = ShootObjectSrc => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    ShootObjectSrc
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                ShootObjectSrc
            }
        });
    }

    setGameShootObjectSrcHover = ShootObjectSrcHover => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    ShootObjectSrcHover
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                ShootObjectSrcHover
            }
        });
    }

    setGameTranslateTime = TranslateTime => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TranslateTime
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TranslateTime
            }
        });
    }

    setGameMoveObjectRotate = Rotate => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    Rotate
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                Rotate
            }
        });
    }

    setGameIconType = IconType => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    IconType
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                IconType
            }
        });
    }

    setTransformOriginX = TransformOriginX => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TransformOriginX
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TransformOriginX
            }
        });
    }

    setTransformOriginY = TransformOriginY => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    TransformOriginY
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                TransformOriginY
            }
        });
    }

    setGameCustomBackground = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                gamesSetting: {
                    ...this.state.CustomStyle.gamesSetting,
                    CustomBackground: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            gamesSetting: {
                ...this.state.CustomStyle.gamesSetting,
                CustomBackground: value
            }
        });
    }

    setQuestionAnswerCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                Custom: value
            }
        });
    }
    setQuestionAnswerBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BgSize: value
            }
        });
    }
    setQuestionAnswerBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BgAttachment: value
            }
        });
    }
    setQuestionAnswerBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BgRepeat: value
            }
        });
    }
    setQuestionAnswerBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                backgroundColor: value
            }
        });
    }
    setQuestionAnswerBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                backgroundImage: value
            }
        });
    }
    setQuestionAnswerOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    Opacity: value
                }
            });
        }
    }
    setQuestionAnswerBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BgPositionX: value
                }
            });
        }
    }
    setQuestionAnswerBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BgPositionY: value
                }
            });
        }
    }

    setQuestionAnswerBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderRadiusUnit: value
            }
        });
    }
    setQuestionAnswerBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });
    }
    setQuestionAnswerBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderWidthTop: value
            }
        });
    }
    setQuestionAnswerBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderWidthRight: value
            }
        });
    }
    setQuestionAnswerBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderWidthLeft: value
            }
        });
    }
    setQuestionAnswerBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderWidthBottom: value
            }
        });
    }
    setQuestionAnswerBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });
    }
    setQuestionAnswerBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderRadiusTop: value
            }
        });
    }
    setQuestionAnswerBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderRadiusRight: value
            }
        });
    }
    setQuestionAnswerBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderRadiusLeft: value
            }
        });
    }
    setQuestionAnswerBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderRadiusBottom: value
            }
        });
    }
    setQuestionAnswerBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderColor: value
            }
        });
    }
    setQuestionAnswerBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                BorderStyle: value
            }
        });
    }

    setQuestionAnswerShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                shadowColor: value
            }
        });
    }
    setQuestionAnswerShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowPositionX: 100
                }
            });
        }
    }
    setQuestionAnswerShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowPositionY: 100
                }
            });
        }
    }
    setQuestionAnswerShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerStyle: {
                        ...this.state.CustomStyle.answerStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    ShadowRadius: value
                }
            });
        }
    }

    setQuestionAnswerPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });
    }
    setQuestionAnswerPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                PaddingTop: value
            }
        });
    }
    setQuestionAnswerPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                PaddingRight: value
            }
        });
    }
    setQuestionAnswerPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                PaddingLeft: value
            }
        });
    }
    setQuestionAnswerPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                PaddingBottom: value
            }
        });
    }
    setQuestionAnswerWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    width: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                width: value
            }
        });
    }
    setQuestionAnswerHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    height: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                height: value
            }
        });
    }
    setQuestionAnswerAnswersAlign = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerStyle: {
                    ...this.state.CustomStyle.answerStyle,
                    AnswersAlign: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerStyle: {
                ...this.state.CustomStyle.answerStyle,
                AnswersAlign: value
            }
        });
    }


    setQuestionAnswerBoxCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                Custom: value
            }
        });

    }
    setQuestionAnswerBoxBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BgSize: value
            }
        });

    }
    setQuestionAnswerBoxBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BgAttachment: value
            }
        });

    }
    setQuestionAnswerBoxBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BgRepeat: value
            }
        });

    }
    setQuestionAnswerBoxBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                backgroundColor: value
            }
        });

    }
    setQuestionAnswerBoxBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                backgroundImage: value
            }
        });

    }
    setQuestionAnswerBoxOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    Opacity: value
                }
            });
        }

    }
    setQuestionAnswerBoxBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BgPositionX: value
                }
            });
        }

    }
    setQuestionAnswerBoxBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BgPositionY: value
                }
            });
        }

    }

    setQuestionAnswerBoxBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderRadiusUnit: value
            }
        });

    }
    setQuestionAnswerBoxBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionAnswerBoxBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderWidthTop: value
            }
        });

    }
    setQuestionAnswerBoxBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderWidthRight: value
            }
        });

    }
    setQuestionAnswerBoxBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderWidthLeft: value
            }
        });

    }
    setQuestionAnswerBoxBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionAnswerBoxBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionAnswerBoxBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderRadiusTop: value
            }
        });

    }
    setQuestionAnswerBoxBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderRadiusRight: value
            }
        });

    }
    setQuestionAnswerBoxBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderRadiusLeft: value
            }
        });

    }
    setQuestionAnswerBoxBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionAnswerBoxBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderColor: value
            }
        });

    }
    setQuestionAnswerBoxBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                BorderStyle: value
            }
        });

    }

    setQuestionAnswerBoxShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                shadowColor: value
            }
        });

    }
    setQuestionAnswerBoxShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowPositionX: 100
                }
            });
        }

    }
    setQuestionAnswerBoxShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowPositionY: 100
                }
            });
        }

    }
    setQuestionAnswerBoxShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    answerBoxStyle: {
                        ...this.state.CustomStyle.answerBoxStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    ShadowRadius: value
                }
            });
        }

    }

    setQuestionAnswerBoxPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });

    }
    setQuestionAnswerBoxPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                PaddingTop: value
            }
        });
    }
    setQuestionAnswerBoxPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                PaddingRight: value
            }
        });

    }
    setQuestionAnswerBoxPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                PaddingLeft: value
            }
        });

    }
    setQuestionAnswerBoxPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                PaddingBottom: value
            }
        });
    }
    setQuestionAnswerBoxWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    width: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                width: value
            }
        });
    }
    setQuestionAnswerBoxHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    height: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                height: value
            }
        });
    }
    setQuestionAnswerBoxAnswersAlign = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    AnswersAlign: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                AnswersAlign: value
            }
        });
    }
    setAnswersColsMarginBoxAnswers = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    AnswersColsMargin: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                AnswersColsMargin: value
            }
        });
    }
    setAnswersOrderBoxAnswers = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerBoxStyle: {
                    ...this.state.CustomStyle.answerBoxStyle,
                    AnswerOrder: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerBoxStyle: {
                ...this.state.CustomStyle.answerBoxStyle,
                AnswerOrder: value
            }
        });
    }

    setQuestionCategoryCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                Custom: value
            }
        });

    }
    setQuestionCategoryBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BgSize: value
            }
        });

    }
    setQuestionCategoryBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BgAttachment: value
            }
        });

    }
    setQuestionCategoryBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BgRepeat: value
            }
        });

    }
    setQuestionCategoryBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                backgroundColor: value
            }
        });

    }
    setQuestionCategoryBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                backgroundImage: value
            }
        });

    }
    setQuestionCategoryOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    Opacity: value
                }
            });
        }

    }
    setQuestionCategoryBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BgPositionX: value
                }
            });
        }

    }
    setQuestionCategoryBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BgPositionY: value
                }
            });
        }

    }
    setQuestionCategoryBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderRadiusUnit: value
            }
        });

    }
    setQuestionCategoryBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionCategoryBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderWidthTop: value
            }
        });

    }
    setQuestionCategoryBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderWidthRight: value
            }
        });

    }
    setQuestionCategoryBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderWidthLeft: value
            }
        });

    }
    setQuestionCategoryBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionCategoryBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionCategoryBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderRadiusTop: value
            }
        });

    }
    setQuestionCategoryBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderRadiusRight: value
            }
        });

    }
    setQuestionCategoryBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderRadiusLeft: value
            }
        });

    }
    setQuestionCategoryBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionCategoryBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderColor: value
            }
        });

    }
    setQuestionCategoryBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                BorderStyle: value
            }
        });

    }
    setQuestionCategoryShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                shadowColor: value
            }
        });

    }
    setQuestionCategoryShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowPositionX: 100
                }
            });
        }

    }
    setQuestionCategoryShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowPositionY: 100
                }
            });
        }

    }
    setQuestionCategoryShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryStyle: {
                        ...this.state.CustomStyle.categoryStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    ShadowRadius: value
                }
            });
        }

    }
    setQuestionCategoryPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });

    }
    setQuestionCategoryPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                PaddingTop: value
            }
        });

    }
    setQuestionCategoryPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                PaddingRight: value
            }
        });

    }
    setQuestionCategoryPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                PaddingLeft: value
            }
        });

    }
    setQuestionCategoryPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryStyle: {
                    ...this.state.CustomStyle.categoryStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryStyle: {
                ...this.state.CustomStyle.categoryStyle,
                PaddingBottom: value
            }
        });

    }

    setQuestionCategoryHoverCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                Custom: value
            }
        });

    }
    setQuestionCategoryHoverBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BgSize: value
            }
        });

    }
    setQuestionCategoryHoverBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BgAttachment: value
            }
        });

    }
    setQuestionCategoryHoverBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BgRepeat: value
            }
        });

    }
    setQuestionCategoryHoverBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                backgroundColor: value
            }
        });

    }
    setQuestionCategoryHoverBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                backgroundImage: value
            }
        });

    }
    setQuestionCategoryHoverOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    Opacity: value
                }
            });
        }

    }
    setQuestionCategoryHoverBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BgPositionX: value
                }
            });
        }

    }
    setQuestionCategoryHoverBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BgPositionY: value
                }
            });
        }

    }
    setQuestionCategoryHoverBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderRadiusUnit: value
            }
        });

    }
    setQuestionCategoryHoverBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionCategoryHoverBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderWidthTop: value
            }
        });

    }
    setQuestionCategoryHoverBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderWidthRight: value
            }
        });

    }
    setQuestionCategoryHoverBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderWidthLeft: value
            }
        });

    }
    setQuestionCategoryHoverBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionCategoryHoverBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionCategoryHoverBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderRadiusTop: value
            }
        });

    }
    setQuestionCategoryHoverBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderRadiusRight: value
            }
        });

    }
    setQuestionCategoryHoverBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderRadiusLeft: value
            }
        });

    }
    setQuestionCategoryHoverBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionCategoryHoverBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderColor: value
            }
        });

    }
    setQuestionCategoryHoverBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                BorderStyle: value
            }
        });

    }
    setQuestionCategoryHoverShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                shadowColor: value
            }
        });

    }
    setQuestionCategoryHoverShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowPositionX: 100
                }
            });
        }

    }
    setQuestionCategoryHoverShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowPositionY: 100
                }
            });
        }

    }
    setQuestionCategoryHoverShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryHoverStyle: {
                        ...this.state.CustomStyle.categoryHoverStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    ShadowRadius: value
                }
            });
        }

    }
    setQuestionCategoryHoverPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });

    }
    setQuestionCategoryHoverPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                PaddingTop: value
            }
        });

    }
    setQuestionCategoryHoverPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                PaddingRight: value
            }
        });

    }
    setQuestionCategoryHoverPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                PaddingLeft: value
            }
        });

    }
    setQuestionCategoryHoverPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryHoverStyle: {
                    ...this.state.CustomStyle.categoryHoverStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryHoverStyle: {
                ...this.state.CustomStyle.categoryHoverStyle,
                PaddingBottom: value
            }
        });

    }


    setQuestionCategoryListStyleCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                Custom: value
            }
        });

    }
    setQuestionCategoryListStyleBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BgSize: value
            }
        });

    }
    setQuestionCategoryListStyleBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BgAttachment: value
            }
        });

    }
    setQuestionCategoryListStyleBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BgRepeat: value
            }
        });

    }
    setQuestionCategoryListStyleBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                backgroundColor: value
            }
        });

    }
    setQuestionCategoryListStyleBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                backgroundImage: value
            }
        });

    }
    setQuestionCategoryListStyleOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    Opacity: value
                }
            });
        }

    }
    setQuestionCategoryListStyleBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BgPositionX: value
                }
            });
        }

    }
    setQuestionCategoryListStyleBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BgPositionY: value
                }
            });
        }

    }
    setQuestionCategoryListStyleBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderRadiusUnit: value
            }
        });

    }
    setQuestionCategoryListStyleBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionCategoryListStyleBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderWidthTop: value
            }
        });

    }
    setQuestionCategoryListStyleBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderWidthRight: value
            }
        });

    }
    setQuestionCategoryListStyleBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderWidthLeft: value
            }
        });

    }
    setQuestionCategoryListStyleBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderWidthBottom: value
            }
        });

    }
    setQuestionCategoryListStyleBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionCategoryListStyleBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderRadiusTop: value
            }
        });

    }
    setQuestionCategoryListStyleBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderRadiusRight: value
            }
        });

    }
    setQuestionCategoryListStyleBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderRadiusLeft: value
            }
        });

    }
    setQuestionCategoryListStyleBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderRadiusBottom: value
            }
        });

    }
    setQuestionCategoryListStyleBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderColor: value
            }
        });

    }
    setQuestionCategoryListStyleBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                BorderStyle: value
            }
        });

    }
    setQuestionCategoryListStyleShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                shadowColor: value
            }
        });

    }
    setQuestionCategoryListStyleShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowPositionX: 100
                }
            });
        }

    }
    setQuestionCategoryListStyleShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowPositionY: 100
                }
            });
        }

    }
    setQuestionCategoryListStyleShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    categoryListStyle: {
                        ...this.state.CustomStyle.categoryListStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    ShadowRadius: value
                }
            });
        }

    }
    setQuestionCategoryListStylePadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });

    }
    setQuestionCategoryListStylePaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                PaddingTop: value
            }
        });

    }
    setQuestionCategoryListStylePaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                PaddingRight: value
            }
        });

    }
    setQuestionCategoryListStylePaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                PaddingLeft: value
            }
        });

    }
    setQuestionCategoryListStylePaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                categoryListStyle: {
                    ...this.state.CustomStyle.categoryListStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            categoryListStyle: {
                ...this.state.CustomStyle.categoryListStyle,
                PaddingBottom: value
            }
        });

    }

    setButtonCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                Custom: value
            }
        });

    }
    setButtonBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BgSize: value
            }
        });

    }
    setButtonBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BgAttachment: value
            }
        });

    }
    setButtonBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BgRepeat: value
            }
        });

    }
    setButtonBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                backgroundColor: value
            }
        });

    }
    setButtonBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                backgroundImage: value
            }
        });

    }
    setButtonOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    Opacity: value
                }
            });
        }

    }
    setButtonBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BgPositionX: value
                }
            });
        }

    }
    setButtonBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BgPositionY: value
                }
            });
        }

    }
    setButtonBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderRadiusUnit: value
            }
        });

    }
    setButtonBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });

    }
    setButtonBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderWidthTop: value
            }
        });

    }
    setButtonBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderWidthRight: value
            }
        });

    }
    setButtonBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderWidthLeft: value
            }
        });

    }
    setButtonBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderWidthBottom: value
            }
        });

    }
    setButtonBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });

    }
    setButtonBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderRadiusTop: value
            }
        });

    }
    setButtonBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderRadiusRight: value
            }
        });

    }
    setButtonBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderRadiusLeft: value
            }
        });

    }
    setButtonBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderRadiusBottom: value
            }
        });

    }
    setButtonBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderColor: value
            }
        });

    }
    setButtonBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                BorderStyle: value
            }
        });

    }
    setButtonShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                shadowColor: value
            }
        });

    }
    setButtonShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowPositionX: 100
                }
            });
        }

    }
    setButtonShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowPositionY: 100
                }
            });
        }

    }
    setButtonShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonStyle: {
                        ...this.state.CustomStyle.buttonStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    ShadowRadius: value
                }
            });
        }

    }
    setButtonPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });

    }
    setButtonPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                PaddingTop: value
            }
        });

    }
    setButtonPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                PaddingRight: value
            }
        });

    }
    setButtonPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                PaddingLeft: value
            }
        });

    }
    setButtonPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                PaddingBottom: value
            }
        });

    }
    setButtonFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    FontColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                FontColor: value
            }
        });
    }
    setButtonCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    Cursor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                Cursor: value
            }
        });
    }
    setButtonCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    CursorCustom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                CursorCustom: value
            }
        });
    }
    setButtonStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    StrokeWidth: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                StrokeWidth: value
            }
        });
    }
    setButtonStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    StrokeColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                StrokeColor: value
            }
        });
    }
    setButtonFontSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    FontSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                FontSize: value
            }
        });
    }
    setButtonMarginBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    MarginBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                MarginBottom: value
            }
        });
    }
    setButtonHeight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    height: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                height: value
            }
        });
    }
    setButtonWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonStyle: {
                    ...this.state.CustomStyle.buttonStyle,
                    width: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonStyle: {
                ...this.state.CustomStyle.buttonStyle,
                width: value
            }
        });
    }

    setButtonHoverCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    Custom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                Custom: value
            }
        });

    }
    setButtonHoverBgSize = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BgSize: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BgSize: value
            }
        });

    }
    setButtonHoverBgAttachment = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BgAttachment: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BgAttachment: value
            }
        });

    }
    setButtonHoverBgRepeat = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BgRepeat: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BgRepeat: value
            }
        });

    }
    setButtonHoverBackgroundColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    backgroundColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                backgroundColor: value
            }
        });

    }
    setButtonHoverBackgroundImage = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    backgroundImage: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                backgroundImage: value
            }
        });

    }
    setButtonHoverOpacity = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        Opacity: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    Opacity: value
                }
            });
        }

    }
    setButtonHoverBgPositionX = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        BgPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BgPositionX: value
                }
            });
        }

    }
    setButtonHoverBgPositionY = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        BgPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BgPositionY: value
                }
            });
        }

    }
    setButtonHoverBorderRadiusUnit = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderRadiusUnit: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderRadiusUnit: value
            }
        });

    }
    setButtonHoverBorderWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });

    }
    setButtonHoverBorderWidthTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderWidthTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderWidthTop: value
            }
        });

    }
    setButtonHoverBorderWidthRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderWidthRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderWidthRight: value
            }
        });

    }
    setButtonHoverBorderWidthLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderWidthLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderWidthLeft: value
            }
        });

    }
    setButtonHoverBorderWidthBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderWidthBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderWidthBottom: value
            }
        });

    }
    setButtonHoverBorderRadius = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });

    }
    setButtonHoverBorderRadiusTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderRadiusTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderRadiusTop: value
            }
        });

    }
    setButtonHoverBorderRadiusRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderRadiusRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderRadiusRight: value
            }
        });

    }
    setButtonHoverBorderRadiusLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderRadiusLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderRadiusLeft: value
            }
        });

    }
    setButtonHoverBorderRadiusBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderRadiusBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderRadiusBottom: value
            }
        });

    }
    setButtonHoverBorderColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderColor: value
            }
        });

    }
    setButtonHoverBorderStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    BorderStyle: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                BorderStyle: value
            }
        });

    }
    setButtonHoverShadowColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    shadowColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                shadowColor: value
            }
        });

    }
    setButtonHoverShadowPositionX = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowPositionX: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowPositionX: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowPositionX: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowPositionX: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowPositionX: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowPositionX: 100
                }
            });
        }

    }
    setButtonHoverShadowPositionY = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowPositionY: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowPositionY: value
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowPositionY: -100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowPositionY: -100
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowPositionY: 100
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowPositionY: 100
                }
            });
        }

    }
    setButtonHoverShadowRadius = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    buttonHoverStyle: {
                        ...this.state.CustomStyle.buttonHoverStyle,
                        ShadowRadius: value
                    }
                }
            });
            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    ShadowRadius: value
                }
            });
        }

    }
    setButtonHoverPadding = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });

    }
    setButtonHoverPaddingTop = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    PaddingTop: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                PaddingTop: value
            }
        });

    }
    setButtonHoverPaddingRight = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    PaddingRight: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                PaddingRight: value
            }
        });

    }
    setButtonHoverPaddingLeft = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    PaddingLeft: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                PaddingLeft: value
            }
        });

    }
    setButtonHoverPaddingBottom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    PaddingBottom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                PaddingBottom: value
            }
        });

    }
    setButtonHoverFontColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    FontColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                FontColor: value
            }
        });
    }
    setButtonHoverCursor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    Cursor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                Cursor: value
            }
        });
    }
    setButtonHoverCursorCustom = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    CursorCustom: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                CursorCustom: value
            }
        });
    }
    setButtonHoverStrokeWidth = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    StrokeWidth: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                StrokeWidth: value
            }
        });
    }
    setButtonHoverStrokeColor = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                buttonHoverStyle: {
                    ...this.state.CustomStyle.buttonHoverStyle,
                    StrokeColor: value
                }
            }
        });
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            buttonHoverStyle: {
                ...this.state.CustomStyle.buttonHoverStyle,
                StrokeColor: value
            }
        });
    }

    setPaddingLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Padding: value,
                    PaddingTop: value,
                    PaddingRight: value,
                    PaddingLeft: value,
                    PaddingBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                Padding: value,
                PaddingTop: value,
                PaddingRight: value,
                PaddingLeft: value,
                PaddingBottom: value
            }
        });
    }
    setPaddingTopLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingTop: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                PaddingTop: value
            }
        });
    }
    setPaddingRightLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingRight: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                PaddingRight: value
            }
        });
    }
    setPaddingLeftLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingLeft: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                PaddingLeft: value
            }
        });
    }
    setPaddingBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PaddingBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                PaddingBottom: value
            }
        });
    }
    setBorderRadiusUnitLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusUnit: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderRadiusUnit: value
            }
        });
    }
    setBorderWidthLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidth: value,
                    BorderWidthTop: value,
                    BorderWidthRight: value,
                    BorderWidthLeft: value,
                    BorderWidthBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderWidth: value,
                BorderWidthTop: value,
                BorderWidthRight: value,
                BorderWidthLeft: value,
                BorderWidthBottom: value
            }
        });
    }
    setBorderWidthTopLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthTop: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderWidthTop: value
            }
        });
    }
    setBorderWidthRightLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthRight: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderWidthRight: value
            }
        });
    }
    setBorderWidthLeftLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthLeft: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderWidthLeft: value
            }
        });
    }
    setBorderWidthBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderWidthBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderWidthBottom: value
            }
        });
    }
    setBorderRadiusLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadius: value,
                    BorderRadiusTop: value,
                    BorderRadiusRight: value,
                    BorderRadiusLeft: value,
                    BorderRadiusBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderRadius: value,
                BorderRadiusTop: value,
                BorderRadiusRight: value,
                BorderRadiusLeft: value,
                BorderRadiusBottom: value
            }
        });
    }
    setBorderRadiusTopLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusTop: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderRadiusTop: value
            }
        });
    }
    setBorderRadiusRightLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusRight: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderRadiusRight: value
            }
        });
    }
    setBorderRadiusLeftLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusLeft: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderRadiusLeft: value
            }
        });
    }
    setBorderRadiusBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderRadiusBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderRadiusBottom: value
            }
        });
    }
    setBorderColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderColor: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderColor: value
            }
        });
    }
    setBorderStyleLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BorderStyle: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BorderStyle: value
            }
        });
    }
    setCustomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Custom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                Custom: value
            }
        });
    }
    setBgSizeLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgSize: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BgSize: value
            }
        });
    }
    setBgAttachmentLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgAttachment: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BgAttachment: value
            }
        });
    }
    setBgRepeatLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgRepeat: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                BgRepeat: value
            }
        });
    }
    setBackgroundColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    backgroundColor: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                backgroundColor: value
            }
        });
    }
    setShadowColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    shadowColor: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                shadowColor: value
            }
        });
    }
    setShadowPositionXLogoStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionX: value
                    }
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionX: -100
                    }
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionX: 100
                    }
                }
            });
        }

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                ShadowPositionX: value
            }
        });
    }
    setShadowPositionYLogoStyle = value => {
        if (value >= -100 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionY: value
                    }
                }
            });
        }
        else if (value < -100) {
            value = -100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionY: -100
                    }
                }
            });
        }
        else if (value > 100) {
            value = 100;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowPositionY: 100
                    }
                }
            });
        }

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                ShadowPositionY: value
            }
        });
    }
    setShadowRadiusLogoStyle = value => {
        if (value >= 0 && value <= 50) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        ShadowRadius: value
                    }
                }
            });
        }

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                ShadowPositionY: value
            }
        });
    }
    setHeightLogoStyle = value => {
        if (value >= 1 && value <= 1000) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        height: value
                    }
                }
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        height: 1
                    }
                }
            });
        }
        else if (value > 1000) {
            value = 1000;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        height: 1000
                    }
                }
            });
        }

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                height: value
            }
        });
    }
    setWidthLogoStyle = value => {
        if (value >= 1) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        width: value
                    }
                }
            });
        }
        else if (value < 1) {
            value = 1;
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        width: 1
                    }
                }
            });
        }

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                width: value
            }
        });
    }
    setAlignLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    AnswersAlign: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                AnswersAlign: value
            }
        });
    }
    setBackgroundImageLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    backgroundImage: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                backgroundImage: value
            }
        });
    }
    setOpacityLogoStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        Opacity: value
                    }
                }
            });

            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Opacity: value
                }
            });
        }
    }
    setBgPositionXLogoStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        BgPositionX: value
                    }
                }
            });

            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgPositionX: value
                }
            });
        }
    }
    setBgPositionYLogoStyle = value => {
        if (value >= 0 && value <= 100) {
            this.setState({
                CustomStyle: {
                    ...this.state.CustomStyle,
                    LogoStyle: {
                        ...this.state.CustomStyle.LogoStyle,
                        BgPositionY: value
                    }
                }
            });

            this.props.updateQuestionStyle({
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    BgPositionY: value
                }
            });
        }
    }
    setFontColorLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    FontColor: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                FontColor: value
            }
        });
    }
    setMarginLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Margin: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                Margin: value
            }
        });
    }
    setMarginBottomLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    MarginBottom: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                MarginBottom: value
            }
        });
    }

    setLogoMargin = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    Margin: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                Margin: value
            }
        });
    }

    setCustomBackgroundLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    CustomBackground: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                CustomBackground: value
            }
        });
    }

    setPositionYLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PositionY: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                PositionY: value
            }
        });
    }

    setPositionXLogoStyle = value => {
        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                LogoStyle: {
                    ...this.state.CustomStyle.LogoStyle,
                    PositionX: value
                }
            }
        });

        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            LogoStyle: {
                ...this.state.CustomStyle.LogoStyle,
                PositionX: value
            }
        });
    }


    saveStyle = () => {
        const { t, toast } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let postData = {
            CustomStyle: this.state.CustomStyle
        };

        if (this.props.question.typeId === 1) {
            if (this.props.question.question && this.props.question.question.Answers) {
                let answerResult = [];
                let answerPosition = [];
                let checkCount = 0;
                this.props.question.question.Answers.forEach((answer) => {
                    checkCount += answer.True;
                    answerResult.push({
                        Id: answer.Id,
                        Checked: answer.True
                    });
                    answerPosition.push({
                        Id: answer.Id,
                        adjustXPos: answer.adjustXPos,
                        adjustYPos: answer.adjustYPos
                    });
                });
                postData = {
                    ...postData,
                    answerResult: answerResult
                };

                if (checkCount === 0) {

                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    return '';
                }
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return '';
            }
        } else if (this.props.question.typeId === 3) {
            let checkCount = 0;
            if (this.props.question.question && this.props.question.question.Fills) {

                let totalFills = 0;
                this.props.question.question.Categories.forEach((category) => {
                    totalFills += category.Name.split("(_)").length - 1;
                });

                if (totalFills === this.props.question.question.Fills.length) {
                    checkCount = 1;

                    postData = {
                        ...postData,
                        fillResult: this.props.question.question.Fills
                    };
                }
            }
            if (checkCount === 0) {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Fills.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Fills.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return '';
            }
        } else if (this.props.question.typeId === 4 || this.props.question.typeId === 5 || this.props.question.typeId === 6) {

            let noEmptyAnswer = true;
            this.props.question.question.Categories.forEach((category) => {
                var count = (category.Name.match(/\(_\)/g) || []).length;
                var id = category.Id;
                for (let index = 0; index < count && noEmptyAnswer; index++) {
                    if (category.CustomStyle.Answers) {
                        var hasItem = category.CustomStyle.Answers.filter(p => p.Id === id && p.Sort === index)[0];
                        if (hasItem && hasItem.Answer !== '') {
                        } else {
                            noEmptyAnswer = false;
                        }
                    } else {
                        noEmptyAnswer = false;
                    }
                }
            });
            if (!noEmptyAnswer) {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.FillNoAnswer.title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.FillNoAnswer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
        } else if (this.props.question.typeId === 7) {
            if (this.props.question.question && this.props.question.question.Answers && this.props.question.question.Categories && this.props.question.question.Fills) {
                let answerResult = [];
                let answerPosition = [];
                const answers = this.props.question.question.Answers;
                answers.forEach((answer) => {
                    answerResult.push({
                        Id: answer.Id,
                        Checked: answer.True
                    });
                    answerPosition.push({
                        Id: answer.Id,
                        adjustXPos: answer.adjustXPos,
                        adjustYPos: answer.adjustYPos
                    });
                });
                postData = {
                    ...postData,
                    answerResult: answerResult
                };

                let checkCount = 0;
                this.props.question.question.Categories.forEach((category) => {
                    const currentAnswers = answers.filter(p => this.props.question.question.Fills.some(f => f.CategoryId === category.Id && f.AnswerId === p.Id));
                    let hasValue = false;
                    currentAnswers.forEach((answer) => {
                        if (answer.True === 1 && !hasValue) {
                            checkCount++;
                            hasValue = true;
                        }
                    });
                });

                if (checkCount !== this.props.question.question.Categories.length) {

                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    return '';
                }
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return '';
            }
        } else if (this.props.question.typeId === 8) {
            if (this.props.question.question && this.props.question.question.Answers && this.props.question.question.Categories && this.props.question.question.Fills) {
                if (this.props.question.question.Fills.length !== this.props.question.question.Categories.length) {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return '';
                }

                postData = {
                    ...postData,
                    fillResult: this.props.question.question.Fills
                };
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return '';
            }
        } else if (this.props.question.typeId === 9) {
            if (this.props.question.question &&
                this.props.question.question.Fills &&
                this.props.question.question.Answers &&
                this.props.question.question.Categories &&
                this.props.question.question.Matches &&
                this.props.question.question.Answers.length >= 2 &&
                this.props.question.question.Categories.length === 2) {

                let categorys = this.props.question.question.Categories.sort((a, b) => a.Sort - b.Sort);

                const fillsLength = this.props.question.question.Fills.filter(p => p.CategoryId === categorys[0].Id).length;

                if (fillsLength !== this.props.question.question.Matches.length) {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    return '';
                }
                postData = {
                    ...postData,
                    Matches: this.props.question.question.Matches
                };
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return '';
            }
        } else if (this.props.question.typeId === 10 || this.props.question.typeId === 13) {
            let checkCount = 0;
            if (this.props.question.question && this.props.question.question.Fills) {

                if (this.props.question.question.Fills.length > 1) {
                    checkCount = 1;

                    postData = {
                        ...postData,
                        fillResult: this.props.question.question.Fills
                    };
                }
            }
            if (checkCount === 0) {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Fills.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Fills.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return '';
            }
        } else if (this.props.question.typeId === 14 || this.props.question.typeId === 20) {
            if (this.props.question.question &&
                this.props.question.question.Fills &&
                this.props.question.question.Answers &&
                this.props.question.question.Categories &&
                this.props.question.question.Matches &&
                this.props.question.question.Answers.length >= 2) {

                let totalFills = 0;
                this.props.question.question.Categories.forEach((category) => {
                    totalFills += category.Name.split("(_)").length - 1;
                });

                if (totalFills !== this.props.question.question.Matches.length) {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')} </div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    return '';
                }

                postData = {
                    ...postData,
                    Matches: this.props.question.question.Matches
                };

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Lessons.Questions.forms.Error_No_Answer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Error_No_Answer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return '';
            }
        }

        api.put(`/Questions/${this.props.QuestionId}?QuestionStyle=${true}`,
            postData
        ).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Lessons.Questions.forms.Success_Style.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Success_Style.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            this.props.updateQuestionStyle(this.state.CustomStyle);
            this.props.updateQuestionTrueAnswer(this.props.question.question.Answers);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    setQuestionAnswerPosition = answerResult => {
        this.props.updateQuestionStyle({
            ...this.state.CustomStyle,
            answerPosition: answerResult
        });

        this.setState({
            CustomStyle: {
                ...this.state.CustomStyle,
                answerPosition: answerResult
            }
        });
    }
    handleSizeView = value => {
        this.setState({
            SizeView: value
        });
    };

    renderCategoryStyleList() {
        const { t, question } = this.props;
        if (question.typeId === 3 || question.typeId === 9 || question.typeId === 10 || question.typeId === 12 || question.typeId === 13 || question.typeId === 14) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {this.props.categoryStyle ? this.props.categoryStyle : t('Pages.Lessons.Questions.forms.tools.categoryStyle')}
                    </div>
                    <ul className="filter mx-1 mb-3">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setQuestionCategoryBackgroundColor}
                            setQuestionBackgroundImage={this.setQuestionCategoryBackgroundImage}
                            setQuestionBgPositionX={this.setQuestionCategoryBgPositionX}
                            setQuestionBgPositionY={this.setQuestionCategoryBgPositionY}
                            setQuestionBgSize={this.setQuestionCategoryBgSize}
                            setQuestionOpacity={this.setQuestionCategoryOpacity}
                            setQuestionCustom={this.setQuestionCategoryCustom}
                            setQuestionBgRepeat={this.setQuestionCategoryBgRepeat}
                            setQuestionBgAttachment={this.setQuestionCategoryBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setQuestionCategoryBorderStyle}
                            setQuestionBorderColor={this.setQuestionCategoryBorderColor}
                            setQuestionBorderWidth={this.setQuestionCategoryBorderWidth}
                            setQuestionBorderWidthTop={this.setQuestionCategoryBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setQuestionCategoryBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setQuestionCategoryBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setQuestionCategoryBorderWidthBottom}
                            setQuestionBorderRadius={this.setQuestionCategoryBorderRadius}
                            setQuestionBorderRadiusTop={this.setQuestionCategoryBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setQuestionCategoryBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setQuestionCategoryBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setQuestionCategoryBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setQuestionCategoryBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setQuestionCategoryShadowColor}
                            setQuestionShadowRadius={this.setQuestionCategoryShadowRadius}
                            setQuestionShadowPositionX={this.setQuestionCategoryShadowPositionX}
                            setQuestionShadowPositionY={this.setQuestionCategoryShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.categoryStyle ? this.state.CustomStyle.categoryStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setQuestionCategoryPadding}
                            setQuestionPaddingTop={this.setQuestionCategoryPaddingTop}
                            setQuestionPaddingLeft={this.setQuestionCategoryPaddingLeft}
                            setQuestionPaddingRight={this.setQuestionCategoryPaddingRight}
                            setQuestionPaddingBottom={this.setQuestionCategoryPaddingBottom}
                        ></QuestionPadding>
                    </ul>
                </div>
            );
        }
        return "";
    }
    renderAnswerStyleList() {
        const { t, question } = this.props;
        if (question.typeId === 2 ||
            question.typeId === 3 ||
            question.typeId === 9 ||
            question.typeId === 10 ||
            question.typeId === 13 ||
            question.typeId === 14 ||
            question.typeId === 15 ||
            question.typeId === 16 ||
            question.typeId === 17 ||
            question.typeId === 18 ||
            question.typeId === 19 ||
            question.typeId === 20 ||
            question.typeId === 21 ||
            question.typeId === 22 ||
            question.typeId === 23 ||
            question.typeId === 24 ||
            question.typeId === 25 ||
            question.typeId === 26 ||
            question.typeId === 27 ||
            question.typeId === 28 ||
            question.typeId === 29 ||
            question.typeId === 30 ||
            question.typeId === 31) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {t('Pages.Lessons.Questions.forms.tools.answerStyle')}
                    </div>
                    <ul className="filter px-1 mx-1 mb-3">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setQuestionAnswerBackgroundColor}
                            setQuestionBackgroundImage={this.setQuestionAnswerBackgroundImage}
                            setQuestionBgPositionX={this.setQuestionAnswerBgPositionX}
                            setQuestionBgPositionY={this.setQuestionAnswerBgPositionY}
                            setQuestionBgSize={this.setQuestionAnswerBgSize}
                            setQuestionOpacity={this.setQuestionAnswerOpacity}
                            setQuestionCustom={this.setQuestionAnswerCustom}
                            setQuestionBgRepeat={this.setQuestionAnswerBgRepeat}
                            setQuestionBgAttachment={this.setQuestionAnswerBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setQuestionAnswerBorderStyle}
                            setQuestionBorderColor={this.setQuestionAnswerBorderColor}
                            setQuestionBorderWidth={this.setQuestionAnswerBorderWidth}
                            setQuestionBorderWidthTop={this.setQuestionAnswerBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setQuestionAnswerBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setQuestionAnswerBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setQuestionAnswerBorderWidthBottom}
                            setQuestionBorderRadius={this.setQuestionAnswerBorderRadius}
                            setQuestionBorderRadiusTop={this.setQuestionAnswerBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setQuestionAnswerBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setQuestionAnswerBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setQuestionAnswerBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setQuestionAnswerBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setQuestionAnswerShadowColor}
                            setQuestionShadowRadius={this.setQuestionAnswerShadowRadius}
                            setQuestionShadowPositionX={this.setQuestionAnswerShadowPositionX}
                            setQuestionShadowPositionY={this.setQuestionAnswerShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setQuestionAnswerPadding}
                            setQuestionPaddingTop={this.setQuestionAnswerPaddingTop}
                            setQuestionPaddingLeft={this.setQuestionAnswerPaddingLeft}
                            setQuestionPaddingRight={this.setQuestionAnswerPaddingRight}
                            setQuestionPaddingBottom={this.setQuestionAnswerPaddingBottom}
                        ></QuestionPadding>
                        {
                            this.props.isGame === true ?
                                <QuestionAnswer
                                    height={this.state.CustomStyle && this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.width : ""}
                                    AnswersAlign={this.state.CustomStyle && this.state.CustomStyle.answerStyle ? this.state.CustomStyle.answerStyle.AnswersAlign : ""}
                                    setQuestionHeight={this.setQuestionAnswerHeight}
                                    setQuestionAnswersAlign={this.setQuestionAnswerAnswersAlign}
                                    setQuestionWidth={this.setQuestionAnswerWidth}
                                />
                                : ""
                        }
                    </ul>
                </div>
            );
        }
        return "";
    }
    renderAnswerBoxStyleList() {
        const { t, question } = this.props;
        if (question.typeId === 2 ||
            question.typeId === 3 ||
            question.typeId === 10 ||
            question.typeId === 12 ||
            question.typeId === 13 ||
            question.typeId === 14 ||
            question.typeId === 15 ||
            question.typeId === 16 ||
            question.typeId === 19 ||
            question.typeId === 20 ||
            question.typeId === 22 ||
            question.typeId === 23 ||
            question.typeId === 24 ||
            question.typeId === 25 ||
            question.typeId === 26 ||
            question.typeId === 27 ||
            question.typeId === 28 ||
            question.typeId === 29 ||
            question.typeId === 30 ||
            question.typeId === 31) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {this.props.answerBoxStyleTitle ? this.props.answerBoxStyleTitle : t('Pages.Lessons.Questions.forms.tools.answerBoxStyle')}
                    </div>
                    <ul className="filter px-1 mx-1 mb-3">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setQuestionAnswerBoxBackgroundColor}
                            setQuestionBackgroundImage={this.setQuestionAnswerBoxBackgroundImage}
                            setQuestionBgPositionX={this.setQuestionAnswerBoxBgPositionX}
                            setQuestionBgPositionY={this.setQuestionAnswerBoxBgPositionY}
                            setQuestionBgSize={this.setQuestionAnswerBoxBgSize}
                            setQuestionOpacity={this.setQuestionAnswerBoxOpacity}
                            setQuestionCustom={this.setQuestionAnswerBoxCustom}
                            setQuestionBgRepeat={this.setQuestionAnswerBoxBgRepeat}
                            setQuestionBgAttachment={this.setQuestionAnswerBoxBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setQuestionAnswerBoxBorderStyle}
                            setQuestionBorderColor={this.setQuestionAnswerBoxBorderColor}
                            setQuestionBorderWidth={this.setQuestionAnswerBoxBorderWidth}
                            setQuestionBorderWidthTop={this.setQuestionAnswerBoxBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setQuestionAnswerBoxBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setQuestionAnswerBoxBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setQuestionAnswerBoxBorderWidthBottom}
                            setQuestionBorderRadius={this.setQuestionAnswerBoxBorderRadius}
                            setQuestionBorderRadiusTop={this.setQuestionAnswerBoxBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setQuestionAnswerBoxBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setQuestionAnswerBoxBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setQuestionAnswerBoxBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setQuestionAnswerBoxBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setQuestionAnswerBoxShadowColor}
                            setQuestionShadowRadius={this.setQuestionAnswerBoxShadowRadius}
                            setQuestionShadowPositionX={this.setQuestionAnswerBoxShadowPositionX}
                            setQuestionShadowPositionY={this.setQuestionAnswerBoxShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setQuestionAnswerBoxPadding}
                            setQuestionPaddingTop={this.setQuestionAnswerBoxPaddingTop}
                            setQuestionPaddingLeft={this.setQuestionAnswerBoxPaddingLeft}
                            setQuestionPaddingRight={this.setQuestionAnswerBoxPaddingRight}
                            setQuestionPaddingBottom={this.setQuestionAnswerBoxPaddingBottom}
                        ></QuestionPadding>
                        {
                            question.typeId === 15 || question.typeId === 16 || question.typeId === 22 ?
                                <QuestionAnswer
                                    typeId={question.typeId}
                                    height={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.width : ""}
                                    AnswersAlign={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswersAlign : ""}
                                    setQuestionHeight={this.setQuestionAnswerBoxHeight}
                                    setQuestionAnswersAlign={this.setQuestionAnswerBoxAnswersAlign}
                                    setQuestionWidth={this.setQuestionAnswerBoxWidth}
                                />
                                : ""
                        }
                        {
                            question.typeId === 16 ?
                                <QuestionAnswer
                                    hasButton={true}
                                    hasCursor={true}
                                    hasDimensions={true}
                                    height={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.width : ""}
                                    Cursor={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.Cursor : ""}
                                    CursorCustom={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.CursorCustom : ""}
                                    FontColor={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.FontColor : ""}
                                    StrokeWidth={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.StrokeWidth : ""}
                                    StrokeColor={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.StrokeColor : ""}
                                    MarginBottom={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.MarginBottom : ""}
                                    FontSize={this.state.CustomStyle && this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.FontSize : ""}
                                    setStrokeWidth={this.setButtonStrokeWidth}
                                    setStrokeColor={this.setButtonStrokeColor}
                                    setCursor={this.setButtonCursor}
                                    setCursorCustom={this.setButtonCursorCustom}
                                    setFontColor={this.setButtonFontColor}
                                    setFontSize={this.setButtonFontSize}
                                    setMarginBottom={this.setButtonMarginBottom}
                                    setQuestionHeight={this.setButtonHeight}
                                    setQuestionWidth={this.setButtonWidth}
                                />
                                : ""
                        }
                        {
                            question.typeId === 19 ||
                                question.typeId === 20 ||
                                question.typeId === 23 ||
                                question.typeId === 24 ||
                                question.typeId === 25 ||
                                question.typeId === 26 ||
                                question.typeId === 27 ||
                                question.typeId === 28 ||
                                question.typeId === 29 ||
                                question.typeId === 30 ||
                                question.typeId === 31 ?
                                <QuestionAnswer
                                    typeId={question.typeId}
                                    height={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.height : ""}
                                    width={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.width : ""}
                                    AnswersAlign={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswersAlign : ""}
                                    AnswerOrder={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswerOrder : ""}
                                    AnswersColsMargin={this.state.CustomStyle && this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswersColsMargin : ""}
                                    setAnswerOrder={this.setAnswersOrderBoxAnswers}
                                    setAnswersColsMargin={this.setAnswersColsMarginBoxAnswers}
                                    setQuestionHeight={this.setQuestionAnswerBoxHeight}
                                    setQuestionAnswersAlign={this.setQuestionAnswerBoxAnswersAlign}
                                    setQuestionWidth={this.setQuestionAnswerBoxWidth}
                                />
                                : ""
                        }
                    </ul>
                </div>
            );
        }
        return "";
    }
    renderCategoryHoverStyleList() {
        const { t, question } = this.props;
        if (question.typeId === 3 || question.typeId === 10 || question.typeId === 13 || question.typeId === 14 || question.typeId === 16 || question.typeId === 20) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {t('Pages.Lessons.Questions.forms.tools.categoryHoverStyle')}
                    </div>
                    <ul className="filter mx-1 mb-3">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setQuestionCategoryHoverBackgroundColor}
                            setQuestionBackgroundImage={this.setQuestionCategoryHoverBackgroundImage}
                            setQuestionBgPositionX={this.setQuestionCategoryHoverBgPositionX}
                            setQuestionBgPositionY={this.setQuestionCategoryHoverBgPositionY}
                            setQuestionBgSize={this.setQuestionCategoryHoverBgSize}
                            setQuestionOpacity={this.setQuestionCategoryHoverOpacity}
                            setQuestionCustom={this.setQuestionCategoryHoverCustom}
                            setQuestionBgRepeat={this.setQuestionCategoryHoverBgRepeat}
                            setQuestionBgAttachment={this.setQuestionCategoryHoverBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setQuestionCategoryHoverBorderStyle}
                            setQuestionBorderColor={this.setQuestionCategoryHoverBorderColor}
                            setQuestionBorderWidth={this.setQuestionCategoryHoverBorderWidth}
                            setQuestionBorderWidthTop={this.setQuestionCategoryHoverBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setQuestionCategoryHoverBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setQuestionCategoryHoverBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setQuestionCategoryHoverBorderWidthBottom}
                            setQuestionBorderRadius={this.setQuestionCategoryHoverBorderRadius}
                            setQuestionBorderRadiusTop={this.setQuestionCategoryHoverBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setQuestionCategoryHoverBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setQuestionCategoryHoverBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setQuestionCategoryHoverBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setQuestionCategoryHoverBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setQuestionCategoryHoverShadowColor}
                            setQuestionShadowRadius={this.setQuestionCategoryHoverShadowRadius}
                            setQuestionShadowPositionX={this.setQuestionCategoryHoverShadowPositionX}
                            setQuestionShadowPositionY={this.setQuestionCategoryHoverShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.categoryHoverStyle ? this.state.CustomStyle.categoryHoverStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setQuestionCategoryHoverPadding}
                            setQuestionPaddingTop={this.setQuestionCategoryHoverPaddingTop}
                            setQuestionPaddingLeft={this.setQuestionCategoryHoverPaddingLeft}
                            setQuestionPaddingRight={this.setQuestionCategoryHoverPaddingRight}
                            setQuestionPaddingBottom={this.setQuestionCategoryHoverPaddingBottom}
                        ></QuestionPadding>
                    </ul>
                </div>
            );
        }
        return "";
    }

    renderCategoryList() {
        const { t, question } = this.props;
        if (question.typeId === 14) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {t('Pages.Lessons.Questions.forms.tools.categoryListStyle')}
                    </div>
                    <ul className="filter mx-1 mb-3">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setQuestionCategoryListStyleBackgroundColor}
                            setQuestionBackgroundImage={this.setQuestionCategoryListStyleBackgroundImage}
                            setQuestionBgPositionX={this.setQuestionCategoryListStyleBgPositionX}
                            setQuestionBgPositionY={this.setQuestionCategoryListStyleBgPositionY}
                            setQuestionBgSize={this.setQuestionCategoryListStyleBgSize}
                            setQuestionOpacity={this.setQuestionCategoryListStyleOpacity}
                            setQuestionCustom={this.setQuestionCategoryListStyleCustom}
                            setQuestionBgRepeat={this.setQuestionCategoryListStyleBgRepeat}
                            setQuestionBgAttachment={this.setQuestionCategoryListStyleBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setQuestionCategoryListStyleBorderStyle}
                            setQuestionBorderColor={this.setQuestionCategoryListStyleBorderColor}
                            setQuestionBorderWidth={this.setQuestionCategoryListStyleBorderWidth}
                            setQuestionBorderWidthTop={this.setQuestionCategoryListStyleBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setQuestionCategoryListStyleBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setQuestionCategoryListStyleBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setQuestionCategoryListStyleBorderWidthBottom}
                            setQuestionBorderRadius={this.setQuestionCategoryListStyleBorderRadius}
                            setQuestionBorderRadiusTop={this.setQuestionCategoryListStyleBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setQuestionCategoryListStyleBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setQuestionCategoryListStyleBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setQuestionCategoryListStyleBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setQuestionCategoryListStyleBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setQuestionCategoryListStyleShadowColor}
                            setQuestionShadowRadius={this.setQuestionCategoryListStyleShadowRadius}
                            setQuestionShadowPositionX={this.setQuestionCategoryListStyleShadowPositionX}
                            setQuestionShadowPositionY={this.setQuestionCategoryListStyleShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setQuestionCategoryListStylePadding}
                            setQuestionPaddingTop={this.setQuestionCategoryListStylePaddingTop}
                            setQuestionPaddingLeft={this.setQuestionCategoryListStylePaddingLeft}
                            setQuestionPaddingRight={this.setQuestionCategoryListStylePaddingRight}
                            setQuestionPaddingBottom={this.setQuestionCategoryListStylePaddingBottom}
                        ></QuestionPadding>
                    </ul>
                </div>
            );
        }
        return "";
    }

    renderFillStyle() {
        const { t, question } = this.props;
        if (question.typeId === 20) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {t('Pages.Lessons.Questions.forms.tools.fillStyle')}
                    </div>
                    <ul className="filter mx-1 mb-3">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setQuestionCategoryListStyleBackgroundColor}
                            setQuestionBackgroundImage={this.setQuestionCategoryListStyleBackgroundImage}
                            setQuestionBgPositionX={this.setQuestionCategoryListStyleBgPositionX}
                            setQuestionBgPositionY={this.setQuestionCategoryListStyleBgPositionY}
                            setQuestionBgSize={this.setQuestionCategoryListStyleBgSize}
                            setQuestionOpacity={this.setQuestionCategoryListStyleOpacity}
                            setQuestionCustom={this.setQuestionCategoryListStyleCustom}
                            setQuestionBgRepeat={this.setQuestionCategoryListStyleBgRepeat}
                            setQuestionBgAttachment={this.setQuestionCategoryListStyleBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setQuestionCategoryListStyleBorderStyle}
                            setQuestionBorderColor={this.setQuestionCategoryListStyleBorderColor}
                            setQuestionBorderWidth={this.setQuestionCategoryListStyleBorderWidth}
                            setQuestionBorderWidthTop={this.setQuestionCategoryListStyleBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setQuestionCategoryListStyleBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setQuestionCategoryListStyleBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setQuestionCategoryListStyleBorderWidthBottom}
                            setQuestionBorderRadius={this.setQuestionCategoryListStyleBorderRadius}
                            setQuestionBorderRadiusTop={this.setQuestionCategoryListStyleBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setQuestionCategoryListStyleBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setQuestionCategoryListStyleBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setQuestionCategoryListStyleBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setQuestionCategoryListStyleBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setQuestionCategoryListStyleShadowColor}
                            setQuestionShadowRadius={this.setQuestionCategoryListStyleShadowRadius}
                            setQuestionShadowPositionX={this.setQuestionCategoryListStyleShadowPositionX}
                            setQuestionShadowPositionY={this.setQuestionCategoryListStyleShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.categoryListStyle ? this.state.CustomStyle.categoryListStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setQuestionCategoryListStylePadding}
                            setQuestionPaddingTop={this.setQuestionCategoryListStylePaddingTop}
                            setQuestionPaddingLeft={this.setQuestionCategoryListStylePaddingLeft}
                            setQuestionPaddingRight={this.setQuestionCategoryListStylePaddingRight}
                            setQuestionPaddingBottom={this.setQuestionCategoryListStylePaddingBottom}
                        ></QuestionPadding>
                    </ul>
                </div>
            );
        }
        return "";
    }

    showCopyQuestionStyle = () => {
        this.setState({
            showCopyStyleModal: true
        });
    }
    handleClose = () => {
        this.setState({
            showCopyStyleModal: false,
            showSaveQuestionStyleModal: false,
            showDownloadQuestionStyleModal: false
        });
    }
    renderCopyStyleModal = () => {
        const { t } = this.props;
        if (this.props.lessons.current.Questions) {
            const Questions = this.props.lessons.current.Questions.filter(p => p.Id !== this.props.question.id);
            if (Questions) {
                return Questions.map(question => {
                    return <div className="col-12" key={question.Id} >
                        <div className="d-flex justify-content-between p-3" style={{
                            borderBottom: '2px solid rgb(14, 44, 83)'
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: question.Name }}></div>
                            <div>
                                <button type="button" className="btn btn-sm m-1 btn-success" onClick={() => this.copyQuestionStyle(question.CustomStyle)}
                                    title={t('Pages.Lessons.Questions.forms.tools.copyStyleButton')} style={{
                                        height: "35px",
                                        width: "35px"
                                    }}><i className=" fal fa-copy" ></i></button>
                            </div>
                        </div>
                    </div>
                });
            }
        }
        return "";
    }
    copyQuestionStyle = CustomStyle => {
        this.setState({
            CustomStyle,
            showCopyStyleModal: false,
            showDownloadQuestionStyleModal: false
        });
        this.props.updateQuestionStyle({
            ...CustomStyle,
        });

    }

    showSaveQuestionStyle = () => {
        this.setState({
            showSaveQuestionStyleModal: true,
            Title: ''
        });
    }
    showDownloadQuestionStyle = () => {
        this.setState({
            showDownloadQuestionStyleModal: true
        });
        this.props.fetchTemplates(this.props.question.typeId);
    }
    setTitle = Title => {
        this.setState({
            Title
        });
    }
    uploadStyle = () => {
        const { t, toast } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        if (this.state.Title) {
            let postData = {
                Title: this.state.Title,
                CustomStyle: this.state.CustomStyle,
            };

            api.post(`/QuestionStyles/${this.props.question.typeId}`,
                postData
            ).then(response => {

                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-exclamation-triangle"></i>
                            {t('Pages.Templates.add_success_toast.Title')}
                        </div>
                        <div className="h6">{t('Pages.Templates.add_success_toast.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });

                this.handleClose();

            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-bug"></i>
                        {t('Pages.Templates.error_AllInformationIsRequired_toast.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Templates.error_AllInformationIsRequired_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    renderButton() {
        const { t, question } = this.props;
        if (question.typeId === 16) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {t('Pages.Lessons.Questions.forms.tools.buttonStyle')}
                    </div>
                    <ul className="filter mx-1 mb-3 px-1">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setButtonBackgroundColor}
                            setQuestionBackgroundImage={this.setButtonBackgroundImage}
                            setQuestionBgPositionX={this.setButtonBgPositionX}
                            setQuestionBgPositionY={this.setButtonBgPositionY}
                            setQuestionBgSize={this.setButtonBgSize}
                            setQuestionOpacity={this.setButtonOpacity}
                            setQuestionCustom={this.setButtonCustom}
                            setQuestionBgRepeat={this.setButtonBgRepeat}
                            setQuestionBgAttachment={this.setButtonBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setButtonBorderStyle}
                            setQuestionBorderColor={this.setButtonBorderColor}
                            setQuestionBorderWidth={this.setButtonBorderWidth}
                            setQuestionBorderWidthTop={this.setButtonBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setButtonBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setButtonBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setButtonBorderWidthBottom}
                            setQuestionBorderRadius={this.setButtonBorderRadius}
                            setQuestionBorderRadiusTop={this.setButtonBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setButtonBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setButtonBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setButtonBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setButtonBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setButtonShadowColor}
                            setQuestionShadowRadius={this.setButtonShadowRadius}
                            setQuestionShadowPositionX={this.setButtonShadowPositionX}
                            setQuestionShadowPositionY={this.setButtonShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setButtonPadding}
                            setQuestionPaddingTop={this.setButtonPaddingTop}
                            setQuestionPaddingLeft={this.setButtonPaddingLeft}
                            setQuestionPaddingRight={this.setButtonPaddingRight}
                            setQuestionPaddingBottom={this.setButtonPaddingBottom}
                        ></QuestionPadding>

                        <QuestionAnswer
                            hasButton={true}
                            hasCursor={true}
                            Cursor={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.Cursor : ""}
                            CursorCustom={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.CursorCustom : ""}
                            FontColor={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.FontColor : ""}
                            StrokeWidth={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.StrokeWidth : ""}
                            StrokeColor={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.StrokeColor : ""}
                            MarginBottom={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.MarginBottom : ""}
                            FontSize={this.state.CustomStyle.buttonStyle ? this.state.CustomStyle.buttonStyle.FontSize : ""}
                            setStrokeWidth={this.setButtonStrokeWidth}
                            setStrokeColor={this.setButtonStrokeColor}
                            setCursor={this.setButtonCursor}
                            setCursorCustom={this.setButtonCursorCustom}
                            setFontColor={this.setButtonFontColor}
                            setFontSize={this.setButtonFontSize}
                            setMarginBottom={this.setButtonMarginBottom}
                        ></QuestionAnswer>
                    </ul>
                </div>
            );
        }
        return "";
    }

    renderButtonHover() {
        const { t, question } = this.props;
        if (question.typeId === 16) {
            return (
                <div className="w-50 mx-auto">
                    <div className="h6 text-center title">
                        {t('Pages.Lessons.Questions.forms.tools.buttonHoverStyle')}
                    </div>
                    <ul className="filter mx-1 mb-3 px-1">
                        <QuestionBackground
                            BgSize={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BgSize : ''}
                            Custom={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.Custom : ''}
                            Opacity={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.Opacity : ''}
                            BgRepeat={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BgRepeat : ''}
                            BgPositionX={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BgPositionX : ''}
                            BgPositionY={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BgPositionY : ''}
                            BgAttachment={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BgAttachment : ''}
                            backgroundColor={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.backgroundColor : ''}
                            backgroundImage={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.backgroundImage : ''}
                            setQuestionBackgroundColor={this.setButtonHoverBackgroundColor}
                            setQuestionBackgroundImage={this.setButtonHoverBackgroundImage}
                            setQuestionBgPositionX={this.setButtonHoverBgPositionX}
                            setQuestionBgPositionY={this.setButtonHoverBgPositionY}
                            setQuestionBgSize={this.setButtonHoverBgSize}
                            setQuestionOpacity={this.setButtonHoverOpacity}
                            setQuestionCustom={this.setButtonHoverCustom}
                            setQuestionBgRepeat={this.setButtonHoverBgRepeat}
                            setQuestionBgAttachment={this.setButtonHoverBgAttachment}
                        ></QuestionBackground>
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderColor : ''}
                            BorderStyle={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderStyle : ''}
                            BorderWidth={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderWidth : ''}
                            BorderWidthTop={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderWidthTop : ''}
                            BorderWidthRight={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderWidthRight : ''}
                            BorderWidthLeft={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderWidthLeft : ''}
                            BorderWidthBottom={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderWidthBottom : ''}
                            BorderRadiusUnit={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderRadiusUnit : ''}
                            BorderRadius={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderRadius : ''}
                            BorderRadiusTop={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderRadiusTop : ''}
                            BorderRadiusRight={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderRadiusRight : ''}
                            BorderRadiusLeft={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderRadiusLeft : ''}
                            BorderRadiusBottom={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.BorderRadiusBottom : ''}
                            setQuestionBorderStyle={this.setButtonHoverBorderStyle}
                            setQuestionBorderColor={this.setButtonHoverBorderColor}
                            setQuestionBorderWidth={this.setButtonHoverBorderWidth}
                            setQuestionBorderWidthTop={this.setButtonHoverBorderWidthTop}
                            setQuestionBorderWidthLeft={this.setButtonHoverBorderWidthLeft}
                            setQuestionBorderWidthRight={this.setButtonHoverBorderWidthRight}
                            setQuestionBorderWidthBottom={this.setButtonHoverBorderWidthBottom}
                            setQuestionBorderRadius={this.setButtonHoverBorderRadius}
                            setQuestionBorderRadiusTop={this.setButtonHoverBorderRadiusTop}
                            setQuestionBorderRadiusLeft={this.setButtonHoverBorderRadiusLeft}
                            setQuestionBorderRadiusRight={this.setButtonHoverBorderRadiusRight}
                            setQuestionBorderRadiusBottom={this.setButtonHoverBorderRadiusBottom}
                            setQuestionBorderRadiusUnit={this.setButtonHoverBorderRadiusUnit}
                        ></QuestionBorder>
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.shadowColor : ''}
                            ShadowRadius={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.ShadowRadius : ''}
                            ShadowPositionX={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.ShadowPositionX : ''}
                            ShadowPositionY={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.ShadowPositionY : ''}
                            setQuestionShadowColor={this.setButtonHoverShadowColor}
                            setQuestionShadowRadius={this.setButtonHoverShadowRadius}
                            setQuestionShadowPositionX={this.setButtonHoverShadowPositionX}
                            setQuestionShadowPositionY={this.setButtonHoverShadowPositionY}
                        ></QuestionShadow>
                        <QuestionPadding
                            Padding={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.Padding : ''}
                            PaddingTop={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.PaddingTop : ''}
                            PaddingRight={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.PaddingRight : ''}
                            PaddingLeft={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.PaddingLeft : ''}
                            PaddingBottom={this.state.CustomStyle.buttonHoverStyle ? this.state.CustomStyle.buttonHoverStyle.PaddingBottom : ''}
                            setQuestionPadding={this.setButtonHoverPadding}
                            setQuestionPaddingTop={this.setButtonHoverPaddingTop}
                            setQuestionPaddingLeft={this.setButtonHoverPaddingLeft}
                            setQuestionPaddingRight={this.setButtonHoverPaddingRight}
                            setQuestionPaddingBottom={this.setButtonHoverPaddingBottom}
                        ></QuestionPadding>

                    </ul>
                </div>
            );
        }
        return "";
    }

    renderLogoStyle() {
        const { t, isGame } = this.props;
        if (isGame === true) {
            return (
                <div className="w-100 mx-auto">
                    <ul className="filter mx-1 mb-3 px-1">
                        <li>
                            {t('Pages.Lessons.Questions.forms.tools.LogoStyle')}
                        </li>
                        <QuestionBackground
                            BgSize={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgSize : ""}
                            Custom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Custom : ""}
                            Opacity={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Opacity : ""}
                            BgRepeat={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgRepeat : ""}
                            BgPositionX={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgPositionX : ""}
                            BgPositionY={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgPositionY : ""}
                            BgAttachment={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BgAttachment : ""}
                            backgroundColor={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.backgroundColor : ""}
                            backgroundImage={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.backgroundImage : ""}
                            setQuestionBackgroundColor={this.setBackgroundColorLogoStyle}
                            setQuestionBackgroundImage={this.setBackgroundImageLogoStyle}
                            setQuestionBgPositionX={this.setBgPositionXLogoStyle}
                            setQuestionBgPositionY={this.setBgPositionYLogoStyle}
                            setQuestionBgSize={this.setBgSizeLogoStyle}
                            setQuestionOpacity={this.setOpacityLogoStyle}
                            setQuestionCustom={this.setCustomLogoStyle}
                            setQuestionBgRepeat={this.setBgRepeatLogoStyle}
                            setQuestionBgAttachment={this.setBgAttachmentLogoStyle}
                        />
                        <QuestionBorder
                            BorderColor={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderColor : ""}
                            BorderStyle={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderStyle : ""}
                            BorderWidth={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidth : ""}
                            BorderWidthTop={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthTop : ""}
                            BorderWidthRight={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthRight : ""}
                            BorderWidthLeft={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthLeft : ""}
                            BorderWidthBottom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderWidthBottom : ""}
                            BorderRadiusUnit={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusUnit : ""}
                            BorderRadius={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadius : ""}
                            BorderRadiusTop={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusTop : ""}
                            BorderRadiusRight={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusRight : ""}
                            BorderRadiusLeft={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusLeft : ""}
                            BorderRadiusBottom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.BorderRadiusBottom : ""}
                            setQuestionBorderStyle={this.setBorderStyleLogoStyle}
                            setQuestionBorderColor={this.setBorderColorLogoStyle}
                            setQuestionBorderWidth={this.setBorderWidthLogoStyle}
                            setQuestionBorderWidthTop={this.setBorderWidthTopLogoStyle}
                            setQuestionBorderWidthLeft={this.setBorderWidthLeftLogoStyle}
                            setQuestionBorderWidthRight={this.setBorderWidthRightLogoStyle}
                            setQuestionBorderWidthBottom={this.setBorderWidthBottomLogoStyle}
                            setQuestionBorderRadius={this.setBorderRadiusLogoStyle}
                            setQuestionBorderRadiusTop={this.setBorderRadiusTopLogoStyle}
                            setQuestionBorderRadiusLeft={this.setBorderRadiusLeftLogoStyle}
                            setQuestionBorderRadiusRight={this.setBorderRadiusRightLogoStyle}
                            setQuestionBorderRadiusBottom={this.setBorderRadiusBottomLogoStyle}
                            setQuestionBorderRadiusUnit={this.setBorderRadiusUnitLogoStyle}
                        />
                        <QuestionShadow
                            shadowColor={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.shadowColor : ""}
                            ShadowRadius={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.ShadowRadius : ""}
                            ShadowPositionX={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.ShadowPositionX : ""}
                            ShadowPositionY={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.ShadowPositionY : ""}
                            setQuestionShadowColor={this.setShadowColorLogoStyle}
                            setQuestionShadowRadius={this.setShadowRadiusLogoStyle}
                            setQuestionShadowPositionX={this.setShadowPositionXLogoStyle}
                            setQuestionShadowPositionY={this.setShadowPositionYLogoStyle}
                        />
                        <QuestionPadding
                            Padding={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Padding : ""}
                            PaddingTop={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingTop : ""}
                            PaddingRight={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingRight : ""}
                            PaddingLeft={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingLeft : ""}
                            PaddingBottom={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PaddingBottom : ""}
                            setQuestionPadding={this.setPaddingLogoStyle}
                            setQuestionPaddingTop={this.setPaddingTopLogoStyle}
                            setQuestionPaddingLeft={this.setPaddingLeftLogoStyle}
                            setQuestionPaddingRight={this.setPaddingRightLogoStyle}
                            setQuestionPaddingBottom={this.setPaddingBottomLogoStyle}
                        />

                        <QuestionAnswer
                            Margin={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.Margin : ""}
                            setLogoMargin={this.setLogoMargin}
                            CustomBackground={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.CustomBackground : ""}
                            setCustomBackground={this.setCustomBackgroundLogoStyle}
                            PositionY={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PositionY : ""}
                            setLogoPositionY={this.setPositionYLogoStyle}
                            PositionX={this.state.CustomStyle && this.state.CustomStyle.LogoStyle ? this.state.CustomStyle.LogoStyle.PositionX : ""}
                            setLogoPositionX={this.setPositionXLogoStyle}
                        />
                    </ul>
                </div>
            );
        }
        return "";
    }

    renderLogo = () => {
        let style = {};
        let styleBackground = {};
        let margin = 2;
        let Custom = "";
        let logoSrc = "/assets/images/logo/black.png";
        let position = {
            bottom: 0,
            right: 0,
            zIndex: 14
        };
        if (this.state.CustomStyle && this.state.CustomStyle.LogoStyle) {
            style = {
                minHeight: `${this.state.CustomStyle.LogoStyle.height}px`,
                width: `${this.state.CustomStyle.LogoStyle.width}%`,
                maxWidth: '100%',
                color: this.state.CustomStyle.LogoStyle.FontColor,
                paddingLeft: `${this.state.CustomStyle.LogoStyle.PaddingLeft}px`,
                paddingTop: `${this.state.CustomStyle.LogoStyle.PaddingTop}px`,
                paddingRight: `${this.state.CustomStyle.LogoStyle.PaddingRight}px`,
                paddingBottom: `${this.state.CustomStyle.LogoStyle.PaddingBottom}px`,
                borderColor: this.state.CustomStyle.LogoStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.LogoStyle.BorderColor : "",
                borderStyle: `${this.state.CustomStyle.LogoStyle.BorderStyle}`,
                borderLeftWidth: `${this.state.CustomStyle.LogoStyle.BorderWidthLeft}px`,
                borderRightWidth: `${this.state.CustomStyle.LogoStyle.BorderWidthRight}px`,
                borderTopWidth: `${this.state.CustomStyle.LogoStyle.BorderWidthTop}px`,
                borderBottomWidth: `${this.state.CustomStyle.LogoStyle.BorderWidthBottom}px`,
                borderRadius: `${this.state.CustomStyle.LogoStyle.BorderRadiusTop}${this.state.CustomStyle.LogoStyle.BorderRadiusUnit} ${this.state.CustomStyle.LogoStyle.BorderRadiusRight}${this.state.CustomStyle.LogoStyle.BorderRadiusUnit} ${this.state.CustomStyle.LogoStyle.BorderRadiusBottom}${this.state.CustomStyle.LogoStyle.BorderRadiusUnit} ${this.state.CustomStyle.LogoStyle.BorderRadiusLeft}${this.state.CustomStyle.LogoStyle.BorderRadiusUnit}`,
                boxShadow: `${this.state.CustomStyle.LogoStyle.ShadowPositionX}px ${this.state.CustomStyle.LogoStyle.ShadowPositionY}px ${this.state.CustomStyle.LogoStyle.ShadowRadius}px 0px ${this.state.CustomStyle.LogoStyle.shadowColor}`,
                marginBottom: `${this.state.CustomStyle.LogoStyle.Margin}px`,
                zIndex: 9
            };

            styleBackground = {
                height: `100%`,
                opacity: `${this.state.CustomStyle.LogoStyle.Opacity}%`,
                backgroundColor: this.state.CustomStyle.LogoStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.LogoStyle.backgroundColor : "",
                backgroundImage: this.state.CustomStyle.LogoStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.LogoStyle.backgroundImage}')` : "",
                backgroundPositionX: `${this.state.CustomStyle.LogoStyle.BgPositionX}%`,
                backgroundPositionY: `${this.state.CustomStyle.LogoStyle.BgPositionY}%`,
                backgroundSize: `${this.state.CustomStyle.LogoStyle.BgSize}`,
                backgroundRepeat: `${this.state.CustomStyle.LogoStyle.BgRepeat}`,
                backgroundAttachment: `${this.state.CustomStyle.LogoStyle.BgAttachment}`,
                marginLeft: `-${this.state.CustomStyle.LogoStyle.PaddingLeft}px`,
                marginTop: `-${this.state.CustomStyle.LogoStyle.PaddingTop}px`,
                marginRight: `-${this.state.CustomStyle.LogoStyle.PaddingRight}px`,
                marginBottom: `-${this.state.CustomStyle.LogoStyle.PaddingBottom}px`,
                zIndex: -1
            };
            Custom = this.state.CustomStyle.LogoStyle.Custom;

            if (this.state.CustomStyle.LogoStyle.CustomBackground) {
                logoSrc = this.state.CustomStyle.LogoStyle.CustomBackground;
            }
            if (this.state.CustomStyle.LogoStyle.Margin) {
                margin = this.state.CustomStyle.LogoStyle.Margin;
            }

            position = {
                zIndex: 14
            };
            if (this.state.CustomStyle.LogoStyle.PositionX === "left") {
                position = {
                    ...position,
                    left: 0
                };
            } else {
                position = {
                    ...position,
                    right: 0
                };
            }

            if (this.state.CustomStyle.LogoStyle.PositionY === "top") {
                position = {
                    ...position,
                    top: 0
                };
            } else {
                position = {
                    ...position,
                    bottom: 0
                };
            }
        }
        return <div className={`position-absolute m-${margin}`} id="game-sub-logo" style={position}>
            <div className={`position-relative overflow-hidden`} style={style}>
                <style dangerouslySetInnerHTML={{ __html: `#game-sub-logo{${Custom} }` }}></style>
                <div className="w-100 position-absolute" style={styleBackground} ></div>
                <img src={logoSrc} alt={``} />
            </div>
        </div>;
    }

    render() {
        const { t, templates } = this.props;

        let isLoaded = false;
        if (templates) {
            isLoaded = templates.isLoaded;
        }
        return (
            <>
                {
                    this.state.showSaveQuestionStyleModal ?
                        <Modal
                            size="md"
                            show={this.state.showSaveQuestionStyleModal}
                            onHide={() => this.handleClose()}
                            backdrop="static"
                            keyboard={false}
                            centered
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-cloud-upload"></i> {t('Pages.Lessons.Questions.forms.tools.uploadStyle')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="contact-form">
                                    <div className="form-group w-100">
                                        <label className="text-dark font-size-h5" htmlFor="Title">
                                            <i className="icon fas fa-heading"></i>
                                            {t('Pages.Templates.form.Title.title')}
                                        </label>
                                        <input
                                            ref={Title => this.Title = Title}
                                            id="Title"
                                            name="Title"
                                            type="text"
                                            required={true}
                                            onChange={e => this.setTitle(e.target.value)}
                                            placeholder={t('Pages.Templates.form.Title.placeholder')}
                                            title={t('Pages.Templates.form.Title.title')}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group w-100 text-center">
                                        <button className="custom-button" title={t('Save')} type="button" onClick={() => this.uploadStyle()}>
                                            <span>
                                                <i className="icon far fa-save"></i>
                                                {t('Save')}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={() => this.handleClose()}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                {
                    this.state.showDownloadQuestionStyleModal ?
                        <Modal
                            size="xl"
                            show={this.state.showDownloadQuestionStyleModal}
                            onHide={() => this.handleClose()}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-cloud-download"></i>
                                    {t('Pages.Lessons.Questions.forms.tools.downloadStyle')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    isLoaded === false ?
                                        <div className="product-list-item" >
                                            <div className="product-content w-100 text-center ">
                                                <div className="display-1 text-danger">
                                                    <i className="fad fa-sync-alt fa-spin"></i>
                                                </div>
                                                <h4 className="display-3 subtitle">
                                                    {t("Pages.Templates.Loading")}
                                                </h4>
                                            </div>
                                        </div> :
                                        templates.templates.map((item, key) =>
                                            <ListItem copyQuestionStyle={this.copyQuestionStyle} key={key} item={item} IsAdmin={false}></ListItem>
                                        )
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={() => this.handleClose()}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                {
                    this.state.showCopyStyleModal ?
                        <Modal
                            size="xl"
                            show={this.state.showCopyStyleModal}
                            onHide={() => this.handleClose()}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-copy"></i> {t('Pages.Lessons.Questions.forms.tools.copyStyle')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row p-3 mx-2" style={{
                                    background: 'rgb(246, 250, 249)',
                                    border: '5px dashed rgb(255, 255, 255)'
                                }}>
                                    {this.renderCopyStyleModal()}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={() => this.handleClose()}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }

                <div className="position-relative w-100">
                    {
                        this.props.isGame !== true ?
                            <div className="w-100 ">
                                <ul className="filter mb-2">
                                    <li>
                                        <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                            <input
                                                type='radio'
                                                defaultChecked={true}
                                                onChange={e => this.handleSizeView(100)}
                                                className="custom-control-input"
                                                name="fullPage"
                                                id="fullPage" />
                                            <label className="custom-control-label pt-1" htmlFor="fullPage" >
                                                <i className="icon fal fa-book-open"></i>
                                                {t('Pages.Lessons.Questions.forms.tools.fullPage')}
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                            <input
                                                type='radio'
                                                onChange={e => this.handleSizeView(50)}
                                                className="custom-control-input"
                                                name="fullPage"
                                                id="halfPage" />
                                            <label className="custom-control-label pt-1" htmlFor="halfPage" >
                                                <i className="icon fad fa-book-open"></i>
                                                {t('Pages.Lessons.Questions.forms.tools.halfPage')}
                                            </label>
                                        </div>
                                    </li>
                                    <li title={t('Pages.Lessons.Questions.forms.tools.uploadStyle')} style={{
                                        flexGrow: 0
                                    }} onClick={() => this.showSaveQuestionStyle()}>
                                        <i className="fad fa-cloud-upload" style={{
                                            fontSize: '24px',
                                            verticalAlign: 'sub'
                                        }}></i>
                                    </li>
                                    <li title={t('Pages.Lessons.Questions.forms.tools.downloadStyle')} style={{
                                        flexGrow: 0
                                    }} onClick={() => this.showDownloadQuestionStyle()}>
                                        <i className="fad fa-cloud-download" style={{
                                            fontSize: '24px',
                                            verticalAlign: 'sub'
                                        }}></i>
                                    </li>
                                    <li title={t('Pages.Lessons.Questions.forms.tools.copyStyle')} style={{
                                        flexGrow: 0
                                    }} onClick={() => this.showCopyQuestionStyle()}>
                                        <i className="fad fa-copy" style={{
                                            fontSize: '24px',
                                            verticalAlign: 'sub'
                                        }}></i>
                                    </li>
                                </ul>
                            </div>
                            : ""
                    }

                    <div className="w-100">
                        <div className="h6 text-center title d-none">
                            {t('Pages.Lessons.Questions.forms.tools.questionStyle')}
                        </div>
                        <ul className="filter mb-2" title={t('Pages.Lessons.Questions.forms.tools.questionStyle')}>
                            {
                                this.props.isGame === true ?
                                    <>
                                        <QuestionBackground
                                            title={t('Pages.Lessons.Questions.forms.tools.Screenbackground')}
                                            typeId={this.props.question.typeId}
                                            BgSize={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgSize : ""}
                                            Custom={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.Custom : ""}
                                            Opacity={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.Opacity : ""}
                                            BgRepeat={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgRepeat : ""}
                                            BgPositionX={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgPositionX : ""}
                                            BgPositionY={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgPositionY : ""}
                                            BgAttachment={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgAttachment : ""}
                                            backgroundColor={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.backgroundColor : ""}
                                            backgroundImage={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.backgroundImage : ""}
                                            CustomBackground={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.CustomBackground : ""}
                                            setQuestionCustomBackground={this.setGameCustomBackground}
                                            setQuestionBackgroundColor={this.setGameBackgroundColor}
                                            setQuestionBackgroundImage={this.setGameBackgroundImage}
                                            setQuestionBgPositionX={this.setGameBgPositionX}
                                            setQuestionBgPositionY={this.setGameBgPositionY}
                                            setQuestionBgSize={this.setGameBgSize}
                                            setQuestionOpacity={this.setGameOpacity}
                                            setQuestionCustom={this.setGameCustom}
                                            setQuestionBgRepeat={this.setGameBgRepeat}
                                            setQuestionBgAttachment={this.setGameBgAttachment}
                                        />
                                        <GameSound
                                            StartSound={this.state.CustomStyle.gamesSetting.StartSound}
                                            CustomStartSound={this.state.CustomStyle.gamesSetting.CustomStartSound}
                                            setGameStartSound={this.setGameStartSound}
                                            setGameCustomStartSound={this.setGameCustomStartSound}

                                            GameOverSound={this.state.CustomStyle.gamesSetting.GameOverSound}
                                            CustomGameOverSound={this.state.CustomStyle.gamesSetting.CustomGameOverSound}
                                            setGameOverSound={this.setGameOverSound}
                                            setCustomGameOverSound={this.setCustomGameOverSound}

                                            BonusSound={this.state.CustomStyle.gamesSetting.BonusSound}
                                            CustomBonusSound={this.state.CustomStyle.gamesSetting.CustomBonusSound}
                                            setGameBonusSound={this.setBonusSound}
                                            setCustomGameBonusSound={this.setCustomBonusSound}


                                            BonusTimeSound={this.state.CustomStyle.gamesSetting.BonusTimeSound}
                                            CustomBonusTimeSound={this.state.CustomStyle.gamesSetting.CustomBonusTimeSound}
                                            setGameBonusTimeSound={this.setBonusTimeSound}
                                            setCustomGameBonusTimeSound={this.setCustomBonusTimeSound}

                                            WinSound={this.state.CustomStyle.gamesSetting.WinSound}
                                            CustomWinSound={this.state.CustomStyle.gamesSetting.CustomWinSound}
                                            setGameWinSound={this.setGameWinSound}
                                            setWinGameCustomSound={this.setWinGameCustomSound}

                                            StageSound={this.state.CustomStyle.gamesSetting.StageSound}
                                            CustomStageSound={this.state.CustomStyle.gamesSetting.CustomStageSound}
                                            setGameStageSound={this.setGameStageSound}
                                            setStageGameCustomSound={this.setStageGameCustomSound}

                                            BackgroundSound={this.state.CustomStyle.gamesSetting.BackgroundSound}
                                            CustomBackgroundSound={this.state.CustomStyle.gamesSetting.CustomBackgroundSound}
                                            setGameBackgroundSound={this.setGameBackgroundSound}
                                            setBackgroundGameCustomSound={this.setBackgroundGameCustomSound}

                                            TrueSound={this.state.CustomStyle.gamesSetting.TrueSound}
                                            CustomTrueSound={this.state.CustomStyle.gamesSetting.CustomTrueSound}
                                            setQuestionTrueSound={this.setGameTrueSound}
                                            setQuestionCustomTrueSound={this.setGameCustomTrueSound}
                                            FalseSound={this.state.CustomStyle.gamesSetting.FalseSound}
                                            CustomFalseSound={this.state.CustomStyle.gamesSetting.CustomFalseSound}
                                            setQuestionFalseSound={this.setGameFalseSound}
                                            setQuestionCustomFalseSound={this.setGameCustomFalseSound}
                                            Confetti={this.state.CustomStyle.gamesSetting.Confetti}
                                            CustomConfetti={this.state.CustomStyle.gamesSetting.CustomConfetti}
                                            setQuestionConfetti={this.setGameConfetti}
                                            setQuestionCustomConfetti={this.setGameCustomConfetti}
                                            ConfettiFalse={this.state.CustomStyle.gamesSetting.ConfettiFalse}
                                            CustomConfettiFalse={this.state.CustomStyle.gamesSetting.CustomConfettiFalse}
                                            setQuestionConfettiFalse={this.setGameConfettiFalse}
                                            setQuestionCustomConfettiFalse={this.setGameCustomConfettiFalse}

                                        />
                                        <BackgroundGameObject
                                            BgSize={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgSize : ""}
                                            Custom={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.Custom : ""}
                                            Opacity={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.Opacity : ""}
                                            BgRepeat={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgRepeat : ""}
                                            BgPositionX={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgPositionX : ""}
                                            BgPositionY={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgPositionY : ""}
                                            BgAttachment={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BgAttachment : ""}
                                            backgroundColor={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.backgroundColor : ""}
                                            backgroundImage={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.backgroundImage : ""}
                                            BackgroundGameObject={this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.BackgroundGameObject : []}
                                            setBackgroundGameObject={this.setBackgroundGameObject}
                                        />
                                        <GameSetting
                                            typeId={this.props.question.typeId}
                                            MoveObject={this.state.CustomStyle.gamesSetting.MoveObject}
                                            MoveObjectSrc={this.state.CustomStyle.gamesSetting.MoveObjectSrc}
                                            MoveObjectSrcHover={this.state.CustomStyle.gamesSetting.MoveObjectSrcHover}
                                            MoveCustomColor={this.state.CustomStyle.gamesSetting.MoveCustomColor}
                                            DropObject={this.state.CustomStyle.gamesSetting.DropObject}
                                            DropObjectSrc={this.state.CustomStyle.gamesSetting.DropObjectSrc}
                                            DropObjectSrcHover={this.state.CustomStyle.gamesSetting.DropObjectSrcHover}
                                            DropCustomColor={this.state.CustomStyle.gamesSetting.DropCustomColor}
                                            TryCount={this.state.CustomStyle.gamesSetting.TryCount}
                                            Time={this.state.CustomStyle.gamesSetting.Time}
                                            TryShape={this.state.CustomStyle.gamesSetting.TryShape}
                                            TryColor={this.state.CustomStyle.gamesSetting.TryColor}
                                            TryColorCustom={this.state.CustomStyle.gamesSetting.TryColorCustom}
                                            Cursor={this.state.CustomStyle.gamesSetting.Cursor}
                                            CursorCustom={this.state.CustomStyle.gamesSetting.CursorCustom}
                                            ExplosionObject={this.state.CustomStyle.gamesSetting.ExplosionObject}
                                            IconType={this.state.CustomStyle.gamesSetting.IconType ? this.state.CustomStyle.gamesSetting.IconType : "fad"}
                                            Rotate={this.state.CustomStyle.gamesSetting.Rotate}
                                            FlipX={this.state.CustomStyle.gamesSetting.FlipX}
                                            FlipY={this.state.CustomStyle.gamesSetting.FlipY}
                                            TranslateX={this.state.CustomStyle.gamesSetting.TranslateX}
                                            TranslateTime={this.state.CustomStyle.gamesSetting.TranslateTime}
                                            ShootObjectSrc={this.state.CustomStyle.gamesSetting.ShootObjectSrc}
                                            ShootObjectSrcHover={this.state.CustomStyle.gamesSetting.ShootObjectSrcHover}
                                            RopeColor={this.state.CustomStyle.gamesSetting.RopeColor}
                                            OffsetTop={this.state.CustomStyle.gamesSetting.OffsetTop}
                                            MaxTop={this.state.CustomStyle.gamesSetting.MaxTop}
                                            MinTop={this.state.CustomStyle.gamesSetting.MinTop}
                                            OffsetTopObject={this.state.CustomStyle.gamesSetting.OffsetTopObject}
                                            ShootObjectSound={this.state.CustomStyle.gamesSetting.ShootObjectSound}
                                            ShootObjectOption={this.state.CustomStyle.gamesSetting.ShootObjectOption}
                                            TransformOriginX={this.state.CustomStyle.gamesSetting.TransformOriginX}
                                            TransformOriginY={this.state.CustomStyle.gamesSetting.TransformOriginY}
                                            setTransformOriginX={this.setTransformOriginX}
                                            setTransformOriginY={this.setTransformOriginY}
                                            setGameIconType={this.setGameIconType}
                                            setGameMoveObject={this.setGameMoveObject}
                                            setGameMoveObjectSrc={this.setGameMoveObjectSrc}
                                            setGameMoveObjectSrcHover={this.setGameMoveObjectSrcHover}
                                            setGameDropObject={this.setGameDropObject}
                                            setGameDropObjectSrc={this.setGameDropObjectSrc}
                                            setGameDropObjectSrcHover={this.setGameDropObjectSrcHover}
                                            setGameTryCount={this.setGameTryCount}
                                            setGameTime={this.setGameTime}
                                            setGameTryShape={this.setGameTryShape}
                                            setGameTryColor={this.setGameTryColor}
                                            setGameTryColorCustom={this.setGameTryColorCustom}
                                            setGameExplosionObject={this.setGameExplosionObject}
                                            setMoveCustomColor={this.setMoveCustomColor}
                                            setDropCustomColor={this.setDropCustomColor}
                                            setCursor={this.setCursor}
                                            setCursorCustom={this.setCursorCustom}
                                            setGameMoveObjectRotate={this.setGameMoveObjectRotate}
                                            setGameMoveObjectFlipX={this.setGameMoveObjectFlipX}
                                            setGameMoveObjectFlipY={this.setGameMoveObjectFlipY}
                                            setGameTranslateTime={this.setGameTranslateTime}
                                            setGameShootObjectSrc={this.setGameShootObjectSrc}
                                            setGameShootObjectSrcHover={this.setGameShootObjectSrcHover}
                                            setGameyRopeColor={this.setGameyRopeColor}
                                            setGameOffsetTop={this.setGameOffsetTop}
                                            setGameOffsetTopObject={this.setGameOffsetTopObject}
                                            setGameTranslateX={this.setGameTranslateX}
                                            setGameMaxTop={this.setGameMaxTop}
                                            setGameMinTop={this.setGameMinTop}
                                            setShootObjectSound={this.setShootObjectSound}
                                            setGameShootObjectOption={this.setGameShootObjectOption}
                                        />
                                        <li title={t('Pages.Lessons.Questions.forms.tools.copyStyle')} style={{
                                            flexGrow: 0
                                        }} onClick={() => this.showCopyQuestionStyle()}>
                                            <i className="fad fa-copy" style={{
                                                fontSize: '24px',
                                                verticalAlign: 'sub'
                                            }}></i>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <QuestionBackground
                                            typeId={this.props.question.typeId}
                                            BgSize={this.state.CustomStyle.questionStyle.BgSize}
                                            Custom={this.state.CustomStyle.questionStyle.Custom}
                                            Opacity={this.state.CustomStyle.questionStyle.Opacity}
                                            BgRepeat={this.state.CustomStyle.questionStyle.BgRepeat}
                                            BgPositionX={this.state.CustomStyle.questionStyle.BgPositionX}
                                            BgPositionY={this.state.CustomStyle.questionStyle.BgPositionY}
                                            BgAttachment={this.state.CustomStyle.questionStyle.BgAttachment}
                                            backgroundColor={this.state.CustomStyle.questionStyle.backgroundColor}
                                            backgroundImage={this.state.CustomStyle.questionStyle.backgroundImage}
                                            setQuestionBackgroundColor={this.setQuestionBackgroundColor}
                                            setQuestionBackgroundImage={this.setQuestionBackgroundImage}
                                            setQuestionBgPositionX={this.setQuestionBgPositionX}
                                            setQuestionBgPositionY={this.setQuestionBgPositionY}
                                            setQuestionBgSize={this.setQuestionBgSize}
                                            setQuestionOpacity={this.setQuestionOpacity}
                                            setQuestionCustom={this.setQuestionCustom}
                                            setQuestionBgRepeat={this.setQuestionBgRepeat}
                                            setQuestionBgAttachment={this.setQuestionBgAttachment}
                                        ></QuestionBackground>

                                        <QuestionBorder
                                            BorderColor={this.state.CustomStyle.questionStyle.BorderColor}
                                            BorderStyle={this.state.CustomStyle.questionStyle.BorderStyle}
                                            BorderWidth={this.state.CustomStyle.questionStyle.BorderWidth}
                                            BorderWidthTop={this.state.CustomStyle.questionStyle.BorderWidthTop}
                                            BorderWidthRight={this.state.CustomStyle.questionStyle.BorderWidthRight}
                                            BorderWidthLeft={this.state.CustomStyle.questionStyle.BorderWidthLeft}
                                            BorderWidthBottom={this.state.CustomStyle.questionStyle.BorderWidthBottom}
                                            BorderRadiusUnit={this.state.CustomStyle.questionStyle.BorderRadiusUnit}
                                            BorderRadius={this.state.CustomStyle.questionStyle.BorderRadius}
                                            BorderRadiusTop={this.state.CustomStyle.questionStyle.BorderRadiusTop}
                                            BorderRadiusRight={this.state.CustomStyle.questionStyle.BorderRadiusRight}
                                            BorderRadiusLeft={this.state.CustomStyle.questionStyle.BorderRadiusLeft}
                                            BorderRadiusBottom={this.state.CustomStyle.questionStyle.BorderRadiusBottom}
                                            setQuestionBorderStyle={this.setQuestionBorderStyle}
                                            setQuestionBorderColor={this.setQuestionBorderColor}
                                            setQuestionBorderWidth={this.setQuestionBorderWidth}
                                            setQuestionBorderWidthTop={this.setQuestionBorderWidthTop}
                                            setQuestionBorderWidthLeft={this.setQuestionBorderWidthLeft}
                                            setQuestionBorderWidthRight={this.setQuestionBorderWidthRight}
                                            setQuestionBorderWidthBottom={this.setQuestionBorderWidthBottom}
                                            setQuestionBorderRadius={this.setQuestionBorderRadius}
                                            setQuestionBorderRadiusTop={this.setQuestionBorderRadiusTop}
                                            setQuestionBorderRadiusLeft={this.setQuestionBorderRadiusLeft}
                                            setQuestionBorderRadiusRight={this.setQuestionBorderRadiusRight}
                                            setQuestionBorderRadiusBottom={this.setQuestionBorderRadiusBottom}
                                            setQuestionBorderRadiusUnit={this.setQuestionBorderRadiusUnit}
                                        ></QuestionBorder>
                                        <QuestionShadow
                                            shadowColor={this.state.CustomStyle.questionStyle.shadowColor}
                                            ShadowRadius={this.state.CustomStyle.questionStyle.ShadowRadius}
                                            ShadowPositionX={this.state.CustomStyle.questionStyle.ShadowPositionX}
                                            ShadowPositionY={this.state.CustomStyle.questionStyle.ShadowPositionY}
                                            setQuestionShadowColor={this.setQuestionShadowColor}
                                            setQuestionShadowRadius={this.setQuestionShadowRadius}
                                            setQuestionShadowPositionX={this.setQuestionShadowPositionX}
                                            setQuestionShadowPositionY={this.setQuestionShadowPositionY}
                                        ></QuestionShadow>
                                        <QuestionPadding
                                            Padding={this.state.CustomStyle.questionStyle.Padding}
                                            PaddingTop={this.state.CustomStyle.questionStyle.PaddingTop}
                                            PaddingRight={this.state.CustomStyle.questionStyle.PaddingRight}
                                            PaddingLeft={this.state.CustomStyle.questionStyle.PaddingLeft}
                                            PaddingBottom={this.state.CustomStyle.questionStyle.PaddingBottom}
                                            setQuestionPadding={this.setQuestionPadding}
                                            setQuestionPaddingTop={this.setQuestionPaddingTop}
                                            setQuestionPaddingLeft={this.setQuestionPaddingLeft}
                                            setQuestionPaddingRight={this.setQuestionPaddingRight}
                                            setQuestionPaddingBottom={this.setQuestionPaddingBottom}
                                        ></QuestionPadding>
                                    </>
                            }
                            {
                                this.props.question.typeId !== 11 && this.props.isGame !== true ?
                                    <QuestionSound
                                        TrueSound={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.TrueSound : null}
                                        CustomTrueSound={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.CustomTrueSound : null}
                                        setQuestionTrueSound={this.setQuestionTrueSound}
                                        setQuestionCustomTrueSound={this.setQuestionCustomTrueSound}
                                        FalseSound={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.FalseSound : null}
                                        CustomFalseSound={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.CustomFalseSound : null}
                                        setQuestionFalseSound={this.setQuestionFalseSound}
                                        setQuestionCustomFalseSound={this.setQuestionCustomFalseSound}
                                        Confetti={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.Confetti : null}
                                        CustomConfetti={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.CustomConfetti : null}
                                        setQuestionConfetti={this.setQuestionConfetti}
                                        setQuestionCustomConfetti={this.setQuestionCustomConfetti}
                                        ConfettiFalse={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.ConfettiFalse : null}
                                        CustomConfettiFalse={this.state.CustomStyle.questionSound ? this.state.CustomStyle.questionSound.CustomConfettiFalse : null}
                                        setQuestionConfettiFalse={this.setQuestionConfettiFalse}
                                        setQuestionCustomConfettiFalse={this.setQuestionCustomConfettiFalse}
                                    ></QuestionSound>
                                    : ""
                            }
                            {
                                this.props.isGame !== true ?
                                    <>
                                        <QuestionAnswer
                                            typeId={this.props.question.typeId}
                                            width={this.state.CustomStyle.questionStyle.width}
                                            height={this.state.CustomStyle.questionStyle.height}
                                            AnswerOrder={this.state.CustomStyle.questionTypeStyle.AnswerOrder}
                                            AnswersCols={this.state.CustomStyle.questionTypeStyle.AnswersCols}
                                            AnswersSeparated={this.state.CustomStyle.questionTypeStyle.AnswersSeparated}
                                            AnswersSelectType={this.state.CustomStyle.questionTypeStyle.AnswersSelectType}
                                            AnswersSelectClass={this.state.CustomStyle.questionTypeStyle.AnswersSelectClass}
                                            BorderWidth={this.state.CustomStyle.questionTypeStyle.BorderWidth}
                                            BorderStyle={this.state.CustomStyle.questionTypeStyle.BorderStyle}
                                            BorderColor={this.state.CustomStyle.questionTypeStyle.BorderColor}
                                            AnswersMargin={this.state.CustomStyle.questionTypeStyle.AnswersMargin}
                                            AnswersColsMargin={this.state.CustomStyle.questionTypeStyle.AnswersColsMargin}
                                            AnswersAlign={this.state.CustomStyle.questionTypeStyle.AnswersAlign}
                                            ColsWidthLeft={this.state.CustomStyle.questionTypeStyle.ColsWidthLeft}
                                            ColsWidthRight={this.state.CustomStyle.questionTypeStyle.ColsWidthRight}
                                            AutoCorrection={this.state.CustomStyle.questionTypeStyle.AutoCorrection}
                                            AnswersSelectTemplate={this.state.CustomStyle.questionTypeStyle.AnswersSelectTemplate}
                                            setQuestionWidth={this.setQuestionWidth}
                                            setQuestionHeight={this.setQuestionHeight}
                                            setQuestionAnswerOrder={this.setQuestionAnswerOrder}
                                            setQuestionAnswersAlign={this.setQuestionAnswersAlign}
                                            setQuestionAutoCorrection={this.setQuestionAutoCorrection}
                                            setQuestionAnswersCols={this.setQuestionAnswersCols}
                                            setQuestionAnswersSelectType={this.setQuestionAnswersSelectType}
                                            setQuestionAnswersSelectClass={this.setQuestionAnswersSelectClass}
                                            setQuestionAnswersSeparated={this.setQuestionAnswersSeparated}
                                            setQuestionAnswersSelectTemplate={this.setQuestionAnswersSelectTemplate}

                                            setQuestionAnswersColsMargin={this.setAnswersColsMargin}
                                            setColsWidthRight={this.setColsWidthRight}
                                            setColsWidthLeft={this.setColsWidthLeft}

                                            setQuestionBorderStyle={this.setQuestionTypeBorderStyle}
                                            setQuestionBorderColor={this.setQuestionTypeBorderColor}
                                            setQuestionBorderWidth={this.setQuestionTypeBorderWidth}
                                            setQuestionAnswersMargin={this.setQuestionAnswersMargin}

                                        ></QuestionAnswer>
                                    </> : ""
                            }
                        </ul>
                    </div>

                    <div className="d-flex flex-wrap">
                        {this.renderLogoStyle()}
                        {this.renderCategoryList()}
                        {this.renderCategoryStyleList()}
                        {this.renderAnswerStyleList()}

                        {this.renderAnswerBoxStyleList()}
                        {this.renderFillStyle()}
                        {this.renderCategoryHoverStyleList()}

                    </div>
                    <div className="w-100 position-relative">
                        {
                            this.props.isGame === true && this.state.CustomStyle && this.state.CustomStyle.LogoStyle ?
                                this.renderLogo()
                                : ""
                        }
                        <QuestionView
                            updateVersion={this.updateVersion}
                            question={this.props.question.question}
                            key={this.props.QuestionId}
                            index={1}
                            onEdit={true}
                            solveButton={false}
                            FontSize={this.props.FontSize}
                            version={this.state.version}
                            setQuestionAnswerPosition={this.setQuestionAnswerPosition}
                            setQuestionDesignerContainer={this.setQuestionDesignerContainer}
                            SizeView={this.state.SizeView} />
                    </div>
                </div>
                <div className="form-group w-100 text-center">
                    <button className="custom-button" title={t('Pages.Lessons.Questions.forms.tools.saveStyle')} type="button" onClick={() => this.saveStyle()}>
                        <span>
                            <i className="icon far fa-save"></i>
                            {t('Pages.Lessons.Questions.forms.tools.saveStyle')}
                        </span>
                    </button>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons,
        question: state.questions,
        templates: state.templates
    };
};

export default connect(mapStateToProps, { updateQuestion, fetchTemplates })(withTranslation('common')(SelectDesigner));
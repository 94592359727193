import React from "react";
import TextBox from "../../TextBox";
import './style.scss';

class SubmarineFish extends React.Component {

    render() {
        return (
            <div className="">
                <div className="SubmarineFish d-block-inline ">
                    <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                        <div style={{
                            transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                            transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                        }}>
                            <div className="circle-wrapper mx-auto">
                                <div className="submarine-wrapper">
                                    <div className="submarine-body">
                                        <div className="window"></div>
                                        <div className="engine"></div>
                                        <div className="light"></div>
                                    </div>
                                    <div className="helix"></div>
                                    <div className="hat">
                                        <div className="leds-wrapper">
                                            <div className="periscope"></div>
                                            <div className="leds"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <TextBox {...this.props} />
            </div>
        );
    }
}

export default SubmarineFish;

import React from "react";
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class BackgroundGameObjectDragDrop extends React.Component {

    state = { items: [] };
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.items) {
            state.items = props.items;
        }
        return state;
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });

        this.props.setSort(items);
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    getListStyle = isDraggingOver => ({
        // background: !isDraggingOver ? "rgb(246 250 249)" : "#fff",
        padding: 10,
        border: ' 5px dashed rgb(255, 255, 255)'
    });

    getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: '2px 5px',
        margin: `0 0 ${8}px 0`,
        position: 'static !important',
        // change background colour if dragging
        borderBottom: isDragging ? "2px dashed lightgreen" : "2px solid rgb(14, 44, 83)",
    });

    render() {
        const { t } = this.props;

        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd} className="position-relative align-self-cente  ">
                    <Droppable droppableId="droppable" >
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="sortedItem w-100 p-0 m-0"
                                style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.items.map((item, index) => (
                                    <Draggable key={index} draggableId={`${index}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={this.getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className="subItem"
                                            >
                                                <div className="d-flex justify-content-between">
                                                    <span className="m-1">
                                                        {t('Pages.Lessons.Questions.forms.tools.BackgroundGameObjectNumber')}
                                                        :
                                                        {index + 1}
                                                    </span>
                                                    <span className="m-1 mx-2">
                                                        <div className="btn btn-sm btn-success mx-1" title={t('Pages.Lessons.Questions.forms.tools.edit')} onClick={() => this.props.EditItem(index)}>
                                                            <i className="fal fa-edit"></i>
                                                        </div>
                                                        <div className="btn btn-sm btn-info mx-1" title={t('Pages.Lessons.Questions.forms.tools.copy')} onClick={() => this.props.copyItem(index)}>
                                                            <i className="fal fa-copy"></i>
                                                        </div>
                                                        <div className="btn btn-sm btn-danger mx-1" title={t('Pages.Lessons.Questions.forms.tools.delete')} onClick={() => this.props.DeleteItem(index)}>
                                                            <i className="fal fa-trash"></i>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        );
    }
}
export default withTranslation('common')(BackgroundGameObjectDragDrop);
const classRoomsReducers = (state = [], action) => {
    switch (action.type) {
        case "CLASSROOMS_LOADING":
            return {
                classRooms: state.classRooms,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "CLASSROOMS_CLEAN_LIST_NODES":
            return [];
        case "CLASSROOMS_INITIAL":
            return {
                classRooms: action.classRooms,
                toRemove: [],
                isLoaded: true
            };
        case "CLASSROOMS_ADD_NEW":
            return {
                classRooms: AddClassRoomData(action.classRoom, state.classRooms),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "CLASSROOMS_UPDATE_INFO":
            return {
                classRooms: UpdateClassRoomData(action.classRoom, state.classRooms, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "CLASSROOMS_QUEUE_FOR_REMOVAL":
            return {
                classRooms: state.classRooms,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "CLASSROOMS_CLEAN_NODES":
            return {
                classRooms: state.toRemove.some(v => v === action.id) ? state.classRooms.filter(v => v.Id !== action.id) : state.classRooms,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "CLASSROOMS_UNDO":
            return {
                classRooms: state.classRooms,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

function AddClassRoomData(newInfo, classRooms) {
    let copy = [];
    classRooms.map(classRoom => {
        copy.push({
            ...classRoom
        });
        return '';
    });
    copy.push({
        ...newInfo
    });
    return copy;
}

function UpdateClassRoomData(newInfo, classRooms, id) {
    let copy = [];
    classRooms.map(classRoom => {
        if (classRoom.Id === id) {
            classRoom = {
                ...classRoom,
                ...newInfo
            };
        }
        copy.push({
            ...classRoom
        });
        return '';
    });
    return copy;
}

export default classRoomsReducers;

import React from "react";
import { withTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';

import LessonItem from "./Tracks/LessonItem";
import ListItem from "../Admin/Files/ListItem";

class LastNews extends React.Component {

    state = { showAll: false };

    setFavorite = id => {
        this.props.setFavorite(id, this.props.typeToFacorite);
    }

    renderItem = count => {
        const { Items, indices, isLoaded, user } = this.props;

        if (isLoaded === false) {
            return this.renderLoading();
        }

        if (Items) {
            if (this.props.type === "Files") {
                return Items.slice(0, count).map(item =>
                    <ListItem
                        isFromHome={true}
                        user={user}
                        noBio={true}
                        indices={indices.nodes}
                        key={item.Id}
                        IsAdmin={false}
                        setFavorite={this.setFavorite}
                        item={item}
                        isSmall={count === 2 ? true : false}
                        type={this.props.type}
                    />
                );
            }

            return Items.slice(0, count).map(item =>
                <LessonItem
                    isFromHome={true}
                    indices={indices.nodes}
                    user={user}
                    key={item.Id}
                    IsAdmin={false}
                    noBio={true}
                    setFavorite={this.setFavorite}
                    lesson={item}
                    isSmall={count === 2 ? true : false}
                    type={this.props.type}
                />
            );
        }
    }

    showAll = () => {
        this.setState({
            showAll: !this.state.showAll
        });
        this.props.showMenu();
    }

    renderLoading() {
        const { t } = this.props;
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger" style={{
                    fontSize: '5rem'
                }}>
                    <i className="fad fa-sync-alt fa-spin"></i>
                </div>
                <h4 className="display-3 subtitle" style={{
                    fontSize: '20px'
                }}>
                    {t("Pages.Home.Last.Loading")}
                </h4>
            </div>
        </div>
    }

    render() {
        const { t, title, background, hasOffset, isCenter } = this.props;

        return <>
            {
                this.state.showAll ?
                    <Modal
                        size="lg"
                        show={true}
                        onHide={this.showAll}
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title> <span className="title text-center" dangerouslySetInnerHTML={{ __html: title }}></span> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <div className="widget">
                                <div className="widget-body" style={{
                                    background: background
                                }}>
                                    {this.renderItem(1000)}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12">
                                <button className="btn btn-danger" onClick={this.showAll}>
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ""
            }
            <div className={`col-xl-4 col-lg-6 col-xs-12 mb-5 ${hasOffset ? 'offset-xl-4' : ""} ${isCenter ? "mx-auto" : ""}`}>
                <div className="widget widget-category">
                    <div className="widget-header">
                        <h5 className="title text-center" dangerouslySetInnerHTML={{ __html: title }}></h5>
                    </div>
                    <div className="widget-body p-2" style={{
                        background: background
                    }}>

                        <div className="row row-cols-sm-2 justify-content-center align-items-center">
                            {this.renderItem(2)}
                        </div>
                    </div>
                    <div className="tags-area p-0" >
                        <div className="d-flex justify-content-center w-100">
                            <div onClick={() => this.showAll()} className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more">
                                <span className="txt pl-2">
                                    {t('showAll')}
                                    <i className="fa-regular fa-arrow-up-right-from-square mx-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}


export default withTranslation('common')(LastNews);
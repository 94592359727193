import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import Footer from "../../../Footer/FooterBottom";
import UserForm from "./Forms/Step1";
import Backgrounds from "../../../Admin/Question/View/Background";
import api from "../../../../api/api";
import { toast } from "react-toastify";

class SingleRegister extends React.Component {

    state = { data: {} };

    constructor(props) {
        super(props);
        ReactPixel.init('484815450795869');
    }

    componentDidMount() {
        window.scrollToPosition("single-register-section");
    }

    saveLoginInfo = data => {

        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        ReactPixel.track('GoToPurchase', data?.Email);

        api.get(`/PayProglobalPayment/?Email=${data.Email}`,
        ).then(response => {
            window.location.href = response.data;
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        this.setState({
            data: { ...data },
        });
    }

    renderForm = () => {
        return <UserForm onSubmit={this.saveLoginInfo} />
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.Single.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel call-in-action call-overlay oh" id="single-register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom padding-top" style={{
                        minHeight: '100vh'
                    }}>
                        <Backgrounds CustomBackground="Registration" />
                        <div className="container h-100" >
                            <div className="widget widget-category col-xl-8 mx-auto">
                                <div className="widget-header">
                                    <h5 className="title">
                                        <i className="fal fa-user-graduate "></i> {t('Pages.Register.Single.title')}
                                    </h5>

                                    <div className="position-absolute" style={{
                                        top: '20px',
                                        left: '20px'
                                    }}>
                                        <Link to="/Register" className="btn btn-dark btn-sm" title={t('Pages.Register.Subscriptions')}>
                                            <i className="fal fa-arrow-right"></i> <span className="d-none d-lg-inline-block"> {t('Pages.Register.Subscriptions')} </span>
                                        </Link>
                                    </div>

                                </div>
                                <div className="widget-body">
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

export default withTranslation('common')(SingleRegister);
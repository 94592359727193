import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import FooterBottom from "../Footer/FooterBottom";
import TracksBg from "../Home/Tracks/TracksBg";

class ControlPanel extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>{t('ControlPanel.title')} | {t('alarabeyya')}</title>
                </Helmet>
                <TracksBg
                    title={t('alarabeyya')}
                    subTitle={t('ControlPanel.title')}
                    onEdit={true}
                    trackId={""}
                    perfixUrl={"/ControlPanel"}
                />
                <div className="position-sticky">
                    <FooterBottom />
                </div>
            </>
        );
    }
}

export default withTranslation('common')(ControlPanel);
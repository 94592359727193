import React from "react";
import TextBox from "../../../TextBox";
import './style.css';

class Fish extends React.Component {

    render() {
        let color = "orange";
        if (this.props.customColor) {
            color = this.props.customColor;
        }
        const style = {
            backgroundColor: color
        };
        return <div className="position-relative">
            <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                <div style={{
                    transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                    transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                }}>
                    <div style={{
                        transform: `scaleX(-1)`
                    }}>
                        <div className="mx-auto custom-fish-fish">
                            <div className="custom-fish-top-fin" style={style}></div>
                            <div className="custom-fish-fish-body" style={style}>
                                <div className="custom-fish-mouth"></div>
                            </div>
                            <div className="custom-fish-tail-fin" style={style}>
                                <div className="custom-fish-tail-fin2" style={style}></div>
                            </div>
                            <div className="custom-fish-side-fin" style={style}></div>
                            <div className="custom-fish-scale custom-fish-scale-1" style={style}></div>
                            <div className="custom-fish-scale custom-fish-scale-2" style={style}></div>
                            <div className="custom-fish-scale custom-fish-scale-3" style={style}></div>
                        </div>
                    </div>
                </div>
            </div>
            <TextBox {...this.props} />
        </div>;
    }
}

export default Fish;

import api from '../../../../api/api';

export const fetch = (startDate, endDate, search, Type, page) => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_LOADING' });
    const response = await api.get(`/ErrorReport/?skip=${page}&startDate=${startDate}&endDate=${endDate}&Type=${Type}&search=${search}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'ERROR_REPORT_LAZY_LOADING', ErrorReport: response.data.data });
    } else {
        dispatch({ type: 'ERROR_REPORT_LAZY_LOADING_STOP' });
    }
}

export const reset = () => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_RESET' });
}

export const setIsSolve = id => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_UPDATE_IS_SOLVE', id });
}

export const clearInfo = () => async dispatch => {
    dispatch({ type: 'CLEAR_ERROR_REPORT_INFO' });
}

export const remove = (id) => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_QUEUE_FOR_REMOVAL', id });
}

export const undoDelete = (id) => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_UNDO', id });
}

export const clear = (id) => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_CLEAN_NODES', id });
}

export const setIsArchived = id => async dispatch => {
    dispatch({ type: 'ERROR_REPORT_UPDATE_IS_ARCHIVED', id });
}

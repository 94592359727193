import React from "react";
import TextBox from "../../TextBox";

class Planet extends React.Component {

    state = {
        isHover: false,
        fill: '#2f62d2'
    }

    isHover = isHover => {
        let fill = '#2f62d2';
        if (this.props.customColor) {
            fill = this.props.customColor;
        }
        if (isHover === true) {
            fill = "#f4ca5a";
        }
        this.setState({
            isHover,
            fill
        });
    }

    render() {

        let fill = '#2f62d2';
        if (this.props.customColor) {
            fill = this.props.customColor;
        }
        if (this.state.isHover === true) {
            fill = "#f4ca5a";
        }

        return (
            <>
                <div className=" moon__move"
                    style={{
                        zIndex: 10000
                    }}
                    onMouseEnter={() => this.isHover(true)}
                    onMouseLeave={() => this.isHover(false)}
                >
                    <div className="d-flex flex-column align-items-center">
                        <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                            <div style={{
                                transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                                transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                            }}>
                                <svg viewBox="0 0 160 160" width="100" height="100" className={` ${this.props.isSpain ? "fa-spin" : ""}`}>
                                    <circle cx="80" cy="80" r="50" fill={fill} ></circle>
                                    <g transform=" matrix(0.866, -0.5, 0.25, 0.433, 80, 80)">
                                        <path d="M 0,70 A 65,70 0 0,0 65,0 5,5 0 0,1 75,0 75,70 0 0,1 0,70Z" fill="#FFF">
                                            <animateTransform attributeName="transform" type="rotate" from="360 0 0" to="0 0 0" dur="1s" repeatCount="indefinite" />
                                        </path>
                                    </g>
                                    <path d="M 50,0 A 50,50 0 0,0 -50,0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 80, 80)" fill={fill} />
                                </svg>
                            </div>
                        </div>
                        <TextBox {...this.props} />
                    </div>
                </div>
            </>
        );
    }
}

export default Planet;

import React from "react";
import { withTranslation } from 'react-i18next';
import BeaconInNight from "./BeaconInNight";
import SpaceBackground from "./SpaceWithMoon";
import BeaconInLightHouse from "./LightHouse";
import WinterIsland from "./WinterIsland";
import SunriseOverStillLake from "./SunriseOverStillLake";
import BubbleBackground from "./bubble";
import FlyingBirdBackground from "./FlyingBird";
import Sea from "./Sea/Sea";
import Beach from "./Beach";
import Oacen from "./Oacen";
import DashBoardBg from "./DashBoard/DashBoardBg";
import TugOfWar from "./TugOfWar";
import Mountain from "./Mountain";
import Field from "./Field";
import School from "./School";
import Library from "./Library";
import ClassRoom from "./ClassRoom";
import Rank from "./Rank";
import TopOfMountain from "./TopOfMountain";
import ErrorPage from "./ErrorPage";
import Grass from "./Grass";
import Sky from "./Sky";
import FooterBg from "./FooterBg";
import FootballStadium from "./FootballStadium";
import Garden from "./Garden";
import ClassRoomWithCertificate from "./ClassRoomWithCertificate";
import Library2 from "./Library2";
import Labs from "./Labs";
import SnowfallBackground from "./Snowfall";
import PullGame from "./PullGame";
import StreetMove from "./StreetMove";
import OacenMove from "./OacenMove";
import Registration from "./Registration";
import ReadGroup from "./ReadGroup";

class Backgrounds extends React.Component {

    render() {
        const { CustomBackground } = this.props;
        if (CustomBackground === "SpaceBackground") {
            return <SpaceBackground  {...this.props} />
        } else if (CustomBackground === "BeaconInNight") {
            return <BeaconInNight {...this.props} />
        } else if (CustomBackground === "BeaconInLightHouse") {
            return <BeaconInLightHouse {...this.props} />
        } else if (CustomBackground === "SunriseOverStillLake") {
            return <SunriseOverStillLake {...this.props} />
        } else if (CustomBackground === "WinterIsland") {
            return <WinterIsland  {...this.props} />
        } else if (CustomBackground === "BubbleBackground") {
            return <BubbleBackground {...this.props} />
        } else if (CustomBackground === "FlyingBirdBackground") {
            return <FlyingBirdBackground {...this.props} />
        } else if (CustomBackground === "Oacen") {
            return <Oacen {...this.props} />
        } else if (CustomBackground === "Sea") {
            return <Sea {...this.props} />
        } else if (CustomBackground === "Beach") {
            return <Beach {...this.props} />
        } else if (CustomBackground === "DashBoard") {
            return <DashBoardBg {...this.props} />
        } else if (CustomBackground === "TugOfWar") {
            return <TugOfWar {...this.props} />
        } else if (CustomBackground === "Mountain") {
            return <Mountain {...this.props} />
        } else if (CustomBackground === "Field") {
            return <Field {...this.props} />
        } else if (CustomBackground === "School") {
            return <School {...this.props} />
        } else if (CustomBackground === "Library") {
            return <Library {...this.props} />
        } else if (CustomBackground === "ClassRoom") {
            return <ClassRoom {...this.props} />
        } else if (CustomBackground === "Rank") {
            return <Rank {...this.props} />
        } else if (CustomBackground === "TopOfMountain") {
            return <TopOfMountain {...this.props} />
        } else if (CustomBackground === "ErrorPage") {
            return <ErrorPage {...this.props} />
        } else if (CustomBackground === "Grass") {
            return <Grass {...this.props} />
        } else if (CustomBackground === "Sky") {
            return <Sky {...this.props} />
        } else if (CustomBackground === "FooterBg") {
            return <FooterBg {...this.props} />
        } else if (CustomBackground === "FootballStadium") {
            return <FootballStadium {...this.props} />
        } else if (CustomBackground === "Garden") {
            return <Garden {...this.props} />
        } else if (CustomBackground === "ClassRoomWithCertificate") {
            return <ClassRoomWithCertificate {...this.props} />
        } else if (CustomBackground === "Library2") {
            return <Library2 {...this.props} />
        } else if (CustomBackground === "Labs") {
            return <Labs {...this.props} />
        } else if (CustomBackground === "SnowfallBackground") {
            return <SnowfallBackground {...this.props} />
        } else if (CustomBackground === "PullGame") {
            return <PullGame {...this.props} />
        } else if (CustomBackground === "StreetMove") {
            return <StreetMove {...this.props} />
        } else if (CustomBackground === "OacenMove") {
            return <OacenMove {...this.props} />
        } else if (CustomBackground === "Registration") {
            return <Registration {...this.props} />
        } else if (CustomBackground === "ReadGroup") {
            return <ReadGroup {...this.props} />
        }
        return "";
    }
}

export default withTranslation('common')(Backgrounds);
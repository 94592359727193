import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import Footer from "../Footer/FooterBottom";
import InnerAbout from "./InnerAbout";
import GameObjects from "../Admin/Question/View/GameObjects";

class About extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-about-section");
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>{t("About")} | {t('alarabeyya')}</title>
                </Helmet>
                <div className="pos-rel padding-bottom padding-top" id="main-about-section" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/about/bgt.png"})`,
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                    <div className="position-absolute user-select-none d-none d-md-block" style={{
                        left: '10%',
                        bottom: '40%'
                    }}><GameObjects objectCss="BoyStandingWithHisHandOnHisWaist" /></div>

                    <div className="position-absolute user-select-none d-none d-md-block" style={{
                        right: '10%',
                        bottom: '40%'
                    }}><GameObjects objectCss="GirlCheers" /></div>

                    <div className="position-absolute user-select-none MoveblackBird"><img src={`${process.env.PUBLIC_URL + "/assets/images/Mountain/bird.png"}`} alt="" /></div>

                    <div className="position-absolute user-select-none d-none d-sm-block" style={{
                        left: '2%',
                        top: '0%',
                        animation: `dropObjectMovment linear forwards 4s normal infinite`
                    }}><GameObjects objectCss="Cloud1" /></div>
                    <div className="position-absolute user-select-none d-none d-sm-block" style={{
                        right: '2%',
                        top: '2%',
                        animation: `dropObjectMovment linear forwards 5s normal infinite`
                    }}><GameObjects objectCss="Cloud3" /></div>

                    <div className="container pt-5 padding-bottom">
                        <div className="page-header-content padding-bottom">
                            <h2 className="text-white "> {t('PageHeader.head')}</h2>
                            <h2 className="title text-white pt-4">{t('PageHeader.subHead')}</h2>
                        </div>
                    </div>
                </div>
                <section className="pos-rel " style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/about/bg.png"})`,
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'top',
                    minHeight: '100vh'
                }}>
                    <div className="text-right mx-5 padding-bottom padding-top" >
                        <div className="row" >
                            <div className="col-lg-6 d-flex align-items-center ">
                                <div className=" pl-4 d-none d-lg-block">
                                    <div className="d-flex align-items-center flex-wrap justify-content-center">

                                        <div className="mx-3 mb-5 user-select-none d-none d-md-block" style={{
                                            flex: '1 1 25%'
                                        }}><GameObjects objectCss="GirlOnWindowLooking" /></div>

                                        <div className="mx-3 mb-5 user-select-none d-none d-md-block" style={{
                                            flex: '1 1 25%'
                                        }}><GameObjects objectCss="BoyOnWindowRead" /></div>

                                        <div className="mx-3 mb-5 user-select-none d-none d-md-block" style={{
                                            flex: '1 1 25%'
                                        }}><GameObjects objectCss="GirlOnWindowSing" /></div>

                                        <div className="mx-3 mb-5 user-select-none d-none d-md-block" style={{
                                            flex: '1 1 25%'
                                        }}><GameObjects objectCss="BoyOnWindowPointTo" /></div>

                                        <div className="mx-3 mb-5 user-select-none d-none d-md-block" style={{
                                            flex: '1 1 25%'
                                        }}><GameObjects objectCss="GirlOnWindowDrink" /></div>

                                        <div className="mx-3 mb-5 user-select-none d-none d-md-block" style={{
                                            flex: '1 1 25%'
                                        }}><GameObjects objectCss="BoyOnWindowWithTablet" /></div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <InnerAbout />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default withTranslation('common')(About);
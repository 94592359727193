import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { fetchIndices } from '../../Admin/Indices/actions/';
import { getIndeices, setIndeices } from "../../../context/sessionStorage";
import {
    fetchFiles as fetch,
    setFileFavorite as setFavorite
} from '../../Admin/Files/actions';
import SubMenu from "./SubMenu";
import history from '../../../history';
import TreeView from "../../Admin/Indices/TreeView";
import Footer from "../../Footer/FooterBottom";
import ListItem from "../../Admin/Files/ListItem";
import { fetchClassRooms } from '../../School/ClassRooms/actions';
import { fetchStudentInClassRooms } from '../../School/StudentInClassRooms/actions/';
import School from "../../Admin/Question/View/Background/School";

class Files extends React.Component {

    state = { currentItem: '', indices: [], search: '' };

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });

        const { indexId } = this.props.match.params;
        if (indexId !== '0') {
            this.setState({
                currentItem: {
                    id: parseInt(indexId)
                }
            });
            this.props.fetch(indexId);
        }

        if (this.props.user && this.props.user.length > 0) {
            this.props.fetchClassRooms();
        }
        window.scrollToPosition("main-files-section");
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user &&
            props.user.user &&
            props.user.user.UserType &&
            (props.user.user.UserType.Id === 3 || props.user.user.UserType.Id === 4) &&
            props.classRooms && props.classRooms.length === 0) {
            props.fetchClassRooms();
        }

        return state;
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        this.props.fetch(item.id);
        history.push(`/Tracks/Files/${item.id}`);
    }

    Sync = () => {
        if (this.state.currentItem) {
            this.props.fetch(this.state.currentItem.id);
        }
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id && node.Level >= 2) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    fetchStudentInClassRooms = ClassRoomId => {
        this.props.fetchStudentInClassRooms(ClassRoomId);
    }

    renderFiles() {
        const { t, classRooms, user } = this.props;
        let files = this.props.files;
        let isLoaded = false;

        if (files) {
            isLoaded = files.isLoaded;
        }

        let toRemove = [];
        if (files) {
            toRemove = files.toRemove;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (files && files.files && files.files.length > 0 && this.state.currentItem) {
            let searchText = search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterFile = files.files.filter(v => v.SearchName.includes(searchText) && v.Index.Id === this.state.currentItem.id);

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Files.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterFile.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Files.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Files.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            if (filterFile.length === 0) {
                if (indices && indices.nodes && this.state.currentItem) {
                    var nodes = this.GetNode(indices.nodes, this.state.currentItem.id);
                    if (nodes && nodes.length > 0) {
                        return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                            {
                                nodes.map(item => {
                                    return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                        <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                            <div className="shop-item">
                                                <div className="product-thumb">
                                                    {
                                                        item.icon ?
                                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                            :
                                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                                    }
                                                </div>
                                                <div className="product-content">
                                                    <h5 className="title">{item.label}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    }
                }
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Files.NotFound")}
                            </h4>
                        </div>
                    </div>
                );
            }

            return filterFile.sort((a, b) => a.Sort < b.Sort ? 1 : -1).map(file =>
                (toRemove && toRemove.includes(file.Id)) ? "" :
                    <ListItem
                        classRooms={classRooms}
                        getStudent={this.fetchStudentInClassRooms}
                        setFavorite={this.setFavorite}
                        user={user}
                        key={file.Id}
                        IsAdmin={false}
                        setIndex={this.setCurrentItem}
                        item={file}
                        indices={indices.nodes} />
            );
        }
        if (!this.state.currentItem) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-glasses "></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Files.SelectIndex")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (files && files.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Files.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        if (indices && indices.nodes && this.state.currentItem) {

            let start = 6;
            let IndexId = (this.state.currentItem && this.state.currentItem.id) ? parseInt(this.state.currentItem.id) : start;

            var indexNodes = this.GetNode(indices.nodes, IndexId);
            if (indexNodes && indexNodes.length > 0) {
                return <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                    {
                        indexNodes.map(item => {
                            return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                    <div className="shop-item">
                                        <div className="product-thumb">
                                            {
                                                item.icon ?
                                                    <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                    :
                                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                            }
                                        </div>
                                        <div className="product-content">
                                            <h5 className="title">{item.label}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })
                    }
                </div>
            }
        }
        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Files.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    render() {
        const { t, startFrom } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let isLoaded = false;
        let files = this.props.files;
        if (files) {
            isLoaded = files.isLoaded;
        }

        let currentId = (this.state.currentItem) ? this.state.currentItem.id : 0;

        let start = 6;
        if (startFrom) {
            start = startFrom;
        }

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.Files")} | {t('alarabeyya')}</title>
                    <meta property="og:title" content={t("PageHeader.Files") + ' | ' + t('alarabeyya')} />
                    <meta property="twitter:title" content={t("PageHeader.Files") + ' | ' + t('alarabeyya')} />
                    <meta name="description" content={t('PageHeader.Files_desc')} />
                    <meta property="og:description" content={t('PageHeader.Files_desc')} />
                    <meta property="twitter:description" content={t('PageHeader.Files_desc')} />
                </Helmet>
                <section className="position-relative" id="main-files-section" style={{
                    minHeight: '100vh'
                }}>

                    <School />
                    <div className="container padding-top padding-bottom">
                        <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                            <div className="section-header mb-4">
                                <span className="cate custome-title"> {t('PageHeader.StudyTrack')} </span>
                                <div className="display-4 h3 custome-title" ><i className="mx-3 fal fa-paperclip "></i> {t('PageHeader.Files')} {this.props.title ? ` - ${this.props.title}` : ""}</div>
                            </div>
                        </div>
                        <div data-aos="fade-bottom" data-aos-easing="linear">
                            <SubMenu />
                        </div>

                        <div className="row mb-30-none">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        currentId={currentId}
                                        data={indices.nodes}
                                        toRemove={indices.toRemove}
                                        startFrom={start}
                                        setCurrentItem={this.setCurrentItem}></TreeView>
                                    : ""
                                }
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Files.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.Sync()} title={t("Pages.Lessons.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderFiles()}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }

}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        files: state.files
    };
};

export default connect(mapStateToProps, { fetchStudentInClassRooms, fetchClassRooms, setFavorite, fetchIndices, fetch })(withTranslation('common')(Files));
import React from "react";
import GameObjects from "../../GameObjects";
import './style.scss';

class FlyingBirdBackground extends React.Component {
    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }
    render() {
        return (
            <div className="FlyingBirdBackground-background">
                <div className="FlyingBirdBackground_RedBird FlyingBirdBackground_RedBird--one">
                    <GameObjects
                        objectCss={"RedBird"}
                        text=""
                        type="dragdrop-move"
                        status={this.props.currentStatus} FinishMove={this.FinishMove}
                    ></GameObjects>
                </div>
                <div className="FlyingBirdBackground_RedBird FlyingBirdBackground_RedBird--two">
                    <GameObjects
                        objectCss={"RedBird"}
                        text=""
                        type="dragdrop-move"
                        status={this.props.currentStatus} FinishMove={this.FinishMove}
                    ></GameObjects>
                </div>
            </div>
        );
    }
}

export default FlyingBirdBackground;
import api from '../../../../api/api';

export const fetchContacts = (startDate, endDate) => async dispatch => {
    dispatch({ type: 'CONTACT_US_LOADING' });
    const response = await api.get(`/ContactUs/?startDate=${startDate}&endDate=${endDate}`);
    dispatch({ type: 'CONTACT_US_INITIAL', contacts: response.data.data });
}

export const updateContact = (id, contact) => async dispatch => {
    dispatch({ type: 'CONTACT_US_UPDATE_INFO', contact, id });
}

export const removeContacts = id => async dispatch => {
    dispatch({ type: 'CONTACT_US_QUEUE_FOR_REMOVAL', id });
}

export const undoContacts = id => async dispatch => {
    dispatch({ type: 'CONTACT_US_UNDO', id });
}

export const clearContacts = id => async dispatch => {
    dispatch({ type: 'CONTACT_US_CLEAN_NODES', id });
}

export const seenContacts = id => async dispatch => {
    api.put(`/ContactUs/${id}`);
    dispatch({ type: 'CONTACT_US_IS_SEEN', id });
}

export const fetchReplies = id => async dispatch => {
    dispatch({ type: 'CONTACT_US_REPLIES_LOADING' });
    const response = await api.get(`/ContactUs/${id}`);
    dispatch({ type: 'CONTACT_US_REPLIES_INITIAL', replies: response.data.data });
}

export const addReply = id => async dispatch => {
    dispatch({ type: 'CONTACT_US_ADD_REPLY', id });
}


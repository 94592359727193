import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

import 'bootstrap-daterangepicker/daterangepicker.css';

import {
    fetchBills as fetch,
} from './actions';
import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import BillItem from "./BillItem";

class BillsList extends React.Component {

    constructor(props) {
        super(props);
        window.scrollToTop();
        moment.locale('en');
        this.state = {
            search: '',
            sumNIS: 0,
            sumDollor: 0,
            date: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY')
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.bills && props.bills.bills) {
            let sumNIS = 0;
            let sumDollor = 0;
            props.bills.bills.forEach(element => {
                if (element.IsNIS) {
                    sumNIS += element.Price - element.Discount;
                } else {
                    sumDollor += element.Price - element.Discount;
                }
            });
            state.sumNIS = sumNIS;
            state.sumDollor = sumDollor;
        }
        return state;
    }

    componentDidMount() {
        this.props.fetch(moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY'));
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));

        this.props.fetch(picker.startDate.format('DD/MM/YYYY'), picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    Sync = () => {
        this.props.fetch(this.state.startDate, this.state.endDate);
    }

    renderMails() {
        const { t, bills } = this.props;
        let isLoaded = false;

        if (bills) {
            isLoaded = bills.isLoaded;
        }

        if (bills && bills.bills && bills.bills.length > 0) {
            let filterBills = bills.bills.filter(v => v.ForUser.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Bills.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterBills.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Bills.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Bills.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterBills.map(bill =>
                <BillItem
                    key={bill.Id}
                    item={bill}
                ></BillItem>
            );
        }

        if (bills && bills.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Bills.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Bills.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }



    render() {
        const { t, user, bills } = this.props;
        let isLoaded = false;
        if (bills) {
            isLoaded = bills.isLoaded;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">

                        <Helmet>
                            <title>
                                {t('Pages.Bills.title')} | {t('alarabeyya')}
                            </title>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                        </Helmet>

                        <div className="row mt-30 mb-30-none">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.Bills.title')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form d-flex">
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>
                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Bills.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.Sync()} title={t("Pages.Bills.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.sumNIS > 0 || this.state.sumDollor > 0 ?
                                        <div className="widget widget-search" >
                                            <div className="widget-body">
                                                <div className="d-flex justify-content-around flex-wrap">
                                                    {
                                                        this.state.sumNIS > 0 ?
                                                            <div>
                                                                {t("Pages.Bills.total")} :
                                                                {this.state.sumNIS}
                                                                <i title={t("Pages.Profile.services.shekel")} className="fad fa-shekel-sign text-info mx-1"></i>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.state.sumDollor > 0 ?
                                                            <div>
                                                                {t("Pages.Bills.total")} :
                                                                {this.state.sumDollor}
                                                                <i title={t("Pages.Profile.services.dollar")} className="fad fa-dollar-sign text-info mx-1"></i>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }

                                {this.renderMails()}
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user,
        bills: state.bills
    };
};

export default connect(mapStateToProps, { fetch })(withTranslation('common')(BillsList));

import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";

class SelectView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        answers: [],
        answersList: [],
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });
        setTimeout(
            () => {
                window.webuiPopover();
            },
            500
        );
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        if (props.question.question && props.question.question.Answers) {
            state.answers = props.question.question.Answers;
        } else {
            state.answers = props.answers;
        }
        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        let upadteAnswerList = [];
        if (state.answersList) {
            state.answersList.forEach((item) => {
                let True = answerList.filter(v => v.Id === item.Id)[0];
                if (True) {
                    upadteAnswerList.push({
                        ...item,
                        ...True
                    });
                }
            });
        }
        state.answersList = upadteAnswerList;
        return state;
    }

    render() {
        const { title, Id } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`}>
                <div className="bg-white w-100 p-3 form-group w-100 position-relative">
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} style={style} >
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`} > </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            <div className="position-relative" dangerouslySetInnerHTML={{ __html: title }}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(SelectView));
import React from "react";
import { withTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import api from '../../../api/api';
import { notesInitial } from "../../actions/";

class UserNote extends React.Component {

    state = {
        showNotes: false,
        showAddNewNote: false,
        showEditNote: false,
        currentNoteId: null,
        currentNote: null
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        this.props.notesInitial(this.props.user.Id);
    }

    showAddNewNote = () => {
        this.setState({
            showAddNewNote: true
        });
    }

    handleClose = () => {
        this.setState({
            showAddNewNote: false,
            showEditNote: false
        });
    }

    showEditNote = (Id, Note) => {
        this.setState({
            showEditNote: true,
            currentNote: Note,
            currentNoteId: Id
        });
    }

    renderNotes = () => {
        const { t, notes, isLoaded } = this.props;
        if (isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.UsersControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (notes && notes.notes) {
            if (notes.notes.length > 0) {
                return notes.notes.map(note => {
                    return <div className="product-list-item" key={note.Id} >
                        <div className="product-content w-100  ">
                            <div className="d-flex justify-content-between">
                                <h4 className="display-3 subtitle" title={t("Pages.UsersControl.notes.DataEntry")}>
                                    {note.User.Name}
                                </h4>
                                <h4 className="display-3 text-danger subtitle" title={t("Pages.UsersControl.notes.CreationDate")}>
                                    {note.CreationDate}
                                </h4>
                            </div>

                            <div className="d-flex justify-content-between ">
                                <div className="p-2 pr-4" dangerouslySetInnerHTML={{
                                    __html: note.Note
                                }}></div>

                                <div className="d-flex align-items-center justify-content-center flex-wrap">

                                    <div className="d-flex align-items-center mr-1" >
                                        <span className="cate btn btn-info " onClick={() => this.showEditNote(note.Id, note.Note)} title={t("Pages.UsersControl.notes.edit")}>
                                            <i className="fas fa-edit "></i>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center mr-1" >
                                        <span className="cate btn btn-danger " onClick={() => this.showDeleteNote(note.Id)} title={t("Pages.UsersControl.notes.Delete")}>
                                            <i className="fas fa-trash "></i>
                                        </span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                });
            }
        }
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-telescope"></i>
                </div>
                <h4 className="display-3 subtitle">
                    {t("Pages.UsersControl.notes.NoNotes")}
                </h4>
            </div>
        </div>
    }

    onSubmitNote = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.Note.value) {
            let postData = {
                Note: this.Note.value
            };

            api.post(`/Notes/${this.props.user.Id}`,
                postData
            ).then(response => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.notes.success_toast.Title')}</div> <div className="h6">{t('Pages.UsersControl.notes.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.notesInitial(this.props.user.Id);
                this.handleClose();
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.notes.Note.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    onSubmitUpdateNote = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.Note.value) {
            let postData = {
                Note: this.Note.value
            };

            api.put(`/Notes/${this.state.currentNoteId}`,
                postData
            ).then(response => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.notes.success_toast.Title')}</div> <div className="h6">{t('Pages.UsersControl.notes.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.notesInitial(this.props.user.Id);
                this.handleClose();
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.notes.Note.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    showDeleteNote = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteNote(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    deleteNote = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Notes/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UsersControl.notes.toast_Delete.Title')}</div> <div className="h6">{t('Pages.UsersControl.notes.toast_Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

            this.props.notesInitial(this.props.user.Id);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user } = this.props;

        return <>

            {
                this.state.showAddNewNote ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showAddNewNote}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-plus text-primary icon"></i> {t("Pages.UsersControl.notes.add")}
                                    :
                                    <span className="text-primary mx-1">
                                        {user.Name}
                                    </span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category mb-2">
                                <div className="widget-body">
                                    <div className="form-group w-100">
                                        <label className='text-dark font-size-h5'>
                                            {t('Pages.UsersControl.notes.Note.title')}
                                            <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i>
                                        </label>
                                        <div>
                                            <textarea
                                                ref={Note => this.Note = Note}
                                                name="Note"
                                                style={{
                                                    resize: "vertical"
                                                }}
                                                placeholder={t('Pages.UsersControl.notes.Note.Placeholder')}
                                                title={t('Pages.UsersControl.notes.Note.title')}
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="custom-button" onClick={() => this.onSubmitNote()}>
                                    <span>
                                        <i className="icon fal fa-save"></i> {t('Pages.UsersControl.notes.Send')}
                                    </span>
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showEditNote ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showEditNote}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-plus text-primary icon"></i> {t("Pages.UsersControl.notes.edit")}
                                    :
                                    <span className="text-primary mx-1">
                                        {user.Name}
                                    </span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category mb-2">
                                <div className="widget-body">
                                    <div className="form-group w-100">
                                        <label className='text-dark font-size-h5'>
                                            {t('Pages.UsersControl.notes.Note.title')}
                                            <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i>
                                        </label>
                                        <div>
                                            <textarea
                                                style={{
                                                    resize: "vertical"
                                                }}
                                                ref={Note => this.Note = Note}
                                                defaultValue={this.state.currentNote}
                                                name="Note"
                                                placeholder={t('Pages.UsersControl.notes.Note.Placeholder')}
                                                title={t('Pages.UsersControl.notes.Note.title')}
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="custom-button" onClick={() => this.onSubmitUpdateNote()}>
                                    <span>
                                        <i className="icon fal fa-save"></i> {t('Pages.UsersControl.notes.Send')}
                                    </span>
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <Modal
                size="xl"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="h5 mb-0">
                            <i className="fad fa-sticky-note text-primary icon"></i> {t("Pages.UsersControl.action.notes")}
                            :
                            <span className="text-primary mx-1">
                                {user.Name}
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <button className="btn mb-2 mr-2 w-auto btn-dark" onClick={() => this.showAddNewNote()} title={t('Pages.UsersControl.notes.add')}>
                        <i className="icon fad fa-plus"></i>
                        <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.notes.add')} </span>
                    </button>
                    {this.renderNotes()}
                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        notes: state.notes
    };
};

export default connect(mapStateToProps, { notesInitial })(withTranslation('common')(UserNote));
import React from "react";
import Spritesheet from 'react-responsive-spritesheet';
import TextBox from "../../TextBox";

import img from './images/1.png';

const width = 16 / 1;
const height = 64 / 4;

const Shoot = (props) => {

    return <div className="position-relative" >
        <div className={`mb-1 d-inline-block`} >
            <Spritesheet
                image={img}
                widthFrame={width}
                heightFrame={height}
                steps={14}
                startAt={1}
                endAt={4}
                fps={12}
                autoplay={true}
                loop={true}
            />
        </div>
        <TextBox {...props} />
    </div >
}

export default Shoot;
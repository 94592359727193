import React from "react";

import Edit from "../Lessons/LessonEdit";

class BookLessonEdit extends React.Component {

    render() {
        return <>
            <Edit match={this.props.match} type="Books" typeTreeView="Books"></Edit>
        </>;
    }
}

export default BookLessonEdit;

import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ScrollArea from "react-scrollbar";

import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import {
    fetch,
    reset,
    remove,
    clearInfo as clear,
    undoDelete as undo,
    setIsSolve,
    setIsArchived
} from './actions';
import ListItem from "./ListItem";
import { getIndeices, setIndeices } from "../../../context/sessionStorage";
import { fetchIndices } from '../Indices/actions/';
import api from '../../../api/api';
import { notificationIsView, ErrorReportIsSolveBySocket } from '../../../api/socket.io';

class ErrorReport extends React.Component {

    state = {
        indices: [],
        isFetching: true,
        isClicked: true,
        Type: 2,
        search: '',
        startDate: moment().add(-45, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        date: moment().add(-45, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
        page: 0
    };

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });
    }

    constructor(props) {
        super(props);
        window.scrollToTop();
        this.fetchMoreContainer = React.createRef();
        this.scrollArea = React.createRef();
        setTimeout(() => {
            this.Reset();
        }, 50);
    }

    scrollDown = () => {
        this.scrollArea.current.scrollArea.scrollBottom();
        this.Sync();
    }
    handleScroll = () => {
        var react = this.fetchMoreContainer;
        if (react && react.current) {
            let rect = react.current.getBoundingClientRect();
            if (rect) {
                if (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    (rect.bottom - rect.height) <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) && this.state.isFetching
                ) {
                    this.setState({
                        isFetching: false
                    });
                    setTimeout(() => {
                        this.Sync();
                    }, 50);
                }
            }
        }
    }
    Reset = () => {
        this.props.reset();
        this.setState({
            page: 0
        });
        setTimeout(() => {
            this.Sync();
        }, 50);
    }
    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }
    setSearch = value => {
        this.setState({
            search: value
        });
    }
    setType = Type => {
        this.setState({
            Type
        });
    }
    Sync = () => {
        let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
        this.props.fetch(this.state.startDate, this.state.endDate, searchText, this.state.Type, this.state.page);
        this.setState({
            page: this.state.page + 1,
            isClicked: true,
            isFetching: true
        });
    }

    renderItems = () => {
        const { t, ErrorReport } = this.props;

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        let toRemove = [];
        if (ErrorReport) {
            toRemove = ErrorReport.toRemove;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        if (ErrorReport && ErrorReport.ErrorReport && ErrorReport.ErrorReport.length > 0) {
            let filterErrorReport = ErrorReport.ErrorReport.filter(v => v.Name.includes(search));


            if (filterErrorReport.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.ErrorReport.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.ErrorReport.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterErrorReport.map((ErrorReport, index) =>
                (toRemove && toRemove.includes(ErrorReport.Id)) ? "" :
                    <ListItem
                        isSolved={this.isSolved}
                        remove={this.showDelete}
                        indices={indices.nodes}
                        key={`${index}-${ErrorReport.Id}`}
                        user={this.props.user}
                        setIndex={this.setCurrentItem}
                        item={ErrorReport}
                    ></ListItem>
            );
        }

        if (ErrorReport && ErrorReport.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.ErrorReport.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }
        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.ErrorReport.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteError(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteError = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/ErrorReport/${id}`).then(response => {
            this.props.clear(id);
            notificationIsView("ERROR_REPORT", this.props.user.user.Id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.ErrorReport.toast.Delete.Title')}</div> <div className="h6">{t('Pages.ErrorReport.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    isSolved = (item, type) => {
        if (item) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('Pages.ErrorReport.' + type)}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.errorIsSolved(item, type); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    errorIsSolved = (item, type) => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/ErrorReport/${item.Id}?type=${type}`).then(response => {
            if (type === "IsSolved") {
                this.props.setIsSolve(item.Id);
                notificationIsView("ERROR_REPORT", this.props.user.user.Id);
                ErrorReportIsSolveBySocket(item);
            } else {
                this.props.setIsArchived(item.Id);
            }

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-badge-check "></i>
                        {t('Pages.ErrorReport.toast.IsSolve.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.ErrorReport.toast.IsSolve.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, ErrorReport } = this.props;

        let lazyLoading = true;
        let isLoaded = false;
        if (ErrorReport) {
            isLoaded = ErrorReport.isLoaded;
            lazyLoading = ErrorReport.lazyLoading;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <section className="pos-rel padding-top padding-bottom">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("Pages.UserControl.ErrorReport")} | {t('alarabeyya')}</title>
                        </Helmet>

                        <div className="row mb-30-none">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header mb-4">
                                            <h3 className="title ">{t('Pages.UserControl.ErrorReport')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div className="py-2 flex-grow-1 ">
                                                <form className="search-form d-flex w-100">
                                                    <select className="mr-2" placeholder={t("Pages.ErrorReport.search")} onChange={e => this.setType(e.target.value)}>
                                                        <option value={2}> {t(`Pages.ErrorReport.NotShow`)}</option>
                                                        <option value={1}> {t(`Pages.ErrorReport.ByDate`)}</option>
                                                        <option value={3}> {t(`Pages.ErrorReport.Archived`)}</option>
                                                    </select>
                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.ErrorReport.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>

                                            <div className={`search-form align-items-center w-100 ${this.state.Type === "1" ? " d-flex" : "d-none"}`}>
                                                <DateRangePicker
                                                    ref={Date => this.Date = Date}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        autoUpdateInput: false,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        ranges: ranges,
                                                        minYear: 2020,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            firstDay: 6,
                                                            applyLabel: t('Apply'),
                                                            customRangeLabel: t('range.customRange'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.date}
                                                        name="Date"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        placeholder={t('SearchDate.Placeholder')}
                                                        title={t('SearchDate.title')}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Reset()} >
                                                <span>
                                                    {
                                                        isLoaded ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ScrollArea
                                    horizontal={false}
                                    className={`area w-100 lazyLoading  p-2 `}
                                    smoothScrolling={true}
                                    style={{
                                        height: '100vh',
                                        background: '#fafafa',
                                        borderRadius: '10px'
                                    }}
                                    ref={this.scrollArea}
                                    contentClassName="content "
                                    onScroll={this.handleScroll} >

                                    {this.renderItems()}
                                    {
                                        lazyLoading && isLoaded && this.state.isClicked ?
                                            <div className="product-list-item" ref={this.fetchMoreContainer}>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-danger">
                                                        <i className="fad fa-sync-alt fa-spin"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle">
                                                        {t("Pages.Lessons.Loading")}
                                                    </h4>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </ScrollArea>

                                {
                                    lazyLoading && isLoaded && this.state.isClicked ?
                                        <div className="row justify-content-md-center" style={{
                                            marginTop: '-50px'
                                        }} >
                                            <div className="d-flex justify-content-center w-100">
                                                <div onClick={() => this.scrollDown()} className="theme-btn btn-style-two d-inline-flex mb-1" style={{
                                                    borderRadius: 'unset',
                                                    borderTopRightRadius: '50px',
                                                    borderTopLeftRadius: '50px'
                                                }}>
                                                    <span className="txt pl-2"> {t('Pages.Lessons.LoadMore')} </span>
                                                    <div className="mouse-btn-down">
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>


                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices,
        ErrorReport: state.ErrorReport,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetch, reset, clear, fetchIndices, remove, undo, setIsSolve, setIsArchived })(withTranslation('common')(ErrorReport));

import React from "react";

import roundsData from "../../../../Config/rounds.json";
import GameObjects from "../../../Question/View/GameObjects";
import HorizontalMove from "./HorizontalMove";
import PopUps from "../PopUps";

class DropVertical extends React.Component {

    constructor(props) {
        super(props);
        this.selectedCount = 0;
        this.selectableCount = 0;
        this.stageCount = 0;
        this.randomKey = Math.floor(Math.random() * 100000);
        this.state = {
            userAnswers: [],
            showItems: [],
            randomKey: this.randomKey,
            ShootNumber: 0,
            currentStageIndex: 0,
            currentCategoryId: 0,
            currentStatus: "",
            removeState: false,
            position: {
                x: 0,
                y: 0
            },
            BounsTry: 0,
            BounsTime: 0,
            showBounsTime: false,
            showBounsTry: false,
            clickNumber: 0,
            stageName: {}
        };
        this.Rounds = roundsData.Rounds;
        this.audio = new Audio();
        this.boundingClientRect = null;
        this.randomList = [];
        this.refList = [];
    }

    componentWillUnmount() {
        this.randomKey = Math.floor(Math.random() * 100000);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.clickNumber !== state.clickNumber) {
            state.clickNumber = props.clickNumber;
        }
        return state;
    }

    componentDidMount() {
        this.stageCount = this.props.Question.Answers.length;
        this.nextStage();
    }

    playMoveSound = MoveSound => {
        if (MoveSound) {
            if (this.audio && !this.audio.paused === false) {
                this.audio.pause();
            }
            this.audio = new Audio(MoveSound);
            this.audio.play();
        }
    }

    nextStage = () => {
        if (this.props.Question.Answers) {
            var firstAnswer = this.props.Question.Answers.sort((a, b) => a.Sort - b.Sort)[this.state.currentStageIndex];
            if (firstAnswer) {
                if (this.state.currentStageIndex > 0) {
                    this.props.playStageSound();
                }
                this.setState({
                    removeState: true,
                    currentStageIndex: this.state.currentStageIndex + 1,
                    stageName: this.Rounds[this.stageCount === (this.state.currentStageIndex + 1) ? 0 : (this.state.currentStageIndex + 1)],
                    showItems: this.setItemToShow(firstAnswer.Id)
                });
            } else if (this.randomKey === this.state.randomKey) {
                this.props.showEndGame();
            }
        }
    };

    getRandomNumber = (depth = 0) => {
        let number = Math.floor(Math.random() * 40);
        if (depth === 10) {
            return number;
        }
        var inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * 40);
            inRange = this.randomList.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (this.randomList.length > 0) {
            const lastItem = this.randomList[this.randomList.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (this.randomList.length > 1) {
                const beforeItem = this.randomList[this.randomList.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
            if (number > 40) {
                number = this.getRandomNumber(depth + 1);
            }
        }
        return number;
    }

    setItemToShow = currentId => {
        const { Question } = this.props;
        const answer = Question.Answers.filter(p => p.Id === currentId)[0];
        const html = answer.Name;
        const CustomStyle = answer.CustomStyle;
        let items = [];

        const styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${Question.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerStyle.BorderRadiusTop}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusRight}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusBottom}${Question.CustomStyle.answerStyle.BorderRadiusUnit} ${Question.CustomStyle.answerStyle.BorderRadiusLeft}${Question.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerStyle.ShadowPositionX}px ${Question.CustomStyle.answerStyle.ShadowPositionY}px ${Question.CustomStyle.answerStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${Question.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerStyle.BgAttachment}`,
            zIndex: 1
        };

        const count = (html.match(/\(*_\)/g) || []).length;

        this.randomList = [];
        for (let index = 0; index < count; index++) {
            this.randomList.push(this.getRandomNumber());
        }
        let fromRight = false;
        const ShootObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.ShootObjectSrc : "";
        const ShootObjectOption = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.ShootObjectOption : "";

        const splitAnswer = html.split('(*_)');
        for (let index = 0; index < count; index++) {
            let text = splitAnswer[index + 1] ? splitAnswer[index + 1].split('(_*)')[0] : "";
            if (CustomStyle && CustomStyle.Answers && CustomStyle.Movments) {
                const param = CustomStyle.Answers.filter(p => p.Id === currentId && p.Sort === index)[0];
                const Movments = CustomStyle.Movments.filter(p => p.Id === currentId && p.Sort === index);
                if (param) {
                    let customColor = param.CustomColor;
                    let objectCss = param.AnswersSelectObject;
                    let ObjectSrc = param.AnswersSelectObjectSrc;
                    let ObjectHoverSrc = param.AnswersSelectObjectSrcHover;
                    if (objectCss === "") {
                        objectCss = Question.CustomStyle.gamesSetting.MoveObject;
                        ObjectSrc = Question.CustomStyle.gamesSetting.MoveObjectSrc;
                        ObjectHoverSrc = Question.CustomStyle.gamesSetting.MoveObjectSrcHover;
                        if (!customColor) {
                            customColor = Question.CustomStyle.gamesSetting.MoveCustomColor;
                        }
                    }
                    if (param.AnswersSelectType !== "radio" && /\d/.test(param.AnswersSelectType) && param.AnswersSelectType !== "0") {
                        this.selectableCount++;
                    }

                    objectCss = this.props.getRandomObject(objectCss);
                    fromRight = !fromRight;
                    var ref = React.createRef();
                    const top = this.randomList[index];
                    items.push({
                        Id: param.Sort,
                        jsx: <HorizontalMove
                            refList={this.refList}
                            playMoveSound={this.playMoveSound}
                            designerContainer={this.props.designerContainer}
                            item={answer}
                            id={param.Id}
                            top={top}
                            img={ShootObjectSrc}
                            rotate={param.rotate}
                            isSpin={param.isSpin}
                            flipX={param.flipX === true || param.flipX === "true" ? true : false}
                            delay={param.delay}
                            scale={param.scale}
                            transformOriginX={param.transformOriginX}
                            transformOriginY={param.transformOriginY}
                            ShootObjectOption={ShootObjectOption}
                            randomKey={this.randomKey}
                            ref={ref}
                            fromRight={fromRight}
                            key={`objectId-${param.Id}-${param.Sort}`}
                            param={param}
                            index={param.Sort}
                            style={styleAnswer}
                            ObjectSrc={ObjectSrc}
                            customColor={customColor}
                            ObjectHoverSrc={ObjectHoverSrc}
                            MoveType={param.AnswersMoveType}
                            Movments={Movments}
                            handleDropObject={this.handleDropObject}
                            Time={param.AnswersTime}
                            objectCss={objectCss}
                            text={text} />
                    });
                }
            }
        }
        return items;
    };

    removeBounsMove = () => {
        setTimeout(() => {
            this.setState({
                BounsTry: 0,
                BounsTime: 0,
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
        }, 4 * 1000);
    }

    handleDropObject = (clickedItem, dropItem, TrueSound, FalseSound) => {
        const hasAnswer = parseInt(clickedItem.AnswersSelectType);
        let currentStatus = "";
        let returnAnswer = false;

        if (this.props.isMission === true) {
            let userAnswers = this.state.userAnswers.slice();
            userAnswers.push({
                Answer: clickedItem,
                Category: dropItem,
                isTrue: dropItem && hasAnswer === dropItem.Id
            });
            this.setState({
                userAnswers
            });
            this.props.saveAnswer(userAnswers);
        }

        if (dropItem && hasAnswer === dropItem.Id) {
            currentStatus = "True";
            this.setState({
                currentDrop: dropItem.Id,
                currentStatus
            });
            if (parseInt(clickedItem.BounsTry) > 0 && parseInt(clickedItem.BounsTime) > 0) {
                this.setState({
                    showBounsTime: true,
                    showBounsTry: true,
                    BounsTry: clickedItem.BounsTry,
                    BounsTime: clickedItem.BounsTime,
                });
                this.props.addBounsTimeAndTry(parseInt(clickedItem.BounsTime), parseInt(clickedItem.BounsTry), true, TrueSound);
                this.removeBounsMove();
            } else if (parseInt(clickedItem.BounsTry) > 0) {
                this.setState({
                    showBounsTry: true,
                    BounsTry: clickedItem.BounsTry,
                });
                this.props.addBounsTry(parseInt(clickedItem.BounsTry), true, TrueSound);
                this.removeBounsMove();
            } else if (parseInt(clickedItem.BounsTime) > 0) {
                this.setState({
                    showBounsTime: true,
                    BounsTime: clickedItem.BounsTime,
                });
                this.props.addBounsTime(parseInt(clickedItem.BounsTime), true, TrueSound);
                this.removeBounsMove();
            } else {
                this.props.addTrueTry(true, TrueSound);
            }

        } else {
            currentStatus = "Wrong";
            this.props.addFalseTry(true, FalseSound);
            if (dropItem && dropItem.Id) {
                this.setState({
                    currentDrop: dropItem.Id,
                    currentStatus
                });
            } else if (hasAnswer) {
                returnAnswer = true;
            }
        }

        if (returnAnswer) {
            return true;
        }

        if (hasAnswer > 0) {
            this.selectedCount++;
        }
        if (this.selectedCount === this.selectableCount) {
            this.setState({
                removeState: false,
                showBounsTime: false,
                showBounsTry: false
            });
            this.selectedCount = 0;
            this.selectableCount = 0;
            this.nextStage();
        }
        return currentStatus;
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { Question } = this.props;

        const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
        const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";
        const objectCss = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObject : "";
        const DropObjectSrc = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrc : "";
        const DropObjectSrcHover = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const DropCustomColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.DropCustomColor : "";
        const TranslateX = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TranslateX : "";
        const TranslateTime = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TranslateTime : "";
        let styleBox = {

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${Question.CustomStyle.answerBoxStyle.width}px`,
            minHeight: `${Question.CustomStyle.answerBoxStyle.height}px`,

            maxWidth: '100%',
            paddingLeft: `${Question.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${Question.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${Question.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${Question.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: Question.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${Question.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${Question.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${Question.CustomStyle.answerBoxStyle.BorderRadiusTop}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusRight}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusBottom}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${Question.CustomStyle.answerBoxStyle.BorderRadiusLeft}${Question.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${Question.CustomStyle.answerBoxStyle.ShadowPositionX}px ${Question.CustomStyle.answerBoxStyle.ShadowPositionY}px ${Question.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${Question.CustomStyle.answerBoxStyle.shadowColor}`,
            height: `100%`,
            opacity: `${Question.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: Question.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? Question.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: Question.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${Question.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${Question.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${Question.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${Question.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${Question.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${Question.CustomStyle.answerBoxStyle.BgAttachment}`,
        };

        return <>
            <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${Question.CustomStyle.answerStyle.Custom}} .dragdrop-static-box{${Question.CustomStyle.answerBoxStyle.Custom}} @keyframes dropObjectMovment{ 0%{transform:translateX(0%)} 25%{transform:translateX(${TranslateX}%)} 50%{transform:translateX(0%)} 75%{transform:translateX(-${TranslateX}%)} 100%{transform:translateX(0%)} } ` }}></style>
            <PopUps
                removeState={this.state.removeState}
                showBounsTry={this.state.showBounsTry}
                showBounsTime={this.state.showBounsTime}
                bounsTry={this.state.BounsTry}
                bounsTime={this.state.BounsTime}
                stageCount={this.stageCount}
                TryColorCustom={TryColorCustom}
                TryColor={TryColor}
                label={this.state.stageName.label}
                position={this.state.position}
            />
            {
                this.state.showItems ?
                    this.state.showItems.map(item => {
                        return <span key={`${this.state.currentStageIndex}-${item.Id}`}>{item.jsx}</span>
                    }) : ""
            }
            <div className="position-absolute w-100 d-flex justify-content-around align-items-end flex-nowrap" key="DropObject-items" style={{
                bottom: '1%',
                zIndex: 18,
                transform: `scale(${this.props.scale})`
            }}>
                {
                    Question.Categories.map(item => {
                        let objectCssCategory = objectCss;
                        let ObjectSrcCategory = DropObjectSrc;
                        let ObjectHoverSrcCategory = DropObjectSrcHover;
                        let customColorCategory = DropCustomColor;
                        let TrueSound = "";
                        let FalseSound = "";
                        let flipX = "";
                        let scale = "";
                        let isSpin = "";
                        let rotate = "";
                        if (item.CustomStyle && item.CustomStyle.Answers) {
                            var subItem = item.CustomStyle.Answers.filter(p => p.Id === item.Id)[0];
                            if (subItem) {
                                flipX = subItem.flipX;
                                scale = subItem.scale;
                                rotate = subItem.rotate;
                                isSpin = subItem.isSpin;
                                if (subItem.AnswersSelectObject) {
                                    objectCssCategory = subItem.AnswersSelectObject;
                                    ObjectSrcCategory = subItem.AnswersSelectObjectSrc;
                                    ObjectHoverSrcCategory = subItem.AnswersSelectObjectSrcHover;
                                    customColorCategory = subItem.CustomColor;
                                }
                                if (subItem.TrueSound && subItem.TrueSound !== "none" && subItem.TrueSound !== "custom") {
                                    TrueSound = process.env.PUBLIC_URL + subItem.TrueSound;
                                } else if (subItem.TrueSound === "custom") {
                                    TrueSound = subItem.CustomTrueSound;
                                }

                                if (subItem.FalseSound && subItem.FalseSound !== "none" && subItem.FalseSound !== "custom") {
                                    FalseSound = process.env.PUBLIC_URL + subItem.FalseSound;
                                } else if (subItem.FalseSound === "custom") {
                                    FalseSound = subItem.CustomFalseSound;
                                }
                            }
                        }
                        var ref = React.createRef();
                        this.refList.push({
                            Id: item.Id,
                            item,
                            ref,
                            TrueSound,
                            FalseSound
                        });
                        return <div ref={ref} key={`DropObject-${item.Id}`} className="objectMovment" style={{
                            animation: `dropObjectMovment linear forwards ${TranslateTime}s normal infinite`
                        }}>
                            <GameObjects
                                setDropTarget={this.setDropTarget}
                                isSpin={isSpin}
                                flipX={flipX}
                                scale={scale}
                                rotate={rotate}
                                TrueSound={TrueSound}
                                FalseSound={FalseSound}
                                objectCss={objectCssCategory}
                                ObjectSrc={ObjectSrcCategory}
                                ObjectHoverSrc={ObjectHoverSrcCategory}
                                customColor={customColorCategory}
                                style={styleBox}
                                FinishMove={this.FinishMove}
                                status={this.state.currentDrop === item.Id ? this.state.currentStatus : ""}
                                item={item}
                                text={item.Name}
                            />
                        </div>
                    })
                }
            </div>
        </>;
    }
}

export default DropVertical;
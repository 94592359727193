const LocationReducers = (state = [], action) => {
    switch (action.type) {
        case "LOCATION_LOADING":
            return {
                location: null,
                isLoaded: false
            };
        case "LOCATION_INITIAL":
            return {
                location: {
                    ...action.location,
                    country_code: action.location.countryCode.toLowerCase(),
                    Address: action.location?.city
                },
                isLoaded: true
            };
        default:
            return state;
    }
}

export default LocationReducers;
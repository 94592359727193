import React from "react";
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { toast } from 'react-toastify';

import Footer from "../../Footer/FooterBottom";

import Field from "../Question/View/Background/Field";
import ClassTypeData from "../../Config/classType.json";
import api from "../../../api/api";

class SearchResultList extends React.Component {

    state = {
        date: `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
        startDate: `${moment().format('DD/MM/YYYY')}`,
        endDate: `${moment().format('DD/MM/YYYY')}`,
        ClassType: [],
        ClassTypeId: "",
        IsExams: true,
        IsFun: true,
        IsBooks: true,
        IsQuizzes: true,
        IsLessons: true,
        IsFiles: true,
        IsDetails: true,
        NoResultOnly: false,
        data: []
    };

    constructor(props) {
        super(props);
        this.state.ClassType = ClassTypeData.ClassType;
    }

    componentDidMount() {
        window.scrollToPosition("main-search-section");
    }

    setIsExams = IsExams => {
        this.setState({
            IsExams,
            data: []
        });
    }

    setIsFun = IsFun => {
        this.setState({
            IsFun,
            data: []
        });
    }

    setIsBooks = IsBooks => {
        this.setState({
            IsBooks,
            data: []
        });
    }

    setIsQuizzes = IsQuizzes => {
        this.setState({
            IsQuizzes,
            data: []
        });
    }

    setIsDetails = IsDetails => {
        this.setState({
            IsDetails,
            data: []
        });
    }

    setNoResultOnly = NoResultOnly => {
        this.setState({
            NoResultOnly,
            data: []
        });
    }

    setIsFiles = IsFiles => {
        this.setState({
            IsFiles,
            data: []
        });
    }

    setIsLessons = IsLessons => {
        this.setState({
            IsLessons,
            data: []
        });
    }

    getSearch = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const postData = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            IsExams: this.IsExams.checked,
            IsFiles: this.IsFiles.checked,
            IsBooks: this.IsBooks.checked,
            IsQuizzes: this.IsQuizzes.checked,
            IsSelfDevelopment: this.IsQuizzes.checked,
            IsLessons: this.IsLessons.checked,
            IsDetails: this.IsDetails.checked,
            IsFun: this.IsFun.checked,
            ClassTypeId: this.state.ClassTypeId,
            NoResultOnly: this.NoResultOnly.checked,
        };

        api.post("/SearchResult/", postData).then(response => {
            this.setState({ data: response.data.data });
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    renderLoading() {
        const { t } = this.props;
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-sync-alt fa-spin"></i>
                </div>
                <h4 className="display-3 subtitle">
                    {t("Pages.Search.Loading")}
                </h4>
            </div>
        </div>
    }

    renderClassTypeOption() {
        return this.state.ClassType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    setClassTypeId = ClassTypeId => {
        this.setState({
            ClassTypeId,
            data: []
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY'),
            data: [],
            date: picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY')
        });
    }

    renderCols = () => {
        const { t } = this.props;
        const listOfClass = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'];
        return this.state.data.map((item, index) => {
            const ClassType = this.state.ClassType.find(p => p.Code === item.ClassTypeId);
            const Type = item.Type.split(',');
            return <tr key={`search-result-${index}`}>
                <td>
                    {item.Name}
                    {
                        item.HasResult ? "" :
                            <span className={`badge badge-pill badge-danger p-1 blink`}>     {t("Pages.Search.NoResult")}  </span>

                    }
                </td>
                <td>
                    {
                        Type.map((type, subIndex) => {
                            if (type) {
                                return <span key={`search-result-${index}-${type}`} className={`badge badge-pill badge-${listOfClass[subIndex]} m-1 p-2`}>   {t(`PageHeader.${type}`)}</span>
                            }
                            return "";
                        })
                    }

                </td>
                <td>
                    <span className={`badge badge-success m-1 p-1 text-nowrap`} title={t('Pages.Search.Table.user')}>
                        <i className="icon fa-light fa-calendar"></i>
                        {item.CreationDate}
                    </span>
                    {
                        item.User ?
                            <span className={`badge badge-primary m-1 p-1 text-nowrap`} title={t('Pages.Search.Table.user')}>
                                <i className="icon fa-light fa-user"></i>
                                {item.User.Name}
                            </span>
                            : ""
                    }
                    {
                        ClassType ?
                            <span className={`badge badge-info m-1 p-1 text-nowrap`} title={t('Pages.Search.ClassType')}>
                                <i className="icon fa-light fa-screen-user"></i>
                                {ClassType.Name}
                            </span>
                            : ""
                    }

                </td>
            </tr >
        });
    }

    renderColsGroup = () => {
        return this.state.data.map((item, index) => {
            return <tr key={`search-result-${index}`}>
                <td>
                    {item.Name}
                </td>
                <td>
                    {item.Total}
                </td>
            </tr >
        });
    }

    render() {
        const { t } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment(), moment().toDate()];
        ranges[t("range.Last30Days")] = [moment().subtract(30, 'days'), moment(), moment().toDate()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("range.ThisYear")] = [moment().startOf('year'), moment().endOf('year')];

        if (moment().startOf('year').month("September") < moment()) {
            ranges[t("range.FirstSemester")] = [moment().startOf('year').month("September").startOf('month'), moment().endOf('year')];
            ranges[t("range.ThisStudyYear")] = [moment().startOf('year').month("September").startOf('month'), moment().toDate()];
        } else {
            ranges[t("range.FirstSemester")] = [moment().subtract(1, 'year').month("September").startOf('month'), moment().subtract(1, 'year').endOf('year')];

            if (moment().startOf('year') <= moment()) {
                ranges[t("range.SecondSemester")] = [moment().startOf('year'), moment().startOf('year').month("April").day(10)];
            }
            if (moment().startOf('year').month("April").day(15) <= moment()) {
                ranges[t("range.ThirdSemester")] = [moment().startOf('year').month("April").day(15), moment().startOf('year').month("June").endOf('month')];
            }
            ranges[t("range.ThisStudyYear")] = [moment().subtract(1, 'year').month("September").startOf('month'), moment().toDate()];
        }

        return (
            <>
                <Helmet>
                    <title>{t("Pages.UserControl.SearchResult")} | {t('alarabeyya')}</title>
                </Helmet>
                <section className="position-relative padding-bottom padding-top oh " id="main-search-section" style={{
                    backgroundColor: '#b8f0ff',
                    minHeight: '100vh'
                }}>
                    <Field />
                    <style dangerouslySetInnerHTML={{
                        __html: `.form-container{max-width:500px;padding:5px;background:#92278f;}.product-list-item .product-thumb{min-height: unset;}.product-list-item{margin-bottom:10px;}.shop-item .product-content .title, .product-list-item .product-content .title{font-size:18px;}.product-list-item .product-content{padding: 10px 10px 5px 5px;}.product-list-item{min-height: 210px;}.product-list-item .product-content .subtitle{font-size:14px;}.product-list-item .product-content p{font-size:13px;}`
                    }}></style>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-4 mb-50">
                                <div className="widget widget-category">
                                    <div className="widget-header d-flex justify-content-between">
                                        <h5 className="title align-self-center">
                                            {t("Pages.Search.title")}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <div className="contact-form">

                                            <div className={`form-group w-100`}>
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-calendar-week"></i>{t('Pages.Search.Date.title')}</label>
                                                <DateRangePicker
                                                    ref={Date => this.Date = Date}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        ranges: ranges,
                                                        autoUpdateInput: false,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        timePicker: false,
                                                        format: 'DD/MM/YYYY',
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        minYear: 2023,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            firstDay: 6,
                                                            applyLabel: t('Apply'),
                                                            customRangeLabel: t('range.customRange'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.date}
                                                        name="Date"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        placeholder={t('Pages.Search.Date.Placeholder')}
                                                        title={t('Pages.Search.Date.title')}
                                                    />
                                                </DateRangePicker>
                                            </div>

                                            <div className="form-group w-100 ">
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-keyboard"></i>{t('Pages.Search.Type.title')}</label>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsLessons(e.target.checked)}
                                                        ref={IsLessons => this.IsLessons = IsLessons}
                                                        defaultChecked={true}
                                                        name="IsLessons"
                                                        id="IsLessons" />
                                                    <label className="custom-control-label" htmlFor="IsLessons" >
                                                        {t("PageHeader.Lessons")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsFiles(e.target.checked)}
                                                        ref={IsFiles => this.IsFiles = IsFiles}
                                                        defaultChecked={true}
                                                        name="IsFiles"
                                                        id="IsFiles" />
                                                    <label className="custom-control-label" htmlFor="IsFiles" >
                                                        {t("PageHeader.Files")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={true}
                                                        onChange={e => this.setIsExams(e.target.checked)}
                                                        ref={IsExams => this.IsExams = IsExams}
                                                        name="IsExams"
                                                        id="IsExams" />
                                                    <label className="custom-control-label" htmlFor="IsExams" >
                                                        {t("PageHeader.Exams")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        ref={IsQuizzes => this.IsQuizzes = IsQuizzes}
                                                        onChange={e => this.setIsQuizzes(e.target.checked)}
                                                        defaultChecked={true}
                                                        name="IsQuizzes"
                                                        id="IsQuizzes" />
                                                    <label className="custom-control-label" htmlFor="IsQuizzes" >
                                                        {t("PageHeader.Quizzes")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsFun(e.target.checked)}
                                                        ref={IsFun => this.IsFun = IsFun}
                                                        defaultChecked={true}
                                                        name="IsFun"
                                                        id="IsFun" />
                                                    <label className="custom-control-label" htmlFor="IsFun" >
                                                        {t("PageHeader.Fun")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-primary">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsBooks(e.target.checked)}
                                                        ref={IsBooks => this.IsBooks = IsBooks}
                                                        defaultChecked={true}
                                                        name="IsBooks"
                                                        id="IsBooks" />
                                                    <label className="custom-control-label" htmlFor="IsBooks" >
                                                        {t("PageHeader.Books")}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group w-100">
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-screen-users"></i>
                                                    {t('Pages.UsersControl.advancedSearch.ClassType.Placeholder')}</label>
                                                <select
                                                    defaultValue={this.state.ClassTypeId}
                                                    onChange={e => this.setClassTypeId(e.target.value)}
                                                    className="form-control"
                                                    name="ClassTypeId"
                                                    placeholder={t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}
                                                    title={t("Pages.UsersControl.advancedSearch.ClassType.title")}  >
                                                    <option value="">{t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}</option>
                                                    {this.renderClassTypeOption()}
                                                </select>
                                            </div>
                                            <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-danger">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    ref={IsDetails => this.IsDetails = IsDetails}
                                                    onChange={e => this.setIsDetails(e.target.checked)}
                                                    defaultChecked={true}
                                                    name="IsDetails"
                                                    id="IsDetails" />
                                                <label className="custom-control-label" htmlFor="IsDetails" >
                                                    {t("Pages.Search.IsDetails")}
                                                </label>
                                            </div>

                                            <div className="custom-control custom-checkbox my-1 mr-sm-4 py-2 checkbox-warning">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    ref={NoResultOnly => this.NoResultOnly = NoResultOnly}
                                                    onChange={e => this.setNoResultOnly(e.target.checked)}
                                                    defaultChecked={false}
                                                    name="NoResultOnly"
                                                    id="NoResultOnly" />
                                                <label className="custom-control-label" htmlFor="NoResultOnly" >
                                                    {t("Pages.Search.NoResultOnly")}
                                                </label>
                                            </div>

                                            <div className="form-group w-100 ">
                                                <button className="custom-button w-100" onClick={() => this.getSearch()}  >
                                                    <span>
                                                        <i className={`icon fal fa-magnifying-glass`}></i>
                                                        {
                                                            t('Pages.Search.submit')
                                                        }
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-category h-100">
                                    <div className="widget-body">
                                        <table className="table table-striped table-hover table-bordered my-0 w-100 bg-white">
                                            {
                                                this.IsDetails && this.IsDetails.checked ?
                                                    <>
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th title={t(`Pages.Search.key`)} className="align-middle text-nowrap">
                                                                    <i className="icon fa-light fa-key "></i>
                                                                    <span className="d-none d-lg-inline-block">
                                                                        {t(`Pages.Search.key`)}
                                                                    </span>
                                                                </th>
                                                                <th title={t('Pages.Search.type')} className="align-middle text-nowrap">
                                                                    <i className="icon fa-light fa-keyboard "></i>
                                                                    <span className="d-none d-lg-inline-block">
                                                                        {t('Pages.Search.type')}
                                                                    </span>
                                                                </th>
                                                                <th title={t('Pages.Search.Table.detail')} className="align-middle text-nowrap">
                                                                    <i className="icon fa-light fa-info"></i>
                                                                    <span className="d-none d-lg-inline-block">
                                                                        {t('Pages.Search.Table.detail')}
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.renderCols()}
                                                        </tbody>
                                                    </>
                                                    :
                                                    <>
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th title={t(`Pages.Search.key`)} className="align-middle text-nowrap">
                                                                    <i className="icon fa-light fa-key "></i>
                                                                    <span className="d-none d-lg-inline-block">
                                                                        {t(`Pages.Search.key`)}
                                                                    </span>
                                                                </th>
                                                                <th title={t('Pages.Search.Table.count')} className="align-middle text-nowrap">
                                                                    <i className="icon fa-light fa-tally "></i>
                                                                    <span className="d-none d-lg-inline-block">
                                                                        {t('Pages.Search.Table.count')}
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.renderColsGroup()}
                                                        </tbody>
                                                    </>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(SearchResultList));
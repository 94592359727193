import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Backgrounds from "../../Admin/Question/View/Background";
import Footer from "../../Footer/FooterBottom";
import UserGuideIndices from "./Indices/";
import {
    fetchIndices as fetch
} from './Indices/actions';
import TreeView from "./Indices/TreeView";
import InfoForm from "./Indices/Forms/InfoForm";
import api from "../../../api/api";
import DragDropModal from "./Modal/DragDropModal";
import history from "../../../history";

class UserGuide extends React.Component {

    state = {
        showEditIndex: false,
        showEditFormModal: false,
        showDragDropModal: false,
        currentItem: null,
        UserGuides: [],
        item: null
    };

    componentDidMount() {
        window.scrollToPosition("user-guide-section");
        const { id } = this.props.match.params;
        if (id) {
            this.setState({
                currentItem: {
                    id: parseInt(id)
                }
            });
            this.getUserGuides(id);
        }
        this.props.fetch();
    }

    handleClose = () => {
        this.setState({
            showDragDropModal: false,
            showEditIndex: false,
            showEditFormModal: false
        });
    }

    showDragDropModal = () => {
        this.setState({
            showDragDropModal: true
        });
    }

    setSort = UserGuides => {
        this.setState({
            showDragDropModal: false,
            UserGuides
        });
    }

    showEditIndex = () => {
        this.setState({
            showEditIndex: true
        });
    }

    showEditFormModal = () => {
        this.setState({
            showEditFormModal: true
        });
    }

    setCurrentItem = item => {
        if (!this.state.currentItem || item.id !== this.state.currentItem.id) {
            this.setState({ currentItem: item });
            this.getUserGuides(item.id);
            history.push(`/User/UserGuide/${item.id}`);
        }
    }

    getUserGuides = id => {
        const { t } = this.props;
        let _toastId = new React.createRef();

        _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.get(`/UserGuides/${id}?ByIndex=${true}`).then(response => {

            this.setState({ UserGuides: response.data.data });
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/UserGuides/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UserGuide.Article.add_success_toast.Title')}</div> <div className="h6">{t('Pages.UserGuide.Article.add_success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.setState({
                    showEditFormModal: false
                });

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UserGuide.Article.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.UserGuide.Article.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    edit = item => {
        this.setState({
            showEditFormModal: true,
            item
        });
    }

    showDelete = item => {
        if (item) {
            const id = item.Id;
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteUserGuides(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    deleteUserGuides = id => {
        const { t } = this.props;
        const UserGuides = this.state.UserGuides.filter(p => p.Id !== id);

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/UserGuides/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UserGuide.Indices.Delete.Title')}</div> <div className="h6">{t('Pages.UserGuide.Indices.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            this.setState({
                UserGuides
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    renderUserGuides = () => {
        const { t } = this.props;
        return this.state.UserGuides.map(item => {
            return <li key={`UserGuides-${item.Id}`} className="d-flex justify-content-between flex-wrap">
                <Link to={`/User/UserGuides/Article/${item.Id}`}>
                    {item.Title}
                </Link>
                <div className="d-flex">
                    <div className="btn btn-sm btn-success" onClick={() => this.edit(item)} title={t("Pages.UserGuide.Article.action.edit")}><i className="far fa-edit"></i></div>
                    <div className="btn btn-sm btn-danger mx-1" onClick={() => this.showDelete(item)} title={t("Pages.UserGuide.Article.action.remove")}><i className="far fa-trash"></i></div>
                </div>
            </li>
        });
    }

    render() {
        const { t, Indices } = this.props;

        return (
            <>
                {
                    this.state.showDragDropModal ?
                        <DragDropModal
                            show={this.state.showDragDropModal}
                            nodes={this.state.UserGuides}
                            IndexId={this.state.currentItem ? this.state.currentItem.id : 0}
                            setSort={this.setSort}
                            handleClose={this.handleClose} />
                        : ""
                }
                {
                    this.state.showEditIndex ?
                        <Modal
                            size="xl"
                            show={this.state.showEditIndex}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fa-light fa-indent "></i> {t('Pages.UserGuide.title')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UserGuideIndices />
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                {
                    this.state.showEditFormModal ?
                        <Modal
                            size="xl"
                            show={this.state.showEditFormModal}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-image-polaroid"></i> {t('Pages.UserGuide.Article.form.title')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <InfoForm onSubmit={this.onSubmit} UserGuideIndices={Indices} item={this.state.item} />
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                <Helmet>
                    <title>
                        {t('Pages.UserControl.UserGuide')} | {t('alarabeyya')}
                    </title>
                    <link rel="stylesheet" type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                    <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                </Helmet>
                <section className="pos-rel call-in-action call-overlay oh" id="user-guide-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom padding-top" style={{
                        minHeight: '100vh'
                    }}>
                        <Backgrounds CustomBackground="ReadGroup" />
                        <div className="container h-100" >
                            <div className="widget widget-search" >
                                <div className="widget-body">
                                    <div className="row mb-30-none">
                                        <div className="col-lg-4 mb-50">
                                            <div onClick={() => this.showEditIndex()} title={t("Pages.UserGuide.Article.Add")} className="btn btn-primary ">
                                                <i className="fas fa-plus"></i>
                                            </div>
                                            <TreeView
                                                currentId={this.state.currentItem ? this.state.currentItem.id : 0}
                                                data={Indices.nodes}
                                                toRemove={Indices.toRemove}
                                                setCurrentItem={this.setCurrentItem}
                                            />
                                        </div>
                                        <div className="col-lg-8 mb-50">

                                            <div className="d-flex">
                                                <div className="p-2 flex-grow-1 ">
                                                    <form className="search-form">
                                                        <input type="text" placeholder={t("Pages.UserGuide.Article.search")} />
                                                        <button type="button"><i className="fas fa-search"></i></button>
                                                    </form>
                                                </div>
                                                <div className="pt-3">
                                                    <div onClick={() => this.showEditFormModal()} title={t("Pages.UserGuide.Article.Add")} className="btn btn-primary ">
                                                        <i className="fas fa-plus"></i>
                                                    </div>
                                                    <div onClick={() => this.showDragDropModal()} title={t("Pages.Lessons.Sort")} className="btn btn-dark mx-1">
                                                        <i className="fas fa-sort-numeric-up"></i>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="list">
                                                <ul>
                                                    {this.renderUserGuides()}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        Indices: state.UserGuideIndices
    };
};
export default connect(mapStateToProps, { fetch })(withTranslation('common')(UserGuide));
import React from "react";
import { withTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Popover from "react-awesome-popover";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { Link } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import { Modal } from "react-bootstrap";

import TreeView from "../../Indices/TreeView";
import { getIndeices, setIndeices } from "../../../../context/sessionStorage";
import { fetchIndices } from '../../Indices/actions/';
import effectData from "../../../Config/effect.json";
import soundData from "../../../Config/Sound.json";
import MaterialsObjectDragDrop from "./MaterialsObjectDragDrop";
import api from "../../../../api/api";
import { toast } from "react-toastify";

class InfoForm extends React.Component {

    state = {
        IndexId: 0, indices: [], isLoaded: false,
        MaterialsObject: [],
        CopyId: "",
        isFile: true,
        displayColorPicker: false,
        displayTimeColorPicker: false,
        displayBackgroundColorPicker: false,
        showConfetti: false,
        showConfettiTimesUp: false,
        ShowMaterials: false,
        TimeType: "continue",
        width: 100,
        BorderWidth: 3,
        TimeHour: 0,
        TimeMinutes: 0,
        BorderStyle: 'dashed',
        BorderColor: 'rgba(68, 68, 68, 1)',
        TimeBorderColor: 'rgba(146, 39, 143, 1)',
        BackgroundBorderColor: 'rgba(255, 255, 255, 1)',
    };

    constructor(props) {
        super(props);
        this.examBackground = effectData.exam;
        this.times_up = effectData.times_up;
        this.Finish_Sound = soundData.Finish_Sound;
        this.audio = new Audio();
    }

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });
    }

    renderTimesUpSound() {
        return this.Finish_Sound.map(item => {
            return <option key={item.url} value={item.url}> {item.name}</option >;
        });
    }

    renderTimesUp() {
        return this.times_up.map(item => {
            return <option key={item.url} value={item.url}> {item.name}</option >;
        });
    }

    renderConfetti() {
        return this.examBackground.map(item => {
            return <option key={item.url} value={item.url}> {item.name}</option >;
        });
    }

    playTimesUpSound = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
        }
        if (this.state.TimesUpSound !== 'custom' && this.state.TimesUpSound !== 'random' && this.state.TimesUpSound !== 'none') {
            this.audio = new Audio(process.env.PUBLIC_URL + this.state.TimesUpSound);
            this.audio.play();
        } else if (this.state.TimesUpSound === 'custom' && this.state.CustomTimesUpSound) {
            this.audio = new Audio(this.state.CustomTimesUpSound);
            this.audio.play();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isLoaded) {
            state.IndexId = props.IndexId;
            state.isLoaded = true;
        }

        state.Confetti = props.Confetti;
        state.CustomConfetti = props.CustomConfetti;

        state.CustomTimesUpSound = props.CustomTimesUpSound;
        state.TimesUpSound = props.TimesUpSound;
        state.TimeType = props.TimeType;
        state.TimeHour = props.TimeHour;
        state.TimeMinutes = props.TimeMinutes;
        state.ConfettiTimesUp = props.ConfettiTimesUp;
        state.CustomConfettiTimesUp = props.CustomConfettiTimesUp;
        state.BorderStyle = props.BorderStyle;
        state.BorderWidth = props.BorderWidth;
        state.BorderColor = props.BorderColor;
        state.TimeBorderColor = props.TimeBorderColor ? props.TimeBorderColor : 'rgba(146, 39, 143, 1)';
        state.BackgroundBorderColor = props.BackgroundBorderColor ? props.BackgroundBorderColor : 'rgba(255, 255, 255, 1)';
        state.width = props.width;
        state.type = props.type ? `Pages.${props.type}.forms.LessonInfo` : "Pages.Lessons.forms.LessonInfo";
        state.MaterialsObject = props.MaterialsObject;
        return state;
    }

    ShowMaterials = () => {
        this.setState({
            ShowMaterials: true
        });
    }

    handleClose = () => {
        this.setState({
            ShowMaterials: false,
            CopyId: ""
        });
    }

    playConfetti = () => {
        this.setState({
            showConfetti: !this.state.showConfetti
        });
    }

    playConfettiTimesUp = () => {
        this.setState({
            showConfettiTimesUp: !this.state.showConfettiTimesUp
        });
    }

    renderPalyerTimesUp() {
        if (this.state.ConfettiTimesUp && this.state.showConfettiTimesUp) {
            let src = "";
            if (this.state.ConfettiTimesUp !== 'custom' && this.state.ConfettiTimesUp !== 'random' && this.state.ConfettiTimesUp !== 'none') {
                src = process.env.PUBLIC_URL + this.state.ConfettiTimesUp;
            } else if (this.state.ConfettiTimesUp === 'custom' && this.state.CustomConfettiTimesUp) {
                src = this.state.CustomConfettiTimesUp;
            }
            if (src) {
                return (
                    <div className="position-absolute w-100" style={{
                        pointerEvents: 'none',
                        top: '50%',
                        left: '50%',
                        zIndex: 1009,
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <Player
                            onEvent={event => {
                                if (event === 'complete') {
                                    this.setState({
                                        showConfettiTimesUp: false
                                    });
                                }
                            }}
                            autoplay
                            loop={false}
                            src={src}
                            style={{ height: '100%', width: '100%' }}   ></Player>
                    </div>
                );
            }
        }
    }

    renderPalyer() {
        if (this.state.Confetti && this.state.showConfetti) {
            let src = "";
            if (this.state.Confetti !== 'custom' && this.state.Confetti !== 'random' && this.state.Confetti !== 'none') {
                src = process.env.PUBLIC_URL + this.state.Confetti;
            } else if (this.state.Confetti === 'custom' && this.state.CustomConfetti) {
                src = this.state.CustomConfetti;
            }
            if (src) {
                return (
                    <div className="position-absolute w-100" style={{
                        pointerEvents: 'none',
                        top: '50%',
                        left: '50%',
                        zIndex: 1009,
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <Player
                            onEvent={event => {
                                if (event === 'complete') {
                                    this.setState({
                                        showConfetti: false
                                    });
                                }
                            }}
                            autoplay
                            loop={false}
                            src={src}
                            style={{ height: '100%', width: '100%' }}   ></Player>
                    </div>
                );
            }
        }
    }

    setIsPublic = value => {
        this.props.setIsPublic(value);
    }
    setIsActive = value => {
        this.props.setIsActive(value);
    }
    setIcon = value => {
        this.props.setIcon(value);
    }
    setBackground = value => {
        this.props.setBackground(value);
    }
    setSound = value => {
        this.props.setSound(value);
    }
    setBio = value => {
        this.props.setBio(value);
    }
    setUrl = value => {
        this.props.setUrl(value);
    }
    setName = value => {
        this.props.setName(value);
    }
    setFontSize = value => {
        this.props.setFontSize(value);
    }

    validateName = value => {
        const { t } = this.props;
        if (!value) {
            return t(`${this.state.type}.Name.placeholder`);
        }
    }

    setCurrentIndexItem = item => {
        this.setState({ IndexId: item.id });

        this.props.setIndexId(item.id);
    }

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false })
    };

    handleClickDisplayColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleColorChange = (color) => {
        this.setState({ BorderColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setBorderColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    handleCloseTimeColorPicker = () => {
        this.setState({ displayTimeColorPicker: false })
    };

    handleClickDisplayTimeColorPicker = () => {
        this.setState({ displayTimeColorPicker: !this.state.displayTimeColorPicker });
    };

    handleTimeColorChange = (color) => {
        this.setState({ TimeBorderColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setTimeBorderColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    handleCloseBackgroundColorPicker = () => {
        this.setState({ displayBackgroundColorPicker: false })
    };

    handleClickDisplayBackgroundColorPicker = () => {
        this.setState({ displayBackgroundColorPicker: !this.state.displayBackgroundColorPicker });
    };

    handleBackgroundColorChange = (color) => {
        this.setState({ BackgroundBorderColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
        this.props.setBackgroundBorderColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    setWidth = value => {
        this.setState({ width: value });
        if (value >= 1 && value <= 100) {
            this.props.setWidth(value);
        } else if (value > 100) {
            this.setState({ width: 100 });
            this.props.setWidth(100);
        }
    }

    renderField = ({
        input,
        title,
        type,
        defaultValue,
        id,
        placeholder,
        meta: { touched, error }
    }) => (
        <div>
            <input {...input} id={id} value={defaultValue} title={title} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
    );

    setCopyId = value => {
        this.setState({
            CopyId: value
        });
    }

    setIsFile = isFile => {
        this.setState({
            isFile
        });
    }

    addMaterials = () => {
        const { t } = this.props;

        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.get(`/LessonInfo/?id=${this.state.CopyId}&isFile=${this.state.isFile}`).then(response => {
            let MaterialsObject = this.state.MaterialsObject;
            if (MaterialsObject) {
                MaterialsObject.push({
                    id: response.data.id,
                    title: response.data.title,
                    type: response.data.type
                });
            }
            this.setState({
                MaterialsObject
            });
            toast.dismiss(toastId);
            this.props.setMaterialsObject(MaterialsObject);
            this.handleClose();
        }).catch(error => {
            toast.update(toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-wifi-slash"></i>
                        {t('Error.NoData.Title')}
                    </div>
                    <div className="h6">
                        {t('Error.NoData.Body')}
                    </div>
                </div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    removeItem = itemIndex => {
        let MaterialsObject = this.state.MaterialsObject.filter(p => p.id !== itemIndex);
        this.setState({
            MaterialsObject
        });
        this.props.setMaterialsObject(MaterialsObject);
    }

    setSort = MaterialsObject => {
        this.props.setMaterialsObject(MaterialsObject);
    }

    render() {
        const { t } = this.props;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '12',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let style = {
            borderWidth: `${this.state.BorderWidth}px`,
            borderStyle: this.state.BorderStyle,
            borderColor: this.state.BorderColor,
            width: `${this.state.width}%`
        };

        return (
            <>
                {
                    this.state.ShowMaterials ? <>
                        <Modal
                            size="md"
                            show={true}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            autoFocus={true}
                            enforceFocus={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div className="h5 mb-0">
                                        <i className="icon fal fa-marker"></i>
                                        {t('Pages.SelfDevelopment.Materials.add')}
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className='col-12'>
                                        <div className="widget widget-search"  >
                                            <div className="widget-body ">
                                                <div className="d-flex mb-0">
                                                    <div className="p-2 flex-grow-1 " title={t("Pages.Lessons.Questions.forms.CopyId")}>
                                                        <form className="search-form">
                                                            <input
                                                                onChange={e => this.setCopyId(e.target.value)}
                                                                defaultValue={this.state.CopyId}
                                                                placeholder={t("Pages.Lessons.Questions.forms.CopyId")} />
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="w-100">
                                                    <ul className="filter mb-2 px-0" style={{
                                                        boxShadow: "none"
                                                    }}>
                                                        <li>
                                                            <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                                                <input
                                                                    type='radio'
                                                                    checked={this.state.isFile ? true : false}
                                                                    onChange={e => this.setIsFile(true)}
                                                                    className="custom-control-input"
                                                                    title={t('Pages.SelfDevelopment.Materials.File')}
                                                                    name="IsFile"
                                                                    id="IsFile" />
                                                                <label className="custom-control-label pt-1" htmlFor="IsFile" >
                                                                    <i className="icon fas fa-paperclip "></i>
                                                                    {t('Pages.SelfDevelopment.Materials.File')}
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="custom-control custom-radio mr-sm-4 checkbox-dark">
                                                                <input
                                                                    type='radio'
                                                                    checked={!this.state.isFile ? true : false}
                                                                    onChange={e => this.setIsFile(false)}
                                                                    title={t('Pages.SelfDevelopment.Materials.Lessons')}
                                                                    className="custom-control-input"
                                                                    name="IsFile"
                                                                    id="IsNotFile" />
                                                                <label className="custom-control-label pt-1" htmlFor="IsNotFile" >
                                                                    <i className="icon fas fa-layer-group"></i>
                                                                    {t('Pages.SelfDevelopment.Materials.Lessons')}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="form-group w-100 text-center">
                                                    <button className="custom-button" type="button" onClick={() => this.addMaterials()}>
                                                        <span>
                                                            <i className="icon fal fa-edit"></i>
                                                            {t('Save')}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </> : ""
                }
                <form method="POST" className="contact-form" onSubmit={this.props.onSubmit}>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Name"> <i className="icon fas fa-heading"></i> {t(`${this.state.type}.Name.title`)}</label>
                        <Field
                            ref={Name => this.Name = Name}
                            id="Name"
                            name="Name"
                            defaultValue={this.props.Name}
                            component={this.renderField}
                            type="text"
                            required={true}
                            validate={this.validateName}
                            onChange={e => this.setName(e.target.value)}
                            placeholder={t(`${this.state.type}.Name.placeholder`)}
                            title={t(`${this.state.type}.Name.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Bio"> <i className="icon fas fa-feather-alt"></i> {t(`${this.state.type}.Bio.title`)}</label>
                        <Field
                            ref={Bio => this.Bio = Bio}
                            id="Bio"
                            name="Bio"
                            defaultValue={this.props.Bio}
                            component={this.renderField}
                            onChange={e => this.setBio(e.target.value)}
                            type="text"
                            placeholder={t(`${this.state.type}.Bio.placeholder`)}
                            title={t(`${this.state.type}.Bio.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="bg-white w-100 p-1 mb-2"></div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Sound"> <i className="icon fas fa-volume"></i> {t(`${this.state.type}.Sound.title`)}</label>
                        <Field
                            ref={Sound => this.Sound = Sound}
                            id="Sound"
                            name="Sound"
                            defaultValue={this.props.Sound}
                            component={this.renderField}
                            type="url"
                            onChange={e => this.setSound(e.target.value)}
                            placeholder={t(`${this.state.type}.Sound.placeholder`)}
                            title={t(`${this.state.type}.Sound.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="FontSize"> <i className="icon fas fa-font"></i> {t(`${this.state.type}.FontSize.title`)}</label>
                        <Field
                            ref={FontSize => this.FontSize = FontSize}
                            id="FontSize"
                            name="FontSize"
                            defaultValue={this.props.FontSize}
                            component={this.renderField}
                            type="number"
                            onChange={e => this.setFontSize(e.target.value)}
                            placeholder={t(`${this.state.type}.FontSize.placeholder`)}
                            title={t(`${this.state.type}.FontSize.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Background"> <i className="icon fas fa-image"></i> {t(`${this.state.type}.Background.title`)}</label>
                        <Field
                            ref={Background => this.Background = Background}
                            id="Background"
                            name="Background"
                            defaultValue={this.props.Background}
                            component={this.renderField}
                            onChange={e => this.setBackground(e.target.value)}
                            type="url"
                            placeholder={t(`${this.state.type}.Background.placeholder`)}
                            title={t(`${this.state.type}.Background.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Icon"> <i className="icon fas fa-image"></i> {t(`${this.state.type}.Icon.title`)}</label>
                        <Field
                            ref={Icon => this.Icon = Icon}
                            id="Icon"
                            name="Icon"
                            defaultValue={this.props.Icon}
                            component={this.renderField}
                            onChange={e => this.setIcon(e.target.value)}
                            type="url"
                            placeholder={t(`${this.state.type}.Icon.placeholder`)}
                            title={t(`${this.state.type}.Icon.placeholder`)}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="Url"> <i className="icon fas fa-file-pdf"></i> {t(`${this.state.type}.Url.title`)}</label>
                        <Field
                            ref={Url => this.Url = Url}
                            id="Url"
                            name="Url"
                            defaultValue={this.props.Url}
                            component={this.renderField}
                            onChange={e => this.setUrl(e.target.value)}
                            type="url"
                            placeholder={t(`${this.state.type}.Url.placeholder`)}
                            title={t(`${this.state.type}.Url.placeholder`)}
                            className="form-control"
                        />
                    </div>
                    <div className="bg-white w-100 p-1 mb-2"></div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="IsActive"> <i className="icon fas fa-lock-alt"></i> {t(`${this.state.type}.IsActive.title`)}</label>
                        <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-success">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={e => this.setIsActive(e.target.checked)}
                                ref={IsActive => this.IsActive = IsActive}
                                defaultChecked={this.props.IsActive}
                                name="IsActive"
                                id="IsActive" />
                            <label className="custom-control-label" htmlFor="IsActive" >
                                {t(`${this.state.type}.IsActive.placeholder`)}
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="text-dark font-size-h5" htmlFor="IsPublic"> <i className="icon far fa-globe"></i>{t(`${this.state.type}.IsPublic.title`)}</label>
                        <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-danger">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={e => this.setIsPublic(e.target.checked)}
                                ref={IsPublic => this.IsPublic = IsPublic}
                                defaultChecked={this.props.IsPublic}
                                name="IsPublic"
                                id="IsPublic" />
                            <label className="custom-control-label" htmlFor="IsPublic" >
                                {t(`${this.state.type}.IsPublic.placeholder`)}
                            </label>
                        </div>
                    </div>
                    <div className="bg-white w-100 p-1 mb-2"></div>

                    <div className="d-flex w-100">

                        <div className="px-5 py-3 bg-white m-2  text-primary border border-primary rounded">
                            <Popover overlayColor="rgba(0,0,0,0)">
                                <Link to="#" className="cate d-flex align-items-center">
                                    <i className="icon fas fa-border-style"></i>
                                    {t('Pages.Lessons.Questions.forms.tools.border')}
                                </Link>
                                <div className='widget widget-search' style={{
                                    width: '400px'
                                }}>
                                    <div className='widget-header p-2 text-center' >
                                        <i className="icon fas fa-border-style"></i>
                                        {t('Pages.Lessons.Questions.forms.tools.border')}
                                    </div>
                                    <div className='widget-body p-3'>
                                        <div className="contact-form">

                                            <div className="form-group mb-3">
                                                <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.Lessons.Questions.forms.tools.color')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.color')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.color')}
                                                        readOnly={true}
                                                        value={this.state.BorderColor}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={this.handleClickDisplayColorPicker}
                                                        style={{
                                                            color: this.state.BorderColor !== 'transparent' ? this.state.BorderColor : "",
                                                        }}>
                                                        <i className="fas fa-tint"></i>
                                                    </button>
                                                    {
                                                        this.state.displayColorPicker ?
                                                            <div style={styles.popover}>
                                                                <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                                                                <SketchPicker color={this.state.BorderColor} onChange={this.handleColorChange} />
                                                            </div> : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label className="text-dark h6"><i className="icon fas fa-border-style"></i> {t('Pages.Lessons.Questions.forms.tools.BorderStyle.title')}</label>
                                                <div className="search-form">
                                                    <select
                                                        onChange={e => this.props.setBorderStyle(e.target.value)}
                                                        className="w-100"
                                                        value={this.state.BorderStyle}
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.BorderStyle.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.BorderStyle.title')}>
                                                        <option value="none">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.none')}</option>
                                                        <option value="solid">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.solid')}</option>
                                                        <option value="dashed">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.dashed')}</option>
                                                        <option value="dotted">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.dotted')}</option>
                                                        <option value="double">{t('Pages.Lessons.Questions.forms.tools.BorderStyle.double')}</option>
                                                    </select>
                                                    <button type="button">
                                                        <i className="fas fa-border-style"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label className="text-dark h6"><i className="icon fas fa-border-all"></i> {t('Pages.Lessons.Questions.forms.tools.BorderWidth.title')}</label>
                                                <div className="search-form">
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.BorderWidth.placeholder')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.BorderWidth.title')}
                                                        value={this.state.BorderWidth}
                                                        onChange={e => this.props.setBorderWidth(e.target.value)}
                                                    />
                                                    <button type="button">
                                                        <i className="fas fa-border-all"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="text-dark h6"><i className="icon fas fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.width')}</label>
                                                <div className="search-form">
                                                    <input
                                                        type="text"
                                                        className="w-100"
                                                        placeholder={t('Pages.Lessons.Questions.forms.tools.width')}
                                                        title={t('Pages.Lessons.Questions.forms.tools.width')}
                                                        value={this.state.width}
                                                        onChange={e => this.setWidth(e.target.value)} />
                                                    <button type="button">
                                                        <i className="fas fa-arrows-h"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                        <div className="d-flex w-100 align-items-center">
                            <div className="w-100 d-flex justify-content-center">
                                <div className="border-top-0 border-left-0 border-right-0" style={style}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-100 p-1 mb-2"></div>

                    {
                        this.props.type === "Exams" || this.props.type === "Quizzes" || this.props.type === "SelfDevelopment" ?
                            <>
                                {this.renderPalyer()}
                                {this.renderPalyerTimesUp()}
                                <div className="d-flex w-100 align-items-center">
                                    <div className="px-5 py-3 bg-white m-2 text-primary border border-primary rounded">
                                        <Popover overlayColor="rgba(0,0,0,0)">
                                            <Link to="#" className="cate d-flex align-items-center" style={{
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <i className="icon fal fa-stopwatch"></i>
                                                {t('Pages.' + this.props.type + '.Time')}
                                            </Link>
                                            <div className='widget widget-search' style={{
                                                width: '400px'
                                            }}>
                                                <div className='widget-header p-2 text-center' >
                                                    <i className="icon fal fa-stopwatch"></i>
                                                    {t('Pages.' + this.props.type + '.Time')}
                                                </div>
                                                <div className='widget-body p-3'>
                                                    <div className="contact-form">

                                                        <div className="form-group mb-3">
                                                            <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.' + this.props.type + '.tools.color')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.' + this.props.type + '.tools.color')}
                                                                    title={t('Pages.' + this.props.type + '.tools.color')}
                                                                    readOnly={true}
                                                                    value={this.state.TimeBorderColor}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={this.handleClickDisplayTimeColorPicker}
                                                                    style={{
                                                                        color: this.state.TimeBorderColor !== 'transparent' ? this.state.TimeBorderColor : "",
                                                                    }}>
                                                                    <i className="fas fa-tint"></i>
                                                                </button>
                                                                {
                                                                    this.state.displayTimeColorPicker ?
                                                                        <div style={styles.popover}>
                                                                            <div style={styles.cover} onClick={this.handleCloseTimeColorPicker} />
                                                                            <SketchPicker color={this.state.TimeBorderColor} onChange={this.handleTimeColorChange} />
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="text-dark h6"><i className="icon fas fa-tint"></i> {t('Pages.' + this.props.type + '.tools.background')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.' + this.props.type + '.tools.background')}
                                                                    title={t('Pages.' + this.props.type + '.tools.background')}
                                                                    readOnly={true}
                                                                    value={this.state.BackgroundBorderColor}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={this.handleClickDisplayBackgroundColorPicker}
                                                                    style={{
                                                                        color: this.state.BackgroundBorderColor !== 'transparent' ? this.state.BackgroundBorderColor : "",
                                                                    }}>
                                                                    <i className="fas fa-tint"></i>
                                                                </button>
                                                                {
                                                                    this.state.displayBackgroundColorPicker ?
                                                                        <div style={styles.popover}>
                                                                            <div style={styles.cover} onClick={this.handleCloseBackgroundColorPicker} />
                                                                            <SketchPicker color={this.state.BackgroundBorderColor} onChange={this.handleBackgroundColorChange} />
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="text-dark h6"><i className="icon fas fa-hourglass-start"></i> {t('Pages.' + this.props.type + '.tools.TimeHour.title')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.' + this.props.type + '.tools.TimeHour.placeholder')}
                                                                    title={t('Pages.' + this.props.type + '.tools.TimeHour.title')}
                                                                    value={this.state.TimeHour}
                                                                    onChange={e => this.props.setTimeHour(e.target.value)}
                                                                />
                                                                <button type="button">
                                                                    <i className="fas fa-hourglass-start"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="text-dark h6"><i className="icon fas fa-hourglass-start"></i> {t('Pages.' + this.props.type + '.tools.TimeMinutes.title')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.' + this.props.type + '.tools.TimeMinutes.placeholder')}
                                                                    title={t('Pages.' + this.props.type + '.tools.TimeMinutes.title')}
                                                                    value={this.state.TimeMinutes}
                                                                    onChange={e => this.props.setTimeMinutes(e.target.value)}
                                                                />
                                                                <button type="button">
                                                                    <i className="fas fa-hourglass-start"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group w-100">
                                                            <label className="text-dark h6"><i className="icon fas fa-traffic-light-stop"></i> {t('Pages.' + this.props.type + '.tools.Type.title')}</label>
                                                            <div className="search-form">
                                                                <select
                                                                    onChange={e => this.props.setTimeType(e.target.value)}
                                                                    className="w-100"
                                                                    value={this.state.TimeType}
                                                                    placeholder={t('Pages.' + this.props.type + '.tools.Type.placeholder')}
                                                                    title={t('Pages.' + this.props.type + '.tools.Type.title')}>
                                                                    <option value="continue">{t('Pages.' + this.props.type + '.tools.Type.continue')}</option>
                                                                    <option value="stoped">{t('Pages.' + this.props.type + '.tools.Type.stoped')}</option>
                                                                </select>
                                                                <button type="button">
                                                                    <i className="fas fa-traffic-light-stop"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3 ">
                                                            <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.TimesUp')}</label>
                                                            <div className="search-form">

                                                                <select
                                                                    onChange={e => this.props.setQuestionConfettiTimesUp(e.target.value)}
                                                                    className="w-100"
                                                                    value={this.state.ConfettiTimesUp ? this.state.ConfettiTimesUp : 'none'}
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                                    <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.none')}</option>
                                                                    <option value="random">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.random')}</option>
                                                                    <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</option>
                                                                    {this.renderTimesUp()}
                                                                </select>

                                                                <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfettiTimesUp()}>
                                                                    <i className="fas fa-violin"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                                    value={this.state.CustomConfettiTimesUp ? this.state.CustomConfettiTimesUp : ''}
                                                                    onChange={e => this.props.setQuestionCustomConfettiTimesUp(e.target.value)}
                                                                />
                                                                <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfettiTimesUp()}>
                                                                    <i className="fas fa-violin"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3 ">
                                                            <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.TimesUp')}</label>
                                                            <div className="search-form">

                                                                <select
                                                                    onChange={e => this.props.setTimesUpSound(e.target.value)}
                                                                    className="w-100"
                                                                    value={this.state.TimesUpSound ? this.state.TimesUpSound : 'none'}
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                                    <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.none')}</option>
                                                                    <option value="random">{t('Pages.Lessons.Questions.forms.tools.sounds.random')}</option>
                                                                    <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</option>
                                                                    {this.renderTimesUpSound()}
                                                                </select>

                                                                <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.play')} onClick={() => this.playTimesUpSound()}>
                                                                    <i className="fas fa-volume"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group ">
                                                            <label className="text-dark h6"><i className="fal fa-volume"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.custom.title')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.sounds.custom.placeholder')}
                                                                    value={this.state.CustomTimesUpSound ? this.state.CustomTimesUpSound : ''}
                                                                    onChange={e => this.props.setCustomTimesUpSound(e.target.value)}
                                                                />
                                                                <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.play')} onClick={() => this.playTimesUpSound()}>
                                                                    <i className="fas fa-volume"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="d-flex w-100 align-items-center">
                                        <div className="w-100 d-flex justify-content-center">
                                            <div className="clock" style={{
                                                borderColor: `${this.state.TimeBorderColor}`,
                                                color: `${this.state.TimeBorderColor}`,
                                                background: `${this.state.BackgroundBorderColor}`
                                            }}>{this.state.TimeHour > 10 ? this.state.TimeHour : "0" + this.state.TimeHour}:{this.state.TimeMinutes > 10 ? this.state.TimeMinutes : "0" + this.state.TimeMinutes}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white w-100 p-1 mb-2"></div>
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <div className="px-5 py-3 bg-white m-2 text-primary border border-primary rounded">
                                        <Popover overlayColor="rgba(0,0,0,0)">
                                            <Link to="#" className="cate d-flex align-items-center" style={{
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <i className="icon fal fa-photo-video"></i>
                                                {t('Pages.' + this.props.type + '.Background')}
                                            </Link>
                                            <div className='widget widget-search' style={{
                                                width: '400px'
                                            }}>
                                                <div className='widget-header p-2 text-center' >
                                                    <i className="icon fal fa-photo-video"></i>
                                                    {t('Pages.' + this.props.type + '.Background')}
                                                </div>
                                                <div className='widget-body p-3'>
                                                    <div className="contact-form">

                                                        <div className="form-group mb-3 ">
                                                            <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.title')}</label>
                                                            <div className="search-form">

                                                                <select
                                                                    onChange={e => this.props.setQuestionConfetti(e.target.value)}
                                                                    className="w-100"
                                                                    value={this.state.Confetti ? this.state.Confetti : 'none'}
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.sounds.placeholder')}>
                                                                    <option value="none">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.none')}</option>
                                                                    <option value="random">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.random')}</option>
                                                                    <option value="custom">{t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</option>
                                                                    {this.renderConfetti()}
                                                                </select>

                                                                <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfetti()}>
                                                                    <i className="fas fa-violin"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group ">
                                                            <label className="text-dark h6"><i className="fal fa-violin"></i> {t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}</label>
                                                            <div className="search-form">
                                                                <input
                                                                    className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                                    title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.custom')}
                                                                    value={this.state.CustomConfetti ? this.state.CustomConfetti : ''}
                                                                    onChange={e => this.props.setQuestionCustomConfetti(e.target.value)}
                                                                />
                                                                <button type="button" title={t('Pages.Lessons.Questions.forms.tools.sounds.confetti.play')} onClick={() => this.playConfetti()}>
                                                                    <i className="fas fa-violin"  ></i>
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                                    {
                                        this.props.type === "SelfDevelopment" ?
                                            <div className="px-5 py-3 bg-white m-2 text-primary border border-primary rounded">
                                                <Popover overlayColor="rgba(0,0,0,0)">
                                                    <Link to="#" className="cate d-flex align-items-center" style={{
                                                        whiteSpace: 'nowrap'
                                                    }}><i className="icon fal fa-marker"></i>
                                                        {t('Pages.SelfDevelopment.Materials.title')}
                                                    </Link>
                                                    <div className='widget widget-search' style={{
                                                        width: '400px'
                                                    }}>
                                                        <div className='widget-header p-2 text-center' >
                                                            <i className="icon fal fa-marker"></i>
                                                            {t('Pages.SelfDevelopment.Materials.title')}
                                                        </div>
                                                        <div className='widget-body p-3'>
                                                            <div className="contact-form">
                                                                <div className="btn btn-block btn-dark" onClick={() => this.ShowMaterials()}>
                                                                    <i className="icon fas fa-plus"></i>
                                                                    {t('Pages.SelfDevelopment.Materials.add')}
                                                                </div>
                                                                <div className="widget-header p-1 w-100 mb-3"></div>
                                                                <div className="w-100 position-relative">
                                                                    <MaterialsObjectDragDrop
                                                                        items={this.state.MaterialsObject}
                                                                        setSort={this.setSort}
                                                                        DeleteItem={this.removeItem}
                                                                    />
                                                                </div>
                                                                <div className="widget-header p-1 w-100 mb-3"></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Popover>
                                            </div> : ""
                                    }
                                </div>
                                <div className="bg-white w-100 p-1 mb-2"></div>

                            </> : ""
                    }
                    <div className="form-group col-sm-12 col-md-11  col-lg-10 col-xl-10 col-xxl-8 mx-auto">
                        {indices ?
                            <TreeView
                                currentId={this.state.IndexId}
                                data={indices.nodes}
                                type={this.props.typeTreeView ? this.props.typeTreeView : `Lessons`}
                                title={t(`${this.state.type}.Index.placeholder`)}
                                toRemove={indices.toRemove}
                                setCurrentItem={this.setCurrentIndexItem}></TreeView>
                            : ""
                        }
                    </div>
                </form>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, { fetchIndices })(withTranslation('common')(reduxForm({
    form: 'Contact'
})(InfoForm)));

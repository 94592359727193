import React from "react";
import GameObjects from "../../GameObjects";
import BubbleBackground from "../bubble";
import './style.css';

class Sea extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url('/assets/images/bg/sea.png')`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
            top: '0'
        }}>

            <BubbleBackground />
            <div className="position-absolute d-none d-sm-block" style={{
                bottom: `0%`,
                left: `10%`
            }}><GameObjects objectCss="Shell" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute MoveSubmarine d-none d-sm-block" style={{
                bottom: `50%`,
                left: `10%`
            }}><GameObjects objectCss="SubmarineWithGun" flipX={true} status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute MoveBird "><GameObjects objectCss="YallowParrot" scale={0.7} transformOriginY="top" /></div>
            <div className="position-absolute MoveFish2 d-none d-sm-block"><GameObjects objectCss="YellowFish" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="position-absolute MoveFish3 d-none d-sm-block"><GameObjects objectCss="PurpleFish" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
            <div className="position-absolute MoveFish4 d-none d-sm-block"><GameObjects objectCss="GreenFish" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute MoveDiver" style={{
                bottom: `15%`,
                right: `2%`
            }}><GameObjects objectCss="KidDiver" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
        </div>
    }
}
export default Sea;
import React from "react";
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';

import Lessons from './Lessons';

class BookLessons extends React.Component {

    state = { startFrom: 0, id: 0, title: '', parentId: 0 }

    constructor(props) {
        super(props);
        window.scrollToTop();
        const GetNode = this.GetNode;
        const HasNode = this.HasNode;
        const hasSelectedNodeInChildren = this.hasSelectedNodeInChildren;
        this.state = {
            GetNode,
            HasNode,
            hasSelectedNodeInChildren
        };
    }

    componentDidMount() {
        const { indexId } = this.props.match.params;
        if (indexId !== '0') {
            this.setState({
                id: parseInt(indexId)
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.indices && props.indices.nodes) {
            let parentNode = state.GetNode(props.indices.nodes, state.id, 4);
            if (parentNode) {
                state.startFrom = parentNode.id;
                state.title = parentNode.label;
                parentNode = state.GetNode(props.indices.nodes, state.id, 3);
                state.parentId = parentNode.id;
            }
        }
        return state;
    }

    GetNode(nodes, Id, Level) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.Level === Level && this.HasNode(node, Id)) {
                    result = node;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id, Level);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    HasNode(node, Id) {
        if (node) {
            return (Id === node.id) || this.hasSelectedNodeInChildren(node.nodes, Id);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, Id) {
        var result = false;
        nodes.forEach(node => {
            result = result || (Id === node.id) || this.hasSelectedNodeInChildren(node.nodes, Id);
        });
        return result;
    }

    render() {
        return (
            <>
                <Lessons match={this.props.match} type="BookLessons" title={this.state.title} startFrom={this.state.startFrom} parentId={this.state.parentId}></Lessons>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(BookLessons));
import React from "react";

const BounsTime = (props) => {

    const { TryColor, position, count, TryColorCustom } = props;

    return <div className={`text-${TryColor === "custom" ? "" : TryColor} position-absolute display-4 font-weight-bold game-font`} style={{
        zIndex: 99,
        opacity: 0,
        WebkitTextStroke: "3px #000",
        animation: `plus-up-text ease forwards 6s normal 0.5s`,
        top: `${position.y}px`,
        left: `${position.x}px`,
        color: TryColorCustom
    }}>
        {count} <i className="fad fa-alarm-plus"></i>
    </div>
}
export default BounsTime;
const ErrorReportReducers = (state = [], action) => {
    switch (action.type) {
        case "ERROR_REPORT_LOADING":
            return {
                ErrorReport: state.ErrorReport,
                toRemove: state.toRemove,
                isLoaded: false,
                lazyLoading: true
            };
        case "ERROR_REPORT_RESET":
            return {
                ErrorReport: [],
                toRemove: [],
                isLoaded: true,
                lazyLoading: true
            };
        case "ERROR_REPORT_LAZY_LOADING_STOP":
            return {
                ErrorReport: state.ErrorReport,
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: false
            };
        case "ERROR_REPORT_LAZY_LOADING":
            return {
                ErrorReport: state.ErrorReport ? [...state.ErrorReport, ...action.ErrorReport] : action.ErrorReport,
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: true
            };
        case "ERROR_REPORT_QUEUE_FOR_REMOVAL":
            return {
                ErrorReport: state.ErrorReport,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true,
                lazyLoading: true
            };
        case "ERROR_REPORT_UPDATE_IS_SOLVE":
            return {
                ErrorReport: UpdateErrorReportIsSolved(state.ErrorReport, action.id),
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: true
            };
        case "ERROR_REPORT_UPDATE_IS_ARCHIVED":
            return {
                ErrorReport: UpdateErrorReportIsArchived(state.ErrorReport, action.id),
                toRemove: state.toRemove,
                isLoaded: true,
                lazyLoading: true
            };
        case "ERROR_REPORT_CLEAN_NODES":
            return {
                ErrorReport: state.toRemove.some(v => v === action.id) ? state.ErrorReport.filter(v => v.Id !== action.id) : state.ErrorReport,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true,
                lazyLoading: true
            };
        case "ERROR_REPORT_UNDO":
            return {
                ErrorReport: state.ErrorReport,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true,
                lazyLoading: true
            };
        default:
            return state;
    }
}

function UpdateErrorReportIsArchived(ErrorReport, id) {
    let copy = [];
    ErrorReport.map(error => {
        if (error.Id === id) {
            error = {
                ...error,
                IsArchived: !error.IsArchived
            };
        }
        copy.push({
            ...error
        });
        return '';
    });
    return copy;
}

function UpdateErrorReportIsSolved(ErrorReport, id) {
    let copy = [];
    ErrorReport.map(error => {
        if (error.Id === id) {
            error = {
                ...error,
                IsSolved: true
            };
        }
        copy.push({
            ...error
        });
        return '';
    });
    return copy;
}

export default ErrorReportReducers;
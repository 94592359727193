import React from "react";
import { withTranslation } from 'react-i18next';

class ResultScreen extends React.Component {
    render() {
        const { t, CustomStyle, starCount, TimesUp, lesson, item } = this.props;

        const randomId = Math.floor(Math.random() * 1000);

        let styleLabelStyle = {};
        let styleGlobalStyle = {};
        let GlobalStyleCustom = "";

        let LabelStyleCustom = "";
        let alignLabelStyle = "x";
        let MarginBottomLabelStyle = 0;
        let MarginLabelStyle = 0;

        let ScoreStyleStrokeColor = "";
        let ScoreStyleStrokeWidth = "";
        let ScoreStyleCustom = "";
        let styleScoreStyle = {};

        let ButtonOneStyle = "";
        let ButtonSecondStyle = "";
        let ButtonNextStyle = "";

        let ButtonOneCursor = "";
        let buttonStyle = {};
        let buttonHoverStyle = {};
        let buttonStyleMarginBottom = "";

        let ButtonSecondCursor = "";
        let buttonSecondStyle = {};
        let buttonSecondHoverStyle = {};
        let buttonSecondStyleMarginBottom = "";
        let color = "";

        let ButtonNextCursor = "";
        let buttonNextStyle = {};
        let buttonNextHoverStyle = {};
        let buttonNextStyleMarginBottom = "";
        let funStyle = {};

        if (CustomStyle) {
            if (CustomStyle.GlobalStyle) {
                styleGlobalStyle = {
                    minHeight: `${CustomStyle.GlobalStyle.height}px`,
                    minWidth: `${CustomStyle.GlobalStyle.width}px`,
                    color: CustomStyle.GlobalStyle.FontColor,
                    maxWidth: '100%',
                    paddingLeft: `${CustomStyle.GlobalStyle.PaddingLeft}px`,
                    paddingTop: `${CustomStyle.GlobalStyle.PaddingTop}px`,
                    paddingRight: `${CustomStyle.GlobalStyle.PaddingRight}px`,
                    paddingBottom: `${CustomStyle.GlobalStyle.PaddingBottom}px`,
                    borderColor: CustomStyle.GlobalStyle.BorderColor !== 'transparent' ? CustomStyle.GlobalStyle.BorderColor : "",
                    borderStyle: `${CustomStyle.GlobalStyle.BorderStyle}`,
                    borderLeftWidth: `${CustomStyle.GlobalStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${CustomStyle.GlobalStyle.BorderWidthRight}px`,
                    borderTopWidth: `${CustomStyle.GlobalStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${CustomStyle.GlobalStyle.BorderWidthBottom}px`,
                    borderRadius: `${CustomStyle.GlobalStyle.BorderRadiusTop}${CustomStyle.GlobalStyle.BorderRadiusUnit} ${CustomStyle.GlobalStyle.BorderRadiusRight}${CustomStyle.GlobalStyle.BorderRadiusUnit} ${CustomStyle.GlobalStyle.BorderRadiusBottom}${CustomStyle.GlobalStyle.BorderRadiusUnit} ${CustomStyle.GlobalStyle.BorderRadiusLeft}${CustomStyle.GlobalStyle.BorderRadiusUnit}`,
                    height: `100%`,
                    opacity: `${CustomStyle.GlobalStyle.Opacity}%`,
                    backgroundColor: CustomStyle.GlobalStyle.backgroundColor !== 'transparent' ? CustomStyle.GlobalStyle.backgroundColor : "",
                    backgroundImage: CustomStyle.GlobalStyle.backgroundImage !== '' ? `url('${CustomStyle.GlobalStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${CustomStyle.GlobalStyle.BgPositionX}%`,
                    backgroundPositionY: `${CustomStyle.GlobalStyle.BgPositionY}%`,
                    backgroundSize: `${CustomStyle.GlobalStyle.BgSize}`,
                    backgroundRepeat: `${CustomStyle.GlobalStyle.BgRepeat}`,
                    backgroundAttachment: `${CustomStyle.GlobalStyle.BgAttachment}`,
                    boxShadow: `${CustomStyle.GlobalStyle.ShadowPositionX}px ${CustomStyle.GlobalStyle.ShadowPositionY}px ${CustomStyle.GlobalStyle.ShadowRadius}px 0px ${CustomStyle.GlobalStyle.shadowColor}`,
                };
                ButtonOneStyle = CustomStyle.GlobalStyle.ButtonOneStyle;
                ButtonSecondStyle = CustomStyle.GlobalStyle.ButtonSecondStyle;
                ButtonNextStyle = CustomStyle.GlobalStyle.ButtonNextStyle;
                GlobalStyleCustom = CustomStyle.GlobalStyle.Custom;

                funStyle = {
                    backgroundColor: CustomStyle.GlobalStyle.BorderColor !== 'transparent' ? CustomStyle.GlobalStyle.BorderColor : "",
                    boxShadow: `${CustomStyle.GlobalStyle.ShadowPositionX}px ${CustomStyle.GlobalStyle.ShadowPositionY}px ${CustomStyle.GlobalStyle.ShadowRadius}px 0px ${CustomStyle.GlobalStyle.shadowColor}`,
                    borderRadius: `0px 0px ${CustomStyle.GlobalStyle.BorderRadiusBottom}${CustomStyle.GlobalStyle.BorderRadiusUnit} ${CustomStyle.GlobalStyle.BorderRadiusLeft}${CustomStyle.GlobalStyle.BorderRadiusUnit}`,
                };

            }
            if (CustomStyle.LabelStyle) {
                styleLabelStyle = {
                    minHeight: `${CustomStyle.LabelStyle.height}px`,
                    width: `${CustomStyle.LabelStyle.width}%`,
                    maxWidth: '100%',
                    color: CustomStyle.LabelStyle.FontColor,
                    paddingLeft: `${CustomStyle.LabelStyle.PaddingLeft}px`,
                    paddingTop: `${CustomStyle.LabelStyle.PaddingTop}px`,
                    paddingRight: `${CustomStyle.LabelStyle.PaddingRight}px`,
                    paddingBottom: `${CustomStyle.LabelStyle.PaddingBottom}px`,
                    borderColor: CustomStyle.LabelStyle.BorderColor !== 'transparent' ? CustomStyle.LabelStyle.BorderColor : "",
                    borderStyle: `${CustomStyle.LabelStyle.BorderStyle}`,
                    borderLeftWidth: `${CustomStyle.LabelStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${CustomStyle.LabelStyle.BorderWidthRight}px`,
                    borderTopWidth: `${CustomStyle.LabelStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${CustomStyle.LabelStyle.BorderWidthBottom}px`,
                    borderRadius: `${CustomStyle.LabelStyle.BorderRadiusTop}${CustomStyle.LabelStyle.BorderRadiusUnit} ${CustomStyle.LabelStyle.BorderRadiusRight}${CustomStyle.LabelStyle.BorderRadiusUnit} ${CustomStyle.LabelStyle.BorderRadiusBottom}${CustomStyle.LabelStyle.BorderRadiusUnit} ${CustomStyle.LabelStyle.BorderRadiusLeft}${CustomStyle.LabelStyle.BorderRadiusUnit}`,
                    boxShadow: `${CustomStyle.LabelStyle.ShadowPositionX}px ${CustomStyle.LabelStyle.ShadowPositionY}px ${CustomStyle.LabelStyle.ShadowRadius}px 0px ${CustomStyle.LabelStyle.shadowColor}`,
                    marginBottom: `${CustomStyle.LabelStyle.Margin}px`,
                    zIndex: 9,
                    height: `100%`,
                    opacity: `${CustomStyle.LabelStyle.Opacity}%`,
                    backgroundColor: CustomStyle.LabelStyle.backgroundColor !== 'transparent' ? CustomStyle.LabelStyle.backgroundColor : "",
                    backgroundImage: CustomStyle.LabelStyle.backgroundImage !== '' ? `url('${CustomStyle.LabelStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${CustomStyle.LabelStyle.BgPositionX}%`,
                    backgroundPositionY: `${CustomStyle.LabelStyle.BgPositionY}%`,
                    backgroundSize: `${CustomStyle.LabelStyle.BgSize}`,
                    backgroundRepeat: `${CustomStyle.LabelStyle.BgRepeat}`,
                    backgroundAttachment: `${CustomStyle.LabelStyle.BgAttachment}`
                };

                if (CustomStyle.LabelStyle.AnswersAlign) {
                    if (CustomStyle.LabelStyle.AnswersAlign === 'left') {
                        alignLabelStyle = "l";
                    } else if (CustomStyle.LabelStyle.AnswersAlign === 'right') {
                        alignLabelStyle = "r";
                    }
                }
                MarginBottomLabelStyle = CustomStyle.LabelStyle.MarginBottom;
                MarginLabelStyle = CustomStyle.LabelStyle.Margin;
                LabelStyleCustom = CustomStyle.LabelStyle.Custom;
            }
            if (CustomStyle.ScoreStyle) {
                color = CustomStyle.ScoreStyle.FontColor;
                styleScoreStyle = {
                    minHeight: `${CustomStyle.ScoreStyle.height}px`,
                    width: `${CustomStyle.ScoreStyle.width}%`,
                    maxWidth: '100%',
                    color: CustomStyle.ScoreStyle.FontColor,
                    paddingLeft: `${CustomStyle.ScoreStyle.PaddingLeft}px`,
                    paddingTop: `${CustomStyle.ScoreStyle.PaddingTop}px`,
                    paddingRight: `${CustomStyle.ScoreStyle.PaddingRight}px`,
                    paddingBottom: `${CustomStyle.ScoreStyle.PaddingBottom}px`,
                    borderColor: CustomStyle.ScoreStyle.BorderColor !== 'transparent' ? CustomStyle.ScoreStyle.BorderColor : "",
                    borderStyle: `${CustomStyle.ScoreStyle.BorderStyle}`,
                    borderLeftWidth: `${CustomStyle.ScoreStyle.BorderWidthLeft}px`,
                    borderRightWidth: `${CustomStyle.ScoreStyle.BorderWidthRight}px`,
                    borderTopWidth: `${CustomStyle.ScoreStyle.BorderWidthTop}px`,
                    borderBottomWidth: `${CustomStyle.ScoreStyle.BorderWidthBottom}px`,
                    borderRadius: `${CustomStyle.ScoreStyle.BorderRadiusTop}${CustomStyle.ScoreStyle.BorderRadiusUnit} ${CustomStyle.ScoreStyle.BorderRadiusRight}${CustomStyle.ScoreStyle.BorderRadiusUnit} ${CustomStyle.ScoreStyle.BorderRadiusBottom}${CustomStyle.ScoreStyle.BorderRadiusUnit} ${CustomStyle.ScoreStyle.BorderRadiusLeft}${CustomStyle.ScoreStyle.BorderRadiusUnit}`,
                    boxShadow: `${CustomStyle.ScoreStyle.ShadowPositionX}px ${CustomStyle.ScoreStyle.ShadowPositionY}px ${CustomStyle.ScoreStyle.ShadowRadius}px 0px ${CustomStyle.ScoreStyle.shadowColor}`,
                    zIndex: 9,
                    opacity: `${CustomStyle.ScoreStyle.Opacity}%`,
                    backgroundColor: CustomStyle.ScoreStyle.backgroundColor !== 'transparent' ? CustomStyle.ScoreStyle.backgroundColor : "",
                    backgroundImage: CustomStyle.ScoreStyle.backgroundImage !== '' ? `url('${CustomStyle.ScoreStyle.backgroundImage}')` : "",
                    backgroundPositionX: `${CustomStyle.ScoreStyle.BgPositionX}%`,
                    backgroundPositionY: `${CustomStyle.ScoreStyle.BgPositionY}%`,
                    backgroundSize: `${CustomStyle.ScoreStyle.BgSize}`,
                    backgroundRepeat: `${CustomStyle.ScoreStyle.BgRepeat}`,
                    backgroundAttachment: `${CustomStyle.ScoreStyle.BgAttachment}`,
                    marginLeft: `-${CustomStyle.ScoreStyle.PaddingLeft}px`,
                    marginTop: `-${CustomStyle.ScoreStyle.PaddingTop}px`,
                    marginRight: `-${CustomStyle.ScoreStyle.PaddingRight}px`,
                    marginBottom: `-${CustomStyle.ScoreStyle.PaddingBottom}px`
                };
                ScoreStyleCustom = CustomStyle.ScoreStyle.Custom;
                ScoreStyleStrokeColor = CustomStyle.ScoreStyle.StrokeColor;
                ScoreStyleStrokeWidth = CustomStyle.ScoreStyle.StrokeWidth;
            }
            if (CustomStyle.ButtonOneStyle) {
                ButtonOneCursor = "";
                if (CustomStyle.ButtonOneStyle.Cursor !== 'custom' && CustomStyle.ButtonOneStyle.Cursor !== 'none' && CustomStyle.ButtonOneStyle.Cursor) {
                    ButtonOneCursor = process.env.PUBLIC_URL + CustomStyle.ButtonOneStyle.Cursor;
                } else if (CustomStyle.ButtonOneStyle.Cursor === 'custom' && CustomStyle.ButtonOneStyle.CursorCustom) {
                    ButtonOneCursor = CustomStyle.ButtonOneStyle.CursorCustom;
                }
                buttonStyleMarginBottom = CustomStyle.ButtonOneStyle.MarginBottom;
                buttonStyle = `max-width: 100%;
                                user-select: none;
                                transition-property: all;
                                transition-duration: 0.35s;
                                transition-timing-function: ease-in-out;
                                width: ${CustomStyle.ButtonOneStyle.width}px;
                                height: ${CustomStyle.ButtonOneStyle.height}px;
                                cursor: url('${ButtonOneCursor}') 20 20, auto;
                                color: ${CustomStyle.ButtonOneStyle.FontColor};
                                font-size: ${CustomStyle.ButtonOneStyle.FontSize}px;
                                -webkit-text-stroke-width: ${CustomStyle.ButtonOneStyle.StrokeWidth}px;
                                -webkit-text-stroke-color: ${CustomStyle.ButtonOneStyle.StrokeColor};
                                padding-left: ${CustomStyle.ButtonOneStyle.PaddingLeft}px;
                                padding-top: ${CustomStyle.ButtonOneStyle.PaddingTop}px;
                                padding-right: ${CustomStyle.ButtonOneStyle.PaddingRight}px;
                                padding-bottom: ${CustomStyle.ButtonOneStyle.PaddingBottom}px;
                                opacity: ${CustomStyle.ButtonOneStyle.Opacity}%;
                                background-color: ${CustomStyle.ButtonOneStyle.backgroundColor !== 'transparent' ? CustomStyle.ButtonOneStyle.backgroundColor : ""};
                                background-image: ${CustomStyle.ButtonOneStyle.backgroundImage !== '' ? `url('${CustomStyle.ButtonOneStyle.backgroundImage}')` : ""};
                                background-position-x: ${CustomStyle.ButtonOneStyle.BgPositionX}%;
                                background-position-y: ${CustomStyle.ButtonOneStyle.BgPositionY}%;
                                background-size: ${CustomStyle.ButtonOneStyle.BgSize};
                                background-repeat: ${CustomStyle.ButtonOneStyle.BgRepeat};
                                background-attachment: ${CustomStyle.ButtonOneStyle.BgAttachment};
                                border-color: ${CustomStyle.ButtonOneStyle.BorderColor !== 'transparent' ? CustomStyle.ButtonOneStyle.BorderColor : ""};
                                border-style: ${CustomStyle.ButtonOneStyle.BorderStyle};
                                border-left-width: ${CustomStyle.ButtonOneStyle.BorderWidthLeft}px;
                                border-right-width: ${CustomStyle.ButtonOneStyle.BorderWidthRight}px;
                                border-top-width: ${CustomStyle.ButtonOneStyle.BorderWidthTop}px;
                                border-bottom-width: ${CustomStyle.ButtonOneStyle.BorderWidthBottom}px;
                                border-radius: ${CustomStyle.ButtonOneStyle.BorderRadiusTop}${CustomStyle.ButtonOneStyle.BorderRadiusUnit} ${CustomStyle.ButtonOneStyle.BorderRadiusRight}${CustomStyle.ButtonOneStyle.BorderRadiusUnit} ${CustomStyle.ButtonOneStyle.BorderRadiusBottom}${CustomStyle.ButtonOneStyle.BorderRadiusUnit} ${CustomStyle.ButtonOneStyle.BorderRadiusLeft}${CustomStyle.ButtonOneStyle.BorderRadiusUnit};
                                box-shadow: ${CustomStyle.ButtonOneStyle.ShadowPositionX}px ${CustomStyle.ButtonOneStyle.ShadowPositionY}px ${CustomStyle.ButtonOneStyle.ShadowRadius}px 0px ${CustomStyle.ButtonOneStyle.shadowColor};
                                ${CustomStyle.ButtonOneStyle.Custom} `;
            }
            if (CustomStyle.ButtonOneHoverStyle) {
                buttonHoverStyle = `max-width: 100%;
                                    user-select: none;
                                    -webkit-text-stroke-width: ${CustomStyle.ButtonOneHoverStyle.StrokeWidth};
                                    -webkit-text-stroke-color: ${CustomStyle.ButtonOneHoverStyle.StrokeColor};
                                    color: ${CustomStyle.ButtonOneHoverStyle.FontColor};
                                    padding-left: ${CustomStyle.ButtonOneHoverStyle.PaddingLeft}px;
                                    padding-top: ${CustomStyle.ButtonOneHoverStyle.PaddingTop}px;
                                    padding-right: ${CustomStyle.ButtonOneHoverStyle.PaddingRight}px;
                                    padding-bottom: ${CustomStyle.ButtonOneHoverStyle.PaddingBottom}px;
                                    opacity: ${CustomStyle.ButtonOneHoverStyle.Opacity}%;
                                    background-color: ${CustomStyle.ButtonOneHoverStyle.backgroundColor !== 'transparent' ? CustomStyle.ButtonOneHoverStyle.backgroundColor : ""};
                                    background-image: ${CustomStyle.ButtonOneHoverStyle.backgroundImage !== '' ? `url('${CustomStyle.ButtonOneHoverStyle.backgroundImage}')` : ""};
                                    background-position-x: ${CustomStyle.ButtonOneHoverStyle.BgPositionX}%;
                                    background-position-y: ${CustomStyle.ButtonOneHoverStyle.BgPositionY}%;
                                    background-size: ${CustomStyle.ButtonOneHoverStyle.BgSize};
                                    background-repeat: ${CustomStyle.ButtonOneHoverStyle.BgRepeat};
                                    background-attachment: ${CustomStyle.ButtonOneHoverStyle.BgAttachment};
                                    border-color: ${CustomStyle.ButtonOneHoverStyle.BorderColor !== 'transparent' ? CustomStyle.ButtonOneHoverStyle.BorderColor : ""};
                                    border-style: ${CustomStyle.ButtonOneHoverStyle.BorderStyle};
                                    border-left-width: ${CustomStyle.ButtonOneHoverStyle.BorderWidthLeft}px;
                                    border-right-width: ${CustomStyle.ButtonOneHoverStyle.BorderWidthRight}px;
                                    border-top-width: ${CustomStyle.ButtonOneHoverStyle.BorderWidthTop}px;
                                    border-bottom-width: ${CustomStyle.ButtonOneHoverStyle.BorderWidthBottom}px;
                                    border-radius: ${CustomStyle.ButtonOneHoverStyle.BorderRadiusTop}${CustomStyle.ButtonOneHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonOneHoverStyle.BorderRadiusRight}${CustomStyle.ButtonOneHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonOneHoverStyle.BorderRadiusBottom}${CustomStyle.ButtonOneHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonOneHoverStyle.BorderRadiusLeft}${CustomStyle.ButtonOneHoverStyle.BorderRadiusUnit};
                                    box-shadow: ${CustomStyle.ButtonOneHoverStyle.ShadowPositionX}px ${CustomStyle.ButtonOneHoverStyle.ShadowPositionY}px ${CustomStyle.ButtonOneHoverStyle.ShadowRadius}px 0px ${CustomStyle.ButtonOneHoverStyle.shadowColor};
                                    ${CustomStyle.ButtonOneHoverStyle.Custom} `;
            }
            if (CustomStyle.ButtonSecondStyle) {
                ButtonSecondCursor = "";
                if (CustomStyle.ButtonSecondStyle.Cursor !== 'custom' && CustomStyle.ButtonSecondStyle.Cursor !== 'none' && CustomStyle.ButtonSecondStyle.Cursor) {
                    ButtonSecondCursor = process.env.PUBLIC_URL + CustomStyle.ButtonSecondStyle.Cursor;
                } else if (CustomStyle.ButtonSecondStyle.Cursor === 'custom' && CustomStyle.ButtonSecondStyle.CursorCustom) {
                    ButtonSecondCursor = CustomStyle.ButtonSecondStyle.CursorCustom;
                }
                buttonSecondStyleMarginBottom = CustomStyle.ButtonSecondStyle.MarginBottom;
                buttonSecondStyle = `max-width: 100%;
                                user-select: none;
                                transition-property: all;
                                transition-duration: 0.35s;
                                transition-timing-function: ease-in-out;
                                width: ${CustomStyle.ButtonSecondStyle.width}px;
                                height: ${CustomStyle.ButtonSecondStyle.height}px;
                                cursor: url('${ButtonSecondCursor}') 20 20, auto;
                                color: ${CustomStyle.ButtonSecondStyle.FontColor};
                                font-size: ${CustomStyle.ButtonSecondStyle.FontSize}px;
                                -webkit-text-stroke-width: ${CustomStyle.ButtonSecondStyle.StrokeWidth}px;
                                -webkit-text-stroke-color: ${CustomStyle.ButtonSecondStyle.StrokeColor};
                                padding-left: ${CustomStyle.ButtonSecondStyle.PaddingLeft}px;
                                padding-top: ${CustomStyle.ButtonSecondStyle.PaddingTop}px;
                                padding-right: ${CustomStyle.ButtonSecondStyle.PaddingRight}px;
                                padding-bottom: ${CustomStyle.ButtonSecondStyle.PaddingBottom}px;
                                opacity: ${CustomStyle.ButtonSecondStyle.Opacity}%;
                                background-color: ${CustomStyle.ButtonSecondStyle.backgroundColor !== 'transparent' ? CustomStyle.ButtonSecondStyle.backgroundColor : ""};
                                background-image: ${CustomStyle.ButtonSecondStyle.backgroundImage !== '' ? `url('${CustomStyle.ButtonSecondStyle.backgroundImage}')` : ""};
                                background-position-x: ${CustomStyle.ButtonSecondStyle.BgPositionX}%;
                                background-position-y: ${CustomStyle.ButtonSecondStyle.BgPositionY}%;
                                background-size: ${CustomStyle.ButtonSecondStyle.BgSize};
                                background-repeat: ${CustomStyle.ButtonSecondStyle.BgRepeat};
                                background-attachment: ${CustomStyle.ButtonSecondStyle.BgAttachment};
                                border-color: ${CustomStyle.ButtonSecondStyle.BorderColor !== 'transparent' ? CustomStyle.ButtonSecondStyle.BorderColor : ""};
                                border-style: ${CustomStyle.ButtonSecondStyle.BorderStyle};
                                border-left-width: ${CustomStyle.ButtonSecondStyle.BorderWidthLeft}px;
                                border-right-width: ${CustomStyle.ButtonSecondStyle.BorderWidthRight}px;
                                border-top-width: ${CustomStyle.ButtonSecondStyle.BorderWidthTop}px;
                                border-bottom-width: ${CustomStyle.ButtonSecondStyle.BorderWidthBottom}px;
                                border-radius: ${CustomStyle.ButtonSecondStyle.BorderRadiusTop}${CustomStyle.ButtonSecondStyle.BorderRadiusUnit} ${CustomStyle.ButtonSecondStyle.BorderRadiusRight}${CustomStyle.ButtonSecondStyle.BorderRadiusUnit} ${CustomStyle.ButtonSecondStyle.BorderRadiusBottom}${CustomStyle.ButtonSecondStyle.BorderRadiusUnit} ${CustomStyle.ButtonSecondStyle.BorderRadiusLeft}${CustomStyle.ButtonSecondStyle.BorderRadiusUnit};
                                box-shadow: ${CustomStyle.ButtonSecondStyle.ShadowPositionX}px ${CustomStyle.ButtonSecondStyle.ShadowPositionY}px ${CustomStyle.ButtonSecondStyle.ShadowRadius}px 0px ${CustomStyle.ButtonSecondStyle.shadowColor};
                                ${CustomStyle.ButtonSecondStyle.Custom} `;
            }
            if (CustomStyle.ButtonSecondHoverStyle) {
                buttonSecondHoverStyle = `max-width: 100%;
                                    user-select: none;
                                    -webkit-text-stroke-width: ${CustomStyle.ButtonSecondHoverStyle.StrokeWidth};
                                    -webkit-text-stroke-color: ${CustomStyle.ButtonSecondHoverStyle.StrokeColor};
                                    color: ${CustomStyle.ButtonSecondHoverStyle.FontColor};
                                    padding-left: ${CustomStyle.ButtonSecondHoverStyle.PaddingLeft}px;
                                    padding-top: ${CustomStyle.ButtonSecondHoverStyle.PaddingTop}px;
                                    padding-right: ${CustomStyle.ButtonSecondHoverStyle.PaddingRight}px;
                                    padding-bottom: ${CustomStyle.ButtonSecondHoverStyle.PaddingBottom}px;
                                    opacity: ${CustomStyle.ButtonSecondHoverStyle.Opacity}%;
                                    background-color: ${CustomStyle.ButtonSecondHoverStyle.backgroundColor !== 'transparent' ? CustomStyle.ButtonSecondHoverStyle.backgroundColor : ""};
                                    background-image: ${CustomStyle.ButtonSecondHoverStyle.backgroundImage !== '' ? `url('${CustomStyle.ButtonSecondHoverStyle.backgroundImage}')` : ""};
                                    background-position-x: ${CustomStyle.ButtonSecondHoverStyle.BgPositionX}%;
                                    background-position-y: ${CustomStyle.ButtonSecondHoverStyle.BgPositionY}%;
                                    background-size: ${CustomStyle.ButtonSecondHoverStyle.BgSize};
                                    background-repeat: ${CustomStyle.ButtonSecondHoverStyle.BgRepeat};
                                    background-attachment: ${CustomStyle.ButtonSecondHoverStyle.BgAttachment};
                                    border-color: ${CustomStyle.ButtonSecondHoverStyle.BorderColor !== 'transparent' ? CustomStyle.ButtonSecondHoverStyle.BorderColor : ""};
                                    border-style: ${CustomStyle.ButtonSecondHoverStyle.BorderStyle};
                                    border-left-width: ${CustomStyle.ButtonSecondHoverStyle.BorderWidthLeft}px;
                                    border-right-width: ${CustomStyle.ButtonSecondHoverStyle.BorderWidthRight}px;
                                    border-top-width: ${CustomStyle.ButtonSecondHoverStyle.BorderWidthTop}px;
                                    border-bottom-width: ${CustomStyle.ButtonSecondHoverStyle.BorderWidthBottom}px;
                                    border-radius: ${CustomStyle.ButtonSecondHoverStyle.BorderRadiusTop}${CustomStyle.ButtonSecondHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonSecondHoverStyle.BorderRadiusRight}${CustomStyle.ButtonSecondHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonSecondHoverStyle.BorderRadiusBottom}${CustomStyle.ButtonSecondHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonSecondHoverStyle.BorderRadiusLeft}${CustomStyle.ButtonSecondHoverStyle.BorderRadiusUnit};
                                    box-shadow: ${CustomStyle.ButtonSecondHoverStyle.ShadowPositionX}px ${CustomStyle.ButtonSecondHoverStyle.ShadowPositionY}px ${CustomStyle.ButtonSecondHoverStyle.ShadowRadius}px 0px ${CustomStyle.ButtonSecondHoverStyle.shadowColor};
                                    ${CustomStyle.ButtonSecondHoverStyle.Custom} `;
            }
            if (CustomStyle.ButtonNextStyle) {
                ButtonNextCursor = "";
                if (CustomStyle.ButtonNextStyle.Cursor !== 'custom' && CustomStyle.ButtonNextStyle.Cursor !== 'none' && CustomStyle.ButtonNextStyle.Cursor) {
                    ButtonNextCursor = process.env.PUBLIC_URL + CustomStyle.ButtonNextStyle.Cursor;
                } else if (CustomStyle.ButtonNextStyle.Cursor === 'custom' && CustomStyle.ButtonNextStyle.CursorCustom) {
                    ButtonNextCursor = CustomStyle.ButtonNextStyle.CursorCustom;
                }
                buttonNextStyleMarginBottom = CustomStyle.ButtonNextStyle.MarginBottom;
                buttonNextStyle = `max-width: 100%;
                                user-select: none;
                                transition-property: all;
                                transition-duration: 0.35s;
                                transition-timing-function: ease-in-out;
                                width: ${CustomStyle.ButtonNextStyle.width}px;
                                height: ${CustomStyle.ButtonNextStyle.height}px;
                                cursor: url('${ButtonNextCursor}') 20 20, auto;
                                color: ${CustomStyle.ButtonNextStyle.FontColor};
                                font-size: ${CustomStyle.ButtonNextStyle.FontSize}px;
                                -webkit-text-stroke-width: ${CustomStyle.ButtonNextStyle.StrokeWidth}px;
                                -webkit-text-stroke-color: ${CustomStyle.ButtonNextStyle.StrokeColor};
                                padding-left: ${CustomStyle.ButtonNextStyle.PaddingLeft}px;
                                padding-top: ${CustomStyle.ButtonNextStyle.PaddingTop}px;
                                padding-right: ${CustomStyle.ButtonNextStyle.PaddingRight}px;
                                padding-bottom: ${CustomStyle.ButtonNextStyle.PaddingBottom}px;
                                opacity: ${CustomStyle.ButtonNextStyle.Opacity}%;
                                background-color: ${CustomStyle.ButtonNextStyle.backgroundColor !== 'transparent' ? CustomStyle.ButtonNextStyle.backgroundColor : ""};
                                background-image: ${CustomStyle.ButtonNextStyle.backgroundImage !== '' ? `url('${CustomStyle.ButtonNextStyle.backgroundImage}')` : ""};
                                background-position-x: ${CustomStyle.ButtonNextStyle.BgPositionX}%;
                                background-position-y: ${CustomStyle.ButtonNextStyle.BgPositionY}%;
                                background-size: ${CustomStyle.ButtonNextStyle.BgSize};
                                background-repeat: ${CustomStyle.ButtonNextStyle.BgRepeat};
                                background-attachment: ${CustomStyle.ButtonNextStyle.BgAttachment};
                                border-color: ${CustomStyle.ButtonNextStyle.BorderColor !== 'transparent' ? CustomStyle.ButtonNextStyle.BorderColor : ""};
                                border-style: ${CustomStyle.ButtonNextStyle.BorderStyle};
                                border-left-width: ${CustomStyle.ButtonNextStyle.BorderWidthLeft}px;
                                border-right-width: ${CustomStyle.ButtonNextStyle.BorderWidthRight}px;
                                border-top-width: ${CustomStyle.ButtonNextStyle.BorderWidthTop}px;
                                border-bottom-width: ${CustomStyle.ButtonNextStyle.BorderWidthBottom}px;
                                border-radius: ${CustomStyle.ButtonNextStyle.BorderRadiusTop}${CustomStyle.ButtonNextStyle.BorderRadiusUnit} ${CustomStyle.ButtonNextStyle.BorderRadiusRight}${CustomStyle.ButtonNextStyle.BorderRadiusUnit} ${CustomStyle.ButtonNextStyle.BorderRadiusBottom}${CustomStyle.ButtonNextStyle.BorderRadiusUnit} ${CustomStyle.ButtonNextStyle.BorderRadiusLeft}${CustomStyle.ButtonNextStyle.BorderRadiusUnit};
                                box-shadow: ${CustomStyle.ButtonNextStyle.ShadowPositionX}px ${CustomStyle.ButtonNextStyle.ShadowPositionY}px ${CustomStyle.ButtonNextStyle.ShadowRadius}px 0px ${CustomStyle.ButtonNextStyle.shadowColor};
                                ${CustomStyle.ButtonNextStyle.Custom} `;
            }
            if (CustomStyle.ButtonNextHoverStyle) {
                buttonNextHoverStyle = `max-width: 100%;
                                    user-select: none;
                                    -webkit-text-stroke-width: ${CustomStyle.ButtonNextHoverStyle.StrokeWidth};
                                    -webkit-text-stroke-color: ${CustomStyle.ButtonNextHoverStyle.StrokeColor};
                                    color: ${CustomStyle.ButtonNextHoverStyle.FontColor};
                                    padding-left: ${CustomStyle.ButtonNextHoverStyle.PaddingLeft}px;
                                    padding-top: ${CustomStyle.ButtonNextHoverStyle.PaddingTop}px;
                                    padding-right: ${CustomStyle.ButtonNextHoverStyle.PaddingRight}px;
                                    padding-bottom: ${CustomStyle.ButtonNextHoverStyle.PaddingBottom}px;
                                    opacity: ${CustomStyle.ButtonNextHoverStyle.Opacity}%;
                                    background-color: ${CustomStyle.ButtonNextHoverStyle.backgroundColor !== 'transparent' ? CustomStyle.ButtonNextHoverStyle.backgroundColor : ""};
                                    background-image: ${CustomStyle.ButtonNextHoverStyle.backgroundImage !== '' ? `url('${CustomStyle.ButtonNextHoverStyle.backgroundImage}')` : ""};
                                    background-position-x: ${CustomStyle.ButtonNextHoverStyle.BgPositionX}%;
                                    background-position-y: ${CustomStyle.ButtonNextHoverStyle.BgPositionY}%;
                                    background-size: ${CustomStyle.ButtonNextHoverStyle.BgSize};
                                    background-repeat: ${CustomStyle.ButtonNextHoverStyle.BgRepeat};
                                    background-attachment: ${CustomStyle.ButtonNextHoverStyle.BgAttachment};
                                    border-color: ${CustomStyle.ButtonNextHoverStyle.BorderColor !== 'transparent' ? CustomStyle.ButtonNextHoverStyle.BorderColor : ""};
                                    border-style: ${CustomStyle.ButtonNextHoverStyle.BorderStyle};
                                    border-left-width: ${CustomStyle.ButtonNextHoverStyle.BorderWidthLeft}px;
                                    border-right-width: ${CustomStyle.ButtonNextHoverStyle.BorderWidthRight}px;
                                    border-top-width: ${CustomStyle.ButtonNextHoverStyle.BorderWidthTop}px;
                                    border-bottom-width: ${CustomStyle.ButtonNextHoverStyle.BorderWidthBottom}px;
                                    border-radius: ${CustomStyle.ButtonNextHoverStyle.BorderRadiusTop}${CustomStyle.ButtonNextHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonNextHoverStyle.BorderRadiusRight}${CustomStyle.ButtonNextHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonNextHoverStyle.BorderRadiusBottom}${CustomStyle.ButtonNextHoverStyle.BorderRadiusUnit} ${CustomStyle.ButtonNextHoverStyle.BorderRadiusLeft}${CustomStyle.ButtonNextHoverStyle.BorderRadiusUnit};
                                    box-shadow: ${CustomStyle.ButtonNextHoverStyle.ShadowPositionX}px ${CustomStyle.ButtonNextHoverStyle.ShadowPositionY}px ${CustomStyle.ButtonNextHoverStyle.ShadowRadius}px 0px ${CustomStyle.ButtonNextHoverStyle.shadowColor};
                                    ${CustomStyle.ButtonNextHoverStyle.Custom} `;
            }
        }

        let ButtonOneStyleTitle = t('Pages.Games.Replay');
        if (this.props.type === "stop") {
            ButtonOneStyleTitle = t('Pages.Games.ContinueGame');
        }
        if (this.props.type === "level") {
            ButtonOneStyleTitle = t('Pages.Games.Start');
        }

        if (this.props.isPaused) {
            ButtonOneStyleTitle = t('Pages.Games.ContinueGame');
        }

        let GameLevel = "";
        if (this.props.LevelOfGame) {
            if (this.props.LevelOfGame.Id === 1) {
                GameLevel = "text-success";
            } else if (this.props.LevelOfGame.Id === 2) {
                GameLevel = "text-warning";
            } else if (this.props.LevelOfGame.Id === 3) {
                GameLevel = "text-danger";
            }
        }
        let showNextButtonAfterMultiErrors = false;
        let TryNumber = 0;
        let IsPassed = false;
        if (lesson && lesson.scores && lesson.scores.Levels) {
            const level = lesson.scores.Levels.filter(p => item.QuestionLessonId === p.LessonQuestionId || item.Id === p.QuestionId)[0];
            if (level) {
                TryNumber = level.TryNumber;
                IsPassed = level.IsPassed;
                if (item && item.Position && level.TryNumber >= item.Mark) {
                    showNextButtonAfterMultiErrors = true;
                }
            }
        }
        return (
            <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center  " >
                <div style={{
                    maxWidth: '90%'
                }} >
                    <div style={{
                        zIndex: '15'
                    }}>
                        <div className="row d-flex flex-wrap justify-content-center game-font" style={{
                            marginBottom: `${MarginLabelStyle}px`
                        }}>
                            <div className={`m${alignLabelStyle}-auto position-relative overflow-hidden d-flex align-items-center justify-content-center`} style={styleLabelStyle}>
                                <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyleTitle_${randomId}{${LabelStyleCustom} }` }}></style>
                                <div className="h3" style={{
                                    marginBottom: `${MarginBottomLabelStyle}px`
                                }}>
                                    {this.props.type === "success" ? t('Pages.Games.WinGame') : ""}
                                    {this.props.type === "error" ? t('Pages.Games.GameOver') : ""}
                                    {this.props.type === "stop" || this.props.type === "rotate" ? t('Pages.Games.PauseGame') : ""}
                                    {this.props.type === "level" ? <>{t('Pages.Games.GameLevel')} {this.props.level}</> : ""}
                                </div>
                                <div className="w-100 position-absolute h-100" style={{
                                    zIndex: -1
                                }} id={`SelectDesignerPreviewLabelStyleTitle_${randomId}`}></div>
                            </div>
                        </div>
                        <div id={`SelectDesignerPreviewLabelStyleBody_${randomId}`} className="  mx-auto position-relative d-flex justify-content-center align-items-center flex-column" style={styleGlobalStyle}>

                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyleBody_${randomId}{${GlobalStyleCustom} }` }}></style>
                            {
                                this.props.type === "stop" || this.props.type === "rotate" ?
                                    this.props.type === "stop" ?
                                        <div className="h3 my-4 game-font" >
                                            {t('Pages.Games.Pause')}
                                        </div>
                                        :
                                        <div className="h3 my-4 game-font" >
                                            {t('Pages.Games.Rotate')}
                                        </div>
                                    :
                                    this.props.type === "level" ?
                                        <>
                                            <div className=" position-relative">
                                                {
                                                    item && item.Position ?
                                                        <div className="ribbon-corner ribbon-fold" data-position="right top" title={t('Pages.Games.forms.LessonInfo.IsForFun')} style={{
                                                            right: '1px',
                                                            top: '1px'
                                                        }}>
                                                            <i className="fa-regular fa-gamepad-modern"></i>
                                                        </div>
                                                        : ""
                                                }
                                                <div id={`SelectDesignerPreviewLabelStyleScore_${randomId}`} className="h2 text-center m-0 mb-3" style={styleScoreStyle}>

                                                    <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyleScore_${randomId}{${ScoreStyleCustom} }` }}></style>
                                                    <div className="h3 my-4" dangerouslySetInnerHTML={{
                                                        __html: this.props.text
                                                    }}></div>
                                                </div>
                                            </div>
                                            {
                                                GameLevel ?
                                                    <div className="h5 mb-3 game-font" style={{
                                                        color: color
                                                    }}>
                                                        <i className={`icon fas fa-water-rise ${GameLevel}`}></i>
                                                        {t('Pages.Games.LevelOfGame')} :
                                                        <span className={` mx-1 ${GameLevel}`}>
                                                            {this.props.LevelOfGame.Name}
                                                        </span>
                                                    </div>
                                                    : ""
                                            }
                                            {
                                                this.props.Time ?
                                                    <div className="h5 mb-3 game-font" style={{
                                                        color: color
                                                    }}>

                                                        <i className={`icon fas fa-stopwatch ${GameLevel}`}></i>
                                                        {t('Pages.Games.Time')} :
                                                        <span className="">
                                                            {Math.floor(this.props.Time / 60) > 0 ?
                                                                <>
                                                                    <span className={`${GameLevel} mx-1`}>
                                                                        {Math.floor(this.props.Time / 60)}
                                                                    </span>
                                                                    {
                                                                        Math.floor(this.props.Time / 60) > 2 && Math.floor(this.props.Time / 60) <= 10 ?
                                                                            t('Pages.Games.Minutes')
                                                                            :
                                                                            t('Pages.Games.Minute')
                                                                    }
                                                                </> : ""
                                                            }
                                                            {
                                                                Math.floor(this.props.Time / 60) > 0 && this.props.Time % 60 > 0 ?
                                                                    <span className={`mr-1 text-dark`}> {t('Pages.Games.And')}</span> : ""
                                                            }
                                                            {this.props.Time % 60 > 0 ?
                                                                <>
                                                                    <span className={`${GameLevel} mx-1`}>

                                                                        {this.props.Time % 60}
                                                                    </span>
                                                                    {
                                                                        this.props.Time % 60 > 2 && this.props.Time % 60 <= 10 ?
                                                                            t('Pages.Games.Seconds')
                                                                            :
                                                                            t('Pages.Games.Second')
                                                                    }
                                                                </>
                                                                : ""}
                                                        </span>
                                                    </div>
                                                    : ""
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                TimesUp === true ?
                                                    <div className="h6 mt-3 game-font" >
                                                        {t('Pages.Games.TimesUp')}
                                                    </div>
                                                    :
                                                    this.props.type === "error" ?
                                                        <div className="h6 mt-3 game-font" >
                                                            {t('Pages.Games.Failed')}
                                                        </div>
                                                        : ""
                                            }
                                            <div className="w-100 d-flex justify-content-around align-items-end">
                                                <div className="display-3">
                                                    <i className={`right-star fas fa-star ${this.props.type !== "error" && starCount >= 1 ? 'star-color' : 'empty-star'}`}></i>
                                                </div>
                                                <div className="display-2 pb-3">
                                                    <i className={`fas fa-star  ${this.props.type !== "error" && starCount >= 2 ? 'star-color' : 'empty-star'}`}></i>
                                                </div>
                                                <div className="display-3">
                                                    <i className={`left-star fas fa-star ${this.props.type !== "error" && starCount >= 3 ? 'star-color' : 'empty-star'}`}></i>
                                                </div>
                                            </div>
                                            {
                                                this.props.type !== "error" ?
                                                    <>
                                                        <div className="h3 mt-3  game-font" >
                                                            {t('Pages.Games.YourPoint')}
                                                        </div>
                                                        <div title={t('Pages.Games.YourPoint')} id={`SelectDesignerPreviewLabelStyleScore_${randomId}`} className="h2 text-center m-0 mb-3" style={styleScoreStyle}>
                                                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreviewLabelStyleScore_${randomId}{${ScoreStyleCustom} }` }}></style>
                                                            <div className={` font-weight-bold h-100 d-flex align-items-center justify-content-center`} style={{
                                                                WebkitTextStrokeColor: ScoreStyleStrokeColor,
                                                                WebkitTextStrokeWidth: `${ScoreStyleStrokeWidth}px`
                                                            }}>
                                                                {this.props.Score}
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""
                                            }
                                        </>
                            }
                            {
                                this.props.type === "rotate" || this.props.hideButton === true ? "" :
                                    <div className="d-flex align-items-center w-100 flex-wrap game-font" style={{
                                        justifyContent: 'space-evenly'
                                    }}>
                                        {
                                            this.props.GameType === "hard" ? "" :
                                                <div title={ButtonOneStyleTitle} className={` text-center  m-1 ${ButtonOneStyle === "custom" ? "custom-game-button-one" : `custom-button  ${ButtonOneStyle}-button`}  `} onClick={() => this.props.reStartGame()}>
                                                    <style dangerouslySetInnerHTML={{
                                                        __html: `.custom-game-button-one{${buttonStyle}}.custom-game-button-one:hover{${buttonHoverStyle}}`
                                                    }}></style>
                                                    <span className="d-flex w-100 justify-content-center align-items-center" style={{
                                                        marginBottom: `${buttonStyleMarginBottom}px`,
                                                        display: `${ButtonOneStyle === "custom" ? "block" : ""}`
                                                    }}>
                                                        {
                                                            this.props.type === "stop" || this.props.type === "level" ?
                                                                <i className="icon far fa-play"></i>
                                                                :
                                                                <i className="icon far fa-retweet-alt"></i>
                                                        }
                                                        {ButtonOneStyleTitle}
                                                    </span>
                                                </div>
                                        }
                                        {
                                            this.props.isPaused ? ""
                                                :
                                                <div title={t('Pages.Lessons.tools.exit')} className={` text-center  m-1 ${ButtonSecondStyle === "custom" ? "custom-game-button-second" : `custom-button  ${ButtonSecondStyle}-button`}  `} onClick={() => this.props.goBack()}>
                                                    <style dangerouslySetInnerHTML={{
                                                        __html: `.custom-game-button-second{${buttonSecondStyle}}.custom-game-button-second:hover{${buttonSecondHoverStyle}}`
                                                    }}></style>
                                                    <span className="d-flex w-100 justify-content-center align-items-center" style={{
                                                        marginBottom: `${buttonSecondStyleMarginBottom}px`,
                                                        display: `${ButtonSecondStyle === "custom" ? "block" : ""}`
                                                    }}>
                                                        <i className="icon far fa-power-off"></i>{t('Pages.Lessons.tools.exit')}
                                                    </span>
                                                </div>
                                        }
                                        {
                                            ((this.props.type === "success" || this.props.type === "error") && this.props.hasNext) ||
                                                (this.props.type === "error" && showNextButtonAfterMultiErrors && this.props.hasNext) ?
                                                <div title={t('Pages.Games.NextGame')} className={` text-center  m-1 ${ButtonNextStyle === "custom" ? "custom-game-button-Next" : `custom-button  ${ButtonNextStyle}-button`}  `} onClick={() => this.props.goNext()}>
                                                    <style dangerouslySetInnerHTML={{
                                                        __html: `.custom-game-button-Next{${buttonNextStyle}}.custom-game-button-Next:hover{${buttonNextHoverStyle}}`
                                                    }}></style>
                                                    <span className="d-flex w-100 justify-content-center align-items-center" style={{
                                                        marginBottom: `${buttonNextStyleMarginBottom}px`,
                                                        display: `${ButtonNextStyle === "custom" ? "block" : ""}`
                                                    }}>
                                                        <i className="icon far fa-forward-step"></i>{t('Pages.Games.NextGame')}
                                                    </span>
                                                </div>
                                                : ""
                                        }
                                    </div>
                            }
                            {
                                (this.props.type === "success" || this.props.type === "error") && this.props.hideButton === true ?
                                    <div className="product-list-item mb-0" >
                                        <div className="product-content w-100 text-center ">
                                            <div className="display-1 text-danger">
                                                <i className="fad fa-sync-alt fa-spin"></i>
                                            </div>
                                            <h4 className="display-3 subtitle">
                                                {t("Pages.Games.Saveing")}
                                            </h4>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>
                        {
                            this.props.type === "level" && item && item.Position && !IsPassed ?
                                <div className="position-relative d-flex justify-content-center" >
                                    <div className=" h5 text-white game-font px-4 pb-3 pt-0 text-center" style={funStyle} >
                                        <div className="mb-1" title={t('Pages.Games.forms.LessonInfo.IsForFun')} >
                                            <i className="fa-regular fa-gamepad-modern"></i>
                                        </div>
                                        {
                                            (item.Mark - TryNumber) <= 0 ?
                                                t('Pages.Games.TryToPassGame.next') :
                                                item.Mark - TryNumber === 1 ?
                                                    t('Pages.Games.TryToPassGame.1') :
                                                    item.Mark - TryNumber === 2 ?
                                                        t('Pages.Games.TryToPassGame.2') :
                                                        <>
                                                            {t('Pages.Games.TryToPassGame.remain')}
                                                            <span className="mx-1">
                                                                {item.Mark - TryNumber}
                                                            </span>
                                                            {t('Pages.Games.TryToPassGame.3')}
                                                        </>
                                        }
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation('common')(ResultScreen);
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';

import ClassRoomItem from "./ClassRoomItem";
import {
    fetchClassRooms as fetch,
    removeClassRooms as remove,
    updateClassRoom as update,
    addClassRoom as add,
    undoClassRooms as undo,
    clearClassRooms as clear,
    clearListClassRoom as clearList
} from './actions';

import { fetchTeachers } from '../Teachers/actions/';
import api, { apiBlob } from '../../../api/api';
import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import EditClassRoomForm from '../../Home/Forms/EditClassRoomForm';
import ImportExcelForm from '../../Home/Forms/ImportExcelForm';

class ClassRoomsList extends React.Component {

    state = { search: '', showAdd: false, showImprotExcel: false, showStudentNotAdded: false };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.props.fetch(true);
        this.props.fetchTeachers();
    }

    componentWillUnmount() {
        this.props.clearList();
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    showAdd = () => {
        this.setState({
            showAdd: true
        });
    }
    showImprotExcel = () => {
        this.setState({
            showImprotExcel: true
        });
    }

    handleClose = () => {
        this.setState({
            showImprotExcel: false,
            showAdd: false,
            showStudentNotAdded: false
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteItem(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteItem = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/ClassRooms/${id}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.ClassRoomsControl.toast.Delete.Title')}</div> <div className="h6">{t('Pages.ClassRoomsControl.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    renderClassRooms() {
        const { t, classRooms, teachers } = this.props;
        let isLoaded = false;
        let toRemove = [];

        if (classRooms) {
            toRemove = classRooms.toRemove;
            isLoaded = classRooms.isLoaded;
        }

        if (classRooms && classRooms.classRooms && classRooms.classRooms.length > 0) {
            let filterClassRooms = classRooms.classRooms.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.ClassRoomsControl.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterClassRooms.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.ClassRoomsControl.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.ClassRoomsControl.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterClassRooms.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(classRoom =>
                (toRemove && toRemove.includes(classRoom.Id)) ? "" :
                    <ClassRoomItem
                        teachers={teachers}
                        key={classRoom.Id}
                        item={classRoom}
                        update={this.props.update}
                        remove={this.showDelete}
                    ></ClassRoomItem>
            );
        }

        if (classRooms && classRooms.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.ClassRoomsControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.ClassRoomsControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    Sync = () => {
        this.props.fetch();
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/ClassRooms/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.add(response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ClassRoomsControl.toast.Add.Title')}</div> <div className="h6">{t('Pages.ClassRoomsControl.toast.Add.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();

            } else if (response.data.code === "exist_class") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ClassRoomsControl.toast.ExistClass.Title')}</div> <div className="h6">{t('Pages.ClassRoomsControl.toast.ExistClass.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitFile = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/ImprotExcel/`,
            postData
        ).then(response => {
            if (response.data.code === "success") {
                this.Sync();
                this.handleClose();
                if (response.data.users === undefined || response.data.users.length === 0) {

                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ClassRoomsControl.toast.ImprotExcel.Title')}</div> <div className="h6">{t('Pages.ClassRoomsControl.toast.ImprotExcel.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({
                        StudentNotAdded: response.data.users
                    });
                    toast.update(_toastId, {
                        render: () => <div>
                            <div className="h5">
                                <i className="icon fal fa-engine-warning"></i>
                                {t('Pages.ClassRoomsControl.toast.ImprotExcelNotAll.Title')}
                            </div>
                            <div className="h6">
                                {t('Pages.ClassRoomsControl.toast.ImprotExcelNotAll.Body')}
                            </div>
                        </div>,
                        type: toast.TYPE.WARNING,
                        position: "bottom-left",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    this.showStudentNotAdded();
                }
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showStudentNotAdded = () => {
        this.setState({
            showStudentNotAdded: true
        });
    }

    download = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        apiBlob.get(`/ImprotExcel/`).then(response => {
            if (response) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";
                const blob = new Blob([response.data]),
                    url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `${t('Pages.ClassRoomsControl.Form.file_name')}.xlsx`;
                a.click();
                window.URL.revokeObjectURL(url);
            }
            toast.dismiss(this._toastId.current);
        }).catch(error => {
            toast.dismiss(this._toastId.current);
        });
    }

    render() {
        const { t, classRooms, teachers, user } = this.props;

        let isLoaded = false;
        if (classRooms) {
            isLoaded = classRooms.isLoaded;
        }

        return (
            <>
                {
                    this.state.showImprotExcel ?
                        <>
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showImprotExcel}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-file-excel text-success icon"></i> {t("Pages.ClassRoomsControl.ImprotExcel")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <ImportExcelForm download={this.download} onSubmit={this.onSubmitFile}></ImportExcelForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                        : ""
                }
                {
                    this.state.showAdd ?
                        <>
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showAdd}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-plus text-primary icon"></i> {t("Pages.ClassRoomsControl.Add")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <EditClassRoomForm teachers={teachers} onSubmit={this.onSubmit}></EditClassRoomForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                        : ""
                }
                {
                    this.state.showStudentNotAdded ?
                        <>
                            <Modal
                                size="xl"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showStudentNotAdded}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-engine-warning text-danger icon"></i> {t("Pages.ClassRoomsControl.StudentNotAdded")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <div className={`contact-form p-3 w-100 mb-3 inner-box p-3 justify-content-center  `} style={{
                                                background: 'rgb(255 170 170)',
                                                border: '1px solid rgb(235 92 92)',
                                                borderRadius: '10px'
                                            }}  >
                                                <div>
                                                    <p className="">
                                                        {t('Pages.ClassRoomsControl.StudentNotAddedInfo')}
                                                    </p>
                                                </div>
                                            </div>
                                            <table className="table table-striped table-hover table-bordered mt-0">
                                                <thead>
                                                    <tr className="text-center ">
                                                        <th className="text-center ">
                                                            {t('Pages.Register.FirstName.title')}
                                                        </th>
                                                        <th className="text-center ">
                                                            {t('Pages.Register.SecondName.title')}
                                                        </th>
                                                        <th className="text-center ">
                                                            {t('Pages.Register.LastName.title')}
                                                        </th>
                                                        <th className="text-center ">
                                                            {t('Pages.Register.Username.title')}
                                                        </th>
                                                        <th className="text-center ">
                                                            {t('Pages.Register.Email.title')}
                                                        </th>
                                                        <th className="text-center ">
                                                            {t('Pages.ClassRoomsControl.Form.ClassType.title')}
                                                        </th>
                                                        <th className="text-center ">
                                                            {t('Pages.ClassRoomsControl.Form.Division.title')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.StudentNotAdded && this.state.StudentNotAdded.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    {item.FirstName}
                                                                </td>
                                                                <td>
                                                                    {item.SecondName}
                                                                </td>
                                                                <td>
                                                                    {item.LastName}
                                                                </td>
                                                                <td>
                                                                    {item.Username}
                                                                </td>
                                                                <td>
                                                                    {item.Email}
                                                                </td>
                                                                <td>
                                                                    {item.ClassType}
                                                                </td>
                                                                <td>
                                                                    {item.Division}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                        : ""
                }
                <section className="pos-rel padding-bottom padding-top">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.ClassRoomsControl.title')} | {t('alarabeyya')}
                            </title>
                        </Helmet>
                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.ClassRoomsControl.title')}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.ClassRoomsControl.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.showAdd()} title={t("Pages.ClassRoomsControl.Add")} className="btn btn-primary mx-1">
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.ClassRoomsControl.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                                <div onClick={() => this.download()} title={t("Pages.ClassRoomsControl.Form.download")} className="btn btn-dark mx-1">
                                                    <i className="fas fa-download"></i>
                                                </div>
                                                <div onClick={() => this.showImprotExcel()} title={t("Pages.ClassRoomsControl.ImprotExcel")} className="btn btn-success">
                                                    <i className="fas fa-file-excel"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderClassRooms()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        teachers: state.teachers,
        classRooms: state.classRooms
    };
};

export default connect(mapStateToProps, { fetch, remove, clear, undo, add, update, fetchTeachers, clearList })(withTranslation('common')(ClassRoomsList));
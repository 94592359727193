import React from "react";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

class ChangePasswordForm extends React.Component {

    state = { errorList: [] };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};


        if (!this.NewPassword.ref.current.getValue()) {
            errors.NewPassword = 'Pages.ChangePassword.NewPassword.error';
        } else if (this.props.isTeacher !== true && !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(this.NewPassword.ref.current.getValue())) {
            errors.NewPassword = 'Pages.ChangePassword.NewPassword.error_notmatch';
        }

        if (!this.RePassword.ref.current.getValue()) {
            errors.RePassword = 'Pages.ChangePassword.RePassword.error';
        } else if (this.RePassword.ref.current.getValue() !== this.NewPassword.ref.current.getValue()) {
            errors.RePassword = 'Pages.ChangePassword.RePassword.error_notmatch';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                NewPassword: this.NewPassword.ref.current.getValue(),
                RePassword: this.RePassword.ref.current.getValue()
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);
        }
        e.preventDefault();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5 text-right w-100'>{t('Pages.ChangePassword.NewPassword.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <Field
                            ref={NewPassword => this.NewPassword = NewPassword}
                            name="NewPassword"
                            component={this.renderField}
                            type="password"
                            required={true}
                            className="form-control"
                            placeholder={t('Pages.ChangePassword.NewPassword.Placeholder')}
                            title={t('Pages.ChangePassword.NewPassword.title')}
                        />
                    </div>

                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5 text-right w-100'>{t('Pages.ChangePassword.RePassword.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <Field
                            ref={RePassword => this.RePassword = RePassword}
                            name="RePassword"
                            component={this.renderField}
                            type="password"
                            required={true}
                            className="form-control"
                            placeholder={t('Pages.ChangePassword.RePassword.Placeholder')}
                            title={t('Pages.ChangePassword.RePassword.title')}
                        />
                    </div>

                    <div className="form-group w-100">
                        <button className="custom-button mt-1 w-100"><span> <i className="fal fa-save"></i> {t('Pages.ChangePassword.submit')}</span></button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.NewPassword) {
        errors.NewPassword = 'Pages.ChangePassword.NewPassword.error';
    }

    if (!values.RePassword) {
        errors.RePassword = 'Pages.ChangePassword.RePassword.error';
    } else if (values.RePassword !== values.NewPassword) {
        errors.RePassword = 'Pages.ChangePassword.RePassword.error_notmatch';
    }

    return errors;
}

const formChangePassword = reduxForm({
    form: 'ChangePassword',
    validate
});

export default withTranslation('common')(formChangePassword(ChangePasswordForm));

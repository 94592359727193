import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import {
    fetchSchools
} from '../Users/actions';
import {
    fetchEmailContent
} from '../EmailContent/actions';
import configData from "../../Config/UserType.json";
import configDataCountries from "../../Config/countries.json";
import api from "../../../api/api";
import { SendEmailBySocket } from '../../../api/socket.io';
import ClassTypeData from "../../Config/classType.json";

class SendEmail extends React.Component {

    state = {
        success: false,
        Total: 0,
        ClassTypeId: 0,
        ClassType: [],
        Body: '',
        Title: '',
        search: '',
        UserTypeId: 0,
        Sex: "",
        SchoolId: "",
        IsActive: null,
        ActiveUntil: null,
        CountryCustom: null,
        IsLocked: null,
        IsTeacher: null,
        Email: "",
        Name: "",
        Username: "",
        Phone: "",
        schools: [],
        startActiveDate: "",
        endActiveDate: "",
        isFirst: false
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        moment.locale('en');
        window.scrollToTop();
        this.props.fetchSchools();
        this.state.UserType = configData.UserType;
        this.state.ClassType = ClassTypeData.ClassType;
        this.state.countries = configDataCountries.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
        this.props.fetchEmailContent("send_template");
    }

    static getDerivedStateFromProps(props, state) {
        if (props.schools && props.schools.schools) {
            state.schools = props.schools.schools.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }
        if (!state.isFirst && props.emailContents && props.emailContents.emails && props.emailContents.emails[0]) {
            state.Body = props.emailContents.emails[0].Body;
            setTimeout(() => {
                window.setRedactor("#Message", state.Body);
            }, 300);
            state.isFirst = true;
        }
        return state;
    }

    setEmail = Email => {
        this.setState({
            Email
        });
    }

    setUsername = Username => {
        this.setState({
            Username
        });
    }

    setPhone = Phone => {
        this.setState({
            Phone
        });
    }

    setName = Name => {
        this.setState({
            Name
        });
    }

    setClassTypeId = ClassTypeId => {
        this.setState({
            ClassTypeId
        });
    }

    send = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const Message = window.getRedactor(`#Message`);
        if (!Message) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } else if (!this.state.Title) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {

            const postData = {
                UserTypeId: this.state.UserTypeId,
                SchoolId: this.SchoolId.state.value ? this.SchoolId.state.value.value : 0,
                Country: this.Country.state.value ? this.Country.state.value.value : "",
                ActiveUntil: this.state.ActiveUntil,
                CountryCustom: this.state.CountryCustom,
                IsActive: this.state.IsActive,
                IsLocked: this.state.IsLocked,
                IsTeacher: this.state.IsTeacher,
                Sex: this.state.Sex,
                startActiveDate: this.state.startActiveDate,
                endActiveDate: this.state.endActiveDate,
                Title: this.state.Title,
                Email: this.state.Email,
                Username: this.state.Username,
                Phone: this.state.Phone,
                Name: this.state.Name,
                ClassTypeId: this.state.ClassTypeId,
                Message
            };

            SendEmailBySocket(this.state.Title, Message, postData);


            api.post(`/ContactUs/?IsSend=${true}`,
                postData
            ).then(response => {
                window.scrollToTop();

                this.setState({
                    Total: response.data,
                    success: true
                });

                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-shield-check"></i>
                            {t('Pages.SendEmail.success_toast.Title')}
                        </div>
                        <div className="h6">
                            {t('Pages.SendEmail.success_toast.Body')} {response.data}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    setUserType = UserTypeId => {
        this.setState({
            UserTypeId
        });
    }

    setSex = Sex => {
        this.setState({
            Sex
        });
    }

    setActiveUntil = ActiveUntil => {
        this.setState({
            ActiveUntil
        });
    }

    setCountry = CountryCustom => {
        this.setState({
            CountryCustom
        });
    }

    setIsActive = IsActive => {
        this.setState({
            IsActive
        });
    }

    setIsLocked = IsLocked => {
        this.setState({
            IsLocked
        });
    }

    setIsTeacher = IsTeacher => {
        this.setState({
            IsTeacher
        });
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    renderUserTypeOption() {
        return this.state.UserType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startActiveDate: picker.startDate.format('DD/MM/YYYY'),
            endActiveDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    closeDate = () => {
        this.Date.ref.value = "";
        this.setState({
            startActiveDate: "",
            endActiveDate: ""
        });
    }

    validateTitle = Title => {
        this.setState({
            Title
        });
    }

    handleAfterSubmit = (value) => {
        this.setState({ success: value });
        setTimeout(() => {
            window.setRedactor("#Message", this.state.Body);
        }, 300);
    }

    renderSuccess() {
        const { t } = this.props;
        return (
            <div className="widget" >
                <div className="widget-body">

                    <div className={`card card-custom card-stretch gutter-b  `}>
                        <div className="card-body">
                            <div className="icon success animate">
                                <span className="line tip animateSuccessTip"></span>
                                <span className="line long animateSuccessLong"></span>
                                <div className="placeholder"></div>
                                <div className="fix"></div>
                            </div>
                            <div className="text-center">
                                <div className="text-center font-size-h1">
                                    {t('Pages.SendEmail.success_toast.Title')}
                                </div>
                                <p className="lead">
                                    {t('Pages.SendEmail.success_toast.Body')}
                                    <span className="text-danger">
                                        {this.state.Total}
                                    </span>
                                </p>
                                <div className="btn btn-success font-weight-bold  btn-lg btn-block" onClick={() => this.handleAfterSubmit(false)}>
                                    <i className="icon fal fa-envelope"></i>
                                    {t('ContactFormSendSuccess.Resend')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderClassTypeOption() {
        return this.state.ClassType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    render() {

        const { t, user, schools } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Tomorrow")] = [moment().subtract(-1, 'days'), moment().subtract(-1, 'days')];
        ranges[t("range.Next7Days")] = [moment().toDate(), moment().subtract(-6, 'days'), moment()];
        ranges[t("range.Next30Days")] = [moment().toDate(), moment().subtract(-29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.NextMonth")] = [moment().subtract(-1, 'month').startOf('month'), moment().subtract(-1, 'month').endOf('month')];

        let isLoadedSchool = false;
        if (schools) {
            isLoadedSchool = schools.isLoaded;
        }

        return (
            <>
                <section className="pos-rel padding-bottom padding-top">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.SendEmail.title')} | {t('alarabeyya')}
                            </title>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=22'} ></script>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                {
                                    this.state.success ? this.renderSuccess()
                                        :
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-8">
                                                    <div className="section-header">
                                                        <h3 className="title">{t('Pages.SendEmail.title')}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="widget widget-category" >

                                                <div className="widget-header">
                                                    <h5 className="title">
                                                        <i className="fal fa-search "></i> {t('Pages.SendEmail.search')}
                                                    </h5>
                                                </div>
                                                <div className="widget-body">
                                                    <div className="contact-form">
                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.UserTypeId}
                                                                onChange={e => this.setUserType(e.target.value)}
                                                                className="form-control"
                                                                name="UserTypeId"
                                                                title={t("Pages.UsersControl.UserType.title")}
                                                                placeholder={t("Pages.UsersControl.UserType.Placeholder")}    >
                                                                <option value="0">{t("Pages.UsersControl.advancedSearch.UserType")}  </option>
                                                                {this.renderUserTypeOption()}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.Sex}
                                                                onChange={e => this.setSex(e.target.value)}
                                                                className="form-control"
                                                                name="UserTypeId"
                                                                title={t("Pages.UsersControl.advancedSearch.Sex")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.Sex")}  </option>
                                                                <option value="male">{t("Pages.Register.Sex.male")}  </option>
                                                                <option value="female">{t("Pages.Register.Sex.female")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <div>
                                                                <Select
                                                                    defaultValue={this.state.CountryIndex >= 0 ? {
                                                                        label: this.state.countries[this.state.CountryIndex].label,
                                                                        value: this.state.countries[this.state.CountryIndex].value
                                                                    } : ""}
                                                                    name="Country"
                                                                    placeholder={t('Pages.Register.Country.Placeholder')}
                                                                    isClearable={true}
                                                                    isRtl={true}
                                                                    isSearchable={true}
                                                                    ref={Country => this.Country = Country}
                                                                    options={this.state.countries}
                                                                    filterOption={this.filterOptions}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#92278f',
                                                                            primary: '#92278f',
                                                                        },
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div>
                                                                <Select
                                                                    isClearable={true}
                                                                    name="SchoolId"
                                                                    placeholder={t('Pages.UsersControl.advancedSearch.School')}
                                                                    isRtl={true}
                                                                    isLoading={!isLoadedSchool}
                                                                    isSearchable={true}
                                                                    ref={SchoolId => this.SchoolId = SchoolId}
                                                                    options={this.state.schools}
                                                                    filterOption={this.filterOptions}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#92278f',
                                                                            primary: '#92278f',
                                                                        },
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.IsActive}
                                                                onChange={e => this.setIsActive(e.target.value)}
                                                                className="form-control"
                                                                name="IsActive"
                                                                title={t("Pages.UsersControl.advancedSearch.IsActive")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.IsActive")}  </option>
                                                                <option value={true}>{t("Pages.UsersControl.Active")}  </option>
                                                                <option value={false}>{t("Pages.UsersControl.NotActive")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.IsLocked}
                                                                onChange={e => this.setIsLocked(e.target.value)}
                                                                className="form-control"
                                                                name="IsLocked"
                                                                title={t("Pages.UsersControl.advancedSearch.IsLocked")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.IsLocked")}  </option>
                                                                <option value={false}>{t("Pages.UsersControl.NotLocked")}  </option>
                                                                <option value={true}>{t("Pages.UsersControl.Locked")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.IsTeacher}
                                                                onChange={e => this.setIsTeacher(e.target.value)}
                                                                className="form-control"
                                                                name="IsTeacher"
                                                                title={t("Pages.UsersControl.advancedSearch.IsTeacher")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.IsTeacher")}  </option>
                                                                <option value={false}>{t("Pages.UsersControl.NotTeacher")}  </option>
                                                                <option value={true}>{t("Pages.UsersControl.Teacher")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.Country}
                                                                onChange={e => this.setCountry(e.target.value)}
                                                                className="form-control"
                                                                name="CountryCustom"
                                                                title={t("Pages.UsersControl.advancedSearch.Country.title")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.Country.title")}  </option>
                                                                <option value={false}>{t("Pages.UsersControl.advancedSearch.Country.OutSide")}  </option>
                                                                <option value={true}>{t("Pages.UsersControl.advancedSearch.Country.InSide")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.ActiveUntil}
                                                                onChange={e => this.setActiveUntil(e.target.value)}
                                                                className="form-control"
                                                                name="ActiveUntil"
                                                                title={t("Pages.UsersControl.advancedSearch.ActiveUntil.title")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.ActiveUntil.title")}  </option>
                                                                <option value={true}>{t("Pages.UsersControl.advancedSearch.ActiveUntil.Active")}  </option>
                                                                <option value={false}>{t("Pages.UsersControl.advancedSearch.ActiveUntil.NotActive")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group pos-rel d-flex align-items-center">
                                                            <div className=" flex-grow-1">
                                                                <DateRangePicker
                                                                    ref={Date => this.Date = Date}
                                                                    initialSettings={{
                                                                        opens: 'center',
                                                                        autoUpdateInput: false,
                                                                        buttonClasses: 'btn',
                                                                        cancelClass: "btn-danger",
                                                                        applyButtonClasses: "btn-primary",
                                                                        showDropdowns: true,
                                                                        ranges: ranges,
                                                                        minYear: 2020,
                                                                        locale: {
                                                                            format: 'DD/MM/YYYY',
                                                                            cancelLabel: t('Close'),
                                                                            firstDay: 6,
                                                                            applyLabel: t('Apply'),
                                                                            customRangeLabel: t('range.customRange'),
                                                                            monthNames: [
                                                                                `${t("months.January")}`,
                                                                                `${t("months.February")}`,
                                                                                `${t("months.March")}`,
                                                                                `${t("months.April")}`,
                                                                                `${t("months.May")}`,
                                                                                `${t("months.June")}`,
                                                                                `${t("months.July")}`,
                                                                                `${t("months.August")}`,
                                                                                `${t("months.September")}`,
                                                                                `${t("months.October")}`,
                                                                                `${t("months.November")}`,
                                                                                `${t("months.December")}`
                                                                            ],
                                                                            daysOfWeek: [
                                                                                t("daysOfWeek.Sunday"),
                                                                                t("daysOfWeek.Monday"),
                                                                                t("daysOfWeek.Tuesday"),
                                                                                t("daysOfWeek.Wednesday"),
                                                                                t("daysOfWeek.Thursday"),
                                                                                t("daysOfWeek.Friday"),
                                                                                t("daysOfWeek.Saturday")
                                                                            ],
                                                                        },
                                                                    }}
                                                                    onApply={this.handleApplyDate} >
                                                                    <input
                                                                        defaultValue={this.state.activeDate}
                                                                        name="Date"
                                                                        autoComplete="off"
                                                                        required
                                                                        type="text"
                                                                        placeholder={t('Pages.UsersControl.advancedSearch.activeDate')}
                                                                        title={t('Pages.UsersControl.advancedSearch.activeDate')}
                                                                    />
                                                                </DateRangePicker>
                                                            </div>
                                                            <div className="btn position-absolute  d-flex align-items-center" onClick={() => this.closeDate()} style={{
                                                                left: 0
                                                            }}>
                                                                <i className="fas fa-times"></i>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <input
                                                                defaultValue={this.state.Name}
                                                                onChange={e => this.setName(e.target.value)}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t("Pages.UsersControl.advancedSearch.name")}
                                                                title={t("Pages.UsersControl.advancedSearch.name")} />
                                                        </div>

                                                        <div className="form-group">
                                                            <input
                                                                defaultValue={this.state.Email}
                                                                onChange={e => this.setEmail(e.target.value)}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t("Pages.UsersControl.advancedSearch.email")}
                                                                title={t("Pages.UsersControl.advancedSearch.email")} />
                                                        </div>

                                                        <div className="form-group">
                                                            <input
                                                                defaultValue={this.state.Username}
                                                                onChange={e => this.setUsername(e.target.value)}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t("Pages.UsersControl.advancedSearch.username")}
                                                                title={t("Pages.UsersControl.advancedSearch.username")} />
                                                        </div>

                                                        <div className="form-group">
                                                            <input
                                                                defaultValue={this.state.Phone}
                                                                onChange={e => this.setPhone(e.target.value)}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t("Pages.UsersControl.advancedSearch.phone")}
                                                                title={t("Pages.UsersControl.advancedSearch.phone")} />
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                defaultValue={this.state.ClassTypeId}
                                                                onChange={e => this.setClassTypeId(e.target.value)}
                                                                className="form-control"
                                                                name="ClassTypeId"
                                                                placeholder={t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}
                                                                title={t("Pages.UsersControl.advancedSearch.ClassType.title")}  >
                                                                <option value={0}>{t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}</option>
                                                                {this.renderClassTypeOption()}
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="widget" >
                                                <div className="widget-body">

                                                    <div className={`contact-form p-3 w-100 mb-3 inner-box pt-4  justify-content-center d-flex `} style={{
                                                        background: 'rgb(174 213 241)',
                                                        border: '1px solid rgb(53 128 220)',
                                                        borderRadius: '10px'
                                                    }}  >
                                                        <div>
                                                            <div className="text-center h2 mb-3">
                                                                <i className="icon fal fa-info"></i>
                                                                {t('Pages.SendEmail.info.title')}
                                                            </div>
                                                            <ul className="w-100 list-group list-group-flush">
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t1')
                                                                }}></li>
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t2')
                                                                }}></li>
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t3')
                                                                }}></li>
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t4')
                                                                }}></li>
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t5')
                                                                }}></li>
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t6')
                                                                }}></li>
                                                                <li dangerouslySetInnerHTML={{
                                                                    __html: t('Pages.SendEmail.info.t7')
                                                                }}></li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="contact-form">
                                                        <div className="form-group ">
                                                            <label className='text-dark font-size-h5'>{t('ContactUs.Title.title')}</label>
                                                            <div>
                                                                <input
                                                                    ref={Title => this.Title = Title}
                                                                    name="Title"
                                                                    type="text"
                                                                    onChange={e => this.validateTitle(e.target.value)}
                                                                    required={true}
                                                                    placeholder={t('ContactUs.Title.Placeholder')}
                                                                    title={t('ContactUs.Title.title')}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mt-4 w-100 text-center">
                                                        <label className="text-dark font-size-h5" htmlFor="Message"> <i className="icon fas fa-text"></i> {t('Pages.SendEmail.Message.title')}</label>
                                                        <textarea
                                                            ref={Message => this.Message = Message}
                                                            id={`Message`}
                                                            name="Message"
                                                            placeholder={t('Pages.SendEmail.Message.placeholder')}
                                                            title={t('Pages.SendEmail.Message.title')}
                                                            autoComplete="off"
                                                            className="form-control" ></textarea>
                                                    </div>

                                                    <div className="form-group w-100 text-center">
                                                        <button className="custom-button" onClick={() => this.send()} title={t("Pages.SendEmail.send")} >
                                                            <span>
                                                                <i className="fas icon fa-paper-plane"></i>
                                                                {t('Pages.SendEmail.send')}
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        schools: state.schools,
        user: state.user,
        emailContents: state.emailContents
    };
};

export default connect(mapStateToProps, { fetchEmailContent, fetchSchools })(withTranslation('common')(SendEmail));
import React from "react";
import TextBox from "../../TextBox";
import './style.css';

class Apple extends React.Component {

    constructor(props) {
        super(props);
        this.random = Math.ceil(Math.random() * 500);
    }

    render() {

        let color = "#ff4355";
        let style = "";
        if (this.props.customColor) {
            color = this.props.customColor;
            style = `.Fruit-apple_${this.random}{background-color: ${color};}.Fruit-apple_${this.random}:before{box-shadow: -1.5em 0 0 ${color}, inset 0.2em 0.2em  ${color}; }`;
        }

        return <>
            <style dangerouslySetInnerHTML={{ __html: style }}></style>
            <div className="position-relative">
                <div className={`${this.props.isSpin === "true" || this.props.isSpin === true ? "fa-spin" : ""}`}>
                    <div style={{
                        transformOrigin: `${this.props.transformOriginX ? this.props.transformOriginX : "center"} ${this.props.transformOriginY ? this.props.transformOriginY : "center"}`,
                        transform: `
                        ${this.props.scale ? `scale(${this.props.scale})` : ""}
                        ${this.props.flipX === "true" || this.props.flipX === true ? "scaleX(-1)" : ""}
                        ${this.props.rotate ? `rotate(${this.props.rotate}deg)` : ""}
                        `
                    }}>
                        <div className={`Fruit-apple mx-auto position-relative Fruit-apple_${this.random} mb-2 `}></div>
                    </div>
                </div>
                <TextBox {...this.props} />
            </div>
        </>;
    }
}

export default Apple;
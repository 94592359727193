import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

import Paint from "./Paint";
import Tools from '../../../Admin/Lessons/Tools';
import { apiBlob } from '../../../../api/api';
import history from '../../../../history';

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            FontSize: 18,
            showSound: false,
            showText: false,
            styleLeftSide: '',
            styleBind: '',
            styleRightSide: '',
            token: '',
            showPaint: false,
            currentLeft: 0,
            currentRight: 0,
            first: true,

            bookView: true,
            widthForBoth: true,
            width: 0,
            scale: 1,
            pageNumber: 1,

            Id: 0,
            Name: '',
            Bio: '',
            search: '',
            Url: '',
            Sort: 0,
            Sound: '',
            Icon: '',
            Background: '',
            Questions: [],
            QuestionToRemove: [],
            IsActive: true,
            IsPublic: false,
            IndexId: 0,
            questionSeparated: {
                BorderStyle: "dashed",
                BorderColor: 'rgba(68, 68, 68, 1)',
                BorderWidth: 3,
                width: 100
            }
        };
        this._toastId = new React.createRef();
        this.page_right = new React.createRef();
        this.page_section = new React.createRef();

        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

        window.addEventListener('resize', this.updateSize);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.item) {
            let cItem = props.item;
            if (props.code && props.code === "no_permission") {
                history.push(`/NoPermissions/`);
            }
            let obj = {
                Sort: cItem.Sort,
                Id: cItem.Id,
                LessonId: cItem.LessonId,
                Name: cItem.Name,
                token: cItem.token,
                Bio: cItem.Bio,
                Background: cItem.Background,
                Sound: cItem.Sound,
                IsActive: cItem.IsActive,
                IsPublic: cItem.IsPublic,
                IndexId: cItem.Index ? cItem.Index.Id : state.IndexId,
                Icon: cItem.Icon,
                Url: cItem.Url,
            };
            return obj;
        }
        return state;
    }

    renderAudioPlayer() {
        const { t } = this.props;
        if (this.state.showSound && this.state.Sound) {
            return (
                <div id="AudioPlayer" className={`position-fixed w-100 bg-white`} style={{
                    bottom: 0,
                    zIndex: 9999999
                }}>
                    <div className="ribbon-target sound" onClick={() => this.hideSound()} title={t('Close')} style={{ top: '0px', height: '40px' }}>
                        <span className="ribbon-inner bg-danger"></span>
                        <i className="fas fa-times px-1"></i>
                    </div>
                    <div className="text-center" style={{ direction: 'ltr' }}>
                        <AudioPlayer src={this.state.Sound} />
                    </div>
                </div>
            );
        }
        return '';
    }
    showPaint = () => {
        this.setState({ showPaint: !this.state.showPaint });
    }
    openViewWindow = () => {
        let id = this.state.Id;
        if (this.props.isFromFile && this.state.LessonId) {
            id = this.state.LessonId;
        }
        window.openLessonView(id);
    }
    hideSound = () => {
        this.setState({ showSound: false });
    }
    showSound = () => {
        this.setState({ showSound: !this.state.showSound });
        if (!this.state.Sound) {
            const { t } = this.props;

            this._toastId.current = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-volume-slash"></i> {t('Error.NoSound.Title')}</div> <div className="h6">{t('Error.NoSound.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({ showSound: false });
        }

    }
    renderPaint() {
        if (this.state.showPaint) {
            return <Paint key={`Painter`} showPaint={this.showPaint}></Paint>
        }
        return '';
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
        if (numPages === 1) {
            this.oneSide();
        }
    };
    moreSize = () => {
        if (this.state.scale < 2) {
            this.setState({ scale: parseFloat(this.state.scale) + 0.2 });

            if (this.state.scale + 0.2 > 1) {
                this.setState({
                    styleLeftSide: {
                        style: {
                            display: 'none'
                        }
                    },
                    styleBind: {
                        style: {
                            display: 'none'
                        }
                    },
                    styleRightSide: {
                        style: {
                            width: '100%'
                        }
                    },
                    bookView: false
                });
            }
        }

    }
    lessSize = () => {
        if (this.state.scale > 0.6) {
            this.setState({ scale: parseFloat(this.state.scale) - 0.2 });
        }
        if (this.state.scale > 1) {
            this.setState({
                styleLeftSide: {
                    style: {
                        display: 'none'
                    }
                },
                styleBind: {
                    style: {
                        display: 'none'
                    }
                },
                styleRightSide: {
                    style: {
                        width: '100%'
                    }
                },
                bookView: false
            });
        }
    }
    resetSize = () => {
        this.setState({ scale: 1 });
    }

    first = () => {
        this.setState({ pageNumber: 1 });
    }
    prev = () => {
        if (this.state.pageNumber > 1) {
            let step = 1;
            if (this.state.bookView) {
                step = 2;
            }
            this.setState({ pageNumber: parseFloat(this.state.pageNumber) - step });
        }
    }
    next = () => {
        let step = 1;
        if (this.state.bookView) {
            step = 2;
        }
        if (this.state.pageNumber + step - 1 < this.state.numPages) {
            this.setState({ pageNumber: parseFloat(this.state.pageNumber) + step });
        }
    }
    last = () => {
        let pageNumber = parseFloat(this.state.numPages);
        if (this.state.bookView) {
            if (pageNumber % 2 === 0) {
                pageNumber -= 1;
            }
        }
        this.setState({ pageNumber });
    }

    oneSide = () => {
        this.setState({
            styleLeftSide: {
                style: {
                    display: 'none'
                }
            },
            styleBind: {
                style: {
                    display: 'none'
                }
            },
            styleRightSide: {
                style: {
                    width: '100%'
                }
            },
            bookView: false
        });
    }
    twoSide = () => {
        let pageNumber = this.state.pageNumber;
        if (pageNumber % 2 === 0) {
            pageNumber -= 1;
        }
        this.setState({
            styleLeftSide: {},
            styleBind: {},
            styleRightSide: {},
            bookView: true,
            scale: 1,
            pageNumber
        });
    }
    updateSize = () => {
        if (this.page_right) {
            let widthForBoth = true;
            let pageWidth = this.page_section.current ? this.page_section.current.offsetWidth : 0;
            if (pageWidth < 1220) {
                this.oneSide();
                widthForBoth = false;
            } else {
                this.twoSide();
            }
            let width = this.page_right.current ? this.page_right.current.offsetWidth : 0;
            this.setState({
                width,
                widthForBoth
            });
        }
    }

    renderLoader = () => {
        const { t } = this.props;
        return (
            <>
                <div className="title h1 text-center mt-5">
                    <i className="fad fa-spinner-third fa-spin text-danger"></i> {t('Loading')}
                </div>
            </>
        );
    }

    download = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        apiBlob.post(`/EducationalFiles/?token=${this.state.token}`).then(response => {
            if (response) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";
                const blob = new Blob([response.data]),
                    url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = this.state.Name + '.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            }
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.dismiss(_toastId);
            history.push("/NoPermissions");
        });
    }

    render() {
        const { t, user } = this.props;
        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: `header{ display:none; }.filter{margin-bottom:5px;}section{padding-top:5px !important;padding-bottom:5px !important;}` }}></style>
                {this.renderPaint()}
                <section key={`section${this.state.Id}`} ref={this.page_section} className={`pos-rel padding-bottom padding-top ${this.state.Background !== '' ? "" : " call-in-action call-overlay min-h-full"}`} >

                    {
                        this.state.Background === '' ?
                            <>
                                <div className="top-shape-center" style={{
                                    transform: "translateX(50%)"
                                }}>
                                    <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                                </div>
                                <div className="bottom-shape-left" style={{
                                    left: "15px"
                                }}>
                                    <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                                </div>
                            </>
                            : ""
                    }

                    <div className="row mt-0" id="bookWapper" >
                        <Helmet>
                            <title>{this.state.Name} | {t('alarabeyya')}</title>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <link rel="stylesheet" href="https://cdn.jsdelivr.net/jquery.webui-popover/1.2.1/jquery.webui-popover.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                        </Helmet>
                        <style dangerouslySetInnerHTML={{
                            __html: `canvas{margin: 0 auto;}html{background-position: 50% 50%;height: 100vh;background-size: cover;background-repeat: no-repeat;background-attachment: fixed;background-image: ${this.state.Background !== '' ? "url('" + this.state.Background + "?v=1')" : ""};}body{background: unset;}`
                        }}></style>
                        <div className="container">
                            <Tools
                                user={user}
                                isOnEdit={false}
                                isPDF={true}
                                bookView={this.state.bookView}
                                token={this.state.token}
                                widthForBoth={this.state.widthForBoth}
                                LessonId={this.state.Id}
                                HasLesson={this.state.LessonId}
                                lessonTitle={this.state.Name}
                                hideSound={this.hideSound}
                                Sound={this.state.Sound}
                                Url={this.state.Url}
                                openPDFViewWindow={this.openPDFViewWindow}
                                download={this.download}
                                oneSide={this.oneSide}
                                twoSide={this.twoSide}
                                first={this.first}
                                prev={this.prev}
                                next={this.next}
                                last={this.last}
                                resetSize={this.resetSize}
                                lessSize={this.lessSize}
                                moreSize={this.moreSize}
                                openViewWindow={this.openViewWindow}
                                showPaint={this.showPaint}
                                showSound={this.showSound}>
                            </Tools>
                        </div>
                        <div className="col-sm-12 col-md-11 col-lg-10 col-xxl-8 mx-auto">
                            <div className="row bookWapper position-relative full-h mx-auto">
                                <div className="book_cover col-md-12 full-h paper overflow-hidden">
                                    <div className="book_pages col-md-12 full-h paper overflow-hidden">
                                        <div className="book_pages col-md-12 full-h paper overflow-hidden">
                                            <div className="book_pages col-md-12 full-h paper overflow-hidden">
                                                <div className="col-md-12 open-page full-h paper overflow-hidden" >
                                                    <div id="book-header">
                                                    </div>
                                                    <div id="book-body" className={this.state.bookView ? `d-flex align-self-stretch` : ""}>
                                                        <div className="full-h bookPageWapper page_right" ref={this.page_right} {...this.state.styleRightSide}>
                                                            <Document loading={() => this.renderLoader()} key={`Document${this.state.pageNumber}`} file={this.state.Url} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                <Page width={this.state.width} key={this.state.pageNumber} pageNumber={this.state.pageNumber} scale={this.state.scale} rotate={0} />
                                                            </Document>
                                                        </div>
                                                        {
                                                            this.state.bookView && (this.state.pageNumber + 1 <= this.state.numPages) ?
                                                                <>
                                                                    <div className="page_bind full-h "  {...this.state.styleBind}>
                                                                        <span className="top"></span>
                                                                        <span className="bottom"></span>
                                                                    </div>
                                                                    <div className={`full-h bookPageWapper page_left`} {...this.state.styleLeftSide} >
                                                                        <Document loading={() => this.renderLoader()} key={`Document${this.state.pageNumber + 1}`} file={this.state.Url} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                            <Page width={this.state.width} key={this.state.pageNumber + 1} pageNumber={this.state.pageNumber + 1} scale={this.state.scale} rotate={0} />
                                                                        </Document>
                                                                    </div>
                                                                </> : ""
                                                        }
                                                    </div>
                                                    <div id="book-footer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderAudioPlayer()}
            </>
        );
    }
}

export default withTranslation('common')(View);
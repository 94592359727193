import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'

import { fetchLesson } from '../../../Admin/Lessons/actions/';
import Loading from "../../../Loading/";
import View from "./View";

class PDFView extends React.Component {

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            Id: id
        });
        if (id !== '0') {
            this.props.fetchLesson(id, false);
        }
    }

    render() {
        const { lessons, user } = this.props;

        if (lessons.length === 0 || !lessons.current) {
            return <Loading></Loading>
        }
        return <View Id={this.state.Id} user={user} item={this.props.lessons.current}></View>
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchLesson })(withTranslation('common')(PDFView));
import React from "react";
import { withTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import { toast } from 'react-toastify';

import Background from "../Question/View/Background";
import Tools from "../Question/View/GamePreview/Tools";
import SelectGame from "./Play/";
import ResultScreen from "./Screen/ResultScreen";
import api from "../../../api/api";
import GameObjects from "../Question/View/GameObjects";
import { UpdateGameScoreBySocket } from '../../../api/socket.io';
import Backgrounds from "../Question/View/Background/";

let pauseTimer = 0;
class GamePlay extends React.Component {

    constructor(props) {
        super(props);

        this.randomListTop = [];
        this.randomListLeft = [];
        this.yourTime = 0;
        this.duration = 0;
        this.timer = 0;
        this.StopTime = 0;

        this.state = {
            Id: 0,
            isStart: false,
            showGame: false,
            isEnded: false,
            showCountDown: false,
            startTime: true,
            GameOver: false,
            TimesUp: false,
            pause: false,
            hideButton: true,
            isPlayVolumeSound: true,
            falseTry: 0,
            trueTry: 0,
            TryCount: 1,
            countDown: 3,
            BounsTry: 0,
            BounsTime: 0,
            userAnswers: [],
            currentStatus: "",
            score: 0,
            initGameSound: this.initGameSound.bind(this)
        };
    }

    initGameSound = gamesSetting => {
        if (gamesSetting.FalseSound && gamesSetting.FalseSound !== 'custom' && gamesSetting.FalseSound !== 'random' && gamesSetting.FalseSound !== 'none') {
            this.audioFalse = new Audio(process.env.PUBLIC_URL + gamesSetting.FalseSound);
        } else if (gamesSetting.FalseSound === 'custom' && gamesSetting.CustomFalseSound) {
            this.audioFalse = new Audio(gamesSetting.CustomFalseSound);
        }
        if (gamesSetting.BonusSound && gamesSetting.BonusSound !== 'custom' && gamesSetting.BonusSound !== 'random' && gamesSetting.BonusSound !== 'none') {
            this.audioBouns = new Audio(process.env.PUBLIC_URL + gamesSetting.BonusSound);
        } else if (gamesSetting.BonusSound === 'custom' && gamesSetting.CustomBonusSound) {
            this.audioBouns = new Audio(gamesSetting.CustomBonusSound);
        }

        if (gamesSetting.TrueSound && gamesSetting.TrueSound !== 'custom' && gamesSetting.TrueSound !== 'random' && gamesSetting.TrueSound !== 'none') {
            this.audioTrueSound = new Audio(process.env.PUBLIC_URL + gamesSetting.TrueSound);
        } else if (gamesSetting.TrueSound === 'custom' && gamesSetting.CustomTrueSound) {
            this.audioTrueSound = new Audio(gamesSetting.CustomTrueSound);
        }

        if (gamesSetting.WinSound && gamesSetting.WinSound !== 'custom' && gamesSetting.WinSound !== 'random' && gamesSetting.WinSound !== 'none') {
            this.audioWinSound = new Audio(process.env.PUBLIC_URL + gamesSetting.WinSound);
        } else if (gamesSetting.WinSound === 'custom' && gamesSetting.CustomWinSound) {
            this.audioWinSound = new Audio(gamesSetting.CustomWinSound);
        }

        if (gamesSetting.GameOverSound && gamesSetting.GameOverSound !== 'custom' && gamesSetting.GameOverSound !== 'random' && gamesSetting.GameOverSound !== 'none') {
            this.audioGameOverSound = new Audio(process.env.PUBLIC_URL + gamesSetting.GameOverSound);
        } else if (gamesSetting.GameOverSound === 'custom' && gamesSetting.CustomGameOverSound) {
            this.audioGameOverSound = new Audio(gamesSetting.CustomGameOverSound);
        }

        if (gamesSetting.StartSound && gamesSetting.StartSound !== 'custom' && gamesSetting.StartSound !== 'random' && gamesSetting.StartSound !== 'none') {
            this.audioStartSound = new Audio(process.env.PUBLIC_URL + gamesSetting.StartSound);
        } else if (gamesSetting.StartSound === 'custom' && gamesSetting.CustomStartSound) {
            this.audioStartSound = new Audio(gamesSetting.CustomStartSound);
        }

        if (gamesSetting.StageSound && gamesSetting.StageSound !== 'custom' && gamesSetting.StageSound !== 'random' && gamesSetting.StageSound !== 'none') {
            this.audioStageSound = new Audio(process.env.PUBLIC_URL + gamesSetting.StageSound);
        } else if (gamesSetting.StageSound === 'custom' && gamesSetting.CustomStageSound) {
            this.audioStageSound = new Audio(gamesSetting.CustomStageSound);
        }

        if (gamesSetting.BonusTimeSound && gamesSetting.BonusTimeSound !== 'custom' && gamesSetting.BonusTimeSound !== 'random' && gamesSetting.BonusTimeSound !== 'none') {
            this.audioBonusTimeSound = new Audio(process.env.PUBLIC_URL + gamesSetting.BonusTimeSound);
        } else if (gamesSetting.BonusTimeSound === 'custom' && gamesSetting.CustomBonusTimeSound) {
            this.audioBonusTimeSound = new Audio(gamesSetting.CustomBonusTimeSound);
        }
    }

    getRandomNumber = (count, max, type) => {
        if (type === "top") {
            const length = this.randomListTop.length;
            if (length === count) {
                this.randomListTop.shift();
            }
            const number = this.generateNumberTop(max);
            this.randomListTop.push(number);
            return number;
        } else {
            const length = this.randomListLeft.length;
            if (length === count) {
                this.randomListLeft.shift();
            }
            const number = this.generateNumberLeft(max);
            this.randomListLeft.push(number);
            return number;
        }

    }

    generateNumberTop = max => {
        let number = Math.floor(Math.random() * max);
        var inRange = this.randomListTop.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * max);
            inRange = this.randomListTop.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (this.randomListTop.length > 0) {
            const lastItem = this.randomListTop[this.randomListTop.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (this.randomListTop.length > 1) {
                const beforeItem = this.randomListTop[this.randomListTop.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
        }
        return number;
    }

    generateNumberLeft = max => {
        let number = Math.floor(Math.random() * max);
        var inRange = this.randomListLeft.some(p => p > number - 10 && p < number + 10);
        if (!inRange) {
            return number;
        } else {
            number = Math.floor(Math.random() * max);
            inRange = this.randomListLeft.some(p => p > number - 10 && p < number + 10);
            if (!inRange) {
                return number;
            }
        }
        if (this.randomListLeft.length > 0) {
            const lastItem = this.randomListLeft[this.randomListLeft.length - 1];
            if (Math.abs(lastItem - number) < 10) {
                number = (lastItem + 10) % 100;
            }
            if (this.randomListLeft.length > 1) {
                const beforeItem = this.randomListLeft[this.randomListLeft.length - 2];
                if (Math.abs(beforeItem - number) < 10) {
                    number = (beforeItem + 10) % 100;
                }
            }
        }
        return number;
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (pauseTimer) {
            clearInterval(pauseTimer);
        }
        this.stopSound();
    }

    stopSound = () => {
        if (this.state.isPlayVolumeSound) {
            if (this.audioBonusTimeSound) {
                if (this.audioBonusTimeSound.paused === false) {
                    this.audioBonusTimeSound.pause();
                }
            }
            if (this.audioStageSound) {
                if (this.audioStageSound.paused === false) {
                    this.audioStageSound.pause();
                }
            }
            if (this.audioTrueSound) {
                if (this.audioTrueSound.paused === false) {
                    this.audioTrueSound.pause();
                }
            }
            if (this.audioGameOverSound) {
                if (this.audioGameOverSound.paused === false) {
                    this.audioGameOverSound.pause();
                }
            }
            if (this.audioWinSound) {
                if (this.audioWinSound.paused === false) {
                    this.audioWinSound.pause();
                }
            }
            if (this.audioBouns) {
                if (this.audioBouns.paused === false) {
                    this.audioBouns.pause();
                }
            }
            if (this.audioFalse) {
                if (this.audioFalse.paused === false) {
                    this.audioFalse.pause();
                }
            }
            if (this.audioStartSound) {
                if (this.audioStartSound.paused === false) {
                    this.audioStartSound.pause();
                }
            }
        }
    }

    componentDidMount() {
        const TryCount = parseInt(this.props.Question.CustomStyle.gamesSetting.TryCount);
        this.setState({
            TryCount
        });
        this.initGameSound(this.props.Question.CustomStyle.gamesSetting);
    }

    playStageSound = () => {
        if (this.state.isPlayVolumeSound) {
            if (this.audioStageSound) {
                if (this.audioStageSound.paused === false) {
                    this.audioStageSound.pause();
                }
                this.audioStageSound.currentTime = 0;
                this.audioStageSound.play();
            }
        }
    }

    addFalseTry = (hasSound, CustomSound) => {
        if (hasSound) {
            this.playFalseTrySound(CustomSound);
        }
        if (this.state.falseTry + 1 === (this.state.TryCount + this.state.BounsTry)) {
            this.EndGame();
        }
        this.setState({
            falseTry: this.state.falseTry + 1,
            currentStatus: "Wrong"
        });
    }

    playFalseTrySound = CustomSound => {
        if (this.state.isPlayVolumeSound) {
            if (CustomSound) {
                var customAudio = new Audio(CustomSound);
                customAudio.currentTime = 0;
                customAudio.play();
            } else if (this.audioFalse) {
                if (this.audioFalse.paused === false) {
                    this.audioFalse.pause();
                }
                this.audioFalse.currentTime = 0;
                this.audioFalse.play();
            }
        }
    }

    addTrueTry = (hasSound, CustomSound) => {
        this.setState({
            trueTry: this.state.trueTry + 1,
            currentStatus: "True"
        });
        if (hasSound) {
            this.playTrueTrySound(CustomSound);
        }
    }

    playTrueTrySound = CustomSound => {
        if (this.state.isPlayVolumeSound) {
            if (CustomSound) {
                var customAudio = new Audio(CustomSound);
                customAudio.currentTime = 0;
                customAudio.play();
            } else if (this.audioTrueSound) {
                if (this.audioTrueSound.paused === false) {
                    this.audioTrueSound.pause();
                }
                this.audioTrueSound.currentTime = 0;
                this.audioTrueSound.play();
            }
        }
    }

    playBounsTimeSound = CustomSound => {
        if (this.state.isPlayVolumeSound) {
            if (CustomSound) {
                var customAudio = new Audio(CustomSound);
                customAudio.currentTime = 0;
                customAudio.play();
            } else if (this.audioBonusTimeSound) {
                if (this.audioBonusTimeSound.paused === false) {
                    this.audioBonusTimeSound.pause();
                }
                this.audioBonusTimeSound.currentTime = 0;
                this.audioBonusTimeSound.play();
            } else {
                this.playTrueTrySound();
            }
        }
    }

    playBounsSound = CustomSound => {
        if (this.state.isPlayVolumeSound) {
            if (CustomSound) {
                var customAudio = new Audio(CustomSound);
                customAudio.currentTime = 0;
                customAudio.play();
            } else if (this.audioBouns) {
                if (this.audioBouns.paused === false) {
                    this.audioBouns.pause();
                }
                this.audioBouns.currentTime = 0;
                this.audioBouns.play();
            } else {
                this.playTrueTrySound();
            }
        }
    }

    addBounsTimeAndTry = (BounsTime, BounsTry, hasSound, CustomSound) => {
        this.setState({
            trueTry: this.state.trueTry + 1,
            BounsTry: this.state.BounsTry + BounsTry,
            BounsTime: this.state.BounsTime + BounsTime,
            currentStatus: "True"
        });
        if (hasSound) {
            this.playBounsSound(CustomSound);
            this.playBounsTimeSound(CustomSound);
        }
    }

    addBounsTry = (BounsTry, hasSound, CustomSound) => {
        this.setState({
            trueTry: this.state.trueTry + 1,
            BounsTry: this.state.BounsTry + BounsTry,
            currentStatus: "True"
        });
        if (hasSound) {
            this.playBounsSound(CustomSound);
        }
    }

    addBounsTime = (BounsTime, hasSound, CustomSound) => {
        this.setState({
            trueTry: this.state.trueTry + 1,
            BounsTime: this.state.BounsTime + BounsTime,
            currentStatus: "True"
        });
        if (hasSound) {
            this.playBounsTimeSound(CustomSound);
        }
    }

    showEndGame = () => {
        const userMark = this.getMark();
        if (userMark < (this.props.Question.Mark / 2)) {
            this.EndGame();
        } else {
            this.PassGame();
        }
    }

    PassGame = () => {
        this.saveScore();
        this.setState({
            GameOver: false,
            isEnded: true
        }, () => this.saveMark());
        if (this.state.isPlayVolumeSound) {
            if (this.audioWinSound) {
                this.stopSound();
                this.audioWinSound.play();
            }
        }
        clearInterval(this.timer);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.Question.Id !== state.Id) {
            const TryCount = parseInt(props.Question.CustomStyle.gamesSetting.TryCount);
            state = {
                ...state,
                Id: props.Question.Id,
                isStart: false,
                showGame: false,
                isEnded: false,
                showCountDown: false,
                startTime: true,
                GameOver: false,
                TimesUp: false,
                pause: false,
                isPlayVolumeSound: true,
                hideButton: true,
                falseTry: 0,
                trueTry: 0,
                TryCount: TryCount,
                countDown: 3,
                BounsTry: 0,
                BounsTime: 0,
                userAnswers: [],
                currentStatus: "",
                score: 0
            };
            state.initGameSound(props.Question.CustomStyle.gamesSetting);
        }
        if (props.pause === false) {
            state.pause = false;
            state.startTime = true;
            clearInterval(pauseTimer);
        }
        return state;
    }

    goNext = () => {
        this.stopSound();
        this.props.goNext();
    }

    reStartGame = () => {
        const TryCount = parseInt(this.props.Question.CustomStyle.gamesSetting.TryCount);
        this.yourTime = 0;
        this.duration = 0;
        this.BounsTime = 0;
        this.setState({
            countDown: 3,
            falseTry: 0,
            BounsTry: 0,
            BounsTime: 0,
            TryCount,
            trueTry: 0,
            isStart: false,
            showCountDown: false,
            showGame: false,
            pause: false,
            TimesUp: false,
            isEnded: false,
            GameOver: false,
            hideButton: true
        });
        clearInterval(this.timer);
    }

    playVolumeSound = isPlayVolumeSound => {
        this.setState({
            isPlayVolumeSound
        });
    }

    saveAnswer = userAnswers => {
        this.setState({
            userAnswers
        });
    }

    saveMark = () => {
        if (this.props.onEdit !== true) {
            const { t, lesson, Question } = this.props;
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i>
                    {t('Processing')}
                </div>,
                autoClose: false,
                closeOnClick: false,
            });

            try {
                const userMark = this.getMark();
                var postData = {
                    Score: this.state.score,
                    Mark: userMark,
                    IsPassed: !this.state.GameOver && (userMark >= (Question.Mark / 2)),
                    Star: this.state.GameOver ? 0 : parseInt(userMark / Question.Mark * 100 / 30),
                    LessonId: this.props.LessonId,
                    LessonQuestionId: Question.QuestionLessonId,
                    Time: this.yourTime,
                    IsTimesUp: this.state.TimesUp,
                };
                let apiURL = `/GameScores/`;
                let method = api.post;
                if (this.props.isMission === true) {
                    postData = {
                        ...postData,
                        QuestionId: Question.Id,
                        Answer: this.state.userAnswers
                    };
                    apiURL = `/MyMissions/${this.props.MissionId}`;
                    method = api.put;
                }
                method(apiURL, postData).then(response => {
                    if (this.state.GameOver) {
                        if (this.state.TimesUp) {
                            toast.update(_toastId, {
                                render: () => <div>
                                    <div className="h5">
                                        <i className="icon fa-regular fa-timer"></i>
                                        {t('Pages.Games.toast.FailedToPassLevelTimeUp.Title')}</div>
                                    <div className="h6">
                                        {t('Pages.Games.toast.FailedToPassLevelTimeUp.Body')}
                                    </div>
                                </div>,
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        } else {
                            toast.update(_toastId, {
                                render: () => <div>
                                    <div className="h5">
                                        <i className="icon fal fa-bone-break"></i>
                                        {t('Pages.Games.toast.FailedToPassLevel.Title')}</div>
                                    <div className="h6">
                                        {t('Pages.Games.toast.FailedToPassLevel.Body')}
                                    </div>
                                </div>,
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }
                    } else if (userMark < (Question.Mark / 2)) {
                        toast.update(_toastId, {
                            render: () => <div>
                                <div className="h5">
                                    <i className="icon fal fa-bone-break"></i>
                                    {t('Pages.Games.toast.FailedToPassLevel.Title')}</div>
                                <div className="h6">
                                    {t('Pages.Games.toast.FailedToPassLevel.Body')}
                                </div>
                            </div>,
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else if (response.data.isSave === true) {
                        toast.update(_toastId, {
                            render: () => <div>
                                <div className="h5">
                                    <i className="icon fal fa-award"></i>
                                    {t('Pages.Games.toast.CompleteLevel.Title')}</div>
                                <div className="h6">
                                    {t('Pages.Games.toast.CompleteLevel.Body')}
                                </div>
                            </div>,
                            type: toast.TYPE.INFO,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    } else {
                        toast.update(_toastId, {
                            render: () => <div>
                                <div className="h5">
                                    <i className="icon fal fa-bomb"></i>
                                    {t('Pages.Games.toast.FailedToSaveLevel.Title')}</div>
                                <div className="h6">
                                    {t('Pages.Games.toast.FailedToSaveLevel.' + response.data.code)}
                                </div>
                            </div>,
                            type: toast.TYPE.ERROR,
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                    if (this.state.GameOver || (userMark < (Question.Mark / 2)) || this.state.TimesUp) {
                        if (lesson && lesson.scores && lesson.scores.Levels) {
                            const level = lesson.scores.Levels.filter(p => Question.QuestionLessonId === p.LessonQuestionId || Question.Id === p.QuestionId)[0];
                            if (level) {
                                if (Question.Position && level.TryNumber >= Question.Mark) {
                                    toast.update(_toastId, {
                                        render: () => <div>
                                            <div className="h5">
                                                <i className="icon fa-regular fa-bone-break"></i>
                                                {t('Pages.Games.toast.FailedToPassLevelCanContinue.Title')}</div>
                                            <div className="h6">
                                                {t('Pages.Games.toast.FailedToPassLevelCanContinue.Body')}
                                            </div>
                                        </div>,
                                        type: toast.TYPE.WARNING,
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                    });
                                }
                            }
                        }
                    }
                    this.props.updateGameScore();
                    this.setState({
                        hideButton: false
                    });
                    UpdateGameScoreBySocket(this.props.user.Id);
                }).catch(error => {
                    toast.update(_toastId, {
                        render: () => <div>
                            <div className="h5">
                                <i className="icon fal fa-wifi-slash"></i>
                                {t('Error.NoConnection.Title')}
                            </div>
                            <div className="h6">
                                {t('Error.NoConnection.Body')}
                            </div>
                        </div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

            } catch {
                toast.update(_toastId, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-bugs"></i>
                            {t('Error.AnKnown.Title')}
                        </div>
                        <div className="h6">
                            {t('Error.AnKnown.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            this.setState({
                hideButton: false
            });
        }
    }

    EndGame = () => {
        this.saveScore();
        this.setState({
            isEnded: true,
            GameOver: true
        }, () => this.saveMark());
        if (this.state.isPlayVolumeSound) {
            if (this.audioGameOverSound) {
                this.stopSound();
                this.audioGameOverSound.play();
            }
        }
        clearInterval(this.timer);
    }

    timesUp = () => {
        this.setState({
            TimesUp: true
        });
        this.EndGame();
    }

    startGame = () => {
        this.duration = parseInt(this.props.Question.CustomStyle.gamesSetting.Time) + 3;

        this.setState({
            showCountDown: true
        });

        if (this.state.isPlayVolumeSound) {
            if (this.audioStartSound) {
                if (this.audioStartSound.paused === false) {
                    this.audioStartSound.pause();
                }
                this.audioStartSound.currentTime = 0;
                this.audioStartSound.play();
            }
        }

        this.timer = setInterval(() => {
            this.duration -= 1;
            this.yourTime += 1;
            if (this.duration + this.state.BounsTime === 0) {
                this.timesUp();
            }
            if (this.state.countDown > -1) {
                this.setState({
                    countDown: this.state.countDown - 1
                });
            }
            if (this.state.countDown === 0) {
                this.setState({
                    showGame: true
                });
            }
        }, 1000);

        setTimeout(() => {
            this.setState({
                isStart: true
            });
        }, 3000);
    }

    renderPalyerGameOver() {
        const { Question } = this.props;

        if (Question.CustomStyle.gamesSetting.ConfettiFalse) {
            let src = "";
            if (Question.CustomStyle.gamesSetting.ConfettiFalse !== 'custom' && Question.CustomStyle.gamesSetting.ConfettiFalse !== 'random' && Question.CustomStyle.gamesSetting.ConfettiFalse !== 'none') {
                src = process.env.PUBLIC_URL + Question.CustomStyle.gamesSetting.ConfettiFalse;
            } else if (Question.CustomStyle.gamesSetting.ConfettiFalse === 'custom' && Question.CustomStyle.gamesSetting.CustomConfettiFalse) {
                src = Question.CustomStyle.gamesSetting.CustomConfettiFalse;
            }
            if (src) {
                return (
                    <div className="position-fixed w-100 h-100" style={{
                        pointerEvents: 'none',
                        top: '50%',
                        left: '50%',
                        zIndex: -1,
                        transform: 'translate(-50%, -50%) scale(1)'
                    }}>
                        <Player
                            autoplay
                            loop={true}
                            src={src}
                            style={{ height: '50%', width: '70%' }}   ></Player>
                    </div>
                );
            }
        }
    }

    renderPalyerWinGame() {
        const { Question } = this.props;

        if (Question.CustomStyle.gamesSetting.Confetti) {
            let src = "";
            if (Question.CustomStyle.gamesSetting.Confetti !== 'custom' && Question.CustomStyle.gamesSetting.Confetti !== 'random' && Question.CustomStyle.gamesSetting.Confetti !== 'none') {
                src = process.env.PUBLIC_URL + Question.CustomStyle.gamesSetting.Confetti;
            } else if (Question.CustomStyle.gamesSetting.Confetti === 'custom' && Question.CustomStyle.gamesSetting.CustomConfetti) {
                src = Question.CustomStyle.gamesSetting.CustomConfetti;
            }
            if (src) {
                return (
                    <div className="position-fixed w-100 h-100" style={{
                        pointerEvents: 'none',
                        top: '50%',
                        left: '50%',
                        zIndex: -1,
                        transform: 'translate(-50%, -50%) scale(1)'
                    }}>
                        <Player
                            autoplay
                            loop={true}
                            src={src}
                            style={{ height: '50%', width: '70%' }}   ></Player>
                    </div>
                );
            }
        }
    }

    goBack = () => {
        this.setState({
            pause: true,
            startTime: false
        });
        pauseTimer = setInterval(() => {
            this.StopTime += 1;
            this.duration += 1;
            this.yourTime -= 1;
        }, 1000);
        this.props.goBack();
    }

    showInfo = () => {
        this.setState({
            pause: true,
            startTime: false
        });
        pauseTimer = setInterval(() => {
            this.StopTime += 1;
            this.duration += 1;
            this.yourTime -= 1;
        }, 1000);
        this.props.showInfo();
    }

    getMark() {
        const { Question } = this.props;
        const mark = Question.Mark;
        let trueAnswers = 0;
        var answers = Question.Answers;
        answers.forEach(answer => {
            if (Question.Type.Id === 20) {
                const hasFill = Question.Matches.some(p => p.Target === answer.Id);
                if (hasFill) {
                    trueAnswers++;
                }
            } else {
                const CustomStyle = answer.CustomStyle;
                if (CustomStyle && CustomStyle.Answers) {
                    CustomStyle.Answers.filter(p => p.Id === answer.Id).forEach(param => {
                        if (param) {
                            if (param.AnswersSelectType !== "radio" && /\d/.test(param.AnswersSelectType) && param.AnswersSelectType !== "0") {
                                trueAnswers++;
                            }
                        }
                    });
                }
            }
        });
        if ((this.state.trueTry + this.state.falseTry) > trueAnswers) {
            return mark * (this.state.trueTry - (this.state.falseTry / trueAnswers)) / trueAnswers;
        }
        return mark * this.state.trueTry / trueAnswers;
    }

    saveScore = () => {
        const { Question } = this.props;
        let TryCount = 0;
        const bouns = parseInt(Question.CustomStyle.gamesSetting.TryCount) + this.state.BounsTry - this.state.falseTry;
        if (bouns > 0) {
            TryCount = bouns;
        }
        let trys = this.state.trueTry + TryCount;
        let Score = Math.round(trys / Question.Mark * 100, 2) * 100;

        let Time = parseInt(Question.CustomStyle.gamesSetting.Time) + this.state.BounsTime;
        if (this.StopTime) {
            Time += this.StopTime;
        }

        const bounsTime = Time - this.yourTime + 3;
        if (Score > 0 && bounsTime > 0) {
            Score = Score + bounsTime;
        }
        this.setState({
            score: Score
        });
    }

    renderMoveStyle = currentMovment => {
        let result = "";
        if (currentMovment) {
            currentMovment.sort((a, b) => a.Index - b.Index).forEach(item =>
                result += item.Index + "% {visibility:visible; transform-origin:100%; transform:  scale(" + item.AnswersScale + ") rotate(" + item.AnswersRotate + "deg); opacity:" + item.AnswersOpacity / 100 + `; ${item.AnswersTranslatePositionY === "bottom" ? "bottom" : "top"} :  ` + item.AnswersTranslateY + `%; ${item.AnswersTranslatePositionX === "left" ? "left" : "right"} :` + item.AnswersTranslateX + "% }"
            );
        }
        return result;
    }

    renderBackgroundGameObject = () => {
        const { Question } = this.props;

        const BackgroundGameObject = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.BackgroundGameObject : "";
        if (BackgroundGameObject) {
            return BackgroundGameObject.map((item, index) => {

                const Background = item.Background;
                if (Background) {
                    return <div key={`objectMovment-${Question.Id}-${index}`} className="w-100 h-100 position-absolute"><Backgrounds CustomBackground={Background} absolute={false} /></div>;
                }

                return <div key={`objectMovment-${Question.Id}-${index}`} className={`position-absolute objectMovment-${Question.Id}-${index}`} style={{
                    visibility: 'hidden',
                    animationDelay: `${item.delay}s`,
                    animationName: `objectMovment-${Question.Id}-${index} `,
                    animationIterationCount: 'infinite',
                    animationDuration: `${item.Time}s`,
                    animationFillMode: 'forwards',
                    animationTimingFunction: 'linear'
                }}>
                    <style type="text/css" dangerouslySetInnerHTML={
                        {
                            __html: `
                            @keyframes objectMovment-${Question.Id}-${index} { ${this.renderMoveStyle(item.Movments)} }`
                        }
                    }></style>
                    <GameObjects
                        Time={item.Time}
                        status={this.state.currentStatus}
                        FinishMove={this.FinishMove}
                        rotate={item.rotate}
                        isSpin={item.isSpin}
                        flipX={item.flipX}
                        scale={item.scale}
                        ObjectSrc={item.ObjectSrc}
                        ObjectHoverSrc={item.ObjectHoverSrc}
                        customColor={item.CustomColor}
                        transformOriginX={item.transformOriginX}
                        transformOriginY={item.transformOriginY}
                        objectCss={item.MoveObject !== "" ? item.MoveObject : "SpaceShip"}
                    />
                </div>
            });
        }

        return "";
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { Question } = this.props;

        if (Question &&
            Question.CustomStyle &&
            Question.CustomStyle.gamesSetting) {
            let title = Question.Name;
            let CustomBackground = Question.CustomStyle.gamesSetting.CustomBackground;

            const TryColorCustom = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColorCustom : "";
            const TryColor = Question.CustomStyle.gamesSetting ? Question.CustomStyle.gamesSetting.TryColor : "";

            const userMark = this.getMark();

            let Cursor = "";
            if (Question.CustomStyle.gamesSetting.Cursor !== 'custom' && Question.CustomStyle.gamesSetting.Cursor !== 'none' && Question.CustomStyle.gamesSetting.Cursor) {
                Cursor = process.env.PUBLIC_URL + Question.CustomStyle.gamesSetting.Cursor;
            } else if (Question.CustomStyle.gamesSetting.Cursor === 'custom' && Question.CustomStyle.gamesSetting.CursorCustom) {
                Cursor = Question.CustomStyle.gamesSetting.CursorCustom;
            }
            return <>

                {
                    !this.state.showGame && this.state.showCountDown && this.state.countDown > 0 ?
                        <div className="position-absolute display-1 h1 w-100 h-100 d-flex justify-content-center align-items-center flex-column game-font" style={{
                            zIndex: '15',
                            cursor: `url('${Cursor}') 20 20, auto`,
                            fontSize: '30rem'
                        }}>
                            <span className={`text-${TryColor === "custom" ? "" : TryColor} font-weight-bold`} style={{
                                WebkitTextStroke: "3px #000",
                                color: TryColorCustom
                            }}>
                                {this.state.countDown}
                            </span>
                        </div>
                        : ""
                }
                {
                    title && !this.state.showCountDown && !this.state.isStart ?
                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center " style={{
                            cursor: `url('${Cursor}') 20 20, auto`,
                            zIndex: 20
                        }} >
                            <ResultScreen
                                type="level"
                                Time={Question.CustomStyle.gamesSetting.Time}
                                TimesUp={false}
                                level={this.props.level}
                                lesson={this.props.lesson}
                                text={title}
                                LevelOfGame={Question.Level}
                                item={Question}
                                reStartGame={() => this.startGame()}
                                goBack={() => this.props.goBack()}
                                CustomStyle={this.props.CustomStyle.StartScreen} />
                        </div>
                        : ""
                }
                {
                    this.state.isEnded && this.state.GameOver ?
                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center " style={{
                            cursor: `url('${Cursor}') 20 20, auto`,
                            zIndex: 20
                        }} >
                            <ResultScreen
                                item={Question}
                                lesson={this.props.lesson}
                                type="error"
                                TimesUp={this.state.TimesUp}
                                reStartGame={() => this.reStartGame()}
                                goBack={() => this.props.goBack()}
                                starCount={userMark / Question.Mark * 100 / 30}
                                goNext={() => this.goNext()}
                                Score={this.state.score}
                                hideButton={this.state.hideButton}
                                GameType={this.props.GameType}
                                CustomStyle={this.props.CustomStyle.ErrorScreen} />
                            {this.renderPalyerGameOver()}
                        </div>
                        : ""
                }
                {
                    this.state.isEnded && !this.state.GameOver ?
                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" style={{
                            cursor: `url('${Cursor}') 20 20, auto`,
                            zIndex: 20
                        }} >
                            <ResultScreen
                                type="success"
                                reStartGame={() => this.reStartGame()}
                                goBack={() => this.props.goBack()}
                                goNext={() => this.goNext()}
                                hasNext={this.props.hasNext}
                                starCount={userMark / Question.Mark * 100 / 30}
                                Score={this.state.score}
                                hideButton={this.state.hideButton}
                                CustomStyle={this.props.CustomStyle.WinScreen} />
                            {this.renderPalyerWinGame()}
                        </div>
                        : ""
                }
                {
                    this.state.isStart && !this.state.isEnded ?
                        <>
                            <Tools
                                BounsTry={this.state.BounsTry}
                                BounsTime={this.state.BounsTime}
                                StopTime={this.StopTime}
                                falseTry={this.state.falseTry}
                                goBack={this.goBack}
                                showInfo={this.showInfo}
                                playVolumeSound={this.playVolumeSound}
                                startTime={this.state.startTime}
                                gamesSetting={Question.CustomStyle.gamesSetting}
                                isPlayBackgroundSound={!this.props.isMute} />
                            <SelectGame
                                {...this.props}
                                currentStatus={this.state.currentStatus}
                                saveAnswer={this.saveAnswer}
                                getRandomNumber={this.getRandomNumber}
                                showEndGame={this.showEndGame}
                                addBounsTry={this.addBounsTry}
                                addBounsTime={this.addBounsTime}
                                addTrueTry={this.addTrueTry}
                                addFalseTry={this.addFalseTry}
                                addBounsTimeAndTry={this.addBounsTimeAndTry}
                                playStageSound={this.playStageSound}
                                playBounsSound={this.playBounsSound}
                                playBounsTimeSound={this.playBounsTimeSound}
                                playTrueTrySound={this.playTrueTrySound}
                                playFalseTrySound={this.playFalseTrySound}
                                isPlayVolumeSound={this.state.isPlayVolumeSound} />
                        </> : ""
                }
                {
                    CustomBackground ? <Background CustomBackground={CustomBackground} FinishMove={this.FinishMove} currentStatus={this.state.currentStatus} /> : ""
                }
                {this.renderBackgroundGameObject()}
            </>
        }
        return "";
    }
}

export default withTranslation('common')(GamePlay);
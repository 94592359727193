import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import ScrollArea from "react-scrollbar";
import { Link } from "react-router-dom";

import { getOnilneUser } from '../../../api/socket.io';
import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import configData from "../../Config/countries.json";

getOnilneUser();

const OnlineUser = (props) => {
    const { t, user, socket } = props;
    const [onlineUsers, setOnlineUsers] = useState(0);
    const [search, setSearch] = useState('');
    const [countries, setCountries] = useState(0);

    useEffect(() => {
        setSearch('');
        setCountries(configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        }));

        socket.on("ONLINE_USERS", data => {
            setOnlineUsers(data);
        });

    }, [socket]);

    const renderUsers = () => {
        if (onlineUsers && onlineUsers.length > 0) {
            let filterUsers = onlineUsers.filter(v => v.Name.includes(search));

            return filterUsers.map(item => {
                let Country = "";
                if (item.Country && countries) {
                    Country = countries.filter(p => p.value === item.Country)[0];
                }
                return <div className="col px-0 mx-0" key={item.Id}  >
                    <div className="product-list-item" >
                        <div className="product-thumb">
                            {
                                item.Photo ?
                                    <LazyLoadImage src={item.Photo} alt={`${item.Name}`} />
                                    :
                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                            }
                        </div>
                        <div className="product-content py-3">
                            <h5 className="title">{item.Name} </h5>
                            <p>
                                {
                                    item.Birthday ?
                                        <span title={t('Pages.Register.Birthday.title')}>
                                            <i className="icon fal fa-calendar-alt"></i>
                                            {item.Birthday}
                                        </span>
                                        : ""
                                }
                                {
                                    item.Sex ?
                                        <span className="mx-2" title={t('Pages.Register.Sex.title')} >
                                            <i className={`icon text-info fad fa-${item.Sex}`}></i>
                                            {t('Pages.Register.Sex.' + item.Sex)}
                                        </span>
                                        : ""
                                }
                                {
                                    item.ActivateUntilDate && item.ActivateUntilDate !== "0" ?
                                        <span className="text-success mx-1" title={t('Pages.Profile.ActivateUntilDate.title')} >
                                            <i className="icon fas fa-calendar text-success"></i>
                                            {item.ActivateUntilDate}
                                        </span>
                                        : ""
                                }
                            </p>
                            <p>
                                {
                                    item.Username ?
                                        <span title={t('Pages.Register.Username.title')} >
                                            <i className="icon fal fa-hashtag"></i>
                                            {item.Username}
                                        </span>
                                        : ""
                                }
                                {
                                    item.Email ?
                                        <>
                                            {
                                                item.Username ? " - " : ""
                                            }
                                            <a href={`mailto:${item.Email}`} title={t('Pages.Register.Email.title')}>
                                                <i className="icon fal fa-envelope-open-text"></i>
                                                {item.Email}
                                            </a>
                                        </>
                                        : ""
                                }
                                {
                                    item.Phone ?
                                        <>
                                            {
                                                item.Email ? " - " : ""
                                            }
                                            <a href={`Tel:${item.Phone}`} title={t('Pages.Register.Phone.title')}>
                                                <i className="icon fal fa-phone-alt"></i>
                                                <span dir="ltr">
                                                    {item.Phone}
                                                </span>
                                            </a>
                                        </> : ""
                                }
                            </p>

                            <div className="d-flex justify-content-between ">
                                <p>
                                    {
                                        Country ?
                                            <span title={t('Pages.Register.Country.title')} >
                                                {Country.label}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Address ?
                                            <span title={t('Pages.Register.Address.title')} >
                                                {
                                                    Country ? " - " : ""
                                                }
                                                {item.Address}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.School ?
                                            <>
                                                <br />
                                                <span className="mt-2">
                                                    <span title={t('Pages.Register.SchoolName.title')}>
                                                        <i className="icon fal fa-school"></i>
                                                        {item.School.Name + " - "}
                                                        <span className="text-danger" >
                                                            {
                                                                item.UserType ?
                                                                    <>
                                                                        {item.UserType.Name}
                                                                    </>
                                                                    : ""
                                                            }
                                                        </span>
                                                    </span>
                                                </span>
                                            </>
                                            : ""
                                    }
                                </p>

                                <div className="d-flex align-items-center justify-content-center flex-wrap">
                                    {
                                        item.IsTeacher ?
                                            <div className="d-flex align-items-center mr-1" >
                                                <span className="cate btn btn-light " title={t("Pages.UsersControl.IsTeacher")}>
                                                    <i className="fad fa-chalkboard-teacher text-primary"></i>
                                                </span>
                                            </div> : ""
                                    }
                                    {
                                        item.title && (
                                            item.location.toLowerCase().includes('LessonView'.toLowerCase()) ||
                                            item.location.toLowerCase().includes('ExamView'.toLowerCase()) ||
                                            item.location.toLowerCase().includes('MissionView'.toLowerCase())) ?
                                            <Link to={item.location} target="_blank" className="d-flex align-items-center mr-1" >
                                                <span className="cate btn btn-dark" title={item.title}>
                                                    <i className="fas fa-eye "></i>
                                                </span>
                                            </Link> : ""
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
            );
        }
        return <div className="product-list-item">
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-wifi-slash "></i>
                </div>
                <h4 className="display-3 subtitle">
                    {t("Pages.OnlineUser.no_user")}
                </h4>
            </div>
        </div>;
    }

    return <>
        <Helmet>
            <title>{t("Pages.UserControl.OnlineUser")} | {t('alarabeyya')}</title>
        </Helmet>
        <section className="pos-rel padding-top oh">
            <div className="top-shape-center">
                <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
            </div>
            <div className="bottom-shape-left">
                <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
            </div>
            <div className="container">
                <div className="row ">
                    <div className="col-lg-8 mb-50">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-header">
                                    <h3 className="title"> {t('Pages.UserControl.OnlineUser')} </h3>
                                </div>
                            </div>
                        </div>
                        <ScrollArea
                            horizontal={false}
                            className={`area w-100 lazyLoading  p-2 `}
                            smoothScrolling={true}
                            contentClassName="content "
                            style={{
                                height: '100vh',
                                background: '#fafafa',
                                borderRadius: '10px'
                            }}
                        >
                            {renderUsers()}
                        </ScrollArea>
                    </div>
                    <div className="col-lg-4 mb-50">
                        <aside>
                            <UserSubMenu user={user}></UserSubMenu>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </>;
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(OnlineUser));
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactHtml from 'raw-html-react';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import Background from "../Background/";
import GameObjects from "../GameObjects/";
import Tools from "./Tools";
import DropableGameFiled from '../FillFiled/DropableGameFiled';
import MoveBackgroundObject from "./MoveBackground";

class GameFillView extends React.Component {

    state = {
        categories: [],
        CustomStyle: configData.CustomStyle
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.categories = props.categories;
        return state;
    }

    renderDropableFiled(item, style, hoverStyle) {
        const count = item.Name.split("(_)").length - 1;
        var html = item.Name;
        for (let index = 0; index < count; index++) {
            html = html.replace("(_)", `<span version='${this.props.version}' Sort='${index + 1}' data-react-component="DropableGameFiled"></span>`);
        }
        return <ReactHtml
            html={html}
            style={style}
            onEdit={true}
            answers={this.state.answers}
            componentMap={{ DropableGameFiled }}
            hoverStyle={hoverStyle}
            CategoryId={item.Id}
            QuestionId={this.props.Id}
            setDropTargetAnswer={this.setDropTargetAnswer}
        />;
    }

    setDropTargetAnswer = () => { }

    render() {
        const { t, ViewGameResult, examAnswers, categories, answers, fills } = this.props;

        let styleAnswer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: `${this.state.CustomStyle.answerStyle.width}px`,
            minHeight: `${this.state.CustomStyle.answerStyle.height}px`,
            paddingLeft: `${this.state.CustomStyle.answerStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerStyle.BorderRadiusTop}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusRight}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusBottom}${this.state.CustomStyle.answerStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerStyle.BorderRadiusLeft}${this.state.CustomStyle.answerStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerStyle.ShadowPositionX}px ${this.state.CustomStyle.answerStyle.ShadowPositionY}px ${this.state.CustomStyle.answerStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.answerStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerStyle.BgAttachment}`,
        };

        let styleBox = {
            minWidth: `${this.state.CustomStyle.answerBoxStyle.width}%`,
            maxWidth: '100%',
            minHeight: `${this.state.CustomStyle.answerBoxStyle.height}px`,
            paddingLeft: `${this.state.CustomStyle.answerBoxStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.answerBoxStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.answerBoxStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.answerBoxStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.answerBoxStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.answerBoxStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.answerBoxStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.answerBoxStyle.BorderRadiusTop}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusRight}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusBottom}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit} ${this.state.CustomStyle.answerBoxStyle.BorderRadiusLeft}${this.state.CustomStyle.answerBoxStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.answerBoxStyle.ShadowPositionX}px ${this.state.CustomStyle.answerBoxStyle.ShadowPositionY}px ${this.state.CustomStyle.answerBoxStyle.ShadowRadius}px 0px ${this.state.CustomStyle.answerBoxStyle.shadowColor}`,
            opacity: `${this.state.CustomStyle.answerBoxStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.answerBoxStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.answerBoxStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.answerBoxStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.answerBoxStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.answerBoxStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.answerBoxStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.answerBoxStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.answerBoxStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.answerBoxStyle.BgAttachment}`,
        };

        let align = "x";
        if (this.state.CustomStyle.answerBoxStyle.AnswersAlign) {
            if (this.state.CustomStyle.answerBoxStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.answerBoxStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        const CustomBackground = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.CustomBackground : "";

        const MoveObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObject : "";
        const MoveObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrc : "";
        const MoveObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveObjectSrcHover : "";
        const MoveCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.MoveCustomColor : "";
        const AnswerOrder = this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswerOrder : "";
        const AnswersColsMargin = this.state.CustomStyle.answerBoxStyle ? this.state.CustomStyle.answerBoxStyle.AnswersColsMargin : "";


        const DropObject = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObject : "";
        const DropObjectSrc = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObjectSrc : "";
        const DropObjectSrcHover = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropObjectSrcHover : "";
        const DropCustomColor = this.state.CustomStyle.gamesSetting ? this.state.CustomStyle.gamesSetting.DropCustomColor : "";


        let Cursor = "";
        if (this.state.CustomStyle.gamesSetting.Cursor !== 'custom' && this.state.CustomStyle.gamesSetting.Cursor !== 'none' && this.state.CustomStyle.gamesSetting.Cursor) {
            Cursor = process.env.PUBLIC_URL + this.state.CustomStyle.gamesSetting.Cursor;
        } else if (this.state.CustomStyle.gamesSetting.Cursor === 'custom' && this.state.CustomStyle.gamesSetting.CursorCustom) {
            Cursor = this.state.CustomStyle.gamesSetting.CursorCustom;
        }

        let styleBackground = {
            height: '500px',
            opacity: `${this.state.CustomStyle.gamesSetting.Opacity}%`,
            backgroundColor: this.state.CustomStyle.gamesSetting.backgroundColor !== 'transparent' ? this.state.CustomStyle.gamesSetting.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.gamesSetting.backgroundImage !== '' ? `url('${this.state.CustomStyle.gamesSetting.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.gamesSetting.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.gamesSetting.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.gamesSetting.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.gamesSetting.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.gamesSetting.BgAttachment}`,
            cursor: `url('${Cursor}') 20 20, auto`
        };

        let item = {
            Id: 0,
            Name: t("Pages.Games.Question")
        };
        if (this.state.categories && this.state.categories.length > 1) {
            item = this.state.categories[0];
        }

        let style = {
            maxWidth: '100%',
            minWidth: '100px',
            paddingLeft: `${this.state.CustomStyle.categoryListStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.categoryListStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.categoryListStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.categoryListStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.categoryListStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryListStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.categoryListStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.categoryListStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.categoryListStyle.BorderRadiusTop}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryListStyle.BorderRadiusRight}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryListStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryListStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryListStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.categoryListStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryListStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryListStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryListStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.categoryListStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.categoryListStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryListStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.categoryListStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryListStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.categoryListStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.categoryListStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.categoryListStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.categoryListStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.categoryListStyle.BgAttachment}`,
        };

        let hoverStyle = {
            maxWidth: '100%',
            minWidth: '100px',
            paddingLeft: `${this.state.CustomStyle.categoryHoverStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.categoryHoverStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.categoryHoverStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.categoryHoverStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.categoryHoverStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.categoryHoverStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.categoryHoverStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.categoryHoverStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.categoryHoverStyle.BorderRadiusTop}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusRight}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusBottom}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit} ${this.state.CustomStyle.categoryHoverStyle.BorderRadiusLeft}${this.state.CustomStyle.categoryHoverStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.categoryHoverStyle.ShadowPositionX}px ${this.state.CustomStyle.categoryHoverStyle.ShadowPositionY}px ${this.state.CustomStyle.categoryHoverStyle.ShadowRadius}px 0px ${this.state.CustomStyle.categoryHoverStyle.shadowColor}`,

            opacity: `${this.state.CustomStyle.categoryHoverStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.categoryHoverStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.categoryHoverStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.categoryHoverStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.categoryHoverStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.categoryHoverStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.categoryHoverStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.categoryHoverStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.categoryHoverStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.categoryHoverStyle.BgAttachment}`,
        };

        if (ViewGameResult) {
            return <>
                <div className="widget">
                    <div className="widget-body">
                        {
                            categories.sort((a, b) => a.Sort - b.Sort).map(category => {
                                let index = 1;
                                const answerForCategories = fills.filter(p => p.CategoryId === category.Id);
                                return <div className="w-100 position-relative oh mb-3" key={`table-${category.Id}`} style={styleBox}>
                                    <table className="table table-striped table-hover table-bordered bg-white" >
                                        <caption className="text-center pt-3 pb-1" style={{
                                            captionSide: 'top',
                                            color: '#000'
                                        }}><div className="text-center d-flex justify-content-center align-items-center h4 mb-0">{this.renderDropableFiled(category, style, hoverStyle)}</div></caption>
                                        <tbody>
                                            <tr className="text-center">
                                                <th>
                                                    {t('Pages.Missions.table.FillNumber')}
                                                </th>
                                                <th>
                                                    {t('Pages.Missions.table.userAnswer')}
                                                </th>
                                                <th>
                                                    {t('Pages.Missions.table.result')}
                                                </th>
                                            </tr>
                                            {
                                                examAnswers && answerForCategories && examAnswers[0] ?
                                                    examAnswers[0].map(item => {
                                                        if (item && item.Answer) {
                                                            var hasItem = answerForCategories.filter(p => p.AnswerId === item.Answer.Id)[0];
                                                            if (hasItem) {
                                                                const answer = answers.filter(p => p.Id === item.Answer.Id)[0];
                                                                if (answer) {
                                                                    index++;
                                                                    return <tr className="text-center" key={`Answer-${category.Id}-${item.Answer.Id}-${item.Answer.Sort}`}>
                                                                        <td className="text-center">{item.Sort} </td>
                                                                        <td className="text-center" ><div className="text-center d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{
                                                                            __html: answer.Name
                                                                        }}></div></td>
                                                                        <td className="text-center">{item.isTrue ?
                                                                            <i className="fas fa-check-square text-success mx-2" title={t('Pages.Lessons.Questions.forms.trueAnswer')}></i>
                                                                            :
                                                                            <i className="fas fa-times-square text-danger mx-2" title={t('Pages.Lessons.Questions.forms.falseAnswer')}></i>
                                                                        }</td>
                                                                    </tr>;
                                                                }
                                                                return "";
                                                            }
                                                            return "";
                                                        }
                                                        return "";
                                                    })
                                                    :
                                                    <tr>
                                                        <td className="text-center" colSpan={3}>{t('Pages.Missions.table.NoCategoryAnswer')}</td>
                                                    </tr>
                                            }
                                            {
                                                index === 1 ?
                                                    <tr>
                                                        <td className="text-center" colSpan={3}>{t('Pages.Missions.table.NoCategoryAnswer')}</td>
                                                    </tr>
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            })
                        }
                    </div>
                </div >
                <div className="widget">
                    <div className="widget-body">
                        <table className="table table-striped table-hover table-bordered">
                            {this.props.renderFooter()}
                        </table>
                    </div>
                </div >
            </>
        }

        return (
            <div className={`w-100  mx-auto`} style={{
                userSelect: 'none'
            }}>
                {
                    (this.props.updateVersion) ?
                        <i onClick={() => this.props.updateVersion()} title={t('Pages.Lessons.Questions.sync')} className="mx-2 cursor-pointer text-info fad fa-sync"></i> : ""
                }
                <div className="bg-white w-100 position-relative" ref={this.designerContainer}>
                    <div className="w-100 position-relative oh" style={styleBackground} >
                        <style dangerouslySetInnerHTML={{ __html: `.dragdrop-move-box{${this.state.CustomStyle.answerStyle.Custom} } .dragdrop-static-box{${this.state.CustomStyle.answerBoxStyle.Custom}}` }}></style>
                        <Tools
                            BounsTime={0}
                            BounsTry={0}
                            falseTry={0}
                            gamesSetting={this.state.CustomStyle.gamesSetting}></Tools>

                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center flex-column" style={{
                            zIndex: '15'
                        }}>
                            <div className={`h-100 w-100 position-relative d-flex justify-content-around align-items-center flex-column${AnswerOrder === "top" ? "-reverse" : ""}`} >
                                <div className=" w-100 position-relative d-flex justify-content-around align-items-center ">
                                    <div>
                                        <GameObjects
                                            objectCss={MoveObject}
                                            ObjectSrc={MoveObjectSrc}
                                            ObjectHoverSrc={MoveObjectSrcHover}
                                            customColor={MoveCustomColor}
                                            style={styleAnswer}
                                            text={t("Pages.Games.Answer")}
                                            type="dragdrop-move"
                                        ></GameObjects>
                                    </div>
                                    <div>
                                        <GameObjects
                                            objectCss={DropObject}
                                            ObjectSrc={DropObjectSrc}
                                            ObjectHoverSrc={DropObjectSrcHover}
                                            customColor={DropCustomColor}
                                            style={{}}
                                            text={""}
                                            type="dragdrop-static"
                                        ></GameObjects>
                                    </div>
                                </div>

                                <div className={` d-flex justify-content-center align-items-center flex-wrap mx-3 m${align}-auto my-${AnswersColsMargin}`} style={styleBox}>
                                    {this.renderDropableFiled(item, style, hoverStyle)}
                                </div>
                            </div>
                        </div>
                        <Background absolute={true} CustomBackground={CustomBackground} />
                        <MoveBackgroundObject CustomStyle={this.props.CustomStyle} Id={this.props.Id} />
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(GameFillView));
const schoolsReducers = (state = [], action) => {
    switch (action.type) {
        case "SCHOOLS_LOADING":
            return {
                schools: state.schools,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "SCHOOLS_INITIAL":
            return {
                schools: action.schools,
                toRemove: [],
                isLoaded: true
            };
        case "SCHOOLS_ADD_NEW":
            return {
                schools: AddSchoolData(action.school, state.schools),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "SCHOOLS_UPDATE_INFO":
            return {
                schools: UpdateSchoolData(action.school, state.schools, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "SCHOOLS_QUEUE_FOR_REMOVAL":
            return {
                schools: state.schools,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "SCHOOLS_CLEAN_NODES":
            return {
                schools: state.toRemove.some(v => v === action.id) ? state.schools.filter(v => v.Id !== action.id) : state.schools,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "SCHOOLS_UNDO":
            return {
                schools: state.schools,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

function AddSchoolData(newInfo, schools) {
    let copy = [];
    schools.map(school => {
        copy.push({
            ...school
        });
        return '';
    });
    copy.push({
        ...newInfo
    });
    return copy;
}

function UpdateSchoolData(newInfo, schools, id) {
    let copy = [];
    schools.map(school => {
        if (school.Id === id) {
            school = {
                ...school,
                ...newInfo
            };
        }
        copy.push({
            ...school
        });
        return '';
    });
    return copy;
}

export default schoolsReducers;

import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

class ClickFiled extends React.Component {

    state = {
        currentState: 'none',
        answer: '',
        style: '',
        text: '',
        isTrue: 2,
        Sort: 0,
        AnswerId: 0
    };

    constructor(props) {
        super(props);
        this.changeEvent = "";
    }

    static getDerivedStateFromProps(props, state) {
        state.answersList = props.answersList;
        state.answers = props.answers;
        state.fills = props.fills;
        state.CategoryId = props.CategoryId;
        return state;
    }


    componentDidMount() {
        const Sort = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("Sort"));
        const isTrue = parseInt(ReactDOM.findDOMNode(this).parentNode.getAttribute("isTrue"));
        const text = ReactDOM.findDOMNode(this).parentNode.getAttribute("text");
        this.setState({
            Sort,
            isTrue,
            text
        });

        if (this.props.answersList) {
            var answer = this.props.answersList.filter(a => a.Sort === Sort && a.Id === this.props.CategoryId)[0];
            if (answer) {
                this.setState({
                    currentState: answer.SelectType
                });
            }
        }
    }

    changeType = () => {
        if (this.changeEvent) {
            window.clearTimeout(this.changeEvent);
        }
        let SelectType = "none";
        if (this.state.currentState === "none") {
            this.setState({
                currentState: "click"
            });
            SelectType = "click";
        } else if (this.state.currentState === "click" && this.props.twoType) {
            this.setState({
                currentState: "dbclick"
            });
            SelectType = "dbclick";
        } else {
            this.setState({
                currentState: "none"
            });
        }

        this.changeEvent = window.setTimeout(() => {
            this.props.handleAnswerChange(this.props.CategoryId, this.state.Sort, SelectType);
        }, 200);
    }

    render() {

        const { t } = this.props;

        let answerIsTrue = "";
        if (this.state.isTrue === 1) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="mx-1 fad fa-check-circle text-success h6"></i>;
        } else if (this.state.isTrue === 0) {
            answerIsTrue = <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="mx-1 fas fa-exclamation-triangle text-danger h6"></i>;
        }

        let style = {};
        if (this.state.currentState === "click") {
            style = this.props.clickStyle;
        } else if (this.state.currentState === "dbclick") {
            style = this.props.dbclickStyle;
        }

        return (
            <>
                <span
                    className={`cursor-pointer `}
                    style={style}
                    onClick={() => this.changeType()} dangerouslySetInnerHTML={{
                        __html: this.state.text
                    }}></span>
                {answerIsTrue}
            </>
        );
    }
}
export default withTranslation('common')(ClickFiled);
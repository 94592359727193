import React from "react";
import './style.scss';

class BeaconInNight extends React.Component {

    render() {
        return (
            <>

                <div className="BeaconInNight-scene">
                    <div className="BeaconInNight-background">
                        <div className="BeaconInNight-stars">
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                            <div className="BeaconInNight-star"></div>
                        </div>
                        <div className="BeaconInNight-moon"></div>
                        <div className="BeaconInNight-mountains">
                            <div className="BeaconInNight-mountain"> </div>
                            <div className="BeaconInNight-mountain"> </div>
                            <div className="BeaconInNight-mountain"> </div>
                            <div className="BeaconInNight-mountain"> </div>
                        </div>
                        <div className="BeaconInNight-sea">
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-wave"></div>
                            <div className="BeaconInNight-boat">
                                <div className="BeaconInNight-sail"></div>
                                <div className="BeaconInNight-sail"></div>
                                <div className="BeaconInNight-base"></div>
                            </div>
                        </div>
                    </div>
                    <div className="BeaconInNight-lighthouse-group">
                        <div className="BeaconInNight-land"></div>
                        <div className="BeaconInNight-lighthouse-holder">
                            <div className="BeaconInNight-shadow"></div>
                            <div className="BeaconInNight-lighthouse"></div>
                            <div className="BeaconInNight-top">
                                <div className="BeaconInNight-light-container">
                                    <div className="BeaconInNight-light"></div>
                                </div>
                                <div className="BeaconInNight-rail"></div>
                                <div className="BeaconInNight-middle"></div>
                                <div className="BeaconInNight-roof">
                                    <div className="BeaconInNight-roof-light"></div>
                                </div>
                                <div className="BeaconInNight-glow"></div>
                            </div>
                            <div className="BeaconInNight-windows">
                                <div className="BeaconInNight-window"></div>
                                <div className="BeaconInNight-window"></div>
                                <div className="BeaconInNight-window"></div>
                                <div className="BeaconInNight-window"></div>
                            </div>
                            <div className="BeaconInNight-door">
                                <div className="BeaconInNight-stairs"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BeaconInNight;
import api from '../../../../api/api';

export const fetchMissions = (startDate, endDate, OnlyMe) => async dispatch => {
    dispatch({ type: 'MISSIONS_LOADING' });
    const response = await api.get(`/Missions/?startDate=${startDate}&endDate=${endDate}&OnlyMe=${OnlyMe}`);
    dispatch({ type: 'MISSIONS_INITIAL', missions: response.data.data });
}

export const removeMission = (id) => async dispatch => {
    dispatch({ type: 'MISSION_QUEUE_FOR_REMOVAL', id });
}

export const undoDeleteMission = (id) => async dispatch => {
    dispatch({ type: 'MISSION_UNDO', id });
}

export const clearMission = (id) => async dispatch => {
    dispatch({ type: 'MISSION_CLEAN_NODES', id });
}
import React from "react";
import { withTranslation } from 'react-i18next';


class BackgroundGameObjectMoveItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentItem: {
                AnswersTranslateX: "",
                AnswersTranslatePositionX: "right",
                AnswersTranslateY: "",
                AnswersTranslatePositionY: "top",
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentItem) {
            state.currentItem = props.currentItem;
        } else {
            state.currentItem = {
                AnswersTranslateX: "",
                AnswersTranslatePositionX: "right",
                AnswersTranslateY: "",
                AnswersTranslatePositionY: "top",
                AnswersOpacity: 100,
                AnswersScale: 1,
                AnswersRotate: 0,
                AnswersTransformOrigin: "center"
            }
        }
        return state;
    }

    setTranslateX = AnswersTranslateX => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersTranslateX
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersTranslateX
            });
        });
    }

    setTranslatePositionX = AnswersTranslatePositionX => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersTranslatePositionX
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersTranslatePositionX
            });
        });
    }

    setTranslateY = AnswersTranslateY => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersTranslateY
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersTranslateY
            });
        });
    }

    setTranslatePositionY = AnswersTranslatePositionY => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersTranslatePositionY
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersTranslatePositionY
            });
        });
    }

    setOpacity = AnswersOpacity => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersOpacity
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersOpacity
            });
        });
    }

    setScale = AnswersScale => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersScale
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersScale
            });
        });
    }

    setRotate = AnswersRotate => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersRotate
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersRotate
            });
        });
    }

    setTransformOrigin = AnswersTransformOrigin => {
        this.setState({
            currentItem: {
                ...this.state.currentItem,
                AnswersTransformOrigin
            }
        }, () => {
            this.props.setMovmentItem({
                ...this.state.currentItem,
                AnswersTransformOrigin
            });
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="form-group col mb-1">
                    <div className=" row row-cols-2">
                        <div className="form-group col">
                            <label className="text-dark h6"><i className="fal fa-arrows-h"></i> {t('Pages.Lessons.Questions.forms.tools.move.TranslateX')}</label>
                            <div className="search-form">
                                <input
                                    className="w-100 form-control"
                                    type="text"
                                    placeholder={t('Pages.Lessons.Questions.forms.tools.move.TranslateX')}
                                    title={t('Pages.Lessons.Questions.forms.tools.move.TranslateX')}
                                    value={this.state.currentItem.AnswersTranslateX}
                                    onChange={e => this.setTranslateX(e.target.value)}
                                />
                                <button type="button" >
                                    <i className="fas fa-arrows-h"  ></i>
                                </button>
                            </div>
                        </div>
                        <div className="form-group col">
                            <label className="text-dark h6">
                                <i className="icon fas fa-ruler-combined"></i>
                                {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}
                            </label>
                            <div className="search-form">
                                <select
                                    onChange={e => this.setTranslatePositionX(e.target.value)}
                                    className="w-100"
                                    value={this.state.currentItem.AnswersTranslatePositionX}
                                    placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                    title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}>
                                    <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>
                                    <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                </select>
                                <button type="button" >
                                    <i className="fas fa-ruler-combined"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group col mb-1">
                    <div className=" row row-cols-2">
                        <div className="form-group col">
                            <label className="text-dark h6"><i className="fal fa-arrows-v"></i> {t('Pages.Lessons.Questions.forms.tools.move.TranslateY')}</label>
                            <div className="search-form">
                                <input
                                    className="w-100 form-control"
                                    type="text"
                                    placeholder={t('Pages.Lessons.Questions.forms.tools.move.TranslateY')}
                                    title={t('Pages.Lessons.Questions.forms.tools.move.TranslateY')}
                                    value={this.state.currentItem.AnswersTranslateY}
                                    onChange={e => this.setTranslateY(e.target.value)}
                                />
                                <button type="button" >
                                    <i className="fas fa-arrows-v"  ></i>
                                </button>
                            </div>
                        </div>
                        <div className="form-group col">
                            <label className="text-dark h6">
                                <i className="icon fas fa-ruler-combined"></i>
                                {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}
                            </label>
                            <div className="search-form">
                                <select
                                    onChange={e => this.setTranslatePositionY(e.target.value)}
                                    className="w-100"
                                    value={this.state.currentItem.AnswersTranslatePositionY}
                                    placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                    title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}>
                                    <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                    <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                </select>
                                <button type="button" >
                                    <i className="fas fa-ruler-combined"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" row row-cols-4">

                    <div className="form-group col">
                        <label className="text-dark h6"><i className="fal fa-braille"></i> {t('Pages.Lessons.Questions.forms.tools.move.Opacity')}</label>
                        <div className="search-form">
                            <input
                                className="w-100 form-control"
                                type="text"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.move.Opacity')}
                                title={t('Pages.Lessons.Questions.forms.tools.move.Opacity')}
                                value={this.state.currentItem.AnswersOpacity}
                                onChange={e => this.setOpacity(e.target.value)}
                            />
                            <button type="button" >
                                <i className="fas fa-braille"  ></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group col">
                        <label className="text-dark h6"><i className="fal fa-expand-arrows"></i> {t('Pages.Lessons.Questions.forms.tools.move.Scale')}</label>
                        <div className="search-form">
                            <input
                                className="w-100 form-control"
                                type="text"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                title={t('Pages.Lessons.Questions.forms.tools.move.Scale')}
                                value={this.state.currentItem.AnswersScale}
                                onChange={e => this.setScale(e.target.value)}
                            />
                            <button type="button" >
                                <i className="fas fa-expand-arrows"  ></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group col">
                        <label className="text-dark h6"><i className="fal fa-sync-alt"></i> {t('Pages.Lessons.Questions.forms.tools.move.Rotate')}</label>
                        <div className="search-form">
                            <input
                                className="w-100 form-control"
                                type="text"
                                placeholder={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                title={t('Pages.Lessons.Questions.forms.tools.move.Rotate')}
                                value={this.state.currentItem.AnswersRotate}
                                onChange={e => this.setRotate(e.target.value)}
                            />
                            <button type="button" >
                                <i className="fas fa-sync-alt"  ></i>
                            </button>
                        </div>
                    </div>

                    <div className="form-group col">
                        <label className="text-dark h6">
                            <i className="icon fas fa-ruler-combined"></i>
                            {t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}
                        </label>
                        <div className="search-form">
                            <select
                                onChange={e => this.setTransformOrigin(e.target.value)}
                                className="w-100"
                                value={this.state.currentItem.AnswersTransformOrigin}
                                placeholder={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.placeholder')}
                                title={t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.title')}>
                                <option value="center">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.center')}</option>
                                <option value="top">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.top')}</option>
                                <option value="bottom">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.bottom')}</option>
                                <option value="left">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.left')}</option>
                                <option value="right">{t('Pages.Lessons.Questions.forms.tools.move.transformOrigin.right')}</option>

                            </select>
                            <button type="button" >
                                <i className="fas fa-ruler-combined"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default withTranslation('common')(BackgroundGameObjectMoveItem);
import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import ScrollArea from "react-scrollbar";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";

import Footer from "../../Footer/FooterBottom";
import api from "../../../api/api";
import FootballStadium from "../../Admin/Question/View/Background/FootballStadium";
import GameObjects from "../../Admin/Question/View/GameObjects";
import { socket } from '../../../api/socket.io';

class CompetitionProfile extends React.Component {

    state = {
        data: [],
        CurrentUser: {}
    };

    componentDidMount() {
        socket.on("SEND_UPDATE_PAGE", response => {
            if (response.type === 'UPDATE_GAME_SCORE') {
                this.updateData();
            }
        });
        this.updateData();
        window.scrollToPosition("main-game-profile-section");
    }

    updateData = () => {
        // const { user } = this.props;
        // api.get(`/MyCompetition/${user && user.user ? user.user.Id : 0}`).then(response => {
        //     const CurrentUser = response.data.data.filter(p => p.CurrentUser === true)[0];
        //     this.setState({
        //         data: response.data.data
        //         CurrentUser
        //     });
        // });
        api.post(`/MyCompetition/`).then(response => {
            this.setState({
                data: response.data.data
            });
        });
    }

    renderResultRow = () => {
        const { t } = this.props;

        if (this.state.data.Questions && this.state.data.Questions.length && this.state.data.Questions.length > 0) {
            return this.state.data.Questions.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map((item, index) => {
                let starCount = 0;
                let TryNumber = 0;
                let Time = 0;
                let Score = 0;
                let isPlay = false;
                if (this.state.data && this.state.data.Levels) {
                    const level = this.state.data.Levels.filter(p => item.LessonQuestionId === p.LessonQuestionId)[0];
                    if (level) {
                        starCount = level.Star;
                        Time = level.Time;
                        TryNumber = level.TryNumber;
                        Score = level.Score;
                        isPlay = true;
                    }
                }
                const UserTime = this.calcTime(Time);

                if (isPlay) {
                    return <tr>
                        <td className="text-center">{t('Pages.Games.Table.Level')} {index + 1}</td>
                        <td className="text-center" title={t('Pages.Games.Table.Stars')}>
                            {
                                starCount >= 3 ? <i className="text-warning fas fa-star"></i> : <i className="text-secondary fas fa-star"></i>
                            }
                            {
                                starCount >= 2 ? <i className="text-warning fas fa-star"></i> : <i className="text-secondary fas fa-star"></i>
                            }
                            {
                                starCount >= 1 ? <i className="text-warning fas fa-star"></i> : <i className="text-secondary fas fa-star"></i>
                            }
                        </td>
                        <td className="text-center" title={t('Pages.Games.Table.Time')}>{UserTime}</td>
                        <td className="text-center" title={t('Pages.Games.Table.Repate')}>{TryNumber}</td>
                        <td className="text-center" title={t('Pages.Games.Table.Point')}>{Score}</td>
                    </tr>
                } else {
                    return <tr>
                        <td className="text-center">{t('Pages.Games.Table.Level')} {index + 1}</td>
                        <td colSpan={4} className="text-center">
                            {t('Pages.Games.NotPlay')}
                        </td>
                    </tr>
                }

            })
        }

        return "";
    }

    renderRow = () => {
        if (this.state.data && this.state.data.length && this.state.data.length > 0) {
            return this.state.data.sort((a, b) => a.TryNumber > b.TryNumber ? 1 : -1).sort((a, b) => a.Time < b.Time ? 1 : -1).sort((a, b) => a.Star < b.Star ? 1 : -1).map((item, index) => {
                const Time = this.calcTime(item.Time);
                return <tr>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.User.Name}</td>
                    <td>{item.User.SchoolName}</td>
                    <td className="text-center">{item.Star}</td>
                    <td className="text-center">{Time}</td>
                    <td className="text-center">{item.TryNumber}</td>
                </tr>
            })
        }
        return "";
    }

    calcTime = Time => {
        let result = "";
        if (Time >= 3600) {
            result = (parseInt(Time / 3600) > 9 ? parseInt(Time / 3600) : "0" + parseInt(Time / 3600)) + ":";
            Time = Time % 3600;
        } else {
            result += "00:";
        }

        if (Time >= 60) {
            result += (parseInt(Time / 60) > 9 ? parseInt(Time / 60) : "0" + parseInt(Time / 60)) + ":";
            Time = Time % 60;
        } else {
            result += "00:";
        }

        if (Time > 0) {
            result += parseInt(Time) > 9 ? parseInt(Time) : "0" + parseInt(Time);
        } else {
            result += "00";
        }
        return result;
    }

    renderLevel = () => {
        let Level = 0;
        let prefix = this.props.user && this.props.user.user && this.props.user.user.Sex === "female" ? "Girl" : "";
        if (this.state.data && this.state.data.Star) {
            Level = Math.floor(this.state.data.Star / ((this.state.data.Questions.length * 3) / 6));
            if (Level < 0) {
                Level = 0;
            } else if (Level > 6) {
                Level = 6;
            }
        }
        return <div className="position-relative">
            <div className="display-3 custome-title position-absolute w-auto d-none" style={{
                color: '#ed651e',
                bottom: 0,
                left: '10%',
                zIndex: 9
            }}>
                {Level + 1}
            </div>

            <div className="position-absolute w-100 px-5 pt-2" style={{
                color: '#ed651e',
                zIndex: 9
            }}>
                <div className="w-100 d-flex justify-content-around align-items-end">
                    <div className="display-4">
                        <i className={`right-star fa-solid fa-star ${Level >= 5 ? Level >= 6 ? 'star-color' : 'fa-regular fa-star-half-stroke star-color' : 'empty-star'}`}></i>
                    </div>
                    <div className="display-4 pb-2">
                        <i className={`fa-solid fa-star ${Level >= 3 ? Level >= 4 ? 'star-color' : 'fa-regular fa-star-half-stroke star-color' : 'empty-star'}`}></i>
                    </div>
                    <div className="display-4">
                        <i className={`left-star fa-solid fa-star ${Level >= 1 ? Level >= 2 ? 'star-color' : 'fa-regular fa-star-half-stroke star-color' : 'empty-star'}`}></i>
                    </div>
                </div>
            </div>

            <GameObjects objectCss={`${prefix}Level${Level}`} />
        </div>;
    }

    openViewWindow = () => {
        window.openCompetitionView();
    }

    render() {
        const { t, user } = this.props;

        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const datetime1 = new Date('2023-02-21T08:00:00');
        const startDate = datetime1.toLocaleString('en-UK', options);

        const datetime2 = new Date('2023-02-28T20:00:00');
        const endDate = datetime2.toLocaleString('en-UK', options);

        const now = new Date();
        const nowDateString = now.toLocaleString('en-UK', options);

        let canPaly = false;
        if (((startDate <= nowDateString) && (endDate >= nowDateString)) || user.user.School.Id === 4) {
            canPaly = true;
        }

        let isEnd = false;
        if (endDate < nowDateString) {
            isEnd = true;
        }

        return (
            <>
                <Helmet>
                    <title>{t('Pages.Competition.Title')} | {t("Pages.Games.MyScore")} | {t('alarabeyya')}</title>
                </Helmet>

                <section className="position-relative" id="main-game-profile-section" style={{
                    minHeight: '100vh',
                    backgroundColor: '#74caee'
                }}>
                    <FootballStadium />
                    <div className="container padding-top padding-bottom ">
                        <div className="row ">

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <div className="teacher-wrapper p-4 mt-0">
                                        <div className="teacher-single-top bg-white">
                                            <div className="teacher-single-item d-flex w-75 mx-auto justify-content-center align-items-center m-0" >
                                                <div className="teacher-single-thumb d-flex w-100 ">
                                                    <LazyLoadImage src={`${user.user.Photo ? user.user.Photo : "/assets/images/teacher/teacher-single.jpg"}`} alt={`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} />
                                                </div>
                                            </div>
                                            <h3 className="title pt-4 text-center">
                                                {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`}
                                            </h3>
                                        </div>

                                        <div className={`contact-form p-3 w-100 mb-0 inner-box p-3 pt-4  mt-3`} style={{
                                            background: 'rgb(255 170 170)',
                                            border: '1px solid rgb(255 0 0)',
                                            borderRadius: '10px'
                                        }}  >
                                            <div>
                                                {
                                                    canPaly ? "" :
                                                        <div className=" h5 text-center mb-2">
                                                            {isEnd ? t('Pages.Competition.Time.end') : t('Pages.Competition.Time.Title')}
                                                        </div>
                                                }
                                                <p className=" text-justify">
                                                    {t('Pages.Competition.Time.Body')}
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            canPaly ?
                                                <>
                                                    <Link to="#" onClick={() => this.openViewWindow()} className=" custom-button orange-button w-100 mt-2">
                                                        <span>
                                                            <i className="icon fal fa-play"></i>
                                                            {t('Pages.Games.PlayNow')}
                                                        </span>
                                                    </Link>
                                                    <Link to="/CompetitionStage" className=" custom-button primary-button w-100 mt-2">
                                                        <span>
                                                            <i className="icon fal fa-crown"></i>
                                                            {t('Pages.Competition.Stage')}
                                                        </span>
                                                    </Link>
                                                </> : ""
                                        }
                                    </div>
                                    {
                                        canPaly ?
                                            <div className="teacher-wrapper p-4 mt-3">
                                                <div className="teacher-single-top bg-white ">
                                                    {this.renderLevel()}
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </aside>
                            </div>

                            <div className="col-lg-8 mb-50">


                                <div className={`row mt-0`}>
                                    <div className="col-12" >
                                        <div className="widget widget-search" style={{
                                            boxShadow: '0px 0px 10px 0px rgb(20 19 19 / 64%)',
                                            backgroundColor: '#fafafa91'
                                        }}>

                                            <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                                                <div className="section-header mb-1 mt-3">
                                                    <div className=" h1 custome-title" style={{
                                                        color: '#ed651e'
                                                    }}><i className="mx-3 fa-solid fa-trophy-star "></i>
                                                        {t('Pages.Games.MyScore')}
                                                    </div>
                                                </div>
                                            </div>

                                            <ScrollArea
                                                horizontal={false}
                                                className={`area w-100 lazyLoading  px-2 `}
                                                smoothScrolling={true}
                                                style={{
                                                    height: '65vh',
                                                    borderRadius: '10px'
                                                }}
                                                ref={this.scrollArea}
                                                contentClassName="content "
                                                onScroll={this.handleScroll} >
                                                <div className="widget-body p-2 oh" >
                                                    <table className="table table-striped table-bordered  table-hover table-sm w-100 bg-white">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th className="h3" title={t('Pages.Games.Table.Level')}><i className="text-success fas fa-sort-numeric-down"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Stars')}><i className="text-warning fas fa-stars"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Time')}><i className="text-info fas fa-user-clock"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Repate')}><i className="text-danger fas fa-repeat-1-alt"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Point')}><i className="text-primary mx-1 fas fa-coin-vertical"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.renderResultRow()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ScrollArea>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(CompetitionProfile));

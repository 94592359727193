import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import configData from "../../Config/countries.json";
import api from '../../../api/api';
import { notificationIsView } from '../../../api/socket.io';

class MailItem extends React.Component {

    state = {
        moreInfo: false,
        showReplies: false,
        showNewReplies: false
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        });
        this._editor = new React.createRef();
    }

    moreInfo = () => {
        const { item, user } = this.props;
        if (item && item.IsRead === false && !this.state.moreInfo) {
            this.props.IsSeen(item);
            notificationIsView("CONTACTUS", user.user.Id);
        }
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    showReplies = () => {
        const { item } = this.props;
        this.props.fetchReplies(item.Id);
        this.setState({
            showReplies: true
        });
    }

    showNewReplies = () => {
        const { item } = this.props;
        this.setState({
            showNewReplies: true
        });
        setTimeout(() => {
            window.createRedactor(`#Redactor-${item.Id}`);
        }, 500);
    }

    handleClose = () => {
        this.setState({
            showReplies: false
        });
    }

    handleCloseNewReplies = () => {
        const { item } = this.props;
        this.setState({
            showNewReplies: false
        });
        window.cleanRedactor(`#Redactor-${item.Id}`);
    }

    sendReplies = () => {
        const { t, item } = this.props;
        const html = window.getRedactor(`#Redactor-${item.Id}`);
        if (html) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            let postData = {
                Body: html
            };

            api.post(`/ContactUs/${item.Id}`, postData).then(response => {

                if (item && item.RepliesCount === 0) {
                    this.props.addReply(item);
                }
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-paper-plane"></i> {t('Pages.MailBox.toast.Send.Title')}</div> <div className="h6">{t('Pages.MailBox.toast.Send.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.handleCloseNewReplies();
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    renderReplies = () => {
        const { t, replies, isLoaded } = this.props;
        if (isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.MailBox.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (replies) {
            if (replies.length > 0) {
                return replies.map(reply => {
                    return <div className="product-list-item" key={reply.Id} >
                        <div className="product-content w-100  ">
                            <div className="d-flex justify-content-between">
                                <h4 className="display-3 subtitle" title={t("Pages.MailBox.DataEntry")}>
                                    {reply.User.Name}
                                </h4>
                                <h4 className="display-3 text-danger subtitle" title={t("Pages.MailBox.CreationDate")}>
                                    {reply.CreationDate}
                                </h4>
                            </div>

                            <div className="p-2 pr-4" dangerouslySetInnerHTML={{
                                __html: reply.Body
                            }}>
                            </div>
                        </div>
                    </div>
                });
            } else {
                return <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-telescope"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.MailBox.NoReplies")}
                        </h4>
                    </div>
                </div>
            }
        }
        return "";
    }

    render() {
        const { t, item } = this.props;
        if (item) {
            let Country = "";
            if (item.Country) {
                Country = this.state.countries.filter(p => p.value === item.Country)[0];
            }
            return (
                <>
                    {
                        this.state.showReplies ?
                            <Modal
                                size="lg"
                                centered
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showReplies}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-mail-bulk text-primary icon"></i> {t("Pages.MailBox.RepliesList")}
                                            <span className="text-danger">
                                                {item.Sender}
                                            </span>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.renderReplies()}
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showNewReplies ?
                            <Modal
                                size="lg"
                                centered
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showNewReplies}
                                onHide={this.handleCloseNewReplies}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-paper-plane text-primary icon"></i> {t("Pages.MailBox.NewReplies")}
                                            <span className="text-danger">
                                                {item.Sender}
                                            </span>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row ">
                                        <div className="col-12">
                                            <textarea id={`Redactor-${item.Id}`} autoComplete="off" className="form-control" ref={this._editor} ></textarea>
                                        </div>
                                        <div className="form-group w-100 text-center mt-3">
                                            <button className="custom-button" title={t('Pages.MailBox.send')} type="button" onClick={() => this.sendReplies()}>
                                                <span>
                                                    <i className="icon far fa-paper-plane"></i>
                                                    {t('Pages.MailBox.send')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }

                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            {
                                item.IsRead ? "" :
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.MailBox.IsRead')}>
                                        <i className="fas fa-envelope"></i>
                                    </div>
                            }
                            {
                                item.RepliesCount === 0 ?
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.MailBox.NoReplies")}>
                                        <i className="fab fa-creative-commons-zero"></i>
                                    </div> : ""
                            }
                            <div className="product-thumb">
                                {
                                    item.FromUser && item.FromUser.Photo ?
                                        <LazyLoadImage src={`${item.FromUser.Photo}`} alt={`${item.FromUser.Name}`} />
                                        : <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Sender}`} />
                                }
                                <div className="product-action-link">
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.MailBox.action.info")}><i className="far fa-info"></i></div>
                                    <div className="my-1" onClick={() => this.showNewReplies()} title={t("Pages.MailBox.action.new")}><i className="far fa-plus"></i></div>
                                    {
                                        item.RepliesCount === 0 ? "" :
                                            <div className="my-1" onClick={() => this.showReplies()} title={t("Pages.MailBox.action.replies")}><i className="far fa-mail-bulk"></i></div>
                                    }
                                    <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.MailBox.action.remove")}><i className="far fa-trash"></i></div>
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title"><Link to="#"> {item.Title} </Link></h5>
                                <p>
                                    {
                                        <span title={t('Pages.MailBox.Sender')}>
                                            <i className="icon fal fa-user"></i>
                                            <span className="text-danger">
                                                {item.Sender}
                                            </span>
                                        </span>
                                    }
                                    {
                                        item.Email ?
                                            <>
                                                <a className="mr-3" href={`mailto:${item.Email}`} title={t('Pages.MailBox.Email')}>
                                                    <i className="icon fal fa-envelope-open-text"></i>
                                                    {item.Email}
                                                </a>
                                            </>
                                            : ""
                                    }
                                    {
                                        item.FromUser && item.FromUser.Phone ?
                                            <>
                                                <a className="mr-3" href={`Tel:${item.FromUser.Phone}`} title={t('Pages.Register.Phone.title')}>
                                                    <i className="icon fal fa-phone"></i>
                                                    {item.FromUser.Phone}
                                                </a>
                                            </>
                                            : ""
                                    }
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            Country ?
                                                <span title={t('Pages.MailBox.Country')}  >
                                                    {Country.label}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.FromUser && item.FromUser.Address ?
                                                <span title={t('Pages.Register.Address.title')} >
                                                    {
                                                        Country ? " - " : ""
                                                    }
                                                    {item.FromUser.Address}
                                                </span>
                                                : ""
                                        }
                                        <br />
                                        <span className="mt-2">
                                            {
                                                item.FromUser && item.FromUser.School ?
                                                    <>
                                                        <span title={t('Pages.Register.SchoolName.title')}>
                                                            <i className="icon fal fa-school"></i>
                                                            {item.FromUser.School.Name + " - "}
                                                            <span className="text-danger" >
                                                                {
                                                                    item.FromUser.UserType ?
                                                                        <>
                                                                            {item.FromUser.UserType.Name}
                                                                        </>
                                                                        : ""
                                                                }
                                                            </span>
                                                        </span>
                                                        <br />
                                                    </>
                                                    : ""
                                            }
                                        </span>

                                        <span className="mt-2">
                                            {
                                                item.CreationDate ?
                                                    <>
                                                        <span title={t('Pages.MailBox.CreationDate')}>
                                                            <i className="icon fal fa-calendar-alt"></i>
                                                            {item.CreationDate}
                                                        </span>
                                                    </>
                                                    : ""
                                            }
                                        </span>
                                    </p>
                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-4 mt-3 mb-2 px-3 d-flex flex-wrap flex-column justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title d-flex">
                                                {t("Pages.MailBox.action.info")}  :
                                                <div className="text-danger h4 mx-1">
                                                    {item.Details}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <div className="title d-flex">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(MailItem));
import axios from 'axios';
import { getToken } from "../context/auth";


let api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/octet-stream',
        'content-type': 'multipart/form-data',
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'TimezoneOffset': - new Date().getTimezoneOffset(),
        Authorization: `Bearer ${getToken()}`
    },
    withCredentials: true,
});

export const getGeoInfo = () => {
    try {
        // const response = axios.get('https://ipapi.co/json/');
        const response = axios.get('https://pro.ip-api.com/json/?key=7TQ3WP5Aq3VaLAF');
        return response;
    } catch {
        return null;
    }
};

export const fetchLocations = () => async dispatch => {
    dispatch({ type: 'LOCATION_LOADING' });
    const response = await getGeoInfo();
    dispatch({ type: 'LOCATION_INITIAL', location: response.data });
}

export const setToken = token => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    apiBlob.defaults.headers.Authorization = `Bearer ${token}`;
}

export const apiBlob = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'TimezoneOffset': - new Date().getTimezoneOffset(),
        Authorization: `Bearer ${getToken()}`
    },
    responseType: 'blob',
    withCredentials: true,
});

export default api;


import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactPixel from 'react-facebook-pixel';

import Footer from "../Footer/FooterBottom";
import api from '../../api/api';

class PayproAfterPurchase extends React.Component {

    state = { data: [], showResult: false, showError: true, count: 1, qs: "" };

    constructor(props) {
        super(props);
        window.scrollToTop();
        ReactPixel.init('484815450795869');
    }

    componentDidMount() {
        const { search } = this.props.location;
        this.setState({
            qs: search.substring(1)
        });
        this.reloadAfterPurchaes(search.substring(1));
    }

    reloadAfterPurchaes = (qs) => {
        let postData = {
            qs
        };
        api.put(`/PayProglobalPayment/`,
            postData
        ).then(response => {
            this.setState({
                showResult: true,
                showError: false,
                data: response.data
            });

            ReactPixel.track('Purchase', response.data?.Email);

        }).catch(error => {
            this.setState({
                showError: true,
                showResult: false
            });
        });
    }

    render() {
        const { t, user } = this.props;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>
                    <Helmet>
                        <title>{t("Pages.AfterPurchase.title")} | {t('alarabeyya')}</title>
                        <script src={process.env.PUBLIC_URL + '/assets/js/PAyProAW.js?v=' + process.env.REACT_APP_VERSION} ></script>
                    </Helmet>
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="teacher-wrapper pos-rel pt-5  " style={{
                            minWidth: '50%'
                        }}>
                            <div className="teacher-single-top mt-2">
                                {
                                    this.state.showError || this.state.showResult ?
                                        this.state.showError ?
                                            <div className="product-content w-100 text-center ">
                                                <div className="display-1 text-danger">
                                                    <i className="fad fa-exclamation-triangle"></i>
                                                </div>
                                                <h4 className="display-3 subtitle  my-3">
                                                    {t("Pages.AfterPurchase.no_data.title")}
                                                </h4>
                                                <p className="text-justify">
                                                    {t("Pages.AfterPurchase.no_data.s1")}
                                                    <br />
                                                    {t("Pages.AfterPurchase.no_data.s2")}
                                                    <br />
                                                    {t("Pages.AfterPurchase.no_data.s3")}
                                                    <a href={`mailto:${t('HeaderTop.email')}`} className="ml-1 text-primary font-weight-bold d-inline-block">
                                                        {t('HeaderTop.email')}
                                                    </a>
                                                </p>
                                            </div>
                                            :
                                            <>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-success">
                                                        <i className="fad fa-shield-check"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle mt-0 mb-4">
                                                        {t("Pages.AfterPurchase.done")}
                                                    </h4>
                                                    <p className="text-justify">
                                                        {
                                                            this.state.data ? <>
                                                                <h5 className="subtitle">
                                                                    {t("Pages.AfterPurchase.service")} :
                                                                    <span className="text-primary">
                                                                        {this.state.data.Name}
                                                                    </span>
                                                                </h5>
                                                                {this.state.data.Description}
                                                                <br />
                                                                {
                                                                    this.state.data.Email ?
                                                                        <>
                                                                            {t("Pages.AfterPurchase.check")}
                                                                            <span className="ml-1 font-weight-bold ">
                                                                                {t("Pages.AfterPurchase.mail_check")}
                                                                            </span>
                                                                            <a href={`mailto:${this.state.data.Email}`} className="ml-1 text-primary font-weight-bold d-inline-block">
                                                                                {this.state.data.Email}
                                                                            </a>
                                                                        </>
                                                                        : ""
                                                                }
                                                            </> : ""
                                                        }
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-center mt-4 align-items-center  ">
                                                    {
                                                        user && user.user ?
                                                            <Link to="/Profile" className="custom-button mt-1" >
                                                                <span>
                                                                    <i className="icon fal fa-user"></i>
                                                                    {t('Pages.Profile.title')}
                                                                </span>
                                                            </Link>
                                                            :
                                                            <Link to="/Login" className="custom-button mt-1" >
                                                                <span>
                                                                    <i className="icon fal fa-sign-in-alt"></i>
                                                                    {t('Login')}
                                                                </span>
                                                            </Link>
                                                    }
                                                </div>
                                                <p className="text-justify mt-3">
                                                    في حال لم يصلك معلومات الدخول قم
                                                    <Link to="/Contact" className="mr-1" >
                                                        بالاتصال بنا
                                                    </Link>
                                                    .
                                                </p>
                                            </>
                                        :
                                        <div className="product-content w-100 text-center ">
                                            <div className="display-1 text-danger">
                                                <i className="fad fa-sync-alt fa-spin"></i>
                                            </div>
                                            <h4 className="display-3 subtitle">
                                                {t("Pages.AfterPurchase.Loading")}
                                            </h4>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(PayproAfterPurchase));
import React from "react";
import GameObjects from "../../GameObjects";

class School extends React.Component {

    state = {
        hover: false
    };

    setHover = hover => {
        this.setState({
            hover
        });
    }

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/School/bg.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute " style={{
                bottom: `15%`,
                right: `45%`
            }}>
                <GameObjects objectCss="TowGirlsStandTogather" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>


            <div className="position-absolute d-none d-sm-block" style={{
                bottom: `2%`,
                left: `1%`
            }}>
                <GameObjects objectCss="BoysTakeSalfe" />
            </div>
            <div className="position-absolute user-select-none d-none d-lg-block" style={{
                bottom: `1%`,
                right: `1%`
            }} onMouseEnter={() => this.setHover(true)} onMouseLeave={() => this.setHover(false)}><img src={`${process.env.PUBLIC_URL + `/assets/images/School/${this.state.hover ? "bus_hover" : "bus"}.png`}`} alt="" /></div>

            <div className="position-absolute user-select-none MoveBlueBird" style={{
                left: '5%',
                top: '5%'
            }}><GameObjects objectCss="RedBird" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>
        </div>
    }
}
export default School;
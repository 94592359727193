import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import ScrollArea from "react-scrollbar";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import api from "../../../../api/api";
import GameObjects from "../../Question/View/GameObjects";
import { LazyLoadImage } from "react-lazy-load-image-component";

class Scoreboard extends React.Component {

    state = {
        data: [],
        showMore: true
    };

    componentDidMount() {
        if (this.props.isMission) {
            api.get(`/MissionScoreboard/${this.props.MissionId}`).then(response => {
                this.setState({
                    data: response.data.data
                });
            });
        } else {
            api.get(`/Scoreboard/${this.props.lesson.Id}`).then(response => {
                this.setState({
                    data: response.data.data
                });
            });
        }
    }

    renderRow = () => {
        if (this.state.data && this.state.data.length && this.state.data.length > 0) {
            return this.state.data.sort((a, b) => a.TryNumber > b.TryNumber ? 1 : -1).sort((a, b) => a.Time < b.Time ? 1 : -1).sort((a, b) => a.Star < b.Star ? 1 : -1).map((item, index) => {
                const Time = this.calcTime(item.Time);
                return <tr>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.User.Name}</td>
                    <td>{item.User.SchoolName}</td>
                    <td className="text-center">{item.Star}</td>
                    <td className="text-center">{Time}</td>
                    <td className="text-center">{item.TryNumber}</td>
                </tr>
            })
        }
        return "";
    }

    calcTime = Time => {
        let result = "";
        if (Time >= 3600) {
            result = (parseInt(Time / 3600) > 9 ? parseInt(Time / 3600) : "0" + parseInt(Time / 3600)) + ":";
            Time = Time % 3600;
        } else {
            result += "00:";
        }

        if (Time >= 60) {
            result += (parseInt(Time / 60) > 9 ? parseInt(Time / 60) : "0" + parseInt(Time / 60)) + ":";
            Time = Time % 60;
        } else {
            result += "00:";
        }

        if (Time > 0) {
            result += parseInt(Time) > 9 ? parseInt(Time) : "0" + parseInt(Time);
        } else {
            result += "00";
        }
        return result;
    }

    showMore = () => {
        this.setState({
            showMore: true
        });
    }

    renderCol = () => {
        if (this.state.data && this.state.data.length && this.state.data.length > 0) {
            const { t } = this.props;
            const ListOfColor = ["warning", "primary ", "orange"];
            const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd", "#fff", "#fff", "#fff"];

            return this.state.data.map((item, index) => {
                const Time = this.calcTime(item.Time);
                return <div className="col mb-3 py-0" key={`MostStudentsHavePoints-${index}-${item.Id}`}>
                    <div className="product-list-item mb-0" style={{
                        backgroundColor: ListOfBackgroundColor[index]
                    }}>
                        <div className="product-thumb" style={{
                            minHeight: '120px',
                            backgroundColor: ListOfBackgroundColor[index]
                        }}>
                            <LazyLoadImage src={item.User.Photo} alt={item.User.Name} />
                        </div>
                        <div className="product-content py-1">
                            <div className="d-flex justify-content-between ">
                                <div className="d-flex flex-column justify-content-center">
                                    <h5 className="title ">
                                        {item.User.Name}
                                    </h5>
                                    <p className="my-0">
                                        <span className="mt-1">
                                            {
                                                item.User.SchoolName ?
                                                    <span title={t('Pages.Register.SchoolName.title')}>
                                                        <i className="icon fal fa-school"></i>
                                                        {item.User.SchoolName}
                                                    </span>
                                                    : ""
                                            }
                                        </span>
                                    </p>
                                    <p className="my-0">
                                        <span className={`h4 text-${ListOfColor[index]}`} title={t('Pages.Home.Point')}>
                                            <i className={`icon h5 fad fa-circle text-${ListOfColor[index]}`}></i>
                                            {item.Star}
                                        </span>
                                    </p>
                                </div>
                                <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                    <LazyLoadImage src={`/assets/images/archive/school/${1 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Games.Table.UserTime")} : ${Time} `} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            });
        }
        return "";
    }

    render() {
        const { t } = this.props;

        return (<>
            <Helmet>
                <title>{t("Pages.Games.Scoreboard")} | {t('alarabeyya')}</title>
                <style dangerouslySetInnerHTML={{
                    __html: `.table-striped tbody tr:nth-of-type(odd) {
                        background-color: rgb(233 153 185);
                    }`
                }}></style>
            </Helmet>
            <div className="w-100 px-0 mx-0" style={{
                height: '100vh',
                backgroundSize: 'cover',
                backgroundColor: '#74caee'
            }}>

                <div className="w-100 h-100 position-absolute user-select-none" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/scoreboard/mounten.png"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: "100%",
                    backgroundPositionX: "50%",
                    backgroundSize: '100%'
                }}></div>
                <div className="position-absolute user-select-none" style={{
                    right: '2%',
                    top: '10%',
                    animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
                }}><GameObjects objectCss="Cloud2" /></div>
                <div className="position-absolute user-select-none" style={{
                    right: 'calc(50% - 169px)',
                    top: '0%',
                    animation: `dropObjectMovment linear forwards 4s normal infinite`
                }}><GameObjects objectCss="Cloud1" /></div>
                <div className="position-absolute user-select-none" style={{
                    left: '2%',
                    top: '10%',
                    animation: `dropObjectMovment linear forwards 5s normal infinite`
                }}><GameObjects objectCss="Cloud3" /></div>
                <div className="position-absolute w-100 overflow-hidden-lg align-items-center justify-content-center user-select-none" style={{
                    pointerEvents: 'none',
                    top: '0',
                    right: '0',
                    height: '100%',
                    width: '100%',
                    flexShrink: '0'
                }}>
                    <div style={{
                        position: "sticky",
                        top: 0
                    }}>
                        <Player
                            autoplay
                            loop
                            src={process.env.PUBLIC_URL + "/assets/effect/success/61528-confetti.json"}
                            style={{ height: '100%' }}
                        ></Player>
                    </div>
                </div>
                <div className="w-100 h-100 position-absolute user-select-none" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/scoreboard/ground.png"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: "100%",
                    backgroundPositionX: "50%",
                    backgroundSize: '100%'
                }}></div>

                <div className="position-absolute user-select-none h-100 w-100" style={{
                    zIndex: '5'
                }}>
                    <div className="container pt-5" >
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="display-4 game-font h3 w-100 pb-3 text-center"
                                    style={{
                                        color: `rgb(239 102 157)`,
                                        filter: `drop-shadow(rgb(255, 255, 255) 1px 1px 0px)
                                    drop-shadow(rgb(255, 255, 255) -1px -1px 0px)
                                    drop-shadow(rgb(255, 255, 255) -1px 1px 0px)
                                    drop-shadow(rgb(255, 255, 255) 1px -1px 0px)`
                                    }}>
                                    <i className="fas fa-trophy-alt mx-2 text-warning"></i>
                                    {t('Pages.Games.Scoreboard')}
                                </div>

                            </div>
                        </div>
                        {
                            this.state.showMore ?
                                <div className={`row mt-0`}>
                                    <div className="col-12" >
                                        <div className="widget widget-search" style={{
                                            boxShadow: '0px 0px 10px 0px rgb(20 19 19 / 64%)',
                                            backgroundColor: '#fafafa91'
                                        }}>

                                            <ScrollArea
                                                horizontal={false}
                                                className={`area w-100 lazyLoading p-0 `}
                                                smoothScrolling={true}
                                                contentClassName="content "
                                                style={{
                                                    height: '65vh',
                                                    borderRadius: '10px'
                                                }}
                                            >
                                                <div className="widget-body p-2 oh" >
                                                    <table className="table table-striped table-bordered  table-hover table-sm w-100 m-0 bg-white" >
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th className="h3" title={t('Pages.Games.Table.Order')}><i className="text-success fas fa-sort-numeric-down"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Name')}><i className="text-primary mx-1 fas fa-user-graduate"></i> {t('Pages.Games.Table.Name')}</th>
                                                                <th className="h3" title={t('Pages.Games.Table.School')}><i className="text-default mx-1 fas fa-school"></i> {t('Pages.Games.Table.School')}</th>
                                                                <th className="h3" title={t('Pages.Games.Table.Point')}><i className="text-warning fas fa-stars"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Time')}><i className="text-info fas fa-user-clock"></i></th>
                                                                <th className="h3" title={t('Pages.Games.Table.Repate')}><i className="text-danger fas fa-repeat-1-alt"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.renderRow()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ScrollArea>

                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="row mt-0 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
                                        {this.renderCol()}
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center align-content-center">
                                        <div className={` text-center w-auto h-auto h3 px-5 font-weight-bold d-inline-flex mb-1 btn-MoreResult`} onClick={() => this.showMore()}>
                                            <div className="mouse-btn-down">
                                                <div className="chevron white-borader"></div>
                                                <div className="chevron white-borader"></div>
                                                <div className="chevron white-borader"></div>
                                            </div>
                                            <span className="txt px-2">
                                                {t('Pages.Games.Scoreboard_MoreResult')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                <style dangerouslySetInnerHTML={{ __html: `  @keyframes dropObjectMovment{ 0%{transform:translateX(0%)} 25%{transform:translateX(4%)} 50%{transform:translateX(0%)} 75%{transform:translateX(-4%)} 100%{transform:translateX(0%)} }` }}></style>
                <div className="position-absolute user-select-none" style={{
                    right: 'calc(50% - 420px)',
                    bottom: '3%',
                    animation: `dropObjectMovment linear forwards 7s normal infinite`
                }}><GameObjects objectCss="BoysTugOfWar" /></div>

                <div className="w-100 h-100 position-absolute user-select-none" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/scoreboard/gress.png"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: "100%",
                    backgroundPositionX: "50%",
                    bottom: '0%',
                    backgroundSize: '100%'
                }}></div>

            </div>
        </>
        );
    }
}
export default withTranslation('common')(Scoreboard);
const TemplatesReducers = (state = [], action) => {
    switch (action.type) {
        case "GAME_TEMPLATES_LOADING":
            return {
                gameTemplates: state.gameTemplates,
                current: null,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "GAME_TEMPLATES_INITIAL":
            return {
                gameTemplates: action.gameTemplates,
                current: null,
                toRemove: [],
                isLoaded: true
            };
        case "GAME_TEMPLATE_INITIAL":
            return {
                gameTemplates: state.gameTemplates,
                current: action.template,
                toRemove: [],
                isLoaded: true
            };
        case "GAME_TEMPLATE_QUEUE_FOR_REMOVAL":
            return {
                gameTemplates: state.gameTemplates,
                current: null,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "GAME_TEMPLATE_CLEAN_NODES":
            return {
                gameTemplates: state.toRemove.some(v => v === action.id) ? state.gameTemplates.filter(v => v.Id !== action.id) : state.gameTemplates,
                current: null,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "GAME_TEMPLATE_UNDO":
            return {
                gameTemplates: state.gameTemplates,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        default:
            return state;
    }
}

export default TemplatesReducers;
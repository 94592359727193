import React from "react";
import GameObjects from "../../GameObjects";

class Library extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Library/bg.png?v=2"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute " style={{
                bottom: `25%`,
                left: `40%`
            }}>
                <GameObjects objectCss="BoySitAndReadBook" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `15%`,
                right: `30%`
            }}>
                <GameObjects objectCss="BoySitAndReadBook1" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute d-none d-md-block" style={{
                bottom: `15%`,
                left: `1%`
            }}>
                <GameObjects objectCss="GirlHoldsBooks" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

            <div className="position-absolute " style={{
                bottom: `10%`,
                right: `1%`
            }}>
                <GameObjects objectCss="GirlPointingTo" status={this.props.currentStatus} FinishMove={this.FinishMove} />
            </div>

        </div>
    }
}
export default Library;
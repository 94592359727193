import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import {
    fetchFileManager as fetch,
    removeFileManager as remove,
    undoDeleteFileManager as undo,
    clearFileManager as clear
} from './actions';
import ListItem from "./ListItem";
import UploadFileForm from "./Forms/Upload";
import NameForm from "./Forms/NameForm";
import api from '../../../api/api';

class FileManagerList extends React.Component {

    state = { search: '', path: "", viewType: 'grid', showEditFormModal: false, newFolder: false };

    componentDidMount() {
        this.Sync(this.state.path);
    }

    changeView = () => {
        this.setState({
            viewType: this.state.viewType === "list" ? "grid" : "list"
        });
    }

    newFolder = () => {
        this.setState({
            newFolder: true
        });
    }

    openFolder = path => {
        this.setState({
            path
        });
        this.Sync(path);
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = path => {
        this.props.fetch(path);
    }

    ShowEditForm = () => {
        this.setState({
            showEditFormModal: true,
            onUpdate: false
        });
    }

    renderFileManager() {
        const { t, fileManager } = this.props;
        let isLoaded = false;

        if (fileManager) {
            isLoaded = fileManager.isLoaded;
        }

        let toRemove = [];
        if (fileManager) {
            toRemove = fileManager.toRemove;
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (fileManager && fileManager.files && fileManager.files.length > 0) {
            let filterFiles = fileManager.files.filter(v => v.attributes.name.includes(search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.FileManager.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterFiles.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.FileManager.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.FileManager.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterFiles.map(file =>
                (toRemove && toRemove.includes(file.id)) ? "" :
                    <ListItem
                        key={file.id}
                        item={file}
                        Sync={this.Sync}
                        path={this.state.path}
                        openFolder={this.openFolder}
                        remove={this.showDelete}></ListItem>
            );
        }

        if (fileManager && fileManager.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.FileManager.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.FileManager.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    handleClose = () => {
        this.setState({
            showEditFormModal: false,
            newFolder: false
        });
    }

    showDelete = item => {
        if (item.id) {
            this.props.remove(item.id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(item); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteFiles(item); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = item => {
        this.props.undo(item.id);
    }

    deleteFiles = item => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/FileManager/?path=${item.id}`).then(response => {
            this.props.clear(item.id);
            this.Sync(this.state.path);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.FileManager.toast_Delete.Title')}</div> <div className="h6">{t('Pages.FileManager.toast_Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/FileManager/`,
            postData
        ).then(response => {
            this.Sync(this.state.path);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.FileManager.add_success_toast.Title')}</div> <div className="h6">{t('Pages.FileManager.add_success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                showEditFormModal: false
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitAddFolder = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/FileManager/?path=${this.state.path}&isFolder=true`,
            postData
        ).then(response => {
            this.Sync(this.state.path);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.FileManager.add_folder_success_toast.Title')}</div> <div className="h6">{t('Pages.FileManager.add_folder_success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                newFolder: false
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, fileManager } = this.props;

        let isLoaded = false;
        if (fileManager) {
            isLoaded = fileManager.isLoaded;
        }

        var split = this.state.path.split("/");
        var parent = split.slice(0, split.length - 2).join("/") + "/";
        return (
            <>
                {
                    this.state.newFolder ?
                        <Modal
                            size="md"
                            show={this.state.newFolder}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-folder-plus"></i> {t('Pages.FileManager.newFolder')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category">
                                    <div className="widget-body">
                                        <NameForm
                                            path={this.state.path}
                                            onSubmit={this.onSubmitAddFolder}
                                        ></NameForm>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                {
                    this.state.showEditFormModal ?
                        <Modal
                            size="xl"
                            show={this.state.showEditFormModal}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-upload"></i> {t('Pages.FileManager.Add')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category">
                                    <div className="widget-body">
                                        <UploadFileForm
                                            path={this.state.path}
                                            onSubmit={this.onSubmit}
                                        ></UploadFileForm>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }

                <section className="pos-rel padding-top padding-bottom">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("Pages.FileManager.title")} | {t('alarabeyya')}</title>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                            <link rel="stylesheet" href={process.env.PUBLIC_URL + '/assets/css/folder.css'} ></link>
                        </Helmet>

                        <div className="row mb-30-none">
                            <div className="col-lg-8 mb-50 ">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.FileManager.title')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex align-items-center">

                                            <div>
                                                <div onClick={() => this.openFolder("")} title={t("Pages.FileManager.root")} className="btn my-1 mr-1 btn-dark ">
                                                    <i className="fas fa-home"></i>
                                                </div>
                                                {
                                                    this.state.path && this.state.path !== "/" ?
                                                        <div onClick={() => this.openFolder(parent ? parent : "")} title={t("Pages.FileManager.upFolder")} className="btn my-1 mr-1 btn-warning ">
                                                            <i className="fas fa-level-up"></i>
                                                        </div>
                                                        : ""
                                                }
                                                <div onClick={() => this.newFolder()} title={t("Pages.FileManager.newFolder")} className="btn my-1 mr-1 btn-info ">
                                                    <i className="fas fa-folder-plus"></i>
                                                </div>
                                            </div>

                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.FileManager.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div >
                                                <div onClick={() => this.ShowEditForm()} title={t("Pages.FileManager.Add")} className="btn my-1 mr-1 btn-primary ">
                                                    <i className="fas fa-upload"></i>
                                                </div>
                                                <div onClick={() => this.Sync(this.state.path)} title={t("Pages.FileManager.Sync")} className="btn my-1 mr-1  btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                                <div onClick={() => this.changeView()} title={t("Pages.FileManager.changeView")} className="btn my-1 mr-1 btn-success ">
                                                    {
                                                        this.state.viewType === "list" ?
                                                            <i className={`fas fa-list`}></i>
                                                            :
                                                            <i className={`fas fa-th`}></i>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`shop-product-wrap row mb-30-none ${this.state.viewType}`}>

                                    {this.renderFileManager()}
                                </div>
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        fileManager: state.fileManager
    };
};

export default connect(mapStateToProps, { fetch, remove, undo, clear })(withTranslation('common')(FileManagerList));
import api from '../../../../api/api';

export const fetchQuestion = id => async dispatch => {
    const response = await api.get(`/Questions/${id}`);
    dispatch({ type: 'QUESTION_INITIAL', question: response.data.data });
}

export const fetchQuestionType = () => async dispatch => {
    const response = await api.get(`/QuestionType/`);
    dispatch({ type: 'QUESTION_TYPE_INITIAL', questionTypes: response.data.data });
}

export const fetchRandomQuestion = id => async dispatch => {
    const response = await api.get(`/RandomQuestionType/${id}`);
    dispatch({ type: 'QUESTION_INITIAL', question: response.data.data });
}

export const showUpdateModal = (id, typeId) => async dispatch => {
    dispatch({ type: 'QUESTION_SHOW_UPDATE_MODAL', id, typeId });
}

export const hideUpdateModal = () => async dispatch => {
    dispatch({ type: 'QUESTION_HIDE_UPDATE_MODAL' });
}

export const updateQuestion = question => async dispatch => {
    dispatch({ type: 'QUESTION_UPDATE', question });
}

export const updateQuestionVersion = version => async dispatch => {
    dispatch({ type: 'QUESTION_UPDATE_VERSION', version });
}

export const removeQuestionAnswer = (id) => async dispatch => {
    dispatch({ type: 'QUESTION_ANSWER_QUEUE_FOR_REMOVAL', id });
}
export const undoRemoveQuestionAnswer = (id) => async dispatch => {
    dispatch({ type: 'QUESTION_ANSWER_UNDO', id });
}
export const clearQuestionAnswer = (id) => async dispatch => {
    dispatch({ type: 'QUESTION_ANSWER_CLEAN_NODES', id });
}

export const removeQuestionCategory = (id) => async dispatch => {
    dispatch({ type: 'QUESTION_CATEGORY_QUEUE_FOR_REMOVAL', id });
}
export const undoRemoveQuestionCategory = (id) => async dispatch => {
    dispatch({ type: 'QUESTION_CATEGORY_UNDO', id });
}
export const clearQuestionCategory = (id) => async dispatch => {
    dispatch({ type: 'QUESTION_CATEGORY_CLEAN_NODES', id });
}
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { updateQuestion } from '../../actions/';
import configData from "../../../../Config/CustomStyle.json";
import QuestionTitle from '../QuestionTitle/';

class QuestionnaireView extends React.Component {

    state = {
        CustomStyle: configData.CustomStyle,
        fills: [],
        answers: [],
        categories: [],
        answersList: [],
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        if (props.question.question && props.question.question.Answers) {
            state.answers = props.question.question.Answers;
        } else {
            state.answers = props.answers;
        }
        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        state.categories = props.categories;
        if (props.question.question && props.question.question.Fills) {
            state.fills = props.question.question.Fills;
        } else {
            state.fills = props.fills;
        }

        if (props.examAnswers) {
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answersList = hasAnswer.answers;
                if (props.showResult && hasAnswer.answers) {
                    const { t, mark } = props;

                    let trueResultCount = state.fills.length;
                    let trueAnswer = 0;
                    hasAnswer.answers.forEach((answer) => {
                        let isTrue = state.fills.some(p => p.CategoryId === answer.CategoryId && p.AnswerId === answer.AnswerId);
                        if (isTrue) {
                            trueAnswer++;
                        }
                    });

                    if (trueResultCount === trueAnswer) {
                        state.mark = {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        };
                    } else if (trueAnswer === 0) {
                        state.mark = {
                            type: 'danger',
                            userMark: 0,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    } else {
                        let userMark = (mark * trueAnswer / trueResultCount).toFixed(2);
                        state.mark = {
                            type: userMark >= (mark / 2) ? 'warning' : 'danger',
                            userMark: userMark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        };
                    }
                }
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }

        return state;
    }

    renderCategory() {
        if (this.state.categories && this.state.answers) {
            let categories = this.state.categories;
            let answers = this.state.answers;

            let RandomId = (Math.random() * 10000).toFixed(0);
            return (
                <>
                    <div className="" style={{
                        height: '100%',
                        position: 'relative'
                    }}>
                        <table className={`table table-sm mb-0 table-hover table-${this.state.CustomStyle.questionTypeStyle.AnswersSelectType} table-${this.state.CustomStyle.questionTypeStyle.BorderStyle} `}>
                            <thead>
                                <tr className={`table-${this.state.CustomStyle.questionTypeStyle.BorderColor}`}>
                                    <td className={`my-1 border-top-0 bg-${this.state.CustomStyle.questionTypeStyle.BorderColor}`}></td>
                                    {
                                        answers.sort((a, b) => a.Sort - b.Sort).map(item => {
                                            return (
                                                <td key={item.Id} className={`my-1 border-top-0 bg-${this.state.CustomStyle.questionTypeStyle.BorderColor}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: item.Name }}></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categories.sort((a, b) => a.Sort - b.Sort).map(category => {
                                        return (
                                            <tr key={category.Id}>
                                                <td className={`align-middle px-3`}> <div className={` my-${this.state.CustomStyle.questionTypeStyle.AnswersMargin}`} dangerouslySetInnerHTML={{ __html: category.Name }}></div></td>
                                                {
                                                    answers.sort((a, b) => a.Sort - b.Sort).map((answer, index) => {
                                                        return (
                                                            <td key={answer.Id} >
                                                                <div className='d-flex justify-content-center'>
                                                                    <div className={`custom-control custom-radio d-flex align-items-center  mr-sm-3 py-2 checkbox-${this.state.CustomStyle.questionTypeStyle.AnswersSelectClass}`}>
                                                                        {
                                                                            this.props.onEdit ?
                                                                                <input
                                                                                    defaultChecked={(this.state.fills && this.state.fills.some(p => p.CategoryId === category.Id && p.AnswerId === answer.Id)) ? true : false}
                                                                                    type="radio"
                                                                                    onChange={e => this.handleChangeSelect(answer.Id, category.Id)}
                                                                                    className="custom-control-input"
                                                                                    name={`checkboxAnswer-${category.Id}-${this.props.Id}`}
                                                                                    id={`checkboxAnswer-${index}-${category.Id}-${this.props.Id}-${RandomId}`} />
                                                                                :
                                                                                this.controledCheck(answer, category.Id, RandomId, index)
                                                                        }
                                                                        <label className="custom-control-label" htmlFor={`checkboxAnswer-${index}-${category.Id}-${this.props.Id}-${RandomId}`} >
                                                                        </label>
                                                                        {this.renderCheckAnswerIsTrue(answer, category.Id)}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>
                </>
            );
        }
        return "";
    }

    renderCheckAnswerIsTrue(item, categoryId) {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            const answer = this.state.answersList.filter(v => v.CategoryId === categoryId)[0];

            if (answer) {
                let isTrue = this.state.fills.some(p => p.CategoryId === answer.CategoryId && p.AnswerId === item.Id);
                const isChecked = this.state.answersList.some(v => v.AnswerId === item.Id && v.CategoryId === categoryId);
                if (isTrue && isChecked) {
                    return (
                        <i title={t('Pages.Lessons.Questions.forms.trueAnswer')} className="icon fad fa-check-circle text-success"></i>
                    );
                } else {
                    if (isChecked) {
                        return (
                            <i title={t('Pages.Lessons.Questions.forms.falseAnswer')} className="icon fas fa-exclamation-triangle text-danger"></i>
                        );
                    }
                }
            }
        }
        return "";
    }

    controledCheck(item, categoryId, RandomId, index) {
        const isChecked = this.state.answersList.some(v => v.CategoryId === categoryId && v.AnswerId === item.Id) ? true : false;
        return (
            <>
                <input
                    value={item.Id}
                    checked={isChecked ? isChecked : false}
                    type="radio"
                    onChange={() => this.handleChangeAnswerSelect(item.Id, categoryId)}
                    className="custom-control-input"
                    name={`checkboxAnswer-${categoryId}-${this.props.Id}`}
                    id={`checkboxAnswer-${index}-${categoryId}-${this.props.Id}-${RandomId}`} />
            </>
        );
    }

    handleChangeAnswerSelect = (AnswerId, categoryId) => {
        let answerResult = this.state.answersList.filter(p => p.CategoryId !== categoryId);
        answerResult.push({
            CategoryId: categoryId,
            AnswerId: AnswerId,
            Sort: AnswerId
        });

        this.setState({
            answersList: answerResult,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });

        if (this.props.saveExamAnswer) {
            let trueResultCount = this.state.fills.length;
            let falseAnswer = 0;
            let trueAnswer = 0;
            answerResult.forEach((answer) => {
                let isTrue = this.state.fills.some(p => p.CategoryId === answer.CategoryId && p.AnswerId === answer.AnswerId);
                if (isTrue) {
                    trueAnswer++;
                }
            });

            let userMark = 0;
            const mark = this.props.mark
            if (trueAnswer === trueResultCount) {
                userMark = mark;
            } else {
                userMark = (mark * trueAnswer / trueResultCount).toFixed(2);
            }

            this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answerResult, {
                trueResultCount,
                trueAnswer,
                falseAnswer,
                questionMark: this.props.mark
            }, userMark);
        }
    }

    handleChangeSelect = (AnswerId, categoryId) => {
        let fills = this.state.fills ? this.state.fills.filter(p => p.CategoryId !== categoryId) : [];
        fills.push({
            CategoryId: categoryId,
            AnswerId: AnswerId,
            Sort: AnswerId
        });

        this.setState({
            fills,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });

        if (this.props.question && this.props.question.question) {
            var copy = this.props.question;
            copy.question = {
                ...copy.question,
                Fills: fills
            };
            this.props.updateQuestion(copy);
        }
    }

    ResetAnswer = () => {
        this.setState({
            answersList: [],
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });
    }

    CheckAnswer = () => {
        const { t, mark, toast } = this.props;

        if (this.state.answersList.length === 0) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-lightbulb-on"></i> {t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return "";
        }

        let trueResultCount = this.state.fills.length;
        let trueAnswer = 0;
        this.state.answersList.forEach((answer) => {
            let isTrue = this.state.fills.some(p => p.CategoryId === answer.CategoryId && p.AnswerId === answer.AnswerId);
            if (isTrue) {
                trueAnswer++;
            }
        });

        if (trueResultCount === trueAnswer) {
            this.props.playTrueSound();
            this.props.showConfetti();
            this.setState({
                mark: {
                    type: 'success',
                    userMark: mark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                    title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                }
            });
        } else if (trueAnswer === 0) {
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        } else {
            let userMark = (mark * trueAnswer / trueResultCount).toFixed(2);
            this.props.playFalseSound();
            this.setState({
                mark: {
                    type: userMark >= (mark / 2) ? 'warning' : 'danger',
                    userMark: userMark,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                    title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                }
            });
        }
    }

    renderMark() {
        const { t } = this.props;
        if (this.state.mark.isSolved) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        } else if (showResult) {
            return (
                <>
                    {this.renderMark()}
                </>
            );
        }
        return "";
    }

    render() {
        const { onEdit, title, mark, index, Id, isExamQuestion, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'absolute') {
            style = { ...style, height: `${this.state.CustomStyle.questionStyle.height}px` };
        }

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`}>
                <div className="bg-white w-100 p-3 form-group w-100 position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} style={style} >
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`} > </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            {this.renderCategory()}
                        </div>
                    </div>
                    {isExamQuestion === true ? showResult === true ? this.renderMark() : "" : ""}
                    {isExamQuestion !== true ? this.renderControlButton() : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(QuestionnaireView));
import api from '../../../../api/api';

export const fetchRegistrationOutSchools = postData => async dispatch => {
    dispatch({ type: 'REGISTRATION_OUT_SCHOOLS_LOADING' });
    const response = await api.post(`/RegistrationOutSchools/`, postData);
    dispatch({ type: 'REGISTRATION_OUT_SCHOOLS_INITIAL', schools: response.data.data });
}

export const loadRegistrationOutSchools = () => async dispatch => {
    dispatch({ type: 'REGISTRATION_OUT_SCHOOLS_LOADING' });
}

export const removeRegistrationOutSchools = id => async dispatch => {
    dispatch({ type: 'REGISTRATION_OUT_SCHOOLS_QUEUE_FOR_REMOVAL', id });
}

export const undoRegistrationOutSchools = id => async dispatch => {
    dispatch({ type: 'REGISTRATION_OUT_SCHOOLS_UNDO', id });
}

export const clearRegistrationOutSchools = id => async dispatch => {
    dispatch({ type: 'REGISTRATION_OUT_SCHOOLS_CLEAN_NODES', id });
}
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import FooterBottom from "../../Footer/FooterBottom";
import TreeView from "../Indices/TreeView";
import { fetchIndices } from '../Indices/actions/';
import { getIndeices, setIndeices } from "../../../context/sessionStorage";
import history from '../../../history';

class Books extends React.Component {

    state = { currentItem: '', indices: [], search: '' };

    componentDidMount() {
        const GetNode = this.GetNode;
        this.setState({ GetNode });

        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices
        });

        const { id } = this.props.match.params;
        if (id !== '0') {
            this.setState({
                currentItem: {
                    id: parseInt(id)
                }
            });
        }
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id) {
                    result = node;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.indices && props.indices.nodes && state.currentItem && state.currentItem.id && !state.currentItem.label) {
            state.currentItem = state.GetNode(props.indices.nodes, state.currentItem.id);
        }
        return state;
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        history.push(`/ControlPanel/Books/${item.id}`);
    }

    openBook = id => {
        history.push(`/ControlPanel/BookLessons/${id}`);
    }

    renderBooks() {
        const { t } = this.props;
        if (this.state.currentItem && this.state.currentItem.Level === 3 && this.state.currentItem.nodes.length > 0) {
            let search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            return this.state.currentItem.nodes.filter(v => v.label.includes(search)).map(item => {
                return (
                    <div className="pr-1 bookWrap mb-50 " key={item.id}>
                        <Link to="#" className='w-100' onClick={() => this.openBook(item.id)}>
                            <div className="shop-item">
                                <div className="product-thumb">
                                    {
                                        item.icon ?
                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                            :
                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                    }
                                </div>
                                <div className="product-content">
                                    <h5 className="title">{item.label}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                );

            });
        } else if (this.state.currentItem && this.state.currentItem.Level === 2 && this.state.currentItem.nodes.length > 0) {
            let search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            return this.state.currentItem.nodes.filter(v => v.label.includes(search)).map(item => {
                return (
                    <div className="pr-1 bookWrap mb-50 " key={item.id}>
                        <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                            <div className="shop-item">
                                <div className="product-thumb">
                                    {
                                        item.icon ?
                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                            :
                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                    }
                                </div>
                                <div className="product-content">
                                    <h5 className="title">{item.label}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                );

            });
        } else {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-telescope"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Books.NotFound")}
                        </h4>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { t } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let currentId = (this.state.currentItem) ? this.state.currentItem.id : 0;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("PageHeader.Books")} | {t('alarabeyya')}</title>
                        </Helmet>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-header">
                                    <span className="cate">{t('ControlPanel.title')}</span>
                                    <h3 className="title">{t('PageHeader.Books')} </h3>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-30-none grid">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        currentId={currentId}
                                        data={indices.nodes}
                                        toRemove={indices.toRemove}
                                        type='Books'
                                        Level={3}
                                        setCurrentItem={this.setCurrentItem}></TreeView>
                                    : ""
                                }
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Books.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between justify-content-sm-around">
                                    {this.renderBooks()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <FooterBottom></FooterBottom>
                </footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, { fetchIndices, fetch })(withTranslation('common')(Books));
import React from "react";
import GameObjects from "../../../Question/View/GameObjects";
import TextBox from "../../../Question/View/GameObjects/TextBox";

class HorizontalMove extends React.Component {

    constructor(props) {
        super(props);
        this.timer = "";
        this.to = this.props.fromRight ? 100 : -10;
        this.startDropTimer = "";
        this.designerContainer = React.createRef();
        this.dropContainer = React.createRef();
        this.state = {
            right: this.props.fromRight ? -500 : this.props.designerContainer.current.clientWidth,
            status: "",
            startDrop: false,
            isShow: false,
            top: this.props.top ? this.props.top : Math.floor(Math.random() * 35),
            delay: this.props.delay ? (this.props.delay * 1000) : (Math.floor(Math.random() * 10) * 300),
            timerSound: "",
            zIndex: 19,
            flipX: this.props.fromRight ? this.props.flipX : !this.props.flipX,
            isHide: false,
            isHover: false,
            text: this.props.text,
            img: this.props.img,
            style: this.props.style,
            speed: 5,
            canClick: true,
            isRepate: true,
            objectMovmentId: this.props.objectMovmentId ? this.props.objectMovmentId : Math.floor(Math.random() * 100000),
            cursorClickPosition: {
                left: 0,
                top: 0,
                rotate: 0
            }
        };
    }

    componentDidMount() {
        let timeout = this.state.delay;
        setTimeout(() => {
            this.setState({
                isShow: true
            });
            if (this.props.direction === "bottom") {
                window.moveToFromBottom(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
            } else {
                window.moveTo(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
            }

            this.timer = setInterval(() => {
                if (this.state.isHide === false && this.state.isRepate) {
                    let left = -15;
                    if (this.designerContainer && this.designerContainer.current) {
                        var react = this.designerContainer.current.getBoundingClientRect();
                        const screenWidth = this.props.designerContainer.current.scrollWidth;
                        left = (parseFloat((react.width / screenWidth * 100).toFixed(2)) + 1) * -1;
                    }
                    if (this.to === 100) {
                        this.to = left;
                    } else {
                        this.to = 100;
                    }
                    this.setState({
                        flipX: !this.state.flipX,
                        text: this.props.text,
                        img: this.props.img,
                        style: this.props.style,
                        canClick: true,
                        top: Math.floor(Math.random() * 35)
                    });
                    if (this.props.direction === "bottom") {
                        window.moveToFromBottom(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
                    } else {
                        window.moveTo(`objectMovment${this.state.objectMovmentId}`, this.state.top, this.to, this.props.Time * 1000);
                    }
                } else if (this.state.isHide === false && this.state.isRepate === false) {
                    this.setState({
                        isHide: true
                    });
                    clearInterval(this.timer);
                }
            }, (this.props.Time * 1000));

        }, timeout);
        this.boundingClientRect = this.props.designerContainer.current.getBoundingClientRect();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.startDropTimer);
    }

    handleTouchMove = event => {
        if (!this.state.isHide && !this.state.startDrop && this.state.isRepate && this.state.canClick && this.props.noDrop !== true) {
            let rect = this.props.designerContainer.current.getBoundingClientRect();
            let object = this.designerContainer.current.getBoundingClientRect();
            this.setState({
                cursorClickPosition: {
                    left: event.nativeEvent.targetTouches[0].clientX - rect.left - (object.width / 2),
                    top: event.nativeEvent.targetTouches[0].clientY,
                    rotate: 0
                },
                text: "",
                img: "",
                status: "",
                style: [],
                startDrop: true,
                isRepate: false,
                canClick: false
            });
            this.startDrop();
        }
    }

    onClick = event => {
        if (!this.state.isHide && !this.state.startDrop && this.state.canClick && this.state.isRepate && this.props.noDrop !== true) {
            let rect = this.props.designerContainer.current.getBoundingClientRect();
            let object = this.designerContainer.current.getBoundingClientRect();
            this.setState({
                cursorClickPosition: {
                    left: event.clientX - rect.left - (object.width / 2),
                    top: event.clientY,
                    rotate: 0
                },
                text: "",
                status: "",
                img: "",
                style: [],
                startDrop: true,
                canClick: false,
                isRepate: false
            });
            this.startDrop();
        }
    }
    startDrop = () => {
        this.startDropTimer = setInterval(() => {
            if (this.designerContainer && this.designerContainer.current && this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide) {
                this.fall();
            }
        }, 20);
    }

    fall = () => {
        if (this.state.startDrop) {
            if (this.state.cursorClickPosition.top < this.props.designerContainer.current.clientHeight - this.designerContainer.current.clientHeight) {
                this.setState({
                    cursorClickPosition: {
                        top: this.state.cursorClickPosition.top + this.state.speed,
                        left: this.state.cursorClickPosition.left + (0.1 * (!this.state.flipX ? -1 : 1)),
                        rotate: this.state.cursorClickPosition.rotate + (0.3 * (!this.state.flipX ? -1 : 1))
                    }
                });
            } else {
                const result = this.props.handleDropObject(this.props.param);
                if (result) {
                    this.setState({
                        status: "Wrong",
                        isRepate: result === true ? true : false
                    });
                }
                this.setState({
                    startDrop: false
                });
            }
            this.checkIfCaught();
        }
    }

    FinishMove = () => {
        this.setState({
            status: ""
        });
    }

    checkIfCaught = () => {
        if (this.props.refList) {
            this.props.refList.filter(p => p.ref.current).forEach(basket => {
                if (basket && basket.ref && basket.ref.current) {
                    const box = basket.ref.current.getBoundingClientRect();
                    if (this.dropContainer && this.dropContainer.current) {
                        const object = this.dropContainer.current.getBoundingClientRect();
                        if (((object.x >= box.x && object.x <= (box.x + box.width)) || (box.x >= object.x && box.x <= (object.x + object.width)))
                            &&
                            (((object.y + object.height) >= box.y && object.y <= (box.y + box.height)) || ((box.y + box.height) >= object.y && box.y <= (object.y + object.height)))
                        ) {
                            // clearInterval(this.timer);
                            clearInterval(this.startDropTimer);
                            const status = this.props.handleDropObject(this.props.param, basket.item, basket.TrueSound, basket.FalseSound);
                            this.setState({
                                startDrop: false,
                                status,
                                isRepate: false
                            });
                        }
                    }
                }
            });
        }
    }

    render() {
        if (this.props.designerContainer && this.props.designerContainer.current && !this.state.isHide && this.state.isShow) {

            return <>
                <div ref={this.designerContainer}
                    className={`position-absolute objectMovment `}
                    id={`objectMovment${this.state.objectMovmentId}`}
                    onClick={e => this.onClick(e)}
                    onTouchMove={e => {
                        this.handleTouchMove(e)
                    }}
                    style={{
                        left: `${this.state.right}px`,
                        top: this.props.direction === "bottom" ? 'unset' : `${this.state.top}%`,
                        bottom: this.props.direction === "bottom" ? `${this.state.top}%` : 'unset',
                        zIndex: this.state.zIndex ? this.state.zIndex : 19
                    }}>
                    <GameObjects
                        {...this.props}
                        flipX={this.state.flipX}
                        style={this.state.style}
                        text={this.state.text}
                        img={this.props.ShootObjectOption !== "onaction" ? this.state.img : ""}
                        status={this.state.status}
                        FinishMove={this.FinishMove}
                        type="dragdrop-move"
                    />
                </div>
                {
                    this.state.startDrop ?
                        <div ref={this.dropContainer} className={`position-absolute objectMovment `} style={{
                            left: `${this.state.cursorClickPosition.left}px`,
                            top: `${this.state.cursorClickPosition.top}px`,
                            zIndex: this.state.zIndex ? this.state.zIndex : 19,
                            transform: `rotate(${this.state.cursorClickPosition.rotate}deg)`
                        }}>
                            <TextBox {...this.props} />
                        </div>
                        : ""
                }
            </>;
        }
        return "";
    }
}

export default HorizontalMove;
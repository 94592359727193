import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import configData from "../../Config/countries.json";
import EditSchoolForm from '../../Home/Forms/EditSchoolForm';
import api from '../../../api/api';
import UserNote from "../Users/UserNote";
import UserBill from "../Users/UserBill";

class SchoolItem extends React.Component {

    state = {
        showBills: false,
        showNotes: false,
        moreInfo: false,
        showEdit: false
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    edit = () => {
        this.setState({
            showEdit: true
        });
    }

    handleClose = () => {
        this.setState({
            showBills: false,
            showNotes: false,
            showEdit: false
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/Schools/${this.props.item.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.update(this.props.item.Id, response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.SchoolsControl.toast.Update.Title')}</div> <div className="h6">{t('Pages.SchoolsControl.toast.Update.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    clearStudents = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <i className="fa-spin fas fa-spinner"></i> {t('Pages.SchoolsControl.DeleteProcessing')}
                <div className="row row-cols-2">
                    <div className="col">
                        <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-trash-undo"></i> {t('undo')}
                        </button>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-light btn-block " onClick={() => { this.clearStudentsItem(); toast.dismiss(_toastId); }}>
                            <i className="icon fal fa-recycle"></i> {t('confirm')}
                        </button>
                    </div>
                </div>
            </div>,
            type: toast.TYPE.DARK,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
    }

    clearStudentsItem = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/DeleteSchoolStudents/${this.props.item.Id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.SchoolsControl.toast.DeleteStudents.Title')}</div> <div className="h6">{t('Pages.SchoolsControl.toast.DeleteStudents.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    bills = () => {
        this.setState({
            showBills: true
        });
    }

    notes = () => {
        this.setState({
            showNotes: true
        });
    }

    render() {
        const { t, item } = this.props;
        if (item) {

            let Country = "";
            if (item.Country) {
                Country = this.state.countries.filter(p => p.value === item.Country)[0];
            }

            return (
                <>
                    {
                        this.state.showEdit ?
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showEdit}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-edit text-primary icon"></i> {t("Pages.SchoolsControl.action.edit")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <EditSchoolForm
                                                isAdmin={true}
                                                isEdit={true}
                                                school={item}
                                                onSubmit={this.onSubmit} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showBills ? <UserBill handleClose={this.handleClose} user={item.SchoolUser} /> : ""
                    }
                    {
                        this.state.showNotes ? <UserNote handleClose={this.handleClose} user={item.SchoolUser} /> : ""
                    }
                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            {
                                item.IsLocked ?
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.SchoolsControl.IsLocked')}>
                                        <i className="fas fa-lock"></i>
                                    </div> : ""
                            }
                            {
                                item.IsForTest ?
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t('Pages.SchoolsControl.IsForTest')}>
                                        <i className="fas fa-alarm-exclamation"></i>
                                    </div> : ""
                            }
                            <div className="product-thumb">
                                <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                <div className="product-action-link">
                                    <div className="my-1" onClick={() => this.edit()} title={t("Pages.SchoolsControl.action.edit")}><i className="far fa-edit"></i></div>
                                    <div className="my-1" onClick={() => this.bills()} title={t("Pages.UsersControl.action.bills")}><i className="far fa-file-invoice-dollar"></i></div>
                                    <div className="my-1" onClick={() => this.notes()} title={t("Pages.UsersControl.action.notes")}><i className="far fa-sticky-note"></i></div>
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.SchoolsControl.action.info")}><i className="far fa-info"></i></div>
                                    <div className="my-1" onClick={() => this.clearStudents()} title={t("Pages.SchoolsControl.action.clearStudents")}><i className="far fa-eraser text-danger"></i></div>
                                    <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.SchoolsControl.action.remove")}><i className="far fa-trash"></i></div>
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title"><Link to="#" onClick={() => this.edit(item)}> {item.Name} </Link></h5>
                                <p>

                                    {
                                        item.Email ?
                                            <>
                                                <a href={`mailto:${item.Email}`} title={t('School.Email.title')}>
                                                    <i className="icon fal fa-envelope-open-text"></i>
                                                    {item.Email}
                                                </a>
                                            </>
                                            : ""
                                    }
                                    {
                                        item.Phone ?
                                            <>
                                                {
                                                    item.Email ? " - " : ""
                                                }
                                                <a href={`Tel:${item.Phone}`} title={t('School.Phone.title')}>
                                                    <i className="icon fal fa-phone-alt"></i>
                                                    <span dir="ltr">
                                                        {item.Phone}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                    {
                                        item.Fax ?
                                            <>
                                                {
                                                    item.Phone ? " - " : ""
                                                }
                                                <a href={`Tel:${item.Fax}`} title={t('School.Fax.title')}>
                                                    <i className="icon fal fa-fax"></i>
                                                    <span dir="ltr">
                                                        {item.Fax}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            Country ?
                                                <span title={t('Pages.Register.Country.title')} >
                                                    {Country.label}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.Address ?
                                                <span title={t('Pages.Register.Address.title')} >
                                                    {
                                                        Country ? " - " : ""
                                                    }
                                                    {item.Address}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.Code ?
                                                <span className="mx-2 " title={t('Pages.Register.Code.title')}>
                                                    <i className="icon fal fa-hashtag"></i>
                                                    {item.Code}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.MaxTeacherCount ?
                                                <span className="mx-2 text-danger " title={t('Pages.Register.MaxTeacherCount.title')}>
                                                    <i className="icon fal fa-abacus"></i>
                                                    {item.MaxTeacherCount}
                                                </span>
                                                : ""
                                        }
                                        <br />
                                        <span className="mt-2">
                                            {
                                                item.PosCode ?
                                                    <span title={t('Pages.Register.SchoolCode.title')}>
                                                        <i className="icon fal fa-mail-bulk"></i>
                                                        {item.PosCode + " - "}
                                                    </span>
                                                    : ""
                                            }
                                        </span>
                                    </p>
                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

export default withTranslation('common')(SchoolItem);
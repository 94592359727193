const contactsReducers = (state = [], action) => {
    switch (action.type) {
        case "CONTACT_US_LOADING":
            return {
                contacts: state.contacts,
                toRemove: state.toRemove,
                isLoaded: false
            };
        case "CONTACT_US_INITIAL":
            return {
                contacts: action.contacts,
                toRemove: [],
                isLoaded: true
            };

        case "CONTACT_US_REPLIES_LOADING":
            return {
                contacts: state.contacts,
                toRemove: state.toRemove,
                isLoaded: state.isLoaded,
                replies: [],
                repliesIsLoaded: false
            };
        case "CONTACT_US_REPLIES_INITIAL":
            return {
                contacts: state.contacts,
                toRemove: state.toRemove,
                isLoaded: state.isLoaded,
                replies: action.replies,
                repliesIsLoaded: true
            };
        case "CONTACT_US_UPDATE_INFO":
            return {
                contacts: UpdateContactData(action.contact, state.contacts, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "CONTACT_US_QUEUE_FOR_REMOVAL":
            return {
                contacts: state.contacts,
                toRemove: [...state.toRemove, action.id],
                isLoaded: true
            };
        case "CONTACT_US_CLEAN_NODES":
            return {
                contacts: state.toRemove.some(v => v === action.id) ? state.contacts.filter(v => v.Id !== action.id) : state.contacts,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "CONTACT_US_UNDO":
            return {
                contacts: state.contacts,
                toRemove: state.toRemove.filter(v => v !== action.id),
                isLoaded: true
            };
        case "CONTACT_US_IS_SEEN":
            return {
                contacts: SeeContactData(state.contacts, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        case "CONTACT_US_ADD_REPLY":
            return {
                contacts: AddReplyData(state.contacts, action.id),
                toRemove: state.toRemove,
                isLoaded: true
            };
        default:
            return state;
    }
}

function AddReplyData(contacts, id) {
    let copy = [];
    contacts.map(contact => {
        if (contact.Id === id) {
            contact = {
                ...contact,
                RepliesCount: 1
            };
        }
        copy.push({
            ...contact
        });
        return '';
    });
    return copy;
}

function SeeContactData(contacts, id) {
    let copy = [];
    contacts.map(contact => {
        if (contact.Id === id) {
            contact = {
                ...contact,
                IsRead: true
            };
        }
        copy.push({
            ...contact
        });
        return '';
    });
    return copy;
}


function UpdateContactData(newInfo, contacts, id) {
    let copy = [];
    contacts.map(contact => {
        if (contact.Id === id) {
            contact = {
                ...contact,
                ...newInfo
            };
        }
        copy.push({
            ...contact
        });
        return '';
    });
    return copy;
}

export default contactsReducers;

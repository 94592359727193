import React from "react";
import GameObjects from "../../GameObjects";

class FootballStadium extends React.Component {

    FinishMove = () => {
        if (this.props.FinishMove) {
            this.props.FinishMove();
        }
    }

    render() {

        return <div className={`position-${this.props.absolute === true ? "absolute" : "fixed"} w-100 h-100`} style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/FootballStadium/bg.png"})`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: '0'
        }}>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                left: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud9" /></div>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '2%',
                top: '10%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud8" /></div>

            <div className="position-absolute user-select-none  d-none d-sm-block" style={{
                right: '45%',
                top: '1%',
                animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
            }}><GameObjects objectCss="Cloud7" /></div>

            <div className="position-absolute user-select-none MoveBlueBird" style={{
                left: '5%',
                top: '5%'
            }}><GameObjects objectCss="ReoParrot" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

            <div className="position-absolute user-select-none" style={{
                left: 'calc(50% - 219.76px)',
                bottom: '1%'
            }}><GameObjects objectCss="FootBallTeam" status={this.props.currentStatus} FinishMove={this.FinishMove} /></div>

        </div>
    }
}
export default FootballStadium;
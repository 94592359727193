import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Popover from "react-awesome-popover";

import BackgroundGameObjectItem from "./BackgroundGameObjectItem";
import BackgroundGameObjectDragDrop from "./BackgroundGameObjectDragDrop";

class BackgroundGameObject extends React.Component {

    state = {
        showModal: false,
        currentIndex: "",
        BackgroundGameObject: [],
        currentItem: []
    };

    static getDerivedStateFromProps(props, state) {
        if (props.BackgroundGameObject) {
            state.BackgroundGameObject = props.BackgroundGameObject;
        } else {
            state.BackgroundGameObject = [];
        }
        return state;
    }

    showModal = currentIndex => {
        let currentItem = this.state.BackgroundGameObject[currentIndex];
        this.setState({
            showModal: true,
            currentIndex,
            currentItem
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    SubmitItems = currentItem => {
        let BackgroundGameObject = this.state.BackgroundGameObject;
        if (this.state.currentIndex || this.state.currentIndex === 0) {
            BackgroundGameObject[this.state.currentIndex] = currentItem;
        } else {
            BackgroundGameObject.push(currentItem);
        }
        this.setState({
            showModal: false,
            currentIndex: ""
        });
        this.props.setBackgroundGameObject(BackgroundGameObject);
    }

    removeItem = itemIndex => {
        let BackgroundGameObject = this.state.BackgroundGameObject;
        BackgroundGameObject.splice(itemIndex, 1);
        this.props.setBackgroundGameObject(BackgroundGameObject);
    }

    copyItem = currentIndex => {
        let BackgroundGameObject = this.state.BackgroundGameObject;
        let currentItem = BackgroundGameObject[currentIndex];
        BackgroundGameObject.push(currentItem);

        this.setState({
            showModal: true,
            currentIndex: BackgroundGameObject.length - 1,
            currentItem
        });
    }

    setSort = BackgroundGameObject => {
        this.props.setBackgroundGameObject(BackgroundGameObject);
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {
                    this.state.showModal ?
                        <BackgroundGameObjectItem
                            backgroundImage={this.props.backgroundImage}
                            backgroundColor={this.props.backgroundColor}
                            BgSize={this.props.BgSize}
                            Custom={this.props.Custom}
                            BgRepeat={this.props.BgRepeat}
                            BgPositionX={this.props.BgPositionX}
                            BgPositionY={this.props.BgPositionY}
                            BgAttachment={this.props.BgAttachment}
                            currentItem={this.state.currentItem}
                            setBackgroundGameObject={this.SubmitItems}
                            handleClose={this.handleClose}
                        />
                        : ""
                }
                <li id="PopoverPadding" title={t('Pages.Lessons.Questions.forms.tools.BackgroundGameObject')}>
                    <Popover overlayColor="rgba(0,0,0,0)" placement="left-center">
                        <Link to="#" className="cate">
                            <i className="fal fa-shapes"></i>
                            {
                                this.props.title ? this.props.title :
                                    t('Pages.Lessons.Questions.forms.tools.BackgroundGameObject')
                            }
                        </Link>
                        <div className='widget widget-search widget-category' style={{
                            width: '400px'
                        }}>
                            <div className='widget-header p-2' >
                                <i className="fal fa-shapes icon"></i>
                                {
                                    this.props.title ? this.props.title :
                                        t('Pages.Lessons.Questions.forms.tools.BackgroundGameObject')
                                }
                            </div>
                            <div className='widget-body p-3'>
                                <div className="contact-form">
                                    <div className="btn btn-block btn-dark" onClick={() => this.showModal()}>
                                        <i className="icon fas fa-plus"></i>
                                        {t('Pages.Lessons.Questions.forms.tools.add_BackgroundGameObject')}
                                    </div>
                                    <div className="widget-header p-1 w-100 mb-3"></div>

                                    <div className="w-100 position-relative">
                                        <BackgroundGameObjectDragDrop
                                            items={this.state.BackgroundGameObject}
                                            setSort={this.setSort}
                                            DeleteItem={this.removeItem}
                                            copyItem={this.copyItem}
                                            EditItem={this.showModal}
                                        />
                                    </div>
                                    <div className="widget-header p-1 w-100 mb-3"></div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </li>
            </>
        );
    }
}

export default withTranslation('common')(BackgroundGameObject);
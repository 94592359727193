
import React from 'react';
import CanvasDraw from 'react-canvas-draw';

class CustomMovePaint extends React.Component {

    constructor() {
        super();
        this.lines = [];
        this.state = {
            isDrawing: false,
            brushRadius: 2,
            brushColor: '#fa0302'
        };
    }

    componentDidMount() {
        window.addEventListener("mouseup", this.handleMouseUp, { passive: true });
    }
    componentWillUnmount() {
        window.removeEventListener("mouseup", this.handleMouseUp);
    }
    handleMouseUp = () => {
        this.setState({ isDrawing: false });
        this.setDrawMovment();
    }

    handleMouseMove = mouseEvent => {
        if (!this.state.isDrawing) {
            return;
        }
        const point = this.relativeCoordinatesForEvent(mouseEvent);
        if (point !== 0) {
            this.lines.push(point);
        }
    }
    setDrawMovment = () => {
        if (this.lines && this.lines.length > 0) {
            this.props.setDrawMovment(this.lines, this.props.index, this.props.id);
        }
    }

    handleMouseDown = mouseEvent => {
        this.lines = [];
        this.canvasDraw.clear();
        this.setState({
            isDrawing: true
        }, () => this.handleMouseMove(mouseEvent));
    }

    relativeCoordinatesForEvent = mouseEvent => {
        const boundingRect = this.refs.drawArea.getBoundingClientRect();
        const { width, height } = boundingRect;
        let x = parseFloat(((mouseEvent.clientX - boundingRect.left - 50) / (width - 100) * 100).toFixed(2));
        let y = parseFloat(((mouseEvent.clientY - boundingRect.top - 50) / (height - 100) * 100).toFixed(2));

        x = 100 - x;
        x = x <= 100 && x >= 0 ? x : x < 0 ? -20 : 120;
        y = y <= 100 && y >= 0 ? y : y < 0 ? -20 : 120;

        const lines = this.lines.length > 0 ? this.lines[this.lines.length - 1] : { x: 0, y: 0 };
        const { x: oldx, y: oldy } = lines;
        if (oldx === x && oldy === y) {
            return 0;
        }
        if ((oldx === -20 && x === -20) || (oldy === -20 && y === -20) || (oldx === 120 && x === 120) || (oldy === 120 && y === 120)) {
            return 0;
        }
        const dx = x - oldx;
        const dy = y - oldy;
        let inRads = Math.atan2(dy, dx);

        if (inRads < 0)
            inRads = Math.abs(inRads);
        else
            inRads = 2 * Math.PI - inRads;
        const rotate = inRads * (180 / Math.PI);
        return {
            x: x <= 100 && x >= 0 ? x : x < 0 ? -20 : 120,
            y: y <= 100 && y >= 0 ? y : y < 0 ? -20 : 120,
            rotate
        };
    }

    render() {
        return (
            <div className="col-12 mb-3">
                <div dir="ltr" className='position-relative' onMouseUp={this.setDrawMovment} onMouseMove={this.handleMouseMove} ref="drawArea" onMouseDown={this.handleMouseDown} style={{
                    background: "#efefef"
                }}>
                    <div className='position-absolute bg-white' style={{
                        height: '500px',
                        width: 'calc(100% - 100px)',
                        margin: '50px',
                        border: '2px solid #000'
                    }}></div>
                    <CanvasDraw
                        id="canvas"
                        ref={canvasDraw => (this.canvasDraw = canvasDraw)}
                        brushColor={this.state.brushColor}
                        lazyRadius={this.state.brushRadius}
                        brushRadius={this.state.brushRadius}
                        canvasWidth='100%'
                        canvasHeight='100%'
                        hideGrid
                        style={{
                            background: 'transparent',
                            height: "600px",
                            zIndex: '21',
                            border: '2px solid #000'
                        }} />
                </div>
            </div>
        );
    }
}

export default CustomMovePaint;


import api from '../../../api/api';

export const fetchLessons = () => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/LastLessons/`);
    dispatch({ type: 'LESSONS_INITIAL', lessons: response.data.data });
}

export const fetchStudentRanks = (PointMonth, PointYear, ClassRoomId) => async dispatch => {
    dispatch({ type: 'STUDENT_RANKS_LOADING' });
    const response = await api.post(`/StudentRank/`, {
        PointMonth,
        PointYear,
        ClassRoomId
    });
    dispatch({ type: 'STUDENT_RANKS_INITIAL', studentRanks: response.data.data });
}

export const fetchRanks = () => async dispatch => {
    dispatch({ type: 'RANKS_LOADING' });
    const response = await api.get(`/Ranks/`);
    dispatch({ type: 'RANKS_INITIAL', ranks: response.data.data });
}

export const setLessonFavorite = id => async dispatch => {
    const response = await api.put(`/User/${id}?IsForLesson=${true}`);
    dispatch({ type: 'SEARCH_LESSONS_UPDATE_FAVORITE', id: response.data });
}
export const searchLessons = postData => async dispatch => {
    dispatch({ type: 'SEARCH_LOADING' });
    const response = await api.post(`/Search/`, postData);
    dispatch({ type: 'SEARCH_INITIAL', data: response.data.data })
}

export const setFileFavorite = id => async dispatch => {
    const response = await api.put(`/User/${id}?IsForLesson=${false}`);
    dispatch({ type: 'SEARCH_FILES_UPDATE_FAVORITE', id: response.data });
}
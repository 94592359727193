export const setObject = (Type, Object) => {
    sessionStorage.setItem(Type, JSON.stringify(Object));
}

export const getObject = Type => {
    const ObjectStr = sessionStorage.getItem(Type);
    if (ObjectStr) return JSON.parse(ObjectStr);
    else return null;
}


export const setIndeices = (Indeices) => {
    sessionStorage.setItem('Indeices', JSON.stringify(Indeices));
}

export const getIndeices = () => {
    const IndeicesStr = sessionStorage.getItem('Indeices');
    if (IndeicesStr) return JSON.parse(IndeicesStr);
    else return null;
}

// remove the token and user from the session storage
export const removeIndeices = () => {
    sessionStorage.removeItem('Indeices');
}

export const removeIndeicesCount = (id) => {
    var Indeices = getIndeices();
    if (Indeices) {
        Indeices.nodes = removeIndeicesCountAction(Indeices.nodes, id);
    }
    sessionStorage.setItem('Indeices', JSON.stringify(Indeices));
}

function removeIndeicesCountAction(nodes, id) {
    let copy = [];
    nodes.map(node => {
        if (node.id === id) {
            node = {
                ...node,
                count: node.count - 1,
            };
        }
        copy.push({
            ...node,
            nodes: removeIndeicesCountAction(node.nodes, id)
        });
        return '';
    });
    return copy;
}

export const setLessonSort = (id, sort) => {
    var lessons = getLessons();
    if (lessons) {
        let copy = [];
        lessons.lessons.map(node => {
            if (node.Id === id) {
                node = {
                    ...node,
                    Sort: sort
                };
            }
            copy.push({
                ...node
            });
            return '';
        });
        lessons.lessons = copy;
        sessionStorage.setItem('Lessons', JSON.stringify(lessons));
    }
}

export const setLessons = (Lessons) => {
    var old = getLessons();
    if (old) {
        let lessons = Lessons.lessons;
        if (lessons) {
            let copy = [];
            lessons.map(lesson => {
                copy = [...copy, lesson];
                return '';
            });
            Lessons.lessons = copy;
        }
    }
    sessionStorage.setItem('Lessons', JSON.stringify(Lessons));
}

export const removeLesson = (id) => {
    var Lessons = getLessons();
    if (Lessons) {
        Lessons.lessons = Lessons.lessons.filter(v => v.Id !== id);
    }
    sessionStorage.setItem('Lessons', JSON.stringify(Lessons));
}

export const getLessons = () => {
    const LessonStr = sessionStorage.getItem('Lessons');
    if (LessonStr) return JSON.parse(LessonStr);
    else return null;
}

export const getFiles = () => {
    const FilesStr = sessionStorage.getItem('Files');
    if (FilesStr) return JSON.parse(FilesStr);
    else return null;
}

export const setFiles = (Files) => {
    var old = getFiles();
    if (old) {
        let files = Files.files;
        if (files) {
            let copy = [];
            files.map(lesson => {
                copy = [...copy, lesson];
                return '';
            });
            Files.files = copy;
        }
    }
    sessionStorage.setItem('Files', JSON.stringify(Files));
}

export const getExams = () => {
    const ExamsStr = sessionStorage.getItem('Exams');
    if (ExamsStr) return JSON.parse(ExamsStr);
    else return null;
}

export const setExams = (Exams) => {
    var old = getExams();
    if (old) {
        let files = Exams.files;
        if (files) {
            let copy = [];
            files.map(lesson => {
                copy = [...copy, lesson];
                return '';
            });
            Exams.files = copy;
        }
    }
    sessionStorage.setItem('Exams', JSON.stringify(Exams));
}

export const removeExam = (id) => {
    var Exams = getExams();
    if (Exams) {
        Exams.lessons = Exams.lessons.filter(v => v.Id !== id);
    }
    sessionStorage.setItem('Exams', JSON.stringify(Exams));
}

export const setExamSort = (id, sort) => {
    var items = getExams();
    if (items) {
        let copy = [];
        items.lessons.map(node => {
            if (node.Id === id) {
                node = {
                    ...node,
                    Sort: sort
                };
            }
            copy.push({
                ...node
            });
            return '';
        });
        items.lessons = copy;
        sessionStorage.setItem('Exams', JSON.stringify(items));
    }
}


export const getGames = () => {
    const GamesStr = sessionStorage.getItem('Games');
    if (GamesStr) return JSON.parse(GamesStr);
    else return null;
}

export const setGames = (Games) => {
    var old = getGames();
    if (old) {
        let files = Games.files;
        if (files) {
            let copy = [];
            files.map(lesson => {
                copy = [...copy, lesson];
                return '';
            });
            Games.files = copy;
        }
    }
    sessionStorage.setItem('Games', JSON.stringify(Games));
}

export const removeGame = (id) => {
    var Games = getGames();
    if (Games) {
        Games.lessons = Games.lessons.filter(v => v.Id !== id);
    }
    sessionStorage.setItem('Games', JSON.stringify(Games));
}

export const setGameSort = (id, sort) => {
    var items = getGames();
    if (items) {
        let copy = [];
        items.lessons.map(node => {
            if (node.Id === id) {
                node = {
                    ...node,
                    Sort: sort
                };
            }
            copy.push({
                ...node
            });
            return '';
        });
        items.lessons = copy;
        sessionStorage.setItem('Games', JSON.stringify(items));
    }
}
import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ErrorPage from "../Admin/Question/View/Background/ErrorPage";

class NotFound extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>{t("Pages.NotFound.title")} | {t('alarabeyya')}</title>
                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `header{ display:none; }section{padding-top:5px !important;padding-bottom:5px !important;}` }}></style>
                <section className="error-section min-height-section" style={{
                    height: '100vh'
                }} >
                    <ErrorPage />
                    <div className="container">
                        <div className="error-wrapper">
                            <div className="inner-logo">
                                <Link to="/">
                                    <LazyLoadImage src="/assets/images/logo/big-logo.png" alt={`${t('alarabeyya')}`} />
                                </Link>
                            </div>
                            <div className="main-thumb">
                                <LazyLoadImage src="/assets/images/error/404.png" alt={`${t('error')}`} />
                            </div>
                            <div className="error-content">
                                <h3 className="title">{t("Pages.NotFound.title")}</h3>
                                <p>{t("Pages.NotFound.Message")}</p>
                                <Link to="/" className="custom-button">
                                    <span>{t("Pages.NotFound.GoBackToHome")}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default withTranslation('common')(NotFound);